<div class="ancillaryTabs">
  <div class="">
    <div class="tabButton" [ngClass]="{'activeTab':currentTab===i}" *ngFor="let tab of tabs; let i = index;" (click)="setCurrentTab(i)">

      <img class="serviceImageCr" src="assets/img/airline/icon/{{flights[i].operatingCarrier}}.png"/>

      {{flights[i].ref}}) {{flights[i].departure.location}} -> {{flights[i].arrival.location}}
    </div>
  </div>

  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <hr/>
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <br/>
      <h5>{{'LOADING'|translate}}</h5>
    </div>

  </div>


  <div *ngIf="!loading && ancillaryServices && flights[currentTab].operatingCarrier == reservation.marketingCarrier" class="tabContent">
    <div class="mb-3">{{'PLEASE_SELECT_SERVICES_FOR'|translate}} {{flights[currentTab].departure.location}} -> {{flights[currentTab].arrival.location}}</div>

    <div class="mb-3" *ngIf="!category">{{'PLEASE_SELECT_SERVICES_CATEGORY'|translate}}</div>

    <div class="mb-3 buttonrow">
      <div class="row">
        <div class="col-lg-6 col-6">
          <button [ngClass]="{'activeBtn':passenger.ref == currentPassenger!.ref}" *ngFor="let passenger of passengers" mat-stroked-button (click)="setCurrentPax(passenger)">
            {{passenger.surname}} {{passenger.name}}
          </button>
        </div>
        <div class="col-lg-6 col-6">
          <span *ngIf="!category" class="material-icons">
            subdirectory_arrow_right
          </span>
          <button [ngClass]="{'activeBtn':ancillary.key == category}" *ngFor="let ancillary of ancillaryServices | keyvalue" mat-stroked-button (click)="setCategory(ancillary.key)">
            {{ancillary.key}}
          </button>
        </div>
      </div>
      <hr *ngIf="this.ancillariesSelection.length>0"/>
      <div class="row" *ngIf="this.ancillariesSelection.length>0">
        <div class="col-lg-6 col-6">
          <button mat-stroked-button (click)="saveAncillaries(reservationId)">{{'CONFIRM_SELECTIONS'|translate}}</button>
        </div>
        <div class="col-lg-6 col-6">
          {{'TOTAL'|translate}} {{calculateAncillariesTotal()|number:'.2-2'}}€
        </div>
      </div>
    </div>
    <div class="" *ngIf="category">
      <div class="table-responsive">
        <table class="table ancillariesTable">
          <tr>
            <td>{{'SERVICE'|translate}}</td>
            <td>{{'INCLUDED'|translate}}</td>
            <td>{{'PRICE'|translate}}</td>
            <td>{{currentPassenger.surname}} {{currentPassenger.name}}</td>
          </tr>
          <ng-container *ngFor="let service of ancillaryServices[category]">
            <tr *ngIf="service.price !== 0">
              <td *ngIf="isIncluded(service)">{{service.name}}</td>
              <td *ngIf="isIncluded(service)">
              <span class="serviceSect" *ngFor="let fl of service.flights">
                <span [ngClass]="{'thisTab':flights[currentTab].ref == fl}">{{fl}}) {{returnSingleFl(fl)}}</span>
              </span>
              </td>
              <td *ngIf="isIncluded(service)">{{service.price|number:'.2-2'}}€</td>
              <td *ngIf="isIncluded(service)">
                <button [disabled]="isReserved(service, currentPassenger.ref)" (click)="addServiceToSelection(service, currentPassenger.ref)" mat-icon-button aria-label="Selection button">
                  <mat-icon *ngIf="isSelected(service, currentPassenger.ref) || isReserved(service, currentPassenger.ref)">check_box</mat-icon>
                  <mat-icon *ngIf="!isSelected(service, currentPassenger.ref) && !isReserved(service, currentPassenger.ref)">check_box_outline_blank</mat-icon>
                </button>
                <button *ngIf="isReserved(service, currentPassenger.ref)" (click)="removeAncillaries(service, reservationId, service.item.ssr, currentPassenger.ref)" mat-icon-button aria-label="Selection button">
                  <mat-icon >delete_outline</mat-icon>
                </button>
              </td>
            </tr>
          </ng-container>

        </table>
      </div>
    </div>
  </div>

  <div *ngIf="!loading && ancillaryServices && flights[currentTab].operatingCarrier != reservation.marketingCarrier">
    <div class="tabContent text-center">
      <p>Su questo volo non é possibile prenotare servizi accessori in autonomia.</p>
      <p>Contattare l'assistenza per verificarne la possibilità tramite operatore.</p>
      <p>Grazie</p>
      <div class="pnrActionsButtons">
        <p><button mat-raised-button color="primary" (click)="openBottomSheetEmission()">Chiedi supporto</button></p>
      </div>
      
    </div>
  </div>
</div>

<div class="modal fade" id="deleteAncillary" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="deleteAncillary" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content" *ngIf="currentAncillary">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteAncillaryModalTitle">{{'DELETE_ANCILLARY'|translate}} ?</h5>
        <button *ngIf="!deleting" type="button" class="close" (click)="toggleDeleteModal(undefined)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3">{{'DELETE_ANCILLARY_SERVICE'|translate}} {{currentAncillary.service.name}}?</div>
        <!-- <div>{{'DELETE_RESERVATION_TEXT'|translate}}</div> -->
      </div>

      <hr/>

      <div class="text-right" *ngIf="!deleting">
        <button type="button" mat-button (click)="toggleDeleteModal(undefined)">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="removeAncillariesApi(currentAncillary)">{{'DELETE_ANCILLARY'|translate}}</button>
      </div>
      <div class="text-right" *ngIf="deleting">
        <div class="mb-2" >{{'LOADING'|translate}}</div>
        <mat-progress-bar style="max-width: 200px;display:inline-block;" mode="indeterminate"></mat-progress-bar>
      </div>

    </div>
  </div>
</div>
