<custom-title></custom-title>
<div class="padding20">

  <div class="container">
    <div class="loginContainer">
      <p class="mb-2">{{'LOGIN_AS'|translate}}</p>
      <mat-form-field class="full-width" appearance="outline">
        <input matInput placeholder="{{'USERNAME'|translate}}" aria-label="Carrier" (ngModelChange)="searchUser($event)" [(ngModel)]="text" [matAutocomplete]="users">
        <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #users="matAutocomplete" (optionSelected)="getUser($event.option.value)" (closed)="selectFirst()">
          <mat-option *ngFor="let user of userList" [value]="user">
            <span>{{user.name}} {{user.surname}}</span>
            <span class="material-icons">
              arrow_right_alt
            </span>
            <small *ngIf="user && user.company">{{user.company.name}}</small>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <button [disabled]="!userToLog" class="full-width" (click)="loginAs()" mat-raised-button color="primary">
        {{'LOGIN'|translate}}
      </button>
    </div>
  </div>

</div>
