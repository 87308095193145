<custom-title></custom-title>
<div [ngClass]="{'padding20':!page}">
  <div class="card marginbottom20">

    <div *ngIf="loading" class="loader">
      <div class="card text-center padding20">
        <div class="d-flex justify-content-center">
          <mat-spinner></mat-spinner>
        </div>
        <h5>{{'LOADING'|translate}}...</h5>
      </div>
    </div>

    <div *ngIf="!loading" class="row">

      <div class="col-lg-6 d-md-flex align-items-md-center pl-3">
        <button mat-button [matMenuTriggerFor]="orderMenu" class="listButton">
          {{sort|translate}} <span class="material-icons">keyboard_arrow_down</span>
        </button>
        <mat-menu #orderMenu="matMenu">
          <button mat-menu-item (click)="sortValue('createdAt')">{{'CREATED_DATE'|translate}}</button>
        </mat-menu>

        <button mat-icon-button (click)="reverseOrder()" class="iconButtons">
          <mat-icon *ngIf="query.order==='asc'" matTooltip="{{'ASC'|translate}}">vertical_align_top</mat-icon>
          <mat-icon *ngIf="query.order==='desc'" matTooltip="{{'DESC'|translate}}">vertical_align_bottom</mat-icon>
        </button>

        <button mat-icon-button (click)="openFilters()" class="iconButtons">
          <mat-icon matTooltip="{{'SEARCH'|translate}}">search</mat-icon>
        </button>

        <button mat-icon-button (click)="addDossierToggle()" class="iconButtons">
          <mat-icon matTooltip="{{'ADD_DOSSIER'|translate}}">add</mat-icon>
        </button>
        <mat-checkbox *ngIf="mainComponent.loggedUser.role.superAdmin && !appComponent.isSbt" class="ml-3 vertical d-inline-block"
                      [(ngModel)]="query.options.viewAll" (ngModelChange)="getDossierList()" color="primary">
          viewAll
        </mat-checkbox>
      </div>
      <div class="col-lg-6 d-flex justify-content-end text-right">
        <div class="paginationr pr-3">
          <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
          <span>
            {{'PAGE'|translate}}
            <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
              <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p + 1}}</mat-option>
              </mat-select>
            </mat-form-field>
            {{'OF'|translate}} {{maxpages}}
          </span>

          <button mat-icon-button class="iconButtons" (click)="prev()" [disabled]="currentpage==0">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button mat-icon-button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>

      </div>

    </div>

    <div *ngIf="!loading" class="collapse pnrfilters padding10">
      <hr/>
      <div class="row marginbottom10">
        <div class="col-12 col-sm-6 col-md-4 mb-2">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{'SEARCH'|translate}}</mat-label>
            <input matInput type="text" [(ngModel)]="query.text" (change)="setFilterText()">
            <!--
            <button mat-button *ngIf="query.text" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilterText()">
              <mat-icon>close</mat-icon>
            </button> -->
            <mat-hint align="start">{{'DIGIT_YOUR_SEARCH'|translate}}</mat-hint>
            <mat-hint style="color:red;cursor:pointer;" *ngIf="query.text" (click)="clearFilterText()" align="end">{{'CLEAR_SEARCH'|translate}} X</mat-hint>
          </mat-form-field>
        </div>

        <div class="col-12 col-sm-6 col-md-4 mb-2" *ngIf="mainComponent.loggedUser.role.superAdmin && !page">
          <!-- <app-companies [filtering]="filterCompanies" [query]="query"></app-companies> -->
        </div>
        <div class="col-12 mb-2 cpfilter" *ngIf="filterCompanies.length>0">
          <hr/>
          <h6 class="mb-2">{{'FILTERING_COMPANIES'|translate}}</h6>
          <div *ngIf="filterCompanies.length>0 && !page">
            <mat-chip-listbox aria-label="Companies selections" class="d-inline" *ngIf="filterCompanies[0].name">
              <mat-chip-option *ngFor="let element of filterCompanies">
                {{element.name}}
                <mat-icon (click)="removeCpFilter(element._id)" matChipRemove>cancel</mat-icon>
              </mat-chip-option>

            </mat-chip-listbox>
            <div *ngIf="!filterCompanies[0].name">
              {{filterCompanies.length}} {{'COMPANIES_SELECTED'|translate}}
              <button mat-button (click)="clearAllCp()">{{'CLEAR_ALL_CP'|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="" *ngIf="progress">
      <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>

  <div class="hotelList" *ngIf="dossierList && dossierList.list.length > 0">

    <mat-card appearance="outlined" class="example-card mb-3" *ngFor="let dossier of dossierList.list">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>
          <span *ngIf="dossier.locator">{{dossier.locator}}</span>
        </mat-card-title>
        <mat-card-subtitle>
          <span>{{dossier.name}} - {{dossier.createdAt|date:'dd/MM/yyyy - HH:mm'}}</span>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="emptyDossier(dossier)" class="mb-2">
          {{'DOSSIER_IS_EMPTY'|translate}}
        </div>
        <div *ngIf="!emptyDossier(dossier)" class="mb-2">
          <div class="dossierItemtype" *ngIf="dossier.flights.length > 0">
            <div class="row" *ngFor="let flight of dossier.flights" [ngClass]="{'del':flight.doc.status === 'cancelled'}">
              <div class="col-lg-3">
                <span class="material-icons">
                  flight
                </span>
                {{flight.doc.status|translate}}<br/>
                <span class="dossierCarrier">
                  <img class="" src="/assets/img/airline/icon/{{flight.doc.marketingCarrier}}.png">
                </span>
                <span class="smalltext" *ngIf="flight.doc.locator">{{flight.doc.locator}}</span><br/>
                <span class="pl-4 d-block smalltext" *ngIf="flight.doc.timeLimit">Time limit: {{flight.doc.timeLimit| date:'dd/MM/yyyy - HH:mm'}}</span>
              </div>
              <div class="col-lg-7">
                <div class="row newItemPnr" *ngFor="let itinerary of flight.doc.itineraries; let l = last">
                  <div class="col-5">
                    {{itinerary.flights[0].departure.date|date:'dd/MM/yyyy'}}
                    <span class="smallName">{{itinerary.flights[0].departure.hours}}:{{itinerary.flights[0].departure.minutes}}</span><br/>
                    <span class="smallName">{{itinerary.flights[0].departure.location}}</span>
                  </div>
                  <div class="col-2 align-self-center">
                    <span class="material-icons">arrow_right_alt</span>
                  </div>
                  <div class="col-5">
                    {{itinerary.flights[itinerary.flights.length - 1].arrival.date|date:'dd/MM/yyyy'}}
                    <span class="smallName">{{itinerary.flights[itinerary.flights.length - 1].arrival.hours}}:{{itinerary.flights[itinerary.flights.length - 1].arrival.minutes}}</span>
                    <br/>
                    <span class="smallName">{{itinerary.flights[itinerary.flights.length - 1].arrival.location}}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <span *ngIf="!mainComponent.appcomponent.isB2B">
                  <span *ngIf="flight.doc.negotiated && !priceService.isNegoHidden()">{{flight.doc.fare.amount + flight.doc.fare.fees|number:'.2-2'}}€</span>
                  <span *ngIf="flight.doc.negotiated && priceService.isNegoHidden()">{{flight.doc.fare.publicAmount|number:'.2-2'}}€</span>
                  <span *ngIf="!flight.doc.negotiated">{{flight.doc.fare.amount|number:'.2-2'}}€</span>
                  <span *ngIf="flight.doc.fare.services != 0"> | {{'SERVICES'|translate}} {{flight.doc.fare.services|number:'.2-2'}}€</span>
                  <span *ngIf="flight.doc.negotiated" matTooltip="{{'NEGOTIATED_FARE'|translate}}" class="ml-1 material-icons">
                    star_border
                  </span>
                </span>
                <span *ngIf="mainComponent.appcomponent.isB2B">
                  <span *ngIf="!flight.doc.negotiated">{{flight.doc.fare.amount|number:'.2-2'}}€</span>
                  <span *ngIf="flight.doc.negotiated">{{flight.doc.fare.publicAmount - flight.doc.fare.fees|number:'.2-2'}}€</span>
                  <span *ngIf="flight.doc.fare.services != 0"> | {{'SERVICES'|translate}} {{flight.doc.fare.services|number:'.2-2'}}€</span>
                  <span *ngIf="flight.doc.negotiated" matTooltip="{{'DISCOUNT_FARE'|translate}}" class="ml-1 material-icons">
                    star_border
                  </span>
                </span>
              </div>
            </div>

          </div>
          <hr *ngIf="dossier.hotels.length > 0"/>
          <div class="dossierItemtype" *ngIf="dossier.hotels.length > 0">
            <div class="" *ngFor="let hotel of dossier.hotels">
              <div class="row" *ngIf="hotel.doc">
                <div class="col-lg-3">
                <span class="material-icons">
                  hotel
                </span>
                  <span *ngIf="hotel.doc">{{hotel.doc.status|translate}}</span>
                  <br/>
                  <span class="dossierCarrier">
                  <img class="" src="/assets/img/hotel-icon.png">
                </span>
                  <span class="smalltext" *ngIf="hotel.doc.locator">{{hotel.doc.locator}}</span>
                </div>
                <div class="col-lg-7">
                  <div class="row newItemPnr">
                    <div class="col-12 mb-2">
                      {{hotel.doc.hotel.name}} <span class="smalltext" *ngIf="!hotel.doc.hotel.address">({{hotel.doc.city}})</span>
                      <span class="smalltext" *ngIf="hotel.doc.hotel.address">({{hotel.doc.hotel.address.city}})</span>
                      <br/>
                      {{hotel.doc.room.roomRates[0].text}}
                    </div>
                    <div class="col-5">
                      Check-in {{hotel.doc.hotel.checkIn|date:'dd/MM/yyyy'}}
                    </div>
                    <div class="col-2 align-self-center">
                      <span class="material-icons">arrow_right_alt</span>
                    </div>
                    <div class="col-5">
                      Check-out {{hotel.doc.hotel.checkOut|date:'dd/MM/yyyy'}}
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  {{hotel.doc.room.roomRates[0].amount|number:'.2-2'}}€
                </div>
              </div>

            </div>

          </div>
          <hr *ngIf="dossier.cars.length > 0"/>
          <div class="dossierItemtype" *ngIf="dossier.cars.length > 0">
            <div class="row" *ngFor="let car of dossier.cars">
              <div class="col-lg-3">
                <span class="material-icons">
                  directions_car
                </span>
                {{car.doc.status|translate}}<br/>
                <span class="dossierCarrier">
                  <img *ngIf="car.doc.car.images[0]!='https://none'" src="{{car.doc.car.images[0]}}"/>
                  <img *ngIf="car.doc.car.images[0]=='https://none'" src="/assets/img/cars/nophoto.png"/>
                </span>
                <span class="smalltext" *ngIf="car.doc.locator">{{car.doc.locator}}</span>
              </div>
              <div class="col-lg-7">
                <div class="row newItemPnr">
                  <div class="col-12 mb-2">
                    {{car.doc.car.model}}
                  </div>
                  <div class="col-5">
                    {{car.doc.car.pickUpInfo.date | date:'dd/MM/yyyy'}}<br/>
                    {{car.doc.rentCompany.rentalLocations[0].address}}
                  </div>
                  <div class="col-2 align-self-center">
                    <span class="material-icons">arrow_right_alt</span>
                  </div>
                  <div class="col-5">
                    {{car.doc.car.dropOffInfo.date | date:'dd/MM/yyyy'}}<br/>
                    <span *ngIf="car.doc.rentCompany.rentalLocations[1]">{{car.doc.rentCompany.rentalLocations[1].address}}</span>
                    <span *ngIf="!car.doc.rentCompany.rentalLocations[1]">{{car.doc.rentCompany.rentalLocations[0].address}}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                {{returnCarAmount(car.doc.car.rates)}} €
              </div>
            </div>

          </div>
          <ng-container #trainList *ngIf="dossier.trains.length > 0">
            <hr/>
            <div class="dossierItemtype">
              <div class="row" *ngFor="let train of dossier.trains" [ngClass]="{'del':['CANCELLED','EXPIRED'].includes(train.doc?.status)}">
                <ng-container *ngIf="train.doc != null">
                  <div class="col-3">
                  <span class="material-icons">
                    train
                  </span>
                  {{train.doc.status|translate}}<br/>
                  <span class="dossierCarrier">
                    <img class="" src="{{getTrainLogo(train.doc.carrier)}}">
                  </span>
                    <span class="smalltext" *ngIf="train.doc.pnr">{{train.doc.pnr}}</span><br/>
                  </div>
                  <div class="col-7">
                    <div class="row" *ngFor="let seg of train.doc.itineraries">
                      <div class="col-5">
                        <div class="row">
                          {{seg.from.date | date:'dd/MM/yyyy - HH:mm'}}
                        </div>
                        <div class="row">
                          {{seg.from.station}}
                        </div>
                      </div>
                      <div class="col-2 align-self-center">
                        <span class="material-icons">arrow_right_alt</span>
                      </div>
                      <div class="col-5">
                        <div class="row">
                          {{seg.to.date | date:'dd/MM/yyyy - HH:mm'}}
                        </div>
                        <div class="row">
                          {{seg.to.station}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    {{train.doc.ratedPrice|number:'.2-2'}}€
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row pnrActionRow">
          <div class="col-lg-6">
            <div class="mt-2">
              <a routerLink="/users/list/{{dossier.user._id}}">{{dossier.user.surname}} {{dossier.user.name}}</a>
              <span class="material-icons">arrow_right_alt</span>
              <a routerLink="/companies/list/{{dossier.company._id}}">{{dossier.company.name}}</a>
            </div>
          </div>
          <div class="col-lg-6 text-right buttonrowBis">
            <div class="buttonrow" *ngIf="!page">
              <button mat-stroked-button (click)="navigateToDossier(dossier._id)" color="primary">{{'OPEN_DOSSIER'|translate}}</button>
              <button mat-stroked-button (click)="deleteDossierToggle(dossier._id)">{{'DELETE_DOSSIER'|translate}}</button>
            </div>
            <div class="buttonrow" *ngIf="page && page=='flight'">
              <button mat-stroked-button color="primary" (click)="addFlights(dossier._id)">{{'SELECT_DOSSIER'|translate}}</button>
            </div>
            <div class="buttonrow" *ngIf="page && page=='hotel'">
              <button mat-stroked-button color="primary" (click)="addHotelss(dossier._id)">{{'SELECT_DOSSIER'|translate}}</button>
            </div>
            <div class="buttonrow" *ngIf="page && page=='car'">
              <button mat-stroked-button color="primary" (click)="addCars(dossier._id)">{{'SELECT_DOSSIER'|translate}}</button>
            </div>
            <div class="buttonrow" *ngIf="page && page=='train'">
              <button mat-stroked-button color="primary" (click)="addTrains(dossier._id)">{{'SELECT_DOSSIER'|translate}}</button>
            </div>

          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <!--
    <div class="card padding20 mb-3" *ngFor="let dossier of dossierList.list">
      <div class="row">
        <div class="col-9 align-self-center">
          <div class="row">
            <div class="col-lg-4 align-self-center">
              <div class="pnrliststatus marginbottom5">
                <span class="init">{{dossier.locator}}</span>
                <div class="listlocator"><span>{{dossier.name}}</span></div>
                <div class="mt-2">{{dossier.createdAt|date:'dd/MM/yyyy - HH:mm'}}</div>
              </div>

            </div>

            <div class="col-lg-8 align-self-center">
              <div class="dossierElements">
                <div *ngIf="emptyDossier(dossier)" class="mb-2">
                  {{'DOSSIER_IS_EMPTY'|translate}}
                </div>

                <div class="mb-2" *ngIf="dossier.flights.length > 0">
                  <div *ngFor="let flight of dossier.flights" [ngClass]="{'del':flight.doc.status === 'cancelled'}">
                    <div class="cicledElement" *ngFor="let itinerary of flight.doc.itineraries; let l = last">
                      <div class="row">
                        <div class="col-1 align-self-center">
                          <span class="dossierCarrier">
                            <img class="" src="/assets/img/airline/icon/{{flight.doc.marketingCarrier}}.png">
                          </span>
                        </div>
                        <div class="col-lg-4 align-self-center">
                          {{itinerary.flights[0].departure.date|date:'dd/MM/yyyy'}}
                          <span class="smallName">{{itinerary.flights[0].departure.hours}}:{{itinerary.flights[0].departure.minutes}}</span><br/>
                          <span class="smallName">{{itinerary.flights[0].departure.location}}</span>
                        </div>
                        <div class="col-lg-4 align-self-center">
                          {{itinerary.flights[itinerary.flights.length-1].arrival.date|date:'dd/MM/yyyy'}}
                          <span class="smallName">{{itinerary.flights[itinerary.flights.length-1].arrival.hours}}:{{itinerary.flights[itinerary.flights.length-1].arrival.minutes}}</span>
                          <br/>
                          <span class="smallName">{{itinerary.flights[itinerary.flights.length-1].arrival.location}}</span>
                        </div>
                        <div class="col-lg-3 align-self-center" >
                          <span *ngIf="!mainComponent.appcomponent.isB2B">
                            <span *ngIf="flight.doc.negotiated && !priceService.isNegoHidden()">{{flight.doc.fare.amount + flight.doc.fare.fees|number:'.2-2'}}€</span>
                            <span *ngIf="flight.doc.negotiated && priceService.isNegoHidden()">{{flight.doc.fare.publicAmount|number:'.2-2'}}€</span>
                            <span *ngIf="!flight.doc.negotiated">{{flight.doc.fare.amount|number:'.2-2'}}€</span>
                            <span *ngIf="flight.doc.fare.services != 0"> | {{'SERVICES'|translate}} {{flight.doc.fare.services|number:'.2-2'}}€</span>
                            <span *ngIf="flight.doc.negotiated" matTooltip="{{'NEGOTIATED_FARE'|translate}}" class="ml-1 material-icons">
                              star_border
                            </span>
                          </span>
                          <span *ngIf="mainComponent.appcomponent.isB2B">
                            <span *ngIf="!flight.doc.negotiated">{{flight.doc.fare.amount|number:'.2-2'}}€</span>
                            <span *ngIf="flight.doc.negotiated">{{flight.doc.fare.publicAmount - flight.doc.fare.fees|number:'.2-2'}}€</span>
                            <span *ngIf="flight.doc.fare.services != 0"> | {{'SERVICES'|translate}} {{flight.doc.fare.services|number:'.2-2'}}€</span>
                            <span *ngIf="flight.doc.negotiated" matTooltip="{{'DISCOUNT_FARE'|translate}}" class="ml-1 material-icons">
                              star_border
                            </span>
                          </span>
                        </div>
                      </div>
                      <hr *ngIf="(flight.doc.itineraries.length > 1 && !l) || dossier.hotels.length > 0"/>
                    </div>

                  </div>
                </div>

                <div class="mb-2" *ngIf="dossier.hotels.length > 0">
                  <div class="cicledElement" *ngFor="let hotel of dossier.hotels; let l = last">
                    <div class="row">
                      <div class="col-1 align-self-center">
                        <span class="dossierCarrier"><img class="" src="/assets/img/hotel-icon.png"></span>
                      </div>
                      <div class="col-lg-4 align-self-center" *ngIf="hotel.doc">
                        <span class="material-icons">login</span> {{hotel.doc.hotel.checkIn|date:'dd/MM/yyyy'}}<br/>
                        <span class="material-icons">logout</span> {{hotel.doc.hotel.checkOut|date:'dd/MM/yyyy'}}
                      </div>
                      <div class="col-lg-4 align-self-center smallName" *ngIf="hotel.doc">
                        {{hotel.doc.hotel.name}} <span class="smalltext">({{hotel.doc.hotel.city}})</span>
                      </div>
                      <div class="col-lg-3 align-self-center" *ngIf="hotel.doc">
                        {{hotel.doc.room.roomRates[0].amount|number:'.2-2'}}€
                      </div>
                    </div>
                    <hr *ngIf="dossier.hotels.length > 1 && !l"/>

                  </div>
                </div>


                <div class="mb-2" *ngIf="dossier.cars.length > 0">

                  <div class="cicledElement" *ngFor="let car of dossier.cars; let l = last">
                    <div class="row">
                      <div class="col-1 align-self-center">
                        <span class="dossierCarrier"><img class="" src="/assets/img/cars/Car-Icon.png"></span>
                      </div>
                      <div class="col-lg-4 align-self-center" *ngIf="car.doc">
                        <span class="material-icons">login</span> {{car.doc.car.pickUpInfo.date | date:'dd/MM/yyyy'}}<br/>
                        {{car.doc.car.pickUpInfo.location}}
                      </div>
                      <div class="col-lg-4 align-self-center smallName" *ngIf="car.doc">
                        <span class="material-icons">logout</span> {{car.doc.car.dropOffInfo.date | date:'dd/MM/yyyy'}}<br/>
                        {{car.doc.car.dropOffInfo.location}}
                      </div>
                      <div class="col-lg-3 align-self-center" *ngIf="car.doc">
                        {{car.doc.car.model}}<br/>
                        {{returnCarAmount(car.doc.car.rates)}} €
                      </div>
                    </div>
                    <hr *ngIf="dossier.cars.length > 1 && !l"/>
                  </div>
                </div>

              </div>

            </div>
          </div>


        </div>
        <div class="col-3 align-self-center text-center">

          <div class="serviceIcons mb-2">
            <div *ngIf="dossier.flights.length > 0">
              <span class="material-icons">
               flight
              </span>
            </div>
            <div *ngIf="dossier.hotels.length > 0">
              <span class="material-icons">
               hotel
              </span>
            </div>
            <div *ngIf="dossier.trains && dossier.trains.length > 0">
              <span class="material-icons">
               train
              </span>
            </div>
            <div *ngIf="dossier.cars && dossier.cars.length > 0">
             <span class="material-icons">
               directions_car
              </span>
            </div>
          </div>

          <div class="buttonrow" *ngIf="!page">
            <button mat-stroked-button (click)="navigateToDossier(dossier._id)" color="primary">{{'OPEN_DOSSIER'|translate}}</button>
            <button mat-stroked-button (click)="deleteDossierToggle(dossier._id)">{{'DELETE_DOSSIER'|translate}}</button>
          </div>
          <div class="buttonrow" *ngIf="page && page=='flight'">
            <button mat-stroked-button color="primary" (click)="addFlights(dossier._id)">{{'SELECT_DOSSIER'|translate}}</button>
          </div>
          <div class="buttonrow" *ngIf="page && page=='hotel'">
            <button mat-stroked-button color="primary" (click)="addHotelss(dossier._id)">{{'SELECT_DOSSIER'|translate}}</button>
          </div>
          <div class="buttonrow" *ngIf="page && page=='car'">
            <button mat-stroked-button color="primary" (click)="addCars(dossier._id)">{{'SELECT_DOSSIER'|translate}}</button>
          </div>
          <div class="buttonrow" *ngIf="page && page=='train'">
            <button mat-stroked-button color="primary" (click)="addTrains(dossier._id)">{{'SELECT_DOSSIER'|translate}}</button>
          </div>
        </div>

        <div class="col-12 pnrlistuser">
          <hr/>
          <div>
            <a routerLink="/users/list/{{dossier.user._id}}">{{dossier.user.surname}} {{dossier.user.name}}</a>
            <mat-icon>arrow_right_alt</mat-icon>
            <a routerLink="/companies/list/{{dossier.company._id}}">{{dossier.company.name}}</a>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>

  <div class="padding20 text-center noresult" *ngIf="!loading && dossierList && dossierList.list.length==0">
    <h3>{{'NO_RESULTS'|translate}}.</h3>
    <p *ngIf="text">{{'NO_RESULTS_TEXT'|translate}} <span class="searchtext">{{text}}</span>.</p>
    <p *ngIf="text">{{'NO_RESULTS_TRY_AGAIN'|translate}}.</p>
  </div>

  <div class="card" *ngIf="!loading">
    <div class="paginationr pr-3 justify-content-end">
      <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
      <span>
          {{'PAGE'|translate}}
          <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
            <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
              <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p + 1}}</mat-option>
            </mat-select>
          </mat-form-field>
          {{'OF'|translate}} {{maxpages}}
        </span>

      <button mat-icon-button class="iconButtons" (click)="prev()" [disabled]="currentpage==0">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-icon-button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>

</div>

<div class="modal fade" id="addDosier" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="addDosier" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          {{'CREATE_DOSSIER'|translate}}
        </div>
        <button type="button" class="close" (click)="addDossierToggle()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <mat-form-field>
          <mat-label>{{'DOSSIER_NAME'|translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="newDossier">
          <button *ngIf="newDossier" matSuffix mat-icon-button aria-label="Clear" (click)="newDossier=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <div class="text-right">
          <button type="button" mat-button (click)="addDossierToggle()">{{'CLOSE'|translate}}</button>
          <button type="button" mat-button color="primary" [disabled]="!newDossier" (click)="createDossier()">{{'CREATE_DOSSIER'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteDosier" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="deleteDosier" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          {{'DELETE_DOSSIER'|translate}}
        </div>
        <button type="button" class="close" (click)="deleteDossierToggle(null)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <p>{{'DELETE_DOSSIER_QUESTION'|translate}}</p>

        <div class="text-right">
          <button type="button" mat-button (click)="deleteDossierToggle(null)">{{'CLOSE'|translate}}</button>
          <button type="button" mat-button color="primary" (click)="deleteDossier(dossierToDelete)">{{'DELETE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
