import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  constructor() { }

  returnPrice(value) {
    const usersettings = JSON.parse(localStorage.getItem('fl_settings'));
    // usersettings
    const originalprice = parseFloat(value);
    const newvalue = JSON.parse(JSON.stringify(originalprice));
    let price: any;
    if (usersettings.useMarkup) {
      if (usersettings.markup.type === 'sum') {
        if (usersettings.markup.value) {
          price = Math.round((newvalue + usersettings.markup.value) * 100) / 100;
          return price;
        } else {
          return originalprice;
        }
      } else {
        if (usersettings.markup.value) {
          price = Math.round((newvalue + (newvalue * usersettings.markup.value / 100)) * 100) / 100;
          return price;
        } else {
          return originalprice;
        }

      }
    } else {
      return originalprice;
    }
  }

  returnPriceHotel(value) {
    const usersettings = JSON.parse(localStorage.getItem('fl_settings'));
    // usersettings
    const originalprice = parseFloat(value);
    const newvalue = JSON.parse(JSON.stringify(originalprice));
    let price: any;
    if (usersettings.useMarkupHotel) {
      if (usersettings.markupHotel.type === 'sum') {
        if (usersettings.markupHotel.value) {
          price = Math.round((newvalue + usersettings.markupHotel.value) * 100) / 100;
          return price;
        } else {
          return originalprice;
        }
      } else {
        if (usersettings.markupHotel.value) {
          price = Math.round((newvalue + (newvalue * usersettings.markupHotel.value / 100)) * 100) / 100;
          return price;
        } else {
          return originalprice;
        }

      }
    } else {
      return originalprice;
    }
  }

  isNegoHidden() {
    const usersettings = JSON.parse(localStorage.getItem('fl_settings'));
    return usersettings.hidenego;
  }
}
