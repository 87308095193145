import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UsersService} from '../../../services/profile/users.service';
import {TravelPolicyService} from '../../../services/travel-policy/travel-policy.service';
import {MatSelectionList} from '@angular/material/list';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-default-user',
  templateUrl: './default-user.component.html',
  styleUrls: ['./default-user.component.scss']
})
export class DefaultUserComponent implements OnInit {
  @ViewChild('users') selectionList: MatSelectionList;

  loading = false;
  /*
  defaultUser = new FormControl(null, Validators.required);

  userSelected = null;

  filteredUsers$ = of(null);

  valueChanges$ = this.defaultUser.valueChanges.pipe(
    startWith(''), distinctUntilChanged(),
    debounceTime(1500),
    tap((value: string) => {
      if (this.selectionList?.selectedOptions.selected[0]?.value) {
        this.selectionList.deselectAll();
      }
      console.log(value);
      this.userSelected = false;
      this.loading = true;
      this.filteredUsers$ = this.usersService.getUsersComplete(value.toLowerCase())
        .pipe(pluck('data'), tap(() => this.loading = false));
    }));
  */

  text: string;
  userList: any;
  userToSet: any;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private travelPolicyService: TravelPolicyService,
    private dialogRef: MatDialogRef<DefaultUserComponent>,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
  ) {
    this.userList = [];
  }


  ngOnInit(): void {
  }

  searchUser(value) {
    // delete this.userList;
    if (value.length >= 2 ) {
      this.getUsersList(value);
    }
  }

  getUsersList(value) {
    // delete this.userList;
    delete this.userToSet;
    this.usersService.getUsersComplete(value).subscribe((res) => {
      this.loading = false;
      this.userList = res;
      console.log('this.users => ', this.userList);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  clear() {
    this.text = '';
    delete this.userToSet;
    delete this.userList;
  }

  getUser(value) {
    const user = value;
    // delete this.userList;
    this.text = user.surname + ' ' + user.name + ' -> ' + user.company.name;
    this.userToSet = user;
  }

  selectFirst() {
    const user = this.userList[0];
    // delete this.userList;
    this.text = user.surname + ' ' + user.name + ' -> ' + user.company.name;
    this.userToSet = user;
  }

  saveDefaultUser(): void {
    this.travelPolicyService.saveDefaultUser(this.userToSet._id).subscribe(res => {
        this.dialogRef.close();
      },
      error => {
        this.snackBar.open(error.message, 'Close');
      }
    );
  }

}
