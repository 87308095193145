import {
  Component,
  OnInit,
} from '@angular/core';
import {
  Journey,
  TrainOffer,
  TrainReservation,
  TrainTravel,
} from '../../../classes/train.models';
import {
  filter,
  map,
} from 'rxjs/operators';
import {
  NavigationEnd,
  Router,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TrainUtils } from '../../train.utils';

@Component({
  selector: 'train-main',
  templateUrl: './train-main.component.html',
  styleUrls: ['./train-main.component.scss'],
})
export class TrainMainComponent implements OnInit{
  static CURRENT_SEARCH_KEY = 'CURRENT_SEARCH';
  static TRAIN_TRAVEL_KEY = 'TRAIN_TRAVEL';
  static CHECK_MONGO_ID = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i
  currentSearch : any;
  trainTravel : TrainTravel;
  protected routePath : string;

  constructor(
    public router : Router,
    public titleService : Title,
    public translate : TranslateService,
  ){
    this.currentSearch = JSON.parse(localStorage.getItem(TrainMainComponent.CURRENT_SEARCH_KEY)) || {};
    this.trainTravel = JSON.parse(localStorage.getItem(TrainMainComponent.TRAIN_TRAVEL_KEY)) || new TrainTravel();
    this.trainTravel.travellers = this.trainTravel.travellers?.filter(t => t._id && TrainMainComponent.CHECK_MONGO_ID.test(t._id)) || [];
    this.trainTravel.arrayOfAlerts = this.trainTravel.arrayOfAlerts || [];
    this.routePath = this.router.url;
  }

  ngOnInit() : void{
    this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.router),
        ).subscribe((event) => {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).map(t => this.translate.instant(t)).join(' | ');
        this.titleService.setTitle(title);
      },
    );
    this.onInit();
  }

  onInit() {}

  getTitle(state, parent){
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title){
      data.push(parent.snapshot.data.title);
    }

    if (state && parent){
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  /**
   * opens a popup detail on the offer
   */
  public openTrenitInfoPopup(offer) : void{
    const anchor = encodeURIComponent(offer).toUpperCase();
    const url = `http://www.trenitalia.com/it/html/trenitalia/Info_Tariffe_it_LeFrecce.html#${anchor}`;
    window.open(url, 'open_window', 'menubar=no, toolbar=no,scrollbars=yes, resizable=yes,width=640, height=480, left=0, top=0');
  }

  getOfferDisplayText(offer : TrainOffer){
    const price = (offer.price.amount) ? offer.price.amount + '€ | ' : '';
    const requiresExtraParams = (offer.requiresParams) ? ' (*)' : '';
    return price + offer.displayName + requiresExtraParams;
  }

  /**
   *
   * returns the train Logo for tenitalia according to the type of train available on the route
   */
  getTrenitLogo(reservation?: TrainReservation, journey?: Journey) : string{
    return TrainUtils.getTrenitLogo(reservation, journey);
  }

  protected updateCurrentSearch(){
    localStorage.setItem(TrainMainComponent.CURRENT_SEARCH_KEY, JSON.stringify(this.currentSearch));
  }

  protected updateTrainTravel(){
    localStorage.setItem(TrainMainComponent.TRAIN_TRAVEL_KEY, JSON.stringify(this.trainTravel));
  }

  protected resetStorage(){
    localStorage.removeItem('CURRENT_SEARCH');
    localStorage.removeItem('TRAIN_TRAVEL');
    this.currentSearch = {};
    this.trainTravel = new TrainTravel();
  }

  protected getSortedOffers(classe : any) : { name : string, offers : any[] }[]{
    const offersKeys = Object.keys(classe).filter((k : any) => !['name', 'description', 'type', 'level', 'displayName'].includes(k));
    const corporate = [];
    const routeDeal = [];
    const discount = [];
    const carnet = [];
    const base = [];

    offersKeys.forEach((k : string) => {
      const offer = {
        name: k,
        ...classe[k],
      };
      switch(true){
        case offer.name.toUpperCase().includes('CORPORATE'):
          corporate.push(offer);
          break;
        case offer.name.toUpperCase().includes('ROUTE'):
          routeDeal.push(offer);
          break;
        case offer.name.toUpperCase().includes('SCONTO'):
          discount.push(offer);
          break;
        case offer.family === 'CARNET_PRENOTAZIONE':
          carnet.push(offer);
          break;
        default:
          base.push(offer);
          break;
      }
    });
    const sortedOffers = [];
    if (base.length > 0){
      sortedOffers.push({ name: 'Base', offers: base.sort(this.sortPrice) });
    }
    if (corporate.length > 0){
      sortedOffers.push({ name: 'Tariffe Corporate', offers: corporate.sort(this.sortPrice) });
    }
    if (routeDeal.length > 0){
      sortedOffers.push({ name: 'Route Deal', offers: routeDeal.sort(this.sortPrice) });
    }
    if (discount.length > 0){
      sortedOffers.push({ name: 'Sconto', offers: discount.sort(this.sortPrice) });
    }
    if (carnet.length > 0){
      sortedOffers.push({ name: 'Carnet', offers: carnet.sort(this.sortPrice) });
    }
    return sortedOffers;
  }

  protected getSortedServices(paxServices : any) : { name : string, services : any[] }[]{
    const normal = paxServices.services.slice().filter((s : any) => {
      return s.name.toUpperCase().indexOf('CARNET') === - 1 && s.type !== 'CARNET'
    }).sort(this.sortAlphabetical);
    const carnet = paxServices.services.slice().filter((s : any) => {
      return s.name.toUpperCase().indexOf('CARNET') !== - 1 || s.type === 'CARNET'
    }).sort(this.sortAlphabetical);
    const sortedServices = [];
    if (normal.length > 0){
      sortedServices.push({
        name: 'Classe', services: normal.map((s : any) => {
          return {
            ...s,
            paxId: paxServices.paxId,
            offers: this.getSortedOffers(s),
          };
        }),
      });
    }
    if (carnet.length > 0){
      sortedServices.push({
        name: 'Carnet', services: carnet.map((s : any) => {
          return {
            ...s,
            paxId: paxServices.paxId,
            offers: this.getSortedOffers(s),
          };
        }),
      });
    }
    return sortedServices;
  }

  protected sortAlphabetical(a : any, b : any){
    const typeA = a.name.toUpperCase();
    const typeB = b.name.toUpperCase();
    if (typeA < typeB){
      return - 1;
    }
    if (typeA > typeB){
      return 1;
    }
    return 0;
  }

  protected sortPrice(a : any, b : any){
    const typeA = parseFloat(a.price.amount) || 0;
    const typeB = parseFloat(b.price.amount) || 0;
    if (typeA < typeB){
      return 1;
    }
    if (typeA > typeB){
      return - 1;
    }
    return 0;
  }

  // tslint:disable-next-line:max-line-length
  protected getFilteredOffers(paxIndex : number, offeredServices : any[], solutionNodeRef : string, selectedClass : any, selectedOfferId : any){
    return offeredServices
      .map((offerSelected : any) => {
        const copy = Object.assign({}, offerSelected);
        if (offerSelected.solutionNodeRef === solutionNodeRef){
          if (selectedClass[paxIndex][selectedOfferId[paxIndex]].id === offerSelected.xmlId){
            copy.status = 'SELECTED';
          } else if (selectedClass[paxIndex].paxId === offerSelected.bookingInfo.traveller){
            copy.status = 'OFFERED';
          }
        }
        return copy;
      });
  }

  isAftersale(): boolean{
    return this.routePath.includes('reservation');
  }
}
