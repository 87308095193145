import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as $ from 'jquery';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AlternativeTravelSolution } from '../../../../classes/train.models';
import { TrainService } from '../../../../train.service';
import { TrainUtils } from '../../../train.utils';

@Component({
  selector: 'train-change-date',
  templateUrl: './train-change-date.component.html',
  styleUrls: ['./train-change-date.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TrainChangeDateComponent implements OnInit{
  @Input() isDisabled : boolean;
  @Input() pnr : string;
  @Input() selectedTravelSolution = 0;
  @Output() reloadEvent = new EventEmitter<any>();

  changeDateReservation$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  departureStartDate$ = new BehaviorSubject(new Date());
  public searchForm : UntypedFormGroup;

  checkingDates : boolean;
  changeAttempt : boolean;
  newDates : AlternativeTravelSolution[] = [];
  errorMessage : string;
  newSelectedDate : AlternativeTravelSolution;
  columnsToDisplay = ['stations', 'departure', 'arrival', 'train'];
  expandedElement : any;
  private catalog : any;
  private travel : any;


  /**
   *  Constructor
   */
  constructor(
    private fb : UntypedFormBuilder,
    private trainService : TrainService,
  ){ }

  /**
   *  OnInit function is empty for now
   */
  ngOnInit(){
    this.searchForm = this.fb.group({
      departure_date: [undefined, [Validators.required]],
    });
  }

  toggleModal(){
    $('#changeDateModal').modal('toggle');
  }

  checkDates(){
    this.checkingDates = true;
    const newDate = this.searchForm.controls.departure_date.value;
    this.newSelectedDate = undefined;
    this.trainService.checkNewDates(this.pnr, newDate.toISOString()).subscribe(data => {
      this.catalog = data.catalog;
      this.checkingDates = false;
      this.newDates = data.searchSimilarRecords.map(sr => {
        return {
          ...sr,
          travelSolution: {
            ...sr.travelSolution,
            offeredServices: TrainUtils.toArray(sr.travelSolution.offeredServices),
            parsedSolutionNodes: this.getSolutionNodes(sr.travelSolution.solutionNodes),
          },
        };
      }).sort();
      this.travel = data.travel;
    }, error => {
      this.checkingDates = false;
      console.error('Couldnt check dates =>', error);
    });
  }

  confirmChange(){
    this.changeDateReservation$.next(true);
    const confirmRequest = { selectedSimilarRecord: this.newSelectedDate, travel: this.travel };
    this.trainService.confirmChangeDate(this.pnr, confirmRequest).subscribe(data => {
      this.changeDateReservation$.next(false);
      this.changeAttempt = true;
      this.reload();
    }, error => {
      this.changeDateReservation$.next(false);
      this.errorMessage = error;
      this.reload();
    });
  }

  reload(){
    this.reloadEvent.emit(true);
  }

  isSelected(element){
    return this.newSelectedDate === element ? 'row-selected' : '';
  }

  getStationFromCatalog(id){
    return this.catalog.stations[id];
  }

  getSolutionNodes(solutionNodes) {
    const solutionArray = TrainUtils.toArray(solutionNodes);
    const result = [];
    for (const solutionNode of solutionArray){
      if (solutionNode.subSegments){
        result.push(...this.getSolutionNodes(solutionNode.subSegments));
      } else if (solutionNode.type !== 'SOLUTION_SHOP' && solutionNode.type !== 'SOLUTION_LOCATION'){
        result.push(solutionNode);
      }
    }
    return result;
  }

  getTrainName(offeredTransportMeanDeparture){
    const name = this.catalog.trains[offeredTransportMeanDeparture?.classificationId];
    if (name) {
      return name + ' ' + offeredTransportMeanDeparture?.name;
    } else{
      return '-';
    }
  }

  expandRow(row){
    if (row.travelSolution.parsedSolutionNodes?.length > 1){
      this.expandedElement = this.expandedElement === row ? null : row;
    }
    this.newSelectedDate = this.newSelectedDate === row ? null : row;
  }
}

export class TravelSolutionForDate{
  date : Date;
  xmlId : string;
}
