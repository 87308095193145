export class Destination{
  zoneId : string;
  display : string;
  coordinates : any[];
  radius : number;
}

export class HotelGuest{
  constructor(code? : number, quantity? : number, age? : number) {
    age ? this.age = age : null;
    this.code = code;
    this.quantity = quantity;
  }
  age : any;
  code: any;
  quantity: any;
}

export class Occupancy{
  guests : HotelGuest[];
  roomCategory : string[];
  bedTypes : string[];
}

export class HotelOptions{
  stars : number[];
}

export class HotelForm{
  destination : Destination;
  iata: string;
  residency : string;
  checkIn : Date;
  checkOut : Date;
  occupancy : Occupancy[];
  options : HotelOptions;
  sort : string;
  cache : string;
  moreIndicator : string;
  nameSearch : boolean;
  hotels? : string[];
  strict : boolean;
  chain : string;
  ratePlans : string[];
}

export interface HotelsPayload{
  session? : Session;
  moreIndicator? : string;
  hotels? : Hotel[];
  rooms? : Room[];
  services? : Service[];
  currencyConversions? : any[];
}


export interface Session{
  SessionId? : string;
  SequenceNumber? : string;
  SecurityToken? : string;
}

export interface Hotel{
  rooms? : number[];
  brandCode? : string;
  hotelCode? : string;
  cityCode? : string;
  name? : string;
  rating? : number;
  position? : any;
  address? : Address;
  contacts? : Contact[];
  type? : string;
  chain? : string;
}

export interface Address{
  line? : string;
  city? : string;
  zip? : string;
}

export interface Contact{
  type? : string;
  number? : string;
}


export interface Room{
  types? : Type[];
  ratePlans? : RatePlan[];
  roomRates? : RoomRate[];
  startDate? : Date | string;
  endDate? : Date | string;
  amount? : number;
  referenceType? : string;
  referenceID? : string;
  services? : number[];
  currency? : string;
}

export interface Type{
  type? : string;
  typeCode? : string;
}


export interface RoomRate{
  guests? : Guest[];
  amount? : number;
  name? : string;
  id? : string;
  text? : string;
  bookingCode? : string;
}

export interface RatePlan{
  code? : string;
  status? : string;
  guarantee? : Guarantee;
  penalty? : Penalty[];
  commission? : string;
  breakfast? : boolean;
  mealPlan? : string;
}

export interface Guarantee{
  type? : string;
  accepted? : Accepted[];
}

export interface Penalty{
  type? : string;
  amount? : number;
}

export interface Accepted{
  cardCode? : string;
  mandatoryFields? : any[];
}

export interface Guest{
  ageCode? : string;
  count? : number;
}

export interface Service{
  RPH? : number;
  included? : boolean;
  type? : string;
  inventoryCode? : string;
  pricingType? : string;
}

