import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FlightService} from '../../../../../../services/flight/flight.service';

@Component({
  selector: 'app-checklowcostpayment',
  templateUrl: './checklowcostpayment.component.html',
  styleUrls: ['./checklowcostpayment.component.scss']
})
export class ChecklowcostpaymentComponent implements OnInit {
  bookingId: string;
  reservationId: string;
  checking: boolean;
  payMentStatus: string;
  redirect: boolean;
  constructor(
    private route: ActivatedRoute,
    private flightService: FlightService,
    private router: Router,
  ) {
    this.checking = true;
  }

  ngOnInit(): void {
    this.bookingId = this.route.snapshot.paramMap.get('id');
    this.reservationId = this.route.snapshot.paramMap.get('pnr');
    console.log('bookingId => ',  this.bookingId);
    console.log('reservationId => ',  this.reservationId);
    // data ref
    if (this.bookingId) {
      this.verifyLowcost();
    }
  }

  verifyLowcost() {
    const body = {
      ref: this.bookingId,
    };
    this.flightService.checkBooking(body, this.reservationId).subscribe((res: any) => {
      // tslint:disable-next-line:max-line-length
      console.log('res => ', res);
      this.checking = false;
      this.goToPnr();
      /*
      if (res.Status === 'Succeeded') {
        this.payMentStatus = 'PAYMENT_ACCEPTED';
        this.redirect = true;
        this.goToPnr();
      } */
    }, error => {
      this.checking = false;
      this.goToPnr();
      console.error('res => ', error);
    });
  }

  goToPnr(): void {

    setTimeout(() => {
      this.redirect = false;
      this.router.navigate(['/flight/reservations/' + this.reservationId]);
    }, 900);
  }

}
