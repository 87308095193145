<div mat-dialog-title>
  {{'FEE_DELETE'|translate}}
</div>
<mat-dialog-content class="mat-typography">

  <div class="">
    {{'CONFIRM_DELETE'|translate}} {{data.fee.name}} ?
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'ABORT'|translate}}</button>
  <button mat-button (click)="confirmDelete()">{{'DELETE'|translate}}</button>
</mat-dialog-actions>
