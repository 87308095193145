<h1 mat-dialog-title>{{'ADD_USER'|translate}}</h1>
<div mat-dialog-content>
  <div *ngIf="!companyDetails">
    <mat-progress-bar mode="query"></mat-progress-bar>
  </div>
  <div *ngIf="companyDetails">
    <!--
    <div>
    <pre>
      <code>
        companyId: {{this.newuser.company|json}}
      </code>
    </pre>
    </div>
    -->
    <div *ngIf="companyDetails" class="mb-3">
      {{'COMPANY'|translate}}: {{companyDetails.name}}
    </div>
    <hr/>
    <div class="row">
      <div class="col-lg-6 mb-2">
        <mat-form-field class="full-width mb-1">
          <mat-label>{{'SURNAME'|translate}}</mat-label>
          <input matInput [(ngModel)]="newuser.surname" required>
        </mat-form-field>
      </div>
      <div class="col-lg-6 mb-2">
        <mat-form-field class="full-width mb-1">
          <mat-label>{{'NAME'|translate}}</mat-label>
          <input matInput [(ngModel)]="newuser.name" required>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-2">
        <mat-form-field class="full-width mb-1">
          <mat-label>{{'PHONE'|translate}}</mat-label>
          <input matInput [(ngModel)]="newuser.phoneNumber" required>
        </mat-form-field>
      </div>
      <div class="col-lg-6 mb-2">
        <mat-form-field class="full-width mb-1">
          <mat-label>{{'BIRTHDAY'|translate}}</mat-label>
          <input (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="newuser.birthday" [max]="today">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 mb-2">
        <div>
          <label id="example-radio-group-label">{{'SEX'|translate}}</label>
          <mat-radio-group [(ngModel)]="newuser.sex"
                           aria-labelledby="example-radio-group-label"
                           class="d-block">
            <mat-radio-button class="d-inline-block mr-2" *ngFor="let value of sex" [value]="value">
              <span class="d-inline-block mr-2" *ngIf="value == 'M'">{{'MALE'|translate}}</span>
              <span class="d-inline-block" *ngIf="value == 'F'">{{'FEMALE'|translate}}</span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-lg-6 mb-2">
        <mat-form-field class="full-width mb-1">
          <mat-label>{{'EMAIL'|translate}}</mat-label>
          <input matInput [(ngModel)]="newuser.email" required>
        </mat-form-field>
      </div>
      <div class="col-lg-6 mb-2">
        <div>
          <mat-form-field class="full-width mb-1">
            <mat-label>{{'ROLE'|translate}}</mat-label>
            <mat-select [(ngModel)]="newuser.role" required>
              <mat-option *ngFor="let role of companyLevels" [value]="role.level">{{role.trad|translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-lg-6 mb-2">
        <mat-form-field class="full-width mb-1">
          <mat-label>{{'PASSWORD'|translate}}</mat-label>
          <input required [type]="hide ? 'password' : 'text'" matInput [(ngModel)]="newuser.password" (ngModelChange)="measureStrength(newuser.password)">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

      </div>
      <div class="col-lg-6 mb-2">
        <mat-form-field class="full-width mb-1">
          <mat-label>{{'PASSWORD_CONFIRM'|translate}}</mat-label>
          <input required [type]="hideConfirm ? 'password' : 'text'" matInput [(ngModel)]="passwordconfirm">
          <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirm">
            <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="col-12">
        <p class="mt-0 text-danger small" *ngIf="passwordconfirm !== newuser.password">
          Le password non coincidono
        </p>

        <div class="text-left mb-3" style="font-size:11px;">
          <div>
            <span style="font-size:11px;vertical-align: middle">La password deve contenere almeno 4 lettere, due numeri ed un carattere speciale</span>
          </div>
          <span style="vertical-align: middle;font-size:14px;color:red;" class="material-icons" *ngIf="barValue <= 66">
                close
            </span>
          <span style="vertical-align: middle;font-size:14px;color:green;" class="material-icons" *ngIf="barValue > 66">
                done
            </span>
          <span style="vertical-align: middle">Forza della password</span>
          <div class="bar force{{barValue}}">
            <div class="barStep1"></div>
            <div class="barStep2"></div>
            <div class="barStep3"></div>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <div>
      <div class="row">
        <div class="col-6">
          <p>
            <mat-slide-toggle [(ngModel)]="isNotItalyUser" (ngModelChange)="changeCountry($event)">Stato estero</mat-slide-toggle>
          </p>
        </div>
        <div class="col-6">
          <div class="text-right pointer" *ngIf="newuser.company.location" (click)="setAddress()">
            {{'USE_COMPANY_ADDRESS'|translate}}
            <span class="material-icons" *ngIf="!sameLocation">
              check_box_outline_blank
            </span>
            <span class="material-icons" *ngIf="sameLocation">
              check_box
            </span>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-lg-6 mb-2"  *ngIf="!isNotItalyUser" >
          <mat-form-field class="full-width mb-1">
            <mat-label>{{'COUNTRY'|translate}}</mat-label>
            <mat-select required [(ngModel)]="newuser.location.country">
              <mat-option *ngFor="let country of countries" [value]="country.value">
                {{country.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-lg-6 mb-2"  *ngIf="!isNotItalyUser">
          <mat-form-field class="full-width mb-1">
            <mat-label>{{'REGION'|translate}}</mat-label>
            <mat-select (selectionChange)="setRegion()"  [(ngModel)]="newuser.location.region" required>
              <mat-option *ngFor="let region of regions" [value]="region.value">
                {{region.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-lg-6 mb-2"  *ngIf="isNotItalyUser" >
          <mat-form-field class="full-width mb-1">
            <mat-label>{{'FOREIGN_COUNTRY'|translate}}</mat-label>
            <input matInput type="text" [(ngModel)]="newuser.location.country" required>
          </mat-form-field>
        </div>

        <div class="col-lg-6 mb-2"  *ngIf="isNotItalyUser">
          <mat-form-field class="full-width mb-1">
            <mat-label>{{'REGION'|translate}}</mat-label>
            <input matInput type="text" [(ngModel)]="newuser.location.region" required>
          </mat-form-field>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-6 mb-2"  *ngIf="!isNotItalyUser">
          <mat-form-field class="full-width mb-1" *ngIf="newuser.location.region">
            <mat-label>{{'PROVINCE'|translate}}</mat-label>
            <mat-select [(ngModel)]="newuser.location.province" required>
              <mat-option *ngFor="let province of provinces" [value]="province.value">
                {{province.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-lg-6 mb-2"  *ngIf="isNotItalyUser">
          <mat-form-field class="full-width mb-1" *ngIf="newuser.location.region">
            <mat-label>{{'PROVINCE'|translate}}</mat-label>
            <input matInput [(ngModel)]="newuser.location.province" type="text" required>
          </mat-form-field>
        </div>

        <div class="col-lg-6 mb-2">
          <mat-form-field class="full-width mb-1" *ngIf="newuser.location.province">
            <mat-label>{{'CITY'|translate}}</mat-label>
            <input matInput placeholder="Città" [(ngModel)]="newuser.location.city" required>
          </mat-form-field>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-6 mb-2">
          <mat-form-field class="full-width mb-1" *ngIf="newuser.location.city">
            <mat-label>{{'POSTAL_CODE'|translate}}</mat-label>
            <input matInput placeholder="Codice postale" [(ngModel)]="newuser.location.postalCode" required>
          </mat-form-field>
        </div>
        <div class="col-lg-6 mb-2">
          <mat-form-field class="full-width mb-1" *ngIf="newuser.location.postalCode">
            <mat-label>{{'ADDRESS'|translate}}</mat-label>
            <input matInput placeholder="Indirizzo" [(ngModel)]="newuser.location.address" required>
          </mat-form-field>
        </div>
      </div>

    </div>
  </div>

</div>
<hr/>
<div mat-dialog-actions align="end">
  <button mat-button [disabled]="!validForm()" (click)="saveUser()">{{'SAVE'|translate}}</button>
  <button mat-button (click)="onNoClick()">{{'CLOSE'|translate}}</button>
</div>

