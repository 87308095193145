import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {PriceService} from '../../../../../../../services/price/price.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-currentselection',
  templateUrl: './currentselection.component.html',
  styleUrls: ['./currentselection.component.scss']
})
export class CurrentselectionComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<CurrentselectionComponent>,
    public priceservice: PriceService,
  ) { }

  ngOnInit(): void {
  }

  returnZeroTime(value) {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }

  calculateFlightTime(departure, arrival) {
    departure = new Date(departure).getTime();
    arrival = new Date(arrival).getTime();
    const flightTime = arrival - departure;
    return this.returnTime(flightTime);
  }

  /**
   *  Return flight duration in dd-hh-mm
   */
  returnTime(t) {
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(t / cd);
    let h = Math.floor( (t - d * cd) / ch);
    let m = Math.round( (t - d * cd - h * ch) / 60000);
    const pad = ((n) => n < 10 ? '0' + n : n);
    if ( m === 60 ) {
      h++;
      m = 0;
    }
    if ( h === 24 ) {
      d++;
      h = 0;
    }
    if (d > 0) {
      return [d + 'g ' + pad(h) + 'h', pad(m) + 'm'].join(':');
    } else {
      return [pad(h) + 'h', pad(m) + 'm'].join(':');
    }

  }

  otherday(value, other) {
    return value !== other;
  }

  opeSect(it) {
    $('#collapse' + it).collapse('toggle');
    $('#itinerary' + it).collapse('toggle');
  }
}
