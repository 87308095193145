export class ListNt {
  _id: string;
  block: boolean;
  type: string;
  ref: string;
  payload: string;
  category: string;
  status: string;
  body: string;
  subject: string;
  reason: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  read: boolean;
}

export class Notifications {
  list: ListNt[] = [new ListNt()];
  count: number;
  unread: number;
}

