import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-product-info-dialog',
  templateUrl: './product-info-dialog.component.html',
  styleUrls: [ './product-info-dialog.component.scss' ]
})
export class ProductInfoDialogComponent implements OnInit {

  search;
  productDetails;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit(): void {
    this.search = this.data.search;
    this.productDetails = this.data.search.productDetails;
  }

}
