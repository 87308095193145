import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as $ from 'jquery';
import {AppComponent} from '../../../../app.component';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {ActivatedRoute, Router} from '@angular/router';
import {FlightService} from '../../../../services/flight/flight.service';
import {PriceService} from '../../../../services/price/price.service';
import {IatacompleteService} from '../../../../services/iatacomplete/iatacomplete.service';
import {DossierService} from '../../../../services/dossier/dossier.service';
@Component({
  selector: 'app-flightslist',
  templateUrl: './flightslist.component.html',
  styleUrls: ['./flightslist.component.scss']
})
export class FlightslistComponent implements OnInit {
  currentTab: number;
  reservationlist: any;
  @Input() page: string;
  @Input() idcompany: string;
  @Input() dossierId: string;
  // tslint:disable-next-line:no-output-native no-output-rename
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();
  loading: boolean;
  currentpage: number;
  maxpages: number;
  // setQuery
  query: any;
  limit: number;
  skip: number;
  sort: string;
  order: boolean;
  progress: boolean;
  text: string;
  filterCompanies = [];
  pagenumbers = [];
  constructor(
    public appComponent: AppComponent,
    public mainComponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    private flightService: FlightService,
    public priceservice: PriceService,
    // public dateservice: DateService,
    public iataComplete: IatacompleteService,
    private dossierService: DossierService,
  ) {
    this.currentTab = 1;
    this.loading = true;
    this.progress = false;
  }

  ngOnInit(): void {
    this.limit = 10;
    this.sort = 'createdAt';
    this.order = true;
    this.currentpage = 0;
    this.skip = (this.currentpage) * this.limit;

    this.query = {
      options: {
        tab: 'active',
        companies: [],
        users: [],
        carrier: '',
      },
      sort: this.sort,
      limit: this.limit,
      skip: this.skip,
      order: this.order ? 'asc' : 'desc',
      text: '',
    };
    this.navigate();
  }
  changeTab(value) {
    if (value === 'init') {
      this.query.sort = 'createdAt';
      this.sort = 'createdAt';
    }
    if (value === 'linked' || value === 'withoutTL') {
      this.query.sort = 'generatedAt';
      this.sort = 'generatedAt';
    }
    if (value === 'active') {
      this.query.sort = 'createdAt';
      this.sort = 'createdAt';
    }
    if (value === 'linked' || value === 'expired' || value === 'control') {
      this.query.sort = 'generatedAt';
      this.sort = 'generatedAt';
    }
    if (value === 'departures') {
      this.query.sort = 'generatedAt';
      this.sort = 'generatedAt';
    }
    if (value === 'deadline') {
      this.query.sort = 'timeLimit';
      this.sort = 'timeLimit';
    }
    if (value === 'issued' || value === 'flown') {
      this.query.sort = 'issuedOn';
      this.sort = 'issuedOn';
    }
    if (value === 'cancelled') {
      this.query.sort = 'cancelledAt';
      this.sort = 'cancelledAt';
    }
    if (value === 'deadline') {
      this.order = false;
      this.query.order = 'desc';
    }
    if (this.query.options.tab !== value) {
      this.query.options.tab = value;
      this.currentpage = 0;
      this.query.skip = 0;
      this.navigate();
    }

  }

  openFilters() {
    $('.pnrfilters').collapse('toggle');
  }

  setFilterText() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }
  clearFilterText() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.query.text = '';
    this.navigate();
  }

  navigate() {
    this.getReservationList();
  }

  next() {
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev() {
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value) {
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  getReservationList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.flightService.getReservationsList(this.query).subscribe((res) => {
      this.loading = false;
      this.reservationlist = res;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.reservationlist => ', this.reservationlist);
      this.maxpages = Math.ceil((this.reservationlist.count / this.limit));
      console.log('this.maxpages ', this.maxpages);
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;
      this.iataComplete.createToCompletePrnList(this.reservationlist);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  returnTabNum(string) {
    if (this.reservationlist) { return this.reservationlist.tabs[string]; }
    if (!this.reservationlist) { return 0; }
  }

  addFlights(id) {
    const body = {
      flights: [id]
    };
    this.dossierService.addFlightsToDossier(this.dossierId, body).subscribe((res) => {
      console.log('res => ', res);
      this.close.emit();
    }, error => {
      console.error('res => ', error);
    });
  }

}
