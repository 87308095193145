import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FeesModService} from '../fees.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-fee-delete',
  templateUrl: './fee-delete.component.html',
  styleUrls: ['./fee-delete.component.scss']
})
export class FeeDeleteComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FeeDeleteComponent>,
    private feesService: FeesModService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

  }

  confirmDelete(){
    this.feesService.deleteFee(this.data.fee._id).subscribe((res) => {
      console.log('this.currentFee =>', res);
      this.dialogRef.close(true);
    }, err => {
      console.log('error => ', err);
    });

  }

}
