import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { HotelService } from '../../../hotel.service';
import { GalleryItem, ImageItem } from 'ng-gallery';
import * as $ from 'jquery';
declare var google : any;

@Component({
  selector: 'app-singlehotelinfo',
  templateUrl: './singlehotelinfo.component.html',
  styleUrls: ['./singlehotelinfo.component.scss'],
})
export class SinglehotelinfoComponent implements OnInit{
  @Input() search : any;
  @Input() hotel : any;
  @Input() currentGeo : any;
  @Input() hotelRes : any;
  @Input() allImages : any;
  @Input() allHotels : any;
  loadingInfos : boolean;
  @Input() distance : any;
  @Input() images : any;
  currentThumb = 0;
  activeImage = 0;
  /**
   *  local variable for map
   */
  map : any;

  /**
   * Constructor
   */
  constructor(
    private hotelService : HotelService,
  ){
    this.allImages = [];
    this.allHotels = [];
  }

  ngOnInit() : void{
    console.log('hotel =>', this.hotel);
    this.hotel.address = this.hotelRes.hotels[0].address;
    setTimeout(() => {
      this.initMap();
    }, 200);
    // this.allImages = this.getAllImages();
    // this.currentGeo.hotels.forEach(i => this.getHotelNewInfos(i.hotelCode));
  }

  getHotelNewInfos(code){
    const body = {
      hotels: [code],
    };
    /*const body = {
     hotels: this.hotelToGetInfos.splice(1, 1),
     };*/
    this.loadingInfos = true;
    this.hotelService.askHotelInfos(body).subscribe(res => {
      this.loadingInfos = false;
      // console.log('hotelsInfos =>', res);
      this.allHotels.push(res);
      if (this.allHotels.length === this.currentGeo.hotels.length){
        this.allImages = this.getAllImages();
      }
      console.log('allHotels =>', this.allHotels);
    }, error => {
      this.loadingInfos = false;
      console.error('error hotelsInfos =>', error);
    });
  }

  checkValue(a){
    if (a < 1){
      return Math.round(a * 1000) + 'm';
    } else{
      return Math.round(a * 10) / 10 + 'km';
    }
  }

  initMap() : void{
    this.map = new google.maps.Map(document.getElementById('hotelMap') as HTMLElement, {
      center: { lat: this.hotel.info.position.latitude, lng: this.hotel.info.position.longitude },
      zoom: 14,
    });
    const image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
    let myLatlng;
    if (this.search.destination){
      myLatlng = new google.maps.LatLng(this.search.destination.coordinates[1], this.search.destination.coordinates[0]);
    } else{
      myLatlng = new google.maps.LatLng(41.90313516565217, 12.493317924529677);
    }
    const marker = new google.maps.Marker({
      position: myLatlng,
      title: 'Punto di interesse',
      icon: image,
    });
    const infoWindow = new google.maps.InfoWindow({
      content: '<p style="font-size:12px;">' + marker.title + '</p>',
    });
    marker.addListener('mouseover', () => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    marker.addListener('mouseout', () => {
      infoWindow.close();
      marker.setZIndex(1);
    });
    marker.setMap(this.map);
    this.createMarker();
  }

  createMarker() : void{
    const infoWindow = new google.maps.InfoWindow({
      content: '<h5>' + this.hotel.name + '</h5>' + '<p style="font-size:12px;">' + this.hotel.address.line + '</p>',
    });
    const marker = new google.maps.Marker({
      position: { lat: this.hotel.info.position.latitude, lng: this.hotel.info.position.longitude },
      label: '🛏',
      map: this.map,
      // label: labels[i % labels.length],
    });
    marker.addListener('click', () => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });

    return marker;
  }

  getAllImages(){
    const imgs = [];
    for (let h = 0; h < this.allHotels.length; h ++){
      const current = this.allHotels[h][0];
      if (current.info && current.info.images){
        current.info.images.forEach(i => imgs.push(i.list[0].url));
      }
    }
    return imgs.filter((value, index) => imgs.indexOf(value) === index);
  }

  toggleDialogGallery(index:number){
    $('.carousel-inner').removeClass('active');
    this.activeImage = index;
    console.log('active Index => ', this.activeImage);
    $('#galleryModal').modal('toggle');
  }

  showDialogGallery(index:number){
    $('.carousel-inner').removeClass('active');
    this.activeImage = index;
    $('#galleryModal').modal('show');
  }

  hideDialogGallery(){
    delete this.activeImage;
    const gall = document.querySelectorAll('.carousel-item');
    gall.forEach(gal => {
      gal.classList.remove('active');
    });
    $('#galleryModal').modal('hide');
  }

  getWidthScrollL(){
    const widthTh = $( '#imageThumb'+this.currentThumb ).width();
    if(this.currentThumb>0){
      this.currentThumb = this.currentThumb-1;
      console.log(this.currentThumb);
    }
    $('.thumbsContainer').animate({scrollLeft: widthTh*(this.currentThumb)}, 200);
    console.log('widthTh => ', widthTh);
  }

  getWidthScrollR(){
    const widthTh = $( '#imageThumb'+this.currentThumb ).width();
    $('.thumbsContainer').animate({scrollLeft: widthTh*(this.currentThumb+1)}, 200);
    if(this.currentThumb < this.allImages.length){
      this.currentThumb = this.currentThumb+1;
      console.log(this.currentThumb);
    }
    console.log('widthTh => ', widthTh);
  }

  getWidthScrollLCa(){
    const widthTh = $( '#imageThumbCa'+this.currentThumb ).width();
    if(this.currentThumb>0){
      this.currentThumb = this.currentThumb-1;
      console.log(this.currentThumb);
    }
    $('.thumbsContainerCa').animate({scrollLeft: widthTh*(this.currentThumb)}, 200);
    console.log('widthTh => ', widthTh);
  }

  getWidthScrollRCa(){
    const widthTh = $( '#imageThumbCa'+this.currentThumb ).width();
    $('.thumbsContainerCa').animate({scrollLeft: widthTh*(this.currentThumb+1)}, 200);
    if(this.currentThumb < this.allImages.length){
      this.currentThumb = this.currentThumb+1;
      console.log(this.currentThumb);
    }
    console.log('widthTh => ', widthTh);
  }

  prevSlide(){

  }

  nextSlide(){

  }
}
