<div *ngIf="!!travel" class="card marginbottom20 padding10">
  <div class="row buttonrow">
    <div class="col-lg-6 mb-3">
      <div class="mb-2">
        {{'PNR'|translate}}: <span *ngIf="pnr else emptyInformation"> {{pnr}}</span>
      </div>
      <div *ngIf="!pnr && travel.entitlements?.id" class="mb-2">
        {{'TRAIN_MODULE.CARNET'|translate}}: <span *ngIf="travel.entitlements?.id else emptyInformation"> {{travel.entitlements.id}}</span>
      </div>
      <div *ngIf="!pnr && travel.entitlements?.maxUsages" class="mb-2">
        {{'TRAIN_MODULE.TRENIT.USAGE'|translate}}: <span> {{travel.entitlements?.currentUsages}}/{{travel.entitlements?.maxUsages}}</span>
      </div>
      <div class="mb-2">
        {{'STATUS'|translate}}: <span *ngIf="travel.state else emptyInformation"> {{travel.state}}</span>
      </div>
      <div class="mb-2">
        {{'TRAIN_MODULE.AFTERSALE.CREATION_DATE'|translate}}: <span
        *ngIf="travel.creationDate else emptyInformation"> {{travel.creationDate | date }}</span>
      </div>
      <div class="mb-2">
        {{'LAST_UPDATE'|translate}}: <span
        *ngIf="travel.lastUpdated else emptyInformation"> {{travel.lastUpdated | date }}</span>
      </div>
      <mat-checkbox [(ngModel)]="showDeleted">{{ 'TRAIN_MODULE.AFTERSALE.SHOW_DELETED' | translate}}</mat-checkbox>
    </div>
  </div>

  <mat-tab-group animationDuration="0ms" (selectedTabChange)="changeSelectedTab($event)" [(selectedIndex)]="initialIndex"
                 mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab *ngFor="let ts of travelSolutions;let i = index;" [ngClass]="(ts.state !== 'FINALIZED')?'closed-travel':''"
             label="{{'TRAIN_MODULE.AFTERSALE.TICKET' | translate }} {{i+1}}" [disabled]="ts.state === 'CLOSED' && !this.showDeleted">
      <div class="overflow-hidden">
        <div class="row mb-3 mt-2">
          <div class="col-5">
            {{'DESCRIPTION'|translate}}
            <h6>{{ts.description}}</h6>
          </div>
          <div class="col-3">
            {{'DATE'|translate}}
            <h6>{{ ts.date.split('+')[0] | date:'dd/MM/yyyy' }}</h6>
          </div>
          <div class="col-2">
            {{'STATUS'|translate}}
            <h6>{{ts.state | translate }}</h6>
          </div>
          <div class="col-2">
            {{'PRICE'|translate}}
            <h6 *ngIf="ts.totalPrice else emptyInformation"> {{ts.totalPrice?.amount}} {{ts.totalPrice?.currency}}</h6>
          </div>
        </div>
        <div class="row">
          <div *ngFor="let pax of ts.travellers" class="col-lg-12 mb-3">
            <div *ngFor="let segment of getFilteredSegments(pax)" class="row train-ticket" [ngClass]="segment.deleted ? 'deleted-train-ticket' : ''">
              <div class="col-12 my-0 mx-0 train-ticket-header">
                <mat-icon>train</mat-icon>
                <span>{{'TRAIN_MODULE.AFTERSALE.TICKET' | translate}} {{'TRAIN_MODULE.FORWARDJOURNEY' | translate}} |
                  {{pax.name | uppercase }} {{pax.surname | uppercase}}<sup>({{pax.type}})</sup>
                </span>
              </div>
              <div class="col-12 train-ticket-itinerary">
                <div class="row mb-1">
                  <div class="col-3">
                    <div class="row mb-1">
                      <div class="col text-left">
                        {{'TRAIN_MODULE.TRAIN_DEPARTURE' | translate}}
                        <h6>{{getStationName(segment.start?.id)}}</h6>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col text-left">
                        {{'DATE' | translate}}
                        <h6 *ngIf="segment.departureTime else noData">{{(segment.departureTime | date:'dd/MM/yyyy')}}</h6>
                      </div>
                      <div class="col text-right">
                        {{'TIME' | translate}}
                        <h6 *ngIf="segment.departureTime else noData">{{(segment.departureTime | date:'HH:mm')}}</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 pl-3">
                    <div class="row mb-1">
                      <div class="col text-left">
                        {{'TRAIN_MODULE.TRAIN_ARRIVAL' | translate}}
                        <h6>{{getStationName(segment.end?.id)}}</h6>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col text-left" style="border-left: thin lightgray">
                        {{'DATE' | translate}}
                        <h6 *ngIf="segment.arrivalTime else noData">{{(segment.arrivalTime | date:'dd/MM/yyyy')}}</h6>
                      </div>
                      <div class="col text-right">
                        {{'TIME' | translate}}
                        <h6 *ngIf="segment.arrivalTime else noData">{{(segment.arrivalTime | date:'HH:mm')}}</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 offer-col">
                    <div class="row mb-1">
                      <div class="col"><h6>{{'STATUS'|translate}}</h6></div>
                      <div class="col"><h6>{{'TRAIN_MODULE.TRENIT.OFFER'|translate}}</h6></div>
                      <div class="col"><h6>{{'TRAIN_MODULE.CABIN'|translate}}</h6></div>
                      <div class="col"><h6>{{'SEAT'|translate}}</h6></div>
                      <div class="col"><h6>{{'PRICE'|translate}}</h6></div>
                    </div>
                    <div *ngFor="let offer of filteredOffers(segment.offers);let i = index" class="row mb-1">
                      <div class="col">{{offer.status}}</div>
                      <div class="col">{{getOfferName(offer.offer.id) | translate }}</div>
                      <div class="col">{{getServiceName(offer.catalogServiceId.id)}}</div>
                      <div *ngIf="offer.bookingInfo.reservation?.seat else noSeat" class="col">
                        {{offer.bookingInfo.reservation?.seat}} ({{'TRAIN' | translate}} {{offer.bookingInfo.reservation?.wagon}})
                      </div>
                      <ng-template #noSeat>
                        <div class="col">--</div>
                      </ng-template>
                      <div class="col">{{offer.price.amount}} {{offer.price.currency}}</div>
<!--                      <div class="col">-->
<!--                        <div class="buttonrow">-->
<!--                          <button matTooltip="{{getAfterSaleDisabled(offer, 'TRAVELLER_CHANGE') | json}}" [class]="getAfterSaleDisabled(offer, 'TRAVELLER_CHANGE') ? 'after-button' :-->
<!--                          'after-button-disabled'">N</button>-->
<!--                          <button matTooltip="{{getAfterSaleDisabled(offer, 'TRAVEL_CHANGE') | json}}" [class]="getAfterSaleDisabled(offer, 'TRAVEL_CHANGE') ? 'after-button' :-->
<!--                          'after-button-disabled'">T</button>-->
<!--                          <button matTooltip="{{getAfterSaleDisabled(offer, 'BOOKING_CHANGE') | json}}" [class]="getAfterSaleDisabled(offer, 'BOOKING_CHANGE') ? 'after-button' :-->
<!--                          'after-button-disabled'">D</button>-->
<!--                          <button matTooltip="{{getAfterSaleDisabled(offer, 'REFUND') | json}}" [class]="getAfterSaleDisabled(offer, 'REFUND') ? 'after-button' :-->
<!--                          'after-button-disabled'">R</button>-->
<!--                        </div>-->
<!--                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #noData><h6> _ </h6></ng-template>

            </div>
          </div>
          <ng-container *ngIf="ts.returnTravelSolution as tsreturn">
            <div *ngFor="let pax of tsreturn.travellers" class="col-lg-12 mb-3">
              <div *ngFor="let segment of getFilteredSegments(pax)" class="row train-ticket" [ngClass]="segment.deleted ? 'deleted-train-ticket' : ''">
                <div class="col-12 my-0 mx-0 train-ticket-header">
                  <mat-icon>train</mat-icon>
                  <span>{{'TRAIN_MODULE.AFTERSALE.TICKET' | translate}} {{'TRAIN_MODULE.RETURNJOURNEY' | translate}} |
                    {{pax.name | uppercase }} {{pax.surname | uppercase}}<sup>({{pax.type}})</sup>
                  </span>
                </div>
                <div class="col-12 train-ticket-itinerary">
                  <div class="row mb-1">
                    <div class="col-3">
                      <div class="row mb-1">
                        <div class="col text-left">
                          {{'TRAIN_MODULE.TRAIN_DEPARTURE' | translate}}
                          <h6>{{getStationName(segment.start?.id)}}</h6>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col text-left">
                          {{'DATE' | translate}}
                          <h6 *ngIf="segment.departureTime else noData">{{(segment.departureTime | date:'dd/MM/yyyy')}}</h6>
                        </div>
                        <div class="col text-right">
                          {{'TIME' | translate}}
                          <h6 *ngIf="segment.departureTime else noData">{{(segment.departureTime | date:'HH:mm')}}</h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 pl-3">
                      <div class="row mb-1">
                        <div class="col text-left">
                          {{'TRAIN_MODULE.TRAIN_ARRIVAL' | translate}}
                          <h6>{{getStationName(segment.end?.id)}}</h6>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col text-left" style="border-left: thin lightgray">
                          {{'DATE' | translate}}
                          <h6 *ngIf="segment.arrivalTime else noData">{{(segment.arrivalTime | date:'dd/MM/yyyy')}}</h6>
                        </div>
                        <div class="col text-right">
                          {{'TIME' | translate}}
                          <h6 *ngIf="segment.arrivalTime else noData">{{(segment.arrivalTime | date:'HH:mm')}}</h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 offer-col">
                      <div class="row mb-1">
                        <div class="col"><h6>{{'STATUS'|translate}}</h6></div>
                        <div class="col"><h6>{{'TRAIN_MODULE.TRENIT.OFFER'|translate}}</h6></div>
                        <div class="col"><h6>{{'TRAIN_MODULE.CABIN'|translate}}</h6></div>
                        <div class="col"><h6>{{'SEAT'|translate}}</h6></div>
                        <div class="col"><h6>{{'PRICE'|translate}}</h6></div>
                      </div>
                      <div *ngFor="let offer of filteredOffers(segment.offers);let i = index" class="row mb-1">
                        <div class="col">{{offer.status}}</div>
                        <div class="col">{{getOfferName(offer.offer.id) | translate }}</div>
                        <div class="col">{{getServiceName(offer.catalogServiceId.id)}}</div>
                        <div *ngIf="offer.bookingInfo.reservation?.seat else noSeat" class="col">
                          {{offer.bookingInfo.reservation?.seat}} ({{'TRAIN' | translate}} {{offer.bookingInfo.reservation?.wagon}})
                        </div>
                        <ng-template #noSeat>
                          <div class="col">--</div>
                        </ng-template>
                        <div class="col">{{offer.price.amount}} {{offer.price.currency}}</div>
<!--                        <div class="col">-->
<!--                          <div class="buttonrow">-->
<!--                            <button matTooltip="{{getAfterSaleDisabled(offer, 'TRAVELLER_CHANGE') | json}}" [class]="getAfterSaleDisabled(offer, 'TRAVELLER_CHANGE') ? 'after-button' :-->
<!--                          'after-button-disabled'">N</button>-->
<!--                            <button matTooltip="{{getAfterSaleDisabled(offer, 'TRAVEL_CHANGE') | json}}" [class]="getAfterSaleDisabled(offer, 'TRAVEL_CHANGE') ? 'after-button' :-->
<!--                          'after-button-disabled'">T</button>-->
<!--                            <button matTooltip="{{getAfterSaleDisabled(offer, 'BOOKING_CHANGE') | json}}" [class]="getAfterSaleDisabled(offer, 'BOOKING_CHANGE') ? 'after-button' :-->
<!--                          'after-button-disabled'">D</button>-->
<!--                            <button matTooltip="{{getAfterSaleDisabled(offer, 'REFUND') | json}}" [class]="getAfterSaleDisabled(offer, 'REFUND') ? 'after-button' :-->
<!--                          'after-button-disabled'">R</button>-->
<!--                          </div>-->
<!--                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
                <ng-template #noData><h6> _ </h6></ng-template>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <ng-template #emptyInformation>
    <span> _ _ _ _</span>
  </ng-template>
</div>
