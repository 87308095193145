<mat-card appearance="outlined" class="collapse show" id="searchCard">
  <mat-card-content>
    <div class="row">
      <div *ngFor="let t of travellers; let i = index" class="col-md-auto">
        <span *ngIf="t.name" class="singleiata">
            {{t.name}} {{t.surname}}
          <span (click)="removePax(i)" aria-hidden="true" class="cancelicon">&times;</span>
        </span>
      </div>
    </div>
    <div class="row searchContainer d-flex justify-content-end">
      <div class="formTopButtons ">
        <div class="formTopAction reset" (click)="resetForm()" matTooltip="{{'CLEAR_FORM'|translate}}">
              <span class="material-icons">
                refresh
              </span>
        </div>
      </div>
    </div>
    <form (submit)="onSubmit($event)" [formGroup]="searchForm" id="frmSearch">
      <div class="row lightbg">
        <!-- STAZIONE PARTENZA -->
        <div class="col-xs-12 col-lg-3">
          <mat-form-field class="full-width">
            <mat-label>{{ 'TRAIN_MODULE.FROM_STATION' | translate}}</mat-label>
            <input [matAutocomplete]="auto_dep" formControlName="departure_station" matInput>
          </mat-form-field>

          <mat-autocomplete #auto_dep="matAutocomplete" [displayWith]="displayFn" class="full-width">
            <mat-option *ngIf="isStationLoading" class="is-loading">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-option>
            <ng-container *ngIf="!isStationLoading">
              <mat-option *ngFor="let station of filteredDepartureStation" [value]="station">
                <span>{{ station?.name }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>

        <!-- STAZIONE ARRIVO -->
        <div class="col-xs-12 col-lg-3">
          <mat-form-field class="full-width">
            <mat-label>{{ 'TRAIN_MODULE.TO_STATION' | translate}}</mat-label>
            <input [matAutocomplete]="auto_arr" formControlName="arrival_station" matInput>
          </mat-form-field>

          <mat-autocomplete #auto_arr="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngIf="isStationLoading" class="is-loading">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-option>
            <ng-container *ngIf="!isStationLoading">
              <mat-option *ngFor="let station of filteredArrivalStation" [value]="station">
                <span>{{ station?.name }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>

        <!-- PIT -->
        <div class="col-xs-12 col-lg-2">
          <mat-form-field class="full-width">
            <mat-label>{{'TRAIN_MODULE.PIT' | translate}}</mat-label>
            <input formControlName="pit_code" matInput maxlength="24" placeholder="{{'TRAIN_MODULE.PIT' | translate}}">
          </mat-form-field>
        </div>

        <div class="col-xs-12 col-lg-1 mb-3 mb-md-0">
          <button (click)="togglePaxModal()" mat-button type="button">{{'PASSENGERS'|translate}}</button>
        </div>
      </div>
    </form>
    <div class="row text-right search-buttons">
      <div class="col-sm-0">
        <!--<button (click)="resetForm()" mat-stroked-button type="button">
          {{'TRAIN_MODULE.RESET'|translate}}
        </button>-->
      </div>
      <div class="col-sm-1 mr-3 mr-lg-0">
        <button (click)="toggleSearchForm()" [disabled]="!isFormValid()" form="frmSearch" class="newButtonPrimary" type="submit">
          {{'TRAIN_MODULE.FORM.SEARCH' | translate}}
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

  <div class="row" style="margin-top: 10px;" *ngIf="(fetchingInformation$ | async)">
    <div class="col-xs-12 col-lg-12">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
  <mat-card appearance="outlined" *ngIf="!(fetchingInformation$ | async) && details" style="margin-top: 10px;">
    <mat-card-content>
      <div class="col-lg">
        <ng-container *ngIf="sortedSegments[0].services.length > 0 && sortedSegments[0].services[0].services?.length > 0 else trenitNoSolutions">
          <div class="padding10">
            <div class="row mb-3 lightbg">
              <div class="col-md-auto align-self-center">
                <span class="material-icons">train</span>
              </div>
              <div class="col-md-auto align-self-center">
                <p>{{sortedSegments[0].departureDate | date:'dd/MM/yyyy - HH:mm'}}</p>
                <p>{{sortedSegments[0].departureStation?.name}}</p>
              </div>
              <div class="col-md-auto align-self-center">
                <span class="material-icons">arrow_right</span>
              </div>
              <div class="col-md-auto align-self-center">
                <p>{{sortedSegments[0].arrivalDate | date:'dd/MM/yyyy - HH:mm'}}</p>
                <p>{{sortedSegments[0].arrivalStation?.name}}</p>
              </div>
            </div>
            <hr/>
            <div class="mb-2">
              {{'TRAIN_MODULE.SELECT_TARIFF'|translate}}
            </div>
            <div *ngFor="let pax of sortedSegments[0].services; index as i" class="row">
              <div class="col-3 align-self-center">
                {{ getTraveller(pax)?.surname }} {{ getTraveller(pax)?.name }}
                <sup>{{ getTraveller(pax)?.type }}</sup>
              </div>
              <div class="col-2 align-self-center">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>{{'TRAIN_MODULE.LEVEL'|translate}}</mat-label>
                  <mat-select [(ngModel)]="selectedLevel[i]" placeholder="{{'TRAIN_MODULE.LEVEL'|translate}}" (click)="this.selectedOffer = {}">
                    <mat-option *ngFor="let level of availableLevels" [value]="level">{{ level }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="selectedLevel[i]" class="col-3 align-self-center">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>{{'TRAIN_MODULE.CABIN'|translate}}</mat-label>
                  <mat-select [(ngModel)]="selectedClass[i]" placeholder="Service">
                    <mat-optgroup *ngFor="let group of pax.services" [label]="group.name">
                      <mat-option (click)="setOffer(serviceClass, details.result.travelSolutions, i, pax)"
                                  *ngFor="let serviceClass of filterByLevel(group.services, i)" [value]="serviceClass">
                        {{getServiceName(serviceClass)}}
                      </mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="selectedClass[i]" class="col-3 align-self-center">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>{{'TRAIN_MODULE.TARIFF'|translate}}</mat-label>
                  <mat-select [(ngModel)]="selectedOffer[i]">
                    <mat-optgroup *ngFor="let groupOffer of selectedClass[i].offers" [label]="groupOffer.name">
                      <mat-option (click)="selectTrenitaliaOffer(details.result.travelSolutions, offer, i, pax)" *ngFor="let offer of groupOffer.offers"
                                  [disabled]="isOfferDisabled(pax, offer)"
                                  [value]="offer">
                        {{ getOfferDisplayText(offer) }}
                      </mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="selectedClass[i]" class="col-1 align-self-center">
                <a (click)="openTrenitInfoPopup(selectedOfferId[i])" *ngIf="selectedOfferId" style="cursor: pointer">
                  <mat-icon matTooltip="{{ selectedClass[i][selectedOfferId[i]]?.description | translate}}">info</mat-icon>
                </a>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-6 align-self-center buttonrow">
                  <span matTooltip="{{getEligibilityMessage()}}">
                    <button (click)="evaluateTravelSolution()" [disabled]="getDisabledOffer() || (evaluateTravelLoading$ | async) || !selectedOfferId"
                            color="primary" mat-button>
                      {{'TRAIN_MODULE.FORM.NEXT' | translate}}
                      <ng-container *ngIf="!(evaluateTravelLoading$ | async) else evaluateTravelLoadingTpl">
                        <mat-icon>arrow_right_alt</mat-icon>
                      </ng-container>
                      <ng-template #evaluateTravelLoadingTpl>
                        <mat-icon class="spin">autorenew</mat-icon>
                      </ng-template>
                    </button>
                  </span>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #trenitNoSolutions>
          <p>{{'TRAIN_MODULE.TRENIT.NO_SOLUTIONS_AVAILABLE' | translate}}</p>
        </ng-template>

      </div>
    </mat-card-content>
  </mat-card>

<div aria-hidden="true" aria-labelledby="paxModal" class="modal fade" data-backdrop="static" id="paxModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document" style="top:70px">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paxModalTitle">{{'TRAIN_MODULE.ADD_PAX.TITLE'|translate}}</h5>
        <button (click)="togglePaxModal()" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body overflow-auto">
        <div class="">
          <app-passenger-cards [maxPax]="1" [passengers]="travellers" (removePassengers)="removePax($event)" (setPassengers)="setTravellers($event)"></app-passenger-cards>
        </div>
        <div class="row justify-content-end">
          <button (click)="togglePaxModal()" mat-raised-button type="button">
            <span aria-hidden="true">{{'CONFIRM'|translate}}</span>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
