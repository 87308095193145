<custom-title></custom-title>
<div class="dashboard padding20">
  <div class="row mb-3">
    <div class="col-lg-3">
      <div class="homeWidg" *ngIf="(maincomponent.loggedUser.role.superAdmin || (appComponent.isSbt || appComponent.isB2B)) && !appComponent.isGat">
        <div class="row">
          <div class="col-8 align-self-center">
            <h5>{{'TRAVEL_FOLDER'|translate}}</h5>
            <div class="widAction" matRipple (click)="maincomponent.addDossierToggle()">
              {{'NEW_FOLDER'|translate}}
            </div>
            <div class="widAction" matRipple routerLink="/dossier/list">
              {{'FOLDER_LIST'|translate}}
            </div>
          </div>
          <div class="col-4 align-self-center text-center">
            <span style="color:#43c76f;font-size:36px" class="material-icons">topic</span>
          </div>
        </div>
      </div>

      <div class="homeWidg">
        <div class="row">
          <div class="col-8 align-self-center">
            <h5>{{'REGISTRY'|translate}}</h5>
            <div class="widAction" matRipple>
              -
            </div>
            <div class="widAction" matRipple routerLink="/travellers/list">
              {{'TRAVELLERS_BOOK'|translate}}
            </div>
          </div>
          <div class="col-4 align-self-center text-center">
            <span style="color:#43c76f;font-size:36px" class="material-icons">person_outline</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6" *ngIf="maincomponent.loggedUser.role.superAdmin || maincomponent.loggedUser.company.services.flight">
      <div class="row">
        <div class="col-lg-6">
          <div class="homeWidg">
            <div class="row">
              <div class="col-8 align-self-center">
                <h5>{{'FLIGHT'|translate}}</h5>
                <div class="widAction">
                  <span class="d-inline-block" style="vertical-align: middle" matRipple routerLink="/flight/search">{{'SEARCH_FLIGHT'|translate}}</span>
                  <span class="d-inline-block mr-2 ml-2" style="vertical-align: middle"> | </span>
                  <span class="d-inline-block" style="vertical-align: middle" matRipple routerLink="/flight/history">{{'HISTORY'|translate}}</span>
                </div>
                <div class="widAction" matRipple routerLink="/flight/reservations">
                  {{'PNR_LIST'|translate}} <span *ngIf="activeFlights > 0">({{activeFlights}})</span>
                </div>
              </div>
              <div class="col-4 align-self-center text-center">
                <!-- <span *ngIf="activeFlights > 0">{{activeFlights}}</span> -->
                <span [ngClass]="{'topfix':activeFlights > 0}" style="color:#4d79f6;font-size:36px" class="material-icons">flight</span>
                <!-- <div class="text-center" *ngIf="percFlights">{{percFlights|number:'.2-2'}} %</div> -->
              </div>
            </div>
          </div>

        </div>

        <div class="col-lg-6" *ngIf="(maincomponent.loggedUser.role.superAdmin || maincomponent.loggedUser.company.services.hotel) && !appComponent.isGat">
          <div class="homeWidg">
            <div class="row">
              <div class="col-8 align-self-center">
                <h5>{{'HOTEL'|translate}}</h5>
                <div class="widAction" matRipple routerLink="/hotel/search">
                  {{'HOTEL_SEARCH'|translate}}
                </div>
                <div class="widAction" matRipple routerLink="/hotel/reservations">
                  {{'HOTEL_RESERVATION_LIST'|translate}} <span *ngIf="activeHotels > 0">({{activeHotels}})</span>
                </div>
              </div>
              <div class="col-4 align-self-center text-center">
                <!-- <span *ngIf="activeHotels > 0">{{activeHotels}}</span> -->
                <span [ngClass]="{'topfix':activeHotels > 0}" style="color:#4d79f6;font-size:36px" class="material-icons">hotel</span>
                <!-- <div class="text-center" *ngIf="percHotels">{{percHotels|number:'.2-2'}} %</div> -->
              </div>
            </div>

          </div>
        </div>
        <div class="col-lg-6" *ngIf="(maincomponent.loggedUser.role.superAdmin || maincomponent.loggedUser.company.services.train) && !appComponent.isGat">
          <div class="homeWidg">
            <div class="row">
              <div class="col-8 align-self-center">
                <h5>{{'TRAIN'|translate}}</h5>
                <div class="widAction" matRipple routerLink="/trains/search">
                  {{'TRAIN_SEARCH'|translate}}
                </div>
                <div class="widAction" matRipple routerLink="/trains/reservations">
                  {{'TRAIN_RESERVATION_LIST'|translate}} <span *ngIf="activeTrains > 0">({{activeTrains}})</span>
                </div>
              </div>
              <div class="col-4 align-self-center text-center">
                <!-- <span *ngIf="activeTrains > 0">{{activeTrains}}</span> -->
                <span [ngClass]="{'topfix':activeTrains > 0}" style="color:#4d79f6;font-size:36px" class="material-icons">train</span>
                <!-- <div class="text-center" *ngIf="percTrains">{{percTrains|number:'.2-2'}} %</div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6" *ngIf="(maincomponent.loggedUser.role.superAdmin || maincomponent.loggedUser.company.services.car) && !appComponent.isGat">
          <div class="homeWidg">
            <div class="row">
              <div class="col-8 align-self-center">
                <h5>{{'CAR_RENT'|translate}}</h5>
                <div class="widAction" matRipple routerLink="/car-rent/search">
                  {{'CAR_RENT_SEARCH'|translate}}
                </div>
                <div class="widAction" matRipple routerLink="/car-rent/reservations">
                  {{'CAR_RESERVATION_LIST'|translate}} <span *ngIf="activeCars > 0">({{activeCars}})</span>
                </div>
              </div>
              <div class="col-4 align-self-center text-center">
                <!-- <span *ngIf="activeCars > 0">{{activeCars}}</span> -->
                <span [ngClass]="{'topfix':activeCars > 0}" style="color:#4d79f6;font-size:36px" class="material-icons">directions_car</span>
                <!-- <div class="text-center" *ngIf="percCars">{{percCars|number:'.2-2'}} %</div> -->
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="col-lg-3">
      <div class="homeWidg" *ngIf="(maincomponent.loggedUser.role.superAdmin || (appComponent.isB2B || appComponent.isSbt)) && !appComponent.isGat">
        <div class="row">
          <div class="col-8 align-self-center">
            <h5>{{'REQUESTS'|translate}}</h5>
            <div class="widAction" matRipple routerLink="/offline-requests/open-request">
              {{'NEW_TRAVEL_REQUEST'|translate}}
            </div>
            <div class="widAction" matRipple routerLink="/offline-requests/request-list">
              {{'REQUESTS_LIST'|translate}}
            </div>
          </div>
          <div class="col-4 align-self-center text-center">
            <span style="color:#7367f0;font-size:36px" class="material-icons">moving</span>
          </div>
        </div>
      </div>

      <div class="homeWidg">
        <div class="row">
          <div class="col-10 align-self-center">
            <h5>{{'SUPPORT'|translate}}</h5>
            <div class="widAction">
              <span class="d-inline-block" style="vertical-align: middle" matRipple (click)="openBottomSheet()">{{'OPEN_TICKET'|translate}}</span>
              <span class="d-inline-block mr-2 ml-2" style="vertical-align: middle"> | </span>
              <span class="d-inline-block" style="vertical-align: middle" matRipple (click)="openHelpDialog()">{{'PHONE_SUPPORT'|translate}}</span>
            </div>
            <div class="widAction" matRipple routerLink="/ticketing/list" *ngIf="!appComponent.isGat">
              {{'REQUESTS_LIST'|translate}}
            </div>
          </div>
          <div class="col-2 align-self-center text-center">
            <span style="color:#fa7d33;font-size:36px" class="material-icons">support</span>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="row mb-3" *ngIf="generalStats && (!appComponent.isB2B && !appComponent.isSbt)">

    <div class="col-lg-3">
      <div class="homeWidg">
        <div class="row">
          <div class="col-10 align-self-center">
            <h5>Reportistica volo<br/> <span class="smalltext">{{today|date: 'MM/yyyy'}}</span></h5>
            <div class="widAction" routerLink="/statistic/year">
              {{generalStats?.flight?.amount||0|number:'.2-2'}}€
            </div>
          </div>
          <div class="col-2 align-self-center text-center">
            <span style="color:#1d7cbc;font-size:26px" class="material-icons">flight</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="homeWidg">
        <div class="row">
          <div class="col-10 align-self-center">
            <h5>Prenotazioni<br/> <span class="smalltext">{{today|date: 'MM/yyyy'}}</span></h5>
            <div class="widAction" routerLink="/statistic/year">
              {{generalStats?.flight?.pnr||0|number}}
            </div>
          </div>
          <div class="col-2 align-self-center text-center">
            <span style="color:#1d7cbc;font-size:26px" class="material-icons">check</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="homeWidg">
        <div class="row">
          <div class="col-10 align-self-center">
            <h5>Segmenti<br/> <span class="smalltext">{{today|date: 'MM/yyyy'}}</span></h5>
            <div class="widAction" routerLink="/statistic/year">
              {{generalStats?.flight?.segments||0|number}}
            </div>
          </div>
          <div class="col-2 align-self-center text-center">
            <span style="color:#1d7cbc;font-size:26px" class="material-icons">multiple_stop</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="homeWidg">
        <div class="row">
          <div class="col-10 align-self-center">
            <h5>Incentivi<br/> <span class="smalltext">{{today|date: 'MM/yyyy'}}</span></h5>
            <div class="widAction" routerLink="/statistic/year">
              {{generalStats?.flight?.fees||0|number:'.2-2'}} €
            </div>
          </div>
          <div class="col-2 align-self-center text-center">
            <span style="color:#1d7cbc;font-size:26px" class="material-icons">euro</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="display:none" class="row buttonSections">

    <div class="col-lg-3">
      <h4>{{'TRAVEL_FOLDER'|translate}}</h4>
      <div class="row">
        <div class="col-md-6 col-lg-12 col-12">
          <div class="row dashButton mb-3" >
            <div class="col-4 blueBg text-center">
              <div class="iconCont">
                <span class="material-icons">topic</span>
              </div>
            </div>
            <div class="col-8">
              <div class="dashText" matRipple (click)="maincomponent.addDossierToggle()">
                <div class="dashAction">
                  <span class="material-icons">add</span>
                  {{'NEW_FOLDER'|translate}}
                </div>
                <div class="subDash">{{'TICKETING'|translate}}</div>
              </div>
              <div class="dashText" matRipple routerLink="/dossier/list">
                <div class="dashAction">
                  <span class="material-icons">list</span>
                  {{'FOLDER_LIST'|translate}}
                </div>
                <div class="subDash">{{'TICKETING'|translate}}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-lg-6">
      <h4>{{'SELF_BOOKING_TOOL'|translate}}</h4>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="row dashButton mb-3">
            <div class="col-4 blueBg text-center">
              <div class="iconCont">
                <span class="material-icons">flight</span>
              </div>
            </div>
            <div class="col-8">
              <div class="dashText" matRipple routerLink="/flight/search">
                <div class="dashAction">
                  <span class="material-icons">search</span>
                  {{'SEARCH_FLIGHT'|translate}}
                </div>
                <div class="subDash">{{'FLIGHT'|translate}}</div>
              </div>

              <div class="dashText" matRipple routerLink="/flight/reservations">
                <div class="dashAction">
                  <span class="material-icons">list</span>
                  {{'PNR_LIST'|translate}}
                </div>
                <div class="subDash">{{'FLIGHT'|translate}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12">
          <div class="row dashButton mb-3">
            <div class="col-4 blueBg text-center">
              <div class="iconCont">
                <span class="material-icons">hotel</span>
              </div>
            </div>
            <div class="col-8">
              <div class="dashText" matRipple routerLink="/hotel/search">
                <div class="dashAction">
                  <span class="material-icons">search</span>
                  {{'HOTEL_SEARCH'|translate}}
                </div>
                <div class="subDash">{{'HOTEL'|translate}}</div>
              </div>
              <div class="dashText" matRipple routerLink="/hotel/reservations">
                <div class="dashAction">
                  <span class="material-icons">list</span>
                  {{'HOTEL_RESERVATION_LIST'|translate}}
                </div>
                <div class="subDash">{{'HOTEL'|translate}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12" *ngIf="maincomponent.loggedUser.role.superAdmin || appComponent.isSbt">
          <div class="row dashButton mb-3">
            <div class="col-4 blueBg text-center">
              <div class="iconCont">
                <span class="material-icons">train</span>
              </div>
            </div>
            <div class="col-8">
              <div class="dashText" matRipple routerLink="/trains/search">
                <div class="dashAction">
                  <span class="material-icons">search</span>
                  {{'TRAIN_SEARCH'|translate}}
                </div>
                <div class="subDash">{{'TRAIN'|translate}}</div>
              </div>
              <div class="dashText" matRipple routerLink="/trains/reservations">
                <div class="dashAction">
                  <span class="material-icons">list</span>
                  {{'TRAIN_RESERVATION_LIST'|translate}}
                </div>
                <div class="subDash">{{'TRAIN'|translate}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12" *ngIf="maincomponent.loggedUser.role.superAdmin || appComponent.isSbt">
          <div class="row dashButton mb-3">
            <div class="col-4 blueBg text-center">
              <div class="iconCont">
                <span class="material-icons">directions_car</span>
              </div>
            </div>
            <div class="col-8">
              <div class="dashText" matRipple routerLink="/car-rent/search">
                <div class="dashAction">
                  <span class="material-icons">search</span>
                  {{'CAR_RENT_SEARCH'|translate}}
                </div>
                <div class="subDash">{{'CAR_RENT'|translate}}</div>
              </div>
              <div class="dashText" matRipple routerLink="/car-rent/reservations">
                <div class="dashAction">
                  <span class="material-icons">list</span>
                  {{'CAR_RESERVATION_LIST'|translate}}
                </div>
                <div class="subDash">{{'CAR_RENT'|translate}}</div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
    <div class="col-lg-3">
      <h4>{{'REQUESTS'|translate}}</h4>
      <div class="row">
        <div class="col-md-6 col-lg-12 col-12">
          <div class="row dashButton greenButton helpbutton mb-3">
            <div class="col-4 greenBg text-center">
              <div class="iconCont">
                <span class="material-icons">question_answer</span>
              </div>
            </div>
            <div class="col-8">
              <div class="dashText" matRipple routerLink="/offline-requests/open-request">
                <div class="dashAction">
                  <span class="material-icons">create</span>
                  {{'NEW_TRAVEL_REQUEST'|translate}}
                </div>
                <div class="subDash">{{'MOD_REQUESTS'|translate}}</div>
              </div>
              <div class="dashText" matRipple routerLink="/offline-requests/request-list">
                <div class="dashAction">
                  <span class="material-icons">list</span>
                  {{'REQUESTS_LIST'|translate}}
                </div>
                <div class="subDash">{{'MOD_REQUESTS'|translate}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-12 col-12">
          <div class="row dashButton helpbutton mb-3" >
            <div class="col-4 orangeBg text-center">
              <div class="iconCont">
                <span class="material-icons">contact_support</span>
              </div>
            </div>
            <div class="col-8">
              <div class="dashText" matRipple (click)="openBottomSheet()">
                <div class="dashAction">
                  <span class="material-icons">create</span>
                  {{'OPEN_TICKET'|translate}}
                </div>
                <div class="subDash">{{'TICKETING'|translate}}</div>
              </div>
              <div class="dashText" matRipple routerLink="/ticketing/list">
                <div class="dashAction">
                  <span class="material-icons">list</span>
                  {{'REQUESTS_LIST'|translate}}
                </div>
                <div class="subDash">{{'TICKETING'|translate}}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row mb-3" *ngIf="!appComponent.isB2B && !appComponent.isSbt">
    <!--
    <div class="col-lg-6 mb-3" *ngIf="maincomponent.loggedUser.role.superAdmin && !appComponent.isGat">
      <app-moneychart></app-moneychart>
    </div>
    -->

    <div class="col-lg-6 mb-3" *ngIf="maincomponent.loggedUser.role.superAdmin && activeFlights && activeHotels && activeTrains && activeCars && !appComponent.isGat">
      <app-donutchart [flights]="activeFlights" [hotels]="activeHotels" [trains]="activeTrains" [cars]="activeCars"></app-donutchart>
    </div>

    <div class="col-lg-6" *ngIf="maincomponent.loggedUser.role.superAdmin && !appComponent.isSbt && !appComponent.isGat">
      <div class="default_panel">
        <div class="default_heading d-flex justify-content-between">
          <div class="d-flex align-self-center">Admin area</div>
          <div class="d-flex align-items-center">

          </div>
        </div>
        <div class="default_content">
          <ul class="dashmenu">
            <li>
              <a routerLink="/loginAs" routerLinkActive="active-link">{{'LOGIN_AS'|translate}}</a>
            </li>
            <li>
              <a routerLinkActive="active-link" routerLink="/adminArea/flightCryptic">
                {{'FLIGHT_CRYPTIC'|translate}}
              </a>
            </li>
            <li>
              <a routerLinkActive="active-link" routerLink="/adminArea/selling-platform">
                {{'SELLING_PLATFORM'|translate}}
              </a>
            </li>
            <li>
              <a routerLinkActive="active-link" routerLink="/adminArea/addProfile">
                {{'ADD_PROFILE_COMPANY'|translate}}
              </a>
            </li>
            <li>
              <a routerLinkActive="active-link" routerLink="/adminArea/manage-iata">
                {{'MANAGE_IATA'|translate}}
              </a>
            </li>
            <li>
              <a routerLinkActive="active-link" routerLink="/adminArea/manage-blocked-flight-rates">
                {{'MANAGE_ISSUE_FARE_FLIGHT'|translate}}
              </a>
            </li>
            <li>
              <a routerLinkActive="active-link" routerLink="/adminArea/import-pnr">
                {{'IMPORT_PNR'|translate}}
              </a>
            </li>
            <li>
              <a routerLinkActive="active-link" routerLink="/adminArea/incentives">
                {{'MANAGE_INCENTIVES'|translate}}
              </a>
            </li>
          </ul>
        </div>

      </div>

    </div>
    <!--
    <div class="col-lg-6" *ngIf="maincomponent.loggedUser.role.superAdmin">
      <div class="default_panel">
        <div class="default_heading d-flex justify-content-between">
          <div class="d-flex align-self-center">{{'REQUESTS_LIST'|translate}}</div>
        </div>
        <div class="default_content" style="padding:0 10px">
          <app-ticketslist [page]="'dashboard'"></app-ticketslist>
        </div>
      </div>

    </div>
    -->
  </div>

  <!-- <div class="modal fade" id="addDosier" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="addDosier" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            {{'CREATE_DOSSIER'|translate}}
          </div>
          <button type="button" class="close" (click)="addDossierToggle()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">

          <mat-form-field>
            <mat-label>{{'DOSSIER_NAME'|translate}}</mat-label>
            <input matInput type="text" [(ngModel)]="newDossier">
            <button mat-button *ngIf="newDossier" matSuffix mat-icon-button aria-label="Clear" (click)="newDossier=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <div class="text-right">
            <button type="button" mat-button (click)="addDossierToggle()">{{'CLOSE'|translate}}</button>
            <button type="button" mat-button color="primary" [disabled]="!newDossier" (click)="createDossier()">{{'CREATE_DOSSIER'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="row">
    <div class="col-lg-6" *ngIf="!appComponent.isSbt && !appComponent.isGat">
      <div>
        <div style="padding:10px;background-color: #fff;">
          {{'REQUESTS_LIST'|translate}}
        </div>
        <div style="background-color: #fff;">
          <app-ticketslist [page]="'dashboard'"></app-ticketslist>
        </div>

      </div>
    </div>

    <!--
    <div class="col-lg-6">
      <div class="default_panel">
        <div class="default_heading d-flex justify-content-between">
          <div class="d-flex align-self-center">{{'GOVERNO_RSS'|translate}}</div>
        </div>
        <div class="default_content">
          <app-rss></app-rss>
        </div>

      </div>
    </div>
    -->
  </div>

</div>

