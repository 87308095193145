import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
declare var google : any;
@Component({
  selector: 'app-map-info-dialog',
  templateUrl: './map-info-dialog.component.html',
  styleUrls: ['./map-info-dialog.component.scss']
})
export class MapInfoDialogComponent implements OnInit {
  map:any;
  constructor(
    public dialogRef: MatDialogRef<MapInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private router: Router,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    setTimeout(() => {
      this.createMap();
    }, 200);
  }

  createMap(){
    const styles = {
      default: [],
      hide: [
        {
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },

      ],
    };
    let myLatlng;
    if(this.data.form.destination){
      myLatlng = new google.maps.LatLng(this.data.form.destination.coordinates[1], this.data.form.destination.coordinates[0]);
    } else {
      myLatlng = new google.maps.LatLng(this.data.hotel.coordinates.latitude, this.data.hotel.coordinates.longitude);
    }

    this.map = new google.maps.Map(document.getElementById('mapDialog') as HTMLElement, {
      center: myLatlng,
      mapTypeControl: false,
      // zoom: 8,
    });
    // console.log('hotelForm => ', this.hotelSearch);
    this.map.setOptions({ styles: styles["hide"] });
    const image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
    const marker = new google.maps.Marker({
      position: myLatlng,
      title: this.translate.instant('POINT_OF_INTEREST'),
      icon: image,
    });
    const infoWindow = new google.maps.InfoWindow({
      content: '<p style="font-size:12px;">' + marker.title + '</p>',
    });
    marker.addListener('click', () => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    /*
    marker.addListener('mouseout', () => {
      infoWindow.close();
      marker.setZIndex(1);
    });
    */
    marker.setMap(this.map);
    this.map.setZoom(12);
    this.createMarker();
  }

  returnImg(hotel){
    let img = '';
    if(hotel.images){
      if (hotel.images.length > 0) {
        img = hotel.images[0];
      } else {
        img = 'assets/img/placeholder-image.png';
      }
    } else {
      img = 'assets/img/placeholder-image.png';
    }
    return img;
  }

  convertDistance(distance: number){
    if(distance < 1){
      return (Math.round(distance * 1000)) + ' mt';
    } else {
      return (Math.round(distance * 10) / 10) + ' km'
    }
  }

  starCounter(i : string){
    try{
      const s = parseFloat(i);
      return new Array(s);
    } catch {
      return [];
    }
  }

  quote(hotel){
    console.log(hotel);
    // tslint:disable-next-line:max-line-length
    const url = this.router.serializeUrl(this.router.createUrlTree(['/hotel/quotation'], { queryParams: { code: hotel.hotelCode, checkIn: this.data.form.checkIn, checkOut: this.data.form.checkOut, occupancy: JSON.stringify(this.data.form.occupancy) } }));
    window.open(url, '_blank');
    // tslint:disable-next-line:max-line-length
    // this.router.navigate(['/hotel/quotation'], { queryParams: { code, checkIn: this.this.data.form.checkIn, checkout: this.this.data.form.checkOut, occupancy: JSON.stringify(this.this.data.form.occupancy) } });
  }

  createMarker(){
    let infoWindow;
    const img = this.returnImg(this.data.hotel);
    if(!this.data.available){
      infoWindow = new google.maps.InfoWindow({
        content: '<div style="max-width:200px"><div style="margin-bottom:5px;">' + this.data.hotel.name + '</div>' +
          '<div style="margin-bottom:5px;color: #ffaf00;font-size:12px;">' + this.data.hotel.rating + ' STARS </div>' +
          '<div style="margin-bottom:10px;font-size:12px;">' + this.data.hotel.address||this.data.hotel.address.city + '</div>' +
          '<div style="margin-bottom:10px;"><img style="max-width:150px;max-height:150px;" src="' + img+ '"/></div>' +
          '<div>'+ this.translate.instant('PRICE_FROM') + ' ' + this.data.hotel.amount + '€' + '</div>' +
          '</div>',
      });
    } else {
      infoWindow = new google.maps.InfoWindow({
        content: '<div style="max-width:200px"><div style="margin-bottom:5px;">' + this.data.hotel.name + '</div>' +
          '</div>',
      });
    }
    const marker = new google.maps.Marker({
      // tslint:disable-next-line:max-line-length
      position: { lat: this.data.hotel.coordinates.latitude, lng: this.data.hotel.coordinates.longitude },
      // label: 'H',
      icon: '/assets/img/maps/hotel-v3.png',
      map: this.map,
      // label: labels[i % labels.length],
    });
    marker.addListener('click', () => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    /*
    marker.addListener('mouseout', () => {
      infoWindow.close();
      marker.setZIndex(1);
    });
    */
    /*
    marker.addListener('click', () => {
      this.quote(this.data.hotel.hotelCode);
      marker.setZIndex(1);
    });
    */
    // this.markers.push(marker);
  }

}
