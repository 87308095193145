import {Component, OnInit, Inject, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsersService} from '../../../../../services/profile/users.service';
import * as $ from 'jquery';
import {MainComponent} from '../../../../mainmodule/main/main.component';
import {AppComponent} from '../../../../../app.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpserviceService} from '../../../../../services/httpservice.service';
import {UsersList} from '../../../../../classes/userlist';
import { TranslateService } from '@ngx-translate/core';
import {AddUserDialogComponent} from '../../../add-user-dialog/add-user-dialog.component';
import {AdmserviceService} from '../../../../../services/adminserv/admservice.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  title: string;
  recipient: any;
  message: string;
  sending: boolean;
  @Input() page: string;
  @Input() idcompany: string;
  displayedColumns = [];
  loading: boolean;
  usersList: UsersList;
  isList: boolean;
  currentpage: number;
  maxpages: number;
  // setQuery
  query: any;
  limit: number;
  skip: number;
  sort: string;
  order: boolean;
  progress: boolean;
  text: string;
  selection = [];
  pagenumbers = [];
  duplicated = [];
  cloned = [];
  constructor(
    private usersService: UsersService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public appcomponent: AppComponent,
    public maincomponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpserviceService,
    private translate: TranslateService,
    private admService: AdmserviceService,
  ) {
    this.title = 'USERS_LIST';
    this.loading = true;
    this.progress = false;
    this.isList = true;
  }

  ngOnInit() {

    if (this.maincomponent.loggedUser.role.superAdmin) {
      this.displayedColumns = ['select', 'name', 'data', 'email', 'phone', 'company', 'activation', 'loginas'];
    } else {
      this.displayedColumns = ['name', 'data', 'email', 'phone', 'company'];
    }
    if (this.page === 'dashboard') {
      this.displayedColumns = ['name', 'company', 'email'];
    }
    if (this.page === 'company' && !this.maincomponent.loggedUser.role.superAdmin) {
      this.displayedColumns = ['name', 'data', 'email', 'phone'];
    }
    if (this.page === 'company' && this.maincomponent.loggedUser.role.superAdmin) {
      this.displayedColumns = ['select', 'name', 'data', 'email', 'phone', 'loginas'];
    }
    if (this.page !== 'widget') {
      this.limit = 10;
    } else {
      this.limit = 10;
    }
    this.sort = 'email';
    this.order = true;
    this.currentpage = 0;
    this.skip = (this.currentpage) * this.limit;

    this.query = {
      options: {
        viewAll: false,
      },
      sort: this.sort,
      limit: this.limit,
      skip: this.skip,
      order: this.order ? 'asc' : 'desc',
      text: '',
    };
    if (!this.page) {
      this.route.queryParams.subscribe(params => {
        this.query.sort = params.sort || this.sort;
        this.query.limit = params.limit || this.limit;
        this.query.skip = params.skip || this.skip;
        this.query.order = params.order || 'asc';
        this.query.text = params.text || '';
        this.text = params.text || '';
        this.currentpage = this.query.skip / this.query.limit;
        this.getUserList();
      });
    } else {
      this.navigate();
    }
  }

  getUserList(): void {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.usersService.getUsersList(this.query).subscribe((res: UsersList) => {
      this.loading = false;

      this.usersList = res;
      setTimeout(() => {
        this.progress = false;
      }, 500);
      if (!this.page) {
        this.scrollTop();
      }
      console.log('this.usersList => ', this.usersList);
      this.maxpages = Math.ceil((this.usersList.count / this.limit));
      if (this.maxpages === 0) {
        this.maxpages = 1;
      }
      for (let i = 0; i < this.usersList.list.length; i++) {
        // tslint:disable-next-line:max-line-length
        this.usersList.list[i].image = this.httpService.setApiUrlImage() + 'static/user/' + this.usersList.list[i]._id + '.png?time=' + new Date().getTime();
      }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  scrollTop(): void {
    $('.tablelist').animate({scrollTop: 0}, 400);
    $('#content_main').animate({scrollTop: 0}, 400);
  }

  selectValue(id) {
    let exist = false;
    for (let i = 0; i < this.selection.length; i++) {
      if (id === this.selection[i]) {
        exist = true;
        this.selection.splice(i, 1);
      }
    }
    if (!exist) {
      this.selection.push(id);
    }
    console.log('this.selection => ', this.selection);
  }

  isSelected(id) {
    for (let i = 0; i < this.selection.length; i++) {
      if (id === this.selection[i]) {
        return true;
      }
    }
  }

  isAllSelected() {
    return this.selection.length === this.usersList.list.length;
  }

  someSelected() {
    return this.selection.length > 0 && this.selection.length < this.usersList.list.length;
  }

  selectOnAll() {
    if (this.selection.length === this.usersList.list.length && !this.findLogged()) {
      this.selection = [];
    } else if (this.findLogged() && this.selection.length === this.usersList.list.length - 1) {
      this.selection = [];
    } else {
      this.selection = [];
      for (let i = 0; i < this.usersList.list.length; i++) {
        if ( this.usersList.list[i]._id !== this.maincomponent.loggedUser._id) {
          this.selection.push(this.usersList.list[i]._id);
        }
      }
    }
  }

  findLogged() {
    return this.usersList.list.find(r => r._id === this.maincomponent.loggedUser._id);
  }

  next() {
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev() {
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value) {
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  searchName() {
    this.query.text = this.text;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
    // this.getUserList()
  }

  sortResults(value) {
    if (this.query.sort === value) {
      this.order = ! this.order;
      this.query.order = this.order ? 'asc' : 'desc';
    }
    this.query.sort = value;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
    // this.getUserList();
  }

  navigate() {
    this.selection = [];
    if (this.page) {
      if (this.page !== 'company') {
        this.getUserList();
      } else {
        this.getUserListCp();
      }
    } else {
      this.router.navigate([], {
        queryParams: this.query,
      });
    }
  }

  getUserListCp(): void {

    console.log('this.query => ', this.query);
    // this.loading = true;
    this.progress = true;
    this.usersService.getUsersListCp(this.query, this.idcompany).subscribe((res) => {
      this.loading = false;

      this.usersList = res;
      setTimeout(() => {
        this.progress = false;
      }, 500);
      if (!this.page) {
        this.scrollTop();
      }
      console.log('this.usersList => ', this.usersList);
      this.maxpages = Math.ceil((this.usersList.count / this.limit));
      if (this.maxpages === 0) {
        this.maxpages = 1;
      }
      for (let i = 0; i < this.usersList.list.length; i++) {
        // tslint:disable-next-line:max-line-length
        this.usersList.list[i].image = this.httpService.setApiUrlImage() + 'static/user/' + this.usersList.list[i]._id + '.png?time=' + new Date().getTime();
      }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  findClone(){
    this.usersList.list.forEach(u => {
      this.cloned.forEach(c => {
        if (u.email.toLowerCase() === c.email.toLowerCase()){
          const find = this.duplicated.findIndex(d => d.email.toLowerCase() === u.email.toLowerCase());
          if (find == -1){
            let user = {
              id: '',
              name:'',
              surname:'',
              email: '',
              created: ''
            }
            if(new Date(c.createdAt).getTime() < new Date(u.createdAt).getTime()){
              user.id = c._id;
              user.name = c.name;
              user.surname = c.surname;
              user.email = c.email;
              user.created = c.createdAt;
            } else {
              user.id = u._id;
              user.name = u.name;
              user.surname = u.surname;
              user.email = u.email;
              user.created = u.createdAt;
            }
            this.duplicated.push(user)
          }

        }
      });

      if(this.currentpage<(this.maxpages-1)){
        console.log('this.duplicated => ', this.duplicated);
        this.next();
      }

    });
  }


  openMessage(user) {
    this.message = '';
    this.recipient = user;
  }

  toggleActivation(user) {
    user.active = ! user.active;
    this.openSnackBar(user);
  }

  openSnackBar(user) {
    const message = ((user.active) ? 'User enabled' : 'User disabled');
    this.snackBar.open(message, 'ok', {
      duration: 2000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  sendMessage(message) {
    this.sending = true;
    setTimeout(() => {
      this.sending = false;
      $('#emailModal').modal('hide');
      this.snackBar.open('Message send', 'ok', {
        duration: 2000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }, 1000);
  }

  deleteUser(id) {
    this.usersService.deleteUser(id).subscribe((res) => {
      this.getUserList();
      this.snackBar.open(this.translate.instant('USER_UPDATED'), 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  enableUser(id, user) {
    this.usersService.updateUser(id, user).subscribe((res) => {
      this.getUserList();
      this.snackBar.open(this.translate.instant('USER_UPDATED'), 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  deleteSelected() {
    for (let i = 0; i < this.selection.length; i++) {
      this.sequenceDelete(this.selection[i]);
    }
    this.snackBar.open(this.translate.instant('USER_UPDATED'), 'Ok', {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
    setTimeout(() => {
      if(this.idcompany){
        this.getUserListCp();
      } else {
        this.getUserList();
      }
    }, 500);
  }

  sequenceDelete(id) {
    this.usersService.deleteUser(id).subscribe((res) => {

    }, error => {

    });
  }

  loginAs(element) {
    const body = {
      email: element.email,
      id: element._id,
      name: element.name,
      surname: element.surname,
    };
    this.usersService.loginAs(body).subscribe(response => {

      localStorage.setItem('prev_fl_profile', localStorage.getItem('fl_profile'));
      localStorage.setItem('prev_fl_access_token', localStorage.getItem('fl_access_token'));

      localStorage.setItem('fl_profile', JSON.stringify(response.profile));
      localStorage.setItem('fl_access_token', response.token);

      this.maincomponent.setUserAs();

      this.router.navigate(['/dashboard']);
    }, error => {
      console.error('Error => ', error);
    });
  }

  openDialogNewUser() {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      data:{
        company: this.idcompany ? this.idcompany : this.maincomponent.loggedUser.company._id,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result);
        this.addUser(result);
      }
    });

  }

  addUser(user){
    this.admService.addProfileUser(user).subscribe((res) => {
      this.loading = false;
      this.snackBar.open(this.translate.instant('USER_ADDED'), 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
      if(this.idcompany){
        this.getUserListCp();
      } else {
        this.getUserList();
      }

    },error => {
      this.loading = false;
      console.error('Error => ', error);
      this.snackBar.open(error, 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    });
  }
}
