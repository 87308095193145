<hr/>
<h4>{{'LOYALTY'|translate}}</h4>
<div class="row mb-2" *ngFor="let lc of traveller.loyaltyCodes; let i = index">
  <div class="col-2">
    <mat-form-field class="full-width">
      <mat-label>{{'PRODUCT'|translate}}</mat-label>
      <mat-select [(ngModel)]="lc.product">
        <mat-option *ngFor="let prod of loyaltyProducts" [value]="prod">{{ prod | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field class="full-width">
      <mat-label>{{'TYPE'|translate}}</mat-label>
      <mat-select [(ngModel)]="lc.type">
          <mat-option *ngFor="let loyaltyType of getLoyaltyTypes(lc.product, lc.type)" [value]="loyaltyType.type">{{loyaltyType.type | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-2">
    <mat-form-field class="full-width">
      <mat-label>{{'ID'|translate}}</mat-label>
      <input matInput [(ngModel)]="lc.code">
    </mat-form-field>
  </div>
  <button mat-icon-button color="warn" (click)="delete(i)">
    <mat-icon>close</mat-icon>
  </button>
</div>
<button mat-icon-button (click)="add()">
  <mat-icon>add</mat-icon>
</button>
