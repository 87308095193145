import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpserviceService } from '../../../../services/httpservice.service';
import { MainComponent } from '../../../mainmodule/main/main.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  title: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpserviceService,
    private translate: TranslateService,
    public maincomponent: MainComponent,
  ) {
    this.title = 'NOTIFICATIONS';
  }

  ngOnInit() {
  }

}
