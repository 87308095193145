import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import {HttpserviceService} from '../../../services/httpservice.service';
import {Router} from '@angular/router';
import {catchError, retry} from 'rxjs/operators';
import { SnackErrorComponent } from '../../errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root'
})
export class UserGroupsService extends HttpserviceService {

  groups: any;

  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
    this.groups = this.getGroups();
  }

  getGroups(): Observable<any> {
    const getGroupsUrl = this.apiUrl + 'policy/groups';
    return this.http
      .get<any>(getGroupsUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }
  /*
  assignGroup(payload: {group: string, user: string}): Observable<any> {
    return this.http.post(`${ this.apiUrl }policy/groups/assign`, payload);
  }*/

  assignGroup(body): Observable<any> {
    const assignGroupUrl = this.apiUrl + 'policy/groups/assign';
    return this.http
      .post<any>(assignGroupUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }
}
