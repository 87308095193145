<div>
  <div class="text-right">
    <button mat-icon-button (click)="closeModal()"><mat-icon>close</mat-icon></button>
  </div>
  <hr/>
  <form autocomplete="off">

    <h4>{{'TRAVELER_DETAILS'|translate}} <span style="font-size:12px;" *ngIf="newTraveler.type">({{newTraveler.type|translate}})</span></h4>
    <div class="row">
      <div class="col-6 mb-2">
        <mat-form-field class="full-width">
          <mat-label>{{'NAME'|translate}}</mat-label>
          <input matInput [disabled]="fromReservation" [ngModelOptions]="{standalone:true}" [(ngModel)]="newTraveler.name"
                 (ngModelChange)="suggestion($event)" required>
          <mat-icon matSuffix matTooltip="{{'NAME_VALIDATION_TEXT'|translate}}">help_outline</mat-icon>
          <mat-hint class="text-danger" *ngIf="newTraveler.name.length > 0 && !namesControll(newTraveler.name)">
            {{'NOT_VALID_FIELD'|translate}}
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-6 mb-2">
        <mat-form-field class="full-width">
          <mat-label>{{'SURNAME'|translate}}</mat-label>
          <input matInput [disabled]="fromReservation" [ngModelOptions]="{standalone:true}" [(ngModel)]="newTraveler.surname"
                 (ngModelChange)="suggestion($event)" required>
          <mat-icon matSuffix matTooltip="{{'NAME_VALIDATION_TEXT'|translate}}">help_outline</mat-icon>
          <mat-hint class="text-danger" *ngIf="newTraveler.surname.length > 0 && !namesControll(newTraveler.surname)">
            {{'NOT_VALID_FIELD'|translate}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>{{'PHONE'|translate}}</mat-label>
          <input matInput [disabled]="fromReservation" [ngModelOptions]="{standalone:true}" [(ngModel)]="newTraveler.phoneNumber" [required]="passPort"
                 (ngModelChange)="phoneChange($event)">
          <mat-hint>Sono accettati solo numeri</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>{{'EMAIL'|translate}}</mat-label>
          <input matInput [disabled]="fromReservation" [ngModelOptions]="{standalone:true}" [(ngModel)]="newTraveler.email" [required]="passPort">
          <mat-hint class="text-danger" *ngIf="passPort">{{'MANDATORY_FIELD'|translate}}</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field class="full-width mb-2">
          <mat-label>{{'BIRTHDAY'|translate}}</mat-label>
          <input [required]="newTraveler.type === 'CHD' || newTraveler.type === 'INF' || passPort" matInput (dateChange)="dateEvent($event)"
                 [matDatepicker]="piker" [max]="today" (click)="piker.open()" readonly [ngModelOptions]="{standalone:true}" [(ngModel)]="newTraveler.birthday">
          <mat-datepicker-toggle matSuffix [for]="piker"></mat-datepicker-toggle>
          <mat-datepicker startView="multi-year" [startAt]="newTraveler.birthday || startDate" touchUi #piker></mat-datepicker>
          <mat-hint class="text-danger" *ngIf="!newTraveler.birthday && (newTraveler.type === 'CHD' || newTraveler.type === 'INF' || passPort)">
            {{'MANDATORY_FIELD'|translate}}
          </mat-hint>
        </mat-form-field>

        <div class="mb-2" *ngIf="!newTraveler.type && newTraveler.birthday">
          {{'AGE'|translate}} {{calcAge()}} | {{'TYPE'|translate}} {{newTraveler.type|translate}}
        </div>
        <div class="mb-2 text-danger" *ngIf="ageError">
          {{'AGE_ERROR'|translate}} | {{'PLEASE_INSERT'|translate}} {{newTraveler.type|translate}}
        </div>

      </div>
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>{{'SEX'|translate}}</mat-label>
          <mat-select [ngModelOptions]="{standalone:true}" [(ngModel)]="newTraveler.sex"
                      [required]="newTraveler.type === 'CHD' || newTraveler.type === 'INF' || passPort">
            <mat-option value="M">{{'MALE'|translate}}</mat-option>
            <mat-option value="F">{{'FEMALE'|translate}}</mat-option>
            <mat-option value="X">{{'NOT_SPECIFIED'|translate}}</mat-option>
            <mat-option value="U">{{'UNKNOW'|translate}}</mat-option>
          </mat-select>
          <mat-hint class="text-danger" *ngIf="!newTraveler.sex && (newTraveler.type === 'CHD' || newTraveler.type === 'INF' || passPort)">
            {{'MANDATORY_FIELD'|translate}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <hr/>
    <h4>{{'PASSPORT'|translate}}</h4>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="full-width mb-2">
          <mat-label>{{'PASSPORT_NUMBER'|translate}}</mat-label>
          <input matInput [disabled]="fromReservation" [ngModelOptions]="{standalone:true}" [(ngModel)]="newTraveler.document.number">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="full-width mb-2">
          <mat-label>{{'EXPIRY'|translate}}</mat-label>
          <input matInput [matDatepicker]="pikerExp" [min]="today" (click)="pikerExp.open()" readonly [ngModelOptions]="{standalone:true}"
                 [(ngModel)]="newTraveler.document.expiryDate">
          <mat-datepicker-toggle matSuffix [for]="pikerExp"></mat-datepicker-toggle>
          <mat-datepicker startView="multi-year" [startAt]="today" touchUi #pikerExp></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <!--
        <mat-form-field class="full-width mb-2">
          <mat-label>{{'NATIONALITY'|translate}}</mat-label>
          <input matInput [disabled]="fromReservation" [ngModelOptions]="{standalone:true}" [(ngModel)]="newTraveler.document.nationality">
        </mat-form-field>
        -->
        <mat-form-field class="full-width mb-2">
          <mat-label>{{'NATIONALITY'|translate}}</mat-label>
          <mat-select [disabled]="fromReservation" [ngModelOptions]="{standalone:true}" [(ngModel)]="newTraveler.document.nationality">
            <mat-option [value]="null">-- {{'NATIONALITY'|translate}} --</mat-option>
            <mat-option *ngFor="let country of countriesSl.countries" [value]="country.code">{{country.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <!--
        <mat-form-field class="full-width mb-2">
          <mat-label>{{'ISSUING_COUNTRY'|translate}}</mat-label>
          <input matInput [disabled]="fromReservation" [ngModelOptions]="{standalone:true}" [(ngModel)]="newTraveler.document.issuingCountry">
        </mat-form-field>
        -->
        <mat-form-field class="full-width mb-2">
          <mat-label>{{'ISSUING_COUNTRY'|translate}}</mat-label>
          <mat-select [disabled]="fromReservation" [ngModelOptions]="{standalone:true}" [(ngModel)]="newTraveler.document.issuingCountry">
            <mat-option [value]="null">-- {{'ISSUING_COUNTRY'|translate}} --</mat-option>
            <mat-option *ngFor="let country of countriesSl.countries" [value]="country.code">{{country.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </form>
  <div class="suggestionContainer mb-2" *ngIf="suggestionList && suggestionList.list">
    <div class="mb-2">{{'SUGGESTIONS_FOUNDED'|translate}}: {{suggestionList.count|number}}</div>
    <div>
      <div class="singleSuggestion" (click)="selectSuggested(pax)" *ngFor="let pax of suggestionList.list;let p = index">
        {{pax.name}} {{pax.surname}}
      </div>
    </div>
  </div>
  <hr/>
  <div class="buttonrow text-right">
    <button mat-stroked-button (click)="closeModal()">{{'CLOSE'|translate}}</button>
    <button mat-stroked-button [disabled]="travelerController() || !namesControll(newTraveler.name) || !namesControll(newTraveler.surname)"
            (click)="saveTraveler()">
      {{'SAVE'|translate}}
    </button>
  </div>
</div>
