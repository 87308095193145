import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {OpenticketComponent} from '../../ticketing/components/openticket/openticket.component';
import {TkbottomsheetComponent} from '../../ticketing/components/tkbottomsheet/tkbottomsheet.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Router} from '@angular/router';
import {DossierService} from '../../../services/dossier/dossier.service';
import {MainComponent} from '../../mainmodule/main/main.component';
import {CustomAuthService} from '../../../services/customAuth/custom-auth.service';
import {FlightService} from '../../../services/flight/flight.service';
import {CarService} from '../../../services/car/car.service';
import {StatisticService} from '../../../services/statistic/statistic.service';
import {AppComponent} from '../../../app.component';
import {HelpdialogComponent} from './helpdialog/helpdialog.component';
import {MatDialog} from '@angular/material/dialog';
import { TrainService } from '../../trains/train.service';
import { HotelService } from '../../hotel/hotel.service';

@Component({
  selector: 'app-dashboardcp',
  templateUrl: './dashboardcp.component.html',
  styleUrls: ['./dashboardcp.component.scss']
})
export class DashboardcpComponent implements OnInit {
  newDossier: string;
  activeFlights: number;
  percFlights: number;
  activeHotels: number;
  percHotels: number;
  activeCars: number;
  percCars: number;
  activeTrains: number;
  percTrains: number;
  activeDossier: number;
  totalCount: number;
  query: any;
  today = new Date();
  fromGeneral: Date = new Date(new Date().setDate(1));
  toGeneral: Date = new Date();
  generalStats: any;
  constructor(
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private dossierSerivice: DossierService,
    public maincomponent: MainComponent,
    public customAuth: CustomAuthService,
    private flightService: FlightService,
    private hotelService: HotelService,
    private carService: CarService,
    private trainService: TrainService,
    private statsService: StatisticService,
    public appComponent: AppComponent,
    public dialog: MatDialog
  ) {
    this.newDossier = '';
    this.query = {
      options: {
        tab: 'active',
        companies: [],
        users: [],
        carrier: '',
      },
      sort: 'createdAt',
      limit: 10,
      skip: 0,
      order: 'desc',
      text: '',
    };
  }

  ngOnInit() {
    if (this.maincomponent.loggedUser.role.company === false && (this.appComponent.isB2B || this.appComponent.isSbt)) {
      this.router.navigate(['/dossier/list']);
    }
    if (!this.customAuth.returnAuthTf(this.maincomponent.loggedUser._id)) {
      this.router.navigate(['/flight/search']);
    }
    if (this.maincomponent.loggedUser.company.services.flight) {
      this.getActiveFlights();
    }
    if (this.maincomponent.loggedUser.company.services.hotel && !this.maincomponent.appcomponent.isGat) {
      this.getActiveHotels();
    }
    if (this.maincomponent.loggedUser.company.services.car && !this.maincomponent.appcomponent.isGat) {
      this.getActiveCars();
    }
    if (this.maincomponent.loggedUser.company.services.train && !this.maincomponent.appcomponent.isGat) {
      this.getactiveTrains();
    }
    this.countTotal();
    this.getGeneralStats();
  }

  getGeneralStats(): void {
    const options = {
      from: this.fromGeneral.toISOString().substr(0,10),
      to: this.toGeneral.toISOString().substr(0,10),
      company: null,
    };
    this.statsService.getGeneral(JSON.stringify(options)).subscribe((res) => {
      this.generalStats = {};
      res.data.forEach(a => {
        this.generalStats[a._id] = {...a};
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  countTotal() {
    setTimeout(() => {
      this.totalCount = this.activeFlights + this.activeHotels + this.activeCars + this.activeTrains;
      this.percFlights = ((100 * this.activeFlights) / this.totalCount);
      this.percHotels = ((100 * this.activeHotels) / this.totalCount);
      this.percTrains = ((100 * this.activeTrains) / this.totalCount);
      this.percCars = ((100 * this.activeCars) / this.totalCount);
      console.log('this.totalCount => ', this.totalCount);
    }, 2500);
  }

  reverse() {
    $('.flip').toggleClass('reverse');
  }

  openBottomSheet() {
    this.bottomSheet.open(TkbottomsheetComponent, {
      data: {
        logged: this.maincomponent.loggedUser,
        isGat: this.appComponent.isGat,
        isSbt: this.appComponent.isSbt,
        isB2B: this.appComponent.isB2B,
      },
      // disableClose: true,
    });
  }
  /*
  // FUNZIONI SPOSTATE IN MAIN COMPONENT
  addDossierToggle() {
    $('#addDosier').modal('toggle');
  }

  createDossier() {
    const body = {
      flights: [],
      hotels: [],
      trains: [],
      cars: []
    };
    this.dossierSerivice.createDossier(body).subscribe((res) => {
      console.log('res => ', res);
      this.nameDossier(res._id);
    }, error => {
      console.error('res => ', error);
    });
  }

  nameDossier(id) {
    const body = {
      name: this.newDossier,
    };
    this.dossierSerivice.nameDossier(body, id).subscribe((res) => {
      console.log('res => ', res);
      this.router.navigate(['/dossier/list/' + id]);
      // this.navigateToDossier(id);
      this.addDossierToggle();
    }, error => {
      console.error('res => ', error);
    });
  }
  */

  getActiveFlights(): void {
    this.flightService.getReservationsList(this.query).subscribe((res) => {
      setTimeout(() => {
        this.activeFlights = res.count;
      }, 500);
    }, error => {
      console.error('Error => ', error);
    });
  }

  getActiveHotels(): void {
    this.hotelService.getReservationsList(this.query).subscribe((res) => {
      setTimeout(() => {
        this.activeHotels = res.count;
      }, 500);
    }, error => {
      console.error('Error => ', error);
    });
  }

  getActiveCars(): void {
    this.carService.getReservationsList(this.query).subscribe((res) => {
      setTimeout(() => {
        this.activeCars = res.count;
      }, 500);
    }, error => {
      console.error('Error => ', error);
    });
  }

  getactiveTrains(): void {
    this.trainService.getReservationList(this.query).subscribe((res) => {
      setTimeout(() => {
        for (let i = 0; i < res.tabs.length; i++) {
          if (res.tabs[i]._id === 'COMPLETED') {
            this.activeTrains = res.tabs[i].total;
          }
        }
      }, 500);
    }, error => {
      console.error('Error => ', error);
    });
  }

  openHelpDialog() {
    this.dialog.open(HelpdialogComponent, {
      data: {
        logged: this.maincomponent.loggedUser,
        isGat: this.appComponent.isGat,
        isSbt: this.appComponent.isSbt,
        isB2B: this.appComponent.isB2B,
        mainComponent: this.maincomponent,
      },
      // disableClose: true,
    });
    /*
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
    */
  }

}
