import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material/material.module';
import {FopsComponent} from './fops/fops.component';
import { MethodsComponent } from './methods/methods.component';
import { CreditcardrequestComponent } from './creditcardrequest/creditcardrequest.component';



@NgModule({
  declarations: [
    FopsComponent,
    MethodsComponent,
    CreditcardrequestComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
  ],
  exports: [
    FopsComponent,
    MethodsComponent,
    CreditcardrequestComponent
  ]
})
export class FopsModule { }
