import { Component, OnInit } from '@angular/core';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {AppComponent} from '../../../../app.component';
import { ActivatedRoute, Router } from '@angular/router';
import {Title} from '@angular/platform-browser';
import {CustomAuthService} from '../../../../services/customAuth/custom-auth.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import {UsersService} from '../../../../services/profile/users.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
// const {version} = require('../../../../../../package.json');
import * as pack from '../../../../../../package.json';
import * as $ from 'jquery';
import {MatDialog} from '@angular/material/dialog';
import {PolicyNotificationsComponent} from '../../../../components/policyNotifications/notifications.component';
import {StoreService} from '../../../../components/policyNotifications/service/store.service';
import {NotificationsService} from '../../../../components/policyNotifications/service/notifications.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  title: string;
  allActive: boolean = false;
  // version: string = version;
  app: any;
  version: any;
  upToDate: boolean;
  badge = this.storeService.notifications$;
  constructor(
    public mainComponent: MainComponent,
    public appComponent: AppComponent,
    private router: Router,
    private titleService: Title,
    public customAuth: CustomAuthService,
    private authService: AuthenticationService,
    private userservice: UsersService,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    private matDialog: MatDialog,
    private storeService: StoreService,
    private notificationsService: NotificationsService,
  ) {
    this.title = this.titleService.getTitle();
    this.app = pack;
    this.upToDate = true;
    this.version = this.app.version;
    setInterval(() => {
      this.checkAppVersion()
    }, 1*60*60*1000);
    this.notificationsService.getNotifications().pipe(take(1)).subscribe(result => {
      this.storeService.setNotifications(result.data.length > 0 ? result.data.length : 0);
    });
  }

  notificationList(): void {
    this.matDialog.open(PolicyNotificationsComponent, { width: '400px' });
  }

  checkAppVersion(): void{
      this.authService.getLatestVersion().subscribe(data => {
          this.version = data.version;
          this.upToDate = this.app.version === this.version;
      }, _ => this.upToDate = this.app.version === this.version)
  }

  openVersionModal(): void {
    this.upToDate = true;
    // $('#VersionModal').modal('show');
  }
  closeVersionModal(): void {
    this.upToDate = true;
    // $('#VersionModal').modal('hide');
  }
  reload():void {
    location.reload();
  }

  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    if (this.mainComponent.userSettings.useMarkup || this.mainComponent.userSettings.useMarkupHotel || this.mainComponent.userSettings.hidenego || this.mainComponent.userSettings.hideHotelnego) {
      this.allActive = true;
      this.startAnimation();
    }
  }

  logOut() {
    this.authService.logout();
  }
  goto(value) {
    this.router.navigate(['/' + value]);
  }

  updateSettings() {
    this.userservice.updateUserSettings(this.mainComponent.loggedUser._id, this.mainComponent.userSettings).subscribe((res) => {
      this.mainComponent.getUserSettings();
      this.snackBar.open(this.translate.instant('SETTINGS_UPDATED'), 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  removeMarkupFl(): void {
    this.mainComponent.userSettings.useMarkup = false;
    this.updateSettings();
  }
  removeHideNegoFl(): void {
    this.mainComponent.userSettings.hidenego = false;
    this.updateSettings();
  }
  removeMarkupHt(): void {
    this.mainComponent.userSettings.useMarkupHotel = false;
    this.updateSettings();
  }
  removeHideNegoHT(): void {
    this.mainComponent.userSettings.hideHotelnego = false;
    this.updateSettings();
  }

  startAnimation(): void {
    setInterval(() => {
      $('.toAnimateIcon').toggleClass('animateMarkup');
    }, 2500);
  }

  toggleAll(): void {
    this.allActive = ! this.allActive;
    if (this.allActive) {
      this.mainComponent.userSettings.useMarkup = true;
      this.mainComponent.userSettings.hidenego = true;
      this.mainComponent.userSettings.useMarkupHotel = true;
      this.mainComponent.userSettings.hideHotelnego = true;
    } else {
      this.mainComponent.userSettings.useMarkup = false;
      this.mainComponent.userSettings.hidenego = false;
      this.mainComponent.userSettings.useMarkupHotel = false;
      this.mainComponent.userSettings.hideHotelnego = false;
    }
    this.updateSettings();
  }

}
