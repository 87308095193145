import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'alerts',
  pure: false
})
export class AlertsPipe implements PipeTransform {

  transform(value: any[], ...args: unknown[]): string {
    /**
     * Get array of alert messages and transform them into one string
     */
    let message = '';
    if (value) {
      value.map(nestedValue => {
        nestedValue.map(alert => {
          message = message.concat(alert.message + '\n');
        });
      });
    }
    if (message.length === 0) {
      message = 'No restrictions';
    }
    return message;
  }

}
