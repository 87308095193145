<div class="form-header-container">
  <button class="iconButtons" mat-icon-button color="primary" routerLink="/allgroups">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <div>Create Group</div>
</div>
<div class="container">
  <div class="card">
    <div class="padding20">
      <app-role-form [loading]="loading" (submitted)="handleSubmit($event)"></app-role-form>
    </div>
  </div>
</div>

