<h2 mat-dialog-title>
  {{'FARE_RULES'|translate}}
  <span *ngFor="let class of data.tariff.details;let l = last;">
    <span> {{class.name||class.tariffClass}}</span> <span *ngIf="!l"> + </span>
  </span>
</h2>
<mat-dialog-content class="mat-typography">
  <div *ngIf="!data.loggedUser.role.superAdmin">
    <mat-tab-group (selectedTabChange)="tabChanged($event)">
      <mat-tab *ngFor="let fare of data.tariff.details; let i = index;">
        <ng-template mat-tab-label>{{fare.tariffClass}}</ng-template>
        <div class="padding10" *ngIf="loadingFare">
          <div>
            <div class="mb-2">{{'LOADING'|translate}}</div>
            <mat-progress-bar mode="buffer"></mat-progress-bar>
          </div>
        </div>
        <div class="padding10" *ngIf="rules && !loadingFare">
          <div [innerHTML]="toText(rules)"></div>
        </div>
        <!--
        <div class="padding10">
          <div *ngIf="loadingFare">
            <div class="mb-2">{{'LOADING'|translate}}</div>
            <mat-progress-bar mode="buffer"></mat-progress-bar>
          </div>
          <div *ngIf="!loadingFare && rules[i]">
            <mat-tab-group>
              <mat-tab *ngFor="let rule of rules[i]">
                <ng-template mat-tab-label>{{rule.title}}</ng-template>
                <div class="pt-4">
                  <div class="roleTitle">{{rule.title}}</div>
                  <hr/>
                  <div [innerHTML]="toText(rule.text)"></div>
                </div>

              </mat-tab>
            </mat-tab-group>
          </div>
        </div> -->
      </mat-tab>
    </mat-tab-group>
  </div>


  <div *ngIf="data.loggedUser.role.superAdmin">
    <div class="padding10" *ngIf="loadingFare">
      <div>
        <div class="mb-2">{{'LOADING'|translate}}</div>
        <mat-progress-bar mode="buffer"></mat-progress-bar>
      </div>
    </div>
    <div *ngIf="!loadingFare && rules">
      <div class="text-right">
        <mat-slide-toggle [(ngModel)]="showFullNew">Show Full Response</mat-slide-toggle>
      </div>
      <mat-tab-group *ngIf="showFull">
        <mat-tab *ngFor="let rule of rules; let l = last" [label]="rule.descripionCategory|translate">
          <div class="mb-3"></div>

          <div [ngClass]="{'mb-3': rule.dateInfo[i+1] && rule.dateInfo[i+1].textType != single.textType}" *ngFor="let single of rule.dateInfo; let i = index; let l = last">
            <!-- <div *ngIf="i == 0"><h4 class="minititle">{{'dateInfo'|translate}}</h4></div> -->
            <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.dateInfo[i-1] && rule.dateInfo[i-1].textType != single.textType)">{{single.textType|translate}}</div>
            <div [innerHTML]="toTextMini(single.freeText)"></div>
            <!--<div class="pt-1 pl-2 small mb-3" *ngIf="single.infoType">{{single.infoType}}: {{rule.numberCategory+single.infoType|translate}}</div>-->
            <hr *ngIf="l"/>
          </div>
          <!--
          <div [ngClass]="{'mb-3': rule.mnrRestriAppLoc[i+1] && rule.mnrRestriAppLoc[i+1].textType != single.textType}" *ngFor="let single of rule.mnrRestriAppLoc let i = index; let l = last">
            <div *ngIf="i == 0"><h4 class="minititle">{{'mnrRestriAppLoc'|translate}}</h4></div>
            <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.mnrRestriAppLoc[i-1] && rule.mnrRestriAppLoc[i-1].textType != single.textType)">{{single.textType|translate}}</div>
            <div [innerHTML]="toTextMini(single.freeText)"></div>
            <hr *ngIf="l"/>
          </div>
          -->

          <div [ngClass]="{'mb-3': rule.mnrMonLoc[i+1] && rule.mnrMonLoc[i+1].textType != single.textType}" *ngFor="let single of rule.mnrMonLoc let i = index; let l = last">
            <!-- <div *ngIf="i == 0"><h4 class="minititle">{{'mnrMonLoc'|translate}}</h4></div> -->
            <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.mnrMonLoc[i-1] && rule.mnrMonLoc[i-1].textType != single.textType)">{{single.textType|translate}}</div>
            <div [innerHTML]="toTextMini(single.freeText)"></div>
            <!--<div class="pt-1 pl-2 small mb-3" *ngIf="single.infoType">{{single.infoType}}: {{rule.numberCategory+single.infoType|translate}}</div>-->
            <hr *ngIf="l"/>
          </div>



          <div *ngIf="rule.noCategoryRestriction">
            <div class="mb-2">

              <div>{{rule.noCategoryRestriction.freeText}}</div>
            </div>
          </div>

        </mat-tab>

      </mat-tab-group>
    </div>


    <div *ngIf="!loadingFare && originalRulesResponse && !showFullNew">
      <mat-tab-group *ngFor="let pricing of originalRulesResponse.pricingRecord; let la = last">

        <mat-tab *ngFor="let rule of pricing; let l = last" [label]="rule.descripionCategory|translate">
          <!-- {{pricing|json}} -->
          <div class="mb-3"></div>

          <div [ngClass]="{'mb-3': rule.dateInfo[i+1] && rule.dateInfo[i+1].textType != single.textType}" *ngFor="let single of rule.dateInfo; let i = index; let l = last">

            <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.dateInfo[i-1] && rule.dateInfo[i-1].textType != single.textType)">{{single.textType|translate}}</div>
            <div [innerHTML]="toTextMini(single.freeText)"></div>

            <hr *ngIf="l"/>
          </div>

          <div [ngClass]="{'mb-3': rule.mnrMonLoc[i+1] && rule.mnrMonLoc[i+1].textType != single.textType}" *ngFor="let single of rule.mnrMonLoc let i = index; let l = last">

            <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.mnrMonLoc[i-1] && rule.mnrMonLoc[i-1].textType != single.textType)">{{single.textType|translate}}</div>
            <div [innerHTML]="toTextMini(single.freeText)"></div>

            <hr *ngIf="l"/>
          </div>
          <div *ngIf="rule.noCategoryRestriction">
            <div class="mb-2">

              <div>{{rule.noCategoryRestriction.freeText}}</div>
            </div>
          </div>

        </mat-tab>

      </mat-tab-group>
    </div>

    <div *ngIf="!loadingFare && originalRulesAmaResponse && showFullNew">
      <mat-tab-group *ngFor="let pricing of originalRulesAmaResponse.pricingRecord; let la = last">

        <mat-tab *ngFor="let rule of pricing; let l = last" [label]="rule.descripionCategory|translate">
          <!-- {{pricing|json}} -->
          <div class="mb-3"></div>

          <div [ngClass]="{'mb-3': rule.dateInfo[i+1] && rule.dateInfo[i+1].textType != single.textType}" *ngFor="let single of rule.dateInfo; let i = index; let l = last">
            <div *ngIf="i == 0"><h4 class="minititle">{{'dateInfo'|translate}}</h4></div>
            <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.dateInfo[i-1] && rule.dateInfo[i-1].textType != single.textType)">{{single.textType|translate}}</div>
            <div [innerHTML]="toTextMini(single.freeText)"></div>
            <div class="pt-1 pl-2 small mb-3" *ngIf="single.infoType">{{single.infoType}}: {{rule.numberCategory+single.infoType|translate}}</div>
            <hr *ngIf="l"/>
          </div>

          <div [ngClass]="{'mb-3': rule.mnrRestriAppLoc[i+1] && rule.mnrRestriAppLoc[i+1].textType != single.textType}" *ngFor="let single of rule.mnrRestriAppLoc let i = index; let l = last">
            <div *ngIf="i == 0"><h4 class="minititle">{{'mnrRestriAppLoc'|translate}}</h4></div>
            <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.mnrRestriAppLoc[i-1] && rule.mnrRestriAppLoc[i-1].textType != single.textType)">{{single.textType|translate}}</div>
            <div [innerHTML]="toTextMini(single.freeText)"></div>
            <hr *ngIf="l"/>
          </div>


          <div [ngClass]="{'mb-3': rule.mnrMonLoc[i+1] && rule.mnrMonLoc[i+1].textType != single.textType}" *ngFor="let single of rule.mnrMonLoc let i = index; let l = last">
            <div *ngIf="i == 0"><h4 class="minititle">{{'mnrMonLoc'|translate}}</h4></div>
            <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.mnrMonLoc[i-1] && rule.mnrMonLoc[i-1].textType != single.textType)">{{single.textType|translate}}</div>
            <div [innerHTML]="toTextMini(single.freeText)"></div>
            <div class="pt-1 pl-2 small mb-3" *ngIf="single.infoType">{{single.infoType}}: {{rule.numberCategory+single.infoType|translate}}</div>
            <hr *ngIf="l"/>
          </div>
          <div *ngIf="rule.noCategoryRestriction">
            <div class="mb-2">

              <div>{{rule.noCategoryRestriction.freeText}}</div>
            </div>
          </div>

        </mat-tab>

        <mat-tab label="JSON">
          <code>
            <pre>
              {{originalRulesResponse|json}}
            </pre>
          </code>
        </mat-tab>

      </mat-tab-group>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'CLOSE'|translate}}</button>
</mat-dialog-actions>
