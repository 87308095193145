import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FlightService } from '../../../../../../services/flight/flight.service';

@Component({
  selector: 'app-carriers',
  templateUrl: './carriers.component.html',
  styleUrls: ['./carriers.component.scss'],
})
export class CarriersComponent implements OnInit{
  @Input() parentForm : any;
  text : string;
  carriers : any;
  loading : boolean;

  constructor(
    private flightService : FlightService,
  ){
    this.loading = false;
    this.text = '';
  }

  ngOnInit() : void{
  }

  searchCarrier(value){
    delete this.carriers;
    if (value.length >= 2){
      this.getCarriersList(value);
    }
  }

  getCarriersList(value){
    delete this.carriers;
    this.flightService.getCarriersList(value).subscribe((res) => {
      this.loading = false;
      this.carriers = res;
      console.log('this.airports => ', this.carriers);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  clear(){
    this.text = '';
    delete this.carriers;
  }

  getCarrier(value){
    delete this.carriers;
    const index = value.indexOf('-');
    const iata = value.substr(0, index);
    this.parentForm.options.carrierIdentification.push(iata);
    this.text = '';
  }

  selectFirst(){
    if (this.carriers) {
      this.parentForm.options.carrierIdentification.push(this.carriers[0].iata);
      delete this.carriers;
      this.text = '';
    }
  }
}
