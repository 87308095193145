import {Component, Input, OnInit} from '@angular/core';
import {SearchRes} from '../../../../../../classes/flights/search-res';
import * as $ from 'jquery';
import {FlightService} from '../../../../../../services/flight/flight.service';
import {Tariffes} from '../../../../../../classes/flights/tariffes';
import {BottomsheetComponent} from '../bottomsheet/bottomsheet.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {NewTraveller} from '../../../../../../classes/travelers/newTraveller';
import {MainComponent} from '../../../../../mainmodule/main/main.component';
import {CompaniesService} from '../../../../../../services/profile/companies.service';
import {MatDialog} from '@angular/material/dialog';
import {IatacompleteService} from '../../../../../../services/iatacomplete/iatacomplete.service';
import {FarerulesComponent} from '../../farerules/farerules.component';
import {CurrentselectionComponent} from './currentselection/currentselection.component';
import {AppComponent} from '../../../../../../app.component';
import {animate, group, style, transition, trigger} from '@angular/animations';
import { TemplateDialogComponent } from '../searchresults/template-dialog/template-dialog.component';

@Component({
  selector: 'app-stepsearchresultscp',
  templateUrl: './stepsearchresults.component.html',
  styleUrls: ['./stepsearchresults.component.scss'],
  animations: [
    trigger('listItemAnimation', [
      transition(':enter', [
        style({ height: '0px', overflow: 'hidden' }),
        group([animate('250ms ease-out', style({ height: '!' }))]),
      ]),
      transition(':leave', [
        style({ height: '!', overflow: 'hidden' }),
        group([animate('250ms ease-out', style({ height: '0px' }))]),
      ]),
    ]),
  ],
})
export class StepsearchresultsComponent implements OnInit {
  @Input() searchResults: SearchRes;
  @Input() selections: any;
  @Input() request: any;
  steps: number;
  currentStep = 0;
  firstPrice: number;
  stepSelection = [];
  matchedRefs = [];
  loadingTar: boolean;
  currentReco: any;
  travelers: NewTraveller[];
  company: any;
  travel = [];
  formvisible: boolean;
  carriersAvailable: string[];
  carrierToFilter: string[];
  minPrice: number;
  maxPrice: number;
  maxPriceModel: number;
  minPriceModel: number;
  allPrice: number[];
  availableStops: any[];
  selectedStops: number;
  filterTime = ['no_preference', 'morning', 'afternoon', 'evening', 'night'];
  defaultFilter = 'no_preference';
  filterTimeMin: number;
  filterTimeMax: number;
  constructor(
    private flightService: FlightService,
    private companyService: CompaniesService,
    private bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private mainComponent: MainComponent,
    public iataComplete: IatacompleteService,
    public appComponent: AppComponent,
  ) {
    // this.steps = this.request.sections.length;
    this.travelers = [];
    this.travel = [];
    this.carriersAvailable = [];
    this.carrierToFilter = [];
    this.allPrice = [];
    this.minPrice = 0;
    this.maxPriceModel = 0;
    this.minPriceModel = 0;
    this.maxPrice = 0;
    this.availableStops = [];
    this.selectedStops = 0;
    this.filterTimeMin = 0;
    this.filterTimeMax = 24;
  }

  ngOnInit(): void {
    this.steps = this.request.sections.length;
    this.firstPrice = this.searchResults.recommendations[0].fare.amount;
    this.stepSelection.length = this.request.sections.length;

    for (let i = 0; i < this.request.passengers.adt; i++) {
      const traveler = new NewTraveller();
      traveler.type = 'ADT';
      this.travelers.push(traveler);
    }
    for (let i = 0; i < this.request.passengers.chd; i++) {
      const traveler = new NewTraveller();
      traveler.type = 'CHD';
      this.travelers.push(traveler);
    }
    for (let i = 0; i < this.request.passengers.inf; i++) {
      const traveler = new NewTraveller();
      traveler.type = 'INF';
      this.travelers.push(traveler);
    }

    this.getCompany();
    this.pushCarrier();

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.searchResults.itineraries.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let s = 0; s < this.searchResults.itineraries[i].sections.length; s ++) {
        const sect = this.searchResults.itineraries[i].sections[s];
        if (!this.availableStops.includes(sect.flights.length - 1)) {
          this.availableStops.push(sect.flights.length - 1);
        }
      }
    }
    this.availableStops.sort( (a, b) => b - a);
    this.selectedStops = this.availableStops[0];

    for (let r = 0; r < this.searchResults.recommendations.length; r++) {
      if (this.searchResults.recommendations[r].negotiated) {
        this.askNegoFares(this.searchResults.recommendations[r].ref, r);
      }
    }
  }

  askNegoFares(ref, r) {
    // tslint:disable-next-line:no-shadowed-variable
    const recommendation = JSON.parse(JSON.stringify(this.searchResults.recommendations.find(r => r.ref === ref)));
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      recommendation.itineraries[i] = this.searchResults.itineraries[i].sections[this.selections[ref][i] - 1];
    }
    console.log('recommendation =>', recommendation);
    recommendation.options = this.request.options;
    this.flightService.askFares(recommendation).subscribe((res: Tariffes[]) => {
      console.log('res => ', res);
      this.searchResults.recommendations[r].rates = res;
      for (let rate = 0; rate < this.searchResults.recommendations[r].rates.length; rate++) {
        if (rate === 0) {
          this.searchResults.recommendations[r].rates[rate].selected = true;
          this.searchResults.recommendations[r].tariff = this.searchResults.recommendations[r].rates[rate];
        } else {
          this.searchResults.recommendations[r].rates[rate].selected = false;
        }
      }
      this.searchResults.recommendations[r].fare = this.searchResults.recommendations[r].rates[0].fare;
      console.log('this.tariffes => ', res);
    }, error => {
      console.log('error => ', error);
    });
  }

  getCompany() {
    this.companyService.getCompany(this.mainComponent.loggedUser.company._id).subscribe((res) => {
      this.company = res;
    });
  }

  returnZeroTime(value) {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }

  opeSect(rec, it) {
    $('#collapse' + rec + it).collapse('toggle');
    $('#itinerary' + rec + it).collapse('toggle');
  }

  calculateFlightTime(departure, arrival) {
    departure = new Date(departure).getTime();
    arrival = new Date(arrival).getTime();
    const flightTime = arrival - departure;
    return this.returnTime(flightTime);
  }

  returnTime(t) {
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(t / cd);
    let h = Math.floor( (t - d * cd) / ch);
    let m = Math.round( (t - d * cd - h * ch) / 60000);
    const pad = ((n) => n < 10 ? '0' + n : n);
    if ( m === 60 ) {
      h++;
      m = 0;
    }
    if ( h === 24 ) {
      d++;
      h = 0;
    }
    if (d > 0) {
      return [d + 'g ' + pad(h) + 'h', pad(m) + 'm'].join(':');
    } else {
      return [pad(h) + 'h', pad(m) + 'm'].join(':');
    }

  }

  returnAmount(section, value) {
    let recommendation = this.searchResults.recommendations;
    [...this.stepSelection.filter(r => !!r), value].forEach((ref, i) => {
      recommendation = recommendation.filter(r => r.itineraries[i].sections.find(s => s.ref === ref));
    });
    if(section) {
      this.setAlters(section, value);
    }
    if (recommendation[0].negotiated) {
      if (this.maxPrice < recommendation[0].fare.publicAmount - recommendation[0].fare.fees) {
        this.maxPrice = Math.ceil(recommendation[0].fare.publicAmount - recommendation[0].fare.fees);
        this.maxPriceModel = Math.ceil(recommendation[0].fare.publicAmount - recommendation[0].fare.fees);
      }
      return recommendation[0].fare.publicAmount - recommendation[0].fare.fees;
    } else {
      return recommendation[0].fare.amount;
    }
  }

  setAlters(section, value) {
    let recommendation = this.searchResults.recommendations;
    [...this.stepSelection.filter(r => !!r), value].forEach((ref, i) => {
      recommendation = recommendation.filter(r => r.itineraries[i].sections.find(s => s.ref === ref));
    });
    if (recommendation[0].arrayOfAlerts && recommendation[0].arrayOfAlerts.length > 0) {
      section.arrayOfAlerts = recommendation[0].arrayOfAlerts;
    } else {
      section.arrayOfAlerts = [];
    }
  }

  returnNegoAmount(value) {
    let recommendation = this.searchResults.recommendations;
    [...this.stepSelection.filter(r => !!r), value].forEach((ref, i) => {
      recommendation = recommendation.filter(r => r.itineraries[i].sections.find(s => s.ref === ref));
    });
    return recommendation[0].fare;
  }

  returnAmountPlus(value) {
    let recommendation = this.searchResults.recommendations;
    [...this.stepSelection.filter(r => !!r), value].forEach((ref, i) => {
      recommendation = recommendation.filter(r => r.itineraries[i].sections.find(s => s.ref === ref));
    });
    if (recommendation[0].negotiated) {
      return Math.round((recommendation[0].fare.publicAmount - recommendation[0].fare.fees - this.firstPrice) * 100) / 100;
    } else {
      return Math.round((recommendation[0].fare.amount - this.firstPrice) * 100) / 100;
    }
  }

  returnNego(value) {
    let recommendation = this.searchResults.recommendations;
    [...this.stepSelection.filter(r => !!r), value].forEach((ref, i) => {
      recommendation = recommendation.filter(r => r.itineraries[i].sections.find(s => s.ref === ref));
    });
    return recommendation[0].negotiated;
  }

  otherday(value, other) {
    return value !== other;
  }

  select(value, section) {
    this.stepSelection[this.currentStep] = value;
    this.currentStep = this.currentStep + 1;
    console.log('this.stepSelection =>', this.stepSelection);
    if (this.currentStep > this.searchResults.itineraries.length - 1) {
      this.askFare();
    } else if (this.currentStep > 0) {
      // tslint:disable-next-line:max-line-length
      const matchRecom = this.searchResults.recommendations.filter(r => (r.itineraries[this.currentStep - 1].sections.find(s => (s.ref === value))));
      console.log('matchRecom =>', matchRecom);
      const itineraries = [];
      // tslint:disable-next-line:max-line-length
      matchRecom.forEach(r => r.itineraries[this.currentStep - 1].sections.find(s => (s.ref === value)).with.forEach(w => itineraries.push(w)));
      console.log('itineraries 2 =>', itineraries);
      this.matchedRefs = itineraries;
      this.filteredResults();
    }
    if (section) {
      this.travel.push(section);
      console.log('this.travel => ', this.travel);
    }
  }

  askFare() {
    delete this.currentReco;
    let recommendation = JSON.parse(JSON.stringify(this.searchResults.recommendations));
    this.stepSelection.forEach((ref, i) => {
      recommendation = recommendation.filter(r => {
        if (!r.itineraries[i]) {
          console.log('undefined iti', r);
        }
        return r.itineraries[i].sections.find(s => s.ref === ref);
      });
    });
    recommendation = recommendation[0];
    recommendation.rates = [];
    this.loadingTar = true;
    // tslint:disable-next-line:no-shadowed-variable
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      const secondIndex = this.stepSelection[i] - 1;
      recommendation.itineraries[i] = this.searchResults.itineraries[i].sections[secondIndex];
    }
    console.log('recommendation step =>', recommendation);
    recommendation.options = this.request.options;
    this.flightService.askFares(recommendation).subscribe((res: Tariffes[]) => {
      console.log('res => ', res);
      recommendation.rates = res;
      for (let rate = 0; rate < recommendation.rates.length; rate++) {
        if (rate === 0) {
          recommendation.rates[rate].selected = true;
          recommendation.tariff = recommendation.rates[rate];
        } else {
          recommendation.rates[rate].selected = false;
        }

      }
      this.currentReco = recommendation;
      this.loadingTar = false;
    }, error => {
      console.log('error => ', error);
      this.loadingTar = false;
    });
  }

  askRules(tariff) {
    let recommendation = JSON.parse(JSON.stringify(this.searchResults.recommendations));
    this.stepSelection.forEach((ref, i) => {
      recommendation = recommendation.filter(r => {
        if (!r.itineraries[i]) {
          console.log('undefined iti', r);
        }
        return r.itineraries[i].sections.find(s => s.ref === ref);
      });
    });
    recommendation = recommendation[0];
    // tslint:disable-next-line:no-shadowed-variable
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      const secondIndex = this.stepSelection[i] - 1;
      recommendation.itineraries[i] = this.searchResults.itineraries[i].sections[secondIndex];
    }
    const flights = [];
    recommendation.itineraries.forEach((iti) => {
      iti.flights.forEach((flight) => {
        flights.push({
          departure: flight.departure,
          arrival: flight.arrival,
          marketingCarrier: flight.marketingCarrier,
          operativeCarrier: flight.operativeCarrier,
          ref: flights.length + 1,
        });
      });
    });
    this.openDialogRules(tariff, flights);
  }

  openDialogRules(tariff, flights) {
    const dialogRef = this.dialog.open(FarerulesComponent, {
      data: {
        flights,
        tariff,
      },
      disableClose: true,
      width: '100%',
      maxWidth: '960px',
      // height: '100%',
      maxHeight: '900px',
    });
  }

  selectFare(f) {
    // tslint:disable-next-line:prefer-for-of
    for (let rate = 0; rate < this.currentReco.rates.length; rate++) {
      this.currentReco.rates[rate].selected = false;
    }
    this.currentReco.rates[f].selected = true;
    this.currentReco.fare = this.currentReco.rates[f].fare;
    this.currentReco.tariff = this.currentReco.rates[f];
  }

  back() {
    this.stepSelection.splice(this.currentStep - 1, 1);
    this.travel.splice(this.currentStep - 1, 1);
    this.currentReco = {};
    this.currentStep = this.currentStep - 1;
    if ( this.currentStep === 0) {
      this.matchedRefs = [];
      this.filteredResults();
    } else {
      this.currentStep = this.currentStep - 1;
      this.select(this.stepSelection[this.currentStep], undefined);
    }
  }

  filteredResults() {
    if (!this.matchedRefs || this.matchedRefs.length === 0) {
      return this.searchResults.itineraries[this.currentStep].sections;
    } else {
      return this.searchResults.itineraries[this.currentStep].sections.filter(r => this.matchedRefs.includes(r.ref));
    }
  }

  toggleFilters() {
    $('.filterSearch').toggleClass('openfilters');
    $('.filtersearchBackdrop').toggleClass('openfiltersearchBackdrop');
  }

  openBottomSheet(fare) {
    this.bottomSheet.open(BottomsheetComponent, {
      data: {fare},
    });
  }

  openCurrentSelection(travel) {
    this.bottomSheet.open(CurrentselectionComponent, {
      data: {travel},
      panelClass: 'bottomLarge'
    });
  }

  calculateNewFare(a, b, c) {
    return b + c;
  }

  openDialog() {
    console.log('this.travelers =>', this.travelers);
    this.currentReco.options = this.request.options;
    this.currentReco.company = this.company;
    console.log('this.selections =>', this.currentReco);
    const dialogRef = this.dialog.open(TemplateDialogComponent, {
      data: {
        request: this.request,
        recommendation: this.currentReco,
        passengers: this.travelers,
        searchResults: this.searchResults,
        selections: this.selections,
        dialog: this.dialog,
        passPort: this.iataComplete.returnCarrierOPTPassport(this.currentReco.marketingCarrier, this.currentReco.itineraries),
        mainComponent: this.mainComponent
      },
      disableClose: true,
      width: '100%',
      maxWidth: '960px',
      // height: '100%',
      maxHeight: '900px',

    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  toggleForm() {
    this.formvisible = ! this.formvisible;
    $('#searchCard').collapse('toggle');
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }

  pushCarrier() {
    for (const recommendation of this.searchResults.recommendations) {
      if (!this.carriersAvailable.includes(recommendation.marketingCarrier)) {
        this.carriersAvailable.push(recommendation.marketingCarrier);
      }
      this.allPrice.push(recommendation.fare.amount);
    }
    this.allPrice.sort((n1, n2) => n1 - n2);
    this.minPrice = Math.floor(this.allPrice[0]);
    this.minPriceModel = Math.floor(this.allPrice[0]);
    this.maxPriceModel = Math.ceil(this.allPrice[this.allPrice.length - 1]);
    this.maxPrice = Math.ceil(this.allPrice[this.allPrice.length - 1]);
    // tslint:disable-next-line:only-arrow-functions
  }
  filterCarrier(carrier) {
    if (!this.carrierToFilter.includes(carrier)) {
      this.carrierToFilter.push(carrier);
    } else {
      this.carrierToFilter = this.carrierToFilter.filter(r => r !== carrier);
    }
  }
  isIncludedCarr(carrier) {
    return this.carrierToFilter.includes(carrier);
  }

  isInCarrierFilter(carrier) {
    if (this.carrierToFilter.length === 0) {
      return true;
    } else {
      return this.carrierToFilter.includes(carrier);
    }
  }

  isInPriceRange(price) {
    if (price >= this.minPriceModel && price <= this.maxPriceModel) { return true; }
  }

  changeTimeFilter() {
    if (this.defaultFilter === 'no_preference') {
      this.filterTimeMin = 0;
      this.filterTimeMax = 24;
    } else if (this.defaultFilter === 'morning') {
      this.filterTimeMin = 6;
      this.filterTimeMax = 12;
    } else if (this.defaultFilter === 'afternoon') {
      this.filterTimeMin = 12;
      this.filterTimeMax = 18;
    } else if (this.defaultFilter === 'evening') {
      this.filterTimeMin = 18;
      this.filterTimeMax = 24;
    } else {
      this.filterTimeMin = 0;
      this.filterTimeMax = 6;
    }
    console.log('this.defaultFilter =>', this.defaultFilter);
  }

  isInFilterTime(section) {
    if (section.flights[0].departure.hours >= this.filterTimeMin && section.flights[0].departure.hours <= this.filterTimeMax) {
      return !(section.flights[0].departure.hours === this.filterTimeMax && section.flights[0].departure.minutes > 0);
    }
  }

  isInFilter(section) {
    // tslint:disable-next-line:max-line-length
    if (this.isInFilterTime(section) && this.isInCarrierFilter(section.flights[0].marketingCarrier) && this.isInPriceRange(this.returnAmount(null, section.ref))) {
      return true;
    }
  }

  returnNegoB2B(fare) {
    const margin = (fare.publicAmount - (fare.fees || 0));
    const percent = (margin * 20 / 100);
    //return fare.publicAmount - percent;
    return margin;
  }

  checkPolicy(tariff:any) :boolean {
    if(this.appComponent.isSbt && tariff.arrayOfAlerts?.length > 0){
      return tariff.arrayOfAlerts.every(a => a.block === true)
    }
    return false;
  }

}
