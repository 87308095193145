import {Injectable} from '@angular/core';
import {Notifications} from '../../classes/notifications';
import {HttpserviceService} from '../httpservice.service';

import {HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';
import {SnackErrorComponent} from '../../modules/errorNotification/snackerror/snack-error.component';
import {io} from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends HttpserviceService {
  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
  }

  getNotificationsList(data): Observable<Notifications> {
    // tslint:disable-next-line:max-line-length
    const notificationsListUrl = this.apiUrl + 'notification/list?limit=' + data.limit + '&skip=' + data.skip + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<Notifications>(notificationsListUrl, this.setHttpOptions())
      .pipe(
        retry(2),
        catchError(err => this.handleError(err))
      );
  }

  setAsRead(data) {
    const readNotificationUrl = this.apiUrl + 'notification/' + data + '/read';
    return this.http
      .post(readNotificationUrl, null, this.setHttpOptions())
      .pipe(
        retry(1),
        catchError(err => this.handleError(err))
      );
  }

  setAllAsRead() {
    const readAllNotificationUrl = this.apiUrl + 'notification/read';
    return this.http
      .post(readAllNotificationUrl, null, this.setHttpOptions())
      .pipe(
        retry(1),
        catchError(err => this.handleError(err))
      );
  }

  sendNotification(value): Observable<any> {
    const sendNotificationUrl = this.apiUrl + 'notification/create';
    return this.http
      .put<any>(sendNotificationUrl, value, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
}
