<div class="autonotificationlist" *ngIf="notificationType==='auto'">
  <div *ngIf="clonedAutoNotificationsList && clonedAutoNotificationsList.length>0" class="notificationcontainer">
    <div matRipple class="row rowbordered singlemessage notificationsingle" (click)="notificationAction(notification)"
         *ngFor="let notification of clonedAutoNotificationsList; let i = index;last as isLast"
         [ngClass]="{'unread':!notification.read,'noborder':isLast,'warning':notification.reason=='expiring'||notification.reason=='changes','info':notification.reason=='timeLimit','success':notification.reason=='new'}">
      <div class="col-2 nopaddingupdown">
        <div class="notificationIcon">
          <mat-icon *ngIf="notification.ref=='FlightPnr' || notification.ref=='FlightPnr_v2'">airplanemode_active</mat-icon>
          <mat-icon *ngIf="notification.ref=='Trial'">person</mat-icon>
          <mat-icon *ngIf="!notification.ref">chat</mat-icon>
          <mat-icon *ngIf="notification.ref=='TktTicket' && notification.reason=='new'">error</mat-icon>
          <mat-icon *ngIf="notification.ref=='TktTicket' && notification.reason=='userReply'">person</mat-icon>
        </div>
      </div>
      <div class="col-10 nopaddingupdown">
        <h6>
          {{notification.subject}}
        </h6>

        <div class="truncate preview" [innerHTML]="notification.body"></div>

        <p class="notificationdate">
          {{notification.createdAt|date:'dd/MM/yyyy - HH:mm'}}
        </p>
      </div>
    </div>
  </div>


  <div class="notificationsActions">
    <div *ngIf="clonedAutoNotificationsList && clonedAutoNotificationsList.length>0">
      <button mat-button class="small-md half" (click)="loadMore()">{{'LOAD_MORE'|translate}}</button>
      <button mat-button class="small-md half" (click)="setAllAsRead()">{{'SET_ALL_READ'|translate}}</button>
    </div>

  </div>
</div>

<div class="autonotificationlist" *ngIf="notificationType==='manual'">
  <div *ngIf="clonedManNotificationsList && clonedManNotificationsList.length>0" class="notificationcontainer">
    <div matRipple class="row rowbordered singlemessage notificationsingle" (click)="notificationAction(notification)"
         *ngFor="let notification of clonedManNotificationsList; let i = index;last as isLast"
         [ngClass]="{'unread':!notification.read,'noborder':isLast,'warning':notification.reason=='expiring'||notification.reason=='changes','info':notification.reason=='timeLimit','success':notification.reason=='new'}">
      <div class="col-2 nopaddingupdown">
        <div class="notificationIcon">
          <mat-icon *ngIf="notification.ref=='FlightPnr' || notification.ref=='FlightPnr_v2'">airplanemode_active</mat-icon>
          <mat-icon *ngIf="notification.ref=='Trial'">person</mat-icon>
          <mat-icon *ngIf="!notification.ref">chat</mat-icon>
          <mat-icon *ngIf="notification.ref=='TktTicket' && notification.reason=='new'">error</mat-icon>
          <mat-icon *ngIf="notification.ref=='TktTicket' && notification.reason=='userReply'">person</mat-icon>
        </div>
      </div>
      <div class="col-10 nopaddingupdown">
        <h6>
          {{notification.subject}}
        </h6>

        <div class="truncate preview" [innerHTML]="notification.body"></div>

        <p class="notificationdate">
          {{notification.createdAt|date:'dd/MM/yyyy - HH:mm'}}
        </p>
      </div>
    </div>
  </div>


  <div class="notificationsActions">
    <div *ngIf="clonedManNotificationsList && clonedManNotificationsList.length>0">
      <button mat-button class="small-md half" (click)="loadMore()">{{'LOAD_MORE'|translate}}</button>
      <button mat-button class="small-md half" (click)="setAllAsRead()">{{'SET_ALL_READ'|translate}}</button>
    </div>

  </div>
</div>
