<div class="hotelSlides" style="width: 100%!important">
  <div class="row">
    <div [ngClass]="{'col-4':allImages.length>=3,'col-6':allImages.length==2,'col-12':allImages.length==1}" *ngFor="let image of allImages | slice:0:3; let i = index">
      <div class="imageTopSlide" [ngStyle]="{backgroundImage:'url('+image+')'}">
        <div class="moreImages text-center pointer" *ngIf="i === 2" (click)="openModal(images)">
          <div class="slideNum">
            {{allImages.length}}
          </div>
          {{'SHOW_GALLERY'|translate}}
        </div>
      </div>
    </div>
  </div>
</div>
<img id="Image1" />
