import { Component, OnInit } from '@angular/core';
import {StatisticService} from '../../../../services/statistic/statistic.service';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {AppComponent} from '../../../../app.component';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-companies-stats',
  templateUrl: './companies-stats.component.html',
  styleUrls: ['./companies-stats.component.scss']
})
export class CompaniesStatsComponent implements OnInit {
  currentTab: string;
  availableTabs: any[] = ['FLIGHT', 'HOTEL', 'TRAIN', 'CAR_RENT'];
  availableSections = {
    'FLIGHT': 'FLIGHT',
    'HOTEL': 'HOTEL.NAME',
    'TRAIN': 'TRAIN',
    'CAR_RENT': 'CAR_RENT'
  }
  year: number;
  currentYear: number;
  loading: boolean;
  years = [];
  totalyears: number;
  startingYear = 2017;
  fromGeneral: any;
  toGeneral: any;
  today = new Date();
  generalStats: any;
  segments: any[];
  activeCompanies = 0;
  inactiveCompanies = 0;
  imagePath = 'assets/img/themes/Logo-Flyleo-blue.png';
  loadingPdf = false;
  topdf: any;
  yearSelection: number;
  constructor(
    private statsService: StatisticService,
    public mainComponent: MainComponent,
    public appComponent: AppComponent
  ) {
    this.year = new Date().getFullYear();
    this.currentYear = new Date().getFullYear();
    this.totalyears = (this.currentYear - this.startingYear) + 1;
    this.years = [];
    let i = 0;
    while (i < this.totalyears) {
      const anno = this.startingYear + i;
      this.years.push(anno);
      i++;
    }
    this.currentTab = 'FLIGHT';
    this.toGeneral = new Date();
    this.fromGeneral = new Date(new Date(this.today).setDate(1));

    if (this.appComponent.isGat) {
      this.availableTabs = ['FLIGHT'];
    }
  }

  ngOnInit(): void {
    this.yearSelection = new Date().getFullYear();
    this.getCompaniesStats(null);
  }

  setTab(value) {
    this.currentTab = value;
    this.getCompaniesStats(null);
  }

  getCompaniesStats(value): void {
    console.log('fromGeneral => ', this.fromGeneral);
    if (!value) {
      this.loading = true;
    }
    const options = {
      from: new Date(new Date(this.fromGeneral).setHours(6)),
      to: new Date(new Date(this.toGeneral).setHours(6)),
      service: this.currentTab.toLowerCase(),
    };
    this.statsService.getCompanies(JSON.stringify(options)).subscribe((res) => {
      this.generalStats = res;
      this.generalStats.total = 0;
      this.generalStats.amount = 0;
      this.generalStats.fees = 0;
      this.activeCompanies = 0;
      this.inactiveCompanies = 0;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.generalStats.data.length; i++) {
        if (this.generalStats.data[i].segments > 0) {
          this.activeCompanies += 1;
        } else {
          this.inactiveCompanies += 1;
        }
        this.generalStats.total += this.generalStats.data[i].segments;
        this.generalStats.fees += this.generalStats.data[i].fees;
        this.generalStats.amount += this.generalStats.data[i].amount;
      }
      this.loading = false;
    }, error => {
      console.error('Error => ', error);
      this.loading = false;
    });
  }

  countTotal(i, element) {
    const total = this.generalStats.data[i][element];
    return ((100 * total) / this.generalStats.total).toFixed(2);
  }

  addHeaderFooter(doc, totalPages) {
    const header = 'Report ' + this.appComponent.appName;
    const footer = 'Pdf generato in data ' + new Date().toLocaleDateString('it-IT') + ' dal sistema ' + this.appComponent.appName;

    // Carica l'immagine dal percorso in "assets"
    const logo = new Image();
    logo.src = this.imagePath;
    const width = 100;
    const aspectRatio = logo.width / logo.height;
    const height = width / aspectRatio;
    // doc.addImage(logo, 'JPEG', 10, 10, width, height);
    // Disegna l'immagine dell'intestazione
    // doc.addImage(logo, 'JPEG', 10, 10, 50, 50);

    // Disegna testo dell'intestazione
    doc.setFontSize(14);
    doc.setTextColor(100);
    doc.text(header, 10, 35, { align: 'left' });

    // Disegna testo del piè di pagina
    doc.setFontSize(8);
    doc.setTextColor(100);
    doc.text(footer, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
  }

  returnLoadingPdf(){
    return this.loadingPdf;
  }

  downloadPDF() {
    this.loadingPdf = true;
    const options = {
      filename: this.appComponent.appName +'_report_' + this.fromGeneral.toLocaleDateString('it-IT') + '-' +this.toGeneral.toLocaleDateString('it-IT') + '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
      margin: [60, 10, 50, 10],
    };
    console.log('step 1');

    const element = document.getElementById('printpdf');
    this.topdf = html2pdf();
    this.topdf.from(element)
      .set(options)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        console.log('step 2');
        // Aggiungi l'intestazione e il piè di pagina
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          this.addHeaderFooter(pdf, totalPages);
        }
        console.log('step 3', this.loadingPdf);
        // Salva il file PDF
        pdf.save(options.filename);
      });
    setTimeout(()=>{
      this.loadingPdf = false;
    }, 5000);

  }

  downLoadAnnual(){
    const options = {
      year: this.yearSelection.toString(),
      service: 'flight'
    }
    const fileName = 'export-' + options.year + '.xlsx';
    this.statsService.getExport(JSON.stringify(options), fileName).subscribe((res:Blob) => {
      this.loading = false;
      const file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const blobURL = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = blobURL;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }, error => {
      console.error('Error => ', error);
      this.loading = false;
    });
  }

}
