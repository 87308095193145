import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {FlightCardModule} from '../flight-card/flight-card.module';
import {HotelCardModule} from '../hotel-card/hotel-card.module';
import {TrainCardModule} from '../train-card/train-card.module';
import {VehicleCardModule} from '../vehicle-card/vehicle-card.module';
import {ProductInfoDialogComponent} from './product-info-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  declarations: [ProductInfoDialogComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        FlightCardModule,
        HotelCardModule,
        TrainCardModule,
        VehicleCardModule,
        MatDialogModule,
    ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductInfoDialogModule { }
