import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-creditcardrequest',
  templateUrl: './creditcardrequest.component.html',
  styleUrls: ['./creditcardrequest.component.scss']
})
export class CreditcardrequestComponent implements OnInit {
  newuserfop: any;
  documents: any;
  newfop: any;
  contractFops: any;
  addingCardContr: boolean;
  constructor() {
    this.addingCardContr = false;
    this.newfop = {
      type:'',
      card:{
        type:'',
        number:'',
        expirationMonth:'',
        expirationYear:'',
        holder:'',
        cvc:''
      }
    };
    this.documents=[
      {
        name:'Carta di identità'
      },
      {
        name:'Passaporto'
      }
    ];
    this.newuserfop={
      companydetails:{
        name:'',
        address:'',
        piva:'',
        contacts:''
      },
      owner:{
        name:'',
        surname:'',
        address:'',
        codicef:'',
        docType:'',
        docnumber:'',
        phone:''
      },
      card:{
        type:'',
        circuit:'',
        primaserie:'',
        terzaserie:'',
        quartaserie:'',
        expiration:''
      }
    };

    this.contractFops=[];
  }

  ngOnInit(): void {
    this.pushnewfop();
  }

  toggleContractModal() {
    this.addingCardContr = ! this.addingCardContr;
  }

  togglePrevModal() {
    $('#contractPrev').modal('toggle');
  }

  pushnewfop(): void{
    const topush = JSON.parse(JSON.stringify(this.newuserfop));
    this.contractFops.push(topush)
  }

  removenewfop(ind){
    this.contractFops.splice(ind,1)
  }

  generatePdf(): void {
    const htmlWidth = $('#pdfPrint').width();
    const htmlHeight = $('#pdfPrint').height();
    const topLefMargin = 15;
    const pdfWidth = htmlWidth + (topLefMargin * 2);
    const pdfHeight = (pdfWidth * 1.5) + (topLefMargin * 2);
    const canvasImageWidth = htmlWidth;
    const canvasImageHeight = htmlHeight;

    const totalPDFPages = this.contractFops.length; // Math.ceil(htmlHeight/pdfHeight)-1;

    html2canvas($('#pdfPrint')[0], { allowTaint: true }).then((canvas): jsPDF => {
      canvas.getContext('3d');

      console.log(canvas.height + ' ' + canvas.width);

      const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);
      const imgData = canvas.toDataURL('image/jpeg', 1.0);

      pdf.addImage(imgData, 'JPG', topLefMargin, topLefMargin, canvasImageWidth, canvasImageHeight);

      for (let i = 1; i <= totalPDFPages - 1; i++) {
        pdf.addPage();
        pdf.addImage(imgData, 'JPG', topLefMargin, -(pdfHeight * i) + (topLefMargin * 4), canvasImageWidth, canvasImageHeight);
      }

      return pdf;

    }).then(res => { this.createPdf(res); });
  }

  createPdf(pdf): void {
    pdf.save('contratto.pdf');
  }

  validModule(): boolean {
    const isValidCard = (value) => !(
      !value.owner.name ||
      !value.owner.surname ||
      !value.owner.address ||
      !value.owner.codicef ||
      !value.owner.docType ||
      !value.owner.docnumber ||
      !value.owner.phone ||
      !value.card.type ||
      !value.card.primaserie ||
      !value.card.terzaserie ||
      !value.card.quartaserie ||
      !value.card.expiration
    );
    return this.contractFops.every(isValidCard);
  }

  placeSlash(i, event): void {
    if (event.length === 2){
      this.contractFops[i].card.expiration = this.contractFops[i].card.expiration + '/';
    }
    console.log(event);
  }

  validCardNum(newFop, serie){
    let max = 4;
    if(newFop.card.type === 'Air Plus' && serie === 4) {
      max = 3;
    }
    if(newFop.card.type === 'Credit Card' && (newFop.card.circuit === 'Visa' || newFop.card.circuit === 'Master Card')){
      max = 4
    }
    if(newFop.card.type === 'Credit Card' && newFop.card.circuit === 'American Express' && serie === 4){
      max = 3
    }
    if(serie < 4){
      max = 4;
    }
    return max;
  }

}
