<mat-form-field class="full-width">
    <span class="isInfo" matPrefix matTooltip="{{'CARRIER'|translate}}">
      <mat-icon>help_outline</mat-icon>
    </span>
  <input matInput placeholder="{{'CARRIER'|translate}}" aria-label="Carrier" (ngModelChange)="searchCarrier($event)" [(ngModel)]="text" [matAutocomplete]="carr">
  <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #carr="matAutocomplete" (optionSelected)="getCarrier($event.option.value)" (closed)="selectFirst()">
    <mat-option *ngFor="let carrier of carriers" [value]="carrier.iata+'-'+carrier.name">
      <span>{{carrier.iata}}</span>-<small>{{carrier.name}}</small>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
