<div class="mt-2 mb-3">
  {{'CURRENT_SELECTION'|translate}}
</div>
<hr/>
<div class="travelDetails">
  <div class="mb-3" *ngFor="let itinerary of data.travel; let i = index;let la = last;">
    <div [ngClass]="{'nextExist':i<data.travel.length && i!=data.travel.length-1}">

      <div class="row">
        <div class="col-12 collapse show" id="itinerary{{i}}">
          <div class="row">
            <div class="col-3">
              {{itinerary.flights[0].departure.location}}<br/>
              {{itinerary.flights[0].departure.dateTime|date:'dd/MM/yyyy'}} -
              {{returnZeroTime(itinerary.flights[0].departure.hours)}}:
              {{returnZeroTime(itinerary.flights[0].departure.minutes)}}
            </div>
            <div class="col-3">
              <div>{{returnTime(itinerary.flyingTime)}}</div>
              <div>
                <span *ngIf="itinerary.flights.length>1">
                  {{itinerary.flights.length-1}} {{'STOPS'|translate}}
                </span>
                <span *ngIf="itinerary.flights.length==1">
                {{'NON_STOPS'|translate}}
              </span>
              </div>
            </div>
            <div class="col-3">
              {{itinerary.flights[itinerary.flights.length-1].arrival.location}}<br/>
              {{itinerary.flights[itinerary.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy'}} -
              {{returnZeroTime(itinerary.flights[itinerary.flights.length-1].arrival.hours)}}
              {{returnZeroTime(itinerary.flights[itinerary.flights.length-1].arrival.minutes)}}
            </div>

            <div class="col-3 buttonrow">
              <button mat-stroked-button (click)="opeSect(i)">
                {{'DETAILS'|translate}} <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 collapse issection" id="collapse{{i}}">

          <div class="row" *ngFor="let flight of itinerary.flights; let f = index;let fl = last;">

            <div class="col-12 mb-2" *ngIf="f===0">
              <div class="row">
                <div class="col-10 align-self-center">
                  <div class="md-titlebis">{{'DEPARTURE'|translate}}: {{flight.departure.date | date:'dd MMM yyyy'}}</div>
                  <div class="md-subhead">{{returnTime(itinerary.flyingTime)}}</div>
                </div>
                <div class="col-2 buttonrow">
                  <button mat-stroked-button (click)="opeSect(i)">
                    {{'DETAILS'|translate}} <mat-icon>keyboard_arrow_up</mat-icon>
                  </button>
                </div>
              </div>
              <hr/>
            </div>

            <div class="col-md-1 text-center">
              <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
            </div>
            <div class="col-md-11">
              <div class="flightdet">
                <p class="headingseg">
                  {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
                  {{'AIRPORT_OF'|translate}} {{flight.departure.location}}
                </p>
                <p class="headingdetails">
                  {{'FLIGHT_TIME'|translate}}: {{calculateFlightTime(flight.departure.dateTime,flight.arrival.dateTime)}}
                  <span *ngIf="flight.departure.terminal"> - Terminal: {{flight.departure.terminal}}</span>
                </p>
                <p class="headingseg">
                      <span class="redt" *ngIf="otherday(flight.departure.date | date:'dd',flight.arrival.date | date:'dd')">
                        {{flight.arrival.date | date:'dd MMM yyyy'}} -
                      </span>
                  {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
                  {{'AIRPORT_OF'|translate}} {{flight.arrival.location}} ({{flight.arrival.location}})
                </p>
                <p class="fldetails">
                  {{flight.operatingCarrier}} {{flight.number}} <span *ngIf="flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
                  <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>
                </p>
              </div>

              <div class="scalepause" *ngIf="!fl">
                    <span>
                      <i class="fas fa-random"></i> {{'STOP'|translate}} in {{flight.arrival.location}}
                      <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}} </span>
                    </span>
                <span class="changeairport" *ngIf="flight.arrival.location != itinerary.flights[f+1].departure.location">
                      {{'CHANGE'|translate}}:
                  {{itinerary.flights[f+1].departure.location}}</span>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</div>
