import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  throwError,
} from 'rxjs';
import { Router } from '@angular/router';
import {
  catchError,
  map,
  retry,
} from 'rxjs/operators';
import {
  ApiRes,
  ApplyChange,
  ApplyDiscountCodeRequest,
  CancelReservationResponse,
  ChangeDateResponse,
  ChangeServiceResponse,
  CheckPenaltyResponse,
  CompleteReservationReq,
  CompleteReservationResponse,
  CreateReservationReq,
  CreateReservationResponse,
  EvaluateTravelSolutionReq,
  EvaluateTravelSolutionRes,
  GetReservationRequest,
  HoldItaloBookingResponse,
  ItaloBooking,
  ProvideLayoutReq,
  ProvideLayoutResponse,
  RefundRequest,
  SearchBaseRequest,
  SearchBaseResponse,
  SearchCarnetBookingRequest,
  SearchTrainsRequest,
  SearchTrainsResponse,
  StationResponse,
  TrainAllowedOperations,
  TrainAuditTransaction,
  TrainInformation,
  TrainLogResponse,
  TrainReservation,
  TrainReservationList,
  TravelSolutionResponse,
  ValidateChangeServiceResponse,
} from './classes/train.models';
import { environment } from '../../../environments/environment';
import { SnackErrorComponent } from '../errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root',
})
export class TrainService{

  constructor(
    public router : Router,
    public http : HttpClient,
    public snackComponent : SnackErrorComponent,
  ){
    this.trainUrl = environment.apiTrain + 'train/';
    this.production = true;
  }
  trainUrl : string;
  production : boolean;

    static asUTC(formDate : Date){
        const originalDate = typeof formDate === 'string' ? new Date(formDate) : formDate;
        return new Date(Date.UTC(originalDate.getFullYear(), originalDate.getMonth(), originalDate.getDate(), originalDate.getHours(), originalDate.getMinutes(), 0))
    }

  public getStations(filter : string) : Observable<StationResponse[]>{
    const url = this.trainUrl + 'stations';
    const options = {
      ...this.setHttpOptions(),
      params: new HttpParams().set('filter', filter),
    };
    return this.http.get<ApiRes<StationResponse[]>>(url, options)
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<StationResponse[]>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public searchTrains(searchTrainRequest : SearchTrainsRequest) : Observable<any>{
    const adults = searchTrainRequest.adults;
    const children = searchTrainRequest.children;
    const fromStation = searchTrainRequest.departure_station;
    const toStation = searchTrainRequest.arrival_station;
    let departureDate: any = searchTrainRequest.departure_date;
    let returnDate: any = searchTrainRequest.return_date ? TrainService.asUTC(searchTrainRequest.return_date) : null;

    departureDate = departureDate.toISOString();
    if(returnDate){
      returnDate = returnDate.toISOString();
    }

    const url = `${this.trainUrl}search/trains/${adults}/${children}/${fromStation}/${toStation}/${departureDate}/${returnDate}`;
    return this.http.get<ApiRes<SearchTrainsResponse>>(url, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<SearchTrainsResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public searchTrainsForCarnet(searchCarnetBookingRequest : SearchCarnetBookingRequest) : Observable<any>{
    const url = this.trainUrl + 'bookingCarnets';
    const options = this.buildOptionsWithParams(searchCarnetBookingRequest);
    return this.http.get<ApiRes<SearchTrainsResponse>>(url, options)
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<SearchTrainsResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public searchCarnets(body: {travellers: any, pit_code: any}, searchTrainRequest : SearchTrainsRequest) : Observable<any>{
    const url = this.trainUrl + 'searchCarnets';
    const options = this.buildOptionsWithParams(searchTrainRequest);
    return this.http.post<ApiRes<SearchBaseResponse>>(url, body, options)
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<SearchBaseResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public searchCustomers(pitCode : string) : Observable<any>{
    const url = this.trainUrl + 'customers';
    const options = this.buildOptionsWithParams({ pitCode });
    return this.http.get<ApiRes<any>>(url, options)
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<any>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public getReservationList(getReservationRequest : GetReservationRequest) : Observable<TrainReservationList>{
    const url = this.trainUrl + 'reservations';
    const options = this.buildOptionsWithParams(new GetReservationRequest(getReservationRequest));
    return this.http.get<TrainReservationList>(url, options)
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  public getReservation(pnr) : Observable<TrainReservation>{
    return this.http.get<TrainReservation>(this.trainUrl + 'reservations' + '/' + pnr, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  public getTrainInformation(travelSolution) : Observable<TrainInformation[]>{
    const url = this.trainUrl + 'trenitalia/information';
    return this.http.post<ApiRes<TrainInformation[]>>(url, { travelSolution }, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<TrainInformation[]>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public getCreatedReservation(pnr){
    const url = this.trainUrl + 'reservations' + '/' + pnr + '/logs/createReservation';
    return this.http.get<TrainLogResponse>(url, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  public setTrainCostCenter(_id, body: { costCenter: any }) {
    const url = this.trainUrl + 'reservations' + '/' + _id + '/cost-center';
    return this.http.put<ApiRes<boolean>>(url, this.setHttpOptions())
      .pipe(
        retry(0),
        map(result => this.handleApiRes<boolean>(result)),
        catchError(err => this.handleError(err)),
      );
  }

  public getAllowedOperations(pnr){
    const url = this.trainUrl + 'reservations' + '/' + pnr + '/aftersale/allowed-operations';
    return this.http.get<ApiRes<TrainAllowedOperations>>(url, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<TrainAllowedOperations>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public getAuditTransactions(pnr){
    const url = this.trainUrl + 'reservations' + '/' + pnr + '/transactions';
    return this.http.get<ApiRes<TrainAuditTransaction[]>>(url, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<TrainAuditTransaction[]>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public checkPenalty(pnr, body : RefundRequest){
    const url = this.trainUrl + 'reservations' + '/' + pnr + '/aftersale/trenitalia/check-penalty';
    return this.http.post<ApiRes<CheckPenaltyResponse>>(url, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<CheckPenaltyResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public refundReservation(pnr : string, body : RefundRequest) : Observable<any>{
    const url = this.trainUrl + 'reservations/' + pnr + '/aftersale/trenitalia/confirm-refund';
    return this.http.post<ApiRes<CheckPenaltyResponse>>(url, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<CheckPenaltyResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public cancelReservation(pnr : string) : Observable<any>{
    const url = this.trainUrl + 'reservations/' + pnr + '/aftersale/cancel';
    return this.http.post<ApiRes<CancelReservationResponse>>(url, {}, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<CancelReservationResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public changeTraveller(pnr : string, travellers : { [xmlId:string]: any }) : Observable<any>{
    const body = { travellers };
    const url = this.trainUrl + 'reservations/' + pnr + '/aftersale/change-traveller';
    return this.http.post<ApiRes<any>>(url, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<any>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public checkNewDates(pnr : string, newDate : string) : Observable<any>{
    const url = this.trainUrl + 'reservations/' + pnr + '/aftersale/change-date?newDate=' + newDate;
    return this.http.get<ApiRes<ChangeDateResponse>>(url, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<ChangeDateResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public checkNewDatesEconomy(pnr : string, newDate : string) : Observable<ChangeDateResponse>{
    const url = this.trainUrl + 'reservations/' + pnr + '/aftersale/change-date-economy?newDate=' + newDate;
    return this.http.get<ApiRes<ChangeDateResponse>>(url, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<ChangeDateResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public checkBaseEconomy(pnr : string, body : { travelSolution: any, travellers: any, postSaleDetails: any }) : Observable<any>{
    const url = this.trainUrl + 'reservations/' + pnr + '/aftersale/change-date-economy';
    return this.http.post<ApiRes<ChangeDateResponse>>(url, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<ChangeDateResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public confirmChangeDate(pnr : string, selectedChange : ApplyChange) : Observable<any>{
    const url = this.trainUrl + 'reservations/' + pnr + '/aftersale/change-date';
    return this.http.post<ApiRes<any>>(url, selectedChange, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<any>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public checkAlternatives(pnr : string) : Observable<ChangeServiceResponse>{
    const url = this.trainUrl + 'reservations/' + pnr + '/aftersale/change-service';
    return this.http.get<ApiRes<ChangeServiceResponse>>(url, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<ChangeServiceResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public validateAlternative(pnr : string, alternative : { travel : any, postSaleDetails : any }) : Observable<ValidateChangeServiceResponse>{
    const url = this.trainUrl + 'reservations/' + pnr + '/aftersale/validate-travel';
    return this.http.post<ApiRes<ValidateChangeServiceResponse>>(url, alternative, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<ValidateChangeServiceResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public confirmChangeService(pnr : string, selectedChange : ApplyChange) : Observable<any>{
    const url = this.trainUrl + 'reservations/' + pnr + '/aftersale/change-service';
    return this.http.post<ApiRes<any>>(url, selectedChange, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<any>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public getReservationPDF(pnr : string) : Observable<Blob>{
    const url = this.trainUrl + 'reservations/' + pnr + '/download-pdf';
    return this.http.get(url, { ...this.setHttpOptions(), responseType: 'blob' });
  }

  public deleteReservation(id : string) : Observable<any>{
    const url = this.trainUrl + 'reservations/' + id;
    return this.http.delete(url, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  public searchBase(searchBaseRequest : SearchBaseRequest) : Observable<SearchBaseResponse>{
    const url = this.trainUrl + 'trenitalia/searchBase';
    return this.http.post<ApiRes<SearchBaseResponse>>(url, searchBaseRequest, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<SearchBaseResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public evaluateTravelSolution(evaluateTravelReq : EvaluateTravelSolutionReq) : Observable<EvaluateTravelSolutionRes>{
    const url = this.trainUrl + 'trenitalia/evaluateTravelSolution';
    return this.http.post<ApiRes<EvaluateTravelSolutionRes>>(url, evaluateTravelReq, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<EvaluateTravelSolutionRes>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public evaluateCarnetBooking(evaluateTravelReq : EvaluateTravelSolutionReq) : Observable<CreateReservationResponse>{
    const url = this.trainUrl + 'trenitalia/evaluateCarnetBooking';
    return this.http.post<ApiRes<CreateReservationResponse>>(url, evaluateTravelReq, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<CreateReservationResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public applyDiscountCode(body : ApplyDiscountCodeRequest) : Observable<TravelSolutionResponse>{
    const url = this.trainUrl + 'trenitalia/applyDiscountCode';
    return this.http.post<ApiRes<TravelSolutionResponse>>(url, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<TravelSolutionResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public provideLayout(body : ProvideLayoutReq) : Observable<ProvideLayoutResponse>{
    const url = this.trainUrl + 'trenitalia/provideLayout';
    return this.http.post<ApiRes<ProvideLayoutResponse>>(url, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<ProvideLayoutResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public createReservation(body : CreateReservationReq) : Observable<CreateReservationResponse>{
    const url = this.trainUrl + 'trenitalia/createReservation';
    return this.http.post<ApiRes<CreateReservationResponse>>(url, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<CreateReservationResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public completeOrder(body : CompleteReservationReq) : Observable<CompleteReservationResponse>{
    const url = this.trainUrl + 'trenitalia/completeOrder';
    return this.http.post<ApiRes<CompleteReservationResponse>>(url, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<CompleteReservationResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public italoHoldBooking(body : ItaloBooking) : Observable<HoldItaloBookingResponse>{
    const url = this.trainUrl + 'italo/holdBooking';
    return this.http.post<ApiRes<HoldItaloBookingResponse>>(url, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<HoldItaloBookingResponse>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public italoManagePayment(data) : Observable<any>{
    const url = this.trainUrl + 'italo/managePayment';
    return this.http.post<ApiRes<any>>(url, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<any>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public italoFinalizeBooking(data) : Observable<any>{
    const url = this.trainUrl + 'italo/finalizeBooking';
    return this.http.post<ApiRes<any>>(url, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<any>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public italoCompleteOrder(data) : Observable<any>{
    const url = this.trainUrl + 'italo/completeOrder';
    return this.http.post<ApiRes<any>>(url, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<any>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public italoGetBooking(data: { pnr: string, passenger: { FirstName: string, LastName: string }}) : Observable<any>{
    const url = this.trainUrl + 'italo/getBooking';
    return this.http.post<ApiRes<any>>(url, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 map(result => this.handleApiRes<any>(result)),
                 catchError(err => this.handleError(err)),
               );
  }

  public updateApprovalId(_id: string, approvalId: string){
    const url = this.trainUrl + 'reservations/' + _id + '/approvalId';
    return this.http.patch<ApiRes<any>>(url, {approvalId}, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  // --- PRIVATE METHODS ---//

  private handleApiRes<T>(result : ApiRes<T>) : T{
    if (result.Status === 'KO' || !Array.isArray(result.Result) && Object.keys(result.Result).length === 0){
      throw new HttpErrorResponse({ error: result.Message });
    } else{
      return result.Result;
    }
  }

  private setHttpOptions(){
    const headers: any = {
      'Content-Type': 'application/json',
    };
    if (environment.isSbt) {
      headers['X-Api-Key'] = 'f15bfbd7-f617-4e7e-92bc-8773dced2c63';
    } else {
      headers.Authorization = 'Bearer ' + localStorage.getItem('fl_access_token');
    }
    return {
      headers: new HttpHeaders(headers),
    };
  }

  private buildOptionsWithParams(requestObject : object){
    let params = new HttpParams();
    Object.keys(requestObject).forEach((item) => {
        params = params.set(item, requestObject[item]);
    });
    return {
      ...this.setHttpOptions(),
      params,
    };
  }

  private handleError(error : HttpErrorResponse, showSnackBar : boolean = true, customMsg? : string){
    let message : any = '';
    if (error.error instanceof ErrorEvent){
      const msg = error.error.message;
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', msg);
      message = msg;
    } else{
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.errorMessage}`);
      if (error.error.errorMessage === 'Token is Expired'){
        this.router.navigate(['/login']);
      }
      message = error.error;
    }
    if (showSnackBar && !error.error?.isTrusted){
      const msg = customMsg ? customMsg : (message.errorMessage) ? message.errorMessage : JSON.stringify(message);
      this.snackComponent.openSnackBar(msg);
    }
    // return an observable with a user-facing error message
    return throwError(message);
  }
}
