import {
  Component,
  OnInit,
} from '@angular/core';
import {
  animate,
  group,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NotifierService } from 'angular-notifier';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { FlightRequest } from '../../../flight/classes/flightrequest';
import {
  Options,
  Section,
} from '../../../../classes/flights/search/flightsearch';
import { IatacompleteService } from '../../../../services/iatacomplete/iatacomplete.service';
import { TicketingService } from '../../../../services/ticketing/ticketing.service';
import { TranslateService } from '@ngx-translate/core';
import { OpenTicketRequest } from '../../../../services/ticketing/api/ticketing.service.models';
import {AppComponent} from '../../../../app.component';

@Component({
  selector: 'group-requests',
  templateUrl: './group-requests.component.html',
  styleUrls: ['./group-requests.component.scss'],
  animations: [
    trigger('listItemAnimation', [
      transition(':enter', [
        style({ height: '0px', overflow: 'hidden' }),
        group([animate('250ms ease-out', style({ height: '!' }))]),
      ]),
      transition(':leave', [
        style({ height: '!', overflow: 'hidden' }),
        group([animate('250ms ease-out', style({ height: '0px' }))]),
      ]),
    ]),
  ],
})

export class GroupRequestsComponent implements OnInit{
  groupRequest : UntypedFormGroup;
  preferredClass : any;
  preferredTimeSlot : any;
  flightRequest : FlightRequest = new FlightRequest();
  departureStartDate$ = new BehaviorSubject(new Date());
  private readonly notifier: NotifierService;

  constructor(
    private fb : UntypedFormBuilder,
    private ticketService : TicketingService,
    public iataComplete : IatacompleteService,
    public translate: TranslateService,
    private appComponent: AppComponent,
    notifierService : NotifierService,
  ){
    this.notifier = notifierService;
  }

  ngOnInit(){
    this.resetForm();
  }

  onSubmit(){
    this.flightRequest.sections.forEach((s:any) =>{
      const date = new Date(s.options.date);
      s.options.date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
    });
    const payload = {
      flights: [this.flightRequest],
      ...this.groupRequest.getRawValue(),
    }
    const request: OpenTicketRequest = {
      subject: 'Group Request',
      body: {
        payload,
        data: JSON.stringify(payload, null, 4),
      },
      section: 'flight',
      department: 'group-request',
      // requestType: '10038', // Fisso il ID di Biglietteria
      component: 'group'
    };
    this.ticketService.sendTicket(request).subscribe((res) => {
      this.notifier.notify('success', this.translate.instant('TICKET_OPENED'));
      console.log('res => ', res);
      this.resetForm();
    }, error => {
      this.notifier.notify('error', this.translate.instant(error));
      console.error('Error => ', error);
    });
  }

  addSection(){
    const lastSection = this.flightRequest.sections[this.flightRequest.sections.length-1];
    const section = JSON.parse(JSON.stringify(lastSection));
    section.from = JSON.parse(JSON.stringify(lastSection.to));
    section.to = JSON.parse(JSON.stringify(lastSection.from));
    this.flightRequest.sections.push(section);
  }

  removeSection(i : number){
    this.flightRequest.sections.splice(i, 1);
  }

  /**
   *  Remove departure location from departure array
   */
  removeIataFrom(segindex, iataindex){
    this.flightRequest.sections[segindex].from.splice(iataindex, 1);
    this.flightRequest.sections[segindex].fromLegend.splice(iataindex, 1);
  }

  /**
   *  Remove arrival location from arrival array
   */
  removeIataTo(segindex, iataindex){
    this.flightRequest.sections[segindex].to.splice(iataindex, 1);
    this.flightRequest.sections[segindex].toLegend.splice(iataindex, 1);
  }

  invertIata(z){
    const to = [...this.flightRequest.sections[z].to];
    const toLegend = [...this.flightRequest.sections[z].toLegend];
    const from = [...this.flightRequest.sections[z].from];
    const fromLegend = [...this.flightRequest.sections[z].fromLegend];
    this.flightRequest.sections[z].to = from;
    this.flightRequest.sections[z].toLegend = fromLegend;
    this.flightRequest.sections[z].from = to;
    this.flightRequest.sections[z].fromLegend = toLegend;
  }

  isCompleted(){
    return this.groupRequest.valid &&
      this.flightRequest.sections[0].from.length > 0 &&
      this.flightRequest.sections[0].to.length > 0 &&
      this.flightRequest.sections[0].options.date;
  }

  /**
   *  Remove carrier from carriers array
   */
  removeIataCarrier(iataindex){
    this.flightRequest.options.carrierIdentification.splice(iataindex, 1);
  }

  private resetForm(){
    this.groupRequest = this.fb.group({
      travellers: [undefined, [Validators.required]],
      notes: [undefined, []],
    });
    this.flightRequest.sections = [];
    this.flightRequest.options.carrierIdentification = [];
    this.flightRequest.sections.push(new FlightSection());
    this.flightRequest.sections[0].options.cabin = '';
    this.flightRequest.sections[0].options.timeSlot = '';
  }
}

class FlightSection implements Section{
  from : string[] = [];
  fromLegend : any[] = [];
  to : string[] = [];
  toLegend : any[] = [];
  options : Options = {} as Options;
}
