<mat-card appearance="outlined" style="margin: 20px 0">
  <div style="display: flex; justify-content: space-between">
    <h4>Search Id: {{search.searchId}}</h4>
    <div>
      <button *ngIf="delete" (click)="deleteSearch(search)" mat-raised-button color="warn">
        <mat-icon>delete</mat-icon>
      </button>
      <button (click)="continueBooking(search)" *ngIf="search.status === 'approved'" mat-stroked-button>Continue booking</button>
    </div>
  </div>
  <h5><b>Status: </b>{{search.status}}</h5>
  <h5>Product type: {{search.ref}}</h5>
  <h5>Created at: {{search.createdAt | date: 'long'}}</h5>
  <h5>Product:</h5><button (click)="showProductInfo()" mat-raised-button color="secondary">Show</button>
<!--   <app-flight-product-card [flight]="search.productDetails" *ngIf="search.productDetails && search.ref === 'Flight'">
  </app-flight-product-card> -->


  <ng-container *ngIf="search.authorisations">
    <div  *ngFor="let authorisation of search.authorisations">
    <div [ngClass]="
         {'warn-alert': authorisation.status === 'pending',
         'danger-alert': authorisation.status === 'declined',
         'success-alert': authorisation.status === 'approved'
         }">

      <div style="display: flex; justify-content: space-between">
        <span>{{authorisation.note}}</span>
        <span>Status: {{authorisation.status}}</span></div>
    </div>
      <p *ngIf="authorisation.status === 'approved'">Approved by: {{authorisation.userResponse.name}} {{authorisation.userResponse.surname}}</p>
      <p *ngIf="authorisation.status === 'declined'">Declined by: {{authorisation.userResponse.name}} {{authorisation.userResponse.surname}}</p>
      <p *ngIf="authorisation.status !== 'pending'">Note: {{authorisation.userResponeNote}}</p>
    </div>
  </ng-container>

</mat-card>
