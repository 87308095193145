import { Component, OnInit } from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {TkbottomsheetComponent} from '../tkbottomsheet/tkbottomsheet.component';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {AppComponent} from '../../../../app.component';

@Component({
  selector: 'app-openticket',
  templateUrl: './openticket.component.html',
  styleUrls: ['./openticket.component.scss']
})
export class OpenticketComponent implements OnInit {

  constructor(
    private bottomSheet: MatBottomSheet,
    public mainComponent: MainComponent,
    public appComponent: AppComponent
  ) { }

  ngOnInit(): void {
  }

  openBottomSheet() {
    this.bottomSheet.open(TkbottomsheetComponent, {
      data: {
        logged: this.mainComponent.loggedUser,
        isGat: this.appComponent.isGat,
        isSbt: this.appComponent.isSbt,
        isB2B: this.appComponent.isB2B,
      },
      // disableClose: true,
    });
  }

}
