import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, UntypedFormBuilder, Validators} from '@angular/forms';
import {TravelPolicyService} from '../../../services/travel-policy/travel-policy.service';

@Component({
  selector: 'app-create-policy-dialog',
  templateUrl: './create-policy-dialog.component.html',
  styleUrls: ['./create-policy-dialog.component.scss']
})
export class CreatePolicyDialogComponent implements OnInit {
  rolesList: any[];
  roleObj;
  createPolicyForm = this.fb.group({
    policyName: ['', Validators.required],
    policyRole: ['', Validators.required],
    hotels: this.fb.group({
      value: [''],
      type: [''],
    }),
    flights: this.fb.group({
      value: [''],
      type: [''],
    }),
    vehicles: this.fb.group({
      value: [''],
      type: [''],
    }),
    trains: this.fb.group({
      value: [''],
      type: [''],
    }),
    transfers: this.fb.group({
      value: [''],
      type: [''],
    }),
  });
  private polices;
  private isEditable: any;

  constructor(private dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data,
              private travelPolicyService: TravelPolicyService,
              private fb: UntypedFormBuilder

  ) {
  }

  get policyName(): AbstractControl {
    return this.createPolicyForm.get('policyName');
  }

  get policyRole(): AbstractControl {
    return this.createPolicyForm.get('policyRole');
  }

  ngOnInit(): void {
    this.isEditable = !!this.data.data.group && this.data.data;

    if (this.isEditable) {
      console.log(this.data);
      const formData = {
        policyName: this.data.data.name,
        policyRole: this.data.data.group.name,
        hotels: this.data.data?.tolerance?.hotels ? this.data.data?.tolerance?.hotels : {value: '', type: ''},
        flights: this.data.data?.tolerance?.flights ? this.data.data?.tolerance?.flights : {value: '', type: ''},
        vehicles: this.data.data?.tolerance?.vehicles ? this.data.data?.tolerance?.vehicles : {value: '', type: ''},
        trains: this.data.data?.tolerance?.trains ? this.data.data?.tolerance?.trains : {value: '', type: ''},
        transfers: this.data.data?.tolerance?.transfers ? this.data.data?.tolerance?.transfers : {value: '', type: ''},
      };
      this.createPolicyForm.patchValue(formData);
    }
    // this.setRolesList();
    // this.setPolicy();
  }
  /*
  setPolicy(): void {
    this.travelPolicyService.list().subscribe(polices => {
      this.polices = polices.data;
    });
  }

  setRolesList(): void {
    this.travelPolicyService.roleList().subscribe(result => {
      this.rolesList = result.data;
    });
  }
  */
  checkIfRoleExist(): void {
    const isRoleExistent = this.data.data.policies.some(policy => policy.group.name === this.policyRole.value);
    if (isRoleExistent) {
      this.policyRole.setErrors({roleAlreadyExist: true});
    }
  }

  checkIfNameExist(): void {
    if (this.data.data.policies) {
      return;
    }
    const isNameExistent = this.data.data.policies.some(item => {
      return item.name === this.policyName.value;
    });
    if (isNameExistent) {
      this.policyName.setErrors({nameAlreadyExist: true});
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
