import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdmserviceService} from '../../../../services/adminserv/admservice.service';

@Component({
  selector: 'app-admin-reservation-changes',
  templateUrl: './admin-reservation-changes.component.html',
  styleUrls: ['./admin-reservation-changes.component.scss']
})
export class AdminReservationChangesComponent implements OnInit {
  @Input() type: string;
  @Input() reservation: any;
  @Output('update') change: EventEmitter<any> = new EventEmitter<any>();
  fareManual: any;
  newPrice: any;
  notes: any;
  constructor(
    private admService:AdmserviceService,
  ) {
    this.fareManual = {
      amount: 0,
      description: '',
    }
  }

  ngOnInit(): void {
    if(this.reservation){
      console.log('this.reservation biglietteria => ', this.reservation)
    }
    if(this.reservation.fare.manual){
      this.fareManual = JSON.parse(JSON.stringify(this.reservation.fare.manual));
    }
  }

  save(){
    this.admService.changePnr(this.type, this.fareManual, this.reservation._id).subscribe((res) => {
      console.log('res => ', res);
      this.change.emit(true);
      // this.getPnr(this.reservation._id);
    }, error => {
      console.error('Error => ', error);
    });
  }
}
