<div class="left_bar">
  <!--
  <button mat-icon-button [matMenuTriggerFor]="theme" aria-label="Example icon-button with a menu">
    <mat-icon>format_color_fill</mat-icon>
  </button>
  <mat-menu #theme="matMenu">
    <button (click)="appcomponent.onSetTheme('main-theme')" mat-menu-item>
      <span>first theme</span>
    </button>
    <button (click)="appcomponent.onSetTheme('second-theme')" mat-menu-item>
      <span>Second theme</span>
    </button>
    <button (click)="appcomponent.onSetTheme('third-theme')" mat-menu-item>
      <span>third theme</span>
    </button>
    <button (click)="appcomponent.onSetTheme('sbt-theme')" mat-menu-item>
      <span>sbt theme</span>
    </button>
  </mat-menu>
  <div class="btndivider"></div> -->
  <button mat-icon-button [matMenuTriggerFor]="langmenu" aria-label="Example icon-button with a menu">
    <img *ngIf="appcomponent.currentLang==='it'" class="" src="assets/img/flags/italian_flag.png"/>
    <img *ngIf="appcomponent.currentLang==='en'" class="" src="assets/img/flags/english_flag.png"/>
  </button>
  <mat-menu #langmenu="matMenu">
    <button [ngClass]="{'active':appcomponent.currentLang=='en'}" (click)="appcomponent.useLanguage('en')" mat-menu-item>
      <span>English</span>
    </button>
    <!--
    <button [ngClass]="{'active':appcomponent.currentLang=='fr'}" (click)="appcomponent.useLanguage('fr')" mat-menu-item>
      <span>Francais</span>
    </button>
    -->
    <button [ngClass]="{'active':appcomponent.currentLang=='it'}" (click)="appcomponent.useLanguage('it')" mat-menu-item>
      <span>Italian</span>
    </button>
  </mat-menu>
  <div class="btndivider"></div>
</div>
<div class="barcontainer">
  <!--
  <div class="left_bar">
    <button type="button" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  -->

  <div class="right_menu">
    <div class="userDetails">
      <div class="userAvatar">
        <img src="{{maincomponent.loggedUser.image}}" alt="avatar"/>
      </div>
      <div class="row">
        <div class="col-8 align-self-center">
          <div class="username">{{maincomponent.loggedUser.name}} {{maincomponent.loggedUser.surname}}</div>
        </div>
        <div class="col-4 align-self-center text-right">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="goto('/users/list/' + maincomponent.loggedUser._id)" mat-menu-item>
              <span>{{'PROFILE'|translate}}</span>
            </button>
            <button (click)="logOut()" mat-menu-item>
              <span>Log out</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <a *ngIf="customAuth.returnAuth(maincomponent.loggedUser._id) && customAuth.returnAuthTf(maincomponent.loggedUser._id)" class="mainlink" matRipple routerLink="/dashboard" routerLinkActive="active-link">
      <span class="material-icons menuicon">home</span>Dashboard
    </a>

    <mat-accordion displayMode="flat" class="mainmenu">
      <!--
      <mat-expansion-panel *ngIf="maincomponent.loggedUser.role.superAdmin">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Theme components
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu">
          <li><a matRipple routerLinkActive="active-link" routerLink="/theme-components/autocomplete">Autocomplete</a></li>
        </ul>
      </mat-expansion-panel>
      -->

      <mat-expansion-panel *ngIf="maincomponent.loggedUser.role.superAdmin || (maincomponent.company.cau && maincomponent.loggedUser.role.company)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons menuicon">admin_panel_settings</span> {{'ADMIN_AREA'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu">
          <li *ngIf="!appcomponent.isSbt && maincomponent.loggedUser.role.superAdmin">
            <a matRipple routerLink="/loginAs" routerLinkActive="active-link">{{'LOGIN_AS'|translate}}</a>
          </li>
          <li *ngIf="appcomponent.isSbt && !appcomponent.isGat && maincomponent.loggedUser.role.superAdmin">
            <a matRipple routerLink="/users/list" routerLinkActive="active-link">{{'LOGIN_AS'|translate}}</a>
          </li>
          <li *ngIf="!appcomponent.isSbt && !appcomponent.isGat && maincomponent.loggedUser.role.superAdmin">
            <a matRipple routerLinkActive="active-link" routerLink="/adminArea/flightCryptic">
              {{'FLIGHT_CRYPTIC'|translate}}
            </a>
          </li>
          <li *ngIf="!appcomponent.isSbt && !appcomponent.isGat && maincomponent.loggedUser.role.superAdmin">
            <a matRipple routerLinkActive="active-link" routerLink="/adminArea/selling-platform">
              {{'SELLING_PLATFORM'|translate}}
            </a>
          </li>
          <li *ngIf="maincomponent.loggedUser.role.superAdmin">
            <a matRipple routerLinkActive="active-link" routerLink="/adminArea/addProfile">
              {{'ADD_PROFILE_COMPANY'|translate}}
            </a>
          </li>
          <li *ngIf="maincomponent.loggedUser.role.superAdmin">
            <a matRipple routerLinkActive="active-link" routerLink="/adminArea/manage-iata">
              {{'MANAGE_IATA'|translate}}
            </a>
          </li>
          <li *ngIf="maincomponent.loggedUser.role.superAdmin">
            <a matRipple routerLinkActive="active-link" routerLink="/adminArea/manage-airports">
              {{'MANAGE_AIRPORTS'|translate}}
            </a>
          </li>
          <li *ngIf="maincomponent.loggedUser.role.superAdmin">
            <a matRipple routerLinkActive="active-link" routerLink="/adminArea/manage-blocked-flight-rates">
              {{'MANAGE_ISSUE_FARE_FLIGHT'|translate}}
            </a>
          </li>
          <li>
            <a matRipple routerLinkActive="active-link" routerLink="/adminArea/import-pnr">
              {{'IMPORT_PNR'|translate}}
            </a>
          </li>
          <li *ngIf="maincomponent.loggedUser.role.superAdmin">
            <a matRipple routerLinkActive="active-link" routerLink="/adminArea/incentives">
              {{'MANAGE_INCENTIVES'|translate}}
            </a>
          </li>
        </ul>
      </mat-expansion-panel>

      <!--
      <a *ngIf="customAuth.returnAuth(maincomponent.loggedUser._id)" class="mainlink" matRipple routerLink="/dossier/list" routerLinkActive="active-link"><span class="material-icons menuicon">receipt_long</span>{{'DOSSIER_LIST'|translate}}</a>
      -->
      <mat-expansion-panel *ngIf="(maincomponent.loggedUser.company.services.dossier && (appcomponent.isSbt || appcomponent.isB2B)) || (maincomponent.loggedUser.role.superAdmin && !appcomponent.isGat)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons menuicon">folder</span> {{'TRAVEL_FOLDER'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu">
          <li>
            <a matRipple style="cursor:pointer;" (click)="maincomponent.addDossierToggle()" routerLinkActive="active-link">{{'NEW_FOLDER'|translate}}</a>
          </li>
          <li>
            <a matRipple routerLink="/dossier/list" routerLinkActive="active-link">{{'FOLDER_LIST'|translate}}</a>
          </li>
        </ul>
      </mat-expansion-panel>
      <ng-container *ngIf="!appcomponent.isGat && !appcomponent.isSbt">
        <mat-expansion-panel *ngIf="(maincomponent.loggedUser.role.company && (maincomponent.loggedUser.company.services.b2b || maincomponent.loggedUser.company.services.b2c)) || maincomponent.loggedUser.role.superAdmin">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="material-icons menuicon">euro</span> {{'FEES'|translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="sidemenu">
            <li><a matRipple routerLinkActive="active-link" routerLink="/fees">{{'FEES_MANAGEMENT'|translate}}</a></li>
          </ul>
        </mat-expansion-panel>
      </ng-container>


      <mat-expansion-panel *ngIf="maincomponent.loggedUser.company.services.flight && !sarce">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons menuicon">flight</span> {{'FLIGHT'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu">
          <li><a matRipple routerLinkActive="active-link" routerLink="/flight/search">{{'SEARCH_FLIGHT'|translate}}</a></li>
          <li><a matRipple routerLinkActive="active-link" routerLink="/flight/history">{{'HISTORY'|translate}}</a></li>
          <li><a matRipple routerLinkActive="active-link" routerLink="/flight/reservations">{{'PNR_LIST'|translate}}</a></li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="maincomponent.loggedUser.company.services.hotel && !appcomponent.isGat && !sarce">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons menuicon">hotel</span> {{'HOTEL'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu">
          <li><a matRipple routerLinkActive="active-link" routerLink="/hotel/search">{{'HOTEL_SEARCH'|translate}}</a></li>
          <li><a matRipple routerLinkActive="active-link" routerLink="/hotel/reservations">{{'HOTEL_RESERVATION_LIST'|translate}}</a></li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="maincomponent.loggedUser.company.services.train && !appcomponent.isGat && !sarce">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons menuicon">train</span> {{'TRAIN'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu">
          <li><a matRipple routerLinkActive="active-link" routerLink="/trains/search">{{'TRAIN_SEARCH'|translate}}</a></li>
          <li><a matRipple routerLinkActive="active-link" routerLink="/trains/reservations">{{'TRAIN_RESERVATION_LIST'|translate}}</a></li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="maincomponent.loggedUser.company.services.car && !appcomponent.isGat && !sarce">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons menuicon">directions_car</span> {{'CAR_RENT'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu">
          <li><a matRipple routerLinkActive="active-link" routerLink="/car-rent/search">{{'CAR_RENT_SEARCH'|translate}}</a></li>
          <li><a matRipple routerLinkActive="active-link" routerLink="/car-rent/reservations">{{'CAR_RESERVATION_LIST'|translate}}</a></li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons menuicon">person_outline</span> {{'REGISTRY'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu">
          <li><a *ngIf="maincomponent.loggedUser.role.profile.user.get.company" matRipple routerLinkActive="active-link" routerLink="/users/list">{{'USERS_LIST'|translate}}</a></li>
          <li *ngIf="maincomponent.loggedUser.role.superAdmin"><a matRipple routerLinkActive="active-link" routerLink="/companies/list">{{'COMPANIES_LIST'|translate}}</a></li>
          <li>
            <a matRipple routerLinkActive="active-link" routerLink="/travellers/list">
              {{'TRAVELLERS_BOOK'|translate}}
            </a>
          </li>
          <li *ngIf="((appcomponent.isB2B || appcomponent.isSbt) &&  maincomponent.loggedUser.company.services.siap) || maincomponent.loggedUser.role.superAdmin && !appcomponent.isGat">
            <a matRipple routerLinkActive="active-link" routerLink="/costcenter">
              {{'COST_CENTER'|translate}}
            </a>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="(maincomponent.loggedUser.role.superAdmin && !appcomponent.isGat) || (maincomponent.loggedUser.company.services.policy && maincomponent.loggedUser.role.company && (this.appcomponent.isSbt || this.appcomponent.isB2B))">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons menuicon">policy</span> {{'TRAVEL_POLICIES'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu">
          <li *ngIf="maincomponent.loggedUser.role.company">
            <a matRipple routerLinkActive="active-link" routerLink="/allgroups">
              {{'TRAVEL_POLICIES_GROUPS'|translate}}
            </a>
          </li>
          <li *ngIf="maincomponent.loggedUser.role.company">
            <a matRipple routerLinkActive="active-link" routerLink="/policies">
              {{'TRAVEL_POLICIES_ROLES'|translate}}
            </a>
          </li>
          <!--
          <li>
            <a matRipple routerLinkActive="active-link" routerLink="/usergroups">
              {{'USERS_GROUPS'|translate}}
            </a>
          </li>
          -->
          <li *ngIf="maincomponent.loggedUser.role.company">
            <a matRipple routerLinkActive="active-link" routerLink="/usergroupsbulk">
              {{'USERS_GROUPS'|translate}}
            </a>
          </li>
          <li>
            <a matRipple routerLinkActive="active-link" routerLink="/policy-requests">{{'RECEIVED_REQUESTS'|translate}}</a>
          </li>
          <li>
            <a matRipple routerLinkActive="active-link" routerLink="/policy-searches">{{'SEARCH_REQUESTS'|translate}}</a>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="(maincomponent.loggedUser.role.superAdmin || (appcomponent.isB2B || appcomponent.isSbt)) && !appcomponent.isGat">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons menuicon">question_answer</span> {{'MOD_REQUESTS'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu">
          <li><a matRipple routerLinkActive="active-link" routerLink="/offline-requests/open-request">{{'NEW_TRAVEL_REQUEST'|translate}}</a></li>
          <li><a matRipple routerLinkActive="active-link" routerLink="/offline-requests/request-list">{{'TRAVEL_REQUESTS_LIST'|translate}}</a></li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="!(appcomponent.isB2B || appcomponent.isSbt)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons menuicon">send</span> {{'REQUESTS'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu">
          <li><a matRipple routerLinkActive="active-link" routerLink="/requests/minors-request">{{'UNACCOMPANIED_MINORS'|translate}}</a></li>
        </ul>
        <ul class="sidemenu">
          <li><a matRipple routerLinkActive="active-link" routerLink="/requests/group-request">{{'GROUP_REQUEST'|translate}}</a></li>
        </ul>
        <ul class="sidemenu">
          <li><a matRipple routerLinkActive="active-link" routerLink="/requests/list">{{'TRAVEL_REQUESTS_LIST'|translate}}</a></li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="!appcomponent.isGat && !maincomponent.company.cauDK">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons menuicon">contact_support</span> {{'TICKETING'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu" *ngIf="!appcomponent.isSbt && !appcomponent.isGat">
          <li *ngIf="!maincomponent.loggedUser.role.isTktOperator"><a matRipple routerLinkActive="active-link" routerLink="/ticketing/list">{{'TICKETS_LIST'|translate}}</a></li>
          <li *ngIf="maincomponent.loggedUser.role.isTktOperator"><a href="https://leonardotravel.atlassian.net/jira/servicedesk/projects/BIG" target="_blank">{{'TICKETS_LIST'|translate}}</a></li>
        </ul>
        <ul class="sidemenu" *ngIf="appcomponent.isSbt || appcomponent.isGat">
          <li><a matRipple routerLinkActive="active-link" routerLink="/ticketing/list">{{'TICKETS_LIST'|translate}}</a></li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="maincomponent.loggedUser.role.superAdmin || (maincomponent.loggedUser.company.type === 'A' && maincomponent.loggedUser.role.company)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons menuicon">insights</span> {{'STATISTIC'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidemenu">
          <li><a matRipple routerLinkActive="active-link" routerLink="/statistic/general">{{'GENERAL_STATS'|translate}}</a></li>
        </ul>
        <ul class="sidemenu">
          <li><a matRipple routerLinkActive="active-link" routerLink="/statistic/year">{{'YEAR_STATS'|translate}}</a></li>
        </ul>
        <ul class="sidemenu" *ngIf="maincomponent.loggedUser.role.superAdmin">
          <li><a matRipple routerLinkActive="active-link" routerLink="/statistic/companies-stats">{{'COMPANIES_STATS'|translate}}</a></li>
        </ul>
      </mat-expansion-panel>

    </mat-accordion>
    <a *ngIf="maincomponent.loggedUser.role.superAdmin" class="mainlink" matRipple routerLink="/notifications" routerLinkActive="active-link">
      <span class="material-icons menuicon">notifications_none</span>{{'NOTIFICATIONS'|translate}}
    </a>
  </div>
</div>



