import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CompaniesService} from '../../../services/profile/companies.service';
import {MainComponent} from '../../mainmodule/main/main.component';
import * as $ from 'jquery';
import 'bootstrap';
import {Fops} from '../../companies/classes/fops';
import {AppComponent} from '../../../app.component';
@Component({
  selector: 'app-methods',
  templateUrl: './methods.component.html',
  styleUrls: ['./methods.component.scss']
})
export class MethodsComponent implements OnInit {
  methods: any;
  @Input() id: string;
  @Input() reservation: any;
  @Input() resType: string;
  @Input() corporate?: boolean;
  
  loading: boolean;
  loadingFops: boolean;
  newMethod: any;
  selectedCp: any;
  services: string[];
  companytext: string;
  companiesList: any;
  availableCard: any;
  currentFop: any;
  fops: Fops[];
  methodType: string;
  constructor(
    private route: ActivatedRoute,
    private companyService: CompaniesService,
    public maincomponent: MainComponent,
    private companiesService: CompaniesService,
    private appCompo: AppComponent,
  ) {
    if (this.appCompo.isGat){
      this.services = ['flight', '*'];
    } else {
      this.services = ['flight', 'hotel', 'train', 'car', '*'];
    }

    this.methodType = undefined;
  }

  ngOnInit(): void {
    this.getMethods();
  }

  searchCompany(value) {
    delete this.companiesList;
    // this.clearFrom();
    if (value.length >= 2) { this.getCompaniesList(value); }
  }

  getCompaniesList(value) {
    // this.clearFrom();
    this.companiesService.getCompaniesAuto(value).subscribe((res) => {
      this.loading = false;
      this.companiesList = res;
      console.log('this.companiesList => ', this.companiesList);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  selectFirst() {
    if ( this.companiesList ) {
      this.selectedCp = this.companiesList[0];
      this.newMethod.amadeusProfile = this.selectedCp._id;
      this.newMethod.company = this.selectedCp._id;
      console.log('company => ', this.selectedCp);
      this.getFops();
    }
    // this.clearFrom();
  }

  selectComp(value) {
    console.log('entro => ', value);
    this.selectedCp = value;
    this.newMethod.amadeusProfile = this.selectedCp._id;
    this.newMethod.company = this.selectedCp._id;
    this.getFops();
    // this.clearFrom();
    // this.companytext = '';
  }

  clearFrom() {
    this.companytext = '';
    // delete this.company;
    delete this.companiesList;
    this.newMethod.amadeusProfile = '';
    this.newMethod.company = '';
    this.newMethod.index = '';
    delete this.fops;
  }

  removeCompany() {
    this.selectedCp = '';
    this.newMethod.amadeusProfile = '';
    this.newMethod.company = '';
  }

  getMethods() {
    this.loading = true;
    this.companyService.getMethods(this.id).subscribe((res) => {
      this.loading = false;
      this.methods = res;
      this.elaborateFop();
    }, error => {
      this.loading = false;
      // this.notifier.notify('error', error);
      console.error('Error => ', error);
    });
  }

  toggleAddModal() {
    this.createNewMethod();
    this.clearFrom();
    $('#addMethodModal').modal('toggle');
  }

  createNewMethod() {
    this.newMethod = {
      service: '*',
      cash: false,
      index: '',
      amadeusProfile: undefined,
      name: '',
      company: undefined,
    };
  }

  validForm() {
    // tslint:disable-next-line:max-line-length
    return (this.newMethod.service && this.newMethod.amadeusProfile && this.newMethod.name && this.newMethod.company && this.methodType);
  }

  addFop() {
    this.loading = true;
    this.companyService.saveMethod(this.id, this.newMethod).subscribe((res) => {
      this.loading = false;
      this.toggleAddModal();
      this.getMethods();
      this.createNewMethod();
    }, error => {
      this.loading = false;
      this.toggleAddModal();
    });
  }

  getFops() {
    this.loading = true;
    const id = (this.newMethod.cash) ? this.id : this.newMethod.company;
    this.companyService.getFops(id).subscribe((res) => {
      this.loadingFops = false;
      this.fops = res;
    }, error => {
      this.loadingFops = false;
      // this.notifier.notify('error', error);
      console.error('Error => ', error);
    });
  }

  elaborateFop(): void {
    if (this.resType === 'hotel') {
      this.methods.forEach(f => {
        if (f.card) {
          if (f.card.type === 'CC') {
            const find = this.reservation.room.ratePlans[0].guarantee.accepted.findIndex(r => r.cardCode === f.card.card.type);
            if (find !== -1) {
              f.accepted = true;
            } else {
              f.accepted = false;
            }
            if(this.corporate){
              f.accepted = true;
            }
          } else {
            f.accepted = false;
          }
        } else {
          f.accepted = true;
        }
      });
    } else {
      this.methods.forEach(f => {
        if (f.card || f.cash || f.manual) {
          f.accepted = true;
        }
      });
    }
  }

  deleteMethod(id) {
    this.companyService.deleteMethod(this.id, id).subscribe((res) => {
      this.loading = false;
      this.getMethods();
      this.toggleDeleteModal();
    }, error => {
      this.loading = false;
      this.toggleDeleteModal();
    });
  }

  toggleDeleteModal(fop?) {
    if (fop) {
      this.currentFop = fop;
    } else {
      delete this.currentFop;
    }
    $('#deleteFopModal').modal('toggle');
  }

  modifyMethodType(event: any) {
    if (event === 'MANUAL') {
      this.newMethod.manual = true;
      this.newMethod.cash = false;
    } else if (event === 'CASH') {
      this.newMethod.cash = true;
      this.newMethod.manual = false;
    } else {
      this.newMethod.cash = false;
      this.newMethod.manual = false;
    }
  }

  selectFop(method) {
    if (this.reservation) {
      this.methods.forEach(m => {
        m.selected = false;
      });
      method.selected = true;
      if(this.reservation.lowCost){
        this.reservation.creditcard = method._id;
      } else if(method.card){
        this.reservation.creditcard = method.card.indexes.lowcost
      } else {
        this.reservation.creditcard = method._id;
      }
      // this.reservation.creditcard.type = fop.type;
      // this.reservation.creditcard.type = fop.type;
    }
  }

  checkService(method): boolean {
    let valid = false;
    if (this.reservation) {
      if (this.resType === 'hotel') {
        valid = this.hotelValidation(method);
      } else {
        valid = this.genericValidation(method);
      }
    } else {
      valid = true;
    }
    return valid;
  }

  hotelValidation(method): boolean {
    console.log('method => ', method); // || this.reservation.hotel.provider === 'HB'
    if (this.reservation.hotel.provider === 'AD' || this.reservation.hotel.provider === 'XD' || this.reservation.hotel.provider === 'RI') {
      if ((method.service === '*' || method.service === this.resType) && method.cash) {
        return true;
      } else {
        console.log('test 1')
        return false;
      }  // && this.reservation.hotel.provider !== 'HB'
    } else if (this.reservation.hotel.provider !== 'AD' && this.reservation.hotel.provider !== 'XD' && this.reservation.hotel.provider !== 'RI') {
    if (method.cash || (method.service !== this.resType && method.service!=='*')) {
        return false;
      } else {
        console.log('test 2')
        return true;
      }
    } else {
      return this.genericValidation(method);
    }
  }

  genericValidation(method): boolean {
    console.log('entro generic');
    if (method.service === '*') {
      return true;
    } else {
      return method.service === this.resType;
    }
  }
}
