import {Component, Input, OnInit} from '@angular/core';
import {CostCenterDialogComponent} from '../../../shared/cost-center-dialog/cost-center-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {CostcenterService} from '../../../../services/costcenter/costcenter.service';
import {TrainService} from '../../train.service';

@Component({
  selector: 'app-sel-centro-di-costo',
  templateUrl: './sel-centro-di-costo.component.html',
  styleUrls: ['./sel-centro-di-costo.component.scss']
})
export class SelCentroDiCostoComponent implements OnInit {
  @Input() reservationId: string;
  @Input() costCenter: any;
  costCenterList: any = [];
  loadingCenters = false;
  updating = false;

  constructor(
    public dialog: MatDialog,
    public mainComponent: MainComponent,
    public trainService: TrainService,
    public costCenterService: CostcenterService,
  ) {
  }

  ngOnInit(): void {
    this.getCostsCenters();
  }

  openCostCenterDialog() {
    const dialogRef = this.dialog.open(CostCenterDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        console.log(result);
        this.costCenter = result;
        this.getCostsCenters();
        this.updateCostCenter();
      }
    });
  }

  updateCostCenter() {
    this.updating = true;
    const body = {
      costCenter: this.costCenter,
    }
    this.trainService.setTrainCostCenter(this.reservationId, body).subscribe((res: any) => {
      this.updating = false;
      console.log('this.costCenter', this.costCenter);
    }, error => {
      this.updating = false;
      console.error('Error => ', error);
    });
  }

  private getCostsCenters() {
    this.loadingCenters = true;
    const companyId = this.mainComponent.loggedUser.company._id;
    const query = {
      sort: 'createdAt',
      limit: 1000,
      skip: 0,
      order: 'asc',
      text: '',
    };
    this.costCenterService.getCostCenterList(query, companyId).subscribe((res: any) => {
      this.loadingCenters = false;
      this.costCenterList = res.list;
    }, error => {
      this.loadingCenters = false;
      console.error('Error => ', error);
    });
  }
}
