import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FeesModService} from '../fees.service';
import {ActivatedRoute} from '@angular/router';
import {CompaniesService} from '../../../services/profile/companies.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-fee-association',
  templateUrl: './fee-association.component.html',
  styleUrls: ['./fee-association.component.scss']
})
export class FeeAssociationComponent implements OnInit {
  currentFee: any;
  companiesList: any = [];
  loading: false;
  companytext: string;
  filtering: any = [];
  cpWithNames: any = [];
  unlink: any = [];
  saving = false;
  unlinking = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private feesService: FeesModService,
    private route: ActivatedRoute,
    private companiesService: CompaniesService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<FeeAssociationComponent>,
  ) { }

  ngOnInit(): void {
    this.currentFee = this.data.fee;
    console.log('this.currentFee => ', this.currentFee);
    // this.filtering = JSON.parse(JSON.stringify(this.currentFee.companies));
    this.currentFee.companies.forEach(id => {
      this.getCompanyById(id);
    })
    console.log('cpWithNames => ', this.cpWithNames);
    /*
    if(this.data.profile.company.daughters.length === 0){
      // filtering
      const cp = {
        name: this.data.profile.company.name,
        _id: this.data.profile.company._id,
      }
      if ( !this.filtering.find(r => r._id === cp._id) && !this.cpWithNames.find(r => r._id === cp._id)) {
        this.filtering.push(cp);
      }
    }
    */
  }

  getCompanyById(id){
    this.companiesService.getCompany(id).subscribe((res) => {
      console.log('res => ', res);
      const obj = {
        name: res.name,
        _id: res._id,
      }
      this.cpWithNames.push(obj);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getCompaniesList(value) {
    this.clearFrom();
    this.companiesService.getCompaniesAuto(value).subscribe((res) => {
      this.loading = false;
      this.companiesList = res;
      console.log('this.companiesList => ', this.companiesList);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  searchCompany(value) {
    delete this.companiesList;
    this.clearFrom();
    if (value.length >= 2) { this.getCompaniesList(value); }
  }

  selectComp(value) {
    if ( !this.filtering.find(r => r._id === value._id) && !this.cpWithNames.find(r => r._id === value._id)) {
      this.filtering.push(value);
    } else {
      this.snackBar.open('Azienda già inserita', 'Error', {
        duration: 2000,
      });
    }
    this.clearFrom();
  }

  selectFirst() {
    if ( this.companiesList ) {
      // tslint:disable-next-line:max-line-length
      if ( !this.filtering.find(r => r._id === this.companiesList[0]._id) && !this.cpWithNames.find(r => r._id === this.companiesList[0]._id) ) {
        this.filtering.push(this.companiesList[0]);
      } else {
        this.snackBar.open('Azienda già inserita', 'Error', {
          duration: 2000,
        });
      }
    }
    this.clearFrom();
  }

  clearFrom() {
    this.companytext = '';
    delete this.companiesList;
  }

  removeCp(value){
    const ind = this.unlink.findIndex(c => c._id === value._id);
    if (ind < 0) {
      this.unlink.push(value);
    }
  }

  removeCpFromDelete(value){
    const ind = this.unlink.findIndex(c => c._id === value._id);
    if (ind >= 0) {
      this.unlink.splice(ind, 1);
    }
  }

  removeCpFromFilter(value){
    const ind2 = this.filtering.findIndex(c => c._id === value._id);
    if (ind2 >= 0) {
      this.filtering.splice(ind2, 1);
    }
  }

  save(){
    const linkArray = [];
    const unlinkArray = [];
    this.filtering.forEach(f => {
      if(!this.currentFee.companies.includes(f._id)){
        linkArray.push(f._id);
      }
    })
    this.unlink.forEach(f => {
      unlinkArray.push(f._id);
    })
    const link = {
      companiesIds: linkArray,
    }
    const unlink = {
      companiesIds: unlinkArray,
    }
    if(linkArray.length > 0){
      this.saving = true;
      this.feesService.linkFeesToCompany(this.currentFee._id, link).subscribe((res) => {
        this.saving = false;
        this.closeDialog();
      }, error => {
        this.saving = false;
        this.closeDialog();
        console.error('Error => ', error);
      });
    }
    if(unlinkArray.length > 0){
      this.unlinking = true;
      this.feesService.unlinkFeesToCompany(this.currentFee._id, unlink).subscribe((res) => {
        this.unlinking = false;
        this.closeDialog();
      }, error => {
        this.unlinking = false;
        this.closeDialog();
        console.error('Error => ', error);
      });
    }
  }

  closeDialog(){
    if(!this.saving && !this.unlinking){
      this.dialogRef.close(true);
    }
  }

}
