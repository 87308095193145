<div mat-dialog-title>{{'ADD_COST_CENTER'|translate}}</div>
<div mat-dialog-content>
  <div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'NAME'|translate}}</mat-label>
        <input [(ngModel)]="costCenter.tag" matInput placeholder="...">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>{{'CODE'|translate}}</mat-label>
        <input [(ngModel)]="costCenter.description" matInput placeholder="">
      </mat-form-field>
    </div>
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'ABORT'|translate}}</button>
  <button mat-button (click)="addCostCenter()" [disabled]="!costCenter.tag || !costCenter.description || adding" cdkFocusInitial>{{'SAVE'|translate}}</button>
</mat-dialog-actions>
