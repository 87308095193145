import {NgModule} from '@angular/core';

import {HotelCardComponent} from './hotel-card.component';
import {PipesModule} from '../../pipes/pipes.module';
import {CommonModule} from '@angular/common';


@NgModule({
  declarations: [ HotelCardComponent ],
  exports: [
    HotelCardComponent
  ],
  imports: [
    CommonModule,
    PipesModule
  ]
})
export class HotelCardModule { }
