import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FlightService} from '../../../../../../services/flight/flight.service';
import * as $ from 'jquery';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TkbottomsheetComponent } from 'src/app/modules/ticketing/components/tkbottomsheet/tkbottomsheet.component';
/**
 * Reservation ancillary component
 */
@Component({
  selector: 'app-ancillary',
  templateUrl: './ancillary.component.html',
  styleUrls: ['./ancillary.component.scss']
})
export class AncillaryComponent implements OnInit {
  deleting: boolean;
  /**
   * Local variable for reservation passed from parent component
   */
  @Input() reservation: any;
  /**
   * Local variable for reservation id passed from parent component
   */
  @Input() reservationId: string;
  /**
   * Local variable for flights passed from parent component
   */
  @Input() flights: any[];
  /**
   * Local variable for passengers passed from parent component
   */
  @Input() passengers: any[];
  /**
   * Local variable for tabs ( one tab for any flight)
   */
  tabs = [];
  /**
   * Local variable for current active tab
   */
  currentTab: number;
  /**
   * Local variable for current ancillary category to show
   */
  category: number;
  /**
   * Local variable for loading ancillaryServices status
   */
  loading: boolean;
  /**
   * Local variable for selected passenger
   */
  currentPassenger: any;
  /**
   * Local variable for ancillaryServices
   */
  ancillaryServices: any;
  /**
   * Local variable for ancillaryServices
   */
  ancillariesSelection: any[];

  currentAncillary: any;

  // tslint:disable-next-line:no-output-rename
  @Output('someUpdate') select: EventEmitter<any> = new EventEmitter<any>();
  /**
   * The "constructor"
   */
  constructor(
    private bottomSheet: MatBottomSheet,
    /**
     *  Local variable for flightService
     */
    private flightService: FlightService,
  ) {
    this.ancillariesSelection = [];
    this.deleting = false;
  }
  /**
   * On init fire get ancillaryServices function
   */
  ngOnInit(): void {
    this.getAncillaries(this.reservationId);
    // this.generateTabs(this.flights);
    this.tabs.length = this.flights.length;
    this.currentTab = 0;
    this.currentPassenger = this.passengers[0];
  }

  /**
   * Set currentTab
   * @param i number
   */
  setCurrentTab(i) {
    this.currentTab = i;
  }
  /**
   * Call tst history for given reservation
   * @params {reservationId} string
   */
  getAncillaries(reservationId) {
    this.loading = true;
    this.flightService.getAncillary(reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.ancillaryServices = res;
      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  /**
   * Check if service is available for selected flight
   * @params service
   */
  isIncluded(service) {
    return service.flights.includes(this.flights[this.currentTab].ref);
  }

  /**
   * Set the service category to display
   * @params value
   */
  setCategory(value) {
    this.category = value;
    console.log('category', this.category);
  }

  /**
   * Show the flight included in service
   * @params fl
   */
  returnSingleFl(fl) {
    const ind = this.flights.findIndex(r => r.ref === fl);
    return this.flights[ind].departure.location + ' -> ' + this.flights[ind].arrival.location;
  }

  /**
   * Set current passenger selection
   * @params passenger
   */
  setCurrentPax(passenger) {
    this.currentPassenger = passenger;
  }

  /**
   * Add service to service selections
   * @params service
   * @params ref
   */
  addServiceToSelection(service, ref) {
    const clonedService = JSON.parse(JSON.stringify(service));
    if (this.ancillariesSelection.length === 0) {
      clonedService.availableFor = JSON.parse(JSON.stringify(service.passengers));
      clonedService.passengers = [];
      clonedService.passengers.push(ref);
      this.ancillariesSelection.push(clonedService);
      console.log('this.ancillariesSelection => ', this.ancillariesSelection);
    } else {
      const inArray = this.ancillariesSelection.findIndex( r => r.item.ref === service.item.ref);
      let paxSelected = -1;
      if (inArray > -1) {
        paxSelected = this.ancillariesSelection[inArray].passengers.findIndex( p => p === ref);
      }
      if (inArray === -1) {
        clonedService.availableFor = JSON.parse(JSON.stringify(service.passengers));
        clonedService.passengers = [];
        clonedService.passengers.push(ref);
        this.ancillariesSelection.push(clonedService);
      } else if (inArray > -1 && paxSelected === -1) {
        this.ancillariesSelection[inArray].passengers.push(ref);
      } else {
        this.ancillariesSelection[inArray].passengers.splice(paxSelected , 1);
        if (this.ancillariesSelection[inArray].passengers.length === 0) {
          this.ancillariesSelection.splice(inArray, 1);
        }
      }
      console.log('this.ancillariesSelection 2 => ', this.ancillariesSelection);
    }
  }

  /**
   * Check if service is selected for current passenger
   * @params service
   * @params ref
   */
  isSelected(service, ref) {
    let selected = false;
    const inArray = this.ancillariesSelection.findIndex( r => r.item.ref === service.item.ref);
    let paxSelected = -1;
    if (inArray > -1) {
      paxSelected = this.ancillariesSelection[inArray].passengers.findIndex( p => p === ref);
    }
    if (inArray > -1 && paxSelected > -1) {
      selected = true;
    }
    return selected;
  }

  isReserved(service, ref) {
    let selected = false;
    let paxSelected = -1;
    if (this.reservation.services.length > 0) {
      const inArray = this.reservation.services.findIndex( r => r.type === service.item.ssr && r.passenger === ref && r.price === service.price);
      if (inArray > -1) {
        /*if (this.reservation.services[inArray].passenger === ref) {
          paxSelected = 1;
        }*/
        paxSelected = 1;
      }
      if (inArray > -1 && paxSelected > -1) {
        selected = true;
      }
    }
    return selected;
  }

  calculateAncillariesTotal() {
    let total = 0;
    for ( let i = 0; i < this.ancillariesSelection.length; i++ ) {
      total += Math.round((this.ancillariesSelection[i].price * this.ancillariesSelection[i].passengers.length) * 100) / 100;
    }
    return total;
  }

  saveAncillaries(reservationId) {
    this.loading = true;
    const body = {
      services: this.ancillariesSelection,
    };
    this.flightService.saveAncillaries(body, reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.select.emit();
      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  removeAncillaries(service, reservationId, type, ref) {
    const body = {
      service,
      type,
      ref,
      reservationId,
    };
    this.toggleDeleteModal(body);
  }

  toggleDeleteModal(body) {
    if (body) {
      this.currentAncillary = body;
    } else {
      delete this.currentAncillary;
    }
    $('#deleteAncillary').modal('toggle');
  }

  removeAncillariesApi(body) {
    this.deleting = true;
    console.log('body =>', body);
    this.toggleDeleteModal(undefined);
    this.deleting = false;

    this.flightService.removeAncillaries(body).subscribe((res) => {
      console.log('Res => ', res);
      this.select.emit();
      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  openBottomSheetEmission() {
    this.bottomSheet.open(TkbottomsheetComponent, {
      data: {
        seatMap: false,
        ancillary: true,
        emission: false,
        discount: false,
        blocked: false,
        locator:  this.reservation.locator,
        text: 'ASK_ANCILLARY_SERVICE',
      },
      // disableClose: true,
    });
  }
}
