<form [formGroup]="form">
  <div class="form-container">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
      <mat-error *ngIf="form.get('name')?.errors?.required">Name is required.</mat-error>
    </mat-form-field>

    <div class="buttonrow">
      <button mat-raised-button color="primary" (click)="handleSubmit()" [disabled]="loading">
        <span style="display: flex; align-items: center; justify-content: flex-start">
          <mat-spinner diameter="20" *ngIf="loading" style="margin-right: 5px"> </mat-spinner>
          <span>Save</span>
        </span>
      </button>
    </div>
  </div>
</form>
