import {UntypedFormGroup, ValidationErrors} from '@angular/forms';

export function validateAccessDialog(form: UntypedFormGroup): ValidationErrors | null {
  const radioCtrl = form.get('radioButton');
  const rolesCtrl = form.get('authorization_roles');
  const usersCtrl = form.get('authorization_users');

  if (radioCtrl.value === '1') {
    return null;
  }
  if (!rolesCtrl?.value || !usersCtrl?.value) {
    return { empty: true };
  }

  if (rolesCtrl?.value?.length === 0 && usersCtrl?.value?.length === 0) {
    return { empty: true };
  }

  if (rolesCtrl?.value?.length > 0 || usersCtrl?.value?.length > 0) {
    form.setErrors({});
    return null;
  }
  if (rolesCtrl?.value?.length === 0 || !usersCtrl?.value) {
    return { empty: true };
  }
  if (!rolesCtrl?.value ||  usersCtrl?.value?.length === 0) {
    return { empty: true };
  }

  form.setErrors({});
  return null;

}
