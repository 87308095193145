import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as $ from 'jquery';
import { TrainService } from '../../../../train.service';
import { CancelReservationResponse } from '../../../../classes/train.models';


@Component({
  selector: 'train-cancel',
  templateUrl: './train-cancel.component.html',
  styleUrls: ['./train-cancel.component.scss'],
})
export class TrainCancelComponent implements OnInit{
  @Input() isDisabled : boolean;
  @Input() pnr : string;
  @Output() reloadEvent = new EventEmitter<any>();

  cancelReservation$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  deleting : boolean;
  cancelAttempt : boolean = false;
  errorMessage : string;

  /**
   *  Constructor
   */
  constructor(
    private trainService : TrainService,
  ){ }

  /**
   *  OnInit function is empty for now
   */
  ngOnInit(){
  }

  /**
   *  Toggle modal for cancellation confirm
   */
  toggleCancelModal(){
    this.cancelAttempt = false;
    this.errorMessage = undefined;
    $('#cancelPnrModal').modal('toggle');
  }

  cancelPNR(){
    this.cancelReservation$.next(true);
    this.trainService.cancelReservation(this.pnr).subscribe((action : CancelReservationResponse) => {
      this.cancelReservation$.next(false);
      this.cancelAttempt = true;
      this.reload();
    }, error => {
      this.cancelAttempt = false;
      this.cancelReservation$.next(false);
      this.errorMessage = error.message;
    });
  }

  reload(){
    this.reloadEvent.emit(true);
  }
}
