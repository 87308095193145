import {Component, OnInit} from '@angular/core';
import {AuthorizationRequestsService} from '../../services/authorization-requests.service';
import {take} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-all-authorization-requests',
  templateUrl: './all-authorization-requests.component.html',
  styleUrls: [ './all-authorization-requests.component.scss' ]
})
export class AllAuthorizationRequestsComponent implements OnInit {

  authorizations$ = this.authorizationsService.getAuthorizations();

  constructor(
    private authorizationsService: AuthorizationRequestsService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
  }

  handleSubmit(payload: any): void {
    this.authorizationsService.sendAuthorizationResponse(payload).subscribe(result => {
        this.snackBar.open('Authorization sent successfully', 'Close');
        this.authorizations$ = this.authorizationsService.getAuthorizations();
      },
      error => {
        this.snackBar.open(error.message, 'Close');
      }
    );
  }

}
