import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  Input,
  IterableDiffers,
  OnInit,
  ViewChild,
} from '@angular/core';
import 'bootstrap';
import MarkerClusterer from '@google/markerclusterer';
import * as $ from 'jquery';
import {
  MatPaginator,
  PageEvent,
} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { NewTraveller } from '../../../../../../classes/travelers/newTraveller';
import { Router } from '@angular/router';
import { DossierService } from '../../../../../../services/dossier/dossier.service';
import { AppComponent } from '../../../../../../app.component';
import { TravelersService } from '../../../../../../services/travelers/travelers.service';
import { HotelService } from '../../../../hotel.service';

declare var google : any;

/**
 *  Component for hotel results
 */
@Component({
  selector: 'app-hotelsearchresults',
  templateUrl: './searchresults.component.html',
  styleUrls: ['./searchresults.component.scss'],
})
export class SearchHotelresultsComponent implements OnInit, DoCheck{
  tabIndex = 0;
  currentTraveler : number;
  loadTravelers : boolean;
  editingTraveler : boolean;
  searchName : string;
  hotelsProviders = [];
  providersToFilter = [];
  sorting : string;
  sarce : any;
  numberOfNights : number;
  refoundToFilter = [];
  roomsLoaded = false;
  hotelsToFilter = [];
  availableHotels = [];
  /**
   * Local variable for angular paginator
   */
  @ViewChild(MatPaginator) paginator : MatPaginator;
  /**
   * Local variable for array paginator
   */
  obs : Observable<any>;
  /**
   * Local variable for paginator datasource
   */
  dataSource : MatTableDataSource<any>;
  /**
   * Local variable for angular paginator pagesize
   */
  pageSize = 10;
  /**
   * Local variable for angular paginator page size options
   */
  pageSizeOptions : number[] = [5, 10];
  /**
   * Local variable for angular paginator page event
   */
  pageEvent : PageEvent;
  /**
   * Local variable to set search form visible or not
   */
  formvisible : boolean;
  /**
   *  local variable Input hotel results from parent component
   */
  @Input() hotelRes : any;
  @Input() hotelSearch : any;
  @Input() hotelDb : any;
  /**
   *  local variable current hotel
   */
  currentHotel : any;
  /**
   *  local variable for map
   */
  map : any;
  /**
   *  local variable for clusterMarker
   */
  clusterMarker : any;
  /**
   *  local variable for markers
   */
  markers : any;
  /**
   *  local variable for markers
   */
  hotelToGetInfos : string[];
  /**
   *  local variable for loading infos status
   */
  loadingInfos : boolean;
  /**
   *  local variable for hotels infos
   */
  hotelsInfos : any;
  /**
   *  local variable for show map status
   */
  showMap : boolean;
  /**
   *  The constructor
   */
  iterableDiffer : any;
  resultsLength : number;
  changeCount : number;
  filteredRes : any[];
  currentPage : number;
  loading : boolean;
  clonedResults : any;
  allStars = [];
  starsToFilter = [];
  passengers = [];
  mealsType = [];
  mealsToFilter = [];

  constructor(
    private hotelService : HotelService,
    private changeDetectorRef : ChangeDetectorRef,
    private iterableDiffers : IterableDiffers,
    private router : Router,
    private dossierService : DossierService,
    public appComponent : AppComponent,
    private travelersService : TravelersService,
  ){
    this.iterableDiffer = iterableDiffers.find([]).create(null);
    this.hotelToGetInfos = [];
    this.showMap = false;
    this.formvisible = true;
    this.markers = [];
    this.searchName = '';
    this.sorting = 'RELEVANCE';
    if (localStorage.getItem('sarceDossier')){
      this.sarce = JSON.parse(localStorage.getItem('sarceDossier'));
      // this.getTravelerCid();
    } else{
      delete this.sarce;
    }

  }

  /**
   *  On init start with pushRooms function
   */
  getTravelerDossier(){
    this.travelersService.getTraveler(this.passengers[0]._id).subscribe((res : any) => {
      if (!res.type){
        res.type = 'ADT';
      }
      this.travelerSet(res, 0);
    }, error => {
      console.error('res => ', error);
    });
  }

  calculateNights(){
    const date1 = new Date(this.hotelSearch.checkIn);
    const date2 = new Date(this.hotelSearch.checkOut);
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    this.numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

  getTravelerCid(){
    // console.log('this.sarce.cid => ', this.sarce.cid);
    this.travelersService.getTravelerCid(this.sarce.cid).subscribe((res : any) => {
      res.type = 'ADT';
      this.travelerSet(res, 0);
    }, error => {
      console.error('res => ', error);
    });
  }

  ngOnInit() : void{
    this.doSort(undefined);
    this.calculateNights();
    this.toggleSearch();
    // console.log('this.hotelRes => ', this.hotelRes);
    // this.getDataAndMap();
    this.changeCount = 0;
    this.resultsLength = this.filteredRes.length;
    // this.pushServices();
    this.hotelRes.hotels.forEach(hotel => {
      if (!this.hotelsProviders.includes(hotel.chain)){
        this.hotelsProviders.push(hotel.chain);
      }
      if (hotel.rating && !this.allStars.includes(hotel.rating)){
        this.allStars.push(hotel.rating);
        this.allStars.sort((a, b) => a - b);
        // console.log('hotel.rating => ', hotel.rating);
      }

    });
    // console.log('HOTEL RES => ', this.hotelRes);
    this.getNewDataSource();
    this.initMap();
  }

  setPrices(){
    this.filteredRes.forEach(hotel => {

      const p2 = {
        lat: hotel.geo.latitude,
        lng: hotel.geo.longitude,
      };
      if (!hotel.distance && this.hotelSearch.destination){
        hotel.distance = this.getDistance(p2);
      }
      hotel.hotels.forEach(subHotel => {
        subHotel.rooms.forEach(room => {
          const indice = subHotel.roomsV2.findIndex(r => r.index === room);
          if (indice !== - 1){
            if (!hotel.minPrice || subHotel.roomsV2[indice].roomRates[0].amount < hotel.minPrice){
              hotel.currency = subHotel.roomsV2[indice].currency;
              hotel.minPrice = subHotel.roomsV2[indice].roomRates[0].amount;
            }
          }
        });
      });
    });
  }

  rad(x){
    return x * Math.PI / 180;
  }

  getDistance(p2){
    const p1 = {
      lat: this.hotelSearch.destination.coordinates[1],
      lng: this.hotelSearch.destination.coordinates[0],
    };
    const R = 6378137; // Raggio della terra in metri
    const dLat = this.rad(p2.lat - p1.lat);
    const dLong = this.rad(p2.lng - p1.lng);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  checkValue(a){
    if (a < 1){
      return Math.round(a * 1000) + 'm';
    } else{
      return Math.round(a * 10) / 10 + 'km';
    }
  }

  ngDoCheck(){
    const changes = this.iterableDiffer.diff(this.hotelRes.hotels);
    if (changes){
      // console.log('Hotel res con camere => ', this.hotelRes.hotels);
      this.hotelRes.hotels.forEach(hotel => {
        if (!this.hotelsProviders.includes(hotel.chain)){
          this.hotelsProviders.push(hotel.chain);
        }
        if (hotel.rating && !this.allStars.includes(hotel.rating)){
          this.allStars.push(hotel.rating);
          this.allStars.sort((a, b) => a - b);
          // console.log('hotel.rating => ', hotel.rating);
        }
      });
      this.doSort(undefined);
      this.changeCount += 1;
      if (this.changeCount > 1){
        // console.log('resultsLength => ', this.resultsLength);
        const hotels = JSON.parse(JSON.stringify(this.filteredRes));
        hotels.splice(0, this.resultsLength);
        this.resultsLength = this.filteredRes.length;
        for (let i = 0; i < hotels.length; i ++){
          this.addMarker(hotels[i]);
        }
        // this.doSort();
      }
      this.getNewDataSource();
    }
    /* const changes = this.iterableDiffer.diff(this.hotelRes.hotels);
     if (changes) {
     this.changeCount += 1;
     if (this.changeCount > 1) {
     // console.log('resultsLength => ', this.resultsLength);
     const hotels = JSON.parse(JSON.stringify(this.hotelRes.hotels));
     hotels.splice(0, this.resultsLength);
     this.resultsLength = this.hotelRes.hotels.length;
     this.hotelRes.hotels.forEach( hotel => {
     if (!this.hotelsProviders.includes(hotel.chain)) {
     this.hotelsProviders.push(hotel.chain);
     }
     });
     for (let i = 0; i < hotels.length; i++) {
     // this.addMarker(hotels[i]);
     }
     // this.doSort();
     }
     } */
  }

  /**
   *  push rooms in relative hotel ordered by price, later hotel will be sorted by price
   */
  pushRooms(){

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.hotelRes.hotels.length; i ++){
      const hotel = this.hotelRes.hotels[i];
      hotel.hotelRooms = hotel.rooms.map(r => this.hotelRes.rooms[r]);
      hotel.hotelRooms.sort((a, b) => a.amount - b.amount);
    }
    this.hotelRes.hotels.sort((a, b) => a.hotelRooms[0].amount - b.hotelRooms[0].amount);
    if (this.map){
      delete this.map;
    }
    this.initMap();
    this.getDataSource();
  }

  getDataAndMap(){
    this.initMap();
  }

  getDataSource(){
    // console.log('dataSourceAll =>', this.dataSource.connect().value);
    const data = this.dataSource.connect().value;
    this.hotelToGetInfos = [];
    for (let i = 0; i < data.length; i ++){
      this.hotelToGetInfos = [];
      const hotel = data[i];
      if (!hotel.detailInfo){
        this.hotelToGetInfos.push(hotel.hotelCode);
      }
      // this.getHotelInfos();
    }
    // console.log('this.hotelToGetInfos => ', this.hotelToGetInfos);
    // this.getHotelInfos();
  }

  /**
   *  push services in relative hotel
   */
  pushServices(){
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.hotelRes.rooms.length; i ++){
      const room = this.hotelRes.rooms[i];
      room.roomServices = room.services.map(r => this.hotelRes.services[r]);
    }
    this.pushRooms();
  }

  /**
   * Create google map using hotel in hotel result
   */
  initMap() : void{
    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 41.90313516565217, lng: 12.493317924529677 },
      // zoom: 8,
    });
    // console.log('hotelForm => ', this.hotelSearch);
    const image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
    let myLatlng;
    if (this.hotelSearch.destination){
      myLatlng = new google.maps.LatLng(this.hotelSearch.destination.coordinates[1], this.hotelSearch.destination.coordinates[0]);
    } else{
      myLatlng = new google.maps.LatLng(41.90313516565217, 12.493317924529677);
    }
    const marker = new google.maps.Marker({
      position: myLatlng,
      title: 'Punto di interesse',
      icon: image,
    });
    const infoWindow = new google.maps.InfoWindow({
      content: '<p style="font-size:12px;">' + marker.title + '</p>',
    });
    marker.addListener('mouseover', () => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    marker.addListener('mouseout', () => {
      infoWindow.close();
      marker.setZIndex(1);
    });
    marker.setMap(this.map);
    this.createMarkers();
  }

  /**
   * Create markers for google map
   */
  createMarkers() : void{
    const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    // console.log('this.filteredRes MAPPA =>', this.filteredRes);
    this.markers = this.filteredRes.map((hotel, i) => {
      let infoWindow;
      if (hotel.hotels){
        infoWindow = new google.maps.InfoWindow({
          content: '<h5>' + hotel.name + '</h5>' + '<p style="font-size:12px;"> Providers: ' + hotel.hotels.length + '</p>',
        });
      } else{
        infoWindow = new google.maps.InfoWindow({
          content: '<h5>' + hotel.name + '</h5>',
        });
      }

      const marker = new google.maps.Marker({
        position: { lat: hotel.geo.latitude, lng: hotel.geo.longitude },
        label: 'H',
        // label: labels[i % labels.length],
      });
      marker.addListener('mouseover', () => {
        infoWindow.open(this.map, marker);
        marker.setZIndex(100);
      });
      marker.addListener('mouseout', () => {
        infoWindow.close();
        marker.setZIndex(1);
      });
      if (hotel.hotels){
        marker.addListener('click', () => {
          this.toggleNewSingleHotel(hotel);
        });
      }
      return marker;
    });
    const bounds = new google.maps.LatLngBounds();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.markers.length; i ++){
      bounds.extend(this.markers[i].getPosition());
    }
    this.map.fitBounds(bounds);
    this.clusterMarkers(this.markers);
  }

  /**
   * Cluster all markers in google map
   */
  clusterMarkers(makers) : void{
    this.clusterMarker = new MarkerClusterer(
      this.map,
      makers,
      { maxZoom: 14, imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' },
    );
  }

  addMarker(hotel){
    let infoWindow;
    if (hotel.hotels){
      infoWindow = new google.maps.InfoWindow({
        content: '<h5>' + hotel.name + '</h5>' + '<p style="font-size:12px;"> Providers: ' + hotel.hotels.length + '</p>',
      });
    } else{
      infoWindow = new google.maps.InfoWindow({
        content: '<h5>' + hotel.name + '</h5>',
      });
    }

    const marker = new google.maps.Marker({
      position: { lat: hotel.geo.latitude, lng: hotel.geo.longitude },
      label: 'H',
      // label: labels[i % labels.length],
    });
    marker.addListener('mouseover', () => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    marker.addListener('mouseout', () => {
      infoWindow.close();
      marker.setZIndex(1);
    });
    if (hotel.hotels){
      marker.addListener('click', () => {
        this.toggleNewSingleHotel(hotel);
      });
    }
    this.markers.push(marker);
    this.clusterMarker.addMarker(marker);
  }

  /**
   *  Open modal to manage rooms of selected hotel
   *  @params hotel
   */
  toggleSingleHotel(hotel){
    this.tabIndex = 0;
    this.hotelToGetInfos = [];
    if (hotel){
      this.hotelToGetInfos.push(hotel.hotelCode);
      this.currentHotel = hotel;
      this.getHotelInfos();
    } else{
      delete this.currentHotel;
    }
    $('#singleHotelModal').modal('toggle');
  }

  getHotelInfos(){
    const body = {
      hotels: this.hotelToGetInfos,
    };
    /*const body = {
     hotels: this.hotelToGetInfos.splice(1, 1),
     };*/
    this.loadingInfos = true;
    this.hotelService.askHotelInfos(body).subscribe(res => {
      this.loadingInfos = false;
      // console.log('hotelsInfos =>', res);
      this.hotelsInfos = res;
      this.setHotelInfos();
    }, error => {
      this.loadingInfos = false;
      // console.log('error hotelsInfos =>', error);
    });
  }

  setHotelInfos(){
    for (const hotel of this.hotelRes.hotels){
      const index = this.hotelsInfos.findIndex(r => r.code === hotel.hotelCode);
      if (index !== - 1){
        hotel.detailInfo = this.hotelsInfos[index];
        if (this.currentHotel){
          this.currentHotel.detailInfo = this.hotelsInfos[index];
        }
      }
    }
    // console.log('this.hotelRes.hotels =>', this.hotelRes.hotels);
  }

  pageChanged(event){
    setTimeout(() => {
      this.scrollTop();
      // this.getDataSource();
      this.getNewDataSource();
    }, 200);
  }

  scrollTop(){
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }

  toggleSearch(){
    this.formvisible = !this.formvisible;
    $('#searchCard').collapse('toggle');
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }

  toggleFilters(){
    $('.filterSearch').toggleClass('openfilters');
    $('.filtersearchBackdrop').toggleClass('openfiltersearchBackdrop');
  }

  /**
   *  Hide or show map function
   */
  toggleMap(){
    this.showMap = !this.showMap;
    $('.hotelMapContainer').toggleClass('openMap');
    setTimeout(() => {
      const bounds = new google.maps.LatLngBounds();
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.markers.length; i ++){
        bounds.extend(this.markers[i].getPosition());
      }
      this.map.fitBounds(bounds);
      // this.initMap();
    }, 200);
    // this.createMarkers();
  }

  changeIndex(value){
    this.tabIndex = value;
  }

  roomSelect(event){
    // console.log('this.hotelSearch => ', this.hotelSearch);
    this.currentHotel.selecetedHotel = event.hotel;
    this.currentHotel.roomSelected = event.room;
    this.currentHotel.fareSelected = event.fare;
    // console.log('ROOM SELECTED => ', event);
    $('.modal-body').animate({ scrollTop: 0 }, 300);
    setTimeout(() => {
      this.tabIndex = 2;
    }, 400);
    const traveler = new NewTraveller();
    this.currentHotel.guests = [];
    let adtCount = 0;
    let chdCount = 0;
    let overCount = 0;
    for (let i = 0; i < this.hotelSearch.occupancy.length; i ++){
      for (let g = 0; g < this.hotelSearch.occupancy[i].guests.length; g ++){
        if (this.hotelSearch.occupancy[i].guests[g].code === 10){
          adtCount += this.hotelSearch.occupancy[i].guests[g].quantity;
        } else if (this.hotelSearch.occupancy[i].guests[g].code === 2){
          overCount += this.hotelSearch.occupancy[i].guests[g].quantity;
        } else{
          chdCount += this.hotelSearch.occupancy[i].guests[g].quantity;
        }
      }
    }
    const tr = JSON.parse(JSON.stringify(traveler));
    for (let i = 0; i < adtCount; i ++){
      tr.type = 'ADT';
      this.currentHotel.guests.push(tr);
    }
    for (let i = 0; i < overCount; i ++){
      tr.type = 'ADT';
      this.currentHotel.guests.push(tr);
    }
    for (let i = 0; i < chdCount; i ++){
      tr.type = 'CHD';
      this.currentHotel.guests.push(tr);
    }
    // tslint:disable-next-line:prefer-for-of
    /*for (let g = 0; g < event.fare.guests.length; g++) {
     if (event.fare.guests[g].ageCode === 'Adult') {
     traveler.type = 'ADT';
     }
     this.currentHotel.guests.push(traveler);
     }*/
    if (localStorage.getItem('sarceDossier')){
      this.getTravelerCid();
    }
    if (localStorage.getItem('dossierPassengers')){
      this.passengers = JSON.parse(localStorage.getItem('dossierPassengers'));
      this.getTravelerDossier();
    }
  }

  toggleDialog(currentTraveler){
    this.currentTraveler = currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  toggleEditDialog(currentTraveler){
    this.editingTraveler = true;
    this.currentTraveler = currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  closeEvent(event){
    delete this.currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  closeEditEvent(event){
    this.editingTraveler = false;
    delete this.currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  closeListEvent(event){
    delete this.currentTraveler;
    $('#listTravelerModal').modal('toggle');
  }

  loadTravelersList(currentTraveler){
    this.currentTraveler = currentTraveler;
    $('#listTravelerModal').modal('toggle');
    this.loadTravelers = true;
  }

  travelerSet(event, i){
    this.currentHotel.guests[i] = event;
    const z = 1;
    this.currentHotel.guests[i].ref = i + z + 1;
    // console.log('this.data.passengers => ', this.currentHotel.guests);
    delete this.currentTraveler;
    // $('#addTravelerModal').modal('toggle');
  }

  removeTraveler(i){
    Object.keys(this.currentHotel.guests[i]).forEach(k => delete this.currentHotel.guests[i][k]);
  }

  travelerController(){
    const isValidTraveler = (value) => !(!value.name || !value.surname || !value.phoneNumber);
    return this.currentHotel.guests.every(isValidTraveler);
  }

  bookHotel(){
    // console.log('this.currentHotel => ', this.currentHotel);
    delete this.currentHotel.roomSelected.hotel;
    const hotelTobuy = JSON.parse(JSON.stringify(this.currentHotel));
    const reservation = {
      hotel: {
        code: hotelTobuy.selecetedHotel.hotelCode,
        name: hotelTobuy.selecetedHotel.name,
        provider: hotelTobuy.selecetedHotel.hotelCode.slice(0, 2),
        checkIn: hotelTobuy.roomSelected.startDate,
        checkOut: hotelTobuy.roomSelected.endDate,
        city: hotelTobuy.selecetedHotel.cityCode,
        address: hotelTobuy.hotels[0].address,
      },
      guests: hotelTobuy.guests,
      room: hotelTobuy.roomSelected,
      session: hotelTobuy.selecetedHotel.session,
      b2b: false,
      // room: this.currentHotel.roomSelected.room,
    };
    if (this.appComponent.isB2B){
      reservation.b2b = true;
    }

    // CONVERTING ALL AMOUNT IN EUR FROM LOCAL CURRENCY
    if (reservation.room.currency !== 'EUR'){
      reservation.room.amount = this.convertPrice(reservation.room.amount, reservation.room.currency);
      reservation.room.roomRates[0].amount = this.convertPrice(reservation.room.roomRates[0].amount, reservation.room.currency);
      if (reservation.room.ratePlans[0]){
        if (reservation.room.ratePlans[0].penalty){
          for (let p = 0; p < reservation.room.ratePlans[0].penalty.length; p ++){
            reservation.room.ratePlans[0].penalty[p].amount = this.convertPrice(reservation.room.ratePlans[0].penalty[p].amount, reservation.room.currency);
          }
        }
      }
    }

    // console.log('reservation => ', reservation);

    this.hotelService.postReservation(reservation).subscribe(resp => {
      this.loading = false;
      // console.log(resp);

      if (localStorage.getItem('fl_dossierId')){
        const dossierId = JSON.parse(localStorage.getItem('fl_dossierId'));
        const body = {
          hotels: [resp._id],
        };
        this.dossierService.addHotelsToDossier(dossierId, body).subscribe((res) => {
          // console.log('res => ', res);
        }, error => {
          // console.error('res => ', error);
        });
      }

      this.router.navigate(['/hotel/reservations/' + resp._id]);
    }, error => {
      alert(error);
      this.loading = false;
    });

  }

  someFilterSelected(){
    let filters = 0;
    if (this.searchName !== ''){
      filters += 1;
    }
    if (this.providersToFilter.length > 0){
      filters += 1;
    }
    if (this.hotelsToFilter.length > 0){
      filters += 1;
    }
    if (this.starsToFilter.length > 0){
      filters += 1;
    }
    return filters;
  }

  checkName(value){
    if (this.searchName === '' || value.toLowerCase().includes(this.searchName.toLowerCase())){
      return true;
    }
  }

  checkProvider(value){
    if (this.providersToFilter.length === 0 || this.providersToFilter.some(r => value.includes(r))){
      return true;
    }
  }

  checkHotel(value){
    if (this.hotelsToFilter.length === 0 || this.hotelsToFilter.some(r => value.includes(r))){
      return true;
    }
  }

  checkStar(value){
    if (this.starsToFilter.length === 0 || this.starsToFilter.some(r => value === r)){
      return true;
    }
  }

  manageProviders(value){
    const index = this.providersToFilter.indexOf(value);
    if (index > - 1){
      this.providersToFilter.splice(index, 1);
    } else{
      this.providersToFilter.push(value);
    }
    this.doSort('provider');
  }

  manageHotels(value){
    const index = this.hotelsToFilter.indexOf(value);
    if (index > - 1){
      this.hotelsToFilter.splice(index, 1);
    } else{
      this.hotelsToFilter.push(value);
    }
    this.doSort('hotel');
  }

  manageStars(value){
    const index = this.starsToFilter.indexOf(value);
    if (index > - 1){
      this.starsToFilter.splice(index, 1);
    } else{
      this.starsToFilter.push(value);
    }
    this.doSort('stars');
  }

  isProviderSelected(value){
    return this.providersToFilter.includes(value);
  }

  isHotelselected(value){
    return this.hotelsToFilter.includes(value);
  }

  isStarSelected(value){
    return this.starsToFilter.includes(value);
  }

  doSort(value){
    this.filteredRes = [];
    /*for (let i = 0; i < this.hotelRes.hotels.length; i++) {
     const hotel = this.hotelRes.hotels[i];
     if (this.checkName(hotel.name) && this.checkProvider(hotel.chain)) {
     this.filteredRes.push(hotel);
     }
     }*/
    for (let i = 0; i < this.hotelDb.length; i ++){
      const hotel = this.hotelDb[i];
      if (hotel.hotels){
        hotel.providers = [];
        hotel.hotels.forEach(h => {
          hotel.providers.push(h.chain);
        });
        if (!this.availableHotels.includes(hotel.name)){
          this.availableHotels.push(hotel.name);
          this.availableHotels.sort((a, b) => a - b);
          // console.log('hotel.rating => ', hotel.rating);
        }
      }
      if (hotel.hotels && this.checkName(hotel.name) && this.checkProvider(hotel.providers) && this.checkStar(hotel.rating) && this.checkHotel(hotel.name)){
        this.filteredRes.push(hotel);
      }
    }
    this.setPrices();
    if (this.sorting === 'STAR_ASCENDING'){
      this.filteredRes.sort((a, b) => parseFloat(a.rating) - parseFloat(b.rating));
      setTimeout(() => {
        this.scrollTop();
        this.getNewDataSource();
      }, 200);
    } else if (this.sorting === 'STAR_DESCENDING'){
      this.filteredRes.sort((a, b) => parseFloat(b.rating) - parseFloat(a.rating));
      setTimeout(() => {
        this.scrollTop();
        this.getNewDataSource();
      }, 200);
    }
    if (this.sorting === 'ASCENDING'){
      this.filteredRes.sort((a, b) => parseFloat(a.minPrice) - parseFloat(b.minPrice));
      setTimeout(() => {
        this.scrollTop();
        this.getNewDataSource();
      }, 200);
    } else if (this.sorting === 'DESCENDING'){
      this.filteredRes.sort((a, b) => parseFloat(b.minPrice) - parseFloat(a.minPrice));
      setTimeout(() => {
        this.scrollTop();
        this.getNewDataSource();
      }, 200);
    } else if (this.sorting === 'ALPHABETIC_ASC'){
      this.filteredRes.sort((a, b) => a.name.localeCompare(b.name));
      setTimeout(() => {
        this.scrollTop();
        this.getNewDataSource();
      }, 200);
    } else if (this.sorting === 'ALPHABETIC_DESC'){
      this.filteredRes.sort((a, b) => b.name.localeCompare(a.name));
      setTimeout(() => {
        this.scrollTop();
        this.getNewDataSource();
      }, 200);
    } else if (this.sorting === 'DISTANCE_ASCENDING'){
      this.filteredRes.forEach(hotel => {
        hotel.sortingDistance = Math.round(hotel.distance * 1000);
      });
      this.filteredRes.sort((a, b) => a.sortingDistance - b.sortingDistance);
      setTimeout(() => {
        this.scrollTop();
        this.getNewDataSource();
      }, 200);
    } else if (this.sorting === 'DISTANCE_DESCENDING'){
      this.filteredRes.forEach(hotel => {
        hotel.sortingDistance = Math.round(hotel.distance * 1000);
      });
      this.filteredRes.sort((a, b) => b.sortingDistance - a.sortingDistance);
      setTimeout(() => {
        this.scrollTop();
        this.getNewDataSource();
      }, 200);
    }
    // console.log('filtrati => ', this.filteredRes);
    this.dataSource = new MatTableDataSource<any>(this.filteredRes);
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;

    if (value){
      this.currentPage = 0;
      this.paginator.firstPage();
      this.getNewDataSource();
    }
    this.obs = this.dataSource.connect();
    // console.log('this.obs => ', this.obs);
    // this.getDataSource();
  }

  setSort(value){
    this.clonedResults = { ...this.filteredRes };
    this.sorting = value;
    this.doSort(value);
  }

  getNewDataSource(){
    const data = this.dataSource.connect().value;
    this.hotelToGetInfos = [];
    for (let i = 0; i < data.length; i ++){
      // this.hotelToGetInfos = [];
      /*for (let h = 0; h < data[i].hotels.length; h++) {
       const hotel = data[i].hotels[h];
       this.hotelToGetInfos.push(hotel.hotelCode);
       // this.getHotelNewInfos();
       }*/
      // console.log('data[i] => ', data[i]);
      // console.log('data.length => ', data.length);
      if (!data[i].hotels[0].detailInfo){
        this.hotelToGetInfos.push(data[i].hotels[0].hotelCode);
      }
      // console.log('hotelToGetInfos => ', this.hotelToGetInfos);
    }
    if (this.hotelToGetInfos.length > 0){
      this.getHotelNewInfos();
    }

    // console.log('this.hotelToGetInfos => ', this.hotelToGetInfos);
    // this.getHotelInfos();
  }

  getHotelNewInfos(){
    const body = {
      hotels: this.hotelToGetInfos,
    };
    /*const body = {
     hotels: this.hotelToGetInfos.splice(1, 1),
     };*/
    this.loadingInfos = true;
    this.hotelService.askHotelInfos(body).subscribe(res => {
      this.loadingInfos = false;
      // console.log('hotelsInfos =>', res);
      this.hotelsInfos = res;
      this.setNewHotelInfos();
    }, error => {
      this.loadingInfos = false;
      // console.error('error hotelsInfos =>', error);
    });
  }

  setNewHotelInfos(){
    this.roomsLoaded = false;
    for (const hotel of this.filteredRes){
      for (const h of hotel.hotels){
        const index = this.hotelsInfos.findIndex(r => r.code === h.hotelCode);
        if (index !== - 1){
          h.detailInfo = this.hotelsInfos[index];
          /* if (this.currentHotel) {
           this.currentHotel.detailInfo = this.hotelsInfos[index];
           } */
        }
      }
    }
    if (this.currentHotel){
      for (const h of this.currentHotel.hotels){
        const index = this.hotelsInfos.findIndex(r => r.code === h.hotelCode);
        if (index !== - 1){
          h.detailInfo = this.hotelsInfos[index];
        }
      }
    }
    if (this.currentHotel){
      this.currentHotel.roomList = [];
      this.currentHotel.mealToFilter = [];
      this.currentHotel.providerToFilter = [];
      for (const h of this.currentHotel.hotels){
        for (const r of h.roomsV2){
          r.chain = h.chain;
          r.hotel = JSON.parse(JSON.stringify(h));
          delete r.hotel.roomsV2;
          this.currentHotel.roomList.push(r);
          this.createFilters(r);
        }
      }
      this.currentHotel.filteredRoomList = this.currentHotel.roomList.slice();
      this.currentHotel.filteredRoomList = this.currentHotel.filteredRoomList.sort((a, b) => a.roomRates[0].amount - b.roomRates[0].amount);
      this.roomsLoaded = true;
      console.log('filteredRoomList => ', this.currentHotel.filteredRoomList);
    }
    // console.log('this.filteredRes =>', this.filteredRes);
  }

  isFreeCancellation(room){
    return room.ratePlans[0].penalty[0] && room.ratePlans[0].penalty[0].absoluteDeadLine;
  }

  createFilters(room){
    if (!this.currentHotel.mealsTypes){
      this.currentHotel.mealsTypes = [];
    }
    if (!this.currentHotel.hotelProviders){
      this.currentHotel.hotelProviders = [];
    }
    if (!this.currentHotel.mealsTypes.includes(room.ratePlans[0].mealPlan)){
      this.currentHotel.mealsTypes.push(room.ratePlans[0].mealPlan);
    }
    if (!this.currentHotel.hotelProviders.includes(room.chain)){
      this.currentHotel.hotelProviders.push(room.chain);
    }
    if (this.isFreeCancellation(room)){
      room.refoundable = true;
    } else{
      room.refoundable = false;
    }
    // console.log('this.currentHotel.roomList', this.currentHotel.roomList);
  }

  setMeal(meal){
    const index = this.currentHotel.mealToFilter.indexOf(meal);
    if (index > - 1){
      this.currentHotel.mealToFilter.splice(index, 1);
    } else{
      this.currentHotel.mealToFilter.push(meal);
    }
  }

  setProvider(provider){
    const index = this.currentHotel.providerToFilter.indexOf(provider);
    if (index > - 1){
      this.currentHotel.providerToFilter.splice(index, 1);
    } else{
      this.currentHotel.providerToFilter.push(provider);
    }
  }

  isMealSelected(value){
    return this.currentHotel.mealToFilter.includes(value);
  }

  isCProviderSelected(value){
    return this.currentHotel.providerToFilter.includes(value);
  }

  checkMeal(room) : boolean{
    return this.currentHotel.mealToFilter.length === 0 || this.currentHotel.mealToFilter.includes(room.ratePlans[0].mealPlan);
  }

  checkCProvider(room) : boolean{
    return this.currentHotel.providerToFilter.length === 0 || this.currentHotel.providerToFilter.includes(room.chain);
  }

  checkRefound(room) : boolean{
    return this.refoundToFilter.length === 0 || this.refoundToFilter.includes(room.refoundable);
  }

  setRefound(value){
    const index = this.refoundToFilter.indexOf(value);
    if (index > - 1){
      this.refoundToFilter.splice(index, 1);
    } else{
      this.refoundToFilter.push(value);
    }
  }

  isRefoundSelected(value){
    return this.refoundToFilter.includes(value);
  }

  passFilters(room) : boolean{
    return this.checkMeal(room) && this.checkCProvider(room) && this.checkRefound(room);
  }

  returnImg(hotel){
    let img = '';
    let count = 0;
    while ((img === '' || !img) && count < hotel.hotels.length){
      // tslint:disable-next-line:max-line-length
      if (img === '' && hotel.hotels[count].detailInfo && hotel.hotels[count].detailInfo.info.images && hotel.hotels[count].detailInfo.info.images.length > 0){
        img = hotel.hotels[count].detailInfo.info.images[0].list[0].url;
      }
      count += 1;
    }
    if (img === ''){
      return 'assets/img/placeholder-image.png';
    } else{
      return img;
    }
  }

  toggleNewSingleHotel(hotel){
    // console.log('hotel => ', hotel);
    this.tabIndex = 0;
    this.hotelToGetInfos = [];
    if (hotel){
      for (let i = 0; i < hotel.hotels.length; i ++){
        this.hotelToGetInfos.push(hotel.hotels[i].hotelCode);
      }
      this.currentHotel = JSON.parse(JSON.stringify(hotel));
      this.getHotelNewInfos();
    } else{
      delete this.currentHotel;
    }
    $('#singleHotelModal').modal('toggle');

  }

  abortSort(){
    this.filteredRes = this.clonedResults;
    this.sorting = 'RELEVANCE';
    this.doSort(undefined);
  }

  starCounter(i : number){
    return new Array(i);
  }

  convertPrice(value, currency) : number{
    for (let i = 0; i < this.hotelRes.currencyConversions.length; i ++){
      if (this.hotelRes.currencyConversions[i].local === currency){
        const result = value * this.hotelRes.currencyConversions[i].rate;
        return Math.round(result * 100) / 100;
      }

    }
  }

  reloadFilteredRooms(){
    this.currentHotel.filteredRoomList = this.currentHotel.roomList.filter(room => this.passFilters(room));
    this.currentHotel.filteredRoomList = this.currentHotel.filteredRoomList.sort((a, b) => a.roomRates[0].amount - b.roomRates[0].amount);
  }
}
