<div mat-dialog-title>
  <div>
    {{data.form.destination?.display}}
  </div>
  <div class="smalltext">{{"DAL"|translate}} {{data.form.checkIn|date:'dd/MM/yyyy'}} {{"AL"|translate}} {{data.form.checkOut|date:'dd/MM/yyyy'}}</div>
</div>
<mat-dialog-content>
  <div class="row">
   <!-- <div class="col-lg-4">

      <mat-card appearance="outlined" class="example-card">
        <div (click)="quote(data.hotel.hotelCode)" class="imageCont pointer" [ngStyle]="{backgroundImage:'url('+returnImg(data.hotel)+')'}">
        </div>
        <mat-card-title>{{data.hotel.name}}</mat-card-title>
        <mat-card-subtitle>{{data.hotel.hotelCode}}</mat-card-subtitle>
        <mat-card-content>
          <div class="mb-1" *ngIf="data.hotel.rating">
            <span *ngFor="let star of starCounter(data.hotel.rating)" class="material-icons ratingIcon">
              star_rate
            </span>
          </div>
          <div *ngIf="!data.hotel.address.city">{{data.hotel.address}}</div>
          <div *ngIf="!data.hotel.address.city">{{data.hotel.address.city}}</div>
          <div *ngIf="data.hotel.type">{{"TYPE"|translate}}: {{data.hotel.type}}</div>
          <div *ngIf="data.hotel.distanceKm">{{"DISTANCE_SEARCH_POINT" |translate}}: {{convertDistance(data.hotel.distanceKm)}}</div>
          <div class="row actionButs">
            <div class="col-8 align-self-center" *ngIf="!this.data.available">
              <span style="font-size:11px" *ngIf="!data.hotel.amount">{{"NO_ROOMS_FOR_SELECTED_DATES" | translate}}</span>
              <span (click)="quote(data.hotel)" class="btnNew pointer" *ngIf="data.hotel.amount">€ {{data.hotel.amount|number:'.2-2'}}</span>
            </div>
            <div class="col-4 text-right align-self-center">
              <span class="material-icons" style="color: red;">
                favorite_border
              </span>
            </div>
          </div>

        </mat-card-content>
      </mat-card>

    </div>-->
    <div class="col-lg-12">
      <div class="" id="mapDialog"></div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close> {{'CLOSE'|translate}}</button>
  <button mat-raised-button [mat-dialog-close]="true" (click)="quote(data.hotel)" *ngIf="!this.data.available">
    {{"LOOK_ROOMS" |translate}}
    <span class="material-icons"> arrow_right_alt </span>
  </button>
</mat-dialog-actions>

