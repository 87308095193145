<div class="container" style="margin-top: 20px">
  <div class="header-container buttonrow">
    <div>Groups</div>
    <button mat-raised-button color="primary" routerLink="/add">Create group</button>
  </div>
  <app-roles-table (deleted)="handleDelete($event)" *ngIf="groups; else loading" [data]="groups.data"></app-roles-table>
</div>


<ng-template #loading>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

