import {
    Pipe,
    PipeTransform,
} from '@angular/core';

@Pipe({
    name: 'moneydetail',
})
export class MoneydetailPipe implements PipeTransform{

    transform(val : string) : string{
        return val ? MONEY_DETAILS[val] || val : val;
    }

}

const MONEY_DETAILS = {
    REF: 'Refundable amount',
    RES: 'Residual Value',
    REU: 'Reusable amuont',
    RTA: 'New Tax',
    RTO: 'Total Price of the Reissue',
    TAC: 'Total Additional Collection',
    TSC: 'TST Additional Collection',
    TST: 'TST Amount',
    UPC: 'Upgrade Cost in Cash',
    ITA: 'Old Tax',
    ITO: 'Total price of the issue',
    NTA: 'Non Refundable Tax Amount',
    TNT: 'Total of new Taxes',
    TRT: 'Total of refundable Taxes',
    BEQ: 'Fare Balance',
    BGT: 'Grand Total',
    BTA: 'Tax Balance',
    BTO: 'Ticket Difference',
    NEQ: 'Non Refundable Amount',
};