import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {Md5} from 'ts-md5';

@Component({
  selector: 'app-setnewpassword',
  templateUrl: './setnewpassword.component.html',
  styleUrls: ['./setnewpassword.component.scss']
})
export class SetnewpasswordComponent implements OnInit {
  password: any;
  confirmPassword: any;
  hide = true;
  token: any;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
        // this.getAccess();
      }
    });
  }

  getAccess(): void {
    console.log('this.token => ', this.token);
    const body = {
      password: String(Md5.hashStr(String(this.password))),
      token: this.token,
    };
    this.authService.resetPassword(body).subscribe(response => {
      console.error('response => ', response);
      this.router.navigate(['login']);
    }, error => {
      console.error('Error => ', error);
    });
    // /v2/forgot-password [PUT] {password:"asdfaw234", “token”:”XXXXXX”}
  }

}
