import { Injectable } from '@angular/core';
import {HttpserviceService} from '../httpservice.service';
import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {UsersList} from '../../classes/userlist';
import {catchError, retry} from 'rxjs/operators';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root'
})
export class CostcenterService extends HttpserviceService{

  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
  }

  getCostCenterList(data, id): Observable<UsersList> {
    // tslint:disable-next-line:max-line-length
    const costCenterListUrl = this.apiUrl + 'profile/company/' + id + '/costsCenter?sort=' + data.sort + '&limit=' + data.limit + '&skip=' + data.skip + '&order=' + data.order + '&text=' + data.text;
    return this.http
      .get<UsersList>(costCenterListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }

  addCostCenter(company, body): Observable<any> {
    const addCostCenterUrl = this.apiUrl + 'profile/company/' + company + '/costsCenter';
    return this.http
      .put<any>(addCostCenterUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  editCostCenter(company, body, id): Observable<any> {
    const editCostCenterUrl = this.apiUrl + 'profile/company/' + company + '/costsCenter/' + id;
    return this.http
      .post<any>(editCostCenterUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  deleteCostCenter(company, id): Observable<any> {
    const deleteCostCenterUrl = this.apiUrl + 'profile/company/' + company + '/costsCenter/' + id;
    return this.http
      .delete<any>(deleteCostCenterUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
}
