import { Component, OnInit, OnDestroy } from '@angular/core';
import {CarRequest, Coordinates} from '../../classes/car-request';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  request: CarRequest;
  today = new Date();
  searchResults: any;
  constructor() {
    this.createForm();

  }

  ngOnInit(): void {
    if (localStorage.getItem('searchCarForm')) {
      this.request = JSON.parse(localStorage.getItem('searchCarForm'));
    }
    /*
    if (localStorage.getItem('searchCarRes')) {
      this.searchResults = JSON.parse(localStorage.getItem('searchCarRes'));
      this.searchResults.cars.forEach((car, i) => {
        this.returnCompanyDetails(car.companyCode, i);
      });
    }
    */
  }

  createForm() {
    this.request = {
      begin: {
        date: new Date(),
        coordinates: {
          latitude: undefined,
          longitude: undefined,
        },
        iata: '',
        display: '',
      },
      end: {
        date: new Date(),
        coordinates: {
          latitude: undefined,
          longitude: undefined,
        },
        iata: '',
        display: '',
      },
    };
    delete this.searchResults;
    console.log('this.request =>', this.request);
  }

  resultChange(event) {
    console.log('entro => results => ', event);
    this.searchResults = event;
    if (this.searchResults) {
      this.searchResults.cars.forEach((car, i) => {
        this.returnCompanyDetails(car.companyLocationsCodes, i);
      });
    }
  }

  returnCompanyDetails(value, index) {
    this.searchResults.companies.forEach((company) => {
      if (value === company.codes) {
        this.searchResults.cars[index].company = company;
        this.searchResults.cars[index].pickUp = company.rentalLocations[0].address;
        this.searchResults.cars[index].companyName = company.name;
        this.searchResults.cars[index].companyImage = company.images[0];
        if (company.rentalLocations[1]) {
          this.searchResults.cars[index].dropOff = company.rentalLocations[1].address;
        } else {
          this.searchResults.cars[index].dropOff = company.rentalLocations[0].address;
        }
      }
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem('searchCarRes');
    localStorage.removeItem('searchCarForm');
  }

}
