import {Component, Inject,} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog,} from '@angular/material/dialog';
import {FlightService} from '../../../../../../../services/flight/flight.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Router} from '@angular/router';
import {DossierService} from '../../../../../../../services/dossier/dossier.service';
import {TranslateService} from '@ngx-translate/core';
import {PriceService} from '../../../../../../../services/price/price.service';
import {TravelersService} from '../../../../../../../services/travelers/travelers.service';
import * as CU from '../../../../../../../../assets/json/countries.json';
import {BottomsheetComponent} from '../../bottomsheet/bottomsheet.component';
import {FarerulesComponent} from '../../../farerules/farerules.component';
import * as $ from 'jquery';
import { CompaniesService } from 'src/app/services/profile/companies.service';

@Component({
  selector: 'app-template-dialog',
  templateUrl: 'template-dialog.component.html',
  styleUrls: ['./template-dialog.component.scss'],
})
export class TemplateDialogComponent {
  WHEELCHAIR_OPTIONS = ['WCBD', 'WCBW', 'WCHC', 'WCHR', 'WCHS', 'WCMP', 'WCOB'];
  currentTraveler: number;
  loadTravelers: boolean;
  editingTraveler: boolean;
  loadingLink: boolean;
  isB2B: boolean;
  sarce: any;
  passengers: any;
  cacheId: string;
  countriesSl: any;
  selectedIndex = 2;
  loadingCustom: boolean = false;
  protected readonly notifier: NotifierService;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  wheelchair: any = {};
  onWarning: boolean;
  customFields: any[] = [];
  fieldSaved = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    protected flightService: FlightService,
    private bottomSheet: MatBottomSheet,
    protected router: Router,
    notifierService: NotifierService,
    protected dossierService: DossierService,
    protected snackBar: MatSnackBar,
    public translate: TranslateService,
    public priceService: PriceService,
    private travelersService: TravelersService,
    private companyService : CompaniesService,
  ) {
    this.countriesSl = CU;
    this.cacheId = this.data.cacheId;
    this.isB2B = JSON.parse(localStorage.getItem('isB2B'));
    this.notifier = notifierService;
    // this.currentTraveler = 0;
    // this.data.recommendation.passengers = this.data.passengers;
    console.log('this.data.request => ', this.data.request);
    console.log('this.data.recommendation => ', this.data.recommendation);
    // tslint:disable-next-line:prefer-for-of
    if (localStorage.getItem('sarceDossier')) {
      this.sarce = JSON.parse(localStorage.getItem('sarceDossier'));
      this.getTravelerCid();
    } else {
      delete this.sarce;
    }
    if (localStorage.getItem('dossierPassengers')) {
      this.passengers = JSON.parse(localStorage.getItem('dossierPassengers'));
      this.getTravelerDossier();
    }
    if (this.data.recommendation.lowCost && this.data.lowCost) {
      const selectedTarif = [];
      for (let i = 0; i < this.data.recommendation.cabins.length; i++)
        selectedTarif.push(this.data.recommendation.cabins[i].split('*')[0].split('=')[1]);
      localStorage.setItem('selectedRates', JSON.stringify(selectedTarif));
      this.processDetLowCost();
    }
    if (!this.data.recommendation.lowCost && !this.data.lowCost) {
      const ind = this.data.recommendation.rates.findIndex(r => r.selected === true);
      this.selectFare(ind);
    }
    if (!this.data.recommendation.customFields) {
      this.getCustomFields();
    } else {
      this.fieldSaved = true;
    }
  }

  getCustomFields(){
    this.loadingCustom = true;
    this.companyService.getCustomFields(this.data.recommendation.company._id).subscribe((res) => {
      // this.form = [];
      if(res && res.data){
        this.customFields = res.data;
      }

      //this.companyRemarks = res;
      console.log('customFields => ', res);

      this.loadingCustom = false;
    }, error => {
      this.loadingCustom = false;
      console.error('Error => ', error);
    });
  }

  selectFq(indxPax, indexFq) {
    this.data.passengers[indxPax].frequentFlyers.forEach(r => {
      if (r.number !== this.data.passengers[indxPax].frequentFlyers[indexFq].number) {
        r.selected = false;
      }
    });
    this.data.passengers[indxPax].frequentFlyers[indexFq].selected = !this.data.passengers[indxPax].frequentFlyers[indexFq].selected;
    const find = this.data.passengers[indxPax].frequentFlyers.findIndex(r => r.selected);
    if (find !== -1) {
      this.data.passengers[indxPax].frequentFlyer = {
        number: this.data.passengers[indxPax].frequentFlyers[find].number,
        iata: this.data.passengers[indxPax].frequentFlyers[find].iata
      };
    } else {
      delete this.data.passengers[indxPax].frequentFlyer;
    }
    console.log('this.data.passengers[indxPax] => ', this.data.passengers[indxPax]);
  }

  processDetLowCost() {
    const body = {
      // tslint:disable-next-line:max-line-length
      queryParams: {
        param1: this.data.routingId,
        param2: undefined,
        param3: undefined,
        param4: this.data.request.passengers.adt,
        param5: this.data.request.passengers.chd,
        param6: this.data.request.passengers.inf
      },
      // replaceUrl: true, // optional
    };
    if (this.data.recommendation.itineraries.length === 1) {
      body.queryParams.param2 = this.data.recommendation.itineraries[0].flightId;
    } else {
      body.queryParams.param2 = this.data.recommendation.itineraries[0].flightId;
      body.queryParams.param3 = this.data.recommendation.itineraries[1].flightId;
    }
    setTimeout(() => {
      this.dialog.closeAll();
    }, 2000);

    this.router.navigate(['/flight/processLowCost'], body);

    /*
    this.flightService.processTermLowCost(body).subscribe((res: any) => {
      this.loadingLink = false;
      console.log('res => ', res);
    }, error => {
      console.error('res => ', error);
    });
    console.log('body => ', body);
    */
  }

  setTravelers() {
    this.loadingLink = true;
    this.data.recommendation.passengers = this.data.passengers;
    const z = 1;
    for (let i = 0; i < this.data.recommendation.passengers.length; i++) {
      this.data.recommendation.passengers[i].ref = i + z + 1;
      if (this.data.recommendation.passengers[i].birthday == null || this.data.recommendation.passengers[i].birthday === '') {
        this.data.recommendation.passengers[i].birthday = undefined;
      }
    }
    console.log('this.data.recommendation => ', this.data.recommendation);
    if (this.isB2B) {
      this.data.recommendation.b2b = true;
    }
    this.data.recommendation.fare = this.data.recommendation.tariff.fare;
    if (this.data.request.options.resident) {
      this.data.recommendation.resident = true;
    }
    this.data.recommendation.fare.options = this.data.request.options;
    this.flightService.generateReservation(this.data.recommendation).subscribe((res: any) => {
      console.log('res => ', res);
      this.data.recommendation = res;

      if (localStorage.getItem('fl_dossierId')) {
        const dossierId = JSON.parse(localStorage.getItem('fl_dossierId'));
        const body = {
          flights: [res._id]
        };
        this.dossierService.addFlightsToDossier(dossierId, body).subscribe((resp) => {
          console.log('res => ', resp);
        }, error => {
          console.error('res => ', error);
        });
      }
      this.snackBar.open(this.translate.instant('RESERVATION_PLACED'), null, {
        duration: 800,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      // localStorage.setItem('FlReservation', JSON.stringify(this.data.recommendation));
      setTimeout(() => {
        this.loadingLink = false;
        this.router.navigate(['/flight/reservations/' + res._id]);
        this.dialog.closeAll();
      }, 2000);

    }, error => {
      this.loadingLink = false;
      this.notifier.notify('error', error.errorMessage);
      console.error('res => ', error);
    });

  }

  toggleDialog(currentTraveler) {
    this.currentTraveler = currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  toggleEditDialog(currentTraveler) {
    this.editingTraveler = true;
    this.currentTraveler = currentTraveler;
    console.log('pax => ', this.data.passengers);
    $('#EditTravelerModal').modal('toggle');
  }

  validCountry(value): boolean {
    return this.countriesSl.countries.some(e => e.code === value);
    // return this.countriesSl.countries.filter(e => e.code === value).length > 0;
  }

  travelerController() {
    if (this.data.passPort) {
      const isValidTraveler = (value) => !(!value.name || !value.surname || !value.birthday || !value.sex || !value.phoneNumber || !value.email); // || !value.phoneNumber
      return this.data.passengers.every(isValidTraveler);
    } else {
      const isValidTraveler = (value) => !(!value.name || !value.surname); // || !value.phoneNumber
      return this.data.passengers.every(isValidTraveler);
    }
  }

  checkLengthPassengers() {
    const lengthType = this.data.passengers.filter(r => r.type !== 'INF' && r.name && r.surname).map(p => Object({
      lenName: p.name.length + p.surname.length,
      type: p.type,
    }));
    const infantTypesLength = this.data.passengers.filter(r => r.type === 'INF' && r.name && r.surname).map(p => Object({
      lenName: p.name.length + p.surname.length,
    }));
    let infantIndex = 0;
    let i = 0;
    while (i < lengthType.length && !this.onWarning) {
      let length = lengthType[i].lenName
      if (infantIndex < infantTypesLength.length && lengthType[i].type === 'ADT') {
        length += infantTypesLength[i].lenName
        infantIndex++;
      }
      this.onWarning = length >= 30;
      i++;
    }
    return this.onWarning;

  }

  removeTraveler(i) {
    Object.keys(this.data.passengers[i]).forEach(k => {
      const type = this.data.passengers[i].type;
      delete this.data.passengers[i][k];
      this.data.passengers[i].type = type;
    });
  }


  selectFare(f) {
    // tslint:disable-next-line:prefer-for-of
    for (let rate = 0; rate < this.data.recommendation.rates.length; rate++) {
      this.data.recommendation.rates[rate].selected = false;
    }
    this.data.recommendation.rates[f].selected = true;
    this.data.recommendation.fare = this.data.recommendation.rates[f].fare;
    this.data.recommendation.tariff = this.data.recommendation.rates[f];
    console.log(this.data.recommendation.rates[f]);
    if (this.data.recommendation.rates[f].passengers.ADT) {
      this.data.recommendation.paxFare.adt = this.data.recommendation.rates[f].passengers.ADT.fare;
    }
    if (this.data.recommendation.rates[f].passengers.CHD) {
      this.data.recommendation.paxFare.chd = this.data.recommendation.rates[f].passengers.CHD.fare;
    }
    if (this.data.recommendation.rates[f].passengers.INF) {
      this.data.recommendation.paxFare.inf = this.data.recommendation.rates[f].passengers.INF.fare;
    }
    console.log('this.reco => ', this.data.recommendation);
  }

  travelerSet(event, i) {
    const type = this.data.passengers[i].type;
    this.data.passengers[i] = event;
    this.data.passengers[i].addressBook = this.data.passengers[i]._id;
    this.data.passengers[i].type = type;
    console.log('this.data.passengers => ', this.data.passengers);
    delete this.currentTraveler;
    // $('#addTravelerModal').modal('toggle');
  }

  getTravelerDossier() {
    this.data.passengers.length = this.passengers.length;
    for (let i = 0; i < this.passengers.length; i++) {
      this.travelersService.getTraveler(this.passengers[i]._id).subscribe((res: any) => {
        if (!res.type) {
          res.type = 'ADT';
        }
        this.travelerSet(res, i);
      }, error => {
        console.error('res => ', error);
      });
    }
  }

  getTravelerCid() {
    console.log('this.sarce.cid => ', this.sarce.cid);
    this.travelersService.getTravelerCid(this.sarce.cid).subscribe((res: any) => {
      res.type = 'ADT';
      this.travelerSet(res, 0);
    }, error => {
      console.error('res => ', error);
    });
  }

  openBottomSheet(fare) {
    this.bottomSheet.open(BottomsheetComponent, {
      data: {fare},
    });
  }

  closeEvent(event) {
    delete this.currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  closeEditEvent(event) {
    this.editingTraveler = false;
    delete this.currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  closeListEvent(event) {
    delete this.currentTraveler;
    $('#listTravelerModal').modal('toggle');
  }

  loadTravelersList(currentTraveler) {
    this.currentTraveler = currentTraveler;
    $('#listTravelerModal').modal('toggle');
    this.loadTravelers = true;
  }

  returnZeroTime(value) {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }

  /**
   *  Calculate flight duration based on departure and arrival date time and pass result to return time function
   */
  calculateFlightTime(departure, arrival) {
    departure = new Date(departure).getTime();
    arrival = new Date(arrival).getTime();
    const flightTime = arrival - departure;
    return this.returnTime(flightTime);
  }

  /**
   *  Return flight duration in dd-hh-mm
   */
  returnTime(t) {
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(t / cd);
    let h = Math.floor((t - d * cd) / ch);
    let m = Math.round((t - d * cd - h * ch) / 60000);
    const pad = ((n) => n < 10 ? '0' + n : n);
    if (m === 60) {
      h++;
      m = 0;
    }
    if (h === 24) {
      d++;
      h = 0;
    }
    if (d > 0) {
      return [d + 'g ' + pad(h) + 'h', pad(m) + 'm'].join(':');
    } else {
      return [pad(h) + 'h', pad(m) + 'm'].join(':');
    }

  }

  otherday(value, other) {
    return value !== other;
  }

  opeSect(it) {
    $('#collapse' + it).collapse('toggle');
    $('#itinerary' + it).collapse('toggle');
  }

  openDialogRules(tariff) {
    console.log('this.travelers =>', this.data.passengers);
    // tslint:disable-next-line:no-shadowed-variable
    const recommendation = JSON.parse(JSON.stringify(this.data.recommendation));
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      recommendation.itineraries[i] = this.data.searchResults.itineraries[i].sections[this.data.selections[recommendation.ref][i] - 1];
    }
    recommendation.options = this.data.request.options;
    recommendation.company = this.data.company;
    recommendation.passengers = this.data.passengers;
    recommendation.tariff = tariff;
    const dialogRef = this.dialog.open(FarerulesComponent, {
      data: {
        loggedUser: this.data.loggedUser,
        tariff,
        recommendation
      },
      disableClose: true,
      width: '100%',
      maxWidth: '960px',
      // height: '100%',
      maxHeight: '900px',
    });
  }

  togglePrint(data) {
    this.data.recommendation = data.recommendation;
    console.log('apri il modale ->' + this.data.recommendation);
    $('#PrintModal').modal('toggle');
  }

  setRadioValue(field:any, radio:any){
    field.fieldValue = radio.value;
  }

  validFields(){
    let inValid = false;
    if(this.data.recommendation.company.customFields){
      this.customFields.forEach((c:any) => {
        if(!c.isOptional && !c.fieldValue){
          inValid = true;
        } else {
          this.fieldSaved = true;
        }
      })
    } else {
      this.fieldSaved = true;
    }
    return inValid;
  }

  saveFieldsToPnr(){
    this.data.recommendation.customFields = [];
    this.customFields.forEach((c:any) => {
      let obj = {
        siapField:c.siapField,
        fieldValue: c.fieldValue,
      }
      if(obj.fieldValue){
        this.data.recommendation.customFields.push(obj)
      }
      this.fieldSaved = true;
    })
    console.log('this.data.recommendation => ', this.data.recommendation);
  }

  returnDate(value){
    const date = new Date(value)
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const final = new Date(date.getTime() + userTimezoneOffset);
    return final;
  }

  convertMilliseconds(det) {
    const ms = det.to - det.from;
    // Un'ora ha 3,600,000 millisecondi
    const hours = Math.floor(ms / 3600000);
    // Un minuto ha 60,000 millisecondi
    const minutes = Math.floor((ms % 3600000) / 60000);

    // Ritorna la durata in formato 'ore minuti'
    return `${hours}h ${minutes}m`;
  }

  checkTechnicalStops(flights){
    let count = 0;
    flights.forEach((f:any) => {
      if(f.detail.technicalStop && f.detail.technicalStop.from > 0){
        count += 1;
      }
    });
    return count;
  }
}
