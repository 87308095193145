import {Component, OnInit, OnChanges, SimpleChanges, Input} from '@angular/core';

@Component({
  selector: 'app-areachart',
  templateUrl: './areachart.component.html',
  styleUrls: ['./areachart.component.scss']
})
export class AreachartComponent implements OnInit, OnChanges {
  @Input() chartmode:string;
  chartOptions:any;
  constructor() { }

  ngOnInit() {
    this.buildChart();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.buildChart();
  }

  buildChart(){
    this.chartOptions = {
      theme: {
        mode:this.chartmode,
      },
      series: [
        {
          name: "User registration",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148,24,90,178]
        },
        {
          name: "Newsletter subscriptions",
          data: [23, 33, 43, 24, 35, 36, 25, 63, 177,33,75,44]
        }
      ],
      chart: {
        height: 200,
        type: "area",
        zoom: {
          enabled: false
        }
      },
      stroke: {
        curve: "straight"
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ]
      }
    };
  }

}
