import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  notifications = new BehaviorSubject<number>(0);
  notifications$ = this.notifications.asObservable();

  constructor() {
  }

  setNotifications(value: number): void {
    this.notifications.next(value);
  }
}
