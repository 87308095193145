import {Component, OnInit} from '@angular/core';
import {GroupsService} from '../../groups.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {

  loading = false;

  constructor(
    private groupsService: GroupsService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
  }

  handleSubmit(payload: any): void {
    this.loading = true;
    this.groupsService.create(payload).subscribe(result => {
        this.loading = false;
        this.snackBar.open('Group created successfully', 'Close');
        this.router.navigate(['/allgroups']);
      },
      error => {
        this.loading = false;
      }
    );
  }

}
