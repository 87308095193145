<div *ngIf="loading" class="loader">
  <div class="card text-center padding20">
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
    <h5>{{'LOADING'|translate}}...</h5>
  </div>
</div>

<div class="fopsContainer" *ngIf="!loading">

  <div class="smallActionButtons text-right mb-3" *ngIf="maincomponent.loggedUser.role.superAdmin && !reservation">
    <button mat-raised-button (click)="toggleAddModal()">
      {{'ADD_PAYMENT'|translate}}
      <span class="material-icons">
        add
      </span>
    </button>
  </div>
  <div *ngIf="!methods || methods.length == 0">
    {{'NO_PAYMENT_METHODS'|translate}}
  </div>
  <div *ngIf="methods">
    <div class="row">
      <div class="col-md-4 mb-3" *ngFor="let method of methods" [hidden]="!checkService(method)">
        <mat-card appearance="outlined" [ngClass]="{'fopselected':method.selected}">
          <mat-card-header>
            <div mat-card-avatar class="header-image">
              <span *ngIf="!method.cash && !method.manual" class="material-icons">
                credit_card
              </span>
              <span class="material-icons" *ngIf="method.cash">
                euro
              </span>
              <span class="material-icons" *ngIf="method.manual">
                support_agent
              </span>
            </div>
            <mat-card-title>{{method.name}}</mat-card-title>
            <mat-card-subtitle>
              <span class="d-block">{{method.amadeusProfile.name}}</span>
              <span class="smalltext" *ngIf="!method.cash && !method.manual">
                {{method.card?.card.holder}} |
                **** **** **** {{method.card?.card.number}} <sup>{{method.card?.card.type}}</sup>
              </span>
              <span *ngIf="method.manual"> Manual</span>
              <span *ngIf="method.cash"> {{'CASH'|translate}}</span>
              <br/>
              <span *ngIf="method.service === '*'">{{'ALL_SERVICES'|translate}}</span>
              <span *ngIf="method.service !== '*'">{{method.service}}</span>
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="reservation && !method.accepted" class="text-danger">{{'NOT_ACCEPTED'|translate}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-actions align="end" class="buttonrow">

            <button color="warn" *ngIf="!reservation" mat-button (click)="toggleDeleteModal(method)">{{'DELETE'|translate}}</button>
            <button [disabled]="!method.accepted" *ngIf="reservation" mat-button (click)="selectFop(method)">{{'SELECT'|translate}}</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

    <!--
    <div class="table-responsive">
      <table class="table table-striped w-100" style="min-width: 600px;">
        <tr>
          <td>{{'PAYMENT_TYPE'|translate}}</td>
          <td>{{'PAYMENT_NAME'|translate}}</td>
          <td>{{'SERVICES'|translate}}</td>
          <td>{{'COMPANY'|translate}}</td>
          <td>{{'ACTIONS'|translate}}</td>
        </tr>
        <tr *ngFor="let method of methods">
          <td>
            <span *ngIf="!method.cash && !method.manual" class="material-icons">
              payment
            </span>
            <span *ngIf="method.cash" class="material-icons">
              attach_money
            </span>
            <span *ngIf="!method.cash && !method.manual"> Credit card</span>
            <span *ngIf="method.manual"> Manual</span>
            <span *ngIf="method.cash"> Cash</span>
            <div class="smalltext" *ngIf="!method.cash && !method.manual">
              {{method.card.card.holder}} <br/>
              **** **** **** {{method.card.card.number}}
            </div>
          </td>
          <td>{{method.name}}</td>
          <td>
            <span *ngIf="method.service === '*'">{{'ALL_SERVICES'|translate}}</span>
            <span *ngIf="method.service !== '*'">{{method.service}}</span>
          </td>
          <td>{{method.amadeusProfile.name}}</td>
          <td class="buttonrow">
            <button *ngIf="!reservation" mat-button (click)="toggleDeleteModal(method)">{{'DELETE'|translate}}</button>
            <button [disabled]="!checkService(method)" *ngIf="reservation" mat-button (click)="selectFop(method)">{{'SELECT'|translate}}</button>
          </td>
        </tr>
      </table>
    </div>
    -->

  </div>
</div>

<div class="modal fade" id="addMethodModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="addFopModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content" *ngIf="newMethod">
      <div class="modal-header">
        <h5 class="modal-title" id="addFopModalTitle">{{'ADD_PAYMENT'|translate}}</h5>
        <button type="button" class="close" (click)="toggleAddModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="row">

          <div class="col-lg-6">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'SELECT_COMPANY'|translate}}</mat-label>
              <input [ngModelOptions]="{standalone: true}" matInput placeholder="{{'SEARCH_COMPANY'|translate}}" aria-label="Departure"
                     (ngModelChange)="searchCompany($event)" [(ngModel)]="companytext" [matAutocomplete]="cpcomplete">
              <button type="button" *ngIf="companytext" matSuffix mat-icon-button aria-label="Clear" (click)="clearFrom()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete #cpcomplete="matAutocomplete" (closed)="selectFirst()">
                <mat-option *ngFor="let comp of companiesList" [value]="comp.name" (click)="selectComp(comp)">
                  <span>{{comp.name}}</span>
                </mat-option>
              </mat-autocomplete>

            </mat-form-field>
          </div>

          <div class="col-lg-6" *ngIf="selectedCp">
            <div class="mb-3">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'TYPE'|translate}}</mat-label>
                <mat-select [(ngModel)]="methodType" (ngModelChange)="modifyMethodType($event)">
                  <mat-option [value]="undefined">{{'SELECT'|translate}} {{'TYPE'|translate}}</mat-option>
                  <mat-option *ngFor="let type of ['CASH','MANUAL','CREDIT']" [value]="type">{{ type | translate }}</mat-option>
                </mat-select>
              </mat-form-field>

            </div>

          </div>

        </div>
        <hr *ngIf="selectedCp"/>
        <div class="row" *ngIf="selectedCp">

          <div class="col-lg-6">

            <div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'PAYMENT_NAME'|translate}}</mat-label>
                <input matInput type="text" [(ngModel)]="newMethod.name">
              </mat-form-field>
            </div>

            <div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'SERVICE'|translate}}</mat-label>
                <mat-select [(ngModel)]="newMethod.service">
                  <mat-option [value]="service" *ngFor="let service of services;">
                    {{service}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>amadeusProfile</mat-label>
                <input disabled matInput type="text" [(ngModel)]="newMethod.amadeusProfile">
              </mat-form-field>
            </div>

            <div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'COMPANY'|translate}}</mat-label>
                <input disabled matInput type="text" [(ngModel)]="newMethod.company">
              </mat-form-field>
            </div>

          </div>

          <div class="col-lg-6">
            <div *ngIf="fops && !newMethod.cash && !newMethod.manual && methodType==='CREDIT'">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'AVAILABLE_CREDIT_CARDS'|translate}}</mat-label>
                <mat-select [(ngModel)]="newMethod.index">
                  <mat-option [value]="undefined">
                    select
                  </mat-option>
                  <mat-option [value]="fop.indexes.lowcost" *ngFor="let fop of fops;">
                    <span>
                      <img style="width: 20px;" *ngIf="fop.type==='CC'" [src]="'assets/img/fops/' + fop.card.type + '.png'"/>
                      <img style="width: 20px;" *ngIf="fop.type!=='CC'" [src]="'assets/img/fops/TP.png'"/>
                    </span>
                    {{fop.name}} <span *ngIf="fop.type!=='CC'">(Airplus)</span> **** **** **** **** {{fop.card.number}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

      </div>

      <hr/>

      <div class="text-right">
        <button type="button" mat-button (click)="toggleAddModal()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button [disabled]="!validForm()" (click)="addFop()">{{'SAVE'|translate}}</button>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="deleteFopModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="deleteFopModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteFopModalTitle">{{'DELETE_PAYMENT'|translate}}</h5>
        <button type="button" class="close" (click)="toggleDeleteModal(undefined)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <p>{{'DELETE_CURRENT_PAYMENT'|translate}}? </p>
        <hr/>
        <mat-card appearance="outlined" class="mb-2" style="max-width: 300px">
          <mat-card-header *ngIf="currentFop">
            <mat-card-title>{{currentFop.name}}</mat-card-title>
            <mat-card-subtitle>
              <span *ngIf="!currentFop.cash"> Credit card</span>
              <span *ngIf="currentFop.cash"> {{'CASH'|translate}}</span>
            </mat-card-subtitle>
            <mat-card-subtitle>{{currentFop.amadeusProfile.name}}</mat-card-subtitle>
            <mat-card-subtitle>
              {{'SERVICES'|translate}}:
              <span *ngIf="currentFop.service === '*'">{{'ALL_SERVICES'|translate}}</span>
              <span *ngIf="currentFop.service !== '*'">{{currentFop.service}}</span>
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </div>

      <hr/>

      <div class="text-right">
        <button type="button" mat-button (click)="toggleDeleteModal(undefined)">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="deleteMethod(currentFop._id)">{{'DELETE'|translate}}</button>
      </div>

    </div>
  </div>
</div>
