<div class="product__item" role="listitem">
  <div class="product__header">
    <h2 class="product__name">Hotel name: {{productDetails.hotel.name}}</h2>
  </div>
  <div>
    <img style="width:50%" [src]="productDetails.room.images[0]">
  </div>
  <div class="product__body">
    <h3 class="product__rooms"><b>Hotel info:</b> </h3>
    <div class="product__room">
      <p class="product__room__item"><b>CheckIn:</b> {{productDetails.hotel.checkIn}}</p>
      <p class="product__room__item"><b>CheckOut:</b> {{productDetails.hotel.checkOut}}</p>
      <p class="product__room__item"><b>City:</b> {{productDetails.hotel.city}}</p>
      <p class="product__room__item"><b>Code:</b> {{productDetails.hotel.code}}</p>
    </div>
  </div>
  <div class="product__body">
    <h3 class="product__rooms"><b>Hotel room:</b> </h3>
    <div class="product__room">
      <p class="product__room__item"><b>Room Price:</b> &euro; {{productDetails.room.amount}}</p>
      <p class="product__room__item"><b>Room Start Date:</b> {{productDetails.room.startDate}}</p>
      <p class="product__room__item"><b>Room End Date:</b> {{productDetails.room.endDate}}</p>
    </div>
  </div>
  <div class="product__guests">
    <h3 class="product__rooms"><b>Hotel guests:</b></h3>
    <div class="product__room" *ngFor="let guest of productDetails.guests; let i=index;">
      <p class="product__guests"><b>Gest No.: </b>{{i+1}}</p>
      <p class="product__guests"><b>Name: </b>{{guest.name}}</p>
      <p class="product__guests"><b>Surname: </b>{{guest.surname}}</p>
      <p class="product__guests"><b>Name: </b>{{guest.name}}</p>
    </div>
  </div>
</div>

