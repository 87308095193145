import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {HttpserviceService} from '../httpservice.service';
import {Observable} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';
import { OpenTicketRequest } from './api/ticketing.service.models';

@Injectable({
  providedIn: 'root'
})
export class TicketingService extends HttpserviceService {
  tktUrl: string;
  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
    this.tktUrl = this.apiUrl;
  }

  sendTicket(data : OpenTicketRequest) {
    // return (this.isGat) ? this.sendTicketGat(data) : this.sendTicketFly(data);
    return this.sendTicketFly(data);
  }

  private sendTicketFly(data: OpenTicketRequest): Observable<any> {
    let openTicketUrl = '';
    const shouldUseJira = true;
    if (data.requestType && shouldUseJira) {
      openTicketUrl = this.tktUrl + 'servicedesk/issue';
      return this.http
        .post<any>(openTicketUrl, data, this.setHttpOptions())
        .pipe(
          retry(0),
          catchError(err => this.handleError(err, false))
        );
    } else {
      openTicketUrl = this.tktUrl + 'support/create';
      return this.http
        .put<any>(openTicketUrl, data, this.setHttpOptions())
        .pipe(
          retry(0),
          catchError(err => this.handleError(err))
        );
    }

  }

  sendTicketGat(data: OpenTicketRequest): Observable<any> {
    const body = {
      message: data.body.data,
      subject: 'Dipartimento: ' + data.department + ', oggetto: ' + data.subject,
    };
    const openTicketUrl = this.tktUrl + 'support/sendTicket';
    return this.http
      .post<any>(openTicketUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, false))
      );

  }

  getTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'support/list/all?limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getFlightTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'support/list/all/flight?limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getHotelTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'support/list/all/hotel?limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getTrainTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'support/list/all/train?limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getCarTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'support/list/all/car?limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getOfflineRequestsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getOfflineListUrl = this.tktUrl + 'support/list/office/request?limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getOfflineListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getRequestsList(filter, params): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getOfflineListUrl = this.tktUrl + 'support/list/'+filter;
    return this.http
               .get<any>(getOfflineListUrl, {...this.setHttpOptions(), params})
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err))
               );
  }

  getTicketDetails(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketDetail = this.tktUrl + 'support/' + data;
    return this.http
      .get<any>(getTicketDetail, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getJiraTicketDetails(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketDetail = this.tktUrl + 'servicedesk/issue/' + data;
    return this.http
      .get<any>(getTicketDetail, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, false))
      );
  }

  putTicketAnswer(obj, id): Observable<any> {
    const data = {
      body: obj,
    };
    const putTicket = this.tktUrl + 'support/' + id + '/reply';
    return this.http
      .put<any>(putTicket, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  postJiraTicketAnswer(obj, id): Observable<any> {
    const data = {
      body: obj,
    };
    const putTicket = this.tktUrl + 'servicedesk/issue/' + id;
    return this.http
      .post<any>(putTicket, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, false))
      );
  }

  getOperators(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getOperators = this.apiUrl + 'support/operators';
    return this.http
      .get<any>(getOperators, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  setOperator(opId, id): Observable<any> {
    const data = {
      user: opId,
    };
    const setOperator = this.tktUrl + 'support/' + id + '/assign';
    return this.http
      .post<any>(setOperator, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
  setSection(sect, id): Observable<any> {
    const data = {
      section: sect,
    };
    const setSection = this.tktUrl + 'support/' + id + '/section';
    return this.http
      .post<any>(setSection, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  setDepartment(sect, id): Observable<any> {
    const data = {
      department: sect,
    };
    const setDepartment = this.tktUrl + 'support/' + id + '/department';
    return this.http
      .post<any>(setDepartment, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  closeTkt(id) {
    const closeTkt = this.tktUrl + 'support/' + id + '/close';
    return this.http
      .post<any>(closeTkt, null, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getJiraDepartments(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const departments = this.apiUrl + 'servicedesk/issue/types';
    return this.http
      .get<any>(departments, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getJiraSections(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const sections = this.apiUrl + 'servicedesk/issue/components';
    return this.http
      .get<any>(sections, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getJiraTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'servicedesk/list?limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getFlightJiraTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'servicedesk/list?component=10119&limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getHotelJiraTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'servicedesk/list?component=10118&limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getCarJiraTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'servicedesk/list?component=10120&limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getTrainJiraTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'servicedesk/list?component=10121&limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getGroupJiraTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'servicedesk/list?component=10124&limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getMinorJiraTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'servicedesk/list?component=10123=limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getGeneralJiraTicketsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.tktUrl + 'servicedesk/list?component=10122=limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getTicketsListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }


  postGatTkt(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getTicketsListUrl = this.apiUrl + 'support/sailsforce';
    return this.http
      .post<any>(getTicketsListUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
}
