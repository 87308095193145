import { Injectable } from '@angular/core';
import { HttpserviceService } from '../httpservice.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SearchRes } from '../../classes/flights/search-res';
import {
  catchError,
  retry,
} from 'rxjs/operators';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root',
})
export class DossierService extends HttpserviceService{
  private dossierUrl : string;

  constructor(
    public router : Router,
    public http : HttpClient,
    public snackComponent: SnackErrorComponent,
  ){
    super(router, http, snackComponent);
    this.dossierUrl = this.apiUrl + 'dossier';
  }

  getDossierList(data) : Observable<any>{
    // tslint:disable-next-line:max-line-length
    const getDossierListUrl = this.dossierUrl + '/list?limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
               .get<any>(getDossierListUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  createDossier(data) : Observable<any>{
    const createDossierUrl = this.dossierUrl + '/create';
    return this.http
               .put<any>(createDossierUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  nameDossier(data, id) : Observable<any>{
    const notesDossierUrl = this.dossierUrl + '/' + id + '/name';
    return this.http
               .post<any>(notesDossierUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  getDossier(id) : Observable<any>{
    const deleteDossierUrl = this.dossierUrl + '/' + id;
    return this.http
               .get<any>(deleteDossierUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  deleteDossier(id) : Observable<any>{
    const deleteDossierUrl = this.dossierUrl + '/' + id;
    return this.http
               .delete<any>(deleteDossierUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  private removeProduct(id, data, path){
    const removeFromDossierUrl = this.dossierUrl + '/' + id + '/' + path + '/remove';
    return this.http
               .post<any>(removeFromDossierUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  private addProduct(id, data, path){
    const addToDossierUrl = this.dossierUrl + '/' + id + '/' + path + '/add';
    return this.http
               .post<any>(addToDossierUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  addFlightsToDossier(id, data){ return this.addProduct(id, data, 'flights'); }

  addHotelsToDossier(id, data){ return this.addProduct(id, data, 'hotels'); }

  addCarsToDossier(id, data){ return this.addProduct(id, data, 'cars'); }

  addTrainsToDossier(id, data){ return this.addProduct(id, data, 'trains'); }

  removeFlightsFromDossier(id, data){ return this.removeProduct(id, data, 'flights'); }

  removeHotelsFromDossier(id, data){ return this.removeProduct(id, data, 'hotels'); }

  removeCarsFromDossier(id, data){ return this.removeProduct(id, data, 'cars'); }

  removeTrainsFromDossier(id, data){ return this.removeProduct(id, data, 'trains'); }

  /*
   ? /api/v2/dossier/{_id}/hotels.js [POST]
   X /api/v2/dossier/{_id}/flights/remove.js [POST]
   * /api/v2/dossier/{_id}/flights/add.js [POST]
   - /api/v2/dossier/{_id}/notes.js [POST]
   X /api/v2/dossier/{_id}/hotels/remove.js [POST]
   * /api/v2/dossier/{_id}/hotels/add.js [POST]
   ? /api/v2/dossier/{_id}/flights.js [POST]
   ? /api/v2/dossier/{_id}/description.js
   - /api/v2/dossier/{_id}/name.js [POST]
   * /api/v2/dossier/create.js [PUT]
   * /api/v2/dossier/list.js [GET]
   - /api/v2/dossier/{_id}.js [GET]/[DELETE]
   */

}
