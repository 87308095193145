interface DocumentPx {
  expiryDate: string;
  type: string;
  nationality: string;
  number: string;
  issuingCountry: string;
}

interface CostCenter {
  _id: string;
  tag: string;
  description: string;
  company: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export class NewTraveller{
  document: DocumentPx | undefined;
  costCenter: CostCenter[] | undefined;
  name: string | undefined;
  surname: string | undefined;
  phoneNumber: string | undefined;
  email: string | undefined;
  sex: string | undefined;
  birthday: string | undefined;
  type: string | undefined;
  saved: boolean | undefined;
  addressBook: string | undefined;
  frequentFlyers: [] | undefined;
  frequentFlyer: any | undefined;
  wheelchair: string | undefined = null;
  _id: string | undefined;
}

