<div class="contentNewPass">

  <mat-card appearance="outlined">
    <div class="mb-3">
      Inserisci una nuova password
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'NEW_PASSWORD'|translate}}</mat-label>
        <input matInput [(ngModel)]="password" required [type]="hide ? 'password' : 'text'">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>{{'CONFIRM_PASSWORD'|translate}}</mat-label>
        <input matInput required type="password" [(ngModel)]="confirmPassword">
      </mat-form-field>
    </div>

    <div class="buttonrow">
      <div class="mb-2 smalltext text-danger" *ngIf="password != confirmPassword">
        Attenzione: Le password non coincidono.
      </div>
      <button (click)="getAccess()" mat-raised-button color="primary" [disabled]="password != confirmPassword">Modifica password</button>
    </div>


  </mat-card>

</div>
