import { Component, OnInit, Inject, Input } from '@angular/core';
import { Notifications } from '../../../../classes/notifications';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import 'bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpserviceService } from '../../../../services/httpservice.service';
import { MainComponent } from '../../../mainmodule/main/main.component';
import { AppComponent } from '../../../../app.component';


export interface DialogData {
  element: any;
}

@Component({
             selector: 'app-auto',
             templateUrl: './auto.component.html',
             styleUrls: ['./auto.component.scss'],
           })
export class AutoComponent implements OnInit{
  // FlightPnr_v2
  private readonly notifier: NotifierService;
  @Input() notificationType: string;
  @Input() type: string;
  @Input() read: any;
  @Input() autoNotificationsList: Notifications;
  @Input() manualNotificationsList: Notifications;
  // clonedNotificationsList = [];
  @Input() clonedAutoNotificationsList = [];
  @Input() clonedManNotificationsList = [];
  loading: boolean;
  currentpage: number;
  maxpages: number;
  query: any;
  limit: number;
  skip: number;
  progress: boolean;
  text: string;
  pagenumbers = [];
  unread: number;
  blockNotification: any;
  testSocket: any;
  constructor(
    private notificationsServices: NotificationsService,
    public appcomponent: AppComponent,
    public maincomponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    private httpservice: HttpserviceService,
    public translate: TranslateService,
    notifierService: NotifierService,
    public dialog: MatDialog,
  ){
    this.loading = true;
    this.progress = false;
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.unread = 0;
    if (this.read === 'false') {
      this.read = false;
    }
    this.currentpage = 0;
    this.limit = 5;
    this.skip = (this.currentpage) * this.limit;
    this.query = {
      limit: this.limit,
      skip: this.skip,
      text: '',
      options: {
        read: this.read,
        type: this.notificationType,
      },
    };
    if (!this.type) {
      this.route.queryParams.subscribe(params => {
        this.query.limit = params.limit || this.limit;
        this.query.skip = params.skip || this.skip;
        this.query.text = params.text || '';
        this.text = params.text || '';
        this.currentpage = this.query.skip / this.query.limit;
        this.getNotificationsList();
      });
    } else {
      this.navigate();
    }
    this.getNotificationsListUnread();
  }

  getNotificationsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.notificationsServices.getNotificationsList(this.query).subscribe((res) => {
      this.loading = false;
      if (this.notificationType === 'auto') {
        this.autoNotificationsList = res;
        for (let i = 0; i < this.autoNotificationsList.list.length; i ++) {
          const notify = this.clonedAutoNotificationsList.find(r => r._id === this.autoNotificationsList.list[i]._id);
          if (!notify) {
            this.clonedAutoNotificationsList.push(this.autoNotificationsList.list[i]);
          }
        }
      } else {

        this.manualNotificationsList = res;
        for (let i = 0; i < this.manualNotificationsList.list.length; i ++) {
          const notify = this.clonedManNotificationsList.find(r => r._id === this.manualNotificationsList.list[i]._id);
          if (!notify) {
            this.clonedManNotificationsList.push(this.manualNotificationsList.list[i]);
          }
        }
      }

      if (this.notificationType === 'auto' && this.query.options.read === false && this.type) {
        this.unread = res.count;
        this.maincomponent.unreadAuto = this.unread;
        if (this.unread > 0) {
          // tslint:disable-next-line:max-line-length
          this.notifier.notify('default', this.translate.instant('YOU_HAVE') + ' ' + this.unread + ' ' + this.translate.instant('NEW_NOTIFICATIONS'));
        }

      }
      if (this.notificationType === 'manual' && this.query.options.read === false && this.type) {

        for (let i = 0; i < this.manualNotificationsList.list.length; i ++) {
          this.manualNotificationsList.list[i].body = this.manualNotificationsList.list[i].body.replace(/<[^>]*>/g, '');
          if (this.manualNotificationsList.list[i].block) {
            this.openDialog(this.manualNotificationsList.list[i]);
          }
        }

        this.unread = res.count;
        this.maincomponent.unreadManual = this.unread;
        console.log('this.maincomponent.unreadManual => ', this.maincomponent.unreadManual);
        if (this.unread > 0) {
          // tslint:disable-next-line:max-line-length
          this.notifier.notify('default', this.translate.instant('YOU_HAVE') + ' ' + this.unread + ' ' + this.translate.instant('NEW_COMMUNICATIONS'));
        }
      }

      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      // this.scrollTop();

      console.log('this.manualNotificationsList => ', this.manualNotificationsList);
      console.log('this.autoNotificationsList => ', this.autoNotificationsList);
      if (this.notificationType === 'auto') {
        this.maxpages = Math.ceil((this.autoNotificationsList.count / this.limit));
      } else {
        this.maxpages = Math.ceil((this.manualNotificationsList.count / this.limit));
      }
      if (this.maxpages === 0) { this.maxpages = 1; }

      const numeration = [];
      for (let i = 0; i < this.maxpages; i ++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getNotificationsListUnread(){
    const notreadquery = JSON.parse(JSON.stringify(this.query));
    notreadquery.options.read = false;
    this.notificationsServices.getNotificationsList(notreadquery).subscribe((res) => {

      if(this.notificationType === 'auto' && notreadquery.options.read === false && this.type) {
        this.unread = res.count;
        this.maincomponent.unreadAuto = this.unread;
        if (this.unread > 0) {
          // tslint:disable-next-line:max-line-length
          this.notifier.notify('default', this.translate.instant('YOU_HAVE') + ' ' + this.unread + ' ' + this.translate.instant('NEW_NOTIFICATIONS'));
        }
      }
      if(this.notificationType === 'manual' && notreadquery.options.read === false && this.type) {
        this.unread = res.count;
        this.maincomponent.unreadManual = this.unread;
        if (this.unread > 0) {
          // tslint:disable-next-line:max-line-length
          this.notifier.notify('default', this.translate.instant('YOU_HAVE') + ' ' + this.unread + ' ' + this.translate.instant('NEW_COMMUNICATIONS'));
        }
      }
    }, error => {
      console.error('Error => ', error);
    });
  }

  navigate() {
    if (this.type) {
      this.getNotificationsList();
    } else {
      this.router.navigate([], {
        queryParams: this.query,
      });
    }
  }

  setAllAsRead() {
    // ciclo notifiche
    if (this.notificationType === 'auto') {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.autoNotificationsList.list.length; i ++) {
        const notiToRead = this.autoNotificationsList.list[i];
        // this.setAsReadApi(notiToRead._id);
        this.autoNotificationsList.list[i].read = true;
      }
      if (this.maincomponent.loggedUser.role.superAdmin) {
        // this.notifier.notify('default', this.translate.instant('MARKED_AS_READ'));
      }
      delete this.autoNotificationsList;
    } else {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.manualNotificationsList.list.length; i ++) {
        const notiToRead = this.manualNotificationsList.list[i];
        // this.setAsReadApi(notiToRead._id);
        this.manualNotificationsList.list[i].read = true;
      }
      if (this.maincomponent.loggedUser.role.superAdmin) {
        // this.notifier.notify('default', this.translate.instant('MARKED_AS_READ'));
      }
      delete this.manualNotificationsList;
    }

    this.setAllAsReadCall();
    this.getNotificationsList();
    this.getNotificationsListUnread();
  }

  setAsRead(id) {
    this.setAsReadApi(id);
    // this.notifier.notify('default', this.translate.instant('MARKED_AS_READ') + id);
    this.getNotificationsListUnread();
  }

  setAsReadApi(id) {
    this.notificationsServices.setAsRead(id).subscribe((res) => {
      console.error('Res => ', res);
      // delete this.notificationsList;
    }, error => {
      console.error('Error => ', error);
    });

  }

  setAllAsReadCall() {
    this.notificationsServices.setAllAsRead().subscribe((res) => {
      console.error('Res => ', res);
      // delete this.notificationsList;
    }, error => {
      console.error('Error => ', error);
    });
  }

  openDialog(element): void {
    const dialogRef = this.dialog.open(Notificationdialog, {
      width: '350px',
      data: { element },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed => ', result);
      if (result) {
        this.setAsRead(result);
      }
    });
  }

  loadMore() {
    this.currentpage = this.currentpage + 1;
    this.query.skip = (this.currentpage) * this.limit;
    this.navigate();
  }

  notificationAction(notification) {
    if (!notification.read) this.setAsRead(notification._id);
    notification.read = true;
    if (!notification.ref || notification.ref === 'Company') this.openDialog(notification);
    if (notification.ref === 'FlightPnr') this.notifier.notify('default', 'Ticket aperto su tool.flyleo.it');
    if (notification.ref === 'FlightPnr_v2') {
      this.router.navigate(['/flight/reservations/' + notification.payload]);
    }
    if (notification.ref === 'TktTicket') {
      this.notifier.notify('default', this.translate.instant('OPENING_TICKET'));
      this.router.navigate(['/ticketing/list/' + notification.payload]);
    }
    if (notification.type !== 'manual') {
      this.maincomponent.toggleNotifications();
    } else {
      this.maincomponent.toggleManualNotifications();
    }

  }

}

@Component({
   selector: 'notificationdialog',
   templateUrl: '../notification-dialog.html',
 })
export class Notificationdialog{

  constructor(
    public dialogRef: MatDialogRef<Notificationdialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
