<div class="row" *ngIf="!isTransit">
  <div class="col-md-6 col-12">
    <mat-form-field class="full-width">
      <input type="text" matInput placeholder="{{'DEPARTURE_LOCATION'|translate}}" [(ngModel)]="fromtext" (ngModelChange)="searchAirport($event)"
             aria-label="Departure" [matAutocomplete]="airp">
      <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearFrom()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #airp="matAutocomplete" (optionSelected)="getFrom($event.option.value)" (closed)="selectFirst('fromtext')">
        <mat-option style="height:40px;" *ngFor="let airport of airports" [value]="airport.iata+'-'+airport.name+'_'+airport.iatacity"
                    [ngStyle]="{'background-color': (airport.isCity) ? '#d3d3d330' : '', 'text-transform':'capitalize'}">
          <small *ngIf="!airport.isCity">&nbsp;&nbsp;&nbsp;</small>
          <small>{{airport.name}}</small>
          <small *ngIf="!airport.isCity"> ({{airport.iata}})</small>
          <small *ngIf="airport.isCity"> (All)</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </div>

  <div class="col-md-6 col-12">
    <mat-form-field class="full-width">
      <input matInput placeholder="{{'ARRIVAL_LOCATION'|translate}}" aria-label="Arrival" (ngModelChange)="searchAirport($event)"
             [(ngModel)]="totext" [matAutocomplete]="toairp" name="totext"> <!-- (blur)="selectFirst('totext')" -->
      <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearTo()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #toairp="matAutocomplete" (optionSelected)="getTo($event.option.value)" (closed)="selectFirst('totext')">
        <mat-option style="height:40px;" *ngFor="let airport of airports" [value]="airport.iata+'-'+airport.name+'_'+airport.iatacity"
          [ngStyle]="{'background-color': (airport.isCity) ? '#d3d3d330' : '', 'text-transform':'capitalize'}">
          <small *ngIf="!airport.isCity">&nbsp;&nbsp;&nbsp;</small>
          <small>{{airport.name}}</small>
          <small *ngIf="!airport.isCity"> ({{airport.iata}})</small>
          <small *ngIf="airport.isCity"> (All)</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

</div>

<div *ngIf="isTransit">
  <mat-form-field class="full-width">
    <span class="isInfo" matPrefix matTooltip="{{'TRANSIT_TEXT'|translate}}">
      <mat-icon>help_outline</mat-icon>
    </span>
    <input matInput placeholder="{{'TRANSIT'|translate}}" aria-label="Transit" (ngModelChange)="searchAirport($event)" [(ngModel)]="fromtext" [matAutocomplete]="airp">
    <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearFrom()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #airp="matAutocomplete" (optionSelected)="getFrom($event.option.value)" (closed)="selectFirst('fromtext')">
      <mat-option *ngFor="let airport of airports" [value]="airport.iata+'-'+airport.name">
        <span>{{airport.iata}}</span>-<small>{{airport.name}}</small>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
