import { Traveler } from '../../../classes/travelers/singletraveler';

export class ApiRes<T>{
  public Status : any;
  public Message : string;
  public Result : T;
}

export class StationResponse{
  public id : number;
  public italo_id : string;
  public trenitalia_id : number;
  public name : string;
  public description : string;
}

export class SearchCarnetBookingRequest{
  departure_date : string = new Date().toISOString();
  carnetId : string;
}

export class SearchTrainsRequest extends SearchCarnetBookingRequest{
  arrival_station : string;
  departure_station : string;
  return_date : Date;
  adults : number = 1;
  children : number = 0;
}

export class SearchTrainsResponse{
  public ForwardJourney : Journey[];
  public ReturnJourney : Journey[];
  public travellers : any;
  public carnet : any;
}

export class GetReservationRequest{
  filter;
  text;
  pnr;
  sort;
  limit;
  skip;
  order;
  companies;
  carnets;
  options;
  viewAll;
  channels: string[] = ['DIRECT'];
  constructor(query: any = {}){
    this.filter = query.filter || '';
    this.text = query.text || '';
    this.pnr = query.pnr || '';
    this.sort = query.sort || 'createdAt';
    this.limit = query.limit || 10;
    this.skip = query.skip || 0;
    this.order = query.order || -1;
    this.companies = query.companies || [];
    this.viewAll = query.viewAll || false;
    this.channels = query.channels || ['DIRECT'];
    // this.options = query.options || {};
    if (query.carnets) this.carnets = query.carnets;
  }
}

export class TrainReservationList{
  reservations : TrainReservation[];
  total_pages : number;
  total : number;
  skip : number;
  limit : number;
  filtered : boolean;
  tabs : PnrTab[];
}

export class TrainReservation{
  _id : any;
  approvalId: string | undefined;
  id : any;
  user : any;
  company : any;
  paper : any;
  itineraries : TrainItinerary[];
  travellers : TrainTraveller[];
  cancellation : any;
  carrier : string;
  pnr : string;
  issuedDate : Date;
  status : string;
  ratedPrice : number;
  createdAt : Date;
  updatedAt : Date;
  costCenter : string | undefined;
}

export class TrainLogResponse{
  createReservationResponse: { travel : any };
}

export class TrainAftersaleOperation{
  offeredServiceId : any;
  penaltyAmount : any;
  postSaleType : { code : string, subCode : string };
  allowed : boolean;
}

export class TrainAllowedOperations{
  travel : any;
  postSaleDetails : TrainAftersaleOperation[];
  catalog : any;
}

export interface TrainAuditTransaction{
  createdAt: Date;
  action: string;
  amountPaid: number;
  amountRefunded: number;
  newTravel: any;
  oldTravel: any;
}

export class TrainNote{
  description : string;
  items : any[];
  image : any;
}

export class TrainStop{
  location : string;
  arrivalTime : any;
  departureTime : any;
}

export class TrainInformation{
  classification : string;
  description : { departureStation : string, departureTime : any, arrivalStation : string, arrivalTime : any };
  notes : TrainNote[];
  stops : TrainStop[];
}

export class TrainAmount{
  amount : number;
  currency : string;
  indicative : boolean;
}

export class RefundRequest{
  travel : any;
  post_sale_details : any;
}

export class CheckPenaltyResponse{
  postSaleDetails : any;
  totalValue : TrainAmount;
  totalPenalties : TrainAmount;
  totalNetValue : TrainAmount;
  allowed : string;
  message : any;
}

export class CancelReservationResponse{
  status : any;
  reason : any;
}

export class AlternativeTravelSolution{
  travelSolution? : any;
  validatedPostSaleRecord : any;
  reusedPaymentRecords : any;
  reversedPaymentRecords : any;
  additionalCharges: any;
  missingAmount : TrainAmount;
}

export class ChangeDateResponse{
  searchSimilarRecords : AlternativeTravelSolution[] = [];
  travel : any;
  catalog : any;
  postSaleDetails?: any;
}

export class ApplyChange{
  travel: any;
  selectedSimilarRecord : AlternativeTravelSolution;
  notifyTraveller?: boolean; // unset will be default true on service
}

export class ChangeServiceResponse{
  result : any;
  segments : Journey[];
  prices : any;
  travel : any;
  selectedPostSaleDetails : any;
}

export class ValidateChangeServiceResponse{
  travel : any;
  catalog : TrainCatalog = new TrainCatalog();
  validatedPostSaleRecord : any;
  missingAmount : TrainAmount;
  reversedPaymentRecords : any;
  reusedPaymentRecords : any;
  additionalCharges : any;
}

export class SearchBaseRequest{
  travelSolution : any;
  forwardTrip : any;
  travellers : any;
  pit_code : string;

  constructor(travelSolution : any, forwardTrip : any, travellers : any, pit? : string){
    this.travelSolution = travelSolution;
    this.forwardTrip = forwardTrip;
    this.travellers = travellers;
    this.pit_code = pit;
  }
}

export class SearchBaseResponse{
  result : any;
  segments : any;
  travellers : any;
  customerKey : string | undefined;
}

export class TrenitReservation{
  public bookingDb : any;
  public travel : any;
  public carnetId? : string;
}

export class EvaluateTravelSolutionReq{
  travelSolution : TravelSolution;
  travellers : TrenitTraveller[];
  carnetId : string;
  customerKey : string;

  constructor(travelSolution : any, travellers : TrenitTraveller[], customerKey : any){
    this.travelSolution = travelSolution;
    this.travellers = travellers;
    this.customerKey = customerKey;
  }
}

export class EvaluateTravelSolutionRes{
  evaluateTravelSolutionsResponse : EvaluateTravelSolutionResponse;
  requiredTravellerParameters : { [xmlId : string] : RequiredTravellerParameter[] };
  allowedServices : AllowedServices;
}

export class EvaluateTravelSolutionResponse{
  serviceOutcome : any;
  travelSolutions : TravelSolution;
  travellers : any;
  validSelection : boolean;
}

export class ApplyDiscountCodeRequest{
  travelSolution : any;
  travellers : any;
  discountCode : string;

  constructor(travelSolution : any, travellers : any, discountCode : string){
    this.travelSolution = travelSolution;
    this.travellers = travellers;
    this.discountCode = discountCode;
  }
}

export class TravelSolutionResponse{
  travelSolution : any;
}

export class CreateReservationReq{
  travelSolution : any;
  travellers : any;
  carnetId : string;
  customerKey : string | undefined;

  constructor(travelSolution : any, travellers : any, customerKey : any){
    this.travelSolution = travelSolution;
    this.travellers = travellers;
    this.customerKey = customerKey;
  }
}

export class CreateReservationResponse{
  public bookingDb : any;
  public result : any;
  public carnetId : string;
}

export class CompleteReservationReq{
  travel : any;
  notifyTraveller : boolean;
  carnetId : string;
  costCenter : string;
  phoneNumber : any;

  constructor(travel : any, notifyTraveller : boolean, carnetId : string, costCenter: string, phoneNumber?: any){
    this.travel = travel;
    this.notifyTraveller = notifyTraveller;
    this.carnetId = carnetId;
    this.phoneNumber = phoneNumber;
    this.costCenter = costCenter;
  }
}

export class CompleteReservationResponse{
  bookingDb : any;
}

export class ProvideLayoutReq{
  travelSolution : any;
  paySeatSelection : boolean;

  constructor(travelSolution : any){
    this.travelSolution = travelSolution;
  }
}

export class ProvideLayoutResponse{
  layoutResults : LayoutQueryResponse[];
  catalog : TrainCatalog = new TrainCatalog();
}

export class TrainCatalog{
  stations: any = {};
  offers: any = {};
  services: any = {};
  trains: any = {};
}

export class LayoutQueryResponse{
  transportUnits : TransportUnit[];
  query : { solutionSegment : any };
}

export class TransportUnit{
  layout : string; // XML with SVG
  seatsAvailability : any; // SeatAvailability
  unitIndex : number;
  totalQuantity : number;
  availableQuantity : number;
  reservedSeatsQuantity : number;
}

export class SeatAvailability{
  wagonId : number;
  type : string;
  serviceId : string;
  seats : { number : number, airCraftNumber : string, available : boolean }[];
}

export class HoldItaloBookingResponse{
  Signature : string;
  Booking : any;
  bookingDb : any;
}

// -- OLD -- //

export class TrainItinerary{
  from : Itinerary;
  to : Itinerary;
  trainNumber : string;
  trainClassification : string;
  trainAcronym : string;
  bookingClass : string;
  fareType : string;
}

export class Itinerary{
  public date : Date;
  public station : string;
}

export class TrainTraveller{
  doc : Traveler;
  type : string;
  seats : TravellerSeat[];
}

export class TravellerSeat{
  trainIndex : number;
  row : number;
  column : string;
  wagon : string;
  price : number;
  raw : string;
}

export class PnrTab{
  _id : string;
  total : number = 0;
}

export class Journey{
  public key : string;
  public signature : string;
  public departureDate : Date;
  public arrivalDate : Date;
  public journeyLength : Date;
  public departureStation : { name: string };
  public arrivalStation : { name: string };
  public trainNumber : string;
  public trainClassification : any;
  public segments : any[];
  public fares : any[];
  public faresMatrix : { [serviceClass : string] : { [productClass : string] : any } };
  public company : 'ITALO' | 'TRENITALIA';
  public travelSolution : any;
}

export class TrenitEvaluatedTravels{
  public forwardTravel : TrenitEvaluatedTravel;
  public returnTravel : TrenitEvaluatedTravel;
  public roundtrip : boolean;
  public travellers : any;
  public requiredParameters : { [xmlId : string] : RequiredTravellerParameter[] };
  public allowedServices : AllowedServices;
}

export class RequiredTravellerParameter{
  public id : number;
  public name : string;
  public description : string;
  public typeDefinition : {
    validationPattern : string,
    inputPattern : string,
    minLength : number,
    maxLength : number,
    baseType : string,
  };
}

export class AllowedServices{
  seatSelection : boolean = false;
  seatSelectionCharged : any;
}

export class TrenitEvaluatedTravel{
  public travellers : TrenitTraveller[] = [];
  public travelSolutions : any;
  public serviceOutcome : TrenitServiceOutcome;
}

export class TrenitServiceOutcome{
  public status : string;
}

export class TrenitTraveller extends Traveler{
  public xmlId : string;
  public freccia : any;
  public seat : any;
  public parameters : TrenitTravellerParameter[];

  constructor(xmlId : string){
    super();
    this.xmlId = xmlId;
    this.parameters = [];
  }

}

export class TrenitTravellerParameter{
  public readOnly = false;
  public value : any = null;
  public valid = true;
  public typeId : number;
  public _private = false;

  constructor(id : number){
    this.typeId = id;
  }
}

export class StationDecode{
  [id : number] : { name : string }
}

export interface TrainBooking{
  ForwardJourney : any;
  ReturnJourney : any;
  travellers : any;
}

export class TrenitaliaBooking implements TrainBooking{
  ForwardJourney : any;
  ReturnJourney : any;
  travellers : TrenitTraveller[];
  requiredParameters : { [xmlId : string] : RequiredTravellerParameter[] };
  allowedServices : AllowedServices;
}

export interface IItaloState{
  journeyDetail : any;
  booking : ItaloBooking;
  heldBooking : HoldItaloBookingResponse;
}

export class ItaloState implements IItaloState{
  public journeyDetail : any = null;
  public booking : ItaloBooking = null;
  public heldBooking : HoldItaloBookingResponse = null;
}

export class ItaloBookingResult{
  public PaxCount : number;
  public CurrencyCode : string;
  public BookingSum : ItaloBookingSum;
  public Passengers : ItaloPassenger[];
  public Journeys : any[];
}

export class ItaloBookingSum{
  public AuthorizedBalanceDue : number;
  public BalanceDue : number;
  public TotalCost : number;
}

export interface ITrainState{
  italo : IItaloState;
}

export class ItaloPassengerSeatPreferences{
  public PropertyCode : string;
  public PropertyTypeCode : string;

  constructor(){
    this.PropertyCode = 'TRUE';
    this.PropertyTypeCode = 'WINDOW';
  }
}

export class ItaloPassenger extends TrenitTraveller{
  public PassengerNumber : number;
}

export class ItaloJourney{
  public JourneySellKey : string = null;
  public FareSellKey : string = null;
  public CurrencyCode : string;
  public Segments : [];
  public PassengerSeatPreference : ItaloPassengerSeatPreferences[];
  public SegmentSeats : any;
  public detail : any = null;
  public fare : any = null;

  constructor(){
    this.CurrencyCode = 'EUR';
    this.PassengerSeatPreference = null;
    this.SegmentSeats = null;
  }

}

export class ItaloBooking implements TrainBooking{
  public Signature : string;
  public ForwardJourney : ItaloJourney;
  public ReturnJourney : ItaloJourney;
  public travellers : ItaloPassenger[];
  public SourceSystem : number;
  public WaiveFee : boolean;

  constructor(){
    this.ForwardJourney = new ItaloJourney();
    this.ReturnJourney = new ItaloJourney();
    this.travellers = [];
    this.SourceSystem = 0;
    this.WaiveFee = false;
    this.Signature = null;
  }

}

export class ItaloPaymentField{
  public FieldName : string;
  public FieldValue : any;
}

export class ItaloPayment{
  public PaymentMethodType : number;
  public PaymentMethodCode : string;
  public CurrencyCode : string;
  public PaymentID : number;
  public AccountNumber : number;
  public Expiration : number;
  public PaymentFields : ItaloPaymentField[];

  public constructor(){
    this.CurrencyCode = 'EUR';
    this.PaymentID = 0;
  }
}

export class ItaloPaymentRequest{
  public Signature : string;
  public Payment : ItaloPayment;
}

export class TrainTravel{
  italo : {
    booking : ItaloBooking;
    finalizeBookingResponse : any;
    holdBookingResponse : HoldItaloBookingResponse
  };
  forwardJourney : Journey;
  returnJourney : Journey;
  travellers : TrenitTraveller[];
  selectedTravelSolution : TravelSolution;
  evaluatedTravel : EvaluateTravelSolutionRes;
  reservatedTravel : TrenitReservation;
  customerKey : string | undefined;
  arrayOfAlerts : any[];
}

export class TravelSolution{
  date : Date;
  xmlId : string;
  state : string;
  id : any;
  context : any;
  mainTransportTypes : string;
  returnTravelSolution : any;
  solutionNodes : any;
  offeredServices : any[];
  solutionId : string;
  totalPrice : any;
  saleability : string;
}

export class TrainOffer{
  id : string;
  displayName : string;
  name : string;
  price : any;
  description : string;
  available : number;
  family : string;
  requiresParams : number[];
  eligibility : {
    level : string;
    messages : string;
  };
}
