<custom-title></custom-title>
<div class="padding20">

  <div class="row">
    <div class="col-9">
      <div class="terminal">
        <div class="terminalcontent" >
          <div class="mb-3">
            <div *ngIf="rows.length == 0">
              Inserisci un comando
            </div>
            <div *ngIf="rows.length > 0">
              <div *ngFor="let row of rows; let l = last">
                <div [innerHtml]="row.text" [ngClass]="{'greenTx':row.command,'pdLeft':!row.command}"></div>
                <hr *ngIf="!l && !row.command"/>
              </div>
            </div>
          </div>
          <div>
            <p>Enter command</p>
            <input (keyup.enter)="onEnter()" (keyup.arrowup)="prevCommand()" (keyup.arrowdown)="nextCommand()" [(ngModel)]="text">

          </div>
        </div>
      </div>

    </div>
    <div class="col-3">
      <div class="terminal" *ngIf="commandList.length>0">
        <div class="terminalcontent">
          <ol *ngIf="commandList.length>0">
            <li *ngFor="let command of commandList" (click)="selectHistory(command)" style="cursor:pointer;">
              <span class="material-icons">arrow_right_alt</span> {{command}}
            </li>
          </ol>
        </div>
      </div>

    </div>
  </div>

</div>

