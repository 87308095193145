import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import * as $ from 'jquery';
import { AppComponent } from '../../../../app.component';
import { MainComponent } from '../../../mainmodule/main/main.component';
import { PriceService } from '../../../../services/price/price.service';
import { DossierService } from '../../../../services/dossier/dossier.service';
import {
  GetReservationRequest,
  PnrTab,
  TrainReservation,
  TrainReservationList,
} from '../../classes/train.models';
import { TrainService } from '../../train.service';
import { TrainUtils } from '../train.utils';

@Component({
  selector: 'train-reservationlist',
  templateUrl: './reservationlist.component.html',
  styleUrls: ['./reservationlist.component.scss'],
})
export class TrainReservationListComponent implements OnInit{

  constructor(
    public appComponent : AppComponent,
    public mainComponent : MainComponent,
    private route : ActivatedRoute,
    private router : Router,
    public priceservice : PriceService,
    private trainService : TrainService,
    private dossierService : DossierService,
  ){
    this.currentTab = 1;
    this.loading = true;
    this.progress = false;
    console.log('this.appcomponent.loggedUser =>', this.mainComponent.loggedUser);
  }

  reservationsList : TrainReservationList;
  @Input() page : string;
  @Input() idcompany : string;
  @Input() iduser : string;

  currentTab : number;
  currentpage : number;
  pageNumbers : number[];

  loading : boolean;
  progress : boolean;

  // setQuery
  query : GetReservationRequest = new GetReservationRequest();
  limit : number;
  skip : number;
  sort : string;
  order : boolean;

  // Filter
  filterCompanies = [];

  currentTrainId : string;

  saleChannels: string[] = ['DIRECT'];

  ngOnInit() : void{
    this.limit = 10;
    this.sort = 'createdAt';
    this.order = false;
    this.currentpage = 0;
    this.query.filter = '';
    this.query.viewAll = false;
    this.skip = (this.currentpage) * this.limit;
    const order = this.order ? 1 : - 1;

    if(this.mainComponent.loggedUser.company.services.b2c || this.mainComponent.loggedUser.role.superAdmin){
      this.saleChannels.push('B2C');
    }
    if(this.mainComponent.loggedUser.company.services.b2b || this.mainComponent.loggedUser.role.superAdmin){
      this.saleChannels.push('B2B');
    }
    if(this.mainComponent.loggedUser.role.superAdmin){
      this.saleChannels.push('CAU');
    }

    if (!this.page){
      this.route.queryParams.subscribe(params => {
        this.query.sort = params.sort || this.sort;
        this.query.limit = params.limit || this.limit;
        this.query.skip = params.skip || this.skip;
        this.query.order = params.order || order;
        this.query.filter = params.filter || '';
        this.query.pnr = params.pnr || '';
        this.query.companies = params.companies || [];
        this.query.viewAll = params.viewAll || false;
        this.currentpage = this.query.skip / this.query.limit;
        this.getReservationList();
      });
    }  else {
      this.navigate();
    }
    this.loading = false;
  }

  returnTabNum(text){
    if (this.reservationsList && text){
      return this.reservationsList.tabs.find((t : PnrTab) => t._id === text)?.total;
    } else{
      return 0;
    }
  }

  changeTab(value){
    if (this.query.filter !== value){
      this.query.filter = value;
      this.currentpage = 0;
      this.query.skip = 0;
      this.navigate();
    }

  }

  sortValue(value){
    this.query.sort = value;
    this.sort = value;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  reverseOrder(){
    if (!this.order){
      this.query.order = 1;
      this.order = true;
    } else{
      this.query.order = - 1;
      this.order = false;
    }
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  openFilters(){
    $('.pnrfilters').collapse('toggle');
  }

  setFilterText(){
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  clearFilterText(){
    this.currentpage = 0;
    this.query.skip = 0;
    this.query.pnr = '';
    this.navigate();
  }

  navigate(){
    if (this.page){
      this.getReservationList();
    } else{
      this.router.navigate([], {
        queryParams: this.query,
      });
    }
  }

  next(){
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev(){
    this.currentpage = (this.query.skip / this.query.limit) - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value){
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  getReservationList(){
    console.log('this.query => ', this.query);
    this.progress = true;
    this.trainService.getReservationList(this.query).subscribe((res) => {
      this.loading = false;
      this.reservationsList = res;
      if (this.reservationsList.filtered && this.query.filter !== ''){
        let totDocs = this.reservationsList.tabs.find((t:PnrTab) => t._id === this.query.filter).total;
        this.pageNumbers = [...Array(Math.ceil(totDocs / this.reservationsList.limit)).keys()]
        this.currentpage = this.reservationsList.skip / this.reservationsList.limit
      } else {
        this.currentpage = this.reservationsList.skip / this.reservationsList.limit;
        this.pageNumbers = [...Array(this.reservationsList.total_pages).keys()];
      }
      /*this.currentpage = this.reservationsList.skip / this.reservationsList.limit;
      this.pageNumbers = [...Array(this.reservationsList.total_pages).keys()];*/
      console.log('this.reservationsList => ', this.reservationsList);
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      this.scrollTop();
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  scrollTop(){
    if (!this.page){ $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300); }
    if (this.page){ $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300); }
  }

  toggleAddtoTravel(id){
    if (!this.mainComponent.dossierId){
      if (id){
        this.currentTrainId = id;
      } else{
        delete this.currentTrainId;
      }
      $('#travelListModal').modal('toggle');
    } else if (id && this.mainComponent.dossierId){
      this.addReservations(id);
    }
  }

  addReservations(id){
    const body = {
      trains: [id],
    };
    this.dossierService.addTrainsToDossier(this.mainComponent.dossierId, body).subscribe((res) => {
      console.log('res => ', res);
      this.getReservationList();
    }, error => {
      console.error('res => ', error);
    });
  }

  closeAddtoTravel(){
    delete this.currentTrainId;
    $('#travelListModal').modal('toggle');
    this.getReservationList();
  }

  // Companies Filter
  removeCpFilter(id){
    const index = this.filterCompanies.findIndex(r => r._id === id);
    if (index > - 1){
      this.query.companies.splice(index, 1);
      this.filterCompanies.splice(index, 1);
      this.currentpage = 0;
      this.query.skip = 0;
      this.navigate();
    }
  }

  clearAllCp(){
    this.query.companies = [];
    this.filterCompanies = [];
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  setCpFilter(){
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  public getTrenitLogo(reservation : TrainReservation) : string{
    return TrainUtils.getTrenitLogo(reservation, undefined, true);
  }

}
