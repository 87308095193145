/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { Address } from './models/Address';
export type { Amenity } from './models/Amenity';
export type { CompanyUserInfoDto } from './models/CompanyUserInfoDto';
export type { ContactInfo } from './models/ContactInfo';
export type { CorporateCreateDto } from './models/CorporateCreateDto';
export type { CorporateListResponseDto } from './models/CorporateListResponseDto';
export type { CorporateUpdateDto } from './models/CorporateUpdateDto';
export type { Description } from './models/Description';
export type { Destination } from './models/Destination';
export type { Email } from './models/Email';
export type { EstablishmentInfoResponseDto } from './models/EstablishmentInfoResponseDto';
export type { GuestRoom } from './models/GuestRoom';
export type { HotelAutocompleteResponseDto } from './models/HotelAutocompleteResponseDto';
export type { HotelAutocompleteResult } from './models/HotelAutocompleteResult';
export type { HotelCorporate } from './models/HotelCorporate';
export type { HotelGuest } from './models/HotelGuest';
export type { HotelInfoInfo } from './models/HotelInfoInfo';
export type { HotelOptions } from './models/HotelOptions';
export type { Image } from './models/Image';
export type { InfoElement } from './models/InfoElement';
export type { List } from './models/List';
export type { Occupancy } from './models/Occupancy';
export type { PhoneNumber } from './models/PhoneNumber';
export type { PnrChainLocator } from './models/PnrChainLocator';
export type { PnrFare } from './models/PnrFare';
export type { PnrFareGuest } from './models/PnrFareGuest';
export type { PnrHotel } from './models/PnrHotel';
export type { PnrHotelAddress } from './models/PnrHotelAddress';
export type { PnrHotelReservation } from './models/PnrHotelReservation';
export type { PnrPassenger } from './models/PnrPassenger';
export type { PnrPassengerDocument } from './models/PnrPassengerDocument';
export type { Position } from './models/Position';
export type { ReservationBookRequestDto } from './models/ReservationBookRequestDto';
export type { ReservationBookResponseDto } from './models/ReservationBookResponseDto';
export type { ReservationCreateRequestDto } from './models/ReservationCreateRequestDto';
export type { ReservationCreateResponseDto } from './models/ReservationCreateResponseDto';
export type { Restaurant } from './models/Restaurant';
export type { Room } from './models/Room';
export type { SearchFareRateResponseDto } from './models/SearchFareRateResponseDto';
export type { SearchFareRequestDto } from './models/SearchFareRequestDto';
export type { SearchFareResponseDto } from './models/SearchFareResponseDto';
export type { SearchFormDestination } from './models/SearchFormDestination';
export type { SearchFormHotelGuest } from './models/SearchFormHotelGuest';
export type { SearchFormRequestDto } from './models/SearchFormRequestDto';
export type { SearchHistoryItemDto } from './models/SearchHistoryItemDto';
export type { SearchHistoryResponseDto } from './models/SearchHistoryResponseDto';
export type { SearchHotelResultDto } from './models/SearchHotelResultDto';
export type { SearchHotelResultListDto } from './models/SearchHotelResultListDto';
export type { SearchHotelResultPositionDto } from './models/SearchHotelResultPositionDto';
export type { Session } from './models/Session';

export { CorporateService } from './services/CorporateService';
export { EstablishmentService } from './services/EstablishmentService';
export { ReservationService } from './services/ReservationService';
export { SearchService } from './services/SearchService';
