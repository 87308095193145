import {
  AfterViewInit,
  Component, EventEmitter,
  Input, Output,
} from '@angular/core';
import * as $ from 'jquery';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BottomsheetComponent } from '../../../../flight/components/flight/searchflight/bottomsheet/bottomsheet.component';
import {
  RequiredTravellerParameter,
  TrainTravel,
  TrenitTraveller,
} from '../../../classes/train.models';

@Component({
  selector: 'app-passenger-cards',
  templateUrl: 'passenger-cards.component.html',
  styleUrls: ['./passenger-cards.component.scss'],
})
export class PassengerCards implements AfterViewInit{
  currentTraveler : number;
  loadTravelers : boolean;
  editingTraveler : boolean;
  @Input() maxPax : number = 9;
  @Input() passengers : TrenitTraveller[];
  @Input() travel : TrainTravel = undefined;
  @Input() trainCompany : string = 'train';
  @Input() editable : boolean = true;
  @Output() setPassengers : EventEmitter<any> = new EventEmitter();
  @Output() removePassengers : EventEmitter<any> = new EventEmitter();
  horizontalPosition : MatSnackBarHorizontalPosition = 'end';
  verticalPosition : MatSnackBarVerticalPosition = 'top';
  initialPax : TrenitTraveller[];

  constructor(
    public dialog : MatDialog,
    private bottomSheet : MatBottomSheet,
    public translate : TranslateService,
  ){
  }

  ngAfterViewInit() : void{
    this.initialPax = [...this.passengers];
    console.log('this.passengers', this.passengers)
  }

  toggleDialog(currentTraveler){
    this.currentTraveler = currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  toggleEditDialog(currentTraveler){
    this.editingTraveler = true;
    this.currentTraveler = currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  travelerController(){
    const isValidTraveler = (value) => !(!value.name || !value.surname); // || !value.phoneNumber
    return this.passengers.every(isValidTraveler);
  }

  removeTraveler(i: number){
    this.passengers[i] = {...new TrenitTraveller(undefined)};
    this.removePassengers.emit({index : i, isDelete : false})
  }

  travelerSet(event: any, i: number){
    console.log('this.passengers event => ', event);
    this.passengers[i] = {
      ...this.passengers[i],
      ...JSON.parse(JSON.stringify(event)),
      freccia: this.getCartaFreccia(this.passengers[i]),
    };
    console.log('this.passengers => ', this.passengers);
    this.setPassengers.emit(this.passengers)
    delete this.currentTraveler;
    // $('#addTravelerModal').modal('toggle');
  }

  openBottomSheet(fare){
    this.bottomSheet.open(BottomsheetComponent, {
      data: { fare },
    });
  }

  closeEvent(event){
    delete this.currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  closeEditEvent(event){
    this.editingTraveler = false;
    delete this.currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  closeListEvent(event){
    delete this.currentTraveler;
    $('#listTravelerModal').modal('toggle');
  }

  loadTravelersList(currentTraveler){
    this.currentTraveler = currentTraveler;
    $('#listTravelerModal').modal('toggle');
    this.loadTravelers = true;
  }

  getCartaFreccia(traveller : TrenitTraveller) : any{
    const frecciaFromLoyalty = (traveller.loyaltyCodes) ? traveller.loyaltyCodes.find(lc => lc.product === 'TRENITALIA')?.code : undefined;
    const frecciaFromParam = traveller.parameters?.find((p : any) => Number.parseInt(p.typeId, 10) === 13)?.value;
    return frecciaFromParam || frecciaFromLoyalty;
  }

  getRequiredParameter(requiredParameters : { [xmlId : string] : RequiredTravellerParameter[] } | undefined, i : number, traveller : TrenitTraveller) : RequiredTravellerParameter{
    return requiredParameters[traveller.xmlId][i];
  }

  addPax(){
    this.passengers.push(new TrenitTraveller(undefined));
  }

  removePax(index : number){
    this.passengers.splice(index, 1);
    if (this.passengers.length === 0){
      this.passengers.push(new TrenitTraveller(undefined));
    }
    this.removePassengers.emit(index)
  }

  getUsefulParams(parameters : any){
    return parameters?.filter(p => ![13, 14, 10, 11, 20, 12, 15, 49, 50].includes(Number.parseInt(p.typeId, 10)));
  }
}
