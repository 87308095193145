<h4 style="margin: 20px">User Authorizations</h4>
<ng-container *ngIf="authorizations$ | async as authorizations; else loading">
  <div class="container" *ngIf="authorizations.data.length">
    <app-authorization-card (submitted)="handleSubmit($event)" *ngFor="let auth of authorizations.data" [authorization]="auth"></app-authorization-card>
  </div>
  <div class="no-data-found container" *ngIf="!authorizations.data.length">
    <h4>No Authorization Requests Found</h4>
  </div>
  <pre>
  </pre>
</ng-container>
<ng-template #loading>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
