<div class="singleRoomDetails">
  <div class="row">

    <div class="col-md-2 text-center align-self-center">
      <span class="material-icons">
        bed
      </span>
    </div>

    <div class="col-md-9 align-self-center">
      <div *ngFor="let rate of room.roomRates" class="mb-2">
        <div class="mb-2 smalltext" *ngIf="!isSbt && !isGat">
          {{returnProvider(room.chain)}}
        </div>
        <div class="mb-2 smalltext" *ngIf="isSbt || isGat">
          <div *ngIf="checkVoucher(room.chain)">Prepaid Voucher</div>
        </div>
        <div class="rateText">
          <span *ngIf="corporate(rate.text)" class="d-block greentxt"><b>{{'CORPORATE'|translate}}</b></span>
          {{rate.text}}
        </div>
      </div>
    </div>

    <div class="col-md-12 align-self-center">
      <div class="midCol">
        <div *ngIf="!isFreeCancellationBis()" class="">
          <div *ngFor="let plan of room.ratePlans">

            <div *ngFor="let penal of plan.penalty; let l = last" class="penalty">
              <!-- <b *ngIf="!penal.nonRefundable">{{penal.type}}</b>:
              <span *ngIf="penal.nonRefundable==false">{{'REFUNDABLE_FARE'|translate}} {{'UNTIL'|translate}} </span>
              <span *ngIf="!penal.absoluteDeadLine">{{room.startDate|date:'dd/MM/yyyy - HH:mm'}}</span>
              <span *ngIf="penal.absoluteDeadLine">{{penal.absoluteDeadLine|date:'dd/MM/yyyy - HH:mm'}} </span> -->
              <div class="row mb-2">
                <div class="col-2 text-center align-self-center">
                  <span class="material-icons text-danger">
                    highlight_off
                  </span>
                </div>
                <div class="col-10 align-self-center">
                  {{'NO_REFOUND'|translate}}
                  <br/>
                  <span *ngIf="penal.amount && !l">{{'CANCELLATION'|translate}} {{penal.amount|number:'.2-2'}} {{room.currency}}
                    <span *ngIf="room.currency != 'EUR'"><br/>({{convertPrice(penal.amount, room.currency)|number:'.2-2'}}€)</span>
                  </span>
                  <span *ngIf="penal.amount && l">{{'CANCELLATION'|translate}} {{room.roomRates[0].amount|number:'.2-2'}} {{room.currency}}
                    <span *ngIf="room.currency != 'EUR'"><br/>({{convertPrice(room.roomRates[0].amount, room.currency)|number:'.2-2'}}€)</span>
                  </span>
                  <span *ngIf="!penal.amount">{{'CANCELLATION'|translate}} {{room.roomRates[0].amount|number:'.2-2'}} {{room.currency}}
                    <span *ngIf="room.currency != 'EUR'"><br/>({{convertPrice(room.roomRates[0].amount, room.currency)|number:'.2-2'}}€)</span>
                  </span>
                </div>
              </div>
            </div>

          </div>
          <div *ngIf="!room.ratePlans[0]" class="penalty">
            <div class="row mb-2">
              <div class="col-2 text-center align-self-center">
                  <span class="material-icons text-danger">
                    highlight_off
                  </span>
              </div>
              <div class="col-10 align-self-center">
                {{'NO_REFOUND'|translate}}
                <br/>
                <span>{{'CANCELLATION'|translate}} {{room.roomRates[0].amount|number:'.2-2'}} {{room.currency}}
                  <span *ngIf="room.currency != 'EUR'"><br/>({{convertPrice(room.roomRates[0].amount, room.currency)|number:'.2-2'}}€)</span>
                </span>
              </div>
            </div>
          </div>

          <div *ngIf="!room.ratePlans[0].penalty[0]" class="penalty">
            <div class="row mb-2">
              <div class="col-2 text-center align-self-center">
                  <span class="material-icons text-danger">
                    highlight_off
                  </span>
              </div>
              <div class="col-10 align-self-center">
                {{'NO_REFOUND'|translate}}<br/>
                <span>{{'CANCELLATION'|translate}} {{room.roomRates[0].amount|number:'.2-2'}} {{room.currency}}
                  <span *ngIf="room.currency != 'EUR'"><br/>({{convertPrice(room.roomRates[0].amount, room.currency)|number:'.2-2'}}€)</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isFreeCancellationBis()" class="">
          <div *ngFor="let plan of room.ratePlans">
            <div *ngFor="let penal of plan.penalty; let l = last;" class="penalty">
              <div class="row mb-2">
                <div class="col-2 text-center align-self-center">
                  <span class="material-icons greentxt">
                    task_alt
                  </span>
                </div>
                <div class="col-10 align-self-center">
                  {{'YES_REFOUND'|translate}}<br/> {{'TILL'|translate}}
                  <span *ngIf="penal.absoluteDeadLine"> {{removeHr(penal.absoluteDeadLine)|date:'dd/MM/yyyy - HH:mm'}} </span>
                </div>
              </div>

              <div class="row">
                <div class="col-2 text-center align-self-center">
                  <span class="material-icons">
                    arrow_right_alt
                  </span>
                </div>
                <div class="col-10 align-self-center">
                  {{'CANCELLATION'|translate}} {{'DAL'|translate}}
                  <span *ngIf="penal.absoluteDeadLine"> {{penal.absoluteDeadLine|date:'dd/MM/yyyy'}} </span><br/>
                  <span *ngIf="penal.amount && !l"><b> {{penal.amount|number:'.2-2'}} {{room.currency}}</b>
                    <span *ngIf="room.currency != 'EUR'"><br/>({{convertPrice(penal.amount, room.currency)|number:'.2-2'}}€)</span>
                  </span>
                  <span *ngIf="penal.amount && l"><b> {{room.roomRates[0].amount|number:'.2-2'}} {{room.currency}}</b>
                    <span *ngIf="room.currency != 'EUR'"><br/>({{convertPrice(room.roomRates[0].amount, room.currency)|number:'.2-2'}}€)</span>
                  </span>

                  <span *ngIf="!penal.amount"><b>{{room.roomRates[0].amount|number:'.2-2'}} {{room.currency}}</b>
                    <span *ngIf="room.currency != 'EUR'"><br/>({{convertPrice(room.roomRates[0].amount, room.currency)|number:'.2-2'}}€)</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-2 text-center align-self-center">
            <span class="material-icons">
              local_dining
            </span>
          </div>
          <div class="col-10 align-self-center" style="font-size:12px;">
            {{room.ratePlans[0].mealPlan}}
          </div>
        </div>
      </div>

    </div>

    <div class="col-md-12 align-self-center">
      <hr/>
      <div *ngFor="let rate of room.roomRates, let ind = index;" class="mb-2">
        <!-- <div class="roomPrice">{{rate.amount|number:'.2-2'}}€</div> -->
        <div>
          <div class="buttonrow">
            <p class="mb-1 smalltext" *ngIf="!isBooking(room.chain)">
              Tassa di soggiorno non inclusa
            </p>
            <p class="mb-1 smalltext">{{numberOfNights}} <span *ngIf="numberOfNights == 1">{{'NIGHT'|translate}}</span><span *ngIf="numberOfNights > 1">{{'NIGHTS'|translate}}</span></p>
            <!--
            <p *ngIf="rate.amountBeforeTaxes > 0" class="mb-1 smalltext"> Net:
              <span>{{rate.amountBeforeTaxes|number:'.2-2'}} {{room.currency}}</span>
            </p>
            -->
            <p *ngIf="rate.rates[0] && rate.rates[0].type" class="mb-1 smalltext"> Payment Type:
              <span>{{rate.rates[0].type}}</span>
            </p>
            <p *ngIf="rate.rates[0] && rate.rates[0].description" class="mb-1 smalltext"> Payment Info:
              <span>{{rate.rates[0].description}}</span>
            </p>
            <button (click)="selectRoomFare(rate, ind)" color="primary" mat-stroked-button>
              {{priceService.returnPriceHotel(rate.amount)|number:'.2-2'}} {{room.currency}}
              <span *ngIf="room.currency != 'EUR'" class="material-icons">arrow_right_alt</span>
              <span *ngIf="room.currency != 'EUR'">{{convertPrice(rate.amount, room.currency)|number:'.2-2'}} EUR</span>

              <!-- {{'SELECT_FARE'|translate}} -->
            </button>
            <div class="pt-2" *ngIf="isB2B || isSbt">
              <div *ngIf="room.arrayOfAlerts && room.arrayOfAlerts.length > 0; else noAlert"> <!-- (mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) -->
                <div class="verySmall text-danger pointer" (click)="room.showPolicy =! room.showPolicy">
                  Out of policy <span *ngIf="!room.showPolicy" class="smalIcon material-icons ml-1 pointer">expand_more</span>
                  <span *ngIf="room.showPolicy" class="smalIcon material-icons ml-1 pointer">expand_less</span>
                </div>
                <div *ngIf="room.showPolicy">
                  <div class="verySmall text-danger" *ngFor="let alert of room.arrayOfAlerts">
                    <span class="smalIcon material-icons mr-1">warning_amber</span> {{alert.message}}
                  </div>
                </div>

              </div>
              <ng-template  #noAlert>
                <div class="text-right"> <!-- (mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) -->
                  <div class="verySmall text-success">
                    <span class="smalIcon material-icons mr-1">check</span> in policy</div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div *ngIf="room.Infos">
        <div *ngFor="let info of room.Infos;let l = last;let i = index">
          <span *ngIf="info.name">{{info.name}} - </span>
          <span *ngIf="info.maxOccupancy">Max-occupancy {{info?.maxOccupancy}}</span>
          <span *ngIf="info.maxChild"> - Max-child {{info?.maxChild}}</span>

          <mat-accordion *ngIf="info.amenities">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'AMENITIES'|translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <span class="amenity" *ngFor="let amenity of info.amenities;let a = index">
                  {{amenity.type}}
                </span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <hr *ngIf="!l"/>
        </div>
      </div> -->

    </div>
    <!--
    <div *ngIf="room.Infos" class="col-12">
      <hr *ngIf="room.Infos.length > 0"/>


      <div *ngFor="let info of room.Infos;let l = last;let i = index">
        <div>
          <span *ngIf="info.name">{{info.name}} - </span>
          <span *ngIf="info.maxOccupancy">Max-occupancy {{info?.maxOccupancy}}</span>
          <span *ngIf="info.maxChild"> - Max-child {{info?.maxChild}}</span>
        </div>
        <span *ngFor="let amenity of info.amenities;let a = index" class="amenity">
            <span class="material-icons">done</span> {{amenity.type}}
          </span>
      </div>

    </div>

    <div *ngIf="room.roomServices && room.roomServices.length>0" class="col-12">
      <hr/>
      {{'SERVICES'|translate}}
      <div *ngFor="let service of room.services">
        <div *ngIf="service">
          {{searchRes.services[service].inventoryCode}}, <span *ngIf="searchRes.services[service].included">{{'INCLUDED'|translate}}</span> <span
          *ngIf="!searchRes.services[service].included">{{'NOT_INCLUDED'|translate}}</span>
        </div>

      </div>
    </div>
    -->
  </div>
</div>

