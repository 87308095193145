<div class="dialog">
  <h2 mat-dialog-title>
    Delete
  </h2>
  <mat-dialog-content>
    <div class="dialog__body">
      Are you sure want to delete <strong>{{data.data?.policyName}}</strong>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="buttonrow">
      <button mat-raised-button color="secondary" [mat-dialog-close]="false">No</button>
      <button mat-raised-button  [mat-dialog-close]="true" color="primary">Yes</button>
    </div>

  </mat-dialog-actions>
</div>

