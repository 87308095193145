import { Component, OnInit } from '@angular/core';
import { Settings } from '../../../../classes/profiles/settings';
import { AppComponent } from '../../../../app.component';
import { MainComponent } from '../../../mainmodule/main/main.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '../../../../services/profile/users.service';
import { TranslateService } from '@ngx-translate/core';
import {HttpserviceService} from '../../../../services/httpservice.service';
import {CustomAuthService} from '../../../../services/customAuth/custom-auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  currentSetting: Settings;
  isSbt: boolean;
  isB2B: boolean;
  constructor(
    public appcomponent: AppComponent,
    public maincomponent: MainComponent,
    private snackBar: MatSnackBar,
    private userservice: UsersService,
    public translate: TranslateService,
    public httpService: HttpserviceService,
    public customAuth: CustomAuthService,
  ) { }

  ngOnInit() {
    this.isSbt = localStorage.getItem('isStep') === 'true';
    this.isB2B = localStorage.getItem('isStep') === 'true';
    // this.currentSetting = this.maincomponent.userSettings;
    // console.log('module settings => ', this.currentSetting );
    if (this.appcomponent.isSbt && !this.maincomponent.userSettings.menuTop) {
      this.maincomponent.userSettings.menuTop = true;
      this.updateValue();
    }
    if (!this.maincomponent.userSettings.markupHotel) {
      this.maincomponent.userSettings.markupHotel = {
        type: 'sum',
        value: 0,
      };
    }
    if (!this.maincomponent.userSettings.markup) {
      this.maincomponent.userSettings.markup = {
        type: 'sum',
        value: 0,
      };
    }
  }

  updateValue() {
    this.updateSettings();
  }

  setFlyMarkupType(event) {
    this.maincomponent.userSettings.markup.type = event;
    this.updateSettings();
  }

  setHotelMarkupType(event) {
    this.maincomponent.userSettings.markupHotel.type = event;
    this.updateSettings();
  }

  updateSettings() {
    this.userservice.updateUserSettings(this.maincomponent.loggedUser._id, this.maincomponent.userSettings).subscribe((res) => {
      this.maincomponent.getUserSettings();
      this.snackBar.open(this.translate.instant('SETTINGS_UPDATED'), 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  setB2b(event) {
    this.isSbt = event.checked;
    this.appcomponent.setSearch(this.isSbt);
  }
  setB2bb(event) {
    this.isB2B = event.checked;
    this.appcomponent.setB2BSearch(this.isB2B);
  }

  setStepSearch(event) {
    this.appcomponent.stepSearch = event.checked;
    localStorage.setItem('fl_searchType', JSON.stringify(this.appcomponent.stepSearch));
  }

}
