import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchCardComponent} from './search-card.component';
import {MatButtonModule} from '@angular/material/button';
import {ProductInfoDialogModule} from '../product-info-dialog/product-info-dialog.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';


// noinspection AngularInvalidImportedOrDeclaredSymbol
@NgModule({
  declarations: [ SearchCardComponent ],
  exports: [
    SearchCardComponent
  ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        ProductInfoDialogModule,
        MatCardModule,
        MatIconModule
    ]
})
export class SearchCardModule { }
