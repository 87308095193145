<button (click)="toggleChangeTravellerModal()" [disabled]="isDisabled" id="TRAVELLER_CHANGE" mat-button type="button">
  {{'TRAIN_MODULE.AFTERSALE.TRAVELLER_CHANGE'|translate}}
</button>

<div aria-hidden="true" aria-labelledby="changeTravellerModal" class="modal fade" data-backdrop="static" id="changeTravellerModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-md" role="document" style="top:70px">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="changeTravellerModalTitle">{{'TRAIN_MODULE.AFTERSALE.TRAVELLER_CHANGE'|translate}}</h5>
        <button (click)="toggleChangeTravellerModal()" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h6>{{'TRAIN_MODULE.AFTERSALE.CHANGE_TRAVELLER_QUESTION'|translate}}</h6>
        <div class="buttonrow">
          <ng-container *ngFor="let tra of getTravellers()">
            <button (click)="addTraveller(tra)" [ngClass]="(currentSelection === tra) ? 'btn-selected':''" class="mr-2 mb-2 pl-2" mat-raised-button>
              {{tra.name}} {{tra.surname}}
            </button>
          </ng-container>
        </div>
        <ng-container *ngFor="let tra of getTravellers()">
          <div *ngIf="!!selectedTravellers[tra.id] && currentSelection === tra" class="col-md-8">
            <app-passenger-cards [maxPax]="1" [passengers]="selectedTravellers[tra.id]" [trainCompany]="'trenitalia'"
                                 [travel]="allowedOperations.travel"></app-passenger-cards>
          </div>
        </ng-container>
        <div class="text-right">
          <button (click)="toggleChangeTravellerModal()" mat-button type="button">{{'TRAIN_MODULE.ABORT'|translate}}</button>
          <button (click)="changeTraveller()" [disabled]="isChangeReady()" mat-button type="button">
            {{'TRAIN_MODULE.AFTERSALE.CONFIRM_CHANGE'|translate}}
            <span *ngIf="(changeReservation$|async)">
              <mat-icon class="spin">autorenew</mat-icon>
            </span>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
