import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../../app.component';
import {AuthenticationService} from '../../../services/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {DossierService} from '../../../services/dossier/dossier.service';
import * as moment from 'moment';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  cid: string;
  securitykey: string;
  travelcode: string;
  userid: string;
  start: any;
  end: any;
  loading: boolean;
  constructor(
    public appcompo: AppComponent,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private titleService: Title,
    private route: ActivatedRoute,
    private dossierService: DossierService,
    private router: Router,
  ) {
    this.loading = false;
  }

  ngOnInit(): void {
    this.loading = true;
    this.authService.logoutFromSignIn();
    // tslint:disable-next-line:max-line-length
    // ?cid=3001&securitykey=8fec82af4cb00e0237a6ae8670e73fb1&travelcode=T000000241&start=20210316&end=20210327&userid=MzAwMS0lJS08Ymxhbms%2BLSUlLTAzLSUlLTEwLSUlLTIwMDAtJSUtMDEtJSUtMDAxLSUlLTxibGFuaz4tJSUt
    this.route.queryParams.subscribe(params => {
      this.cid = params.cid;
      this.securitykey = params.securitykey;
      this.travelcode = params.travelcode;
      this.userid = decodeURIComponent(params.userid);

      this.start = moment(params.start);
      this.end = moment(params.end);
      this.start = new Date(this.start);
      this.end = new Date(this.end);
    });
    console.log('this.cid => ', this.cid);
    console.log('this.securitykey => ', this.securitykey);
    console.log('this.userid => ', btoa(this.userid));
    console.log('this.start => ', this.start);
    console.log('this.end => ', this.end);
    const data = {
      key: this.securitykey,
      userId: this.userid,
      dossierId: this.travelcode,
      travelerId: this.cid,
      start: this.start,
      end: this.end,
    };

    setTimeout(() => {
      if (this.securitykey) {
        this.loginAction(data);
      }
    }, 400 );
  }

  loginAction(data): void {
    this.authService.singleSignIn(data).subscribe((res) => {
      this.loading = false;
      console.log('res => ', res);
      this.authService.setUserSignIn(res);
      this.createDossier();
      console.log('login response => ', res);
      // tslint:disable-next-line:max-line-length
      this.snackBar.open(this.appcompo.translate.instant('ACCESS_PERMITTED') + ' ' + res.profile.surname + ' ' + res.profile.name, 'X', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }, error => {
      this.snackBar.open(this.appcompo.translate.instant('LOGIN_ERROR'), 'ok', {
        duration: 60000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'snackerror'
      });
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  createDossier() {
    const body = {
      sarce: {
        userid: this.userid,
        end: this.end,
        start: this.start,
        travelcode: this.travelcode,
        cid: this.cid,
      },
      flights: [],
      hotels: [],
      trains: [],
      cars: []
    };
    this.dossierService.createDossier(body).subscribe((res) => {
      console.log('res => ', res);
      this.nameDossier(res._id);
    }, error => {
      console.error('error => ', error);
      if (error._id) {
        localStorage.setItem('dossierIsActive', JSON.stringify(true));
        this.router.navigate(['/dossier/list/' + error._id]);
      }
    });
  }

  nameDossier(id) {
    const body = {
      name: 'TR' + this.travelcode,
    };
    this.dossierService.nameDossier(body, id).subscribe((res) => {
      console.log('res => ', res);
      localStorage.setItem('dossierIsActive', JSON.stringify(true));
      this.router.navigate(['/dossier/list/' + id]);
      // this.navigateToDossier(id);
      // this.addDossierToggle();
    }, error => {
      console.error('res => ', error);
    });
  }

}
