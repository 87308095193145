<div *ngIf="loading" class="loader">
  <div class="card text-center padding20">
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
    <h5>{{'LOADING'|translate}}...</h5>
  </div>
</div>

<div class="fopsContainer" *ngIf="!loading">

  <div class="smallActionButtons text-right mb-3" *ngIf="maincomponent.loggedUser.role.superAdmin && !reservation">
    <button mat-raised-button (click)="toggleAddModal()">
      {{'ADD_CREDIT_CARD'|translate}}
      <span class="material-icons">
        add
      </span>
    </button>
  </div>
<div class="row" *ngIf="supportedCards">
  <div class="col-12 mb-2" >
    <p>Carte supportate:</p>
    <span *ngFor="let card of reservation.supportedCards">
      <span class="supportedCards">{{card.CardType}}</span>
    </span>
  </div>
</div>

  <!--
  <div class="row">
    <div class="col-lg-4 col-md-6" *ngFor="let fop of fops; let f = index">
      <mat-card class="mb-4 singlefop" (click)="selectFop(fop)" [ngClass]="{'fopselected':fop.selected}">
        <mat-card-header>
          <div *ngIf="fop.type==='CC'" mat-card-avatar class="example-header-image" [style.background]="'url(assets/img/fops/' + fop.card.type + '.png)'"></div>
          <div *ngIf="fop.type!=='CC'" mat-card-avatar class="example-header-image" style="background-image: url('assets/img/fops/TP.png')"></div>
          <mat-card-title>{{fop.name}} <span *ngIf="fop.type!=='CC'">(Airplus)</span></mat-card-title>
          <mat-card-subtitle>**** **** **** **** {{fop.card.number}}</mat-card-subtitle>
          <mat-card-subtitle>Valid thru {{fop.card.expirationMonth}}/{{fop.card.expirationYear}}</mat-card-subtitle>
          <mat-card-subtitle *ngIf="reservation && !fop.accepted" class="text-danger">{{'NOT_ACCEPTED'|translate}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions *ngIf="maincomponent.loggedUser.role.superAdmin && !reservation">
          <button (click)="toggleDeleteModal(fop)" mat-button>{{'DELETE'|translate}}</button>
        </mat-card-actions>
      </mat-card>

    </div>
  </div>
  -->


  <div class="creditCardContainer" *ngIf="!loading">
    <div *ngIf="(fops && fops.length == 0) || !fops">
      {{'NO_CREDIT_CARDS_YET'|translate}}
    </div>
    <div *ngIf="fops &&  fops.length > 0">
      <div class="singleCreditCard" *ngFor="let card of fops">
        <div class="creditCard">
          <div class="face front bg{{card.card.type}}" [ngClass]="{'selectedCard':card.selected}">
            <h3 class="debit">{{card.name}}</h3>
            <h3 class="bank">
            <span *ngIf="card.type === 'CC'">
              {{returnCreditCardName(card.card.type)}}
            </span>
              <span *ngIf="card.type !== 'CC'">
              AirPlus
            </span>
            </h3>
            <img class="chip" src="/assets/images/chip.png" alt="chip">
            <h3 class="number">xxxx xxxx xxxx {{card.card.number}}</h3>
            <h5 class="valid">
              <span>VALID <br /> THRU</span>
              <span>{{card.card.expirationMonth}}/{{card.card.expirationYear}}</span>
            </h5>
            <h5 class="card-holder">{{card.card.holder}}</h5>
          </div>
          <div class="cardSelection" *ngIf="maincomponent.loggedUser.role.superAdmin && !reservation">
            <span class="selectCard" (click)="toggleDeleteModal(card)">
              {{'DELETE'|translate}}
            </span>
          </div>
          <div class="cardSelection" *ngIf="reservation">
            <span class="selectCard" *ngIf="!card.selected && card.accepted" (click)="selectFop(card)">
              {{'SELECT'|translate}}
            </span>
            <span class="selectedCard" *ngIf="card.selected">
              {{'CARD_SELECTED'|translate}}
            </span>
            <span class="bg-danger p-1 d-inline-block text-white" *ngIf="!card.accepted" class="text-danger">
              {{'NOT_ACCEPTED'|translate}}
            </span>
          </div>
        </div>
      </div>


    </div>
  </div>



  <div class="modal fade" id="addFopModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="addFopModal" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="top:70px" role="document">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addFopModalTitle">{{'ADD_PAYMENT'|translate}}</h5>
          <button type="button" class="close" (click)="toggleAddModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6">
              <div>
                <mat-form-field class="full-width">
                  <mat-label>{{'CARD_TYPE'|translate}}</mat-label>
                  <mat-select [(ngModel)]="newFop.type">
                    <mat-option value="CC">
                      CREDIT CARD
                    </mat-option>
                    <mat-option value="TP">
                      AIRPLUS
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="newFop.type==='CC'">
                <mat-form-field class="full-width">
                  <mat-label>{{'CARD_CIRCUIT'|translate}}</mat-label>
                  <mat-select [(ngModel)]="newFop.card.type">
                    <mat-option value="AX">American Express</mat-option>
                    <mat-option value="DC">Diners</mat-option>
                    <mat-option value="CA">Master Card</mat-option>
                    <mat-option value="VI">Visa</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div>
                <div class="row">
                  <div class="col-6 p-1">
                    <mat-form-field class="small-width d-inline">
                      <mat-label>{{'CARD_MONTH_EXPIRATION'|translate}}</mat-label>
                      <mat-select [(ngModel)]="newFop.card.expirationMonth">
                        <mat-option value="01">01</mat-option>
                        <mat-option value="02">02</mat-option>
                        <mat-option value="03">03</mat-option>
                        <mat-option value="04">04</mat-option>
                        <mat-option value="05">05</mat-option>
                        <mat-option value="06">06</mat-option>
                        <mat-option value="07">07</mat-option>
                        <mat-option value="08">08</mat-option>
                        <mat-option value="09">09</mat-option>
                        <mat-option value="10">10</mat-option>
                        <mat-option value="11">11</mat-option>
                        <mat-option value="12">12</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-6 p-1">
                    <mat-form-field class="small-width d-inline">
                      <mat-label>{{'CARD_YEAR_EXPIRATION'|translate}}</mat-label>
                      <mat-select [(ngModel)]="newFop.card.expirationYear">
                        <mat-option value="20">20</mat-option>
                        <mat-option value="21">21</mat-option>
                        <mat-option value="22">22</mat-option>
                        <mat-option value="23">23</mat-option>
                        <mat-option value="24">24</mat-option>
                        <mat-option value="25">25</mat-option>
                        <mat-option value="26">26</mat-option>
                        <mat-option value="27">27</mat-option>
                        <mat-option value="28">28</mat-option>
                        <mat-option value="29">29</mat-option>
                        <mat-option value="30">30</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div>
                <mat-form-field class="full-width">
                  <input matInput [(ngModel)]="newFop.card.number" placeholder="{{'CARD_NUMBER'|translate}}" type="number">
                </mat-form-field>
              </div>

              <div *ngIf="newFop.type==='CC'">
                <mat-form-field class="full-width">
                  <input matInput [(ngModel)]="newFop.card.holder" placeholder="{{'CARD_OWNER'|translate}}">
                </mat-form-field>
              </div>
              <div *ngIf="newFop.type==='CC'">
                <mat-form-field class="small-width">
                  <input matInput [(ngModel)]="newFop.card.cvc" placeholder="{{'CARD_CVC'|translate}}">
                </mat-form-field>
              </div>
            </div>

            <div class="col-lg-6">
              <mat-card appearance="outlined" class="pb-4">
                <mat-card-header>
                  <div *ngIf="newFop.type==='CC'" mat-card-avatar class="example-header-image" [style.background]="'url(assets/img/fops/' + newFop.card.type + '.png)'"></div>
                  <div *ngIf="newFop.type!=='CC'" mat-card-avatar class="example-header-image" style="background-image: url('assets/img/fops/TP.png')"></div>
                  <mat-card-title>
                    <span *ngIf="newFop.type==='CC'">Credit card</span>
                    <span *ngIf="newFop.type!=='CC'">(Airplus)</span>
                  </mat-card-title>
                  <mat-card-subtitle class="py-2">
                    <span *ngIf="!newFop.card.number">_ _ _ _  _ _ _ _  _ _ _ _  _ _ _ _</span>
                    <span *ngIf="newFop.card.number">{{newFop.card.number}}</span>
                  </mat-card-subtitle>
                  <mat-card-subtitle class="pb-2">Valid thru {{newFop.card.expirationMonth}}/{{newFop.card.expirationYear}}</mat-card-subtitle>
                </mat-card-header>
              </mat-card>
            </div>
          </div>

        </div>

        <hr/>

        <div class="text-right">
          <button type="button" mat-button (click)="toggleAddModal()">{{'ABORT'|translate}}</button>
          <button type="button" mat-button [disabled]="!validForm()" (click)="addFop()">{{'SAVE'|translate}}</button>
        </div>

      </div>
    </div>
  </div>

  <div class="modal fade" id="deleteFopModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="deleteFopModal" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="top:70px" role="document">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteFopModalTitle">{{'DELETE_PAYMENT'|translate}}</h5>
          <button type="button" class="close" (click)="toggleDeleteModal(undefined)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <p class="mb-3">{{'DELETE_CURRENT_PAYMENT'|translate}}? </p>
          <div class="creditCard" *ngIf="currentFop">
            <div class="face front bg{{currentFop.card.type}}">
              <h3 class="debit">{{currentFop.name}}</h3>
              <h3 class="bank">
            <span *ngIf="currentFop.type === 'CC'">
              {{returnCreditCardName(currentFop.card.type)}}
            </span>
                <span *ngIf="currentFop.type !== 'CC'">
              AirPlus
            </span>
              </h3>
              <img class="chip" src="/assets/images/chip.png" alt="chip">
              <h3 class="number">xxxx xxxx xxxx {{currentFop.card.number}}</h3>
              <h5 class="valid">
                <span>VALID <br /> THRU</span>
                <span>{{currentFop.card.expirationMonth}}/{{currentFop.card.expirationYear}}</span>
              </h5>
              <h5 class="card-holder">{{currentFop.card.holder}}</h5>
            </div>
          </div>
        </div>

        <hr/>

        <div class="text-right">
          <button type="button" mat-button (click)="toggleDeleteModal(undefined)">{{'ABORT'|translate}}</button>
          <button type="button" mat-button (click)="deleteFop(currentFop)">{{'DELETE'|translate}}</button>
        </div>

      </div>
    </div>
  </div>

</div>
