<div mat-dialog-title>
  <div>
    {{data.form.destination.display}}
  </div>
  <div class="smalltext">{{"DAL"|translate}} {{data.form.checkIn|date:'dd/MM/yyyy'}} {{"AL"|translate}} {{data.form.checkOut|date:'dd/MM/yyyy'}}</div>
</div>
<mat-dialog-content>
  <div class="row">
    <div class="col-lg-3">
      <div class="mapList">
        <div id="listHotels">
          <div (click)="openInfo(i)" *ngFor="let hotel of data.hotels; let i = index" class="singleMapEntity mb-3" [id]="'row'+i">
            <div class="itemContainer" [ngClass]="{'highlight': hotel.highlight}">
              <div class="row">
                <div class="col-3">
                  <div class="bgHotel" [ngStyle]="{backgroundImage:'url('+returnImg(hotel)+')'}">
                  </div>
                </div>
                <div class="col-9">
                  <div class="hotelName">{{hotel.name}}</div>
                  <div class="mb-2">
                    <span *ngFor="let star of starCounter(hotel.rating)" class="material-icons ratingIcon">
                      star_rate
                    </span>
                  </div>
                  <!-- <div class="mb-1">{{hotel.hotelCode}}</div> -->
                  <div class="mb-1">{{hotel.address}}</div>
                  <div class="mb-1">{{"DISTANCE_SEARCH_POINT"|translate}}: {{convertDistance(hotel.distanceKm)}}</div>
                  <div *ngIf="hotel.amount" class="mb-2 hotelPrice">
                    {{hotel.amount|number:'.2-2'}}€
                  </div>
                  <div class="text-right" *ngIf="hotel.amount">
                    <span class="showRooms" (click)="quote(hotel.hotelCode)">
                      {{"LOOK_ROOMS"|translate}} &rarr;
                    </span>
                  </div>
                  <div *ngIf="!hotel.amount" class="mb-2 text-danger">{{"NOT_AVAILABLE_FOR_THIS_DATE"|translate}}</div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>


    </div>
    <div class="col-lg-9">
      <div class="" id="mapDialog"></div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>{{"CLOSE"|translate}}</button>
</mat-dialog-actions>
