<button (click)="toggleModal()" [disabled]="isDisabled" id="NEW_TRAVEL_CHANGE" mat-button
        type="button">{{'TRAIN_MODULE.AFTERSALE.NEW_TRAVEL_CHANGE'|translate}}</button>

<div aria-hidden="true" aria-labelledby="changeNewTravelModal" class="modal fade modal-scroll" data-backdrop="static" id="changeNewTravelModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-xl" role="document" style="top:70px">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="changeNewTravelModalTitle">{{'TRAIN_MODULE.AFTERSALE.CHANGE_NEW_TRAVEL_QUESTION'|translate}}</h5>
        <button (click)="toggleModal()" *ngIf="!loading" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <mat-stepper linear #stepper *ngIf="!changeAttempt else successDialog">
          <mat-step [stepControl]="searchFormGroup" [editable]="step === 'search'">
            <ng-template matStepLabel>{{'TRAIN_MODULE.NEW_SEARCH' | translate}}</ng-template>
            <train-search-trains (nextStepEvent)="stepperNextStep($event, searchFormGroup)" [travellers]="getTravellers()" (toggle)="$event"></train-search-trains>
          </mat-step>
          <mat-step [stepControl]="paxFormGroup" [editable]="step === 'passengers'">
            <ng-template matStepLabel>{{'TRAIN_MODULE.PASSENGERS' | translate}}</ng-template>
            <ng-container *ngIf="step === 'passengers'">
              <app-passengers (nextStepEvent)="stepperFinalStep($event, paxFormGroup)" (previousStepEvent)="stepperReset()"></app-passengers>
            </ng-container>
          </mat-step>
          <mat-step [stepControl]="completedFormGroup" [editable]="step === 'completed'">
            <ng-template matStepLabel>{{'CONFIRM' | translate}}</ng-template>
            <ng-container *ngIf="step === 'completed'">

                <div class="row">
                  <h3>{{ 'TRAIN_MODULE.AFTERSALE.NEW_TRAVEL_CHANGE' | translate }}</h3>
                  <train-travel-solution-detail [catalog]="validatedTravelResponse.catalog" [travel]="newTravel"></train-travel-solution-detail>
                </div>
                <mat-divider></mat-divider>
                <div class="row">
                  <app-validated-travel-penalty [validatedTravel]="validatedTravelResponse"></app-validated-travel-penalty>
                </div>
                <div class="text-right">
                  <button (click)="stepperReset()" mat-button type="button">{{'TRAIN_MODULE.ABORT'|translate}}</button>
                  <button (click)="confirmChange()" [disabled]="validatedTravelResponse.validatedPostSaleRecord.allowed === 'false'" mat-button type="button">
                    {{'CONFIRM_SELECTIONS'|translate}}
                    <span *ngIf="(changeReservation$|async)"><mat-icon class="spin">autorenew</mat-icon></span>
                  </button>
                </div>

            </ng-container>

          </mat-step>
        </mat-stepper>
        <ng-template #successDialog>
          <mat-dialog-content>
            <ng-container *ngIf="errorMessage else successMessage">
              <mat-icon>warning</mat-icon>
              {{ errorMessage | translate}}
            </ng-container>
            <ng-template #successMessage>
              {{'TRAIN_MODULE.CHANGE_SUCCESS' | translate}}
            </ng-template>
          </mat-dialog-content>
        </ng-template>
      </div>
    </div>
  </div>
</div>
