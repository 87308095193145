import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FlightService} from '../../../../../../services/flight/flight.service';
import { KeyValue } from '@angular/common';
import * as $ from 'jquery';
import {SearchRes} from '../../../../../../classes/flights/search-res';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TkbottomsheetComponent } from 'src/app/modules/ticketing/components/tkbottomsheet/tkbottomsheet.component';
/**
 * Seatmap component
 */
@Component({
  selector: 'app-seatmap',
  templateUrl: './seatmap.component.html',
  styleUrls: ['./seatmap.component.scss']
})
export class SeatmapComponent implements OnInit {
  deleting: boolean;
  /**
   * Local variable for reservation id passed from parent component
   */
  @Input() reservation: any;
  @Input() reservationId: string;
  /**
   * Local variable for flights passed from parent component
   */
  @Input() flights: any[];
  /**
   * Local variable for tabs ( one tab for any flight)
   */
  tabs = [];
  /**
   * Local variable for current active tab
   */
  currentTab: number;
  /**
   * Local variable for passengers passed from parent component
   */
  @Input() passengers: any[];

  @Input() seatsSelections: any;
  // tslint:disable-next-line:no-output-rename
  @Output('someUpdate') select: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Local variable for loading status
   */
   loading: boolean;
  /**
   * Local variable for seatmap
   */
  seatMap: any;
  /**
   * Local variable for selected passenger
   */
  currentPassenger: any;
  /**
   * Local variable for current flight compartment to show
   */
  currentCompartment: number;
  /**
   * Local variable for current seat
   */
  currentSeat: any;
  /**
   * Local variable selected seats
   */
  selectedSeats: any[];
  constructor(
    /**
     *  Local variable for flightService
     */
    private flightService: FlightService,
    private bottomSheet: MatBottomSheet,
  ) {
    this.selectedSeats = [];
    this.deleting = false;
  }

  ngOnInit(): void {
    this.selectedSeats.length = this.flights.length;
    for (let i = 0; i < this.selectedSeats.length; i++) {
      this.selectedSeats[i] = undefined;
    }
    if (this.seatsSelections) {
      for (let i = 0; i < this.seatsSelections.length; i++) {
        for (let s = 0; s < this.seatsSelections[i].length; s++) {
          this.seatsSelections[i][s].name = this.seatsSelections[i][s].seat;
          if (this.seatsSelections[i][s].name[0] === '0') {
            this.seatsSelections[i][s].name = this.seatsSelections[i][s].name.substring(1);
          }
        }
      }
      this.selectedSeats = JSON.parse(JSON.stringify(this.seatsSelections));
    }
    this.tabs.length = this.flights.length;
    this.currentTab = 0;
    this.currentCompartment = 0;
    this.currentPassenger = this.passengers[0];
    this.getSeatMap(this.reservationId);
  }

  getSeatMap(reservationId) {
    this.loading = true;
    this.flightService.getSeatMap(reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.seatMap = res;
      console.log(this.seatMap , 'SeatMap')
      this.loading = false;
      this.invertSeats();

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  /**
   * Set currentTab
   * @param i number
   */
  setCurrentTab(i) {
    this.currentTab = i;
    this.currentCompartment = 0;
    delete this.currentSeat;
  }

  /**
   * Set current passenger selection
   * @params passenger
   */
  setCurrentPax(passenger) {
    this.currentPassenger = passenger;
  }

  /**
   * Set the service category to display
   * @params value
   */
  setCompartment(value) {
    this.currentCompartment = value;
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  /**
   * Set current seat and select it for current passenger
   * @params seat
   */
  selectSeat(element) {
    const seat = {
      name: element.name,
      ref: this.currentPassenger.ref,
      price: element.price,
      passenger: this.currentPassenger.ref,
    };
    if (this.selectedSeats[this.currentTab] !== undefined) {
      const found = this.selectedSeats[this.currentTab].findIndex(r => r.ref === seat.ref);
      if (found > -1) {
        this.selectedSeats[this.currentTab][found] = seat;
      } else {
        this.selectedSeats[this.currentTab].push(seat);
      }
    } else {
      this.selectedSeats[this.currentTab] = [];
      this.selectedSeats[this.currentTab].push(seat);
    }
    delete this.currentSeat;
    console.log('this.selectedSeats =>', this.selectedSeats);
  }

  /**
   * Remove current seat from selection
   * @params seat
   */
  removeSeat(element) {
    const seat = {
      name: element.name,
      ref: this.currentPassenger.ref,
      price: element.price,
    };
    if (this.selectedSeats[this.currentTab] !== undefined) {
      const found = this.selectedSeats[this.currentTab].findIndex(r => r.name === seat.name);
      if (found > -1) {
        this.selectedSeats[this.currentTab].splice(found, 1);
      }
    }
    delete this.currentSeat;
    console.log('this.selectedSeats =>', this.selectedSeats);
  }

  removeBookedSeat(element) {
    this.deleting = true;
    const body = {
      name: element.name,
      ref: this.currentPassenger.ref,
      flight: this.reservation.retrieve.flights[this.currentTab].ref,
    };
    console.log('seat to remove =>', body);
    console.log('reservationid =>', this.reservationId);
    this.deleting = false;
    this.toggleDeleteModal();
    /*
    this.flightService.removeSeat(body, reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.select.emit();
      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });*/

  }

  toggleDeleteModal() {
    $('#deleteSeat').modal('toggle');
  }

  /**
   * If seat include given code
   * @params element
   * @params value
   */
  include(col, i, element, value) {
    const ind = element.includes(value);
    if (col[i + 1] && col[i + 1].descriptions) {
      const rwInd = col[i + 1].descriptions.includes(value);
      if (rwInd) {
        return 'aAfter';
      } else {
        return 'aBefore';
      }
    }
  }

  /**
   * Return if seat is with aisle
   * @params row
   * @params i
   * @params element
   * @params value
   */
  returnClass(row, i, element, value) {
    const ind = element.findIndex(r => r.id === value);
    if (ind > -1 && row[i + 1]) {
      const rwInd = row[i + 1].descriptions.findIndex(r => r.id === value);
      if (rwInd > -1) {
        return 'aAfter';
      } else {
        return 'aBefore';
      }
    }
  }

  /**
   * Set current seat
   * @params seat
   */
  currentSeatToView(seat) {
    if (this.currentSeat && this.currentSeat.name === seat.name) {
      delete this.currentSeat;
    } else {
      this.currentSeat = seat;
    }
  }

  /**
   * CHeck seat map and change color to seat === to current seat
   * @params seat
   */
  isCurrentSeat(seat) {
    if (this.currentSeat) {
      if (seat.name === this.currentSeat.name) {
        return true;
      }
    }
  }

  someSelected(seat) {
    if (this.selectedSeats[this.currentTab] !== undefined) {
      const found = this.selectedSeats[this.currentTab].findIndex(r => r.name === seat.name);
      if (found > -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  someSeatForPax(passenger) {

    if (this.selectedSeats[this.currentTab] !== undefined) {
      const found = this.selectedSeats[this.currentTab].findIndex(r => r.passenger === passenger.ref);
      if (found > -1) {
        return this.selectedSeats[this.currentTab][found].name + ' ' + this.selectedSeats[this.currentTab][found].price + '€';
      }
    }
  }

  saveSeatMap(reservationId) {
    const seats = this.selectedSeats;
    for (let pax = 0; pax < this.passengers.length; pax++) {
      for (let s = 0; s < seats.length; s++) {
        for (let p = 0; p < seats[s].length; p++) {
          if (seats[s][p]._id) {
            seats[s].splice(p, 1);
          }
        }
      }
    }
    this.loading = true;
    const body = {
      seats,
    };
    this.flightService.saveSeatMap(body, reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.loading = false;
      this.select.emit();
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  alreadyBuy() {
    const found = this.seatsSelections[this.currentTab].findIndex(r => r.passenger === this.currentPassenger.ref);
    return found > -1;
  }

  returnInvertedArray(array){
    return array.reverse()
  }

  invertSeats(){
    this.seatMap.forEach(i => {
      if(i && i.compartments){
        i.compartments.forEach(c => {
          c.colums = c.columns.reverse();
          for (const key of Object.keys(c.seats)) {
            c.seats[key].rows = c.seats[key].rows.reverse();
          }
        })
      }

    })
  }

  openBottomSheetEmission() {
    this.bottomSheet.open(TkbottomsheetComponent, {
      data: {
        seatMap: true,
        emission: false,
        discount: false,
        blocked: false,
        locator:  this.reservation.locator,
        text: 'ASK_SEATMAP_SERVICE',
      },
      // disableClose: true,
    });
  }
}
