import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import * as $ from 'jquery';
import { ChangesData } from '../airport-pnr/ChangesData';
import {FlightService} from '../../../../../../../services/flight/flight.service';
import {ActivatedRoute, Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-changes-form',
  templateUrl: './changes-form.component.html',
  styleUrls: ['./changes-form.component.scss']
})
export class ChangesFormComponent implements OnInit {
  today:any;
  z = 0;
  loading = false;
  @Input() reservation: any;
  @Input() changesRequired: ChangesData;
  @Input() changesLowCost: any;
  dateDisabledOut:boolean;
  dateDisabledRet:boolean;
  checkRoutingChange: any;
  selections: {};
  searchResults: any;
  constructor(
    private flightService: FlightService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar : MatSnackBar) {

  }

  ngOnInit(): void {
    this.reservation.changesRequest = [];
    for(let i=0; i<this.reservation.itineraries.length; i++){
      this.reservation.itineraries[i].flights.newSelections = {
        from: {
          from:[this.reservation.itineraries[i].flights[0].departure.location],
          fromLegend: [this.reservation.itineraries[i].flights[0].departure.location]
        },
        to: {
          to: [this.reservation.itineraries[i].flights[this.reservation.itineraries[i].flights.length-1].arrival.location],
          toLegend: [this.reservation.itineraries[i].flights[this.reservation.itineraries[i].flights.length-1].arrival.location],
        },
        date:this.reservation.itineraries[i].flights[0].departure.dateTime
      };
      this.reservation.changesRequest.push(this.reservation.itineraries[i].flights);
    }
    this.checkDisable();
  }


  checkDisable(){
    for(const c of this.changesLowCost.result){
      if(c.label === 'OUT_DATE' && !c.selected) this.dateDisabledOut = true;
      if(c.label === 'RET_DATE' && !c.selected) this.dateDisabledRet = true;
      if(c.label === 'DATES' && !c.selected) this.dateDisabledRet = this.dateDisabledOut = true;
    }
  }

  isDatedisable(index){
    if(index === 0) return this.dateDisabledOut;
    if(index === 1) return this.dateDisabledRet;
  }

  getMinDate(index) {
      return new Date();
  }
  setNextDate(index) {
    if (index < this.reservation.changesRequest.length) {
      if(this.reservation.changesRequest[index].newSelections)
        this.reservation.changesRequest[index].newSelections.date = new Date(this.reservation.changesRequest[index].newSelections.date.setHours(6,0));
    }
  }
  removeSection(index) {

  }
  isValid(){


    /*this.flightService.startChangeBooking(this.route.snapshot.paramMap.get('id'), {...this.reservation.itineraries}).subscribe(data => {
     this.changesLowCost = data;
     })

     */
  }
  clearSearch(){

  }


  toggleCollapse(index) {
    $('#collapseF' + index).collapse('toggle');
  }
  startSearch(){
    let first, second;
    // aggiungere filtri in base a cosa hai chiesto di cambiare e cosa hai effettivamente cambiato, se mismatch avvisa e blocca prosecuzione
    const infoToSend =[];
    const changed = false;
    this.loading = true;
    // fare un controllo che se itineraries è lungo 2 e la prima data è più grande di quella nuova selezionata(newSeelctions)oppure maggiore di quella che aveva prima
    // allora deve visualizzare un errore ed impedire di fare la chiamata
    if(this.reservation.itineraries.length === 2){
      // verifica allineamento delle date
      if(!(this.reservation.itineraries[0].flights.newSelections.date instanceof Date)){
        first = new Date(this.reservation.itineraries[0].flights.newSelections.date).getTime();
      } else {
        first = this.reservation.itineraries[0].flights.newSelections.date.getTime();
      }
      if(!(this.reservation.itineraries[1].flights.newSelections.date instanceof Date)){
        second = new Date(this.reservation.itineraries[1].flights.newSelections.date).getTime();
      } else {
        second = this.reservation.itineraries[1].flights.newSelections.date.getTime();
      }
      if(second < first) {
        this.loading = false;
        console.log('la data di partenza è dopo la data di arrivo');
        this.snackBar.open('la data di partenza è dopo la data di arrivo', 'X', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        return;
      }
    }
    for (let i=0; i<this.reservation.itineraries.length; i++){
      infoToSend.push({
        id: this.route.snapshot.paramMap.get('id'),
        data: []
      })
      this.reservation.itineraries[i].flights.newSelections.changesLowCost = this.changesLowCost;
      infoToSend[i].data.push(this.reservation.itineraries[i].flights.newSelections);
    }
    this.checkRoutingChange = false;
    // infoToSend = this.removeFilteredValues(infoToSend) // reimposta i valori per date e aereoporti che erano stati filtrati
    console.log('invio questo ' + JSON.stringify(infoToSend));
    this.flightService.startChangeBooking(infoToSend).subscribe(data => {
       console.log(JSON.stringify(data));
       this.checkRoutingChange = true;
       this.searchResults = data;
       this.loading = false;
     }, error => {
        console.log(error);
        this.loading = false;
    })
  }

  removeFilteredValues(info){
    const infoToSend = JSON.parse(JSON.stringify(info));
    let toCheck;
    let tempData = [];
    for(let j=0; j< infoToSend.length; j++) {
      tempData = [];
      toCheck = infoToSend[j].data[0].changesLowCost.result;
      for(let i=0; i< toCheck.length; i++){
        if( toCheck[i].label === 'DATES'){
          tempData.push({ label: 'OUT_DATE', selected: toCheck[i].selected })
          // tslint:disable-next-line:max-line-length
          infoToSend.length === 1 ? tempData.push({ label: 'RET_DATE', selected: false }) : tempData.push({ label: 'RET_DATE', selected: toCheck[i].selected });
        }
        if( toCheck[i].label === 'LOCATIONS'){
          tempData.push({label: 'ORIGIN', selected: toCheck[i].selected })
          tempData.push({label: 'DESTINATION', selected: toCheck[i].selected })
        }
      }
      infoToSend[j].data[0].changesLowCost.result = tempData;
    }
    return infoToSend;
  }

}
