<div class="dialog px-2">
  <h2 mat-dialog-title>
    {{data.data.title}}
  </h2>
  <mat-dialog-content>
    <div class="dialog__body">
      <form [formGroup]="createPolicyForm" class="dialog__form">
        <div class="dialog__form-group">
          <mat-form-field class="full-width">
            <mat-label>Policy name</mat-label>
            <input (ngModelChange)="checkIfNameExist()"  formControlName="policyName" matInput placeholder="Ex. Manager" value="">
            <mat-error *ngIf="policyName.hasError('required')">
              Policy name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="policyName.hasError('nameAlreadyExist')">
              This name already exist
            </mat-error>
          </mat-form-field>
        </div>
        <div class="dialog__form-group">
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Select group</mat-label>
            <mat-select (selectionChange)="checkIfRoleExist()" formControlName="policyRole">
              <mat-option *ngFor="let role of data.data.rolesList" [value]="role.name">{{role.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="policyRole.hasError('required')">
              Policy role is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="policyRole.hasError('roleAlreadyExist')">
              This role already exists!
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <div class="row" formGroupName="flights">
            <div class="col-12">Flights</div>
            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Value</mat-label>
                <input formControlName="value" type="number" matInput>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type">
                  <mat-option value="percentage">%</mat-option>
                  <mat-option value="euro">Euro</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div>
          <div class="row" formGroupName="hotels">
            <div class="col-12">Hotels</div>
            <div class="col-6">
              <div>
                <mat-form-field class="full-width" appearance="fill">
                  <mat-label>Value</mat-label>
                  <input formControlName="value" type="number" matInput>
                </mat-form-field>
              </div>
            </div>
            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type">
                  <mat-option value="percentage">%</mat-option>
                  <mat-option value="euro">Euro</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div>
          <div class="row" formGroupName="vehicles">
            <div class="col-12">Cars</div>
            <div class="col-6">
              <div>
                <mat-form-field class="full-width" appearance="fill">
                  <mat-label>Value</mat-label>
                  <input formControlName="value" type="number" matInput>
                </mat-form-field>
              </div>
            </div>

            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type">
                  <mat-option value="percentage">%</mat-option>
                  <mat-option value="euro">Euro</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div>
          <div class="row" formGroupName="trains">
            <div class="col-12">Trains</div>
            <div class="col-6">
              <div>
                <mat-form-field class="full-width" appearance="fill">
                  <mat-label>Value</mat-label>
                  <input formControlName="value" type="number" matInput>
                </mat-form-field>
              </div>
            </div>

            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type">
                  <mat-option value="percentage">%</mat-option>
                  <mat-option value="euro">Euro</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div>
          <div class="row" formGroupName="transfers">
            <div class="col-12">
              Transfers
            </div>
            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Value</mat-label>
                <input formControlName="value" type="number" matInput>
              </mat-form-field>
            </div>

            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type">
                  <mat-option value="percentage">%</mat-option>
                  <mat-option value="euro">Euro</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="buttonrow">
      <button mat-raised-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button [disabled]="createPolicyForm.invalid" [mat-dialog-close]="createPolicyForm.controls" color="primary" autofocus>
        Ok
      </button>
    </div>
  </mat-dialog-actions>
</div>

