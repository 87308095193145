import { Injectable } from '@angular/core';
import {HttpserviceService} from '../httpservice.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root'
})
export class StatisticService extends HttpserviceService {
  statsUrl: string;
  pnrUrl: string;
  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
    this.statsUrl = this.apiUrl + 'analytics/segments/';
    this.pnrUrl = this.apiUrl + 'analytics/pnrs/';
  }

  getYear(data): Observable<any> {
    const getYear = this.statsUrl + 'yearMonth?options={"service":' + '"' + data.service.toLowerCase() + '"' + '}';
    return this.http
      .get<any>(getYear, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getAllYear(): Observable<any> {
    const getAllYear = this.statsUrl + 'yearMonth';
    return this.http
      .get<any>(getAllYear, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getGeneral(data): Observable<any> {
    const getGeneral = this.statsUrl + 'general';
    return this.http
      .get<any>(getGeneral, this.setHttpOptionsWithParams(data))
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getPnrs(data): Observable<any> {
    const getGeneral = this.pnrUrl + 'general';
    return this.http
      .get<any>(getGeneral, this.setHttpOptionsWithParams(data))
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getCompanies(data): Observable<any> {
    const getGeneral = this.statsUrl + 'perCompany';
    return this.http
      .get<any>(getGeneral, this.setHttpOptionsWithParams(data))
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getRss(): Observable<any> {
    const getRss = this.oldApiUrl + 'misc/rss/gov';
    return this.http
      .get<any>(getRss, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getExport(options:any, fileName:any): Observable<any> {
    const getStats = this.apiUrl + 'analytics/segments/perCompany/export?options=' + options;
    return this.http
      .get<any>(getStats, this.setHttpOptionsDownload(fileName))
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  // http://localhost:3000/api/v2/analytics/segments/perCompany/export?options={"year":"2023","service":"flight"}
}
