<div class="topbar">
  <div [ngClass]="{'topMenuBar':mainComponent.userSettings.menuTop}">
    <div class="row">
      <div class="col-5 col-md-8 d-flex align-content-center">
        <div class="btn50top" *ngIf="!mainComponent.userSettings.menuTop">
          <button type="button" mat-icon-button (click)="mainComponent.toggleSidenav()" class="iconButtons my-1">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div class="btn50top d-xs-inline-block d-sm-inline-block d-md-none" *ngIf="mainComponent.userSettings.menuTop">
          <button class="iconButtons" type="button" mat-icon-button (click)="mainComponent.toggleTopmenu()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <span class="d-inline-block ml-2">
          <span class="logoImg" *ngIf="appComponent.isSbt">
              <img style="height:40px;" src="assets/img/suite.png"/>
          </span>
          <span class="logoImg" *ngIf="appComponent.isGat">
              <img style="height:40px;" src="assets/images/gattinonilogo1.png"/>
          </span>
          <span class="logoImg" *ngIf="appComponent.isB2B && !appComponent.isSbt">
              <!-- <img style="height:40px;" src="assets/img/B2BTravel2.png"/> -->
              <img style="height:40px;" src="assets/img/b2blogo.png"/>
            </span>
            <span class="logoImg" *ngIf="!appComponent.isSbt && !appComponent.isB2B && !appComponent.isGat">
              <img style="height:40px;" src="assets/img/themes/Logo-Flyleo-blue.png"/>
            </span>
          <!-- <span *ngIf="!mainComponent.userSettings.menuTop"> - {{appComponent.title|translate}}</span> -->
        </span>
        <div class="menuTop d-inline-block d-md-flex align-self-md-center" *ngIf="mainComponent.userSettings.menuTop">
          <button *ngIf="customAuth.returnAuth(mainComponent.loggedUser._id)" routerLink="/dashboard" mat-icon-button class="iconButtons" color="primary">
            <mat-icon>home</mat-icon>
          </button>

          <button *ngIf="mainComponent.loggedUser.role.superAdmin || (mainComponent.company.cau && mainComponent.loggedUser.role.company)"  mat-button [matMenuTriggerFor]="admin">
            {{'ADMIN_AREA'|translate}} <span class="material-icons">expand_more</span>
          </button>
          <mat-menu #admin="matMenu">
            <a *ngIf="mainComponent.loggedUser.role.superAdmin && !appComponent.isSbt" mat-menu-item routerLinkActive="active-link" routerLink="/loginAs">{{'LOGIN_AS'|translate}}</a>
            <a *ngIf="appComponent.isSbt && !appComponent.isGat" mat-menu-item routerLinkActive="active-link" routerLink="/users/list">{{'LOGIN_AS'|translate}}</a>
            <a *ngIf="mainComponent.loggedUser.role.superAdmin && !appComponent.isSbt && !appComponent.isGat" mat-menu-item routerLinkActive="active-link" routerLink="/adminArea/flightCryptic">{{'FLIGHT_CRYPTIC'|translate}}</a>
            <a *ngIf="mainComponent.loggedUser.role.superAdmin && !appComponent.isSbt && !appComponent.isGat"mat-menu-item routerLinkActive="active-link" routerLink="/adminArea/selling-platform">{{'SELLING_PLATFORM'|translate}}</a>
            <a *ngIf="mainComponent.loggedUser.role.superAdmin" mat-menu-item routerLinkActive="active-link" routerLink="/adminArea/addProfile">{{'ADD_PROFILE_COMPANY'|translate}}</a>
            <a *ngIf="mainComponent.loggedUser.role.superAdmin" mat-menu-item routerLinkActive="active-link" routerLink="/adminArea/manage-iata">{{'MANAGE_IATA'|translate}}</a>
            <a *ngIf="mainComponent.loggedUser.role.superAdmin" mat-menu-item routerLinkActive="active-link" routerLink="/adminArea/manage-blocked-flight-rates">{{'MANAGE_ISSUE_FARE_FLIGHT'|translate}}</a>
            <a mat-menu-item routerLinkActive="active-link" routerLink="/adminArea/import-pnr"> {{'IMPORT_PNR'|translate}}</a>
          </mat-menu>

          <button *ngIf="!appComponent.isGat" mat-button [matMenuTriggerFor]="travel">
            {{'TRAVEL_SERVICES'|translate}} <span class="material-icons">expand_more</span>
          </button>
          <mat-menu #travel="matMenu">
            <!-- <a *ngIf="customAuth.returnAuth(mainComponent.loggedUser._id)"  mat-menu-item routerLinkActive="active-link" routerLink="/dossier/list">{{'DOSSIER_LIST'|translate}}</a> -->
            <button *ngIf="mainComponent.loggedUser.role.superAdmin || (mainComponent.loggedUser.company.services.dossier && (appComponent.isSbt || appComponent.isB2B))"  mat-menu-item [matMenuTriggerFor]="dossiers">{{'TRAVEL_FOLDER'|translate}}</button>
            <button *ngIf="mainComponent.loggedUser.company.services.flight"  mat-menu-item [matMenuTriggerFor]="flight">{{'FLIGHT'|translate}}</button>
            <button *ngIf="mainComponent.loggedUser.company.services.hotel"  mat-menu-item [matMenuTriggerFor]="hotel">{{'HOTEL'|translate}}</button>
            <button *ngIf="mainComponent.loggedUser.company.services.train" mat-menu-item [matMenuTriggerFor]="train">{{'TRAIN'|translate}}</button>
            <button *ngIf="mainComponent.loggedUser.company.services.car" mat-menu-item [matMenuTriggerFor]="car">{{'CAR_RENT'|translate}}</button>
          </mat-menu>

          <button *ngIf="appComponent.isGat" mat-button [matMenuTriggerFor]="flight">{{'FLIGHT'|translate}}
            <span class="material-icons">expand_more</span>
          </button>
          <mat-menu #dossiers="matMenu">
            <a mat-menu-item style="cursor:pointer;" (click)="mainComponent.addDossierToggle()" routerLinkActive="active-link">{{'NEW_FOLDER'|translate}}</a>
            <a mat-menu-item routerLinkActive="active-link" routerLink="/dossier/list">{{'FOLDER_LIST'|translate}}</a>
          </mat-menu>

          <mat-menu #flight="matMenu">
            <a mat-menu-item routerLinkActive="active-link" routerLink="/flight/search">{{'SEARCH_FLIGHT'|translate}}</a>
            <a mat-menu-item routerLinkActive="active-link" routerLink="/flight/history">{{'HISTORY'|translate}}</a>
            <a mat-menu-item routerLinkActive="active-link" routerLink="/flight/reservations">{{'PNR_LIST'|translate}}</a>
          </mat-menu>

          <mat-menu #hotel="matMenu">
            <a mat-menu-item routerLinkActive="active-link" routerLink="/hotel/search">{{'HOTEL_SEARCH'|translate}}</a>
            <a mat-menu-item routerLinkActive="active-link" routerLink="/hotel/reservations">{{'HOTEL_RESERVATION_LIST'|translate}}</a>
          </mat-menu>

          <mat-menu #train="matMenu">
            <a mat-menu-item routerLinkActive="active-link" routerLink="/trains/search">{{'TRAIN_SEARCH'|translate}}</a>
            <a mat-menu-item routerLinkActive="active-link" routerLink="/trains/reservations">{{'TRAIN_RESERVATION_LIST'|translate}}</a>
          </mat-menu>

          <mat-menu #car="matMenu">
            <a mat-menu-item routerLinkActive="active-link" routerLink="/car-rent/search">{{'CAR_RENT_SEARCH'|translate}}</a>
            <a mat-menu-item routerLinkActive="active-link" routerLink="/car-rent/reservations">{{'CAR_RESERVATION_LIST'|translate}}</a>
          </mat-menu>

          <button *ngIf="(mainComponent.loggedUser.role.superAdmin || (appComponent.isB2B || appComponent.isSbt)) && !appComponent.isGat"  mat-button [matMenuTriggerFor]="modrequest">
            {{'MOD_REQUESTS'|translate}} <span class="material-icons">expand_more</span>
          </button>
          <mat-menu #modrequest="matMenu">
            <a *ngIf="!appComponent.isGat" mat-menu-item routerLinkActive="active-link" routerLink="/offline-requests/open-request">{{'NEW_TRAVEL_REQUEST'|translate}}</a>
            <a *ngIf="!appComponent.isGat" mat-menu-item routerLinkActive="active-link" routerLink="/offline-requests/request-list">{{'TRAVEL_REQUESTS_LIST'|translate}}</a>
            <a  *ngIf="appComponent.isGat || appComponent.isSbt" mat-menu-item routerLinkActive="active-link" routerLink="/ticketing/list">{{'TICKETS_LIST'|translate}}</a>
            <a  *ngIf="(!appComponent.isGat && !appComponent.isSbt) && !mainComponent.loggedUser.role.isTktOperator" mat-menu-item routerLinkActive="active-link" routerLink="/ticketing/list">{{'TICKETS_LIST'|translate}}</a>
            <a *ngIf="(!appComponent.isGat && !appComponent.isSbt) && mainComponent.loggedUser.role.isTktOperator" mat-menu-item href="https://leonardotravel.atlassian.net/jira/servicedesk/projects/BIG" target="_blank">{{'TICKETS_LIST'|translate}}</a>
          </mat-menu>

          <button *ngIf="customAuth.returnAuth(mainComponent.loggedUser._id)"  mat-button [matMenuTriggerFor]="registry">
            {{'REGISTRY'|translate}} <span class="material-icons">expand_more</span>
          </button>
          <mat-menu #registry="matMenu">
            <a *ngIf="mainComponent.loggedUser.role.profile.user.get.company" mat-menu-item routerLinkActive="active-link" routerLink="/users/list">{{'USERS_LIST'|translate}}</a>
            <a *ngIf="mainComponent.loggedUser.role.superAdmin" mat-menu-item routerLinkActive="active-link" routerLink="/companies/list">{{'COMPANIES_LIST'|translate}}</a>
            <a mat-menu-item routerLinkActive="active-link" routerLink="/travellers/list">{{'TRAVELLERS_BOOK'|translate}}</a>
            <a *ngIf="((appComponent.isB2B || appComponent.isSbt) &&  mainComponent.loggedUser.company.services.siap) || mainComponent.loggedUser.role.superAdmin && !appComponent.isGat"
               mat-menu-item routerLinkActive="active-link" routerLink="/costcenter">
              {{'COST_CENTER'|translate}}
            </a>

            <!-- <a *ngIf="(mainComponent.loggedUser.role.superAdmin || (mainComponent.loggedUser.company.services.policy && mainComponent.loggedUser.role.company && (this.appComponent.isSbt || this.appComponent.isB2B))) && !appComponent.isGat" mat-menu-item routerLinkActive="active-link" routerLink="/usergroups">{{'USERS_GROUPS'|translate}}</a> -->
          </mat-menu>

          <button *ngIf="mainComponent.loggedUser.company.services.policy && mainComponent.loggedUser.role.company && (this.appComponent.isSbt || this.appComponent.isB2B)"  mat-button [matMenuTriggerFor]="tpolicy">
            {{'TRAVEL_POLICIES'|translate}} <span class="material-icons">expand_more</span>
          </button>
          <mat-menu #tpolicy="matMenu">
            <a  *ngIf="(mainComponent.loggedUser.role.superAdmin || (mainComponent.loggedUser.company.services.policy && mainComponent.loggedUser.role.company && (this.appComponent.isSbt || this.appComponent.isB2B))) && !appComponent.isGat" mat-menu-item routerLinkActive="active-link" routerLink="/allgroups">{{'TRAVEL_POLICIES_GROUPS'|translate}}</a>
            <a *ngIf="(mainComponent.loggedUser.role.superAdmin || (mainComponent.loggedUser.company.services.policy && mainComponent.loggedUser.role.company && (this.appComponent.isSbt || this.appComponent.isB2B))) && !appComponent.isGat" mat-menu-item routerLinkActive="active-link" routerLink="/policies">{{'TRAVEL_POLICIES_ROLES'|translate}}</a>
            <a *ngIf="(mainComponent.loggedUser.role.company && mainComponent.loggedUser.company.services.policy && (this.appComponent.isSbt || this.appComponent.isB2B)) && !appComponent.isGat" mat-menu-item routerLinkActive="active-link" routerLink="/usergroupsbulk">
              {{'USERS_GROUPS'|translate}}
            </a>
            <a *ngIf="(mainComponent.loggedUser.company.services.policy && (this.appComponent.isSbt || this.appComponent.isB2B)) && !appComponent.isGat" mat-menu-item routerLinkActive="active-link" routerLink="/policy-requests">{{'RECEIVED_REQUESTS'|translate}}</a>
            <a *ngIf="(mainComponent.loggedUser.company.services.policy && (this.appComponent.isSbt || this.appComponent.isB2B)) && !appComponent.isGat" mat-menu-item routerLinkActive="active-link" routerLink="/policy-searches">{{'SEARCH_REQUESTS'|translate}}</a>

          </mat-menu>

          <button *ngIf="mainComponent.loggedUser.role.superAdmin || (mainComponent.loggedUser.company.type === 'A' && mainComponent.loggedUser.role.company)"  mat-button [matMenuTriggerFor]="stats">
            {{'STATISTIC'|translate}} <span class="material-icons">expand_more</span>
          </button>
          <mat-menu #stats="matMenu">
            <a mat-menu-item  routerLinkActive="active-link" routerLink="/statistic/general">{{'GENERAL_STATS'|translate}}</a>
            <a mat-menu-item  routerLinkActive="active-link" routerLink="/statistic/year">{{'YEAR_STATS'|translate}}</a>
            <a mat-menu-item *ngIf="mainComponent.loggedUser.role.superAdmin" routerLinkActive="active-link" routerLink="/statistic/companies-stats">{{'COMPANIES_STATS'|translate}}</a>
          </mat-menu>
        </div>
        <div class="menuTopBackDrop" (click)="mainComponent.toggleTopmenu()"></div>
      </div>
      <div class="col-7 col-md-4 d-flex align-self-center justify-content-end">



        <button class="iconButtons my-1" *ngIf="mainComponent.userSettings.menuTop" mat-icon-button [matMenuTriggerFor]="langmenu" aria-label="Example icon-button with a menu">
          <img *ngIf="appComponent.currentLang==='it'" class="" src="assets/img/flags/italian_flag.png"/>
          <img *ngIf="appComponent.currentLang==='en'" class="" src="assets/img/flags/english_flag.png"/>
        </button>
        <mat-menu #langmenu="matMenu">
          <button [ngClass]="{'active':appComponent.currentLang=='en'}" (click)="appComponent.useLanguage('en')" mat-menu-item>
            <span>English</span>
          </button>
          <button [ngClass]="{'active':appComponent.currentLang=='fr'}" (click)="appComponent.useLanguage('fr')" mat-menu-item>
            <span>Francais</span>
          </button>
          <button [ngClass]="{'active':appComponent.currentLang=='it'}" (click)="appComponent.useLanguage('it')" mat-menu-item>
            <span>Italian</span>
          </button>
        </mat-menu>

        <button class="iconButtons my-1" *ngIf="mainComponent.prevUser" type="button" matTooltip="Origina User" mat-icon-button [matMenuTriggerFor]="oldProfile" color="primary">
          <mat-icon>admin_panel_settings</mat-icon>
        </button>
        <mat-menu #oldProfile="matMenu">
          <button (click)="mainComponent.backToProfile()" mat-menu-item>
            <span>Back to profile</span>
          </button>
        </mat-menu>

        <button style="position: relative" class="iconButtons my-1" *ngIf="mainComponent.userSettings.useMarkup || mainComponent.userSettings.useMarkupHotel || mainComponent.userSettings.hidenego || mainComponent.userSettings.hideHotelnego" type="button" matTooltip="Markup" mat-icon-button [matMenuTriggerFor]="currentSettings" color="warn">
          <mat-icon class="toAnimateIcon">euro</mat-icon>
        </button>
        <mat-menu #currentSettings="matMenu">
          <div mat-menu-item *ngIf="mainComponent.userSettings.useMarkup && mainComponent.loggedUser.company.services.flight" (click)="$event.stopPropagation()">
            <mat-checkbox class="mr-2" (ngModelChange)="removeMarkupFl()" [(ngModel)]="mainComponent.userSettings.useMarkup"></mat-checkbox>
            {{'FLIGHT'|translate}}:
            +
            <span *ngIf="mainComponent.userSettings.markup.type == 'sum'">{{mainComponent.userSettings.markup.value|number:'.2-2'}}€</span>
            <br/>
            <span *ngIf="mainComponent.userSettings.markup.type !== 'sum'">{{mainComponent.userSettings.markup.value|number}}%</span>
          </div>
          <div mat-menu-item *ngIf="mainComponent.userSettings.hidenego && mainComponent.loggedUser.company.services.flight" (click)="$event.stopPropagation()">
            <mat-checkbox class="mr-2" (ngModelChange)="removeHideNegoFl()" [(ngModel)]="mainComponent.userSettings.hidenego">
              {{'HIDE_NEGO'|translate}} {{'FLIGHT'|translate}}
            </mat-checkbox>
          </div>
          <mat-divider *ngIf="mainComponent.loggedUser.company.services.hotel && (mainComponent.userSettings.useMarkupHotel || mainComponent.userSettings.hideHotelnego)"></mat-divider>
          <div mat-menu-item *ngIf="mainComponent.userSettings.useMarkupHotel && mainComponent.loggedUser.company.services.hotel" (click)="$event.stopPropagation()">
            <mat-checkbox class="mr-2" (ngModelChange)="removeMarkupHt()" [(ngModel)]="mainComponent.userSettings.useMarkupHotel"></mat-checkbox>
            {{'HOTEL'|translate}}:
            +
            <span *ngIf="mainComponent.userSettings.markupHotel.type == 'sum'">
              {{mainComponent.userSettings.markupHotel.value|number:'.2-2'}}€
            </span>
            <span *ngIf="mainComponent.userSettings.markupHotel.type !== 'sum'">{{mainComponent.userSettings.markupHotel.value|number}}%</span>
          </div>
          <div mat-menu-item *ngIf="mainComponent.userSettings.hideHotelnego && mainComponent.loggedUser.company.services.hotel" (click)="$event.stopPropagation()">
            <mat-checkbox class="mr-2" (ngModelChange)="removeHideNegoHT()" [(ngModel)]="mainComponent.userSettings.hideHotelnego">
              {{'HIDE_NEGO'|translate}} {{'HOTEL'|translate}}
            </mat-checkbox>
          </div>
        </mat-menu>

        <button class="iconButtons my-1" style="position: relative" (click)="toggleAll()" *ngIf="allActive && !appComponent.isSbt && !appComponent.isB2B" type="button" matTooltip="Markup attivo" mat-icon-button>
          <mat-icon>north</mat-icon>
        </button>

        <button class="iconButtons my-1" style="position: relative" *ngIf="!allActive && !appComponent.isSbt && !appComponent.isB2B" (click)="toggleAll()" type="button" matTooltip="Markup non attivo" mat-icon-button>
          <mat-icon>east</mat-icon>
        </button>

        <button class="iconButtons my-1" *ngIf="(customAuth.returnAuth(mainComponent.loggedUser._id) && !mainComponent.appcomponent.isGat) && mainComponent.unreadManual==0"  type="button" matTooltip="Communications" mat-icon-button (click)="mainComponent.toggleManualNotifications()">
          <mat-icon *ngIf="mainComponent.unreadManual==0">chat_bubble_outline</mat-icon>
        </button>

        <button class="iconButtons topbarbadges my-1" *ngIf="(customAuth.returnAuth(mainComponent.loggedUser._id) && !mainComponent.appcomponent.isGat) && mainComponent.unreadManual>0"  type="button"
                matTooltip="Communications" mat-icon-button (click)="mainComponent.toggleManualNotifications()"
                matBadge="{{mainComponent.unreadManual}}" matBadgeColor="primary">
          <mat-icon *ngIf="mainComponent.unreadManual>0" >textsms</mat-icon>
        </button>

        <button *ngIf="customAuth.returnAuth(mainComponent.loggedUser._id) && mainComponent.unreadAuto>0" matBadge="{{mainComponent.unreadAuto}}"
                matBadgeColor="warn" class="iconButtons topbarbadges my-1"  type="button" matTooltip="Notifications" mat-icon-button
                (click)="mainComponent.toggleNotifications()">
          <mat-icon *ngIf="mainComponent.unreadAuto>0" >notification_important</mat-icon>
        </button>

        <button *ngIf="customAuth.returnAuth(mainComponent.loggedUser._id) && mainComponent.unreadAuto === 0"
                class="iconButtons topbarbadges my-1"  type="button" matTooltip="Notifications"
                mat-icon-button (click)="mainComponent.toggleNotifications()">
          <mat-icon *ngIf="mainComponent.unreadAuto === 0">notifications_none</mat-icon>
        </button>



        <button *ngIf="appComponent.isB2B || appComponent.isSbt" class="iconButtons my-1"  type="button" matTooltip="Travel policies notifications" mat-icon-button (click)="notificationList()"
                matBadge="{{badge | async}}" matBadgePosition="after" matBadgeColor="accent">
          <mat-icon class="booked-hotels__notification">campaign</mat-icon>
        </button>


        <button class="iconButtons my-1" *ngIf="customAuth.returnAuth(mainComponent.loggedUser._id)"  type="button" matTooltip="Settings" mat-icon-button (click)="mainComponent.toggleSettings()">
          <mat-icon>tune</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="profiletop" color="primary" aria-label="Profile menu" class="text-uppercase iconButtons mt-1 mr-2" style="font-size: 14px!important;padding: 0!important;line-height:14px" >
          {{mainComponent.loggedUser.name|slice:0:1}}. {{mainComponent.loggedUser.surname|slice:0:1}}.
        </button >
        <mat-menu #profiletop="matMenu" class="profileMenu">
          <button *ngIf="customAuth.returnAuth(mainComponent.loggedUser._id)" (click)="goto('users/list/' + mainComponent.loggedUser._id)" mat-menu-item>
            <span>{{'PROFILE'|translate}}</span>
          </button>
          <!--
          <button *ngIf="customAuth.returnAuth(mainComponent.loggedUser._id)" (click)="goto('users/list/' + mainComponent.loggedUser._id + '/change-password')" mat-menu-item>
            <span>{{'PASSWORD_CHANGE'|translate}}</span>
          </button>
          -->
          <button [hidden]="mainComponent.loggedUser.role.company === false && !customAuth.canBook(mainComponent.loggedUser.company._id)" *ngIf="customAuth.returnAuth(mainComponent.loggedUser._id) && customAuth.returnAuthTf(mainComponent.loggedUser._id)" (click)="goto('companies/list/' + mainComponent.loggedUser.company._id)" mat-menu-item>
            <span>{{mainComponent.loggedUser.company.name}}</span>
          </button>
          <button (click)="logOut()" mat-menu-item>
            <span>Log out</span>
          </button>
          <div mat-menu-item style="border-top:1px solid #eee">
            <div class="text-right" style="">{{appComponent.appName}}: {{app.version}}</div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>

</div>

<div class="versionControl" [ngClass]="{'showVersion': !upToDate}">
  <div class="mb-2">E' stato rilasciato un nuovo aggiornamento, perfavore aggiorna l'applicazione</div>
  <div class="mb-2">{{app.version}} --> {{version}}</div>
  <button mat-stroked-button (click)="reload()">
    Aggiorna
    <span class="ml-2 material-icons">
    upgrade
    </span>
  </button>
</div>

<div class="modal fade" id="VersionModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        E' stato rilasciato un nuovo aggiornamento, perfavore aggiorna l'applicazione
      </div>
      <div class="modal-footer">
        <button mat-button (click)="reload()">Aggiorna</button>
        <button mat-button color="warn" (click)="closeVersionModal()">Annulla</button>
      </div>
    </div>
  </div>
</div>
