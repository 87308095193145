import {Component, Input, OnInit} from '@angular/core';
import {CarService} from '../../../../services/car/car.service';
import {AppComponent} from '../../../../app.component';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {ActivatedRoute, Router} from '@angular/router';
import {PriceService} from '../../../../services/price/price.service';
import {DossierService} from '../../../../services/dossier/dossier.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-carlist',
  templateUrl: './carlist.component.html',
  styleUrls: ['./carlist.component.scss']
})
export class CarlistComponent implements OnInit {
  currentTab: number;
  reservationlist: any;
  @Input() page: string;
  @Input() idcompany: string;
  @Input() iduser: string;
  loading: boolean;
  currentpage: number;
  maxpages: number;
  query: any;
  limit: number;
  skip: number;
  sort: string;
  order: boolean;
  progress: boolean;
  text: string;
  filterCompanies = [];
  pagenumbers = [];
  currentCarId: any;
  allowedChannels = {
    b2c: false,
    b2b: false,
    cau: false,
    direct: true,
  };
  constructor(
    private carService: CarService,
    public appComponent: AppComponent,
    public mainComponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    public priceservice: PriceService,
    private dossierService: DossierService,
  ) {
    this.currentTab = 1;
    this.loading = true;
    this.progress = false;
  }

  ngOnInit(): void {
    this.limit = 10;
    this.sort = 'createdAt';
    this.order = true;
    this.currentpage = 0;
    this.skip = (this.currentpage) * this.limit;

    this.query = {
      options: {
        tab: 'active',
        companies: [],
        users: [],
        salesChannels: ['DIRECT'],
      },
      sort: this.sort,
      limit: this.limit,
      skip: this.skip,
      order: this.order ? 'asc' : 'desc',
      text: '',
    };
    if(this.mainComponent.loggedUser.company.services.b2c){
      this.query.options.salesChannels.push('B2C');
      this.allowedChannels.b2c = true;
    }
    if(this.mainComponent.loggedUser.company.services.b2b){
      this.query.options.salesChannels.push('B2B');
      this.allowedChannels.b2b = true;
    }
    if(!this.mainComponent.loggedUser.role.superAdmin && !this.mainComponent.loggedUser.company.services.b2b && !this.mainComponent.loggedUser.company.services.b2c){
      this.allowedChannels.direct = false;
      this.query.options.salesChannels = [];
    }
    if (!this.page) {
      this.route.queryParams.subscribe(params => {
        this.query.sort = params.sort || this.sort;
        this.query.limit = params.limit || this.limit;
        this.query.skip = params.skip || this.skip;
        this.query.order = params.order || 'asc';
        this.query.text = params.text || '';
        this.query.options = (params.options) ? JSON.parse(params.options) : this.query.options;
        this.text = params.text || '';
        this.currentpage = this.query.skip / this.query.limit;
        if ( this.filterCompanies.length === 0 ) { this.filterCompanies = JSON.parse(JSON.stringify(this.query.options.companies)); }
        this.getReservationList();
      });
    } else {
      if ( this.page === 'user' && this.iduser) { this.query.options.users.push(this.iduser); }
      if ( this.page === 'company' && this.idcompany) { this.query.options.companies.push(this.idcompany); }
      this.navigate();
    }
    this.loading = false;
  }

  // tslint:disable-next-line:variable-name
  returnTabNum(string) {
    if (this.reservationlist) { return this.reservationlist.tabs[string]; }
    if (!this.reservationlist) { return 0; }
  }

  addOrRemoveSalesChannels(type:string){
    const tipo = type.toUpperCase();
    const find = this.query.options.salesChannels.findIndex((s:any) => s === tipo);
    if(find >=0 ){
      this.query.options.salesChannels.splice(find, 1);
    } else {
      this.query.options.salesChannels.push(tipo);
    }
    this.getReservationList()
  }

  setB2bChannels(event:any){
    this.allowedChannels.b2b = event;
    this.addOrRemoveSalesChannels('B2B');
  }
  setB2cChannels(event:any){
    this.allowedChannels.b2c = event;
    this.addOrRemoveSalesChannels('B2C');
  }
  setDirectChannels(event:any){
    this.allowedChannels.direct = event;
    this.addOrRemoveSalesChannels('DIRECT');
  }
  setCauChannels(event:any){
    this.allowedChannels.cau = event;
    this.addOrRemoveSalesChannels('CAU');
  }

  removeCpFilter(id) {
    const index =  this.filterCompanies.findIndex( r => r._id === id);
    if ( index > -1 ) {
      this.query.options.companies.splice(index, 1);
      this.filterCompanies.splice(index, 1);
      this.currentpage = 0;
      this.query.skip = 0;
      this.navigate();
    }
  }

  clearAllCp() {
    this.query.options.companies = [];
    this.filterCompanies = [];
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  setCpFilter() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  changeTab(value) {
    if (value === 'init') {
      this.query.sort = 'createdAt';
      this.sort = 'createdAt';
    }
    if (value === 'linked' || value === 'withoutTL') {
      this.query.sort = 'generatedAt';
      this.sort = 'generatedAt';
    }
    if (value === 'active') {
      this.query.sort = 'createdAt';
      this.sort = 'createdAt';
    }
    if (value === 'linked' || value === 'expired' || value === 'control') {
      this.query.sort = 'generatedAt';
      this.sort = 'generatedAt';
    }
    if (value === 'departures') {
      this.query.sort = 'generatedAt';
      this.sort = 'generatedAt';
    }
    if (value === 'deadline') {
      this.query.sort = 'timeLimit';
      this.sort = 'timeLimit';
    }
    if (value === 'issued' || value === 'flown') {
      this.query.sort = 'issuedOn';
      this.sort = 'issuedOn';
    }
    if (value === 'cancelled') {
      this.query.sort = 'cancelledAt';
      this.sort = 'cancelledAt';
    }
    if (value === 'deadline') {
      this.order = false;
      this.query.order = 'desc';
    }
    if (this.query.options.tab !== value) {
      this.query.options.tab = value;
      this.currentpage = 0;
      this.query.skip = 0;
      this.navigate();
    }
  }

  sortValue(value) {
    this.query.sort = value;
    this.sort = value;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  reverseOrder() {
    if (!this.order) {
      this.query.order = 'asc';
      this.order = true;
    } else {
      this.query.order = 'desc';
      this.order = false;
    }
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  openFilters() {
    $('.pnrfilters').collapse('toggle');
  }

  setFilterText() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  clearFilterText() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.query.text = '';
    this.navigate();
  }

  navigate() {
    if (this.page) {
      this.getReservationList();
    } else {
      this.query.options = JSON.stringify(this.query.options);
      this.router.navigate([], {
        queryParams: this.query,
      });
    }
  }

  next() {
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev() {
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value) {
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  getReservationList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.carService.getReservationsList(this.query).subscribe((res) => {
      this.loading = false;
      this.reservationlist = res;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      this.scrollTop();
      console.log('this.reservationlist => ', this.reservationlist);
      this.maxpages = Math.ceil((this.reservationlist.count / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;
      this.setAddresses();
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  setAddresses(): void {
    this.reservationlist.list.forEach((reservation) => {
      reservation.car.pickUp = reservation.rentCompany.rentalLocations[0].address;
      if (reservation.rentCompany.rentalLocations[1]) {
        reservation.car.dropOff = reservation.rentCompany.rentalLocations[1].address;
      } else {
        reservation.car.dropOff = reservation.rentCompany.rentalLocations[0].address;
      }
    });
  }

  scrollTop() {
    if ( !this.page ) { $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300); }
    if ( this.page ) { $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300); }
  }

  toggleAddtoTravel(id) {
    if (!this.mainComponent.dossierId) {
      if (id) {
        this.currentCarId = id;
      } else {
        delete this.currentCarId;
      }
      $('#travelListModal').modal('toggle');
    } else if (id && this.mainComponent.dossierId) {
      this.addCars(id);
    }
  }

  closeAddtoTravel() {
    delete this.currentCarId;
    $('#travelListModal').modal('toggle');
    this.getReservationList();
  }

  addCars(id) {
    const body = {
      hotels: [id]
    };
    this.dossierService.addCarsToDossier(this.mainComponent.dossierId, body).subscribe((res) => {
      console.log('res => ', res);
      this.getReservationList();
    }, error => {
      console.error('res => ', error);
    });
  }

  returnDate(value) {
    return new Date(value);
  }
}
