interface Options {
  date: Date;
  minDate: Date;
  departureTime: string;
  cabin: string;
  viaAirport: any[];
  expandAdvance: boolean;
  flightType: string;
  timeSlot: string;
}

interface Section {
  from: any[];
  to: any[];
  fromLegend: any[];
  toLegend: any[];
  options: Options;
}

interface Passengers {
  adt: number;
  chd: number;
  inf: number;
}

interface ItineraryOptions {
  carrierIdentification: any[];
  corporate: string;
  cabin: string;
  carrierQualifier: string;
  flightType: string;
  resident: boolean;
  refundable: boolean;
  family: boolean;
  etnic: boolean;
  calendar: any;
  alliance: boolean;
  tourOperator: boolean;
  priceToBeat?: number;
  maxTimeConnections?: number;
}

export class FlightRequest {
  searchType: string;
  sections: Section[];
  passengers: Passengers;
  options: ItineraryOptions = {} as ItineraryOptions;
}
