import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompaniesService} from '../../../../services/profile/companies.service';

@Component({
  selector: 'app-autocompletecompanies',
  templateUrl: './autocompletecompanies.component.html',
  styleUrls: ['./autocompletecompanies.component.scss']
})
export class AutocompletecompaniesComponent implements OnInit {

  @Input() query: any;
  @Input() filtering: any = [];
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChange = new EventEmitter<any>();
  companiesList: any;
  loading: boolean;
  company: any;
  companytext: string;
  constructor(
    private companiesService: CompaniesService,
  ) {
    this.loading = false;
  }

  ngOnInit(): void {
  }

  clearFrom() {
    this.companytext = '';
    delete this.company;
    delete this.companiesList;
  }

  selectComp(value) {
    if ( !this.filtering.find(r => r._id === value._id) ) {
      this.filtering.push(value);
      this.query.options.companies.push(value._id);
      this.onChange.emit();
    }
    this.clearFrom();
  }

  selectFirst() {
    if ( this.companiesList ) {
      if ( !this.filtering.find(r => r._id === this.companiesList[0]._id) ) {
        this.filtering.push(this.companiesList[0]);
        this.query.options.companies.push(this.companiesList[0]._id);
        this.onChange.emit();
      }
    }
    this.clearFrom();
  }

  searchCompany(value) {
    delete this.companiesList;
    this.clearFrom();
    if (value.length >= 2) { this.getCompaniesList(value); }
  }

  getCompaniesList(value) {
    this.clearFrom();
    this.companiesService.getCompaniesAuto(value).subscribe((res) => {
      this.loading = false;
      this.companiesList = res;
      console.log('this.companiesList => ', this.companiesList);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

}
