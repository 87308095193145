import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { HotelService } from '../../../../hotel.service';

@Component({
  selector: 'app-newresults',
  templateUrl: './newresults.component.html',
  styleUrls: ['./newresults.component.scss'],
})
export class NewresultsComponent implements OnInit{
  @Input() hotelForm : any;
  @Input() hotelDb : any;
  allHotels : number;
  codesToRate : any[];
  results : any[];
  callTime : number;
  interval : any;
  searching : boolean;

  constructor(
    private hotelService : HotelService,
  ){
    this.codesToRate = [];
    this.results = [];
    this.callTime = 0;
    this.searching = false;
    this.allHotels = 0;
  }

  ngOnInit() : void{
    console.log('this.hotelDb =>', this.hotelDb);
    this.arrangeBookingHotels();
  }

  arrangeBookingHotels(){
    for (let h = 0; h < this.hotelDb.length; h ++){
      for (let bk = 0; bk < this.hotelDb[h].codes.length; bk ++){
        /*if (this.hotelDb[h].codes[bk].substring(0, 2) === 'BK') {
         this.codesToRate.push(this.hotelDb[h].codes[bk]);
         }*/
        this.codesToRate.push(this.hotelDb[h].codes[bk]);
      }
    }
    console.log('this.codesToRate => ', this.codesToRate);
    this.interval = setInterval(() => {
      this.callTime = this.callTime + 1;
    }, 1000);
    this.searching = true;
    this.redoSearch(0);
  }

  startSearchRates(newCount){
    let count = newCount;
    const array = this.codesToRate.slice(count, count + 5);
    const request = JSON.parse(JSON.stringify(this.hotelForm));
    request.hotels = array;
    delete request.destination;
    delete request.coordinates;
    delete request.display;
    delete request.zoneId;
    delete request.nameSearch;
    count = count + 5;
    this.hotelService.rateHotels(request).subscribe(res => {
      console.log('res bk => ', res);
      this.arrangeRoomsAndServices(res, count);
    }, error => {
      this.redoSearch(count);
      console.log('res bk error => ', error);
    });
  }

  arrangeRoomsAndServices(res, count){
    for (let r = 0; r < res.rooms.length; r ++){
      res.rooms[r].availableServices = [];
      for (let s = 0; s < res.rooms[r].services.length; s ++){
        res.rooms[r].availableServices.push(res.services[res.rooms[r].services[s]]);
      }
    }
    for (let h = 0; h < res.hotels.length; h ++){
      res.hotels[h].availableRooms = [];
      for (let r = 0; r < res.hotels[h].rooms.length; r ++){
        res.hotels[h].availableRooms.push(res.rooms[res.hotels[h].rooms[r]]);
      }
      this.results.push(res.hotels[h]);
      for (let db = 0; db < this.hotelDb.length; db ++){
        const find = this.hotelDb[db].codes.findIndex(r => r === res.hotels[h].hotelCode);
        if (find !== - 1){
          if (!this.hotelDb[db].availableHotels){
            this.hotelDb[db].availableHotels = [];
          }
          this.hotelDb[db].availableHotels.push(res.hotels[h]);
        }
      }
    }
    console.log('this.results => ', this.results);
    console.log('this.hotelDb => ', this.hotelDb);
    this.redoSearch(count);
  }

  redoSearch(count){
    console.log('count => ', count);
    console.log('this.codesToRate.length + 1 => ', this.codesToRate.length + 1);
    if (count <= this.codesToRate.length){
      this.startSearchRates(count);
      /*setTimeout(() => {
       this.startSearchRates(count);
       }, 200);*/
    } else{
      this.searching = false;
      if (this.interval){
        clearInterval(this.interval);
      }
    }
  }

  returnCount(){
    this.allHotels = 0;
    for (let db = 0; db < this.hotelDb.length; db ++){
      if (this.hotelDb[db].availableHotels){
        this.allHotels = this.allHotels + 1;
      }
    }
    return this.allHotels;
  }

}
