<div class="mb-2">
  <div *ngIf="getForwardJourney()" class="">
    <mat-card appearance="outlined" class="example-card py-3 mb-3">
      <mat-card-header>
        <div class="example-header-image" mat-card-avatar></div>
        <mat-card-title>{{'TRAIN_MODULE.FORWARDJOURNEY' | translate}}</mat-card-title>
        <mat-card-subtitle class="my-1">
          {{getForwardJourney().departureStation}} {{(getForwardJourney().departureDate | date:'dd/MM/yyyy HH:mm')}}
        </mat-card-subtitle>
        <mat-card-subtitle class="my-1">
          {{getForwardJourney().arrivalStation}} {{(getForwardJourney().arrivalDate | date:'dd/MM/yyyy HH:mm')}}
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="getFwdPrice()" class="my-2">
          {{getFwdPrice() | number:'.2-2'}} €
        </mat-card-subtitle>
      </mat-card-header>
      <div class="row my-2 mx-0 px-3 alert alert-warning isVenice d-block"  *ngIf="isVenice()">
        <span>{{'IS_VENICE_ALERT' | translate}}</span>
        <span><a href="{{'IS_VENICE_URL' | translate}}" target="new">{{'HERE' | translate}}</a>.</span>
      </div>
    </mat-card>
  </div>
</div>
<div class="mb-2">
  <div *ngIf="getReturnJourney()" class="">
    <mat-card appearance="outlined" class="example-card py-3 mb-3">
      <mat-card-header>
        <div class="example-header-image" mat-card-avatar></div>
        <mat-card-title>{{'TRAIN_MODULE.RETURNJOURNEY' | translate}}</mat-card-title>
        <mat-card-subtitle>
          {{getReturnJourney().departureStation}} {{(getReturnJourney().departureDate | date:'dd/MM/yyyy HH:mm')}}
        </mat-card-subtitle>
        <mat-card-subtitle>
          {{getReturnJourney().arrivalStation}} {{(getReturnJourney().arrivalDate | date:'dd/MM/yyyy HH:mm')}}
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="getRtnPrice()">
          {{getRtnPrice() | number:'.2-2'}} €
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>
</div>
