import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../../../../app.component';
import {IatacompleteService} from '../../../../../services/iatacomplete/iatacomplete.service';
import {FlightService} from '../../../../../services/flight/flight.service';
import {MainComponent} from '../../../../mainmodule/main/main.component';
import {ActivatedRoute, Router} from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  history: any;
  loading: boolean;
  currentpage: number;
  maxpages: number;
  // setQuery
  query: any;
  limit: number;
  skip: number;
  pagenumbers = [];
  constructor(
    private flightService: FlightService,
    public appComponent: AppComponent,
    /**
     *  service for airports iata code full name
     */
    public iataComplete: IatacompleteService,
    public mainComponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.loading = true;

    this.limit = 10;
    this.currentpage = 0;
    this.skip = (this.currentpage) * this.limit;

    this.query = {
      limit: this.limit,
      skip: this.skip,
    };
    // this.navigate();
  }

  ngOnInit(): void {
    // this.getHistory();
    this.route.queryParams.subscribe(params => {
      this.query.limit = params.limit || this.limit;
      this.query.skip = params.skip || this.skip;
      this.currentpage = this.query.skip / this.query.limit;
      this.getHistory();
    });
  }

  navigate() {
    this.router.navigate([], {
      queryParams: this.query,
    });
  }

  next() {
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev() {
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value) {
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  getHistory(): void {
    this.flightService.askFlightHistory(this.query).subscribe((res) => {
      console.log('res => ', res);
      this.loading = false;
      this.history = res;
      this.scrollTop();
      this.maxpages = Math.ceil((this.history.count / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }

      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;
      this.iataComplete.createToCompletePrnHistory(this.history);
    }, error => {
      this.loading = false;
    });
  }

  scrollTop() {
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }

  openHistory(value) {
    this.router.navigate(['/flight/search'], { queryParams: { cacheId: value } });
  }

}
