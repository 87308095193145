import { NgModule } from '@angular/core';
import { RouterModule, Routes, } from '@angular/router';
import { AuthGuard } from '../../guards/auth.guard';
import { SearchHotelComponent } from './components/hotel/search/search.component';
import { HotelReservationlistComponent } from './components/hotel/reservationlist/reservationlist.component';
import { HotelreservationComponent } from './components/hotel/hotelreservation/hotelreservation.component';
import { SingleHotelQuotationComponent } from './components/hotel/single-hotel-quotation/single-hotel-quotation.component';
import { HotelQuotationV3Component } from './hotel-quotation-v3/hotel-quotation-v3.component';
import { NewHotelQuotationV3Component } from "./new-hotel-quotation-v3/new-hotel-quotation-v3.component";


const routes : Routes = [
  {
    path: 'search',
    component: SearchHotelComponent,
    data: { title: 'HOTEL_SEARCH' },
    canActivate: [AuthGuard],
  },
  {
    path: 'reservations',
    component: HotelReservationlistComponent,
    data: { title: 'HOTEL_RESERVATION_LIST' },
    canActivate: [AuthGuard],
  },
  {
    path: 'reservations/:id',
    component: HotelreservationComponent,
    data: { title: 'HOTEL_RESERVATION' },
    canActivate: [AuthGuard],
  },
  {
    path: 'quotation',
    component: NewHotelQuotationV3Component,
    data: { title: 'HOTEL_QUOTATION' },
    canActivate: [AuthGuard],
  }
  /*{
    path: 'quotation',
    component: HotelQuotationV3Component,
    data: { title: 'HOTEL_QUOTATION' },
    canActivate: [AuthGuard],
  },*/
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HotelRoutingModule{}
