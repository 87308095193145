import {
  Component,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TrainMainComponent } from '../common/train-main/train-main.component';
import { TrainService } from '../../train.service';

/**
 * Booking recap and ticket download
 */
@Component({
  selector: 'app-booking-completed',
  templateUrl: './booking-completed.component.html',
  styleUrls: ['./booking-completed.component.scss'],
})
export class BookingCompletedComponent implements OnInit{

  public bookingId : string = null;

  constructor(protected route : ActivatedRoute,
              private service : TrainService,
              private router : Router){ }

  public ngOnInit() : void{
    this.route.params.subscribe((params) => {
      this.bookingId = params.id;
    });
  }

  public downloadPdf(id : string) : void{
    this.service.getReservationPDF(id).subscribe(data => {
      const blob = new Blob([data], { type: 'file/zip' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', url);
      link.setAttribute('download', `${id}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }, _ => console.log('Error downloading the pdf.'));
  }

  public goTo($event, pathList : string[]) : void{
    localStorage.removeItem(TrainMainComponent.CURRENT_SEARCH_KEY);
    localStorage.removeItem(TrainMainComponent.TRAIN_TRAVEL_KEY);
    this.router.navigate(pathList);
  }

}
