import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { TicketingService } from '../../../../services/ticketing/ticketing.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { AdmserviceService } from '../../../../services/adminserv/admservice.service';
import { OpenTicketRequest } from '../../../../services/ticketing/api/ticketing.service.models';
import * as $ from 'jquery';
import {UsersService} from '../../../../services/profile/users.service';
import {CompaniesService} from '../../../../services/profile/companies.service';

@Component({
  selector: 'app-tkbottomsheet',
  templateUrl: './tkbottomsheet.component.html',
  styleUrls: ['./tkbottomsheet.component.scss'],
})
export class TkbottomsheetComponent implements OnInit{
  route : string;
  ticket : OpenTicketRequest;
  departements : any[];
  sections : any[];
  subject : string;
  loading : boolean;
  jiraTkt = false;
  user: any;
  loadingUser = true;
  companyUser: any;
  loadingCompany = true;
  form:any;
  sending:boolean = false;
  type: any = [
    'Aggiunta servizi extra',
    'Gestione schedule change',
    'Richiesta di rimborso',
    'Richiesta informazioni',
    'Riemissione'
  ];
  extra: any = [
    'Aggiunta servizi extra',
  ];
  schedule: any = [
    'Richiesta riemissione',
    'Richiesta rimborso',
  ];
  rimborso: any = [
    'Richiesta rimborso in GDS',
    'Richiesta rimborso in BSP Link',
    'Status rimborso',
  ];
  informazioni: any = [
    'Richiesta informazioni generica',
  ];
  riemissione: any = [
    'Calcolo riemissione',
    'Conferma riemissione',
  ];
  selectedType: null;
  selectedDetail: '';
  opetarive: any;
  loadingCompanyOp = true;
  subjectgat: '';
  descriptiongat: '';
  pnrGt: '';
  private readonly notifier : NotifierService;
  constructor(
    private router : Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data : any,
    private bottomSheetRef : MatBottomSheetRef<TkbottomsheetComponent>,
    private ticketService : TicketingService,
    public translate : TranslateService,
    private admServ : AdmserviceService,
    notifierService : NotifierService,
    private usersService: UsersService,
    private companyService : CompaniesService,
  ){
    this.form = {
      'orgid': '00D2o000000Z8l4',
      'retURL': 'https://flygattinoni.it/',
      // 'debug': '1',
      'debugEmail':'alessio.marinoni@gattinoni.it',
      'recordType':'0127T000000PElV',
      '00N2o000008u57G': '',
      'name': '',
      'email': '',
      'phone': '',
      '00N2o000008uM6n':'FlyGattinoni',
      '00N7T000000qGJS':'',
      '00N7T000000qFOY':'',
      'subject':'',
      'description':'',
      'status':'Aperta Richiesta Assistenza',
      '00N7T000001BIcJ':'',
    }
    console.log(this.data);
    console.log('this.data.logged => ', this.data.logged);
    this.notifier = notifierService;
    this.route = this.router.url;
    if (!this.data.isGat && !this.data.isSbt){
      this.jiraTkt = true;
    }
    this.subject = '';
    this.ticket = {
      subject: '',
      body: {
        payload: this.route,
        data: '',
      },
      section: '',
      department: '',
    };
    if (this.jiraTkt){
      this.getDepartements();
      this.getSections();
    } else{
      this.departements = [
        {
          value: 'tech',
          name: 'TECH',
        },
        {
          value: 'office',
          name: 'OFFICE',
        },
      ];
      if (this.data.isGat){
        this.sections = [
          {
            value: 'flight',
            name: 'flight',
            description: 'flight',
          },
        ];
      } else{
        this.sections = [
          {
            value: 'flight',
            name: 'flight',
            description: 'flight',
          },
          {
            value: 'hotel',
            name: 'hotel',
            description: 'hotel',
          },
          {
            value: 'car',
            name: 'car',
            description: 'car',
          },
          {
            value: 'train',
            name: 'train',
            description: 'train',
          },
        ];
        this.departements = [
          {
            value: 'office',
            name: 'OFFICE',
          },
        ];
        this.ticket.department = 'office';
      }

    }
    /*
     this.departements = [
     {
     value: 'tech',
     name: 'TECH'
     },
     {
     value: 'office',
     name: 'OFFICE'
     }
     ];
     this.sections = [
     {
     value: 'flight',
     name: 'flight',
     description: 'flight'
     },
     {
     value: 'hotel',
     name: 'hotel',
     description: 'hotel',
     },
     {
     value: 'car',
     name: 'car',
     description: 'car'
     },
     {
     value: 'train',
     name: 'train',
     description: 'train'
     }
     ];
     */
    if (this.data.logged){
      this.sections = [];
      if (this.data.logged.company.services.flight){
        this.sections.push({ value: 'flight', name: 'flight', description: 'flight' });
      }
      if (this.data.logged.company.services.hotel && !this.data.isGat){
        this.sections.push({ value: 'hotel', name: 'hotel', description: 'hotel' });
      }
      if (this.data.logged.company.services.car && !this.data.isGat){
        this.sections.push({ value: 'car', name: 'car', description: 'car' });
      }
      if (this.data.logged.company.services.train && !this.data.isGat){
        this.sections.push({ value: 'train', name: 'train', description: 'train' });
      }
    }
    if (this.sections && this.sections.length === 1){
      this.ticket.section = this.sections[0].value;
    }
    if (this.data.emission && this.data.discount){
      this.ticket = {
        subject: this.translate.instant('ASK_EMISSION'),
        body: {
          payload: this.route,
          data: this.translate.instant('ASK_DISCOUNT_EMISSION_FOR_PNR_NUMBER') + ' ' + this.data.locator,
        },
        department: 'office',
        section: 'flight',
        locator: this.data.locator,
      };
      if (this.jiraTkt){
        this.ticket.department = '10038';
        this.ticket.section = 'flight';
      }
    }
    if (this.data.emission && this.data.blocked){
      this.ticket = {
        subject: this.translate.instant('ASK_EMISSION'),
        body: {
          payload: this.route,
          data: this.translate.instant(this.data.text) + ' ' + this.data.locator,
        },
        department: 'office',
        section: 'flight',
        locator: this.data.locator,
      };
      if (this.jiraTkt){
        this.ticket.department = '10038';
        this.ticket.section = 'flight';
      }
      this.sendTicket();
    }
    if (this.data.change){
      this.ticket = {
        subject: this.translate.instant('RESERVATION_CHANGE'),
        body: {
          payload: this.route,
          data: this.translate.instant('CHANGE_CONFIRMATION') + ' ' + this.data.locator,
        },
        department: 'office',
        section: 'flight',
        locator: this.data.locator,
      };
      if (this.jiraTkt){
        this.ticket.department = '10038';
        this.ticket.section = 'flight';
      }
    }
    if (this.data.hotelEmission){
      this.ticket = {
        subject: this.translate.instant('ASK_HOTEL_EMISSION'),
        body: {
          payload: this.route,
          data: this.translate.instant('ASK_HOTEL_EMISSION_FOR') + ' ' + this.data.name,
        },
        department: 'office',
        section: 'hotel',
      };
      if (this.jiraTkt){
        this.ticket.department = '10038';
        this.ticket.section = 'hotel';
      }
    }
    if(this.data.seatMap){
      this.ticket = {
        subject: this.translate.instant('ASK_SEATMAP_SERVICE'),
        body: {
          payload: this.route,
          data: this.translate.instant('ASK_SEATMAP_SERVICE') + ' ' + this.data.locator,
        },
        department: 'office',
        section: 'flight',
        locator: this.data.locator,
      };
      if (this.jiraTkt){
        this.ticket.department = '10038';
        this.ticket.section = 'flight';
      }
    }

    if(this.data.ancillary){
      this.ticket = {
        subject: this.translate.instant('ASK_ANCILLARY_SERVICE'),
        body: {
          payload: this.route,
          data: this.translate.instant('ASK_ANCILLARY_SERVICE') + ' ' + this.data.locator,
        },
        department: 'office',
        section: 'flight',
        locator: this.data.locator,
      };
      if (this.jiraTkt){
        this.ticket.department = '10038';
        this.ticket.section = 'flight';
      }
    }
    if (this.data.isGat){
      this.ticket.department = 'office';
    }
    $('#repricePnrModal').modal('hide');
  }

  ngOnInit() : void{
    if(this.data.isGat){
      this.getUser();
    }
  }

  getUser(){
    this.usersService.getUser(this.data.logged._id).subscribe((res) => {
      this.loadingUser = false;
      this.user = res;
      this.form.name = this.user.name +' '+ this.user.surname,
      this.form.email = this.user.email,
      this.form.phone = this.user.phoneNumber,
      this.getCompany();
    }, error => {
      this.loadingUser = false;
      console.error('Error => ', error);
    });
  }

  getCompany() : void{
    this.companyService.getCompany(this.data.logged.company._id).subscribe((res) => {
      this.loadingCompany = false;
      this.companyUser = res;
      this.getOperative();
    }, error => {
      this.loadingCompany = false;
      console.error('Error => ', error);
    });
  }

  getOperative(){
    const body = {
      id:this.companyUser.dkNumber
    }
    this.companyService.getOperative(body).subscribe((res:any) => {
      this.loadingCompanyOp = false;
      if(res.datiAggiuntiviCliente){
        this.opetarive = res.datiAggiuntiviCliente.$.unitaOp
      } else {
        this.opetarive = '';
      }
      this.form['00N2o000008u57G'] = this.opetarive;
    }, error => {
      this.loadingCompanyOp = false;
      console.error('Error => ', error);
    });
  }

  getDepartements() : void{
    this.ticketService.getJiraDepartments().subscribe((res) => {
      // this.loading = false;
      this.departements = res.values;
      this.departements.forEach(d => {
        d.value = d.id;
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  getSections() : void{
    this.ticketService.getJiraSections().subscribe((res) => {
      // this.loading = false;
      this.sections = res.values;
      this.sections.forEach((s : any, i : number) => {
        s.value = s.name;
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  close(){
    this.admServ.sended = false;
    this.bottomSheetRef.dismiss();
  }

  sendTicket(){
    this.loading = true;

    if (this.jiraTkt){
      this.ticket.requestType = this.ticket.department;
      this.ticket.component = this.ticket.section;
      delete this.ticket.department;
      delete this.ticket.section;
    }
    this.ticketService.sendTicket(this.ticket).subscribe((res) => {
      this.admServ.sended = true;
      this.loading = false;
      this.notifier.notify('success', this.translate.instant('TICKET_OPENED'));
      this.bottomSheetRef.dismiss();
    }, error => {
      this.admServ.sended = true;
      this.loading = false;
      // this.notifier.notify('success', this.translate.instant('TICKET_OPENED'));
      this.bottomSheetRef.dismiss();
      // this.notifier.notify('error', error);
      this.notifier.notify('error', this.translate.instant(error));
      console.error('Error => ', error);
    });

  }

  validForm(){
    return !(!this.ticket.body.data || !this.ticket.subject || !this.ticket.department);
  }

  changeType(value:any){
    delete this.selectedDetail;
    this.form['00N7T000000qFOY'] = '';
    this.selectedType = value;
    this.form['00N7T000000qGJS'] = value;
  }

  changeTypeDet(value:any){
    delete this.selectedDetail;
    if(value){
      console.log('form => ', value);
      this.selectedDetail = value;
    }
    this.form['00N7T000000qFOY'] = value;
  }

  sendGatForm(){
    this.form['00N7T000001BIcJ'] = this.pnrGt;
    this.sending = true;
    console.log('form => ', this.form);
    this.ticketService.postGatTkt(this.form).subscribe((res) => {
      console.error('res => ', res);
      this.sending = false;
      this.notifier.notify('success', this.translate.instant('TICKET_OPENED'));
      this.bottomSheetRef.dismiss();
    }, error => {
      this.notifier.notify('error', 'Si è verificato un errore, ti preghiamo di riprovare più tardi');
      this.sending = false;
      console.error('Error => ', error);
    });
  }

}
