import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-policy-alerts-icon',
  templateUrl: './policy-alerts-icon.component.html',
  styleUrls: ['./policy-alerts-icon.component.scss']
})
export class PolicyAlertsIconComponent implements OnInit {
  @Input() arrayOfAlerts: any[] = [];
  @Input() isB2B?: boolean;
  @Input() isSbt?: boolean;

  constructor(
    public appComponent: AppComponent,
  ) { }

  public static policyBlock(arrayOfAlerts : any[]){
    return !!arrayOfAlerts && arrayOfAlerts.length > 0 && arrayOfAlerts?.some(p => p.block);
  }

  ngOnInit(): void {
    if(this.isB2B !== undefined && this.isB2B !== null){
      console.log('-_-')
    } else {
      this.isB2B = this.appComponent.isB2B;
      this.isSbt = this.appComponent.isSbt;
    }
  }
}
