
<div class="buttonrow text-left mb-3">
  <button class="mainbg" mat-stroked-button (click)="toggleForm()">
    <span *ngIf="!formvisible">{{'EDIT_SEARCH'|translate}}</span>
    <span *ngIf="formvisible">{{'HIDE_FORM'|translate}}</span>
  </button>
  <button (click)="toggleFilters()" class="mainbg" mat-stroked-button>{{'OPEN_FILTERS'|translate}}</button>
</div>
<!--sezione nascosta per i voli selezionati -->
<div class="selections" *ngIf="mustBeVisible()">
  <div  id="outSelection">
    <div class="row" *ngFor="let recommendation of searchResults.recommendations; let r = index; let l = last;">
      <div class=" col-12"  *ngFor="let iti of outwardItinerariesUnified let i = index; let la = last;">
        <div class=" col-12" *ngIf="recommendation.ref -1 === iti.ref && i === outwardIndex" [ngClass]="{'mb-4':!l}">
          <div [ngClass]="">
            <div class="introItinerary d-flex justify-content-between" *ngIf="recommendation.lowCost">
              <div>
                {{iataComplete.returnAirport(iti.flights[0].departure.location)}}
                <sup>{{iti.flights[0].departure.location}}</sup>
                <mat-icon>arrow_right_alt</mat-icon>
                {{iataComplete.returnAirport(iti.flights[iti.flights.length-1].arrival.location)}}
                <sup>{{iti.flights[iti.flights.length-1].arrival.location}}</sup>
              </div>
              <button class="text-right mb-2" mat-raised-button color="primary" *ngIf="mainComponent.loggedUser.role.flight.taw.post.options.link && recommendation.lowCost" (click)="openDialog(recommendation.ref,r, searchResults.routingId, searchResults._id, null,recommendation.cabins)">
                {{'BUY_RESERVATION'|translate}}
              </button>
            </div>
            <div class="row" *ngFor="let section of iti.flights; let s = index; let z = last" [hidden]="section.hidden || section.hiddenT">
              <div class="col-12 collapse show" id="selectionOut{{r}}{{i}}{{s}}">
                <div class="row" *ngIf="s== 0">
                  <div class="col-sm-1 d-sm-inline-block d-none text-center">
                    <img [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                  </div>
                  <div class="col-3">
                    {{iataComplete.returnAirport(section.departure.location)}}<br/>
                    {{section.departure.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                    {{returnZeroTime(section.departure.hours)}}:
                    {{returnZeroTime(section.departure.minutes)}}
                  </div>
                  <div class="col-3">
                    <div>{{returnTime(iti.flyingTime)}}</div>
                    <div>
                    <span *ngIf="iti.flights.length>1">
                      {{iti.flights.length-1}} {{'STOPS'|translate}}
                    </span>
                      <span *ngIf="iti.flights.length==1">
                      {{'NON_STOPS'|translate}}
                    </span>
                    </div>
                  </div>
                  <div class="col-3">
                    {{iataComplete.returnAirport(iti.flights[iti.flights.length-1].arrival.location)}}<br/>
                    {{iti.flights[iti.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                    {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.hours)}}
                    {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.minutes)}}
                  </div>

                  <div class="col-3 col-sm-2">
                    <button mat-icon-button *ngIf="recommendation.lowCost" (click)="opeSectSelectedOut(r,i,s)">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 collapse issection" id="selectionOutCollapse{{r}}{{i}}{{s}}">
                <div class="row" *ngFor="let flight of iti.flights; let f = index;let fl = last;">
                  <div class="col-12 mb-2" *ngIf="f===0">
                    <div class="row">
                      <div class="col-1 text-center">
                        <img class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                      </div>
                      <div class="col-9 align-self-center">
                        <div>{{iataComplete.returnCarrier(flight.marketingCarrier)}}</div>
                        <div class="md-titlebis">{{'DEPARTURE'|translate}}: {{flight.departure.date | date:'dd MMM yyyy'}}</div>
                        <div class="md-subhead">{{returnTime(iti.flyingTime)}}</div>
                      </div>
                      <div class="col-2">
                        <button mat-icon-button (click)="opeSectSelectedOut(r,i,s)">
                          <mat-icon>keyboard_arrow_up</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1 text-center">
                    <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
                  </div>
                  <div class="col-md-11">
                    <div class="flightdet">
                      <p class="headingseg">
                        {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.departure.location)}} ({{flight.departure.location}})
                      </p>
                      <p class="headingdetails">
                        <!--{{'FLIGHT_TIME'|translate}}: {{calculateFlightTime(flight.departure.dateTime,flight.arrival.dateTime)}}-->
                        <span *ngIf="flight.departure.terminal"> Terminal: {{flight.departure.terminal}}</span>
                      </p>
                      <p class="headingseg">
                      <span class="redt" *ngIf="otherday(flight.departure.date | date:'dd',flight.arrival.date | date:'dd')">
                        {{flight.arrival.date | date:'dd MMM yyyy'}} -
                      </span>
                        {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.arrival.location)}} ({{flight.arrival.location}})
                      </p>
                      <p class="fldetails">
                      <span *ngIf="!recommendation.lowCost">
                        Booking Class:
                      <span *ngIf="recommendation.tariff; else loadAnimation">
                        {{recommendation.tariff.bookingClasses[f+i]}} -
                      </span>
                      <ng-template #loadAnimation>
                        loading... -
                      </ng-template>
                      </span>

                        {{flight.operatingCarrier}} {{flight.number}} <span *ngIf="flight.detail && flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
                        <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>

                      </p>
                    </div>

                    <div class="scalepause" *ngIf="!fl">
                          <span>
                            <i class="fas fa-random"></i> {{'STOP'|translate}} in {{flight.arrival.location}}
                            <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}} <!-- ({{flight.waitingTime}}ms) --> </span>
                          </span>
                      <span class="alert-danger changeairport" *ngIf="flight[f+1] && flight.arrival.location != flight[f+1].departure.location">
                            {{'CHANGE'|translate}}:
                        {{flight[f+1].departure.location}}
                          </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row " *ngFor="let section of iti.sections; let s = index; let z = last"  [hidden]="iti.hidden || iti.hiddenT">
            <div class="col-12 collapse show" id="selectionOutCollapse{{r}}{{i}}{{s}}">
              <div class="row">
                <div class="col-sm-1 d-sm-inline-block d-none text-center">
                  <img [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                </div>
                <div class="col-3">
                  {{iataComplete.returnAirport(iti.flights[0].departure.location)}}<br/>
                  {{iti.flights[0].departure.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                  {{returnZeroTime(iti.flights[0].departure.hours)}}:
                  {{returnZeroTime(iti.flights[0].departure.minutes)}}
                </div>
                <div class="col-3">
                  <div>{{returnTime(iti.flyingTime)}}</div>
                  <div>
                    <span *ngIf="iti.flights.length>1">
                      {{iti.flights.length-1}} {{'STOPS'|translate}}
                    </span>
                    <span *ngIf="iti.flights.length==1">
                      {{'NON_STOPS'|translate}}
                    </span>
                  </div>
                </div>
                <div class="col-3">
                  {{iataComplete.returnAirport(iti.flights[iti.flights.length-1].arrival.location)}}<br/>
                  {{iti.flights[iti.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                  {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.hours)}}
                  {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.minutes)}}
                </div>
                <div class="col-3 col-sm-2">
                  <button mat-icon-button *ngIf="recommendation.lowCost" (click)="opeSectSelectedOut(r,i,s)">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div  id="returnSelection" >
    <div class="row" *ngFor="let recommendation of searchResults.recommendations; let r = index; let l = last;" >
      <div class=" col-12"  *ngFor="let iti of returnsItinerariesUnified let i = index; let la = last;">
        <div class=" col-12" *ngIf="recommendation.ref -1 === iti.ref && i === returnIndex" [ngClass]="{'mb-4':!l}">
          <div [ngClass]="">
            <div class="introItinerary " *ngIf="recommendation.lowCost">
              {{iataComplete.returnAirport(iti.flights[0].departure.location)}}
              <sup>{{iti.flights[0].departure.location}}</sup>
              <mat-icon>arrow_right_alt</mat-icon>
              {{iataComplete.returnAirport(iti.flights[iti.flights.length-1].arrival.location)}}
              <sup>{{iti.flights[iti.flights.length-1].arrival.location}}</sup>
            </div>
            <div class="row" *ngFor="let section of iti.flights; let s = index; let z = last" [hidden]="section.hidden || section.hiddenT">
              <div class="col-12 collapse show" id="selectionRet{{r}}{{i}}{{s}}">
                <div class="row" *ngIf="s== 0">
                  <div class="col-sm-1 d-sm-inline-block d-none text-center">
                    <img [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                  </div>
                  <div class="col-3">
                    {{iataComplete.returnAirport(section.departure.location)}}<br/>
                    {{section.departure.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                    {{returnZeroTime(section.departure.hours)}}:
                    {{returnZeroTime(section.departure.minutes)}}
                  </div>
                  <div class="col-3">
                    <div>{{returnTime(iti.flyingTime)}}</div>
                    <div>
                    <span *ngIf="iti.flights.length>1">
                      {{iti.flights.length-1}} {{'STOPS'|translate}}
                    </span>
                      <span *ngIf="iti.flights.length==1">
                      {{'NON_STOPS'|translate}}
                    </span>
                    </div>
                  </div>
                  <div class="col-3">
                    {{iataComplete.returnAirport(iti.flights[iti.flights.length-1].arrival.location)}}<br/>
                    {{iti.flights[iti.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                    {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.hours)}}
                    {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.minutes)}}
                  </div>

                  <div class="col-3 col-sm-2">
                    <button mat-icon-button *ngIf="recommendation.lowCost" (click)="opeSectSelected(r,i,s)">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 collapse issection" id="selectionRetCollapse{{r}}{{i}}{{s}}">
                <div class="row" *ngFor="let flight of iti.flights; let f = index;let fl = last;">
                  <div class="col-12 mb-2" *ngIf="f===0">
                    <div class="row">
                      <div class="col-1 text-center">
                        <img class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                      </div>
                      <div class="col-9 align-self-center">
                        <div>{{iataComplete.returnCarrier(flight.marketingCarrier)}}</div>
                        <div class="md-titlebis">{{'DEPARTURE'|translate}}: {{flight.departure.date | date:'dd MMM yyyy'}}</div>
                        <div class="md-subhead">{{returnTime(iti.flyingTime)}}</div>
                      </div>
                      <div class="col-2">
                        <button mat-icon-button (click)="opeSectSelected(r,i,s)">
                          <mat-icon>keyboard_arrow_up</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1 text-center">
                    <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
                  </div>
                  <div class="col-md-11">
                    <div class="flightdet">
                      <p class="headingseg">
                        {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.departure.location)}} ({{flight.departure.location}})
                      </p>
                      <p class="headingdetails">
                        <!--{{'FLIGHT_TIME'|translate}}: {{calculateFlightTime(flight.departure.dateTime,flight.arrival.dateTime)}}-->
                        <span *ngIf="flight.departure.terminal"> Terminal: {{flight.departure.terminal}}</span>
                      </p>
                      <p class="headingseg">
                      <span class="redt" *ngIf="otherday(flight.departure.date | date:'dd',flight.arrival.date | date:'dd')">
                        {{flight.arrival.date | date:'dd MMM yyyy'}} -
                      </span>
                        {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.arrival.location)}} ({{flight.arrival.location}})
                      </p>
                      <p class="fldetails">
                      <span *ngIf="!recommendation.lowCost">
                        Booking Class:
                      <span *ngIf="recommendation.tariff; else loadAnimation">
                        {{recommendation.tariff.bookingClasses[f+i]}} -
                      </span>
                      <ng-template #loadAnimation>
                        loading... -
                      </ng-template>
                      </span>

                        {{flight.operatingCarrier}} {{flight.number}} <span *ngIf="flight.detail && flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
                        <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>

                      </p>
                    </div>

                    <div class="scalepause" *ngIf="!fl">
                          <span>
                            <i class="fas fa-random"></i> {{'STOP'|translate}} in {{flight.arrival.location}}
                            <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}} <!-- ({{flight.waitingTime}}ms) --> </span>
                          </span>
                      <span class="alert-danger changeairport" *ngIf="flight[f+1] && flight.arrival.location != flight[f+1].departure.location">
                            {{'CHANGE'|translate}}:
                        {{flight[f+1].departure.location}}
                          </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row " *ngFor="let section of iti.sections; let s = index; let z = last"  [hidden]="iti.hidden || iti.hiddenT">
            <div class="col-12 collapse show" id="selectionRetCollapse{{r}}{{i}}{{s}}">
              <div class="row">
                <div class="col-sm-1 d-sm-inline-block d-none text-center">
                  <img [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                </div>
                <div class="col-3">
                  {{iataComplete.returnAirport(iti.flights[0].departure.location)}}<br/>
                  {{iti.flights[0].departure.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                  {{returnZeroTime(iti.flights[0].departure.hours)}}:
                  {{returnZeroTime(iti.flights[0].departure.minutes)}}
                </div>
                <div class="col-3">
                  <div>{{returnTime(iti.flyingTime)}}</div>
                  <div>
                    <span *ngIf="iti.flights.length>1">
                      {{iti.flights.length-1}} {{'STOPS'|translate}}
                    </span>
                    <span *ngIf="iti.flights.length==1">
                      {{'NON_STOPS'|translate}}
                    </span>
                  </div>
                </div>
                <div class="col-3">
                  {{iataComplete.returnAirport(iti.flights[iti.flights.length-1].arrival.location)}}<br/>
                  {{iti.flights[iti.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                  {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.hours)}}
                  {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.minutes)}}
                </div>
                <div class="col-3 col-sm-2">
                  <button mat-icon-button *ngIf="recommendation.lowCost">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="tarifsVisible">
    <!-- qui dentro ci vanno le tariffe che ho trovato -->
    <div class="col-12 d-flex justify-content-end">
      <div class="col-3 col-sm-2">
        <button mat-icon-button (click)="opeTarifs()">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <span>{{'TARIFS'|translate}}</span>
      </div>
    </div>
  </div>
  <div class="row collapse hide" id="tarif">
    <div class="col-12 col-md-4" id="" *ngFor="let fareIndex of selectedFares; let i=index" >
      <div class="col-12">
        <p>{{getPrice(fareIndex)}}</p>
        <p>{{checkFare(fareIndex)}}</p>
        -------------------------------------
        <div>
          <div class="" *ngIf="isSupported(this.searchResults.recommendations[fareIndex].marketingCarrier, this.searchResults.recommendations[fareIndex].cabins[0].split('=')[1].split('*')[0])">
            <div class="text-center">
              <h5>{{'TARIFF'|translate}}: {{this.searchResults.recommendations[fareIndex].cabins[0].split('=')[1].split('*')[0].toUpperCase()}}</h5>
            </div>
            <div class="" *ngFor="let ele of fare.info[0].tarif.details" >
              <hr class="hrBlue">
              <div class="row">
                <div class="" style="margin-left: 20px;">
                  <mat-icon *ngIf="ele.icon">{{ele.icon}}</mat-icon>
                </div>
                <div class="" style="margin-left: 10px;">
                  {{ele.name}}<br>{{ele.description}}
                </div>
              </div>
              <div *ngIf="ele.fixedMessage && fare.info.length==1" class=" mt-4 text-danger">
                <a href="{{ele.link}}" target="_blank"> <u>{{ele.fixedMessage}}</u></a>
              </div>
            </div>
            <div *ngIf="fare.info.length >1">
              <hr class="hrBlue" style="height: 10px;">
              <div class="text-center">
                <h5>{{'CARRIER'|translate}}: {{fare.info[1].supplier.toUpperCase()}}</h5>
                <h5>{{'TARIFF'|translate}}: {{fare.info[1].tarif.name.toUpperCase()}}</h5>
                <small>{{fare.info[1].tarif.subName}}</small>
              </div>
              <div class="" *ngFor="let ele of fare.info[1].tarif.details" >
                <hr class="hrBlue">
                <div class="row">
                  <div class="" style="margin-left: 20px;">
                    <mat-icon *ngIf="ele.icon">{{ele.icon}}</mat-icon>
                  </div>
                  <div class="" style="margin-left: 10px;">
                    {{ele.name}}<br>{{ele.description}}
                  </div>
                </div>
                <div *ngIf="ele.fixedMessage" class=" mt-4 text-danger">
                  <a href="{{ele.link}}" target="_blank"> <u>{{ele.fixedMessage}}</u></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!--sezione nascosta per i voli selezionati -->



<div class="isRecom">
    <div class="row" *ngFor="let recommendation of searchResults.recommendations; let r = index; let l = last;">
      <div class=" col-12"  *ngFor="let iti of outwardItinerariesUnified let i = index; let la = last;">
        <div class=" col-12" *ngIf="recommendation.ref -1 === iti.ref" [ngClass]="{'mb-4':!l}">
          <div [ngClass]="">
            <div class="introItinerary " *ngIf="recommendation.lowCost">
              <p *ngIf="iti.flights.length>0 && i===0" >
                    {{iti.flights[0].departure.date|date:'EEE dd MMM yyyy'}}
                  </p>
                  {{iataComplete.returnAirport(iti.flights[0].departure.location)}}
                  <sup>{{iti.flights[0].departure.location}}</sup>
                  <mat-icon>arrow_right_alt</mat-icon>
                  {{iataComplete.returnAirport(iti.flights[iti.flights.length-1].arrival.location)}}
                  <sup>{{iti.flights[iti.flights.length-1].arrival.location}}</sup>
                </div>
                <div class="row" *ngFor="let section of iti.flights; let s = index; let z = last" [hidden]="section.hidden || section.hiddenT">
                  <div class="col-12 collapse show" id="itinerary{{r}}{{i}}{{s}}">
                    <div class="row" *ngIf="s== 0">
                      <div class="col-sm-1 d-sm-inline-block d-none text-center">
                        <img [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                      </div>
                      <div class="col-3">
                        {{iataComplete.returnAirport(section.departure.location)}}<br/>
                        {{section.departure.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                        {{returnZeroTime(section.departure.hours)}}:
                        {{returnZeroTime(section.departure.minutes)}}
                      </div>
                      <div class="col-3">
                        <div>{{returnTime(iti.flyingTime)}}</div>
                        <div>
                    <span *ngIf="iti.flights.length>1">
                      {{iti.flights.length-1}} {{'STOPS'|translate}}
                    </span>
                          <span *ngIf="iti.flights.length==1">
                      {{'NON_STOPS'|translate}}
                    </span>
                        </div>
                      </div>
                      <div class="col-3">
                        {{iataComplete.returnAirport(iti.flights[iti.flights.length-1].arrival.location)}}<br/>
                        {{iti.flights[iti.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                        {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.hours)}}
                        {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.minutes)}}
                      </div>

                      <div class="col-3 col-sm-2">
                        <button mat-icon-button *ngIf="recommendation.lowCost" (click)="opeSect(r,i,s)">
                          <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                        <mat-checkbox class="marginleft5 align-bottom"
                                      [disabled]="outwardCheckBoxSelectionList[i]"
                                      (ngModelChange)="changeSelection(recommendation.ref,0,i)"
                                      [ngModel]="outwardCheckBoxSelectionList[i]">
                          <span>{{'SELECT'|translate}}</span>
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 collapse issection" id="collapse{{r}}{{i}}{{s}}">
                    <div class="row" *ngFor="let flight of iti.flights; let f = index;let fl = last;">
                      <div class="col-12 mb-2" *ngIf="f===0">
                        <div class="row">
                          <div class="col-1 text-center">
                            <img class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                          </div>
                          <div class="col-9 align-self-center">
                            <div>{{iataComplete.returnCarrier(flight.marketingCarrier)}}</div>
                            <div class="md-titlebis">{{'DEPARTURE'|translate}}: {{flight.departure.date | date:'dd MMM yyyy'}}</div>
                            <div class="md-subhead">{{returnTime(iti.flyingTime)}}</div>
                          </div>
                          <div class="col-2">
                            <button mat-icon-button (click)="opeSect(r,i,s)">
                              <mat-icon>keyboard_arrow_up</mat-icon>
                            </button>
                            <mat-checkbox class="marginleft5 align-bottom"
                                          [disabled]="outwardCheckBoxSelectionList[i]"
                                          (ngModelChange)="changeSelection(recommendation.ref,0,i)"
                                          [ngModel]="outwardCheckBoxSelectionList[i]">
                              <span>{{'SELECT'|translate}}</span>
                            </mat-checkbox>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-1 text-center">
                        <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
                      </div>
                      <div class="col-md-11">
                        <div class="flightdet">
                          <p class="headingseg">
                            {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
                            {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.departure.location)}} ({{flight.departure.location}})
                          </p>
                          <p class="headingdetails">
                            <!--{{'FLIGHT_TIME'|translate}}: {{calculateFlightTime(flight.departure.dateTime,flight.arrival.dateTime)}}-->
                            <span *ngIf="flight.departure.terminal"> Terminal: {{flight.departure.terminal}}</span>
                          </p>
                          <p class="headingseg">
                      <span class="redt" *ngIf="otherday(flight.departure.date | date:'dd',flight.arrival.date | date:'dd')">
                        {{flight.arrival.date | date:'dd MMM yyyy'}} -
                      </span>
                            {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
                            {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.arrival.location)}} ({{flight.arrival.location}})
                          </p>
                          <p class="fldetails">
                      <span *ngIf="!recommendation.lowCost">
                        Booking Class:
                      <span *ngIf="recommendation.tariff; else loadAnimation">
                        {{recommendation.tariff.bookingClasses[f+i]}} -
                      </span>
                      <ng-template #loadAnimation>
                        loading... -
                      </ng-template>
                      </span>

                            {{flight.operatingCarrier}} {{flight.number}} <span *ngIf="flight.detail && flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
                            <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>

                          </p>
                        </div>

                        <div class="scalepause" *ngIf="!fl">
                          <span>
                            <i class="fas fa-random"></i> {{'STOP'|translate}} in {{flight.arrival.location}}
                            <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}} <!-- ({{flight.waitingTime}}ms) --> </span>
                          </span>
                          <span class="alert-danger changeairport" *ngIf="flight[f+1] && flight.arrival.location != flight[f+1].departure.location">
                            {{'CHANGE'|translate}}:
                            {{flight[f+1].departure.location}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <div class="row " *ngFor="let section of iti.sections; let s = index; let z = last"  [hidden]="iti.hidden || iti.hiddenT">
            <div class="col-12 collapse show" id="itinerary{{r}}{{i}}{{s}}">
              <div class="row">
                <div class="col-sm-1 d-sm-inline-block d-none text-center">
                  <img [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                </div>
                <div class="col-3">
                  {{iataComplete.returnAirport(iti.flights[0].departure.location)}}<br/>
                  {{iti.flights[0].departure.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                  {{returnZeroTime(iti.flights[0].departure.hours)}}:
                  {{returnZeroTime(iti.flights[0].departure.minutes)}}
                </div>
                <div class="col-3">
                  <div>{{returnTime(iti.flyingTime)}}</div>
                  <div>
                    <span *ngIf="iti.flights.length>1">
                      {{iti.flights.length-1}} {{'STOPS'|translate}}
                    </span>
                    <span *ngIf="iti.flights.length==1">
                      {{'NON_STOPS'|translate}}
                    </span>
                  </div>
                </div>
                <div class="col-3">
                  {{iataComplete.returnAirport(iti.flights[iti.flights.length-1].arrival.location)}}<br/>
                  {{iti.flights[iti.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                  {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.hours)}}
                  {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.minutes)}}
                </div>
                <div class="col-3 col-sm-2">
                  <button mat-icon-button *ngIf="recommendation.lowCost">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                  <mat-checkbox class="marginleft5 align-bottom"
                                [disabled]="outwardCheckBoxSelectionList[i]"
                                (ngModelChange)="changeSelection(recommendation.ref ,0,i) "
                                [ngModel]="outwardCheckBoxSelectionList[i]">
                      <span>{{'SELECT'|translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
  </div>

<div class="isRecom mt-4" *ngIf="searchResults.itineraries.length === 2">
  <div class="row" *ngFor="let recommendation of searchResults.recommendations; let r = index; let l = last;">
    <div class=" col-12"  *ngFor="let iti of returnsItinerariesUnified let i = index; let la = last;">
      <div class=" col-12" *ngIf="recommendation.ref -1 === iti.ref" [ngClass]="{'mb-4':!l}">
        <div [ngClass]="">
          <div class="introItinerary " *ngIf="recommendation.lowCost">
            <p *ngIf="iti.flights.length>0 && i===0" >
              {{iti.flights[0].departure.date|date:'EEE dd MMM yyyy'}}
            </p>
            {{iataComplete.returnAirport(iti.flights[0].departure.location)}}
            <sup>{{iti.flights[0].departure.location}}</sup>
            <mat-icon>arrow_right_alt</mat-icon>
            {{iataComplete.returnAirport(iti.flights[iti.flights.length-1].arrival.location)}}
            <sup>{{iti.flights[iti.flights.length-1].arrival.location}}</sup>
          </div>
          <div class="row" *ngFor="let section of iti.flights; let s = index; let z = last" [hidden]="section.hidden || section.hiddenT">
            <div class="col-12 collapse show" id="itinerary{{r}}{{i}}{{s}}">
              <div class="row" *ngIf="s== 0">
                <div class="col-sm-1 d-sm-inline-block d-none text-center">
                  <img [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                </div>
                <div class="col-3">
                  {{iataComplete.returnAirport(section.departure.location)}}<br/>
                  {{section.departure.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                  {{returnZeroTime(section.departure.hours)}}:
                  {{returnZeroTime(section.departure.minutes)}}
                </div>
                <div class="col-3">
                  <div>{{returnTime(iti.flyingTime)}}</div>
                  <div>
                    <span *ngIf="iti.flights.length>1">
                      {{iti.flights.length-1}} {{'STOPS'|translate}}
                    </span>
                    <span *ngIf="iti.flights.length==1">
                      {{'NON_STOPS'|translate}}
                    </span>
                  </div>
                </div>
                <div class="col-3">
                  {{iataComplete.returnAirport(iti.flights[iti.flights.length-1].arrival.location)}}<br/>
                  {{iti.flights[iti.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                  {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.hours)}}
                  {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.minutes)}}
                </div>

                <div class="col-3 col-sm-2">
                  <button mat-icon-button *ngIf="recommendation.lowCost" (click)="opeSect(r,i,s)">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                  <mat-checkbox class="marginleft5 align-bottom"
                                [disabled]="returnCheckBoxSelectionList[i]"
                                (ngModelChange)="changeSelection(recommendation.ref,1,i)"
                                [ngModel]="returnCheckBoxSelectionList[i]">
                    <span>{{'SELECT'|translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="col-12 collapse issection" id="collapse{{r}}{{i}}{{s}}">
              <div class="row" *ngFor="let flight of iti.flights; let f = index;let fl = last;">
                <div class="col-12 mb-2" *ngIf="f===0">
                  <div class="row">
                    <div class="col-1 text-center">
                      <img class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                    </div>
                    <div class="col-9 align-self-center">
                      <div>{{iataComplete.returnCarrier(flight.marketingCarrier)}}</div>
                      <div class="md-titlebis">{{'DEPARTURE'|translate}}: {{flight.departure.date | date:'dd MMM yyyy'}}</div>
                      <div class="md-subhead">{{returnTime(iti.flyingTime)}}</div>
                    </div>
                    <div class="col-2">
                      <button mat-icon-button (click)="opeSect(r,i,s)">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                      </button>
                      <mat-checkbox class="marginleft5 align-bottom"
                                    [disabled]="returnCheckBoxSelectionList[i]"
                                    (ngModelChange)="changeSelection(recommendation.ref,1,i)"
                                    [ngModel]="returnCheckBoxSelectionList[i]">
                        <span>{{'SELECT'|translate}}</span>
                      </mat-checkbox>
                    </div>
                  </div>
                </div>

                <div class="col-md-1 text-center">
                  <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
                </div>
                <div class="col-md-11">
                  <div class="flightdet">
                    <p class="headingseg">
                      {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
                      {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.departure.location)}} ({{flight.departure.location}})
                    </p>
                    <p class="headingdetails">
                      <!--{{'FLIGHT_TIME'|translate}}: {{calculateFlightTime(flight.departure.dateTime,flight.arrival.dateTime)}}-->
                      <span *ngIf="flight.departure.terminal"> Terminal: {{flight.departure.terminal}}</span>
                    </p>
                    <p class="headingseg">
                      <span class="redt" *ngIf="otherday(flight.departure.date | date:'dd',flight.arrival.date | date:'dd')">
                        {{flight.arrival.date | date:'dd MMM yyyy'}} -
                      </span>
                      {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
                      {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.arrival.location)}} ({{flight.arrival.location}})
                    </p>
                    <p class="fldetails">
                      <span *ngIf="!recommendation.lowCost">
                        Booking Class:
                      <span *ngIf="recommendation.tariff; else loadAnimation">
                        {{recommendation.tariff.bookingClasses[f+i]}} -
                      </span>
                      <ng-template #loadAnimation>
                        loading... -
                      </ng-template>
                      </span>

                      {{flight.operatingCarrier}} {{flight.number}} <span *ngIf="flight.detail && flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
                      <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>

                    </p>
                  </div>

                  <div class="scalepause" *ngIf="!fl">
                          <span>
                            <i class="fas fa-random"></i> {{'STOP'|translate}} in {{flight.arrival.location}}
                            <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}} <!-- ({{flight.waitingTime}}ms) --> </span>
                          </span>
                    <span class="alert-danger changeairport" *ngIf="flight[f+1] && flight.arrival.location != flight[f+1].departure.location">
                            {{'CHANGE'|translate}}:
                      {{flight[f+1].departure.location}}
                          </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row " *ngFor="let section of iti.sections; let s = index; let z = last"  [hidden]="iti.hidden || iti.hiddenT">
          <div class="col-12 collapse show" id="itinerary{{r}}{{i}}{{s}}">
            <div class="row">
              <div class="col-sm-1 d-sm-inline-block d-none text-center">
                <img [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
              </div>
              <div class="col-3">
                {{iataComplete.returnAirport(iti.flights[0].departure.location)}}<br/>
                {{iti.flights[0].departure.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                {{returnZeroTime(iti.flights[0].departure.hours)}}:
                {{returnZeroTime(iti.flights[0].departure.minutes)}}
              </div>
              <div class="col-3">
                <div>{{returnTime(iti.flyingTime)}}</div>
                <div>
                    <span *ngIf="iti.flights.length>1">
                      {{iti.flights.length-1}} {{'STOPS'|translate}}
                    </span>
                  <span *ngIf="iti.flights.length==1">
                      {{'NON_STOPS'|translate}}
                    </span>
                </div>
              </div>
              <div class="col-3">
                {{iataComplete.returnAirport(iti.flights[iti.flights.length-1].arrival.location)}}<br/>
                {{iti.flights[iti.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.hours)}}
                {{returnZeroTime(iti.flights[iti.flights.length-1].arrival.minutes)}}
              </div>
              <div class="col-3 col-sm-2">
                <button mat-icon-button *ngIf="recommendation.lowCost">
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <mat-checkbox class="marginleft5 align-bottom"
                              [disabled]="returnCheckBoxSelectionList[i]"
                              (ngModelChange)="changeSelection(recommendation.ref ,1,i)"
                              [ngModel]="returnCheckBoxSelectionList[i]">
                  <span>{{'SELECT'|translate}}</span>
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




