import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  TrainAmount,
  ValidateChangeServiceResponse,
} from '../../../../classes/train.models';
import { TrainUtils } from '../../../train.utils';

@Component({
  selector: 'app-validated-travel-penalty',
  templateUrl: './validated-travel-penalty.component.html',
  styleUrls: ['./validated-travel-penalty.component.scss']
})
export class ValidatedTravelPenaltyComponent implements OnInit {
  @Input() validatedTravel : ValidateChangeServiceResponse;

  parsedValidatedTravel : ValidateChangeServiceResponse;
  oldPayment : number;

  constructor() { }

  ngOnInit(): void {
    this.parsedValidatedTravel = {
      ...this.validatedTravel,
      reversedPaymentRecords: TrainUtils.toArray(this.validatedTravel.reversedPaymentRecords),
      reusedPaymentRecords: TrainUtils.toArray(this.validatedTravel.reusedPaymentRecords),
      additionalCharges: TrainUtils.toArray(this.validatedTravel.additionalCharges),
    };
    const payments = TrainUtils.toArray(this.validatedTravel.travel.entitlements).find(e => e.id === this.validatedTravel.validatedPostSaleRecord.cancelledEntitlements)?.paymentAmounts;
    if (payments){
      this.oldPayment = this.getSum(payments);
    }
  }

  getSum(payment : any[]){
    return payment.map(p => Number.parseFloat(p.amount.amount)).reduce((partialSum, a) => partialSum + a, 0);
  }

  getPenaltySum(postSalePaymentPenalties : any){
    const penalty = Array.isArray(postSalePaymentPenalties) ? postSalePaymentPenalties : [postSalePaymentPenalties];
    return penalty.map(p => Number.parseFloat(p.penaltyAmount.amount)).reduce((partialSum, a) => partialSum + a, 0);
  }
}
