<mat-card appearance="outlined" class="collapse show" id="searchCard">
  <mat-card-content>
    <div *ngIf="searchForm.getRawValue()['carnetId'] && searchForm.getRawValue()['carnetId'].travellers" class="row">
      <div class="row mb-3">
        <div class="col-md-auto align-self-center">
          <span class="singleiata">
            {{searchForm.getRawValue()['carnetId'].travellers[0]?.doc.surname + ' ' + searchForm.getRawValue()['carnetId'].travellers[0]?.doc.name}}
          </span>
        </div>
        <div class="col-md-auto align-self-center">
          <span class="material-icons">train</span>
        </div>
        <div class="col-md-auto align-self-center">
          {{ searchForm.getRawValue()['carnetId'].itineraries[0]?.from.station + ' -> ' + searchForm.getRawValue()['carnetId'].itineraries[0]?.to.station}}
        </div>
      </div>
    </div>
    <div class="row searchContainer d-flex justify-content-end">
      <div class="formTopButtons ">
        <div class="formTopAction reset" (click)="initSearchForm()" matTooltip="{{'CLEAR_FORM'|translate}}">
              <span class="material-icons">
                refresh
              </span>
        </div>
      </div>
    </div>
    <form (submit)="onSubmit($event)" [formGroup]="searchForm" id="frmSearch">
      <div class="row lightbg">

        <!-- CARNET-->
        <div class="col-md-12 col-lg-4">
          <mat-form-field class="full-width">
            <mat-label>{{'TRAIN_MODULE.CARNET' | translate}}</mat-label>
            <input (click)="searchCarnets($event)" [matAutocomplete]="carnet_auto" formControlName="carnetId" matInput min="0" type="number">
          </mat-form-field>
          <mat-autocomplete #carnet_auto="matAutocomplete" [displayWith]="displayCarnetFn" class="full-width">
            <mat-option *ngIf="isCarnetLoading" class="is-loading">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-option>
            <ng-container *ngIf="!isCarnetLoading">
              <mat-option *ngFor="let carnet of getCarnets()" [value]="carnet">
                  <span matTooltip="[{{ carnet.pnr }}] {{ carnet.travellers[0]?.doc.surname + ' ' + carnet.travellers[0]?.doc.name}}">
                    {{ carnet.itineraries[0]?.from.station + ' -> ' + carnet.itineraries[0]?.to.station}}
                  </span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>

        <!-- DATA PARTENZA-->
        <div class="col-xs-12 col-lg-2">
          <mat-form-field class="full-width">
            <mat-label>{{'TRAIN_MODULE.DEPARTURE_DATE' | translate}}</mat-label>
            <input (click)="departure_date.open()" [min]="departureStartDate$ | async" [ngxMatDatetimePicker]="departure_date" formControlName="departure_date" matInput readonly>
            <mat-datepicker-toggle [for]="departure_date" matSuffix></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #departure_date [showSeconds]="false" [showSpinners]="true"
                                     [stepHour]="1" [stepMinute]="5" [stepSecond]="1">
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
      </div>
    </form>

    <div class="row text-right search-buttons">
      <div class="col-sm-0">
        <!--<button (click)="initSearchForm()" mat-stroked-button type="button">
          {{'TRAIN_MODULE.RESET'|translate}}
        </button>-->
      </div>
      <div class="col-sm-1 mr-3 mr-lg-0">
        <button (click)="toggleSearchForm()" [disabled]="!searchForm.valid" form="frmSearch"  class="newButtonPrimary" type="submit">
          {{'TRAIN_MODULE.FORM.SEARCH' | translate}}
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<ng-container *ngIf="searchResults || (searching$ | async)">
  <div *ngIf="searchResults?.carnet as carnet" class="row mt-2 mb-2">
    <div class="col-lg-6">
      <mat-card appearance="outlined" class="carnet-card">
        <mat-card-header>
          <div class="header-image" mat-card-avatar></div>
          <mat-card-title>
            {{searchResults.travellers?.surname}}, {{searchResults.travellers?.name}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-card-subtitle>
            <div class="row">
              <div class="col-8 align-self-center">
                {{'TRAIN_MODULE.CARNET' | translate }}: {{carnet?.id}}
              </div>
              <div class="col-8 align-self-center">
                {{'ISSUED' | translate }}: {{carnet?.issueDate | date:'dd/MM/yyyy'}}
              </div>
              <div class="col-8 align-self-center">
                {{'TRAIN_MODULE.TRENIT.USAGE' | translate }}: {{carnet?.currentUsages}}/{{carnet?.maxUsages}}
              </div>
            </div>
          </mat-card-subtitle>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <train-searchresult *ngIf="!(searching$ | async) else loadingSearchResults"
                      [searchResults]="searchResults" style="margin-top: 10px;"></train-searchresult>
  <ng-template #loadingSearchResults>
    <div class="row" style="margin-top: 10px;">
      <div class="col-xs-12 col-lg-12">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </ng-template>
</ng-container>

