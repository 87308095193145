import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
    EvaluateTravelSolutionReq,
    EvaluateTravelSolutionRes,
    ItaloBooking,
    Journey,
    LayoutQueryResponse,
    ProvideLayoutReq,
    ProvideLayoutResponse,
    SearchBaseRequest,
    SearchBaseResponse,
    SearchTrainsResponse,
} from '../../../classes/train.models';
import { MatTableDataSource } from '@angular/material/table';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Router } from '@angular/router';
import { TrainMainComponent } from '../../common/train-main/train-main.component';
import { TrainService } from '../../../train.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TrainUtils } from '../../train.utils';
import { PolicyAlertsIconComponent } from '../../../../shared/policy-alerts-icon/policy-alerts-icon.component';
import * as $ from 'jquery';


@Component({
    selector: 'train-searchresult',
    templateUrl: './train-searchresult.component.html',
    styleUrls: ['./train-searchresult.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TrainSearchresultComponent extends TrainMainComponent implements AfterViewInit{
    /**
     *  Constructor
     */
    constructor(
        private trainService : TrainService,
        public router : Router,
        public titleService : Title,
        public translate : TranslateService,
    ){
        super(router, titleService, translate);
    }

    @Input() searchResults : SearchTrainsResponse;
    @Output() nextStepEvent = new EventEmitter<string>();

    // Table
    displayedColumns = ['logo', 'departure', 'journey_length', 'arrival', 'train_segment', 'details'];
    expandedElement : Journey | null;
    searchDataSource : any = new MatTableDataSource([]);

    // Details
    selectedOfferId : string[];
    selectedClass : any[];
    selectedOffer : any = {};

    sortedSegments : any;
    selectedIndex = 0;
    serviceColumns : string[] = [];
    classOfService = ['Low Cost', 'Economy', 'Flex'];
    productClass : { [id : string] : string } = {
        S: 'Smart',
        T: 'Comfort',
        P: 'Prima',
        C: 'Club',
        S1: 'Salotto 1',
        S2: 'Salotto 2',
    };
    faresAlerts : any = {};

    loadingDetail$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
    evaluateTravelLoading$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
    seatSelection$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
    seatSelection : LayoutQueryResponse[];
    selectedSeats : any = {};

    currentTrip = 'ForwardJourney';
    searchBaseResponse : SearchBaseResponse;
    selectedJourney : Journey;
    catalog : any;
    filteredRes = [];
    companyFilter = [];
    filterTimeMin = [];
    filterTimeMax = [];
    cpFilterStatus = [];
    originalData : SearchTrainsResponse;
    trainType = [];
    toggleSelected = true;
    italoSelectedFare;
    italoSelectedClass;
    paxButtonSelected = false;
    hasBeenSelected = false;
    trenitaliaFareSelected = false;

    // Trenitalia detail table
    offerForTable : any[];
    classesForTable : any[];
    selectedTravellerId : string;

    ngAfterViewInit(){
      console.log('this.searchResults', this.searchResults)
        this.searchDataSource = new MatTableDataSource(this.searchResults.ForwardJourney);
        const getalerts = (aOfAlerts) => aOfAlerts ? aOfAlerts.filter(a => !!a) : [];
        this.searchResults.ForwardJourney.forEach(s => s.fares.forEach(f => this.faresAlerts[f.FareSellKey] = getalerts(f.arrayOfAlerts)));
        this.searchResults.ReturnJourney.forEach(s => s.fares.forEach(f => this.faresAlerts[f.FareSellKey] = getalerts(f.arrayOfAlerts)));
        this.originalData = Object.assign({}, this.searchResults);
        this.createFilters();
        $(window).width() > 960 ? this.toggleSelected = false : this.toggleSelected = true;
    }

    isCorporate(tfName:string){
        let isCorporate = false;
        if (tfName.toLowerCase().includes('corporate')){
            isCorporate = true;
        }
        return isCorporate;
    }

    createFilters(){
        let resultToFilter;
        if (this.currentTrip === 'ForwardJourney'){
            resultToFilter = this.searchResults.ForwardJourney;
        } else{
            resultToFilter = this.searchResults.ReturnJourney;
        }
        resultToFilter = this.orderByTime(resultToFilter);

        // fill company data for filter
        let toAdd = true;
        for (const c of resultToFilter){
            toAdd = true;
            for (const cf of this.companyFilter){
                if (cf.company === c.company){
                    toAdd = false;
                }
            }
            if (toAdd){
                this.companyFilter.push({ company: c.company, selected: true });
            }
        }
        // filtro per tariffa
        let temp;
        let index;
        for (const f of resultToFilter){
            if (f.company === 'TRENITALIA'){
                temp = [...f.segments];
                for (const t of temp){
                    index = this.trainType.findIndex(ele => {
                        return ele.classification === t.trainClassification;
                    });
                    if (index === - 1){
                        this.trainType.push({ classification: t.trainClassification, selected: true });
                    }
                }
            }
        }
        console.log(JSON.stringify(this.trainType));
    }

    toggleStructure(){
        this.toggleSelected = !this.toggleSelected;
        console.log('class->' + this.italoSelectedClass + ' fare->' + JSON.stringify(this.italoSelectedFare));
    }


    updateTrainTypeFilter(data){
        const toFilter = [];
        const filteredData = [];
        for (const d of data){
            for (const fare of this.trainType){
                if (d.company === 'TRENITALIA'){
                    if (fare.selected && (fare.classification === d.segments[0].trainClassification)){
                        filteredData.push(d);
                    }
                }
            }
        }
        for (const d of data){
            if (d.company !== 'TRENITALIA'){
                filteredData.push(d);
            }
        }
        return filteredData;
    }

    hasTrenitalia(){
        const index = this.companyFilter.findIndex(ele => ele.company === 'TRENITALIA');
        return index;
    }

    getTrenitLogoByCPName(name){
        let resultToFilter;
        let index;
        resultToFilter = this.searchResults.ForwardJourney;
        resultToFilter = resultToFilter.concat(this.searchResults.ReturnJourney);
        if (name.company === 'ITALO'){
            index = resultToFilter.findIndex(ele => {
                return ele.company === 'ITALO';
            });
        } else if (name.company === 'TRENITALIA'){
            index = resultToFilter.findIndex(ele => {
                return ele.company === 'TRENITALIA';
            });
        } else{
            return '#';
        }
        return this.getTrenitLogo(undefined, resultToFilter[index]);
    }

    orderByTime(toOrder){
        let first;
        let second;
        for (let i = 0; i <= toOrder.length - 1; i ++){
            for (let j = 0; j < (toOrder.length - i - 1); j ++){
                first = new Date(toOrder[j].departureDate).getTime();
                second = new Date(toOrder[j + 1].departureDate).getTime();
                if (first > second){
                    const temp = toOrder[j];
                    toOrder[j] = toOrder[j + 1];
                    toOrder[j + 1] = temp;
                }
            }
        }
        return toOrder;
    }

    selectionTrigger(e){
        console.log('triggerato evento selezione italo ');
    }

    isCpSelected(ele){
        return this.companyFilter.includes(ele);
    }


    filterCarrier(filterNameCarrier, i){
        if (filterNameCarrier !== null && i !== null){
            if (this.companyFilter[i].company === filterNameCarrier.company){
                this.companyFilter[i].selected = !this.companyFilter[i].selected;
            }
        }
        this.searchResults = Object.assign({}, this.originalData);
        if (this.currentTrip === 'ForwardJourney'){
            this.searchResults.ForwardJourney = this.orderByTime(this.updateTrainTypeFilter(this.doCompanyFilter(this.searchResults.ForwardJourney)));
        } else{
            this.searchResults.ReturnJourney = this.orderByTime(this.updateTrainTypeFilter(this.doCompanyFilter(this.searchResults.ReturnJourney)));
        }
    }

    doCompanyFilter(data){
        const filteredResult = [];
        let index;
        for (const res of data){
            index = this.companyFilter.findIndex(ele => ele.company === res.company);
            if ((index >= 0) && this.companyFilter[index].selected){
                filteredResult.push(res);
            }
        }
        return filteredResult;
    }


    isItalo(i : number, row : Journey) : boolean{
        return row.company === 'ITALO';
    }

    isTrenitalia(i : number, row : Journey) : boolean{
        return row.company === 'TRENITALIA' && !row.travelSolution.offeredServices;
    }

    isTrenitaliaCarnet(i : number, row : Journey) : boolean{
        return row.company === 'TRENITALIA' && !!row.travelSolution.offeredServices;
    }

    toggleFilters(){
        $('.filterSearch').toggleClass('openfilters');
        $('.filtersearchBackdrop').toggleClass('openfiltersearchBackdrop');
    }

    /**
     * set the the selected offer For the trenitalia trip
     * @param solutionNodeRef segment of the trip for multisegment trip
     * @param offer
     * @param paxIndex
     */
    selectTrenitaliaOffer(solutionNodeRef : string, offer : any, paxIndex : number) : void{
        if (this.isOfferDisabled(offer)){
            return;
        }
        this.selectedOfferId[paxIndex] = offer.name;
        const travelSolution = this.trainTravel.selectedTravelSolution;
        if (this.currentTrip === 'ForwardJourney'){
            this.trainTravel = {
                ...this.trainTravel,
                selectedTravelSolution: {
                    ...travelSolution,
                    offeredServices: this.getFilteredOffers(paxIndex, travelSolution.offeredServices, solutionNodeRef, this.selectedClass, this.selectedOfferId),
                },
            };
        } else{
            this.trainTravel = {
                ...this.trainTravel,
                selectedTravelSolution: {
                    ...travelSolution,
                    returnTravelSolution: {
                        ...travelSolution.returnTravelSolution,
                        offeredServices: super.getFilteredOffers(paxIndex, travelSolution.returnTravelSolution.offeredServices, solutionNodeRef, this.selectedClass, this.selectedOfferId),
                    },
                },
            };
        }
        super.updateTrainTravel();
    }

    setItaloClassSelection(e, s){
        console.log(JSON.stringify(e) + 'selezionata la classe');
        this.italoSelectedClass = e;

    }

    setItaloFareSelection(e, index){
        e.index = index;
        console.log(JSON.stringify(e) + 'selezionata la tariffa');
        this.italoSelectedFare = e;
        this.selectItaloSolution(index, index.faresMatrix[this.italoSelectedClass.value][this.italoSelectedFare.key],
            this.policyBlock(this.faresAlerts[index.faresMatrix[this.italoSelectedClass.value][this.italoSelectedFare.key]]));
        this.hasBeenSelected = true;
    }

    /**
     * call to the trenitalia service to evaluate the saleability of the selected solution
     * if service returns OK it moves forward to the passenger detail collection
     */
    async evaluateTravelSolution() : Promise<void>{
        const request = new EvaluateTravelSolutionReq(this.trainTravel.selectedTravelSolution, this.trainTravel.travellers, this.trainTravel.customerKey);
        this.evaluateTravelLoading$.next(true);
        this.trainService.evaluateTravelSolution(request).subscribe((res : EvaluateTravelSolutionRes) => {
            this.evaluateTravelLoading$.next(false);
            if (!res?.evaluateTravelSolutionsResponse.validSelection){
                return;
            }
            Object.values(this.selectedOffer).forEach((o : any) => this.trainTravel.arrayOfAlerts.push(this.faresAlerts[o.id]));
            this.trainTravel = {
                ...this.trainTravel,
                evaluatedTravel: res,
            };
            super.updateTrainTravel();
            if (this.currentSearch.return_date && this.currentTrip === 'ForwardJourney'){
                this.currentTrip = 'ReturnJourney';
                delete this.searchBaseResponse;
                delete this.expandedElement;
                this.onlyTrenitaliaTrains();
                this.searchDataSource = new MatTableDataSource(this.searchResults.ReturnJourney);
            } else{
                this.nextStep();
            }
        }, _ => this.evaluateTravelLoading$.next(false));
    }

    emptySelection(){
        this.selectedOfferId = new Array(this.trainTravel.travellers.length);
        this.selectedClass = new Array(this.trainTravel.travellers.length);
        delete this.trainTravel.selectedTravelSolution;
    }

    /**
     * @param j Jurney instance from searchResult
     * Loads the journey detail call the appropriate service according to ITALO (getAvailableTrain)/TRENIT(searchBase)
     */

    changeTab(value){
        this.selectedOfferId = new Array(this.trainTravel.travellers.length);
        this.selectedClass = new Array(this.trainTravel.travellers.length);
        this.selectedIndex = value;
        this.onlyTrenitaliaTrains();
        this.setSegment(this.sortedSegments[value]);
    }

    getDisabledOffer(){
        const selectedClass = this.selectedClass;
        const offerId = this.selectedOfferId;
        if (!selectedClass || !offerId || offerId.includes(undefined)){
            return true;
        }
        return selectedClass.map(s => {
            return offerId.map(o => {
                return s[o] && s[o].eligibility.level !== 'ELIGIBLE';
            }).includes(true);
        }).includes(true);
    }

    log(data){
        console.log(JSON.stringify(data));
    }

    isButtonSelected(index){
        if (index === 0){
            return true;
        } else{
            return false;
        }
    }

    getEligibilityMessage(){
        const selectedClass = this.selectedClass;
        const offerId = this.selectedOfferId;
        if (!selectedClass || !offerId || offerId.includes(undefined)){
            return '';
        }
        const messages = new Set();
        this.selectedClass.forEach(s => {
            this.selectedOfferId.filter(o => !!s[o] && !!s[o].eligibility.messages)
                .forEach(offerName => {
                    const msg = Array.isArray(s[offerName].eligibility.messages) ? s[offerName].eligibility.messages : [s[offerName].eligibility.messages];
                    msg.forEach(m => messages.add(m));
                });
        });
        return Array.from(messages).join('. ');
    }

    getOffers(element : Journey){
        const travelSolution = element.travelSolution;
        return TrainUtils.toArray(travelSolution.offeredServices);
    }

    // ** Private ** //

    // -- ITALO -- //
    loadJourneyDetail(j : Journey) : void{
        this.selectedIndex = 0;
        if (this.selectedOfferId){
            this.emptySelection();
        }
        if (this.expandedElement === j){
            this.expandedElement = null;
            return;
        }
        this.expandedElement = this.expandedElement === j ? null : j;
        if (j.company === 'ITALO'){
            const booking = this.trainTravel.italo?.booking || new ItaloBooking();
            booking[this.currentTrip] = j;
            booking.travellers = this.trainTravel.travellers.map((t, i) => {
                return { ...t, PassengerNumber: i };
            });
            delete this.trainTravel.evaluatedTravel;
            this.trainTravel = {
                ...this.trainTravel,
                italo: {
                    ...this.trainTravel.italo,
                    booking,
                },
            };
            super.updateTrainTravel();
        } else{
            delete this.trainTravel.italo;
            this.trainTravel = {
                ...this.trainTravel,
                selectedTravelSolution: j.travelSolution,
            };
            super.updateTrainTravel();
            if (!this.searchResults.carnet){
                this.searchBase(j);
            } else{
                this.selectedJourney = j;
                this.expandSeatSelection(j);
            }
        }
    }

    productClassOriginalOrder() : number{
        return 0;
    }

    selectItaloSolutionFromSelect(element){
        console.log(JSON.stringify(element));
    }

    /**
     * sets the selection for ITalo booking according to the current leg of the trip Fwd/Ret
     */
    selectItaloSolution(journey : any, fare : any, blockPolicy : boolean, cls = undefined, prod = undefined) : void{
        if (blockPolicy){
            return;
        }
        if (cls && prod){
            this.italoSelectedClass = cls;
            this.italoSelectedFare = prod;
            this.hasBeenSelected = true;
        }
        if (this.currentTrip === 'ForwardJourney'){
            this.trainTravel.forwardJourney = journey;
            this.trainTravel.arrayOfAlerts.push(this.faresAlerts[fare.FareSellKey]);
            this.trainTravel.italo.booking = {
                ...this.trainTravel.italo.booking,
                Signature: journey.signature,
                ForwardJourney: {
                    ...this.trainTravel.italo.booking.ForwardJourney,
                    JourneySellKey: journey.key,
                    FareSellKey: fare.FareSellKey,
                    detail: journey,
                    fare,
                },
            };
        } else if (this.currentTrip === 'ReturnJourney'){
            this.trainTravel.returnJourney = journey;
            this.trainTravel.arrayOfAlerts.push(this.faresAlerts[fare.FareSellKey]);
            this.trainTravel.italo.booking = {
                ...this.trainTravel.italo.booking,
                Signature: journey.signature,
                ReturnJourney: {
                    ...this.trainTravel.italo.booking.ReturnJourney,
                    JourneySellKey: journey.key,
                    FareSellKey: fare.FareSellKey,
                    detail: journey,
                    fare,
                },
            };
        }
        this.paxButtonSelected = true;
        super.updateTrainTravel();
    }

    getTraveller(pax : any){
        return this.trainTravel.travellers.find(t => t.xmlId === pax.paxId || t._id === pax.paxId);
    }

    selectPassengers() : void{
        if (this.currentSearch.return_date && this.currentTrip === 'ForwardJourney'){
            this.currentTrip = 'ReturnJourney';
            delete this.searchBaseResponse;
            if (this.trainTravel.forwardJourney.company === 'ITALO'){
                this.onlyItaloTrains();
            } else{
                this.onlyTrenitaliaTrains();
            }
            this.searchDataSource = new MatTableDataSource(this.searchResults.ReturnJourney);
        } else{
            const request : any = {
                ...this.trainTravel.italo.booking,
                travellers: this.trainTravel.travellers.map((t) => {
                    if (t.type === 'CHD' || t.type === 'CHILDREN'){
                        t.type = 'CHD';
                    } else if (t.type === 'ADT' || t.type === 'ADULT'){
                        t.type = 'ADT';
                    }
                    return t;
                }),
            };
            this.evaluateTravelLoading$.next(true);
            this.trainService.italoHoldBooking(request).subscribe((result) => {
                this.evaluateTravelLoading$.next(false);
                this.trainTravel.italo.holdBookingResponse = result;
                super.updateTrainTravel();
                this.nextStep(['trains', 'italo', 'checkout']);
            }, _ => this.evaluateTravelLoading$.next(false));
        }
    }

    onlyItaloTrains(){
        const tempSearchResult = [];
        for (const ret of this.searchResults.ReturnJourney){
            if (ret.company === 'ITALO'){
                tempSearchResult.push(ret);
            }
        }
        this.searchResults.ReturnJourney = tempSearchResult;
    }

    onlyTrenitaliaTrains(){
        const tempSearchResult = [];
        for (const ret of this.searchResults.ReturnJourney){
            if (ret.company === 'TRENITALIA'){
                tempSearchResult.push(ret);
            }
        }
        this.searchResults.ReturnJourney = tempSearchResult;
    }


    carnetBooking(j : Journey, seatSelection : boolean = true){
        this.evaluateTravelLoading$.next(true);
        if (seatSelection){
            this.confirmSelectSeat();
        }
        const travelSolution = j.travelSolution;
        this.trainTravel = {
            ...this.trainTravel,
            selectedTravelSolution: {
                ...travelSolution,
                offeredServices: [{
                    ...j.travelSolution.offeredServices,
                    status: 'SELECTED',
                }],
            },
        };
        super.updateTrainTravel();
        const request = new EvaluateTravelSolutionReq(this.trainTravel.selectedTravelSolution, this.trainTravel.travellers, this.trainTravel.customerKey);
        request.carnetId = this.currentSearch.carnetId?.pnr;
        this.trainService.evaluateCarnetBooking(request).subscribe(createReservationResponse => {
            this.evaluateTravelLoading$.next(false);
            this.trainTravel.reservatedTravel = {
                bookingDb: createReservationResponse.bookingDb,
                travel: createReservationResponse.result.travel,
                carnetId: createReservationResponse.carnetId,
            };
            super.updateTrainTravel();
            this.nextStep(['trains', 'trenitalia', 'checkout']);
        });
    }

    backToForwardJourney($event : MouseEvent){
        this.currentTrip = 'ForwardJourney';
        delete this.trainTravel?.selectedTravelSolution?.returnTravelSolution;
        super.updateTrainTravel();
        this.searchDataSource = new MatTableDataSource(this.searchResults.ForwardJourney);
    }

    isOfferDisabled(offer : any){
    return offer.available === 0 || offer.eligibility.level !== 'ELIGIBLE';// || PolicyAlertsIconComponent.policyBlock(this.faresAlerts[offer.id]);
    }

    private searchBase(j : Journey){
        const fwdTrip = (this.currentTrip !== 'ForwardJourney') ? this.trainTravel.evaluatedTravel.evaluateTravelSolutionsResponse.travelSolutions : undefined;
        if (!this.currentSearch.use_pit)  this.currentSearch.pit_code = null;
        const request = new SearchBaseRequest(j.travelSolution, fwdTrip, this.trainTravel.travellers, this.currentSearch.pit_code);
        this.loadingDetail$.next(true);
        this.trainService.searchBase(request).subscribe((response : SearchBaseResponse) => {
            this.loadingDetail$.next(false);
            this.searchBaseResponse = response;
            const travellers = this.cleanTravellers(response.result.travellers);
            const searchBaseTravelSolution = response.result.travelSolutions;
            if (!Array.isArray(searchBaseTravelSolution.offeredServices)){
                searchBaseTravelSolution.offeredServices = [searchBaseTravelSolution.offeredServices];
            }
            searchBaseTravelSolution.offeredServices.forEach(o => this.faresAlerts[o.xmlId] = o.arrayOfAlerts);
            this.trainTravel = {
                ...this.trainTravel,
                travellers,
                customerKey: response.customerKey,
                selectedTravelSolution: {
                    ...searchBaseTravelSolution,
                    offeredServices: searchBaseTravelSolution.offeredServices || this.trainTravel.evaluatedTravel.evaluateTravelSolutionsResponse.travelSolutions.offeredServices,
                },
            };
            super.updateTrainTravel();
            this.sortedSegments = response.segments.slice().map((s : any) => {
                return {
                    ...s,
                    services: s.services.map((paxServices : any) => {
                        return { ...paxServices, services: this.getSortedServices(paxServices) };
                    }),
                };
            });
            this.selectedOfferId = new Array(this.trainTravel.travellers.length);
            this.selectedClass = new Array(this.trainTravel.travellers.length);
            this.setTravellerInTable(this.trainTravel.travellers[0]?.xmlId, this.sortedSegments[0]);
        }, _ => this.loadingDetail$.next(false));
    }

    private nextStep(nextStep : string[] = ['trains', 'passengers']){
        if (this.isAftersale()){
            this.nextStepEvent.emit('passengers');
        } else{
            this.router.navigate(nextStep);
        }
    }

    private expandSeatSelection(j : Journey){
        this.seatSelection$.next(true);
        const selectedTravelSolution = {
            ...this.trainTravel.selectedTravelSolution,
            offeredServices: {
                ...this.trainTravel.selectedTravelSolution.offeredServices,
                status: 'SELECTED',
                price: {
                    amount: '0',
                },
            },
        };
        const request = new ProvideLayoutReq(selectedTravelSolution);
        this.trainService.provideLayout(request).subscribe((response : ProvideLayoutResponse) => {
            this.seatSelection$.next(false);
            this.seatSelection = response.layoutResults;
            this.catalog = response.catalog;
        }, error => {
            this.seatSelection$.next(false);
        });
    }

    getStationFromCatalog(id){
        return this.catalog.stations[id];
    }

    confirmSelectSeat(){
        if (!!this.selectedSeats){
            const seats = {};
            Object.keys(this.selectedSeats).forEach(segment => Object.keys(this.selectedSeats[segment]).forEach(pax => {
                if (!seats[pax]){
                    seats[pax] = {};
                }
                seats[pax][segment] = this.selectedSeats[segment][pax];
            }));
            this.trainTravel.travellers = this.trainTravel.travellers.map(t => {
                return {
                    ...t,
                    seat: seats[t.xmlId],
                };
            });
        }
    }

    setOffer(serviceClass : any, idXml : any, paxIndex : number){
        if (serviceClass.offers.length === 1 && serviceClass.offers[0].offers.length === 1 && this.isOfferDisabled(serviceClass.offers[0])){
            this.selectedOffer[paxIndex] = serviceClass.offers[0]?.offers[0];
            this.selectTrenitaliaOffer(idXml, this.selectedOffer[paxIndex], paxIndex);
        }
    }

    policyBlock(arrayOfAlerts : any){
        return PolicyAlertsIconComponent.policyBlock(arrayOfAlerts);
    }

    getClassesForTable(segment){
        return segment.services.find(s => s.paxId === this.selectedTravellerId).services[0].services;
    }

    getOffersForTable(segment : any){
        const trainClassesForPax = this.getClassesForTable(segment);
        const offersKeys = (classe) => Object.keys(classe).filter((k : any) => !['name', 'description', 'type', 'level', 'displayName', 'paxId', 'offers'].includes(k));
        const allOffers = {};
        for (let i = 0; i < trainClassesForPax.length; i ++){
            offersKeys(trainClassesForPax[i]).forEach(offerId => { // offers from each class
                allOffers[offerId] = { offerId, ...trainClassesForPax[i][offerId], classes: [] };
            });
        }
        const rows = []; // [ { ...offerData, classes: [] } ]
        for (const key of Object.keys(allOffers)){
            const offer = allOffers[key];
            offer.classes = trainClassesForPax.map(trainClass => {
                const offerWithName = trainClass[offer.offerId];
                if (offerWithName){
                    offerWithName.name = offer.offerId;
                }
                return offerWithName;
            });
            rows.push(offer);
        }
        rows.sort((a : any, b : any) => a.displayName.localeCompare(b.displayName));
        return rows;
    }

    selectOfferInTable(segment : any, trainOffer : any, classIndex : number, paxIndex : number){
        this.selectedClass[paxIndex] = this.classesForTable[classIndex];
        this.selectedOffer[paxIndex] = trainOffer; // TODO ngModel non prende l'oggetto perche non è lo stesso che c'e sulla select list.
        this.selectTrenitaliaOffer(segment.idXml, this.selectedOffer[paxIndex], paxIndex);
    }

    isOfferSelectedInTable(segment : any, trainOffer : any, classIndex : number, paxIndex : number){
        const classForPaxSelected = this.selectedClass[paxIndex]?.displayName === this.classesForTable[classIndex]?.displayName;
        const offerForPaxSelected = this.selectedOffer[paxIndex]?.id === trainOffer.id;
        return classForPaxSelected && offerForPaxSelected;
    }

    setTravellerInTable(paxId : any, segment : any){
        this.selectedTravellerId = paxId;
        this.setSegment(segment);
    }

    private setSegment(segment){
        this.classesForTable = this.getClassesForTable(segment);
        this.offerForTable = this.getOffersForTable(segment);
    }

    private cleanTravellers(t : any){
        const travellers = TrainUtils.toArray(t);
        travellers.forEach(t => {
            const uniqParams = {};
            t.parameters.forEach(p => uniqParams[p.typeId] = p);
            t.parameters = Object.values(uniqParams).filter((p : any) => !!p.value);
            t._id = t.xmlId;
        });
        return travellers;
    }

    returnItaloDescription(tariff){
        const name = tariff.toLowerCase();
        const descriptions = {
            flex: 'L’offerta Flex è modificabile fino a 3 minuti prima della partenza del treno scelto. Si possono modificare ambiente e/o data e/o ora del viaggio con il pagamento dell’eventuale differenza di prezzo rispetto all’offerta disponibile al momento del cambio. ' +
                'La modifica del nominativo è gratuita.' +
                'L’offerta Flex è rimborsabile fino a 3 minuti prima della partenza del treno scelto. Rimborso: 80% del prezzo dell’offerta Flex.' +
                'L’offerta Flex è dotata di Extra Tempo.',
            'low cost': 'L\'offerta Low Cost è modificabile fino a 72 ore prima della partenza programmata del treno scelto. Si possono modificare ambiente e/o data e/o ora del viaggio con un’integrazione del 50% del biglietto, oltre all’eventuale differenza di prezzo rispetto all’offerta disponibile al momento del cambio. La modifica del nominativo è a pagamento. L’offerta Low Cost non è rimborsabile. In vigore da: 04/03/2022.',
            economy: 'L’offerta Economy è modificabile fino a 3 minuti prima della partenza del treno scelto. Si possono modificare ambiente e/o data e/o ora del viaggio con un’integrazione del 20% del biglietto, oltre all’eventuale differenza di prezzo rispetto all’offerta disponibile al momento del cambio.\n' +
                'La modifica del nominativo è a pagamento.\n' +
                'L’offerta Economy è rimborsabile fino a 3 minuti prima della partenza del treno scelto. Rimborso: 60% del prezzo dell’offerta Economy.',
            comfort: '',
            'salotto 1': 'Concediti la massima privacy: il Salotto è ad uso esclusivo. L’esperienza inizia già in stazione, con' +
                ' l’accesso alle sale Lounge Italo Club e l’ingresso veloce al treno con il servizio Fast Track. Verifica gli orari di apertura delle Lounge Italo Club in pagina Viaggiare con Italo. In treno troverai poltrone più ampie e comode per il massimo relax e un catering che a seconda della fascia oraria di viaggio prevede prodotti di pasticceria o sandwich preparati freschi ogni giorno. Sono sempre disponibili inoltre bevande alcoliche come birra e spumante, soft drinks, caffè espresso e un’ampia scelta di snack serviti dal personale dedicato. In più puoi goderti l’intrattenimento e i ricchi contenuti del portale di bordo Italolive con film, libri ed edicola digitale.',
            'salotto 2': 'Concediti la massima privacy: il Salotto è ad uso esclusivo. L’esperienza inizia già in stazione, con' +
                ' l’accesso alle sale Lounge Italo Club e l’ingresso veloce al treno con il servizio Fast Track. Verifica gli orari di apertura delle Lounge Italo Club in pagina Viaggiare con Italo. In treno troverai poltrone più ampie e comode per il massimo relax e un catering che a seconda della fascia oraria di viaggio prevede prodotti di pasticceria o sandwich preparati freschi ogni giorno. Sono sempre disponibili inoltre bevande alcoliche come birra e spumante, soft drinks, caffè espresso e un’ampia scelta di snack serviti dal personale dedicato. In più puoi goderti l’intrattenimento e i ricchi contenuti del portale di bordo Italolive con film, libri ed edicola digitale.',
            club: 'Viaggia nell’ambiente più esclusivo di Italo e concediti comfort, privacy ed intrattenimento. Con un' +
                ' biglietto in Club Executive puoi accedere gratuitamente alle esclusive sale Lounge Italo Club, dove attendere in stazione sarà un piacere. Verifica gli orari di apertura in pagina Viaggiare con Italo. In treno troverai poltrone più ampie e comode per il massimo relax e un catering che a seconda della fascia oraria di viaggio prevede prodotti di pasticceria o sandwich preparati freschi ogni giorno. Sono sempre disponibili inoltre bevande alcoliche come birra e spumante, soft drinks, caffè espresso e un’ampia scelta di snack serviti dal personale dedicato. In più, schermi personali da 9” sui treni AGV 575 per godere dei ricchi contenuti del portale di bordo Italolive con film, libri ed edicola digitale.',
            smart: 'L’ambiente di viaggio più conveniente di Italo non rinuncia a comfort e servizi. Sedili in pelle reclinabili, poggiapiedi, tavolini e prese elettriche individuali. Wi-Fi gratuito e accesso al portale di bordo Italolive con film, libri ed edicola digitale.',
            prima: 'L’ambiente dove al comfort di viaggio si unisce il servizio. Servizio di benvenuto con caffè, tè, acqua, succhi, soft drinks, snack dolci e salati serviti direttamente al posto. L’offerta è arricchita con prodotti freschi da forno sulle tratte no stop del mattino e della sera. Più spazio per le gambe e seduta larga e comoda. Sedili in pelle reclinabili, poggiapiedi, tavolini e prese elettriche individuali. Wi-Fi gratuito e accesso al portale di bordo Italolive con film, libri ed edicola digitale.',
        }
        return descriptions[name];
    }

    isVenice(s: any){
      /*console.log(s)*/
      if (!(new Date() >= new Date('2024-7-14'))) return s._id.toString() === '63403b07aacb370db3b4c4f9' || s.name.toUpperCase().startsWith('VENEZIA');
    }
}
