<button (click)="toggleModal()" id="INFO" mat-button type="button">
  <mat-icon>expand_more</mat-icon>
  {{'DETAILS' | translate }}</button>

<div aria-hidden="true" aria-labelledby="informationModal" class="modal fade" data-backdrop="static" id="informationModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document" style="top:70px">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="informationModalTitle">{{'TRAIN_MODULE.TRAIN_INFO'|translate}}</h5>
        <button (click)="toggleModal()" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="!data || (fetchingInformation$ | async) else showResult">
          <div class="mb-2">{{'TRAIN_MODULE.LOADING'|translate}}</div>
          <mat-progress-bar mode="indeterminate" style="max-width: 200px;display:inline-block;"></mat-progress-bar>
        </ng-container>
        <ng-template #showResult>
          <mat-tab-group>
            <mat-tab *ngFor="let d of data" label="{{d.classification}}">
              <div class="row mb-3 lightbg">
                <div class="col-md-auto align-self-center">
                  <span class="material-icons">train</span>
                </div>
                <div class="col-md-auto align-self-center">
                  <p>{{d.description.departureTime | date:'dd/MM/yyyy - HH:mm'}}</p>
                  <p>{{d.description.departureStation}}</p>
                </div>
                <div class="col-md-auto align-self-center">
                  <span class="material-icons">arrow_right</span>
                </div>
                <div class="col-md-auto align-self-center">
                  <p>{{d.description.arrivalTime | date:'dd/MM/yyyy - HH:mm'}}</p>
                  <p>{{d.description.arrivalStation}}</p>
                </div>
              </div>
              <div class="row text-wrap">
                <div class="col-md-6">
                  <mat-card appearance="outlined" *ngFor="let note of d.notes" class="text-left">
                    <mat-card-subtitle>{{note.description}}</mat-card-subtitle>
                    <mat-card-content *ngFor="let desc of note.items"> {{desc.fullDescription}} </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-md-4">
                  <table [dataSource]="d.stops" class="mat-elevation-z8" mat-table>
                    <ng-container matColumnDef="departureTime">
                      <th *matHeaderCellDef mat-header-cell>{{'TRAIN_MODULE.TRAIN_DEPARTURE' | translate}}</th>
                      <td *matCellDef="let element" mat-cell> {{element.departureTime | date:'HH:mm'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="arrivalTime">
                      <th *matHeaderCellDef mat-header-cell>{{'TRAIN_MODULE.TRAIN_ARRIVAL' | translate}}</th>
                      <td *matCellDef="let element" mat-cell> {{element.arrivalTime | date:'HH:mm'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="location">
                      <th *matHeaderCellDef mat-header-cell>{{'TRAIN_MODULE.STATION' | translate}}</th>
                      <td *matCellDef="let element" mat-cell> {{element.location}} </td>
                    </ng-container>
                    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                  </table>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>

        </ng-template>
      </div>

    </div>
  </div>
</div>
