import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';
import { SearchTrainsComponent } from './components/search/search-trains.component';
import { BookingCompletedComponent } from './components/booking-completed/booking-completed.component';
import { PassengersComponent } from './components/passengers/passengers.component';
import { CheckoutComponent } from './components/italo-checkout/checkout.component';
import { CheckoutComponent as TrenitCheckoutComponent } from './components/trenit-checkout/checkout.component';
import { TrainReservationListComponent } from './components/reservationlist/reservationlist.component';
import { AuthGuard } from '../../guards/auth.guard';
import { TrainReservationComponent } from './components/train-single-reservation/singlereservation.component';


const routes : Routes = [
  { path: 'search', component: SearchTrainsComponent },
  { path: 'completed/:id', component: BookingCompletedComponent },
  { path: 'passengers', component: PassengersComponent },
  { path: 'italo/checkout', component: CheckoutComponent, data: { title: 'TRAIN_MODULE.ARRANGE_RESERVATION' } },
  { path: 'trenitalia/checkout', component: TrenitCheckoutComponent, data: { title: 'TRAIN_MODULE.ARRANGE_RESERVATION' } },
  { path: 'reservations', component: TrainReservationListComponent, data: { title: 'TRAIN_RESERVATION_LIST' }, canActivate: [AuthGuard] },
  { path: 'reservations/:id', component: TrainReservationComponent, data: { title: 'RESERVATION' }, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TrainsRoutingModule{}
