import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DossierService} from '../../../../services/dossier/dossier.service';
import {AppComponent} from '../../../../app.component';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {ActivatedRoute, Router} from '@angular/router';
import * as $ from 'jquery';
import {PriceService} from '../../../../services/price/price.service';
import { TrainUtils } from '../../../trains/components/train.utils';

@Component({
  selector: 'app-dossierlist',
  templateUrl: './dossierlist.component.html',
  styleUrls: ['./dossierlist.component.scss']
})
export class DossierlistComponent implements OnInit {
  currentTab: number;
  dossierList: any;
  @Input() page: string;
  @Input() idcompany: string;
  @Input() iduser: string;
  @Input() idFlight: string;
  @Input() idHotel: string;
  @Input() idCar: string;
  @Input() idTrain: string;
  // tslint:disable-next-line:no-output-native no-output-rename
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();
  loading: boolean;
  currentpage: number;
  maxpages: number;
  // setQuery
  query: any;
  limit: number;
  skip: number;
  sort: string;
  order: boolean;
  progress: boolean;
  text: string;
  filterCompanies = [];
  pagenumbers = [];
  newDossier: string;
  dossierToDelete: string;
  constructor(
    public appComponent: AppComponent,
    public mainComponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    private dossierSerivice: DossierService,
    public priceService: PriceService,
  ) {
    this.currentTab = 1;
    this.loading = true;
    this.progress = false;
    this.newDossier = '';
  }

  ngOnInit(): void {
    this.limit = 10;
    this.sort = 'createdAt';
    this.order = true;
    this.currentpage = 0;
    this.skip = (this.currentpage) * this.limit;

    this.query = {
      options: {
        companies: [],
        users: [],
        viewAll: false,
      },
      sort: this.sort,
      limit: this.limit,
      skip: this.skip,
      order: this.order ? 'asc' : 'desc',
      text: '',
    };
    if (!this.page) {
      this.route.queryParams.subscribe(params => {
        this.query.sort = params.sort || this.sort;
        this.query.limit = params.limit || this.limit;
        this.query.skip = params.skip || this.skip;
        this.query.order = params.order || 'asc';
        this.query.text = params.text || '';
        this.query.options = (params.options) ? JSON.parse(params.options) : this.query.options;
        this.text = params.text || '';
        this.currentpage = this.query.skip / this.query.limit;
        if ( this.filterCompanies.length === 0 ) { this.filterCompanies = JSON.parse(JSON.stringify(this.query.options.companies)); }
        this.getDossierList();
      });
    } else {
      if ( this.page === 'user' && this.iduser) { this.query.options.users.push(this.iduser); }
      if ( this.page === 'company' && this.idcompany) { this.query.options.companies.push(this.idcompany); }
      this.navigate();
    }
    this.loading = false;
  }

  removeCpFilter(id) {
    const index =  this.filterCompanies.findIndex( r => r._id === id);
    if ( index > -1 ) {
      this.query.options.companies.splice(index, 1);
      this.filterCompanies.splice(index, 1);
      this.currentpage = 0;
      this.query.skip = 0;
      this.navigate();
    }
  }

  clearAllCp() {
    this.query.options.companies = [];
    this.filterCompanies = [];
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  setCpFilter() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  emptyDossier(dossier) {
    return dossier.flights.length === 0 && dossier.hotels.length === 0 && dossier.cars.length === 0 && dossier.trains.length === 0;
  }

  sortValue(value) {
    this.query.sort = value;
    this.sort = value;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  reverseOrder() {
    if (!this.order) {
      this.query.order = 'asc';
      this.order = true;
    } else {
      this.query.order = 'desc';
      this.order = false;
    }
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  openFilters() {
    $('.pnrfilters').collapse('toggle');
  }

  addDossierToggle() {
    $('#addDosier').modal('toggle');
  }

  deleteDossierToggle(id) {
    if ( id ) {
      this.dossierToDelete = id;
    } else {
      delete  this.dossierToDelete;
    }
    $('#deleteDosier').modal('toggle');
  }

  setFilterText() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  clearFilterText() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.query.text = '';
    this.navigate();
  }

  navigate() {
    if (this.page) {
      this.getDossierList();
    } else {
      this.query.options = JSON.stringify(this.query.options);
      this.router.navigate([], {
        queryParams: this.query,
      });
    }
  }

  next() {
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev() {
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value) {
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  getDossierList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.dossierSerivice.getDossierList(this.query).subscribe((res) => {
      this.loading = false;
      this.dossierList = res;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      this.scrollTop();
      console.log('this.dossierlist => ', this.dossierList);
      this.maxpages = Math.ceil((this.dossierList.count / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;
      this.dossierList.list.forEach((d:any) => {
        if(d.cars.length > 0) {
          d.cars = d.cars.filter((f:any) => f.doc);
        }
        if(d.hotels.length > 0) {
          d.hotels = d.hotels.filter((f:any) => f.doc);
        }
        if(d.flights.length > 0) {
          d.flights = d.flights.filter((f:any) => f.doc);
        }
        if(d.trains.length > 0) {
          d.trains = d.trains.filter((f:any) => f.doc);
        }
      })
    }, error => {
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  scrollTop() {
    if ( !this.page ) { $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300); }
    if ( this.page ) { $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300); }
  }

  createDossier() {
    const body = {
      flights: [],
      hotels: [],
      trains: [],
      cars: []
    };
    this.dossierSerivice.createDossier(body).subscribe((res) => {
      console.log('res => ', res);
      this.nameDossier(res._id);
    }, error => {
      console.error('res => ', error);
    });
  }

  nameDossier(id) {
    const body = {
      name: this.newDossier,
    };
    this.dossierSerivice.nameDossier(body, id).subscribe((res) => {
      console.log('res => ', res);
      this.getDossierList();
      // this.navigateToDossier(id);
      this.addDossierToggle();
    }, error => {
      console.error('res => ', error);
    });
  }

  deleteDossier(id) {
    this.dossierSerivice.deleteDossier(id).subscribe((res) => {
      console.log('res => ', res);
      this.deleteDossierToggle(null);
      if (id === this.mainComponent.dossierId) {
        this.mainComponent.destroyDossier();
      }
      this.getDossierList();
    }, error => {
      console.error('res => ', error);
    });
  }

  navigateToDossier(id) {
    this.router.navigate(['/dossier/list/' + id]);
  }

  addFlights(id) {
    const body = {
      flights: [this.idFlight]
    };
    this.dossierSerivice.addFlightsToDossier(id, body).subscribe((res) => {
      console.log('res => ', res);
      this.close.emit();
    }, error => {
      console.error('res => ', error);
    });
  }

  addHotelss(id) {
    const body = {
      hotels: [this.idHotel]
    };
    this.dossierSerivice.addHotelsToDossier(id, body).subscribe((res) => {
      console.log('res => ', res);
      this.close.emit();
    }, error => {
      console.error('res => ', error);
    });
  }

  addCars(id) {
    const body = {
      cars: [this.idCar]
    };
    this.dossierSerivice.addCarsToDossier(id, body).subscribe((res) => {
      console.log('res => ', res);
      this.close.emit();
    }, error => {
      console.error('res => ', error);
    });
  }

  returnCarAmount(rates) {
    for (let i = 0; i < rates.length; i++) {
      if (rates[i].type === 'estimated total') {
        return rates[i].amount;
      }
    }
  }

  addTrains(id) {
    const body = {
      trains: [this.idTrain]
    };
    this.dossierSerivice.addTrainsToDossier(id, body).subscribe((res) => {
      console.log('res => ', res);
      this.close.emit();
    }, error => {
      console.error('res => ', error);
    });
  }

  getTrainLogo(carrier : any){
    return TrainUtils.getTrainLogo(carrier);
  }
}
