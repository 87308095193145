import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PriceService} from '../../../../../../services/price/price.service';
const ListSupportedRatesDetails = require('../searchresults/ratesDetailsSupportedCompany.json');

@Component({
  selector: 'app-bottomlowcost',
  templateUrl: './bottomlowcost.component.html',
  styleUrls: ['./bottomlowcost.component.scss']
})
export class BottomlowcostComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BottomlowcostComponent>,
    public priceservice: PriceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  isSupported(mark){
    for(let i=0; i<ListSupportedRatesDetails.length; i++){
      if(mark == ListSupportedRatesDetails[i].marketingCarrier){
        return true;
      }
    }
    return false;
  }

}
