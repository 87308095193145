import {Component, Input, OnInit} from '@angular/core';
import {FeesModService} from '../fees.service';
import {PaginationOrder} from '../../../sdk/transaction';

@Component({
  selector: 'app-single-agency-fees',
  templateUrl: './single-agency-fees.component.html',
  styleUrls: ['./single-agency-fees.component.scss']
})
export class SingleAgencyFeesComponent implements OnInit {
  @Input() companyId?: string;
  @Input() profile?: any;
  query = {
    id: '',
    limit: 2000,
    skip: 0,
    text: '',
    sort: '',
  }
  fees: any;
  constructor(
    public feesService: FeesModService,
  ) { }

  ngOnInit(): void {
    this.getFeesByCpId();
  }

  getFeesByCpId(){
    this.query.id = this.companyId;
    this.feesService.getFeesByCompanyId(this.query).subscribe((res) => {
      this.fees = res;
    }, err => {
      console.log('error => ', err);
    });
  }

  getAllFess(){
    this.query.id = this.companyId;
    this.feesService.getFeesByCompanyId(this.query).subscribe((res) => {
      this.fees = res;
    }, err => {
      console.log('error => ', err);
    });
  }

}
