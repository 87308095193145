import { Component, Input } from '@angular/core';
import { PriceService } from "../../../services/price/price.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-room-selected-v2',
  templateUrl: './room-selected-v2.component.html',
  styleUrls: ['./room-selected-v2.component.scss']
})
export class RoomSelectedV2Component {
  @Input() hotel : any;
  @Input() searchRes : any;
  @Input() search : any;
  @Input() room : any;
  // @Input() i : any;
  @Input() isSbt: any;
  @Input() isB2B: any;
  @Input() isGat: any;
  @Input() guests: any;
  numberOfNights : number;
  constructor(
    public priceService: PriceService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.calculateNights();
  }

  calculateNights(){
    const date1 = new Date(this.search.checkIn);
    const date2 = new Date(this.search.checkOut);
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    this.numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

  convertPrice(value, currency) : number{
    if (this.searchRes.currencyConversions){
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.searchRes.currencyConversions.length; i ++){
        if (this.searchRes.currencyConversions[i].local === currency){
          return this.priceService.returnPriceHotel(value * this.searchRes.currencyConversions[i].rate);
        }
      }
    } else{
      return this.priceService.returnPriceHotel(value);
    }
  }

  checkVoucher(value){
    if (value.includes('AMADEUS') || value.includes('HotelBeds')) {
      return true;
    } else {
      return false
    }
  }

  corporate(text){
    return text.includes('CORPORATE') || text.includes('corporate');
  }

  returnProvider(value:string): string{
    if (!this.isGat && value.includes('AMADEUS')){
      return this.translate.instant('TARIFFES') + ' LEONARDO TRAVEL';
    }
    return value;
  }

  isBooking(value:string){
    return !!value.includes('BOOKING');
  }

  isRoomOnly(value?:string){
    if(value && value.toLowerCase().includes('only')){
      return true;
    }
    return false;
  }

  removeHr(date){
    date = new Date(date);
    date = date.setHours(date.getHours() - 4);
    return date;
  }

  isFreeCancellationBis(){
    return this.room.ratePlans[0].penalty[0] && this.room.ratePlans[0].penalty[0].absoluteDeadLine;
  }
}
