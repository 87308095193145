<button (click)="toggleModal()" [disabled]="isDisabled" id="TRAVEL_CHANGE" mat-button
        type="button">{{'TRAIN_MODULE.AFTERSALE.TRAVEL_CHANGE'|translate}}</button>

<div aria-hidden="true" aria-labelledby="changeDateModal" class="modal fade" data-backdrop="static" id="changeDateModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-xl" role="document" style="top:70px">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="changeDateModalTitle">{{'TRAIN_MODULE.AFTERSALE.CHANGE_DATE_QUESTION'|translate}}</h5>
        <button (click)="toggleModal()" *ngIf="!checkingDates" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <ng-container *ngIf="!changeAttempt else successDialog">

          <form (submit)="checkDates()" [formGroup]="searchForm" id="frmDateSearch">
            <div class="col-xs-12">
              <mat-form-field class="full-width">
                <mat-label>{{'TRAIN_MODULE.DEPARTURE_DATE' | translate}}</mat-label>

                <input (ngModelChange)="checkDates()" [min]="departureStartDate$ | async" [ngxMatDatetimePicker]="departure_date" formControlName="departure_date"
                       matInput>
                <mat-datepicker-toggle [for]="departure_date" matSuffix></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #departure_date [showSeconds]="false" [showSpinners]="true" [stepHour]="1" [stepMinute]="5" [stepSecond]="1">
                </ngx-mat-datetime-picker>
              </mat-form-field>

            </div>
          </form>
          <ng-container *ngIf="!checkingDates else fetchingData">
            <table *ngIf="newDates.length > 0 else noData" [dataSource]="newDates" class="mat-elevation-z1 mb-2" mat-table multiTemplateDataRows>

              <ng-container matColumnDef="stations">
                <th *matHeaderCellDef mat-header-cell> {{ 'TRAIN_MODULE.DEP_ARR_STATIONS' | translate }} </th>
                <td *matCellDef="let element" mat-cell>{{ getStationFromCatalog(element.travelSolution.parsedSolutionNodes[0].start.id)}} ->
                  {{ getStationFromCatalog(element.travelSolution.parsedSolutionNodes[element.travelSolution.parsedSolutionNodes.length - 1].end.id) }}</td>
              </ng-container>
              <ng-container matColumnDef="departure">
                <th *matHeaderCellDef mat-header-cell> {{ 'TRAIN_MODULE.AFTERSALE.DEPARTURETIME' | translate }} </th>
                <td *matCellDef="let element" mat-cell>{{element.travelSolution.parsedSolutionNodes[0].departureTime | date:'dd/MM/yyyy - HH:mm' }}</td>
              </ng-container>
              <ng-container matColumnDef="arrival">
                <th *matHeaderCellDef mat-header-cell> {{ 'TRAIN_MODULE.AFTERSALE.ARRIVALTIME' | translate }} </th>
                <td *matCellDef="let element" mat-cell>
                  {{element.travelSolution.parsedSolutionNodes[element.travelSolution.parsedSolutionNodes.length - 1].arrivalTime | date:'dd/MM/yyyy - HH:mm'}}
                </td>
              </ng-container>
              <ng-container matColumnDef="train">
                <th *matHeaderCellDef mat-header-cell> {{ 'TRAIN_MODULE.AFTERSALE.DENOMINATION' | translate }} </th>
                <td *matCellDef="let element" mat-cell>
                  {{element.travelSolution.parsedSolutionNodes.length === 1 ? getTrainName(element.travelSolution.parsedSolutionNodes[0].offeredTransportMeanDeparture) : '-' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="expandedSegments">
                <td *matCellDef="let elemento" [attr.colspan]="columnsToDisplay.length" class="pl-2 pr-2" mat-cell>
                  <tr [@detailExpand]="elemento == expandedElement ? 'expanded' : 'collapsed'" class="element-detail pl-30">
                    <table class="table table-borderless w-100">
                      <tr *ngFor="let sn of elemento.travelSolution.parsedSolutionNodes; let i = index">
                        <td class="element-segments">{{ getStationFromCatalog(sn.start.id)}} -> {{ getStationFromCatalog(sn.end.id) }}</td>
                        <td class="element-segments">{{sn.departureTime | date:'dd/MM/yyyy - HH:mm'}}</td>
                        <td class="element-segments">{{sn.arrivalTime | date:'dd/MM/yyyy - HH:mm'}}</td>
                        <td class="element-segments">{{getTrainName(sn.offeredTransportMeanDeparture)}}</td>
                      </tr>
                    </table>
                  </tr>
                </td>
              </ng-container>

              <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
              <tr (click)="expandRow(row)" *matRowDef="let row; columns: columnsToDisplay;" [class.mat-expanded]="expandedElement === row"
                  [ngClass]="isSelected(row)" class="example-element-row" mat-row></tr>
              <tr *matRowDef="let row; columns: ['expandedSegments']" class="detail-row" mat-row
                  [@detailExpand]="row.xmlId === expandedElement?.xmlId ? 'TRAIN_MODULE.expanded' : 'collapsed'"></tr>

            </table>
            <ng-template #noData>
              <div class="mb-2" *ngIf="searchForm.valid">
                <mat-icon>warning</mat-icon>
                {{'NO_RESULTS'|translate}}
              </div>
            </ng-template>
          </ng-container>

          <ng-template #fetchingData>
            <div class="mb-2">{{'TRAIN_MODULE.LOADING'|translate}}</div>
            <mat-progress-bar mode="indeterminate" style="max-width: 200px;display:inline-block;"></mat-progress-bar>
          </ng-template>
        </ng-container>
        <ng-template #successDialog>
          <mat-dialog-content>
            <ng-container *ngIf="errorMessage else successMessage">
              <mat-icon>warning</mat-icon>
              {{ errorMessage | translate}}
            </ng-container>
            <ng-template #successMessage>
              {{'TRAIN_MODULE.CHANGE_SUCCESS' | translate}}
            </ng-template>
          </mat-dialog-content>
        </ng-template>

        <div *ngIf="!changeAttempt" class="text-right">
          <button (click)="confirmChange()" [disabled]="!newSelectedDate" mat-button type="button">
            {{'CONFIRM_SELECTIONS'|translate}}
            <span *ngIf="(changeDateReservation$|async)">
                <mat-icon class="spin">autorenew</mat-icon>
              </span>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
