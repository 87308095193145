import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpserviceService} from '../../../services/httpservice.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {SnackErrorComponent} from '../../../modules/errorNotification/snackerror/snack-error.component';
import {catchError, retry} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends HttpserviceService {
  private policyNotifications = 'policy/notifications';
  // roleListObj = any;
  constructor(
    public router: Router,
    public httpClient: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, httpClient, snackComponent);
  }

  getNotifications(): Observable<any> {
    const endPointUrl = this.apiUrl + this.policyNotifications + '?takeAll=true';
    return this.http
      .get<any>(endPointUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, false)),
      );
  }

  deleteNotifications(id): Observable<any> {
    const endPointUrl = this.policyNotifications + '/' + id;
    return this.http
      .get<any>(endPointUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }
}
