import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { LoginResponse } from '../classes/loggedProfile';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as pack from '../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // API path
  basePath: string;
  key: any;
  token: string;
  isexpired: boolean;
  decodedToken: any;
  expirationDate: any;
  helper = new JwtHelperService();
  app = pack;
  constructor(
    private router: Router,
    private http: HttpClient,
    private httpService: HttpserviceService,
  ) {
    this.basePath = httpService.setApiurl();
    this.key = `-----BEGIN PUBLIC KEY-----
    MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA5CJ4FYwaiMH0UZKUwaZG
    NCRLwexS4uMPcn95tfaaOR+pFsjUgo1ivxqcwsoGsPjDfAXnpxBkEWLL1fot7b4n
    ggsnvIgu4UrwliuimeN2SCBTC4S4WBq+dqhyKJtKmfxCF1yOq0elz7vWCi7HdjqR
    nVw+/pfzyiD/rJ2gjp8CJHPWEqa6JtNgpwQVv4ldaWh9qoF0Aab2M9dVoxpWggxq
    FK+VH98hjPLnfpb2b0KHxrkZogEKiB4tQ5RYh/2krLzclGMXY74DC7yJ9Oe2vcQd
    vBmiwHZYdD5uD/WjYqnza1I/4Gk136O9nbiS4WVhT8HQZGALtu8ijiW5UPhq2lh+
    Eip/qRnVJxb3mspr5pB2S9nH1c1Peiw853t2geNL7+F8lEdd0XNu7gblXipxHcnJ
    F/1H62pU5kRWMtC/yQ1MBDCZTktPTLBqemm/K+VCz8XYhcZPQYTaUoEYjmccMbfc
    IMaMzGJSkyOU/q+CEwFoBVDdurWU/SeLvHa2ml/LQV8teJ39K89zlkP1u1bs2FYZ
    Lu5UJJcweQj6I1zKQJ88V8ZHcUoRlU3KjObjwE+Kx9izJqk8qlP+A3ufyoSzPCaA
    sCmsoXDFx1gWSAm4GhDVX2+K6ju7Bsr7pHQav0nM1f/928yUU5w2D3devtu4knSg
    qkH6ixUq/wI1SQdALvDis10CAwEAAQ==
    -----END PUBLIC KEY-----
    `;
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-app-version': this.app.version,
    })
  };

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Username and password do not match, please try again');
  }

  // Verify user credentials on server to get token
  loginForm(data): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(this.basePath + 'auth', data, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  singleSignIn(data): Observable<any> {
    return this.http
    // tslint:disable-next-line:max-line-length
      .get<any>(this.basePath + 'single?accesKey=' + data.key + '&userId=' + data.userId + '&travelCode=' + data.dossierId + '&cid=' + data.travelerId + '&securitykey=' + data.key, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  forgotPassword(data): Observable<any> {
    const admHlUrl = this.basePath + 'forgot';
    return this.http
      .post<any>(admHlUrl, data, this.httpOptions)
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  setUserSignIn(resp: LoginResponse) {
    localStorage.setItem('fl_profile', JSON.stringify(resp.profile));
    localStorage.setItem('fl_access_token', resp.token);
  }

  // After login save token and other values(if any) in localStorage
  setUser(resp: LoginResponse) {
    localStorage.setItem('fl_profile', JSON.stringify(resp.profile));
    localStorage.setItem('fl_access_token', resp.token);
    if (resp.profile._id === '5fec90e2df2f4d0012c64cf1') {
      this.router.navigate(['/trains/search']);
    } else if (resp.profile._id === '604f240ec5ec740012a28f46') {
      this.router.navigate(['/flight/search']);
    } else if (!localStorage.getItem('fl_requestedUrl')) {
      this.router.navigate(['/dashboard']);
    } else if (localStorage.getItem('fl_requestedUrl')) {
      const url = localStorage.getItem('fl_requestedUrl');
      console.log('url =>', url);
      if(url.includes('login')){
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate([JSON.parse(url)]);
      }

    }
  }

  getUser(){
    return {
      token : localStorage.getItem('fl_access_token'),
      profile : JSON.parse(localStorage.getItem('fl_profile')),
    }
  }

  // Checking if token is set
  isLoggedIn() {
    if (!localStorage.getItem('fl_access_token')) {
      return false;
    } else {
      this.token = localStorage.getItem('fl_access_token');
      this.decodedToken = this.helper.decodeToken(this.token);
      this.expirationDate = this.helper.getTokenExpirationDate(this.token);
      this.isexpired = this.helper.isTokenExpired(this.token);
      // console.log('token expirationDate => ',this.expirationDate);
      // console.log('token expired? => ', this.isexpired)
      if (this.isexpired) {
        // this.router.navigate(['/login']);
        return false;
      } else {
        return true;
      }
    }

  }

  // After clearing localStorage redirect to login screen
  logout() {
    localStorage.removeItem('fl_access_token');
    localStorage.removeItem('fl_profile');
    localStorage.removeItem('fl_dossierId');
    localStorage.removeItem('fl_dossierName');
    localStorage.removeItem('dossierIsActive');
    localStorage.removeItem('sarceDossier');
    localStorage.removeItem('prev_fl_profile');
    localStorage.removeItem('prev_fl_access_token');
    localStorage.removeItem('fl_user_cp');
    localStorage.removeItem('pwdtmp');
    
    this.router.navigate(['/login']);
  }

  logoutFromSignIn() {
    localStorage.removeItem('fl_access_token');
    localStorage.removeItem('fl_profile');
  }

  // Get data from server for Dashboard
  getData(data): Observable<LoginResponse> {
    return this.http
      .put<LoginResponse>(this.basePath + 'auth', data, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }

  resetPassword(data): Observable<any> {
    return this.http
      .put<any>(this.basePath + 'forgot', data, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  getLatestVersion(){
    return this.http
       .get<any>(this.basePath + 'info', this.httpOptions)
       .pipe(
           retry(0),
           catchError(this.handleError)
       );
    }
}
