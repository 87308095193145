<div class="blockContainer">
    <div class="passwordChanger">
        <div class="subtitleChange mb-1 text-center">
            {{'PASSWORD_SCADUTA'|translate}}
        </div>
        
        <div class="subtitleChange mb-2 text-center">
            {{'PASSWORD_LINE_1'|translate}}
        </div>
        <hr/>
        <div class="mb-2 text-center">
            {{'PASSWORD_LINE_2'|translate}}
        </div>
        <hr/>
        <div class="mb-1">
            {{'PASSWORD_LINE_3'|translate}}
        </div>
        <div class="passRules">
            <div class="mb-1">
                <span class="vertical material-icons mr-1">east</span> <span class="vertical">{{'PASSWORD_LINE_4'|translate}}</span>
            </div>
            <div class="mb-1">
                <span class="vertical material-icons mr-1">east</span> <span class="vertical">{{'PASSWORD_LINE_5'|translate}}</span>
            </div>
            <div class="mb-1">
                <span class="vertical material-icons mr-1">east</span> <span class="vertical">{{'PASSWORD_LINE_6'|translate}}</span>
            </div>
            <div class="mb-1">
                <span class="vertical material-icons mr-1">east</span> <span class="vertical">{{'PASSWORD_LINE_7'|translate}}</span>
            </div>
            <div class="mb-1">
                <span class="vertical material-icons mr-1">east</span> <span class="vertical">{{'PASSWORD_LINE_8'|translate}}</span>
            </div>
            <div class="mb-1">
                <span class="vertical material-icons mr-1">east</span> <span class="vertical">{{'PASSWORD_LINE_9'|translate}}</span>
            </div>
        </div>
        
        <div class="mb-1">
            {{'PASSWORD_LINE_10'|translate}}
        </div>
        <hr/>
        <div class="formContent">
            <div class="mb-3">
                <mat-form-field class="w-100">
                    <mat-label><mat-icon>lock</mat-icon> {{'NEW_PASSWORD'|translate}}</mat-label>
                    <input matInput minlength="4" (ngModelChange)="checkStrength($event)" [(ngModel)]="password" maxlength="15" required [type]="hidePassword ? 'password' : 'text'">
                    <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                      <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    
                    <mat-hint align="end">{{password.length || 0}}/15 max</mat-hint>
    
                </mat-form-field>
                <div class="small text-danger" *ngIf="!password">
                    {{'ENTER_NEW_PASSWORD'|translate}}
                </div>
            </div>
            
            <div class="mb-4">
                <mat-form-field class="w-100">
                    <mat-label><mat-icon>lock</mat-icon> {{'CONFIRM_PASSWORD'|translate}}</mat-label>
                    <input matInput minlength="4" [(ngModel)]="confirmPassword" maxlength="15" required [type]="hideConfirmpassword ? 'password' : 'text'">
                    <button type="button" mat-icon-button matSuffix (click)="hideConfirmpassword = !hideConfirmpassword" [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hideConfirmpassword">
                        <mat-icon>{{hideConfirmpassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    
                    <mat-hint align="end">{{confirmPassword.length || 0}}/15 max</mat-hint>
    
                </mat-form-field>
                <div class="small text-danger" *ngIf="!samePassword()">
                    {{'PASSWORD_NOT_EQUAL'|translate}}
                </div>
            </div>
            <div *ngIf="error" class="small text-danger pt-3">{{'PASSWORD_NEED_TO_BE_CHANGED'|translate}}</div>
            <hr/>
            <div class="mb-2" *ngIf="password">
                <div>{{'PASSWORD_STRENGTH'|translate}}</div>
                <div class="clear">
                    <div class="floatPass" [ngStyle]="{'width': 100/(strength+1) +'%'}" [ngClass]="{'bgRed':strength >= 4, 'bgOrange':strength >= 1 && strength < 4, 'bgGreen':strength == 0}"></div>
                </div>
            </div>
            <div class="text-right">
                <button type="submit" [disabled]="!samePassword() || !confirmPassword || !password" (click)="update()" class="marginbottom5" mat-stroked-button color="primary">{{'SAVE'|translate}}</button>
            </div>
            
            
        </div>
    </div>
</div>