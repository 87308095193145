<!-- <app-pagetitle [title]="title"></app-pagetitle> -->
<div class="padding20">
  <mat-card appearance="outlined" class="marginbottom20">
    <p _ngcontent-jbj-c37="">
      <mat-icon class="marginright10" color="accent" role="img">
        info
      </mat-icon> For more information visit the official Angular Material <a href="https://material.angular.io/components/autocomplete/overview" target="_blank">autocomplete documentation</a>
    </p>
  </mat-card>

  <p>The autocomplete is a normal text input enhanced by a panel of suggested options.</p>
  <p>
    Start by adding a regular <code>matInput</code> to your template. Let's assume you're using the <code>formControl</code>
    directive from <code>ReactiveFormsModule</code> to track the value of the input.
  </p>

  <div class="row">
    <div class="col-lg-6">
      <div class="default_panel">
        <div class="default_heading">Autocomplete</div>
        <div class="default_content">
          <form class="example-form">
            <mat-form-field class="example-full-width">
              <input type="text"
                     placeholder="Pick one"
                     aria-label="Number"
                     matInput
                     [formControl]="myControl"
                     [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of options" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-6"></div>
  </div>
</div>


