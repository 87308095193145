import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../../../app.component';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TicketingService} from '../../../../services/ticketing/ticketing.service';

@Component({
  selector: 'app-ticketslist',
  templateUrl: './ticketslist.component.html',
  styleUrls: ['./ticketslist.component.scss']
})
export class TicketslistComponent implements OnInit {
  currentTab: number;
  ticketslist: any;
  @Input() page: string;
  loading: boolean;
  currentpage: number;
  maxpages: number;
  // setQuery
  query: any;
  limit: number;
  skip: number;
  sort: string;
  order: boolean;
  progress: boolean;
  text: string;
  displayedColumns = [];
  filterCompanies = [];
  pagenumbers = [];
  type: string;
  profile: any;
  types = ['all'];
  availableTypes: any = {'all':'all'};
  currentType: string;
  routeSub: any;
  departements: any[];
  sections: any[];
  jiraTkt: boolean = false;
  constructor(
    public appComponent: AppComponent,
    public mainComponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    private ticketService: TicketingService,
  ) {
    this.currentType = 'all';
    this.profile = this.mainComponent.loggedUser;
    this.currentTab = 1;
    this.loading = true;
    this.progress = false;
    this.type = '';
    if (this.profile.role.superAdmin) {
      this.type = 'operator';
    } else {
      this.type = 'user';
    }
  }

  getDepartements(): void {
    this.ticketService.getJiraDepartments().subscribe((res) => {
      // this.loading = false;
      this.departements = res.values;
      this.departements.forEach(d => {
        d.value = d.id;
      });
    }, error => {
      console.error('Error => ', error);
    });
  }
  getSections(): void {
    this.ticketService.getJiraSections().subscribe((res) => {
      // this.loading = false;
      this.sections = res.values;
      this.sections.forEach((s: any , i: number) => {
        s.value = s.id;
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  ngOnInit(): void {

    if (!this.appComponent.isSbt && !this.appComponent.isGat) {
      this.jiraTkt = true;
      this.getDepartements();
      this.getSections();
    }

    if (this.mainComponent.loggedUser.company.services.flight) {
      this.types.push('flight');
      this.availableTypes['flight'] = 'FLIGHT';
      this.currentType = 'flight';
    }
    if (this.mainComponent.loggedUser.company.services.hotel && !this.appComponent.isGat) {
      this.types.push('hotel');
      this.availableTypes['hotel'] = 'HOTEL.NAME';
    }
    if (this.mainComponent.loggedUser.company.services.car && !this.appComponent.isGat) {
      this.types.push('car');
      this.availableTypes['car'] = 'CAR';
    }
    if (this.mainComponent.loggedUser.company.services.train && !this.appComponent.isGat) {
      this.types.push('train');
      this.availableTypes['train'] = 'TRAIN';
    }

    this.displayedColumns = ['status', 'key', 'subject', 'user', 'date', 'update'];
    if (!this.jiraTkt) {
      if (!this.page) {
        this.limit = 10;
        this.displayedColumns = ['status', 'subject', 'user', 'date', 'update'];
      } else {
        this.limit = 5;
        this.displayedColumns = ['status', 'subject', 'user', 'update'];
      }
    } else {
      this.displayedColumns = ['status', 'key', 'subject', 'created', 'department'];
      if (!this.page) {
        this.limit = 10;
      } else {
        this.limit = 5;
      }
    }

    this.sort = 'createdAt';
    this.order = true;
    this.currentpage = 0;
    this.skip = (this.currentpage) * this.limit;

    this.query = {
      options: {
        company: '',
        user: '',
        viewAll: false,
      },
      sort: this.sort,
      limit: this.limit,
      skip: this.skip,
      order: this.order ? 'asc' : 'desc',
      text: '',
    };
    this.launchCall();
  }

  launchCall() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (!this.page) {
      this.routeSub = this.route.queryParams.subscribe(params => {
        this.query.sort = params.sort || this.sort;
        this.query.limit = params.limit || this.limit;
        this.query.skip = params.skip || this.skip;
        this.query.order = params.order || 'asc';
        this.query.text = params.text || '';
        this.query.options = (params.options) ? JSON.parse(params.options) : this.query.options;
        this.text = params.text || '';
        this.currentpage = this.query.skip / this.query.limit;
        if (this.currentType === 'all') {
          if (this.jiraTkt) {
            this.getJiraTicketsList();
          } else {
            this.getTicketsList();
          }
        } else if (this.currentType === 'flight') {
          // this.getFlightTicketsList();
          if (this.jiraTkt) {
            this.getFlightJiraTicketsList();
          } else {
            this.getFlightTicketsList();
          }
        } else if (this.currentType === 'hotel') {
          if (this.jiraTkt) {
            this.getHotelJiraTicketsList();
          } else {
            this.getHotelTicketsList();
          }
        }  else if (this.currentType === 'car') {
          if (this.jiraTkt) {
            this.getCarJiraTicketsList();
          } else {
            this.getCarTicketsList();
          }
        } else if (this.currentType === 'train') {
          if (this.jiraTkt) {
            this.getTrainJiraTicketsList();
          } else {
            this.getTrainTicketsList();
          }
        }
      });
    } else {
      this.navigate();
    }
  }

  resetQuery() {
    this.query = {
      options: {
        company: '',
        user: '',
      },
      sort: 'createdAt',
      limit: this.limit,
      skip: 0,
      order: 'asc',
      text: '',
    };
  }

  sortValue(value) {
    this.query.sort = value;
    this.sort = value;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  reverseOrder() {
    if (!this.order) {
      this.query.order = 'asc';
      this.order = true;
    } else {
      this.query.order = 'desc';
      this.order = false;
    }
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  setFilterText() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }
  clearFilterText() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.query.text = '';
    this.navigate();
  }

  navigate() {
    if (this.page) {
      if (this.currentType === 'all') {
        if (this.jiraTkt) {
          this.getJiraTicketsList();
        } else {
          this.getTicketsList();
        }
      } else if (this.currentType === 'flight') {
        console.log(this.jiraTkt);
        if (this.jiraTkt) {
          this.getFlightJiraTicketsList();
        } else {
          this.getFlightTicketsList();
        }
      } else if (this.currentType === 'hotel') {
        if (this.jiraTkt) {
          this.getHotelJiraTicketsList();
        } else {
          this.getHotelTicketsList();
        }
      }  else if (this.currentType === 'car') {
        if (this.jiraTkt) {
          this.getCarJiraTicketsList();
        } else {
          this.getCarTicketsList();
        }
      } else if (this.currentType === 'train') {
        if (this.jiraTkt) {
          this.getTrainJiraTicketsList();
        } else {
          this.getTrainTicketsList();
        }
      }
    } else {
      this.query.options = JSON.stringify(this.query.options);
      this.router.navigate([], {
        queryParams: this.query,
      });
    }
  }

  next() {
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev() {
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value) {
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  getTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getTicketsList(this.query).subscribe((res) => {
      this.loading = false;
      this.ticketslist = res;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((this.ticketslist.count / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getJiraTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getJiraTicketsList(this.query).subscribe((res) => {
      this.ticketslist = {};
      this.ticketslist.list = [];
      this.ticketslist.list = res.issues;
      this.loading = false;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((res.total / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getFlightTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getFlightTicketsList(this.query).subscribe((res) => {
      this.loading = false;
      this.ticketslist = res;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((this.ticketslist.count / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getFlightJiraTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getFlightJiraTicketsList(this.query).subscribe((res) => {
      this.ticketslist = {};
      this.ticketslist.list = [];
      this.ticketslist.list = res.issues;
      this.loading = false;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((res.total / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getHotelJiraTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getHotelJiraTicketsList(this.query).subscribe((res) => {
      this.ticketslist = {};
      this.ticketslist.list = [];
      this.ticketslist.list = res.issues;
      this.loading = false;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((res.total / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getCarJiraTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getCarJiraTicketsList(this.query).subscribe((res) => {
      this.ticketslist = {};
      this.ticketslist.list = [];
      this.ticketslist.list = res.issues;
      this.loading = false;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((res.total / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getTrainJiraTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getTrainJiraTicketsList(this.query).subscribe((res) => {
      this.ticketslist = {};
      this.ticketslist.list = [];
      this.ticketslist.list = res.issues;
      this.loading = false;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((res.total / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getGroupJiraTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getGroupJiraTicketsList(this.query).subscribe((res) => {
      this.ticketslist = {};
      this.ticketslist.list = [];
      this.ticketslist.list = res.issues;
      this.loading = false;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((res.total / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getMinorJiraTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getMinorJiraTicketsList(this.query).subscribe((res) => {
      this.ticketslist = {};
      this.ticketslist.list = [];
      this.ticketslist.list = res.issues;
      this.loading = false;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((res.total / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getGeneralJiraTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getGeneralJiraTicketsList(this.query).subscribe((res) => {
      this.ticketslist = {};
      this.ticketslist.list = [];
      this.ticketslist.list = res.issues;
      this.loading = false;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((res.total / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getHotelTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getHotelTicketsList(this.query).subscribe((res) => {
      this.loading = false;
      this.ticketslist = res;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((this.ticketslist.count / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getCarTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getCarTicketsList(this.query).subscribe((res) => {
      this.loading = false;
      this.ticketslist = res;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((this.ticketslist.count / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getTrainTicketsList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.ticketService.getTrainTicketsList(this.query).subscribe((res) => {
      this.loading = false;
      this.ticketslist = res;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((this.ticketslist.count / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  changeType(value) {
    this.resetQuery();
    this.currentpage = 0;
    this.currentType = value;
    this.launchCall();
  }

  /**
   * Given subject remove initial text (FLYLEO ticket n°)
   * @params value
   */
  truncateObj(value) {
    const totaltitle = value;
    const index = totaltitle.indexOf(':');
    const newtitle = totaltitle.substr(index + 1);
    return newtitle;
  }

}
