import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {RoleService} from '../../../../../services/role/role.service';
import {MainComponent} from '../../../../mainmodule/main/main.component';
import {UsersList} from '../../../../../classes/userlist';
import {UsersService} from '../../../../../services/profile/users.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-companyroles',
  templateUrl: './companyroles.component.html',
  styleUrls: ['./companyroles.component.scss']
})
export class CompanyrolesComponent implements OnInit {
  displayedColumns: string[] = ['selection', 'user'];
  obs: Observable<any>;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() company: string;
  roles: any;
  loading: boolean;
  newName: string;
  activeRole: any;
  loadingRole: boolean;
  viewRole: boolean;
  query: any;
  usersList: any;
  userToPass: any;
  constructor(
    private roleService: RoleService,
    public maincomponent: MainComponent,
    private usersService: UsersService,
  ) {
    this.newName = '';
    this.query = {
      sort: 'createdAt',
      limit: 4000,
      skip: 0,
      order: 'desc',
      text: '',
    };
    this.userToPass = [];
  }

  ngOnInit(): void {

    this.getRolesList();
  }

  getRolesList() {
    this.loading = true;
    this.roleService.getRoles(this.company).subscribe((res) => {
      this.roles = res;
      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  addRole() {
    this.loading = true;
    const body = {
      name: this.newName,
      company: this.company
    };
    this.roleService.addRole(body).subscribe((res) => {
      this.newName = '';
      this.getRolesList();
      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  openRole(element) {
    this.loadingRole = true;
    this.roleService.getRole(element._id).subscribe((res) => {
      this.viewRole = true;
      this.activeRole = res;
      if(!this.activeRole.train){
        this.activeRole.train = {
          search : {
            get : {
              company : false,
                link : false,
            },
            post : {
              company : false,
                link : false,
                options : {
                note : false,
              },
            },
            delete : {
              company : false,
                link : false,
            },
            put : {
              options : {
                class : [],
              },
            },
          },
          taw : {
            get : {
              company : false,
                link : false,
            },
            post : {
              company : false,
                link : false,
                options : {
                link : false,
                  issue : false,
                  creditCard : [],
              },
            },
            delete : {
              company : false,
                link : false,
                options : {
                linked : false,
                  issued : false,
              },
            },
          },
        }
      }
      this.loadingRole = false;
      this.getUsersList();
    }, error => {
      this.loadingRole = false;
      console.error('Error => ', error);
    });
    this.activeRole = element;
  }

  editRole() {
    const body = this.activeRole;
    this.roleService.editRole(body._id, body).subscribe((res) => {
      this.getRolesList();
      this.viewRole = false;
      delete this.activeRole;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  closeRole() {
    this.viewRole = false;
    delete this.activeRole;
    this.userToPass = [];
  }

  changeFlightChrono() {
    if (this.activeRole.flight.search.get.company === false) {
      this.activeRole.flight.search.post.company = false;
      this.activeRole.flight.search.delete.company = false;
    }
  }

  changeUser() {
    if (this.activeRole.profile.user.get.company === false) {
      this.activeRole.profile.user.post.company = false;
    }
  }
  changeCompany() {
    if (this.activeRole.profile.company.get.company === false) {
      this.activeRole.profile.company.post.company = false;
    }
  }
  changeCorporate() {
    if (this.activeRole.profile.company.corporate.read === false) {
      this.activeRole.profile.company.corporate.post = false;
      this.activeRole.profile.company.corporate.put = false;
      this.activeRole.profile.company.corporate.delete = false;
    }
  }
  changeTickets() {
    if (this.activeRole.isTktOperator === false) {
      this.activeRole.tktZones.office = false;
      this.activeRole.tktZones.tech = false;
    }
  }

  changeUserB2c(event){
    this.activeRole.customer = event;
  }

  getUsersList() {
    this.usersService.getUsersListCp(this.query, this.company).subscribe((res: UsersList) => {
      this.usersList = res;
      this.usersList.list.forEach( user => {
        user.selected = false;
        if (this.activeRole.users.some(s => user._id === s._id)) {
          /* vendors contains the element we're looking for */
          user.selected = true;
          this.userToPass.push(user._id);
          console.log(user);
        }
      });
      this.dataSource = new MatTableDataSource(this.usersList.list);
      console.log('this.usersList => ', this.usersList);
      this.dataSource.paginator = this.paginator;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
      this.obs = this.dataSource.connect();
    }, error => {
      console.error('Error => ', error);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  manageUser(event) {
    const index = this.userToPass.findIndex( u => u === event);
    if ( index !== -1) {
      this.userToPass.splice(index, 1);
    } else {
      this.userToPass.push(event);
    }
    console.log(this.userToPass);
  }

  saveUsers() {
    const body = this.activeRole;
    this.roleService.manageUsersRole(body._id, this.userToPass).subscribe((res) => {
      this.getRolesList();
      this.viewRole = false;
      this.userToPass = [];
      delete this.activeRole;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }
}
