import {
  Component,
} from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'error-notifier',
  templateUrl: './snack-error.component.html',
  styleUrls: ['./snack-error.component.scss'],
})
export class SnackErrorComponent{
  private readonly notifier: NotifierService;

  constructor(
    public translate: TranslateService,
    notifierService: NotifierService,
  ) {
    this.notifier = notifierService;
  }

  openSnackBar(message: string, errorType: string = 'error') {
    this.notifier.notify(errorType, this.translate.instant(message));
  }

}
