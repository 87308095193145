/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { FeesCompanyLinkRequestDto } from '../models/FeesCompanyLinkRequestDto';
import { FeesGetResponseDto } from '../models/FeesGetResponseDto';
import { FeesListResponseDto } from '../models/FeesListResponseDto';
import { FeesUpsertRequestDto } from '../models/FeesUpsertRequestDto';
import { PaginationOrder } from '../models/PaginationOrder';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class FeesService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all fees
     * @param limit
     * @param skip
     * @param text
     * @param order
     * @param sort
     * @returns FeesListResponseDto
     * @throws ApiError
     */
    public feesControllerList(
        limit: number = 20,
        skip?: number,
        text?: number,
        order?: PaginationOrder,
        sort?: string,
    ): Observable<FeesListResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/transaction/fees',
            query: {
                'limit': limit,
                'skip': skip,
                'text': text,
                'order': order,
                'sort': sort,
            },
        });
    }

    /**
     * Create new fee
     * @param requestBody
     * @returns FeesGetResponseDto
     * @throws ApiError
     */
    public feesControllerCreate(
        requestBody: FeesUpsertRequestDto,
    ): Observable<FeesGetResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v3/transaction/fees',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get fee by id
     * @param id
     * @returns FeesGetResponseDto
     * @throws ApiError
     */
    public feesControllerGet(
        id: string,
    ): Observable<FeesGetResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/transaction/fees/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Delete fee
     * @param id
     * @returns FeesGetResponseDto
     * @throws ApiError
     */
    public feesControllerDelete(
        id: string,
    ): Observable<FeesGetResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v3/transaction/fees/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update fee
     * @param id
     * @param requestBody
     * @returns FeesGetResponseDto
     * @throws ApiError
     */
    public feesControllerUpdate(
        id: string,
        requestBody: FeesUpsertRequestDto,
    ): Observable<FeesGetResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v3/transaction/fees/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get my fees
     * @returns FeesGetResponseDto
     * @throws ApiError
     */
    public feesControllerGetMyFees(): Observable<FeesGetResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/transaction/fees/mine',
        });
    }

    /**
     * Link fees to company
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public feesControllerLinkFeesToCompany(
        id: string,
        requestBody: FeesCompanyLinkRequestDto,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v3/transaction/fees/{id}/link',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

  /**
   * Link fees to company
   * @param id
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public feesControllerUnlinkFeesToCompany(
    id: string,
    requestBody: FeesCompanyLinkRequestDto,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'PUT',
      url: '/api/v3/transaction/fees/{id}/unlink',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

    /**
     * Get all fees for company
     * @param id
     * @param limit
     * @param skip
     * @param text
     * @param order
     * @param sort
     * @returns FeesListResponseDto
     * @throws ApiError
     */
    public feesControllerListForCompany(
        id: string,
        limit: number = 20,
        skip?: number,
        text?: number,
        order?: PaginationOrder,
        sort?: string,
    ): Observable<FeesListResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/transaction/fees/company/{id}',
            path: {
                'id': id,
            },
            query: {
                'limit': limit,
                'skip': skip,
                'text': text,
                'order': order,
                'sort': sort,
            },
        });
    }

}
