import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material/material.module';
import { YearsComponent } from './components/years/years.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {RouterModule} from '@angular/router';
import { GeneralComponent } from './components/general/general.component';
import { CompaniesStatsComponent } from './components/companies-stats/companies-stats.component';
import { CustomCommonModule } from '../common/custom-common.module';

@NgModule({
  declarations: [
    YearsComponent,
    GeneralComponent,
    CompaniesStatsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    NgApexchartsModule,
    RouterModule,
    CustomCommonModule,
  ],
})
export class StatisticModule { }
