import {Component, OnInit} from '@angular/core';
import {TravelPolicyService} from '../../services/travel-policy/travel-policy.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-policyrequests',
  templateUrl: './policyrequests.component.html',
  styleUrls: ['./policyrequests.component.scss']
})
export class PolicyrequestsComponent implements OnInit {
  requestsForActiveUser = [];
  userData: any = {};
  constructor(
    private snackBar: MatSnackBar,
    private policyService: TravelPolicyService,
  ) { }

  ngOnInit(): void {
    // this.userData = this.storageService.get('currentUser');
    this.policyService.getAuthorizations().pipe(take(1)).subscribe(res => {
      this.requestsForActiveUser = res.data;
    });
  }

  approve(authorization, i): void {
    this.policyService.approve(authorization._id).subscribe((res) => {
      this.requestsForActiveUser[i].status = 'approved';
      this.snackBar.open('Authorization approved successfully!', 'Success', {
        duration: 2000,
      });
    });
  }

  decline(authorization, i): void {
    this.policyService.decline(authorization._id).subscribe((res) => {
      this.requestsForActiveUser[i].status = 'declined';
      this.snackBar.open('Authorization declined successfully!', 'Success', {
        duration: 2000,
      });
    });
  }

}
