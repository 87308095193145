import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { MainComponent } from './modules/mainmodule/main/main.component';
import { AutocompleteComponent } from './modules/theme-components/theme/theme-components/autocomplete/autocomplete.component';
import { LoginComponent } from './modules/login/login/login.component';
import { ListComponent } from './modules/users/components/usercopo/list/list.component';
import { UserComponent } from './modules/users/components/usercopo/user/user.component';
import { ListCompanyComponent } from './modules/companies/components/companiescompo/list/list.component';
import { CompanyComponent } from './modules/companies/components/companiescompo/company/company.component';
import { NotificationsComponent } from './modules/notification/notifications/pages/notifications.component';
import { OfflineRequestsComponent } from './modules/offline-requests/offline-requests/offline-requests.component';
import { SearchflightComponent } from './modules/flight/components/flight/searchflight/searchflight.component';
import { SinglepnrComponent } from './modules/flight/components/flight/singlepnr/singlepnr.component';
import { TravelerslistComponent } from './modules/travelers/components/travelers/travelerslist/travelerslist.component';
import { ReservationlistComponent } from './modules/flight/components/flight/reservationlist/reservationlist.component';
import { DashboardcpComponent } from './modules/dashboard/dashboardcp/dashboardcp.component';
import { TicketslistComponent } from './modules/ticketing/components/ticketslist/ticketslist.component';
import { SingleticketComponent } from './modules/ticketing/components/singleticket/singleticket.component';
import { SearchComponent } from './modules/car/carcomponent/search/search.component';
import { CarlistComponent } from './modules/car/carcomponent/carlist/carlist.component';
import { DossierlistComponent } from './modules/dossier/components/dossierlist/dossierlist.component';
import { SingledossierComponent } from './modules/dossier/components/singledossier/singledossier.component';
import { OfflineListComponent } from './modules/offline-requests/list/list.component';
import { LoginAsComponent } from './modules/users/components/login-as/login-as.component';
import { SigninComponent } from './modules/singlesingin/signin/signin.component';
import { FinalizelowcostComponent } from './modules/flight/components/flight/finalizelowcost/finalizelowcost.component';
import { FlightcrypticComponent } from './modules/adminarea/components/flightcryptic/flightcryptic.component';
import { AddProfileComponent } from './modules/adminarea/components/add-profile/add-profile.component';
import { CostcenterComponent } from './modules/travelers/components/costcenter/costcenter.component';
import { ChecklowcostpaymentComponent } from './modules/flight/components/flight/finalizelowcost/checklowcostpayment/checklowcostpayment.component';
import { CarreservationComponent } from './modules/car/carcomponent/carreservation/carreservation.component';
import { SellingPlatformComponent } from './modules/adminarea/components/selling-platform/selling-platform.component';
import { YearsComponent } from './modules/statistic/components/years/years.component';
import { GeneralComponent } from './modules/statistic/components/general/general.component';
import { CompaniesStatsComponent } from './modules/statistic/components/companies-stats/companies-stats.component';
import { ManageIataComponent } from './modules/adminarea/components/manage-iata/manage-iata.component';
import { PolicyComponent } from './components/policy/policy.component';
import { TravelPolicyFormComponent } from './components/policy/travel-policy-form/travel-policy-form.component';
import { AllGroupsComponent } from './components/groups/containers/all-groups/all-groups.component';
import { AddGroupComponent } from './components/groups/containers/add-group/add-group.component';
import { EditGroupComponent } from './components/groups/containers/edit-group/edit-group.component';
import { AllUserGroupsComponent } from './modules/user-groups/containers/all-user-groups/all-user-groups.component';
import { BlockIssuangeRatesFlightComponent } from './modules/adminarea/components/block-issuange-rates-flight/block-issuange-rates-flight.component';
import { HistoryComponent } from './modules/flight/components/flight/history/history.component';
import { ImportpnrComponent } from './modules/adminarea/components/importpnr/importpnr.component';
import { SetnewpasswordComponent } from './modules/login/setnewpassword/setnewpassword.component';
import { RouterContainerComponent } from './modules/common/components/router-container/router-container.component';
import { GroupRequestsComponent } from './modules/requests/components/group-requests/group-requests.component';
import { UnaccompaniedMinorsComponent } from './modules/requests/components/unaccompanied-minors/unaccompanied-minors.component';
import {IncentivesComponent} from './modules/adminarea/components/incentives/incentives.component';
import {ManageAirportsComponent} from './modules/adminarea/components/manage-airports/manage-airports.component';
import {UsersGroupBulkComponent} from './modules/users-group-bulk/users-group-bulk/users-group-bulk.component';
import {PolicyrequestsComponent} from './components/policyrequests/policyrequests.component';
import {AllAuthorizationRequestsComponent} from './components/authorization-requests/containers/all-authorization-requests/all-authorization-requests.component';
import {AllSearchesComponent} from './components/searches/containers/all-searches/all-searches.component';
import {GenericFeesListComponent} from './modules/fees/generic-fees-list/generic-fees-list.component';
import { DashboardVersion2Component } from './modules/dashboard/dashboard-version2/dashboard-version2.component';
import { ChangePasswordComponent } from './modules/users/components/change-password/change-password.component';

const routes : Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'LOGIN' },
  },
  {
    path: 'newpassword',
    component: SetnewpasswordComponent,
    data: { title: 'NEW_PASSWORD' },
  },
  {
    path: 'singlesignin',
    component: SigninComponent,
    data: { title: 'LOGIN' },
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'loginAs',
        component: LoginAsComponent,
        data: { title: 'LOGIN_AS' },
        canActivate: [AuthGuard],
      },
      {
        path: 'dashboard',
        component: DashboardVersion2Component,
        data: { title: 'DASHBOARD' },
        canActivate: [AuthGuard],
      },
      {
        path: 'theme-components/autocomplete',
        component: AutocompleteComponent,
        data: { title: 'AUTOCOMPLETE' },
        canActivate: [AuthGuard],
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        data: { title: 'NOTIFICATIONS' },
        canActivate: [AuthGuard],
      },
      {
        path: 'flight',
        component: RouterContainerComponent,
        data: { title: 'FLIGHT' },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'search',
            component: SearchflightComponent,
            data: { title: 'SEARCH_FLIGHT' },
            canActivate: [AuthGuard],
          },
          {
            path: 'reservations',
            component: ReservationlistComponent,
            data: { title: 'PNR_LIST' },
            canActivate: [AuthGuard],
          },
          {
            path: 'reservations/:id',
            component: SinglepnrComponent,
            data: { title: 'RESERVATION' },
            canActivate: [AuthGuard],
          },
          {
            path: 'reservations/:id/check-3ds',
            component: SinglepnrComponent,
            data: { title: 'RESERVATION' },
            canActivate: [AuthGuard],
          },
          {
            path: 'processLowCost',
            component: FinalizelowcostComponent,
            data: { title: 'RESERVATION' },
            canActivate: [AuthGuard],
          },
          {
            path: 'checklowcost/:pnr/:id',
            component: ChecklowcostpaymentComponent,
            data: { title: 'RESERVATION' },
            canActivate: [AuthGuard],
          },
          {
            path: 'history',
            component: HistoryComponent,
            data: { title: 'HISTORY' },
            canActivate: [AuthGuard],
          },

        ],
      },
      {
        path: 'hotel',
        data: { title: 'HOTEL.NAME' },
        loadChildren: () => import('./modules/hotel/hotel.module').then(m => m.HotelModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'dossier',
        component: RouterContainerComponent,
        data: { title: 'DOSSIER' },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: DossierlistComponent,
            data: { title: 'DOSSIER_LIST' },
            canActivate: [AuthGuard],
          },
          {
            path: 'list/:id',
            component: SingledossierComponent,
            data: { title: 'DOSSIER' },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'trains',
        data: { title: 'TRAIN' },
        loadChildren: () => import('./modules/trains/trains.module').then(m => m.TrainsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'car-rent',
        component: RouterContainerComponent,
        data: { title: 'CAR_RENT' },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'search',
            component: SearchComponent,
            data: { title: 'CAR_SEARCH' },
            canActivate: [AuthGuard],
          },
          {
            path: 'reservations',
            component: CarlistComponent,
            data: { title: 'CAR_RESERVATION_LIST' },
            canActivate: [AuthGuard],
          },
          {
            path: 'reservations/:id',
            component: CarreservationComponent,
            data: { title: 'CAR_RESERVATION' },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'travellers',
        component: RouterContainerComponent,
        data: { title: 'PASSENGERS' },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: TravelerslistComponent,
            data: { title: 'TRAVELLERS_LIST' },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'companies',
        component: RouterContainerComponent,
        data: { title: 'COMPANIES' },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListCompanyComponent,
            data: { title: 'COMPANIES_LIST' },
            canActivate: [AuthGuard],
          },
          {
            path: 'list/:id',
            component: CompanyComponent,
            data: { title: 'COMPANY_PROFILE' },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'users',
        component: RouterContainerComponent,
        data: { title: 'USERS' },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListComponent,
            data: { title: 'USERS_LIST' },
            canActivate: [AuthGuard],
          },
          {
            path: 'list/:id',
            component: UserComponent,
            data: { title: 'USERS_PROFILE' },
            canActivate: [AuthGuard],
          },
          {
            path: 'list/:id/change-password',
            component: ChangePasswordComponent,
            data: { title: 'CHANGE_PASSWORD' },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'offline-requests',
        component: RouterContainerComponent,
        data: { title: 'OFFLINE_REQUESTS' },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'open-request',
            component: OfflineRequestsComponent,
            data: { title: 'OPENING_TICKET' },
            canActivate: [AuthGuard],
          },
          {
            path: 'request-list',
            component: OfflineListComponent,
            data: { title: 'REQUESTS_LIST' },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'requests',
        data: { title: 'REQUESTS' },
        loadChildren: () => import('./modules/requests/requests.module').then(m => m.RequestsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'ticketing',
        component: RouterContainerComponent,
        data: { title: 'TICKETING' },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: TicketslistComponent,
            data: { title: 'TICKETS_LIST' },
            canActivate: [AuthGuard],
          },
          {
            path: 'list/:id',
            component: SingleticketComponent,
            data: { title: 'TICKET_DETAILS' },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'adminArea',
        component: RouterContainerComponent,
        data: { title: 'ADMIN_AREA' },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'flightCryptic',
            component: FlightcrypticComponent,
            data: { title: 'FLIGHT_CRYPTIC' },
            canActivate: [AuthGuard],
          },
          {
            path: 'addProfile',
            component: AddProfileComponent,
            data: { title: 'ADD_PROFILE' },
            canActivate: [AuthGuard],
          },
          {
            path: 'selling-platform',
            component: SellingPlatformComponent,
            data: { title: 'SELLING_PLATFORM' },
            canActivate: [AuthGuard],
          },
          {
            path: 'manage-iata',
            component: ManageIataComponent,
            data: { title: 'MANAGE_IATA' },
            canActivate: [AuthGuard],
          },
          {
            path: 'manage-airports',
            component: ManageAirportsComponent,
            data: { title: 'MANAGE_AIRPORTS' },
            canActivate: [AuthGuard],
          },
          {
            path: 'manage-blocked-flight-rates',
            component: BlockIssuangeRatesFlightComponent,
            data: { title: 'MANAGE_ISSUE_FARE_FLIGHT' },
            canActivate: [AuthGuard],
          },
          {
            path: 'import-pnr',
            component: ImportpnrComponent,
            data: { title: 'IMPORT_PNR' },
            canActivate: [AuthGuard],
          },
          {
            path: 'incentives',
            component: IncentivesComponent,
            data: { title: 'MANAGE_INCENTIVES' },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'statistic',
        component: RouterContainerComponent,
        data: { title: 'STATISTIC' },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'general',
            component: GeneralComponent,
            data: { title: 'GENERAL_STATS' },
            canActivate: [AuthGuard],
          },
          {
            path: 'year',
            component: YearsComponent,
            data: { title: 'YEAR_STATS' },
            canActivate: [AuthGuard],
          },
          {
            path: 'companies-stats',
            component: CompaniesStatsComponent,
            data: { title: 'COMPANIES_STATS' },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'costcenter',
        component: CostcenterComponent,
        data: { title: 'COST_CENTER' },
        canActivate: [AuthGuard],
      },
      {
        path: 'policies',
        component: PolicyComponent,
        data: { title: 'TRAVEL_POLICIES_ROLES' },
        canActivate: [AuthGuard],
      },
      {
        path: 'policy/conditions/:policyId',
        component: TravelPolicyFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'allgroups',
        component: AllGroupsComponent,
        data: { title: 'TRAVEL_POLICIES_GROUPS' },
        canActivate: [AuthGuard],
      },
      {
        path: 'add',
        component: AddGroupComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'edit/:groupId',
        component: EditGroupComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'usergroups',
        component: AllUserGroupsComponent,
        data: { title: 'USERS_GROUPS' },
        canActivate: [AuthGuard],
      },
      {
        path: 'usergroupsbulk',
        component: UsersGroupBulkComponent,
        data: { title: 'USERS_GROUPS' },
        canActivate: [AuthGuard],
      },
      {
        path: 'policy-requests',
        component: AllAuthorizationRequestsComponent,
        data: { title: 'REQUESTS' },
        canActivate: [AuthGuard],
      },
      {
        path: 'policy-searches',
        component: AllSearchesComponent,
        data: { title: 'SEARCH_REQUESTS' },
        canActivate: [AuthGuard],
      },
      {
        path: 'fees',
        component: GenericFeesListComponent,
        data: { title: 'FEES' },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule{}
