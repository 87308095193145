import {Injectable} from '@angular/core';
import {HttpserviceService} from '../httpservice.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SearchRes} from '../../classes/flights/search-res';
import {catchError, retry} from 'rxjs/operators';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class IatacompleteService extends HttpserviceService {
  flightUrl: string;
  airports: string[];
  continents: object;
  carriers: string[];
  airportsToComplete = new Set();
  carriersToComplete = new Set();
  carriersOptions: any[];

  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
    private translateService: TranslateService
  ) {
    super(router, http, snackComponent);
    this.flightUrl = this.apiUrl + 'flight/';
    this.carriers = [];
    this.airports = [];
    this.carriersOptions = [];
  }

  getAirports(data): Observable<any> {
    const airportUrl = this.apiUrl + 'iatabase/airport/names';
    return this.http
      .post<any>(airportUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getCarriers(data): Observable<any> {
    const carriersUrl = this.apiUrl + 'iatabase/airline/names';
    return this.http
      .post<any>(carriersUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getContinents(data) : Observable<any>{
    const carriersUrl = this.apiUrl + 'iatabase/airport/continents';
    return this.http
               .post<any>(
                 carriersUrl,
                 data,
                 this.setHttpOptions(),
               ).pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }

  getCarriersOptions(data): Observable<any> {
    const carriersUrl = this.flightUrl + 'airline/options';
    return this.http
      .post<any>(carriersUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  setContinents(value): void {
    this.continents = value;
    console.log('this.continents => ', this.continents);
  }

  setAirports(value): void {
    this.airports = value;
    console.log('this.airports => ', this.airports);
  }

  setCarriers(value): void {
    this.carriers = value;
    console.log('this.carriers => ', this.carriers);
  }

  setCarriersOptions(value): void {
    this.carriersOptions = value;
    console.log('this.carriers => ', this.carriersOptions);
  }

  returnAirport(value) {
    if (this.airports[value]) {
      return this.airports[value];
    } else {
      /*const airport = [value];
      this.getAirports(airport).subscribe((res) => {
        this.setAirports(res);
        return this.airports[value];
      }, error => {
        console.error(error);
      });*/
      return 'loading...';
    }
  }
  returnCarrier(value) {
    if (this.carriers[value]) {
      return this.carriers[value];
    } else {
      return 'loading...';
    }
  }

  returnCarrierOPT(value){
    console.log(' => ', this.carriersOptions[value]);
    return this.carriersOptions[value];
  }

  returnCarrierOPTPassport(value,itineraries) {
    const airports = new Set();
    const continents = new Set();
    let result = false;// any = 'loading...' // why loading as default?

    itineraries.forEach(i=>
      i.flights.forEach(f=>{
        airports.add(f.arrival.location);
        airports.add(f.departure.location);
      })
    )
    for(const airport of Array.from(airports)){
      continents.add(this.continents[airport+''])
    }
    if(continents.size>1){
      result = true;
    } else if (this.carriersOptions[value]) {
      result =  this.carriersOptions[value].passport || false;
    }
    return result;
  }

  createToComplete(value) {
    value.itineraries.forEach((itinerary) => {
      itinerary.sections.forEach((section) => {
        section.flights.forEach((flight) => {
          this.airportsToComplete.add(flight.departure.location);
          this.airportsToComplete.add(flight.arrival.location);
          this.carriersToComplete.add(flight.marketingCarrier);
          this.carriersToComplete.add(flight.operatingCarrier);
        });
      });
    });
    console.log('this.airportsToComplete => ', this.airportsToComplete);
    console.log('this.carriersToComplete => ', this.carriersToComplete);
    const airportsBody = {
      iata: Array.from(this.airportsToComplete),
    };
    const carriersBody = {
      iata: Array.from(this.carriersToComplete),
    };
    this.getContinents(airportsBody).subscribe((res) => {
      this.setContinents(res);
    }, error => {
      console.error(error);
    });
    this.getAirports(airportsBody).subscribe((res) => {
      this.setAirports(res);
    }, error => {
      console.error(error);
    });
    this.getCarriers(carriersBody).subscribe((res) => {
      this.setCarriers(res);
    }, error => {
      console.error(error);
    });
    this.getCarriersOptions(carriersBody).subscribe((res) => {
      this.setCarriersOptions(res);
    }, error => {
      console.error(error);
    });
  }

  createToCompletePrnList(value) {
    value.list.forEach((recom) => {
      this.carriersToComplete.add(recom.marketingCarrier);
      recom.itineraries.forEach((itinerary) => {
        itinerary.flights.forEach((flight) => {
          this.airportsToComplete.add(flight.departure.location);
          this.airportsToComplete.add(flight.arrival.location);
        });
      });
    });
    console.log('this.airportsToComplete => ', this.airportsToComplete);
    console.log('this.carriersToComplete => ', this.carriersToComplete);
    const airportsBody = {
      iata: Array.from(this.airportsToComplete),
    };
    const carriersBody = {
      iata: Array.from(this.carriersToComplete),
    };
    this.getAirports(airportsBody).subscribe((res) => {
      this.setAirports(res);
    }, error => {
      console.error(error);
    });
    this.getCarriers(carriersBody).subscribe((res) => {
      this.setCarriers(res);
    }, error => {
      console.error(error);
    });
  }

  createToCompletePrnHistory(value) {
    console.log('this.value => ', value);
    value.list.forEach((recom) => {
      recom.sections.forEach((itinerary) => {
        itinerary.from.forEach((flight) => {
          this.airportsToComplete.add(flight);
        });
        itinerary.to.forEach((flight) => {
          this.airportsToComplete.add(flight);
        });
      });
    });
    const airportsBody = {
      iata: Array.from(this.airportsToComplete),
    };
    this.getAirports(airportsBody).subscribe((res) => {
      this.setAirports(res);
    }, error => {
      console.error(error);
    });
    // console.log('this.airportsToComplete => ', this.airportsToComplete);
  }

  createToCompletePrn(value) {
    this.carriersToComplete.add(value.marketingCarrier);
    value.itineraries.forEach((itinerary) => {
      itinerary.flights.forEach((flight) => {
        this.airportsToComplete.add(flight.departure.location);
        this.airportsToComplete.add(flight.arrival.location);
        this.carriersToComplete.add(flight.marketingCarrier);
        this.carriersToComplete.add(flight.operativeCarrier);
      });
    });

    console.log('this.airportsToComplete => ', this.airportsToComplete);
    console.log('this.carriersToComplete => ', this.carriersToComplete);
    const airportsBody = {
      iata: Array.from(this.airportsToComplete),
    };
    const carriersBody = {
      iata: Array.from(this.carriersToComplete),
    };
    this.getAirports(airportsBody).subscribe((res) => {
      this.setAirports(res);
    }, error => {
      console.error(error);
    });
    this.getCarriers(carriersBody).subscribe((res) => {
      this.setCarriers(res);
    }, error => {
      console.error(error);
    });
    this.getCarriersOptions(carriersBody).subscribe((res) => {
      this.setCarriersOptions(res);
    }, error => {
      console.error(error);
    });
  }

  createToCompleteForm(value) {
    this.airportsToComplete.add(value);
    const airportsBody = {
      iata: Array.from(this.airportsToComplete),
    };
    this.getAirports(airportsBody).subscribe((res) => {
      if (this.airports.length > 0) {
        this.airports.push(res);
      } else {
        this.airports = res;
      }
      this.setAirports(this.airports);
      console.log('this.airports 0> ', this.airports);
    }, error => {
      console.error(error);
    });
  }

  async createToCompleteDossier(value) {
    value.forEach((recom) => {
      this.carriersToComplete.add(recom.doc.marketingCarrier);
      recom.doc.itineraries.forEach((itinerary) => {
        itinerary.flights.forEach((flight) => {
          this.airportsToComplete.add(flight.departure.location);
          this.airportsToComplete.add(flight.arrival.location);
        });
      });
    });
    console.log('this.airportsToComplete => ', this.airportsToComplete);
    console.log('this.carriersToComplete => ', this.carriersToComplete);
    const airportsBody = {
      iata: Array.from(this.airportsToComplete),
    };
    const carriersBody = {
      iata: Array.from(this.carriersToComplete),
    };
    this.getAirports(airportsBody).subscribe((res) => {
      this.setAirports(res);
    }, error => {
      console.error(error);
    });
    this.getCarriers(carriersBody).subscribe((res) => {
      this.setCarriers(res);
    }, error => {
      console.error(error);
    });
  }

  getSingleAirport(id): Observable<any> {
    const airportUrl = this.apiUrl + 'iatabase/airport/' + id;
    return this.http
      .get<any>(airportUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
  postSingleAirport(data, id): Observable<any> {
    const airportUrl = this.apiUrl + 'iatabase/airport/' + id;
    return this.http
      .post<any>(airportUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getWebCheckin(carrier): Observable<any> {
    const airportUrl = this.apiUrl + 'iatabase/airline/checkin/' + carrier;
    return this.http
      .get<any>(airportUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, true, this.translateService.instant('NO_WEB_CHECKIN'), 'warning'))
      );
  }
}
