<div class="product__item" role="listitem" *ngIf="productDetails && !userAuthorisations">
  <div class="product__header">
    <h4 class="product__name">Flight: </h4>
  </div>
  <div class="product__body">
    <h5 class="product__rooms"><b>Itineraries:</b> </h5>
    <div class="product__room" *ngFor="let itinerary of productDetails.itineraries">
      <div><p>flyingTime: {{ itinerary.flyingTime | date: 'dd/MM/yyyy H:m:s'}}</p></div>
      <div class="product__room" *ngFor="let flight of itinerary.flights">
        <p class="product__item"><b>Departure Location:</b> {{ flight.departure.terminal }}({{ flight.departure.location }})</p>
        <p class="product__item"><b>Departure Date:</b> {{flight.departure.date | date: 'dd/MM/yyyy H:m:s'}}</p>
        <p class="product__item"><b>Arrival Location:</b> {{ flight.arrival.terminal }}({{ flight.arrival.location }})</p>
        <p class="product__item"><b>Arrival Date:</b> {{flight.arrival.date | date: 'dd/MM/yyyy H:m:s'}}</p>
      </div>
    </div>
  </div>
  <div class="product__body">
    <h5 class="product__rooms"><b>General Info:</b> </h5>
    <div class="product__room">
      <p class="product__item"><b>Price:</b> {{productDetails.fare.amount}}</p>
      <p class="product__item"><b>Penalties:</b> <span *ngFor="let penalty of productDetails.penalties">{{penalty}}</span></p>
    </div>
  </div>
  <div class="product__body">
    <h5 class="product__rooms"><b>Passengers:</b> </h5>
    <div class="product__room" *ngFor="let passenger of productDetails.passengers; let i=index;">
      <p class="product__item"><b>No:</b> {{i}}</p>
      <p class="product__item"><b>Name:</b> {{passenger.name}}</p>
      <p class="product__item"><b>Surname:</b> {{passenger.surname}}</p>
      <p class="product__item"><b>Email:</b> {{passenger.email}}</p>
      <p class="product__item"><b>Phone Number:</b> {{passenger.phoneNumber}}</p>
      <p class="product__item"><b>Ticket:</b> {{passenger.ticket}}</p>
    </div>
  </div>
</div>

<div>
  <ng-container *ngIf="userAuthorisations">
    <div  *ngFor="let authorisation of userAuthorisations">
      <div [ngClass]="
         {'warn-alert': authorisation.status === 'pending',
         'danger-alert': authorisation.status === 'declined',
         'success-alert': authorisation.status === 'approved'
         }">

        <div style="display: flex; justify-content: space-between">
          <span>{{authorisation.note}}</span>
          <span>Status: {{authorisation.status}}</span></div>
      </div>
      <p *ngIf="authorisation.status === 'approved'">Approved by: {{authorisation.userResponse.name}} {{authorisation.userResponse.surname}}</p>
      <p *ngIf="authorisation.status === 'declined'">Declined by: {{authorisation.userResponse.name}} {{authorisation.userResponse.surname}}</p>
      <p *ngIf="authorisation.status !== 'pending'">Note: {{authorisation.userResponeNote}}</p>
    </div>
  </ng-container>
</div>

