import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AppComponent } from '../../../../app.component';
import { MainComponent } from '../../../mainmodule/main/main.component';
import { TicketingService } from '../../../../services/ticketing/ticketing.service';

@Component({
  selector: 'app-request-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class RequestListComponent implements OnInit {

  currentTab: number;
  ticketslist: any;
  loading: boolean;
  currentpage: number;
  maxpages: number;
  // setQuery
  query : any;
  limit = 10;
  skip : number;
  sort = 'createdAt';
  order = true;
  progress : boolean;
  text : string;
  displayedColumns = ['status', 'subject', 'user', 'date', 'update'];
  filterCompanies = [];
  pagenumbers = [];
  type : string;
  profile : any;

  filterTypes = ['group-request','underage'];
  filter = 'group-request';

  constructor(
    public appComponent : AppComponent,
    public mainComponent : MainComponent,
    private route : ActivatedRoute,
    private router : Router,
    private ticketService : TicketingService,
  ){
    this.profile = this.mainComponent.loggedUser;
    this.currentTab = 1;
    this.loading = true;
    this.progress = false;
    this.type = '';
    if (this.profile.role.superAdmin){
      this.type = 'operator';
    } else{
      this.type = 'user';
    }
  }

  ngOnInit() : void{
    this.currentpage = 0;
    this.skip = (this.currentpage) * this.limit;
    this.query = {
      options: {
        company: '',
        user: '',
        viewAll: false,
      },
      sort: this.sort,
      limit: this.limit,
      skip: this.skip,
      order: this.order ? 'asc' : 'desc',
      text: '',
    };
    this.route.queryParams.subscribe(params => {
      this.query.sort = params.sort || this.sort;
      this.query.limit = params.limit || this.limit;
      this.query.skip = params.skip || this.skip;
      this.query.order = params.order || 'asc';
      this.query.text = params.text || '';
      this.query.options = (params.options) ? JSON.parse(params.options) : this.query.options;
      this.text = params.text || '';
      this.currentpage = this.query.skip / this.query.limit;

      this.getTicketsList();
    });
  }

  sortValue(value){
    this.query.sort = value;
    this.sort = value;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  reverseOrder(){
    if (!this.order){
      this.query.order = 'asc';
      this.order = true;
    } else{
      this.query.order = 'desc';
      this.order = false;
    }
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  setFilterText(){
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  clearFilterText(){
    this.currentpage = 0;
    this.query.skip = 0;
    this.query.text = '';
    this.navigate();
  }

  navigate(){
    this.query.options = JSON.stringify(this.query.options);
    this.router.navigate([], {
      queryParams: this.query,
    });
  }

  next(){
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev(){
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value){
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  getTicketsList(){
    this.progress = true;
    this.ticketService.getRequestsList(this.filter, this.query).subscribe((res) => {
      this.loading = false;
      this.ticketslist = res;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.ticketslist => ', this.ticketslist);
      this.maxpages = Math.ceil((this.ticketslist.count / this.limit));
      if (this.maxpages === 0){ this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i ++){
        numeration.push(i);
      }
      this.pagenumbers = numeration;

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  /**
   * Given subject remove initial text (FLYLEO ticket n°)
   * @params value
   */
  truncateObj(value){
    const totaltitle = value;
    const index = totaltitle.indexOf(':');
    const newtitle = totaltitle.substr(index + 1);
    return newtitle;
  }

}
