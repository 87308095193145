import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../../../../app.component';
import {MainComponent} from '../../../../mainmodule/main/main.component';
import {ActivatedRoute, Router} from '@angular/router';
import {FlightService} from '../../../../../services/flight/flight.service';
import {PriceService} from '../../../../../services/price/price.service';
import {IatacompleteService} from '../../../../../services/iatacomplete/iatacomplete.service';
import {DossierService} from '../../../../../services/dossier/dossier.service';
import {Destination, HotelForm, HotelOptions, Occupancy} from '../../../classes/hotel';
import {ApiService} from '../../../../../services/api.service';
import {HotelService} from '../../../hotel.service';
import {CompaniesService} from '../../../../../services/profile/companies.service';
import {NewTraveller} from '../../../../../classes/travelers/newTraveller';
import * as $ from 'jquery';
import {TravelersService} from '../../../../../services/travelers/travelers.service';
import { GalleryItem, ImageItem } from 'ng-gallery';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-single-hotel-quotation',
  templateUrl: './single-hotel-quotation.component.html',
  styleUrls: ['./single-hotel-quotation.component.scss']
})
export class SingleHotelQuotationComponent implements OnInit {
  form: any;
  hotelForm : HotelForm;
  hotelRes: any;
  loading = false;
  allImages : any[] = [];
  allHotels : any[] = [];
  tabs : any[] = [];
  hotelInfos = false;
  currentTraveler : number;
  currentTab: any;
  guests = [];
  editingTraveler : boolean;
  loadTravelers: boolean;
  selecetedHotel: any;
  roomSelected: any;
  fareSelected: any;
  passengers: any;
  sarce : any;
  allRooms: any = [];
  providerToFilter = [];
  refoundToFilter = [];
  mealToFilter = [];
  mealsTypes = [];
  hotelProviders = [];
  filteredRoomList = [];
  images= [];
  selectedTabIndex = 0;
  constructor(
    public appComponent: AppComponent,
    public mainComponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    private flightService: FlightService,
    public priceservice: PriceService,
    // public dateservice: DateService,
    public iataComplete: IatacompleteService,
    private dossierService: DossierService,
    private apiService : ApiService,
    private hotelService : HotelService,
    private api : CompaniesService,
    private travelersService : TravelersService,
    private translate: TranslateService,
  ) {
    this.loading = true;
    this.tabs = [
      {
        active: true,
        name: 'HOTEL_INFOS'
      },
      {
        active: false,
        name: 'TARIFFES'
      },
      {
        active: false,
        name: 'GUESTS'
      }
    ];
    if (localStorage.getItem('sarceDossier')){
      this.sarce = JSON.parse(localStorage.getItem('sarceDossier'));
      // this.getTravelerCid();
    } else{
      delete this.sarce;
    }
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.form = JSON.parse(JSON.stringify(params));
      if(this.form.occupancy) {
        this.form.occupancy = JSON.parse(this.form.occupancy);
      }
      console.log('this.form', this.form);
      this.createHotelForm();
      if(this.form.code.includes(',')){
        this.form.code = this.form.code.split(',');
      }
      console.log('this.form.code', this.form.code);
    });
  }

  toggleGuestsModal(){
    $('#addGuestModal').modal('toggle');
  }

  returnTotalGuests() : number{
    let quantity = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.hotelForm.occupancy.length; i ++){
      // tslint:disable-next-line:prefer-for-of
      for (let c = 0; c < this.hotelForm.occupancy[i].guests.length; c ++){
        quantity = quantity + this.hotelForm.occupancy[i].guests[c].quantity;
      }
    }
    return quantity;
  }

  removeGuestType(i, type){
    const newGuest = {
      code: type,
      quantity: 1,
    };
    const index = this.hotelForm.occupancy[i].guests.findIndex(r => r.code === newGuest.code);
    this.hotelForm.occupancy[i].guests[index].quantity = this.hotelForm.occupancy[i].guests[index].quantity - newGuest.quantity;
    if (this.hotelForm.occupancy[i].guests[index].quantity === 0){
      this.hotelForm.occupancy[i].guests.splice(index, 1);
    }
    console.log(this.hotelForm.occupancy);
  }

  /**
   *  Return adults count
   */
  returnAdt(i){
    if (this.hotelForm.occupancy[i].guests.find(r => r.code === 10)){
      return this.hotelForm.occupancy[i].guests.find(r => r.code === 10).quantity;
    } else{
      return 0;
    }
  }

  /**
   *  Return over 65 count
   */
  returnOver(i){
    if (this.hotelForm.occupancy[i].guests.find(r => r.code === 2)){
      return this.hotelForm.occupancy[i].guests.find(r => r.code === 2).quantity;
    } else{
      return 0;
    }
  }

  /**
   *  Return children count
   */
  returnChd(i){
    let quantity = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let c = 0; c < this.hotelForm.occupancy[i].guests.length; c ++){
      if (this.hotelForm.occupancy[i].guests[c].code === 5){
        quantity = quantity + 1;
      }
    }
    return quantity;
  }

  addGuestType(i, type){
    const newGuest = {
      code: type,
      quantity: 1,
      age: 12,
    };
    if (type !== 5){
      delete newGuest.age;
    }
    const index = this.hotelForm.occupancy[i].guests.findIndex(r => r.code === newGuest.code);
    if (index === - 1 || type === 5){
      this.hotelForm.occupancy[i].guests.push(newGuest);
    } else{
      this.hotelForm.occupancy[i].guests[index].quantity = this.hotelForm.occupancy[i].guests[index].quantity + newGuest.quantity;
    }
    console.log(this.hotelForm.occupancy[i].guests);
  }

  removeRoom(value){
    this.hotelForm.occupancy.splice(value, 1);
  }

  createHotelForm(){
    // this.formLoaded = false;
    this.hotelForm = new HotelForm();
    this.hotelForm.nameSearch = true;
    this.hotelForm.hotels = [this.form.code];
    this.hotelForm.chain = '';
    this.hotelForm.strict = false;
    this.hotelForm.sort = 'RA';
    this.hotelForm.cache = 'Live'; // Live / LessRecent / VeryRecent
    this.hotelForm.checkIn = new Date(new Date(this.form.checkIn).setHours(6));
    this.hotelForm.checkOut = new Date(new Date(this.form.checkOut).setHours(6));
    this.hotelForm.occupancy = this.form.occupancy;
    this.hotelForm.options = new HotelOptions();
    this.hotelForm.options.stars = [];
    this.hotelForm.destination = new Destination();
    this.hotelForm.ratePlans = [];
    this.createGuests(false);
  }

  createGuests(redo:boolean): void {
    this.guests = [];
    const traveler = new NewTraveller();
    let adtCount = 0;
    let chdCount = 0;
    let overCount = 0;
    for (let i = 0; i < this.hotelForm.occupancy.length; i ++){
      for (let g = 0; g < this.hotelForm.occupancy[i].guests.length; g ++){
        if (this.hotelForm.occupancy[i].guests[g].code === 10){
          adtCount += this.hotelForm.occupancy[i].guests[g].quantity;
        } else if (this.hotelForm.occupancy[i].guests[g].code === 2){
          overCount += this.hotelForm.occupancy[i].guests[g].quantity;
        } else{
          chdCount += this.hotelForm.occupancy[i].guests[g].quantity;
        }
      }
    }
    const tr = JSON.parse(JSON.stringify(traveler));
    for (let i = 0; i < adtCount; i ++){
      tr.type = 'ADT';
      this.guests.push(tr);
    }
    for (let i = 0; i < overCount; i ++){
      tr.type = 'ADT';
      this.guests.push(tr);
    }
    for (let i = 0; i < chdCount; i ++){
      tr.type = 'CHD';
      this.guests.push(tr);
    }
    this.StarthotelSearch(redo);
  }

  StarthotelSearch(redo:boolean){
    if(redo) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/hotel/quotation'], { queryParams: { code: this.form.code, checkIn: this.form.checkIn, checkOut: this.form.checkOut, occupancy: JSON.stringify(this.form.occupancy) } }));
      window.open(url, '_self');
    } else {

    }
    this.removeRoomSelection();
    this.loading = true;
    this.allHotels = [];
    this.allImages = [];
    this.allRooms = [];
    this.providerToFilter = [];
    this.refoundToFilter = [];
    this.mealToFilter = [];
    this.mealsTypes = [];
    this.hotelProviders = [];
    this.filteredRoomList = [];
    delete this.hotelForm.destination;
    this.loading = true;
    // this.startCounter();
    if (this.hotelRes){
      delete this.hotelRes;
    }
    this.hotelService.sendHotelSearch(this.hotelForm).subscribe(resp => {
      this.loading = false;
      // console.log('resp =>', resp);
      this.hotelRes = resp;
      this.hotelRes = this.pushRoom(resp);
      this.allRooms.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
      this.filteredRoomList.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
      console.log('this.hotelRes ======>', this.hotelRes);
      this.hotelRes.hotels.forEach((h:any) => {
        this.getHotelNewInfos(h.hotelCode)
      });
      this.loading = false;
      this.setTab(0);

      for (const h of this.hotelRes.hotels){
        for (const r of h.roomsV2){
          r.chain = h.chain;
          r.hotel = JSON.parse(JSON.stringify(h));
          delete r.hotel.roomsV2;
          // this.currentHotel.roomList.push(r);
          this.createFilters(r);
        }
      }

    }, error => {
      console.error(error);
      this.loading = false;
    });
  }

  pushRoom(resp){
    resp.hotels.forEach(hotel => {
      hotel.roomsV2 = [];
      hotel.rooms.forEach(room => {
        if (hotel.roomsV2.length <= hotel.rooms.length){
          resp.rooms[room].index = room;
          hotel.roomsV2.push(resp.rooms[room]);
          const camera = resp.rooms[room];
          camera.chain = hotel.chain;
          camera.originalAmount = {
            currency: camera.currency,
            amount: camera.amount
          };
          if(camera.currency !== 'EUR') {
            camera.amount = this.convertPrice(camera.amount, camera.currency);
            console.log('camera.roomRates[0].amount => ', camera.roomRates[0].amount);
            camera.roomRates[0].amount = this.convertPrice(camera.roomRates[0].amount, camera.currency);
            camera.currency = 'EUR';
          }
          this.allRooms.push(camera);
          this.filteredRoomList.push(camera);
          console.log('camera=> ', camera);
          if (!hotel.hotelReference){
            hotel.hotelReferenceFull = resp.rooms[room].referenceID;
            hotel.hotelReference = resp.rooms[room].referenceID.split('.')[0];
          }
        }
        hotel.roomsV2.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
      });
    });
    this.allRooms.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
    this.filteredRoomList.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
    return resp;
  }

  getHotelNewInfos(code){
    const body = {
      hotels: [code],
    };
    /*const body = {
     hotels: this.hotelToGetInfos.splice(1, 1),
     };*/
    // this.loadingInfos = true;
    this.hotelService.askHotelInfos(body).subscribe(res => {
      // this.loadingInfos = false;
      // console.log('hotelsInfos =>', res);
      this.allHotels.push(res[0]);
      if (this.allHotels.length === this.hotelRes.hotels.length){
        this.allImages = this.getAllImages();
        console.log('allHotels =>', this.allHotels);
        this.allImages.forEach(i => {
          this.images.push(new ImageItem({ src: i, thumb: i }),)
        });
      }

    }, error => {
      console.error('error hotelsInfos =>', error);
    });
  }

  getAllImages(){
    const imgs = [];
    for (let h = 0; h < this.allHotels.length; h ++){
      const current = this.allHotels[h];
      if (current.info && current.info.images){
        current.info.images.forEach(i => imgs.push(i.list[0].url));
      }
    }
    this.hotelInfos = true;
    return imgs.filter((value, index) => imgs.indexOf(value) === index);

  }

  setTab(index){
    /*
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.tabs.length; i ++){
      this.tabs[i].active = false;
    }
    this.tabs[index].active = true;
    this.currentTab = this.tabs[index].name;
    if (index === 0){
      setTimeout(() => {
        // this.initMap();
      }, 200);
    }
    $('.mat-sidenav-content').animate({ scrollTop: 300 }, 300);
    */
    this.selectedTabIndex = index;
  }

  toggleDialog(currentTraveler){
    this.currentTraveler = currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  toggleEditDialog(currentTraveler){
    this.editingTraveler = true;
    this.currentTraveler = currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  removeTraveler(i){
    Object.keys(this.guests[i]).forEach(k => delete this.guests[i][k]);
  }

  loadTravelersList(currentTraveler){
    this.currentTraveler = currentTraveler;
    $('#listTravelerModal').modal('toggle');
    this.loadTravelers = true;
  }

  removeRoomSelection(){
    delete this.selecetedHotel;
    delete this.roomSelected;
    delete this.fareSelected;
    this.setTab(1);
  }

  roomSelect(event){
    // console.log('this.hotelSearch => ', this.hotelSearch);
    this.selecetedHotel = event.hotel;
    this.roomSelected = event.room;
    this.fareSelected = event.fare;
    // console.log('ROOM SELECTED => ', event);
    // $('.mat-sidenav-content').animate({ scrollTop: 100 }, 300);
    // tslint:disable-next-line:prefer-for-of
    /*for (let g = 0; g < event.fare.guests.length; g++) {
     if (event.fare.guests[g].ageCode === 'Adult') {
     traveler.type = 'ADT';
     }
     this.currentHotel.guests.push(traveler);
     }*/
    if (localStorage.getItem('sarceDossier')){
      this.getTravelerCid();
    }
    if (localStorage.getItem('dossierPassengers')){
      this.passengers = JSON.parse(localStorage.getItem('dossierPassengers'));
      this.getTravelerDossier();
    }
    console.log('fareSelected', this.fareSelected);
    this.setTab(2);
  }

  getTravelerCid(){
    // console.log('this.sarce.cid => ', this.sarce.cid);
    this.travelersService.getTravelerCid(this.sarce.cid).subscribe((res : any) => {
      res.type = 'ADT';
      this.travelerSet(res, 0);
    }, error => {
      console.error('res => ', error);
    });
  }

  travelerSet(event, i){
    this.guests[i] = event;
    const z = 1;
    this.guests[i].ref = i + z + 1;
    // console.log('this.data.passengers => ', this.currentHotel.guests);
    delete this.currentTraveler;
    // $('.mat-sidenav-content').animate({ scrollTop: 100 }, 300);
    // $('#addTravelerModal').modal('toggle');
  }

  getTravelerDossier(){
    this.travelersService.getTraveler(this.passengers[0]._id).subscribe((res : any) => {
      if (!res.type){
        res.type = 'ADT';
      }
      this.travelerSet(res, 0);
    }, error => {
      console.error('res => ', error);
    });
  }

  passFilters(room) : boolean{
    return this.checkMeal(room) && this.checkCProvider(room) && this.checkRefound(room);
  }

  checkMeal(room) : boolean{
    return this.mealToFilter.length === 0 || this.mealToFilter.includes(room.ratePlans[0].mealPlan);
  }

  checkCProvider(room) : boolean{
    return this.providerToFilter.length === 0 || this.providerToFilter.includes(room.chain);
  }

  checkRefound(room) : boolean{
    return this.refoundToFilter.length === 0 || this.refoundToFilter.includes(room.refoundable);
  }

  setRefound(value){
    const index = this.refoundToFilter.indexOf(value);
    if (index > - 1){
      this.refoundToFilter.splice(index, 1);
    } else{
      this.refoundToFilter.push(value);
    }
  }

  setProvider(provider){
    const index = this.providerToFilter.indexOf(provider);
    if (index > - 1){
      this.providerToFilter.splice(index, 1);
    } else{
      this.providerToFilter.push(provider);
    }
  }

  setMeal(meal){
    const index = this.mealToFilter.indexOf(meal);
    if (index > - 1){
      this.mealToFilter.splice(index, 1);
    } else{
      this.mealToFilter.push(meal);
    }
  }

  isFreeCancellation(room){
    return room.ratePlans[0].penalty[0] && room.ratePlans[0].penalty[0].absoluteDeadLine;
  }

  createFilters(room){
    if (!this.mealsTypes.includes(room.ratePlans[0].mealPlan)){
      this.mealsTypes.push(room.ratePlans[0].mealPlan);
    }
    if (!this.hotelProviders.includes(room.chain)){
      this.hotelProviders.push(room.chain);
    }
    if (this.isFreeCancellation(room)){
      room.refoundable = true;
    } else{
      room.refoundable = false;
    }
    this.reloadFilteredRooms();
    // console.log('this.currentHotel.roomList', this.currentHotel.roomList);
  }

  reloadFilteredRooms(){
    this.filteredRoomList = this.allRooms.filter(room => this.passFilters(room));
    this.filteredRoomList = this.filteredRoomList.sort((a, b) => a.roomRates[0].amount - b.roomRates[0].amount);
  }

  isMealSelected(value){
    return this.mealToFilter.includes(value);
  }

  isCProviderSelected(value){
    return this.providerToFilter.includes(value);
  }

  isRefoundSelected(value){
    return this.refoundToFilter.includes(value);
  }

  closeEvent(event){
    delete this.currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  closeEditEvent(event){
    this.editingTraveler = false;
    delete this.currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  closeListEvent(event){
    delete this.currentTraveler;
    $('#listTravelerModal').modal('toggle');
  }

  travelerController(){
    const isValidTraveler = (value) => !(!value.name || !value.surname || !value.phoneNumber);
    return this.guests.every(isValidTraveler);
  }

  bookHotel(){
    // console.log('this.currentHotel => ', this.currentHotel);
    // this.roomSelected.hotel;

    // const hotelTobuy = JSON.parse(JSON.stringify('hotelhere'));
    const reservation = {
      hotel: {
        code: this.selecetedHotel.hotelCode,
        name: this.selecetedHotel.name,
        provider: this.selecetedHotel.hotelCode.slice(0, 2),
        checkIn: this.roomSelected.startDate,
        checkOut: this.roomSelected.endDate,
        city: this.selecetedHotel.cityCode,
        address: this.hotelRes.hotels[0].address,
      },
      guests: this.guests,
      room: this.roomSelected,
      session: this.hotelRes.session,
      b2b: false,
      // room: this.currentHotel.roomSelected.room,
    };
    if (this.appComponent.isB2B){
      reservation.b2b = true;
    }

    // CONVERTING ALL AMOUNT IN EUR FROM LOCAL CURRENCY
    if (reservation.room.currency !== 'EUR'){
      reservation.room.amount = this.convertPrice(reservation.room.amount, reservation.room.currency);
      reservation.room.roomRates[0].amount = this.convertPrice(reservation.room.roomRates[0].amount, reservation.room.currency);
      if (reservation.room.ratePlans[0]){
        if (reservation.room.ratePlans[0].penalty){
          for (let p = 0; p < reservation.room.ratePlans[0].penalty.length; p ++){
            // tslint:disable-next-line:max-line-length
            reservation.room.ratePlans[0].penalty[p].amount = this.convertPrice(reservation.room.ratePlans[0].penalty[p].amount, reservation.room.currency);
          }
        }
      }
    }

    // console.log('reservation => ', reservation);

    this.hotelService.postReservation(reservation).subscribe(resp => {
      this.loading = false;
      // console.log(resp);

      if (localStorage.getItem('fl_dossierId')){
        const dossierId = JSON.parse(localStorage.getItem('fl_dossierId'));
        const body = {
          hotels: [resp._id],
        };
        this.dossierService.addHotelsToDossier(dossierId, body).subscribe((res) => {
          // console.log('res => ', res);
        }, error => {
          // console.error('res => ', error);
        });
      }

      this.router.navigate(['/hotel/reservations/' + resp._id]);
    }, error => {
      alert(error);
      this.loading = false;
    });

  }

  convertPrice(value, currency) : number{
    if (this.hotelRes.currencyConversions){
      for (let i = 0; i < this.hotelRes.currencyConversions.length; i ++){
        if (this.hotelRes.currencyConversions[i].local === currency){
          return this.priceservice.returnPriceHotel(value * this.hotelRes.currencyConversions[i].rate);
        }
      }
    } else{
      return this.priceservice.returnPriceHotel(value);
    }

  }

  returnProvider(value:string): string{
    if (!this.appComponent.isGat && value.includes('AMADEUS')){
      return this.translate.instant('TARIFFES') + ' LEONARDO TRAVEL';
    }
    return value;
  }

  onTabChange(selectedTabIndex: number): void {
    this.selectedTabIndex = selectedTabIndex;
  }

}
