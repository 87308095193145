import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldsDialogComponent } from '../customFields/fields-dialog/fields-dialog.component';
import { CompaniesService } from 'src/app/services/profile/companies.service';

export interface CustomField {
  fieldName: string,
  sialField: number,
  isOptional: boolean,
  fieldValue: any,
  fieldType: string,
  fieldOptions: any,
}

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss']
})

export class CustomFieldsComponent implements OnInit {
  loadingCustom = true;
  @Input() companyId: any;
  form: any;
  siapNumbers: string[] = ['C1','C2','C3','C4','C5','C6','C7','C8','C9','C0'];
  fieldTypes: string[] = ['text','select'];
  constructor(
    public dialog: MatDialog,
    private companyService : CompaniesService,
  ) { }

  ngOnInit(): void {
    this.form = [];
    this.getCustomFields();
  }
  
  addField(type:string){
    const field = {
      fieldName: '',
      siapField: '',
      isOptional: true,
      fieldValue: '',
      fieldType: type,
      fieldOptions: [],
    }
    this.form.push(field);
  }

  removeField(index:number){
    this.form.splice(index, 1);
  }

  isSelectedSiap(num:number){
    let find = this.form.findIndex((f:any) => f.siapField === num);
    if(find == -1){
      return false;
    } else {
      return true;
    }
  }

  editField(field:any, i:number){
    const dialogRef = this.dialog.open(FieldsDialogComponent, {
      data: {
        field: JSON.parse(JSON.stringify(field)),
        fieldTypes: this.fieldTypes,
        siapNumbers: this.siapNumbers,
        index: i,
        form: this.form,
      },
      maxWidth:'90%',
      width:'900px'
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log('result => ', result);
      if(result){
        this.form[result.index] = result.field;
      }
      console.log('this.form =>', this.form);
    });
  }

  getCustomFields(){
    this.loadingCustom = true;
    this.companyService.getCustomFields(this.companyId).subscribe((res) => {
      this.form = [];
      //this.companyRemarks = res;
      console.log('res => ', res);
      if(res && res.data){
        res.data.forEach((d:any) => {
          this.form.push(d);
        })
      }
      this.loadingCustom = false;
    }, error => {
      this.loadingCustom = false;
      console.error('Error => ', error);
    });
  }

  saveCustomFields(){
    this.loadingCustom = true;
    if(this.form.length == 0){
      this.deleteCmFields();
    } else {
      this.postCmFields();
    }
    
  }

  deleteCmFields(){
    this.companyService.deleteCustomFields(this.companyId).subscribe((res) => {
      
      //this.companyRemarks = res;
      console.log('res => ', res);
      this.loadingCustom = false;
    }, error => {
      this.loadingCustom = false;
      console.error('Error => ', error);
    });
  }

  postCmFields(){
    const body = {
      data: this.form,
    }
    this.companyService.postCustomFields(body, this.companyId).subscribe((res) => {
      //this.companyRemarks = res;
      console.log('res => ', res);
      this.loadingCustom = false;
    }, error => {
      this.loadingCustom = false;
      console.error('Error => ', error);
    });
  }

  isValidForm(){
    const isValidField = (value) => !(!value.fieldName || !value.siapField || !value.fieldType || (value.fieldType == 'radioButton' && value.fieldOptions.length == 0));
    return this.form.every(isValidField);
  }

  optionNeeded(field:any){
    let needOptions = false;
    if((field.fieldType === 'radioButton' || field.fieldType ==='checkbox' || field.fieldType === 'select') && field.fieldOptions.length == 0){
      needOptions = true;
    }
    return needOptions;
  }

}
