import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FeesModService} from '../fees.service';
import {ActivatedRoute} from '@angular/router';
import {FeeForServiceDto} from '../../../sdk/transaction/models/FeeForServiceDto';

@Component({
  selector: 'app-add-edit-fees',
  templateUrl: './add-edit-fees.component.html',
  styleUrls: ['./add-edit-fees.component.scss']
})
export class AddEditFeesComponent implements OnInit {
  currentFee : any;
  stantardFees: any;
  flightFees: any;
  loggedUser: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditFeesComponent>,
    private feesService: FeesModService,
    private route: ActivatedRoute,
  ) {
    this.flightFees = ['SEGMENT','PASSENGER','PNR'];
    this.stantardFees = ['PASSENGER','PNR'];
  }

  ngOnInit(): void {
    this.loggedUser = JSON.parse(localStorage.getItem('fl_profile'));
    if(this.data.fee){
      this.getFeeById(this.data.fee._id);
    } else {
      this.currentFee = {
        // company:'';
        b2c : false,
        enable: true,
        name: '',
        lowCost: {amount:0, feeServiceFor:'PNR'},
        ndc: {amount:0, feeServiceFor:'PNR'},
        hotel: {amount:0, feeServiceFor:'PNR'},
        flight: {amount:0, feeServiceFor:'PNR'},
        italo: {amount:0, feeServiceFor:'PNR'},
        trenitalia: {amount:0, feeServiceFor:'PNR'},
        car: {amount:0, feeServiceFor:'PNR'},
      }
    }
  }

  getFeeById(id){
    this.feesService.getFeeFromId(id).subscribe((res) => {
      this.currentFee = res;
      console.log('this.currentFee =>', this.currentFee);
    }, err => {
      console.log('error => ', err);
    });
  }

  save(){
    this.feesService.updateFeeFromId(this.data.fee._id, this.currentFee).subscribe((res) => {
      this.currentFee = res;
      console.log('this.currentFee =>', this.currentFee);
      this.dialogRef.close(true);
    }, err => {
      console.log('error => ', err);
    });
  }

  create(){
    this.feesService.createFee(this.currentFee).subscribe((res) => {
      // this.currentFee = res;
      console.log('res =>', res);
      this.dialogRef.close(true);
    }, err => {
      console.log('error => ', err);
    });
  }

}
