<div class="terminal">
  <div class="terminalcontent" *ngIf="!loading && tst">
    <div class="text-right">
      <button mat-stroked-button (click)="getCryptic()">Get PNR</button>
      <button mat-stroked-button (click)="getTst()">Get TST</button>
      <button mat-stroked-button (click)="getTsm()">Get TSM</button>
      <button mat-stroked-button (click)="getTstHistory()">See history</button>
    </div>
    <div [innerHtml]="tst"></div>
  </div>
  <div class="terminalcontent text-center" *ngIf="loading">
    {{'LOADING'|translate}}
  </div>

</div>
