import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class HelperFunctionsService {
  ListSupportedRatesDetails: any[] = [];
  availableLowTarifs:any[] = [];
  currentFee: any = {};
  constructor(
    private readonly translate: TranslateService,
  ) { }

  isSupported(mark){
    for(let i=0; i<this.ListSupportedRatesDetails.length; i++){
      if(mark === this.ListSupportedRatesDetails[i].marketingCarrier){
        return true;
      }
    }
    return false;
  }

  translateString(value:string){
    return this.translate.instant(value);
  }

  getIndex(marketingCarrier){
    for(let i=0; i<this.ListSupportedRatesDetails.length; i++){
      if(marketingCarrier === this.ListSupportedRatesDetails[i].marketingCarrier){
        return this.availableLowTarifs.findIndex(ele => ele.supplier === this.ListSupportedRatesDetails[i].supplier);
      }
    }
  }

  returnAmount(type:string, value:number, paxNumber: number, segments?:number){
    if(this.currentFee){
      if(type === 'flight'){
        if(this.currentFee.flight.feeServiceFor === 'PNR'){
          return value + this.currentFee.flight.amount;
        }
        if(this.currentFee.flight.feeServiceFor === 'SEGMENT'){
          return value + (this.currentFee.flight.amount * segments);
        }
        if(this.currentFee.flight.feeServiceFor === 'PASSENGER'){
          return value + (this.currentFee.flight.amount * paxNumber);
        }
      }
    } else {
      return value;
    }

  }

  returnProfile(){
    return JSON.parse(localStorage.getItem('fl_profile'));
  }
}

