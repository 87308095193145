import { Component, OnInit } from '@angular/core';
import {FlightService} from '../../../../services/flight/flight.service';
import {IatacompleteService} from '../../../../services/iatacomplete/iatacomplete.service';
import {Airports} from '../../../flight/classes/airports';
import {MatChipInputEvent} from '@angular/material/chips';
@Component({
  selector: 'app-manage-airports',
  templateUrl: './manage-airports.component.html',
  styleUrls: ['./manage-airports.component.scss']
})
export class ManageAirportsComponent implements OnInit {
  text: string;
  airports: Airports[];
  loading: boolean;
  editing: any;
  currentEditing: any;
  keywords: any = [];
  editingName: string;
  constructor(
    private flightService: FlightService,
    private iataComplete: IatacompleteService,
  ) { }

  ngOnInit(): void {
    this.loading = false;
    this.text = '';
  }

  searchAirport(value) {
    delete this.airports;
    if (value.length >= 2 ) {
      this.getAirportList(value);
    }
  }

  getAirportList(value) {
    delete this.airports;
    this.flightService.getAirportsList(value).subscribe((res:any) => {
      this.loading = false;

      this.airports = [];
      res.results.forEach(r=>{
        this.airports.push({name:r.name || r.cityName, iatacity : r.cityCode, iata : r.iataCode, isCity : true, id: r.id});
        r.airports.forEach(a=>{
          this.airports.push({name: a.name || a.detailedName.split(':')[1] ,iatacity : a.cityCode, iata : a.iataCode, id:a.id});
        })
      });
      console.log('this.airports => ', this.airports);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  clearForm() {
    this.text = '';
    delete this.editing;
    delete this.keywords;
    delete this.editingName;
    delete this.airports;
  }

  getFrom(value) {
    this.clearForm()
    /*delete this.editing;
    delete this.keywords;
    delete this.editingName;*/
    console.log(value);
    this.editing = value;
    /*delete this.airports;*/
    const iata = value.iataCode;
    this.iataComplete.createToCompleteForm(iata);
    this.getIata();
    this.text = '';
  }

  selectFirst() {
    if (this.airports) {
      delete this.editing;
      delete this.keywords;
      delete this.editingName;
      this.editing = this.airports[0];
      this.iataComplete.createToCompleteForm(this.airports[0].iata);
      this.getIata();
    }
    /*delete this.airports;
    this.text = '';*/
  }

  getIata(): void {
    this.loading = true;
    delete this.currentEditing;
    this.iataComplete.getSingleAirport(this.editing.id).subscribe((res) => {
      this.loading = false;
      this.currentEditing = res;
      this.createKeys();
      // this.prnRetrieve();
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  createKeys(): void {
    this.editingName = this.currentEditing.name;
    this.keywords = this.currentEditing.keywords.split(' ');
  }

  addKeywordFromInput(event: MatChipInputEvent) {
    if (event.value) {
      this.keywords.push(event.value);
      event.chipInput!.clear();
    }
  }

  removeKeyword(i: number) {
    this.keywords.splice(i, 1);
  }

  saveAirport(): void {
    const words = this.keywords.join(' ');
    this.editing.keywords = words;
    this.editing.name =  this.editingName;
    delete this.keywords;
    this.iataComplete.postSingleAirport(this.editing, this.editing.id).subscribe((res) => {
      this.loading = false;
      this.getIata();
      // this.prnRetrieve();
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }
}
