import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../../../app.component';
import {Router} from '@angular/router';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {CustomAuthService} from '../../../../services/customAuth/custom-auth.service';
import { AuthenticationService } from '../../../../services/authentication.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
  @Input() sarce?: any;
  constructor(
    public appcomponent: AppComponent,
    public maincomponent: MainComponent,
    private router: Router,
    public customAuth: CustomAuthService,
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
  }
  logOut() {
    this.authService.logout();
  }
  goto(value) {
    this.router.navigate(['/' + value]);
  }

}
