<div id="searchCard" class="collapse show" *ngIf="!firstLoad">

    <div class="searchNavigation">
        <div class="mb-2">
            <button class="newTabButtonActive">
              <span>{{'FLIGHTS_SEARCH'|translate}}</span>
            </button>
            <a class="newTabButton" routerLink="/flight/history">
              <span>{{'HISTORY'|translate}}</span>
            </a>
        </div>
    </div>

    <div class="searchCard">
        <div class="row mb-2">
          <div class="col-auto">
            <img class="formIcon" src="assets/img/flights.png"/>
          </div>
          <div class="col-10">
            <div class="searchtitle">
              <div class="titleSup">{{'FLIGHTS_SEARCH'|translate}}</div>
              <div class="titleSub">{{'FILL_FORM'|translate}}</div>
            </div>

          </div>
        </div>

        <div class="typeRow mb-3">
          <mat-radio-group aria-labelledby="typeLabel" class="radio-group" [(ngModel)]="request.searchType" (ngModelChange)="changeType()"
            [disabled]="request.options.family">
            <mat-radio-button class="radio-button" value="oneway">{{'ONEWAY'|translate}}</mat-radio-button>
            <mat-radio-button class="radio-button" value="roundtrip">{{'ROUND'|translate}}</mat-radio-button>
            <mat-radio-button *ngIf="mainComponent.loggedUser._id!=='604f240ec5ec740012a28f46'" class="radio-button" value="multy">
              {{'MULTY'|translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="searchContainer" *ngIf="this.request.sections[0]">

          <div class="formTopButtons">
            <div class="formTopAction" [ngClass]="{'ActiveOption': othersOptionsOpen}" (click)="toggleOptions()">
              <span class="material-icons">
                tune
              </span>
            </div>
            <div class="formTopAction reset" (click)="clearSearch()" matTooltip="{{'CLEAR_FORM'|translate}}">
              <span class="material-icons">
                refresh
              </span>
            </div>
          </div>

          <!-- start settings -->

          <div id="collapseOptions" class="row newStandardFields mb-2 collapse">

            <div class="col-md-2 col-sm-6 col-6">
              <div class="animationContent">
                <div *ngIf="!request.options.corporate && !request.options.family && !request.options.alliance && !request.options.etnic && !request.options.tourOperator">

                  <app-carriers [parentForm]="request"></app-carriers>

                  <div *ngIf="request.options.carrierIdentification.length>0" class="mb-3">
                    <span *ngFor="let car of request.options.carrierIdentification;let c = index;" class="singleiata">
                      {{car}}
                      <span (click)="removeIataCarrier(c)" class="cancelicon" aria-hidden="true">&times;</span>
                    </span>
                  </div>
                </div>

                <div class="fakefield" *ngIf="request.options.family">
                  {{request.options.carrierIdentification[0]}}
                </div>

                <div class="fakefield" *ngIf="request.options.etnic">{{'ONLY_ETNIC'}}</div>
                <div class="fakefield" *ngIf="request.options.tourOperator">{{'TOUR_OPERATOR'}}</div>
                <div class="fakefield" *ngIf="request.options.corporate">
                  {{request.options.carrierIdentification[0]}}
                </div>

                <mat-form-field class="full-width" *ngIf="request.options.alliance">
                  <mat-label>{{'ALLIANCE'}}</mat-label>
                  <mat-select [(ngModel)]="request.options.carrierIdentification[0]" required>
                    <mat-option value="*O">ONEWORLD</mat-option>
                    <mat-option value="*S">SKYTEAM</mat-option>
                    <mat-option value="*A">STAR ALLIANCE</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="col-md-2 col-sm-6 col-6">
              <mat-form-field class="full-width">
                <mat-label>{{'CABIN'|translate}}</mat-label>
                <mat-select [(ngModel)]="request.options.cabin" name="cabin" (ngModelChange)="setClassValues($event)">
                  <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                  <mat-option value="Y">{{'ECONOMY'|translate}}</mat-option>
                  <mat-option value="W">{{'ECONOMY_PLUS'|translate}}</mat-option>
                  <mat-option value="C">{{'BUSINESS_CLASS'|translate}}</mat-option>
                  <mat-option value="F">{{'FIRST_CLASS'|translate}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2 col-sm-6 col-6">
              <mat-form-field class="full-width">
                <mat-label>{{'STOPS'|translate}}</mat-label>
                <mat-select [(ngModel)]="request.options.flightType" name="flightType">
                  <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                  <mat-option value="N">{{'ONLY_NON_STOP'|translate}}</mat-option>

                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2 col-sm-6 col-6">
              <mat-form-field class="full-width">
                <mat-label>{{'PRICE_TO_BEAT'|translate}}</mat-label>
                <input matInput type="number" [(ngModel)]="request.options.priceToBeat" name="priceToBeat">

              </mat-form-field>
            </div>

            <div class="col-md-2 col-sm-6 col-6">
              <mat-form-field class="full-width">
                <mat-label>{{'MAX_CONN_TIME'|translate}}</mat-label>
                <mat-select [(ngModel)]="request.options.maxTimeConnections" name="maxTimeConnections">
                  <mat-option [value]="null">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                  <mat-option [value]="2">2</mat-option>
                  <mat-option [value]="4">4</mat-option>
                  <mat-option [value]="6">6</mat-option>
                  <mat-option [value]="8">8</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>

          <!-- end settings -->

          <div *ngIf="request.searchType === 'oneway' || request.searchType === 'multy'">
            <div class="formSections" *ngFor="let section of request.sections;let i = index;let z = index;let j = index;">
              <div class="row sectionBg">
                <div class="flightBorder">

                </div>
                <div class="col-lg-5">
                  <app-airports [parentForm]="request" [index]="z" [isTransit]="false"></app-airports>

                  <!--<div class="row">
                    <div class="col-md-5">
                      <div *ngIf="request.sections[z].from.length>0" class="">
                        <span *ngFor="let iata of request.sections[z].from;let ia = index;" class="singleiata">
                          {{iataComplete.returnAirport(iata)}}
                          <span (click)="removeIataFrom(z,ia)" class="canceliconnew" aria-hidden="true">&times;</span>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-2 col-12 text-center align-self-center">
                      <span (click)="invertIata(z)" class="switchIcon material-icons"
                            [hidden]="request.sections[z].to.length == 0 && request.sections[z].from.length == 0">
                        swap_horiz
                      </span>
                    </div>
                    <div class="col-md-5">
                      <div *ngIf="request.sections[z].to.length>0" class="">
                      <span *ngFor="let iata of request.sections[z].to;let ia = index;" class="singleiata">
                        {{iataComplete.returnAirport(iata)}}
                        <span (click)="removeIataTo(z,ia)" class="canceliconnew" aria-hidden="true">&times;</span>
                      </span>
                      </div>
                    </div>
                  </div>-->
                </div>

                <div class="col-lg-3 col-6" *ngIf="request.searchType === 'oneway' || request.searchType === 'multy'">
                  <mat-form-field class="full-width">
                    <input matInput readonly [matDatepicker]="jj" placeholder="{{'DEPARTURE_DATE'|translate}}" (click)="jj.open()"
                           [min]="getMinDate(z)" [(ngModel)]="section.options.date" (dateChange)="setNextDate(z)" name="date{{z}}">
                    <mat-datepicker-toggle matSuffix [for]="jj"></mat-datepicker-toggle>
                    <mat-datepicker #jj></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-lg-2 col-6" *ngIf="request.searchType === 'roundtrip'">
                  <mat-form-field class="full-width">
                    <input matInput readonly [matDatepicker]="rr" placeholder="{{'RETURN_DATE'|translate}}" (click)="rr.open()"
                           [min]="getMinDate(z+1)" [(ngModel)]="request.sections[1].options.date" name="date{{z+1}}">
                    <mat-datepicker-toggle matSuffix [for]="rr"></mat-datepicker-toggle>
                    <mat-datepicker #rr></mat-datepicker>
                  </mat-form-field>
                </div>


                <div class="col-lg-1 col-6">
                  <div class="paxCounter pointer d-block d-sm-inline mb-2" [matMenuTriggerFor]="menu" *ngIf="i == 0">
                    <div class="fakeFormLabel">{{'PASSENGERS'|translate}}</div>
                    <div class="fakeFormInput">
                      <div class="fakeQtyButton">-</div>
                      <div class="fakeQtyInput" *ngIf="paxCounter()>0">{{paxCounter()}}</div>
                      <div class="fakeQtyButton">+</div>
                    </div>

                  </div>

                  <mat-menu #menu="matMenu">
                    <div (click)="$event.stopPropagation();" class="searchMenuItem" mat-menu-item>
                      <div class="">
                        <div class="labelBtn">
                          {{'ADULTS'|translate}}
                        </div>
                        <div class="buttonMenu">
                          <button class="text-center" [disabled]="request.passengers.adt==1 || (request.passengers.adt==2 && request.options.family)"
                                  aria-label="Minus" (click)="removePax('adt')">
                            <span class="material-icons">remove</span>
                          </button>
                          <div class="paxCount">{{request.passengers.adt}}</div>
                          <button class="text-center" [disabled]="maxPaxReached()" aria-label="Plus" (click)="addPax('adt')">
                            <span class="material-icons">add</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="!appComponent.isB2B && !appComponent.isSbt" (click)="$event.stopPropagation();" class="searchMenuItem" mat-menu-item>
                      <div class="">
                        <div class="labelBtn">
                          {{'CHILDREN'|translate}}
                        </div>
                        <div class="buttonMenu">
                          <button class="text-center" [disabled]="request.passengers.chd==0 || (request.passengers.chd==1 && request.options.family)"
                                  aria-label="Minus" (click)="removePax('chd')">
                            <span class="material-icons">remove</span>
                          </button>
                          <div class="paxCount">{{request.passengers.chd}}</div>
                          <button class="text-center" [disabled]="maxPaxReached()" aria-label="Plus" (click)="addPax('chd')">
                            <span class="material-icons">add</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="!appComponent.isB2B && !appComponent.isSbt" (click)="$event.stopPropagation();" class="searchMenuItem" mat-menu-item>
                      <div class="">
                        <div class="labelBtn">
                          {{'INFANT'|translate}}
                        </div>
                        <div class="buttonMenu">
                          <button class="text-center" [disabled]="request.passengers.inf==0" aria-label="Minus" (click)="removePax('inf')">
                            <span class="material-icons">remove</span>
                          </button>
                          <div class="paxCount">{{request.passengers.inf}}</div>
                          <button class="text-center" [disabled]="maxInfReached()" aria-label="Plus" (click)="addPax('inf')">
                            <span class="material-icons">add</span>
                          </button>
                        </div>
                      </div>
                    </div>


                    <div *ngIf="maxPaxReached()" (click)="$event.stopPropagation();" class="searchMenuItem" mat-menu-item>
                      Massimo numero di passeggeri raggiunto
                    </div>
                    <div *ngIf="request.passengers.adt==request.passengers.inf && maxInfReached()" (click)="$event.stopPropagation();"
                         class="searchMenuItem" mat-menu-item>
                      Massimo 1 neonato per ogni adulto
                    </div>
                  </mat-menu>
                  <!--
                  <span *ngIf="mainComponent.loggedUser._id!=='604f240ec5ec740012a28f46'" class="pointer d-block d-sm-inline" (click)="toggleOptions()">
                    {{'OTHER'|translate}}
                    <span class="material-icons">
                      keyboard_arrow_down
                    </span>
                  </span>
                  -->
                </div>
                <div class="col-lg-2 text-right align-self-center">
                  <button class="newButton" (click)="toggleCollapse(z)">{{'TUNE_SECTION'|translate}}</button>
                </div>

                <div class="col-lg-1 text-center align-self-center">
                  <button class="newIconButton" matTooltip="{{'REMOVE_SECTION'}}" aria-label="delete" (click)="removeSection(z)"
                          [hidden]="request.searchType=='oneway' || request.sections.length<=1">
                    <span class="material-icons">delete_outline</span>
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-12 collapse newSingleOptionRow" id="collapseF{{z}}">
                  <div class="row inwhiterow">
                    <div class="col-md-2 col-sm-6 col-6">
                      <mat-form-field class="full-width">
                        <mat-label>{{'TIME'|translate}}</mat-label>
                        <mat-select [(ngModel)]="section.options.timeSlot" name="time">
                          <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                          <mat-option value="2">{{'MORNING'|translate}} (06-12)</mat-option>
                          <mat-option value="3">{{'AFTERNOON'|translate}} (12-18)</mat-option>
                          <mat-option value="4">{{'EVENING'|translate}} (18-24)</mat-option>
                          <mat-option value="1">{{'NIGHT'|translate}} (00-06)</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2 col-sm-6 col-6">
                      <mat-form-field class="full-width">
                        <mat-label>{{'CABIN'|translate}}</mat-label>
                        <mat-select [(ngModel)]="section.options.cabin" name="cabin" class="firstClassSelect" (ngModelChange)="singleCabinChange()">
                          <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                          <mat-option value="Y">{{'ECONOMY'|translate}}</mat-option>
                          <mat-option value="W">{{'ECONOMY_PLUS'|translate}}</mat-option>
                          <mat-option value="C">{{'BUSINESS_CLASS'|translate}}</mat-option>
                          <mat-option value="F">{{'FIRST_CLASS'|translate}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2 col-sm-6 col-6">
                      <!--
                      <mat-form-field class="full-width">
                        <mat-label>{{'VIA_AIRPORT'|translate}}</mat-label>
                        <input matInput placeholder="Ex. JFK" [(ngModel)]="via[z]">
                        <button mat-button *ngIf="via[z]" matSuffix mat-icon-button aria-label="Clear" (click)="pushBy(z,via[z])">
                          <mat-icon>add</mat-icon>
                        </button>
                      </mat-form-field> -->
                      <app-airports [parentForm]="request" [index]="z" [isTransit]="true"></app-airports>
                      <div *ngIf="request.sections[z].options.viaAirport.length>0" class="mb-3">
                          <span *ngFor="let iata of request.sections[z].options.viaAirport;let ia = index;" class="singleiata">
                            {{iataComplete.returnAirport(iata)}}
                            <span (click)="removeIataVia(z,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                          </span>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-6">
                      <mat-form-field class="full-width">
                        <mat-label>{{'STOPS'|translate}}</mat-label>
                        <mat-select [(ngModel)]="section.options.flightType" name="cabin">
                          <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                          <mat-option value="0">{{'NON_STOPS'|translate}}</mat-option>
                          <mat-option value="1">1 {{'STOP'|translate}}</mat-option>
                          <mat-option value="2">2 {{'STOPS'|translate}}</mat-option>
                          <mat-option value="3">3 {{'STOPS'|translate}}</mat-option>
                          <mat-option value="4">4 {{'STOPS'|translate}}</mat-option>
                          <mat-option value="5">5 {{'STOPS'|translate}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="request.searchType === 'roundtrip'">
            <div class="formSections">
              <div class="row sectionBg">
                <div class="flightBorder">

                </div>
                <div class="col-lg-5">
                  <app-airports [parentForm]="request" [index]="0" [isTransit]="false"></app-airports>

                  <!--<div class="row">
                    <div class="col-md-5">
                      <div *ngIf="request.sections[0].from.length>0" class="">
                        <span *ngFor="let iata of request.sections[0].from;let ia = index;" class="singleiata">
                          {{iataComplete.returnAirport(iata)}}
                          <span (click)="removeIataFrom(0,ia)" class="canceliconnew" aria-hidden="true">&times;</span>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-2 col-12 text-center align-self-center">
                      <span (click)="invertIata(0)" class="switchIcon material-icons"
                            [hidden]="request.sections[0].to.length == 0 && request.sections[0].from.length == 0">
                        swap_horiz
                      </span>
                    </div>
                    <div class="col-md-5">
                      <div *ngIf="request.sections[0].to.length>0" class="">
                      <span *ngFor="let iata of request.sections[0].to;let ia = index;" class="singleiata">
                        {{iataComplete.returnAirport(iata)}}
                        <span (click)="removeIataTo(0,ia)" class="canceliconnew" aria-hidden="true">&times;</span>
                      </span>
                      </div>
                    </div>
                  </div> -->
                </div>

                <div class="col-lg-2 col-6" *ngIf="request.searchType === 'roundtrip'">
                  <mat-form-field class="full-width">
                    <input matInput readonly [matDatepicker]="departure" placeholder="{{'DEPARTURE_DATE'|translate}}" (click)="departure.open()"
                           [min]="getMinDate(0)" [(ngModel)]="request.sections[0].options.date" (dateChange)="setNextDate(0)" name="date{{0}}">
                    <mat-datepicker-toggle matSuffix [for]="departure"></mat-datepicker-toggle>
                    <mat-datepicker #departure></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-lg-2 col-6" *ngIf="request.searchType === 'roundtrip' && request.sections[1]">
                  <mat-form-field class="full-width">
                    <input matInput readonly [matDatepicker]="returnDate" placeholder="{{'RETURN_DATE'|translate}}" (click)="returnDate.open()"
                           [min]="getMinDate(1)" [(ngModel)]="request.sections[1].options.date" name="date{{1}}">
                    <mat-datepicker-toggle matSuffix [for]="returnDate"></mat-datepicker-toggle>
                    <mat-datepicker #returnDate></mat-datepicker>
                  </mat-form-field>
                </div>


                <div class="col-lg-1 col-6 mb-4 mb-lg-0">
                  <div class="paxCounter pointer d-block d-sm-inline mb-1" [matMenuTriggerFor]="menu">
                    <div class="fakeFormLabel">{{'PASSENGERS'|translate}}</div>
                    <div class="fakeFormInput">
                      <div class="fakeQtyButton">-</div>
                      <div class="fakeQtyInput" *ngIf="paxCounter()>0">{{paxCounter()}}</div>
                      <div class="fakeQtyButton">+</div>
                    </div>

                  </div>

                  <mat-menu #menu="matMenu">
                    <div (click)="$event.stopPropagation();" class="searchMenuItem" mat-menu-item>
                      <div class="">
                        <div class="labelBtn">
                          {{'ADULTS'|translate}}
                        </div>
                        <div class="buttonMenu">
                          <button class="text-center" [disabled]="request.passengers.adt==1 || (request.passengers.adt==2 && request.options.family)"
                                  aria-label="Minus" (click)="removePax('adt')">
                            <span class="material-icons">remove</span>
                          </button>
                          <div class="paxCount">{{request.passengers.adt}}</div>
                          <button class="text-center" [disabled]="maxPaxReached()" aria-label="Plus" (click)="addPax('adt')">
                            <span class="material-icons">add</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="!appComponent.isB2B && !appComponent.isSbt" (click)="$event.stopPropagation();" class="searchMenuItem" mat-menu-item>
                      <div class="">
                        <div class="labelBtn">
                          {{'CHILDREN'|translate}}
                        </div>
                        <div class="buttonMenu">
                          <button class="text-center" [disabled]="request.passengers.chd==0 || (request.passengers.chd==1 && request.options.family)"
                                  aria-label="Minus" (click)="removePax('chd')">
                            <span class="material-icons">remove</span>
                          </button>
                          <div class="paxCount">{{request.passengers.chd}}</div>
                          <button class="text-center" [disabled]="maxPaxReached()" aria-label="Plus" (click)="addPax('chd')">
                            <span class="material-icons">add</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="!appComponent.isB2B && !appComponent.isSbt" (click)="$event.stopPropagation();" class="searchMenuItem" mat-menu-item>
                      <div class="">
                        <div class="labelBtn">
                          {{'INFANT'|translate}}
                        </div>
                        <div class="buttonMenu">
                          <button class="text-center" [disabled]="request.passengers.inf==0" aria-label="Minus" (click)="removePax('inf')">
                            <span class="material-icons">remove</span>
                          </button>
                          <div class="paxCount">{{request.passengers.inf}}</div>
                          <button class="text-center" [disabled]="maxInfReached()" aria-label="Plus" (click)="addPax('inf')">
                            <span class="material-icons">add</span>
                          </button>
                        </div>
                      </div>
                    </div>


                    <div *ngIf="maxPaxReached()" (click)="$event.stopPropagation();" class="searchMenuItem" mat-menu-item>
                      Massimo numero di passeggeri raggiunto
                    </div>
                    <div *ngIf="request.passengers.adt==request.passengers.inf && maxInfReached()" (click)="$event.stopPropagation();"
                         class="searchMenuItem" mat-menu-item>
                      Massimo 1 neonato per ogni adulto
                    </div>
                  </mat-menu>
                  <!--
                  <span *ngIf="mainComponent.loggedUser._id!=='604f240ec5ec740012a28f46'" class="pointer d-block d-sm-inline" (click)="toggleOptions()">
                    {{'OTHER'|translate}}
                    <span class="material-icons">
                      keyboard_arrow_down
                    </span>
                  </span>
                  -->
                </div>

                <div class="col-lg-2 col-6 text-center align-self-center">
                  <button class="newButton" (click)="toggleCollapseRound()">{{'TUNE_SECTIONS'|translate}}</button>
                </div>
                <!--
                <div class="col-lg-2 text-right align-self-center">
                  <button class="newButton" (click)="toggleCollapse(z)">{{'TUNE_SECTION'|translate}}</button>
                </div>
                -->
                <!--
                <div class="col-lg-1 text-center align-self-center">
                  <button class="newIconButton" matTooltip="{{'REMOVE_SECTION'}}" aria-label="delete" (click)="removeSection(z)" [hidden]="request.searchType=='oneway' || request.sections.length<=1">
                    <span class="material-icons">delete_outline</span>
                  </button>
                </div>
              -->
              </div>

              <div class="collapse" id="collapseRound">
                <div class="row">
                  <div class="col-12 mb-2 newSingleOptionRow">
                    <div class="mb-2">{{'OPZIONI_TRATTA_ANDATA'|translate}}</div>
                    <div class="row inwhiterow">
                      <div class="col-md-2 col-sm-6 col-6">
                        <mat-form-field class="full-width">
                          <mat-label>{{'TIME'|translate}}</mat-label>
                          <mat-select [(ngModel)]="request.sections[0].options.timeSlot" name="time">
                            <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                            <mat-option value="2">{{'MORNING'|translate}} (06-12)</mat-option>
                            <mat-option value="3">{{'AFTERNOON'|translate}} (12-18)</mat-option>
                            <mat-option value="4">{{'EVENING'|translate}} (18-24)</mat-option>
                            <mat-option value="1">{{'NIGHT'|translate}} (00-06)</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-md-2 col-sm-6 col-6">
                        <mat-form-field class="full-width">
                          <mat-label>{{'CABIN'|translate}}</mat-label>
                          <mat-select [(ngModel)]="request.sections[0].options.cabin" name="cabin" class="firstClassSelect"
                                      (ngModelChange)="singleCabinChange()">
                            <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                            <mat-option value="Y">{{'ECONOMY'|translate}}</mat-option>
                            <mat-option value="W">{{'ECONOMY_PLUS'|translate}}</mat-option>
                            <mat-option value="C">{{'BUSINESS_CLASS'|translate}}</mat-option>
                            <mat-option value="F">{{'FIRST_CLASS'|translate}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-md-2 col-sm-6 col-6">
                        <!--
                        <mat-form-field class="full-width">
                          <mat-label>{{'VIA_AIRPORT'|translate}}</mat-label>
                          <input matInput placeholder="Ex. JFK" [(ngModel)]="via[z]">
                          <button mat-button *ngIf="via[z]" matSuffix mat-icon-button aria-label="Clear" (click)="pushBy(z,via[z])">
                            <mat-icon>add</mat-icon>
                          </button>
                        </mat-form-field> -->
                        <app-airports [parentForm]="request" [index]="0" [isTransit]="true"></app-airports>
                        <div *ngIf="request.sections[0].options.viaAirport.length>0" class="mb-3">
                            <span *ngFor="let iata of request.sections[0].options.viaAirport;let ia = index;" class="singleiata">
                              {{iataComplete.returnAirport(iata)}}
                              <span (click)="removeIataVia(0,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                            </span>
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 col-6">
                        <mat-form-field class="full-width">
                          <mat-label>{{'STOPS'|translate}}</mat-label>
                          <mat-select [(ngModel)]="request.sections[0].options.flightType" name="cabin">
                            <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                            <mat-option value="0">{{'NON_STOPS'|translate}}</mat-option>
                            <mat-option value="1">1 {{'STOP'|translate}}</mat-option>
                            <mat-option value="2">2 {{'STOPS'|translate}}</mat-option>
                            <mat-option value="3">3 {{'STOPS'|translate}}</mat-option>
                            <mat-option value="4">4 {{'STOPS'|translate}}</mat-option>
                            <mat-option value="5">5 {{'STOPS'|translate}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mb-2 newSingleOptionRow">
                    <div class="mb-2">{{'OPZIONI_TRATTA_RITORNO'|translate}}</div>
                    <div class="row inwhiterow">
                      <div class="col-md-2 col-sm-6 col-6">
                        <mat-form-field class="full-width">
                          <mat-label>{{'TIME'|translate}}</mat-label>
                          <mat-select [(ngModel)]="request.sections[1].options.timeSlot" name="time">
                            <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                            <mat-option value="2">{{'MORNING'|translate}} (06-12)</mat-option>
                            <mat-option value="3">{{'AFTERNOON'|translate}} (12-18)</mat-option>
                            <mat-option value="4">{{'EVENING'|translate}} (18-24)</mat-option>
                            <mat-option value="1">{{'NIGHT'|translate}} (00-06)</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-md-2 col-sm-6 col-6">
                        <mat-form-field class="full-width">
                          <mat-label>{{'CABIN'|translate}}</mat-label>
                          <mat-select [(ngModel)]="request.sections[1].options.cabin" name="cabin" class="firstClassSelect"
                                      (ngModelChange)="singleCabinChange()">
                            <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                            <mat-option value="Y">{{'ECONOMY'|translate}}</mat-option>
                            <mat-option value="W">{{'ECONOMY_PLUS'|translate}}</mat-option>
                            <mat-option value="C">{{'BUSINESS_CLASS'|translate}}</mat-option>
                            <mat-option value="F">{{'FIRST_CLASS'|translate}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-md-2 col-sm-6 col-6">
                        <!--
                        <mat-form-field class="full-width">
                          <mat-label>{{'VIA_AIRPORT'|translate}}</mat-label>
                          <input matInput placeholder="Ex. JFK" [(ngModel)]="via[z]">
                          <button mat-button *ngIf="via[z]" matSuffix mat-icon-button aria-label="Clear" (click)="pushBy(z,via[z])">
                            <mat-icon>add</mat-icon>
                          </button>
                        </mat-form-field> -->
                        <app-airports [parentForm]="request" [index]="1" [isTransit]="true"></app-airports>
                        <div *ngIf="request.sections[1].options.viaAirport.length>0" class="mb-3">
                            <span *ngFor="let iata of request.sections[1].options.viaAirport;let ia = index;" class="singleiata">
                              {{iataComplete.returnAirport(iata)}}
                              <span (click)="removeIataVia(1,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                            </span>
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 col-6">
                        <mat-form-field class="full-width">
                          <mat-label>{{'STOPS'|translate}}</mat-label>
                          <mat-select [(ngModel)]="request.sections[1].options.flightType" name="cabin">
                            <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                            <mat-option value="0">{{'NON_STOPS'|translate}}</mat-option>
                            <mat-option value="1">1 {{'STOP'|translate}}</mat-option>
                            <mat-option value="2">2 {{'STOPS'|translate}}</mat-option>
                            <mat-option value="3">3 {{'STOPS'|translate}}</mat-option>
                            <mat-option value="4">4 {{'STOPS'|translate}}</mat-option>
                            <mat-option value="5">5 {{'STOPS'|translate}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="newOptionsRow mb-2">

            <!-- <mat-checkbox *ngIf="mainComponent.loggedUser._id!=='604f240ec5ec740012a28f46' && this.request.sections.length <=2" [(ngModel)]="request.options.calendar">{{'SEARCH_CALENDAR'|translate}}</mat-checkbox> -->

            <mat-checkbox *ngIf="!appComponent.isB2B && !appComponent.isSbt && !request.options.family && !request.options.etnic && !request.options.tourOperator && !showCorporate"
                          [(ngModel)]="request.options.alliance" (change)="setAlliance($event.checked)" color="primary">
              {{'ALLIANCE'|translate}}
            </mat-checkbox>
            <!--
            <mat-checkbox *ngIf="!appComponent.isB2B &&!appComponent.isSbt && !request.options.alliance && !request.options.etnic && !request.options.refundable  && !showCorporate  && !request.options.tourOperator" [(ngModel)]="request.options.family" (ngModelChange)="setFamily($event)">{{'FAMILY_PLAN_ALITALIA'|translate}}</mat-checkbox>
            -->
            <mat-checkbox *ngIf="!appComponent.isB2B &&!appComponent.isSbt && !request.options.alliance && !request.options.family && !request.options.refundable  && !showCorporate  && !request.options.tourOperator"
                          [(ngModel)]="request.options.etnic" (ngModelChange)="setEtnic($event)" color="primary">
              {{'ONLY_ETNIC'|translate}}
            </mat-checkbox>
            <!--
            <mat-checkbox *ngIf="!request.options.family && !request.options.etnic  && !showCorporate && !request.options.tourOperator" [(ngModel)]="request.options.refundable" (ngModelChange)="setRefound($event)">{{'ONLY_REFOUNDABLE'|translate}}</mat-checkbox>
            -->
            <mat-checkbox *ngIf="corporateCodes.length>0 && !request.options.family && !request.options.etnic && !request.options.tourOperator && !request.options.refundable && !request.options.alliance"
                          [(ngModel)]="showCorporate" (ngModelChange)="setCorporate($event)" color="primary">
              {{'CORPORATES_FARES'|translate}}
            </mat-checkbox>

            <mat-checkbox *ngIf="!appComponent.isB2B && !appComponent.isSbt && !request.options.alliance && !request.options.family && !request.options.etnic && !request.options.refundable  && !showCorporate && (mainComponent.loggedUser.role.superAdmin || mainComponent.company.cauDK)"
                          [(ngModel)]="request.options.tourOperator" (ngModelChange)="settourOperator($event)" color="primary">
              {{'TOUR_OPERATOR'|translate}}
            </mat-checkbox>

            <span *ngIf="checkResidents() && !request.options.alliance && !request.options.family && !request.options.etnic && !request.options.refundable  && !showCorporate && !request.options.tourOperator">
              <mat-checkbox (ngModelChange)="setResidents($event)" [(ngModel)]="request.options.resident" color="primary">
                {{'RESIDENTS'|translate}}
              </mat-checkbox>
              <span (click)="toggleInfoResidents()" class="material-icons" style="vertical-align: baseline"> contact_support </span>
            </span>

            <div class="col-md-2 col-sm-6 col-6" *ngIf="showCorporate">
              <mat-form-field class="full-width">
                <mat-select [(ngModel)]="request.options.corporate" name="cabin">
                  <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                  <mat-option *ngFor="let corporateCode of corporateCodes" [value]="corporateCode.code" >{{ corporateCode.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="addSectionButton mb-2">
          <button class="newButtonSecondary" (click)="addNewSection()" [hidden]="request.searchType!='multy' || request.sections.length>=6">
            <span class="material-icons mr-2">add</span>
            <span class="vertical-align">{{'ADD_SECTION'|translate}}</span>
          </button>
        </div>
        <div class="StartSearchButton text-right">
          <button class="newButtonCalendar mr-2" [disabled]="loading==true || !isValidForm()" (click)="startSearch(true, undefined)"
                  *ngIf="mainComponent.loggedUser._id!=='604f240ec5ec740012a28f46' && this.request.sections.length <=2 && this.request.sections[0]">
            <span class="material-icons mr-1"> calendar_month </span>
            <span class="vertical">{{'RANGE_SEARCH'|translate}}</span>
          </button>
          <button class="newButtonPrimary" *ngIf="this.request.sections[0]" [disabled]="loading==true || !isValidForm()"
                  (click)="startSearch(false, undefined)">
            {{'SEARCH'|translate}}
          </button>
        </div>

    </div>

</div>

<div *ngIf="loading || loadinglow" class="nowloading padding20 text-center">
  {{'LOADING'|translate}}
  <div class="currentForm">
    <p *ngFor="let sec of request.sections;">
      {{sec.options.date|date:'EEE dd MMM yyyy'}} {{iataComplete.returnAirport(sec.from)}}
      <sup>{{sec.from}}</sup>
      <mat-icon>arrow_right_alt</mat-icon>
      {{iataComplete.returnAirport(sec.to)}}
      <sup>{{sec.to}}</sup>
    </p>
  </div>
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>

<div class="modal fade" id="infoResidentsModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="infoResidentsModal" aria-hidden="true"></div>
