import {Injectable} from '@angular/core';
import {User} from '../../models/user.model';
import {BaseApiService} from '../baseApi.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {Role} from '../../models/role.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {
  public currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
  public user;

  constructor(httpClient: HttpClient, private router: Router) {
    super(httpClient);
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    const currentUserStorage = JSON.parse(localStorage.getItem('currentUser'));
    if (!!currentUserStorage && !!currentUserStorage.data) {
      this.user = JSON.parse(localStorage.getItem('currentUser')).data.user;
    } else {
      this.user = null;
    }
  }


  userList(): Observable<any> {
    return this.getMany('api/users');
  }

  rolesList(): Observable<any> {
    return this.getMany('/api/groups');
  }

  isAuthorized(): boolean {
    return this.currentUserSubject.getValue()?.data?.token;
  }

  hasRole(role): any {
    return this.isAuthorized() && this.user.group?.name === role;
  }

  /**
   * @param user get user object
   * send request to api
   * set user to local storage
   * if user role is admin redirect to policy
   * if user role is not admin redirect to overview
   */

  login(user): Observable<any> {
    this.user = user;
    return this.post('api/auth/login-as/', {id: user?._id}).pipe(map(u => {
      localStorage.setItem('currentUser', JSON.stringify(u));
      this.currentUserSubject.next(u);
      if (this.currentUserSubject.getValue().data.user.group?.name === Role.Admin) {
        this.router.navigate(['policy']);
      } else {
        this.router.navigate(['overview']);
      }

      return user;
    }));
  }

  /**
   * remove user from local storage
   * set current user subject as null
   * navigate to login
   */
  logout(): void {
    this.user = null;
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['login']).then();
  }
}
