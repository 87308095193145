import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './carcomponent/search/search.component';
import { CarlistComponent } from './carcomponent/carlist/carlist.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { TravelersModule } from '../travelers/travelers.module';
import { FopsModule } from '../fops/fops.module';
import { CarsearchformComponent } from './carcomponent/search/carsearchform/carsearchform.component';
import { CarsearchresultsComponent } from './carcomponent/search/carsearchresults/carsearchresults.component';
import { DossierModule } from '../dossier/dossier.module';
import { CarreservationComponent } from './carcomponent/carreservation/carreservation.component';
import { CartstComponent } from './carcomponent/carreservation/cartst/cartst.component';
import { CustomCommonModule } from '../common/custom-common.module';
import {DirectiveModuleModule} from '../directive/directive-module.module';


@NgModule({
  declarations: [
    SearchComponent,
    CarlistComponent,
    CarsearchformComponent,
    CarsearchresultsComponent,
    CarreservationComponent,
    CartstComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    TravelersModule,
    FopsModule,
    DossierModule,
    CustomCommonModule,
    DirectiveModuleModule,
  ],
})
export class CarModule{}
