import { Component } from '@angular/core';
import {
  NavigationEnd,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  filter,
  map,
} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

/**
 *  Component to render page title, included in every page.
 */
@Component({
  selector: 'custom-title',
  templateUrl: './custom-title.component.html',
  styleUrls: ['./custom-title.component.scss'],
})
export class CustomTitleComponent{

  title : string;
  titleLoaded$ : BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   *  Constructor
   */
  constructor(
    private router : Router,
    private translate : TranslateService,
  ){
    router.events
          .pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => router),
          ).subscribe((event) => {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).map(t => this.translate.instant(t)).join(' > ');
        this.setTitle(title);
      },
    );
  }

  getTitle(state, parent){
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title){
      data.push(parent.snapshot.data.title);
    }

    if (state && parent){
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  setTitle(title){
    this.title = title;
    console.log('title', this.title);
    this.titleLoaded$.next(true);
  }
}
