<custom-title></custom-title>
<div class="padding20">

  <app-carsearchform (update)="resultChange($event)" (reset)="createForm()" [request]="request"></app-carsearchform>

  <div *ngIf="searchResults">
    <app-carsearchresults [searchResults]="searchResults"></app-carsearchresults>
  </div>

</div>
