import { Injectable } from '@angular/core';
import {HttpserviceService} from '../httpservice.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root'
})
export class AdmserviceService extends HttpserviceService {
  admUrl: string;
  profileUrl: string;
  sended: boolean;
  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
    this.admUrl = this.apiUrl;
    this.profileUrl = this.oldApiUrl;
    this.sended = false;
  }

  getFlightsCryptic(): Observable<any> {
    const admFlUrl = this.admUrl + 'flight/cryptic/report';
    return this.http
      .get<any>(admFlUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  updateHotelRes(data, id): Observable<any> {
    const admHlUrl = this.admUrl + 'hotel/pnr/' + id + '/change';
    return this.http
      .post<any>(admHlUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  updateFlightRes(data, id): Observable<any> {
    const admFlUrl = this.admUrl + 'flight/pnr/' + id + '/change';
    return this.http
      .post<any>(admFlUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  updateDossier(id): Observable<any> {
    const body = {
      id,
    };
    const admFlUrl = this.admUrl + 'dossier/' + id + '/update';
    return this.http
      .post<any>(admFlUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  addProfileUser(data): Observable<any> {
    const admHlUrl = this.apiUrl + 'profile/create/user';
    return this.http
      .put<any>(admHlUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, true, 'AN_ERROR_OCCURRED'))
      );
  }
  addProfileCompany(data): Observable<any> {
    const admHlUrl = this.apiUrl + 'profile/create/company';
    return this.http
               .put<any>(admHlUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err, true, 'AN_ERROR_OCCURRED'))
               );
  }

  getIncetives(): Observable<any> {
    const admHlUrl = this.apiUrl + 'profile/company/incentive';
    return this.http
      .get<any>(admHlUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  sendCommand(data): Observable<any> {
    const admHlUrl = this.admUrl + 'cryptic/session';
    return this.http
      .post<any>(admHlUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  closeSession(data): Observable<any> {
    const admHlUrl = this.admUrl + 'cryptic/signOut';
    return this.http
      .post<any>(admHlUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getCpSiap(dk): Observable<any> {
    const admFlUrl = this.admUrl + 'profile/company/siap/' + dk;
    return this.http
      .get<any>(admFlUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getUsersSiap(id): Observable<any> {
    const admFlUrl = this.admUrl + 'profile/company/' + id + '/siap/import';
    return this.http
      .get<any>(admFlUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getIataList(): Observable<any> {
    const admHlUrl = this.apiUrl + 'flight/airline/setup ';
    return this.http
      .get<any>(admHlUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  editIata(obj): Observable<any> {
    const admHlUrl = this.apiUrl + 'flight/airline/setup/' + obj._id;
    return this.http
      .post<any>(admHlUrl, obj, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getIata(obj): Observable<any> {
    const admHlUrl = this.apiUrl + 'flight/airline/setup?iata=' + obj;
    return this.http
      .get<any>(admHlUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  addIata(obj): Observable<any> {
    const admHlUrl = this.apiUrl + 'flight/airline/setup/';
    return this.http
      .put<any>(admHlUrl, obj, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  removeIata(obj): Observable<any> {
    const admHlUrl = this.apiUrl + 'flight/airline/setup/' + obj._id;
    return this.http
      .delete<any>(admHlUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  importPnr(obj): Observable<any> {
    const admHlUrl = this.apiUrl + 'flight/import';
    return this.http
      .post<any>(admHlUrl, obj, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  changePnr(type, obj, id): Observable<any> {
    const body = {
      fareManual: obj,
    }
    const admHlUrl = this.apiUrl + type + '/pnr/' + id + '/change';
    return this.http
      .post<any>(admHlUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
}
