import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {
  myControl = new UntypedFormControl();
  options: string[] = ['One', 'Two', 'Three'];
  title: string;
  constructor() {
    this.title = 'Autocomplete';
  }

  ngOnInit() {
  }

}
