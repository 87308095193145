import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { UserComponent } from './components/usercopo/user/user.component';
import { ListComponent } from './components/usercopo/list/list.component';
import { FlightModule } from '../flight/flight.module';
import { PermissionComponent } from './components/usercopo/permission/permission.component';
import { LoginAsComponent } from './components/login-as/login-as.component';
import { HotelModule } from '../hotel/hotel.module';
import { CustomCommonModule } from '../common/custom-common.module';
import {FopsModule} from '../fops/fops.module';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

@NgModule({
  declarations: [
    ListComponent,
    UserComponent,
    PermissionComponent,
    LoginAsComponent,
    AddUserDialogComponent,
    ChangePasswordComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    FlightModule,
    HotelModule,
    FopsModule,
    CustomCommonModule,
  ],
  exports: [
    ListComponent,
  ],
})
export class UsersModule{}
