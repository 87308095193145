import {Component, Input, OnInit} from '@angular/core';
import {MainComponent} from '../../mainmodule/main/main.component';
import {Notifications} from '../../../classes/notifications';
import {SocketService} from '../../../services/socket/socket.service';
import {NotifierService} from 'angular-notifier';
import {TranslateService} from '@ngx-translate/core';
import {Notificationdialog} from '../notifications/auto/auto.component';
import {MatDialog} from '@angular/material/dialog';
import {NotificationsService} from '../../../services/notifications/notifications.service';
@Component({
  selector: 'app-mainnotifications',
  templateUrl: './mainnotifications.component.html',
  styleUrls: ['./mainnotifications.component.scss']
})
export class MainnotificationsComponent implements OnInit {
  autoNotificationsList: Notifications = new Notifications();
  manualNotificationsList: Notifications = new Notifications();
  newElement: any;
  clonedAutoNotificationsList = [];
  clonedManNotificationsList = [];
  private readonly notifier: NotifierService;
  constructor(
    public mainComponent: MainComponent,
    private socketService: SocketService,
    notifierService: NotifierService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private notificationsServices: NotificationsService,
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.connectToSocket();
  }

  connectToSocket() {
    this.socketService.socket.on('notification', (data) => {
      // this.playAudio();
      console.log(data);
      this.newElement = data;
      // tslint:disable-next-line:max-line-length
      this.notifier.notify('default', this.translate.instant('YOU_HAVE') + ' ' + 1 + ' ' + this.translate.instant('NEW_NOTIFICATIONS'));
      this.manageElement();
    });
  }

  manageElement(): void {
    if (this.newElement.type === 'auto') {
      this.autoNotificationsList.list.unshift(this.newElement);
      this.clonedAutoNotificationsList.unshift(this.newElement);
      this.mainComponent.unreadAuto += 1;
    } else {
      if (this.newElement.type === 'manual' && this.newElement.category === 'adv') {
        this.manualNotificationsList.list.unshift(this.newElement);
        this.clonedManNotificationsList.unshift(this.newElement);
        this.mainComponent.unreadManual += 1;
        if (this.newElement.block) {
          this.openDialog(this.newElement);
        }
      }
    }
    delete this.newElement;
  }

  playAudio() {
    const audio = new Audio();
    audio.src = '../../../assets/audio/bell.mp3';
    audio.load();
    audio.playbackRate = 10;
    audio.play();
  }

  openDialog(element): void {
    const dialogRef = this.dialog.open(Notificationdialog, {
      width: '350px',
      data: { element },
    });
    /*
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed => ', result);
      if (result) {
        this.setAsRead(result);
      }
    });
    */
  }

}
