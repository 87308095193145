import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import { Usero } from '../../../../bisclasses/user';
import {MatSnackBar} from '@angular/material/snack-bar';
import "bootstrap";
import * as $ from 'jquery';

@Component({
  selector: 'app-registrations',
  templateUrl: './registrations.component.html',
  styleUrls: ['./registrations.component.scss']
})
export class RegistrationsComponent implements OnInit {
  userList:Usero[] = [];
  loading:boolean;
  recipient:any;
  message:string;
  sending:boolean;
  constructor(
    private api: ApiService,
    private _snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.getUserList();
  }

  getUserList(){
    this.loading=true;
    this.api.getUsers().subscribe(resp => {
      setTimeout(()=>{
          this.loading = false;
          }, 1000);
      console.log('resp =>', resp);
      this.userList = resp.body;
      console.log('this.documentList => ',this.userList);
    });
  }

  openMessage(user){
    this.message = '';
    this.recipient = user;
  }

  toggleActivation(user){
    user.active =! user.active;
    this.openSnackBar(user);
  }

  openSnackBar(user) {
    let message = ((user.active) ? 'User enabled' : 'User disabled');
    this._snackBar.open(message, 'ok', {
      duration: 2000,
      horizontalPosition:'right',
      verticalPosition:'top',
    });
  }

  sendMessage(message){
    this.sending = true;
    setTimeout(()=>{
      this.sending = false;
      $('#emailModal').modal('hide');
      this._snackBar.open('Message send', 'ok', {
        duration: 2000,
        horizontalPosition:'right',
        verticalPosition:'top',
      });
    }, 1000);
  }

}
