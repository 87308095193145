import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-group-request',
  templateUrl: './group-request.component.html',
  styleUrls: ['./group-request.component.scss']
})
export class GroupRequestComponent implements OnInit {
  @Input() ticket;

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
