import {
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FlightService } from '../../../../../../services/flight/flight.service';
import { AppComponent } from '../../../../../../app.component';
import { IatacompleteService } from '../../../../../../services/iatacomplete/iatacomplete.service';
import { SearchRes } from '../../../../../../classes/flights/search-res';
import { FlightRequest } from '../../../../classes/flightrequest';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-pnratc',
    templateUrl: './pnratc.component.html',
    styleUrls: ['./pnratc.component.scss'],
})
export class PnratcComponent implements OnInit{
    @Input() pnrId : string;
    @Input() pnr;
    eligibility : any;
    eligibilityError : string | undefined;
    searchResults : any = new SearchRes();
    selections: {};
    today = new Date();
    request : any = new FlightRequest();
    singleSection : any;
    showForm = false;

    loadingHistory = false;
    historyResponse : any;
    dataSource: any;
    displayedColumns: string[] = [
        'index',
        'actionType',
        'elementType',
        'previousRecord',
        'currentRecord',
        'textInformation',
    ];
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort!: MatSort;
    pageIndex = 0;
    pageSize = 25;

    constructor(
        public appComponent: AppComponent,
        public iataComplete: IatacompleteService,
        private flightService: FlightService,
    ){
        this.createForm();
        this.singleSection = {
            from: [],
            to: [],
            fromLegend: [],
            toLegend: [],
            options: {
                date: new Date(),
                minDate: this.today,
                departureTime: '',
                cabin: '',
                viaAirport: [],
                expandAdvance: false,
                flightType: '',
                timeSlot: '',
            }
        };
    }

    ngOnInit() : void{
        this.getEligibility();
        // this.getHistory(); TODO in amadeus prod non funziona la chiamata
    }

    getEligibility() {
        this.flightService.getEligibility(this.pnrId).subscribe((res) => {
            this.eligibility = res;
        }, error => {
            console.log('error => ', error);
            this.eligibilityError = error?.errorMessage || 'Error';
        });
    }

    getHistory() {
        this.loadingHistory = true;
        this.flightService.getHistory(this.pnrId).subscribe((res) => {
            this.historyResponse = res;
            this.dataSource = new MatTableDataSource(res.history);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (item:any, property:any) => {
                switch(property) {
                    case 'index': return item.index;
                    case 'actionType': return item.actionType;
                    case 'elementType': return item.elementType;
                    case 'previousRecord': return item.previousRecord;
                    case 'currentRecord': return item.currentRecord;
                    case 'textInformation': return item.textInformation;
                    default: return item[property];
                }
            };
            this.loadingHistory = false;
        }, error => {
            this.loadingHistory = false;
            console.log('error => ', error);
        });
    }

    resultSelChange($event : any){
        this.selections = $event;
        if (this.selections) {
            // this.notifier.notify('success', this.translate.instant('SELECTIONS_GOOD'));
        }
    }

    requestChange($event : any){
        this.request = $event;
    }

    createForm() {
        this.request = {
            searchType: 'roundtrip',
            sections: [],
            passengers: {
                adt: 1,
                chd: 0,
                inf: 0,
            },
            options: {
                carrierIdentification: [],
                corporate: '',
                cabin: '',
                carrierQualifier: '',
                flightType: '',
                resident: false,
                refundable: false,
                family: false,
                etnic: false,
                calendar: false,
                alliance: false,
                tourOperator: false,
                priceToBeat: null,
                maxTimeConnections: null,
            },
        };
        this.showForm = true;
    }

    resultChange($event : SearchRes){
        console.log('change results event');
        this.searchResults = $event;
        if (this.searchResults && this.searchResults.itineraries) {
            // this.notifier.notify('success', this.translate.instant('SEARCH_GOOD'));
            this.createToComplete();
        }
    }

    private createToComplete(){
        if (this.searchResults && this.searchResults.itineraries) {
            console.log('test compile');
            this.iataComplete.createToComplete(this.searchResults);
        }
    }

    getAllowedCarriers(){
        const safeArray = Array.isArray(this.eligibility.allowedCarriers.companyIdentity) ?
            this.eligibility.allowedCarriers.companyIdentity : [this.eligibility.allowedCarriers.companyIdentity];
        return safeArray.map(c => c.otherCompany)
    }

    onPaginateChange(event: any){
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
    }

    applyFilter($event : KeyboardEvent){
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator){
            this.dataSource.paginator.firstPage();
        }
    }

    getPrices() {
        if (!this.historyResponse) return [];
        return Object.keys(this.historyResponse?.prices).map(k => {
            return {
                pax: k,
                payments: this.historyResponse.prices[k]
            }
        });
    }

    getKeys(v){
        return Object.keys(v);
    }
}
