export const environment = {
  production: true,
  apiUrl: 'https://api.flyleo.it/api/v2/',
  apiroot: 'http://localhost:3000/api/v1/',
  isB2b: false,
  isSbt: false,
  isTest: false,
  isGat: false,
  apiTrain: 'https://api.flyleo.it/api/v2/',
  manifestSrc: 'manifests/manifest.webmanifest'
};
