import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse,HttpErrorResponse,HttpEventType } from '@angular/common/http';
import { Usero } from '../bisclasses/user';
import { Transactions } from '../bisclasses/transactions';
import { Observable,throwError } from 'rxjs';
import { retry,catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient
  ) { }

  getUsers(): Observable<HttpResponse<Usero[]>> {
    let localUrl = 'assets/data/userlist.json';
    return this.http.get<Usero[]>(localUrl, { observe: 'response'});
  }
  getUserById(id: any): Observable<any> {
    let localUrl = 'assets/data/userlist.json';
    return this.http.get<Usero>(localUrl + id).pipe(
      retry(3), catchError(err=>{return this.handleError(err)}));
  }

  getTransactions(): Observable<HttpResponse<Transactions[]>> {
    let localUrl = 'assets/data/transactions.json';
    return this.http.get<Transactions[]>(localUrl, { observe: 'response'});
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  getHotelResult(): Observable<any> {
    let localUrl = 'assets/data/hotelres.json';
    return this.http.get(localUrl, { observe: 'response'});
  }
}

// compodoc -p tsconfig.json -s --hideGenerator --disableTemplateTab --disableSourceCode --disableStyleTab --theme gitbook
