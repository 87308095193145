import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInformationComponent } from './search-information.component';
import {RouterModule, Routes} from '@angular/router';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import { FlightCardModule } from 'src/app/components/shared/flight-card/flight-card.module';
import { HotelCardModule } from 'src/app/components/shared/hotel-card/hotel-card.module';
import { TrainCardModule } from 'src/app/components/shared/train-card/train-card.module';
import { VehicleCardModule } from 'src/app/components/shared/vehicle-card/vehicle-card.module';


const routes: Routes = [ {
  path: 'search-info',
  component: SearchInformationComponent
}];

@NgModule({
  declarations: [SearchInformationComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatButtonModule,
    FlightCardModule,
    HotelCardModule,
    TrainCardModule,
    VehicleCardModule,
  ],
  exports:[
    SearchInformationComponent,
  ]
})
export class SearchInformationModule { }
