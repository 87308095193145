import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss']
})
export class PoliciesComponent implements OnInit {
  @Input() company: any;
  tptype: string;
  editFlightBudget: boolean;
  editFlightBase: boolean;
  flightBase: any;
  newroute: any;
  hotelBase: any;
  maxstars: any;
  newdestination: any;
  approvals: any[];
  newApproval: any;
  editApprovals: boolean;
  addApprovals: boolean;
  editing: any;
  loadingtype: boolean;
  editHotelBudget: boolean;
  editHotelBase: boolean;
  constructor() {
    this.tptype = 'flights';
    this.editFlightBase = false;
    this.editFlightBudget = false;

    this.flightBase = {
      advanceBooking: 10,
      defaultcabin: 'Economy, Economy Premium',
      allowClass: 'First Class',
      allowClassForMore: 7,
      globalBudget: 150,
      flightExeptions: {
        allowUpTo: 200,
        forMoreThan: 4
      },
      routes: [{
        from: 'Milano (MXP)',
        to: 'New York (JFK)',
        type: 'roundtrip',
        budget: 2000,
      }]
    };

    this.newroute = {
      from: '',
      to: '',
      type: 'roundtrip',
      budget: 0,
    };

    this.hotelBase = {
      advanceBooking: 30,
      defaultStars: 2,
      globalBudget: 60,
      destinations: [
        {
          name: 'Miami, Florida',
          budget: 100,
        },
        {
          name: 'San Francisco, California',
          budget: 40,
        }
      ]
    };
    this.maxstars = 5;

    this.newdestination = {
      name: '',
      budget: 0,
    };

    this.approvals = [
      {
        type: 'user',
        name: 'Mario Rossi',
        needApprovalType: true,
        approvalType: 1,
        moderator: 'Vincenzo Bianchi'
      }
    ];

    this.newApproval = {
      type: 'group',
      name: '',
      needApprovalType: false,
      approvalType: 0,
      moderator: ''
    };
  }

  ngOnInit(): void {
  }

  addRoute() {
    this.flightBase.routes.push(JSON.parse(JSON.stringify(this.newroute)));
  }

  removeRoute(value) {
    this.flightBase.routes.splice(value, 1);
  }

  addDestination() {
    this.hotelBase.destinations.push(JSON.parse(JSON.stringify(this.newdestination)));
  }

  removeDestination(value) {
    this.hotelBase.destinations.splice(value, 1);
  }

  setStars(value) {
    this.hotelBase.defaultStars = value;
  }

  editApproval(value) {
    this.editApprovals = true;
    this.addApprovals = false;
    this.editing = this.approvals[value];
  }

  addApproval() {
    this.editApprovals = false;
    this.addApprovals = true;
  }

  addNewApproval() {
    this.approvals.push(JSON.parse(JSON.stringify(this.newApproval)));
    this.changeType('approvals');
  }

  removeApproval(value) {
    this.approvals.splice(value, 1);
  }

  changeType(value) {
    this.editApprovals = false;
    this.addApprovals = false;
    this.loadingtype = true;
    this.tptype = value;
    this.editFlightBase = false;
    this.editFlightBudget = false;
    this.editHotelBase = false;
    this.editHotelBudget = false;
    setTimeout(() => {
      this.loadingtype = false;
    }, 1000);
  }

  editFlpolicy() {
    this.editFlightBase = true;
  }

  editHtpolicy() {
    this.editHotelBase = true;
  }

  editFlbudget() {
    this.editFlightBudget = true;
  }

  editHtbudget() {
    this.editHotelBudget = true;
  }

}
