import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import * as $ from 'jquery';
import {SearchRes} from '../../../../../../classes/flights/search-res';
import {FlightService} from '../../../../../../services/flight/flight.service';
import {AppComponent} from '../../../../../../app.component';
import {IatacompleteService} from '../../../../../../services/iatacomplete/iatacomplete.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MainComponent} from '../../../../../mainmodule/main/main.component';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {ActivatedRoute, Router,} from '@angular/router';
import {Location} from '@angular/common';

const SEARCH_TYPE = {
  1: 'oneway',
  2: 'roundtrip',
  3: 'multy'
};

/**
 * Component container for flight search form
 */
@Component({
  selector: 'app-searchformatc',
  templateUrl: './searchformatc.component.html',
  styleUrls: ['./searchformatc.component.scss'],
})
export class SearchformatcComponent implements OnInit {
  @Input() pnr: any;
  @Input() allowedCarriers: any;
  /**
   *  Local variable for request passed by parent controller
   */
  @Input() request: any;
  /**
   *  Local variable for singleSection passed by parent controller
   */
  @Input() singleSection: any;
  /**
   *  object for selections passed by parent controller
   */
  @Input() selections: {};
  /**
   *  Local variable for searchResults by parent controller
   */
  @Input() searchResults: SearchRes;
  /**
   *  Output searchResults to parent controller
   */
    // tslint:disable-next-line:no-output-rename
  @Output('update') change: EventEmitter<SearchRes> = new EventEmitter<SearchRes>();
  /**
   *  Output selections to parent controller
   */
    // tslint:disable-next-line:no-output-rename
  @Output('updateSel') updateSel: EventEmitter<any> = new EventEmitter<any>();
  /**
   *  Output request update to parent controller
   */
    // tslint:disable-next-line:no-output-rename
  @Output('updateReq') updateReq: EventEmitter<any> = new EventEmitter<any>();
  /**
   *  Output reset form to parent controller
   */
    // tslint:disable-next-line:no-output-rename no-output-native
  @Output('reset') resetReq: EventEmitter<any> = new EventEmitter<any>();
  /**
   *  Local variable for single section departure array
   */
  from: any = [];
  /**
   *  Local variable for single section arrival array
   */
  to: any = [];
  /**
   *  Local variable for single section by locations array
   */
  via: any = [];
  /**
   *  Local variable for global itinerary carriers array
   */
  carrierToFilter: any = [];
  /**
   *  Local variable for loading
   */
  loading = false;
  /**
   *  Local variable for single carrier
   */
  carrier: string;
  /**
   *  Local variable to check if first flight is in past date
   */
  isInPast: boolean;
  /**
   *  Local variable to know if start corporate fares search
   */
  showCorporate: false;
  /**
   *  Local variable for today date
   */
  today: Date = new Date();
  /**
   *  Local variable for error
   */
  error: any;
  /**
   *  Local variable to set search form visible or not
   */
  formvisible: boolean;
  /**
   *  Local variable to set other form visible or not
   */
  othersOptionsOpen = false;
  /**
   *  Local variable to activate b2b options
   */
  isB2B: boolean;
  cacheId: string;
  /**
   *  Local variable to active low cost loader
   */
  corporateCodes: any = [];
  /**
   *  the constructor
   *  @params {flightService} FlightService
   */
  classSelected: any;

  departureGroups: any = [];
  arrivalGroups: any = [];

  constructor(
    private flightService: FlightService,
    public appComponent: AppComponent,
    public iataComplete: IatacompleteService,
    private snackBar: MatSnackBar,
    public mainComponent: MainComponent,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
  ) {
  }

  /**
   *  If localstorage is empty add the first flight section to form, otherwise load form from localstorage
   */
  ngOnInit(): void {
    this.isB2B = JSON.parse(localStorage.getItem('isB2B'));
    this.formvisible = true;
    if (!localStorage.getItem('searchForm')) {
      this.addFormSection();

      if (localStorage.getItem('sarceDossier')) {
        const sarce = JSON.parse(localStorage.getItem('sarceDossier'));
        this.addFormSection();
        this.request.sections[0].options.date = sarce.start;
        this.request.sections[1].options.date = sarce.end;
      }
    }
    if (this.searchResults && this.searchResults.itineraries) {
      this.toggleForm();
    }
    this.flightService.getCorporateCode(this.mainComponent.loggedUser.company._id)
      .subscribe((res) => res.list.forEach(r => this.corporateCodes.push({name: r.name, code: r.code})));

    this.loadPnr();
    this.request.edit = {};
    console.log('req', this.request);
  }

  /**
   *  Add a section to search form
   */
  addFormSection() {
    const sect = JSON.parse(JSON.stringify(this.singleSection));
    this.request.sections.push(sect);
    if (this.request.sections.length === 2) {
      this.request.sections[1].options.date = new Date(this.request.sections[0].options.date);
    }
    const index = this.request.sections.length - 1;
    if (this.request.sections.length > 1) {
      if (this.request.sections[index - 1].to.length > 0) {
        this.request.sections[index].from = JSON.parse(JSON.stringify(this.request.sections[index - 1].to));
      }
      if (this.request.sections[index - 1].from.length > 0) {
        this.request.sections[index].to = JSON.parse(JSON.stringify(this.request.sections[index - 1].from));
      }
    }
    this.from.push('');
    this.to.push('');
    this.via.push('');
    if (this.request.options.cabin !== '') {
      this.setClassValues(this.request.options.cabin);
    }
  }

  /**
   *  Add a section to search form
   */
  addNewSection() {
    this.addFormSection();
    // tslint:disable-next-line:max-line-length
    this.request.sections[this.request.sections.length - 1].options.date = new Date(this.request.sections[this.request.sections.length - 2].options.date);
    if (this.request.sections.length === 1) {
      this.request.searchType = 'oneway';
    }
  }

  // PAX MANAGEMENT
  /**
   *  Add a pax to search form
   */
  addPax(value) {
    switch (value.toLowerCase()) {
      case 'adt':
        this.request.passengers.adt = this.request.passengers.adt + 1;
        break;
      case 'chd':
        this.request.passengers.chd = this.request.passengers.chd + 1;
        break;
      case 'inf':
        this.request.passengers.inf = this.request.passengers.inf + 1;
        break;
    }
  }

  /**
   *  Remove pax to search form
   */
  removePax(value) {
    switch (value.toLowerCase()) {
      case 'adt':
        this.request.passengers.adt = this.request.passengers.adt - 1;
        break;
      case 'chd':
        this.request.passengers.chd = this.request.passengers.chd - 1;
        break;
      case 'inf':
        this.request.passengers.inf = this.request.passengers.inf - 1;
        break;
    }
  }

  /**
   *  Count passengers and return count
   */
  paxCounter() {
    return this.request.passengers.adt + this.request.passengers.chd + this.request.passengers.inf;
  }

  /**
   *  Check max passengers number
   */
  maxPaxReached() {
    return this.request.passengers.adt + this.request.passengers.chd + this.request.passengers.inf === 6;
  }

  /**
   *  Check max infant number; max one per adult
   */
  maxInfReached() {
    return this.request.passengers.adt + this.request.passengers.chd + this.request.passengers.inf === 6 ||
      this.request.passengers.adt === this.request.passengers.inf;
  }

  // END PAX MANAGEMENT

  // TYPE SEARCH MANAGEMENT (roundtrip..)

  /**
   *  Change itinerary type (roundtrip, oneway, multy)
   */
  changeType() {
    console.log('this.sections enter => ', this.request.sections);
    if (this.request.searchType === 'oneway') {
      if (this.request.sections.length > 1) {
        this.request.sections.length = 1;
      }
    } else if (this.request.searchType !== 'oneway' && this.request.sections.length === 1) {
      // this.addFormSection();
    } else if (this.request.searchType === 'roundtrip' && this.request.sections.length >= 2) {
      this.request.sections.length = 1;
    }
    console.log('this.sections exit => ', this.request.sections);
  }

  /**
   *  Add location to departure array
   */
  pushFrom(z, value) {
    this.request.sections[z].from.push(value.toUpperCase());
  }

  /**
   *  Add location to destination array
   */
  pushTo(z, value) {
    this.request.sections[z].to.push(value.toUpperCase());
  }

  /**
   *  Add location to transit array
   */
  pushBy(z, value) {
    this.request.sections[z].options.viaAirport.push(value.toUpperCase());
  }

  /**
   *  Add carrier to carriers array
   */
  pushCarrier(value) {
    this.request.options.carrierIdentification.push(value.toUpperCase());
    delete this.carrier;
  }

  /**
   *  Remove departure location from departure array
   */
  removeIataFrom(segindex, iataindex) {
    this.request.sections[segindex].from.splice(iataindex, 1);
    this.request.sections[segindex].fromLegend.splice(iataindex, 1);
  }

  /**
   *  Remove arrival location from arrival array
   */
  removeIataTo(segindex, iataindex) {
    this.request.sections[segindex].to.splice(iataindex, 1);
    this.request.sections[segindex].toLegend.splice(iataindex, 1);
  }

  /**
   *  Remove transit location from transit array
   */
  removeIataVia(segindex, iataindex) {
    this.request.sections[segindex].options.viaAirport.splice(iataindex, 1);
  }

  /**
   *  Show or hide search form
   */
  toggleCollapse(index) {
    $('#collapseF' + index).collapse('toggle');
  }

  /*****/
  /**
   *  Set default arrival date of single section based on departure date
   */
  setNextDate(index) {
    if (index + 1 < this.request.sections.length) {
      const beforeDate = new Date(this.request.sections[index].options.date).getTime();
      const current = new Date(this.request.sections[index + 1].options.date).getTime();
      if (beforeDate > current) {
        this.request.sections[index + 1].options.date = new Date(this.request.sections[index].options.date);
      }
    }
  }

  /**
   *  Set the minimum date for date picker
   */
  getMinDate(index) {
    if (index === 0) {
      return new Date(this.today);
    } else {
      return new Date(this.request.sections[index - 1].options.date);
    }
  }

  /******/

  /**
   *  Set alliance search and modify search form if not compatible with alliance search
   */
  setAlliance(event) {
    this.request.options.carrierIdentification = [];
    this.request.options.carrierQualifier = '';
    this.request.options.family = false;
    this.request.options.etnic = false;
    this.request.options.tourOperator = false;
    if (event === true) {
      this.toggleOptions(true);
      $('.animationContent').addClass('shaking');
      setTimeout(() => {
        $('.animationContent').removeClass('shaking');
      }, 900);
    }
  }

  /**
   *  Set alitalia family search and modify search form if not compatible with alitalia family search
   */
  setFamily(event) {
    if (event === true) {
      this.request.searchType = 'roundtrip';
      this.request.passengers.adt = 2;
      this.request.passengers.chd = 1;
      this.carrier = 'AZ';
      this.request.options.carrierIdentification.push('AZ');
      this.request.options.carrierQualifier = 'AZ';
      this.request.options.alliance = false;
      this.request.options.etnic = false;
      this.request.options.tourOperator = false;
      $('.animationContent').addClass('shaking');
      $('.watchFare').addClass('shaking');
      setTimeout(() => {
        $('.animationContent').removeClass('shaking');
        $('.watchFare').removeClass('shaking');
      }, 900);

    } else {
      this.request.passengers.adt = 1;
      this.request.passengers.chd = 0;
      this.request.options.carrierIdentification = [];
      this.request.options.carrierQualifier = '';
      this.carrier = '';
    }
  }

  /**
   *  Set etnic search and modify search form if not compatible with etnic search
   */
  setEtnic(event) {
    this.request.options.carrierIdentification = [];
    this.request.options.carrierQualifier = '';
    this.request.options.family = false;
    this.request.options.alliance = false;
    this.request.options.refundable = false;
    this.request.options.tourOperator = false;
    if (event === true) {
      $('.animationContent').addClass('shaking');
      setTimeout(() => {
        $('.animationContent').removeClass('shaking');
      }, 900);
    }
  }

  /**
   *  Set refund search and modify search form if not compatible with refund search
   */
  setRefound(event) {
    this.request.options.etnic = false;
    this.request.options.tourOperator = false;
    this.request.options.corporate = '';
  }

  /**
   *  Set tour operator search and modify search form if not compatible with tour operator search
   */
  settourOperator(event) {
    this.request.options.carrierIdentification = [];
    this.request.options.carrierQualifier = '';
    this.request.options.family = false;
    this.request.options.alliance = false;
    this.request.options.refundable = false;
    this.request.options.etnic = false;
    this.request.options.corporate = '';
    if (event === true) {
      $('.animationContent').addClass('shaking');
      setTimeout(() => {
        $('.animationContent').removeClass('shaking');
      }, 900);
    }
  }

  /**
   *  Set corporate fares search and modify search form if not compatible with corporate fares search
   */
  setCorporate(event) {
    this.request.options.carrierIdentification = [];
    this.request.options.carrierQualifier = '';
    this.request.options.alliance = false;
    this.request.options.family = false;
    this.request.options.refundable = false;
    this.request.options.etnic = false;
    this.request.options.tourOperator = false;
    this.request.passengers.inf = 0;
    this.request.passengers.chd = 0;
    if (event === true) {
      // this.getCorporates();
      // this.request.options.corporate = 'IT30286';
    } else {
      this.request.options.carrierIdentification = [];
      this.request.options.carrierQualifier = '';
      this.request.options.corporate = '';
    }
  }

  setResidents(event) {
    this.request.options.carrierIdentification = [];
    this.request.options.carrierQualifier = '';
    this.request.options.alliance = false;
    this.request.options.family = false;
    this.request.options.refundable = false;
    this.request.options.etnic = false;
    this.request.options.tourOperator = false;
    this.request.options.resident = event;
  }

  checkResidents() {
    const validResidentItinerary = (value) => (
      (value.from.includes('OLB') && value.to.includes('FCO')) ||
      (value.from.includes('FCO') && value.to.includes('OLB')) ||
      (value.from.includes('LIN') && value.to.includes('CAG')) ||
      (value.from.includes('CAG') && value.to.includes('LIN')) ||
      (value.from.includes('LIN') && value.to.includes('AHO')) ||
      (value.from.includes('AHO') && value.to.includes('LIN')) ||
      (value.from.includes('FCO') && value.to.includes('CAG')) ||
      (value.from.includes('FCO') && value.to.includes('AHO')) ||

      (value.from.includes('CAG') && value.to.includes('FCO')) ||
      (value.from.includes('AHO') && value.to.includes('FCO')) ||
      (value.from.includes('LIN') && value.to.includes('OLB')) ||
      (value.from.includes('OLB') && value.to.includes('LIN')) ||
      (value.from.includes('MXP') && value.to.includes('CAG')) ||
      (value.from.includes('CAG') && value.to.includes('MXP')) ||
      (value.from.includes('MXP') && value.to.includes('AHO')) ||
      (value.from.includes('AHO') && value.to.includes('MXP')) ||
      (value.from.includes('OLB') && value.to.includes('MXP'))
    );
    return this.request.sections.every(validResidentItinerary);
  }

  toggleInfoResidents() {
    $('#infoResidentsModal').modal('toggle');
  }

  /**
   *  Set corporate fare to form
   */
  setCorporateValue(event) {
    this.request.options.corporate = event.code;
    this.request.options.carrierIdentification.push(event.carrier);
    $('.animationContent').addClass('shaking');
    setTimeout(() => {
      $('.animationContent').removeClass('shaking');
    }, 900);
  }

  /**
   *  CHeck if search form is valid
   */
  isValid() {
    let invalid = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.request.sections.length; i++) {
      const section = this.request.sections[i];
      if (section.from.length === 0 || section.to.length === 0 || !section.options.date) {
        invalid = true;
        return invalid;
      }
    }
    if (this.request.searchType === 'roundtrip' && this.request.sections.length === 1) {
      invalid = true;
      return invalid;
    }
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.request.sections.length; i++) {
      const secDate = new Date(this.request.sections[i].options.date);
      secDate.setHours(0, 0, 0, 0);
      if (secDate < now) {
        this.isInPast = true;
        invalid = true;
        return invalid;
      }
    }

    if (this.showCorporate && !this.request.options.corporate) {
      invalid = true;
      return invalid;
    }
    if (this.request.options.alliance && this.request.options.carrierIdentification.length === 0) {
      invalid = true;
      return invalid;
    }
    // Nessun segmento selezionato per modifica
    return Object.keys(this.request.edit).every(k => !this.request.edit[k]);
  }

  executeFlightSearch(newReq) {

  }

  invertIata(z) {
    if (!this.request.edit[z]) return;
    const to = [...this.request.sections[z].to];
    const toLegend = [...this.request.sections[z].toLegend];
    const from = [...this.request.sections[z].from];
    const fromLegend = [...this.request.sections[z].fromLegend];
    this.request.sections[z].to = from;
    this.request.sections[z].toLegend = fromLegend;
    this.request.sections[z].from = to;
    this.request.sections[z].fromLegend = toLegend;
  }

  manageAmadeusResuls(res) {
    this.loading = false;
    this.selections = {};
    this.searchResults = res;
    // localStorage.setItem('searchRES', JSON.stringify(res));
    localStorage.setItem('searchForm', JSON.stringify(this.request));
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.searchResults.recommendations.length; i++) {
      // tslint:disable-next-line:max-line-length
      this.selections[this.searchResults.recommendations[i].ref] = this.searchResults.recommendations[i].itineraries.map(r => r.sections[0].ref);
      if (this.searchResults.recommendations[i].negotiated) {
        // console.log('boh'); // this.askFares(this.searchResults.recommendations[i].ref,i);
      }
    }
    console.log('this.selections => ', this.selections);
    this.change.emit(this.searchResults);
    this.updateSel.emit(this.selections);
    console.log('req', this.request);
    this.updateReq.emit(this.request);
    this.toggleForm();
  }

  /**
   *  Start flight search
   */
  startSearch(value, request) {
    if (request) {
      this.request = request;
    }
    this.request.options.calendar = value;
    if (this.searchResults) {
      delete this.searchResults;
      this.searchResults = new SearchRes();
      localStorage.removeItem('searchRES');
      localStorage.removeItem('searchForm');
      this.change.emit(this.searchResults);
    }
    // this.request.sections.forEach(r => r.options.date = r.options.date.toUTCString());
    const offset = new Date(this.request.sections[0].options.date).getTimezoneOffset();
    console.log('offset =>', offset);
    // this.request.sections.forEach(r => r.options.date = r.options.date.toDateString());
    const newReq = JSON.parse(JSON.stringify(this.request));
    newReq.options.cabin = '';
    // newReq.sections.forEach(r => r.options.date = new Date(new Date(r.options.date).setHours(6)));
    let sendTfReq = true;
    newReq.sections.forEach(r => {
      if (r.options.cabin === 'C' || r.options.cabin === 'F') {
        sendTfReq = false;
      }
      console.log('ciclo sulle sections ' + JSON.stringify(r));
      const tempDate = moment(r.options.date).set('hour', 6).set('minute', 0);
      r.options.date = tempDate.format('YYYY-MM-DDTHH:mm:ss.SSS').toString();
    });
    console.log('REQ', newReq);
    this.loading = true;
    delete newReq._id;
    this.flightService.sendFlightSearchATC(this.pnr._id, newReq).subscribe(this.manageAmadeusResuls.bind(this), error => {
      this.loading = false;
      this.error = error;
    });

  }

  /**
   *  CLear search form
   */
  clearSearch() {
    localStorage.removeItem('searchRES');
    localStorage.removeItem('searchForm');
    this.searchResults = new SearchRes();
    this.selections = {};
    this.change.emit(this.searchResults);
    this.updateSel.emit(this.selections);
    this.resetReq.emit();
    setTimeout(() => {
      this.addFormSection();
    }, 100);

  }

  /**
   *  toggle form visibility
   */
  toggleForm() {
    this.formvisible = !this.formvisible;
    $('#searchCard').collapse('toggle');
  }

  /**
   *  toggle search options visibility
   */
  toggleOptions(justOpen = false) {
    if ((justOpen && !this.othersOptionsOpen) || !justOpen) {
      $('#collapseOptions').collapse('toggle');
      this.othersOptionsOpen = !this.othersOptionsOpen;
    }
  }

  setClassValues(selectedTypeClass) {
    for (const s of this.request.sections) {
      s.options.cabin = '' + selectedTypeClass;
    }
  }

  singleCabinChange() {
    this.request.options.cabin = '';
  }

  private loadPnr() {
    this.request.passengers = {
      adt: 0,
      chd: 0,
      inf: 0,
    };
    this.request.sections = this.pnr.itineraries.map((f,i) => {
      if (this.request.edit && this.request.sections && this.request.edit[i] && this.request.sections[i]) return this.request.sections[i];
      const flights = Array.isArray(f.flights) ? f.flights : [f.flights];
      const startFlight = flights[0];
      const endFlight = flights[flights.length - 1];
      return {
        options: {
          date: startFlight.departure.date,
          minDate: this.today,
          departureTime: '',
          cabin: '', // f.bookingClass,
          viaAirport: [],
          expandAdvance: false,
          flightType: '',
          timeSlot: ''
        },
        from: [startFlight.departure.location],
        to: [endFlight.arrival.location],
        fromLegend: [startFlight.departure.location],
        toLegend: [endFlight.arrival.location],
      }
    });

    // Pax
    this.request.travellers = this.pnr.passengers.map(p => {
      this.addPax(p.type);
      return p;
    });
    // Class
    this.request.options.cabin = this.pnr.cabins.join('');
    // Options
    this.request.options.family = this.pnr.family;
    this.request.options.alliance = this.pnr.alliance;
    this.request.options.refundable = this.pnr.refundable;
    this.request.options.etnic = this.pnr.etnic;
    this.request.options.corporate = this.pnr.corporate;
    // Carrier
    this.request.options.carrierIdentification = this.allowedCarriers;
    this.request.options.carrierQualifier = '';
    this.request.searchType = this.request.sections.length > 2 ? SEARCH_TYPE[3] : SEARCH_TYPE[this.request.sections.length];
    return;
  }

  editSegment(flightIndex: number) {
    this.request.edit[flightIndex] = !this.request.edit[flightIndex];
    if (!this.request.edit[flightIndex]) {
      this.loadPnr();
    }
  }

}
