<button (click)="toggleModal()" [disabled]="isDisabled" id="TRAVEL_CHANGE" mat-button type="button">
  {{'TRAIN_MODULE.AFTERSALE.TRAVEL_CHANGE_ECONOMY'|translate}}
</button>

<div aria-hidden="true" aria-labelledby="changeEconomyModal" class="modal fade" data-backdrop="static" id="changeEconomyModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document" style="top:70px">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="changeEconomyModalTitle">{{'TRAIN_MODULE.AFTERSALE.CHANGE_DATE_QUESTION'|translate}}</h5>
        <button (click)="toggleModal(); reload()" *ngIf="!checkingDates" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <mat-stepper linear #stepper>
          <mat-step [stepControl]="searchForm">
            <form (submit)="checkDates()" [formGroup]="searchForm" id="frmDateSearch">
              <ng-template matStepLabel>{{'TRAIN_MODULE.AFTERSALE.CHANGE_DATE_QUESTION'|translate}}</ng-template>
              <div class="col-xs-12">
                <mat-form-field class="full-width">
                  <mat-label>{{'TRAIN_MODULE.DEPARTURE_DATE' | translate}}</mat-label>
                  <input (ngModelChange)="checkDates()" [min]="departureStartDate$ | async" [ngxMatDatetimePicker]="departure_date" formControlName="departure_date"
                         matInput>
                  <mat-datepicker-toggle [for]="departure_date" matSuffix></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #departure_date [showSeconds]="false" [showSpinners]="true" [stepHour]="1" [stepMinute]="5" [stepSecond]="1">
                  </ngx-mat-datetime-picker>
                </mat-form-field>
              </div>
            </form>

            <div *ngIf="(checkingDates)">
              <div class="mb-2">{{'TRAIN_MODULE.LOADING'|translate}}</div>
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>

            <ng-container *ngIf="!checkingDates">
              <table *ngIf="newDates.length > 0 else noData" [dataSource]="newDates" class="mat-elevation-z1 mb-2" mat-table multiTemplateDataRows>

                <ng-container matColumnDef="stations">
                  <th *matHeaderCellDef mat-header-cell> {{ 'TRAIN_MODULE.DEP_ARR_STATIONS' | translate }} </th>
                  <td *matCellDef="let element" mat-cell>{{ getStationFromCatalog(element.parsedSolutionNodes[0].start.id)}} ->
                    {{ getStationFromCatalog(element.parsedSolutionNodes[element.parsedSolutionNodes.length - 1].end.id) }}</td>
                </ng-container>
                <ng-container matColumnDef="departure">
                  <th *matHeaderCellDef mat-header-cell> {{ 'TRAIN_MODULE.AFTERSALE.DEPARTURETIME' | translate }} </th>
                  <td *matCellDef="let element" mat-cell>{{element.parsedSolutionNodes[0].departureTime | date:'dd/MM/yyyy - HH:mm' }}</td>
                </ng-container>
                <ng-container matColumnDef="arrival">
                  <th *matHeaderCellDef mat-header-cell> {{ 'TRAIN_MODULE.AFTERSALE.ARRIVALTIME' | translate }} </th>
                  <td *matCellDef="let element" mat-cell>
                    {{element.parsedSolutionNodes[element.parsedSolutionNodes.length - 1].arrivalTime | date:'dd/MM/yyyy - HH:mm'}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="train">
                  <th *matHeaderCellDef mat-header-cell> {{ 'TRAIN_MODULE.AFTERSALE.DENOMINATION' | translate }} </th>
                  <td *matCellDef="let element" mat-cell>
                    {{element.parsedSolutionNodes.length === 1 ? getTrainName(element.parsedSolutionNodes[0].offeredTransportMeanDeparture) : '-' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="expandedSegments">
                  <th *matHeaderCellDef mat-header-cell></th>
                  <td *matCellDef="let elemento" [attr.colspan]="columnsToDisplay.length" class="pl-2 pr-2" mat-cell>
                    <ng-container *ngIf="expandedElement === elemento">
                      <tr class="element-detail pl-30">
                        <table class="table table-borderless w-100">
                          <tr *ngFor="let sn of elemento.parsedSolutionNodes; let i = index">
                            <td class="element-segments">{{ getStationFromCatalog(sn.start.id)}} -> {{ getStationFromCatalog(sn.end.id) }}</td>
                            <td class="element-segments">{{sn.departureTime | date:'dd/MM/yyyy - HH:mm'}}</td>
                            <td class="element-segments">{{sn.arrivalTime | date:'dd/MM/yyyy - HH:mm'}}</td>
                            <td class="element-segments">{{getTrainName(sn.offeredTransportMeanDeparture)}}</td>
                          </tr>
                        </table>
                      </tr>
                    </ng-container>
                  </td>
                </ng-container>


                <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
                <tr (click)="loadBasePrices(row)" *matRowDef="let row; columns: columnsToDisplay;"
                    [class.mat-expanded]="expandedElement === row" [ngClass]="isSelected(row)" class="example-element-row" mat-row></tr>
                <tr *matRowDef="let row; columns: ['expandedSegments']" class="detail-row" mat-row
                    [@detailExpand]="row.xmlId === expandedElement?.xmlId ? 'TRAIN_MODULE.expanded' : 'collapsed'"></tr>


              </table>
              <ng-template #noData>
                <div class="mb-2" *ngIf="searchForm.valid">
                  <mat-icon>warning</mat-icon>
                  {{'NO_RESULTS'|translate}}
                </div>
              </ng-template>
              <div *ngIf="!changeAttempt" class="text-right buttonrow">
                <button (click)="validateAlternative()" [disabled]="!newSelectedDate || (loadingPrices$ | async)  || (evaluateTravelLoading$ | async)"
                        mat-button type="button">
                  {{'VALIDATE'|translate}}
                  <span *ngIf="(loadingPrices$|async)"><mat-icon class="spin">autorenew</mat-icon></span>
                </button>
              </div>
            </ng-container>

          </mat-step>
          <mat-step [editable]="!!validatedTravel" [stepControl]="searchForm">
            <ng-template matStepLabel>{{'CONFIRM_SELECTIONS'|translate}}</ng-template>
            <div *ngIf="validatedTravel">
              <div class="row">
                <app-validated-travel-penalty [validatedTravel]="validatedTravel"></app-validated-travel-penalty>
              </div>
              <div *ngIf="!changeAttempt" class="text-right buttonrow">
                <button (click)="confirmChangeBase()" [disabled]="!newSelectedDate" mat-button type="button">
                  {{'CONFIRM_SELECTIONS'|translate}}
                  <span *ngIf="(changeDateReservation$|async)"><mat-icon class="spin">autorenew</mat-icon></span>
                </button>
              </div>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
</div>
