import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { of } from 'rxjs';
import { TrainUtils } from '../../train.utils';


@Component({
  selector: 'train-travel-solution-detail',
  templateUrl: './train-travel-solution-detail.component.html',
  styleUrls: ['./train-travel-solution-detail.component.scss'],
})
export class TrainTravelSolutionDetailComponent implements OnInit{
  @Input() travel : any;
  @Input() aftersale : any;
  @Input() catalog : any;
  @Input() selectedTravelSolution = 0;
  panelOpenState : boolean;
  showDeleted = false;
  pnr : string;
  @Output() selectedTravelSolutionEvent = new EventEmitter<number>();
  initialIndex = 0;
  travelSolutions : any[] = [];

  constructor(){ }

  ngOnInit() : void{
    const travelSolutions = TrainUtils.toArray(this.travel.travelSolutions);
    travelSolutions.forEach((ts, i) => {
      if (ts.state !== 'CLOSED') {
        this.initialIndex = i;
        this.selectedTravelSolutionEvent.emit(i);
      }
      this.travelSolutions.push(this.processTravelSolution(ts));
    });
    this.pnr = TrainUtils.toArray(this.travel.reservationContexts).find(rc => !!rc?.id)?.id;
  }

  private processTravelSolution(ts: any): any {
    const travellers = {};
    TrainUtils.toArray(this.travel.travellers).forEach(t => {
      travellers[t.xmlId] = {
        ...t,
        segments: []
      };
    });
    const process = (travelSolution) => {
      travelSolution.travellers = {};
      TrainUtils.toArray(travelSolution.solutionNodes).map(sn => {
        if (['SOLUTION_SEGMENT','SUBSCRIPTION_SEGMENT'].includes(sn.type)){
          return [sn];
        } else if (!!sn.subSegments){
          return sn.subSegments.filter(s => (['SOLUTION_SEGMENT','SUBSCRIPTION_SEGMENT'].includes(s.type)));
        } else {
          return [];
        }
      }).forEach(snArray => {
        snArray.forEach(sn => {
          const segmentOffers = TrainUtils.toArray(travelSolution.offeredServices).filter(o => o.solutionNodeRef === sn.idXml);
          const segmentTravellers : any = new Set(segmentOffers.map(o => o.bookingInfo.traveller));
          segmentTravellers.forEach(tid => {
            travelSolution.travellers[tid] = travelSolution.travellers[tid] ?
              travelSolution.travellers[tid] : JSON.parse(JSON.stringify(travellers[tid]));
            const travellerSegmentOffers = segmentOffers.filter(o => o.bookingInfo.traveller === tid).map(o => {
              return {
                ...o,
                afterSale: this.aftersale?.filter(psd => {
                  return psd.offeredServiceId.travelId === o.id.travelId &&
                    psd.offeredServiceId.travelSolutionId === o.id.travelSolutionId &&
                    psd.offeredServiceId.offeredServiceId === o.id.offeredServiceId
                })
              }
            });
            const deleted = travellerSegmentOffers.every(o => o.status === 'DELETED');
            travelSolution.travellers[tid].segments.push({ ...sn, deleted, offers: travellerSegmentOffers});
          });
        });
      });
      travelSolution.travellers = Object.values(travelSolution.travellers);
      return travelSolution;
    };
    if (ts.returnTravelSolution) {
      ts.returnTravelSolution = process(ts.returnTravelSolution);
    }
    return process(ts);
  }

  filteredOffers(offers: any){
    return offers.filter(o => o.status !== 'DELETED' || this.showDeleted);
  }

  getPayments(offer : any){
    return TrainUtils.toArray(this.travel.entitlements).filter(payment => payment.offeredServices === offer.xmlId);
  }

  getStationName(id : any){
    return this.catalog.stations[id];
  }

  getOfferName(id){
    return this.catalog.offers[id];
  }

  getServiceName(id){
    return this.catalog.services[id];
  }

  changeSelectedTab(event : MatTabChangeEvent){
    this.selectedTravelSolutionEvent.emit(event.index);
  }

  getFilteredSegments(pax : any){
    return pax.segments.filter(s => !s.deleted || this.showDeleted);
  }

  getAfterSaleDisabled(offer : any, action : string){
    return offer.afterSale.find(psd => psd.postSaleType.code === action && psd.allowed);
  }
}
