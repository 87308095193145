import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AllAuthorizationRequestsComponent} from './containers/all-authorization-requests/all-authorization-requests.component';
import {RouterModule, Routes} from '@angular/router';
import {AuthorizationCardComponent} from './components/authorization-card/authorization-card.component';
import {MatButtonModule} from '@angular/material/button';
import {NoteModalComponent} from './components/note-modal/note-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProductInfoDialogModule} from '../shared/product-info-dialog/product-info-dialog.module';

const routes: Routes = [ {
  path: 'all-auth',
  component: AllAuthorizationRequestsComponent
} ];

@NgModule({
  declarations: [ AllAuthorizationRequestsComponent, AuthorizationCardComponent, NoteModalComponent ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    ProductInfoDialogModule
  ]
})
export class AuthorizationRequestsModule {
}
