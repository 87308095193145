import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ApplyChange,
  ChangeDateResponse,
  TrainAllowedOperations,
  TrainCatalog,
  TrainTraveller,
  TrenitTraveller,
  ValidateChangeServiceResponse,
} from '../../../../classes/train.models';
import { TrainService } from '../../../../train.service';
import * as $ from 'jquery';
import { TrainUtils } from '../../../train.utils';
import { MatStepper } from '@angular/material/stepper';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'train-change-economy',
  templateUrl: './change-economy.component.html',
  styleUrls: ['./change-economy.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ChangeEconomyComponent implements OnInit{

  @Input() isDisabled : boolean;
  @Input() pnr : string;
  @Input() selectedTravelSolution = 0;
  @Input() allowedOperations : TrainAllowedOperations;
  @Input() travellers: TrainTraveller[] = [];
  @Output() reloadEvent = new EventEmitter<any>();
  @ViewChild(MatStepper) stepper;

  loadingPrices$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  evaluateTravelLoading$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  changeDateReservation$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  departureStartDate$ = new BehaviorSubject(new Date());
  public searchForm : UntypedFormGroup;

  datesFetched = false;
  checkingDates : boolean;
  changeAttempt : boolean;
  newDates : {travelSolution: any}[] = [];
  errorMessage : string;
  newSelectedDate : any;
  columnsToDisplay = ['stations', 'departure', 'arrival', 'train'];
  expandedElement : any;

  private changeDateEconomyResponse : ChangeDateResponse;
  searchBaseResponse : any;

  validatedTravel : ValidateChangeServiceResponse;


  /**
   *  Constructor
   */
  constructor(
    private fb : UntypedFormBuilder,
    private trainService : TrainService,
  ){ }

  /**
   *  OnInit function is empty for now
   */
  ngOnInit(){
    this.searchForm = this.fb.group({
      departure_date: [undefined, [Validators.required]],
      done: [undefined, [Validators.required]]
    });
  }

  toggleModal(){
    $('#changeEconomyModal').modal('toggle');
  }

  checkDates(){
    delete this.newSelectedDate;
    this.checkingDates = true;
    const newDate = this.searchForm.controls.departure_date.value;
    this.trainService.checkNewDatesEconomy(this.pnr, newDate.toISOString()).subscribe(data => {
      this.changeDateEconomyResponse = data;
      this.datesFetched = true;
      this.checkingDates = false;
      this.newDates = data.searchSimilarRecords.map((sr: any) => {
        return {
          ...sr,
          offeredServices: TrainUtils.toArray(sr.offeredServices),
          parsedSolutionNodes: this.getSolutionNodes(sr.solutionNodes),
        };
      }).sort();
    }, error => {
      this.datesFetched = true;
      this.checkingDates = false;
      console.error('Couldnt check dates =>', error);
    });
  }

  reload(){
    this.reloadEvent.emit(true);
  }

  isSelected(element){
    return this.newSelectedDate === element ? 'row-selected' : '';
  }

  getStationFromCatalog(id){
    return this.changeDateEconomyResponse.catalog.stations[id];
  }

  getSolutionNodes(solutionNodes){
    const solutionArray = TrainUtils.toArray(solutionNodes);
    const result = [];
    for (const solutionNode of solutionArray){
      if (solutionNode.subSegments){
        result.push(...this.getSolutionNodes(solutionNode.subSegments));
      } else if (solutionNode.type !== 'SOLUTION_SHOP' && solutionNode.type !== 'SOLUTION_LOCATION'){
        result.push(solutionNode);
      }
    }
    return result;
  }

  getTrainName(offeredTransportMeanDeparture){
    const name = this.changeDateEconomyResponse.catalog.trains[offeredTransportMeanDeparture?.classificationId];
    if (name) {
      return name + ' ' + offeredTransportMeanDeparture?.name;
    } else{
      return '-';
    }
  }

  validateAlternative(){
    this.evaluateTravelLoading$.next(true);
    const oldTravelSolutions = TrainUtils.toArray(this.changeDateEconomyResponse.travel.travelSolutions);
    const travelSolutions = oldTravelSolutions.concat(this.searchBaseResponse.travelSolution);
    const postSaleDetails = this.allowedOperations.postSaleDetails.filter(psd => psd.postSaleType.code === 'TRAVEL_CHANGE');
    const req = {
      postSaleDetails,
      travel: {
        ...this.changeDateEconomyResponse.travel,
        travelSolutions,
        travellers: TrainUtils.toArray(this.searchBaseResponse.travellers).concat(this.changeDateEconomyResponse.travel.travellers)
      },
    };
    this.trainService.validateAlternative(this.pnr, req).subscribe((data : ValidateChangeServiceResponse) => {
      this.evaluateTravelLoading$.next(false);
      this.validatedTravel = data;
      this.searchForm.patchValue({ done: true });
      this.stepper.next();
    }, err => {
      this.evaluateTravelLoading$.next(false);
    });
  }

  confirmChangeBase(){
    this.changeDateReservation$.next(true);
    const req : ApplyChange = {
      travel: this.validatedTravel.travel,
      selectedSimilarRecord: {
        validatedPostSaleRecord: this.validatedTravel.validatedPostSaleRecord,
        reusedPaymentRecords: this.validatedTravel.reusedPaymentRecords,
        reversedPaymentRecords: this.validatedTravel.reversedPaymentRecords,
        additionalCharges: this.validatedTravel.additionalCharges,
        missingAmount: this.validatedTravel.missingAmount,
      },
      notifyTraveller: true
    };
    this.trainService.confirmChangeService(this.pnr, req).subscribe(data => {
      this.changeDateReservation$.next(false);
      this.changeAttempt = true;
      this.reload();
    }, error => {
      this.changeDateReservation$.next(false);
      this.errorMessage = error;
      this.reload();
    });
  }

  loadBasePrices(travelSolution : any){
    if (travelSolution.parsedSolutionNodes?.length > 1){
      this.expandedElement = this.expandedElement === travelSolution ? null : travelSolution;
    }
    this.newSelectedDate = this.newSelectedDate === travelSolution ? null : travelSolution;
    if (this.newSelectedDate === travelSolution){
      const body = {
        travelSolution: this.newSelectedDate,
        travellers: this.travellers.map(p => {
          return {...p.doc, ...new TrenitTraveller(p.doc._id)};
        }),
        postSaleDetails: this.changeDateEconomyResponse.postSaleDetails,
        serviceId: this.allowedOperations.travel.travelSolutions.offeredServices.catalogServiceId.id
      };
      this.loadingPrices$.next(true);
      this.trainService.checkBaseEconomy(this.pnr, body).subscribe(res => {
        this.loadingPrices$.next(false);
        this.searchBaseResponse = res;
      });
    }
    return;
  }
}

