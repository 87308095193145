import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';

@Component({
  selector: 'app-policy-icon-inmodal',
  templateUrl: './policy-icon-inmodal.component.html',
  styleUrls: ['./policy-icon-inmodal.component.scss']
})
export class PolicyIconInmodalComponent implements OnInit {
  @Input() arrayOfAlerts: any[] = [];
  @Input() isB2B?: boolean;
  @Input() isSbt?: boolean;

  constructor(
  ) { }

  public static policyBlock(arrayOfAlerts : any[]){
    return !!arrayOfAlerts && arrayOfAlerts.length > 0 && arrayOfAlerts?.some(p => p.block);
  }

  ngOnInit(): void {
  }

}
