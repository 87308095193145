import { Injectable } from '@angular/core';
import {io} from 'socket.io-client';
import {HttpserviceService} from '../httpservice.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {SnackErrorComponent} from '../../modules/errorNotification/snackerror/snack-error.component';
@Injectable({
  providedIn: 'root'
})
export class SocketService extends HttpserviceService{
  socket: any;
  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
    const url = this.apiUrl.replace('/api/v2/','') + '?token=Bearer ' + localStorage.getItem('fl_access_token');
    this.socket = io(url, {
      path: '/api/v2/socket/connect/',
      autoConnect: false,
      transports: ['websocket', 'polling']
    });
  }

  connectSocket() {
    this.socket.open();
  }

  disconnectSocket() {
    this.socket.disconnect();
  }
}
