<div>
  <div class="buttonrow">
    <button mat-stroked-button [ngClass]="{'md-primary':tptype=='flights'}" (click)="changeType('flights')">
      <span *ngIf="tptype=='flights'" class="material-icons">keyboard_arrow_right</span> Volo
    </button>
    <button mat-stroked-button [ngClass]="{'md-primary':tptype=='hotels'}" (click)="changeType('hotels')">
      <span *ngIf="tptype=='hotels'" class="material-icons">keyboard_arrow_right</span> Hotel
    </button>

    <button mat-stroked-button [ngClass]="{'md-primary':tptype=='approvals'}" (click)="changeType('approvals')">
      <span *ngIf="tptype=='approvals'" class="material-icons">keyboard_arrow_right</span> Processo di approvazione
    </button>
  </div>

  <div class="loadingcircularatzero" *ngIf="loadingtype">
    <mat-spinner></mat-spinner>
  </div>

  <!-- FLIGHT  -->

  <div class="padding10" *ngIf="tptype=='flights' && !editFlightBase && !editFlightBudget">
    <hr/>
    <h5 class="marginbottom20">Prenotazione ed emissione voli</h5>
    <div class="row marginbottom20">
      <div class="col-md-3">
        <i>Prenotazione anticipata:</i>
      </div>
      <div class="col-md-9">
        I voli devono essere prenotati più di <b>{{flightBase.advanceBooking}}</b> giorni prima della data di partenza
      </div>
    </div>

    <div class="row marginbottom20">
      <div class="col-md-3">
        <i>Restrizione classi</i>
      </div>
      <div class="col-md-9">
        {{flightBase.defaultcabin}}
      </div>
    </div>

    <div class="row marginbottom20">
      <div class="col-md-3">
        <i>Eccezioni classi</i>
      </div>
      <div class="col-md-9">
        <b>{{flightBase.allowClass}}</b> è consentita per voli di durata superiore a <b>{{flightBase.allowClassForMore}}</b> ore
      </div>
    </div>
    <div class="buttonrow">
      <button mat-stroked-button (click)="editFlpolicy()">Modifica impostazioni standard</button>
    </div>

    <hr/>
    <h5 class="marginbottom20">Budget globale standard</h5>

    <div class="row marginbottom20">
      <div class="col-md-3">
        <i>Budget globale</i>
      </div>
      <div class="col-md-9">
        Tutte le destinazioni: <b>{{flightBase.globalBudget}}€</b>
      </div>
    </div>

    <div class="row marginbottom20">
      <div class="col-md-3">
        <i>Eccezioni durata volo</i>
      </div>
      <div class="col-md-9">
        Un massimo di <b>{{flightBase.flightExeptions.allowUpTo}}€</b> è consentito per voli di durata maggiore a <b>{{flightBase.flightExeptions.forMoreThan}}</b> ore.
      </div>
    </div>

    <div class="row marginbottom20">
      <div class="col-md-3">
        <i>Eccezioni rotte di volo</i>
      </div>
      <div class="col-md-9">
        <div *ngIf="flightBase.routes.length>0">
          <p class="marginbottom10" *ngFor="let route of flightBase.routes">
            {{route.from}}
            <span *ngIf="route.type=='oneway'" class="material-icons">
              arrow_right_alt
            </span>
            <span *ngIf="route.type=='roundtrip'" class="material-icons">
              sync_alt
            </span>
            {{route.to}} permette un budget massimo di <b>{{route.budget}}€</b>
          </p>
          <p *ngIf="flightBase.routes.length==0">Nessuna eccezione inserita</p>
        </div>

      </div>
    </div>
    <div class="buttonrow">
      <button mat-stroked-button (click)="editFlbudget()">Modifica budget standard</button>
    </div>

    <hr/>

  </div>

  <form class="padding10" name="tpform" *ngIf="editFlightBase">
    <hr/>
    <h5 class="marginbottom20">Modifica la standard policy di {{company.name}}</h5>
    <div class="marginbottom20">
      <span>I voli devono essere prenotati più di</span>
      <mat-form-field class="smallWidth">
        <input matInput type="number" [(ngModel)]="flightBase.advanceBooking" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <span>giorni prima della data di partenza</span>
    </div>
    <hr/>
    <h5 class="marginbottom20">Classi predefinite consentite per tutti i voli:</h5>
    <div class="marginbottom20">
      <mat-radio-group class="radio-group" [(ngModel)]="flightBase.defaultcabin" [ngModelOptions]="{standalone: true}">
        <mat-radio-button class="radio-button" value="Economy" >Economy</mat-radio-button>
        <mat-radio-button class="radio-button" value="Economy, Economy Premium" > Economy, Economy Premium </mat-radio-button>
        <mat-radio-button class="radio-button" value="Economy, Premium Economy e Business Class" > Economy, Premium Economy e Business Class </mat-radio-button>
        <mat-radio-button class="radio-button" value="Economy, Premium Economy, Business Class e First Class" > Economy, Premium Economy, Business Class e First Class</mat-radio-button>
      </mat-radio-group>
    </div>
    <hr/>
    <h5 class="marginbottom20">Eccezioni Classi:</h5>
    <div class="marginbottom20">
      <span>Permetti </span>
      <mat-form-field class="smallWidth">
        <mat-select [(ngModel)]="flightBase.allowClass" [ngModelOptions]="{standalone: true}">
          <mat-option><em>Nessuna classe</em></mat-option>
          <mat-option value="Business Class">Business Class</mat-option>
          <mat-option value="First Class">First Class</mat-option>
        </mat-select>
      </mat-form-field>
      <span>per voli di durata superiore a </span>
      <mat-form-field class="smallWidth">
        <input matInput type="number" [(ngModel)]="flightBase.allowClassForMore" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <span>ore</span>
    </div>
    <hr/>
    <div class="buttonrow">
      <button mat-stroked-button (click)="changeType('flights')">Salva</button>
      <button mat-stroked-button (click)="changeType('flights')">Annulla</button>
    </div>
  </form>

  <form class="padding10" name="tpform" *ngIf="editFlightBudget">
    <hr/>
    <h5 class="marginbottom20">Modifica il budget standard di {{company.name}}</h5>
    <div class="marginbottom20">
      <span>Budget standard per tutte le destinazioni</span>
      <mat-form-field class="smallWidth">
        <input matInput type="number" [(ngModel)]="flightBase.globalBudget" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      €
    </div>
    <hr/>
    <h5 class="marginbottom20">Eccezioni durata volo</h5>
    <div class="marginbottom20">
      <span>E' permesso un budget di</span>
      <mat-form-field class="smallWidth">
        <input matInput type="number" [(ngModel)]="flightBase.flightExeptions.allowUpTo" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      €
      <span>per voli di durata maggiore a</span>
      <mat-form-field class="smallWidth">
        <input matInput type="number" [(ngModel)]="flightBase.flightExeptions.forMoreThan" [ngModelOptions]="{standalone: true}"/>
      </mat-form-field>
      ore
    </div>

    <hr/>
    <h5 class="marginbottom20">Eccezioni rotte di volo</h5>
    <div class="marginbottom20">
      <div class="marginbottom20" *ngFor="let route of flightBase.routes let $index = index">
      <span class="blockmobile">
        Da
        <mat-form-field class="smallWidth">
          <input matInput type="text" [(ngModel)]="route.from" placeholder="partenza" [ngModelOptions]="{standalone: true}"/>
        </mat-form-field>

      </span>
        <span class="blockmobile">
        a
          <mat-form-field class="smallWidth">
            <input matInput  type="text" [(ngModel)]="route.to" placeholder="destinazione" [ngModelOptions]="{standalone: true}"/>
          </mat-form-field>

      </span>
        <span class="blockmobile">
          <mat-form-field class="smallWidth">
            <mat-select [(ngModel)]="route.type" [ngModelOptions]="{standalone: true}">
              <mat-option value="oneway">Sola andata</mat-option>
              <mat-option value="roundtrip">Andata e ritorno</mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span class="blockmobile">
          <mat-form-field class="smallWidth">
            <input matInput type="number" [(ngModel)]="route.budget" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
           €
        <button mat-icon-button aria-label="Cancella" (click)="removeRoute($index)">
          <span class="material-icons">
            close
          </span>
        </button>
      </span>
      </div>
      <div class="buttonrow">
        <button mat-stroked-button (click)="addRoute()">Aggiungi rotta</button>
      </div>


    </div>
    <hr/>
    <div class="buttonrow">
      <button mat-stroked-button (click)="changeType('flights')">Salva</button>
      <button mat-stroked-button (click)="changeType('flights')">Annulla</button>
    </div>
  </form>

  <!-- HOTEL  -->

  <div class="padding10" *ngIf="tptype=='hotels' && !editHotelBase && !editHotelBudget">
    <hr/>
    <h5 class="marginbottom20">Prenotazione hotel</h5>
    <div class="row marginbottom20">
      <div class="col-md-3">
        <i>Prenotazione anticipata:</i>
      </div>
      <div class="col-md-9">
        Gli hotel devono essere prenotati <b>{{hotelBase.advanceBooking}}</b> giorni prima della data di check-in
      </div>
    </div>

    <div class="row marginbottom20">
      <div class="col-md-3">
        <i>Massimo numero di stelle</i>
      </div>
      <div class="col-md-9">
        <span>
          <span *ngIf="hotelBase.defaultStars<1" class="material-icons">star_border</span>
          <span *ngIf="hotelBase.defaultStars >= 1" class="material-icons">star</span>
        </span>
        <span>
          <span *ngIf="hotelBase.defaultStars<2" class="material-icons">star_border</span>
          <span *ngIf="hotelBase.defaultStars >= 2" class="material-icons">star</span>
        </span>
        <span>
          <span *ngIf="hotelBase.defaultStars<3" class="material-icons">star_border</span>
          <span *ngIf="hotelBase.defaultStars >= 3" class="material-icons">star</span>
        </span>
        <span>
          <span *ngIf="hotelBase.defaultStars<4" class="material-icons">star_border</span>
          <span *ngIf="hotelBase.defaultStars >= 4" class="material-icons">star</span>
        </span>
        <span>
          <span *ngIf="hotelBase.defaultStars<5" class="material-icons">star_border</span>
          <span *ngIf="hotelBase.defaultStars >= 5" class="material-icons">star</span>
        </span>
      </div>
    </div>
    <div class="buttonrow">
      <button mat-stroked-button (click)="editHtpolicy()">Modifica impostazioni prenotazioni</button>
    </div>

    <hr/>
    <h5 class="marginbottom20">Budget globale standard</h5>

    <div class="row marginbottom20">
      <div class="col-md-3">
        <i>Budget globale</i>
      </div>
      <div class="col-md-9">
        Per tutte le destinazioni non più di <b>{{hotelBase.globalBudget|number:'.2-2'}} €</b> a notte.
      </div>
    </div>

    <div class="row marginbottom20">
      <div class="col-md-3">
        <i>Eccezioni Budget</i>
      </div>
      <div class="col-md-9">
        <div *ngIf="hotelBase.destinations.length>0">
          <p class="marginbottom10"  *ngFor="let destination of hotelBase.destinations">
            <span class="material-icons" *ngIf="destination.budget > hotelBase.globalBudget">
              trending_up
            </span>
            <span class="material-icons" *ngIf="destination.budget < hotelBase.globalBudget">
             trending_down
            </span>
            <span class="material-icons" *ngIf="destination.budget === hotelBase.globalBudget">
              arrow_right_alt
            </span>
            Per pernottamenti in <b>{{destination.name}}</b> non più di <b>{{destination.budget|number:'.2-2'}} €</b> a notte
            <span *ngIf="destination.budget==hotelBase.globalBudget" class="d-block text-danger">Eccezione uguale al budget globale</span>
          </p>
        </div>

      </div>
    </div>

    <div class="buttonrow">
      <button mat-stroked-button (click)="editHtbudget()">Modifica budget standard</button>
    </div>

  </div>

  <form class="padding10" name="tpform" *ngIf="editHotelBase">
    <hr/>
    <h5 class="marginbottom20">Modifica la standard policy di {{company.name}}</h5>
    <div class="marginbottom20">
      <span>Gli hotel devono essere prenotati più di</span>
      <mat-form-field class="smallWidth">
        <input matInput type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="hotelBase.advanceBooking">
      </mat-form-field>
      <span>giorni prima della data di check-in</span>
    </div>

    <div class="marginbottom20">
      <span style="margin-right:5px;">Massimo numero di stelle</span>
      <span (click)="setStars(1)">
        <span *ngIf="hotelBase.defaultStars<1" class="material-icons">star_border</span>
        <span *ngIf="hotelBase.defaultStars >= 1" class="material-icons">star</span>
      </span>
      <span (click)="setStars(2)">
        <span *ngIf="hotelBase.defaultStars<2" class="material-icons">star_border</span>
        <span *ngIf="hotelBase.defaultStars >= 2" class="material-icons">star</span>
      </span>
      <span (click)="setStars(3)">
        <span *ngIf="hotelBase.defaultStars<3" class="material-icons">star_border</span>
        <span *ngIf="hotelBase.defaultStars >= 3" class="material-icons">star</span>
      </span>
      <span (click)="setStars(4)">
        <span *ngIf="hotelBase.defaultStars<4" class="material-icons">star_border</span>
        <span *ngIf="hotelBase.defaultStars >= 4" class="material-icons">star</span>
      </span>
      <span (click)="setStars(5)">
        <span *ngIf="hotelBase.defaultStars<5" class="material-icons">star_border</span>
        <span *ngIf="hotelBase.defaultStars >= 5" class="material-icons">star</span>
      </span>
    </div>

    <hr/>
    <div class="buttonrow">
      <button mat-stroked-button (click)="changeType('hotels')">Salva</button>
      <button mat-stroked-button (click)="changeType('hotels')">Annulla</button>
    </div>
  </form>

  <form class="padding10" name="tpform" *ngIf="editHotelBudget">
    <hr/>
    <h5 class="marginbottom20">Modifica il budget standard di {{company.name}}</h5>
    <div class="marginbottom20">
      <span>Budget standard per tutte le destinazioni</span>
      <mat-form-field class="smallWidth">
        <input matInput type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="hotelBase.globalBudget">
      </mat-form-field>
      €
    </div>

    <hr/>
    <h5 class="marginbottom20">Eccezioni destinazioni</h5>
    <div class="marginbottom20">
      <div class="marginbottom20" *ngFor="let destination of hotelBase.destinations;let $index = index">
        <span class="blockmobile">
          <mat-form-field>
            <input matInput type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="destination.name" placeholder="Destinazione"/>
          </mat-form-field>
        </span>
        <span class="blockmobile">
          <mat-form-field class="smallWidth">
            <input matInput type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="destination.budget">
          </mat-form-field> €
          <button mat-icon-button (click)="removeDestination($index)">
            <mat-icon>close</mat-icon>
          </button>
        </span>
        <span *ngIf="destination.budget==hotelBase.globalBudget" class="text-danger d-block">Eccezione uguale al budget globale</span>
      </div>
      <div class="buttonrow">
        <button mat-stroked-button (click)="addDestination()">Aggiungi destinazione</button>
      </div>
    </div>
    <hr/>
    <div class="buttonrow">
      <button mat-stroked-button (click)="changeType('hotels')">Salva</button>
      <button mat-stroked-button (click)="changeType('hotels')">Annulla</button>
    </div>
  </form>

  <!-- APPROVAL -->

  <div class="padding10" *ngIf="tptype=='approvals' && !editApprovals && !addApprovals">
    <hr/>
    <h5 class="marginbottom20">Processo di approvazione</h5>

    <div class="row marginbottom20" *ngIf="approvals.length==0">

      <div class="col-md-12">
        Nessun processo di approvazione inserito. Gli utenti possono prenotare senza restrizioni
      </div>
    </div>
    <div class="row" *ngIf="approvals.length>0">

      <div class="col-md-4"  *ngFor="let single of approvals; let $index = index">
        <div class="aprovalCard">
          <div>
            <p class="smalltitle">
              <span *ngIf="single.type=='group'" class="material-icons">
                group
              </span>
              <span *ngIf="single.type=='user'" class="material-icons">
                person
              </span>
              {{ single.name }}
            </p>
            <p>APPROVAZIONE:</p>
            <div *ngIf="single.needApprovalType">
              <p>
                <span *ngIf="single.approvalType==1">Tutte le prenotazioni</span>
                <span *ngIf="single.approvalType==0">Prenotazioni fuori dalla travel policy </span>
              </p>
              <p>Supervisore: <i>{{single.moderator}}</i></p>
            </div>
            <p *ngIf="!single.needApprovalType">Non necessita di approvazione</p>
            <p *ngIf="!single.moderator">Nessun supervisore</p>
            <p *ngIf="!single.needApprovalType && single.moderator">Supervisore: <i>{{single.moderator}}</i></p>
            <hr/>
            <div class="buttonrow">
              <button mat-stroked-button (click)="editApproval($index)">Modifica</button>
              <button mat-stroked-button (click)="removeApproval($index)">Cancella</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttonrow">
      <button mat-stroked-button (click)="addApproval()">Aggiungi processo di approvazione</button>
    </div>

  </div>

  <form class="padding10" name="tpform" *ngIf="editApprovals">
    <hr/>
    <h5 class="marginbottom20">Processo di approvazione per {{editing.name}}</h5>
    <hr/>
    <div class="marginbottom20">
      <p class="marginbottom20">Impostazioni approvazione</p>
      <div class="">
        <mat-radio-group class="radio-group" [ngModelOptions]="{standalone: true}" [(ngModel)]="editing.needApprovalType">
          <mat-radio-button class="radio-button" [value]="false">Può prenotare senza approvazioni</mat-radio-button>
          <mat-radio-button class="radio-button" [value]="true">Ha bisogno di approvazione per prenotare</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="" *ngIf="editing.needApprovalType">
        <hr/>
        <p class="marginbottom20">Deve ricevere approvazione per:</p>
        <div class="">
          <mat-radio-group class="radio-group" [ngModelOptions]="{standalone: true}" [(ngModel)]="editing.approvalType">
            <mat-radio-button class="radio-button" [value]="0">le prenotazioni fuori dalla travel policy</mat-radio-button>
            <mat-radio-button class="radio-button" [value]="1">tutte le prenotazioni</mat-radio-button>
          </mat-radio-group>
        </div>

      </div>
    </div>
    <hr/>
    <h5 class="marginbottom20">Chi può approvare le prenotazioni per questo gruppo o persona?</h5>
    <div class="marginbottom20">
      <span class="material-icons">
       security
      </span>
      <mat-form-field class="smallWidth">
        <mat-select class="autowidth" [ngModelOptions]="{standalone: true}" [(ngModel)]="editing.moderator">
          <mat-option value="Vincenzo Bianchi">
            Vincenzo Bianchi
          </mat-option>
          <mat-option value="Francesca Verdi">
            Francesca Verdi
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <hr/>
    <div class="buttonrow">
      <button mat-stroked-button (click)="changeType('approvals')">Salva</button>
      <button mat-stroked-button (click)="changeType('approvals')">Annulla</button>
    </div>
  </form>

  <form class="padding10" name="tpform" *ngIf="addApprovals">
    <hr/>
    <p class="marginbottom20">
      Aggiungi processo di approvazione per
      <mat-form-field class="smallWidth">
        <mat-select class="autowidth" [ngModelOptions]="{standalone: true}" [(ngModel)]="newApproval.type">
          <mat-option value="user">
            SIngolo utente
          </mat-option>
          <mat-option value="group">
            Gruppo di utenti
          </mat-option>
        </mat-select>
      </mat-form-field>

    </p>
    <div class="marginbottom20">
      <label *ngIf="newApproval.type=='user'">Seleziona utente</label>
      <mat-form-field class="smallWidth" *ngIf="newApproval.type=='user'">
        <mat-select class="autowidth" [ngModelOptions]="{standalone: true}" [(ngModel)]="newApproval.name">
          <mat-option value="Carla Blu">
            Carla Verona
          </mat-option>
          <mat-option value="Francesco Marrone">
            Francesco Marrone
          </mat-option>
        </mat-select>
      </mat-form-field>

      <label *ngIf="newApproval.type=='group'">Seleziona gruppo</label>
      <mat-form-field class="smallWidth" *ngIf="newApproval.type=='group'">
        <mat-select class="autowidth" [ngModelOptions]="{standalone: true}" [(ngModel)]="newApproval.name">
          <mat-option value="Gruppo IT">
            Gruppo IT
          </mat-option>
          <mat-option value="Gruppo Agenti">
            Gruppo Agenti
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <hr/>
    <div class="marginbottom20">
      <p class="marginbottom20">Impostazioni approvazione</p>
      <div class="">
        <mat-radio-group class="radio-group" [ngModelOptions]="{standalone: true}" [(ngModel)]="newApproval.needApprovalType">
          <mat-radio-button class="radio-button" [value]="false">Può prenotare senza approvazioni</mat-radio-button>
          <mat-radio-button class="radio-button" [value]="true">Ha bisogno di approvazione per prenotare</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="" *ngIf="newApproval.needApprovalType">
        <hr/>
        <p class="marginbottom20">Deve ricevere approvazione per:</p>
        <div class="">
          <mat-radio-group class="radio-group" [ngModelOptions]="{standalone: true}" [(ngModel)]="newApproval.approvalType">
            <mat-radio-button class="radio-button" [value]="0">le prenotazioni fuori dalla travel policy</mat-radio-button>
            <mat-radio-button class="radio-button" [value]="1">tutte le prenotazioni</mat-radio-button>
          </mat-radio-group>
        </div>

      </div>
    </div>
    <hr/>
    <h5 class="marginbottom20">Chi può approvare le prenotazioni per
      <span *ngIf="newApproval.type=='group'">questo gruppo</span>
      <span *ngIf="newApproval.type=='user'">questa persona</span>?</h5>
    <div class="marginbottom20">
      <span class="material-icons">
        security
      </span>
      <mat-form-field class="smallWidth">
        <mat-select [ngModelOptions]="{standalone: true}" [(ngModel)]="newApproval.moderator">
          <mat-option value="Vincenzo Bianchi">
            Vincenzo Bianchi
          </mat-option>
          <mat-option value="Francesca Verdi">
            Francesca Verdi
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <hr/>
    <div class="buttonrow">
      <button mat-stroked-button (click)="addNewApproval()">Salva</button>
      <button mat-stroked-button (click)="changeType('approvals')">Annulla</button>
    </div>
  </form>


</div>
