import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material/material.module';
import {TopbarComponent} from './layout/topbar/topbar.component';
import {SidemenuComponent} from './layout/sidemenu/sidemenu.component';
import {TicketingModule} from '../ticketing/ticketing.module';
import {NotificationModule} from '../notification/notification.module';
import {SettingsComponent} from './layout/settings/settings.component';
import {PolicyNotificationsComponent} from '../../components/policyNotifications/notifications.component';
import { SidemenuVersion2Component } from './layout/sidemenu-version2/sidemenu-version2.component';

@NgModule({
  declarations: [
    TopbarComponent,
    SidemenuComponent,
    SettingsComponent,
    PolicyNotificationsComponent,
    SidemenuVersion2Component,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    TicketingModule,
    NotificationModule,
  ],
  exports: [
    TopbarComponent,
    SidemenuComponent,
    SettingsComponent,
    TicketingModule,
    NotificationModule,
    PolicyNotificationsComponent,
    SidemenuVersion2Component
  ]
})
export class LayoutModule { }
