<div class="padding20">
    <custom-title></custom-title>
    <mat-card appearance="outlined" *ngIf="trainTravel else goBackTmpl" class="p-4">
        <div class="row p-2">
            <div class="col-12">
                <div *ngIf="booking as reservation">
                    <train-checkout-booking-summary [bookingDb]="reservation.bookingDb" [title]="'TRAIN_MODULE.ARRANGE_RESERVATION'"></train-checkout-booking-summary>
                </div>

                <div class="row p-2" *ngIf=" arrayOfAlerts().length === 0">
                    <div class="col-lg-12 mt-4" *ngIf="this.cards.length > 0">
                        <mat-card appearance="outlined" class="mat-elevation-z2 p-3" style="margin-top:10px;">
                            <strong>{{'TRAIN_MODULE.FORM.PAY.METHOD' | translate}}</strong>
                            <div *ngIf="!(fetchingCards$ | async) else fetchingCards" class="col-12">
                                <amadeus-cards (selectedCardEvent)="selectedCard = $event" [cards]="cards" class=""></amadeus-cards>
                            </div>
                            <ng-template #fetchingCards>
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </ng-template>
                        </mat-card>
                    </div>

                    <div class="col-lg-12 mt-4" *ngIf="this.cards.length === 0">
                        <mat-card appearance="outlined" class="mat-elevation-z2" style="margin-top:10px;">
                            <div>Non sono disponibili metodi di pagamento validi</div>
                        </mat-card>
                    </div>
                </div>

                <div class="row p-2" *ngIf="arrayOfAlerts().length === 0">
                    <div class="col-lg-6">
                        <mat-card appearance="outlined" *ngIf="contactForm" class="mat-elevation-z2 p-2" style="margin-top:10px;">
                            <strong>{{'TRAIN_MODULE.FORM.PAY.CONTACT' | translate}}</strong>
                            <div>
                                <form [formGroup]="contactForm" id="contactForm">
                                    <ng-container formGroupName="BookingContact">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <mat-form-field class="full-width">
                                                    <mat-label>{{'TRAIN_MODULE.FORM.PAX.EMAIL' | translate}}</mat-label>
                                                    <input email="true" formControlName="EmailAddress" matInput type="email"/>
                                                </mat-form-field>
                                                <ng-container *ngIf="contactForm.get('BookingContact').get('EmailAddress') as field">
                                                    <div *ngIf="field.invalid && (field.dirty || field.touched)" class="alert alert-danger">
                                                        <div *ngIf="field.errors.required">
                                                            {{'TRAIN_MODULE.VALIDATIONS.REQUIRED' | translate}}
                                                        </div>
                                                        <div *ngIf="field.errors.email">
                                                            {{'TRAIN_MODULE.VALIDATIONS.EMAIL' | translate}}
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">
                                                <mat-form-field class="full-width">
                                                    <mat-label>{{'TRAIN_MODULE.FORM.PAX.MOBILE' | translate}}</mat-label>
                                                    <input formControlName="Phone" matInput/>
                                                </mat-form-field>
                                                <ng-container *ngIf="contactForm.get('BookingContact').get('Phone') as field">
                                                    <div *ngIf="field.invalid && (field.dirty || field.touched)" class="alert alert-danger">
                                                        <div *ngIf="field.errors.required">
                                                            {{'TRAIN_MODULE.VALIDATIONS.REQUIRED' | translate}}
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-card>
                    </div>
                </div>

                <div class="row p-3 mb-2">
                  <app-sel-centro-di-costo [reservationId]="trainTravel.italo.holdBookingResponse.bookingDb._id" [costCenter]="costCenter"></app-sel-centro-di-costo>
                </div>
                <hr/>

                <div class="buttonrow mt-2 p-2 text-right">
                    <app-policy-approval-button [disabled]="submitDisabled()" [loading$]="processingPayment$" [reservation]="trainTravel.italo.holdBookingResponse.bookingDb" [type]="'train'" [arrayOfAlerts]="arrayOfAlerts()" (buyAction)="nextStep($event)"></app-policy-approval-button>
                </div>
            </div>
        </div>
    </mat-card>
    <ng-template #goBackTmpl>
        <a class="mat-elevation-z2" mat-button routerLink="/trains/search" type="button">
            {{'TRAIN_MODULE.NEW_SEARCH' | translate}}
            <mat-icon>search</mat-icon>
        </a>
    </ng-template>
</div>
