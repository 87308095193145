import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
declare var google : any;
import 'bootstrap';
import MarkerClusterer from '@google/markerclusterer';
import * as $ from 'jquery';
@Component({
  selector: 'app-map-results-dialog',
  templateUrl: './map-results-dialog.component.html',
  styleUrls: ['./map-results-dialog.component.scss']
})
export class MapResultsDialogComponent implements OnInit {
  map:any;
  markers: any;
  clusterMarker: any;
  constructor(
    public dialogRef: MatDialogRef<MapResultsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private router: Router,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.markers = [];
    console.log(this.data);
    setTimeout(() => {
      this.createMap();
    }, 200);
  }

  returnImg(hotel){
    let img = '';
    if(hotel.images){
      if (hotel.images.length > 0) {
        img = hotel.images[0];
      } else {
        img = 'assets/img/placeholder-image.png';
      }
    } else {
      img = 'assets/img/placeholder-image.png';
    }
    return img;
  }

  convertDistance(distance: number){
    if(distance < 1){
      return (Math.round(distance * 1000)) + ' mt';
    } else {
      return (Math.round(distance * 10) / 10) + ' km'
    }
  }

  starCounter(i : string){
    try{
      const s = parseFloat(i);
      return new Array(s);
    } catch (e) {
      return [];
    }
  }

  returnStars(i : string){
    let html = '';
    try{
      const s = parseFloat(i);
      const arr = new Array(s);
      for (let i = 0; i<arr.length; i++){
        html = html + '★'
      }
      console.log('html => ', html);
    } catch (e) {
      return html;
    }
  }

  quote(code){
    // tslint:disable-next-line:max-line-length
    const url = this.router.serializeUrl(this.router.createUrlTree(['/hotel/quotation'], { queryParams: { code, checkIn: this.data.form.checkIn, checkOut: this.data.form.checkOut, occupancy: JSON.stringify(this.data.form.occupancy) } }));
    window.open(url, '_blank');
    // tslint:disable-next-line:max-line-length
    // this.router.navigate(['/hotel/quotation'], { queryParams: { code, checkIn: this.this.data.form.checkIn, checkout: this.this.data.form.checkOut, occupancy: JSON.stringify(this.this.data.form.occupancy) } });
  }

  createMap(){
    const styles = {
      default: [],
      hide: [
        {
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },

      ],
    };

    const myLatlng = new google.maps.LatLng(this.data.form.destination.coordinates[1], this.data.form.destination.coordinates[0]);
    this.map = new google.maps.Map(document.getElementById('mapDialog') as HTMLElement, {
      center: myLatlng,
      mapTypeControl: false,
      // mapTypeId: 'Light political',
      // zoom: 8,
    });
    // this.map.mapTypes.set('mystyle', new google.maps.StyledMapType(myStyle, { name: 'My Style' }));
    this.map.setOptions({ styles: styles["hide"] });
    // console.log('hotelForm => ', this.hotelSearch);
    const image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
    const marker = new google.maps.Marker({
      position: myLatlng,
      title: this.translate.instant('POINT_OF_INTEREST'),
      icon: image,
    });
    const infoWindow = new google.maps.InfoWindow({
      content: '<p style="font-size:12px;">' + marker.title + '</p>',
    });
    marker.addListener('click', () => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    /*
    marker.addListener('mouseout', () => {
      infoWindow.close();
      marker.setZIndex(1);
    });
    */
    marker.setMap(this.map);
    this.map.setZoom(12);
    this.createMarkers(this.data.hotels);
    this.clusterMarkers(this.markers);
  }

  addMarker(hotel){
    let infoWindow;
    const img = this.returnImg(hotel);
    infoWindow = new google.maps.InfoWindow({
      content: '<div style="max-width:200px"><div style="margin-bottom:5px;">' + hotel.name + '</div>' +
        '<div style="margin-bottom:10px;color: #ffaf00;font-size:12px;">' + hotel.rating + ' stars </div>' +
        '<div style="margin-bottom:5px;"><img style="max-width:150px;max-height:150px;" src="' + img+ '"/></div>' +
        '<div>'+ this.translate.instant('PRICE_FROM') + ' ' + hotel.amount.toFixed(2) + '€' + '</div>' +
        '</div>',
    });

    const marker = new google.maps.Marker({
      // tslint:disable-next-line:max-line-length
      position: { lat: hotel.coordinates.latitude, lng: hotel.coordinates.longitude },
      // label: 'H',
      icon: '/assets/img/maps/hotel-pin.png',
      // map: this.map,
      // label: labels[i % labels.length],
    });
    marker.addListener('click', () => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    /*
    marker.addListener('mouseout', () => {
      infoWindow.close();
      marker.setZIndex(1);
    });
    */
    /*
    marker.addListener('click', () => {
      this.quote(hotel.hotelCode);
      marker.setZIndex(1);
    });
    */
    this.markers.push(marker);
  }

  clusterMarkers(makers) : void{

    this.clusterMarker = new MarkerClusterer(
      this.map,
      makers,
      { maxZoom: 14, imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' },
    );
  }


  createMarkers(hotels: any) {
    const infoWindow = new google.maps.InfoWindow;
    let iconPin = '';
    this.markers = hotels.map((hotel: any, i: any) => {
      if(hotel.amount){
        iconPin = '/assets/img/maps/hotel-v3.png';
      } else {
        iconPin = '/assets/img/maps/hotel-v3-red.png';
      }
      // console.log(location)
      const img = this.returnImg(hotel);
      let contentString = '';
      if(hotel.amount){
        contentString = ' ' + '<div style="max-width:200px"><div style="margin-bottom:5px;">' + hotel.name + '</div>' +
          '<div style="margin-bottom:10px;color: #ffaf00;font-size:12px;">' + this.returnStars(hotel.rating) + '</div>' +
          '<div style="margin-bottom:10px;"><img style="max-width:150px;max-height:150px;" src="' + img+ '"/></div>' +
          '<div style="margin-bottom:10px;">'+ this.translate.instant('PRICE_FROM') + ' ' + hotel.amount.toFixed(2) + '€' + '</div>' +
          '</div>';
      } else {
        contentString = ' ' + '<div style="max-width:200px"><div style="margin-bottom:5px;">' + hotel.name + '</div>' +
          '<div style="margin-bottom:10px;color: #ffaf00;font-size:12px;">' + this.returnStars(hotel.rating) + '</div>' +
          '<div style="margin-bottom:10px;"><img style="max-width:150px;max-height:150px;" src="' + img+ '"/></div>' +
          '<div>Non disponibile in queste date.</div>' +
          '</div>';
      }
      let marker: any = null;
      marker = new google.maps.Marker({
        position: {lat:hotel.coordinates.latitude, lng: hotel.coordinates.longitude},
        map: this.map,
        // animation: google.maps.Animation.DROP,
        icon: iconPin,
        zIndex: i,
      });

      google.maps.event.addListener(marker, 'click', () => {
        infoWindow.setContent(contentString);
        this.removeUnderline();
        infoWindow.open(this.map, marker);
        this.underlineTableRow(marker.zIndex);
      });
      infoWindow.addListener('closeclick', () => {
        this.removeUnderline();
      });
      // allMarkers.push(marker);
      return marker;
    });
    const bounds = new google.maps.LatLngBounds();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.markers.length; i++) {
      bounds.extend(this.markers[i].position);
    }
    this.map.fitBounds(bounds);
  }

  underlineTableRow(index: number) {
    this.data.hotels.forEach((d: any) => {
      d.highlight = false;
    });
    this.data.hotels[index].highlight = true;
    this.scrollToLine(index);
  }

  removeUnderline() {
    this.data.hotels.forEach((d: any) => {
      d.highlight = false;
    });
  }

  scrollToLine(id: any) {
    document.getElementById('row'+ id).scrollIntoView();
    /*
    const rowPos = $('#listHotels #row' + id).position();
    console.log('scroll to pos: ', rowPos);
    $('.mapList').animate({
      scrollTop: rowPos.top // - 55
    }, 1000);
    */
  }

  openInfo(i: number) {
    google.maps.event.trigger(this.markers[i], 'click', {});
    console.log(this.markers[i]);
  }


}
