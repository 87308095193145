import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SearchTrainsComponent } from './components/search/search-trains.component';
import { PassengerCards } from './components/common/passengers-cards/passenger-cards.component';

import { CheckoutComponent } from './components/italo-checkout/checkout.component';
import { CheckoutComponent as TrenitCheckoutComponent } from './components/trenit-checkout/checkout.component';
import { PassengersComponent } from './components/passengers/passengers.component';
import { SelectedBookingSummaryComponent } from './components/common/selected-booking-summary/selected-booking-summary.component';
import { BookingCompletedComponent } from './components/booking-completed/booking-completed.component';
import { TravelersModule } from '../travelers/travelers.module';
import { TrainReservationComponent } from './components/train-single-reservation/singlereservation.component';
import { TrainReservationListComponent } from './components/reservationlist/reservationlist.component';
import { DossierModule } from '../dossier/dossier.module';
import { TrainCheckoutBookingSummaryComponent } from './components/common/checkout-booking-summary/checkout-booking-summary.component';
import { TrainSeatMapComponent } from './components/passengers/train-seatmap/train-seat-map.component';
import { AmadeusCardsComponent } from './components/common/amadeus-cards/amadeus-cards.component';
import { TrainTravelSolutionDetailComponent } from './components/common/train-travelsolution-detail/train-travel-solution-detail.component';
import { TrainRefundComponent } from './components/train-single-reservation/aftersale/refund/refund.component';
import { TrainCancelComponent } from './components/train-single-reservation/aftersale/cancel/train-cancel.component';
import { TrainChangeTravellerComponent } from './components/train-single-reservation/aftersale/change-traveller/train-change-traveller.component';
import { TrainChangeDateComponent } from './components/train-single-reservation/aftersale/change-date/train-change-date.component';
import { TrainChangeServiceComponent } from './components/train-single-reservation/aftersale/change-service/train-change-service.component';
import { TrainInfoComponent } from './components/search/train-information/train-info.component';
import { TrainInfoTableComponent } from './components/search/train-info-table/train-info-table.component';
import { TrainSearchCarnetsComponent } from './components/search/search-carnets/train-search-carnets.component';
import { TrainSearchTrainsComponent } from './components/search/search-trains/train-search-trains.component';
import { TrainSearchresultComponent } from './components/search/train-searchresult/train-searchresult.component';
import { TrainMainComponent } from './components/common/train-main/train-main.component';
import { TrainUseCarnetsComponent } from './components/search/train-use-carnets/train-use-carnets.component';
import { TrainsRoutingModule } from './trains-routing.module';
import { CustomCommonModule } from '../common/custom-common.module';
import { ChangeNewSearchComponent } from './components/train-single-reservation/aftersale/change-new-search/change-new-search.component';
import { ValidatedTravelPenaltyComponent } from './components/train-single-reservation/aftersale/validated-travel-penalty/validated-travel-penalty.component';
import { ChangeEconomyComponent } from './components/train-single-reservation/aftersale/change-economy/change-economy.component';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { MatStepperModule } from '@angular/material/stepper';
import { ItaloCatalogPipe } from './components/pipes/italo-catalog.pipe';
import { SelCentroDiCostoComponent } from './components/sel-centro-di-costo/sel-centro-di-costo.component';


@NgModule({
  declarations: [
    TrainMainComponent,
    SearchTrainsComponent,
    TrainUseCarnetsComponent,
    TrainInfoComponent,
    TrainInfoTableComponent,
    TrainSearchTrainsComponent,
    TrainSearchCarnetsComponent,
    TrainSearchresultComponent,
    PassengerCards,
    CheckoutComponent,
    PassengersComponent,
    TrenitCheckoutComponent,
    SelectedBookingSummaryComponent,
    BookingCompletedComponent,
    TrainReservationComponent,
    TrainRefundComponent,
    TrainCancelComponent,
    TrainChangeTravellerComponent,
    TrainChangeDateComponent,
    TrainChangeServiceComponent,
    TrainReservationListComponent,
    TrainCheckoutBookingSummaryComponent,
    TrainSeatMapComponent,
    TrainTravelSolutionDetailComponent,
    AmadeusCardsComponent,
    ChangeNewSearchComponent,
    ValidatedTravelPenaltyComponent,
    ChangeEconomyComponent,
    ItaloCatalogPipe,
    SelCentroDiCostoComponent,
  ],
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        TrainsRoutingModule,
        TravelersModule,
        DossierModule,
        CustomCommonModule,
        MatStepperModule,
    ],
})

export class TrainsModule{}
