<div *ngIf="!history">
  {{'LOADING'|translate}}
</div>

<div class="row" *ngIf="history">

  <div class="col-12 col-md-4">
    <div class="mb-3 small">
      {{'CHANGES'|translate}}
      <span class="material-icons">
        keyboard_arrow_down
      </span>
    </div>
    <div class="scrollingbig smalltext">
      <div *ngFor="let change of history; let lastH = last; let i = index">
        <div class="mb-1">
          <span></span>
          <span *ngIf="i == 0">{{'RESERVATION_OF'|translate}}</span>
          <span *ngIf="i > 0 && !lastH">{{'CHANGE_OF'|translate}}</span>
          <span *ngIf="i > 0 && lastH">{{'LAST_CHANGE_ON'|translate}}</span>
          : {{change.createdAt | date:'dd/MM/yyyy - HH:mm':"+0000"}}
          <button *ngIf="!sameSelection(change) && !lastH" mat-button class="smallbtn" (click)="selectChange(i)">
            {{'COMPARE'|translate}}
            <span class="material-icons">
            swap_horiz
          </span>
          </button>
          <span *ngIf="sameSelection(change)" class="material-icons greentxt">
            arrow_right_alt
          </span>
        </div>
      </div>
    </div>

  </div>

  <div class="col-md-4 col-12" *ngIf="currentCompare">
    <div class="small">
      {{'VERSION_OF'|translate}} {{currentCompare.createdAt| date:'dd/MM/yyyy - HH:mm':"+0000"}}<br/>
      {{'CURRENT_COMPARE'|translate}}
      <span class="material-icons">
        keyboard_arrow_down
      </span>
    </div>
    <div class="mb-3 small smalltext" *ngIf="getTotalPayment(currentCompare) >= 0">
      {{'PAYMENT'|translate}} {{getTotalPayment(currentCompare) | currency:'eur'}}
    </div>
    <div class="row smalltext" *ngFor="let flight of currentCompare.flights; let f = index;let fl = last;">

      <div class="col-md-1 text-center">
        <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
      </div>
      <div class="col-md-11">
        <div class="flightdet" [ngClass]="{'oldFlight':flight.status == 'HX' || flight.status == 'UN'  || flight.status == 'WK' || flight.status == 'UC' || flight.status == 'NO'}">
          <p class="headingseg">
            {{flight.departure.dateTime |date:'dd/MM/yyyy':"+0000"}} -
            {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
            {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.departure.location)}} <sup>{{flight.departure.location}}</sup>
          </p>
          <p class="headingdetails">
            <!-- {{'FLIGHT_TIME'|translate}}: HH MM -->
            <span *ngIf="flight.departure.terminal"> - Terminal: {{flight.departure.terminal}}</span>
          </p>
          <p class="headingseg">
            <!--
          <span class="redt" *ngIf="otherday(flight.departure.date | date:'dd',flight.arrival.date | date:'dd')">
            {{flight.arrival.date | date:'dd MMM yyyy'}} -
          </span> -->
            {{flight.arrival.dateTime | date:'dd/MM/yyyy':"+0000"}} -
            {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
            {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.arrival.location)}} <sup>{{flight.arrival.location}}</sup>
          </p>
          <p class="fldetails">
            {{flight.operatingCarrier}} {{flight.number}} <span *ngIf="flight.detail && flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
            <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>
            <span *ngIf="flight.status && flight.status.length > 0">
                          | status: {{flight.status}}
              <span class="smalltext text-danger" *ngIf="flight.status == 'HX'">
                            {{'CANCELLED'|translate}}
                          </span>
                        </span>
          </p>
        </div>


      </div>

    </div>
  </div>

  <div class="col-md-4 col-6" *ngIf="currentOption">
    <div class="small">
      {{'LAST_CHANGE_ON'|translate}} {{currentOption.createdAt| date:'dd/MM/yyyy - HH:mm':"+0000"}}<br/>
      {{'CURRENT_OPTION'|translate}}
      <span class="material-icons">
        keyboard_arrow_down
      </span>
    </div>
    <div class="mb-3 small smalltext" *ngIf="getTotalPayment(currentOption) >= 0">
      {{'PAYMENT'|translate}} {{getTotalPayment(currentOption) | currency:'eur'}}
    </div>
    <div class="row smalltext" *ngFor="let flight of currentOption.flights; let f = index;let fl = last;">

      <div class="col-md-1 text-center">
        <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
      </div>
      <div class="col-md-11">
        <div class="flightdet" [ngClass]="{'oldFlight':flight.status == 'HX' || flight.status == 'UN'  || flight.status == 'WK' || flight.status == 'UC' || flight.status == 'NO'}">
          <p class="headingseg">
            <span [ngClass]="{'text-danger': differentFlight(flight.departure.dateTime ,'departure', flight.ref)}">
              {{flight.departure.dateTime |date:'dd/MM/yyyy':"+0000"}} -
              {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}}
            </span>
            -
            {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.departure.location)}}
            <span [ngClass]="{'text-danger': differentFlight(flight.departure.location ,'departureLocation', flight.ref)}">
              <sup>{{flight.departure.location}}</sup>
            </span>

          </p>
          <p class="headingdetails">
            <!-- {{'FLIGHT_TIME'|translate}}: HH MM -->
            <span *ngIf="flight.departure.terminal"> - Terminal: {{flight.departure.terminal}}</span>
          </p>
          <p class="headingseg">
            <!--
          <span class="redt" *ngIf="otherday(flight.departure.date | date:'dd',flight.arrival.date | date:'dd')">
            {{flight.arrival.date | date:'dd MMM yyyy'}} -
          </span> -->
            <span [ngClass]="{'text-danger': differentFlight(flight.arrival.dateTime ,'arrival', flight.ref)}">
              {{flight.arrival.dateTime | date:'dd/MM/yyyy':"+0000"}} -
              {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}}
            </span>
             -
            {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.arrival.location)}}
            <span [ngClass]="{'text-danger': differentFlight(flight.arrival.location ,'arrivalLocation', flight.ref)}">
              <sup>{{flight.arrival.location}}</sup>
            </span>
          </p>
          <p class="fldetails">
            {{flight.operatingCarrier}}
            <span [ngClass]="{'text-danger': differentFlight(flight.number ,'flightNumber', flight.ref)}">
              {{flight.number}}
            </span>

            <span *ngIf="flight.detail && flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
            <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>
            <span *ngIf="flight.status && flight.status.length > 0">
                          | status: {{flight.status}}
              <span class="smalltext text-danger" *ngIf="flight.status == 'HX'">
                            {{'CANCELLED'|translate}}
              </span>
            </span>
          </p>
        </div>


      </div>

    </div>

  </div>

</div>

