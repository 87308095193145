import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: [ './users-table.component.scss' ]
})
export class UsersTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() data = [];

  @Output() editClicked = new EventEmitter<any>();

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  displayedColumns: string[] = [ 'name', 'company', 'email', 'group', 'edit' ];

  constructor() {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(this.data);
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource<any>(this.data);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

}
