import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VERSION } from '@angular/material/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as $ from 'jquery';
import { Profile } from '../../../../../classes/profile';
// const user = require("./profile.json");
import { ErrorStateMatcher } from '@angular/material/core';
import { UsersService } from '../../../../../services/profile/users.service';
import { HttpserviceService } from '../../../../../services/httpservice.service';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainComponent } from '../../../../mainmodule/main/main.component';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import { TranslateService } from '@ngx-translate/core';
import 'bootstrap';
import {User} from '../../../../../classes/profiles/user';
import {CustomAuthService} from '../../../../../services/customAuth/custom-auth.service';
import * as CryptoJS from 'crypto-js';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

export interface Country {
  value: string;
  viewValue: string;
}

export interface Region {
  value: string;
  viewValue: string;
}

export interface Provincia {
  nome: string;
  sigla: string;
  regione: string;
}

export interface Province {
  value: string;
  viewValue: string;
}

@Component({
 selector: 'app-user',
 templateUrl: './user.component.html',
 styleUrls: ['./user.component.scss'],
})

export class UserComponent implements OnInit, AfterViewInit {
  countries: Country[] = [
    { value: 'IT', viewValue: 'Italia' },
  ];
  regions: Region[] = [
    { value: 'Abruzzo', viewValue: 'Abruzzo' },
    { value: 'Basilicata', viewValue: 'Basilicata' },
    { value: 'Calabria', viewValue: 'Calabria' },
    { value: 'Campania', viewValue: 'Campania' },
    { value: 'Emilia-Romagna', viewValue: 'Emilia-Romagna' },
    { value: 'Friuli-Venezia Giulia', viewValue: 'Friuli-Venezia Giulia' },
    { value: 'Lazio', viewValue: 'Lazio' },
    { value: 'Liguria', viewValue: 'Liguria' },
    { value: 'Lombardia', viewValue: 'Lombardia' },
    { value: 'Marche', viewValue: 'Marche' },
    { value: 'Molise', viewValue: 'Molise' },
    { value: 'Piemonte', viewValue: 'Piemonte' },
    { value: 'Puglia', viewValue: 'Puglia' },
    { value: 'Sardegna', viewValue: 'Sardegna' },
    { value: 'Sicilia', viewValue: 'Sicilia' },
    { value: 'Toscana', viewValue: 'Toscana' },
    { value: 'Trentino-Alto Adige', viewValue: 'Trentino-Alto Adige' },
    { value: 'Umbria', viewValue: 'Umbria' },
    { value: 'Valle d\'Aosta', viewValue: 'Valle d\'Aosta' },
    { value: 'Veneto', viewValue: 'Veneto' },
  ];
  province: Provincia[] = [
    {
      nome: 'Agrigento',
      sigla: 'AG',
      regione: 'Sicilia',
    },
    {
      nome: 'Alessandria',
      sigla: 'AL',
      regione: 'Piemonte',
    },
    {
      nome: 'Ancona',
      sigla: 'AN',
      regione: 'Marche',
    },
    {
      nome: 'Arezzo',
      sigla: 'AR',
      regione: 'Toscana',
    },
    {
      nome: 'Ascoli Piceno',
      sigla: 'AP',
      regione: 'Marche',
    },
    {
      nome: 'Asti',
      sigla: 'AT',
      regione: 'Piemonte',
    },
    {
      nome: 'Avellino',
      sigla: 'AV',
      regione: 'Campania',
    },
    {
      nome: 'Bari',
      sigla: 'BA',
      regione: 'Puglia',
    },
    {
      nome: 'Barletta-Andria-Trani',
      sigla: 'BT',
      regione: 'Puglia',
    },
    {
      nome: 'Belluno',
      sigla: 'BL',
      regione: 'Veneto',
    },
    {
      nome: 'Benevento',
      sigla: 'BN',
      regione: 'Campania',
    },
    {
      nome: 'Bergamo',
      sigla: 'BG',
      regione: 'Lombardia',
    },
    {
      nome: 'Biella',
      sigla: 'BI',
      regione: 'Piemonte',
    },
    {
      nome: 'Bologna',
      sigla: 'BO',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Bolzano/Bozen',
      sigla: 'BZ',
      regione: 'Trentino-Alto Adige',
    },
    {
      nome: 'Brescia',
      sigla: 'BS',
      regione: 'Lombardia',
    },
    {
      nome: 'Brindisi',
      sigla: 'BR',
      regione: 'Puglia',
    },
    {
      nome: 'Cagliari',
      sigla: 'CA',
      regione: 'Sardegna',
    },
    {
      nome: 'Caltanissetta',
      sigla: 'CL',
      regione: 'Sicilia',
    },
    {
      nome: 'Campobasso',
      sigla: 'CB',
      regione: 'Molise',
    },
    {
      nome: 'Carbonia-Iglesias',
      sigla: 'CI',
      regione: 'Sardegna',
    },
    {
      nome: 'Caserta',
      sigla: 'CE',
      regione: 'Campania',
    },
    {
      nome: 'Catania',
      sigla: 'CT',
      regione: 'Sicilia',
    },
    {
      nome: 'Catanzaro',
      sigla: 'CZ',
      regione: 'Calabria',
    },
    {
      nome: 'Chieti',
      sigla: 'CH',
      regione: 'Abruzzo',
    },
    {
      nome: 'Como',
      sigla: 'CO',
      regione: 'Lombardia',
    },
    {
      nome: 'Cosenza',
      sigla: 'CS',
      regione: 'Calabria',
    },
    {
      nome: 'Cremona',
      sigla: 'CR',
      regione: 'Lombardia',
    },
    {
      nome: 'Crotone',
      sigla: 'KR',
      regione: 'Calabria',
    },
    {
      nome: 'Cuneo',
      sigla: 'CN',
      regione: 'Piemonte',
    },
    {
      nome: 'Enna',
      sigla: 'EN',
      regione: 'Sicilia',
    },
    {
      nome: 'Fermo',
      sigla: 'FM',
      regione: 'Marche',
    },
    {
      nome: 'Ferrara',
      sigla: 'FE',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Firenze',
      sigla: 'FI',
      regione: 'Toscana',
    },
    {
      nome: 'Foggia',
      sigla: 'FG',
      regione: 'Puglia',
    },
    {
      nome: 'Forlì-Cesena',
      sigla: 'FC',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Frosinone',
      sigla: 'FR',
      regione: 'Lazio',
    },
    {
      nome: 'Genova',
      sigla: 'GE',
      regione: 'Liguria',
    },
    {
      nome: 'Gorizia',
      sigla: 'GO',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Grosseto',
      sigla: 'GR',
      regione: 'Toscana',
    },
    {
      nome: 'Imperia',
      sigla: 'IM',
      regione: 'Liguria',
    },
    {
      nome: 'Isernia',
      sigla: 'IS',
      regione: 'Molise',
    },
    {
      nome: 'L\'Aquila',
      sigla: 'AQ',
      regione: 'Abruzzo',
    },
    {
      nome: 'La Spezia',
      sigla: 'SP',
      regione: 'Liguria',
    },
    {
      nome: 'Latina',
      sigla: 'LT',
      regione: 'Lazio',
    },
    {
      nome: 'Lecce',
      sigla: 'LE',
      regione: 'Puglia',
    },
    {
      nome: 'Lecco',
      sigla: 'LC',
      regione: 'Lombardia',
    },
    {
      nome: 'Livorno',
      sigla: 'LI',
      regione: 'Toscana',
    },
    {
      nome: 'Lodi',
      sigla: 'LO',
      regione: 'Lombardia',
    },
    {
      nome: 'Lucca',
      sigla: 'LU',
      regione: 'Toscana',
    },
    {
      nome: 'Macerata',
      sigla: 'MC',
      regione: 'Marche',
    },
    {
      nome: 'Mantova',
      sigla: 'MN',
      regione: 'Lombardia',
    },
    {
      nome: 'Massa-Carrara',
      sigla: 'MS',
      regione: 'Toscana',
    },
    {
      nome: 'Matera',
      sigla: 'MT',
      regione: 'Basilicata',
    },
    {
      nome: 'Medio Campidano',
      sigla: 'VS',
      regione: 'Sardegna',
    },
    {
      nome: 'Messina',
      sigla: 'ME',
      regione: 'Sicilia',
    },
    {
      nome: 'Milano',
      sigla: 'MI',
      regione: 'Lombardia',
    },
    {
      nome: 'Modena',
      sigla: 'MO',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Monza e della Brianza',
      sigla: 'MB',
      regione: 'Lombardia',
    },
    {
      nome: 'Napoli',
      sigla: 'NA',
      regione: 'Campania',
    },
    {
      nome: 'Novara',
      sigla: 'NO',
      regione: 'Piemonte',
    },
    {
      nome: 'Nuoro',
      sigla: 'NU',
      regione: 'Sardegna',
    },
    {
      nome: 'Ogliastra',
      sigla: 'OG',
      regione: 'Sardegna',
    },
    {
      nome: 'Olbia-Tempio',
      sigla: 'OT',
      regione: 'Sardegna',
    },
    {
      nome: 'Oristano',
      sigla: 'OR',
      regione: 'Sardegna',
    },
    {
      nome: 'Padova',
      sigla: 'PD',
      regione: 'Veneto',
    },
    {
      nome: 'Palermo',
      sigla: 'PA',
      regione: 'Sicilia',
    },
    {
      nome: 'Parma',
      sigla: 'PR',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Pavia',
      sigla: 'PV',
      regione: 'Lombardia',
    },
    {
      nome: 'Perugia',
      sigla: 'PG',
      regione: 'Umbria',
    },
    {
      nome: 'Pesaro e Urbino',
      sigla: 'PU',
      regione: 'Marche',
    },
    {
      nome: 'Pescara',
      sigla: 'PE',
      regione: 'Abruzzo',
    },
    {
      nome: 'Piacenza',
      sigla: 'PC',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Pisa',
      sigla: 'PI',
      regione: 'Toscana',
    },
    {
      nome: 'Pistoia',
      sigla: 'PT',
      regione: 'Toscana',
    },
    {
      nome: 'Pordenone',
      sigla: 'PN',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Potenza',
      sigla: 'PZ',
      regione: 'Basilicata',
    },
    {
      nome: 'Prato',
      sigla: 'PO',
      regione: 'Toscana',
    },
    {
      nome: 'Ragusa',
      sigla: 'RG',
      regione: 'Sicilia',
    },
    {
      nome: 'Ravenna',
      sigla: 'RA',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Reggio di Calabria',
      sigla: 'RC',
      regione: 'Calabria',
    },
    {
      nome: 'Reggio nell\'Emilia',
      sigla: 'RE',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Rieti',
      sigla: 'RI',
      regione: 'Lazio',
    },
    {
      nome: 'Rimini',
      sigla: 'RN',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Roma',
      sigla: 'RM',
      regione: 'Lazio',
    },
    {
      nome: 'Rovigo',
      sigla: 'RO',
      regione: 'Veneto',
    },
    {
      nome: 'Salerno',
      sigla: 'SA',
      regione: 'Campania',
    },
    {
      nome: 'Sassari',
      sigla: 'SS',
      regione: 'Sardegna',
    },
    {
      nome: 'Savona',
      sigla: 'SV',
      regione: 'Liguria',
    },
    {
      nome: 'Siena',
      sigla: 'SI',
      regione: 'Toscana',
    },
    {
      nome: 'Siracusa',
      sigla: 'SR',
      regione: 'Sicilia',
    },
    {
      nome: 'Sondrio',
      sigla: 'SO',
      regione: 'Lombardia',
    },
    {
      nome: 'Taranto',
      sigla: 'TA',
      regione: 'Puglia',
    },
    {
      nome: 'Teramo',
      sigla: 'TE',
      regione: 'Abruzzo',
    },
    {
      nome: 'Terni',
      sigla: 'TR',
      regione: 'Umbria',
    },
    {
      nome: 'Torino',
      sigla: 'TO',
      regione: 'Piemonte',
    },
    {
      nome: 'Trapani',
      sigla: 'TP',
      regione: 'Sicilia',
    },
    {
      nome: 'Trento',
      sigla: 'TN',
      regione: 'Trentino-Alto Adige',
    },
    {
      nome: 'Treviso',
      sigla: 'TV',
      regione: 'Veneto',
    },
    {
      nome: 'Trieste',
      sigla: 'TS',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Udine',
      sigla: 'UD',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Valle d\'Aosta',
      sigla: 'AO',
      regione: 'Valle d\'Aosta',
    },
    {
      nome: 'Varese',
      sigla: 'VA',
      regione: 'Lombardia',
    },
    {
      nome: 'Venezia',
      sigla: 'VE',
      regione: 'Veneto',
    },
    {
      nome: 'Verbano-Cusio-Ossola',
      sigla: 'VB',
      regione: 'Piemonte',
    },
    {
      nome: 'Vercelli',
      sigla: 'VC',
      regione: 'Piemonte',
    },
    {
      nome: 'Verona',
      sigla: 'VR',
      regione: 'Veneto',
    },
    {
      nome: 'Vibo Valentia',
      sigla: 'VV',
      regione: 'Calabria',
    },
    {
      nome: 'Vicenza',
      sigla: 'VI',
      regione: 'Veneto',
    },
    {
      nome: 'Viterbo',
      sigla: 'VT',
      regione: 'Lazio',
    },
  ];

  provinces: Province[] = [];

  userId: string;
  showEdit: boolean;
  activeform: boolean;
  activecredential: boolean;
  profile: User;
  hidePassword: boolean;
  hideConfirmpassword: boolean;
  UserForm: UntypedFormGroup;
  UserCredential: UntypedFormGroup;
  matcher = new MyErrorStateMatcher();
  param: any;
  showUploadForm: boolean;
  currentTab: number;
  loadingTab: boolean;
  loading: boolean;
  ////// image
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  toggleCountry : boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private snackBar: MatSnackBar,
    public maincomponent: MainComponent,
    private http: HttpClient,
    public translate: TranslateService,
    private httpservice: HttpserviceService,
    // private titleService: Title
    public customAuth: CustomAuthService,
  ) {
    this.currentTab = 0;
    this.showEdit = false;
    this.loading = true;
    this.activeform = false;
    this.activecredential = false;
    this.showUploadForm = false;
    this.hidePassword = true;
    this.hideConfirmpassword = true;

    this.route.params.subscribe(params => {
      this.param = params.id;
      this.getUserId(); // reset and set based on new parameter this time
    });

    this.UserForm = this.formBuilder.group({
       name: ['', [Validators.required]],
       surname: ['', [Validators.required]],
       // email: ['', [Validators.required, Validators.email]],
       phoneNumber: ['', [Validators.pattern('^[0-9]*$'), Validators.required]],
       sex: ['', [Validators.required]],
       birthday: [''],
       location: this.formBuilder.group({
            _id: ['', [Validators.required]],
            country: ['', [Validators.required]],
            region: ['', [Validators.required]],
            province: ['', [Validators.required]],
            city: ['', [Validators.required]],
            postalCode: ['', [Validators.required]],
            address: ['', [Validators.required]]
          }),
       });

    this.UserCredential = this.formBuilder.group({
       email: ['', [Validators.required]],
       password: ['', [Validators.minLength(4), Validators.required]],
       confirmPassword: [''],
     }, { validator: this.checkPasswords });

    this.UserCredential.disable();
    this.UserForm.disable();

  }

  showEditForm() {
    this.showEdit = !this.showEdit;
    if (!this.showEdit) {
      this.activeform = false;
      this.activecredential = false;
      this.UserForm.disable();
      this.UserCredential.disable();
    }
  }

  ngAfterViewInit() {

  }

  /*callingFunction() {
   console.log('form inviato => ', user);
   }*/

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.loadingTab = true;
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
    this.currentTab = tabChangeEvent.index;
    setTimeout(() => {
      this.loadingTab = false;
    }, 1000);
  }

  toggleForm() {
    if (!this.activeform) {
      this.UserForm.disable();
    } else {
      this.UserForm.enable();
    }
    console.log('form', this.UserForm);
  }

  toggleCredential() {
    if (!this.activecredential) {
      this.UserCredential.disable();
    } else {
      this.UserCredential.enable();
    }
  }

  ngOnInit() {
    // this.getUserId();
    console.log('Form utente => ', this.UserForm);
  }

  getUserId() {
    this.userId = this.route.snapshot.paramMap.get('id');
    console.log('this.userId => ', this.userId);
    this.getUser(this.userId);
  }

  setAddressForm(event?:any){
    if (event){
      this.UserForm.controls.location.reset()
    }
    this.UserForm.get('location')['controls']._id.setValue(this.profile.location._id);
    this.UserForm.get('location')['controls'].country.setValue(this.profile.location.country);
    if(this.profile.location.country !== 'IT' ) {
      this.toggleCountry = true;
    } else {
      this.toggleCountry = false;
    }
    this.UserForm.get('location')['controls'].region.setValue(this.profile.location.region);
    this.setRegion();
    this.UserForm.get('location')['controls'].province.setValue(this.profile.location.province);
    this.UserForm.get('location')['controls'].city.setValue(this.profile.location.city);
    this.UserForm.get('location')['controls'].postalCode.setValue(this.profile.location.postalCode);
    this.UserForm.get('location')['controls'].address.setValue(this.profile.location.address);
  }

  getUser(id): void {
    this.loading = false;
    this.currentTab = 0;
    this.usersService.getUser(id).subscribe((res) => {
      this.profile = res;
      if (this.profile) {
        // this.scrollTop();
        this.profile.image = this.httpservice.setApiUrlImage() + 'static/user/' + this.profile._id + '.png?time=' + new Date().getTime();
        if (typeof this.profile.company !== 'string') {
          // tslint:disable-next-line:max-line-length
          this.profile.company.image = this.httpservice.setApiUrlImage() + 'static/company/' + this.profile.company._id + '.png?time=' + new Date().getTime();
        }
        console.log('this.profile call => ', this.profile);
        this.UserForm.controls.name.setValue(this.profile.name);
        this.UserForm.controls.surname.setValue(this.profile.surname);
        this.UserCredential.controls.email.setValue(this.profile.email);
        this.UserForm.controls.phoneNumber.setValue(this.profile.phoneNumber);
        this.UserForm.controls.sex.setValue(this.profile.sex);
        this.UserForm.controls.birthday.setValue(new Date(this.profile.birthday));
        this.setAddressForm()
        this.startCounter();
        this.getUserRole();
      }
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  setRegion() {
    this.UserForm.get('location')['controls'].province.setValue('');
    this.provinces = [];
    for (let i = 0; i < this.province.length; i ++) {
      if (this.UserForm.value.location.region === this.province[i].regione) {
        const prov = {
          value: this.province[i].sigla,
          viewValue: this.province[i].nome,
        };
        this.provinces.push(prov);
      }
    }
  }

  startCounter() {
    setTimeout(() => {
      $('.count').each(function() {
        $(this).prop('Counter', 0).animate({
           Counter: $(this).text(),
         }, {
           duration: 1500,
           easing: 'swing',
           step(now) {
             $(this).text(Math.ceil(now));
           },
         });
      });
    }, 1);

  }

  getCredentialsErrorMessage() {
    return this.UserCredential.controls.email.hasError('required') ? this.translate.instant('ENTER_A_VALUE') :
      this.UserCredential.controls.email.hasError('email') ? this.translate.instant('ENTER_VALID_EMAIL') :
        '';
  }

  checkPasswords(group: UntypedFormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  toggleUSerEnable() {
    if (this.profile.enable) {
      this.deleteUser(this.profile._id);
    } else {
      this.enableUser(this.profile._id);
    }
  }

  deleteUser(id) {
    this.usersService.deleteUser(id).subscribe((res) => {
      this.getUser(id);
      this.snackBar.open(this.translate.instant('USER_DISABLED'), 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  editUser() {
    this.enableUser(this.profile._id);
  }

  editPassword() {
    // tslint:disable-next-line:max-line-length
    this.UserCredential.value.password = String(CryptoJS.MD5(this.UserCredential.value.password)); // ;String(Md5.hashStr(String(this.UserCredential.value.password)));
    const user = {
      email: this.UserCredential.value.email,
      newPassword: this.UserCredential.value.password,
      changedPassword: new Date(),
    };
    this.changePassword(this.profile._id, user);
  }

  changePassword(id, user) {
    this.usersService.updateUser(id, user).subscribe((res) => {
      this.getUser(id);
      this.snackBar.open(this.translate.instant('USER_PASS_UPDATED'), 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  enableUser(id) {
    this.UserForm.value.location.country = this.UserForm.value.location.country.toUpperCase();
    this.usersService.updateUser(id, this.UserForm.value).subscribe((res) => {
      this.getUser(id);
      this.snackBar.open(this.translate.instant('USER_UPDATED'), 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  showUpload() {
    this.showUploadForm = !this.showUploadForm;
  }

  fileProgress(fileInput: any) {
    this.fileData = fileInput.target.files[0] as File;
    this.preview();
  }

  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  onSubmit() {
    console.log('this.fileData=> ', this.fileData);
    const formData = new FormData();
    formData.append('image', this.fileData);
    // this.fileUploadProgress = '0%';
    this.usersService.uploadImage(this.profile._id, formData).subscribe((res) => {
      // this.uploadedFilePath = res.data.filePath;
      this.getUser(this.profile._id);
      if (this.profile._id === this.maincomponent.loggedUser._id) {
        this.maincomponent.getUserImage();
      }
      $('#exampleModal').modal('hide');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      this.snackBar.open('User updated', 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    });

  }

  getUserRole() {
    this.usersService.getUserRole(this.profile._id).subscribe((res) => {
      this.loading = false;
      // this.getUser(id);
      this.profile.schema = res;
      // this.getRole(res._id);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);

    });
  }

  setUserRole(event:any){
    this.profile.schema = event;
    console.log('this.profile.schema =>', this.profile.schema);
  }

  changeCountryType(){
    if (this.toggleCountry !== true){
      this.UserForm.get('location')['controls'].country.setValue('IT');
      this.UserForm.get('location')['controls'].region.setValue('');
      this.setRegion();
      this.UserForm.get('location')['controls'].province.setValue('');
      this.UserForm.get('location')['controls'].city.setValue('');
      this.UserForm.get('location')['controls'].postalCode.setValue('');
      this.UserForm.get('location')['controls'].address.setValue('');
    } else {
      this.UserForm.get('location')['controls'].country.setValue('');
      this.UserForm.get('location')['controls'].region.setValue('');
      this.UserForm.get('location')['controls'].province.setValue('');
      this.UserForm.get('location')['controls'].city.setValue('');
      this.UserForm.get('location')['controls'].postalCode.setValue('');
      this.UserForm.get('location')['controls'].address.setValue('');
    }
  }

  resetData(event?:any) {
    this.setAddressForm(event);
  }
}
