<custom-title></custom-title>
<div class="padding20">
    <div class="row">
      <div class="col-6">
          <mat-card appearance="outlined" class="p-3">
            <div class="ml-2 mb-2">Cerca aeroporto</div>
            <mat-form-field class="full-width">
              <input matInput placeholder="{{'AIRPORT'|translate}}" aria-label="Departure" (ngModelChange)="searchAirport($event)"
                     [(ngModel)]="text" [matAutocomplete]="airp" >
              <button class="iconButtons" type="button" *ngIf="text" matSuffix mat-icon-button aria-label="Clear" (click)="clearForm()" >
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete #airp="matAutocomplete" (optionSelected)="getFrom($event.option.value)" (closed)="selectFirst()" >
                <mat-option style="height:40px;" *ngFor="let airport of airports" [value]="airport"
                            [ngStyle]="{'background-color': (airport.isCity) ? '#d3d3d330' : '', 'text-transform':'capitalize'}" >
                  <small *ngIf="!airport.isCity">&nbsp;&nbsp;&nbsp;</small>
                  <small>{{airport.name}}</small>
                  <small *ngIf="!airport.isCity"> ({{airport.iata}})</small>
                  <small *ngIf="airport.isCity"> (All)</small>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          <div class="mt-3 text-center" *ngIf="loading">
            <mat-spinner></mat-spinner>
          </div>
          <div *ngIf="currentEditing">
            <div class="pt-2">
              {{editing.name}} <sup>{{editing.iata}}</sup>
            </div>
            <div>
              <pre style="color:#ff4081">
                <code> {{currentEditing|json}} </code>
              </pre>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="col-6" *ngIf="currentEditing">
        <mat-card appearance="outlined" class="p-3">
          <div class="mb-4 ml-2">Modifica dati aeroporto</div>
          <div class="mb-2">
            <mat-form-field class="full-width">
              <mat-label>Nome aereoporto</mat-label>
              <input matInput [(ngModel)]="editingName">
            </mat-form-field>
          </div>
          <div class="mb-3">
            <mat-form-field class="full-width">
              <mat-label>Keywords</mat-label>
              <mat-chip-grid #chipList aria-label="keywords">
                <mat-chip-row *ngFor="let keyword of keywords; let i = index" [value]="keyword">
                  {{keyword}}
                  <button matChipRemove (click)="removeKeyword(i)">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                <input placeholder="New keyword..." [matChipInputFor]="chipList" (matChipInputTokenEnd)="addKeywordFromInput($event)">
              </mat-chip-grid>
            </mat-form-field>
          </div>

          <div class="buttonrow">
            <button [disabled]="!editingName" (click)="saveAirport()" mat-button color="primary">Salva</button>
          </div>

        </mat-card>
      </div>
    </div>
</div>
