<custom-title></custom-title>
<div class="padding20 position-relative">
  <div *ngIf="checking" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <div *ngIf="redirect" class="loader">

    <div class="card text-center padding20">
      <div class="mb-3">
        {{payMentStatus|translate}}
      </div>
      <mat-spinner></mat-spinner>
      <h5>{{'REDIRECT'|translate}}...</h5>
    </div>
  </div>
</div>
