import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AuthService} from '../../../../services/auth/auth.service';
import {validateAccessDialog} from './not-empty.validator';
import {TravelPolicyService} from '../../../../services/travel-policy/travel-policy.service';
import {UsersService} from '../../../../services/profile/users.service';

@Component({
  selector: 'app-access-dialog',
  templateUrl: './access-dialog.component.html',
  styleUrls: [ './access-dialog.component.scss' ]
})
export class AccessDialogComponent implements OnInit {
  loggedUser: any;
  rolesList = [];
  isRole = true;
  isValid;
  query: any;
  orderForm = new UntypedFormGroup({
    radioButton: new UntypedFormControl(1),
    authorization_roles: new UntypedFormControl(this.data.userPolicy.authorization_roles),
    authorization_users: new UntypedFormControl(this.data.userPolicy.authorization_roles)
  }, { validators: validateAccessDialog });

  userList = [];

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data,
              private authService: AuthService,
              private travelPolicyService: TravelPolicyService,
              private usersService: UsersService
  ) {
  }

  get radio(): any {
    return this.orderForm.get('radioButton');
  }

  get authorization_roles(): any {
    return this.orderForm.get('authorization_roles');
  }

  get authorization_users(): any {

    return this.orderForm.get('authorization_users');
  }

  ngOnInit(): void {
    this.loggedUser = JSON.parse(localStorage.getItem('fl_profile'));
    this.query = {
      options: {
        viewAll: false,
      },
      sort: 'createdAt',
      limit: 999,
      skip: 0,
      order: 'desc',
      text: '',
    };
    this.usersService.getUsersListCp(this.query, this.loggedUser.company._id).subscribe(response => {
      this.userList = response.list;
      console.log(this.userList);
    });
    /*
    this.authService.userList().pipe(take(1)).subscribe(res => {
      this.userList = res.data;
    });*/
    this.travelPolicyService.getGroupsList().subscribe(response => {
      this.rolesList = response.data;
      console.log(this.rolesList);
    });
    if (this.data.isOldData) {
      this.radio.setValue(`${ this.data.userPolicy.authorization }`);
      this.authorization_roles.setValue(this.data.userPolicy.authorization_roles);
      this.authorization_users.setValue(this.data.userPolicy.authorization_users);
    }
    this.onSelectedRadio();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.isValid = this.orderForm.invalid;
    this.dialogRef.close(this.orderForm.value);
  }

  onSelectedRadio(): void {
    this.isRole = this.radio.value === '1';
    if (this.radio.value === '1') {
      this.isValid = false;
    } else {
      this.isValid = this.orderForm.invalid;
    }
  }
}
