import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {TravelersList} from '../../../../../classes/travelers/travelers';
import {TravelersService} from '../../../../../services/travelers/travelers.service';
import {ActivatedRoute, Router} from '@angular/router';

import * as $ from 'jquery';
import 'bootstrap';
@Component({
  selector: 'app-travelerslist',
  templateUrl: './travelerslist.component.html',
  styleUrls: ['./travelerslist.component.scss']
})
export class TravelerslistComponent implements OnInit {
  @Input() page: string;
  @Input() newTraveler: any;
  @Input() passengers: any;
  @Input() trDate: any;
  @Input() infantMaxAge: number = 2;
  @Input() childMaxAge: number = 12;
  @Input() hotel?: any;
  // tslint:disable-next-line:no-output-rename
  @Output('setTraveler') setTraveler: EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-native no-output-rename
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();
  displayedColumns = [];
  loading: boolean;
  travelersList: TravelersList;
  isList: boolean;
  currentpage: number;
  maxpages: number;
  // setQuery
  query: any;
  limit: number;
  skip: number;
  sort: string;
  order: boolean;
  progress: boolean;
  text: string;
  selection = [];
  pagenumbers = [];
  editingTraveler: any;
  today = new Date();
  constructor(
    private travelersService: TravelersService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.loading = true;
    this.progress = false;
    this.isList = true;
    if (!this.page) {
      this.limit = 10;
    } else {
      this.limit = 10;
    }
    this.sort = 'createdAt';
    this.order = true;
    this.currentpage = 0;
    this.skip = (this.currentpage) * this.limit;
  }

  ngOnInit() {
    this.displayedColumns = ['select', 'name', 'phone', 'data'];
    this.query = {
      sort: this.sort,
      limit: this.limit,
      skip: this.skip,
      order: this.order ? 'asc' : 'desc',
      text: '',
    };
    if (!this.page) {
      this.route.queryParams.subscribe(params => {
        this.query.sort = params.sort || this.sort;
        this.query.limit = params.limit || this.limit;
        this.query.skip = params.skip || this.skip;
        this.query.order = params.order || 'asc';
        this.query.text = params.text || '';
        this.text = params.text || '';
        this.currentpage = this.query.skip / this.query.limit;
        this.getTravelersList();
      });
    } else {
      this.navigate();
    }
  }

  next() {
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev() {
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value) {
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  isSelected(id) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.selection.length; i++) {
      if (id === this.selection[i]) {
        return true;
      }
    }
  }

  selectValue(id) {
    let exist = false;
    for (let i = 0; i < this.selection.length; i++) {
      if (id === this.selection[i]) {
        exist = true;
        this.selection.splice(i, 1);
      }
    }
    if (!exist) {
      this.selection.push(id);
    }
    console.log('this.selection => ', this.selection);
  }

  isAllSelected() {
    if (this.selection.length === this.travelersList.list.length) {
      return true;
    } else {
      return false;
    }
  }

  someSelected() {
    if (this.selection.length > 0 && this.selection.length < this.travelersList.list.length) {
      return true;
    } else {
      return false;
    }
  }

  selectOnAll() {
    if (this.selection.length === this.travelersList.list.length) {
      this.selection = [];
    } else {
      this.selection = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.travelersList.list.length; i++) {
        this.selection.push(this.travelersList.list[i]._id);
      }
    }
  }

  scrollTop() {
    $('.tablelist').animate({ scrollTop: 0 }, 'fast');
    $('#content_main').animate({ scrollTop: 0 }, 'fast');
  }

  getTravelersList() {
    this.progress = true;
    this.travelersService.getTravelersList(this.query).subscribe((res) => {
      this.loading = false;
      this.travelersList = res;

      for (let i = 0; i < this.travelersList.list.length; i++) {
        const pax = this.travelersList.list[i];
        pax.type = this.calcAge(pax);
      }

      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      if (!this.page) {
        this.scrollTop();
      }
      console.log('this.travelersList => ', this.travelersList);
      this.maxpages = Math.ceil((this.travelersList.count / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  navigate() {
    this.selection = [];
    if (this.page) {
      this.getTravelersList();
    } else {
      this.router.navigate([], {
        queryParams: this.query,
      });
    }
  }

  sortResults(value) {
    if (this.query.sort === value) {
      this.order = ! this.order;
      this.query.order = this.order ? 'asc' : 'desc';
    }
    this.query.sort = value;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
    // this.getUserList();
  }

  searchName() {
    this.query.text = this.text;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
    // this.getUserList()
  }

  editTraveler(traveler) {
    $('#editTravelerModal').modal('toggle');
    this.editingTraveler = traveler;
  }

  closeEditEvent(event) {
    $('#editTravelerModal').modal('toggle');
    delete this.editingTraveler;
    // this.getTravelersList();
  }

  togglenewTraveler() {
    $('#addTravelerModal').modal('toggle');
  }
  closeEvent(event) {
    this.togglenewTraveler();
  }

  selectTraveler(pax) {
    this.travelersService.getTraveler(pax._id).subscribe((res) => {
      if (!res.birthday) {
        res.type = 'ADT';
      } else {
        res.type = this.calcAge(pax);
      }
      this.newTraveler = res;

      this.travelersService.getFrequentFlyers(this.newTraveler._id).subscribe((res) => {
        this.loading = false;
        this.newTraveler.frequentFlyers = res;
        // this.filterStates(this.traveler.document.nationality);
        this.setTraveler.emit({...this.newTraveler, addressBook: this.newTraveler._id});
        this.close.emit();
      }, error => {
        this.loading = false;
        this.setTraveler.emit({...this.newTraveler, addressBook: this.newTraveler._id});
        this.close.emit();
      });


      console.log('this.traveler => ', res);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  isPaxSelected(id) {
    if (this.passengers.length > 0) {
      for (let i = 0; i < this.passengers.length; i++) {
        if (id === this.passengers[i].addressBook) {
          return true;
        }
      }
    }
  }

  isDifferent(traveler) {
    if (this.newTraveler.type) {
      return this.newTraveler.type !== traveler.type;
    } else {
      return false;
    }
  }

  returnType(age) {
    /*
    const ageMap = {};
    [...Array(this.infantMaxAge).keys()].forEach(age => ageMap[age] ='INF');
    [...Array(this.childMaxAge-this.infantMaxAge).keys()].map(i => i + this.infantMaxAge+1).forEach(age => ageMap[age] ='CHD');
    return ageMap[age] || 'ADT';
    */
    if(!this.hotel){
      if (age <= 11 && age >= 2) {return 'CHD'; }
      if (age < 2) {return 'INF'; }
      if (age > 11) {return 'ADT'; }
    } else {
      if (age <= 17) {return 'CHD'; }
      if (age > 17) {return 'ADT'; }
    }
  }

  calcAge(pax): any {
    let date = (this.trDate) ? new Date(this.trDate) : this.today;
    if (!pax.birthday) {
      return 'ADT';
    }
    const birthDate = new Date(pax.birthday);
    let age = date.getFullYear() - birthDate.getFullYear();
    const m = date.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && date.getDate() < birthDate.getDate())) {
      age--;
    }
    pax.type = this.returnType(age);
    return pax.type;
  }

  toggleDeleteTraveler() {
    $('#deleteTravelerModal').modal('toggle');
  }

  deleteTravelers() {
    this.progress = true;
    this.selection.forEach(t => {
      this.travelersService.deleteTraveler(t).subscribe((res) => {
        console.log('Deleted => ', t);
      }, error => {
        this.loading = false;
        console.error('Error => ', error);
      });
    });
    this.selection = [];
    this.progress = false;
    this.toggleDeleteTraveler();
    this.getTravelersList();
  }

}
