<table *ngIf="data.length; else noData" mat-table [dataSource]="data" class="mat-elevation-z1">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="company">
    <th mat-header-cell *matHeaderCellDef> Company </th>
    <td mat-cell *matCellDef="let element"> {{element.company.name}} </td>
  </ng-container>


  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef>  </th>
    <td mat-cell *matCellDef="let element">

      <button class="iconButtons" [routerLink]="'/edit/' + element._id" mat-icon-button>
        <mat-icon>edit</mat-icon>
      </button>
      <button class="iconButtons" (click)="handleDelete(element)" color="warn" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<ng-template #noData>
  <div class="no-data-container">
    <h2>No data found.</h2>
  </div>
</ng-template>
