import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NotifierModule } from 'angular-notifier';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';

import { MainmoduleModule } from './modules/mainmodule/mainmodule.module';
import { LoginModule } from './modules/login/login.module';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './modules/material/material.module';
import { SigninComponent } from './modules/singlesingin/signin/signin.component';
import { SinglesinginModule } from './modules/singlesingin/singlesingin.module';
import { ErrorNotificationModule } from './modules/errorNotification/errorNotification.module';
import { PolicyrequestsComponent } from './components/policyrequests/policyrequests.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {SharedModule} from "./modules/shared/shared.module";

export function HttpLoaderFactory(http : HttpClient){
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    PolicyrequestsComponent,
  ],
  imports: [
    MaterialModule,
    MainmoduleModule,
    LoginModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgApexchartsModule,
    SinglesinginModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 20,
        },
        vertical: {
          position: 'top',
          distance: 70,
        },
      },
      behaviour: {
        autoHide: 5000,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
    ErrorNotificationModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SharedModule,
  ],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3000}},
    {
      provide: LOCALE_ID,
      useValue: 'it-IT',
      // deps: [DateService],      // some service handling global settings
      // tslint:disable-next-line:no-shadowed-variable
      // useFactory: (DateService) => DateService.returnLocId()
    },
  ],
  bootstrap: [AppComponent],
  exports: [

  ]
})
export class AppModule{
  constructor(){
    registerLocaleData(localeIt, 'it', localeItExtra);
  }
}

