import { Component, OnInit } from '@angular/core';
import {AdmserviceService} from '../../../../services/adminserv/admservice.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-flightcryptic',
  templateUrl: './flightcryptic.component.html',
  styleUrls: ['./flightcryptic.component.scss']
})
export class FlightcrypticComponent implements OnInit {
  loading: boolean;
  flightCryptic: any;
  searchTerm: string;
  constructor(
    private admService: AdmserviceService,
  ) {
    this.searchTerm = '';
  }

  ngOnInit(): void {
    this.getFlCrypt();
  }

  getFlCrypt() {
    this.loading = true;
    this.admService.getFlightsCryptic().subscribe((res) => {
      this.loading = false;
      this.flightCryptic = this.toText(res.message);
    }, error => {
      this.loading = false;
      console.error('error => ', error);
    });
  }

  toText(toSet) {
    return toSet.replace(new RegExp('\n', 'g'), '<br/>');
  }

}
