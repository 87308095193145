<div *ngIf="!data.isGat">
  <div>
    <h5 *ngIf="!data.emission">{{'OPEN_TICKET'|translate}}</h5>
    <h5 *ngIf="data.emission">{{'ASK_EMISSION'|translate}}</h5>
    <hr/>
    <div>
      <form>
        <mat-form-field class="full-width" *ngIf="departements">
          <mat-label>{{'SELECT_DEPARTMENT'|translate}}</mat-label>
          <mat-select [(ngModel)]="ticket.department" [ngModelOptions]="{standalone:true}">
            <mat-option>{{'SELECT_DEPARTMENT'|translate}}</mat-option>
            <mat-option *ngFor="let dep of departements" [value]="dep.value">
              {{dep.name|translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width" *ngIf="sections">
          <mat-label>{{'SELECT_SECTION'|translate}}</mat-label>
          <mat-select [(ngModel)]="ticket.section" [ngModelOptions]="{standalone:true}">
            <mat-option>{{'SELECT_SECTION'|translate}}</mat-option>
            <mat-option *ngFor="let sect of sections" [value]="sect.value">
              {{sect.description|translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{'SUBJECT'|translate}}</mat-label>
          <input matInput [(ngModel)]="ticket.subject" [ngModelOptions]="{standalone:true}">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{'YOUR_REQUEST'|translate}}</mat-label>
          <textarea matInput [(ngModel)]="ticket.body.data" [ngModelOptions]="{standalone:true}"></textarea>
        </mat-form-field>
      </form>
    </div>
  </div>
  <hr/>
  <div class="text-right mb-2" *ngIf="loading">
    {{'SENDING_TICKET'|translate}}
    <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
  </div>
  <div *ngIf="!loading" class="buttonrow text-right mb-2">
    <button mat-stroked-button (click)="close()">{{'ABORT'|translate}}</button>
    <button mat-stroked-button [disabled]="!validForm()" (click)="sendTicket()">{{'SEND_REQUEST'|translate}}</button>
  </div>
</div>

<div *ngIf="data.isGat">
  <div *ngIf="loadingUser || loadingCompanyOp || loadingCompany">
    <div class="text-center mb-2">
      {{'LOADING'|translate}}
    </div>
    <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
  </div>
  <div *ngIf="!loadingUser && user && !loadingCompanyOp && !loadingCompany &&!opetarive" class="text-center">
    <br/>
    Si è verificato un problema.
    <br/>
    Ti preghiamo di contattarci al numero:<br/> +39 0392622860
    <br/>
    <br/>
  </div>
  <form class="formGat" *ngIf="!loadingUser && user && !loadingCompanyOp && !loadingCompany && !sending">
    <input type=hidden name="orgid" [(ngModel)]="form.orgid">
    <input type=hidden name="retURL" [(ngModel)]="form.retURL">
    <input type="hidden" name="debug" [(ngModel)]="form.debug">
    <input type="hidden" name="debugEmail" [(ngModel)]="form.debugEmail">

    <div class="mb-3" class="d-none">
      <label for="recordType">Tipo di record caso</label>
      <select id="recordType" name="recordType" [(ngModel)]="form.recordType">
        <option value="0127T000000PElV">Assistenza BT Center Agenzie</option>
      </select>
    </div>

    <div class="mb-2 d-none">
      <label for="00N2o000008u57G">Unità Operativa:</label>
      <input id="00N2o000008u57G" maxlength="255" name="00N2o000008u57G" size="20" type="text" [(ngModel)]="opetarive"/>
    </div>

    <div class="mb-3 d-none">
      <label for="name">Nome referente</label>
      <input id="name" maxlength="80" name="name" size="20" type="text" [(ngModel)]="form.name" />
    </div>

    <div class="mb-3 d-none">
      <label for="email">Email</label>
      <input id="email" maxlength="80" name="email" size="20" [(ngModel)]="form.email" type="text" />
    </div>

    <div class="mb-2 d-none">
      <label for="phone">Telefono</label>
      <input id="phone" maxlength="40" name="phone" size="20" [(ngModel)]="form.phone" type="text" />
    </div>

    <div class="d-none">
      <select type="hidden" id="00N2o000008uM6n" name="00N2o000008uM6n" title="Provenienza">
        <option value="FlyGattinoni" selected>FlyGattinoni</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="00N7T000000qGJS">Tipologia segnalazione:</label>
      <select  id="00N7T000000qGJS" name="00N7T000000qGJS" title="Tipologia case" [(ngModel)]="selectedType" (ngModelChange)="changeType($event)">

        <option [value]="'Aggiunta servizi extra'">Aggiunta servizi extra</option>
        <option [value]="'Gestione schedule change'">Gestione schedule change</option>
        <option [value]="'Richiesta di rimborso'">Richiesta di rimborso</option>
        <option [value]="'Richiesta informazioni'">Richiesta informazioni</option>
        <option [value]="'Riemissione'">Riemissione</option>
      </select>
      <div *ngIf="!selectedType" class="small text-danger">{{'REQUIRED_FIELD'|translate}}</div>
    </div>

    <div class="mb-3" *ngIf="selectedType">
      <label for="00N7T000000qFOY">Tipologia di dettaglio segnalazione</label>
      <select  id="00N7T000000qFOY" name="00N7T000000qFOY" title="Tipologia di case di dettaglio" [(ngModel)]="selectedDetail" (ngModelChange)="changeTypeDet($event)">
        <ng-container *ngIf="selectedType == 'Aggiunta servizi extra'">
          <option [value]="option" *ngFor="let option of extra">
            {{option}}
          </option>
        </ng-container>
        <ng-container *ngIf="selectedType == 'Gestione schedule change'">
          <option [value]="option" *ngFor="let option of schedule">
            {{option}}
          </option>
        </ng-container>
        <ng-container *ngIf="selectedType == 'Richiesta di rimborso'">
          <option [value]="option" *ngFor="let option of rimborso">
            {{option}}
          </option>
        </ng-container>
        <ng-container *ngIf="selectedType == 'Richiesta informazioni'">
          <option [value]="option" *ngFor="let option of informazioni">
            {{option}}
          </option>
        </ng-container>
        <ng-container *ngIf="selectedType == 'Riemissione'">
          <option [value]="option" *ngFor="let option of riemissione">
            {{option}}
          </option>
        </ng-container>
      </select>
      <div *ngIf="!selectedDetail" class="small text-danger">{{'REQUIRED_FIELD'|translate}}</div>
    </div>

    <div class="mb-3">
      <label for="subject">Oggetto</label>
      <input  id="subject" maxlength="80" name="subject" size="20" type="text" [(ngModel)]="form['subject']"/>
    </div>

    <div class="mb-3">
      <label for="00N7T000001BIcJ">N° PNR:</label>
      <input id="00N7T000001BIcJ" maxlength="30" name="00N7T000001BIcJ" size="20" type="text" [(ngModel)]="pnrGt"/>
    </div>

    <div class="mb-3">
      <label for="description">Descrizione</label>
      <textarea id="description" name="description" [(ngModel)]="form['description']"></textarea>
    </div>

    <div class="mb-3 text-right">
      <button class="gatBtn" (click)="sendGatForm()" [disabled]="!selectedType || !selectedDetail || !form.subject || !form.description" type="submit" name="submit">Invia</button>
    </div>

  </form>
  <div *ngIf="sending">
    <div class="text-center mb-2" >
      Invio richiesta
    </div>
    <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
  </div>


</div>
