import { Component, HostListener, OnInit } from '@angular/core';
import {HelperFunctionsService} from "../../../services/helper/helper-functions.service";
import {AppComponent} from "../../../app.component";

@Component({
  selector: 'app-pwa',
  templateUrl: './pwa.component.html',
  styleUrls: ['./pwa.component.scss']
})
export class PwaComponent implements OnInit{
  languages: string[];
  showBottomDialog = true;
  hideBanner = false;
  constructor(
    public helper: HelperFunctionsService,
    public appcompo: AppComponent
  ){
    this.languages = ['it', 'en'];
  }
  public promptEvent:any;
  @HostListener('window:beforeinstallprompt', ['$event'])

  onbeforeinstallprompt(e:any) {
    e.preventDefault();
    this.promptEvent = e;
  }

  public installPWA() {
    this.promptEvent.prompt();
  }

  public shouldInstall(): boolean {
    return !this.isRunningStandalone() && this.promptEvent;
  }

  public isRunningStandalone(): boolean {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

  ngOnInit(): void {
    this.shouldInstall();
    const hideBottomBanner = localStorage.getItem('hideBottomBanner');
    this.hideBanner = hideBottomBanner ? JSON.parse(hideBottomBanner) : false;

    if(!this.hideBanner){
      setTimeout(()=>{
        this.inoutAnimation();
      }, 1000);
    }
  }

  inoutAnimation(hide?:boolean){
    const div = document.getElementById('dialogAnim')!;
    div.classList.toggle('showY');
    if(hide){
      setTimeout(()=>{
        this.toggleBottom();
      }, 1000);
    }
  }

  toggleBottom(){
    this.showBottomDialog =! this.showBottomDialog;
  }

  hideMessage(){
    localStorage.setItem('hideBottomBanner', JSON.stringify(true));
    this.inoutAnimation(true);
  }
}
