<custom-title></custom-title>
<div class="padding20">

  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <div class="mb-4 buttonrow" *ngIf="!loading">
    <button [color]="tab === currentTab ? 'primary' : undefined" *ngFor="let tab of availableTabs" (click)="setTab(tab)" mat-raised-button>
      {{availableSections[tab]|translate}}
    </button>
    <button [disabled]="loadingPdf" mat-raised-button (click)="downloadPDF()">{{'PRINT'|translate}}</button>
    <div *ngIf="loadingPdf" class="pt-3 mb-3">
      <mat-progress-bar mode="buffer"></mat-progress-bar>
    </div>
  </div>

  <div class="row" *ngIf="!loading && generalStats">
    <div class="col-lg-6">
      <mat-card appearance="outlined" class="example-card mb-3" id="printpdf">
        <mat-card-header>
          <div mat-card-avatar class="all-year-image"></div>
          <mat-card-title>Report aziende</mat-card-title>
          <mat-card-subtitle>
            Segmenti ed incentivi
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

          <div class="row statsRow mb-3" *ngFor="let item of generalStats.data;let i = index; let l = last">
            <div class="col-6 align-self-center">
              <span style="font-size:10px; text-transform: uppercase;">{{item.name}}</span><br/>
              Segmenti: <span class="smalltext">{{item.segments|number}}</span><br/>
              Pnr: <span class="smalltext">{{item.pnr|number}}</span><br/>
              Totale emissioni:  <span class="smalltext">{{item.amount || 0|number:'.2-2'}} €</span><br/>
              Incentivi: <span class="smalltext">{{item.fees || 0|number:'.2-2'}} €</span>
            </div>
            <div class="col-6 align-self-center">
              <div class="statBar">
                <div class="percBar linked" [style.width.%]="countTotal(i,'segments')"></div>
                <span>{{countTotal(i, 'segments')}}%</span>
              </div>
            </div>
            <div class="col-12" *ngIf="!l"><hr/></div>
          </div>

        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-6">
      <mat-card appearance="outlined" class="example-card mb-3">
        <mat-card-header>
          <div mat-card-avatar class="filter-image"></div>
          <mat-card-title>Filtra report</mat-card-title>
          <mat-card-subtitle>
            Filtra per data
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="row mb-3">
            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Dal</mat-label>
                <input matInput [max]="toGeneral" [matDatepicker]="picker" [(ngModel)]="fromGeneral">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Al</mat-label>
                <input matInput [min]="fromGeneral" [max]="today" [matDatepicker]="pickerto" [(ngModel)]="toGeneral">
                <mat-datepicker-toggle matSuffix [for]="pickerto"></mat-datepicker-toggle>
                <mat-datepicker #pickerto></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-12 text-right buttonrow">
              <button mat-button (click)="getCompaniesStats(true)">Filtra</button>
            </div>
          </div>

          <div>
            <p>Totale segmenti emessi: {{generalStats.total}}</p>
            <p>Totale incentivi: {{generalStats.fees || 0|number:'.2-2'}} €</p>
            <p>Totale emissioni: {{generalStats.amount || 0|number:'.2-2'}} €</p>
          </div>
          <div>
            <p>Aziende attive: {{activeCompanies}}</p>
            <p>Aziende non attive: {{inactiveCompanies}}</p>
          </div>


          <div>
            <hr/>
            <div class="mb-2">Export annuale</div>
            <div class="row">
              <div class="col-4 align-self-center">
                <mat-form-field class="full-width" appearance="fill">
                  <mat-label>Selezione anno</mat-label>
                  <mat-select [(ngModel)]="yearSelection">
                    <mat-option [value]="year" *ngFor="let year of years">
                      {{year}}
                    </mat-option>
                  </mat-select>

                </mat-form-field>
              </div>
              <div class="col-4 align-self-center">
                <div class="">
                  <button class="dsButtonPrimary pointer" style="border:none" (click)="downLoadAnnual()">Dowload</button>
                </div>
              </div>
            </div>




          </div>

        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
