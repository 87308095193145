import {
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { BottomlowcostComponent } from '../../searchflight/bottomlowcost/bottomlowcost.component';
import * as fareData from '../../searchflight/searchresults/ratesDetailsSupportedCompany.json';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { PriceService } from '../../../../../../services/price/price.service';
import { AppComponent } from '../../../../../../app.component';
import { IatacompleteService } from '../../../../../../services/iatacomplete/iatacomplete.service';
import { MainComponent } from '../../../../../mainmodule/main/main.component';
import { NewTraveller } from '../../../../../../classes/travelers/newTraveller';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import {
    animate,
    group,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { FlightService } from '../../../../../../services/flight/flight.service';
import { Observable } from 'rxjs';

export interface DialogData{
    reservation : any;
    accept : boolean;
    isSbt : boolean;
    isGat : boolean;
    newPrice : number;
    prepayed : number;
    netTotalPrice : number;
    notRefundablePrice : number;
    fees : number;
    creditCardFee : number;
    domain : string;
}

@Component({
    selector: 'app-changed-searchresult',
    templateUrl: './changed-searchresult.component.html',
    styleUrls: ['./changed-searchresult.component.scss'],
    animations: [
        trigger('listItemAnimation', [
            transition(':enter', [
                style({ height: '0px', overflow: 'hidden' }),
                group([animate('250ms ease-out', style({ height: '!' }))]),
                // although group is useless here (since we have only one animation)
                // i like to leave it anyway just in case i want to add another
                // parallel animation in the future
            ]),
            transition(':leave', [
                style({ height: '!', overflow: 'hidden' }),
                group([animate('250ms ease-out', style({ height: '0px' }))]),
            ]),
        ]),
    ],
})
export class ChangedSearchresultComponent implements OnInit{
    @Input() lowCost;
    @Input() searchResults;
    @Input() request;
    @Input() mustBeVisible;
    @ViewChild(MatPaginator) paginator : MatPaginator;
    ListSupportedRatesDetails;
    filteredRes : any;
    travellers : NewTraveller[];
    requestedPage = 1;
    currentPage = 0;
    pageSize = 5;
    pageSizeOptions : number[] = [5, 10];
    paginatorLength : number;
    selectedStops : any[];
    processTermChangedResp : any;
    accept : boolean;
    loader : boolean;
    domain : string;
    availableLowTarifs : any = [];
    dataSource : MatTableDataSource<any>;
    obs : Observable<any>;
    minPriceModel : number;
    maxPriceModel : number;
    minPrice : number;
    maxPrice : number;
    carrierToFilter : string[];
    carriersAvailable : string[];
    allPrice : number[];
    defaultFilter : any [];
    filterTimeMin : any [];
    filterTimeMax : any [];
    availableStops : any[];
    filterTime = ['no_preference', 'morning', 'afternoon', 'evening', 'night'];


    constructor(
        private newBottonSheet : MatDialog,
        public priceservice : PriceService,
        public appComponent : AppComponent,
        public iataComplete : IatacompleteService,
        public mainComponent : MainComponent,
        private flightService : FlightService,
        public dialog : MatDialog,
        private router : Router,
        public translate : TranslateService,
        private changeDetectorRef : ChangeDetectorRef){
        this.travellers = [];
        this.selectedStops = [];
        this.loader = false;
        this.carrierToFilter = [];
        this.minPrice = 0;
        this.maxPriceModel = 0;
        this.minPriceModel = 0;
        this.maxPrice = 0;
        this.carriersAvailable = [];
        this.allPrice = [];
        this.defaultFilter = [];
        this.filterTimeMin = [];
        this.filterTimeMax = [];
        this.availableStops = [];
    }

    ngOnInit() : void{
        this.getFareData();
        console.log(this.searchResults);
        if (this.appComponent.isSbt){
            this.domain = 'https://suite4travel.gattinoni.it/flight/checklowcost';
        } else{
            this.domain = 'https://flyleo.lts-srl.it/flight/checklowcost';
        }

        console.log('request =>', this.request);
        for (let i = 0; i < this.searchResults.recommendations.length; i ++){
            this.searchResults.recommendations[i].originalPrice = this.searchResults.recommendations[i].fare.amount;
        }
        const stops = [];
        for (let i = 0; i < this.searchResults.itineraries.length; i ++){
            this.defaultFilter.push('no_preference');
            this.filterTimeMin.push(0);
            this.filterTimeMax.push(24);
            this.availableStops.push(stops);

            for (let s = 0; s < this.searchResults.itineraries[i].sections.length; s ++){
                const sect = this.searchResults.itineraries[i].sections[s];
                if (!this.availableStops[i].includes(sect.flights.length - 1)){
                    this.availableStops[i].push(sect.flights.length - 1);
                }
            }
            this.availableStops[i].sort((a, b) => b - a);
        }
        for (let i = 0; i < this.searchResults.itineraries.length; i ++){
            this.selectedStops.push(this.availableStops[i][0]);
        }
        this.pushCarrier();
        console.log('obj elaborato per filtri ' + JSON.stringify(this.obs));
    }

    getFareData(){
        this.ListSupportedRatesDetails = [{
            supplier: 'lufthansa',
            marketingCarrier: 'LH',
            tarifs:
                [
                    {
                        name: 'Economy Light',
                        subName: '',
                        details: [
                            {
                                icon: 'fastfood',
                                name: 'Snack e bevande a pagamento',
                                description: '',
                            },
                            {
                                icon: 'work',
                                name: '1 bagaglio a mano',
                                description: 'Massimo 8Kg',
                            },
                            {
                                icon: 'euro_symbol',
                                name: 'Cambio prenotazione consentito',
                                description: 'Costo 70€ più possibile differenza tariffaria',
                            },
                            {
                                icon: 'mood_bad',
                                name: 'Rimborso non consentito',
                                description: '',
                                fixedMessage: 'Suggeriamo di verificare sempre le informazioni sul sito della compagnia.',
                                link: 'https://www.lufthansa.com/',
                            },
                        ],
                    },
                    {
                        name: 'Economy Classic',
                        subName: '',
                        details: [
                            {
                                icon: 'fastfood',
                                name: 'Snack e bevande a pagamento',
                                description: '',
                            },
                            {
                                icon: 'airline_seat_recline_normal',
                                name: 'Prenotazione del posto',
                                description: '',
                            },
                            {
                                icon: 'work',
                                name: '1 Bagaglio registrato',
                                description: 'Massimo 23Kg',
                            },
                            {
                                icon: 'work',
                                name: '1 bagaglio a mano',
                                description: 'Massimo 8Kg',
                            },
                            {
                                icon: 'euro_symbol',
                                name: 'Cambio prenotazione senza supplemento',
                                description: 'Potrebbe essere applicata una differenza tariffaria',
                            },
                            {
                                icon: 'mood_bad',
                                name: 'Rimborso non consentito',
                                description: '',
                                fixedMessage: 'Suggeriamo di verificare sempre le informazioni sul sito della compagnia.',
                                link: 'https://www.lufthansa.com/',
                            },
                        ],
                    },
                    {
                        name: 'Economy Flex',
                        subName: '',
                        details: [
                            {
                                icon: 'fastfood',
                                name: 'Snack e bevande a pagamento',
                                description: '',
                            },
                            {
                                icon: 'fast_forward',
                                name: 'Imbarco prioritario',
                                description: '',
                            },
                            {
                                icon: 'airline_seat_recline_normal',
                                name: 'Prenotazione del posto',
                                description: '',
                            },
                            {
                                icon: 'work',
                                name: '1 Bagaglio registrato',
                                description: 'Massimo 23Kg',
                            },
                            {
                                icon: 'work',
                                name: '1 bagaglio a mano',
                                description: 'Massimo 8Kg',
                            },
                            {
                                icon: 'euro_symbol',
                                name: 'Cambio prenotazione senza supplemento',
                                description: 'Potrebbe essere applicata una differenza tariffaria',
                            },
                            {
                                icon: 'mood_bad',
                                name: 'Rimborsabile',
                                description: 'Tranne 70€',
                                fixedMessage: 'Suggeriamo di verificare sempre le informazioni sul sito della compagnia.',
                                link: 'https://www.lufthansa.com/',

                            },
                        ],
                    },
                ],
        }, {
            supplier: 'Austrian air',
            marketingCarrier: 'OS',
            tarifs:
                [
                    {
                        name: 'Economy Light',
                        subName: '',
                        details: [
                            {
                                icon: 'fastfood',
                                name: 'Snack e bevande a pagamento',
                                description: '',
                            },
                            {
                                icon: 'work',
                                name: '1 bagaglio a mano',
                                description: 'Massimo 8Kg',
                            },
                            {
                                icon: 'euro_symbol',
                                name: 'Cambio prenotazione consentito',
                                description: 'Costo 70€ più possibile differenza tariffaria',
                            },
                            {
                                icon: 'mood_bad',
                                name: 'Rimborso non consentito',
                                description: '',
                                fixedMessage: 'Suggeriamo di verificare sempre le informazioni sul sito della compagnia.',
                                link: 'https://www.austrian.com/',
                            },
                        ],
                    },
                    {
                        name: 'Economy Classic',
                        subName: '',
                        details: [
                            {
                                icon: 'fastfood',
                                name: 'Snack e bevande a pagamento',
                                description: '',
                            },
                            {
                                icon: 'airline_seat_recline_normal',
                                name: 'Prenotazione del posto',
                                description: '',
                            },
                            {
                                icon: 'work',
                                name: '1 Bagaglio registrato',
                                description: 'Massimo 23Kg',
                            },
                            {
                                icon: 'work',
                                name: '1 bagaglio a mano',
                                description: 'Massimo 8Kg',
                            },
                            {
                                icon: 'euro_symbol',
                                name: 'Cambio prenotazione senza supplemento',
                                description: 'Potrebbe essere applicata una differenza tariffaria',
                            },
                            {
                                icon: 'mood_bad',
                                name: 'Rimborso non consentito',
                                description: '',
                                fixedMessage: 'Suggeriamo di verificare sempre le informazioni sul sito della compagnia.',
                                link: 'https://www.austrian.com/',
                            },
                        ],
                    },
                    {
                        name: 'Economy Flex',
                        subName: '',
                        details: [
                            {
                                icon: 'fastfood',
                                name: 'Snack e bevande a pagamento',
                                description: '',
                            },
                            {
                                icon: 'fast_forward',
                                name: 'Imbarco prioritario',
                                description: '',
                            },
                            {
                                icon: 'airline_seat_recline_normal',
                                name: 'Prenotazione del posto',
                                description: '',
                            },
                            {
                                icon: 'work',
                                name: '1 Bagaglio registrato',
                                description: 'Massimo 23Kg',
                            },
                            {
                                icon: 'work',
                                name: '1 bagaglio a mano',
                                description: 'Massimo 8Kg',
                            },
                            {
                                icon: 'euro_symbol',
                                name: 'Cambio prenotazione senza supplemento',
                                description: 'Potrebbe essere applicata una differenza tariffaria',
                            },
                            {
                                icon: 'mood_bad',
                                name: 'Rimborsabile',
                                description: 'Tranne 70€',
                                fixedMessage: 'Suggeriamo di verificare sempre le informazioni sul sito della compagnia.',
                                link: 'https://www.austrian.com/',

                            },
                        ],
                    },
                ],
        }, {
            supplier: 'Swiss International',
            marketingCarrier: 'LX',
            tarifs:
                [
                    {
                        name: 'Economy Light',
                        subName: '',
                        details: [
                            {
                                icon: 'fastfood',
                                name: 'Snack e bevande a pagamento',
                                description: '',
                            },
                            {
                                icon: 'work',
                                name: '1 bagaglio a mano',
                                description: 'Massimo 8Kg',
                            },
                            {
                                icon: 'euro_symbol',
                                name: 'Cambio prenotazione consentito',
                                description: 'Costo 70€ più possibile differenza tariffaria',
                            },
                            {
                                icon: 'mood_bad',
                                name: 'Rimborso non consentito',
                                description: '',
                                fixedMessage: 'Suggeriamo di verificare sempre le informazioni sul sito della compagnia.',
                                link: 'https://www.swiss.com/',
                            },
                        ],
                    },
                    {
                        name: 'Economy Classic',
                        subName: '',
                        details: [
                            {
                                icon: 'fastfood',
                                name: 'Snack e bevande a pagamento',
                                description: '',
                            },
                            {
                                icon: 'airline_seat_recline_normal',
                                name: 'Prenotazione del posto',
                                description: '',
                            },
                            {
                                icon: 'work',
                                name: '1 Bagaglio registrato',
                                description: 'Massimo 23Kg',
                            },
                            {
                                icon: 'work',
                                name: '1 bagaglio a mano',
                                description: 'Massimo 8Kg',
                            },
                            {
                                icon: 'euro_symbol',
                                name: 'Cambio prenotazione senza supplemento',
                                description: 'Potrebbe essere applicata una differenza tariffaria',
                            },
                            {
                                icon: 'mood_bad',
                                name: 'Rimborso non consentito',
                                description: '',
                                fixedMessage: 'Suggeriamo di verificare sempre le informazioni sul sito della compagnia.',
                                link: 'https://www.swiss.com/',
                            },
                        ],
                    },
                    {
                        name: 'Economy Flex',
                        subName: '',
                        details: [
                            {
                                icon: 'fastfood',
                                name: 'Snack e bevande a pagamento',
                                description: '',
                            },
                            {
                                icon: 'fast_forward',
                                name: 'Imbarco prioritario',
                                description: '',
                            },
                            {
                                icon: 'airline_seat_recline_normal',
                                name: 'Prenotazione del posto',
                                description: '',
                            },
                            {
                                icon: 'work',
                                name: '1 Bagaglio registrato',
                                description: 'Massimo 23Kg',
                            },
                            {
                                icon: 'work',
                                name: '1 bagaglio a mano',
                                description: 'Massimo 8Kg',
                            },
                            {
                                icon: 'euro_symbol',
                                name: 'Cambio prenotazione senza supplemento',
                                description: 'Potrebbe essere applicata una differenza tariffaria',
                            },
                            {
                                icon: 'mood_bad',
                                name: 'Rimborsabile',
                                description: 'Tranne 70€',
                                fixedMessage: 'Suggeriamo di verificare sempre le informazioni sul sito della compagnia.',
                                link: 'https://www.swiss.com/',

                            },
                        ],
                    },
                ],
        }, {
            supplier: 'Brussels airlines',
            marketingCarrier: 'SN',
            tarifs:
                [
                    {
                        name: 'Economy Light',
                        subName: '',
                        details: [
                            {
                                icon: 'fastfood',
                                name: 'Snack e bevande a pagamento',
                                description: '',
                            },
                            {
                                icon: 'work',
                                name: '1 bagaglio a mano',
                                description: 'Massimo 8Kg',
                            },
                            {
                                icon: 'euro_symbol',
                                name: 'Cambio prenotazione consentito',
                                description: 'Costo 70€ più possibile differenza tariffaria',
                            },
                            {
                                icon: 'mood_bad',
                                name: 'Rimborso non consentito',
                                description: '',
                                fixedMessage: 'Suggeriamo di verificare sempre le informazioni sul sito della compagnia.',
                                link: 'https://www.brusselsairlines.com/',
                            },
                        ],
                    },
                    {
                        name: 'Economy Classic',
                        subName: '',
                        details: [
                            {
                                icon: 'fastfood',
                                name: 'Snack e bevande a pagamento',
                                description: '',
                            },
                            {
                                icon: 'airline_seat_recline_normal',
                                name: 'Prenotazione del posto',
                                description: '',
                            },
                            {
                                icon: 'work',
                                name: '1 Bagaglio registrato',
                                description: 'Massimo 23Kg',
                            },
                            {
                                icon: 'work',
                                name: '1 bagaglio a mano',
                                description: 'Massimo 8Kg',
                            },
                            {
                                icon: 'euro_symbol',
                                name: 'Cambio prenotazione senza supplemento',
                                description: 'Potrebbe essere applicata una differenza tariffaria',
                            },
                            {
                                icon: 'mood_bad',
                                name: 'Rimborso non consentito',
                                description: '',
                                fixedMessage: 'Suggeriamo di verificare sempre le informazioni sul sito della compagnia.',
                                link: 'https://www.brusselsairlines.com/',
                            },
                        ],
                    },
                    {
                        name: 'Economy Flex',
                        subName: '',
                        details: [
                            {
                                icon: 'fastfood',
                                name: 'Snack e bevande a pagamento',
                                description: '',
                            },
                            {
                                icon: 'fast_forward',
                                name: 'Imbarco prioritario',
                                description: '',
                            },
                            {
                                icon: 'airline_seat_recline_normal',
                                name: 'Prenotazione del posto',
                                description: '',
                            },
                            {
                                icon: 'work',
                                name: '1 Bagaglio registrato',
                                description: 'Massimo 23Kg',
                            },
                            {
                                icon: 'work',
                                name: '1 bagaglio a mano',
                                description: 'Massimo 8Kg',
                            },
                            {
                                icon: 'euro_symbol',
                                name: 'Cambio prenotazione senza supplemento',
                                description: 'Potrebbe essere applicata una differenza tariffaria',
                            },
                            {
                                icon: 'mood_bad',
                                name: 'Rimborsabile',
                                description: 'Tranne 70€',
                                fixedMessage: 'Suggeriamo di verificare sempre le informazioni sul sito della compagnia.',
                                link: 'https://www.brusselsairlines.com/',

                            },
                        ],
                    },
                ],
        }];
    }

    pushCarrier(){
        for (const recommendation of this.searchResults.recommendations){
            if (!this.carriersAvailable.includes(recommendation.marketingCarrier)){
                this.carriersAvailable.push(recommendation.marketingCarrier);
            }
            this.allPrice.push(recommendation.fare.amount);
        }
        // tslint:disable-next-line:only-arrow-functions
        this.allPrice.sort((n1, n2) => n1 - n2);
        this.minPrice = Math.floor(this.allPrice[0]);
        this.minPriceModel = Math.floor(this.allPrice[0]);
        this.maxPriceModel = Math.ceil(this.allPrice[this.allPrice.length - 1]);
        this.maxPrice = Math.ceil(this.allPrice[this.allPrice.length - 1]);
        console.log('this.carriersAvailable => ', this.carriersAvailable);
        this.doSort();
    }

    passPriceFilter(reco){
        if (reco.fare.amount >= this.minPriceModel && reco.fare.amount <= this.maxPriceModel){
            return true;
        }
        return false;
    }


    doSort(){
        console.log('START PAGINATION');
        this.filteredRes = [];
        console.log('this.selectedStops => ', this.selectedStops);
        for (let i = 0; i < this.searchResults.recommendations.length; i ++){
            const reco = this.filterStopsAndTime(i);
            let recoPassed = true;
            // tslint:disable-next-line:prefer-for-of
            for (let r = 0; r < reco.itineraries.length; r ++){
                const index = reco.itineraries[r].sections.find(s => s.hidden === false);
                const indexT = reco.itineraries[r].sections.find(s => s.hiddenT === false);
                if (index && indexT){
                    reco.itineraries[r].passedI = true;
                    // recoPassed = false;
                } else{
                    reco.itineraries[r].passedI = false;
                }
            }
            recoPassed = reco.itineraries.every(v => v.passedI === true);
            if (recoPassed && this.isInPriceRange(reco.originalPrice) && this.isInCarrierFilter(reco.marketingCarrier)){
                this.filteredRes.push(reco);
            }
            // this.filterStops(i, 0);
        }
        this.currentPage = 0;
        this.dataSource = new MatTableDataSource<any>(this.filteredRes);
        this.changeDetectorRef.detectChanges();
        this.dataSource.paginator = this.paginator;
        this.paginator.firstPage();
        this.obs = this.dataSource.connect();
        this.closeBanner();
    }

    changeStops(event, i){
        this.selectedStops[i] = event;
        this.doSort();
    }

    isInPriceRange(price){
        if (price >= this.minPriceModel && price <= this.maxPriceModel){ return true; }
    }

    isInCarrierFilter(carrier){
        if (this.carrierToFilter.length === 0){
            return true;
        } else{
            return this.carrierToFilter.includes(carrier);
        }
    }

    isSupported(mark){
        for (let i = 0; i < this.ListSupportedRatesDetails.length; i ++){
            if (mark == this.ListSupportedRatesDetails[i].marketingCarrier){
                return true;
            }
        }
        return false;
    }

    filterStopsAndTime(i){
        const rec = JSON.parse(JSON.stringify(this.searchResults.recommendations[i]));

        for (let iti = 0; iti < rec.itineraries.length; iti ++){
            for (let s = 0; s < rec.itineraries[iti].sections.length; s ++){
                const section = rec.itineraries[iti].sections[s];
                if (this.searchResults.itineraries[iti].sections[section.ref - 1].flights.length - 1 > this.selectedStops[iti]){
                    rec.itineraries[iti].sections[s].hidden = true;
                } else{
                    rec.itineraries[iti].sections[s].hidden = false;
                }
                const flightHour = this.searchResults.itineraries[iti].sections[section.ref - 1].flights[0].departure.hours;
                rec.itineraries[iti].sections[s].hiddenT = (flightHour < this.filterTimeMin[iti] || flightHour > this.filterTimeMax[iti]);
            }
        }
        if (rec.lowCost && this.lowCost){
            for (let c = 0; c < rec.cabins.length; c ++){
                if (!rec.cabins[c].name){
                    const name = this.returnLowTariffName(rec.cabins[c]);
                    if (!this.isSupported(rec.marketingCarrier)){
                        // if (rec.marketingCarrier !== 'FR') {
                        rec.cabins[c] = {
                            name,
                            description: this.returnLowTariffDescription(rec.cabins[c]),
                        };
                    } else{
                        const i = this.getIndex(rec.marketingCarrier);
                        if (i == - 1){
                            rec.cabins[c] = {
                                name,
                                services: [],
                            };
                        } else{
                            rec.cabins[c] = {
                                name,
                                services: (this.availableLowTarifs[i][name]) ? this.availableLowTarifs[i][name] : [],
                            };
                        }
                    }
                }
            }
        }
        return rec;
    }


    returnLowTariffDescription(value){
        if (value.includes('*')){
            const index = value.split('*');
            return index[1].split('=')[1];
        } else{
            return '';
        }

    }

    getIndex(marketingCarrier){
        for (let i = 0; i < this.ListSupportedRatesDetails.length; i ++){
            if (marketingCarrier == this.ListSupportedRatesDetails[i].marketingCarrier){
                return this.availableLowTarifs.findIndex(ele => ele.supplier == this.ListSupportedRatesDetails[i].supplier);
            }
        }
    }

    openBottomSheetLow(cabins, mk, reco){
        if (typeof reco === 'string'){
            reco = { name: reco.split('=')[1].split('*')[0].split(' ')[1], description: '' };
        }
        if (!Array.isArray(reco)){
            reco = [reco];
        }
        let info;
        if (Array.isArray(cabins) && cabins.length === 2){
            info = this.getRatesData(mk, reco, cabins.length);
        } else{
            info = this.getRatesData(mk, reco);
        }

        this.newBottonSheet.open(BottomlowcostComponent, {
            data: { cabins, mk, reco, info },
            panelClass: 'customLowCostStyle',
        });
        console.log('per aprire il modale prende questi parametri ' + JSON.stringify(cabins), mk, reco);
    }

    returnLowTariffName(value){
        const index = value.split('*');
        if (index[0].split('=')[1] !== ' '){
            return index[0].split('=')[1];
        } else{
            return index[2].split('=')[1];
        }
    }

    getRatesData(marketing, tarif, tarifNum = 1){
        const dataList = { ...fareData };
        const moreRet : any = [];
        let toRet : any = {};
        for (let y = 0; y < tarifNum; y ++){
            toRet = {};
            for (let i = 0; i < dataList.length; i ++){
                if (dataList[i].marketingCarrier.toUpperCase() === marketing.toUpperCase()){
                    toRet.supplier = dataList[i].supplier;
                    for (const tar of dataList[i].tarifs){
                        if (tar.name.toUpperCase() === (tarif[y].name.toUpperCase() || '')){
                            toRet.tarif = tar;
                        }
                    }
                    moreRet.push(toRet);
                }
            }
        }
        return moreRet;
    }


    checkPolicy(tariff : any) : boolean{
        if (this.appComponent.isSbt && tariff.arrayOfAlerts?.length > 0){
            return !tariff.arrayOfAlerts.every(a => a.block === false);
        }
        return false;
    }

    openDialog(recoItinerari, routingId){
        const objToSend = {
            outwardId: undefined,
            returnId: undefined,
            pnrId: this.request._id,
            routingId,
        };
        this.processTermChangedResp = {};
        console.log(recoItinerari);
        console.log('prima della sua costruzione completa -> ' + JSON.stringify(objToSend));
        for (let i = 0; i < recoItinerari.length; i ++){
            if (i === 0){
                objToSend.outwardId = this.searchResults.itineraries[0].sections[recoItinerari[0].sections[0].ref - 1].flightId;
            }
            if (i === 1){
                objToSend.returnId = this.searchResults.itineraries[1].sections[recoItinerari[1].sections[0].ref - 1].flightId;
            }
        }
        console.log('Dopo la sua costruzione completa -> ' + JSON.stringify(objToSend));
        this.mustBeVisible = false;
        this.loader = true;
        this.flightService.processDetailsChangesBook(objToSend).subscribe((res) => {
            console.log('res => ', res);
            this.processTermChangedResp = res;
            this.updateFares(res);
            this.loader = false;
        }, error => {
            this.loader = false;
            console.log('error => ', error);
        });
    }


    updateFares(res){
        let newPrice = 0;
        let prepayed = 0;
        let netTotalPrice = 0;
        let notRefundablePrice = 0;
        let fees = 0;
        let creditCardFee = 0;
        if (res?.Router?.GroupList?.Group?.Price){
            const price = res.Router.GroupList.Group.Price;
            const currency = res.Router.GroupList.Group.Price.Currency;
            newPrice = Number(price.Amount);
            if (price.TaxItemList){
                if (!Array.isArray(price.TaxItemList.TaxItem)){
                    price.TaxItemList.TaxItem = [price.TaxItemList.TaxItem];
                }
                for (const item of price.TaxItemList.TaxItem){
                    if (item.Name === 'Amount Already Paid'){
                        prepayed = Number(item.Amount);
                    }
                    if (item.Name === 'Net Total Price'){
                        netTotalPrice = Number(item.Amount);
                    }
                    if (item.Name === 'Non-Refundable Amount'){
                        notRefundablePrice = Number(item.Amount);
                    }
                    if (item.Name === 'Change Booking Fee'){
                        fees = Number(item.Amount);
                    }
                    if (item.Name === 'Credit Card Fee'){
                        creditCardFee = Number(item.Amount);
                    }
                }
            }
        }
        this.openPriceDialog(res, newPrice, prepayed, netTotalPrice, fees, notRefundablePrice, creditCardFee);
    }


    openPriceDialog(reservation, newPrice, prepayed, netTotalPrice, notRefundablePrice, fees, creditCardFee) : void{
        const dialogRef = this.dialog.open(DialogLowCostConfirmChanges, {
            width: '450px',
            maxWidth: '100%',
            data: {
                accept: false,
                reservation,
                isbt: this.appComponent.isSbt,
                isGat: this.appComponent.isGat,
                newPrice,
                prepayed,
                netTotalPrice,
                notRefundablePrice,
                fees,
                creditCardFee,
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined){
                this.accept = true;
                this.checkBook();
            }
        });
    }

    checkBook() : void{
        console.log('this.accept => ', this.accept);
        let phone = this.request.company.phoneNumber;
        if (!phone.substring(0, 3).includes('+39') && !phone.substring(0, 5).includes('0039')){
            phone = '+39' + phone;
        }
        const body = {
            data: this.processTermChangedResp,
            backUrl: this.domain,
            userData: {
                value: this.request.company.email,
            },
            creditCard: {
                index: this.request.creditCard,
            },
            id: this.request._id,
        };

        if (this.appComponent.isSbt){
            console.log('SBT');
        } else{
            this.loader = true;
            this.flightService.startBookingFlow(body).subscribe((res : any) => {
                console.log('risultato modica al pnr => ', res);
                this.loader = false;
                if (res.CardVerificationData){
                    window.location.href = (res.CardVerificationData.Url);
                } else {
                    window.location.reload();
                }
            }, error => {
                console.error('res => ', error);
                this.loader = false;
            });
        }
    }

    goToPnr() : void{
        setTimeout(() => {
            this.router.navigate(['/flight/reservations/' + this.request._id]);
        }, 900);
    }

    returnZeroTime(value){
        if (value < 10){
            return value;
        } else{
            return value;
        }
    }

    returnTime(t){
        const cd = 24 * 60 * 60 * 1000;
        const ch = 60 * 60 * 1000;
        let d = Math.floor(t / cd);
        let h = Math.floor((t - d * cd) / ch);
        let m = Math.round((t - d * cd - h * ch) / 60000);
        const pad = ((n) => n < 10 ? '0' + n : n);
        if (m === 60){
            h ++;
            m = 0;
        }
        if (h === 24){
            d ++;
            h = 0;
        }
        if (d > 0){
            return [d + 'g ' + pad(h) + 'h', pad(m) + 'm'].join(':');
        } else{
            return [pad(h) + 'h', pad(m) + 'm'].join(':');
        }

    }

    toggleFilters(){
        $('.filterSearch').toggleClass('openfilters');
        $('.filtersearchBackdrop').toggleClass('openfiltersearchBackdrop');
    }

    toggleForm(){
        $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
    }

    isIncludedCarr(carrier){
        return this.carrierToFilter.includes(carrier);
    }

    filterCarrier(carrier){
        if (!this.carrierToFilter.includes(carrier)){
            this.carrierToFilter.push(carrier);
        } else{
            this.carrierToFilter = this.carrierToFilter.filter(r => r !== carrier);
        }
        this.doSort();
    }

    changeTimeFilter(event, i){
        this.defaultFilter[i] = event;
        if (this.defaultFilter[i] === 'no_preference'){
            this.filterTimeMin[i] = 0;
            this.filterTimeMax[i] = 24;
        } else if (this.defaultFilter[i] === 'morning'){
            this.filterTimeMin[i] = 6;
            this.filterTimeMax[i] = 12;
        } else if (this.defaultFilter[i] === 'afternoon'){
            this.filterTimeMin[i] = 12;
            this.filterTimeMax[i] = 18;
        } else if (this.defaultFilter[i] === 'evening'){
            this.filterTimeMin[i] = 18;
            this.filterTimeMax[i] = 24;
        } else{
            this.filterTimeMin[i] = 0;
            this.filterTimeMax[i] = 6;
        }
        this.doSort();
    }

    someFilterSelected(){
        let filters = 0;
        if (this.carrierToFilter.length > 0){
            filters += 1;
        }
        if (this.minPriceModel !== this.minPrice || this.maxPriceModel !== this.maxPrice){
            filters += 1;
        }
        for (let i = 0; i < this.selectedStops.length; i ++){
            if (this.selectedStops[i] !== this.availableStops[i][0]){
                filters += 1;
            }
        }
        for (let i = 0; i < this.defaultFilter.length; i ++){
            if (this.defaultFilter[i] !== 'no_preference'){
                filters += 1;
            }
        }
        return filters;


    }

    opeSect(rec, it, value){
        $('#collapse' + rec + it + value).collapse('toggle');
        $('#itinerary' + rec + it + value).collapse('toggle');
    }

    otherday(value, other){
        return value !== other;
    }

    scrollTop(){
        $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
    }

    pageChanged(event){
        this.scrollTop();
        console.log('paginator event', event);
        this.currentPage = event.pageIndex * event.pageSize;
        this.requestedPage = event.pageIndex + 1;
        this.closeBanner();
    }

    toggleShowFare(r){
        this.filteredRes[r + this.currentPage].showFares = !this.filteredRes[r + this.currentPage].showFares;
    }

    closeBanner(){
        for (let r = 0; r < this.filteredRes.length; r ++){
            if (this.filteredRes[r].showFares){
                this.filteredRes[r].showFares = false;
            }
        }
    }

    returnPageLength(){
        const value = (this.paginator) ? this.paginator.length / this.pageSize : 0;
        return Array(
            Math.ceil(
                value,
            ),
        );
    }

    goToPage(pageNumber){
        this.paginatorLength = Math.ceil(this.paginator.length / this.pageSize);
        this.paginator.pageIndex = pageNumber;
        this.paginator.page.next({
            pageIndex: pageNumber,
            pageSize: this.paginator.pageSize,
            length: this.paginator.length,
        });
    }

    returnPagesNumber(){
        const value = (this.paginator) ? Math.ceil(this.paginator.length / this.pageSize) : 0;
        return value;
    }
}

@Component({
    selector: 'app-priceconfirm-lowcost',
    templateUrl: 'priceconfirm-lowcost.html',
})
export class DialogLowCostConfirmChanges{

    constructor(
        public dialogRef : MatDialogRef<DialogLowCostConfirmChanges>,
        @Inject(MAT_DIALOG_DATA) public data : DialogData){ }

    onNoClick() : void{
        this.dialogRef.close();
    }

}
