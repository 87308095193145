import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Fops } from '../../../../companies/classes/fops';

@Component({
  selector: 'amadeus-cards',
  templateUrl: './amadeus-cards.component.html',
  styleUrls: ['./amadeus-cards.component.scss'],
})
export class AmadeusCardsComponent implements OnInit{
  @Input() cards : Fops[];
  @Output() selectedCardEvent = new EventEmitter<any>();

  cardSelected : any;

  constructor(){ }

  ngOnInit() : void{
  }

  getClass(card){
    return (this.cardSelected === card) ? 'mat-stroked-button mat-primary' : 'mat-raised-button mat-basic';
  }

  selectCard(card : any){
    this.cardSelected = card;
    this.selectedCardEvent.emit(card);
  }

  hideNumber(cardNumber : string){
    return '**** **** **** ' + cardNumber.substr(cardNumber.length - 4);
  }

  returnCreditCardName(nameTypeCard){
    if (nameTypeCard === 'AX') return 'American Express';
    if (nameTypeCard === 'DC') return 'Diners';
    if (nameTypeCard === 'CA') return 'Master Card';
    if (nameTypeCard === 'VI') return 'Visa';
    return '';
  }

  selectFop(card){
    card.selected = true;
    this.cardSelected = card;
    for(const c of this.cards){
      if(c !== card){
        c.selected = false;
      }
    }
    this.selectedCardEvent.emit(card);
  }


}
