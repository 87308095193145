<div class="ancillaryTabs">

  <div *ngIf="round">
    <div class="mb-3">
      <div class="tabButton" [ngClass]="{'activeTab':currentTab===i}" *ngFor="let tab of tabs; let i = index;" (click)="setCurrentTab(i)">
        {{reservation.itineraries[i].flights[0].departure.location}} -> {{reservation.itineraries[i].flights[reservation.itineraries[i].flights.length-1].arrival.location}}
      </div>
    </div>

    <div class="buttonrow mb-3">
      <button [ngClass]="{'activeBtn':i == currentPaxIndex}" *ngFor="let passenger of reservation.passengers; let i = index;" mat-stroked-button (click)="setCurrentPax(i)">
        {{passenger.surname}} {{passenger.name}}
      </button>
    </div>

    <div class="mb-3 buttonrow" *ngIf="currentTab === 0">
      <div class="table-responsive">
        <table class="table ancillariesTable">
          <tr>
            <td>{{'BAG'|translate}}</td>
            <td>{{'DESCRIPTION'|translate}}</td>
            <td>{{'PRICE'|translate}}</td>
            <td>{{reservation.passengers[currentPaxIndex].surname}} {{reservation.passengers[currentPaxIndex].name}}</td>
          </tr>
          <ng-container *ngFor="let bag of reservation.luggages.outward">
            <tr>
              <td>
                {{bag.bags}}
              </td>
              <td>
                {{bag.description}}
              </td>
              <td>
                {{bag.amount}} {{bag.currency}}
              </td>
              <td>
                <button mat-icon-button aria-label="Selection button" (click)="selectBagid(bag.id, currentPaxIndex, bag.amount, currentTab)">
                  <mat-icon *ngIf="!isSelected(bag.id, currentPaxIndex)">check_box_outline_blank</mat-icon>
                  <mat-icon *ngIf="isSelected(bag.id, currentPaxIndex)">check_box</mat-icon>
                </button>

              </td>
            </tr>
          </ng-container>

        </table>
      </div>
    </div>

    <div class="mb-3 buttonrow" *ngIf="currentTab === 1">
      <div class="table-responsive">
        <table class="table ancillariesTable">
          <tr>
            <td>{{'BAG'|translate}}</td>
            <td>{{'DESCRIPTION'|translate}}</td>
            <td>{{'PRICE'|translate}}</td>
            <td>{{reservation.passengers[currentPaxIndex].surname}} {{reservation.passengers[currentPaxIndex].name}}</td>
          </tr>
          <ng-container *ngFor="let bag of reservation.luggages.ret">
            <tr>
              <td>
                {{bag.bags}}
              </td>
              <td>
                {{bag.description}}
              </td>
              <td>
                {{bag.amount}} {{bag.currency}}
              </td>
              <td>
                <button mat-icon-button aria-label="Selection button" (click)="selectBagid(bag.id, currentPaxIndex, bag.amount, currentTab)">
                  <mat-icon *ngIf="!isSelected(bag.id, currentPaxIndex)">check_box_outline_blank</mat-icon>
                  <mat-icon *ngIf="isSelected(bag.id, currentPaxIndex)">check_box</mat-icon>
                </button>

              </td>
            </tr>
          </ng-container>

        </table>
      </div>
    </div>
  </div>

  <div *ngIf="!round">
    <div class="mb-3">
      <div class="d-inline-block mr-1" *ngFor="let tab of tabs; let i = index;let l = last">
        {{reservation.itineraries[i].flights[0].departure.location}} -> {{reservation.itineraries[i].flights[reservation.itineraries[i].flights.length-1].arrival.location}}
        <span *ngIf="!l"> -> </span>
      </div>
    </div>

    <div class="buttonrow mb-3">
      <button [ngClass]="{'activeBtn':i == currentPaxIndex}" *ngFor="let passenger of reservation.passengers; let i = index;" mat-stroked-button (click)="setCurrentPax(i)">
        {{passenger.surname}} {{passenger.name}}
      </button>
    </div>

    <div class="mb-3 buttonrow" *ngIf="reservation.luggages">
      <div class="table-responsive">
        <table class="table ancillariesTable">
          <tr>
            <td>{{'BAG'|translate}}</td>
            <td>{{'DESCRIPTION'|translate}}</td>
            <td>{{'PRICE'|translate}}</td>
            <td>{{reservation.passengers[currentPaxIndex].surname}} {{reservation.passengers[currentPaxIndex].name}}</td>
          </tr>
          <ng-container *ngIf="ancillary.length > 0 && reservation.marketingCarrier == 'U2'">
            <ng-container *ngFor="let anc of ancillary">
              <tr>
                <td colspan="2">{{anc.description}}</td>
                <td> {{anc.amount}} {{anc.currency}}</td>
                <td>
                  <button mat-icon-button aria-label="Selection button" (click)="selectAncid(anc.id, currentPaxIndex, anc.amount, 0)">
                    <mat-icon *ngIf="!isSelectedAnc(anc.id, currentPaxIndex)">check_box_outline_blank</mat-icon>
                    <mat-icon *ngIf="isSelectedAnc(anc.id, currentPaxIndex)">check_box</mat-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          
          <ng-container *ngFor="let bag of reservation.luggages.outward">
            <tr>
              <td>
                {{bag.bags}}
              </td>
              <td>
                {{bag.description}}
              </td>
              <td>
                {{bag.amount}} {{bag.currency}}
              </td>
              <td>
                <button mat-icon-button aria-label="Selection button" (click)="selectBagid(bag.id, currentPaxIndex, bag.amount, 0)">
                  <mat-icon *ngIf="!isSelected(bag.id, currentPaxIndex)">check_box_outline_blank</mat-icon>
                  <mat-icon *ngIf="isSelected(bag.id, currentPaxIndex)">check_box</mat-icon>
                </button>

              </td>
            </tr>
          </ng-container>

        </table>
      </div>
    </div>
  </div>

</div>
