import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ActivatedRoute, Router, NavigationStart, Route, NavigationEnd} from '@angular/router';
import { AppComponent } from '../../../app.component';
import { LoginResponse } from '../../../classes/loggedProfile';
import { AuthenticationService } from '../../../services/authentication.service';
import { HttpserviceService } from '../../../services/httpservice.service';
import { UsersService } from '../../../services/profile/users.service';
import { Settings } from '../../../classes/profiles/settings';

import * as $ from 'jquery';
import {animate, group, style, transition, trigger} from '@angular/animations';
import {CustomAuthService} from '../../../services/customAuth/custom-auth.service';
import {DossierService} from '../../../services/dossier/dossier.service';

import * as CryptoJS from 'crypto-js';
import {NewTraveller} from '../../../classes/travelers/newTraveller';
import {CompaniesService} from '../../../services/profile/companies.service';
import { Location } from '@angular/common';
import {SocketService} from '../../../services/socket/socket.service';
import {TravelPolicyService} from '../../../services/travel-policy/travel-policy.service';
import {FeesModService} from '../../fees/fees.service';
import {HelperFunctionsService} from '../../../services/helper/helper-functions.service';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('listItemAnimation', [
      transition(':enter', [
        style({ height: '0px', overflow: 'hidden' }),
        group([animate('250ms ease-out', style({ height: '!' }))]),
      ]),
      transition(':leave', [
        style({ height: '!', overflow: 'hidden' }),
        group([animate('250ms ease-out', style({ height: '0px' }))]),
      ]),
    ]),
  ],
})
export class MainComponent implements OnInit, OnDestroy {
  loggedUser: LoginResponse['profile'];
  userSettings: Settings;
  opened: boolean;
  mobileQuery: MediaQueryList;
  unreadManual: number;
  unreadAuto: number;
  private readonly mobileQueryListener: () => void;
  settingsLoaded: boolean;
  dossierId: string;
  dossierName: string;
  dossierItems: any[];
  newDossier: string;
  prevUser: any;
  loadNotifications: boolean;
  sarce: any;
  selectedTravelers: NewTraveller[] = [];
  numberOfAdults: number;
  numberOfChildren: number;
  numberOfInfants: number;
  currentTraveler: number;
  loadTravelers: boolean;
  editingTraveler: boolean;
  company: any;
  user: any;
  constructor(
    private location: Location,
    public appcomponent: AppComponent,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private thatroute: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private httpservice: HttpserviceService,
    private userservice: UsersService,
    private router: Router,
    public customAuth: CustomAuthService,
    private dossierSerivice: DossierService,
    private companyService: CompaniesService,
    private socketService: SocketService,
    private authService: AuthenticationService,
    private policyService: TravelPolicyService,
    private feeService: FeesModService,
    private helper: HelperFunctionsService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this.mobileQueryListener = () => {
      changeDetectorRef.detectChanges();
      if (this.mobileQuery.matches) {
        this.opened = false;
      } else {
        this.opened = true;
      }
    };

    changeDetectorRef.markForCheck();
    if (this.mobileQuery.matches) {
      this.opened = false;
    } else {
      this.opened = true;
    }

    this.mobileQuery.addListener(this.mobileQueryListener);

    this.thatroute.events.subscribe(val => {
      if (val instanceof NavigationEnd && this.mobileQuery.matches) {
        this.opened = false;
      }
    });

    if (this.authenticationService.isLoggedIn()) {
      this.loggedUser = JSON.parse(localStorage.getItem('fl_profile'));
      if(!this.loggedUser.changedPassword){
        // this.loggedUser.changedPassword = new Date('01/10/2024');
      }
      console.log('this.loggedUser => ', this.loggedUser);
      if (this.loggedUser) {
        this.getUserImage();
        if (typeof this.loggedUser.company !== 'string' && this.loggedUser.company.type !== 'A' && !this.appcomponent.isSbt) {
          this.appcomponent.onSetTheme('b2b-theme');
          this.appcomponent.setB2BSearch(true);
       }
        this.getUserCompany();
        this.getUserSettings();
        this.getUser();
       }
    }
    if (localStorage.getItem('prev_fl_profile')) {
      this.setUserAs();
    }
    this.loadNotifications = true;
    this.getDossier();

    if (this.loggedUser && this.loggedUser.role.company === false && !this.customAuth.canBook(this.loggedUser.company._id)) {
      this.opened = false;
    }
    this.numberOfAdults = 1;
    this.numberOfChildren = 0;
    this.numberOfInfants = 0;
    this.addPaxForm();

    if(!this.loggedUser) {
      this.router.navigate(['/login']);
    }

  }

  getUser(){
    this.userservice.getUser(this.loggedUser._id).subscribe((res) => {
      this.user = res;
      this.loggedUser.changedPassword = this.user.changedPassword;
      if(this.loggedUser.changedPassword){
        if(this.tooOldPassword()){
          this.router.navigate(['users/list/' + this.loggedUser._id + '/change-password']);
        }
      }
    }, error => {
    });
  }

  tooOldPassword(): boolean {
    // Ottieni la data odierna
    const oggi = new Date();

    // Calcola la differenza in millisecondi tra oggi e la data specificata
    console.log(this.loggedUser.changedPassword);
    console.log(oggi);
    const differenzaInMillisecondi = oggi.getTime() - new Date(this.loggedUser.changedPassword).getTime();

    // Calcola il numero di giorni dalla differenza in millisecondi
    const giorniTrascorsi = differenzaInMillisecondi / (1000 * 3600 * 24);
    console.log('giorni trascorsi => ', giorniTrascorsi)
    // Controlla se sono passati almeno 90 giorni
    return giorniTrascorsi >= 90;
  }

  getUserCompany(){
    this.companyService.getCompany(this.loggedUser.company._id).subscribe((res) => {
      this.company = res;
      localStorage.setItem('fl_user_cp', JSON.stringify(this.company));
  }, (error) => {
      // this.loading = false;
      console.log('error => ', error);
    });
  }

  addPaxForm() {
    this.selectedTravelers = [];
    for (let i = 0; i < this.numberOfAdults; i++) {
      const traveler = new NewTraveller();
      traveler.type = 'ADT';
      this.selectedTravelers.push(traveler);
    }
    for (let i = 0; i < this.numberOfChildren; i++) {
      const traveler = new NewTraveller();
      traveler.type = 'CHD';
      this.selectedTravelers.push(traveler);
    }
    for (let i = 0; i < this.numberOfInfants; i++) {
      const traveler = new NewTraveller();
      traveler.type = 'INF';
      this.selectedTravelers.push(traveler);
    }
  }

  addPax(value) {
    if (value === 'adt') {
      this.numberOfAdults = this.numberOfAdults + 1;
    } else if (value === 'chd') {
      this.numberOfChildren = this.numberOfChildren + 1;
    } else if (value === 'inf') {
      this.numberOfInfants = this.numberOfInfants + 1;
    }
    this.addPaxForm();
  }

  toggleDialog(currentTraveler) {
    this.currentTraveler = currentTraveler;
    $('#mainaddTravelerModal').modal('toggle');
  }

  toggleEditDialog(currentTraveler) {
    this.editingTraveler = true;
    this.currentTraveler = currentTraveler;
    $('#mainEditTravelerModal').modal('toggle');
  }
  /**
   *  Remove pax to dossier search form
   */
  removePax(value) {
    if (value === 'adt') {
      this.numberOfAdults = this.numberOfAdults - 1;
    } else if (value === 'chd') {
      this.numberOfChildren = this.numberOfChildren - 1;
    } else if (value === 'inf') {
      this.numberOfInfants = this.numberOfInfants - 1;
    }
    this.addPaxForm();
  }

  paxCounter() {
    return this.numberOfAdults + this.numberOfChildren + this.numberOfInfants;
  }

  maxPaxReached() {
    return this.numberOfAdults + this.numberOfChildren + this.numberOfInfants === 6;
  }

  maxInfReached() {
    return this.numberOfAdults + this.numberOfChildren + this.numberOfInfants === 6 ||
      this.numberOfAdults === this.numberOfInfants;
  }

  removeTraveler(i) {
    Object.keys(this.selectedTravelers[i]).forEach(k =>  {
      const type = this.selectedTravelers[i].type;
      delete this.selectedTravelers[i][k];
      this.selectedTravelers[i].type = type;
    });
  }

  loadTravelersList(currentTraveler) {
    this.currentTraveler = currentTraveler;
    $('#mainlistTravelerModal').modal('toggle');
    this.loadTravelers = true;
  }

  travelerSet(event, i) {
    this.selectedTravelers[i] = event;
    delete this.currentTraveler;
    // $('#addTravelerModal').modal('toggle');
  }
  closeEvent(event) {
    delete this.currentTraveler;
    $('#mainaddTravelerModal').modal('toggle');
  }
  closeListEvent(event) {
    delete this.currentTraveler;
    $('#mainlistTravelerModal').modal('toggle');
  }
  closeEditEvent(event) {
    this.editingTraveler = false;
    delete this.currentTraveler;
    $('#mainEditTravelerModal').modal('toggle');
  }

  setUserAs() {
    this.loggedUser = JSON.parse(localStorage.getItem('fl_profile'));
    console.log('this.loggedUser => ', this.loggedUser);
    if (this.loggedUser) {
      this.getUserImage();
      if (typeof this.loggedUser.company !== 'string' && this.loggedUser.company.type !== 'A' && !this.appcomponent.isSbt) {
        this.appcomponent.onSetTheme('b2b-theme');
      }
      this.getUserSettings();
      this.getCpSettings();
    }
    if (localStorage.getItem('prev_fl_profile')) {
      this.prevUser = JSON.parse(localStorage.getItem('prev_fl_profile'));
    }
    if (this.loggedUser.company.type !== 'A' && !this.appcomponent.isSbt) {
      this.appcomponent.setB2BSearch(true);
    } else {
      this.appcomponent.setB2BSearch(false);
    }
    this.loadNotifications = false;
    setTimeout(() => {
      this.loadNotifications = true;
    }, 200 );

  }

  backToProfile() {
    delete this.prevUser;
    localStorage.setItem('fl_profile', localStorage.getItem('prev_fl_profile'));
    localStorage.setItem('fl_access_token', localStorage.getItem('prev_fl_access_token'));
    localStorage.removeItem('prev_fl_profile');
    localStorage.removeItem('prev_fl_access_token');
    localStorage.removeItem('fl_user_cp');
    this.setUserAs();
  }

  getUserSettings() {
    this.userservice.getUserSettings(this.loggedUser._id).subscribe((res) => {
      setTimeout(() => {
        this.settingsLoaded = true;
      }, 1000 );
      this.userSettings = res;
      localStorage.setItem('fl_settings', JSON.stringify(this.userSettings));
      console.log('user settings => ', this.userSettings);
      if (this.userSettings.menuTop) {
        this.opened = false;
      }
    }, error => {
      if (error.error === 'Token is Expired') {
        this.authService.logout();
      }
      console.error('Error => ', error);
    });
  }

  isDashBoard(): boolean {
    if(this.router.url.includes('/dashboard') || this.router.url.includes('/quotation')){
      return false
    } else {
    return true
    }
  }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loggedUser = JSON.parse(localStorage.getItem('fl_profile'));
      this.getUser()
      this.getUserImage();
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(this.authenticationService.isLoggedIn()){
          if(this.loggedUser && this.loggedUser.changedPassword){
            if(this.tooOldPassword()){
              this.router.navigate(['users/list/' + this.loggedUser._id + '/change-password']);
            }
          }
        }
      }
    });
    this.socketService.connectSocket();
    // console.log('window.location.hostname', window.location.hostname);

    this.settingsLoaded = false;
    // this.opened = true;
    if (this.loggedUser.company.type !== 'A') {
      // this.appcomponent.isB2B = true;
      // localStorage.setItem('isB2B', 'true' );
      // this.appcomponent.onSetTheme('b2b-theme');
    }
    /*if (this.loggedUser.company.type !== 'C') {
      this.appcomponent.isB2B = false;
      localStorage.setItem('isB2B', 'false' );
      this.appcomponent.onSetTheme('main-theme');
    }*/
    if (localStorage.getItem('fl_requestedUrl')) {
      localStorage.removeItem('fl_requestedUrl');
    }

    if (this.loggedUser._id === '604f240ec5ec740012a28f46') {
      this.appcomponent.useLanguage('en');
    }
    this.getCpSettings();
  }

  getUserImage() {
    this.loggedUser.image = this.httpservice.setApiUrlImage() + 'static/user/' + this.loggedUser._id + '.png?time=' + new Date().getTime();
    // tslint:disable-next-line:max-line-length
    this.loggedUser.CpImage = this.httpservice.setApiUrlImage() + 'static/company/' + this.loggedUser.company._id + '.png?time=' + new Date().getTime();
  }

  toggleSettings() {
    $('.settingsBar').toggleClass('open');
    $('.settingsBackDrop').toggleClass('open');
  }

  toggleNotifications() {
    $('.autoNotificationBar').toggleClass('open');
    $('.BackdropAuto').toggleClass('open');
  }

  toggleManualNotifications() {
    $('.manualNotificationBar').toggleClass('open');
    $('.BackdropManual').toggleClass('open');
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
    this.socketService.disconnectSocket();
  }

  toggleSidenav() {
    this.opened = ! this.opened;
  }

  toggleTopmenu() {
    $('.menuTop').toggleClass('visibleMenuTop');
    $('.menuTopBackDrop').toggleClass('visibleMenuTop');
  }

  setDossier(id, name, items) {
    this.dossierId = id;
    this.dossierName = name;
    this.dossierItems = items;
    localStorage.setItem('fl_dossierId', JSON.stringify(this.dossierId));
    localStorage.setItem('fl_dossierName', JSON.stringify(this.dossierName));
    localStorage.setItem('dossierItems', JSON.stringify(this.dossierItems));
    console.log('this.dossierItems => ', this.dossierItems);
    if (localStorage.getItem('sarceDossier')) {
      this.sarce = JSON.parse(localStorage.getItem('sarceDossier'));
    } else {
      delete this.sarce;
    }
  }

  returnDate(value) {
    return new Date(value);
  }

  destroyDossier() {
    delete this.dossierId;
    delete this.dossierName;
    localStorage.removeItem('fl_dossierId');
    localStorage.removeItem('fl_dossierName');
    localStorage.removeItem('dossierIsActive');
    localStorage.removeItem('sarceDossier');
    localStorage.removeItem('dossierPassengers');
    localStorage.removeItem('dossierItems');
  }

  getDossier() {
    if ( localStorage.getItem('fl_dossierId') ) {
      this.dossierId = JSON.parse(localStorage.getItem('fl_dossierId'));
      this.dossierName = JSON.parse(localStorage.getItem('fl_dossierName'));
      this.dossierItems = JSON.parse(localStorage.getItem('dossierItems'));
    }
    if (localStorage.getItem('sarceDossier')) {
      this.sarce = JSON.parse(localStorage.getItem('sarceDossier'));
    } else {
      delete this.sarce;
    }
  }

  goToDossier() {
    this.router.navigate(['/dossier/list/' + this.dossierId]);
    this.openDsDetails();
  }

  openDsDetails() {
    $('.activeDossier').toggleClass('toLeft');
    $('.dossierDetails').toggleClass('toRight');
    $('.errorIc').fadeToggle(50);
    $('.closeIc').fadeToggle(50);
  }

  addDossierToggle() {
    $('#addDosier').modal('toggle');
  }

  createDossier() {
    const body = {
      flights: [],
      hotels: [],
      trains: [],
      cars: [],
      travellers: this.selectedTravelers,
    };
    this.dossierSerivice.createDossier(body).subscribe((res) => {
      console.log('res => ', res);
      if(this.loggedUser.company.services.policy){
        this.getAtisUser(res);
      } else {
        this.nameDossier(res._id);
      }
    }, error => {
      console.error('res => ', error);
    });
  }

  getAtisUser(res){
    this.policyService.getUser(this.loggedUser._id).subscribe((resp) => {
      console.log('res => ', resp);
      if(resp.data.user.group){
        this.createAtisTransfer(res);
      } else {
        this.nameDossier(res._id);
      }
    }, error => {
      console.error('res => ', error);
    });
  }

  createAtisTransfer(res){
    this.policyService.createTransfer(res, this.newDossier).subscribe((resp) => {
      this.nameDossier(res._id);
      console.log('res => ', res);
    }, error => {
      console.error('res => ', error);
    });
  }

  nameDossier(id) {
    const body = {
      name: this.newDossier,
    };
    this.dossierSerivice.nameDossier(body, id).subscribe((res) => {
      console.log('res => ', res);
      this.router.navigate(['/dossier/list/' + id]);
      // this.navigateToDossier(id);
      this.addDossierToggle();
    }, error => {
      console.error('res => ', error);
    });
  }

  backToSarce() {
    const trId = this.sarce.userid;
    const date = new Date();
    // tslint:disable-next-line:max-line-length
    const currentDate = date.getFullYear().toString() + this.pad2(date.getMonth() + 1) + this.pad2( date.getDate()) + this.pad2( date.getHours() ) + this.pad2( date.getMinutes() ) + this.pad2( date.getSeconds() );
    const travelcod = btoa(this.sarce.travelcode);
    const dataToEncrypt = travelcod + '_' + trId + '_' + currentDate;
    const key = 'F72BFFA19CE92E99';
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    const link = 'https://sarcetravel.eataly.it/TNSEAT/LoginS?bizToken=';
    const notBased = encrypted.toString();
    const notEncoded =  btoa(notBased);
    console.log(' encrypted NON base64 => ', notBased);
    console.log(' encrypted base64 => ', notEncoded);
    console.log(' encrypted encodeURI ', encodeURIComponent(notBased));
    console.log('back sarce => ', this.sarce);
    window.location.href = (link + encodeURIComponent(notBased));
  }

  pad2(n) { return n < 10 ? '0' + n : n; }

  getCpSettings() {
    this.companyService.getCpSettings(this.loggedUser.company._id).subscribe((res) => {
      this.loggedUser.company.services = res;
      localStorage.setItem('fl_profile', JSON.stringify(this.loggedUser));
      // this.loading = false;
    }, (error) => {
      // this.loading = false;
      console.log('error => ', error);
    });
    this.getFeesMine();
  }

  getFeesMine(){
    this.feeService.getMine().subscribe((res) => {
      this.helper.currentFee = res;
    }, err => {
      console.log('error => ', err);
    });
  }

  backFunction(): void {
    this.location.back();
  }

  scrollTop() {
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }
}
