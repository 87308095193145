import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material/material.module';
import {LoginComponent} from './login/login.component';
import {JwtModule} from '@auth0/angular-jwt';
import { SetnewpasswordComponent } from './setnewpassword/setnewpassword.component';

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    LoginComponent,
    SetnewpasswordComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: ['localhost'],
        disallowedRoutes: ['localhost/auth/login']
      }
    }),
  ]
})
export class LoginModule { }
