import { Component, OnInit, Input } from '@angular/core';
import {Airports} from '../../../../../classes/airports'
import {FlightService} from '../../../../../../../services/flight/flight.service';
import {IatacompleteService} from '../../../../../../../services/iatacomplete/iatacomplete.service';
import { ChangesData } from './ChangesData';
import * as $ from 'jquery';

@Component({
  selector: 'app-airport-pnr',
  templateUrl: './airport-pnr.component.html',
  styleUrls: ['./airport-pnr.component.scss'],
  providers:[AirportPnrComponent]
})
export class AirportPnrComponent implements OnInit{
  @Input() index: number;
  @Input() parentForm: any;
  @Input() isTransit: boolean;
  @Input() changesData : ChangesData;
  @Input() changesLowCost: any;
  fromtext: string;
  totext: string;
  airports: Airports[];
  loading: boolean;
  inputDeparture:boolean;
  inputArrival:boolean;
  clearButton:boolean;
  sections:any;
  constructor(
    private flightService: FlightService,
    private iataComplete: IatacompleteService,
  ) {
    this.loading = false;
    this.fromtext = '';
    this.totext = '';
  }

  ngOnInit(): void {
    this.fromtext = this.parentForm[0].departure.location;
    this.totext = this.parentForm[this.parentForm.length-1].arrival.location;
    this.checkIfEnable()
  }

  updateData(changesLowCost, flows){
    if(!this.changesData){
      this.changesData = flows;
      this.changesLowCost = changesLowCost;
      this.inputDeparture = !this.changesData.checkOrigin(this.changesLowCost.result);
      this.inputArrival = !this.changesData.checkDestination(this.changesLowCost.result);
    } else {
      this.inputDeparture = !this.changesData.checkOrigin(this.changesLowCost.result);
      this.inputArrival = !this.changesData.checkDestination(this.changesLowCost.result);
    }
  }

  checkIfEnable(){
    console.log('selected values ' + JSON.stringify(this.changesLowCost));

    this.inputDeparture = this.index === 0 ? !this.changesData.checkOrigin(this.changesLowCost.result) : this.changesData.checkOrigin(this.changesLowCost.result);
    this.inputArrival = this.index === 0 ? !this.changesData.checkDestination(this.changesLowCost.result): this.changesData.checkDestination(this.changesLowCost.result);
     this.inputDeparture = !this.changesData.checkOrigin(this.changesLowCost.result);
     this.inputArrival = !this.changesData.checkDestination(this.changesLowCost.result)


    // this.inputDeparture = this.inputArrival = !this.changesData.checkTemporaryOriginAndDestination(this.changesLowCost.result);

  }
  searchAirport(value) {
    delete this.airports;
    if (value.length >= 2 ) {
      this.getAirportList(value);
    }
  }

  getAirportList(value) {
    delete this.airports;
    this.flightService.getAirportsList(value).subscribe((res:any) => {
      this.loading = false;

      this.airports = [];
      res.results.forEach(r=>{
        this.airports.push({name:r.name || r.cityName, iatacity : r.cityCode, iata : r.iataCode, isCity : true});
        r.airports.forEach(a=>{
          this.airports.push({name: a.name || a.detailedName.split(':')[1] ,iatacity : a.cityCode, iata : a.iataCode});
        })
      })
      console.log('this.airports => ', this.airports);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  clearFrom() {
    this.fromtext = '';
    delete this.airports;
    this.fromtext = '';
    this.totext = '';
  }

  clearTo() {
    delete this.airports;
    this.totext = '';
  }

  getFrom(value) {
    delete this.airports;
    const temp = {from:[], fromLegend:[]};
    const index = value.indexOf('-');
    const iata = value.substr(0, index);
    const indexCity = value.lastIndexOf('_');
    const iataCity = value.substr(indexCity + 1);
    if (!this.isTransit) {
      temp.from.push(iata);
      temp.fromLegend.push(iataCity);
      this.parentForm.newSelections.from = temp;
    }
    this.iataComplete.createToCompleteForm(iata);
    this.fromtext = iata;
    $('#departure').attr('value', iata);
  }

  getTo(value) {
    delete this.airports;
    const temp = {to:[], toLegend:[]};
    const index = value.indexOf('-');
    const iata = value.substr(0, index);
    const indexCity = value.lastIndexOf('_');
    const iataCity = value.substr(indexCity + 1);
    temp.to.push(iata);
    temp.toLegend.push(iataCity);
    this.parentForm.newSelections.to = temp;
    this.iataComplete.createToCompleteForm(iata);
    this.totext = iata;
    $('#arrival').attr('value',iata)
  }

  selectFirst(value) {
    if (value === 'fromtext' && this.airports) {
      this.fromtext = this.airports[0].iata + '-' + this.airports[0].name;
      if (!this.isTransit) {
        this.parentForm.sections[this.index].from.push(this.airports[0].iata);
        this.parentForm.sections[this.index].fromLegend.push(this.airports[0].iatacity);
      } else {
        this.parentForm.sections[this.index].options.viaAirport.push(this.airports[0].iata);
      }
      this.iataComplete.createToCompleteForm(this.airports[0].iata);
    } else if (value === 'totext' && this.airports) {
      this.parentForm.sections[this.index].to.push(this.airports[0].iata);
      this.parentForm.sections[this.index].toLegend.push(this.airports[0].iatacity);
      this.iataComplete.createToCompleteForm(this.airports[0].iata);
    }
    delete this.airports;
  }

}
