<mat-card appearance="outlined">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>{{'REORDER_ITINERARIES'|translate}}</mat-card-title>
    <mat-card-subtitle>Set number of itineraries </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="" *ngIf="currentStep == 1">
      <mat-form-field class="">
        <mat-label>Numero di tratte</mat-label>
        <input matInput type="number" [(ngModel)]="numberOfItineraries" min="0" (ngModelChange)="setSteps($event)">
      </mat-form-field>
      <hr/>
      <div class="buttonrow text-right">
        <button mat-button (click)="next()" [disabled]="numberOfItineraries === 0 || numberOfItineraries === null">Avanti</button>
      </div>
    </div>

    <div cdkDropListGroup class="row" *ngIf="currentStep > 1">
      <div class="col-md-4">
        <p>{{'FLIGHTS'|translate}}</p>
        <div
          cdkDropList
          [cdkDropListData]="currentFlights"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of currentFlights; let i = index" cdkDrag>
            <div class="d-block">{{i+1}} {{item.departure.location}} -> {{item.arrival.location}}</div>
            <div class="d-block smalltext">{{item.departure.date|date :'dd/MM/yyyy'}} - {{item.departure.hours}}:{{item.departure.minutes}}</div>
          </div>
        </div>
      </div>

      <div class="col-md-4" *ngFor="let iti of itineraries; let i = index;">
        <p>{{'TRATTA'|translate}} {{i+1}}</p>
        <div
          cdkDropList
          [cdkDropListData]="iti"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of iti; let i = index" cdkDrag>
            <div class="d-block">{{i+1}} {{item.departure.location}} -> {{item.arrival.location}}</div>
            <div class="d-block smalltext">{{item.departure.date|date :'dd/MM/yyyy'}} - {{item.departure.hours}}:{{item.departure.minutes}}</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="currentStep > 1">
      <hr/>
      <div class="buttonrow">
        <div class="row">
          <div class="col-6">
            <button mat-button (click)="prev()">Indietro</button>
          </div>
          <div class="col-6 text-right">
            <button [disabled]="currentFlights.length > 0" mat-button (click)="sendItinerary()">Save</button>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-2 border-danger">
      <div class="card-body">
        <div class="text-danger">
          <p><b>Attenzione</b>: è necessario <span style="font-size:16px"><u>riordinare i segmenti che compongono la prenotazione in modo corretto</u></span>.</p>
          <p>Se questa operazione viene eseguita nella maniera errata il sistema potrebbe riscontrare errori e cancellare la prenotazione.</p>
          <p>In caso di dubbi chiedere assistena all'ufficio IT.</p>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

