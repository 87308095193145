import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-role-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss']
})
export class GroupFormComponent implements OnInit {

  @Input() loading = false;
  @Input() group?;

  @Output() submitted = new EventEmitter<any>();

  form = this.fb.group({
    name: ['', Validators.required]
  });

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    if (this.group) {
      this.form.patchValue(this.group);
    }
  }

  handleSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.submitted.emit(this.form.value);
  }
}
