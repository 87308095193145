import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  MatStepper,
  MatStepperModule,
} from '@angular/material/stepper';
import {FlightService} from '../../../../../../services/flight/flight.service';
import * as moment from 'moment';
import { ChangesData } from '../changeDatesAndFlights/airport-pnr/ChangesData';
import { AirportPnrComponent } from '../changeDatesAndFlights/airport-pnr/airport-pnr.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-change-list',
  templateUrl: './change-list.component.html',
  styleUrls: ['./change-list.component.scss']
})
export class ChangeListComponent implements OnInit {
  @Input() id: string;
  @Input() reservation: any;
  @Input() changesLowCost:any;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @ViewChild(AirportPnrComponent)

  toCancel: boolean ;
  ancillaryChange : boolean;
  travelChange: boolean;
  checking:boolean;
  moment:any = moment;
  changesRequired: any;
  loading = false;

  firstFormGroup = this._formBuilder.group({
    firstCtrl: [''],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: [''],
  });

  constructor(private _formBuilder: UntypedFormBuilder, private flightService: FlightService, private airportComponent:AirportPnrComponent) {
    this.checking = false;
  }

  ngOnInit(): void {
    for(let i=0; i< this.changesLowCost.result.length; i++){
      if(this.changesLowCost.result[i].selected === undefined)
        this.changesLowCost.result[i] = {label: this.changesLowCost.result[i], selected:false}
      else
        this.changesLowCost.result[i].selected = false;
    }
  }


  onChange(event, index){
    this.changesLowCost.result[index].selected = event.checked;
  }

  goToForm(){
    this.loading = true;
    this.checking = false;
    this.toCancel = this.wouldCancel();
    this.ancillaryChange = this.wouldChangeAncillary();
    this.travelChange = this.woulChangeTravel();
    this.changesRequired = new ChangesData(this.ancillaryChange, this.travelChange, this.toCancel);

    console.log('che flusso vuoi? ' + this + '--->'+  this.toCancel, this.ancillaryChange, this.travelChange);
    if(this.travelChange && !this.toCancel && !this.ancillaryChange){
      console.log('changesLowcost -> ' + JSON.stringify(this.changesLowCost));
      console.log('************************changes required**************************'+ this.changesRequired);

    }
    this.setFalseLoading();
    if(this.toCancel || this.ancillaryChange || this.travelChange)
      this.stepper.next();
  }

  goToFirst(){
    this.loading = true;
    this.stepper.previous();
    this.airportComponent.inputDeparture = null;
    this.airportComponent.inputArrival = null;
    this.setFalseLoading();
  }

  setFalseLoading(){
    setTimeout( ()=> {
      this.loading = false;
    }, 100);
  }

  wouldCancel(){
    for(const c of this.changesLowCost.result){
      if(c.selected){
        if(c.label === 'CANCEL'){
          return true;
        }
      }
    }
    return false;
  }

  wouldChangeAncillary(){
    for(const c of this.changesLowCost.result){
      if(c.selected){
        if(c.label === 'LUGGAGE' || c.label === 'INSURANCE' || c.label === 'SEATS' || c.label === 'DETAILS' || c.label === 'TRAVEL_CLASS'){
          return true
        }
      }
    }
    return false;
  }


  woulChangeTravel(){
    for(const c of this.changesLowCost.result){
      if(c.selected){

        if(c.label === 'OUT_DATE' || c.label === 'RET_DATE' || c.label === 'ORIGIN' || c.label === 'DESTINATION'){
          return true
        }

/*
        if(c.label === 'DATES' || c.label === 'LOCATIONS'){
          return true
        }
*/
      }
    }
    return false;
  }

  cancellationRequest(){
    // manda i dati al servizio per tentare la cancellazione. Apri lo spinner quando invi e chiudilo quando torna qualcosa
    this.checking = true;
    console.log('invia richiesta di cancellazione al servizio!!');
    this.flightService.cancelTFRequest(this.id).subscribe((data) =>{
      this.changesLowCost = data;
      this.checking = false;
      this.stepper.next();
    })
  }




  startBookingChanges(){
    console.log(JSON.stringify(this.changesLowCost))
    // this.flightService.startChangeBooking(this.id, this.changesLowCost).subscribe((data) => {
    //   let response = data;
    //   console.log(response);
    // })
  }

}


