<custom-title></custom-title>
<div class="padding20">

  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>{{'SEARCH'|translate}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ABC" #input>
      </mat-form-field>
    </div>
    <div class="col-6 text-right">
      <button mat-icon-button (click)="editInc(null)">
        <span class="material-icons">
         add
        </span>
      </button>
    </div>

  </div>

  <div class="smallTable">
    <table *ngIf="incentiveslist" mat-table class="w-100" [dataSource]="dataSource">

      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let row; let i = index;">
          <span style="cursor: pointer">{{i+1}} </span>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{'INCENTIVE'|translate}} </th>
        <td mat-cell *matCellDef="let row" (click)="editInc(row)">
          <span style="cursor: pointer">{{row.name}} </span>
        </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let row">
          <div class="buttonrow">
            <button mat-button (click)="editInc(row)">{{'EDIT'|translate}}</button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="6">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

  <div class="modal fade" id="editIncModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{'INCENTIVE'|translate}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field class="full-width mb-2">
                <mat-label>{{'TAG'|translate}}</mat-label>
                <input [(ngModel)]="editing.name" matInput placeholder="Placeholder">
              </mat-form-field>

            </div>

          </div>
          <hr/>
          <div class="buttonrow text-right">
            <button color="warn" mat-button (click)="editInc(null)">Annulla</button>
            <button [disabled]="!editing.name" color="primary" mat-button *ngIf="editing._id" (click)="editIncCall()">{{'SAVE'|translate}}</button>
            <button [disabled]="!editing.name" color="primary" mat-button *ngIf="!editing._id" (click)="addIncCall()">{{'ADD'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
