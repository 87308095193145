import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TrainReservation } from '../../../classes/train.models';
import { TrainUtils } from '../../train.utils';

/**
 *  Component to render booking summary matching Reservation Model from DB.
 */
@Component({
  selector: 'train-checkout-booking-summary',
  templateUrl: './checkout-booking-summary.component.html',
  styleUrls: ['./checkout-booking-summary.component.scss'],
})
export class TrainCheckoutBookingSummaryComponent implements OnInit{
  /**
   *  Page title passed by parent component
   */
  @Input() bookingDb : TrainReservation;
  @Input() title : string;

  /**
   *  Constructor
   */
  constructor(){ }

  /**
   *  OnInit function is empty for now
   */
  ngOnInit(){
  }


  getTreniLogo(){
    return TrainUtils.getTrenitLogo(this.bookingDb, undefined);
  }
}
