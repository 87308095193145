import {Pipe, PipeTransform} from '@angular/core';
import {isObservable, Observable, of} from 'rxjs';
import {catchError, map, startWith} from 'rxjs/operators';

@Pipe({
  name: 'obsWithStatus'
})
export class ObsWithStatusPipe implements PipeTransform {

  transform(val: any): Observable<any> {
    return isObservable(val) ? val.pipe(
      map(value => ({loading: false, value})),
      startWith({loading: true}),
      catchError(error => of({loading: false, error}))
    ) : val;
  }

}
