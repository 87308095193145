import { Tariffes } from './tariffes';
import {NewTraveller} from '../travelers/newTraveller';

interface Detail {
  electronicTicketing: boolean;
  equipment: string;
  productDetailQualifier: string;
}

interface Departure {
  location: string;
  terminal: string;
  dateTime: Date;
  date: Date;
  hours: number;
  minutes: number;
}

interface Arrival {
  location: string;
  terminal: string;
  dateTime: Date;
  date: Date;
  hours: number;
  minutes: number;
}

interface Flight {
  detail: Detail;
  operatingCarrier: string;
  marketingCarrier: string;
  departure: Departure;
  arrival: Arrival;
  number: string;
  waitingTime: number;
  mustChangeAirport: boolean;
  flyingTime: number;
}

interface Section {
  ref: number;
  flyingTime: number;
  flights: Flight[];
}

interface Itinerary {
  flyingTime: number;
  sections: Section[];
}

interface Section2 {
  ref: number;
  with: any[];
  hidden: boolean;
  hiddenT: boolean;
}

interface Itinerary2 {
  sections: Section2[];
}

interface Fare {
  amount: number;
  tax: number;
  publicAmount: number;
  negotiated?: boolean | undefined;
  fees?: number;
  currency?: string;
}

interface Adt {
  amount: number;
  tax: number;
  refs: string[];
}

interface PaxFare {
  adt: Adt;
}

interface BookingClass {
  adt: string[];
  inf: any[];
  chd: any[];
}

interface Recommendation {
  originalPrice: number;
  itineraries: Itinerary2[];
  marketingCarrier: string;
  fare: Fare;
  paxFare: PaxFare;
  ref: number;
  bookingClass: BookingClass[];
  cabins: any[];
  fareTypes: string[];
  upsell: boolean;
  corporate: boolean;
  negotiated: boolean;
  lowCost: boolean;
  hybrid: boolean;
  excludeOptions: boolean;
  rates: Tariffes[];
  publicAmount: number;
  showFares: boolean;
  tariff: any;
  passengers: NewTraveller[];
  arrayOfAlerts: string[];
}

export class SearchRes {
  itineraries: Itinerary[];
  recommendations: Recommendation[];
  routingId?: string;
  infoRates?: any;
  _id: string;
}

