/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TransactionRequestDto = {
    company: string;
    user: string;
    service: TransactionRequestDto.service;
    type: string;
    locator: string;
    refId: string;
    amount: number;
    currency?: string;
    description?: string;
    error?: boolean;
    done?: boolean;
    cash?: boolean;
    creditCard?: string;
    expDate?: string;
    cvc?: string;
    headers?: string;
};

export namespace TransactionRequestDto {

    export enum service {
        NDC = 'ndc',
        FLIGHT = 'flight',
        HOTEL = 'hotel',
        LOWCOST = 'lowcost',
        CAR = 'car',
        NTV = 'ntv',
        TRENITALIA = 'trenitalia',
    }


}

