<div class="row">
  <div class="col-md-6">
    <ul>
      <li>
        {{'NO_LOWCOST_FOR_CALENDAR'|translate}}
      </li>
      <li>
        {{'NO_REAL_TIME_QUOTATION'|translate}}
      </li>
    </ul>

  </div>
  <div class="col-md-6">
    <div class="buttonrow text-right mb-3">
      <button class="mainbg" mat-stroked-button (click)="toggleForm()">
        <span *ngIf="!formvisible">{{'EDIT_SEARCH'|translate}}</span>
        <span *ngIf="formvisible">{{'HIDE_FORM'|translate}}</span>
      </button>
    </div>
  </div>
</div>

<div class="card mb-2">
  <div *ngIf="table">
    <div class="table-responsive">
      <table class="table calendarTable" style="min-width:600px;margin-bottom:0">
        <thead>
        <tr>
          <th *ngIf="table[1]">
            <div>-</div>
          </th>
          <th *ngFor="let date of table[0];let i = index">
            <div class="truncate">{{date | date:"EEE dd MMM yyyy"}}</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="table[1]">
          <tr class="" *ngFor="let row of table[1] | slice:0:7 ;let r = index">
            <td *ngIf="table[1]" class="dateCell">
              <div>{{row| date:"EEE dd MMM yyyy"}}</div>
            </td>
            <td *ngFor="let cell of finalTable[r];let c = index">
              <div matRipple *ngIf="cell" [ngClass]="getValuePrice(cell.fare.amount)" (click)="openDialog(cell)">
                <img [matTooltip]="iataComplete.returnCarrier(cell.marketingCarrier)" class="recoCarrier" src="assets/img/airline/icon/{{cell.marketingCarrier}}.png"/>
                <span *ngIf="!cell.negotiated">
                  {{priceservice.returnPrice(cell.fare.amount)|number:'.2-2'}}€
                </span>
                <span *ngIf="cell.negotiated && !priceservice.isNegoHidden()  && !mainComponent.appcomponent.isB2B">
                  <mat-progress-bar style="display: inline-block;max-width: 30px;" *ngIf="!cell.fare.publicAmount" mode="buffer"></mat-progress-bar>
                  <span *ngIf="cell.fare.publicAmount">
                    {{priceservice.returnPrice(cell.fare.amount + cell.fare.fees)|number:'.2-2'}}€
                  </span>
                </span>
                <span *ngIf="cell.negotiated && priceservice.isNegoHidden() && !mainComponent.appcomponent.isB2B">
                  <mat-progress-bar style="display: inline-block;max-width: 30px;" *ngIf="!cell.fare.publicAmount" mode="buffer"></mat-progress-bar>
                  <span *ngIf="cell.fare.publicAmount">
                    {{priceservice.returnPrice(cell.fare.publicAmount)|number:'.2-2'}}€
                  </span>
                </span>

                <span *ngIf="cell.negotiated && mainComponent.appcomponent.isB2B">
                  <mat-progress-bar style="display: inline-block;max-width: 30px;" *ngIf="!cell.fare.publicAmount" mode="buffer"></mat-progress-bar>
                  <span *ngIf="cell.fare.publicAmount">
                    {{priceservice.returnPrice(cell.fare.publicAmount - cell.fare.fees)|number:'.2-2'}}€
                  </span>
                </span>
                <span class="material-icons yellow-text" matTooltip="{{'NEGOTIATED_FARE'|translate}}" *ngIf="cell.negotiated && !mainComponent.appcomponent.isB2B">
                  star_outline
                </span>
                <span class="material-icons yellow-text" matTooltip="{{'DISCOUNT_FARE'|translate}}" *ngIf="cell.negotiated && mainComponent.appcomponent.isB2B">
                  star_outline
                </span>
              </div>
              <div *ngIf="!cell">x</div>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="!table[1]">
          <tr class="">
            <td *ngFor="let cell of finalTable[0];let c = index">
              <div matRipple *ngIf="cell" [ngClass]="getValuePrice(cell.fare.amount)" (click)="openDialog(cell)">
                <img [matTooltip]="iataComplete.returnCarrier(cell.marketingCarrier)" class="recoCarrier" src="assets/img/airline/icon/{{cell.marketingCarrier}}.png"/>
                <span *ngIf="!cell.negotiated">
                  {{priceservice.returnPrice(cell.fare.amount)|number:'.2-2'}}€
                </span>
                <span *ngIf="cell.negotiated && !priceservice.isNegoHidden()  && !mainComponent.appcomponent.isB2B">
                  <mat-progress-bar style="display: inline-block;max-width: 30px;" *ngIf="!cell.fare.publicAmount" mode="buffer"></mat-progress-bar>
                  <span *ngIf="cell.fare.publicAmount">
                    {{priceservice.returnPrice(cell.fare.amount + cell.fare.fees)|number:'.2-2'}}€
                  </span>
                </span>
                <span *ngIf="cell.negotiated && priceservice.isNegoHidden() && !mainComponent.appcomponent.isB2B">
                  <mat-progress-bar style="display: inline-block;max-width: 30px;" *ngIf="!cell.fare.publicAmount" mode="buffer"></mat-progress-bar>
                  <span *ngIf="cell.fare.publicAmount">
                    {{priceservice.returnPrice(cell.fare.publicAmount)|number:'.2-2'}}€
                  </span>
                </span>

                <span *ngIf="cell.negotiated && mainComponent.appcomponent.isB2B">
                  <mat-progress-bar style="display: inline-block;max-width: 30px;" *ngIf="!cell.fare.publicAmount" mode="buffer"></mat-progress-bar>
                  <span *ngIf="cell.fare.publicAmount">
                    {{priceservice.returnPrice(cell.fare.publicAmount - cell.fare.fees)|number:'.2-2'}}€
                  </span>
                </span>
                <span class="material-icons yellow-text" matTooltip="{{'NEGOTIATED_FARE'|translate}}" *ngIf="cell.negotiated && !mainComponent.appcomponent.isB2B">
                  star_outline
                </span>
                <span class="material-icons yellow-text" matTooltip="{{'DISCOUNT_FARE'|translate}}" *ngIf="cell.negotiated && mainComponent.appcomponent.isB2B">
                  star_outline
                </span>
              </div>
              <div *ngIf="!cell">x</div>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>

  </div>
</div>

<div *ngIf="table" class="colorLegend">
  <p>{{'LEGEND'|translate}}</p>
  <span class="marginright10">
    <span class="grprice"></span> {{'LOW_PRICE'|translate}}
  </span>

  <span class="marginright10">
    <span class="mdprice"></span> {{'MEDIUM_PRICE'|translate}}
  </span>

  <span class="marginright10">
    <span class="hiprice"></span> {{'HIGH_PRICE'|translate}}
  </span>

  <span class="marginright10">
    <span class="higprice"></span> {{'HIGHER_PRICE'|translate}}
  </span>
</div>
