import {Component, OnInit} from '@angular/core';
import {GroupsService} from '../../groups.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {throwError} from 'rxjs';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent implements OnInit {

  loadingRequest = false;
  group: any;
  /*
  group$ = this.groupsService.getOne(this.route.snapshot.params.groupId).pipe(catchError(error => {
    this.snackBar.open(error.message, 'Close');
    this.router.navigateByUrl('groups');
    return throwError(error);
  }));
   */

  constructor(
    private groupsService: GroupsService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.getDataGroup();
  }

  getDataGroup() {
    this.groupsService.getOne(this.route.snapshot.params.groupId).subscribe(response => {
      this.group = response;
    }, error => {
      console.error('Error => ', error);
      return throwError(error);

    });
  }

  handleSubmit(payload): void {
    this.loadingRequest = true;
    this.groupsService.update(payload, this.route.snapshot.params.groupId).subscribe(result => {
        this.loadingRequest = false;
        this.snackBar.open('Group edited successfully', 'Close');
        this.router.navigate(['/allgroups']);
      },
      error => {
        this.router.navigate(['/allgroups']);
        this.snackBar.open(error.message, 'Close');
        this.loadingRequest = false;
      }
    );
  }

}
