import {Component, Input, OnInit} from '@angular/core';
import {TravelersService} from '../../../../services/travelers/travelers.service';

@Component({
  selector: 'app-frequentflyer',
  templateUrl: './frequentflyer.component.html',
  styleUrls: ['./frequentflyer.component.scss']
})
export class FrequentflyerComponent implements OnInit {
  @Input() page: string;
  @Input() id: string;
  frequentFlyers: any[] = [];
  loading: boolean;
  adding: boolean;
  newFrequentFlyer: any;
  toEdit: any;
  constructor(
    private travelersService: TravelersService,
  ) { }

  ngOnInit(): void {
    this.newFrequentFlyer = {
      number: '',
      iata: '',
    };
    this.getFrequentFlyers();
  }

  getFrequentFlyers(): void {
    this.loading = true;
    this.travelersService.getFrequentFlyers(this.id).subscribe((res) => {
      this.loading = false;
      this.frequentFlyers = res;
      this.frequentFlyers.forEach(fr => {
        fr.deleting = false;
        fr.editing = false;
      });
      // this.filterStates(this.traveler.document.nationality);
      console.log('this.frequentFlyers => ', this.frequentFlyers);
    }, error => {
      this.loading = false;
    });
  }

  saveFrequentFlyer(): void {
    this.loading = true;
    const body = {
      number: this.newFrequentFlyer.number.toUpperCase(),
      iata: this.newFrequentFlyer.iata.toUpperCase(),
    };
    this.travelersService.saveFrequentFlyers(this.id, body).subscribe((res) => {
      this.loading = false;
      this.frequentFlyers = res;
      this.adding = false;
      this.getFrequentFlyers();
    }, error => {
      this.loading = false;
    });
  }

  editFrequentFlyer(i): void {
    this.loading = true;
    this.frequentFlyers[i].number = this.frequentFlyers[i].number.toUpperCase();
    const body = this.frequentFlyers[i];
    console.log(body);
    this.travelersService.editFrequentFlyers(this.id, body).subscribe((res) => {
      this.loading = false;
      this.frequentFlyers = res;
      this.getFrequentFlyers();
    }, error => {
      this.loading = false;
    });
  }

  deleteFrequentFlyers(fr): void {
    this.loading = true;
    this.travelersService.deleteFrequentFlyers(this.id, fr).subscribe((res) => {
      this.loading = false;
      this.frequentFlyers = res;
      this.getFrequentFlyers();
    }, error => {
      this.loading = false;
    });
  }

}
