import {
    Component,
    OnInit,
} from '@angular/core';
import { TemplateDialogComponent } from '../template-dialog/template-dialog.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-template-dialog-atc',
    templateUrl: './template-dialog-atc.component.html',
    styleUrls: ['./template-dialog-atc.component.scss'],
})
export class TemplateDialogAtcComponent extends TemplateDialogComponent implements OnInit{
    private pnrId : string;
    airRebookResponse : any;
    loading = false;
    selectedTab = 1;
    showDetails = false;

    ngOnInit(){
        this.pnrId = this.data.pnrId;
        this.loadTravellers();
    }

    loadTravellers(){
        this.data.request.travellers.forEach((t, i) => {
            this.travelerSet(t, i);
        });
    }

    override setTravelers(){
        this.loadingLink = true;
        this.data.recommendation.passengers = this.data.passengers;
        const z = 1;
        for (let i = 0; i < this.data.recommendation.passengers.length; i ++){
            this.data.recommendation.passengers[i].ref = i + z + 1;
            if (this.data.recommendation.passengers[i].birthday == null || this.data.recommendation.passengers[i].birthday === ''){
                this.data.recommendation.passengers[i].birthday = undefined;
            }
        }
        console.log('this.data.recommendation => ', this.data.recommendation);
        if (this.isB2B){
            this.data.recommendation.b2b = true;
        }
        this.data.recommendation.fare = this.data.recommendation.tariff.fare;
        if (this.data.request.options.resident){
            this.data.recommendation.resident = true;
        }
        this.data.recommendation.reissue = true;
        this.flightService.airRebookATC(this.pnrId, this.data.recommendation).subscribe((res : any) => {
            this.loadingLink = false;
            console.log('res => ', res);
            this.airRebookResponse = res;
            this.selectedTab = 3;
        }, error => {
            this.loadingLink = false;
            this.notifier.notify('error', error.errorMessage);
            console.error('res => ', error);
        });
    }

    confirmReissue(){
        this.loading = true;
        this.flightService.confirmReissue(this.pnrId, this.data.recommendation).subscribe(res => {
            this.loading = false;
            this.snackBar.open(this.translate.instant('RESERVATION_PLACED'), null, {
                duration: 800,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
            this.dialog.closeAll();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/flight/reservations/' + this.pnrId]);
            });
        }, _ => this.loading = false);
    }

    asDataSource(data : any){
        return new MatTableDataSource(data);
    }

    reissueInfo(automaticReissueInfo : any){
        const createMoneyDetail = (d, q, a, c) => { return { detail: d, qualifier: q, amount: a, currency: c };};
        const moneyAndOtherMoney = (detail, fare) => {
            const data = [];
            if (fare.monetaryDetails){
                const fareMoneyDetails = Array.isArray(fare.monetaryDetails) ? fare.monetaryDetails : [fare.monetaryDetails];
                fareMoneyDetails.forEach(omd => {
                    data.push(createMoneyDetail(detail + ' | MonetaryDetails', omd.typeQualifier, omd.amount, omd.currency));
                });
            }
            if (fare.otherMonetaryDetails){
                const fareOtherMoneyDetails = Array.isArray(fare.otherMonetaryDetails) ? fare.otherMonetaryDetails : [fare.otherMonetaryDetails];
                fareOtherMoneyDetails.forEach(omd => {
                    data.push(createMoneyDetail(detail + ' | OtherMonetaryDetails', omd.typeQualifier, omd.amount, omd.currency));
                });
            }
            return data;
        };
        const moneyDetails = [];
        const baseFare = automaticReissueInfo.baseFareInfo.monetaryDetails;
        moneyDetails.push(createMoneyDetail('Base Fare', baseFare.typeQualifier, baseFare.amount, baseFare.currency));

        const penDisData = automaticReissueInfo.firstDpiGroup.reIssuePenalty.penDisData;
        moneyDetails.push(createMoneyDetail('1st DPI Group | ReIssue Penalty', 'Pen Dis Data', penDisData.penaltyAmount, penDisData.penaltyCurrency));
        moneyDetails.push(...moneyAndOtherMoney('1st DPI Group | ReIssue Balance', automaticReissueInfo.firstDpiGroup.reissueBalanceInfo));
        moneyDetails.push(...moneyAndOtherMoney('1st DPI Group | ReIssue Info', automaticReissueInfo.firstDpiGroup.reissueInfo));
        moneyDetails.push(...moneyAndOtherMoney('1st DPI Group | Old Tax Info', automaticReissueInfo.firstDpiGroup.oldTaxInfo));

        const penalty = automaticReissueInfo.secondDpiGroup.penalty.penDisData;
        moneyDetails.push(createMoneyDetail('2nd DPI Group | Penalty', 'Pen Dis Data', penalty.penaltyAmount, penalty.penaltyCurrency));
        moneyDetails.push(...moneyAndOtherMoney('2nd DPI Group | Issue Balance Info', automaticReissueInfo.secondDpiGroup.issueBalanceInfo));
        moneyDetails.push(...moneyAndOtherMoney('2nd DPI Group | Residual Value Info', automaticReissueInfo.secondDpiGroup.residualValueInfo));
        moneyDetails.push(...moneyAndOtherMoney('2nd DPI Group | Old Tax Info', automaticReissueInfo.secondDpiGroup.oldTaxInfo));

        return new MatTableDataSource(moneyDetails);
    }

    override selectFare(f){
        delete this.airRebookResponse;
        super.selectFare(f);
    }

    asArray(element) {
        return Array.isArray(element) ? element : [element];
    }

    allZero(currencyMap : any){
        return Object.values(currencyMap).every(v => v === 0);
    }
}
