<div *ngIf="searching" class="fixedLoader">
  <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
</div>
<div></div> {{callTime}} {{returnCount()}}
<div *ngFor="let h of hotelDb; let i = index;">
  <ng-container *ngIf="h.availableHotels">
    <div>Index:{{i}} | {{h.name}} | finded: {{h.availableHotels.length}}</div>
    <span class="material-icons">
      arrow_right_alt
    </span>
    <span *ngFor="let child of h.availableHotels; let l = last">
      {{child.hotelCode}} <span *ngIf="!l"> | </span>
    </span>
  </ng-container>

</div>
