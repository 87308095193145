import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as $ from 'jquery';
import { BehaviorSubject } from 'rxjs';
import {
  TrainAllowedOperations,
  TrenitTraveller,
} from '../../../../classes/train.models';
import { TrainService } from '../../../../train.service';
import { TrainUtils } from '../../../train.utils';

@Component({
  selector: 'train-change-traveller',
  templateUrl: './train-change-traveller.component.html',
  styleUrls: ['./train-change-traveller.component.scss'],
})
export class TrainChangeTravellerComponent implements OnInit{
  @Input() isDisabled : boolean;
  @Input() pnr : string;
  @Input() allowedOperations : TrainAllowedOperations;
  @Input() selectedTravelSolution = 0;
  @Output() reloadEvent = new EventEmitter<any>();

  changeReservation$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedTravellers : any = {};
  currentSelection : string;

  /**
   *  Constructor
   */
  constructor(
    private trainService : TrainService,
  ){ }

  /**
   *  OnInit function is empty for now
   */
  ngOnInit(){
  }

  toggleChangeTravellerModal(){
    $('#changeTravellerModal').modal('toggle');
  }

  changeTraveller(){
    const travellersMap = {};
    Object.keys(this.selectedTravellers).forEach(k => travellersMap[k] = this.selectedTravellers[k][0]);
    this.changeReservation$.next(true);
    this.trainService.changeTraveller(this.pnr, travellersMap).subscribe(data => {
      this.changeReservation$.next(false);
      this.selectedTravellers = {};
      this.reload();
    }, error => {
      this.changeReservation$.next(false);
    });
  }

  reload(){
    this.reloadEvent.emit(true);
  }

  getTravellers(){
    const offers = Array.isArray(this.getTravelSolution().offeredServices) ? this.getTravelSolution().offeredServices : [this.getTravelSolution().offeredServices];
    const offerTravellersIds = offers.filter(o => o.status === 'PURCHASED').map(o => o.bookingInfo?.traveller);
    const travellers = TrainUtils.toArray(this.allowedOperations.travel.travellers);
    return travellers.filter(t => !!offerTravellersIds.find(id => id === t.xmlId));
  }

  addTraveller(tra : any){
    console.log(tra);
    if (!this.selectedTravellers[tra.id]){
      const newTraveller = new TrenitTraveller('changed-traveller-id');
      newTraveller.type = this.translatePaxType(tra.type);
      this.selectedTravellers[tra.id] = [newTraveller];
    }
    this.currentSelection = tra;
  }

  isChangeReady(){
    return Object.values(this.selectedTravellers).every((t : any) => !!t.name);
  }

  private translatePaxType(trainPaxType : String, reverse : boolean = false){
    if (!reverse){
      return trainPaxType === 'ADULT' ? 'ADT' : 'CHD';
    } else{
      return trainPaxType === 'ADT' ? 'ADULT' : 'CHILDREN';
    }
  }

  private getTravelSolution(){
    const arrayOfTravelSolutions = Array.isArray(this.allowedOperations.travel.travelSolutions) ? this.allowedOperations.travel.travelSolutions : [this.allowedOperations.travel.travelSolutions];
    return arrayOfTravelSolutions[this.selectedTravelSolution];
  }
}
