import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as $ from 'jquery';
import { TrainInformation } from '../../../classes/train.models';
import { TrainService } from '../../../train.service';


@Component({
  selector: 'train-info-table',
  templateUrl: './train-info-table.component.html',
  styleUrls: ['./train-info-table.component.scss'],
})
export class TrainInfoTableComponent implements OnInit{
  @Input() travelSolution : any;

  fetchingInformation$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  data : TrainInformation[];
  displayedColumns = ['arrivalTime', 'departureTime', 'location'];

  /**
   *  Constructor
   */
  constructor(
    private trainService : TrainService,
  ){ }

  /**
   *  OnInit function is empty for now
   */
  ngOnInit(){
  }

  /**
   *  Toggle modal for cancellation confirm
   */
  toggleModal(){
    if (!this.data){
      this.retrieveInformation();
    }
    $('#informationModal').modal('toggle');
  }

  retrieveInformation(){
    this.fetchingInformation$.next(true);
    this.trainService.getTrainInformation(this.travelSolution).subscribe((data : any) => {
      this.fetchingInformation$.next(false);
      this.data = data;
    }, error => {
      this.fetchingInformation$.next(false);
      console.error('Coudlnt get train information => ', error);
    });
  }

}
