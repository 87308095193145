<div class="actionContainer">
  <div class="mb-2 font-weight-bold">
    Modifiche effettuate da biglietteria <sup>({{type}})</sup>
  </div>

  <div class="mb-2">
    <mat-form-field class="priceInput">
      <mat-label>Nuovo prezzo:</mat-label>
      <input matInput type="number" placeholder="Es: 1234" [(ngModel)]="fareManual.amount">
      <mat-hint>Il prezzo totale della prenotazione.</mat-hint>
    </mat-form-field>
  </div>

  <div class="mb-2">
    <mat-form-field class="full-width">
      <mat-label>Azioni effettuate sulla prenotazione</mat-label>
      <textarea [(ngModel)]="fareManual.description" matInput placeholder="Inserisci tutte le azioni effettuate sulla prenotazione"></textarea>
    </mat-form-field>
  </div>

  <div>
    <button mat-stroked-button color="primary" [disabled]="!fareManual.amount || !fareManual.description" (click)="save()">Salva</button>
  </div>
</div>
