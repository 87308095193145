import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SendComponent} from './notifications/send/send.component';
import {AutoComponent, Notificationdialog} from './notifications/auto/auto.component';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material/material.module';
import {NotificationsComponent} from './notifications/pages/notifications.component';
import {NotificationpagetitleComponent} from './notificationpagetitle/notificationpagetitle.component';
import {CompanyautocompComponent} from './companyautocomp/companyautocomp.component';
import { UsernotifierautocompleteComponent } from './usernotifierautocomplete/usernotifierautocomplete.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {MainnotificationsComponent} from './mainnotifications/mainnotifications.component';

@NgModule({
    declarations: [
        AutoComponent,
        SendComponent,
        NotificationsComponent,
        NotificationpagetitleComponent,
        CompanyautocompComponent,
        Notificationdialog,
        UsernotifierautocompleteComponent,
        MainnotificationsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        CKEditorModule,
    ],
    exports: [
        AutoComponent,
        MainnotificationsComponent
    ]
})
export class NotificationModule { }
