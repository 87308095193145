import {Component, Input, OnInit} from '@angular/core';
import {CostcenterService} from '../../../../services/costcenter/costcenter.service';
import {MatDialog} from '@angular/material/dialog';
import {AppComponent} from '../../../../app.component';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpserviceService} from '../../../../services/httpservice.service';
import {TranslateService} from '@ngx-translate/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-costcenter',
  templateUrl: './costcenter.component.html',
  styleUrls: ['./costcenter.component.scss']
})
export class CostcenterComponent implements OnInit {
  recipient: any;
  message: string;
  sending: boolean;
  @Input() page: string;
  @Input() idcompany: string;
  displayedColumns = [];
  loading: boolean;
  centerList: any;
  currentpage: number;
  maxpages: number;
  // setQuery
  query: any;
  limit: number;
  skip: number;
  sort: string;
  order: boolean;
  progress: boolean;
  text: string;
  selection = [];
  pagenumbers = [];
  costCenter: any;
  currentCenter: any;
  constructor(
    public costCenterServ: CostcenterService,
    public dialog: MatDialog,
    public appcomponent: AppComponent,
    public maincomponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpserviceService,
    private translate: TranslateService,
  ) {
    this.loading = true;
    this.progress = false;
    this.costCenter = {
      tag: '',
      description: '',
    };
  }

  ngOnInit(): void {
    this.displayedColumns = ['name', 'description', 'actions'];
    this.sort = 'tag';
    this.order = true;
    this.currentpage = 0;
    this.limit = 10;
    this.skip = (this.currentpage) * this.limit;

    this.query = {
      sort: this.sort,
      limit: this.limit,
      skip: this.skip,
      order: this.order ? 'asc' : 'desc',
      text: '',
    };

    if (!this.page) {
      this.route.queryParams.subscribe(params => {
        this.query.sort = params.sort || this.sort;
        this.query.limit = params.limit || this.limit;
        this.query.skip = params.skip || this.skip;
        this.query.order = params.order || 'asc';
        this.query.text = params.text || '';
        this.text = params.text || '';
        this.currentpage = this.query.skip / this.query.limit;
        this.getCenterList();
      });
    } else {
      this.navigate();
    }
  }

  getCenterList(): void {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.costCenterServ.getCostCenterList(this.query, this.maincomponent.loggedUser.company._id).subscribe((res: any) => {
      this.loading = false;

      this.centerList = res;
      setTimeout(() => {
        this.progress = false;
      }, 500);
      if (!this.page) {
        this.scrollTop();
      }
      console.log('this.centerList => ', this.centerList);
      this.maxpages = Math.round((this.centerList.count / this.limit));
      if (this.maxpages === 0) {
        this.maxpages = 1;
      }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  removeCostCenter() {
    this.costCenterServ.deleteCostCenter(this.maincomponent.loggedUser.company._id, this.currentCenter._id).subscribe((res: any) => {
      this.getCenterList();
      this.toggleRemoveCenter(undefined);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  addCostCenter() {
    this.costCenterServ.addCostCenter(this.maincomponent.loggedUser.company._id, this.costCenter).subscribe((res: any) => {
      this.toggleNewCenter();
      this.getCenterList();
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  editCostCenter() {
    this.costCenterServ.editCostCenter(this.maincomponent.loggedUser.company._id, this.currentCenter, this.currentCenter._id).subscribe((res: any) => {
      this.toggleEditCenter(undefined);
      this.getCenterList();
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  scrollTop() {
    $('.tablelist').animate({scrollTop: 0}, 400);
    $('#content_main').animate({scrollTop: 0}, 400);
  }

  next() {
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev() {
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value) {
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  searchName() {
    this.query.text = this.text;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
    // this.getUserList()
  }

  sortResults(value) {
    if (this.query.sort === value) {
      this.order = ! this.order;
      this.query.order = this.order ? 'asc' : 'desc';
    }
    this.query.sort = value;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
    // this.getUserList();
  }

  navigate() {
    this.selection = [];
    if (this.page) {
      this.getCenterList();
    } else {
      this.router.navigate([], {
        queryParams: this.query,
      });
    }
  }

  toggleNewCenter() {
    $('#addCostCenterModal').modal('toggle');
  }

  toggleRemoveCenter(center) {
    if (!center) {
      delete this.currentCenter;
    } else {
      this.currentCenter = center;
    }
    $('#removeCostCenterModal').modal('toggle');
  }

  toggleEditCenter(center) {
    if (!center) {
      delete this.currentCenter;
    } else {
      this.currentCenter = center;
    }
    $('#editCostCenterModal').modal('toggle');
  }

}
