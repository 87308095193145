import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-notificationpagetitle',
  templateUrl: './notificationpagetitle.component.html',
  styleUrls: ['./notificationpagetitle.component.scss']
})
export class NotificationpagetitleComponent implements OnInit {
  @Input() title: string;
  constructor() { }

  ngOnInit(): void {
  }

}
