<custom-title></custom-title>
<div [ngClass]="{'padding20':!page}">
  <div class="card marginbottom20">

    <div *ngIf="loading" class="loader">
      <div class="card text-center padding20">
        <div class="d-flex justify-content-center">
          <mat-spinner></mat-spinner>
        </div>
        <h5>{{'LOADING'|translate}}...</h5>
      </div>
    </div>

    <div *ngIf="!loading" class="row">

      <div class="col-lg-6 d-md-flex align-items-md-center pl-3 pt-3 pt-md-0">
        <button [matMenuTriggerFor]="tabMenu" mat-button class="listButton">
          <span *ngIf="query.options.tab=='active'">{{'ACTIVE_PNR'|translate}} ({{returnTabNum('active')}}) </span>
          <span *ngIf="query.options.tab=='linked'">{{'RESERVATIONS_PNR'|translate}} ({{returnTabNum('linked')}}) </span>
          <span *ngIf="query.options.tab=='waitingList'">{{'WAITING_LIST'|translate}} ({{returnTabNum('waitingList')}}) </span>
          <span *ngIf="query.options.tab=='control'">{{'CONTROLL_PNR'|translate}} ({{returnTabNum('control')}}) </span>
          <span *ngIf="query.options.tab=='issued'">{{'ISSUED_PNR'|translate}} ({{returnTabNum('issued')}}) </span>
          <span *ngIf="query.options.tab=='cancelled'">{{'DELETED_PNR'|translate}} ({{returnTabNum('cancelled')}}) </span>
          <span *ngIf="query.options.tab=='init'">{{'TEMPLATES_PNR'|translate}} ({{returnTabNum('init')}}) </span>

          <span class="material-icons">keyboard_arrow_down</span>
        </button>
        <mat-menu #tabMenu="matMenu">
          <button (click)="changeTab('active')" mat-menu-item>{{'ACTIVE_PNR'|translate}} ({{returnTabNum('active')}})</button>
          <button (click)="changeTab('linked')" mat-menu-item>{{'RESERVATIONS_PNR'|translate}} ({{returnTabNum('linked')}})</button>
          <button (click)="changeTab('waitingList')" mat-menu-item>{{'WAITING_LIST'|translate}} ({{returnTabNum('waitingList')}})</button>
          <button (click)="changeTab('control')" mat-menu-item>{{'CONTROLL_PNR'|translate}} ({{returnTabNum('control')}})</button>
          <button (click)="changeTab('issued')" mat-menu-item>{{'ISSUED_PNR'|translate}} ({{returnTabNum('issued')}})</button>
          <button (click)="changeTab('cancelled')" mat-menu-item>{{'DELETED_PNR'|translate}} ({{returnTabNum('cancelled')}})</button>
          <button (click)="changeTab('init')" mat-menu-item>{{'TEMPLATES_PNR'|translate}} ({{returnTabNum('init')}})</button>

        </mat-menu>

        <button [matMenuTriggerFor]="orderMenu" mat-button class="listButton">
          {{sort|translate}}<span class="material-icons">keyboard_arrow_down</span>
        </button>
        <mat-menu #orderMenu="matMenu">
          <button (click)="sortValue('createdAt')" mat-menu-item>{{'CREATED_DATE'|translate}}</button>
          <button (click)="sortValue('generatedAt')" mat-menu-item>{{'GENERATED_DATE'|translate}}</button>
          <button (click)="sortValue('issuedOn')" *ngIf="query.options.tab=='issued'" mat-menu-item>{{'ISSUED_DATE'|translate}}</button>
          <button (click)="sortValue('timeLimit')" *ngIf="query.options.tab=='linked'|| query.options.tab=='deadline'" mat-menu-item>{{'EXPIRY_DATE'|translate}}</button>
          <button (click)="sortValue('cancelledAt')" *ngIf="query.options.tab=='cancelled'" mat-menu-item>{{'CANCELLATION_DATE'|translate}}</button>
          <button (click)="sortValue('fare.amount')" mat-menu-item>{{'PRICE'|translate}}</button>

        </mat-menu>

        <button (click)="reverseOrder()" mat-icon-button class="iconButtons">
          <mat-icon *ngIf="query.order==='asc'" matTooltip="{{'ASC'|translate}}" >vertical_align_top</mat-icon>
          <mat-icon *ngIf="query.order==='desc'" matTooltip="{{'DESC'|translate}}" >vertical_align_bottom</mat-icon>
        </button>

        <button (click)="openFilters()" mat-icon-button class="iconButtons">
          <mat-icon matTooltip="{{'SEARCH'|translate}}">search</mat-icon>
        </button>
        <ng-container *ngIf="!appComponent.isGat && !appComponent.isSbt && (mainComponent.loggedUser.role.superAdmin || mainComponent.loggedUser.company.services.b2b || mainComponent.loggedUser.company.services.b2c)">
          <mat-checkbox color="primary" [disabled]="query.options.salesChannels.length == 1 && allowedChannels.direct"
                        class="checkBoxes vertical ml-2 m-md-1 d-inline-block" [(ngModel)]="allowedChannels.direct" (ngModelChange)="setDirectChannels($event)">
            flyLeo
          </mat-checkbox>
        </ng-container>

        <ng-container *ngIf="(mainComponent.loggedUser.company.services.b2b || mainComponent.loggedUser.role.superAdmin) && !appComponent.isGat && !appComponent.isSbt">
          <mat-checkbox color="primary" [disabled]="query.options.salesChannels.length == 1 && allowedChannels.b2b"
                        class="checkBoxes vertical ml-2 m-md-1 d-inline-block" [(ngModel)]="allowedChannels.b2b" (ngModelChange)="setB2bChannels($event)">
            B2B
          </mat-checkbox>
        </ng-container>

        <ng-container *ngIf="(mainComponent.loggedUser.company.services.b2c || mainComponent.loggedUser.role.superAdmin) && !appComponent.isGat && !appComponent.isSbt">
          <mat-checkbox color="primary" [disabled]="query.options.salesChannels.length == 1 && allowedChannels.b2c"
                        class="checkBoxes vertical ml-2 m-md-1 d-inline-block" [(ngModel)]="allowedChannels.b2c" (ngModelChange)="setB2cChannels($event)">
            B2C
          </mat-checkbox>
        </ng-container>

        <ng-container *ngIf="mainComponent.loggedUser.role.superAdmin">
          <mat-checkbox color="primary" [disabled]="query.options.salesChannels.length == 1 && allowedChannels.cau"
                        class="checkBoxes vertical ml-2 m-md-1 d-inline-block" [(ngModel)]="allowedChannels.cau" (ngModelChange)="setCauChannels($event)">
            Altre IATA
          </mat-checkbox>
        </ng-container>
      </div>
      <div class="col-lg-6 d-flex justify-content-end text-right pb-2 pb-md-0">
        <div class="paginationr pr-3">
          <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
          <span class="mr-2">
            {{'PAGE'|translate}}
            <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
              <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p + 1}}</mat-option>
              </mat-select>
            </mat-form-field>
            {{'OF'|translate}} {{maxpages}}
          </span>
          <button class="iconButtons" (click)="prev()" [disabled]="currentpage==0" mat-icon-button>
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)" mat-icon-button>
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="!loading" class="collapse pnrfilters padding10">
      <hr/>
      <div class="row marginbottom10">
        <div class="col-12 col-sm-6 col-md-4 mb-2">
          <form autocomplete="off" class="example-form">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{'SEARCH_PNR'|translate}}</mat-label>
              <input (change)="setFilterText()" [(ngModel)]="query.text" [ngModelOptions]="{standalone: true}" autocomplete="off" matInput type="text">
              <!--
              <button mat-button *ngIf="query.text" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilterText()">
                <mat-icon>close</mat-icon>
              </button> -->
              <mat-hint align="start">{{'DIGIT_YOUR_SEARCH'|translate}}</mat-hint>
              <mat-hint (click)="clearFilterText()" *ngIf="query.text" align="end" style="color:red;cursor:pointer;">{{'CLEAR_SEARCH'|translate}} X</mat-hint>
            </mat-form-field>
          </form>
        </div>

        <div *ngIf="mainComponent.loggedUser.role.superAdmin && !page" class="col-12 col-sm-6 col-md-4 mb-2">
          <!-- <app-companies [filtering]="filterCompanies" [query]="query"></app-companies> -->
          <app-autocompletecompanies [filtering]="filterCompanies" [query]="query" (onChange)="setCpFilter()"></app-autocompletecompanies>
        </div>
        <div *ngIf="filterCompanies.length>0" class="col-12 mb-2 cpfilter">
          <hr/>
          <h6 class="mb-2">{{'FILTERING_COMPANIES'|translate}}</h6>
          <div *ngIf="filterCompanies.length>0 && !page">
            <mat-chip-listbox *ngIf="filterCompanies[0].name" aria-label="Companies selections" class="d-inline">
              <mat-chip-option *ngFor="let element of filterCompanies">
                {{element.name}}
                <mat-icon (click)="removeCpFilter(element._id)" matChipRemove>cancel</mat-icon>
              </mat-chip-option>

            </mat-chip-listbox>
            <div *ngIf="!filterCompanies[0].name">
              {{filterCompanies.length}} {{'COMPANIES_SELECTED'|translate}}
              <button (click)="clearAllCp()" mat-button>{{'CLEAR_ALL_CP'|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="progress" class="">
      <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>

  <div *ngIf="reservationlist && reservationlist.list.length > 0" class="hotelList">

    <mat-card appearance="outlined" *ngFor="let reservation of reservationlist.list" class="example-card mb-3">
      <mat-card-header>
        <div class="example-header-image" mat-card-avatar style="background-image: url('/assets/img/hotel/hotelicon.png')"></div>
        <mat-card-title>
          <span *ngIf="reservation.locator">{{reservation.locator}} - </span>
          <span>{{reservation.hotel.name}}</span>
        </mat-card-title>
        <mat-card-subtitle class="mb-3">
          <!--
           <span class="init" *ngIf="reservation.status==='init' && !reservation.waitingList">{{'TEMPLATE'|translate}}</span>
          <span *ngIf="reservation.status==='init' && reservation.waitingList" class="init">
            <span class="smalIcon material-icons">update</span> {{'WAITING_CONFIRM'|translate}}
          </span>
          <span class="pnrNewStatus linkedNew" *ngIf="reservation.status==='linked'">
            <span class="smalIcon material-icons">done</span> {{'LINKED'|translate}}
          </span>
          <span class="pnrNewStatus issuedNew" *ngIf="reservation.status==='issued'">
            <span class="smalIcon material-icons">task_alt</span> {{'ISSUED'|translate}}
          </span>
          <span class="pnrNewStatus issuedNew" *ngIf="reservation.status==='flown'"><span class="smalIcon material-icons">task_alt</span> {{'FLOWN'|translate}}</span>
          <span class="pnrNewStatus expiredNew" *ngIf="reservation.status==='expired'">
            <span class="smalIcon material-icons">close</span> {{'EXPIRED'|translate}}
          </span>
          <span class="pnrNewStatus cancelledNew" *ngIf="reservation.status==='cancelled'">
            <span class="smalIcon material-icons">close</span> {{'CANCELLED'|translate}}
          </span>
          <div *ngIf="reservation.status=='issued'">
            <span *ngFor="let locator of reservation.chainLocators">{{locator.chain}} {{locator.locator}}</span>
          </div>
           -->
          <div class="row mt-3">
            <div class="col-auto align-self-center">
              <span class="init" *ngIf="reservation.status==='init' && !reservation.waitingList">{{'TEMPLATE'|translate}}</span>
              <span *ngIf="reservation.status==='init' && reservation.waitingList" class="init">
                <span class="smalIcon material-icons">update</span> {{'WAITING_CONFIRM'|translate}}
              </span>
              <span class="pnrNewStatus linkedNew" *ngIf="reservation.status==='linked'">
                <span class="smalIcon material-icons">done</span> {{'LINKED'|translate}}
              </span>
              <span class="pnrNewStatus issuedNew" *ngIf="reservation.status==='issued'">
                <span class="smalIcon material-icons">task_alt</span> {{'ISSUED'|translate}}
              </span>
              <span class="pnrNewStatus issuedNew" *ngIf="reservation.status==='flown'">
                <span class="smalIcon material-icons">task_alt</span> {{'FLOWN'|translate}}
              </span>
              <span class="pnrNewStatus expiredNew" *ngIf="reservation.status==='expired'">
                <span class="smalIcon material-icons">close</span> {{'EXPIRED'|translate}}
              </span>
              <span class="pnrNewStatus cancelledNew" *ngIf="reservation.status==='cancelled'">
                <span class="smalIcon material-icons">close</span> {{'CANCELLED'|translate}}
              </span>
            </div>
            <div class="col-auto align-self-center">
              <div *ngIf="reservation.status=='issued'">
                <span *ngFor="let locator of reservation.chainLocators">{{locator.chain}} {{locator.locator}}</span>
              </div>
            </div>
          </div>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="row mb-2">
          <div class="col-lg-3">
            <div *ngIf="reservation.status=='init' && !reservation.waitingList" class="dateaction">
              {{'UPDATED_AT'|translate}} {{reservation.updatedAt|date:'dd/MM/yyyy - HH:mm'}}
            </div>
            <div *ngIf="reservation.status=='init' && reservation.waitingList" class="dateaction">
              {{'BOOKED_ON'|translate}} {{reservation.updatedAt|date:'dd/MM/yyyy - HH:mm'}}
            </div>
            <div *ngIf="reservation.status==='issued'" class="dateaction">
              <span>{{'ISSUED_ON'|translate}} {{reservation.issuedOn|date:'dd/MM/yyyy - HH:mm'}}</span>
            </div>
            <div *ngIf="reservation.status==='cancelled'" class="dateaction">
              <span>{{'cancelledAt'|translate}} {{reservation.cancelledAt|date: 'dd/MM/yyyy : HH:mm'}}</span>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="row newItemPnr mb-2">
              <div class="col-5">
                Check-in: {{reservation.hotel.checkIn|date:'dd/MM/yyyy'}}
              </div>
              <div class="col-2 align-self-center">
                <span class="material-icons">arrow_right_alt</span>
              </div>
              <div class="col-5">
                Check-out: {{reservation.hotel.checkOut|date:'dd/MM/yyyy'}}
              </div>
            </div>
            <div class="row newItemPnr mb-2">
              <div class="col-12">
                <span class="material-icons">hotel</span>
                <span [matTooltip]="reservation.room.roomRates[0].text">{{reservation.room.roomRates[0].text | slice:0:60}}</span>
                <span *ngIf="reservation.room.roomRates[0].text.length > 60">...</span>
              </div>
            </div>
          </div>

          <div class="col-lg-2">
            <div *ngIf="reservation.guests.length>0">
              <div *ngFor="let guest of reservation.guests" class="">
                <span *ngIf="guest.surname">{{guest.surname}}</span> <span *ngIf="guest.name"> {{guest.name}}</span> <sup> ({{guest.type}})</sup>
              </div>
            </div>
          </div>

          <div class="col-lg-2 text-center">
            <div class="reservationPrice marginbottom5">
              <span class="recoprice">
                {{reservation.room.roomRates[0].amount|number:'.2-2'}}€
              </span>
            </div>
          </div>

        </div>

        <div class="row pnrActionRow">
          <div class="col-lg-6">
            <div class="mt-2">
              <a routerLink="/users/list/{{reservation.user._id}}">{{reservation.user.surname}} {{reservation.user.name}}</a>
              <span class="material-icons">arrow_right_alt</span>
              <a routerLink="/companies/list/{{reservation.company._id}}">{{reservation.company.name}}</a>
            </div>
          </div>
          <!--
            <div class="col-lg-6 text-right buttonrowBis">
                <button mat-stroked-button  *ngIf="!page && (reservation.status==='linked' || reservation.status==='issued') && !reservation.paper && (appComponent.isSbt || appComponent.isB2B || mainComponent.loggedUser.role.superAdmin) && !appComponent.isGat" (click)="toggleAddtoTravel(reservation._id)">
                  {{'ADD_TO_FOLDER'|translate}}
                </button>
                <a mat-stroked-button *ngIf="reservation.paper" routerLink="/dossier/list/{{reservation.paper}}">
                  {{'GO_TO_DOSSIER'|translate}}
                </a>
                <a color="primary" class="ml-2" mat-flat-button routerLink="/flight/reservations/{{reservation._id}}">
                  {{'OPEN_RESERVATION'|translate}}
                </a>
              </div>
          -->


          <div class="col-lg-6 text-right buttonrowBis">
            <button (click)="toggleAddtoTravel(reservation._id)" *ngIf="!page && (reservation.status==='linked' || reservation.status==='issued') && !reservation.paper" mat-stroked-button>
              <!--<span class="material-icons">create_new_folder</span>-->{{'ADD_TO_FOLDER'|translate}}
            </button>
            <a *ngIf="reservation.paper" mat-stroked-button routerLink="/dossier/list/{{reservation.paper}}">
              <!--<span class="material-icons">folder_open</span>--> {{'GO_TO_DOSSIER'|translate}}
            </a>
            <a color="primary" mat-flat-button routerLink="/hotel/reservations/{{reservation._id}}">
              {{'OPEN_RESERVATION'|translate}} <!--<span class="material-icons">arrow_right_alt</span>-->
            </a>

          </div>
        </div>
      </mat-card-content>

    </mat-card>
    <!--
    <div class="card padding20 mb-3" *ngFor="let reservation of reservationlist.list">
      <div class="row">
        <div class="col-9 align-self-center">

          <div class="row">
            <div class="col-lg-4 align-self-center">
              <div *ngIf="!page && (reservation.status==='init' || reservation.status==='linked' || reservation.status==='issued') && !reservation.paper" class="buttonrow mb-2">
                <button mat-stroked-button (click)="toggleAddtoTravel(reservation._id)">{{'ADD_TO_FOLDER'|translate}}</button>
              </div>
              <div *ngIf="reservation.paper" class="buttonrow mb-2">
                <a mat-stroked-button routerLink="/dossier/list/{{reservation.paper}}">{{'GO_TO_DOSSIER'|translate}}</a>
              </div>
              <div class="pnrliststatus marginbottom5">
                <span class="init" *ngIf="reservation.status==='init' && !reservation.waitingList">{{'TEMPLATE'|translate}}</span>
                <span class="init" *ngIf="reservation.status==='init' && reservation.waitingList">{{'WAITING_CONFIRM'|translate}}</span>
                <span class="linked" *ngIf="reservation.status==='linked'">{{'LINKED'|translate}}</span>
                <span class="issued" *ngIf="reservation.status==='issued'">{{'ISSUED'|translate}}</span>
                <span class="flown" *ngIf="reservation.status==='flown'">{{'FLOWN'|translate}}</span>
                <span class="expired" *ngIf="reservation.status==='expired'">{{'EXPIRED'|translate}}</span>
                <span class="cancelled" *ngIf="reservation.status==='cancelled'">{{'CANCELLED'|translate}}</span>
              </div>
            </div>

            <div class="col-lg-4 align-self-center">
              <hr class="d-sm-block d-md-none d-lg-none"/>
              <div class="travelinfo mr-2">
                <div class="smalltext mt-2 font-weight-bold">
                  <div>Check-in: {{reservation.hotel.checkIn|date:'dd/MM/yyyy'}}</div>
                  <div>Check-out: {{reservation.hotel.checkOut|date:'dd/MM/yyyy'}}</div>
                </div>
                <div class="smalltext">
                  {{reservation.room.roomRates[0].text}}
                </div>

              </div>
            </div>

            <div class="col-lg-4 align-self-center">
              <hr class="d-md-block d-lg-none"/>
              <div *ngIf="reservation.status==='init'" class="dateaction">
                {{'BOOKED_ON'|translate}} {{reservation.createdAt|date:'dd/MM/yyyy - HH:mm'}}
              </div>
              <div *ngIf="reservation.status==='issued'" class="dateaction">
                {{'ISSUED_ON'|translate}} {{reservation.updatedAt|date:'dd/MM/yyyy - HH:mm'}}
              </div>
              <div *ngIf="reservation.guests.length>0">
                <div *ngFor="let guest of reservation.guests" class="listpnrsinglepasseng">
                  <span *ngIf="guest.surname">{{guest.surname}}</span> <span *ngIf="guest.name"> {{guest.name}}</span> <sup> ({{guest.type}})</sup>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-3 align-self-center text-center">

          <div class="reservationimage">
            <img class="hotelIcon" src="assets/img/hotel-icon.png"/>
            <span style="text-transform:uppercase;">
                {{reservation.hotel.name}}
              </span>
          </div>
          <div class="reservationPrice marginbottom5">
              <span class="recoprice">
                {{reservation.room.roomRates[0].amount|number:'.2-2'}}€
              </span>
          </div>
          <div class="linktopnr">
            <a routerLink="/hotel/reservations/{{reservation._id}}">{{'OPEN_RESERVATION'|translate}}</a>
          </div>
        </div>

        <div class="col-12 pnrlistuser">
          <hr/>
          <div>
            <a routerLink="/users/list/{{reservation.user._id}}">{{reservation.user.surname}} {{reservation.user.name}}</a>
            <mat-icon>arrow_right_alt</mat-icon>
            <a routerLink="/companies/list/{{reservation.company._id}}">{{reservation.company.name}}</a>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>

  <div *ngIf="!loading && reservationlist && reservationlist.list.length==0" class="padding20 text-center noresult">
    <h3>{{'NO_RESULTS'|translate}}.</h3>
    <p *ngIf="text">{{'NO_RESULTS_TEXT'|translate}} <span class="searchtext">{{text}}</span>.</p>
    <p *ngIf="text">{{'NO_RESULTS_TRY_AGAIN'|translate}}.</p>
  </div>

  <div *ngIf="!loading" class="card">
    <div class="paginationr pr-3 justify-content-end">
      <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>

      <span class="mr-2">
        {{'PAGE'|translate}}
        <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
          <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
            <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p + 1}}</mat-option>
          </mat-select>
        </mat-form-field>
        {{'OF'|translate}} {{maxpages}}
      </span>

      <button class="iconButtons" (click)="prev()" [disabled]="currentpage==0" mat-icon-button>
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)" mat-icon-button>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <div aria-hidden="true" aria-labelledby="travelListModal" class="modal fade" data-backdrop="static" id="travelListModal" role="dialog" tabindex="-1">
    <div class="modal-dialog-scrollable modal-dialog modal-xl" role="document">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addGuestModalTitle">
            {{'ADD_TO_FOLDER'|translate}}
          </h5>
          <button (click)="toggleAddtoTravel(null)" class="close" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div *ngIf="currentHotelId" class="modal-body">
          <app-dossierlist (close)="closeAddtoTravel()" [idHotel]="currentHotelId" page="hotel"></app-dossierlist>
        </div>

        <hr/>

        <div class="text-right buttonrow">
          <button (click)="toggleAddtoTravel(null)" mat-button type="button">{{'CLOSE'|translate}}</button>
        </div>

      </div>
    </div>
  </div>
</div>
