/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ReservationBookRequestDto } from '../models/ReservationBookRequestDto';
import type { ReservationBookResponseDto } from '../models/ReservationBookResponseDto';
import type { ReservationCreateRequestDto } from '../models/ReservationCreateRequestDto';
import type { ReservationCreateResponseDto } from '../models/ReservationCreateResponseDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ReservationService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns ReservationCreateResponseDto
     * @throws ApiError
     */
    public reservationControllerCreate(
        requestBody: ReservationCreateRequestDto,
    ): Observable<ReservationCreateResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v3/hotel/reservation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param limit
     * @param skip
     * @param text
     * @returns any
     * @throws ApiError
     */
    public reservationControllerGetReservationsList(
        limit: number = 20,
        skip?: number,
        text?: number,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/hotel/reservation',
            query: {
                'limit': limit,
                'skip': skip,
                'text': text,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ReservationBookResponseDto
     * @throws ApiError
     */
    public reservationControllerBook(
        id: string,
        requestBody: ReservationBookRequestDto,
    ): Observable<ReservationBookResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v3/hotel/reservation/{id}/book',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
