/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { SearchFareRequestDto } from '../models/SearchFareRequestDto';
import type { SearchFareResponseDto } from '../models/SearchFareResponseDto';
import type { SearchFormRequestDto } from '../models/SearchFormRequestDto';
import type { SearchHistoryResponseDto } from '../models/SearchHistoryResponseDto';
import type { SearchHotelResultListDto } from '../models/SearchHotelResultListDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class SearchService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns SearchHotelResultListDto
     * @throws ApiError
     */
    public searchControllerSearch(
        requestBody: SearchFormRequestDto,
    ): Observable<SearchHotelResultListDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v3/hotel/search',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param limit
     * @param skip
     * @param text
     * @param user
     * @returns SearchHistoryResponseDto
     * @throws ApiError
     */
    public searchControllerHistory(
        limit: number = 20,
        skip?: number,
        text?: number,
        user?: string,
    ): Observable<SearchHistoryResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/hotel/search',
            query: {
                'limit': limit,
                'skip': skip,
                'text': text,
                'user': user,
            },
        });
    }

    /**
     * @param requestBody
     * @returns SearchFareResponseDto
     * @throws ApiError
     */
    public searchControllerFare(
        requestBody: SearchFareRequestDto,
    ): Observable<SearchFareResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v3/hotel/search/fare',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
