import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HelperFunctionsService} from '../../../services/helper/helper-functions.service';
import {CompaniesService} from '../../../services/profile/companies.service';
import {Country, Province, Provincia, Region} from '../../adminarea/components/add-profile/add-profile.component';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {
  countries: Country[] = [
    { value: 'IT', viewValue: 'Italia' },
  ];
  regions: Region[] = [
    { value: 'Abruzzo', viewValue: 'Abruzzo' },
    { value: 'Basilicata', viewValue: 'Basilicata' },
    { value: 'Calabria', viewValue: 'Calabria' },
    { value: 'Campania', viewValue: 'Campania' },
    { value: 'Emilia-Romagna', viewValue: 'Emilia-Romagna' },
    { value: 'Friuli-Venezia Giulia', viewValue: 'Friuli-Venezia Giulia' },
    { value: 'Lazio', viewValue: 'Lazio' },
    { value: 'Liguria', viewValue: 'Liguria' },
    { value: 'Lombardia', viewValue: 'Lombardia' },
    { value: 'Marche', viewValue: 'Marche' },
    { value: 'Molise', viewValue: 'Molise' },
    { value: 'Piemonte', viewValue: 'Piemonte' },
    { value: 'Puglia', viewValue: 'Puglia' },
    { value: 'Sardegna', viewValue: 'Sardegna' },
    { value: 'Sicilia', viewValue: 'Sicilia' },
    { value: 'Toscana', viewValue: 'Toscana' },
    { value: 'Trentino-Alto Adige', viewValue: 'Trentino-Alto Adige' },
    { value: 'Umbria', viewValue: 'Umbria' },
    { value: 'Valle d\'Aosta', viewValue: 'Valle d\'Aosta' },
    { value: 'Veneto', viewValue: 'Veneto' },
  ];
  province: Provincia[] = [
    {
      nome: 'Agrigento',
      sigla: 'AG',
      regione: 'Sicilia',
    },
    {
      nome: 'Alessandria',
      sigla: 'AL',
      regione: 'Piemonte',
    },
    {
      nome: 'Ancona',
      sigla: 'AN',
      regione: 'Marche',
    },
    {
      nome: 'Arezzo',
      sigla: 'AR',
      regione: 'Toscana',
    },
    {
      nome: 'Ascoli Piceno',
      sigla: 'AP',
      regione: 'Marche',
    },
    {
      nome: 'Asti',
      sigla: 'AT',
      regione: 'Piemonte',
    },
    {
      nome: 'Avellino',
      sigla: 'AV',
      regione: 'Campania',
    },
    {
      nome: 'Bari',
      sigla: 'BA',
      regione: 'Puglia',
    },
    {
      nome: 'Barletta-Andria-Trani',
      sigla: 'BT',
      regione: 'Puglia',
    },
    {
      nome: 'Belluno',
      sigla: 'BL',
      regione: 'Veneto',
    },
    {
      nome: 'Benevento',
      sigla: 'BN',
      regione: 'Campania',
    },
    {
      nome: 'Bergamo',
      sigla: 'BG',
      regione: 'Lombardia',
    },
    {
      nome: 'Biella',
      sigla: 'BI',
      regione: 'Piemonte',
    },
    {
      nome: 'Bologna',
      sigla: 'BO',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Bolzano/Bozen',
      sigla: 'BZ',
      regione: 'Trentino-Alto Adige',
    },
    {
      nome: 'Brescia',
      sigla: 'BS',
      regione: 'Lombardia',
    },
    {
      nome: 'Brindisi',
      sigla: 'BR',
      regione: 'Puglia',
    },
    {
      nome: 'Cagliari',
      sigla: 'CA',
      regione: 'Sardegna',
    },
    {
      nome: 'Caltanissetta',
      sigla: 'CL',
      regione: 'Sicilia',
    },
    {
      nome: 'Campobasso',
      sigla: 'CB',
      regione: 'Molise',
    },
    {
      nome: 'Carbonia-Iglesias',
      sigla: 'CI',
      regione: 'Sardegna',
    },
    {
      nome: 'Caserta',
      sigla: 'CE',
      regione: 'Campania',
    },
    {
      nome: 'Catania',
      sigla: 'CT',
      regione: 'Sicilia',
    },
    {
      nome: 'Catanzaro',
      sigla: 'CZ',
      regione: 'Calabria',
    },
    {
      nome: 'Chieti',
      sigla: 'CH',
      regione: 'Abruzzo',
    },
    {
      nome: 'Como',
      sigla: 'CO',
      regione: 'Lombardia',
    },
    {
      nome: 'Cosenza',
      sigla: 'CS',
      regione: 'Calabria',
    },
    {
      nome: 'Cremona',
      sigla: 'CR',
      regione: 'Lombardia',
    },
    {
      nome: 'Crotone',
      sigla: 'KR',
      regione: 'Calabria',
    },
    {
      nome: 'Cuneo',
      sigla: 'CN',
      regione: 'Piemonte',
    },
    {
      nome: 'Enna',
      sigla: 'EN',
      regione: 'Sicilia',
    },
    {
      nome: 'Fermo',
      sigla: 'FM',
      regione: 'Marche',
    },
    {
      nome: 'Ferrara',
      sigla: 'FE',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Firenze',
      sigla: 'FI',
      regione: 'Toscana',
    },
    {
      nome: 'Foggia',
      sigla: 'FG',
      regione: 'Puglia',
    },
    {
      nome: 'Forlì-Cesena',
      sigla: 'FC',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Frosinone',
      sigla: 'FR',
      regione: 'Lazio',
    },
    {
      nome: 'Genova',
      sigla: 'GE',
      regione: 'Liguria',
    },
    {
      nome: 'Gorizia',
      sigla: 'GO',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Grosseto',
      sigla: 'GR',
      regione: 'Toscana',
    },
    {
      nome: 'Imperia',
      sigla: 'IM',
      regione: 'Liguria',
    },
    {
      nome: 'Isernia',
      sigla: 'IS',
      regione: 'Molise',
    },
    {
      nome: 'L\'Aquila',
      sigla: 'AQ',
      regione: 'Abruzzo',
    },
    {
      nome: 'La Spezia',
      sigla: 'SP',
      regione: 'Liguria',
    },
    {
      nome: 'Latina',
      sigla: 'LT',
      regione: 'Lazio',
    },
    {
      nome: 'Lecce',
      sigla: 'LE',
      regione: 'Puglia',
    },
    {
      nome: 'Lecco',
      sigla: 'LC',
      regione: 'Lombardia',
    },
    {
      nome: 'Livorno',
      sigla: 'LI',
      regione: 'Toscana',
    },
    {
      nome: 'Lodi',
      sigla: 'LO',
      regione: 'Lombardia',
    },
    {
      nome: 'Lucca',
      sigla: 'LU',
      regione: 'Toscana',
    },
    {
      nome: 'Macerata',
      sigla: 'MC',
      regione: 'Marche',
    },
    {
      nome: 'Mantova',
      sigla: 'MN',
      regione: 'Lombardia',
    },
    {
      nome: 'Massa-Carrara',
      sigla: 'MS',
      regione: 'Toscana',
    },
    {
      nome: 'Matera',
      sigla: 'MT',
      regione: 'Basilicata',
    },
    {
      nome: 'Medio Campidano',
      sigla: 'VS',
      regione: 'Sardegna',
    },
    {
      nome: 'Messina',
      sigla: 'ME',
      regione: 'Sicilia',
    },
    {
      nome: 'Milano',
      sigla: 'MI',
      regione: 'Lombardia',
    },
    {
      nome: 'Modena',
      sigla: 'MO',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Monza e della Brianza',
      sigla: 'MB',
      regione: 'Lombardia',
    },
    {
      nome: 'Napoli',
      sigla: 'NA',
      regione: 'Campania',
    },
    {
      nome: 'Novara',
      sigla: 'NO',
      regione: 'Piemonte',
    },
    {
      nome: 'Nuoro',
      sigla: 'NU',
      regione: 'Sardegna',
    },
    {
      nome: 'Ogliastra',
      sigla: 'OG',
      regione: 'Sardegna',
    },
    {
      nome: 'Olbia-Tempio',
      sigla: 'OT',
      regione: 'Sardegna',
    },
    {
      nome: 'Oristano',
      sigla: 'OR',
      regione: 'Sardegna',
    },
    {
      nome: 'Padova',
      sigla: 'PD',
      regione: 'Veneto',
    },
    {
      nome: 'Palermo',
      sigla: 'PA',
      regione: 'Sicilia',
    },
    {
      nome: 'Parma',
      sigla: 'PR',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Pavia',
      sigla: 'PV',
      regione: 'Lombardia',
    },
    {
      nome: 'Perugia',
      sigla: 'PG',
      regione: 'Umbria',
    },
    {
      nome: 'Pesaro e Urbino',
      sigla: 'PU',
      regione: 'Marche',
    },
    {
      nome: 'Pescara',
      sigla: 'PE',
      regione: 'Abruzzo',
    },
    {
      nome: 'Piacenza',
      sigla: 'PC',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Pisa',
      sigla: 'PI',
      regione: 'Toscana',
    },
    {
      nome: 'Pistoia',
      sigla: 'PT',
      regione: 'Toscana',
    },
    {
      nome: 'Pordenone',
      sigla: 'PN',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Potenza',
      sigla: 'PZ',
      regione: 'Basilicata',
    },
    {
      nome: 'Prato',
      sigla: 'PO',
      regione: 'Toscana',
    },
    {
      nome: 'Ragusa',
      sigla: 'RG',
      regione: 'Sicilia',
    },
    {
      nome: 'Ravenna',
      sigla: 'RA',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Reggio di Calabria',
      sigla: 'RC',
      regione: 'Calabria',
    },
    {
      nome: 'Reggio nell\'Emilia',
      sigla: 'RE',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Rieti',
      sigla: 'RI',
      regione: 'Lazio',
    },
    {
      nome: 'Rimini',
      sigla: 'RN',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Roma',
      sigla: 'RM',
      regione: 'Lazio',
    },
    {
      nome: 'Rovigo',
      sigla: 'RO',
      regione: 'Veneto',
    },
    {
      nome: 'Salerno',
      sigla: 'SA',
      regione: 'Campania',
    },
    {
      nome: 'Sassari',
      sigla: 'SS',
      regione: 'Sardegna',
    },
    {
      nome: 'Savona',
      sigla: 'SV',
      regione: 'Liguria',
    },
    {
      nome: 'Siena',
      sigla: 'SI',
      regione: 'Toscana',
    },
    {
      nome: 'Siracusa',
      sigla: 'SR',
      regione: 'Sicilia',
    },
    {
      nome: 'Sondrio',
      sigla: 'SO',
      regione: 'Lombardia',
    },
    {
      nome: 'Taranto',
      sigla: 'TA',
      regione: 'Puglia',
    },
    {
      nome: 'Teramo',
      sigla: 'TE',
      regione: 'Abruzzo',
    },
    {
      nome: 'Terni',
      sigla: 'TR',
      regione: 'Umbria',
    },
    {
      nome: 'Torino',
      sigla: 'TO',
      regione: 'Piemonte',
    },
    {
      nome: 'Trapani',
      sigla: 'TP',
      regione: 'Sicilia',
    },
    {
      nome: 'Trento',
      sigla: 'TN',
      regione: 'Trentino-Alto Adige',
    },
    {
      nome: 'Treviso',
      sigla: 'TV',
      regione: 'Veneto',
    },
    {
      nome: 'Trieste',
      sigla: 'TS',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Udine',
      sigla: 'UD',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Valle d\'Aosta',
      sigla: 'AO',
      regione: 'Valle d\'Aosta',
    },
    {
      nome: 'Varese',
      sigla: 'VA',
      regione: 'Lombardia',
    },
    {
      nome: 'Venezia',
      sigla: 'VE',
      regione: 'Veneto',
    },
    {
      nome: 'Verbano-Cusio-Ossola',
      sigla: 'VB',
      regione: 'Piemonte',
    },
    {
      nome: 'Vercelli',
      sigla: 'VC',
      regione: 'Piemonte',
    },
    {
      nome: 'Verona',
      sigla: 'VR',
      regione: 'Veneto',
    },
    {
      nome: 'Vibo Valentia',
      sigla: 'VV',
      regione: 'Calabria',
    },
    {
      nome: 'Vicenza',
      sigla: 'VI',
      regione: 'Veneto',
    },
    {
      nome: 'Viterbo',
      sigla: 'VT',
      regione: 'Lazio',
    },
  ];
  provinces: Province[] = [];
  profile: any
  newuser: any;
  userdefaultlevel = 3;
  today = new Date();
  sex: string[] = ['M', 'F'];
  companyDetails: any;
  companyLevels = [
    {
      name: 'Amministratore',
      level: 1,
      trad: 'AMMINISTRATORE'
    },
    {
      name: 'Manager',
      level: 2,
      trad: 'MANAGER'
    },
    {
      name: 'Utente',
      level: 3,
      trad: 'USER'
    }
  ];
  sameLocation = false;
  passwordconfirm: string;
  hide = true;
  hideConfirm = true;
  barValue: number;
  color: string;
  companyServices: any;
  isNotItalyUser : boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    private helper: HelperFunctionsService,
    private companyService : CompaniesService,
  ) {
    this.newuser = {
      company: {},
      email: '',
      password: '',
      enable: true,
      role: this.userdefaultlevel,
      name: '',
      surname: '',
      phoneNumber: '',
      sex: 'M',
      birthday: null,
    }
  }

  measureStrength(p: string): number {
    if (!p) {
      p = 'a';
    }
    let force = 0;
    const regex = /[$-/:-?{-~!"^_`[\]]/g; // "
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);
    const flags = [lowerLetters, upperLetters, numbers, symbols];
    const passedMatches = flags.filter((isMatchedFlag: boolean) => {
      return isMatchedFlag === true;
    }).length;

    force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    force += passedMatches * 10;

    // penalty (short password)
    force = p.length <= 6 ? Math.min(force, 10) : force;

    // penalty (poor variety of characters)
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 40) : force;

    if (force < 20) {
      this.barValue = 33;
      this.color = 'warn';
    } else if (force >= 20 && force < 40) {
      this.barValue = 60;
      this.color = 'accent';
    } else {
      this.barValue = 100;
      this.color = 'primary';
    }

    return force;
  }

  saveUser(){
    this.newuser.password = String(CryptoJS.MD5(this.newuser.password));
    this.newuser.company = this.companyDetails._id;
    if(this.newuser.location._id){
      delete this.newuser.location._id;
      delete this.newuser.location.createdAt;
      delete this.newuser.location.updatedAt;
      delete this.newuser.location.__v;

    }
    this.dialogRef.close(this.newuser);
  }

  validForm() {
    // tslint:disable-next-line:max-line-length
    return this.validUser();
  }

  validUser() {
    // tslint:disable-next-line:max-line-length
    return this.newuser.email && this.newuser.password && (this.newuser.role >= 0) && this.newuser.name && this.newuser.surname && this.newuser.phoneNumber && this.newuser.sex && this.validLocation(this.newuser);
  }

  validLocation(element) {
    // tslint:disable-next-line:max-line-length
    return element.location.country && element.location.region && element.location.province && element.location.city && element.location.postalCode && element.location.address;
  }

  changeCountry(state : any){
    this.isNotItalyUser = state;
    this.newuser.location.country = '';
    if(this.isNotItalyUser === false){
      this.newuser.location = {
        country: 'IT',
        region: '',
        province: '',
        city: '',
        postalCode: '',
        address: ''
      }
      this.sameLocation = false;
    }
  }

  setAddress(){
    this.sameLocation =! this.sameLocation;
    if(this.sameLocation){
      if (this.companyDetails.location.country !== 'IT'){
        this.isNotItalyUser = true;
      } else {
        this.isNotItalyUser = false;
      }
      this.newuser.location = this.companyDetails.location;
      this.provinces = [];
      for (let i = 0; i < this.province.length; i ++) {
        if (this.newuser.location.region === this.province[i].regione) {
          const prov = {
            value: this.province[i].sigla,
            viewValue: this.province[i].nome,
          };
          this.provinces.push(prov);
        }
      }
    } else {
      this.newuser.location = {
        country: '',
        region: '',
        province: '',
        city: '',
        postalCode: '',
        address: ''
      }
    }

  }

  setRegion() {
    this.newuser.location.province = '';
    this.provinces = [];
    for (let i = 0; i < this.province.length; i ++) {
      if (this.newuser.location.region === this.province[i].regione) {
        const prov = {
          value: this.province[i].sigla,
          viewValue: this.province[i].nome,
        };
        this.provinces.push(prov);
      }
    }
  }

  ngOnInit(): void {
    this.profile = this.helper.returnProfile();
    if(this.data.company){
      this.getCompanyDetails(this.data.company);
    }
    if(this.profile.role.superAdmin){
      const level = {
          name: 'System Administrator',
          level: 0,
          trad: 'AMMINISTRATORE_SISTEMA'
      }
      this.companyLevels.push(level);
    }
  }

  getCompanyDetails(id){
    this.companyService.getCompany(id).subscribe((res) => {
      this.newuser.company = res;
      this.companyDetails = res;
      this.getCompanyServices(this.companyDetails._id);
      console.log('newuser => ', this.newuser);
      this.setAddress();
    }, error => {
      console.error('Error => ', error);
    });
  }

  getCompanyServices(id){
    this.companyService.getCpSettings(id).subscribe((res) => {
      this.companyServices = res;
      if(this.companyServices.b2c){
        const b2clevel = {
          name: 'customer',
          level: 5,
          trad: 'CUSTOMER'
        }
        this.companyLevels.push(b2clevel);
      }
    }, error => {
      console.error('Error => ', error);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
