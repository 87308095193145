<custom-title></custom-title>
<div class="padding20">
  <mat-card appearance="outlined" class="p-3">
    <div class="row">
      <div *ngFor="let obj of authsSrvTypes; let i = index" class="col-6" [ngClass]="{'col-12': appComponent.isGat && !obj.b2b}">
        <div *ngIf="appComponent.isGat && !obj.b2b">
          <p>{{obj.b2b ? 'Company' : 'Agency'}}</p>
          <hr/>
          <div class="example-list-section">
            <ul *ngFor="let t of getServiceTypes(authsSrvTypes[i])">
              <li>
                <mat-checkbox [(ngModel)]="authsSrvTypes[i][t]" (ngModelChange)="update(i)">
                  {{ t | translate }}
                </mat-checkbox>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="!appComponent.isGat">
          <p>{{obj.b2b ? 'Company' : 'Agency'}}</p>
          <hr/>
          <div class="example-list-section">
            <ul *ngFor="let t of getServiceTypes(authsSrvTypes[i])">
              <li>
                <mat-checkbox [(ngModel)]="authsSrvTypes[i][t]" (ngModelChange)="update(i)">
                  {{ t | translate }}
                </mat-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
