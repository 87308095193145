import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-train-card',
  templateUrl: './train-card.component.html',
  styleUrls: ['./train-card.component.scss']
})
export class TrainCardComponent implements OnInit {

  @Input() productDetails: any = {};

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.productDetails);
  }

}
