
<h4 style="margin: 20px;">Search List</h4>
<ng-container *ngIf="all$ | async as searches; else loading">
  <div class="padding20" style="margin-top: 20px;" *ngIf="searches.data.length">
  <app-search-card [search]="search" *ngFor="let search of searches.data"></app-search-card>
  </div>

  <div class="no-data-found container" *ngIf="!searches.data.length">
    <h4>No Searches Found</h4>
  </div>
<!--  <pre>{{searches.data | json}}</pre>-->
</ng-container>


<ng-template #loading>
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
