import {Component, OnInit, OnChanges, SimpleChanges, Input} from '@angular/core';
import {StatisticService} from '../../../../services/statistic/statistic.service';
import {CompaniesService} from '../../../../services/profile/companies.service';
import {AppComponent} from '../../../../app.component';
import * as html2pdf from 'html2pdf.js';
import {MainComponent} from '../../../mainmodule/main/main.component';

@Component({
  selector: 'app-years',
  templateUrl: './years.component.html',
  styleUrls: ['./years.component.scss']
})
export class YearsComponent implements OnInit, OnChanges {
  currentTab: string;
  availableTabs: any[] = [];
  availableSections : any = {};
  @Input() chartmode: string;
  chartOptions: any;
  chartOptionsAll: any;
  chartOptionsAllMoney: any;
  chartOptionsMoney: any;
  year: number;
  currentYear: number;
  loading: boolean;
  currentYears = [];
  allYears = [];
  segmentsStats = [];
  allSegmentsStats = [];
  reservationsStats = [];
  allReservationsStats = [];
  monetaryStats = [];
  allMonetaryStats = [];
  startingYear = 2017;
  years = [];
  totalyears: number;
  builded: boolean;
  buildedYears: boolean;
  showPnr = true;
  showSegments = true;
  showIncentives = true;
  showMargin = true;
  showTotal = true;
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  monthToDisplay = [];
  totalYears = ['2017', '2018', '2019', '2020', '2021'];
  loadingPdf = false;
  topdf: any;
  constructor(
    private statsService: StatisticService,
    private companiesService: CompaniesService,
    public appComponent: AppComponent,
    private mainComponent: MainComponent,
  ) {
    this.year = new Date().getFullYear();
    this.currentYear = new Date().getFullYear();
    this.totalyears = (this.currentYear - this.startingYear) + 1;
    this.years = [];
    let i = 0;
    while (i < this.totalyears) {
      const anno = this.startingYear + i;
      this.years.push(anno);
      i++;
    }
    this.currentTab = 'FLIGHT';

    if (this.appComponent.isGat) {
      this.availableTabs = ['FLIGHT'];
    }
  }

  toggleValue(element): void {
    this[element] = ! this[element];
  }

  setTab(value) {
    this.currentTab = value;
    this.getYearStat();
  }

  changeYear(): void {
    this.getYearStat();
  }

  ngOnInit(): void {
    if(this.appComponent.isGat){
      this.availableTabs = ['FLIGHT'];
      this.availableSections[this.availableTabs[0]] = this.availableTabs[0];
    } else {
      this.availableTabs = ['FLIGHT'];
      this.availableSections[this.availableTabs[0]] = this.availableTabs[0];
      if(this.mainComponent.loggedUser.company.services.hotel){
        this.availableTabs.push('HOTEL')
        this.availableSections['HOTEL'] = 'HOTEL.NAME';
      }
      if(this.mainComponent.loggedUser.company.services.train){
        this.availableTabs.push('TRAIN')
        this.availableSections['TRAIN'] = 'TRAIN';
      }
      if(this.mainComponent.loggedUser.company.services.car){
        this.availableTabs.push('CAR')
        this.availableSections['CAR'] = 'CAR';
      }
    }

    this.getYearStat();
    // this.getAllYears();
  }

  getYearStat(): void {
    const body = {
      service: this.currentTab,
    };
    this.statsService.getYear(body).subscribe((res) => {
      this.loading = false;
      const current = JSON.parse(JSON.stringify(res.data));
      const all = [...res.data];
      this.currentYears = [];
      this.allYears = [];
      current.forEach(c => {
        if (c._id.year === this.year) {
          this.currentYears.push(c);
        }
      });
      console.log('this.currentYears =>', this.currentYears);
      this.buildData();
      all.forEach(y => {
        if (this.allYears.length === 0) {
          this.allYears.push(y);
        } else {
          const index = this.allYears.findIndex(r => r._id.year === y._id.year);
          if (index === -1) {
            this.allYears.push(y);
          } else {
            this.allYears[index].pnr += y.pnr;
            this.allYears[index].fees += y.fees;
            this.allYears[index].negotiated += y.negotiated;
            this.allYears[index].amount += y.amount;
            this.allYears[index].linked += y.linked;
            this.allYears[index].margins += y.margins;
            this.allYears[index].segments += y.segments;
            this.allYears[index].amount = Math.round(this.allYears[index].amount * 100) / 100;
          }
        }
      });
      console.log('res =>', res);
      this.buildAllData();
      // this.currentYears = res;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getAllYears(): void {
    this.statsService.getAllYear().subscribe((res) => {
      this.loading = false;
      this.allYears = [];
      const years = [];
      res.data.forEach(y => {
        if (years.length === 0) {
          years.push(y);
        } else {
          const index = years.findIndex(r => r._id.year === y._id.year);
          if (index === -1) {
            years.push(y);
          } else {
            years[index].pnr += y.pnr;
            years[index].fees += y.fees;
            years[index].negotiated += y.negotiated;
            years[index].amount += y.amount;
            years[index].linked += y.linked;
            years[index].margins += y.margins;
            years[index].segments += y.segments;
          }
        }
        this.allYears = years;
        console.log('this.allYears =>', this.allYears);
      });
      this.buildAllData();
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  buildData() {
    this.segmentsStats = [];
    this.reservationsStats = [];
    this.monetaryStats = [];
    this.monthToDisplay = [];
    for (let i = 0; i < this.currentYears.length; i++) {

      this.monthToDisplay.push(this.months[this.currentYears[i]._id.month - 1]);
      this.segmentsStats.push(this.currentYears[i].segments);
      this.reservationsStats.push(this.currentYears[i].pnr);
      if (this.currentYears[i].amount) {
        this.monetaryStats.push(this.currentYears[i].amount);
      } else {
        this.monetaryStats.push(0);
      }
    }
    this.buildChart();
    this.buildMoneyChart();
  }

  buildAllData() {
    this.allSegmentsStats = [];
    this.allReservationsStats = [];
    this.allMonetaryStats = [];
    this.years = [];
    for (let i = 0; i < this.allYears.length; i++) {
      this.years.push(this.allYears[i]._id.year);
      this.allSegmentsStats.push(this.allYears[i].segments);
      this.allReservationsStats.push(this.allYears[i].pnr);
      this.allMonetaryStats.push(this.allYears[i].amount);
    }
    this.buildAllYearsChart();
    this.buildAllMoneyChart();
  }

  buildChart() {
    this.builded = false;
    this.chartOptions = {
      theme: {
        palette: 'palette2',
        mode: this.chartmode,
      },
      series: [
        {
          name: 'Segmenti',
          data: this.segmentsStats,
        },
        {
          name: 'Prenotazioni',
          data: this.reservationsStats,
        },
        /*{
          name: 'Totale €',
          data: this.monetaryStats,
        }*/
      ],
      chart: {
        type: 'line',
        height: 230,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      /*stroke: {
        curve: 'straight'
      },*/
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        show: true,
        width: 2,
      },
      xaxis: {
        categories: this.monthToDisplay,
      },
    };
    this.builded = true;
  }

  buildMoneyChart() {
    this.builded = false;
    this.chartOptionsMoney = {
      theme: {
        monochrome: {
          enabled: true,
          color: '#00E38E',
          shadeTo: 'light',
          shadeIntensity: 0.65
        }
      },
      series: [
        {
          name: 'Totale €',
          data: this.monetaryStats,
        }
      ],
      chart: {
        type: 'line',
        height: 230,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      /*stroke: {
        curve: 'straight'
      },*/
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        show: true,
        width: 2,
      },
      xaxis: {
        categories: this.monthToDisplay,
      },
    };
    this.builded = true;
  }

  buildAllMoneyChart() {
    this.builded = false;
    this.chartOptionsAllMoney = {
      theme: {
        monochrome: {
          enabled: true,
          color: '#00E38E',
          shadeTo: 'light',
          shadeIntensity: 0.65
        }
      },
      series: [
        {
          name: 'Totale €',
          data: this.allMonetaryStats,
        }
      ],
      chart: {
        type: 'area',
        height: 230,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        show: true,
        width: 2,
      },
      xaxis: {
        categories: this.years,
      },
    };
    this.builded = true;
  }

  buildAllYearsChart() {
    this.buildedYears = false;
    this.chartOptionsAll = {
      theme: {
        palette: 'palette2',
        mode: this.chartmode,
      },
      series: [
        {
          name: 'Segmenti',
          data: this.allSegmentsStats,
        },
        {
          name: 'Prenotazioni',
          data: this.allReservationsStats,
        },
        /*{
          name: 'Totale €',
          data: this.allMonetaryStats,
        }*/
      ],
      chart: {
        type: 'area',
        height: 230,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      /*stroke: {
        curve: 'straight'
      },*/
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        show: true,
        width: 2,
      },
      xaxis: {
        categories: this.years,
      },
    };
    this.buildedYears = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildChart();
  }

  returnCount(element): number {
    let total = 0;
    for (let i = 0; i < this.currentYears.length; i++) {
      if (this.currentYears[i][element]) {
        total = total + this.currentYears[i][element];
      } else {
        total = total;
      }
    }
    return total;
  }

  addHeaderFooter(doc, totalPages) {
    const header = 'Report ' + this.appComponent.appName;
    const textRow = 'Tabella riepilogativa annuale ' + this.mainComponent.loggedUser.company.name;
    const footer = 'Pdf generato in data ' + new Date().toLocaleDateString('it-IT') + ' dal sistema ' + this.appComponent.appName;

    // Carica l'immagine dal percorso in "assets"
    const logo = new Image();
    // logo.src = this.imagePath;
    const width = 100;
    const aspectRatio = logo.width / logo.height;
    const height = width / aspectRatio;
    // doc.addImage(logo, 'JPEG', 10, 10, width, height);
    // Disegna l'immagine dell'intestazione
    // doc.addImage(logo, 'JPEG', 10, 10, 50, 50);

    // Disegna testo dell'intestazione
    doc.setFontSize(14);
    doc.setTextColor(100);
    doc.text(header, 10, 35, { align: 'left' });
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(textRow, 10, 55, { align: 'left' });

    // Disegna testo del piè di pagina
    doc.setFontSize(8);
    doc.setTextColor(100);
    doc.text(footer, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 10, { align: 'right' });

  }

  downloadPDF() {
    this.loadingPdf = true;
    const options = {
      filename: this.appComponent.appName +'_report_'+this.year,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
      margin: [90, 10, 50, 10],
    };
    console.log('step 1');

    const element = document.getElementById('printpdf');
    this.topdf = html2pdf();
    this.topdf.from(element)
      .set(options)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        console.log('step 2');
        // Aggiungi l'intestazione e il piè di pagina
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          this.addHeaderFooter(pdf, totalPages);
        }
        console.log('step 3', this.loadingPdf);
        // Salva il file PDF
        pdf.save(options.filename);
      });
    setTimeout(()=>{
      this.loadingPdf = false;
    }, 5000);

  }

}
