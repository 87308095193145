import {
  Journey,
  TrainReservation,
} from '../classes/train.models';

export class TrainUtils{
  private static BASE_IMG_PATH = 'assets/img/trains/'
  private static LOGOS = {
    TRAIN: { normal: 'treno.jpeg', minimal: 'train.png'},
    ITALO: { normal: 'italo.png', minimal: 'italo-logo.png'},
    TRENITALIA: { normal: 'Trenitalia.png', minimal: 'Regional-logo.png'},
    EC: { normal: 'Regional.png', minimal: 'Regional-logo.png'}, // eurocity
    IC: { normal: 'Intercity.png', minimal: 'Regional-logo.png'}, // Intercity
    NI: { normal: 'IntercityNotte.png', minimal: 'Regional-logo.png'}, // InterCityNotte
    UB: { normal: 'Regional.png', minimal: 'Regional-logo.png'}, // Urbano
    RV: { normal: 'Regional.png', minimal: 'Regional-logo.png'}, // REGIONALE
    RE: { normal: 'Regional.png', minimal: 'Regional-logo.png'}, // REGIONALE VELOCE
    FR: { normal: 'Frecciarossa.png', minimal: 'Regional-logo.png'}, // Frecciarossa
    FA: { normal: 'Frecciargento.png', minimal: 'Regional-logo.png'}, // Frecciaargento
    FB: { normal: 'Frecciabianca.png', minimal: 'Regional-logo.png'}, // Frecciabianca
  };

  public static getTrenitLogo(reservation?: TrainReservation, journey?: Journey, minimal : boolean = false) : string{
    let imgPath = '';
    const imgType = minimal ? 'minimal' : 'normal';
    const carrier = (reservation) ? reservation.carrier : journey?.company;
    const segments = (reservation) ? reservation.itineraries : journey?.segments;
    if (carrier === 'ITALO') {
      imgPath = this.LOGOS.ITALO[imgType];
    } else if (carrier === 'TRENITALIA') {
      const classificationSet: Set<string> = new Set();
      segments.forEach(t => classificationSet.add(t.trainAcronym));
      if (classificationSet.size === 1) {
        const classification: string = [...classificationSet][0];
        imgPath = this.LOGOS[classification] ? this.LOGOS[classification][imgType] : this.LOGOS.TRENITALIA[imgType];
      } else {
        imgPath = this.LOGOS.TRENITALIA[imgType];
      }
    }
    return TrainUtils.BASE_IMG_PATH + imgPath;
  }

  public static getTrainLogo(carrier: string){
    let imgPath = '';
    if (carrier === 'ITALO') {
      imgPath = this.LOGOS.ITALO.minimal;
    } else {
      imgPath = this.LOGOS.TRENITALIA.minimal;
    }
    return TrainUtils.BASE_IMG_PATH + imgPath;
  }

  public static toArray(something: any){
    return !something ? [] : (Array.isArray(something) ? something : [something]);
  }
}
