<div id="searchCard" class="searchForm mb-5 collapse show">
  <mat-card appearance="outlined" class="search-card searchFlight mb-5">
    <mat-card-header>
      <div mat-card-avatar class="search-header-image"></div>
      <mat-card-title>{{'FLIGHTS_SEARCH'|translate}}</mat-card-title>
      <mat-card-subtitle>{{'FILL_FORM'|translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="row mb-2 typeRow">
        <div class="col-12 lightbg">
          <div class="col-12" *ngFor="let info of reservation.changesRequest let x = index">
            <div class="row">
              <div class="col-12 col-md-8" >
                <app-airport-pnr *ngIf="!this.loading" [parentForm]="info" [index]="x" [isTransit]="false" [changesData] = "changesRequired" [changesLowCost] = changesLowCost></app-airport-pnr>
              </div>
              <div class="col-12 col-md-4">
                <mat-form-field class="full-width">
                  <input matInput readonly [matDatepicker]="jj" placeholder="{{'DEPARTURE_DATE'|translate}}" (click)="jj.open()" [min]="getMinDate(x)" [(ngModel)]="info.newSelections.date" (dateChange)="setNextDate(x)" name="date{{z}}" [disabled]="isDatedisable(x)">
                  <mat-datepicker-toggle matSuffix [for]="jj"></mat-datepicker-toggle>
                  <mat-datepicker #jj></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>

        </div>
      </div>
    </mat-card-content>
    <div class="formActionsButtons text-right">
      <div *ngIf="!loading">
        <button mat-raised-button color="primary" type="button" [disabled]="isValid()" (click)="startSearch()">
          {{'SEARCH'|translate}}
        </button>
      </div>
      <div *ngIf="loading" class="text-right">

        <div class="mb-2">{{'LOADING'|translate}}</div>
        <mat-progress-bar style="max-width: 200px;display:inline-block;" mode="indeterminate"></mat-progress-bar>
      </div>
      <div *ngIf="checkRoutingChange" class="mt-5">
        <app-changed-searchresult [lowCost]="true" [searchResults]="searchResults" [request]="reservation" [mustBeVisible] = checkRoutingChange></app-changed-searchresult>
      </div>
    </div>
  </mat-card>
</div>

