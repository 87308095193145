<div class="default_panel">
  <div class="default_heading d-flex justify-content-between">
    <div class="d-flex align-self-center">Registrations</div>
    <div class="d-flex align-items-center">
      <button (click)="buildChart()" mat-icon-button aria-label="Refresh" class="small-md">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
  <div class="default_content">
    <div class="row">
      <div class="col-lg-6">
        <div class="">
          <div class="numericData">568</div>
          <h5 class="titlesect">Registration</h5>
          <p class="smalltext marginright10">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="">
          <div class="numericData">785</div>
          <h5 class="titlesect">Subscriptions</h5>
          <p class="smalltext marginright10">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </div>
    </div>
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke"
      [theme]="chartOptions.theme"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
    ></apx-chart>
  </div>

</div>
