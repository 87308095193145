import {Component, Input, OnInit} from '@angular/core';
import {UsersService} from '../../../services/profile/users.service';
import {UsersList} from '../../../classes/userlist';
import {SendComponent} from '../notifications/send/send.component';

@Component({
  selector: 'app-usernotifierautocomplete',
  templateUrl: './usernotifierautocomplete.component.html',
  styleUrls: ['./usernotifierautocomplete.component.scss']
})
export class UsernotifierautocompleteComponent implements OnInit {
  @Input() query: any;
  @Input() selected: any = [];
  @Input() filtering: any = [];
  usersList: UsersList;
  loading: boolean;
  user: any;
  usertext: string;
  constructor(
    private userService: UsersService,
    private sendNoti: SendComponent,
  ) {
    this.loading = false;
  }

  ngOnInit(): void {
  }

  clearFrom() {
    this.usertext = '';
    delete this.user;
    delete this.usersList;
  }

  selectValue(value) {
    console.log('manual 1 => ', this.query);
    const find = this.query.sendTo.users.findIndex(r => r === value._id);
    if ( find === -1 ) {
      this.selected.push(value);
      this.query.sendTo.users.push(value._id);
      delete this.usersList;
    }
    this.clearFrom();
    this.usertext = '';
  }

  selectFirst() {
    console.log('first 1 => ', this.query);
    if ( this.usersList ) {
      if ( !this.query.sendTo.users.findIndex(r => r === this.usersList[0]._id) ) {
        this.selected.push(this.usersList[0]);
        this.query.sendTo.users.push(this.usersList[0]._id);
        delete this.usersList;
      }
    }
    this.clearFrom();
  }

  searchUser(value) {
    delete this.usersList;
    this.clearFrom();
    if (value.length >= 2) { this.getUsersList(value); }
  }

  getUsersList(value) {
    this.clearFrom();
    this.userService.getUsersComplete(value).subscribe((res) => {
      this.loading = false;
      this.usersList = res;
      console.log('this.companiesList => ', this.usersList);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  removeUser(id) {
    const toCp = this.query.sendTo.users.findIndex(r => r === id);
    const flCp = this.selected.findIndex(r => r._id === id);
    console.log('flCp =>', toCp);
    console.log('flCp =>', flCp);
    if (toCp !== -1) {
      this.query.sendTo.users.splice(toCp, 1);
    }
    if (flCp !== -1) {
      this.selected.splice( flCp, 1);
    }
  }

}
