<div class="product__item" role="listitem" *ngIf="!!productDetails.ForwardJourney">
  <div class="product__header">
    <h2 class="product__name">ForwardJourney</h2>
    <h3 class="product__name"><b>Train number:</b> {{productDetails.ForwardJourney.segments[0].trainNumber}} </h3>   
    <h3 class="product__name" *ngIf="productDetails.ForwardJourney.company == 'ITALO'"><b>Price: </b>{{productDetails.ForwardJourney.fares.DiscountedFarePrice}} &euro;</h3>
    <h3 class="product__name" *ngIf="productDetails.ForwardJourney.company == 'TRENITALIA'"><b>Price: </b>{{productDetails.ForwardJourney.result.travelSolutions.offeredServices[0].price.amount}} &euro;</h3>
  </div>
  <div class="product__body">
    <h3 class="product__rooms"><b>Train from:</b> </h3>
    <div class="product__room">
      <p class="product__item"><b>Date:</b> {{productDetails.ForwardJourney.segments[0].departureDate | date: 'dd/MM/yyyy H:m:s'}}</p>
      <p class="product__item"><b>Station:</b> {{productDetails.ForwardJourney.segments[0].departureStation}}</p>
    </div>
  </div>
  <div class="product__body">
    <h3 class="product__rooms"><b>Train to:</b> </h3>
    <div class="product__room">
      <p class="product__item"><b>Date:</b> {{productDetails.ForwardJourney.segments[0].arrivalDate | date: 'dd/MM/yyyy H:m:s'}}</p>
      <p class="product__item"><b>Station:</b> {{productDetails.ForwardJourney.segments[0].arrivalStation}}</p>
    </div>
  </div>
</div>

<hr *ngIf="!!productDetails.ReturnJourney">

<div class="product__item" role="listitem" *ngIf="!!productDetails.ReturnJourney">
  <div class="product__header">
    <h2 class="product__name">ReturnJourney</h2>
    <h3 class="product__name"><b>Train number:</b> {{productDetails.ReturnJourney.segments[0].trainNumber}} </h3>   
    <h3 class="product__name" *ngIf="productDetails.ReturnJourney.company == 'ITALO'"><b>Price: </b>{{productDetails.ReturnJourney.fares.DiscountedFarePrice}} &euro;</h3>
    <h3 class="product__name" *ngIf="productDetails.ReturnJourney.company == 'TRENITALIA'"><b>Price: </b>{{productDetails.ReturnJourney.result.travelSolutions.offeredServices[0].price.amount}} &euro;</h3>
  </div>
  <div class="product__body">
    <h3 class="product__rooms"><b>Train from:</b> </h3>
    <div class="product__room">
      <p class="product__item"><b>Date:</b> {{productDetails.ReturnJourney.segments[0].departureDate | date: 'dd/MM/yyyy H:m:s'}}</p>
      <p class="product__item"><b>Station:</b> {{productDetails.ReturnJourney.segments[0].departureStation}}</p>
    </div>
  </div>
  <div class="product__body">
    <h3 class="product__rooms"><b>Train to:</b> </h3>
    <div class="product__room">
      <p class="product__item"><b>Date:</b> {{productDetails.ReturnJourney.segments[0].arrivalDate | date: 'dd/MM/yyyy H:m:s'}}</p>
      <p class="product__item"><b>Station:</b> {{productDetails.ReturnJourney.segments[0].arrivalStation}}</p>
    </div>
  </div>
</div>

