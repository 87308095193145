import { NgModule } from '@angular/core';
import { RouterContainerComponent } from './components/router-container/router-container.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { CustomTitleComponent } from './components/custom-title/custom-title.component';
import { AdminReservationChangesComponent } from './components/admin-reservation-changes/admin-reservation-changes.component';


@NgModule({
  declarations: [
    CustomTitleComponent,
    RouterContainerComponent,
    AdminReservationChangesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
  ],
  exports: [
    CustomTitleComponent,
    AdminReservationChangesComponent
  ],
})
export class CustomCommonModule{}
