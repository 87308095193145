<div class="fopsContainer pt-5">
  <div class="mb-2">
    Per richiedere l'inserimento di una o piu carte, scarica e invia modulo compilato all'indirizzo email: caricamenti@leonardotravel.it
    <hr/>
    <div class="buttonrow">
      <a href="assets/data/modulo-autorizzazione-carte.pdf" target="_blank" mat-stroked-button class="mr-2">Scarica</a>
      <button (click)="toggleContractModal()" mat-stroked-button color="primary">Compila il modulo online</button>
    </div>

  </div>
  <div *ngIf="addingCardContr">
    <hr/>

    <div class="maxWidthModule">
      <div class="mb-2">ABILITAZIONE UTILIZZO CARTA PER EMISSIONE AUTOMATICA</div>
      <div class="mb-2">Per ragioni di sicurezza chiediamo solo i dati parziali, successivamente sarai contattato telefonicamente da un nostro operatore per verificare i dati completi della carta e confermare l'abilitazione</div>
      <div class="mb-2">AUTORIZZAZIONE DI ADDEBITO SU CARTA DI CREDITO</div>
      <hr/>
      <div>
        <div class="detailsCard mb-3" *ngFor="let newFop of contractFops; let ind = index, let l = last;">
          <div class="buttonrow text-right" *ngIf="contractFops.length > 1">
            <button mat-stroked-button color="warn" (click)="removenewfop(ind)">Rimuovi carta</button>
          </div>
          <div>
            <div class="">
              <div>DATI AZIENDA INTESTATARIA DELLA CARTA</div>
              <div class="mb-3">Da compilare solo se carta intestata ad azienda</div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="full-width" >
                    <mat-label>Ragione sociale</mat-label>
                    <input matInput type="text" [(ngModel)]="newFop.companydetails.name">
                    <button *ngIf="newFop.companydetails.name" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.companydetails.name=''">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width" >
                    <mat-label>Sede legale</mat-label>
                    <input matInput type="text" [(ngModel)]="newFop.companydetails.address">
                    <button *ngIf="newFop.companydetails.address" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.companydetails.address=''">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width" >
                    <mat-label>P.IVA/C.F.</mat-label>
                    <input matInput type="text" [(ngModel)]="newFop.companydetails.piva">
                    <button *ngIf="newFop.companydetails.piva" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.companydetails.piva=''">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width" >
                    <mat-label>Recapiti</mat-label>
                    <input matInput type="text" [(ngModel)]="newFop.companydetails.contacts">
                    <button *ngIf="newFop.companydetails.contacts" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.companydetails.contacts=''">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <hr/>
              <div class="">
                <div class="mb-2">IDENTIFICAZIONE DEL POSSESSORE DELLA CARTA</div>
                <div class="row">
                  <div class="col-md-6">
                    <mat-form-field class="full-width" >
                      <mat-label>Nome</mat-label>
                      <input matInput type="text" [(ngModel)]="newFop.owner.name" required>
                      <button *ngIf="newFop.owner.name" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.owner.name=''">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-hint *ngIf="!newFop.owner.name" class="text-danger">{{'MANDATORY_FIELD'|translate}}</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="full-width" >
                      <mat-label>Cognome</mat-label>
                      <input matInput type="text" [(ngModel)]="newFop.owner.surname" required>
                      <button *ngIf="newFop.owner.surname" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.owner.surname=''">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-hint *ngIf="!newFop.owner.surname" class="text-danger">{{'MANDATORY_FIELD'|translate}}</mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="col-md-6">
                    <mat-form-field class="full-width" >
                      <mat-label>Residenza</mat-label>
                      <input matInput type="text" [(ngModel)]="newFop.owner.address" required>
                      <button *ngIf="newFop.owner.address" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.owner.address=''">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-hint *ngIf="!newFop.owner.address" class="text-danger">{{'MANDATORY_FIELD'|translate}}</mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="col-md-6">
                    <mat-form-field class="full-width" >
                      <mat-label>Codice fiscale</mat-label>
                      <input matInput type="text" [(ngModel)]="newFop.owner.codicef" required>
                      <button *ngIf="newFop.owner.codicef" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.owner.codicef=''">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-hint *ngIf="!newFop.owner.codicef" class="text-danger">{{'MANDATORY_FIELD'|translate}}</mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="col-md-6">
                    <mat-form-field class="full-width" >
                      <mat-label>Tipo di documento</mat-label>
                      <mat-select [(ngModel)]="newFop.owner.docType" required>
                        <mat-option *ngFor="let doc of documents" value="{{doc.name}}">{{doc.name}}</mat-option>
                      </mat-select>
                      <mat-hint *ngIf="!newFop.owner.docType" class="text-danger">{{'MANDATORY_FIELD'|translate}}</mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="col-md-6">
                    <mat-form-field class="full-width" >
                      <mat-label>N° documento</mat-label>
                      <input matInput type="text" [(ngModel)]="newFop.owner.docnumber" required>
                      <button *ngIf="newFop.owner.docnumber" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.owner.docnumber=''">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-hint *ngIf="!newFop.owner.docnumber" class="text-danger">{{'MANDATORY_FIELD'|translate}}</mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="col-md-6">
                    <mat-form-field class="full-width" >
                      <mat-label>Recapito telefonico</mat-label>
                      <input matInput type="text" [(ngModel)]="newFop.owner.phone" required>
                      <button *ngIf="newFop.owner.phone" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.owner.phone=''">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-hint *ngIf="!newFop.owner.phone" class="text-danger">{{'MANDATORY_FIELD'|translate}}</mat-hint>
                    </mat-form-field>
                  </div>
                </div>

              </div>
              <hr/>
              <div>
                <div class="mb-2">DATI CARTA DI CREDITO</div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <mat-form-field class="full-width">
                      <mat-label>Tipo carta</mat-label>
                      <mat-select [(ngModel)]="newFop.card.type" (ngModelChange)="newFop.card.circuit=''" required>
                        <mat-option value="Credit Card">Credit Card</mat-option>
                        <mat-option value="Air Plus">Air Plus</mat-option>
                      </mat-select>
                      <mat-hint *ngIf="!newFop.card.type" class="text-danger">{{'MANDATORY_FIELD'|translate}}</mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="col-md-6" *ngIf="newFop.card.type=='Credit Card'">
                    <mat-form-field class="full-width" >
                      <mat-label>Circuito</mat-label>
                      <mat-select [(ngModel)]="newFop.card.circuit">
                        <mat-option value="Visa">Visa</mat-option>
                        <mat-option value="Master Card">Master Card</mat-option>
                        <mat-option value="American Express">American Express</mat-option>
                        <!-- <mat-option value="Diners">Diners</mat-option> -->
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row mb-2" *ngIf="newFop.card.type === 'Air Plus' || (newFop.card.type=='Credit Card' && newFop.card.circuit)">
                  <div class="col-12">
                    Numero carta<br/>
                    <span class="smalFtSize">
                      *Per ragioni di sicurezza chiediamo solo i dati parziali, successivamente sarai contattato telefonicamente da un nostro operatore per verificare i dati completi della carta e confermare l'abilitazione
                    </span>
                  </div>
                  <div class="col-md-2 text-center align-self-center">
                    <mat-form-field class="full-width" >
                      <input placeholder="Prima serie" matInput [maxlength]="validCardNum(newFop, 1)" type="text" [(ngModel)]="newFop.card.primaserie" required>
                      <button *ngIf="newFop.card.primaserie" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.card.primaserie=''">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-hint *ngIf="!newFop.card.primaserie" class="text-danger">{{'MANDATORY_FIELD'|translate}}</mat-hint>
                      <mat-hint *ngIf="newFop.card.primaserie" class="">{{newFop.card.primaserie.length}} / {{validCardNum(newFop, 1)}}</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 text-center align-self-center">
                    X X X X
                  </div>
                  <div class="col-md-2 text-center align-self-center">
                    <mat-form-field class="full-width" >
                      <input matInput placeholder="Terza serie" type="text" [maxlength]="validCardNum(newFop, 3)" [(ngModel)]="newFop.card.terzaserie" required>
                      <button *ngIf="newFop.card.terzaserie" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.card.terzaserie=''">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-hint *ngIf="!newFop.card.terzaserie" class="text-danger">{{'MANDATORY_FIELD'|translate}}</mat-hint>
                      <mat-hint *ngIf="newFop.card.terzaserie" class="">{{newFop.card.terzaserie.length}} / {{validCardNum(newFop, 3)}}</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 text-center align-self-center">
                    <mat-form-field class="full-width" >
                      <input matInput placeholder="Quarta serie" type="text" [maxlength]="validCardNum(newFop, 4)" [(ngModel)]="newFop.card.quartaserie" required>
                      <button *ngIf="newFop.card.quartaserie" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.card.quartaserie=''">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-hint *ngIf="!newFop.card.quartaserie" class="text-danger">{{'MANDATORY_FIELD'|translate}}</mat-hint>
                      <mat-hint *ngIf="newFop.card.quartaserie" class="">{{newFop.card.quartaserie.length}} / {{validCardNum(newFop, 4)}}</mat-hint>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row mb-2" *ngIf="newFop.card.type === 'Air Plus' || (newFop.card.type=='Credit Card' && newFop.card.circuit)">
                  <div class="col-md-3 text-center align-self-center">
                    <mat-form-field class="full-width" >
                      <mat-label>Scadenza</mat-label>
                      <input matInput required placeholder="02/28" max="5" type="text" [(ngModel)]="newFop.card.expiration" (ngModelChange)="placeSlash(ind, $event)">
                      <button *ngIf="newFop.card.expiration" matSuffix mat-icon-button aria-label="Clear" (click)="newFop.card.expiration=''">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-hint *ngIf="!newFop.card.expiration" class="text-danger">{{'MANDATORY_FIELD'|translate}}</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      <div class="buttonrow text-right">
        <button mat-flat-button color="primary" (click)="pushnewfop()">Aggiungi carta</button>
      </div>

      <hr/>

      <div class="text-right">
        <button type="button" mat-button (click)="toggleContractModal()">{{'ABORT'|translate}}</button>
        <button type="button" [disabled]="!validModule()" mat-button (click)="togglePrevModal()">{{'REVIEW'|translate}}</button>
        <div *ngIf="!validModule()" class="pt-3 text-danger smalFtSize">
          Compila tutti i campi obbligatori
        </div>
      </div>

    </div>

  </div>
  <hr/>
  <div>
    Sono accettate le seguenti carte di credito:
    <hr/>
    <img src="assets/images/carte-accettate.png" alt="carte"/>
  </div>
</div>

<div class="modal fade" id="contractPrev" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="addFopModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addFopModalTitle">{{'ADD_PAYMENT'|translate}}</h5>
        <button type="button" class="close" (click)="togglePrevModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="pdfPrint">
          <div class="printArea" *ngFor="let newFop of contractFops">
            <img style="max-width: 120px" src="assets/img/themes/Logo-Flyleo-blue.png"/>
            <hr/>
            <div class="text-center mb-2">
              ABILITAZIONE UTILIZZO CARTA PER EMISSIONE AUTOMATICA
            </div>
            <div class="text-center mb-3 smalFtSize">
              Per ragioni di sicurezza chiediamo solo i dati parziali, successivamente sarai contattato telefonicamente da un nostro operatore per verificare i dati completi della carta e confermare l'abilitazione
            </div>
            <div class="withBor text-center">
              AUTORIZZAZIONE DI ADDEBITO SU CARTA DI CREDITO
            </div>
            <div class="withBor smalFtSize text-center">
              [Compilare 1 copie del modulo per ogni carta da abilitare all'emissione, max 12]
            </div>
            <div class="withBor smalFtSize text-center">
              Da RITORNARE a LEONARDO TRAVEL SERVICE via FAX al N. 0175.654916 oppure via MAIL a caricamenti@leonardotravel.it
            </div>
            <div class="withBor mb-3 smalFtSize">
              Il presente documento è regolato dalle disposizioni IATA, BSP MANUAL 2008, e successive modifiche e/o integrazioni, per utilizzo dell'emissione biglietteria aerea per mezzo CRS e resta valido per tutte le operazioni generate nell'ambito dell'accordo esistente, altresì disponibile per i vettori che eventualmente ne facessero richiesta. Il titolare autorizza sin da adesso alla presentazione del presente deposito ai vettori che ne facessero richiesta e/o agli istituti di Credito emittenti;
            </div>

            <table class="table table-bordered table-condensed w-100 mb-2">
              <tbody>
                <tr>
                  <td colspan="2" class="text-center">
                    DATI AZIENDA INTESTATARIA DELLA CARTA
                  </td>
                </tr>
                <tr>
                  <td style="width:200px" class="text-right">RAGIONE SOCIALE</td>
                  <td>{{newFop.companydetails.name}}</td>
                </tr>
                <tr>
                  <td style="width:200px" class="text-right">SEDE LEGALE</td>
                  <td>{{newFop.companydetails.address}}</td>
                </tr>
                <tr>
                  <td style="width:200px" class="text-right">CF/P.IVA</td>
                  <td>{{newFop.companydetails.piva}}</td>
                </tr>
                <tr>
                  <td style="width:200px" class="text-right">RECAPITI</td>
                  <td>{{newFop.companydetails.contacts}}</td>
                </tr>
              </tbody>
            </table>

            <table class="table table-bordered table-condensed w-100 mb-2">
              <tbody>
              <tr>
                <td colspan="2" class="text-center">
                  IDENTIFICAZIONE DEL POSSESSORE DELLA CARTA
                </td>
              </tr>
              <tr>
                <td style="width:200px" class="text-right">NOME</td>
                <td>{{newFop.owner.name}}</td>
              </tr>
              <tr>
                <td style="width:200px" class="text-right">COGNOME</td>
                <td>{{newFop.owner.surname}}</td>
              </tr>
              <tr>
                <td style="width:200px" class="text-right">RESIDENZA</td>
                <td>{{newFop.owner.address}}</td>
              </tr>
              <tr>
                <td style="width:200px" class="text-right">CODICE FISCALE</td>
                <td>{{newFop.owner.codicef}}</td>
              </tr>
              <tr>
                <td style="width:200px" class="text-right">TIPO DI DOCUMENTO</td>
                <td>{{newFop.owner.docType}}</td>
              </tr>
              <tr>
                <td style="width:200px" class="text-right">NUMERO DOCUMENTO</td>
                <td>{{newFop.owner.docnumber}}</td>
              </tr>
              <tr>
                <td style="width:200px" class="text-right">RECAPITO TELEFONICO</td>
                <td>{{newFop.owner.phone}}</td>
              </tr>

              </tbody>
            </table>

            <table class="table table-bordered table-condensed w-100 mb-3">
              <tbody>
              <tr>
                <td colspan="2" class="text-center">
                  DATI CARTA DI CREDITO
                </td>
              </tr>
              <tr>
                <td style="width:200px" class="text-right">TIPO CARTA</td>
                <td>
                  <span *ngIf="!newFop.card.circuit">{{newFop.card.type}}</span>
                  <span *ngIf="newFop.card.circuit">{{newFop.card.circuit}}</span>
                </td>
              </tr>
              <tr>
                <td style="width:200px" class="text-right">NUMERO</td>
                <td>
                  {{newFop.card.primaserie}} * * * * {{newFop.card.terzaserie}} {{newFop.card.quartaserie}}
                </td>
              </tr>
              <tr>
                <td style="width:200px" class="text-right">SCADENZA</td>
                <td>{{newFop.card.expiration}}</td>
              </tr>
              </tbody>
            </table>

            <div class="withBor smalFtSize mb-3">
              Con il presente modulo il titolare della summenzionata carta di credito autorizza LEONARDO TRAVEL SERVICE, a firmare in nome e per conto proprio, tramite la procedura IATA S.O.F. (Signature On File), i moduli 999/SCCCF /STANDARD CREDIT CARD CHARGE FORM che verranno stampati per ogni emissione e che verranno inviati al BSP secondo termini e modalità previsti dallo stesso. Con il presente il titolare autorizza all'inserimento dei dati indispensabili sul proprio CRS al fine di eseguire in proprio gli acquisti selezionati. LEONARDO TRAVEL non assume responsabilità in ordine a insufficiente plafond della carta nonché ad eventuale revoca della stessa da parte dell'istituto di credito emittente.
            </div>

            <div class="row mb-4">
              <div class="col-6">
                Data _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
              </div>
              <div class="col-6">
                Timbro e firma _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
              </div>
            </div>
            <hr/>
            <div class="row">
              <div class="col-3">
                <img style="max-width: 125px;" src="assets/images/leotravellogo.png"/>
              </div>
              <div class="col-9 smalFtSize">
                SEDE LEGALE: P.zza Garibaldi 30 – 12037 Saluzzo (CN) – P.IVA 03114010048 – IATA n° 38276626 info@leonardotravel.it - Tel +39 0110708337 – Fax +39 0175 654916 – www.leonardotravel.net
              </div>
            </div>


          </div>

        </div>
      </div>

      <hr/>

      <div class="buttonrow text-right">
        <button type="button" mat-button (click)="togglePrevModal()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="generatePdf()">{{'DOWNLOAD'|translate}}</button>
      </div>

    </div>
  </div>
</div>
