<h3 mat-dialog-title>Leave a note for {{data.type}}</h3>
<mat-form-field appearance="outline" style="width: 100%">
  <mat-label>Type something</mat-label>
  <textarea [formControl]="note" matInput></textarea>
</mat-form-field>

<div style="display: flex; justify-content: flex-end">
  <button (click)="closeDialog(false)" mat-raised-button style="margin-right: 10px">Close</button>
  <button [disabled]="note.invalid" (click)="closeDialog(true)" mat-raised-button color="primary">{{data.type | titlecase}}</button>
</div>

