<hr/>
<h4>{{'FREQUENT_FLYERS'|translate}}</h4>

<div *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div *ngIf="!loading && frequentFlyers">
  <div class="mb-1" *ngFor="let fr of frequentFlyers; let i = index;">
    <div *ngIf="!fr.deleting && !fr.editing">
      <span class="mr-2" style="vertical-align:middle">
          <img *ngIf="fr.iata && fr.iata.length == 2" class="freqLogo" src="assets/img/airline/icon/{{fr.iata.toUpperCase()}}.png"/>
      </span>
      <span style="vertical-align:middle;">{{fr.iata}}-{{fr.number}}</span>
      <button mat-icon-button color="primary" (click)="fr.editing = true;">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button color="warn" (click)="fr.deleting = true;">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div *ngIf="fr.deleting">
      {{'DELETE_FREQUENT_FLYER_NUMBER'|translate}} {{fr.iata}}-{{fr.number}} ?
      <button matTooltip="{{'DELETE'|translate}}" (click)="deleteFrequentFlyers(fr._id)" mat-icon-button color="primary">
        <mat-icon>check</mat-icon>
      </button>
      <button matTooltip="{{'ABORT'|translate}}" mat-icon-button color="warn" (click)="fr.deleting = false;">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="row" *ngIf="fr.editing">
      <div class="col-6 align-self-center">
        <span class="mr-2" style="vertical-align:middle">
          <img *ngIf="fr.iata && fr.iata.length == 2" class="freqLogo" src="assets/img/airline/icon/{{fr.iata.toUpperCase()}}.png"/>
        </span>
        <mat-form-field class="d-inline-block smallWidth mr-2">
          <mat-label>{{'IATA'|translate}}</mat-label>
          <input [maxLength]="2" matInput placeholder="es. AZ" [(ngModel)]="fr.iata">
        </mat-form-field>
        <mat-form-field class="d-inline-block w-40">
          <mat-label>{{'FREQUENT_FLYER_NUMBER'|translate}}</mat-label>
          <input matInput placeholder="AZ123456" [(ngModel)]="fr.number">
        </mat-form-field>
      </div>
      <div class="col-6 align-self-center">
        <div class="buttonrow">
          <button (click)="editFrequentFlyer(i)" mat-button>{{'SAVE'|translate}}</button>
          <button (click)="fr.editing = false;" mat-button>{{'ABORT'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="frequentFlyers.length === 0">
    {{'NO_FREQUENT_FLYERS'|translate}}
  </div>
  <hr/>
  <div class="mb-2 row" *ngIf="adding">
    <div class="col-6">
      <span class="mr-2" style="vertical-align:middle">
          <img *ngIf="newFrequentFlyer.iata && newFrequentFlyer.iata.length == 2" class="freqLogo" src="assets/img/airline/icon/{{newFrequentFlyer.iata.toUpperCase()}}.png"/>
      </span>
      <mat-form-field class="smallWidth d-inline-block mr-2">
        <mat-label>{{'IATA'|translate}}</mat-label>
        <input [maxLength]="2" matInput placeholder="es. AZ" [(ngModel)]="newFrequentFlyer.iata">
      </mat-form-field>
      <mat-form-field class="d-inline-block w-40">
        <mat-label>{{'FREQUENT_FLYER_NUMBER'|translate}}</mat-label>
        <input matInput placeholder="123456" [(ngModel)]="newFrequentFlyer.number">
      </mat-form-field>
    </div>
    <div class="col-6 align-self-center">
      <div class="buttonrow" *ngIf="newFrequentFlyer"><button (click)="saveFrequentFlyer()" mat-button>{{'SAVE'|translate}}</button></div>
    </div>
  </div>
  <div>
    <div class="buttonrowBis">
      <button color="primary" (click)="adding =! adding" mat-button>
        <span *ngIf="!adding">{{'ADD_FREQUENT_FLYER'|translate}}</span>
        <span *ngIf="adding">{{'ABORT'|translate}}</span>
      </button>
    </div>
  </div>
</div>
