<custom-title></custom-title>
<div class="padding20 position-relative">
  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <div *ngIf="booking || makingSplit" class="absoluteLoading">
    <div class="text-center padding20">
      <mat-progress-bar mode="buffer" class="mb-5"></mat-progress-bar>
      <h5 *ngIf="booking">{{'BOOKING'|translate}}...</h5>
      <h5 *ngIf="makingSplit">{{'SPLIT'|translate}}...</h5>
    </div>
  </div>

  <div class="cardPnr marginbottom10" *ngIf="!loading && reservation">
    <div class="mb-3 p-2 alert-warning" *ngIf="!canBook() && !appComponent.isB2B && !appComponent.isSbt">
      <div *ngIf="this.authForCarrier.feePerPax && this.authForCarrier.feePerPax > 0">L'emissione per questo vettore ha un costo aggiuntivo di {{this.authForCarrier.feePerPax| number:'.2-2'}}€ per passeggero.<br/></div>
      <div *ngIf="reservation.inQueue || reservation.waitingList">
        La prenotazione è in coda per l'emissione manuale e sarà finalizzata dagli operatori a breve. Vi preghiamo di attendere e rientrare nella prenotazione fra qualche minuto per controllarne lo stato. Grazie.
      </div>
      <div *ngIf="this.authForCarrier.feePerPax && this.authForCarrier.feePerPax > 0">Il costo aggiuntivo è di {{reservation.passengers.length * this.authForCarrier.feePerPax| number:'.2-2'}}€</div>
    </div>

    <div class="padding10" *ngIf="reservation.notes && reservation.notes.text">
      <span class="smalIcon material-icons mr-2" style="font-size:14px;">comment</span>
      <span>{{reservation.notes.text}}</span>
      <hr/>
    </div>
    <div class="padding10 buttonrow smalltext" *ngIf="mainComponent.loggedUser.role.superAdmin && reservation.status=='issued' && reservation.negotiated && tasfStatus?.status !== '100'">
      <button mat-button color="primary" (click)="postTasf()" matTooltip="{{tasfStatus?.description}}">{{'REQUEST_TASF'|translate}}</button>
    </div>
    <div class="priceDetails negoDetails mb-2" *ngIf="!reservation.corporate && reservation.negotiated && !priceservice.isNegoHidden() && !appComponent.isB2B && !appComponent.isSbt">
      <span class="mr-2">{{'PUBLIC_FARE'|translate}}: {{reservation.fare.publicAmount|number:'.2-2'}}€</span>
      <span class="mr-2">{{'NET_FARE'|translate}}: {{reservation.fare.amount|number:'.2-2'}}€</span>
      <span class="mr-2" *ngIf="!appComponent.isGat">{{'FLYLEO_FEE'|translate}}: {{reservation.fare.fees|number:'.2-2'}}€</span>
      <span class="mr-2" *ngIf="appComponent.isGat">{{'FLYGAT_FEE'|translate}}: {{reservation.fare.fees|number:'.2-2'}}€</span>
      <span class="mr-2">{{'NET_DISCOUNT'|translate}}: {{calculateMargin(reservation.fare.publicAmount,reservation.fare.amount, reservation.fare.fees)|number:'.2-2'}}€</span>
    </div>

    <div class="priceDetails corporateDetails mb-2" *ngIf="reservation.corporate && reservation.negotiated && !priceservice.isNegoHidden() && !appComponent.isB2B && !appComponent.isSbt">
      <div>{{'CORPORATE_FARE'|translate}} {{reservation.marketingCarrier}}{{reservation.corporate}}</div>
      <span class="mr-2">{{'PUBLIC_FARE'|translate}}: {{reservation.fare.publicAmount|number:'.2-2'}}€</span>
      <span class="mr-2">{{'NET_FARE'|translate}}: {{reservation.fare.amount|number:'.2-2'}}€</span>
      <span class="mr-2" *ngIf="!appComponent.isGat">{{'FLYLEO_FEE'|translate}}: {{reservation.fare.fees|number:'.2-2'}}€</span>
      <span class="mr-2" *ngIf="appComponent.isGat">{{'FLYGAT_FEE'|translate}}: {{reservation.fare.fees|number:'.2-2'}}€</span>
      <span class="mr-2">{{'NET_DISCOUNT'|translate}}: {{calculateMargin(reservation.fare.publicAmount,reservation.fare.amount, reservation.fare.fees)|number:'.2-2'}}€</span>
    </div>

    <div class="padding20">
      <div class="row">
        <div class="col-md-4 align-self-center mb-2">
          <div class="reservationimage">
            <img *ngIf="reservation.marketingCarrier" class="mb-2" style="margin:0" src="/assets/img/airline/icon/{{reservation.marketingCarrier}}.png">
            <div *ngIf="reservation.marketingCarrier" style="text-transform:uppercase;">{{iataComplete.returnCarrier(reservation.marketingCarrier)}}</div>
            <img *ngIf="!reservation.marketingCarrier" style="margin:0" class="mb-2" src="assets/img/flights.png"/>
          </div>
          <div class="reservationPrice marginbottom5" *ngIf="!mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt">
              <span *ngIf="reservation.negotiated && !priceservice.isNegoHidden()" class="recoprice">
                {{priceservice.returnPrice(reservation.fare.amount+(reservation.fare.fees||0))|number:'.2-2'}}€
              </span>
              <span *ngIf="reservation.negotiated && priceservice.isNegoHidden()" class="recoprice">
                {{priceservice.returnPrice(reservation.fare.publicAmount)|number:'.2-2'}}€
              </span>
              <span class="negoicon" *ngIf="reservation.negotiated && priceservice.isNegoHidden() && !appComponent.isGat">
                <mat-icon color="primary" [matTooltipClass]="'my-tooltip'"
                          matTooltip="{{'PUBLIC_FARE'|translate}}: {{reservation.fare.publicAmount|number:'.2-2'}}€ &#13;
                              {{'NET_FARE'|translate}}: {{reservation.fare.amount|number:'.2-2'}}€ &#13;
                              {{'FLYLEO_FEE'|translate}}: {{reservation.fare.fees|number:'.2-2'}}€ &#13;
                              {{'NET_DISCOUNT'|translate}}: {{calculateMargin(reservation.fare.publicAmount,reservation.fare.amount, reservation.fare.fees)|number:'.2-2'}}€">
                  star_border
                </mat-icon>
              </span>
            <span class="negoicon" *ngIf="reservation.negotiated && priceservice.isNegoHidden() && appComponent.isGat">
                <mat-icon color="primary" [matTooltipClass]="'my-tooltip'"
                          matTooltip="{{'PUBLIC_FARE'|translate}}: {{reservation.fare.publicAmount|number:'.2-2'}}€ &#13;
                              {{'NET_FARE'|translate}}: {{reservation.fare.amount|number:'.2-2'}}€ &#13;
                              {{'FLYGAT_FEE'|translate}}: {{reservation.fare.fees|number:'.2-2'}}€ &#13;
                              {{'NET_DISCOUNT'|translate}}: {{calculateMargin(reservation.fare.publicAmount,reservation.fare.amount, reservation.fare.fees)|number:'.2-2'}}€">
                  star_border
                </mat-icon>
              </span>
              <span class="recoprice" *ngIf="!reservation.negotiated">
                {{'FARE'|translate}} {{reservation.fare.amount|number:'.2-2'}}
                <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                  <span *ngIf="!reservation.fare.currency">€</span>
              </span>
              <br/>
              <span class="recoprice" *ngIf="reservation.fare.services">
                {{'SERVICES'|translate}} {{reservation.fare.services|number:'.2-2'}}
                <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                  <span *ngIf="!reservation.fare.currency">€</span>
              </span>
              <br/>
              <span class="recoprice" *ngIf="reservation.fare.fees && !reservation.company.cauDK">
                {{'ISSUE_COSTS'|translate}} {{reservation.fare.fees|number:'.2-2'}}
                <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                <span *ngIf="!reservation.fare.currency">€</span>
              </span>
              <br/>
              <span class="recoprice" *ngIf="!reservation.negotiated">
                {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.amount + (reservation.fare.services||0))|number:'.2-2'}}
                <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                <span *ngIf="!reservation.fare.currency">€</span>
              </span>
              <span class="recoprice" *ngIf="reservation.negotiated && !priceservice.isNegoHidden()">
                {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.amount + (reservation.fare.fees||0) + (reservation.fare.services||0))|number:'.2-2'}}€
              </span>
              <span class="recoprice" *ngIf="reservation.negotiated && priceservice.isNegoHidden()">
                {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.publicAmount + (reservation.fare.services||0))|number:'.2-2'}}€
              </span>
          </div>

          <div *ngIf="mainComponent.appcomponent.isB2B || mainComponent.appcomponent.isSbt">
            <span *ngIf="reservation.negotiated" class="recoprice">
              <del>{{priceservice.returnPrice(reservation.fare.publicAmount)|number:'.2-2'}}€</del> {{priceservice.returnPrice(reservation.fare.publicAmount-(reservation.fare.fees||0))|number:'.2-2'}}€
            </span>
            <span *ngIf="!reservation.negotiated" class="recoprice">
              {{priceservice.returnPrice(reservation.fare.amount)|number:'.2-2'}}€
            </span>
            <br/>
            <span class="recoprice" *ngIf="reservation.fare.services">
              {{'SERVICES'|translate}} {{reservation.fare.services|number:'.2-2'}}€
            </span>
            <br/>
            <span class="recoprice" *ngIf="reservation.negotiated">
              {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.publicAmount - (reservation.fare.fees||0) + (reservation.fare.services||0))|number:'.2-2'}}€
            </span>
            <span class="recoprice" *ngIf="!reservation.negotiated">
              {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.amount + (reservation.fare.services||0))|number:'.2-2'}}€
            </span>
          </div>
          <div class="smalltext text-danger" *ngIf="reservation.fare && reservation.fare.manual">
            <span *ngIf="reservation.fare.manual.amount">Il prezzo è cambiato: {{reservation.fare.manual.amount|number:'.2-2'}}€</span>
          </div>
          <div class="small">
            <div *ngIf="reservation.status === 'init'">
              {{'CREATED_ON'|translate}}: {{reservation.createdAt | date:'dd/MM/yyyy - HH:mm'}}
            </div>
            <div *ngIf="reservation.status != 'init'">
              {{'CREATED_ON'|translate}}: {{reservation.createdAt | date:'dd/MM/yyyy - HH:mm'}}
            </div>
            <div *ngIf="reservation.status === 'issued' || reservation.status === 'flown'">
              {{'ISSUED_ON'|translate}}: {{reservation.issuedOn | date:'dd/MM/yyyy - HH:mm'}}
            </div>
            <div *ngIf="reservation.status === 'cancelled'">
              {{'CANCELLED_ON'|translate}}: {{reservation.cancelledAt | date:'dd/MM/yyyy - HH:mm'}}
            </div>
          </div>
        </div>

        <div class="col-md-4 align-self-center mb-2">
          <div class="row mb-2">
            <div class="col-auto align-self-center mb-2">
              <div *ngIf="reservation.carriersLocators && reservation.carriersLocators.length > 0 && !reservation.lowCost" class="d-inline-block" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
                <div class="pnrlocatorNew" *ngFor="let locator of reservation.carriersLocators; let l = last">{{locator.carrier}}: {{locator.locator}} </div>
              </div>
              <div *ngIf="reservation.carriersLocators && reservation.carriersLocators.length > 0 && reservation.lowCost && reservation.status != 'init'" class="pnrlocatorNew" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
                <span>{{reservation.carriersLocators[0].locator}}</span>
              </div>
              <br/>
              <div *ngIf="reservation.locator && reservation.status != 'init'" class="pnrlocatorNew" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
                <span>{{reservation.locator}}</span>
              </div>
              <div *ngIf="reservation.changed" class="variationpnr">
                <mat-icon>warning</mat-icon> {{'CHANGE_PNR'|translate}}
              </div>
            </div>
            <div class="col-auto ml-3 align-self-center">
              <span class="pnrNewStatus initNew" *ngIf="reservation.status==='init'">
                <span class="vertical material-icons mr-1">
                  warning
                </span>
                {{'TEMPLATE'|translate}}
              </span>
              <span class="pnrNewStatus linkedNew" *ngIf="reservation.status==='linked'">
                <span class="vertical material-icons mr-1">
                  check_circle
                </span>
                {{'LINKED'|translate}}
              </span>
              <span class="pnrNewStatus issuedNew" *ngIf="reservation.status==='issued'">
                <span class="vertical material-icons mr-1">
                  check_circle_outline
                </span>
                {{'ISSUED'|translate}}
              </span>
              <span class="pnrNewStatus issuedNew" *ngIf="reservation.status==='flown'">
                <span class="vertical material-icons mr-1">
                  check_circle_outline
                </span>
                {{'FLOWN'|translate}}
              </span>
              <span class="pnrNewStatus expiredNew" *ngIf="reservation.status==='expired'">
                <span class="vertical material-icons mr-1">
                  block
                </span>
                {{'EXPIRED'|translate}}
              </span>
              <span class="pnrNewStatus cancelledNew" *ngIf="reservation.status==='cancelled'">
                <span class="vertical material-icons mr-1">
                  block
                </span>
                {{'CANCELLED'|translate}}
              </span>
            </div>
          </div>

          <div *ngIf="!appComponent.isSbt">
            <!--
            <div class="ssrtimelimit marginbottom5" *ngIf="mainComponent.loggedUser.role.superAdmin && !reservation.lowCost">
              [SSR: timelimit]
            </div>
            -->
            <div class="row mb-2" *ngIf="reservation.timeLimit && reservation.status=='linked' && !reservation.lowCost">
              <div class="col-auto mb-3 align-self-center">
                <span class="timeLimitNew">
                  {{reservation.timeLimit | date:'dd/MM/yyyy':"+0000"}}
                </span>
              </div>
              <div class="col-auto mb-3 ml-3 align-self-center">
                <span class="pnrNewStatus timeLimit" *ngIf="appComponent.isGat && reservation.status === 'linked'" matTooltip="{{'TIME_LIMIT_TXT_GAT'|translate}}">
                  <span class="vertical material-icons mr-1">
                    timer
                  </span>
                  Time-limit
                </span>
                <span class="pnrNewStatus timeLimit" *ngIf="!appComponent.isGat && reservation.status === 'linked'" matTooltip="{{'TIME_LIMIT_TXT'|translate}}">
                  <span class="vertical material-icons mr-1">
                    timer
                  </span>
                  Time-limit
                </span>
              </div>
              <div class="col-12">
                <div class="counterlimitNew">
                  {{'EXPIRING_IN'|translate}} {{countDown(reservation.timeLimit)}}
                </div>
              </div>
            </div>
            <div class="waitingtimelimit" *ngIf="!reservation.timeLimit && reservation.status=='linked' && !reservation.lowCost">
              Time limit in attesa
            </div>
          </div>

          <hr style="margin-right:20px"/>
          <div class="smalltext">
            <span *ngFor="let class of reservation.tariff.details;let l = last;">
              <span>{{class.name||class.tariffClass}}</span> <span *ngIf="!l"> + </span>
            </span>
          </div>

          <div class="">
            <div class="smalltext bagsmal">
              <div *ngIf="reservation.tariff.passengers.ADT">
                <span class="material-icons">
                  work
                </span>
                {{'BAG_FOR_ADULT'|translate}}:
                <span *ngFor="let bag of reservation.tariff.passengers.ADT.baggage; let i = index;let l = last">
                  <span *ngIf="l && i != 0"> - </span>
                  {{bag.allowance}}
                  <span *ngIf="bag.allowance < 10">
                      BAG
                  </span>
                  <span *ngIf="bag.allowance >= 10">
                    Kg
                  </span>
                </span>
              </div>
              <div *ngIf="reservation.tariff.passengers.CHD && reservation.tariff.passengers.CHD.baggage.length>0">
                <span class="material-icons">
                  work
                </span>
                {{'BAG_FOR_CHILD'|translate}}:
                <span *ngFor="let bag of reservation.tariff.passengers.CHD.baggage; let i = index;let l = last">
                  <span *ngIf="l && i != 0"> - </span>
                  {{bag.allowance}}
                  <span *ngIf="bag.allowance < 10">
                    BAG
                  </span>
                  <span *ngIf="bag.allowance >= 10">
                    Kg
                  </span>
                </span>
              </div>
              <div *ngIf="reservation.tariff.passengers.INF && reservation.tariff.passengers.INF.baggage.length>0">
                <span class="material-icons">
                  work
                </span>
                {{'BAG_FOR_INFANT'|translate}}:
                <span *ngFor="let bag of reservation.tariff.passengers.INF.baggage; let i = index;let l = last">
                  <span *ngIf="l && i != 0"> - </span>
                  {{bag.allowance}}
                  <span *ngIf="bag.allowance < 10">
                    BAG
                  </span>
                  <span *ngIf="bag.allowance >= 10">
                    Kg
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 align-self-center companydetails">
          <div><a routerLink="/companies/list/{{reservation.company._id}}">{{reservation.company.name}}</a></div>
          <div>
            <mat-icon>subdirectory_arrow_right</mat-icon>
            <a routerLink="/users/list/{{reservation.user._id}}">{{reservation.user.surname}} {{reservation.user.name}}</a>
          </div>
          <hr/>
          <div *ngIf="(reservation.status=='issued' || reservation.status=='flown') && reservation.costCenter">
            {{'COST_CENTER'|translate}}: {{reservation.costCenter}}
          </div>
          <div class="costCenterInputing pnrActionsButtons" *ngIf="(reservation.status!='cancelled' && reservation.status!='issued' && reservation.status!='flown') && costCenter && !this.reservation.lowCost">
            <div class="costTilte mb-2">{{'COST_CENTER_TEXT'|translate}}</div>
            <mat-form-field class="mr-2">
              <mat-label>{{'COST_CENTER'|translate}}</mat-label>
              <mat-select [(ngModel)]="reservation.costCenter">
                <mat-option [value]="null" style="color:#b01818;">
                  {{'NONE'|translate}}
                </mat-option>
                <mat-option (click)="openCostCenterDialog()" style="color:#4d79f6;">+ {{'ADD_COST_CENTER'|translate}}</mat-option>
                <mat-option *ngFor="let cost of costCenter" [value]="cost.description">
                  {{cost.tag}} -> {{cost.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="updateCostCenter()" [disabled]="updating || loadingCenters">{{'UPDATE'|translate}}</button>
            <mat-progress-bar mode="buffer" *ngIf="updating || loadingCenters"></mat-progress-bar>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-md-4 align-self-center mb-2">
          <div class="travelinfo">
            <div *ngFor="let itinerary of reservation.itineraries;let l = last" [ngClass]="{'marginbottom5':!l}">
              <div class="pnrdeparture">
                <span class="material-icons mr-2">
                  flight_takeoff
                </span>
                {{itinerary.flights[0].departure.dateTime |date:'dd/MM/yyyy':"+0000"}} -
                {{returnZeroTime(itinerary.flights[0].departure.hours)}}:{{returnZeroTime(itinerary.flights[0].departure.minutes)}}

                <span class="material-icons ml-2 mr-2">
                  flight_land
                </span>
                {{itinerary.flights[itinerary.flights.length-1].arrival.dateTime |date:'dd/MM/yyyy':"+0000"}} -
                {{returnZeroTime(itinerary.flights[itinerary.flights.length-1].arrival.hours)}}:{{returnZeroTime(itinerary.flights[itinerary.flights.length-1].arrival.minutes)}}
              </div>
              <span class="locationspan" [matTooltip]="itinerary.flights[0].departure.location">
                {{iataComplete.returnAirport(itinerary.flights[0].departure.location)}} <sup>{{itinerary.flights[0].departure.location}}</sup>
              </span>
              <mat-icon>arrow_right_alt</mat-icon>
              <span class="locationspan" [matTooltip]="itinerary.flights[itinerary.flights.length-1].arrival.location">
                {{iataComplete.returnAirport(itinerary.flights[itinerary.flights.length-1].arrival.location)}} <sup>{{itinerary.flights[itinerary.flights.length-1].arrival.location}}</sup>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-4 align-self-center">
          <div *ngIf="reservation.passengers.length>0">
            <div *ngFor="let passenger of reservation.passengers" class="listpnrsinglepasseng">
              <span *ngIf="passenger.surname">{{passenger.surname}}</span>
              <span *ngIf="passenger.name"> {{passenger.name}}</span> <sup> ({{passenger.type}})</sup>
              <span *ngIf="passenger.wheelchair" class="ml-2 smalerIcon material-icons" [matTooltip]="passenger.wheelchair|translate">
                accessible
              </span>
              <div *ngIf="reservation.status==='issued' && passenger.ticket">
                <span class="d-block">TKT n°: {{passenger.ticket}} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 align-self-center pnrActionsButtons">
          <div *ngIf="reservation.lowCost && reservation.status=='linked'">
            {{'WAITING_CONFIRM'|translate}}
          </div>
          <button class="iconButtons" mat-icon-button color="warn" *ngIf="!reservation.inQueue && mainComponent.loggedUser.role.flight.taw.delete.options.linked
                  && !reservation.lowCost && reservation.status=='linked' || reservation.status=='expired'" (click)="toggleDeleteModal()"
                  matTooltip="{{'DELETE_RESERVATION'|translate}}">
            <mat-icon>delete_outline</mat-icon>
          </button>
          <button class="iconButtons" mat-icon-button color="warn" *ngIf="!reservation.inQueue && mainComponent.loggedUser.role.flight.taw.delete.options.linked && isInDay()
                  && !reservation.lowCost && reservation.status=='issued' && (authForCarrier && !authForCarrier.disableVoid)"
                  (click)="toggleDeleteModal()" matTooltip="{{'VOID_RESERVATION'|translate}}">
            <mat-icon>delete_outline</mat-icon>
          </button>
          <button class="iconButtons" mat-icon-button color="warn" *ngIf="reservation.lowCost && reservation.status=='issued' && tfCanBeVoid" (click)="toggleDeleteLowCostNDCModal()" matTooltip="{{'VOID_RESERVATION'|translate}}">
            <mat-icon>delete_outline</mat-icon>
          </button>
          <button *ngIf="reservation.status === 'issued' && webCheckin" mat-raised-button color="primary" (click)="toggleWebCheckinModal()" class="p-2">
            {{'WEB_CHECKIN'|translate}} <span class="material-icons"> arrow_right_alt </span>
          </button>
          <!--
          <button mat-raised-button color="warn" *ngIf="reservation.status=='linked' || reservation.status=='issued'" (click)="toggleDeleteModal()">
            {{'DELETE_RESERVATION'|translate}}
            <span class="material-icons">
              delete_outline
            </span>
          </button>
          -->
          <ng-container *ngIf="mainComponent.loggedUser.role.flight.taw.post.options.issue && (reservation.status=='linked' || reservation.status=='issued')">
            <div *ngIf="!reservation.changed; then issueBlock else confirmChanges"></div>
            <ng-template #confirmChanges>
              <button mat-raised-button color="warn" (click)="openBottomSheetChange()" *ngIf="reservation.changed">
                {{'CONFIRM_CHANGE'|translate}}
                <span class="material-icons"> arrow_right_alt </span>
              </button>
              <button mat-raised-button color="accent" (click)="confirmChangesTF()"
                      *ngIf="reservation.changed && reservation.lowCost && mainComponent.loggedUser.role.superAdmin">
                {{'APPLY_CONFIRM'|translate}}<span class="material-icons">arrow_right_alt</span>
              </button>
              </ng-template>
              <ng-template #issueBlock>
                <div *ngIf="!reservation.lowCost && checkFromSrvIfCanBook(); then thenBlock else elseBlock">
                </div>
                <ng-template #thenBlock>
                  <button *ngIf="!reservation.inQueue && !reservation.waitingList && reservation.status === 'linked' && checkPolicy()" mat-raised-button
                          color="primary" (click)="repriceReservation()" [disabled]="isBuyDisabled()">
                    {{'BUY_RESERVATION'|translate}}<span class="material-icons">arrow_right_alt</span>
                  </button>
                  <button *ngIf="!reservation.inQueue && !reservation.waitingList && reservation.status === 'linked' && !checkPolicy() && !reservation.approvalId"
                          mat-raised-button color="primary" (click)="requestAuthorization()">
                    {{'REQUEST_AUTHORIZATION'|translate}}<span class="material-icons">arrow_right_alt</span>
                  </button>
                  <button *ngIf="reservation.approvalId && reservation.status === 'linked' && !checkPolicy() && (search && search.status == 'approved') && transferApproved()"
                          mat-raised-button color="primary" (click)="repriceReservation()" [disabled]="isBuyDisabled()">
                    {{'BUY_RESERVATION'|translate}} <span class="material-icons"> arrow_right_alt </span>
                  </button>
                  <div class="pt-2 mb-1 smalltext text-danger" *ngIf="!transferApproved()">Trasferta non ancora autorizzata</div>
                  <div class="mb-1 smalltext text-warning" *ngIf="reservation.approvalId && reservation.status === 'linked' && !checkPolicy() && search && search.status === 'pending'">
                    Richiesta di approvazione inviata.
                  </div>
                  <div class="smalltext" *ngIf="reservation.approvalId && reservation.status === 'linked' && !checkPolicy() && search">
                    <div class="mb-1 pt-2">
                      Stato approvazione servizio:
                      <span class="text-warning" *ngIf="search.status === 'pending'">{{search.status}}</span>
                      <span class="text-success" *ngIf="search.status === 'approved'">{{search.status}}</span>
                      <span class="text-danger" *ngIf="search.status === 'declined'">{{search.status}}</span>
                    </div>

                    <div *ngIf="search.status === 'declined'">
                      <div *ngFor="let auth of search.userAuthorisations">
                        <ng-container *ngIf="auth.status === 'declined'">
                          <div>Ragione: <span class="text-danger">{{auth.note}}</span></div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="pt-2 infoTxt" *ngIf="reservation.inQueue || reservation.waitingList">
                    {{'PNR_INQUEUE'|translate}}
                  </div>
                  <div class="pt-2 infoTxt" *ngIf="reservation.status === 'linked' && authForCarrier && authForCarrier.issuanceInQueue">
                    {{'WILL_BE_INQUEUE'|translate}}
                  </div>
                  <div class="text-danger d-block small" *ngIf="authForCarrier && authForCarrier.disableVoid && (reservation.status === 'linked' || reservation.status === 'issued')">
                    Ti ricordiamo che per cancellare l'emissione di questo vettore dovrai contattare la biglietteria.
                  </div>
                </ng-template>
                <ng-template #elseBlock>
                  <button *ngIf="!reservation.lowCost && reservation.negotiated && reservation.status === 'linked'" mat-raised-button
                          color="primary" (click)="openBottomSheetHelp()">
                    {{'ASK_DISCOUNT_EMISSION'|translate}} <span class="material-icons"> arrow_right_alt </span>
                  </button>
                  <button *ngIf="!reservation.lowCost && !reservation.negotiated && reservation.status === 'linked'" mat-raised-button
                          color="primary" (click)="openBottomSheetHelp()">
                    {{'ASK_EMISSION'|translate}} <span class="material-icons"> arrow_right_alt </span>
                  </button>
                </ng-template>
                <button class="smalIconBtn" mat-raised-button *ngIf="canSplit()" color="primary" (click)="setTab(2); scroll_to('tabsContainer')">
                  {{'SPLIT_PNR'|translate}} <span class="material-icons"> alt_route </span>
                </button>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="mainComponent.loggedUser.role.flight.taw.post.options.link && reservation.status=='cancelled' && !reservation.lowCost">
            <button mat-button color="primary" (click)="regenerateModal()">
              {{'REDO_RESERVATION'|translate}}
            </button>
          </ng-container>

          <button mat-stroked-button color="primary" (click)="openRefund()"
                  *ngIf="reservation.status === 'issued' && isAvailableDateAtcRefund() && !reservation.lowCost && calledAtc">
            {{'INFO_REFUND'|translate}}
          </button>
          <div *ngIf="reservation.status === 'linked' && !reservation.creditcard && checkPolicy()" class="pt-2 small text-danger">
            <span class="material-icons">warning_amber</span> {{'PLEASE_SELECT_PAYMENT'|translate}}
          </div>
          <div *ngIf="reservation.status === 'linked' && !reservation.creditcard && !checkPolicy() && (search && search.status == 'approved') && reservation.approvalId && transferApproved()" class="pt-2 small text-danger">
            <span class="material-icons">warning_amber</span> {{'PLEASE_SELECT_PAYMENT'|translate}}
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="newPnrPagination" *ngIf="!loading && reservation">

    <div (click)="setTab(i)" class="singleNewVoice" [ngClass]="{'activeNew':tab.active}" *ngFor="let tab of tabs; let i = index">
      {{tab.name|translate}}
    </div>
  </div>
  <div class="newTabHeader" *ngIf="!loading && reservation">
    <div class="row">
      <div class="col-6 align-self-center">{{currentTab|translate}}</div>
      <div class="col-6 align-self-center text-right">
        <div class="newTabButtons">
          <button mat-icon-button [matMenuTriggerFor]="dossierMenu" *ngIf="reservation.paper" matTooltip="{{'DOSSIER'|translate}}">
            <mat-icon>folder</mat-icon>
          </button>
          <mat-menu #dossierMenu="matMenu">
            <button mat-menu-item routerLink="/dossier/list/{{reservation.paper}}">
              {{'GO_TO_DOSSIER'|translate}}
            </button>
            <button mat-menu-item (click)="toggleRemoveDossierModal()">
              {{'REMOVE_FROM_DOSSIER'|translate}}
            </button>
          </mat-menu>
          <!--
          <button mat-raised-button *ngIf="!reservation.paper && (reservation.status=='linked' || reservation.status=='issued')" (click)="toggleAddtoTravel()">
            {{'ADD_TO_FOLDER'|translate}}
          </button> -->
          <button class="miniIcons" mat-icon-button *ngIf="!reservation.paper && (reservation.status=='linked' || reservation.status=='issued')  &&
                  (appComponent.isSbt || appComponent.isB2B || mainComponent.loggedUser.role.superAdmin) && !appComponent.isGat"  (click)="toggleAddtoTravel()"
                  matTooltip="{{'ADD_TO_FOLDER'|translate}}">
            <mat-icon>create_new_folder</mat-icon>
          </button>
          <button *ngIf="!reservation.changed && reservation.status!='init' && reservation.status!='cancelled'" mat-icon-button
                  (click)="togglePrint()" matTooltip="{{'PRINT'|translate}}" class="miniIcons">
            <mat-icon>print</mat-icon>
          </button>
          <button *ngIf="!reservation.changed && reservation.status!='init' && reservation.status!='cancelled'" mat-icon-button
                  (click)="toggleSendModal()" matTooltip="{{'SEND_BY_MAIL'|translate}}" class="miniIcons">
            <mat-icon>forward_to_inbox</mat-icon>
          </button>
          <button *ngIf="reservation.status!='init' && reservation.status!='cancelled'" mat-icon-button (click)="toggleNotes()"
                  matTooltip="{{'ADD_COSTUMER_NOTES'|translate}}" class="miniIcons">
            <mat-icon>note_alt</mat-icon>
          </button>
        </div>
      </div>
    </div>

  </div>
  <!--
  <div class="pnrPagination" *ngIf="!loading && reservation">
    <div (click)="setTab(i)" class="singleVoice" [ngClass]="{'active':tab.active}" *ngFor="let tab of tabs; let i = index">
      <mat-icon *ngIf="tab.name==='ITINERARY'">flight</mat-icon>
      <mat-icon color="warn" *ngIf="tab.name==='PNRHISTORY' && reservation.changed">history</mat-icon>
      <mat-icon *ngIf="tab.name==='PNRHISTORY' && !reservation.changed">history</mat-icon>
      <mat-icon *ngIf="tab.name==='PASSENGERS'">directions_walk</mat-icon>
      <mat-icon *ngIf="tab.name==='TARIFFES'">euro</mat-icon>
      <mat-icon *ngIf="tab.name==='PNR'">format_list_numbered</mat-icon>
      <mat-icon *ngIf="tab.name==='ATC.TAB'">find_replace</mat-icon>
      <mat-icon *ngIf="tab.name==='SEAT_MAP'">airline_seat_recline_normal</mat-icon>
      <mat-icon *ngIf="tab.name==='ANCILLARY'">work_outline</mat-icon>
      <mat-icon *ngIf="tab.name==='BAGGAGES'">work_outline</mat-icon>
      <mat-icon *ngIf="tab.name==='PAYMENT'">credit_card</mat-icon>
      <mat-icon *ngIf="tab.name==='FARE'">attach_money</mat-icon>
      <mat-icon *ngIf="tab.name==='FARE_RULES'">local_library</mat-icon>
      <mat-icon *ngIf="tab.name==='POST_BOOKING.CHANGE_BOOK'">transform</mat-icon>

      <span class="tabname"> {{tab.name|translate}}</span>
    </div>
  </div>
  -->

  <div id="tabsContainerNew" class="marginbottom20 padding1020" *ngIf="!loading && reservation">
    <div *ngIf="currentTab=='ITINERARY'">

      <div class="travelDetails">
        <div class="mb-3" *ngFor="let itinerary of reservation.itineraries; let i = index;let la = last;">
          <div [ngClass]="{'nextExist':i<reservation.itineraries.length && i!=reservation.itineraries.length-1}">
            <div class="introItinerary">
              <p *ngIf="itinerary.flights.length>0" >
                {{itinerary.flights[0].departure.dateTime |date:'dd/MM/yyyy':"+0000"}}
              </p>
              {{iataComplete.returnAirport(itinerary.flights[0].departure.location)}}
              <mat-icon>arrow_right_alt</mat-icon>
              {{iataComplete.returnAirport(itinerary.flights[itinerary.flights.length-1].arrival.location)}}
              <div class="flightCabin marginbottom10">
                <span *ngIf="reservation.cabins[i] === 'Y'">Economic</span>
                <span *ngIf="reservation.cabins[i]=='M'">Economic standard</span>
                <span *ngIf="reservation.cabins[i]=='W'">Economic premium</span>
                <span *ngIf="reservation.cabins[i]=='C'">Business</span>
                <span *ngIf="reservation.cabins[i]=='F'">First</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 collapse show" id="itinerary{{i}}">
                <div class="row">
                  <div class="col-sm-1 d-sm-inline-block d-none text-center align-self-center">
                    <img *ngIf="reservation.marketingCarrier" class="marketingcarrierimage" src="assets/img/airline/icon/{{reservation.marketingCarrier}}.png"/>
                    <img *ngIf="!reservation.marketingCarrier" class="marketingcarrierimage" src="assets/img/flights.png"/>
                  </div>
                  <div class="col-3 align-self-center">
                    {{iataComplete.returnAirport(itinerary.flights[0].departure.location)}}<sup>{{itinerary.flights[0].departure.location}}</sup><br/>
                    {{itinerary.flights[0].departure.dateTime |date:'dd/MM/yyyy':"+0000"}} -
                    {{returnZeroTime(itinerary.flights[0].departure.hours)}}:
                    {{returnZeroTime(itinerary.flights[0].departure.minutes)}}
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <div>{{returnTime(itinerary.flyingTime)}}</div> -->
                    <div>
                      <span *ngIf="itinerary.flights.length>1">
                        {{itinerary.flights.length-1}} {{'STOPS'|translate}}
                      </span>
                      <span *ngIf="itinerary.flights.length==1">
                        {{'NON_STOPS'|translate}}
                      </span>
                      <span *ngIf="checkTechnicalStops(itinerary.flights)">
                        <span *ngIf="checkTechnicalStops(itinerary.flights)>1">- {{checkTechnicalStops(itinerary.flights)}}</span>
                        <span *ngIf="checkTechnicalStops(itinerary.flights)>1">
                          -
                          <span class="material-icons iconTech">
                            local_gas_station
                          </span>
                          <span class="vertical">{{'TECHNICAL_STOPS'|translate}}</span>
                        </span>
                        <span *ngIf="checkTechnicalStops(itinerary.flights)==1">
                          -
                          <span class="material-icons iconTech">
                            local_gas_station
                          </span>
                          <span class="vertical">{{'TECHNICAL_STOP'|translate}}</span>
                        </span>


                      </span>
                    </div>
                    <div>
                      {{returnMilli(itinerary.flyingTime)}}
                    </div>
                    <div class="variationpnr" *ngIf="includeChange(itinerary)">
                      <mat-icon>warning</mat-icon> {{'CHANGE_PNR'|translate}}
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    {{iataComplete.returnAirport(itinerary.flights[itinerary.flights.length-1].arrival.location)}}<sup>{{itinerary.flights[itinerary.flights.length-1].arrival.location}}</sup><br/>
                    {{itinerary.flights[itinerary.flights.length-1].arrival.dateTime |date:'dd/MM/yyyy':"+0000"}} -
                    {{returnZeroTime(itinerary.flights[itinerary.flights.length-1].arrival.hours)}}
                    {{returnZeroTime(itinerary.flights[itinerary.flights.length-1].arrival.minutes)}}
                  </div>
                  <div class="col-3 col-sm-2 align-self-center">
                    <button mat-icon-button (click)="opeSect(i)" class="iconButtons">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-12 collapse issection" id="collapse{{i}}">
                <div class="row" *ngFor="let flight of itinerary.flights; let f = index;let fl = last;">
                  <div class="col-12 mb-2" *ngIf="f===0">
                    <div class="row">
                      <div class="col-1 text-center">
                        <img *ngIf="reservation.marketingCarrier" class="marketingcarrierimage" src="assets/img/airline/icon/{{reservation.marketingCarrier}}.png"/>
                        <img *ngIf="!reservation.marketingCarrier" class="marketingcarrierimage" src="assets/img/flights.png"/>
                      </div>
                      <div class="col-9 align-self-center">
                        <div class="md-titlebis">{{'DEPARTURE'|translate}}: {{flight.departure.dateTime |date:'dd/MM/yyyy':"+0000"}}</div>
                        <div class="md-subhead">{{returnMilli(itinerary.flyingTime)}}</div>
                        <div class="variationpnr" *ngIf="includeChange(itinerary)">
                          <mat-icon>warning</mat-icon> {{'CHANGE_PNR'|translate}}
                        </div>
                      </div>
                      <div class="col-2">
                        <button mat-icon-button (click)="opeSect(i)" class="iconButtons">
                          <mat-icon>keyboard_arrow_up</mat-icon>
                        </button>
                      </div>
                    </div>
                    <hr/>
                  </div>

                  <div class="col-md-1 text-center">
                    <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
                  </div>
                  <div class="col-md-11">
                    <div class="flightdet" [ngClass]="{'oldFlight':flight.status == 'HX' || flight.status == 'UN'  || flight.status == 'WK' || flight.status == 'UC' || flight.status == 'NO'}">
                      <p class="headingseg">
                        {{flight.departure.dateTime |date:'dd/MM/yyyy':"+0000"}} -
                        {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.departure.location)}} <sup>{{flight.departure.location}}</sup>
                      </p>
                      <p class="headingdetails">
                        <!-- {{'FLIGHT_TIME'|translate}}: HH MM -->
                        <span *ngIf="flight.departure.terminal"> - Terminal: {{flight.departure.terminal}}</span>
                      </p>
                      <p class="fldetails">
                        <span *ngIf="flight.detail.technicalStop && flight.detail.technicalStop.from > 0">
                          <span class="material-icons iconTech">
                            local_gas_station
                          </span>
                          <span class="vertical">
                            {{'TECHNICAL_STOP_IN'|translate}} {{flight.detail.technicalStop.location}}
                          </span><br/>
                          <span class="d-block pl-4">
                            {{'DURATION'|translate}}: {{convertMilliseconds(flight.detail.technicalStop)}}<br/>
                            {{'ARRIVAL'|translate}}:{{returnDate(flight.detail.technicalStop.from) | date:'dd MMM - HH:mm'}} - {{'DEPARTURE'|translate}} {{returnDate(flight.detail.technicalStop.to) | date:'dd MMM - HH:mm'}}
                          </span>

                        </span>
                      </p>
                      <p class="headingseg">
                        <!--
                      <span class="redt" *ngIf="otherday(flight.departure.date | date:'dd',flight.arrival.date | date:'dd')">
                        {{flight.arrival.date | date:'dd MMM yyyy'}} -
                      </span> -->
                        {{flight.arrival.dateTime | date:'dd/MM/yyyy':"+0000"}} -
                        {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.arrival.location)}} <sup>{{flight.arrival.location}}</sup>
                      </p>
                      <p class="fldetails">
                        {{flight.operatingCarrier}} {{flight.number}} <span *ngIf="flight.detail && flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
                        <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>
                        <span *ngIf="flight.status && flight.status.length > 0">
                          | status: {{flight.status}}
                          <span class="smalltext text-danger" *ngIf="flight.status == 'HX'">
                            {{'CANCELLED'|translate}}
                          </span>
                        </span>
                      </p>
                    </div>

                    <div *ngIf="flight.oldFlight" class="flightdet oldFlight">
                      <p class="headingseg">
                        {{flight.oldFlight.departure.dateTime |date:'dd/MM/yyyy':"+0000"}}-
                        {{returnZeroTime(flight.oldFlight.departure.hours)}}:{{returnZeroTime(flight.oldFlight.departure.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.oldFlight.departure.location)}} <sup>{{flight.oldFlight.departure.location}}</sup>
                      </p>
                      <p class="headingdetails">
                        <!-- {{'FLIGHT_TIME'|translate}}: HH MM -->
                        <span *ngIf="flight.oldFlight.departure.terminal"> - Terminal: {{flight.oldFlight.departure.terminal}}</span>
                      </p>
                      <p class="headingseg">
                        <!--
                        <span class="redt" *ngIf="otherday(flight.oldFlight.departure.date | date:'dd',flight.oldFlight.arrival.date | date:'dd')">
                          {{flight.oldFlight.arrival.date | date:'dd MMM yyyy'}} -
                        </span> -->
                        {{flight.oldFlight.arrival.dateTime |date:'dd/MM/yyyy':"+0000"}} -
                        {{returnZeroTime(flight.oldFlight.arrival.hours)}}:{{returnZeroTime(flight.oldFlight.arrival.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.oldFlight.arrival.location)}} <sup>{{flight.oldFlight.arrival.location}}</sup>
                      </p>
                      <p class="fldetails">
                        {{flight.oldFlight.operatingCarrier}} {{flight.oldFlight.number}} <span *ngIf="flight.oldFlight.detail && flight.oldFlight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.oldFlight.detail.equipment}}</span>
                        <span *ngIf="flight.oldFlight.arrival.terminal"> - Terminal: {{flight.oldFlight.arrival.terminal}}</span>
                        <span *ngIf="flight.oldFlight.status">
                          | status: {{flight.oldFlight.status}}
                        </span>
                      </p>
                    </div>
                    <div class="scalepause" *ngIf="!fl">
                    <span>
                      <i class="fas fa-random"></i> {{'STOP'|translate}} in {{iataComplete.returnAirport(flight.arrival.location)}} <sup>{{flight.arrival.location}}</sup>
                      <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}} </span>
                      <span *ngIf="!flight.waitingTime"> {{'WAITING'|translate}}: {{calculateWaiting(flight.arrival.dateTime, itinerary.flights[f+1].departure.dateTime)}} </span>
                    </span>
                      <span class="alert-danger changeairport" *ngIf="flight.arrival.location != itinerary.flights[f+1].departure.location">
                      {{'CHANGE'|translate}}:
                        {{iataComplete.returnAirport(itinerary.flights[f+1].departure.location)}} <sup>{{itinerary.flights[f+1].departure.location}}</sup></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentTab=='PASSENGERS'">
      <div class="buttonrow text-right" *ngIf="canSplit()">
        <button class="smalIconBtn" color="primary" [disabled]="passengersToSplit.length == 0 || makingSplit" mat-raised-button (click)="splitReservation()">
          {{'SPLIT_RESERVATION'|translate}}
        </button>
      </div>
      <div class="row">
        <div class="col-md-6 col-12" *ngFor="let passenger of reservation.passengers; let i = index">
          <div class="fakefield mb-2">
            {{'TYPE'|translate}}:
            <span *ngIf="passenger.type"> {{passenger.type|translate}}</span>
          </div>
          <div class="fakefield mb-2">
            {{'NAME'|translate}}:
            <span *ngIf="passenger.name else noData"> {{passenger.name}}</span>
          </div>
          <div class="fakefield mb-2">
            {{'SURNAME'|translate}}:
            <span *ngIf="passenger.surname else noData"> {{passenger.surname}}</span>
          </div>
          <div class="fakefield mb-2">
            {{'PHONE'|translate}}:
            <span *ngIf="passenger.phoneNumber else noData"> {{passenger.phoneNumber}}</span>
          </div>
          <div class="fakefield mb-2">
            {{'EMAIL'|translate}}:
            <span *ngIf="passenger.email else noData"> {{passenger.email}}</span>
          </div>
          <div class="fakefield mb-2 row mx-0" *ngIf="!reservation.lowCost">
            <div class="col px-0">
              {{'WHEELCHAIR' | translate}}:
              <ng-container *ngIf="passenger.wheelchair">
                <span> {{ passenger.wheelchair | translate }}</span>
                <button *ngIf="reservation.status==='linked'" type="button" class="text-right close" (click)="toggleRemoveWheelchairModal(i)">
                  <span aria-hidden="true">&times;</span>
                </button>
              </ng-container>
            </div>
            <div class="col text-right" *ngIf="!passenger.wheelchair && reservation.status==='linked'">
              <div class="pointer" (click)="toggleAddWheelchairModal(i)">
                {{'ADD_WHEELCHAIR' | translate}} +
              </div>
            </div>
          </div>
          <div class="fakefield mb-2 row mx-0">
            <div class="col px-0">
              {{'FREQUENT_FLYER' | translate}}:
              <span *ngIf="passenger.frequentFlyer else noData">
                {{passenger.frequentFlyer.iata}}-{{passenger.frequentFlyer.number}}
              </span>
            </div>
            <div class="col text-right">
              <button *ngIf="passenger.frequentFlyer && reservation.status==='linked'" type="button" class="text-right close" (click)="toggleRemoveFq(i)">
                <span aria-hidden="true">&times;</span>
              </button>
              <div *ngIf="!passenger.frequentFlyer && reservation.status==='linked'" class="pointer" (click)="toggleAddFqModal(i)">
                {{'ADD_FREQUENT_FLYER' | translate}}
              </div>
            </div>
          </div>
          <div class="buttonrow">
            <button class="smalIconBtn" color="primary" *ngIf="canSplit() && canBeSplitted(passenger)" mat-stroked-button (click)="selectPaxToSplit(passenger)">
              <span *ngIf="!isSplitSelection(passenger)" class="material-icons">
                check_box_outline_blank
              </span>
              <span *ngIf="isSplitSelection(passenger)" class="material-icons">
                check_box
              </span>
              {{'SELECT_FOR_SPLIT'|translate}}
            </button>
            <button class="smalIconBtn" color="primary" *ngIf="isSplitSelection(passenger) && !canBeSplitted(passenger)" mat-stroked-button
                    (click)="selectPaxToSplit(passenger)">
              <span class="material-icons">
                check_box
              </span>
              {{'SELECT_FOR_SPLIT'|translate}}
            </button>
            <button mat-stroked-button *ngIf="reservation.status==='linked' && !reservation.lowCost" (click)="openEdit(i)">{{'EDIT_TRAVELER'|translate}}</button>
          </div>
          <hr/>
        </div>
        <ng-template #noData> _ _ _ _</ng-template>
      </div>

      <div class="modal fade" id="EditTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div *ngIf="currentTraveler!=undefined && editingTraveler" class="modal-body">
              <app-edittraveler (close)="closeEditEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [currentTraveler]="reservation.passengers[currentTraveler]" [parent]="'pnrPage'"></app-edittraveler>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="currentTab=='TARIFFES'">
      <div class="text-right" *ngIf="!reservation.tariffes">
        <button mat-stroked-button *ngIf="!loadingFares && reservation.status==='linked'" [disabled]="reservation.approvalId" (click)="getReservationTariffs(reservation._id)">
          {{'SHOW_ALL_FARES'|translate}}
        </button>
        <div *ngIf="loadingFares">
          {{'REAL_TIME_PRICING'|translate}}
          <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12">
          <div class="uiFare selectedRate">
            <div class="fareNameNew">
              <span *ngFor="let class of reservation.tariff.details;let l = last;">
                <span>{{class.name||class.tariffClass}}</span> <span *ngIf="!l"> + </span>
              </span>
              <app-policy-alerts-icon [arrayOfAlerts]="reservation.tariff.arrayOfAlerts"></app-policy-alerts-icon>
            </div>
            <div class="dialogFareNew">
              <div class="bagInfo">
                <div *ngIf="!reservation.lowCost" class="mb-1">
                  {{'CABINS'|translate}}:
                  <span *ngFor="let class of reservation.tariff.bookingClasses;let l = last;">
                    <span class="singleClass" [ngClass]="{'mr-1': !l}">{{class}}</span> <!-- <span *ngIf="!l"> -> </span> -->
                  </span>
                </div>
                <div *ngIf="reservation.tariff.passengers.ADT">
                    <span class="material-icons">
                      work
                    </span>
                    {{'BAG_FOR_ADULT'|translate}}:
                    <span *ngFor="let bag of reservation.tariff.passengers.ADT.baggage; let i = index;let l = last">
                      <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                      <span *ngIf="bag.allowance < 10">
                          BAG
                      </span>
                      <span *ngIf="bag.allowance >= 10">
                        Kg
                      </span>
                    </span>
                </div>
                <div *ngIf="reservation.tariff.passengers.CHD && reservation.tariff.passengers.CHD.baggage.length>0">
                    <span class="material-icons">
                      work
                    </span>
                    {{'BAG_FOR_CHILD'|translate}}:
                    <span *ngFor="let bag of reservation.tariff.passengers.CHD.baggage; let i = index;let l = last">
                      <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                      <span *ngIf="bag.allowance < 10">
                          BAG
                      </span>
                      <span *ngIf="bag.allowance >= 10">
                        Kg
                      </span>
                    </span>
                </div>
                <div *ngIf="reservation.tariff.passengers.INF && reservation.tariff.passengers.INF.baggage.length>0">
                    <span class="material-icons">
                      work
                    </span>
                    {{'BAG_FOR_INFANT'|translate}}:
                    <span *ngFor="let bag of reservation.tariff.passengers.INF.baggage; let i = index;let l = last">
                      <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                      <span *ngIf="bag.allowance < 10">
                          BAG
                      </span>
                      <span *ngIf="bag.allowance >= 10">
                        Kg
                      </span>
                    </span>
                </div>
              </div>
              <div class="bottomActions">
                <span *ngIf="!reservation.tariff.refundable">
                  <span class="material-icons greytxt">
                    cancel
                  </span>
                  {{'NO_REFOUND'|translate}}
                </span>
                <span *ngIf="reservation.tariff.refundable">
                <span class="material-icons greentxt">
                  check_circle
                  </span> {{'YES_REFOUND'|translate}}
                </span>
                <span class="bottomInfoBtn" (click)="openBottomSheet(reservation.tariff)">
                  {{'FARE_INFO'|translate}}
                </span>
                <span class="bottomInfoBtn" *ngIf="reservation.status!='template' && reservation.status!='flown'" (click)="togglePenaltiesModal()">
                  {{'PENALTIES'|translate}}
                </span>
                <span class="material-icons yellow-text" matTooltip="Negotiated fare" *ngIf="reservation.tariff.negotiated && !mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt">
                  star
                </span>
                <span class="material-icons yellow-text" matTooltip="Discount fare" *ngIf="(mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) && reservation.tariff.negotiated">
                  star
                </span>
              </div>
              <div class="farePrice">
                <span *ngIf="!reservation.tariff.negotiated">
                  {{reservation.tariff.fare.amount|number:'.2-2'}}€
                </span>
                <span *ngIf="(mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) && reservation.tariff.negotiated">
                  <del>{{reservation.tariff.fare.publicAmount|number:'.2-2'}}€</del>
                  <span class="material-icons">
                    arrow_right_alt
                  </span>
                  {{reservation.fare.publicAmount - (reservation.fare.fees)|number:'.2-2'}}€
                </span>
                <span *ngIf="!mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt && reservation.tariff.negotiated && !priceservice.isNegoHidden()">
                  {{reservation.tariff.fare.amount + (reservation.tariff.fare.fees||0)|number:'.2-2'}}€
                </span>
                <span *ngIf="!mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt && reservation.tariff.negotiated && priceservice.isNegoHidden()">
                  {{reservation.tariff.fare.publicAmount|number:'.2-2'}}€
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="reservation.penalties" class="modal fade" id="penaltiesModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="penaltiesModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="top:70px" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="penaltiesModalTitle">{{'PENALTIES'|translate}}</h5>
              <button *ngIf="!deleting" type="button" class="close" (click)="togglePenaltiesModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div *ngIf="loadingRules">
                <div class="mb-3">{{'LOADING'|translate}}</div>
                <mat-progress-bar mode="buffer"></mat-progress-bar>
              </div>
              <div *ngIf="!loadingRules && rulesResponse && !mainComponent.loggedUser.role.superAdmin">
                <div [innerHTML]="toText(rulesResponse)"></div>
              </div>
              <div *ngIf="!loadingRules && originalRulesResponse && mainComponent.loggedUser.role.superAdmin">
                <div class="text-right">
                  <mat-slide-toggle [(ngModel)]="showFullNew">Show Full Response</mat-slide-toggle>
                </div>


                <div *ngIf="!loadingFares && originalRulesResponse && !showFullNew">
                  <mat-tab-group *ngFor="let pricing of originalRulesResponse.pricingRecord; let la = last">

                    <mat-tab *ngFor="let rule of pricing; let l = last" [label]="rule.descripionCategory|translate">
                      <!-- {{pricing|json}} -->
                      <div class="mb-3"></div>

                      <div [ngClass]="{'mb-3': rule.dateInfo[i+1] && rule.dateInfo[i+1].textType != single.textType}" *ngFor="let single of rule.dateInfo; let i = index; let l = last">

                        <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.dateInfo[i-1] && rule.dateInfo[i-1].textType != single.textType)">{{single.textType|translate}}</div>
                        <div [innerHTML]="toTextMini(single.freeText)"></div>

                        <hr *ngIf="l"/>
                      </div>

                      <div [ngClass]="{'mb-3': rule.mnrMonLoc[i+1] && rule.mnrMonLoc[i+1].textType != single.textType}" *ngFor="let single of rule.mnrMonLoc let i = index; let l = last">

                        <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.mnrMonLoc[i-1] && rule.mnrMonLoc[i-1].textType != single.textType)">{{single.textType|translate}}</div>
                        <div [innerHTML]="toTextMini(single.freeText)"></div>

                        <hr *ngIf="l"/>
                      </div>
                      <div *ngIf="rule.noCategoryRestriction">
                        <div class="mb-2">

                          <div>{{rule.noCategoryRestriction.freeText}}</div>
                        </div>
                      </div>

                    </mat-tab>

                  </mat-tab-group>
                </div>

                <div *ngIf="!loadingFares && originalRulesAmaResponse && showFullNew">
                  <mat-tab-group *ngFor="let pricing of originalRulesAmaResponse.pricingRecord; let la = last">

                    <mat-tab *ngFor="let rule of pricing; let l = last" [label]="rule.descripionCategory|translate">
                      <!-- {{pricing|json}} -->
                      <div class="mb-3"></div>

                      <div [ngClass]="{'mb-3': rule.dateInfo[i+1] && rule.dateInfo[i+1].textType != single.textType}" *ngFor="let single of rule.dateInfo; let i = index; let l = last">
                        <div *ngIf="i == 0"><h4 class="minititle">{{'dateInfo'|translate}}</h4></div>
                        <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.dateInfo[i-1] && rule.dateInfo[i-1].textType != single.textType)">{{single.textType|translate}}</div>
                        <div [innerHTML]="toTextMini(single.freeText)"></div>
                        <div class="pt-1 pl-2 small mb-3" *ngIf="single.infoType">{{single.infoType}}: {{rule.numberCategory+single.infoType|translate}}</div>
                        <hr *ngIf="l"/>
                      </div>

                      <div [ngClass]="{'mb-3': rule.mnrRestriAppLoc[i+1] && rule.mnrRestriAppLoc[i+1].textType != single.textType}" *ngFor="let single of rule.mnrRestriAppLoc let i = index; let l = last">
                        <div *ngIf="i == 0"><h4 class="minititle">{{'mnrRestriAppLoc'|translate}}</h4></div>
                        <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.mnrRestriAppLoc[i-1] && rule.mnrRestriAppLoc[i-1].textType != single.textType)">{{single.textType|translate}}</div>
                        <div [innerHTML]="toTextMini(single.freeText)"></div>
                        <hr *ngIf="l"/>
                      </div>


                      <div [ngClass]="{'mb-3': rule.mnrMonLoc[i+1] && rule.mnrMonLoc[i+1].textType != single.textType}" *ngFor="let single of rule.mnrMonLoc let i = index; let l = last">
                        <div *ngIf="i == 0"><h4 class="minititle">{{'mnrMonLoc'|translate}}</h4></div>
                        <div class="bold pt-1 mb-1" *ngIf="i == 0 || (rule.mnrMonLoc[i-1] && rule.mnrMonLoc[i-1].textType != single.textType)">{{single.textType|translate}}</div>
                        <div [innerHTML]="toTextMini(single.freeText)"></div>
                        <div class="pt-1 pl-2 small mb-3" *ngIf="single.infoType">{{single.infoType}}: {{rule.numberCategory+single.infoType|translate}}</div>
                        <hr *ngIf="l"/>
                      </div>
                      <div *ngIf="rule.noCategoryRestriction">
                        <div class="mb-2">

                          <div>{{rule.noCategoryRestriction.freeText}}</div>
                        </div>
                      </div>

                    </mat-tab>

                    <mat-tab label="JSON">
                      <code>
                        <pre>
                          {{originalRulesResponse|json}}
                        </pre>
                      </code>
                    </mat-tab>

                  </mat-tab-group>
                </div>
              </div>
              <!--
              <div *ngFor="let penalty of reservation.penalties">
                <div [innerHTML]="toText(penalty)"></div>
              </div>
               -->
            </div>
            <hr/>
            <div class="text-right" *ngIf="!deleting && reservation">
              <button type="button" mat-button (click)="togglePenaltiesModal()">{{'CLOSE'|translate}}</button>
            </div>
          </div>
        </div>
      </div>

      <hr *ngIf="reservation.tariffes"/>
      <div class="row" *ngIf="reservation.tariffes && reservation.tariffes.length>0">
        <div class="col-lg-4 col-md-6 col-12" *ngFor="let tariff of reservation.tariffes; let t = index">
          <div class="uiFare" [ngClass]="{'selectedRate':tariff.fare.amount === reservation.tariff.fare.amount}">
            <span class="material-icons absoluteCheck" *ngIf="tariff.fare.amount === reservation.tariff.fare.amount">
              radio_button_checked
            </span>
            <span (click)="toggleTariffConfirm(tariff)" class="pointer material-icons absoluteCheck" *ngIf="tariff.fare.amount != reservation.tariff.fare.amount">
              radio_button_unchecked
            </span>
            <div class="fareNameNew">
              <span *ngFor="let class of tariff.details;let l = last;">
                <span>{{class.name||class.tariffClass}}</span> <span *ngIf="!l"> + </span>
              </span>
              <app-policy-alerts-icon [arrayOfAlerts]="tariff.arrayOfAlerts"></app-policy-alerts-icon>
            </div>
            <div class="dialogFareNew">
              <div class="bagInfo">
                <div *ngIf="!reservation.lowCost" class="mb-1">
                  {{'CABINS'|translate}}:
                  <span *ngFor="let class of tariff.bookingClasses;let l = last;">
                    <span class="singleClass" [ngClass]="{'mr-1': !l}">{{class}}</span> <!-- <span *ngIf="!l"> -> </span> -->
                  </span>
                </div>
                <div *ngIf="tariff.passengers.ADT">
                  <span class="material-icons"> work </span>
                  {{'BAG_FOR_ADULT'|translate}}:
                  <span *ngFor="let bag of tariff.passengers.ADT.baggage; let i = index;let l = last">
                    <span *ngIf="l && i != 0"> - </span>
                    {{bag.allowance}}
                    <span *ngIf="bag.allowance < 10">
                      BAG
                    </span>
                    <span *ngIf="bag.allowance >= 10">
                      Kg
                    </span>
                  </span>
                </div>
                <div *ngIf="tariff.passengers.CHD && reservation.tariff.passengers.CHD.baggage.length>0">
                  <span class="material-icons">
                    work
                  </span>
                  {{'BAG_FOR_CHILD'|translate}}:
                  <span *ngFor="let bag of tariff.passengers.CHD.baggage; let i = index;let l = last">
                    <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                    <span *ngIf="bag.allowance < 10">
                          BAG
                    </span>
                    <span *ngIf="bag.allowance >= 10">
                      Kg
                    </span>
                  </span>
                </div>
                <div *ngIf="tariff.passengers.INF && tariff.passengers.INF.baggage.length>0">
                  <span class="material-icons">
                    work
                  </span>
                  {{'BAG_FOR_INFANT'|translate}}:
                  <span *ngFor="let bag of tariff.passengers.INF.baggage; let i = index;let l = last">
                    <span *ngIf="l && i != 0"> - </span>
                    {{bag.allowance}}
                    <span *ngIf="bag.allowance < 10">
                        BAG
                    </span>
                    <span *ngIf="bag.allowance >= 10">
                      Kg
                    </span>
                  </span>
                </div>
              </div>
              <div class="bottomActions">
                <span *ngIf="!tariff.refundable">
                  <span class="material-icons greytxt">
                    cancel
                  </span>
                  {{'NO_REFOUND'|translate}}
                </span>
                <span *ngIf="tariff.refundable">
                  <span class="material-icons greentxt">
                    check_circle
                  </span> {{'YES_REFOUND'|translate}}
                </span>
                <span class="bottomInfoBtn" (click)="openBottomSheet(tariff)">
                  {{'FARE_INFO'|translate}}
                </span>
                <span class="bottomInfoBtn" (click)="openDialogRules(tariff)">
                  {{'PENALTIES'|translate}}
                </span>
                <span class="material-icons yellow-text" matTooltip="Negotiated fare"
                      *ngIf="tariff.negotiated && !mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt">
                 star
                </span>
                <span class="material-icons yellow-text" matTooltip="Discount fare"
                      *ngIf="(mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) && tariff.negotiated">
                  star
                </span>
              </div>
              <!--
              <div class="farePrice" (click)="toggleTariffConfirm(tariff)">
                {{tariff.fare.amount|number:'.2-2'}}€
              </div> -->
              <div class="farePrice" (click)="toggleTariffConfirm(tariff)">
                <span *ngIf="!tariff.negotiated">
                  {{tariff.fare.amount|number:'.2-2'}}€
                </span>
                <span *ngIf="(mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) && tariff.negotiated">
                  <del>{{tariff.fare.publicAmount|number:'.2-2'}}€</del>
                  <span class="material-icons">
                    arrow_right_alt
                  </span>
                  {{tariff.fare.publicAmount - (tariff.fare.fees||0)|number:'.2-2'}}€
                </span>
                <span *ngIf="!mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt && tariff.negotiated && !priceservice.isNegoHidden()">
                  {{tariff.fare.amount + (tariff.fare.fees||0)|number:'.2-2'}}€
                </span>
                <span *ngIf="!mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt && tariff.negotiated && priceservice.isNegoHidden()">
                  {{tariff.fare.publicAmount|number:'.2-2'}}€
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="currentTab=='PNR'">

      <app-tst [reservationId]="reservation._id"></app-tst>
    </div>
    <div *ngIf="currentTab=='SEAT_MAP'">

      <app-seatmap (someUpdate)="reloadFromSeatMap()" [reservation]="reservation" [passengers]="reservation.passengers" [flights]="flights" [reservationId]="reservation._id" [seatsSelections]="reservation.seats"></app-seatmap>
    </div>
    <div *ngIf="currentTab=='ANCILLARY'">

      <app-ancillary (someUpdate)="reloadFromAncillary()" [passengers]="reservation.passengers" [reservation]="reservation" [flights]="flights" [reservationId]="reservation._id"></app-ancillary>
    </div>
    <div *ngIf="currentTab=='BAGGAGES' && reservation.lowCost">

      <div *ngIf="reservation.services.length>0">
        <div *ngFor="let service of reservation.services">
          {{reservation.passengers[service.passenger].name}} {{reservation.passengers[service.passenger].surname}} {{service.type}} {{service.price|number:'.2-2'}} {{service.currency}}
        </div>
      </div>
    </div>
    <div *ngIf="currentTab=='PAYMENT'">

      <mat-tab-group mat- mat-align-tabs="start" (selectedTabChange)="onPaymentTabChanged($event);" mat-stretch-tabs="false">
        <mat-tab *ngIf="reservation.company.services.paymentMethods" label="{{'FOPS'|translate}}">
          <br/>
          <app-methods [id]="reservation.company._id" [reservation]="reservation" [resType]="'flight'"></app-methods>
        </mat-tab>
        <mat-tab *ngIf="reservation.company.services.creditCards" label="{{'CREDIT_CARDS'|translate}}"> <!-- && canBook() -->
          <br/>
          <app-fops [id]="reservation.company._id" [reservation]="reservation"></app-fops>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div *ngIf="currentTab=='ATC.TAB' && mainComponent.loggedUser.role.superAdmin && !this.appComponent.isGat">
        <app-pnratc [pnr]="reservation" [pnrId]="reservation._id"></app-pnratc>
    </div>
    <div *ngIf="currentTab=='PNRHISTORY'">
      <app-pnrhistory [pnrId]="reservation._id"></app-pnrhistory>
    </div>
    <div *ngIf="currentTab=='FARE'" class="marginbottom20 padding10">

      <div *ngIf="this.tarifLowData.length === 0" class="row mt-2">
        <h3 class="text-center">{{'NO_FARE' | translate}}</h3>
      </div>
      <div *ngIf="this.tarifLowData.length > 0" class="mt-2 row marginbottom20 padding10">
        <div *ngFor="let t of this.tarifLowData; let i = index;" class="col-6 pb-2">
          <div *ngIf="i === 0" class="text-center pb-2 mb-2">{{'POST_BOOKING.OUTWARD_TARIF'|translate}}</div>
          <div *ngIf="i === 1" class="text-center pb-2 mb-2">{{'POST_BOOKING.OUT_RET_TARIF'|translate}}</div>
          <div class="col-12 card h-100">
            <div  class="p-2 selectedTarifsCard">
              <div>
                <h4 class="text-center">{{t.supplier}}</h4>
                <h4 class="text-center">{{t.tarif.name}}</h4>
                <h4 *ngIf="t.tarif.subName" class="text-left mb-2 pl-2">{{t.tarif.subName}}</h4>
                <hr style="color: #0c5593; height: 2px;">
                <div *ngFor="let d of t.tarif.details" class="row">
                  <div class="col-12 text-left pl-2">
                    <mat-icon>{{d.icon || ''}}</mat-icon>
                    <span class="pl-2 mt-2">
                      {{d.name}} {{d.description}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="currentTab=='FARE_RULES'">
      <app-tf-fare-rules [fareRules]="reservation.tfFareInfo || []"></app-tf-fare-rules>
    </div>
    <div *ngIf="currentTab=='POST_BOOKING.CHANGE_BOOK' && modifyEnabled && changesLowCost && changesLowCost.message !== 'modifiche non ammesse'">
      <app-change-list [id]="reservation._id" [reservation]="reservation" [changesLowCost]="changesLowCost"></app-change-list>
    </div>
    <div *ngIf="currentTab=='POST_BOOKING.CHANGE_BOOK' && modifyEnabled && changesLowCost && changesLowCost.message === 'modifiche non ammesse'">
      <!--{{"POST_BOOKING.CHANGE_BOOK"|translate}}-->
      <p class="text-center">{{changesLowCost.message}}</p>
      <hr/>
    </div>
  </div>

  <div class="row buttonrow" *ngIf="reservation && mainComponent.loggedUser.role.superAdmin ||  (reservation && reservation.company.services.adminPnr && mainComponent.loggedUser.role.company)"> <!-- (reservation && mainComponent.company.cau && mainComponent.loggedUser.role.company) -->

    <div class="col-md-12 mb-3 reservationChanges">
      <app-admin-reservation-changes (update)="getPnr(pnrId)" [reservation]="reservation" [type]="'flight'"></app-admin-reservation-changes>
    </div>
    <div class="col-md-4">
      <mat-card appearance="outlined">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>{{'ADMIN_ACTIONS'|translate}}</mat-card-title>
          <mat-card-subtitle>{{mainComponent.loggedUser.surname}} {{mainComponent.loggedUser.name}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field class="full-width">
            <input matInput placeholder="{{'ASSIGN'|translate}}" aria-label="Carrier" (ngModelChange)="searchUser($event)" [(ngModel)]="text" [matAutocomplete]="users">
            <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #users="matAutocomplete" (optionSelected)="getUser($event.option.value)" (closed)="selectFirst()">
              <mat-option *ngFor="let user of userList" [value]="user">
                <span>{{user.name}} {{user.surname}}</span>
                <span class="material-icons">
                  arrow_right_alt
                </span>
                <small>{{user.company.name}}</small>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>LOCATOR</mat-label>
            <input matInput type="text" [(ngModel)]="locator">
            <button *ngIf="locator" matSuffix mat-icon-button aria-label="Clear" (click)="locator=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Time limit</mat-label>
            <input matInput placeholder="dd/mm/yyyy hh:mm" type="datetime-local" [(ngModel)]="newTimeLimit">
          </mat-form-field>

          <mat-slide-toggle [(ngModel)]="stopUpdateTimeLimit">Stop Update Time Limit</mat-slide-toggle><br/>
          <span class="smalltext">
            * Spostando in avanti il timelimit questo verrà sovrascritto con il più stringente, se selezioni questo tag il timelimit non verrà
            più ciclato e non riceverà aggiornamenti.
          </span>

          <mat-form-field class="full-width">
            <mat-label>STATUS</mat-label>
            <mat-select [(ngModel)]="status">
              <mat-option value="init">
                Non finalizzato
              </mat-option>
              <mat-option value="linked">
                Linked
              </mat-option>
              <mat-option value="issued">
                Emesso
              </mat-option>
              <mat-option value="cancelled">
                Cancellato
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-stroked-button (click)="updateReservation()">Update</button>
          <button *ngIf="reservation.paper" color="warn" mat-stroked-button (click)="updateDossier()">Update dossier</button>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-8" *ngIf="reservation.retrieve">
      <app-orderflights [flights]="reservation.retrieve.flights" [locator]="reservation.locator" [id]="reservation._id" (updatePnr)="reloadFromSeatMap()"></app-orderflights>
    </div>
  </div>
</div>
<!--
<div *ngIf="reservation && !checkPolicy() && reservation.approvalId">
  <app-search-information [id]="reservation.approvalId"></app-search-information>
</div>
-->

<div class="modal fade" id="repricePnrModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="repricePnrModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div *ngIf="reservation" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="repricePnrModalTitle">{{'CONFIRM_BUY_RESERVATION'|translate}} ?</h5>
        <button *ngIf="!booking" type="button" class="close" (click)="toggleRepriceModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <div class="mb-3 p-2 alert-warning" *ngIf="!canBook() && !appComponent.isB2B && !appComponent.isSbt">
          <div *ngIf="this.authForCarrier.feePerPax && this.authForCarrier.feePerPax > 0">
            L'emissione per questo vettore ha un costo aggiuntivo di {{this.authForCarrier.feePerPax| number:'.2-2'}}€ per passeggero.<br/>
          </div>
          Non è possibile emettere autonomamente questa prenotazione, richiedi l'emissione per inviarla in coda manuale. Sarà finalizzata in breve tempo dagli operatori di biglietteria.

          <div *ngIf="this.authForCarrier.feePerPax && this.authForCarrier.feePerPax > 0">Il costo aggiuntivo è di {{reservation.passengers.length * this.authForCarrier.feePerPax| number:'.2-2'}}€</div>
        </div>

        <div class="mb-3" *ngIf="reprice">
          <div *ngIf="(reservation.fare.amount !== reprice.fare.amount) || (reprice.fare.ccFees && (reprice.fare.ccFees !== 0))">
            <p>{{'PRICE_HAS_CHANGED'|translate}}<p>
            <p>{{'OLD_PRICE'|translate}} {{reservation.fare.amount | number:'.2-2'}}€</p>
            <p>{{'ACTUAL_PRICE'|translate}} {{reprice.fare.amount + reprice.fare.ccFees | number:'.2-2'}}€</p>
          </div>
          <div *ngIf="(reservation.fare.amount === reprice.fare.amount) && reprice.fare.ccFees && (reprice.fare.ccFees === 0)">
            {{'NO_PRICE_CHANGE'|translate}}
          </div>
          <div *ngIf="(reservation.fare.amount === reprice.fare.amount) && !reprice.fare.ccFees">
            {{'NO_PRICE_CHANGE'|translate}}
          </div>
        </div>
        <!-- <div>{{'DELETE_RESERVATION_TEXT'|translate}}</div> -->
      </div>

      <hr/>

      <div class="text-right" *ngIf="!booking && reservation && !reservation.lowCost">
        <button type="button" mat-button (click)="toggleRepriceModal()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="bookReservation()" *ngIf="canBook() || (appComponent.isB2B || appComponent.isSbt)">
          <span>{{'BUY_RESERVATION'|translate}}</span>
        </button>
        <button type="button" mat-button (click)="bookReservation()" *ngIf="!canBook() && !appComponent.isB2B && !appComponent.isSbt"> <!-- (click)="openBottomSheetEmission()" -->
          <span>{{'ASK_EMISSION'|translate}}</span>
        </button>
      </div>
      <div class="text-right" *ngIf="booking">
        <div class="mb-2" >{{'LOADING'|translate}}</div>
        <mat-progress-bar style="max-width: 200px;display:inline-block;" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deletePnrModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="deletePnrModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deletePnrModalTitle">{{'DELETE_RESERVATION'|translate}} ?</h5>
        <button *ngIf="!deleting" type="button" class="close" (click)="toggleDeleteModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3">{{'DELETE_RESERVATION_QUESTION'|translate}}</div>
        <!-- <div>{{'DELETE_RESERVATION_TEXT'|translate}}</div> -->
      </div>

      <hr/>

      <div class="text-right" *ngIf="!deleting && reservation">
        <button type="button" mat-button (click)="toggleDeleteModal()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="cancelReservation()">
          <span *ngIf="reservation.status=='linked' || reservation.status=='expired'">{{'DELETE_RESERVATION'|translate}}</span>
          <span *ngIf="reservation.status=='issued'">{{'VOID_RESERVATION'|translate}}</span>
        </button>
      </div>
      <div class="text-right" *ngIf="deleting">
        <div class="mb-2" >{{'LOADING'|translate}}</div>
        <mat-progress-bar style="max-width: 200px;display:inline-block;" mode="indeterminate"></mat-progress-bar>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="TariffConfirmModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="deletePnrModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="TariffConfirmModalTitle">{{'CONFIRM_CHANGE'|translate}} ?</h5>
        <button type="button" class="close" (click)="closeTariffConfirm()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3">{{'CONFIRM_TARIFF_SELECTION'|translate}}</div>
      </div>
      <hr/>
      <div class="text-right" *ngIf="!loadingChangeTariff">
        <button type="button" mat-button (click)="closeTariffConfirm()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="confirmTariffSelection()">{{'CONFIRM'|translate}}</button>
      </div>
      <div class="text-right" *ngIf="loadingChangeTariff">
        {{'CHANGING_TARIFF'|translate}}
        <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="tfScheduleConfirm" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="deletePnrModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >{{'CONFIRM_CHANGE_RESULT'|translate}}</h5>
        <button type="button" class="close" (click)="toggleTFScheduleConfirm()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3">{{scheduleChangesTFResult}}</div>
      </div>
      <hr/>
    </div>
  </div>
</div>
<div *ngIf="reservation && reservation.paper" class="modal fade" id="RemoveDossierModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="RemoveDossierModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="RemoveDossierModalTitle">{{'REMOVE_FROM_DOSSIER'|translate}} ?</h5>
        <button type="button" class="close" (click)="toggleRemoveDossierModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3">{{'CONFIRM_REMOVE_DOSSIER'|translate}}</div>
      </div>
      <hr/>
      <div class="text-right" *ngIf="!loadingChangeTariff">
        <button type="button" mat-button (click)="toggleRemoveDossierModal()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="confirmDossierRemove()">{{'CONFIRM'|translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="RemoveWheelchairModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="RemoveWheelchairModal"
     aria-hidden="true">
  <div class="modal-dialog modal-sm" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="RemoveWheelchairModalTitle">{{'REMOVE_WHEELCHAIR'|translate}} ?</h5>
        <button type="button" class="close" (click)="toggleRemoveWheelchairModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="text-right" *ngIf="!loadingWheelchair else loadingBar">
        <button type="button" mat-button (click)="toggleRemoveWheelchairModal()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="confirmWheelChairRemove()">{{'CONFIRM'|translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="RemoveFq" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="RemoveFq"
     aria-hidden="true">
  <div class="modal-dialog modal-sm" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="RemoveFqTitle">{{'REMOVE_FREQUENT_FLYER'|translate}} ?</h5>
        <button type="button" class="close" (click)="toggleRemoveFq()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="text-right" *ngIf="!loadingFq else loadingBar">
        <button type="button" mat-button (click)="toggleRemoveFq()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="confirmFqRemove()">{{'CONFIRM'|translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="AddWheelchairModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="AddWheelchairModal"
     aria-hidden="true">
  <div class="modal-dialog modal-md" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AddWheelchairModalTitle">{{'ADD_WHEELCHAIR'|translate}} ?</h5>
        <button type="button" class="close" (click)="toggleAddWheelchairModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <mat-select  [(ngModel)]="wheelchair" placeholder="{{'NO_WHEELCHAIR' | translate}}">
          <mat-option [value]="null" selected>{{'NO_WHEELCHAIR' | translate}}</mat-option>
          <mat-option *ngFor="let wheelOption of WHEELCHAIR_OPTIONS" [value]="wheelOption">{{ wheelOption | translate }}</mat-option>
        </mat-select>
      </div>
      <div class="text-right" *ngIf="!loadingWheelchair else loadingBar">
        <button type="button" mat-button (click)="toggleAddWheelchairModal()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button [disabled]="!wheelchair" (click)="confirmWheelChairAdd()">{{'CONFIRM'|translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="AddFqFModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="AddFqFModal"
     aria-hidden="true">
  <div class="modal-dialog modal-md" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AddFqFModalTitle">{{'ADD_FREQUENT_FLYER'|translate}} ?</h5>
        <button type="button" class="close" (click)="toggleAddFqModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="fqPax">
        <div *ngIf="fqPax.frequentFlyers.length > 0">
          <div *ngFor="let feq of fqPax.frequentFlyers; let f = index">
            <span class="buttonfq" (click)="selectFqForPax(feq, f)" [ngClass]="{'selFq': feq.selected}">
              <span class="mr-2" style="vertical-align:middle">
                <img *ngIf="feq.iata && feq.iata.length == 2" class="freqLogo" src="assets/img/airline/icon/{{feq.iata.toUpperCase()}}.png"/>
              </span>
                {{feq.iata}}-{{feq.number}}
            </span>
          </div>
        </div>
        <div class="text-right" *ngIf="!loadingFq else loadingBar">
          <button type="button" mat-button (click)="toggleAddFqModal()">{{'ABORT'|translate}}</button>
          <button type="button" mat-button [disabled]="someFqsel()" (click)="confirmFqAdd()">{{'CONFIRM'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="((appComponent.isB2B || appComponent.isSbt) || mainComponent.loggedUser.role.superAdmin) && !appComponent.isGat" class="modal fade" id="travelListModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="travelListModal" aria-hidden="true">
  <div class="modal-dialog-scrollable modal-dialog modal-xl" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addGuestModalTitle">
          {{'ADD_TO_FOLDER'|translate}}
        </h5>
        <button type="button" class="close" (click)="toggleAddtoTravel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-dossierlist page="flight" [idFlight]="pnrId" (close)="closeAddtoTravel()"></app-dossierlist>
      </div>
      <hr/>
      <div class="text-right buttonrow">
        <button type="button" mat-button (click)="toggleAddtoTravel()">{{'CLOSE'|translate}}</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="reservation" class="modal fade" id="EditNotesModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="EditNotesModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'ADD_COSTUMER_NOTES'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <mat-form-field class="full-width">
            <mat-label>{{'NOTES'|translate}}</mat-label>
            <textarea matInput placeholder="Ex. Check-in at 10:00 A.M." [(ngModel)]="reservationNotes"></textarea>
          </mat-form-field>
          <div class="" *ngIf="mainComponent.loggedUser.company.type === 'A' && (!mainComponent.appcomponent.isSbt && !mainComponent.appcomponent.isB2B)">
            <hr/>
            <mat-checkbox class="example-margin" [(ngModel)]="changePrice">{{'CHANGE_PRICE'|translate}}</mat-checkbox>
          </div>
          <div *ngIf="changePrice && mainComponent.loggedUser.company.type === 'A' && (!mainComponent.appcomponent.isSbt && !mainComponent.appcomponent.isB2B)">
            <mat-form-field class="full-width">
              <mat-label>{{'NEW_PRICE'|translate}}</mat-label>
              <input matInput type="number" [(ngModel)]="priceChange"/>
            </mat-form-field>
            <div>
              <div class="" *ngIf="reservation.negotiated">
                <span *ngIf="isLowPrice(priceservice.returnPrice(reservation.fare.publicAmount - (reservation.fare.fees||0) + (reservation.fare.services||0)))">
                  <span class="material-icons">warning_amber</span> {{'PRICE_IS_LOWER'|translate}}
                </span>
              </div>
              <div class="" *ngIf="!reservation.negotiated">
                <span *ngIf="isLowPrice(priceservice.returnPrice(reservation.fare.amount + (reservation.fare.services||0)))">
                  <span class="material-icons">warning_amber</span> {{'PRICE_IS_LOWER'|translate}}
                </span>
              </div>
            </div>
          </div>
          <hr/>
        </div>
        <div class="row">
          <div class="col-md-6">
          </div>
          <div class="col-md-6 text-right">
            <button mat-button (click)="saveNotes()">{{'SAVE_NOTES'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="reservation" class="modal fade" id="PrintModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="PrintModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'PRINT'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-2" *ngIf="!reservation.lowCost && (reservation.status === 'issued' || reservation.status === 'flown')">
          <mat-checkbox class="example-margin" [(ngModel)]="showTicket">{{'SHOW_TICKET'|translate}}</mat-checkbox>
        </div>
        <div class="mb-2">
          <section class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="showNotes">{{'SHOW_NOTES'|translate}}</mat-checkbox>
          </section>
        </div>
        <div >
          <div *ngIf="showNotes">
            <mat-form-field class="full-width">
              <mat-label>{{'NOTES'|translate}}</mat-label>
              <textarea matInput placeholder="Ex. Check-in at 10:00 A.M." [(ngModel)]="reservationNotes"></textarea>
            </mat-form-field>
          </div>
          <div class="" *ngIf="mainComponent.loggedUser.company.type === 'A' && (!mainComponent.appcomponent.isSbt && !mainComponent.appcomponent.isB2B)">
            <hr/>
            <section class="example-section">
              <mat-checkbox class="example-margin" [(ngModel)]="showPrice">{{'SHOW_PRICE'|translate}}</mat-checkbox>
            </section>
            <hr/>
            <mat-checkbox class="example-margin" [(ngModel)]="changePrice">{{'CHANGE_PRICE'|translate}}</mat-checkbox>
          </div>
          <div *ngIf="changePrice && mainComponent.loggedUser.company.type === 'A' && (!mainComponent.appcomponent.isSbt && !mainComponent.appcomponent.isB2B)">
            <mat-form-field class="full-width">
              <mat-label>{{'NEW_PRICE'|translate}}</mat-label>
              <input matInput type="number" [(ngModel)]="priceChange"/>
            </mat-form-field>
            <div>
              <div class="" *ngIf="reservation.negotiated">
                <span *ngIf="isLowPrice(priceservice.returnPrice(reservation.fare.publicAmount - (reservation.fare.fees||0) + (reservation.fare.services||0)))">
                  <span class="material-icons">warning_amber</span> {{'PRICE_IS_LOWER'|translate}}
                </span>
              </div>
              <div class="" *ngIf="!reservation.negotiated">
                <span *ngIf="isLowPrice(priceservice.returnPrice(reservation.fare.amount + (reservation.fare.services||0)))">
                  <span class="material-icons">warning_amber</span> {{'PRICE_IS_LOWER'|translate}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
          </div>
          <div class="col-md-6 text-right">
            <button mat-button (click)="saveNotes()">{{'SAVE_NOTES'|translate}}</button>
            <button mat-button (click)="print()">
              {{'PRINT'|translate}} <mat-icon>print</mat-icon>
            </button>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-12">
            Preview
            <div class="padding20" style="overflow-x:auto;">
              <div *ngIf="!loading && reservation" id="toPrint">
                <div style="margin:0 10px;">

                  <!-- Header -->

                  <div class="row">
                    <div class="col-4">
                      <img class="companyImage" [src]="reservation.company.image"/>
                    </div>

                    <div class="col-4"></div>

                    <div class="col-4">
                      <div class="mb-1">
                        <mat-icon class="mr-2">store</mat-icon>
                        {{reservation.company.name}}
                      </div>
                      <div class="mb-1">
                        <mat-icon class="mr-2">call</mat-icon>
                        {{reservation.company.phoneNumber}}
                      </div>
                      <div class="mb-1">
                        <mat-icon class="mr-2">forward_to_inbox</mat-icon>
                        {{reservation.company.email}}
                      </div>
                    </div>
                  </div>
                  <hr/>

                  <!-- Reservation Number -->

                  <h5 class="mb-3">{{'RESERVATION'|translate}} N°
                    <div *ngIf="reservation.carriersLocators && reservation.carriersLocators.length > 0 && !reservation.lowCost" class="d-inline-block" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
                      <span class="" *ngFor="let locator of reservation.carriersLocators; let l = last">{{locator.carrier}}: {{locator.locator}} <span *ngIf="!l"> | </span></span>
                    </div>
                    <div *ngIf="reservation.carriersLocators && reservation.carriersLocators.length > 0 && reservation.lowCost && reservation.status != 'init'" class="" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
                      <span>{{reservation.carriersLocators[0].locator}}</span>
                    </div>
                  </h5>
                  <div class="row">

                    <!-- PNR Details -->

                    <div class="col-3">
                      <div *ngIf="reservation.marketingCarrier" class="mb-1">{{'VECTOR'|translate}}</div>
                      <div *ngIf="reservation.marketingCarrier" class="reservationimage mb-1">
                        <img src="https://application.flyleo.it/assets/img/airline/icon/{{reservation.marketingCarrier}}.png"> {{iataComplete.returnCarrier(reservation.marketingCarrier)}}
                      </div>
                      <div class="mb-1">{{'LOCATOR'|translate}}: {{reservation.locator}}</div>
                      <div class="mb-1">{{'GENERATED_DATE'|translate}}: {{reservation.createdAt|date:'dd/MM/yyyy' :"+0000"}}</div>
                      <div class="mb-1" *ngIf="reservation.status==='issued'">{{'ISSUED_ON'|translate}}: {{reservation.issuedOn|date:'dd/MM/yyyy': "+0000"}}</div>
                    </div>

                    <!-- Pax Row + Tariffs -->

                    <div class="col-3">
                      <div class="mb-1">{{'PASSENGERS'|translate}}</div>
                      <div *ngIf="reservation.passengers.length>0">
                        <div *ngFor="let passenger of reservation.passengers" class="listpnrsinglepasseng">
                          <span *ngIf="passenger.surname">{{passenger.surname}}</span> <span *ngIf="passenger.name"> {{passenger.name}}</span> <sup> ({{passenger.type}})</sup>
                          <div *ngIf="reservation.status==='issued' && passenger.ticket">
                            <span class="d-block">TKT n°: {{passenger.ticket}} </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Fare -->

                    <div class="col-3 farePart" *ngIf="!reservation.lowCost">
                      <div class="mb-1">{{'FARE'|translate}}</div>
                      <div class="mb-2">
                        <span *ngFor="let class of reservation.tariff.details;let l = last;">
                          <span>{{class.name||class.tariffClass}}</span> <span *ngIf="!l"> + </span>
                        </span>
                      </div>

                      <!-- Bags -->

                      <div class="printFare">
                        <div class="infoBag">
                          <div *ngIf="reservation.tariff.passengers.ADT">
                            <span class="material-icons">
                              work
                            </span>
                            {{'BAG_FOR_ADULT'|translate}}:
                            <span *ngFor="let bag of reservation.tariff.passengers.ADT.baggage; let i = index;let l = last">
                            <span *ngIf="l && i != 0"> - </span>
                              {{bag.allowance}}
                              <span *ngIf="bag.allowance < 10">
                                  BAG
                              </span>
                              <span *ngIf="bag.allowance >= 10">
                                Kg
                              </span>
                            </span>
                          </div>
                          <div *ngIf="reservation.tariff.passengers.CHD && reservation.tariff.passengers.CHD.baggage.length>0">
                            <span class="material-icons">
                              work
                            </span>
                            {{'BAG_FOR_CHILD'|translate}}:
                            <span *ngFor="let bag of reservation.tariff.passengers.CHD.baggage; let i = index;let l = last">
                              <span *ngIf="l && i != 0"> - </span>
                                {{bag.allowance}}
                              <span *ngIf="bag.allowance < 10">
                                  BAG
                              </span>
                              <span *ngIf="bag.allowance >= 10">
                                Kg
                              </span>
                            </span>
                          </div>
                          <div *ngIf="reservation.tariff.passengers.INF && reservation.tariff.passengers.INF.baggage.length>0">
                            <span class="material-icons">
                              work
                            </span>
                            {{'BAG_FOR_INFANT'|translate}}:
                            <span *ngFor="let bag of reservation.tariff.passengers.INF.baggage; let i = index;let l = last">
                              <span *ngIf="l && i != 0"> - </span>
                                {{bag.allowance}}
                              <span *ngIf="bag.allowance < 10">
                                BAG
                              </span>
                              <span *ngIf="bag.allowance >= 10">
                                Kg
                              </span>
                            </span>
                          </div>
                        </div>
                        <div class="infoTar">
                          <span *ngIf="!reservation.tariff.refundable">
                            <span class="material-icons">
                              cancel
                            </span>
                            {{'NO_REFOUND'|translate}}
                          </span>
                          <span *ngIf="reservation.tariff.refundable">
                            <span class="material-icons">
                              check_circle
                            </span> {{'YES_REFOUND'|translate}}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- Price Details-->

                    <div class="col-3" *ngIf="!reservation.negotiated && (!changePrice || !priceChange) && !reservation.lowCost && showPrice">
                      <div class="mb-1">{{'PRICE_DETAILS_PAX'|translate}}</div>
                      <div class="row">
                        <div class="col-3">ADT</div>
                        <div class="col-9">
                          Total: {{reservation.paxFare.adt.amount|number:'.2-2'}}€<br/>
                          Tax (included): {{reservation.paxFare.adt.tax|number:'.2-2'}}€
                        </div>
                      </div>
                      <div class="row" *ngIf="reservation.paxFare.chd.amount">
                        <div class="col-3">CHD</div>
                        <div class="col-9">
                          Total: {{reservation.paxFare.chd.amount|number:'.2-2'}}€<br/>
                          Tax: {{reservation.paxFare.chd.tax|number:'.2-2'}}€
                        </div>
                      </div>
                      <div class="row" *ngIf="reservation.paxFare.inf.amount">
                        <div class="col-3">INF</div>
                        <div class="col-9">
                          Total: {{reservation.paxFare.inf.amount|number:'.2-2'}}€<br/>
                          Tax: {{reservation.paxFare.inf.tax|number:'.2-2'}}€
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <!-- Itineraries -->

                <div class="row backPrint" [ngClass]="{'mb-2':!l}" *ngFor="let itinerary of reservation.itineraries;let l = last;let i = index;">

                  <div class="col-12">
                    <table class="table table-bordered">
                      <tr>
                        <td class="infoIti" colspan="4">
                          <b>{{iataComplete.returnAirport(itinerary.flights[0].departure.location)}}</b> <sup>{{itinerary.flights[0].departure.location}}</sup>
                          <span class="material-icons">
                            arrow_right_alt
                          </span>
                          <b>{{iataComplete.returnAirport(itinerary.flights[itinerary.flights.length-1].arrival.location)}}</b> <sup>{{itinerary.flights[itinerary.flights.length-1].arrival.location}}</sup>
                          <div class="maincabin">
                            <span *ngIf="reservation.cabins[i] === 'Y'">Economic</span>
                            <span *ngIf="reservation.cabins[i]=='M'">Economic standard</span>
                            <span *ngIf="reservation.cabins[i]=='W'">Economic premium</span>
                            <span *ngIf="reservation.cabins[i]=='C'">Business</span>
                            <span *ngIf="reservation.cabins[i]=='F'">First</span>
                          </div>
                          <div>
                            <span class="material-icons" style="font-size: 16px;margin-right: 5px">
                              flight_takeoff
                            </span>
                            <b>{{itinerary.flights[0].departure.dateTime | date:'dd/MM/yyyy':"+0000"}}
                              -
                              {{returnZeroTime(itinerary.flights[0].departure.hours)}}:{{returnZeroTime(itinerary.flights[0].departure.minutes)}}</b>
                            <span class="material-icons" style="font-size: 16px;margin-left: 10px">
                              schedule
                            </span>
                            {{'FLIGHT_TIME'|translate}}: <b>{{returnMilli(itinerary.flyingTime)}}</b>
                          </div>
                        </td>
                      </tr>
                      <ng-container *ngFor="let flight of itinerary.flights;let f = index; let l = last">
                        <tr>
                          <td style="width:10%">
                            <img class="operatingImage" src="https://application.flyleo.it/assets/img/airline/icon/{{flight.operatingCarrier}}.png">
                          </td>
                          <td style="width:30%">
                            <div>
                              {{flight.departure.date|date:'dd/MM/yyyy':'+0000'}} -
                              {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}}
                            </div>
                            {{iataComplete.returnAirport(flight.departure.location)}} ({{flight.departure.location}})
                          </td>
                          <td style="width:30%">
                            <div>N° {{flight.operatingCarrier}} {{flight.number}}</div>
                            <div>BookingClass: {{flight.bookingClass}}</div>
                          </td>
                          <td style="width:30%">
                            <div>
                              {{flight.arrival.dateTime |date:'dd/MM/yyyy':"+0000"}} -
                              {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}}
                            </div>
                            {{iataComplete.returnAirport(flight.arrival.location)}} ({{flight.arrival.location}})
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4" class="text-center" *ngIf="flight.detail.technicalStop && flight.detail.technicalStop.from > 0">
                            <span class="material-icons iconTech">
                              local_gas_station
                            </span>
                            <span class="vertical">
                              {{'TECHNICAL_STOP_IN'|translate}} {{flight.detail.technicalStop.location}} - {{'DURATION'|translate}}: {{convertMilliseconds(flight.detail.technicalStop)}} - {{'ARRIVAL'|translate}}:{{returnDate(flight.detail.technicalStop.from) | date:'dd MMM - HH:mm'}} - {{'DEPARTURE'|translate}}: {{returnDate(flight.detail.technicalStop.to) | date:'dd MMM - HH:mm'}}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4" class="text-center" *ngIf="!l">
                            {{'STOP'|translate}} in {{iataComplete.returnAirport(flight.arrival.location)}} <sup>{{flight.arrival.location}}</sup> -
                            {{'WAITING'|translate}} {{calculateWaiting(flight.arrival.dateTime, itinerary.flights[f+1].departure.dateTime)}}
                            <span class="alert-danger changeairport" *ngIf="flight.arrival.location != itinerary.flights[f+1].departure.location">
                              {{'CHANGE'|translate}}:
                              {{iataComplete.returnAirport(itinerary.flights[f+1].departure.location)}} <sup>{{itinerary.flights[f+1].departure.location}}</sup></span>
                          </td>
                        </tr>
                      </ng-container>
                    </table>
                  </div>
                </div>

                <div style="margin:0 10px" class="fakeTable">
                  <div class="mb-1">

                  <!--Seat Selection-->

                    <div class="row" *ngFor="let flight of flights; let fl = index;let l = last">
                      <!--
                      <div class="col-8 mb-1" *ngIf="fl == 0">
                        {{'SEATS_SELECTION'|translate}}
                      </div>
                      -->
                      <div class="col-7 text-right">
                        {{iataComplete.returnAirport(flight.departure.location)}} <span class="material-icons">arrow_right_alt</span> {{iataComplete.returnAirport(flight.arrival.location)}}
                      </div>
                      <div class="col-5 text-right">
                        <div *ngFor="let pax of reservation.passengers">
                          <span class="font-weight-bold"  >{{pax.surname}} {{pax.name}}</span> |
                          <span *ngIf="reservation.seats.length > 0 &&  reservation.seats[fl] && reservation.seats[fl].length>0">
                            <span *ngFor="let seat of reservation.seats[fl];let s = index;">
                              <span *ngIf="seat.passenger === pax.ref">
                                {{'SEAT'|translate}} <span class="font-weight-bold">{{seat.seat}}</span><span *ngIf="(!changePrice || !priceChange) && showPrice"> | {{seat.price||0|number:'.2-2'}}€</span>
                              </span>
                            </span>
                          </span>
                          <span *ngIf="reservation.seats.length > 0 && reservation.seats[fl] && reservation.seats[fl].length === 0">
                            {{'NO_SEAT'|translate}}<span *ngIf="(!changePrice || !priceChange) && showPrice"> | {{0|number:'.2-2'}}€</span>
                          </span>
                          <br/>
                          <span *ngIf="reservation.services.length == 0">
                            {{'NO_SERVICES'|translate}}<span *ngIf="(!changePrice || !priceChange) && showPrice"> | {{0|number:'.2-2'}}€</span>
                          </span>
                          <span *ngIf="reservation.services.length>0">
                            <span *ngFor="let service of reservation.services;let s = index;">
                              <span *ngIf="service.passenger === pax.ref && service.flight === flight.ref">
                                {{'SERVICE'|translate}} {{service.type}}<span *ngIf="(!changePrice || !priceChange) && showPrice"> | {{service.price||0|number:'.2-2'}}€</span>
                              </span>
                            </span>
                          </span>

                        </div>
                        <!--
                        <span class="recoprice" *ngIf="reservation.fare.fees ">
                          {{'FEES'|translate}} {{reservation.fare.fees|number:'.2-2'}}
                                    <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                          <span *ngIf="!reservation.fare.currency">€</span>
                        </span>
                        -->
                      </div>
                      <div class="col-12"><hr/></div>
                    </div>
                  </div>

                  <!-- Services -->

                  <div class="row mb-1" *ngIf="reservation.fare.services && (!changePrice || !priceChange) && showPrice">
                    <div class="col-7">
                    </div>
                    <div class="col-5 text-right">
                      <hr/>
                      <div class="recoprice">
                        {{'SERVICES'|translate}} {{reservation.fare.services|number:'.2-2'}}
                        <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                        <span *ngIf="!reservation.fare.currency">€</span>
                      </div>
                    </div>
                  </div>
                  <!--
                  <div class="row mb-1">
                    <div class="col-7"></div>
                    <div class="col-5 text-right">
                      <div class="recoprice">
                        {{'TAX'|translate}} {{reservation.fare.tax|number:'.2-2'}}€
                      </div>
                    </div>
                  </div>
                  -->
                  <div class="row mb-2">
                    <div class="col-7"></div>

                    <!-- Flight Price -->

                    <div class="col-5 text-right" *ngIf="(!changePrice || !priceChange) && showPrice">
                      <!-- <hr/> -->
                      <div *ngIf="!mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt">
                        <div class="recoprice" *ngIf="!reservation.negotiated">
                          {{'FLIGHT'|translate}} {{priceservice.returnPrice(reservation.fare.amount)|number:'.2-2'}}
                          <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                          <span *ngIf="!reservation.fare.currency">€</span>
                        </div>
                        <div class="recoprice" *ngIf="reservation.negotiated && !priceservice.isNegoHidden()">
                          {{'FLIGHT'|translate}} {{priceservice.returnPrice(reservation.fare.amount + (reservation.fare.fees||0))|number:'.2-2'}}€
                        </div>
                        <div class="recoprice" *ngIf="reservation.negotiated && priceservice.isNegoHidden()">
                          {{'FLIGHT'|translate}} {{priceservice.returnPrice(reservation.fare.publicAmount)|number:'.2-2'}}€
                        </div>
                      </div>
                      <div *ngIf="mainComponent.appcomponent.isB2B || mainComponent.appcomponent.isSbt">
                        <div class="recoprice" *ngIf="reservation.negotiated">
                          {{'FLIGHT'|translate}} {{priceservice.returnPrice(reservation.fare.publicAmount)|number:'.2-2'}}€
                        </div>
                        <div class="recoprice" *ngIf="reservation.negotiated">
                          {{'DISCOUNT'|translate}} {{reservation.fare.fees|number:'.2-2'}}€
                        </div>
                        <div class="recoprice" *ngIf="!reservation.negotiated">
                          {{'FLIGHT'|translate}} {{priceservice.returnPrice(reservation.fare.amount)|number:'.2-2'}}
                          <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                          <span *ngIf="!reservation.fare.currency">€</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-5 text-right" *ngIf="changePrice && priceChange">
                      <hr/>
                      {{'FLIGHT'|translate}} {{priceChange|number:'.2-2'}}
                      <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                      <span *ngIf="!reservation.fare.currency">€</span>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-7"></div>
                    <div class="col-5 text-right">

                      <!-- Total Price -->

                      <hr *ngIf="showPrice"/>
                      <div class="font-weight-bold" *ngIf="(!changePrice || !priceChange) && showPrice">
                        <div class="recoprice" *ngIf="!reservation.negotiated">
                          {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.amount + reservation.fare.services)|number:'.2-2'}}
                          <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                          <span *ngIf="!reservation.fare.currency">€</span>
                        </div>
                        <div *ngIf="!mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt && reservation.negotiated">
                          <div class="recoprice" *ngIf="reservation.negotiated && !priceservice.isNegoHidden()">
                            {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.amount + (reservation.fare.fees||0) + reservation.fare.services)|number:'.2-2'}}€
                          </div>
                          <div class="recoprice" *ngIf="reservation.negotiated && priceservice.isNegoHidden()">
                            {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.publicAmount + reservation.fare.services)|number:'.2-2'}}€
                          </div>
                        </div>
                        <div *ngIf="(mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) && reservation.negotiated">
                          {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.publicAmount + reservation.fare.services - reservation.fare.fees)|number:'.2-2'}}€
                        </div>
                      </div>
                      <div class="font-weight-bold" *ngIf="changePrice && priceChange">
                        {{'TOTAL'|translate}} {{priceChange|number:'.2-2'}}
                        <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                        <span *ngIf="!reservation.fare.currency">€</span>
                      </div>

                    </div>
                  </div>
                </div>
                <!-- NOTES -->
                <div *ngIf="reservationNotes && showNotes">
                  <hr/>
                  <p>{{'NOTES'|translate}}</p>
                  {{reservationNotes}}
                </div>

              <!-- Electronic Ticket -->

                <div class="elTkt" style="font-size:12px;margin:0 10px;" *ngIf="showTicket && (reservation.status === 'issued' || reservation.status === 'flown') && !reservation.lowCost">
                  <hr/>
                  <p class="mb-2 font-weight-bold">ELECTRONIC TICKET RECEIPT</p>
                  <div class="mb-1" *ngFor="let pax of reservation.passengers; let l = last">
                    <div class="row">
                      <div class="col-3">
                        {{'TRAVELER'|translate}} {{pax.surname}} {{pax.name}} {{pax.type}}<br/>
                        {{'TICKET_NUMBER'|translate}}: {{pax.ticket}}
                      </div>
                      <div class="col-9">

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                        {{'BAGGAGES'|translate}}
                      </div>
                      <div class="col-9">
                        <span *ngFor="let bag of reservation.retrieve.tariff.passengers[pax.type].baggage">{{bag.allowance}} {{bag.code}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                        {{'TAX'|translate}}
                      </div>
                      <div class="col-9">
                        <div class="row">
                          <div *ngIf="reservation.retrieve.tariff.passengers[pax.type].fare.taxFields.length==0" class="col-4">
                            {{0|number:'.2-2'}}
                          </div>
                          <div *ngFor="let field of reservation.retrieve.tariff.passengers[pax.type].fare.taxFields" class="col-4">
                            <span>
                              {{field.country}}<!-- {{field.code}}-->: {{field.currency}} {{field.amount|number:'.2-2'}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="!appComponent.isB2B && !appComponent.isSbt">
                      <div class="col-3">
                        {{'FARE'|translate}}
                      </div>
                      <div class="col-9">
                        <span>{{reservation.retrieve.tariff.passengers[pax.type].fare.amount|number:'.2-2'}} EUR</span>
                      </div>
                    </div>
                    <hr *ngIf="!l"/>
                  </div>

                  <div style="font-size:10px">
                    <hr/>
                    NOTICE TO PASSENGERS<br/>
                    Carriage and other services provided bythe carrier are subject to conditions of carriage, which are herebyincorporated byreference. These conditions may be obtained from the issuing carrier. The itinerary/receipt constitutes the
                    passenger ticket for the purpose of article 3 of the Warsaw convention, except where the carrier delivers to the passenger another document complying with the requirements of article 3. NOTICE if the passengers journeyinvolves
                    an ultimate destination or stop in a country other than the country of departure the Warsaw convention may be applicable and the convention governs and in most cases limits the liability of carriers for death or personal injury
                    and in respect of loss of or damage to baggage. See also notices headed advice to international passengers on limitation of liability and ‘notice of baggage liabilitylimitations’ :
                    The carriage of certain hazardous materials, like aerosols, reworks,and ammable liquids, aboard the aircraft is forbidden. Ifyou do not understand these restrictions, further information may be obtained from your airline.
                    <br/><br/>IATA Agency: N. 3827662 6 | Italy
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="reservation" class="modal fade" id="SendMailModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="SendMailModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'SEND_BY_MAIL'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div *ngIf="mainComponent.loggedUser.company.type == 'A'" class="mb-2">
          <div class="mb-2" *ngIf="!reservation.lowCost && (reservation.status === 'issued' || reservation.status === 'flown')">
            <mat-checkbox class="example-margin" [(ngModel)]="showTicket">{{'SHOW_TICKET'|translate}}</mat-checkbox>
          </div>
          <section class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="showPrice">{{'SHOW_PRICE'|translate}}</mat-checkbox>
          </section>
          <section class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="showNotes">{{'SHOW_NOTES'|translate}}</mat-checkbox>
          </section>
          <hr/>
        </div>

        <p class="mb-2">{{'RECIPIENTS'|translate}}</p>
        <div *ngFor="let mail of recipients; let i = index; trackBy:trackByFn">
          <mat-form-field class="full-width">
            <mat-label>Email {{i+1}}</mat-label>
            <input matInput type="email" [(ngModel)]="recipients[i]">
            <button *ngIf="recipients.length>1" matSuffix mat-icon-button aria-label="Clear" (click)="removeRecipient(i)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <hr/>
        <div *ngIf="showNotes">
          <mat-form-field class="full-width">
            <mat-label>{{'NOTES'|translate}}</mat-label>
            <textarea matInput placeholder="Ex. Check-in at 10:00 A.M." [(ngModel)]="reservationNotes">

            </textarea>
          </mat-form-field>
          <hr/>
        </div>
        <div class="row">
          <div class="col-md-6">
            <button mat-button (click)="addRecipient()">{{'ADD_RECIPIENT'|translate}}</button>
          </div>
          <div class="col-md-6 text-right">
            <button mat-button (click)="sendMail()">{{'SEND_BY_MAIL'|translate}}</button>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="regenerateModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="regenerateModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="regenerateModalTitle">{{'REDO_RESERVATION'|translate}}</h5>
        <button *ngIf="!deleting" type="button" class="close" (click)="toggleRegenerate()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <mat-progress-bar *ngIf="askNewFares" mode="buffer" class="mb-2"></mat-progress-bar>
          <div *ngIf="!askNewFares && newFares && !isChurning">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-12" *ngFor="let tariff of newFares; let t = index">
                <div class="fareNameNew">
                <span *ngFor="let class of tariff.details;let l = last;">
                  <span>{{class.name||class.tariffClass}}</span> <span *ngIf="!l"> + </span>
                </span>
                </div>
                <div class="dialogFareNew" [ngClass]="{'selectedRate':tariff.selected === true}">
                  <div class="bagInfo">
                    <div *ngIf="tariff.passengers.ADT">
                      <span class="material-icons">
                        work
                      </span>
                      {{'BAG_FOR_ADULT'|translate}}:
                      <span *ngFor="let bag of tariff.passengers.ADT.baggage; let i = index;let l = last">
                        <span *ngIf="l && i != 0"> - </span>
                          {{bag.allowance}}
                          <span *ngIf="bag.allowance < 10">
                            BAG
                        </span>
                        <span *ngIf="bag.allowance >= 10">
                          Kg
                        </span>
                      </span>
                    </div>
                    <div *ngIf="tariff.passengers.CHD && reservation.tariff.passengers.CHD.baggage.length>0">
                      <span class="material-icons">
                        work
                      </span>
                      {{'BAG_FOR_CHILD'|translate}}:
                      <span *ngFor="let bag of tariff.passengers.CHD.baggage; let i = index;let l = last">
                        <span *ngIf="l && i != 0"> - </span>
                        {{bag.allowance}}
                        <span *ngIf="bag.allowance < 10">
                            BAG
                        </span>
                        <span *ngIf="bag.allowance >= 10">
                          Kg
                        </span>
                      </span>
                    </div>
                    <div *ngIf="tariff.passengers.INF && tariff.passengers.INF.baggage.length>0">
                      <span class="material-icons">
                        work
                      </span>
                      {{'BAG_FOR_INFANT'|translate}}:
                      <span *ngFor="let bag of tariff.passengers.INF.baggage; let i = index;let l = last">
                        <span *ngIf="l && i != 0"> - </span>
                        {{bag.allowance}}
                        <span *ngIf="bag.allowance < 10">
                          BAG
                        </span>
                        <span *ngIf="bag.allowance >= 10">
                          Kg
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="bottomActions">
                    <span *ngIf="!tariff.refundable">
                      <span class="material-icons greytxt">
                        cancel
                      </span>
                      {{'NO_REFOUND'|translate}}
                    </span>
                    <span *ngIf="tariff.refundable">
                      <span class="material-icons greentxt">
                        check_circle
                      </span> {{'YES_REFOUND'|translate}}
                    </span>
                    <span class="bottomInfoBtn" (click)="openBottomSheet(tariff)">
                      {{'FARE_INFO'|translate}}
                    </span>
                    <span class="material-icons yellow-text" matTooltip="Negotiated fare"
                          *ngIf="tariff.negotiated && !mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt">
                     star
                    </span>
                    <span class="material-icons yellow-text" matTooltip="Discount fare"
                          *ngIf="(mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) && tariff.negotiated">
                      star
                    </span>
                  </div>
                  <!--
                  <div class="farePrice" (click)="toggleTariffConfirm(tariff)">
                    {{tariff.fare.amount|number:'.2-2'}}€
                  </div> -->
                  <div class="farePrice" (click)="selectFare(t)">
                    <span *ngIf="!tariff.negotiated">
                      {{tariff.fare.amount|number:'.2-2'}}€
                    </span>
                    <span *ngIf="(mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) && tariff.negotiated">
                      <del>{{tariff.fare.publicAmount|number:'.2-2'}}€</del>
                      <span class="material-icons">
                        arrow_right_alt
                      </span>
                      {{tariff.fare.publicAmount - (tariff.fare.fees||0)|number:'.2-2'}}€
                    </span>
                    <span *ngIf="!mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt && tariff.negotiated && !priceservice.isNegoHidden()">
                      {{tariff.fare.amount + (tariff.fare.fees||0)|number:'.2-2'}}€
                    </span>
                    <span *ngIf="!mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt && tariff.negotiated && priceservice.isNegoHidden()">
                      {{tariff.fare.publicAmount|number:'.2-2'}}€
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isChurning">
            <div class="mb-2 text-danger pr-2 pl-2">
              {{'REDO_WARN_FASTISSUE'|translate}}
            </div>
          </div>
      </div>
      <hr/>
      <div class="text-right">
        <div class="mb-2 text-danger pr-2 pl-2">
          {{'REDO_WARN'|translate}}
        </div>
        <mat-progress-bar *ngIf="redoingRes" mode="buffer" class="mb-2"></mat-progress-bar>
        <button color="primary" (click)="redoReservation()" type="button" mat-button [disabled]="!someNewFare()">{{'REDO_RESERVATION'|translate}}</button>
        <button type="button" mat-button (click)="toggleRegenerate()">{{'CLOSE'|translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="webCheckinModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="webCheckinModal" aria-hidden="true">
  <div class="modal-dialog " role="document">
    <div class="modal-content" *ngIf="webCheckin">
      <div class="modal-header">
        <h5 class="modal-title" id="webCheckinModalTitle">{{'WEB_CHECKIN'|translate}}</h5>
        <button *ngIf="!deleting" type="button" class="close" (click)="toggleWebCheckinModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          {{'FILL_THE_DATA'|translate}}
        </div>
        <div *ngIf="webCheckin.FIRST" class="mb-3">
          <mat-form-field class="full-width">
            <mat-label>{{'NAME'|translate}}</mat-label>
            <input required matInput type="text" [(ngModel)]="dataForCheckin.FIRST">
            <mat-hint>{{webCheckin.FIRST.description}}</mat-hint>
          </mat-form-field>
        </div>
        <div *ngIf="webCheckin.LAST" class="mb-3">
          <mat-form-field class="full-width">
            <mat-label>{{'SURNAME'|translate}}</mat-label>
            <input required matInput type="text" [(ngModel)]="dataForCheckin.LAST">
            <mat-hint>{{webCheckin.LAST.description}}</mat-hint>
          </mat-form-field>
        </div>
        <div *ngIf="webCheckin.FLT_NO" class="mb-3">
          <mat-form-field class="full-width">
            <mat-label>{{'FLIGHT_NUMBER'|translate}}</mat-label>
            <input required matInput type="text" [(ngModel)]="dataForCheckin.FLT_NO">
            <mat-hint>{{webCheckin.FLT_NO.description}}</mat-hint>
          </mat-form-field>
        </div>
        <div *ngIf="webCheckin.PNR" class="mb-3">
          <mat-form-field class="full-width">
            <mat-label>{{'PNR_LOCATOR'|translate}}</mat-label>
            <input required matInput type="text" [(ngModel)]="dataForCheckin.PNR">
            <mat-hint>{{webCheckin.PNR.description}}</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <hr/>
      <div class="text-right">
        <button color="primary" mat-button [disabled]="disabledCheckin()" (click)="elaborateCheckin()">{{'WEB_CHECKIN'|translate}}</button>
        <button type="button" mat-button (click)="toggleWebCheckinModal()">{{'ABORT'|translate}}</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="deleteLowCostNDCModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="deleteLowCostPnrModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteLowCostPnrModalTitle">{{'DELETE_RESERVATION'|translate}} ?</h5>
        <button *ngIf="!deleting" type="button" class="close" (click)="toggleDeleteLowCostNDCModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3">{{'DELETE_RESERVATION_QUESTION'|translate}}</div>
      </div>
      <hr/>
      <div class="text-right" *ngIf="!deleting && reservation">
        <button type="button" mat-button (click)="toggleDeleteLowCostNDCModal()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="cancelLowCostReservation()">
          <span *ngIf="reservation.status=='issued'">{{'VOID_RESERVATION'|translate}}</span>
        </button>
      </div>
      <div class="text-right" *ngIf="deleting">
        <div class="mb-2" >{{'LOADING'|translate}}</div>
        <mat-progress-bar style="max-width: 200px;display:inline-block;" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteLowCostPnrModalResult" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="deleteLowCostPnrModalResult" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >{{"POST_BOOKING.OPERATION_RESULT" | translate}}</h5>
        <button *ngIf="!deleting" type="button" class="close" (click)="deleteLowCostPnrModalResult()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="changesLowCost">
          <div *ngIf="changesLowCost.Status && changesLowCost.Status === 'CancelSucceeded' ">
            <div class="row d-flex justify-content-center">
              <div class="col-12">
                <h3 class="mb-4 pb-4" class="text-center">{{"POST_BOOKING.CANCEL_CONFIRM_RESULT"}}</h3>
              </div>
              <div class="col-12">
                <div *ngFor="let valueRetured of changesLowCost.SupplierConfirmationDataItemList.SupplierConfirmationDataItem">
                  <div *ngIf="valueRetured.Name === 'Paid Amount'" class="text-center">
                    {{"POST_BOOKING.PAYED_AMOUNT" | translate}}: {{valueRetured.Value}}
                  </div>
                  <div *ngIf="valueRetured.Name === 'Refund Amount'" class="text-center">
                    {{"REFOUNDED_AMOUNT" | translate}}: {{valueRetured.Value}}
                  </div>
                  <div *ngIf="valueRetured.Name === 'Supplier Charge'" class="text-center">
                    {{"OPERATION_PRICE" | translate}}: {{valueRetured.Value}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="changesLowCost.Status && changesLowCost.Status === 'CancelFailed'">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-md-6 border-danger p-3" style="border: 2px solid;">
                <h2 class="text-center text-danger"> {{"CANCELLATION_FAILD" | translate}}!</h2>
                <p class="text-center text-danger">{{"ASK_SUPPORT" | translate}}...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="text-right" *ngIf="!deleting && reservation">
        <button type="button" mat-button (click)="deleteLowCostPnrModalResult()">{{'CLOSE'|translate}}</button>
      </div>
      <div class="text-right" *ngIf="deleting">
        <div class="mb-2" >{{'LOADING'|translate}}</div>
        <mat-progress-bar style="max-width: 200px;display:inline-block;" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingBar>
  <mat-progress-bar mode="buffer" class="mb-5"></mat-progress-bar>
</ng-template>
