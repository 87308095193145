import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../../app.component';
import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { Traveler } from '../../../classes/travelers/singletraveler';
import {NewTraveller} from '../../../classes/travelers/newTraveller';
import * as $ from 'jquery';
import {TicketingService} from '../../../services/ticketing/ticketing.service';
export interface Passengers {
  type: string;
  number: number;
}

export interface SingleService {
  type: string;
  notes: string;
  from: string;
  to: string;
  date: string;
  dateTo: string;
  location: string;
}

@Component({
  selector: 'app-offline-requests',
  templateUrl: './offline-requests.component.html',
  styleUrls: ['./offline-requests.component.scss'],
  animations: [
   trigger('listItemAnimation', [
     transition(':enter', [
       style({ height: '0px', overflow: 'hidden' }),
       group([animate('250ms ease-out', style({ height: '!' }))]),
       // although group is useless here (since we have only one animation)
       // i like to leave it anyway just in case i want to add another
       // parallel animation in the future
     ]),
     transition(':leave', [
       style({ height: '!', overflow: 'hidden' }),
       group([animate('250ms ease-out', style({ height: '0px' }))]),
     ]),
   ]),
  ],
})

export class OfflineRequestsComponent implements OnInit {
  private readonly notifier: NotifierService;
  currentStep: number;
  request: {
    service: SingleService,
  };
  passengers: Passengers[];
  summary: any;
  loading: boolean;
  travelers: NewTraveller[];
  newTraveler: NewTraveller;
  sortedActivities: SingleService[];
  currentTraveler: number;
  loadTravelers: boolean;
  editingTraveler: boolean;
  subject: string;
  freeText: string;
  ticket: any;
  constructor(
    public appComponent: AppComponent,
    notifierService: NotifierService,
    public translate: TranslateService,
    private ticketService: TicketingService,
  ) {
    this.notifier = notifierService;
    this.setServiceForm();
    this.summary = {};
    this.summary.subject = '';
    this.summary.freeText = '';
    this.passengers = [
      {
        type: 'ADT',
        number: 1,
      },
      {
        type: 'CHD',
        number: 0,
      },
      {
        type: 'INF',
        number: 0,
      }
    ];
    this.travelers = [];
    this.summary = {
      services: [],
      passengers: this.travelers
    };
    this.currentStep = 1;
  }

  setServiceForm(): void {
    this.request = {
      service: {
        type: 'flight',
        notes: '',
        from: '',
        to: '',
        date: '',
        dateTo: '',
        location: '',
      }
    };
  }

  pushToSummary(): void {
    this.loading = true;
    this.summary.services.push(this.request.service);
    setTimeout(() => {
      this.loading = false;
    }, 1000 );
    this.setServiceForm();
    this.sortArray();
  }

  removeService(value): void {
    this.summary.services.splice(value, 1);
    this.sortArray();
  }

  ngOnInit(): void {
    this.setNewTraveler();
  }

  checkForm() {
    let isValid = true;
    if (this.request.service.type === 'flight') {
      isValid = !(!this.request.service.from || !this.request.service.to || !this.request.service.date);
    } else if ( this.request.service.type === 'hotel' ) {
      isValid = !(!this.request.service.location || !this.request.service.date || !this.request.service.dateTo);
    } else if ( this.request.service.type === 'car' ) {
      isValid = !(!this.request.service.from || !this.request.service.to || !this.request.service.date || !this.request.service.dateTo);
    } else if ( this.request.service.type === 'train' ) {
      isValid = !(!this.request.service.from || !this.request.service.to || !this.request.service.date);
    }
    return isValid;
  }

  returnType(value) {
    if (value === 'ADT') {return 'ADULTS'; }
    if (value === 'CHD') {return 'CHILDREN'; }
    if (value === 'INF') {return 'INFANT'; }
  }

  addPax(value) {
    this.passengers[value].number = this.passengers[value].number + 1;
  }
  removePax(value) {
    if (this.passengers[value].number > 0 && this.passengers[value].type !== 'ADT') {
      this.passengers[value].number = this.passengers[value].number - 1;
    } else if (this.passengers[value].type === 'ADT' && this.passengers[value].number > 1 ) {
      this.passengers[value].number = this.passengers[value].number - 1;
    } else {
      // this.notifier.notify('error', this.translate.instant('ONE_ADULT_MANDATORY'));
    }
  }

  sortArray() {
    this.sortedActivities = this.summary.services.sort((a, b) => a.date - b.date);
  }

  sendRequest() {
    console.log('this.travelers => ', this.summary);
    this.ticket = {
      subject: this.summary.subject,
      body: {
        payload: this.summary,
        data: this.summary.freeText
      },
      department: 'office',
      section: 'request'
    };
    this.ticketService.sendTicket(this.ticket).subscribe((res) => {
      this.loading = false;
      this.notifier.notify('success', this.translate.instant('REQUEST_SENDED'));
      this.summary = {};
      this.summary.subject = '';
      this.summary.freeText = '';
      this.travelers = [];
      this.summary = {
        services: [],
        passengers: this.travelers
      };
    }, error => {
      this.loading = false;
      // this.notifier.notify('error', error);
      this.notifier.notify('error', this.translate.instant(error));
      console.error('Error => ', error);
    });

  }

  nextStep() {
    this.currentStep = this.currentStep + 1;
  }
  prevStep() {
    this.currentStep = this.currentStep - 1;
  }

  removeTraveler(index) {
    this.travelers.splice(index, 1);
    if (this.travelers.length === 0) {
      this.setNewTraveler();
    }
  }

  setNewTraveler() {
    this.newTraveler = new NewTraveller();
    this.travelers.push(this.newTraveler);
  }

  consoleCall() {
    console.log('this.travelers => ', this.travelers);
  }

  travelerController() {
    const isValidTraveler = (value) => !(!value.name || !value.surname || !value.phoneNumber);
    return this.travelers.every(isValidTraveler);
  }

  travelerSet(event, i) {
    this.travelers[i] = event;
    console.log('this.data.passengers => ', this.travelers);
    delete this.currentTraveler;
    // $('#addTravelerModal').modal('toggle');
  }

  toggleDialog(currentTraveler) {
    this.currentTraveler = currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  toggleEditDialog(currentTraveler) {
    this.editingTraveler = true;
    this.currentTraveler = currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  closeEvent(event) {
    delete this.currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  closeEditEvent(event) {
    this.editingTraveler = false;
    delete this.currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  closeListEvent(event) {
    delete this.currentTraveler;
    $('#listTravelerModal').modal('toggle');
  }

  loadTravelersList(currentTraveler) {
    this.currentTraveler = currentTraveler;
    $('#listTravelerModal').modal('toggle');
    this.loadTravelers = true;
  }

}
