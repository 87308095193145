<button (click)="toggleModal()" [disabled]="isDisabled" id="BOOKING_CHANGE" mat-button type="button">
  {{'TRAIN_MODULE.AFTERSALE.BOOKING_CHANGE'|translate}}
</button>

<div aria-hidden="true" aria-labelledby="changeServiceModal" class="modal fade" data-backdrop="static" id="changeServiceModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document" style="top:70px">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="changeServiceModalTitle">{{'TRAIN_MODULE.AFTERSALE.CHANGE_SERVICE_QUESTION'|translate}}</h5>
        <button (click)="toggleModal()" *ngIf="!fetchingData" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <ng-container *ngIf="!changeAttempt else successDialog">
          <div *ngIf="!fetchingData else trenitaliaLoading" class="col-lg">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
              <mat-tab *ngFor="let travelSolution of travelSolutions" label="{{ travelSolution | translate }}">
                <mat-tab-group *ngIf="travelSolution === activeTravelSolution" [selectedIndex]="selectedIndex" mat-stretch-tabs="false" mat-align-tabs="start">
                  <mat-tab *ngFor="let segment of sortedSegments; let i = index;" disabled label="{{segment.trainClassification}} {{segment?.trainNumber}}">
                    <ng-container *ngIf="segment.services.length > 0 && segment.services[0].services?.length > 0 else trenitNoSolutions">
                      <div class="padding10">
                        <div class="row mb-3 lightbg">
                          <div class="col-lg-3 align-self-center">
                            <span class="material-icons">
                              train
                            </span>
                            {{segment.trainClassification}}
                            <div class="smalltext">N° {{segment.trainNumber}}</div>
                          </div>
                          <div class="col-lg-3 align-self-center">
                            <p>{{segment.departureDate | date:'dd/MM/yyyy - HH:mm'}}</p>
                            <p>{{segment.departureStation?.name}}</p>
                          </div>

                          <div class="col-lg-3 align-self-center">
                            <p>{{segment.journeyLength | date:'HH':"+0000" }}h {{segment.journeyLength | date:'mm':"+0000" }}m</p>
                          </div>
                          <div class="col-lg-3 align-self-center">
                            <p>{{segment.arrivalDate | date:'dd/MM/yyyy - HH:mm'}}</p>
                            <p>{{segment.arrivalStation?.name}}</p>
                          </div>
                        </div>
                        <hr/>
                        <ng-container *ngIf="!!alternatives">
                          <strong>{{'TRAIN_MODULE.AFTERSALE.CURRENT_SERVICE'|translate}}:</strong><br>
                          <div class="row">
                            <div class="col-4">{{'TRAIN_MODULE.TARIFF'|translate}}</div>
                            <div class="col-4">{{'TRAIN_MODULE.CABIN'|translate}}</div>
                            <div class="col-2">{{'PRICE'|translate}}</div>
                          </div>
                          <div *ngFor="let offer of getCurrentSelectedOffer(segment)" class="mb-2 row">
                            <div class="col-4">{{getOfferName(offer?.offer.id)}}</div>
                            <div class="col-4">{{getServiceName(offer?.catalogServiceId.id)}}</div>
                            <div class="col-2">{{offer?.price.amount | number:'.2-2'}}€</div>
                          </div>
                        </ng-container>
                        <hr/>
                        <div class="mb-2">
                          {{'TRAIN_MODULE.SELECT_TARIFF'|translate}}
                        </div>
                        <div *ngFor="let pax of segment.services; index as i" class="row">
                          <div class="col-3 align-self-center">
                            {{ getTraveller(pax)?.surname }} {{ getTraveller(pax)?.name }}
                            <sup> ({{getTraveller(pax)?.type}})</sup>
                          </div>
                          <div class="col-4 align-self-center">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>{{'TRAIN_MODULE.CABIN'|translate}}</mat-label>
                              <mat-select [(ngModel)]="selectedClass[i]" placeholder="Service">
                                <mat-optgroup *ngFor="let group of pax.services" [label]="group.name">
                                  <mat-option (click)="setOffer(serviceClass, segment.idXml, i, pax)"
                                              *ngFor="let serviceClass of group.services" [value]="serviceClass">
                                    {{getServiceDisplayName(serviceClass)}}
                                  </mat-option>
                                </mat-optgroup>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div *ngIf="selectedClass[i]" class="col-4 align-self-center">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>{{'TRAIN_MODULE.TARIFF'|translate}}</mat-label>
                              <mat-select placeholder="Select tariff" [(ngModel)]="selectedOffer[i]">
                                <mat-optgroup *ngFor="let groupOffer of selectedClass[i].offers" [label]="groupOffer.name">
                                  <mat-option (click)="selectTrenitaliaOffer(segment.idXml, offer, i, pax)" *ngFor="let offer of groupOffer.offers"
                                              [disabled]="isOfferDisabled(pax, offer)" [value]="offer">
                                    {{ getOfferDisplayText(offer) }}
                                  </mat-option>
                                </mat-optgroup>
                              </mat-select>
                            </mat-form-field>
                            <a (click)="openTrenitInfoPopup(selectedOfferId[i])" *ngIf="selectedOfferId" style="cursor: pointer">
                              <mat-icon matTooltip="{{ selectedClass[i][selectedOfferId[i]]?.description | translate}}">info</mat-icon>
                            </a>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-6 align-self-center buttonrow">
                            <button (click)="changeTab(i-1)" *ngIf="alternatives.segments.length > 1 && i > 0" color="primary" mat-button>
                              <mat-icon>chevron_left</mat-icon>
                              {{'TRAIN_MODULE.BACK' | translate}}
                            </button>
                            <span *ngIf="alternatives.segments.length > 1 && i!=alternatives.segments.length-1" matTooltip="{{getEligibilityMessage()}}">
                              <button (click)="changeTab(i+1)" [disabled]="getDisabledOffer()" color="primary" mat-button>
                                {{'TRAIN_MODULE.FORM.NEXT' | translate}}
                                <mat-icon>chevron_right</mat-icon>
                              </button>
                            </span>
                            <span *ngIf="i == alternatives.segments.length-1" matTooltip="{{getEligibilityMessage()}}">
                              <button (click)="validateAlternative()" [disabled]="getDisabledOffer() || (evaluateTravelLoading$ | async)"
                                      color="primary" mat-button>
                                {{'TRAIN_MODULE.AFTERSALE.VALIDATE_TRAVEL' | translate}}
                                <ng-container *ngIf="(evaluateTravelLoading$ | async)">
                                  <mat-icon class="spin">autorenew</mat-icon>
                                </ng-container>
                              </button>
                            </span>
                            <app-validated-travel-penalty *ngIf="!!validatedTravel" [validatedTravel]="validatedTravel"></app-validated-travel-penalty>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <ng-template #trenitNoSolutions>
                      <p>{{'TRAIN_MODULE.TRENIT.NO_SOLUTIONS_AVAILABLE' | translate}}</p>
                    </ng-template>

                  </mat-tab>
                </mat-tab-group>
              </mat-tab>
            </mat-tab-group>
          </div>
        </ng-container>
        <ng-template #trenitaliaLoading>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-template>
        <ng-template #successDialog>
          <mat-dialog-content>
            <ng-container *ngIf="errorMessage else successMessage">
              <mat-icon>warning</mat-icon>
              {{ errorMessage | translate}}
            </ng-container>
            <ng-template #successMessage>
              {{'TRAIN_MODULE.AFTERSALE.CHANGE_SUCCES' | translate}}
            </ng-template>
          </mat-dialog-content>
        </ng-template>

        <div *ngIf="!changeAttempt" class="text-right">
          <button (click)="toggleModal()" mat-button type="button">{{'TRAIN_MODULE.ABORT'|translate}}</button>
          <button (click)="confirmChange()" mat-button type="button"
                  [disabled]="!validatedTravel || validatedTravel?.validatedPostSaleRecord.allowed === 'false' || (modifyReservation$ | async)">
            {{'TRAIN_MODULE.AFTERSALE.CONFIRM_CHANGE'|translate}}
            <ng-container *ngIf="(modifyReservation$ | async)">
              <mat-icon class="spin">autorenew</mat-icon>
            </ng-container>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
