<custom-title></custom-title>
<div class="padding20">
  <div *ngIf="!company" class="loader">
    <div class="card text-center padding20">
      <mat-spinner></mat-spinner>
      <h5>{{'LOADING'|translate}}</h5>
    </div>

  </div>

  <div class="profilecontainer" *ngIf="company">

    <div class="profiletopdetails marginbottom20 companytop">
      <div class="row">
        <div class="col-xl-4">
          <div class="userAvatarContainer">
            <div class="userimage">
              <img [src]="company.image"/>
            </div>
            <div class="uploadIcon">
              <button mat-mini-fab color="primary" data-toggle="modal" data-target="#exampleModal">
                <mat-icon>photo_camera</mat-icon>
              </button>
            </div>
          </div>

          <div class="userMain">
            <span class="username">{{company.name}}</span>
            <div>
              <span class="userrole">{{returnCompanyType(company.type)|translate}}</span>
              <span class="userAgency" *ngIf="company.daughters.length>0">
                <mat-icon style="vertical-align:bottom;" aria-hidden="false" aria-label="subdirectory_arrow_right">subdirectory_arrow_right</mat-icon>
                {{'SUBORDINATED'|translate}}: {{company.daughters.length}}
              </span>
            </div>
            <div class="pl-2 maxWithScroll" *ngIf="company.daughters.length>0">
              <div  *ngFor="let cp of company.daughters">
                <mat-icon style="vertical-align:bottom;" aria-hidden="false" aria-label="subdirectory_arrow_right">subdirectory_arrow_right</mat-icon>
                {{cp.name}}
              </div>
            </div>


          </div>

        </div>
        <div class="col-xl-4"></div>
        <div class="col-xl-4 align-self-center buttonrow">
          <div class="contacts">
            <p class="d-flex align-self-center">
              <mat-icon aria-hidden="false" aria-label="phone">phone</mat-icon> {{'PHONE'|translate}}: {{company.phoneNumber}}
            </p>
            <p class="d-flex align-self-center">
              <mat-icon aria-hidden="false" aria-label="Email">mail_outline</mat-icon> {{'EMAIL'|translate}}: {{company.email}}
            </p>
            <p class="d-flex align-self-center">
              <mat-icon aria-hidden="false" aria-label="Location">my_location</mat-icon> {{'LOCATION'|translate}}: {{company.location.city}} ({{company.location.province}}), {{company.location.region}} - {{company.location.country}}
            </p>
          </div>
          <button *ngIf="maincomponent.loggedUser.role.profile.company.post.company" class="togglebutton" mat-stroked-button (click)="showEditForm()" color="{{showEdit ? 'warn' : ''}}" mat-button>
            <span *ngIf="!showEdit">{{'EDIT_COMPANY'|translate}}</span>
            <span *ngIf="showEdit">{{'ABORT_EDITING'|translate}}</span>
          </button>
          <button *ngIf="maincomponent.loggedUser.role.profile.company.post.company" class="togglebutton" mat-stroked-button (click)="toggleTokenModal()" color="{{company.pin.enable ? 'warn' : 'primary'}}" mat-button>
            <span *ngIf="!company.pin.enable">{{'ENABLE_SECURITY_TOKEN'|translate}}</span>
            <span *ngIf="company.pin.enable">{{'DISABLE_SECURITY_TOKEN'|translate}}</span>
          </button>

          <button class="togglebutton" mat-stroked-button *ngIf="maincomponent.loggedUser.role.superAdmin && !company.currentlyImporting" (click)="toggleSiapModal()" color="primary" mat-button>
            <span>{{'IMPORT_USERS'|translate}}</span>
          </button>

          <div *ngIf="maincomponent.loggedUser.role.superAdmin && company.currentlyImporting">
            <span>{{'IMPORTING_USERS'|translate}}</span>
          </div>

        </div>
      </div>
    </div>

    <div class="card marginbottom20" [hidden]="showEdit" *ngIf="this.showTabs">
      <mat-tab-group dynamicHeight #tabGroup [selectedIndex]="currentTab" (selectedTabChange)="tabChanged($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="mx-1">account_balance</mat-icon> {{'COMPANY_DETAILS'|translate}}
          </ng-template>
          <div class="padding20">
            <div class="row">
              <div class="col-xl-3 align-self-center marginbottom20">
                <div class="usercompany">
                  <img [src]="company.image"/>
                </div>
              </div>
              <div class="col-xl-3 align-self-center marginbottom20" *ngIf="maincomponent.loggedUser.role.superAdmin">
                <p>{{'TYPE'|translate}}: <i>{{returnCompanyType(company.type)|translate}}</i></p>
                <!--<p>Gruppi assegnati: <i>Manager, IT, Agente</i></p>-->
                <p>
                  {{'ACTIVATION'|translate}}
                  <span *ngIf="company.enable">
                    <mat-icon color="primary" aria-label="activation">fiber_manual_record</mat-icon>
                    {{'ACTIVE'|translate}}
                  </span>
                  <span *ngIf="!company.enable">
                    <mat-icon color="warn" aria-label="activation">cancell</mat-icon>
                    {{'INACTIVE'|translate}}
                  </span>

                </p>

              </div>
              <div class="col-xl-6 align-self-center marginbottom20">
                <div class="row">
                  <div class="col-xl-6 mx-auto">
                    <div class="counteruser">
                      <h1><span class="count">{{company.createdAt|date:'dd'}}</span>/<span class="count">{{company.createdAt|date:'MM'}}</span>/<span class="count">{{company.createdAt|date:'yyyy'}}</span></h1>
                      <h5>{{'REGISTRATION'|translate}}</h5>
                    </div>
                    <div class="counteruser">
                      <h1><span class="count">{{company.updatedAt|date:'dd'}}</span>/<span class="count">{{company.updatedAt|date:'MM'}}</span>/<span class="count">{{company.updatedAt|date:'yyyy'}}</span></h1>
                      <h5>{{'LAST_UPDATE'|translate}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </mat-tab>
        <mat-tab *ngIf="maincomponent.loggedUser.role.profile.user.get.company">
          <ng-template mat-tab-label>
            <mat-icon class="mx-1">group</mat-icon> {{'USERS_LIST'|translate}}
          </ng-template>

          <div class="text-center" *ngIf="loadingTab">
            <p>{{'LOADING'|translate}}</p>
            <mat-progress-bar mode="buffer"></mat-progress-bar>
          </div>

          <div *ngIf="currentTab==1 && !loadingTab">
            <app-list *ngIf="maincomponent.loggedUser.role.profile.user.get.company" page="company" [idcompany]="company._id"></app-list>
            <div class="padding20 text-center" *ngIf="!maincomponent.loggedUser.role.profile.user.get.company">
              <mat-icon style="font-size:40px" class="marginbottom10">sentiment_very_dissatisfied</mat-icon>
              <h3>{{'NO_ACCESS'|translate}}.</h3>
              <p>{{'NO_ACCESS_TEXT'|translate}}</p>
            </div>

          </div>

        </mat-tab>
        <!--
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>airplanemode_active</mat-icon> {{'FLIGHTS'|translate}}
          </ng-template>
          <div class="padding10 nooverflow">
            <div class="text-center" *ngIf="loadingTab">
              <p>{{'LOADING'|translate}}</p>
              <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>
            <div *ngIf="currentTab==2 && !loadingTab">
              <app-reservationlist [page]="'company'" [idcompany]="company._id"></app-reservationlist>
            </div>

          </div>
        </mat-tab>
        -->
        <!--
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>hotel</mat-icon> {{'HOTELS'|translate}}
          </ng-template>
          <div class="padding10 nooverflow">
            <div class="text-center" *ngIf="loadingTab">
              <p>{{'LOADING'|translate}}</p>
              <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>
            <div *ngIf="currentTab==3 && !loadingTab">
              <app-reservationlisthotel [page]="'company'" [idcompany]="company._id"></app-reservationlisthotel>
            </div>

          </div>
        </mat-tab>
        -->
        <mat-tab *ngIf="maincomponent.loggedUser.role.profile.company.get.company">
          <ng-template mat-tab-label>
            <mat-icon class="mx-1">paid</mat-icon> {{'FOPS'|translate}}
          </ng-template>
          <div class="text-center" *ngIf="loadingTab">
            <p>{{'LOADING'|translate}}</p>
            <mat-progress-bar mode="buffer"></mat-progress-bar>
          </div>
          <div class="padding20" *ngIf="currentTab==2 && !loadingTab"> <!-- currentTab==4 -->

            <mat-tab-group dynamicHeight #tabGroupP [selectedIndex]="0" mat-stretch-tabs="false" mat-align-tabs="start">
              <mat-tab *ngIf="company.services.paymentMethods">
                <ng-template mat-tab-label>
                  <mat-icon>list</mat-icon> {{'FOPS'|translate}}
                </ng-template>
                <div class="pt-2">
                  <app-methods [id]="company._id" [reservation]="undefined"></app-methods>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon>payment</mat-icon> {{'CREDIT_CARDS'|translate}}
                </ng-template>
                <app-fops [id]="company._id" [reservation]="undefined"></app-fops>
              </mat-tab>
              <mat-tab *ngIf="!appComponent.isGat && !appComponent.isSbt">
                <ng-template mat-tab-label>
                  <mat-icon>payment</mat-icon> {{'CARD_MODULE'|translate}}
                </ng-template>
                <app-creditcardrequest></app-creditcardrequest>
              </mat-tab>
            </mat-tab-group>

          </div>
        </mat-tab>

        <mat-tab *ngIf="maincomponent.loggedUser.role.profile.user.role.post || maincomponent.loggedUser.role.superAdmin">
          <ng-template mat-tab-label>
            <mat-icon class="mx-1">vpn_key</mat-icon> {{'SYSTEM_ROLES'|translate}}
          </ng-template>
          <div class="text-center" *ngIf="loadingTab">
            <p>{{'LOADING'|translate}}</p>
            <mat-progress-bar mode="buffer"></mat-progress-bar>
          </div>
          <div class="padding20" *ngIf="company && currentTab==3 && !loadingTab">
            <app-companyroles [company]="company._id"></app-companyroles>
          </div>

        </mat-tab>

        <mat-tab *ngIf="maincomponent.loggedUser.role.profile.company.corporate.read">
          <ng-template mat-tab-label>
            <mat-icon class="mx-1">corporate_fare</mat-icon> {{'CORPORATE_CODES'|translate}}
          </ng-template>
          <div class="text-center" *ngIf="loadingTab">
            <p>{{'LOADING'|translate}}</p>
            <mat-progress-bar mode="buffer"></mat-progress-bar>
          </div>
          <div class="padding20" *ngIf="company && (currentTab==4 || currentTab==3) && !loadingTab">
            <app-corporate-codes [isSearch]="false" [_id]="company._id"></app-corporate-codes>
          </div>

        </mat-tab>
        <!--
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>security</mat-icon> {{'TRAVEL_POLICIES'|translate}}
          </ng-template>
          <div class="padding20" *ngIf="company">
            <app-policies [company]="company"></app-policies>
          </div>
        </mat-tab>

        -->
        <mat-tab *ngIf="maincomponent.loggedUser.role.superAdmin">
          <ng-template mat-tab-label>
            <mat-icon class="mx-1">settings</mat-icon> {{'SETTINGS'|translate}}
          </ng-template>
          <div class="text-center" *ngIf="loadingTab">
            <p>{{'LOADING'|translate}}</p>
            <mat-progress-bar mode="buffer"></mat-progress-bar>
          </div>
          <div class="padding20" *ngIf="company && currentTab==5 && !loadingTab || (company && currentTab==4)">
            <app-cp-services (update)="getCompany(company._id)" [cpId]="company._id"></app-cp-services>
          </div>

        </mat-tab>

        <mat-tab *ngIf="maincomponent.loggedUser.role.superAdmin || (maincomponent.loggedUser.role.company && (maincomponent.loggedUser.company.services.b2c || maincomponent.loggedUser.company.services.b2b))">
          <ng-template mat-tab-label>
            <mat-icon class="mx-1">monetization_on</mat-icon> {{'FEES'|translate}}
          </ng-template>
          <div class="padding20" *ngIf="company && currentTab==6 || (company && currentTab==4)">
            <app-single-agency-fees [profile]="maincomponent.loggedUser" [companyId]="company._id"></app-single-agency-fees>
          </div>

        </mat-tab>

        <mat-tab *ngIf="maincomponent.loggedUser.role.superAdmin">
          <ng-template mat-tab-label>
            <mat-icon class="mx-1">edit_note</mat-icon> {{'CUSTOM_REMARK'|translate}}
          </ng-template>
          <div class="padding20" *ngIf="company && currentTab==7 || (company && currentTab==5)">
            <div class="text-center" *ngIf="loadingRemark">
              <p>{{'LOADING'|translate}}</p>
              <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>
            <div *ngIf="companyRemarksClone && !loadingRemark">
              <!--
              <div class="" *ngFor="let remark of companyRemarksClone.rmList; let i = index; trackBy: trackByFn">
                <mat-form-field appearance="standard">
                  <mat-label>Remark</mat-label>
                  <input matInput placeholder="Remark" [(ngModel)]="companyRemarksClone.rmList[i]">
                </mat-form-field>
                <div class="d-inline-block">
                  <button (click)="removeRemark(i)">Rimuovi</button>
                </div>
              </div>
            -->
              <div class="mb-3">Trascina i remark per cambiarne l'ordinamento</div>
              <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let remark of companyRemarksClone.rmList; let i = index; trackBy: trackByFn" cdkDrag>
                  <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                  <div class="row">
                    <div class="col-8">
                      <input class="remarkInput" placeholder="Remark" [(ngModel)]="companyRemarksClone.rmList[i]">
                    </div>
                    <div class="col-2 text-right">
                      <span class="material-icons mr-2 pointer">
                        import_export
                      </span>
                      <span class="material-icons pointer" (click)="removeRemark(i)">
                        close
                      </span>

                    </div>
                  </div>

                </div>
              </div>
            </div>
            <hr/>
            <div class="text-right">
              <button class="btn btn-primary btn-sm mr-2" *ngIf="differenceRmark()" (click)="saveRemark()" [disabled]="loadingRemark">Salva</button>
              <button class="btn btn-secondary btn-sm mr-2" (click)="addRemarks()" [disabled]="loadingRemark">Aggiungi remark</button>
              <button class="btn btn-danger btn-sm" (click)="confirmDeleteRemark()" *ngIf="companyRemarksClone._id" [disabled]="loadingRemark">Rimuovi tutti i remarks</button>
            </div>

          </div>

        </mat-tab>

        <mat-tab *ngIf="maincomponent.loggedUser.role.superAdmin || company.services.customFields">
          <ng-template mat-tab-label>
            <mat-icon class="mx-1">edit_note</mat-icon> {{'SIAP_CUSTOM_FIELDS'|translate}}
          </ng-template>
          <div class="padding20">

              <app-custom-fields [companyId]="company._id"></app-custom-fields>
          </div>

        </mat-tab>
      </mat-tab-group>
    </div>
    <form [hidden]="!showEdit" [formGroup]="CompanyForm" (ngSubmit)="editCompany()" novalidate class="formfull">
      <div class="row marginbottom10">

        <div class="col-xl-6">

          <div class="card marginbottom20">
            <div class="card-header">
              <span class="heading_panel">{{'COMPANY_INFO'|translate}}</span>

              <mat-slide-toggle *ngIf="maincomponent.loggedUser.role.profile.company.post.company" class="float-right smalltext marginright10 margintop5" [(ngModel)]="activeform" [ngModelOptions]="{standalone: true}" (click)="toggleForm()">
                {{'ENABLE_EDITING'|translate}}
              </mat-slide-toggle>
            </div>
            <div class="card-body">

              <div class="example-container">

                <div>
                  <mat-form-field>
                    <mat-label>{{'NAME'|translate}}</mat-label>
                    <input matInput placeholder="{{'NAME'|translate}}" formControlName="name" required>
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field>
                    <mat-label>{{'VAT'|translate}}</mat-label>
                    <input matInput placeholder="{{'VAT'|translate}}" formControlName="pIva" required>
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field>
                    <mat-label>{{'EMAIL'|translate}}</mat-label>
                    <input matInput placeholder="{{'EMAIL'|translate}}" formControlName="email" required>
                    <mat-error *ngIf="CompanyForm.controls.email.invalid">{{getErrorMessage()}}</mat-error>
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field>
                    <mat-label>{{'PHONE'|translate}}</mat-label>
                    <input matInput placeholder="{{'PHONE'|translate}}" formControlName="phoneNumber" required>
                    <mat-error *ngIf="CompanyForm.controls.phoneNumber.errors" >Spazi non ammessi </mat-error>
                  </mat-form-field>
                </div>

                <div *ngIf="maincomponent.loggedUser.role.superAdmin">
                  <mat-form-field>
                    <mat-label>{{'DK'|translate}}</mat-label>
                    <input matInput placeholder="{{'DK'|translate}}" formControlName="dkNumber" required>
                  </mat-form-field>
                </div>

                <div *ngIf="maincomponent.loggedUser.role.superAdmin && !appComponent.isGat">
                  <mat-form-field>
                    <mat-label>{{'CAU_CODE'|translate}}</mat-label>
                    <input matInput placeholder="{{'CAU'|translate}}" formControlName="cau">
                  </mat-form-field>
                </div>

                <div *ngIf="maincomponent.loggedUser.role.superAdmin && !appComponent.isGat">
                  <mat-form-field>
                    <mat-label>{{'CAU_DK'|translate}}</mat-label>
                    <input matInput placeholder="{{'CAU_DK'|translate}}" formControlName="cauDK">
                  </mat-form-field>
                </div>

                <mat-form-field *ngIf="maincomponent.loggedUser.role.superAdmin">
                  <mat-label>{{'TYPE'|translate}}</mat-label>
                  <mat-select formControlName="type" required>
                    <mat-option value="A">
                      {{'AGENCY'|translate}}
                    </mat-option>
                    <mat-option value="C">
                      {{'B2B_COMPANY'|translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="marginbottom20" *ngIf="maincomponent.loggedUser.role.superAdmin">
                  <mat-slide-toggle color="primary" labelPosition="before" class="margintop5" formControlName="enable">
                    {{'ACTIVATION'|translate}}
                  </mat-slide-toggle>
                </div>

                <div *ngIf="maincomponent.loggedUser.role.superAdmin">
                  <!--
                  <mat-form-field>
                    <mat-label>{{'INCENTIVE'|translate}}</mat-label>
                    <input matInput placeholder="{{'INCENTIVE'|translate}}" formControlName="incentive" required>

                  </mat-form-field> -->
                  <mat-form-field>
                    <mat-label>{{'INCENTIVE'|translate}}</mat-label>
                    <mat-select formControlName="incentivePlan" required>
                      <mat-option *ngFor="let plan of incentives" [value]="plan._id">
                        {{plan.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div *ngIf="maincomponent.loggedUser.role.superAdmin" class="pt-2">

                  <div class="mb-1" *ngIf="mother">
                    {{'SUBORINATED_OF'|translate}}: {{this.mother.name}}
                    <div class="small">{{'CHANGE_MOTHER'|translate}}</div>
                  </div>


                  <app-companiesautocomplete [isForm]="!activeform"  [subordinated]="true" (setSub)="setCompany($event)"></app-companiesautocomplete>
                </div>


              </div>

            </div>
          </div>

        </div>

        <div class="col-xl-6">

          <div class="card">
            <div class="card-header">
              <span class="heading_panel">{{'LOCATION'|translate}}</span>

            </div>
            <div class="card-body">

              <div class="example-container">
                <div class="row">
                  <div class="col d-flex justify-content-end">
                    <mat-slide-toggle [disabled]="!activeform" [ngModelOptions]="{standalone : true}" [(ngModel)]="isNotItaly" (ngModelChange)="changeCountry($event)" >Stato estero</mat-slide-toggle>
                  </div>
                </div>
                <div class="example-container" formGroupName="location">

                  <div *ngIf="!isNotItaly">
                    <mat-form-field>
                      <mat-label>{{'COUNTRY'|translate}}</mat-label>
                      <mat-select formControlName="country" required>
                        <mat-option *ngFor="let country of countries" [value]="country.value">
                          {{country.viewValue}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="!isNotItaly">
                    <mat-form-field>
                      <mat-label>{{'REGION'|translate}}</mat-label>
                      <mat-select (selectionChange)="setRegion()"  formControlName="region" required >
                        <mat-option *ngFor="let region of regions" [value]="region.value">
                          {{region.viewValue}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="!isNotItaly">
                    <mat-form-field>
                      <mat-label>{{'PROVINCE'|translate}}</mat-label>
                      <mat-select formControlName="province" required>
                        <mat-option *ngFor="let province of provinces" [value]="province.value">
                          {{province.viewValue}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="isNotItaly">
                    <mat-form-field class="full-width mb-1" >
                      <mat-label>{{'FOREIGN_COUNTRY'|translate}}</mat-label>
                      <input matInput [value]="CompanyForm.value.location.country" formControlName="country" type="text" required>
                    </mat-form-field>
                  </div>

                  <div *ngIf="isNotItaly">
                    <mat-form-field class="full-width mb-1">
                      <mat-label>{{'REGION'|translate}}</mat-label>
                      <input matInput [value]="CompanyForm.value.location.region" formControlName="region" type="text"  required>
                    </mat-form-field>
                  </div>

                  <div *ngIf="isNotItaly">
                    <mat-form-field class="full-width mb-1" >
                      <mat-label>{{'PROVINCE'|translate}}</mat-label>
                      <input matInput [value]="CompanyForm.value.location.province" formControlName="province" type="text" required>
                    </mat-form-field>
                  </div>

                  <mat-form-field>
                    <mat-label>{{'CITY'|translate}}</mat-label>
                    <input matInput placeholder="Città" formControlName="city" required>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>{{'POSTAL_CODE'|translate}}</mat-label>
                    <input matInput placeholder="Codice postale" formControlName="postalCode" required>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>{{'ADDRESS'|translate}}</mat-label>
                    <input matInput placeholder="Indirizzo" formControlName="address" required>
                  </mat-form-field>

                </div>

              </div>
              <hr/>
              <button type="submit" [disabled]="!CompanyForm.valid" mat-stroked-button color="primary">{{'SAVE'|translate}}</button>
              <p class="error" *ngIf="!CompanyForm.valid && activeform">{{'COMPLETE_ALL_FIELDS'|translate}}</p>

            </div>
          </div>

        </div>

      </div>

    </form>

  </div>

</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Carica immagine profilo</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="uploadImage text-center">
          <div>
            <div class="form-group">
              <input type="file" id="image" class="inputfile" name="image" (change)="fileProgress($event)" />
              <label for="image">
                <mat-icon>cloud_upload</mat-icon>
                Choose a file
              </label>
            </div>
            <div *ngIf="fileUploadProgress">
              Upload progress: {{ fileUploadProgress }}
            </div>
            <div class="image-preview" *ngIf="previewUrl">
              <img [src]="previewUrl"/>
            </div>

          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button mat-button mat-stroked-button (click)="onSubmit()" [disabled]="!fileData">Upload</button>
        <button mat-button mat-stroked-button data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" *ngIf="company" id="tokenModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="tokenModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="tokenModalTitle">{{'SECURITY_TOKEN'|translate}}</h5>
        <button type="button" class="close" (click)="toggleTokenModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="!company.pin.enable">
          <div class="mb-2">
            {{'TOKEN_TEXT'|translate}}
          </div>
          <div class="mb-2">
            <mat-form-field class="">
              <input matInput type="password" maxlength="6" placeholder="{{'INSERT_TOKEN'|translate}}" [(ngModel)]="token">
            </mat-form-field>
          </div>
          <div class="mb-2">
            <mat-form-field class="">
              <input matInput type="password" maxlength="6" placeholder="{{'CONFIRM_TOKEN'|translate}}" [(ngModel)]="confirmToken">
              <mat-hint class="text-danger" *ngIf="token !== confirmToken">
                {{'TOKEN_DO_NOT_MATCH'|translate}}
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="company.pin.enable && maincomponent.loggedUser.role.superAdmin">
          {{'PIN_ENABLED'|translate}}
        </div>
        <div *ngIf="company.pin.enable && !maincomponent.loggedUser.role.superAdmin">
          {{'PIN_ENABLED_ASK_TO_REMOVE'|translate}}
        </div>
      </div>

      <hr/>

      <div class="text-right" *ngIf="!company.pin.enable">
        <button type="button" mat-button (click)="toggleTokenModal()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button [disabled]="!token || token !== confirmToken" (click)="setToken()">{{'SET_SECURITY_TOKEN'|translate}}</button>
      </div>

      <div class="text-right" *ngIf="company.pin.enable && maincomponent.loggedUser.role.superAdmin">
        <button type="button" mat-button (click)="toggleTokenModal()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="removeToken()">{{'REMOVE_SECURITY_TOKEN'|translate}}</button>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" *ngIf="company" id="siapModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="siapModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="siapModalTitle">{{'IMPORT_USERS'|translate}}</h5>
        <button type="button" class="close" (click)="toggleSiapModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{'IMPORT_USERS_TEXT'|translate}}
      </div>

      <hr/>

      <div class="text-right buttonrow">
        <button type="button" mat-button (click)="toggleSiapModal()">{{'ABORT'|translate}}</button>
        <button *ngIf="maincomponent.loggedUser.role.superAdmin" type="button" mat-button (click)="importUsers()">{{'IMPORT_USERS'|translate}}</button>
      </div>

    </div>
  </div>
</div>
