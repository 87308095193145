<custom-title></custom-title>
<div class="padding20">
  <mat-card appearance="outlined" class="p-3">
    <h6>{{ 'MINORS_REQUEST_TITLE' | translate }}</h6>
    <ng-container *ngIf="views[activeViw] === 'SHOW_INFO'">
      <div class="mb-2">{{ 'MINORS_REQUEST_SUBTITLE' | translate }}</div>
      <mat-card-content>
        <div class="row mb-2 mx-2">
          <div class="col-1 text-center align-content-center">
            <mat-icon class="circle-icon">child_care</mat-icon>
          </div>
          <div class="col-11">
            {{ 'MINOR_INFO.P1' | translate }}
          </div>
        </div>
        <div class="row mb-2 mx-2">
          <div class="col-1 text-center align-content-center">
            <mat-icon class="circle-icon">done</mat-icon>
          </div>
          <div class="col-11">
            <strong>{{ 'MINOR_INFO.P2.1' | translate }}</strong><br/>
            {{ 'MINOR_INFO.P2.2' | translate }}
          </div>
        </div>
        <div class="row mb-2 mx-2">
          <div class="col-1 text-center align-content-center">
            <mat-icon class="circle-icon">email</mat-icon>
          </div>
          <div class="col-11">
            <strong>{{ 'MINOR_INFO.P3.1' | translate }}</strong><br/>
            {{ 'MINOR_INFO.P3.1' | translate }}
          </div>
        </div>
        <div class="row mb-2 mx-2">
          <div class="col-1 text-center align-content-center">
            <mat-icon class="circle-icon">warning</mat-icon>
          </div>
          <div class="col-11">
            {{ 'MINOR_INFO.P4.1' | translate }}
            <strong>{{ 'MINOR_INFO.P4.2' | translate }}</strong><br/>
          </div>
        </div>
      </mat-card-content>
      <div class="row">
        <button mat-button (click)="activeViw =+ 1" color="primary">
          <mat-icon>edit</mat-icon>
          {{ 'FILL_MODULE' | translate }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="views[activeViw] === 'CHILD'">

      <div class="mb-2">{{ 'FILL_MODULE_SUBTITLE' | translate }}</div>
      <mat-card-content>
        <div class="col-12">
          <div class="row">
            <div class="col-4" *ngFor="let childRequest of childRequests; let i = index;">
              <div *ngIf="childRequests.length > 1" class="text-right">
                <div class="pointer" (click)="removeChild(i)">&times;</div>
              </div>
              {{ 'MINOR_DATA' | translate }} {{ i + 1 }}
              <mat-divider></mat-divider>
              <div class="row px-4 py-2">
                <form [formGroup]="childRequest" style="width:100%">
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'NAME' | translate}}</mat-label>
                      <input matInput formControlName="name" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'SURNAME' | translate}}</mat-label>
                      <input matInput formControlName="surname" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'BIRTHDAY' | translate}}</mat-label>
                      <input matInput readonly [matDatepicker]="jj" placeholder="{{'DEPARTURE_DATE'|translate}}" (click)="jj.open()" formControlName="birthday"
                             name="date{{i}}">
                      <mat-datepicker-toggle matSuffix [for]="jj"></mat-datepicker-toggle>
                      <mat-datepicker #jj></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'ADDRESS' | translate}}</mat-label>
                      <input matInput formControlName="address" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'CITY' | translate}}</mat-label>
                      <input matInput formControlName="city" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'POSTAL_CODE' | translate}}</mat-label>
                      <input matInput formControlName="postal_code" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'LANGUAGE' | translate}}</mat-label>
                      <input matInput formControlName="language" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'DISEASES' | translate}}</mat-label>
                      <input matInput formControlName="diseases" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'OBSERVATIONS' | translate}}</mat-label>
                      <input matInput formControlName="other" type="text">
                    </mat-form-field>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <div class="row">
        <div class="col-6 text-left">
          <button mat-button (click)="activeViw = activeViw - 1">
            <mat-icon>arrow_left_alt</mat-icon>
            {{ 'BACK' | translate }}
          </button>
        </div>
        <div class="col-6 text-right">
          <button mat-button (click)="addChild()">
            <mat-icon>add</mat-icon>
            {{ 'ADD_CHILD' | translate }}
          </button>
          <button mat-button (click)="activeViw = activeViw + 1" [disabled]="!allChildrenCompleted()">
            <mat-icon>arrow_right_alt</mat-icon>
            {{ 'ADD_FLIGHT' | translate }}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="views[activeViw] === 'FLIGHT'">
      <div class="mb-2">{{ 'FILL_MODULE_SUBTITLE' | translate }}</div>
      <mat-card-content>
        <div class="col-12">
          <div class="row">
            <div class="col-12" *ngFor="let flightRequest of flightRequests; let i = index;">
              <div *ngIf="childRequests.length > 1" class="text-right">
                <div class="pointer" (click)="removeChild(i)">&times;</div>
              </div>
              {{ 'FLIGHT' | translate }} {{ i + 1 }}
              <mat-divider></mat-divider>
              <div class="px-2 py-2">
                <form [formGroup]="flightRequest" class="row mb-2">
                  <div class="col-4 px-2">
                    <div class="row">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'DEPARTURE' | translate}}</mat-label>
                        <input matInput [matAutocomplete]="auto_dep" formControlName="departure">
                      </mat-form-field>
                      <mat-autocomplete class="full-width" #auto_dep="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngIf="isDepartureLoading" class="is-loading">
                          <mat-spinner diameter="50"></mat-spinner>
                        </mat-option>
                        <ng-container *ngIf="!isDepartureLoading">
                          <mat-option *ngFor="let airport of filteredDeparture" [value]="airport">
                            <span>{{airport.iata}}</span>-<small>{{airport.name}}</small>
                          </mat-option>
                        </ng-container>
                      </mat-autocomplete>
                    </div>
                    <div class="row">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'ARRIVAL' | translate}}</mat-label>
                        <input matInput [matAutocomplete]="auto_arr" formControlName="arrival">
                      </mat-form-field>
                      <mat-autocomplete class="full-width" #auto_arr="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngIf="isArrivalLoading" class="is-loading">
                          <mat-spinner diameter="50"></mat-spinner>
                        </mat-option>
                        <ng-container *ngIf="!isArrivalLoading">
                          <mat-option *ngFor="let airport of filteredArrival" [value]="airport">
                            <span>{{airport.iata}}</span>-<small>{{airport.name}}</small>
                          </mat-option>
                        </ng-container>
                      </mat-autocomplete>
                    </div>
                    <div class="row">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'DATE' | translate}}</mat-label>
                        <input matInput readonly [matDatepicker]="jj" placeholder="{{'DEPARTURE_DATE'|translate}}" (click)="jj.open()" formControlName="date"
                               name="date{{i}}">
                        <mat-datepicker-toggle matSuffix [for]="jj"></mat-datepicker-toggle>
                        <mat-datepicker #jj></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'TIME' | translate}}</mat-label>
                        <mat-select formControlName="time_slot" name="time">
                          <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                          <mat-option value="2">{{'MORNING'|translate}} (06-12)</mat-option>
                          <mat-option value="3">{{'AFTERNOON'|translate}} (12-18)</mat-option>
                          <mat-option value="4">{{'EVENING'|translate}} (18-24)</mat-option>
                          <mat-option value="1">{{'NIGHT'|translate}} (00-06)</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                    {{'PERSON_GOING_TO_AIRPORT' | translate}}
                    <mat-divider></mat-divider>
                    <div class="row">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'NAME' | translate}}</mat-label>
                        <input matInput formControlName="departure_name" type="text">
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'SURNAME' | translate}}</mat-label>
                        <input matInput formControlName="departure_surname" type="text">
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'CITY' | translate}}</mat-label>
                        <input matInput formControlName="departure_city" type="text">
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'POSTAL_CODE' | translate}}</mat-label>
                        <input matInput formControlName="departure_postal_code" type="text">
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'ADDRESS' | translate}}</mat-label>
                        <input matInput formControlName="departure_address" type="text">
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'CELLULAR' | translate}}</mat-label>
                        <input matInput formControlName="departure_cellular" type="text">
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'PHONE_NUMBER' | translate}}</mat-label>
                        <input matInput formControlName="departure_phone_number" type="text">
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'FAMILY' | translate}}</mat-label>
                        <input matInput formControlName="departure_family" type="text">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                  {{'PERSON_WAITING_AT_AIRPORT' | translate}}
                  <mat-divider></mat-divider>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'NAME' | translate}}</mat-label>
                      <input matInput formControlName="arrival_name" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'SURNAME' | translate}}</mat-label>
                      <input matInput formControlName="arrival_surname" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'CITY' | translate}}</mat-label>
                      <input matInput formControlName="arrival_city" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'POSTAL_CODE' | translate}}</mat-label>
                      <input matInput formControlName="arrival_postal_code" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'ADDRESS' | translate}}</mat-label>
                      <input matInput formControlName="arrival_address" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'CELLULAR' | translate}}</mat-label>
                      <input matInput formControlName="arrival_cellular" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'PHONE_NUMBER' | translate}}</mat-label>
                      <input matInput formControlName="arrival_phone_number" type="text">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="full-width">
                      <mat-label>{{ 'FAMILY' | translate}}</mat-label>
                      <input matInput formControlName="arrival_family" type="text">
                    </mat-form-field>
                  </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <div class="row">
        <div class="col-6 text-left">
          <button mat-button (click)="activeViw = activeViw - 1">
            <mat-icon>arrow_left_alt</mat-icon>
            {{ 'BACK' | translate }}
          </button>
        </div>
        <div class="col-6 text-right">
          <button mat-button (click)="addFlight()">
            <mat-icon>add</mat-icon>
            {{ 'ADD_FLIGHT' | translate }}
          </button>
          <button mat-button (click)="sendRequest()" [disabled]="!allFlightsCompleted()">
            <mat-icon>arrow_right_alt</mat-icon>
            {{ 'SEND_REQUEST' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </mat-card>
</div>
