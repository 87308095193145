import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-banner-slide',
  templateUrl: './banner-slide.component.html',
  styleUrls: ['./banner-slide.component.scss']
})
export class BannerSlideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('#carouselExampleIndicators').carousel({
      interval: 3000,
      cycle: true
    });
  }

}
