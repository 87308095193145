<custom-title></custom-title>
<div class="padding20">
  <div class="wbg borderbt padding5">

    <div class="row">

      <div class="col-lg-6 d-flex justify-content-start text-left">
        <div class="paginationr pl-3">
          <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>

          <span>
            {{'PAGE'|translate}}
            <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
              <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p+1}}</mat-option>
              </mat-select>
            </mat-form-field>
            {{'OF'|translate}} {{maxpages}}
          </span>

          <button mat-icon-button class="iconButtons" (click)="prev()" [disabled]="currentpage==0">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button mat-icon-button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>

      </div>

      <div class="col-lg-6 text-right">
        <button mat-button color="primary" (click)="toggleAddEditFees()">{{'ADD_FEE'|translate}}</button>
      </div>
    </div>

  </div>
  <div class="table-responsive wbg marg table-fees" *ngIf="fees">
    <table class="table table-sm table-bordered table-fees">
      <thead>
      <tr>
        <th scope="col">Update</th>
        <th scope="col">Fee name</th>
        <!--
        <th scope="col" *ngIf="userProfile.company.services.flight">Volo</th>
        <th scope="col" *ngIf="userProfile.company.services.flight && userProfile.company.services.addonFlight.lowcost">LowCost</th>
        <th scope="col" *ngIf="userProfile.company.services.flight && userProfile.company.services.addonFlight.ndc">NDC</th>
        <th scope="col" *ngIf="userProfile.company.services.hotel">Hotel</th>
        <th scope="col" *ngIf="userProfile.company.services.car">Car</th>
        <th scope="col" *ngIf="userProfile.company.services.train && userProfile.company.services.addonTrain.italo">Italo</th>
        <th scope="col" *ngIf="userProfile.company.services.train && userProfile.company.services.addonTrain.trenitalia">Trenitalia</th>
        -->
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fee of fees.items">
          <td>{{fee.updatedAt|date:'dd/MM/yyyy'}}</td>
          <td>{{fee.name}}</td>
          <!--
          <td *ngIf="userProfile.company.services.flight">
            + {{fee.flight.amount|number:'.2-2'}} € for {{fee.flight.feeServiceFor}}
          </td>
          <td *ngIf="userProfile.company.services.flight && userProfile.company.services.addonFlight.lowcost">
            + {{fee.lowCost.amount|number:'.2-2'}} € for {{fee.lowCost.feeServiceFor}}
          </td>
          <td *ngIf="userProfile.company.services.flight && userProfile.company.services.addonFlight.ndc">
            + {{fee.ndc?.amount|number:'.2-2'}} € for {{fee.ndc?.feeServiceFor}}
          </td>
          <td *ngIf="userProfile.company.services.hotel">
            + {{fee.hotel.amount|number:'.2-2'}} € for {{fee.hotel.feeServiceFor}}
          </td>
          <td *ngIf="userProfile.company.services.car">
            + {{fee.car.amount|number:'.2-2'}} € for {{fee.car.feeServiceFor}}
          </td>
          <td *ngIf="userProfile.company.services.train && userProfile.company.services.addonTrain.italo">
            + {{fee.italo.amount|number:'.2-2'}} € for {{fee.italo.feeServiceFor}}
          </td>
          <td *ngIf="userProfile.company.services.hotel && userProfile.company.services.addonTrain.trenitalia">
            + {{fee.trenitalia.amount|number:'.2-2'}} € for {{fee.trenitalia.feeServiceFor}}
          </td>
          -->
          <td>
            <span class="material-icons pointer mr-2" [matTooltip]="'EDIT'|translate" (click)="toggleAddEditFees(fee)">
              edit
            </span>
            <span class="material-icons pointer mr-2" *ngIf="!fee.b2c || mainComponent.loggedUser.role.superAdmin" [matTooltip]="'ASSOCIATE_COMPANIES'|translate" (click)="toggleAssociation(fee)">
              sync_alt
            </span>
            <span class="material-icons pointer" [matTooltip]="'DELETE'|translate" (click)="toggleDelete(fee)">
              delete_outline
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="wbg bordertp padding5">

    <div class="row">
      <div class="col-lg-6"></div>

      <div class="col-lg-6 d-flex justify-content-end text-right">
        <div class="paginationr pl-3">
          <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>

          <span>
            {{'PAGE'|translate}}
            <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
              <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p+1}}</mat-option>
              </mat-select>
            </mat-form-field>
            {{'OF'|translate}} {{maxpages}}
          </span>

          <button mat-icon-button class="iconButtons" (click)="prev()" [disabled]="currentpage==0">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button mat-icon-button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>

      </div>
    </div>

  </div>
</div>
