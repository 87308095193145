<div class="padding5">
  <h3>{{ 'TRAIN_MODULE.AFTERSALE.VALIDATE_TRAVEL' | translate }}</h3>
  <table *ngIf="parsedValidatedTravel" class="table">
    <tbody>
    <tr *ngIf="oldPayment || parsedValidatedTravel.validatedPostSaleRecord.totalValue?.amount">
      <td>{{'TRAIN_MODULE.AFTERSALE.OLD_PAYMENT'|translate}}</td>
      <td>{{(oldPayment || parsedValidatedTravel.validatedPostSaleRecord.totalValue?.amount) | number:'.2-2'}}€</td>
    </tr>
    <tr *ngIf="parsedValidatedTravel.additionalCharges.length > 0">
      <td>{{'TRAIN_MODULE.AFTERSALE.ADDITIONAL_CHARGES'|translate}}</td>
      <td>+{{getSum(parsedValidatedTravel.additionalCharges) | number:'.2-2'}}€</td>
    </tr>
    <tr *ngIf="!!parsedValidatedTravel.validatedPostSaleRecord.postSalePaymentPenalties">
      <td>{{'TRAIN_MODULE.AFTERSALE.PENALTY'|translate}}</td>
      <td>-{{getPenaltySum(parsedValidatedTravel.validatedPostSaleRecord.postSalePaymentPenalties) | number:'.2-2'}}€</td>
    </tr>
    <tr *ngIf="parsedValidatedTravel.reusedPaymentRecords.length > 0">
      <td>{{'TRAIN_MODULE.AFTERSALE.PAID'|translate}}</td>
      <td>-{{getSum(parsedValidatedTravel.reusedPaymentRecords) | number:'.2-2'}}€</td>
    </tr>
    <tr *ngFor="let pay of parsedValidatedTravel.reversedPaymentRecords">
      <td>{{'TRAIN_MODULE.AFTERSALE.REFUND'|translate}}</td>
      <td>{{pay.amount.amount | number:'.2-2'}}€</td>
    </tr>
    <tr>
      <td>{{'TRAIN_MODULE.AFTERSALE.MISSINGAMOUNT'|translate}}</td>
      <td>{{parsedValidatedTravel.missingAmount.amount | number:'.2-2'}}€</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="parsedValidatedTravel.validatedPostSaleRecord.allowed === 'false'" class="alert alert-warning">
    <mat-icon>warning</mat-icon>
    {{parsedValidatedTravel.validatedPostSaleRecord.messages}}
  </div>
  <div *ngIf="parsedValidatedTravel.validatedPostSaleRecord.allowed === 'true'" class="alert alert-success">
    <mat-icon>done</mat-icon>
    {{ 'TRAIN_MODULE.AFTERSALE.POSSIBLE_CHANGE' | translate }}
  </div>
</div>
