/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CaptureRequestDto } from '../models/CaptureRequestDto';
import { PaginationOrder } from '../models/PaginationOrder';
import { TransactionRequestDto } from '../models/TransactionRequestDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class TransactionsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public transactionControllerStart(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/transaction/job',
        });
    }

    /**
     * @param refId
     * @returns any
     * @throws ApiError
     */
    public transactionControllerFindOne(
        refId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/transaction/{refId}',
            path: {
                'refId': refId,
            },
        });
    }

    /**
     * @param refId
     * @returns any
     * @throws ApiError
     */
    public transactionControllerUpdateOne(
        refId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/v3/transaction/{refId}',
            path: {
                'refId': refId,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public transactionControllerRetrieve(
        id: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/transaction/payments/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param company
     * @param limit
     * @param skip
     * @param text
     * @param order
     * @param sort
     * @param service
     * @returns any
     * @throws ApiError
     */
    public transactionControllerFindAll(
        company: string,
        limit: number = 20,
        skip?: number,
        text?: number,
        order?: PaginationOrder,
        sort?: string,
        service?: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/transaction',
            query: {
                'limit': limit,
                'skip': skip,
                'text': text,
                'order': order,
                'sort': sort,
                'company': company,
                'service': service,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public transactionControllerPay(
        requestBody: TransactionRequestDto,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v3/transaction/pay',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public transactionControllerCancelOrCaptureFunds(
        requestBody: CaptureRequestDto,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v3/transaction/{refId}/capture',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
