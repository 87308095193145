<div>
    <div class="row" *ngIf="!loadingCustom">
        <div class="col-md-3">
            <div class="paddingContent">
                <div class="cdTitle">
                    {{'AVAILABLE_FIELDS_TYPES'|translate}} 
                </div>
                <div class="cdSubtitle">
                    {{'INPUT_TEXT'|translate}} 
                </div>
                
            </div>
            <hr/>
            <div class="addField" *ngFor="let field of fieldTypes; let i = index" (click)="addField(field)">
                {{field|translate}}
            </div>
        </div>
        <div class="col-md-6">
            <div class="cdTitle">
                {{'EDIT_FIELDS'|translate}}
            </div>
            <div class="singleField" *ngFor="let field of form;let i =index">
                <div class="row">
                    <div class="col-8 align-self-center">
                        <div>
                            {{'FIELD_TYPE'|translate}}: {{field.fieldType|translate}}
                        </div>
                        <div class="d-inline mr-1 text-danger small" *ngIf="!field.fieldName">
                            {{'MISSING_NAME'|translate}} 
                        </div>
                        <div class="d-inline mr-1 text-primary small" *ngIf="field.fieldName">
                            {{field.fieldName}}
                        </div>
                        <div class="d-inline mr-1 small" *ngIf="field.isOptional">
                            {{'OPTIONAL'|translate}}  
                        </div>
                        <div class="d-inline mr-1 small" *ngIf="!field.isOptional">
                            {{'MANDATORY_FIELD'|translate}}  
                        </div>
                        <div class="d-inline mr-1 small" *ngIf="field.siapField">
                            {{'SIAP_FIELD'|translate}}: 
                            <span *ngIf="field.siapField != 'C0'">{{field.siapField}}</span>
                            <span *ngIf="field.siapField == 'C0'">C10</span>
                        </div>
                        <div class="d-inline mr-1 text-danger small" *ngIf="!field.siapField">
                            {{'MISSING_SIAP_FIELD'|translate}}
                        </div>
                        <div class="d-inline mr-1 text-danger small" *ngIf="optionNeeded(field)">
                            {{'MISSING_OPTIONS'|translate}}
                        </div>
                        <div class="small" *ngIf="field.fieldOptions.length > 0">
                            {{'OPTIONS'|translate}}: {{field.fieldOptions.length}}
                        </div>
                    </div>
                    <div class="col-4 text-right align-self-center">
                        <span class="ml-2 material-icons pointer" (click)="editField(field, i)">settings</span>
                        <span class="ml-2 material-icons pointer" (click)="removeField(i)">delete_outline</span>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-3"></div>
        <div class="col-6 text-right">
            
            <button [disabled]="!isValidForm()" class="actionButton" (click)="saveCustomFields()">{{'SAVE'|translate}}</button>
        </div>
    </div>
    
    
    

</div>
