import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as $ from 'jquery';
import {AdmserviceService} from '../../../../services/adminserv/admservice.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from '../../../../app.component';
export interface Country {
  value: string;
  viewValue: string;
}

export interface Region {
  value: string;
  viewValue: string;
}

export interface Provincia {
  nome: string;
  sigla: string;
  regione: string;
}

export interface Province {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.scss']
})
export class AddProfileComponent implements OnInit {
  countries: Country[] = [
    { value: 'IT', viewValue: 'Italia' },
  ];
  regions: Region[] = [
    { value: 'Abruzzo', viewValue: 'Abruzzo' },
    { value: 'Basilicata', viewValue: 'Basilicata' },
    { value: 'Calabria', viewValue: 'Calabria' },
    { value: 'Campania', viewValue: 'Campania' },
    { value: 'Emilia-Romagna', viewValue: 'Emilia-Romagna' },
    { value: 'Friuli-Venezia Giulia', viewValue: 'Friuli-Venezia Giulia' },
    { value: 'Lazio', viewValue: 'Lazio' },
    { value: 'Liguria', viewValue: 'Liguria' },
    { value: 'Lombardia', viewValue: 'Lombardia' },
    { value: 'Marche', viewValue: 'Marche' },
    { value: 'Molise', viewValue: 'Molise' },
    { value: 'Piemonte', viewValue: 'Piemonte' },
    { value: 'Puglia', viewValue: 'Puglia' },
    { value: 'Sardegna', viewValue: 'Sardegna' },
    { value: 'Sicilia', viewValue: 'Sicilia' },
    { value: 'Toscana', viewValue: 'Toscana' },
    { value: 'Trentino-Alto Adige', viewValue: 'Trentino-Alto Adige' },
    { value: 'Umbria', viewValue: 'Umbria' },
    { value: 'Valle d\'Aosta', viewValue: 'Valle d\'Aosta' },
    { value: 'Veneto', viewValue: 'Veneto' },
  ];
  province: Provincia[] = [
    {
      nome: 'Agrigento',
      sigla: 'AG',
      regione: 'Sicilia',
    },
    {
      nome: 'Alessandria',
      sigla: 'AL',
      regione: 'Piemonte',
    },
    {
      nome: 'Ancona',
      sigla: 'AN',
      regione: 'Marche',
    },
    {
      nome: 'Arezzo',
      sigla: 'AR',
      regione: 'Toscana',
    },
    {
      nome: 'Ascoli Piceno',
      sigla: 'AP',
      regione: 'Marche',
    },
    {
      nome: 'Asti',
      sigla: 'AT',
      regione: 'Piemonte',
    },
    {
      nome: 'Avellino',
      sigla: 'AV',
      regione: 'Campania',
    },
    {
      nome: 'Bari',
      sigla: 'BA',
      regione: 'Puglia',
    },
    {
      nome: 'Barletta-Andria-Trani',
      sigla: 'BT',
      regione: 'Puglia',
    },
    {
      nome: 'Belluno',
      sigla: 'BL',
      regione: 'Veneto',
    },
    {
      nome: 'Benevento',
      sigla: 'BN',
      regione: 'Campania',
    },
    {
      nome: 'Bergamo',
      sigla: 'BG',
      regione: 'Lombardia',
    },
    {
      nome: 'Biella',
      sigla: 'BI',
      regione: 'Piemonte',
    },
    {
      nome: 'Bologna',
      sigla: 'BO',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Bolzano/Bozen',
      sigla: 'BZ',
      regione: 'Trentino-Alto Adige',
    },
    {
      nome: 'Brescia',
      sigla: 'BS',
      regione: 'Lombardia',
    },
    {
      nome: 'Brindisi',
      sigla: 'BR',
      regione: 'Puglia',
    },
    {
      nome: 'Cagliari',
      sigla: 'CA',
      regione: 'Sardegna',
    },
    {
      nome: 'Caltanissetta',
      sigla: 'CL',
      regione: 'Sicilia',
    },
    {
      nome: 'Campobasso',
      sigla: 'CB',
      regione: 'Molise',
    },
    {
      nome: 'Carbonia-Iglesias',
      sigla: 'CI',
      regione: 'Sardegna',
    },
    {
      nome: 'Caserta',
      sigla: 'CE',
      regione: 'Campania',
    },
    {
      nome: 'Catania',
      sigla: 'CT',
      regione: 'Sicilia',
    },
    {
      nome: 'Catanzaro',
      sigla: 'CZ',
      regione: 'Calabria',
    },
    {
      nome: 'Chieti',
      sigla: 'CH',
      regione: 'Abruzzo',
    },
    {
      nome: 'Como',
      sigla: 'CO',
      regione: 'Lombardia',
    },
    {
      nome: 'Cosenza',
      sigla: 'CS',
      regione: 'Calabria',
    },
    {
      nome: 'Cremona',
      sigla: 'CR',
      regione: 'Lombardia',
    },
    {
      nome: 'Crotone',
      sigla: 'KR',
      regione: 'Calabria',
    },
    {
      nome: 'Cuneo',
      sigla: 'CN',
      regione: 'Piemonte',
    },
    {
      nome: 'Enna',
      sigla: 'EN',
      regione: 'Sicilia',
    },
    {
      nome: 'Fermo',
      sigla: 'FM',
      regione: 'Marche',
    },
    {
      nome: 'Ferrara',
      sigla: 'FE',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Firenze',
      sigla: 'FI',
      regione: 'Toscana',
    },
    {
      nome: 'Foggia',
      sigla: 'FG',
      regione: 'Puglia',
    },
    {
      nome: 'Forlì-Cesena',
      sigla: 'FC',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Frosinone',
      sigla: 'FR',
      regione: 'Lazio',
    },
    {
      nome: 'Genova',
      sigla: 'GE',
      regione: 'Liguria',
    },
    {
      nome: 'Gorizia',
      sigla: 'GO',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Grosseto',
      sigla: 'GR',
      regione: 'Toscana',
    },
    {
      nome: 'Imperia',
      sigla: 'IM',
      regione: 'Liguria',
    },
    {
      nome: 'Isernia',
      sigla: 'IS',
      regione: 'Molise',
    },
    {
      nome: 'L\'Aquila',
      sigla: 'AQ',
      regione: 'Abruzzo',
    },
    {
      nome: 'La Spezia',
      sigla: 'SP',
      regione: 'Liguria',
    },
    {
      nome: 'Latina',
      sigla: 'LT',
      regione: 'Lazio',
    },
    {
      nome: 'Lecce',
      sigla: 'LE',
      regione: 'Puglia',
    },
    {
      nome: 'Lecco',
      sigla: 'LC',
      regione: 'Lombardia',
    },
    {
      nome: 'Livorno',
      sigla: 'LI',
      regione: 'Toscana',
    },
    {
      nome: 'Lodi',
      sigla: 'LO',
      regione: 'Lombardia',
    },
    {
      nome: 'Lucca',
      sigla: 'LU',
      regione: 'Toscana',
    },
    {
      nome: 'Macerata',
      sigla: 'MC',
      regione: 'Marche',
    },
    {
      nome: 'Mantova',
      sigla: 'MN',
      regione: 'Lombardia',
    },
    {
      nome: 'Massa-Carrara',
      sigla: 'MS',
      regione: 'Toscana',
    },
    {
      nome: 'Matera',
      sigla: 'MT',
      regione: 'Basilicata',
    },
    {
      nome: 'Medio Campidano',
      sigla: 'VS',
      regione: 'Sardegna',
    },
    {
      nome: 'Messina',
      sigla: 'ME',
      regione: 'Sicilia',
    },
    {
      nome: 'Milano',
      sigla: 'MI',
      regione: 'Lombardia',
    },
    {
      nome: 'Modena',
      sigla: 'MO',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Monza e della Brianza',
      sigla: 'MB',
      regione: 'Lombardia',
    },
    {
      nome: 'Napoli',
      sigla: 'NA',
      regione: 'Campania',
    },
    {
      nome: 'Novara',
      sigla: 'NO',
      regione: 'Piemonte',
    },
    {
      nome: 'Nuoro',
      sigla: 'NU',
      regione: 'Sardegna',
    },
    {
      nome: 'Ogliastra',
      sigla: 'OG',
      regione: 'Sardegna',
    },
    {
      nome: 'Olbia-Tempio',
      sigla: 'OT',
      regione: 'Sardegna',
    },
    {
      nome: 'Oristano',
      sigla: 'OR',
      regione: 'Sardegna',
    },
    {
      nome: 'Padova',
      sigla: 'PD',
      regione: 'Veneto',
    },
    {
      nome: 'Palermo',
      sigla: 'PA',
      regione: 'Sicilia',
    },
    {
      nome: 'Parma',
      sigla: 'PR',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Pavia',
      sigla: 'PV',
      regione: 'Lombardia',
    },
    {
      nome: 'Perugia',
      sigla: 'PG',
      regione: 'Umbria',
    },
    {
      nome: 'Pesaro e Urbino',
      sigla: 'PU',
      regione: 'Marche',
    },
    {
      nome: 'Pescara',
      sigla: 'PE',
      regione: 'Abruzzo',
    },
    {
      nome: 'Piacenza',
      sigla: 'PC',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Pisa',
      sigla: 'PI',
      regione: 'Toscana',
    },
    {
      nome: 'Pistoia',
      sigla: 'PT',
      regione: 'Toscana',
    },
    {
      nome: 'Pordenone',
      sigla: 'PN',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Potenza',
      sigla: 'PZ',
      regione: 'Basilicata',
    },
    {
      nome: 'Prato',
      sigla: 'PO',
      regione: 'Toscana',
    },
    {
      nome: 'Ragusa',
      sigla: 'RG',
      regione: 'Sicilia',
    },
    {
      nome: 'Ravenna',
      sigla: 'RA',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Reggio di Calabria',
      sigla: 'RC',
      regione: 'Calabria',
    },
    {
      nome: 'Reggio nell\'Emilia',
      sigla: 'RE',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Rieti',
      sigla: 'RI',
      regione: 'Lazio',
    },
    {
      nome: 'Rimini',
      sigla: 'RN',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Roma',
      sigla: 'RM',
      regione: 'Lazio',
    },
    {
      nome: 'Rovigo',
      sigla: 'RO',
      regione: 'Veneto',
    },
    {
      nome: 'Salerno',
      sigla: 'SA',
      regione: 'Campania',
    },
    {
      nome: 'Sassari',
      sigla: 'SS',
      regione: 'Sardegna',
    },
    {
      nome: 'Savona',
      sigla: 'SV',
      regione: 'Liguria',
    },
    {
      nome: 'Siena',
      sigla: 'SI',
      regione: 'Toscana',
    },
    {
      nome: 'Siracusa',
      sigla: 'SR',
      regione: 'Sicilia',
    },
    {
      nome: 'Sondrio',
      sigla: 'SO',
      regione: 'Lombardia',
    },
    {
      nome: 'Taranto',
      sigla: 'TA',
      regione: 'Puglia',
    },
    {
      nome: 'Teramo',
      sigla: 'TE',
      regione: 'Abruzzo',
    },
    {
      nome: 'Terni',
      sigla: 'TR',
      regione: 'Umbria',
    },
    {
      nome: 'Torino',
      sigla: 'TO',
      regione: 'Piemonte',
    },
    {
      nome: 'Trapani',
      sigla: 'TP',
      regione: 'Sicilia',
    },
    {
      nome: 'Trento',
      sigla: 'TN',
      regione: 'Trentino-Alto Adige',
    },
    {
      nome: 'Treviso',
      sigla: 'TV',
      regione: 'Veneto',
    },
    {
      nome: 'Trieste',
      sigla: 'TS',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Udine',
      sigla: 'UD',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Valle d\'Aosta',
      sigla: 'AO',
      regione: 'Valle d\'Aosta',
    },
    {
      nome: 'Varese',
      sigla: 'VA',
      regione: 'Lombardia',
    },
    {
      nome: 'Venezia',
      sigla: 'VE',
      regione: 'Veneto',
    },
    {
      nome: 'Verbano-Cusio-Ossola',
      sigla: 'VB',
      regione: 'Piemonte',
    },
    {
      nome: 'Vercelli',
      sigla: 'VC',
      regione: 'Piemonte',
    },
    {
      nome: 'Verona',
      sigla: 'VR',
      regione: 'Veneto',
    },
    {
      nome: 'Vibo Valentia',
      sigla: 'VV',
      regione: 'Calabria',
    },
    {
      nome: 'Vicenza',
      sigla: 'VI',
      regione: 'Veneto',
    },
    {
      nome: 'Viterbo',
      sigla: 'VT',
      regione: 'Lazio',
    },
  ];
  provinces: Province[] = [];
  cpProvinces: Province[] = [];
  currentStep = 0;
  barValue: number;
  color: string;
  sex: string[] = ['M', 'F'];
  plans = ['Incetivazione a scaglioni', 'Incentivo fisso'];
  selectedPlan = 'Incetivazione a scaglioni';
  userdefaultlevel = 3;
  companyLevels: any[];
  companyType: any[];
  company: any;
  userform: any;
  today = new Date();
  passwordconfirm: string;
  hide = true;
  importCp = false;
  hideConfirm = true;
  newagency = false;
  usenewaddress = true;
  usenewcompanyaddress = true;
  incentives: any;
  isImported: boolean;
  agencyType: any[];
  selectedValue: string;
  loading = false;
  isNotItalyUser = false;
  isNotItalyCompany : boolean = false;
  disableToggle : boolean = false;
  phoneNumberPattern : string = '^[0-9]+$';
  spaces : boolean = false;
  constructor(
    private admService: AdmserviceService,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    public appComponent: AppComponent,
  ) {
    this.isImported = false;
    this.createCp();
    this.createForm();
  }

  changeCountry(state : any){
    this.isNotItalyUser = state;
    this.userform.user.location.country = '';
    if(this.isNotItalyUser === false){
      this.userform.user.location.country = 'IT';
    }
  }
  changeCountryCompany(event: any){
    this.isNotItalyCompany = event;
    this.userform.company.location.country = '';
    if(this.isNotItalyCompany === false){
      this.userform.company.location.country = 'IT';
    }
  }

  createForm() {
    this.currentStep = 0;
    this.companyLevels = [
      {
        name: 'System Administrator',
        level: 0,
        trad: 'AMMINISTRATORE_SISTEMA'
      },
      {
        name: 'Amministratore',
        level: 1,
        trad: 'AMMINISTRATORE'
      },
      {
        name: 'Manager',
        level: 2,
        trad: 'MANAGER'
      },
      {
        name: 'Utente',
        level: 3,
        trad: 'USER'
      },
      {
        name: 'customer',
        level: 5,
        trad: 'CUSTOMER'
      }
    ];
    this.userform = {
      user: {
        email: '',
        password: '',
        enable: true,
        role: this.userdefaultlevel,
        name: '',
        surname: '',
        phoneNumber: '',
        sex: 'M',
        birthday: null,
        location: {
          country: 'IT',
          region: '',
          province: '',
          city: '',
          postalCode: '',
          address: ''
        }
      },
      company: this.company,
    };
    if (!this.appComponent.isGat && !this.appComponent.isSbt && !this.appComponent.isB2B){
      this.companyType = [
        {
          name: 'Agenzia',
          value: 'A',
          trad: 'AGENZIA'
        },
        {
          name: 'Company',
          value: 'C',
          trad: 'COMPANY'
        }
      ];
    } else if (this.appComponent.isSbt || this.appComponent.isB2B){
      this.companyType = [
        {
          name: 'Company',
          value: 'C',
          trad: 'COMPANY'
        }
      ];
    } else if (this.appComponent.isGat){
      this.companyType = [
        {
          name: 'Agenzia',
          value: 'A',
          trad: 'AGENZIA'
        }
      ];
      this.userform.company.type = 'A';
    }
  }

  createCp() {
    this.company = {
      name: '',
      pIva: '',
      email: '',
      phoneNumber: '',
      type: '',
      subordinatedOf: '',
      amadeusProfile: '',
      dkNumber: '',
      incentivePlan: '',
      location: {
        country: '',
        region: '',
        province: '',
        city: '',
        postalCode: '',
        address: ''
      }
    };
    if (this.appComponent.isSbt || this.appComponent.isB2B) {
      this.company.type = 'C';
    }
    if (this.appComponent.isGat) {
      this.company.type = 'A';
    }
  }

  ngOnInit(): void {
    this.getIncetives();
  }

  resetCompany(): void {
    this.createCp();
    if (this.newagency) {
      this.companyLevels = [
        {
          name: 'System Administrator',
          level: 0,
          trad: 'AMMINISTRATORE_SISTEMA'
        },
        {
          name: 'Amministratore',
          level: 1,
          trad: 'AMMINISTRATORE'
        },
      ];
      this.userform.user.role = 1;
    } else {
      this.userform.user.role = 3;
      this.companyLevels = [
        {
          name: 'System Administrator',
          level: 0,
          trad: 'AMMINISTRATORE_SISTEMA'
        },
        {
          name: 'Amministratore',
          level: 1,
          trad: 'AMMINISTRATORE'
        },
        {
          name: 'Manager',
          level: 2,
          trad: 'MANAGER'
        },
        {
          name: 'Utente',
          level: 3,
          trad: 'USER'
        }
      ];
    }
  }

  nextStep(): void {
    this.currentStep += 1;
  }

  prevStep(): void {
    this.currentStep -= 1;
  }

  measureStrength(p: string): number {
    if (!p) {
      p = 'a';
    }
    let force = 0;
    const regex = /[$-/:-?{-~!"^_`[\]]/g; // "
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);
    const flags = [lowerLetters, upperLetters, numbers, symbols];
    const passedMatches = flags.filter((isMatchedFlag: boolean) => {
      return isMatchedFlag === true;
    }).length;

    force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    force += passedMatches * 10;

    // penalty (short password)
    force = p.length <= 6 ? Math.min(force, 10) : force;

    // penalty (poor variety of characters)
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 40) : force;

    if (force < 20) {
      this.barValue = 33;
      this.color = 'warn';
    } else if (force >= 20 && force < 40) {
      this.barValue = 60;
      this.color = 'accent';
    } else {
      this.barValue = 100;
      this.color = 'primary';
    }

    return force;
  }

  setCompany(event) {
    this.userform.company = event;
  }

  setRegion() {
    this.userform.user.location.province = '';
    this.provinces = [];
    for (let i = 0; i < this.province.length; i ++) {
      if (this.userform.user.location.region === this.province[i].regione) {
        const prov = {
          value: this.province[i].sigla,
          viewValue: this.province[i].nome,
        };
        this.provinces.push(prov);
      }
    }
    console.log('this.userform => ', this.userform);
  }
  setCpRegion() {
    this.userform.company.location.province = '';
    this.cpProvinces = [];
    for (let i = 0; i < this.province.length; i ++) {
      if (this.userform.company.location.region === this.province[i].regione) {
        const prov = {
          value: this.province[i].sigla,
          viewValue: this.province[i].nome,
        };
        this.cpProvinces.push(prov);
      }
    }
    console.log('this.userform => ', this.userform);
  }

  setAddress(): void {
    if (this.usenewaddress === true) {
      this.usenewaddress = false;
      this.userform.user.location.region = this.userform.company.location.region;
      this.setRegion();
      this.userform.user.location = this.userform.company.location;
    } else {
      this.usenewaddress = true;
      this.userform.user.location = {
        country: 'IT',
        region: '',
        province: '',
        city: '',
        postalCode: '',
        address: ''
      };
    }
  }

  setCpAddress(): void {
    this.disableToggle = false;
    this.isImported = false;
    if (this.usenewcompanyaddress === true) {
      this.usenewcompanyaddress = false;
      if(this.userform.user.location.country !== 'IT') {
        this.isNotItalyCompany = true;
        this.disableToggle = true;
        this.userform.company.location = this.userform.user.location;
      } else {
        this.isNotItalyCompany = false;
        this.disableToggle = true;
        this.userform.company.location.country = this.userform.user.location.country;
        this.userform.company.location.region = this.userform.user.location.region;
        this.setCpRegion();
        this.userform.company.location.province = this.userform.user.location.province;
        this.userform.company.location.city = this.userform.user.location.city;
        this.userform.company.location.postalCode = this.userform.user.location.postalCode;
        this.userform.company.location.address = this.userform.user.location.address;
      }

    } else {
      this.usenewcompanyaddress = true;
      this.isNotItalyCompany = false;
      this.disableToggle = false;
      this.userform.company.location = {
        country: 'IT',
        region: '',
        province: '',
        city: '',
        postalCode: '',
        address: ''
      };
    }
  }

  createUser(onlyUser) {
    this.loading = true;
    const data = JSON.parse(JSON.stringify(this.userform));
    data.user.password = String(CryptoJS.MD5(this.userform.user.password));
    data.user.company = JSON.parse(JSON.stringify(this.userform.company));
    delete data.company;
    if (data.user.company._id) {
      data.user.company = data.user.company._id;
    }
    console.log('creation obj => ', data);
    if(!onlyUser){
      this.admService.addProfileCompany(data.user.company).subscribe((res) => {
        console.log(res);
        data.user.company = res._id || '';
        if (data.user.company === ''){
          throw new Error('La company non è stata salvata!!');
        }
        this.admService.addProfileUser(data.user).subscribe((res) => {
          this.loading = false;
          this.snackBar.open(this.translate.instant('USER_ADDED'), 'Ok', {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });
        });
        this.loading = false;
        this.createCp();
        this.createForm()
      }, error => {
        this.loading = false;
        console.error('Error => ', error);
        this.snackBar.open(error, 'Ok', {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
        });
      });
    } else{
      this.admService.addProfileUser(data.user).subscribe((res) => {
        this.loading = false;
        this.snackBar.open(this.translate.instant('USER_ADDED'), 'Ok', {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
        });
      },error => {
        this.loading = false;
        console.error('Error => ', error);
        this.snackBar.open(error, 'Ok', {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
        });
      });
    }
  }

  validLocation(element) {
    // tslint:disable-next-line:max-line-length
    return element.location.country && element.location.region && element.location.province && element.location.city && element.location.postalCode && element.location.address;
  }

  validForm() {
    // tslint:disable-next-line:max-line-length
    return this.validUser() && this.validCompany();
  }

  validUser() {
    // tslint:disable-next-line:max-line-length
    return this.userform.user.email && this.userform.user.password && (this.userform.user.role >= 0) && this.userform.user.name && this.userform.user.surname && this.validNumber(this.userform.user.phoneNumber) && this.userform.user.sex && this.validLocation(this.userform.user);
  }

  validCompany() {
    if (this.newagency) {
      // tslint:disable-next-line:max-line-length
      return this.userform.company.email && this.userform.company.name && this.userform.company.pIva && this.validNumber(this.userform.company.phoneNumber) && this.userform.company.type && this.validLocation(this.userform.company);
    } else {
      return this.validLocation(this.userform.company);
    }
  }

  setSubordinated(event) {
    if(event){
      this.userform.company.subordinatedOf = event._id;
      console.log('event => ', event);
      console.log('this.userform.company => ', this.userform.company);
      if(event.cau){
        this.userform.company.cau = event.cau;
      } else {
        delete this.userform.company.cau;
      }
    } else {
      delete this.userform.company.subordinatedOf;
      delete this.userform.company.cau;
    }
  }

  getIncetives() {
    this.admService.getIncetives().subscribe((res) => {
      this.incentives = res;
      if (this.appComponent.isSbt || this.appComponent.isB2B) {
        this.userform.company.incentivePlan = res.list[0]._id;
      }
      console.log('this.incentives => ', this.incentives);
    }, error => {
      console.error('Error => ', error);
    });
  }

  importCompany() {
    console.log(this.selectedValue);
    this.admService.getCpSiap(this.userform.company.dkNumber+'?type='+this.userform.company.type || '').subscribe((res) => {
      const dk = this.userform.company.dkNumber;
      this.userform.company = res;
      console.log('this.company => ', this.userform.company);
      this.userform.company.dkNumber = dk;
      if (this.userform.company.location && this.userform.company.location.province) {
        for (let i = 0; i < this.province.length; i++) {
          if (this.province[i].sigla === this.userform.company.location.province) {
            this.userform.company.location.region = this.province[i].regione;
            this.
            setCpRegion();
            setTimeout(() => {
              this.userform.company.location.province = this.province[i].sigla;
            }, 200);
          }
        }
        // province
      } else {
        this.userform.company.location = {
          country: 'IT',
          region: '',
          province: '',
          city: '',
          postalCode: '',
          address: ''
        };
      }
      if(this.appComponent.isSbt || this.appComponent.isGat)
        this.userform.company.email = '';
      this.importCp = false;
      this.isImported = true;
    }, error => {
      console.error('Error => ', error);
    });

  }

  checkSpaces(){
    /*this.spaces = false;*/
    if (this.userform.user.phoneNumber.includes(' ')){
      this.spaces = true;
    } else {
      this.spaces = false;
    }
  }

  validNumber(number : any){
    if (number.match(this.phoneNumberPattern) !== null){
      return true
    } else {
      return false
    }
  }
}
