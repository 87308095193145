import { Component, OnInit } from '@angular/core';
import {AdmserviceService} from '../../../../services/adminserv/admservice.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../../../services/profile/users.service';

@Component({
  selector: 'app-importpnr',
  templateUrl: './importpnr.component.html',
  styleUrls: ['./importpnr.component.scss']
})
export class ImportpnrComponent implements OnInit {
  newLocator: string;
  loading: boolean;
  text: string;
  userList: any;
  userToLog: any;
  constructor(
    private admService: AdmserviceService,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
  ) { }

  ngOnInit(): void {
    this.newLocator = '';
    this.text = '';
  }

  searchUser(value) {
    delete this.userList;
    if (value.length >= 2 ) {
      this.getUsersList(value);
    }
  }

  getUsersList(value) {
    delete this.userList;
    delete this.userToLog;
    this.usersService.getUsersComplete(value).subscribe((res) => {
      this.loading = false;
      this.userList = res;
      console.log('this.airports => ', this.userList);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  clear() {
    this.text = '';
    delete this.userToLog;
    delete this.userList;
  }

  getUser(value) {
    const user = value;
    delete this.userList;
    this.text = user.surname + ' ' + user.name + ' -> ' + user.company.name;
    this.userToLog = user;
  }

  selectFirst() {
    const user = this.userList[0];
    delete this.userList;
    this.text = user.surname + ' ' + user.name + ' -> ' + user.company.name;
    this.userToLog = user;
  }

  importPnr() {
    this.loading = true;
    const obj = {
      locator: this.newLocator,
      user: this.userToLog._id
    };
    this.admService.importPnr(obj).subscribe((res) => {
      this.loading = false;
      console.log(res);
      this.navigate(res._id);
    }, error => {
      this.loading = false;
      console.error('error => ', error);
    });
  }

  navigate(id) {
    this.router.navigate(['/flight/reservations/' + id]);
  }

}
