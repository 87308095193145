import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material/material.module';
import {SingleAgencyFeesComponent} from './single-agency-fees/single-agency-fees.component';
import {FeesService} from '../../sdk/transaction';
import { GenericFeesListComponent } from './generic-fees-list/generic-fees-list.component';
import {CustomCommonModule} from '../common/custom-common.module';
import { AddEditFeesComponent } from './add-edit-fees/add-edit-fees.component';
import { FeeAssociationComponent } from './fee-association/fee-association.component';
import { FeeDeleteComponent } from './fee-delete/fee-delete.component';



@NgModule({
  declarations: [
    SingleAgencyFeesComponent,
    GenericFeesListComponent,
    AddEditFeesComponent,
    FeeAssociationComponent,
    FeeDeleteComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    CustomCommonModule,
  ],
  exports:[
    SingleAgencyFeesComponent,
    GenericFeesListComponent
  ],
  providers:[
    FeesService
  ]
})
export class FeesModule { }
