import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpserviceService} from '../../services/httpservice.service';
import {Router} from '@angular/router';
import {catchError, retry} from 'rxjs/operators';
import {SnackErrorComponent} from '../../modules/errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root'
})
export class GroupsService extends HttpserviceService {
  // groups$ = this.http.get(`${this.apiUrl}groups`);
  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
  }

  getGroups(): Observable<any> {
    const userSettingsUrl = this.apiUrl + 'policy/groups/company';
    return this.http
      .get<any>(userSettingsUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }

  deleteGroup(id: string): Observable<any> {
    const userSettingsUrl = this.apiUrl + 'policy/groups/' + id;
    return this.http
      .delete<any>(userSettingsUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }
  /*
  deleteGroup(id: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}api/groups/${id}`);
  }
  */

  getOne(id: string): Observable<any> {
    const userSettingsUrl = this.apiUrl + 'policy/groups/' + id;
    return this.http
      .get<any>(userSettingsUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }
  /*
  getOne(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}api/groups/${id}`);
  }
  */

  update(payload, id: string): Observable<any> {
    const userSettingsUrl = this.apiUrl + 'policy/groups/' + id;
    return this.http
      .put<any>(userSettingsUrl, payload, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }
  /*
  update(payload: any, id: string): Observable<any> {
    return this.http.put(`${this.apiUrl}api/groups/${id}`, payload);
  }
  */

  create(payload: any): Observable<any> {
    const userSettingsUrl = this.apiUrl + 'policy/groups/';
    return this.http
      .post<any>(userSettingsUrl, payload, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }
  /*
  create(payload: any): Observable<any> {
    return this.http.post(`${this.apiUrl}api/groups`, payload);
  }
  */
}
