import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { HotelService } from '../../../../hotel.service';

@Component({
  selector: 'app-hoteltst',
  templateUrl: './hoteltst.component.html',
  styleUrls: ['./hoteltst.component.scss'],
})
export class HoteltstComponent implements OnInit{
  @Input() reservationId : any;
  loading : boolean;
  /**
   * Local variable for tst
   */
  tst : any;

  /**
   * The "constructor"
   */
  constructor(
    /**
     *  Local variable for hotelService
     */
    private hotelService : HotelService,
  ){ }

  /**
   * On init fire get tst function
   */
  ngOnInit() : void{
    this.getCryptic();
  }

  /**
   * Call tst for given reservation
   * @params {reservationId} string
   */
  getTst() : any{
    this.loading = true;
    this.hotelService.getTst(this.reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.tst = this.toText(res.message);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  /**
   * Call tst history for given reservation
   * @params {reservationId} string
   */
  getTstHistory() : any{
    this.loading = true;
    this.hotelService.getTstHistory(this.reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.tst = this.toText(res.message);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getTsm() : any{
    this.loading = true;
    this.hotelService.getTsm(this.reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.tst = this.toText(res.message);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getCryptic() : any{
    this.loading = true;
    this.hotelService.getRetrieveCryptic(this.reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.tst = this.toText(res.message);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  /**
   * Add br to tst message
   * @params {toSet} string
   */
  toText(toSet){
    return toSet.replace(new RegExp('\n', 'g'), '<br/>');
  }

}
