import { Component, OnInit, OnDestroy } from '@angular/core';
import {AdmserviceService} from '../../../../services/adminserv/admservice.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-selling-platform',
  templateUrl: './selling-platform.component.html',
  styleUrls: ['./selling-platform.component.scss']
})
export class SellingPlatformComponent implements OnInit, OnDestroy {
  session: any;
  command: any;
  md: any;
  text: any;
  rows: any[] = [];
  row: any;
  commandList: any[] = [];
  counter = 0;
  constructor(
    private adminService: AdmserviceService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.session) {
      this.signOut();
    }
  }

  signOut(): void {
    const body = {
      session: this.session,
    };
    this.adminService.closeSession(body).subscribe((res) => {
      console.log(res);
    }, error => {
      console.error('error => ', error);
    });
  }

  onEnter() {
    this.sendCommand();
  }

  prevCommand() {
    if (this.commandList.length > 0 && this.counter >= 0) {
      this.text = this.commandList[this.counter];
      this.counter = this.counter - 1;
    }
  }

  nextCommand() {
    if (this.commandList.length > 0 && this.counter !== this.commandList.length) {
      this.text = this.commandList[this.counter];
      this.counter = this.counter + 1;
    }
  }

  selectHistory(value): void {
    this.text = value;
    this.sendCommand();
  }

  sendCommand(): void {
    const body = {
      session: '',
      command: this.text,
    };
    if (!this.session) {
      delete body.session;
    } else {
      body.session = this.session;
    }
    this.adminService.sendCommand(body).subscribe((res) => {
      this.session = res.session;
      const textRow = {
        text: this.text,
        command: true,
      };
      const answerRow = {
        text: this.toText(res.message),
        command: false,
      };
      this.rows.push(textRow);
      this.rows.push(answerRow);
      this.commandList.push(this.text);
      if (this.counter === 0) {
        this.counter = this.commandList.length;
      }
      this.text = '';
      console.log(res);
      $('.terminalcontent').animate({ scrollTop: 16000 }, 1000);
    }, error => {
      if (error.errorMessage.includes('Session')) {
        delete this.session;
        this.sendCommand();
      }
      console.error('error => ', error);
      this.text = '';
    });
  }

  toText(toSet) {
    return toSet.replace(new RegExp('\n', 'g'), '<br/>');
  }

}
