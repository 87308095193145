<custom-title></custom-title>
<div class="padding20">
  <div class="card mb-2 border-danger">
    <div class="card-body">
      <div class="text-danger">
        <p>
          <b>Attenzione</b>: dopo aver importato una prenotazione è necessario <span style="font-size:16px"><u>riordinare i segmenti che la compongono</u></span>.
        </p>
        <p>Se questa operazione non viene eseguita, il sistema potrebbe riscontrare errori e cancellarla.</p>
      </div>
    </div>
  </div>
  <mat-card appearance="outlined" class="p-3">
    <div>
      <mat-form-field class="full-width">
        <mat-label>LOCATOR</mat-label>
        <input matInput type="text" [(ngModel)]="newLocator">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <input matInput placeholder="{{'USERNAME'|translate}}" aria-label="Carrier" (ngModelChange)="searchUser($event)" [(ngModel)]="text" [matAutocomplete]="users">
        <button matSuffix class="iconButtons" mat-icon-button aria-label="Clear" (click)="clear()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #users="matAutocomplete" (optionSelected)="getUser($event.option.value)" (closed)="selectFirst()">
          <mat-option *ngFor="let user of userList" [value]="user">
            <span>{{user.name}} {{user.surname}}</span>
            <span class="material-icons"> arrow_right_alt </span>
            <small>{{user.company.name}}</small>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <hr/>
    <div class="buttonrow">
      <button mat-button (click)="importPnr()" [disabled]="newLocator === '' || newLocator === null" color="primary">Import</button>
    </div>
  </mat-card>
</div>


