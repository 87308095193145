<div class="tablelist withscroll">
  <table mat-table [dataSource]="dataSource"  style="width: 100%; max-width: 100%;">

    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.surname}} {{element.name}}</td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef> Company </th>
      <td mat-cell *matCellDef="let element"> {{element.company.name}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}}</td>
    </ng-container>

    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef> Group </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.group">{{element.group.name}}</span>
        <span *ngIf="!element.group">NO_GROUP</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <button (click)="editClicked.emit(element)" mat-icon-button color="primary">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
