<div class="terminal">
  <div *ngIf="!loading && tst" class="terminalcontent">
    <div class="text-right">
      <button (click)="getCryptic()" mat-stroked-button>Get PNR</button>
      <button (click)="getTst()" mat-stroked-button>Get TST</button>
      <button (click)="getTsm()" mat-stroked-button>Get TSM</button>
      <button (click)="getTstHistory()" mat-stroked-button>See history</button>
    </div>
    <div [innerHtml]="tst"></div>
  </div>
  <div *ngIf="loading" class="terminalcontent text-center">
    {{'LOADING'|translate}}
  </div>

</div>
