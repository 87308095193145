<div class="">
  <!--
  <div *ngFor="let result of results.results" class="padding10 card mb-3">
    {{results.hotels[result.hotelCode].name}}<br/>
    Categoria: {{result.category}} | rating: {{result.rating}} <br/>
    refundable: {{result.refundable}}<br/>
    Amount: {{result.amount}}
  </div>
  -->
  <div class="row">
    <div [hidden]="!showMap" class="col-12">
      <div class="mapContainer">
        <div class="text-right mb-2">
          <button (click)="toggleMap()" matTooltip="{{'HIDE_MAP'|translate}}" mat-icon-button>
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="" >
          <div  class="mb-4" id="map">

          </div>
        </div>
      </div>

    </div>


    <div *ngIf="!showMap" class="col-lg-3">
      <div class="pr-lg-2">
        <app-banner-slide></app-banner-slide>

        <div class="mb-2">
          <mat-card appearance="outlined" class="mb-3" *ngIf="filters">
            <div class="mb-3">
              Filtra risultati
            </div>
            <hr/>
            <mat-form-field class="full-width" appearance>
              <mat-label>{{'HOTEL_NAME'|translate}}</mat-label>
              <input matInput [(ngModel)]="searchName" (ngModelChange)="doSort()">
            </mat-form-field>

            <mat-form-field class="full-width mb-2" *ngIf="filters.rating">
              <mat-label>Stelle</mat-label>
              <mat-select [(ngModel)]="seletedfilters.rating" multiple (ngModelChange)="doSort()">
                <mat-option *ngFor="let rating of filters.rating" [value]="rating">{{rating}} {{'STARS'|translate}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width mb-2" *ngIf="filters.chain">
              <mat-label>Provider</mat-label>
              <mat-select [(ngModel)]="seletedfilters.chain" multiple (ngModelChange)="doSort()">
                <mat-option *ngFor="let chain of filters.chain" [value]="chain">{{chain}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width mb-2" *ngIf="filters.services">
              <mat-label>Servizi struttura</mat-label>
              <mat-select [(ngModel)]="seletedfilters.services" multiple (ngModelChange)="doSort()">
                <mat-option *ngFor="let service of filters.services" [value]="service">{{service}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width mb-2" *ngIf="filters.roomAmenities">
              <mat-label>Servizi camera</mat-label>
              <mat-select [(ngModel)]="seletedfilters.roomAmenities" multiple (ngModelChange)="doSort()">
                <mat-option *ngFor="let amen of filters.roomAmenities" [value]="amen">{{amen}}</mat-option>
              </mat-select>
            </mat-form-field>

          </mat-card>
        </div>
      </div>


    </div>

    <div *ngIf="!showMap" class="col-lg-9">
      <mat-card appearance="outlined" class="mb-3">
        <div class="buttonrow">
          <div class="mb-2 smalltext" *ngIf="mainComponent.loggedUser.role.superAdmin">Hotel trovati: {{results.results.length}}</div>
          <div class="mb-2">
            <span *ngIf="hotelRes.form.destination">{{hotelRes.form.destination.display}}</span>
            <span *ngIf="!hotelRes.form.destination">Hotel</span><br/>
            CheckIn: {{hotelRes.form.checkIn|date:'dd/MM/yyyy'}} - CheckOut: {{hotelRes.form.checkOut|date:'dd/MM/yyyy'}}
          </div>
          <button (click)="toggleSearch()" mat-stroked-button>
            <span *ngIf="!formvisible">{{'EDIT_SEARCH'|translate}}</span>
            <span *ngIf="formvisible">{{'HIDE_FORM'|translate}}</span>
          </button>

          <button [matMenuTriggerFor]="sortMenu" mat-stroked-button>
            {{'SORTING'|translate}}: {{sorting|translate}}
          </button>
          <mat-menu #sortMenu="matMenu">
            <button (click)="setSort('ASCENDING')" [ngClass]="{'activeMenu':sorting==='ASCENDING'}" mat-menu-item>{{'ASCENDING'|translate}}</button>
            <button (click)="setSort('DESCENDING')" [ngClass]="{'activeMenu':sorting==='DESCENDING'}" mat-menu-item>{{'DESCENDING'|translate}}</button>
            <button (click)="setSort('ALPHABETIC_ASC')" [ngClass]="{'activeMenu':sorting==='ALPHABETIC_ASC'}" mat-menu-item>{{'ALPHABETIC_ASC'|translate}}</button>
            <button (click)="setSort('ALPHABETIC_DESC')" [ngClass]="{'activeMenu':sorting==='ALPHABETIC_DESC'}" mat-menu-item>{{'ALPHABETIC_DESC'|translate}}</button>
            <button (click)="setSort('DISTANCE_ASCENDING')" [ngClass]="{'activeMenu':sorting==='DISTANCE_ASCENDING'}" mat-menu-item>{{'DISTANCE_ASCENDING'|translate}}</button>
            <button (click)="setSort('DISTANCE_DESCENDING')" [ngClass]="{'activeMenu':sorting==='DISTANCE_DESCENDING'}" mat-menu-item>{{'DISTANCE_DESCENDING'|translate}}</button>
            <button (click)="setSort('STAR_ASCENDING')" [ngClass]="{'activeMenu':sorting==='STAR_ASCENDING'}" mat-menu-item>{{'STAR_ASCENDING'|translate}}</button>
            <button (click)="setSort('STAR_DESCENDING')" [ngClass]="{'activeMenu':sorting==='STAR_DESCENDING'}" mat-menu-item>{{'STAR_DESCENDING'|translate}}</button>
            <button (click)="setSort('RELEVANCE')" [ngClass]="{'activeMenu':sorting==='RELEVANCE'}" mat-menu-item>{{'RELEVANCE'|translate}}</button>
          </mat-menu>

          <button (click)="toggleMap()" mat-stroked-button>
            <span>{{'SHOW_MAP'|translate}}</span>
          </button>

        </div>
      </mat-card>

      <div>
        <ng-container *ngFor="let result of results.results;let i = index">
          <div class="card mb-4" *ngIf="i < limit ">
            <div class="row">
              <div class="col-3 align-self-center hotelSlide">
                <div class="introImg" [ngStyle]="{backgroundImage:'url('+returnImg(results.hotels[result.hotelCode])+')'}" (click)="quote(result.hotelCode)">
                  <span class="noImage" *ngIf="noImg(results.hotels[result.hotelCode])">

                    <span class="material-icons">
                      touch_app
                    </span>

                    Mostra immagini
                  </span>
                </div>
              </div>
              <div class="col-9">
                <div class="padding15">
                  <div class="row mb-2">
                    <div class="col-lg-9">
                      <div class="hotelHeader">
                        <span class="hotelName mb-2 mr-1">{{results.hotels[result.hotelCode].name}}</span> <sup matTooltip="Vedi questo riquadro in quanto super admin" *ngIf="!mainComponent.appcomponent.isSbt && mainComponent.loggedUser.role.superAdmin">(Indice: {{i+1}} - {{result.chain}} - {{result.hotelCode}})</sup>
                        <div class="mb-2">
                        <span *ngFor="let star of starCounter(result.rating)" class="material-icons">
                          star_rate
                        </span>
                        </div>
                      </div>
                      <div class="addresses mb-2">
                        <div *ngFor="let address of results.hotels[result.hotelCode].contactInfos[0].addresses">
                          <div>{{address.city}} - {{address.address}}</div>
                        </div>
                        <div>
                          <div *ngIf="result.distance" class="distance">{{checkValue(result.distance)}} {{'FROM_SEARCH_POINT'|translate}}</div>
                        </div>
                      </div>
                      <div class="elementText">
                        {{'HOTEL_TYPE'|translate}}: {{results.hotels[result.hotelCode].info.type}}
                      </div>
                      <div>
                        {{'HOTEL_CATEGORY'|translate}}: {{result.category}}
                      </div>
                      <div>
                        {{results.hotels[result.hotelCode].rooms.length}} {{'ROOM_TYPES'|translate}}
                      </div>
                    </div>
                    <div class="col-lg-3 align-self-start text-right">
                      <div class="hPrice pointer" (click)="quote(result.hotelCode)">
                        {{'PRICE_FROM'|translate}} {{priceService.returnPriceHotel(result.amount)|number:'.2-2'}}€
                        <span class="material-icons ml-1" style="vertical-align: middle">
                          arrow_right_alt
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="(mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) && result.arrayOfAlerts && result.arrayOfAlerts.length > 0; else noAlert"> <!-- (mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) -->
                    <div class="text-right verySmall text-danger pointer" (click)="result.showPolicy =! result.showPolicy">
                      Out of policy <span *ngIf="!result.showPolicy" class="smalIcon material-icons ml-1 pointer">expand_more</span>
                      <span *ngIf="result.showPolicy" class="smalIcon material-icons ml-1 pointer">expand_less</span>
                    </div>
                    <div class="text-right" *ngIf="result.showPolicy">
                      <div class="verySmall text-danger" *ngFor="let alert of result.arrayOfAlerts">
                        <span class="smalIcon material-icons mr-1">warning_amber</span> {{alert.message}}
                      </div>
                    </div>

                  </div>
                  <ng-template  #noAlert>
                    <div *ngIf="(mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B)" class="text-right"> <!-- (mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) -->
                      <div class="verySmall text-success">
                        <span class="smalIcon material-icons mr-1">check</span> in policy</div>
                    </div>
                  </ng-template>

                </div>

              </div>
            </div>
          </div>
        </ng-container>
      </div>




      <div class="text-center" *ngIf="isLast() && !loadMore">
        <button mat-button (click)="updateLimit()">Show more</button>
      </div>
      <div *ngIf="loadMore">
        <mat-progress-bar mode="buffer"></mat-progress-bar>
      </div>
    </div>
  </div>




</div>
