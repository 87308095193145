<div class="all-container">
  <div class="selected-container">
    <div class="selected-user-list" [ngClass]="{'full': showAll}">
      <p *ngIf="selection.selected.length == 0">No user selected</p>
      <div *ngIf="selection.selected.length > 0" class="example-chip-list" appearance="fill">
        <label>Users</label>
        <mat-chip-listbox #chipList aria-label="Fruit selection">
          <mat-chip-option color="primary" *ngFor="let user of selection.selected" (removed)="remove(user)">
            {{user.name}} {{user.surname}}
            <button class="mat-chip-button" mat-button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>

    </div>
    <div class="actions">
      <div class="select-container">
        <mat-select #select placeholder="Select Role">
          <mat-option *ngFor="let role of rolesList" [value]="role._id">{{role.name}}</mat-option>
        </mat-select>
      </div>
      <button mat-button mat-raised-button color="primary" (click)="updateUserRoles(select)">
        Save
      </button>
      <button *ngIf="selection.selected.length > 10" mat-button (click)="showAll = !showAll">
                <span>{{ showAll ? 'See Less' : 'Show More' }}
                  <i [ngClass]="{'arrow-up': showAll, 'arrow-down': !showAll}">>></i>
                </span>
      </button>
    </div>
  </div>

  <div class="show-more-btn-container">
    <button *ngIf="selection.selected.length >= 8" mat-button (click)="showAll = !showAll">
            <span>{{ showAll ? 'See Less' : 'Show More' }}
              <i [ngClass]="{'arrow-up': showAll, 'arrow-down': !showAll}">>></i>
            </span>
    </button>
  </div>
</div>
