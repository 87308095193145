import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { CompaniesService } from '../../../../../services/profile/companies.service';
import * as $ from 'jquery';
import { MainComponent } from '../../../../mainmodule/main/main.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { HttpserviceService } from '../../../../../services/httpservice.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { HttpClient } from '@angular/common/http';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Md5 } from 'ts-md5/dist/md5';
import { Company } from '../../../../../classes/company';
import { AdmserviceService } from '../../../../../services/adminserv/admservice.service';
import {AppComponent} from '../../../../../app.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { RemarkDialogComponent } from './remark-dialog/remark-dialog.component';
/*import {getSortHeaderNotContainedWithinSortError} from "@angular/material/sort/sort-errors";*/

export class MyErrorStateMatcher implements ErrorStateMatcher{
  isErrorState(control : UntypedFormControl | null, form : FormGroupDirective | NgForm | null) : boolean{
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

export interface Country{
  value : string;
  viewValue : string;
}

export interface Region{
  value : string;
  viewValue : string;
}

export interface Provincia{
  nome : string;
  sigla : string;
  regione : string;
}

export interface Province{
  value : string;
  viewValue : string;
}

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit, AfterViewInit{
  companyRemarks: any;
  companyRemarksClone: any;
  mother : any;
  countries : Country[] = [
    { value: 'IT', viewValue: 'Italia' },
  ];
  regions : Region[] = [
    { value: 'Abruzzo', viewValue: 'Abruzzo' },
    { value: 'Basilicata', viewValue: 'Basilicata' },
    { value: 'Calabria', viewValue: 'Calabria' },
    { value: 'Campania', viewValue: 'Campania' },
    { value: 'Emilia-Romagna', viewValue: 'Emilia-Romagna' },
    { value: 'Friuli-Venezia Giulia', viewValue: 'Friuli-Venezia Giulia' },
    { value: 'Lazio', viewValue: 'Lazio' },
    { value: 'Liguria', viewValue: 'Liguria' },
    { value: 'Lombardia', viewValue: 'Lombardia' },
    { value: 'Marche', viewValue: 'Marche' },
    { value: 'Molise', viewValue: 'Molise' },
    { value: 'Piemonte', viewValue: 'Piemonte' },
    { value: 'Puglia', viewValue: 'Puglia' },
    { value: 'Sardegna', viewValue: 'Sardegna' },
    { value: 'Sicilia', viewValue: 'Sicilia' },
    { value: 'Toscana', viewValue: 'Toscana' },
    { value: 'Trentino-Alto Adige', viewValue: 'Trentino-Alto Adige' },
    { value: 'Umbria', viewValue: 'Umbria' },
    { value: 'Valle d\'Aosta', viewValue: 'Valle d\'Aosta' },
    { value: 'Veneto', viewValue: 'Veneto' },
  ];
  province : Provincia[] = [
    {
      nome: 'Agrigento',
      sigla: 'AG',
      regione: 'Sicilia',
    },
    {
      nome: 'Alessandria',
      sigla: 'AL',
      regione: 'Piemonte',
    },
    {
      nome: 'Ancona',
      sigla: 'AN',
      regione: 'Marche',
    },
    {
      nome: 'Arezzo',
      sigla: 'AR',
      regione: 'Toscana',
    },
    {
      nome: 'Ascoli Piceno',
      sigla: 'AP',
      regione: 'Marche',
    },
    {
      nome: 'Asti',
      sigla: 'AT',
      regione: 'Piemonte',
    },
    {
      nome: 'Avellino',
      sigla: 'AV',
      regione: 'Campania',
    },
    {
      nome: 'Bari',
      sigla: 'BA',
      regione: 'Puglia',
    },
    {
      nome: 'Barletta-Andria-Trani',
      sigla: 'BT',
      regione: 'Puglia',
    },
    {
      nome: 'Belluno',
      sigla: 'BL',
      regione: 'Veneto',
    },
    {
      nome: 'Benevento',
      sigla: 'BN',
      regione: 'Campania',
    },
    {
      nome: 'Bergamo',
      sigla: 'BG',
      regione: 'Lombardia',
    },
    {
      nome: 'Biella',
      sigla: 'BI',
      regione: 'Piemonte',
    },
    {
      nome: 'Bologna',
      sigla: 'BO',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Bolzano/Bozen',
      sigla: 'BZ',
      regione: 'Trentino-Alto Adige',
    },
    {
      nome: 'Brescia',
      sigla: 'BS',
      regione: 'Lombardia',
    },
    {
      nome: 'Brindisi',
      sigla: 'BR',
      regione: 'Puglia',
    },
    {
      nome: 'Cagliari',
      sigla: 'CA',
      regione: 'Sardegna',
    },
    {
      nome: 'Caltanissetta',
      sigla: 'CL',
      regione: 'Sicilia',
    },
    {
      nome: 'Campobasso',
      sigla: 'CB',
      regione: 'Molise',
    },
    {
      nome: 'Carbonia-Iglesias',
      sigla: 'CI',
      regione: 'Sardegna',
    },
    {
      nome: 'Caserta',
      sigla: 'CE',
      regione: 'Campania',
    },
    {
      nome: 'Catania',
      sigla: 'CT',
      regione: 'Sicilia',
    },
    {
      nome: 'Catanzaro',
      sigla: 'CZ',
      regione: 'Calabria',
    },
    {
      nome: 'Chieti',
      sigla: 'CH',
      regione: 'Abruzzo',
    },
    {
      nome: 'Como',
      sigla: 'CO',
      regione: 'Lombardia',
    },
    {
      nome: 'Cosenza',
      sigla: 'CS',
      regione: 'Calabria',
    },
    {
      nome: 'Cremona',
      sigla: 'CR',
      regione: 'Lombardia',
    },
    {
      nome: 'Crotone',
      sigla: 'KR',
      regione: 'Calabria',
    },
    {
      nome: 'Cuneo',
      sigla: 'CN',
      regione: 'Piemonte',
    },
    {
      nome: 'Enna',
      sigla: 'EN',
      regione: 'Sicilia',
    },
    {
      nome: 'Fermo',
      sigla: 'FM',
      regione: 'Marche',
    },
    {
      nome: 'Ferrara',
      sigla: 'FE',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Firenze',
      sigla: 'FI',
      regione: 'Toscana',
    },
    {
      nome: 'Foggia',
      sigla: 'FG',
      regione: 'Puglia',
    },
    {
      nome: 'Forlì-Cesena',
      sigla: 'FC',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Frosinone',
      sigla: 'FR',
      regione: 'Lazio',
    },
    {
      nome: 'Genova',
      sigla: 'GE',
      regione: 'Liguria',
    },
    {
      nome: 'Gorizia',
      sigla: 'GO',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Grosseto',
      sigla: 'GR',
      regione: 'Toscana',
    },
    {
      nome: 'Imperia',
      sigla: 'IM',
      regione: 'Liguria',
    },
    {
      nome: 'Isernia',
      sigla: 'IS',
      regione: 'Molise',
    },
    {
      nome: 'L\'Aquila',
      sigla: 'AQ',
      regione: 'Abruzzo',
    },
    {
      nome: 'La Spezia',
      sigla: 'SP',
      regione: 'Liguria',
    },
    {
      nome: 'Latina',
      sigla: 'LT',
      regione: 'Lazio',
    },
    {
      nome: 'Lecce',
      sigla: 'LE',
      regione: 'Puglia',
    },
    {
      nome: 'Lecco',
      sigla: 'LC',
      regione: 'Lombardia',
    },
    {
      nome: 'Livorno',
      sigla: 'LI',
      regione: 'Toscana',
    },
    {
      nome: 'Lodi',
      sigla: 'LO',
      regione: 'Lombardia',
    },
    {
      nome: 'Lucca',
      sigla: 'LU',
      regione: 'Toscana',
    },
    {
      nome: 'Macerata',
      sigla: 'MC',
      regione: 'Marche',
    },
    {
      nome: 'Mantova',
      sigla: 'MN',
      regione: 'Lombardia',
    },
    {
      nome: 'Massa-Carrara',
      sigla: 'MS',
      regione: 'Toscana',
    },
    {
      nome: 'Matera',
      sigla: 'MT',
      regione: 'Basilicata',
    },
    {
      nome: 'Medio Campidano',
      sigla: 'VS',
      regione: 'Sardegna',
    },
    {
      nome: 'Messina',
      sigla: 'ME',
      regione: 'Sicilia',
    },
    {
      nome: 'Milano',
      sigla: 'MI',
      regione: 'Lombardia',
    },
    {
      nome: 'Modena',
      sigla: 'MO',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Monza e della Brianza',
      sigla: 'MB',
      regione: 'Lombardia',
    },
    {
      nome: 'Napoli',
      sigla: 'NA',
      regione: 'Campania',
    },
    {
      nome: 'Novara',
      sigla: 'NO',
      regione: 'Piemonte',
    },
    {
      nome: 'Nuoro',
      sigla: 'NU',
      regione: 'Sardegna',
    },
    {
      nome: 'Ogliastra',
      sigla: 'OG',
      regione: 'Sardegna',
    },
    {
      nome: 'Olbia-Tempio',
      sigla: 'OT',
      regione: 'Sardegna',
    },
    {
      nome: 'Oristano',
      sigla: 'OR',
      regione: 'Sardegna',
    },
    {
      nome: 'Padova',
      sigla: 'PD',
      regione: 'Veneto',
    },
    {
      nome: 'Palermo',
      sigla: 'PA',
      regione: 'Sicilia',
    },
    {
      nome: 'Parma',
      sigla: 'PR',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Pavia',
      sigla: 'PV',
      regione: 'Lombardia',
    },
    {
      nome: 'Perugia',
      sigla: 'PG',
      regione: 'Umbria',
    },
    {
      nome: 'Pesaro e Urbino',
      sigla: 'PU',
      regione: 'Marche',
    },
    {
      nome: 'Pescara',
      sigla: 'PE',
      regione: 'Abruzzo',
    },
    {
      nome: 'Piacenza',
      sigla: 'PC',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Pisa',
      sigla: 'PI',
      regione: 'Toscana',
    },
    {
      nome: 'Pistoia',
      sigla: 'PT',
      regione: 'Toscana',
    },
    {
      nome: 'Pordenone',
      sigla: 'PN',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Potenza',
      sigla: 'PZ',
      regione: 'Basilicata',
    },
    {
      nome: 'Prato',
      sigla: 'PO',
      regione: 'Toscana',
    },
    {
      nome: 'Ragusa',
      sigla: 'RG',
      regione: 'Sicilia',
    },
    {
      nome: 'Ravenna',
      sigla: 'RA',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Reggio di Calabria',
      sigla: 'RC',
      regione: 'Calabria',
    },
    {
      nome: 'Reggio nell\'Emilia',
      sigla: 'RE',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Rieti',
      sigla: 'RI',
      regione: 'Lazio',
    },
    {
      nome: 'Rimini',
      sigla: 'RN',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Roma',
      sigla: 'RM',
      regione: 'Lazio',
    },
    {
      nome: 'Rovigo',
      sigla: 'RO',
      regione: 'Veneto',
    },
    {
      nome: 'Salerno',
      sigla: 'SA',
      regione: 'Campania',
    },
    {
      nome: 'Sassari',
      sigla: 'SS',
      regione: 'Sardegna',
    },
    {
      nome: 'Savona',
      sigla: 'SV',
      regione: 'Liguria',
    },
    {
      nome: 'Siena',
      sigla: 'SI',
      regione: 'Toscana',
    },
    {
      nome: 'Siracusa',
      sigla: 'SR',
      regione: 'Sicilia',
    },
    {
      nome: 'Sondrio',
      sigla: 'SO',
      regione: 'Lombardia',
    },
    {
      nome: 'Taranto',
      sigla: 'TA',
      regione: 'Puglia',
    },
    {
      nome: 'Teramo',
      sigla: 'TE',
      regione: 'Abruzzo',
    },
    {
      nome: 'Terni',
      sigla: 'TR',
      regione: 'Umbria',
    },
    {
      nome: 'Torino',
      sigla: 'TO',
      regione: 'Piemonte',
    },
    {
      nome: 'Trapani',
      sigla: 'TP',
      regione: 'Sicilia',
    },
    {
      nome: 'Trento',
      sigla: 'TN',
      regione: 'Trentino-Alto Adige',
    },
    {
      nome: 'Treviso',
      sigla: 'TV',
      regione: 'Veneto',
    },
    {
      nome: 'Trieste',
      sigla: 'TS',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Udine',
      sigla: 'UD',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Valle d\'Aosta',
      sigla: 'AO',
      regione: 'Valle d\'Aosta',
    },
    {
      nome: 'Varese',
      sigla: 'VA',
      regione: 'Lombardia',
    },
    {
      nome: 'Venezia',
      sigla: 'VE',
      regione: 'Veneto',
    },
    {
      nome: 'Verbano-Cusio-Ossola',
      sigla: 'VB',
      regione: 'Piemonte',
    },
    {
      nome: 'Vercelli',
      sigla: 'VC',
      regione: 'Piemonte',
    },
    {
      nome: 'Verona',
      sigla: 'VR',
      regione: 'Veneto',
    },
    {
      nome: 'Vibo Valentia',
      sigla: 'VV',
      regione: 'Calabria',
    },
    {
      nome: 'Vicenza',
      sigla: 'VI',
      regione: 'Veneto',
    },
    {
      nome: 'Viterbo',
      sigla: 'VT',
      regione: 'Lazio',
    },
  ];

  provinces : Province[] = [];

  companyId : string;
  showEdit : boolean;
  activeform : boolean;
  company : Company;
  CompanyForm : UntypedFormGroup;
  matcher = new MyErrorStateMatcher();
  param : any;
  showUploadForm : boolean;
  currentTab : number;
  loadingTab : boolean;
  ////// image
  fileData : File = null;
  previewUrl : any = null;
  fileUploadProgress : string = null;
  uploadedFilePath : string = null;
  token : string;
  confirmToken : string;
  hashedTk : string;
  incentives: any[] = [];
  showTabs: boolean = true;
  loadingRemark: boolean = true;
  isNotItaly : boolean = false;
  constructor(
    private formBuilder : UntypedFormBuilder,
    private route : ActivatedRoute,
    private companyService : CompaniesService,
    private snackBar : MatSnackBar,
    public maincomponent : MainComponent,
    private http : HttpClient,
    public translate : TranslateService,
    private httpservice : HttpserviceService,
    private adminService : AdmserviceService,
    public appComponent: AppComponent,
    public dialog: MatDialog
  ){
    this.currentTab = 0;
    this.showEdit = false;
    this.activeform = false;
    this.showUploadForm = false;
    this.route.params.subscribe(params => {
      this.param = params.id;
      this.getCompanyId();
    });

    const form = {
      name: ['', [Validators.required]],
      pIva: ['', [Validators.required]],
      type: ['', [Validators.required]],
      dkNumber: ['', [Validators.required]],
      cau: '',
      cauDK: '',
      incentive: [''],
      subordinatedOf: [''],
      incentivePlan: ['', [Validators.required]],
      enable: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.pattern('^[0-9]*$'), Validators.required]],
      location: this.formBuilder.group({
        _id: ['', [Validators.required]],
        country: ['', [Validators.required]],
        region: ['', [Validators.required]],
        province: ['', [Validators.required]],
        city: ['', [Validators.required]],
        postalCode: ['', [Validators.required]],
        address: ['', [Validators.required]],
      }),
    };

    if (!this.maincomponent.loggedUser.role.superAdmin){
      delete form.type;
      delete form.dkNumber;
      delete form.incentive;
      delete form.incentivePlan;
      delete form.subordinatedOf;
      delete form.enable;
    }
    this.CompanyForm = this.formBuilder.group(form);
    this.CompanyForm.disable();
  }

  setRegion(){
    this.CompanyForm.get('location')['controls'].province.setValue('');
    this.provinces = [];
    for (let i = 0; i < this.province.length; i ++){
      if (this.CompanyForm.value.location.region === this.province[i].regione){
        const prov = {
          value: this.province[i].sigla,
          viewValue: this.province[i].nome,
        };
        this.provinces.push(prov);
      }
    }
  }

  returnCompanyType(value){
    if (value === 'A'){
      return 'AGENCY';
    } else{
      return 'B2B_COMPANY';
    }
  }

  ngOnInit(){
    if (this.maincomponent.loggedUser.role.superAdmin) {
      this.getIncentives();
    }
  }

  toggleForm(){
    if (!this.activeform){
      this.CompanyForm.disable();
    } else{
      this.CompanyForm.enable();
      if (!this.maincomponent.loggedUser.role.superAdmin){
        this.CompanyForm.controls.name.disable();
        this.CompanyForm.controls.pIva.disable();
      }
    }
    if(this.appComponent.isGat){
      this.CompanyForm.controls.type.disable();
    }
    /*console.log('form', this.CompanyForm);*/
  }

  getCompanyId(){
    this.companyId = this.route.snapshot.paramMap.get('id');
    console.log('this.companyId => ', this.companyId);
    this.getCompany(this.companyId);
  }

  getCompany(id) : void{
    this.currentTab = 0;
    this.companyService.getCompany(id).subscribe((res) => {
      this.company = res;
      if (this.company){
        this.company.image = this.httpservice.setApiUrlImage() + 'static/company/' + this.company._id + '.png?time=' + new Date().getTime();
        console.log('this.company call => ', this.company);
        if (this.maincomponent.loggedUser.role.superAdmin){
          this.CompanyForm.controls.type.setValue(this.company.type);
          this.CompanyForm.controls.incentive.setValue(this.company.incentive);
          this.CompanyForm.controls.incentivePlan.setValue(this.company.incentivePlan);
          this.CompanyForm.controls.subordinatedOf.setValue(this.company.subordinatedOf);
          this.CompanyForm.controls.dkNumber.setValue(this.company.dkNumber);
          this.CompanyForm.controls.cau.setValue(this.company.cau);
          this.CompanyForm.controls.cauDK.setValue(this.company.cauDK);
          this.CompanyForm.controls.enable.setValue(this.company.enable);
        }
        this.CompanyForm.controls.name.setValue(this.company.name);
        this.CompanyForm.controls.pIva.setValue(this.company.pIva);
        if (!this.maincomponent.loggedUser.role.superAdmin){
          this.CompanyForm.controls.name.disable();
          this.CompanyForm.controls.pIva.disable();
        }
        this.CompanyForm.controls.email.setValue(this.company.email);
        this.CompanyForm.controls.phoneNumber.setValue(this.company.phoneNumber);
        this.setAddress()
        console.log('Company => ', this.company);
        this.isNotItaly = this.company.location.country !== 'IT' ? true : false;
        this.startCounter();
        this.getCpSettings();
        this.getRemark();
        if(this.company.subordinatedOf){
          this.getParent(this.company.subordinatedOf);
        }
      }
      if (!this.company){
        alert('error');
      }
    });
  }

  setAddress(){
    this.CompanyForm.get('location')['controls']._id.setValue(this.company.location._id);
    this.CompanyForm.get('location')['controls'].country.setValue(this.company.location.country);
    this.CompanyForm.get('location')['controls'].region.setValue(this.company.location.region);
    this.setRegion();
    this.CompanyForm.get('location')['controls'].province.setValue(this.company.location.province);
    this.CompanyForm.get('location')['controls'].city.setValue(this.company.location.city);
    this.CompanyForm.get('location')['controls'].postalCode.setValue(this.company.location.postalCode);
    this.CompanyForm.get('location')['controls'].address.setValue(this.company.location.address);
  }

  getIncentives(): void {
    this.companyService.getIncentives().subscribe((res) => {
      console.log('res ince => ', res);
      this.incentives = res.list;
      // this.loading = false;
    }, (error) => {
      // this.loading = false;
      console.log('error => ', error);
    });
  }

  getCpSettings(): void {
    this.companyService.getCpSettings(this.company._id).subscribe((res) => {
      this.company.services = res;
      // this.loading = false;
    }, (error) => {
      // this.loading = false;
      console.log('error => ', error);
    });
  }

  startCounter(){
    setTimeout(() => {
      $('.count').each(function(){
        $(this).prop('Counter', 0).animate({
          Counter: $(this).text(),
        }, {
          duration: 1500,
          easing: 'swing',
          step(now){
            $(this).text(Math.ceil(now));
          },
        });
      });
    }, 1);

  }

  showEditForm(){
    this.showEdit = !this.showEdit;
    if (!this.showEdit){
      this.activeform = false;
      this.CompanyForm.disable();
      ///segnaposto
      this.resetAddressForm()

    }
  }

  getErrorMessage(){
    return this.CompanyForm.controls.email.hasError('required') ? this.translate.instant('ENTER_A_VALUE') :
      this.CompanyForm.controls.email.hasError('email') ? this.translate.instant('ENTER_VALID_EMAIL') :
        '';
  }

  editCompany(){
    this.updateCompany(this.company._id);
  }

  updateCompany(id){
    console.log(this.CompanyForm.value.subordinatedOf , 'onsubmit')
    this.companyService.updateCompany(id, this.CompanyForm.value).subscribe((res) => {

      this.getCompany(id);


      this.snackBar.open(this.translate.instant('COMPANY_UPDATED'), 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  getParent(id:string){
    this.companyService.getCompany(id).subscribe((res) => {
      this.mother = res;
      this.postSubordinated(this.mother);
    }, error => {
      console.error('Error => ', error);
    });
  }


  postSubordinated(company:any){
    const index = company.daughters.findIndex((s:any) => s._id === this.company._id);
    if(index == -1){
      company.daughters.push(this.company._id);
      this.companyService.updateCompany(company._id, company).subscribe((res:any) => {
        console.log('Salvata => ', res);
      }, error => {
        console.error('Error => ', error);
      })
    }
  }

  fileProgress(fileInput : any){
    this.fileData = fileInput.target.files[0] as File;
    this.preview();
  }

  preview(){
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null){
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  onSubmit(){
    console.log('this.fileData=> ', this.fileData);
    const formData = new FormData();
    formData.append('image', this.fileData);
    this.companyService.uploadImage(this.company._id, formData).subscribe((res) => {
      this.getCompany(this.company._id);
      $('#exampleModal').modal('hide');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      this.snackBar.open('Company updated', 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    });
  }

  tabChanged(tabChangeEvent : MatTabChangeEvent) : void{
    this.loadingTab = true;
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
    this.currentTab = tabChangeEvent.index;
    setTimeout(() => {
      this.loadingTab = false;
    }, 1000);
  }

  ngAfterViewInit(){

  }

  toggleTokenModal(){
    $('#tokenModal').modal('toggle');
    this.token = '';
    this.confirmToken = '';
    this.hashedTk = '';
  }

  toggleSiapModal(){
    $('#siapModal').modal('toggle');
  }

  setToken(){
    this.hashedTk = String(Md5.hashStr(String(this.token)));
    const pin = {
      enabled: true,
      pin: this.hashedTk,
    };
    this.companyService.setToken(this.companyId, pin).subscribe((res) => {
      this.toggleTokenModal();
      this.company.pin.enable = true;
      this.snackBar.open(this.translate.instant('TOKEN_SAVED'), 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }, error => {
      console.error('Error => ', error);
      this.toggleTokenModal();
    });
  }

  removeToken(){
    const pin = {
      enabled: false,
    };
    this.companyService.setToken(this.companyId, pin).subscribe((res) => {
      this.toggleTokenModal();
      this.company.pin.enable = false;
      this.snackBar.open(this.translate.instant('TOKEN_REMOVED'), 'Ok', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }, error => {
      this.toggleTokenModal();
      console.error('Error => ', error);
    });
  }

  importUsers() : void{
    this.snackBar.open(this.translate.instant('IMPORTAZIONE_LANCIATA'), 'Ok', {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
    this.adminService.getUsersSiap(this.companyId).subscribe((res) => {
      console.error('res => ', res);
      this.toggleSiapModal();
    }, error => {
      this.toggleSiapModal();
      this.showTabs = false;
      setTimeout(()=>{
        this.showTabs = true;
      }, 1000);
      console.error('Error => ', error);
    });
  }


  getRemark(){
    this.loadingRemark = true;
    this.companyService.getCompanyRemark(this.company._id).subscribe((res) => {
      this.companyRemarks = res;
      if(!this.companyRemarks.rmList){
        this.companyRemarks.rmList = [];
      }
      this.companyRemarksClone = JSON.parse(JSON.stringify(this.companyRemarks));
      this.loadingRemark = false;
    }, error => {
      this.loadingRemark = false;
      console.error('Error => ', error);
    });
  }

  saveRemark(){
    this.loadingRemark = true;
    const body = {
      companyId: this.company._id,
      strings: this.companyRemarksClone.rmList
    }
    if(!this.companyRemarks._id){
      this.companyService.postCompanyRemark(body).subscribe((res) => {
        this.getRemark();
        this.loadingRemark = false;
      }, error => {
        console.error('Error => ', error);
        this.loadingRemark = false;
      });
    } else {
      this.companyService.putCompanyRemark(body).subscribe((res) => {
        this.loadingRemark = false;
        this.getRemark();
      }, error => {
        this.loadingRemark = false;
        console.error('Error => ', error);
      });
    }
  }

  removeRemark(i){
    this.companyRemarksClone.rmList.splice(i, 1);
  }

  deleteRemarks(){
    this.loadingRemark = true;
    this.companyService.deleteCompanyRemark(this.company._id).subscribe((res) => {
      this.getRemark();
    }, error => {
      console.error('Error => ', error);
      this.loadingRemark = false;
    });
  }

  confirmDeleteRemark() {
    const dialogRef = this.dialog.open(RemarkDialogComponent, {
      width: '250px',

    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.deleteRemarks();
      }
    });
  }

  differenceRmark(){
    const original = JSON.stringify(this.companyRemarks);
    const cloned = JSON.stringify(this.companyRemarksClone);
    return original != cloned;
  }

  trackByFn(index: number, item: any): any {
    return index; // o un identificatore univoco associato a ciascun elemento
  }

  addRemarks(){
    const remark = '';
    this.companyRemarksClone.rmList.push(remark);
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.companyRemarksClone.rmList, event.previousIndex, event.currentIndex);
  }

  changeCountry(state : any){
    this.isNotItaly = state;
    this.CompanyForm.value.location.country = '';
    if(this.isNotItaly === false){
      this.CompanyForm.get('location')['controls'].country.setValue('IT');
      this.CompanyForm.get('location')['controls'].region.setValue('');
      this.CompanyForm.get('location')['controls'].province.setValue('');
      this.CompanyForm.get('location')['controls'].city.setValue('');
      this.CompanyForm.get('location')['controls'].postalCode.setValue('');
      this.CompanyForm.get('location')['controls'].address.setValue('');
    } else {
      this.CompanyForm.get('location')['controls'].country.setValue('');
      this.CompanyForm.get('location')['controls'].region.setValue('');
      this.CompanyForm.get('location')['controls'].province.setValue('');
      this.CompanyForm.get('location')['controls'].city.setValue('');
      this.CompanyForm.get('location')['controls'].postalCode.setValue('');
      this.CompanyForm.get('location')['controls'].address.setValue('');
    }
  }

  resetAddressForm(){
    this.setAddress()
  }

  setCompany(event) {
    if (event){
      console.log('Companie selected',event._id , event.name)
      this.CompanyForm.controls.subordinatedOf.setValue(event._id)
      console.log(this.CompanyForm.controls.subordinatedOf.value)
    }
  }

}
