<custom-title></custom-title>
<div class="padding20">

  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <div *ngIf="!loading && ticket && !jiraTkt">
    <mat-card appearance="outlined" class="mb-3">
      <mat-card-content>
        <div>
          {{ticket.user.name}} {{ticket.user.surname}}
          <div class="userinfo" *ngIf="mainComponent.loggedUser.role.isTktOperator">
            <a routerLink="/users/list/{{ticket.user._id}}">{{'GO_TO_PROFILE'|translate}}</a> | <a routerLink="/companies/list/{{ticket.user.company._id}}">{{ticket.user.company.name}}</a>
          </div>
          <hr/>
        </div>
        <p>{{ticket.createdAt|date:'dd/MM/yyyy - HH:mm'}}</p>
        <h4><b>{{'SUBJECT'|translate}}:</b> {{truncateObj(ticket.object)}}</h4>
        <div *ngIf="ticket.locator">Locator: {{ticket.locator}}</div>
        <hr/>
        <app-group-request [ticket]="ticket" *ngIf="ticket.department==='group-request'"></app-group-request>
        <app-unaccompanied-minors [ticket]="ticket" *ngIf="ticket.department==='underage'"></app-unaccompanied-minors>
        <ng-container *ngIf="['office','tech','leonardotravel'].includes(ticket.department)">
          <ng-container *ngIf="!ticket.body.payload.subject">
            <p>{{'MESSAGE'|translate}}</p>
            <p>{{ticket.body.data}}</p>
            <p>{{'REQUEST_PAGE'|translate}}: <a [routerLink]="ticket.body.payload">{{ticket.body.payload}}</a></p>
          </ng-container>
          <div *ngIf="ticket.body.payload.subject">
            <div class="row mb-2">
              <div class="col-md-6 align-self-center">
                <div class="mb-3">
                  <p class="mb-2">{{'SUBJECT'|translate}}: {{truncateObj(ticket.body.payload.subject)}}</p>
                  <p>{{'TEXT'|translate}}:<br/> {{ticket.body.payload.freeText}}</p>
                  <hr/>
                  <h5 class="mb-2">{{'PASSENGERS'|translate}}</h5>
                  <span *ngFor="let traveler of ticket.body.payload.passengers; let ind = index;">
                    <span class="singlepax">
                      {{traveler.name}} {{traveler.surname}}<sup> {{traveler.type}}</sup>
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-md-6 align-self-center">
                <span class="tabtitle mb-2">{{'SERVICES_REQUESTED'|translate}}</span>
                <div *ngIf="ticket.body.payload.services.length>0">
                  <div class="singleService" *ngFor="let service of ticket.body.payload.services; let ind = index;">
                    <div class="row mb-2">
                      <div class="col-2 text-center align-self-center">
                        <span *ngIf="service.type=='flight'" class="material-icons">flight</span>
                        <span *ngIf="service.type=='train'" class="material-icons">train</span>
                        <span *ngIf="service.type=='hotel'" class="material-icons">hotel</span>
                        <span *ngIf="service.type=='car'" class="material-icons">directions_car</span>
                      </div>
                      <div class="col-8 align-self-center">
                        <div *ngIf="service.type === 'flight' || service.type === 'train'">
                          <div>Partenza da {{service!.from}} il {{service.date | date:'dd/MM/yyyy'}}</div>
                          <div>Arrivo a {{service!.to}}</div>
                          <div>{{service!.notes}}</div>
                        </div>

                        <div *ngIf="service.type === 'hotel'">
                          <div>Pernottamento a {{service!.location}}</div>
                          <div>{{'CHECK_IN'|translate}} {{service.date | date:'dd/MM/yyyy'}}</div>
                          <div>{{'CHECK_OUT'|translate}} {{service.dateTo | date:'dd/MM/yyyy'}}</div>
                          <div>{{service!.notes}}</div>
                        </div>

                        <div *ngIf="service.type === 'car'">
                          <div>{{'PICKUP_DATE'|translate}} il {{service.date | date:'dd/MM/yyyy'}} a {{service!.from}}</div>
                          <div>{{'RETURN_DATE'|translate}} il {{service.dateTo | date:'dd/MM/yyyy'}} a {{service!.to}}</div>
                          <div>{{service!.notes}}</div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </ng-container>
      </mat-card-content>
    </mat-card>

    <div class="text-right mb-3">
      <mat-slide-toggle
        class="example-margin"
        (change)="toggleChat($event)"
        [checked]="chatMode">
        Chat mode
      </mat-slide-toggle>
    </div>

    <div class="linearTrs">
      <div class="row">
        <div class="linearTrs" [ngClass]="{'col-12':!chatMode,'col-lg-6':chatMode}">
          <div class="linearTrs ChatMain" [ngClass]="{'chatContainer':chatMode}">
            <mat-card appearance="outlined" class="linearTrs mb-3" *ngFor="let reply of ticket.replies;let i = index" [ngClass]="{'mright':reply.fromUser && chatMode,'mleft':!reply.fromUser && chatMode}">
              <mat-card-header>
                <div mat-card-avatar class="header-image" style="background-image:url({{returnImage(reply.createdBy._id)}})"></div>
                <mat-card-title>{{reply.createdBy.name}} {{reply.createdBy.surname}}</mat-card-title>
                <mat-card-subtitle>{{reply.createdAt|date:'dd/MM/yyyy - HH:mm'}}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div [innerHTML]="reply.body.data"></div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="linearTrs textArea" [ngClass]="{'col-12':!chatMode,'col-lg-6':chatMode}">
          <div class="ticketActions" *ngIf="mainComponent.loggedUser.role.isTktOperator">
            <mat-progress-bar mode="buffer" *ngIf="loadActions"></mat-progress-bar>
            <mat-card appearance="outlined" class="mb-3">
              <mat-card-content>
                <div>{{'ADMIN_ACTIONS'|translate}}</div>
                <hr/>
                <div class="buttonrow row">
                  <div class="col-6 align-self-center" [ngClass]="{'col-lg-3':!chatMode}">
                    <button *ngIf="!ticket.closed" mat-stroked-button (click)="closeTkt()">{{'CLOSE_TICKET'|translate}}</button>
                    <div *ngIf="ticket.closed">{{'TICKET_CLOSED'|translate}}</div>
                  </div>
                  <div class="col-6" [ngClass]="{'col-lg-3':!chatMode}">
                    <mat-form-field class="full-width">
                      <mat-label>{{'SELECT_OPERATOR'|translate}}</mat-label>
                      <mat-select [(ngModel)]="operator" (ngModelChange)="setOperator()">
                        <mat-option *ngFor="let operator of operators" [value]="operator._id">
                          {{operator.name}} {{operator.surname}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-6" [ngClass]="{'col-lg-3':!chatMode}">
                    <mat-form-field class="full-width">
                      <mat-label>{{'SELECT_SECTION'|translate}}</mat-label>
                      <mat-select [(ngModel)]="ticket.section" (ngModelChange)="setSection()">
                        <mat-option *ngFor="let sect of sections" [value]="sect">
                          {{sect|translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-6" [ngClass]="{'col-lg-3':!chatMode}">
                    <mat-form-field class="full-width">
                      <mat-label>{{'SELECT_DEPARTMENT'|translate}}</mat-label>
                      <mat-select [(ngModel)]="ticket.department" (ngModelChange)="setDepartment()">
                        <mat-option *ngFor="let dep of departments" [value]="dep">
                          {{dep|translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                </div>
              </mat-card-content>
            </mat-card>

          </div>
          <mat-card appearance="outlined" class="mb-3">
            <mat-card-content>
              <div>
                <mat-form-field class="full-width">
                  <mat-label>{{'YOUR_MESSAGE'|translate}}</mat-label>
                  <textarea [(ngModel)]="newMessage" matInput class="min-height-text"></textarea>
                </mat-form-field>
              </div>
            </mat-card-content>
            <mat-card-actions class="text-right">
              <button mat-button *ngIf="!sendAnswer" [disabled]="!newMessage" (click)="putTicket()">{{'SEND'|translate}}</button>
              <div *ngIf="sendAnswer">
                {{'SENDING_MESSAGE'|translate}}<br/>
                <mat-progress-bar mode="buffer"></mat-progress-bar>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>

    </div>

  </div>

  <div *ngIf="!loading && ticket && jiraTkt">
    <mat-card appearance="outlined" class="mb-3">
      <mat-card-content>
        <div class="row text-uppercase">
          <div class="col-md-6">
            {{ticket.reporter.name}}<br/>
            {{ticket.reporter.email}}
          </div>
          <div class="col-md-6 text-right">
            {{ticket.type}}<br/>
            {{ticket.status}}
          </div>
        </div>
        <hr/>
        <p>{{ticket.createdAt|date:'dd/MM/yyyy - HH:mm'}}</p>
        <h4><b>{{'SUBJECT'|translate}}:</b> {{truncateObj(ticket.summary)}}</h4>
        <div class="mb-3" *ngIf="ticket.locator">Locator: {{ticket.locator}}</div>
        <div class="mb-3" *ngIf="ticket.component === 'flight' && ticket.payload">
          <a href="{{ticket.payload}}">Vai alla pagina di segnalazione</a>
        </div>
        <div *ngIf="ticket.body" [innerHTML]="ticket.body">

        </div>
        <hr/>
        <ng-container *ngIf="ticket.department==='group-request'">
          <p>{{'MESSAGE'|translate}}</p>
          <div>{{ticket.body|json}}</div>

        </ng-container>
        <ng-container *ngIf="ticket.department==='underage'">

        </ng-container>
        <ng-container *ngIf="['office','tech','leonardotravel'].includes(ticket.department)">
          <p>{{'MESSAGE'|translate}}</p>
          <div [innerHTML]="ticket.body"></div>
          <p>{{'REQUEST_PAGE'|translate}}: <a href="{{ticket.payload}}">{{ticket.payload}}</a></p>
        </ng-container>

      </mat-card-content>
    </mat-card>

    <div class="text-right mb-3">
      <mat-slide-toggle
        class="example-margin"
        (change)="toggleChat($event)"
        [checked]="chatMode">
        Chat mode
      </mat-slide-toggle>
    </div>

    <div class="linearTrs">
      <div class="row">
        <div class="linearTrs" [ngClass]="{'col-12':!chatMode,'col-lg-6':chatMode}">
          <div class="linearTrs ChatMain" [ngClass]="{'chatContainer':chatMode}">
            <mat-card appearance="outlined" class="linearTrs mb-3" *ngFor="let reply of ticket.comments;let i = index" [ngClass]="{'mright':reply.fromUser && chatMode,'mleft':!reply.fromUser && chatMode}">
              <mat-card-header>
                <div mat-card-avatar class="header-image"></div>
                <mat-card-title>{{reply.author.name}}</mat-card-title>
                <mat-card-subtitle>{{reply.createdAt|date:'dd/MM/yyyy - HH:mm'}}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div [innerHTML]="reply.body"></div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="linearTrs textArea" [ngClass]="{'col-12':!chatMode,'col-lg-6':chatMode}">
          <mat-card appearance="outlined" class="mb-3">
            <mat-card-content>
              <div>
                <mat-form-field class="full-width">
                  <mat-label>{{'YOUR_MESSAGE'|translate}}</mat-label>
                  <textarea [(ngModel)]="newMessage" matInput class="min-height-text"></textarea>
                </mat-form-field>
              </div>
            </mat-card-content>
            <mat-card-actions class="text-right">
              <button mat-button *ngIf="!sendAnswer" [disabled]="!newMessage" (click)="postJiraTicket()">{{'SEND'|translate}}</button>
              <div *ngIf="sendAnswer">
                {{'SENDING_MESSAGE'|translate}}<br/>
                <mat-progress-bar mode="buffer"></mat-progress-bar>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>

    </div>
  </div>
</div>
