import { Injectable } from '@angular/core';
import {HttpserviceService} from '../../services/httpservice.service';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {SnackErrorComponent} from '../errorNotification/snackerror/snack-error.component';
import {environment} from '../../../environments/environment';
import {FeesService, OpenAPI} from '../../sdk/transaction';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeesModService extends HttpserviceService{
  private readonly feesUrl : string;
  constructor(
    public router : Router,
    public http : HttpClient,
    public snackComponent : SnackErrorComponent,
    private feeServ: FeesService,
  ){
    super(router, http, snackComponent);
    this.feesUrl = environment.apiUrl + 'transactions/fees/';
    this.initSdKV3();
  }

  initSdKV3(){
    if (!this.isTest && !this.isSbt && !this.isGat) {
      OpenAPI.BASE = 'https://api.flyleo.it'
    } else if (!this.isTest && !this.isSbt && this.isGat) {
      OpenAPI.BASE = 'https://api.flygattinoni.it'
    } else if (!this.isTest && this.isSbt && !this.isGat) {
      OpenAPI.BASE = 'https://api.s4t.flygattinoni.it'
    }
    if(this.isTest){
      OpenAPI.BASE = 'https://api.stage.flyleo.it'
    }
    // OpenAPI.BASE = 'http://localhost:3000';
    // OpenAPI.BASE = 'http://192.168.22.79:3000'
    OpenAPI.TOKEN = async () => `${localStorage.getItem('fl_access_token')}`;

  }

  getFeesByCompanyId(data) : Observable<any>{
    return this.getByCpId(data)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  getAllFees(query) : Observable<any>{
    return this.getFeesList(query)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  getFeeFromId(id:string) : Observable<any>{
    return this.getFeeById(id)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  updateFeeFromId(id:string, body) : Observable<any>{
    return this.feeServ.feesControllerUpdate(id, body)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  createFee(body) : Observable<any>{
    return this.feeServ.feesControllerCreate( body)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  deleteFee(id) : Observable<any>{
    return this.feeServ.feesControllerDelete( id)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  getMine() : Observable<any>{
    return this.feeServ.feesControllerGetMyFees()
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err, false)),
      );
  }

  // feesControllerList

  getFeesList(query:any) : Observable<any>{
    const limit = query.limit;
    const skip = query.skip;
    const sort = query.sort;
    const order = query.order;
    const text = query.text;
    return this.feeServ.feesControllerList(limit, skip, text, order. sort);
  }

  linkFeesToCompany(id, body){
    return this.feeServ.feesControllerLinkFeesToCompany(id, body)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  unlinkFeesToCompany(id, body){
    return this.feeServ.feesControllerUnlinkFeesToCompany(id, body)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  getByCpId(data:any) : Observable<any>{
    return this.feeServ.feesControllerListForCompany(data.id, data.limit);
  }

  getFeeById(id){
    return this.feeServ.feesControllerGet(id);
  }

  private handleErrorV3(error : HttpErrorResponse, showSnackBar : boolean = true, customMsg? : string){
    let message : any = '';
    if(error.message) {
      message = error.message;
    }
    // @ts-ignore
    if (showSnackBar && !error.error?.isTrusted){
      const msg = customMsg ? customMsg : (error.message) ? error.message : JSON.stringify(message);
      this.snackComponent.openSnackBar(msg);
    }
    // return an observable with a user-facing error message
    return throwError(message);
  }
}
