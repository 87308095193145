import {
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SearchRes } from '../../../../../../classes/flights/search-res';
import { MatPaginator } from '@angular/material/paginator';
import {IatacompleteService} from '../../../../../../services/iatacomplete/iatacomplete.service';
import * as $ from 'jquery';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BottomlowcostComponent} from '../bottomlowcost/bottomlowcost.component';
import {AppComponent} from '../../../../../../app.component';
import {PriceService} from '../../../../../../services/price/price.service';
import { NotifierService } from 'angular-notifier';
import {TranslateService} from '@ngx-translate/core';
import {MainComponent} from '../../../../../mainmodule/main/main.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FlightService } from '../../../../../../services/flight/flight.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { DossierService } from '../../../../../../services/dossier/dossier.service';
import { TravelersService } from '../../../../../../services/travelers/travelers.service';
import * as CU from '../../../../../../../assets/json/countries.json';
import { BottomsheetComponent } from '../bottomsheet/bottomsheet.component';
import { FarerulesComponent } from '../../farerules/farerules.component';
import { CompaniesService } from '../../../../../../services/profile/companies.service';
import { Tariffes } from '../../../../../../classes/flights/tariffes';

@Component({
  selector: 'app-searchresults-ndc',
  templateUrl: './searchresults-ndc.component.html',
  styleUrls: ['./searchresults-ndc.component.scss']
})
export class SearchresultsNDCComponent implements OnInit {
  @Input() searchResults: any;
  @Input() selections: any;
  @Input() request: any;
  @Input() lowCost: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  outwardItinerariesUnified:any = [];
  returnsItinerariesUnified:any = [];
  recommendationsUnified:any = [];
  newRecommendations:any = [];
  outwardRefs: any = [];
  returnRefs: any = [];
  formvisible: boolean;
  currentPage = 0;
  company:any;
  ListSupportedRatesDetails:any = {};
  outwardCheckBoxSelectionList = [];
  returnCheckBoxSelectionList = [];
  outwardIndex = -1;
  returnIndex = -1;
  selectedFares = [];
  tarifsVisible = false;
  fare;

  constructor(
    public translate: TranslateService,
    private companyService : CompaniesService,
    public iataComplete : IatacompleteService,
    public priceservice : PriceService,
    public appComponent : AppComponent,
    public mainComponent : MainComponent,
    private newBottonSheet: MatDialog) { }

  ngOnInit(): void {

    console.log('dentro nuovo componente -> '+ JSON.stringify(this.searchResults))
    this.setFalseCheckBox();
    this.ListSupportedRatesDetails = {
      supplier: "lufthansa",
      marketingCarrier:  "LH",
      tarifs:
        [
          {
            name: "Economy Light",
            subName: "",
            details: [
              {
                icon: "fastfood",
                name: "Snack e bevande a pagamento",
                description: ""
              },
              {
                icon: "work",
                name: "1 bagaglio a mano",
                description: "Massimo 8Kg"
              },
              {
                icon: "euro_symbol",
                name: "Cambio prenotazione consentito",
                description: "Costo 70€ più possibile differenza tariffaria"
              },
              {
                icon: "mood_bad",
                name: "Rimborso non consentito",
                description: "",
                fixedMessage: "Suggeriamo di verificare sempre le informazioni sul sito della compagnia.",
                link: "https://www.lufthansa.com/"
              }
            ]
          },
          {
            name: "Economy Classic",
            subName: "",
            details: [
              {
                icon: "fastfood",
                name: "Snack e bevande a pagamento",
                description: ""
              },
              {
                icon: "airline_seat_recline_normal",
                name: "Prenotazione del posto",
                description: ""
              },
              {
                icon: "work",
                name: "1 Bagaglio registrato",
                description: "Massimo 23Kg"
              },
              {
                icon: "work",
                name: "1 bagaglio a mano",
                description: "Massimo 8Kg"
              },
              {
                icon: "euro_symbol",
                name: "Cambio prenotazione senza supplemento",
                description: "Potrebbe essere applicata una differenza tariffaria"
              },
              {
                icon: "mood_bad",
                name: "Rimborso non consentito",
                description: "",
                fixedMessage: "Suggeriamo di verificare sempre le informazioni sul sito della compagnia.",
                link: "https://www.lufthansa.com/"
              }
            ]
          },
          {
            name: "Economy Flex",
            subName: "",
            details: [
              {
                icon: "fastfood",
                name: "Snack e bevande a pagamento",
                description: ""
              },
              {
                icon: "fast_forward",
                name: "Imbarco prioritario",
                description: ""
              },
              {
                icon: "airline_seat_recline_normal",
                name: "Prenotazione del posto",
                description: ""
              },
              {
                icon: "work",
                name: "1 Bagaglio registrato",
                 description: "Massimo 23Kg"
              },
              {
                icon: "work",
                name: "1 bagaglio a mano",
                description: "Massimo 8Kg"
              },
              {
                icon: "euro_symbol",
                name: "Cambio prenotazione senza supplemento",
                description: "Potrebbe essere applicata una differenza tariffaria"
              },
              {
                icon: "mood_bad",
                name: "Rimborsabile",
                description: "Tranne 70€",
                fixedMessage: "Suggeriamo di verificare sempre le informazioni sul sito della compagnia.",
                link: "https://www.lufthansa.com/"

              }
            ]
          }
        ]
    },
    $('#outSelection').collapse('hide');

    if(this.searchResults.itineraries.length === 2){

      $('#returnSelection').collapse('hide');

    }

  }
  openDialog(ref,r, routingId, id, pippo, fares){

}
  setFalseCheckBox(){
    for(let i=0; i<this.outwardItinerariesUnified.length; i++){
      this.outwardCheckBoxSelectionList.push(false);
    }
    if(this.searchResults.itineraries.length === 2) {
      for(let i=0; i<this.outwardItinerariesUnified.length; i++){
        this.returnCheckBoxSelectionList.push(false);
      }
    }
  }

  mustBeVisible(){
    if(this.searchResults.itineraries.length === 1 && this.outwardIndex !== -1) {
      return true;
    }
    if(this.searchResults.itineraries.length === 2 && this.outwardIndex !== -1 && this.returnIndex !== -1) {
      return true;
    }
    else{
      return false;
    }
  }
  unifyOutwardByCode(){
    let outSec;
    let newElement;
    let flightEquals = [];
    let mustAdded;
    for(let i=0; i<this.searchResults.itineraries[0].sections.length; i++){
      outSec = this.searchResults.itineraries[0].sections[i];
      outSec.others = [];
      if(this.outwardItinerariesUnified.length === 0){
        this.outwardItinerariesUnified.push(outSec);
      } else {
        for(let j = 0; j < this.outwardItinerariesUnified.length; j ++){
          mustAdded = true;
          flightEquals = [];
          for(let x=0; x< outSec.flights.length; x++){
            if(this.outwardItinerariesUnified[j].flights[x]){
              if((outSec.flights[x].number === this.outwardItinerariesUnified[j].flights[x].number) && (outSec.flights[x].code === this.outwardItinerariesUnified[j].flights[x].code)){
                flightEquals.push(true);
              } else {
                flightEquals.push(false);
              }
            } else {
              flightEquals.push(false);
            }
          }
          if(flightEquals.length === 2 && (flightEquals[0] === true && flightEquals[1] === false) || (flightEquals[0] === true && flightEquals[1] === false))
            console.log('che fa qui?');
          if(!flightEquals.includes(false)){
            mustAdded = false;
            this.outwardItinerariesUnified[j].others.push(outSec);
            break;
          }
        }
        if(mustAdded){
          this.outwardItinerariesUnified.push(outSec);
        }
      }
    }
    console.log('ritorni unificati ' + JSON.stringify(this.outwardItinerariesUnified));
  }



  getCompany() {
    this.companyService.getCompany(this.mainComponent.loggedUser.company._id).subscribe((res) => {
      this.company = res;
    });
  }

  unifyReturnsByCode() {
    let outSec;
    let newElement;
    let flightEquals = [];
    let mustAdded;
    for(let i=0; i<this.searchResults.itineraries[1].sections.length; i++){
      outSec = this.searchResults.itineraries[1].sections[i];
      outSec.others = [];
      if(this.returnsItinerariesUnified.length === 0){
        this.returnsItinerariesUnified.push(outSec);
      } else {
        for(let j = 0; j < this.returnsItinerariesUnified.length; j ++){
          mustAdded = true;
          flightEquals = [];
            for(let x=0; x< outSec.flights.length; x++){
              if(this.returnsItinerariesUnified[j].flights[x]){
                if((outSec.flights[x].number === this.returnsItinerariesUnified[j].flights[x].number) && (outSec.flights[x].code === this.returnsItinerariesUnified[j].flights[x].code)){
                  flightEquals.push(true);
                } else {
                  flightEquals.push(false);
                }
              } else {
                flightEquals.push(false);
              }
            }
          if(flightEquals.length === 2 && (flightEquals[0] === true && flightEquals[1] === false) || (flightEquals[0] === true && flightEquals[1] === false))
            console.log('che fa qui?');
            if(!flightEquals.includes(false)){
              mustAdded = false;
              this.returnsItinerariesUnified[j].others.push(outSec);
              break;
            }
        }
        if(mustAdded){
          this.returnsItinerariesUnified.push(outSec);
        }
      }
    }
    console.log('ritorni unificati ' + JSON.stringify(this.returnsItinerariesUnified));
  }

  buildCouples(){
    let outRef;
    let retRef
    const allOutFlightsId = [];
    let possibleOutwards = [];
    for(let i=0; i< this.outwardItinerariesUnified.length; i++){
      outRef = this.getAllId(this.outwardItinerariesUnified[i]);
      for(let j=0; j< this.returnsItinerariesUnified.length; j++){
        retRef = this.getAllId(this.returnsItinerariesUnified[j]);
        for(let x=0; x<this.searchResults.recommendations.length; x++){
          if(outRef.includes(this.searchResults.itineraries[0].sections[this.searchResults.recommendations[x].itineraries[0].sections[0].ref -1].flightId) &&
            retRef.includes(this.searchResults.itineraries[1].sections[this.searchResults.recommendations[x].itineraries[1].sections[0].ref -1].flightId)){
            if(!possibleOutwards.includes(this.returnsItinerariesUnified[j].flightId)){
              possibleOutwards.push(this.returnsItinerariesUnified[j].flightId);
            }
          }
        }
      }
      this.outwardItinerariesUnified[i].returnPlane = possibleOutwards;
      possibleOutwards = [];

    }
  }

  getFareForThisCouple(outRef, retRef){
    for(let x=0; x<this.searchResults.recommendations.length; x++){
      if(outRef.includes(this.searchResults.itineraries[0].sections[this.searchResults.recommendations[x].itineraries[0].sections[0].ref -1].flightId) &&
        retRef.includes(this.searchResults.itineraries[1].sections[this.searchResults.recommendations[x].itineraries[1].sections[0].ref -1].flightId)){
        this.checkFare(this.searchResults.recommendations[x]);
      }
    }
  }

  checkFare(reco){
    const fare = this.searchResults.recommendations[reco].cabins[0].split('=')[1].split('*')[0];
    return fare;

  }


getAllId(obj){
    const  toRet = [];
    toRet.push(obj.flightId);
    for(let i=0; i< obj.others.length; i++) {
      toRet.push(obj.others[i].flightId);
    }
    return toRet;
}

  unifyRecommendations(){
    let reco;
    let outRefList = [];
    this.outwardRefs = [];
    let retRefList = [];
    this.returnRefs = [];
    let iti;
    for(let i=0; i<this.outwardItinerariesUnified.length; i++){
      if(!outRefList.includes(this.outwardItinerariesUnified[i].ref))
        outRefList.push(this.outwardItinerariesUnified[i].ref);
      for(let j=0; j<this.outwardItinerariesUnified[i].others.length; j++){
        if(!outRefList.includes(this.outwardItinerariesUnified[i].others[j].ref))
        outRefList.push(this.outwardItinerariesUnified[i].others[j].ref);
      }
      this.outwardRefs.push(outRefList);
      outRefList = [];
    }
    if(this.searchResults.itineraries.length === 2){
      for(let i=0; i<this.returnsItinerariesUnified.length; i++){
        if(!retRefList.includes(this.returnsItinerariesUnified[i].ref))
        retRefList.push(this.returnsItinerariesUnified[i].ref);
        for(let j=0; j<this.returnsItinerariesUnified[i].others.length; j++){
          if(!retRefList.includes(this.returnsItinerariesUnified[i].others[j].ref))
          retRefList.push(this.returnsItinerariesUnified[i].others[j].ref);
        }
        this.returnRefs.push(retRefList);
        retRefList = [];
      }

    }
    console.log('ref andate unificate -> '  + JSON.stringify(this.outwardRefs));
    console.log('ref ritorni unificati -> ' + JSON.stringify(this.returnRefs));



    for(let i=0; i<this.searchResults.recommendation.length; i++){
      this.searchResults.recommendation[i].others = [];
      this.addItineraries(this.searchResults.recommendation[i].itineraries);
    }
  }

  addItineraries(itineraries){
    if(itineraries.length === 1)
      this.findFirstOutward(itineraries[0].sections[0]);
  }

  findFirstOutward(ref){
    for(let i=0; i<this.outwardItinerariesUnified.length; i++){}
  }


  matchRecommendations(){
    if(this.searchResults.itineraries.length === 1){
      this.getOutwardReco();
    } else {
      this.getReturnReco();
    }
  }

  getOutwardReco(){
    for(let i=0; i<this.outwardRefs.length; i++){
      for(let j=0; j< this.searchResults.recommendation.length; j++){
        if(this.outwardRefs[i] === this.searchResults.recommendation[j].itineraries[0].sections[0].ref){
          this.newRecommendations.push(this.searchResults.recommendation[j]);
        }
      }
    }
  }

  getReturnReco(){
    for(let i=0; i<this.returnRefs.length; i++){
      for(let j=0; j< this.searchResults.recommendation.length; j++){
        if(this.returnRefs[i] === this.searchResults.recommendation[j].itineraries[1].sections[0].ref){
          this.newRecommendations.push(this.searchResults.recommendation[j]);
        }
      }
    }
  }

  toggleForm() {
    this.formvisible = ! this.formvisible;
    $('#searchCard').collapse('toggle');
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }
  toggleFilters() {
    $('.filterSearch').toggleClass('openfilters');
    $('.filtersearchBackdrop').toggleClass('openfiltersearchBackdrop');
  }

  isSupported(mark, tarif){
    for(let i=0; i<this.ListSupportedRatesDetails.length; i++){
      if(mark == this.ListSupportedRatesDetails[i].marketingCarrier){
        this.fare =  this.getRatesData(this.ListSupportedRatesDetails[i].marketingCarrier, tarif );
        return true;
      }
    }
    return false;
  }



  openBottomSheetLow(cabins, mk, reco) {
    if(typeof reco === 'string') reco = { name: reco, description: ''};
    if(!Array.isArray(reco)) reco = [reco];
    let info;
    if(Array.isArray(cabins) && cabins.length === 2){
      info = this.getRatesData(mk, reco, cabins.length);
    } else {
      info = this.getRatesData(mk, reco);
    }

    this.newBottonSheet.open(BottomlowcostComponent, {
      data: {cabins, mk, reco,info},
      panelClass: 'customLowCostStyle'
    });
    console.log('per aprire il modale prende questi parametri ' + JSON.stringify(cabins), mk, reco);
  }

  getRatesData(marketing, tarif, tarifNum = 1){
    const dataList = this.ListSupportedRatesDetails;
    const moreRet: any =[];
    let toRet:any = {};
    for(let y=0; y< tarifNum; y++) {
      toRet = {};
      for(let i=0; i< dataList.length; i++){
        if(dataList[i].marketingCarrier.toUpperCase() === marketing.toUpperCase()){
          toRet.supplier = dataList[i].supplier;
          for(const tar of dataList[i].tarifs){
            if(tar.name.toUpperCase() === (tarif[y].name.toUpperCase() ||'')){
              toRet.tarif = tar;
            }
          }
          moreRet.push(toRet);
        }
      }
    }
    return moreRet;
  }

  toggleShowFare(r) {
   // this.filteredRes[r + this.currentPage].showFares = ! this.filteredRes[r + this.currentPage].showFares;
  }

  opeTarifs(){
    $('#tarif').collapse('toggle');
  }

  opeSect(rec, it, value) {
    $('#collapse' + rec + it + value).collapse('toggle');
    $('#itinerary' + rec + it + value).collapse('toggle');
  }
  opeSectSelected(rec, it, value){
    $('#selectionRetCollapse' + rec + it + value).collapse('toggle');
    $('#selectionRet' + rec + it + value).collapse('toggle');
}

  opeSectSelectedOut(rec, it, value){
    $('#selectionOutCollapse' + rec + it + value).collapse('toggle');
    $('#selectionOut' + rec + it + value).collapse('toggle');
  }

  isSelected(){
    if(this.outwardIndex == -1 ) return false;
    else return true;
  }

  returnTime(t) {
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(t / cd);
    let h = Math.floor( (t - d * cd) / ch);
    let m = Math.round( (t - d * cd - h * ch) / 60000);
    const pad = ((n) => n < 10 ? '0' + n : n);
    if ( m === 60 ) {
      h++;
      m = 0;
    }
    if ( h === 24 ) {
      d++;
      h = 0;
    }
    if (d > 0) {
      return [d + 'g ' + pad(h) + 'h', pad(m) + 'm'].join(':');
    } else {
      return [pad(h) + 'h', pad(m) + 'm'].join(':');
    }

  }

  selectionFunction(ref, iti, refIti) {
    if(iti === 0){
      this.outwardCheckBoxSelectionList[refIti] = true;
    } else {
      this.returnCheckBoxSelectionList[refIti] = true;
    }

    /*
    try {
      return (this.selections[ref][iti] === refIti);
    } catch (err) {
      console.log('err => ', err);
    }
     */

  }

  changeSelection(ref, iti, refIti) {
    if(iti === 0){
      for(let i=0; i<this.outwardCheckBoxSelectionList.length; i++){
        if(i === refIti){
          this.outwardCheckBoxSelectionList[i] = true;
          this.outwardIndex = i;
        } else {
          this.outwardCheckBoxSelectionList[i] = false;
        }
      }
    } else if(iti === 1){
      for(let i=0; i<this.returnCheckBoxSelectionList.length; i++){
        if(i === refIti){
          this.returnCheckBoxSelectionList[i] = true;
          this.returnIndex = i;
        } else {
          this.returnCheckBoxSelectionList[i] = false;
        }
      }
    }
    if(this.checkSelections()){
      // sposta la selezione in alto
      // falla vedere, poi visualizza le possibili tariffe per questa selezione
      if(this.searchResults.itineraries.length === 1){
        $('#outSelection').collapse('show');
        $('#returnSelection').collapse('hide');
      } else {
        $('#outSelection').collapse('show');
        $('#returnSelection').collapse('show');
      }
      this.selectedFares = this.recoExists();
      if(this.selectedFares.length > 0){
        this.tarifsVisible = true;
      }

    } else {
      this.tarifsVisible = false;
    }
    /*
    if (this.selections[ref][iti] === refIti) { return; } else { this.selections[ref][iti] = refIti; }

     */
  }
   recoExists(){
    const findedTarifs = [];
    let outRefId = [];
    let retRefId = [];
    if(this.searchResults.itineraries.length === 1){
      // cerca le tariffe per sola andata
      outRefId = this.getAllId(this.outwardItinerariesUnified[this.outwardIndex]);
      for(let i=0; i<this.searchResults.recommendations.length; i++){
        if(outRefId.includes(this.searchResults.itineraries[0].sections[this.searchResults.recommendations[i].ref -1].flightId)){
          findedTarifs.push(i);
        }
      }
    } else if(this.searchResults.itineraries.length === 2){
      // cerca le tariffe andata e ritorno
      outRefId = this.getAllId(this.outwardItinerariesUnified[this.outwardIndex]);
      retRefId = this.getAllId(this.returnsItinerariesUnified[this.returnIndex]);
      for(let i=0; i<this.searchResults.recommendations.length; i++){
        if(outRefId.includes(this.searchResults.itineraries[0].sections[this.searchResults.recommendations[i].itineraries[0].sections[0].ref -1].flightId) &&
           retRefId.includes(this.searchResults.itineraries[1].sections[this.searchResults.recommendations[i].itineraries[1].sections[0].ref -1].flightId)){
            findedTarifs.push(i);
        }
      }
    }
    return findedTarifs;
   }

  getPrice(indexFare){
    return this.searchResults.recommendations[indexFare].fare.amount;
  }
  checkSelections(){
    if(this.searchResults.itineraries.length === 1){
      for(const checkbox of this.outwardCheckBoxSelectionList){
        if(checkbox) return true
      }
    } else {
      let flag = false;
      for(const checkbox of this.outwardCheckBoxSelectionList){
        if(checkbox) flag = true;
      }
      for(const checkbox of this.returnCheckBoxSelectionList){
        if(checkbox && flag) return true;
      }
    }
  }

  returnZeroTime(value) {
    if (typeof value !== 'string') {
      if (value < 10) {
        return '0' + value;
      } else {
        return value;
      }
    } else {
      return value;
    }

  }

  otherday(value, other) {
    return value !== other;
  }
}
