<div class="default_panel">
  <div class="default_heading d-flex justify-content-between">
    <div class="d-flex align-self-center">Servizi di viaggio attivi</div>
    <div class="d-flex align-items-center">
      <button (click)="buildChart()" mat-icon-button aria-label="Refresh" class="small-md">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
  <div class="default_content">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [title]="chartOptions.title"
      [theme]="chartOptions.theme"
      [responsive]="chartOptions.responsive"
    ></apx-chart>
    <!--
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [responsive]="chartOptions.responsive"
    ></apx-chart> -->
  </div>

</div>
