<div>
  <div>
    <span *ngFor="let class of data.fare.details;let l = last;">
        {{class.name||class.tariffClass}} <span *ngIf="!l">+ </span>
    </span>
  </div>
  <hr/>
  <div>
    <span *ngIf="!data.fare.refundable">
              <span class="material-icons greytxt">
                cancel
              </span>
      {{'NO_REFOUND'|translate}}
            </span>
    <span *ngIf="data.fare.refundable">
              <span class="material-icons greentxt">
                check_circle
              </span> {{'YES_REFOUND'|translate}}
            </span>
  </div>
  <hr/>
  <!--
  <div class="data.farePrice mb-2">
    {{'PRICE'|translate}}
    <span *ngIf="!data.fare.negotiated" class="">
      {{priceservice.returnPrice(data.fare.fare.amount)|number:'.2-2'}} €
    </span>
    <span *ngIf="data.fare.negotiated && priceservice.isNegoHidden()" class="">
      {{priceservice.returnPrice(data.fare.fare.publicAmount)|number:'.2-2'}} €
    </span>
    <span *ngIf="data.fare.negotiated && !priceservice.isNegoHidden()" class="">
      {{priceservice.returnPrice(data.fare.fare.amount)|number:'.2-2'}} €
    </span>
  </div> -->
  <div class="bagInfo mb-3">
    <div *ngIf="data.fare.passengers.ADT">
      <span class="material-icons">
        work
      </span>
      {{'BAG_FOR_ADULT'|translate}}:
      <span *ngFor="let bag of data.fare.passengers.ADT.baggage; let i = index;let l = last">
        <span *ngIf="l && i != 0"> - </span>
        {{bag.allowance}}
        <span *ngIf="bag.allowance < 10">
            BAG
        </span>
        <span *ngIf="bag.allowance >= 10">
          Kg
        </span>
        </span>
    </div>
    <div *ngIf="data.fare.passengers.CHD && data.fare.passengers.CHD.baggage.length>0">
      <span class="material-icons">
        work
      </span>
      {{'BAG_FOR_CHILD'|translate}}:
      <span *ngFor="let bag of data.fare.passengers.CHD.baggage; let i = index;let l = last">
        <span *ngIf="l && i != 0"> - </span>
        {{bag.allowance}}
        <span *ngIf="bag.allowance < 10">
            BAG
        </span>
        <span *ngIf="bag.allowance >= 10">
          Kg
        </span>
        </span>
    </div>
    <div *ngIf="data.fare.passengers.INF && data.fare.passengers.INF.baggage.length>0">
      <span class="material-icons">
        work
      </span>
      {{'BAG_FOR_INFANT'|translate}}:
      <span *ngFor="let bag of data.fare.passengers.INF.baggage; let i = index;let l = last">
        <span *ngIf="l && i != 0"> - </span>
        {{bag.allowance}}
        <span *ngIf="bag.allowance < 10">
            BAG
        </span>
        <span *ngIf="bag.allowance >= 10">
          Kg
        </span>
      </span>
    </div>
  </div>

  <div class="fareMessage">
    <div [innerHTML]="convertMessage(data.fare.message)"></div>
  </div>
</div>
