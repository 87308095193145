<div class="row">
  <div class="col-4" *ngFor="let childRequest of ticket.body.payload.children; let i = index;">
    {{ 'MINOR_DATA' | translate }} {{ i + 1 }}
    <mat-divider></mat-divider>
    <div class="col-12 px-4 py-2">
      <div class="row">
        <mat-form-field class="full-width">
          <mat-label>{{ 'NAME' | translate}}</mat-label>
          <input disabled matInput [(ngModel)]="childRequest.name" type="text">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="full-width">
          <mat-label>{{ 'SURNAME' | translate}}</mat-label>
          <input disabled matInput [(ngModel)]="childRequest.surname" type="text">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="full-width">
          <mat-label>{{ 'BIRTHDAY' | translate}}</mat-label>
          <input disabled matInput readonly [matDatepicker]="jj" placeholder="{{'DEPARTURE_DATE'|translate}}" (click)="jj.open()" [(ngModel)]="childRequest.birthday"
                 name="date{{i}}">
          <mat-datepicker-toggle matSuffix [for]="jj"></mat-datepicker-toggle>
          <mat-datepicker #jj></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="full-width">
          <mat-label>{{ 'ADDRESS' | translate}}</mat-label>
          <input disabled matInput [(ngModel)]="childRequest.address" type="text">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="full-width">
          <mat-label>{{ 'CITY' | translate}}</mat-label>
          <input disabled matInput [(ngModel)]="childRequest.city" type="text">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="full-width">
          <mat-label>{{ 'POSTAL_CODE' | translate}}</mat-label>
          <input disabled matInput [(ngModel)]="childRequest.postal_code" type="text">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="full-width">
          <mat-label>{{ 'LANGUAGE' | translate}}</mat-label>
          <input disabled matInput [(ngModel)]="childRequest.language" type="text">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="full-width">
          <mat-label>{{ 'DISEASES' | translate}}</mat-label>
          <input disabled matInput [(ngModel)]="childRequest.diseases" type="text">
        </mat-form-field>
      </div>
      <div class="row">
          <mat-form-field class="full-width">
            <mat-label>{{ 'OBSERVATIONS' | translate}}</mat-label>
            <input disabled matInput [(ngModel)]="childRequest.other" type="text">
          </mat-form-field>
        </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12" *ngFor="let flightRequest of ticket.body.payload.flights; let i = index;">
    {{ 'FLIGHT' | translate }} {{ i + 1 }}
    <mat-divider></mat-divider>
    <div class="row px-2 py-2">
        <div class="col-4 px-2">
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'DEPARTURE' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.departure" [(ngModel)]="flightRequest.departure">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'ARRIVAL' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.arrival" [(ngModel)]="flightRequest.arrival">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'DATE' | translate}}</mat-label>
              <input disabled matInput readonly [matDatepicker]="jj" placeholder="{{'DEPARTURE_DATE'|translate}}" (click)="jj.open()" [(ngModel)]="flightRequest.date"
                     name="date{{i}}">
              <mat-datepicker-toggle matSuffix [for]="jj"></mat-datepicker-toggle>
              <mat-datepicker #jj></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'TIME' | translate}}</mat-label>
              <mat-select disabled [(ngModel)]="flightRequest.time_slot" name="time">
                <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                <mat-option value="2">{{'MORNING'|translate}} (06-12)</mat-option>
                <mat-option value="3">{{'AFTERNOON'|translate}} (12-18)</mat-option>
                <mat-option value="4">{{'EVENING'|translate}} (18-24)</mat-option>
                <mat-option value="1">{{'NIGHT'|translate}} (00-06)</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-4 px-2">
          {{'PERSON_GOING_TO_AIRPORT' | translate}}
          <mat-divider></mat-divider>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'NAME' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.departure_name" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'SURNAME' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.departure_surname" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'CITY' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.departure_city" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'POSTAL_CODE' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.departure_postal_code" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'ADDRESS' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.departure_address" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'CELLULAR' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.departure_cellular" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'PHONE_NUMBER' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.departure_phone_number" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'FAMILY' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.departure_family" type="text">
            </mat-form-field>
          </div>
        </div>
        <div class="col-4 px-2">
          {{'PERSON_WAITING_AT_AIRPORT' | translate}}
          <mat-divider></mat-divider>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'NAME' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.arrival_name" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'SURNAME' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.arrival_surname" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'CITY' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.arrival_city" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'POSTAL_CODE' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.arrival_postal_code" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'ADDRESS' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.arrival_address" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'CELLULAR' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.arrival_cellular" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'PHONE_NUMBER' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.arrival_phone_number" type="text">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="full-width">
              <mat-label>{{ 'FAMILY' | translate}}</mat-label>
              <input disabled matInput [(ngModel)]="flightRequest.arrival_family" type="text">
            </mat-form-field>
          </div>
        </div>
    </div>
  </div>
</div>
