import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'businessClass'
})
export class BusinessClassPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    /**
     * Get flights type and format them
     */
    for (const el in value) {
      if (Object.prototype.hasOwnProperty.call(value, el)) {
        if (value[el].length > 0) {
          return el;
        }
      }
    }
    return null;
  }

}
