import { Component, OnInit } from '@angular/core';
import { UserGroupsService } from '../../services/user-groups.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectGroupDialogComponent } from '../../components/select-group-dialog/select-group-dialog.component';
import { take } from 'rxjs/operators';
import { of } from 'rxjs';
import {UsersService} from '../../../../services/profile/users.service';
import {UsersList} from '../../../../classes/userlist';
import {MainComponent} from '../../../mainmodule/main/main.component';

@Component({
  selector: 'app-all-user-groups',
  templateUrl: './all-user-groups.component.html',
  styleUrls: [ './all-user-groups.component.scss' ]
})
export class AllUserGroupsComponent implements OnInit {

  users: any;
  query: any;

  constructor(
    private userGroupsService: UserGroupsService,
    private usersService: UsersService,
    public dialog: MatDialog,
    private mainComponent: MainComponent,
  ) {
    this.query = {
      options: {
        viewAll: false,
      },
      sort: 'createdAt',
      limit: 9999,
      skip: 0,
      order: 'desc',
      text: '',
    };
  }

  ngOnInit(): void {
    this.getUserList();
  }

  getUserList(): void {
    console.log('this.query => ', this.query);
    this.usersService.getUsersListCp(this.query, this.mainComponent.loggedUser.company._id).subscribe((res: UsersList) => {
      this.users = res;
    }, error => {
      console.error('Error => ', error);
    });
  }

  handleEditUser(user: any): void {
    this.userGroupsService.groups.pipe(take(1)).subscribe((result: any) => {
      const dialogRef = this.dialog.open(SelectGroupDialogComponent, {
        width: '250px',
        data: {
          user: user._id,
          groups: result.data,
          selectedGroup: user?.group?._id
        }
      });

      dialogRef.afterClosed().pipe(take(1)).subscribe(success => {
        if (success) {
          delete this.users;
          this.getUserList();
        }
      });
    });


  }

}
