<custom-title></custom-title>
<div class="dashboard padding20">

  <!--
  <div clasS="flip">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12 marginbottom10">
        <div class="datasetpanel">
          <div class="">
            <h5 class="titlesect">Orders recived</h5>
            <div class="numericData">13.500</div>
            <div class="monthstatus good">
            <span>
              +18% <mat-icon>arrow_upward</mat-icon>
            </span> than last month</div>
          </div>

        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-12 marginbottom10">
        <div class="datasetpanel">
          <div class="">
            <h5 class="titlesect">Product sold</h5>
            <div class="numericData">17.500</div>
            <div class="monthstatus bad"><span>
            -10% <mat-icon>arrow_downward</mat-icon>
          </span> than last month</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 marginbottom10">
        <div class="datasetpanel">
          <div class="">
            <h5 class="titlesect">Total profit</h5>
            <div class="numericData">$45.560,70</div>
            <div class="monthstatus good">
            <span>
              +9,3% <mat-icon>arrow_upward</mat-icon>
            </span> than last month</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 marginbottom10">
        <div class="datasetpanel">
          <div class="">
            <h5 class="titlesect">Expenses</h5>
            <div class="numericData">$5.560,70</div>
            <div class="monthstatus good">
            <span>
              -1,5% <mat-icon>arrow_downward</mat-icon>
            </span> than last month</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 marginbottom10">
        <app-barchart [chartmode]="appcompo.chartmode"></app-barchart>
      </div>

      <div class="col-lg-6 col-md-6 marginbottom10">
        <app-donutchart [chartmode]="appcompo.chartmode"></app-donutchart>
      </div>

    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-12 marginbottom10">
        <app-areachart [chartmode]="appcompo.chartmode"></app-areachart>
      </div>
      <div class="col-lg-6 col-md-6 col-12 marginbottom10">
        <app-moneychart [chartmode]="appcompo.chartmode"></app-moneychart>
      </div>
    </div>

  </div>

   -->

</div>
