<custom-title></custom-title>
<div *ngIf="page!=='widget' && maincomponent.loggedUser.role.profile.user.get.company" class="userlist padding20">
  <div *ngIf="loading" class="loader">
    <mat-card appearance="outlined" class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </mat-card>
  </div>

  <div *ngIf="!loading && usersList" class="searchrow"> <!-- && page==undefined -->
    <div class="row">
      <div class="col-8">
        <mat-form-field class="marginfield">
          <input placeholder="{{'SEARCH'|translate}}" matInput [(ngModel)]="text" (change)="searchName()">
          <mat-icon *ngIf="!text" matSuffix>search</mat-icon>
          <button *ngIf="text" matSuffix mat-icon-button aria-label="Clear" (click)="text='';searchName()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-hint align="start">{{'DIGIT_YOUR_SEARCH'|translate}}</mat-hint>
        </mat-form-field>

        <mat-checkbox *ngIf="maincomponent.loggedUser.role.superAdmin && !appcomponent.isSbt && !appcomponent.isGat && page==undefined"
                      class="ml-3 vertical mt-2 d-inline-block" [(ngModel)]="query.options.viewAll" (ngModelChange)="getUserList()" color="primary">
          viewAll
        </mat-checkbox>

        <mat-form-field *ngIf="!isList">
          <mat-label>{{'ORDER_BY'|translate}}</mat-label>
          <mat-select [(value)]="query.sort">
            <mat-option value="surname" (click)="sortResults('surname')">
              {{'SURNAME_NAME'|translate}}
            </mat-option>
            <mat-option value="createdAt" (click)="sortResults('createdAt')">
              {{'CREATION'|translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button class="iconButtons" (click)="sortResults(query.sort)" *ngIf="!isList" mat-icon-button aria-label="Order">
          <mat-icon *ngIf="query.order=='asc'" matSuffix>vertical_align_bottom</mat-icon>
          <mat-icon *ngIf="query.order=='desc'" matSuffix>vertical_align_top</mat-icon>
        </button>


      </div>
      <div class="col-4 text-right align-self-center">
        <button class="iconButtons" matTooltip="{{'CHANGE_VIEW'|translate}}" matTooltipPosition="before" mat-icon-button aria-label="toggle list" (click)="isList=!isList">
          <mat-icon *ngIf="!isList">format_list_bulleted</mat-icon>
          <mat-icon *ngIf="isList">grid_on</mat-icon>
        </button>

        <button class="iconButtons" *ngIf="maincomponent.loggedUser.role.superAdmin" matTooltip="{{'ADD_USER'|translate}}" matTooltipPosition="before" mat-icon-button aria-label="toggle list" (click)="openDialogNewUser()">
          <mat-icon>add</mat-icon>
        </button>


      </div>
    </div>
  </div>

  <div *ngIf="!loading && usersList" class="" style="background-color: #fff">
    <div class="row">
      <div class="col-12 col-md-6 d-flex justify-content-end pt-3">
        <button *ngIf="selection.length>0" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon>chat_bubble_outline</mat-icon>
            <span>SEND_MESSAGE</span>
          </button>
          <button (click)="deleteSelected()" mat-menu-item>
            <mat-icon>delete_forever</mat-icon>
            <span>DISABLE_SELECTED</span>
          </button>
          <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>ENABLE_SELECTED</span>
          </button>
        </mat-menu>
        <button *ngIf="selection.length>0" class="iconButtons" mat-icon-button aria-label="Message">
          <mat-icon>chat_bubble_outline</mat-icon>
        </button>
        <button *ngIf="selection.length>0" class="iconButtons" mat-icon-button aria-label="Delete" (click)="deleteSelected()" color="warn">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
      <div class="col-12 col-md-6 d-flex justify-content-end text-right">
        <div class="paginationr pr-3">
          <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
          <span class="mr-2">
            {{'PAGE'|translate}}
            <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
              <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p+1}}</mat-option>
              </mat-select>
            </mat-form-field>
            {{'OF'|translate}} {{maxpages}}
          </span>
          <button mat-icon-button class="iconButtons" (click)="prev()" [disabled]="currentpage==0">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button mat-icon-button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="list_table" *ngIf="!loading && isList && page!='dashboard'">
    <div class="progressLoader" *ngIf="progress">
      <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
    </div>

    <div class="tablelist" [ngClass]="{'withscroll':page==undefined}">
      <table mat-table [dataSource]="usersList.list">

        <ng-container matColumnDef="select" *ngIf="maincomponent.loggedUser.role.superAdmin && page!='dashboard'">
          <th mat-header-cell style="min-width: 85px;width:85px;" *matHeaderCellDef>
            <mat-checkbox [ngModel]="isAllSelected()" (change)="selectOnAll()" [indeterminate]="someSelected()" class="example-margin" color="primary">
            </mat-checkbox>
          </th>
          <td mat-cell style="min-width: 85px;width:85px;" *matCellDef="let element">
            <mat-checkbox *ngIf="element._id!==maincomponent.loggedUser._id" [ngModel]="isSelected(element._id)" (change)="selectValue(element._id)"
                          class="example-margin" color="primary">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <span class="sorting tablepadding" matRipple matRippleCentered="true" (click)="sortResults('surname')">{{'SURNAME_NAME'|translate}}</span>
            <mat-icon *ngIf="query.sort==='surname' && query.order==='asc'">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="query.sort==='surname' && query.order==='desc'">keyboard_arrow_up</mat-icon>
          </th>
          <td mat-cell *matCellDef="let element">

            <span class="tablepadding">
              <img class="listimage" [src]="element.image"/>
              <a routerLink="/users/list/{{element._id}}"><span matTooltip="{{element.surname}} {{element.name}}">{{element.surname}} {{element.name}}</span></a>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="data" *ngIf="page!='dashboard'">
          <th mat-header-cell *matHeaderCellDef>
            <span class="sorting" matRipple matRippleCentered="true" (click)="sortResults('createdAt')">{{'CREATION'|translate}}</span>
            <mat-icon *ngIf="query.sort==='createdAt' && query.order==='asc'">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="query.sort==='createdAt' && query.order==='desc'">keyboard_arrow_up</mat-icon>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.createdAt|date:'dd/MM/yyyy - HH:mm'}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="email" >
          <th mat-header-cell *matHeaderCellDef>
            <span class="sorting" matRipple matRippleCentered="true" (click)="sortResults('email')">{{'EMAIL'|translate}}</span>
            <mat-icon *ngIf="query.sort==='email' && query.order==='asc'">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="query.sort==='email' && query.order==='desc'">keyboard_arrow_up</mat-icon>

          </th>
          <td mat-cell *matCellDef="let element"> <span matTooltip="{{element.email}}">{{element.email}}</span> </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="phone" *ngIf="page!='dashboard'">
          <th mat-header-cell *matHeaderCellDef> {{'PHONE'|translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
        </ng-container>

        <!-- Star Column -->
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>
            {{'COMPANY'|translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="capitalize"><a routerLink="/companies/list/{{element.company._id}}">{{element.company.name}}</a></span>
          </td>
        </ng-container>

        <ng-container matColumnDef="activation" *ngIf="maincomponent.loggedUser.role.superAdmin && page==undefined">
          <th mat-header-cell *matHeaderCellDef>{{'ACTIVATION'|translate}}</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon color="primary" *ngIf="element.enable">check</mat-icon>
            <mat-icon color="accent" *ngIf="!element.enable">close</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="loginas" *ngIf="maincomponent.loggedUser.role.superAdmin">
          <th mat-header-cell *matHeaderCellDef>{{'LOGIN_AS'|translate}}</th>
          <td mat-cell *matCellDef="let element">
            <button class="iconButtons" matTooltip="{{'LOGIN_AS'|translate}} {{element.surname}} {{element.name}}" mat-icon-button *ngIf="maincomponent.loggedUser.role.superAdmin" (click)="loginAs(element)">
              <mat-icon>login</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

  </div>

  <div class="list_grid" *ngIf="!loading && !isList">
    <div class="progressLoader" *ngIf="progress">
      <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
    </div>
    <div class="marginbottom20"></div>
    <div class="row">
      <div *ngFor="let user of usersList.list;index as i" [ngClass]="{'col-xl-4 col-lg-6 col-md-6 align-self-center':page==undefined,'col-xl-6':page!=undefined}">
        <mat-card appearance="outlined" class="user-card marginbottom20">
          <mat-card-header>
            <div mat-card-avatar class="user-header-image" [style.background-image]="'url(' + user.image + ')'"></div>
            <button mat-icon-button class="absolright iconButtons" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>dialpad</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <div mat-menu-item>
                <mat-icon>mail_outline</mat-icon>
                <span><a href="mailto:{{user.email}}">{{user.email}}</a></span>
              </div>
              <div mat-menu-item>
                <mat-icon>phone</mat-icon>
                <span><a href="tel:{{user.phoneNumber}}">{{user.phoneNumber}}</a></span>
              </div>
              <div mat-menu-item>
                <mat-icon>account_balance</mat-icon>
                <span><a routerLink="/companies/list//{{user.company._id}}">{{user.company.name}}</a></span>
              </div>


            </mat-menu>
            <mat-card-title><a routerLink="/users/list/{{user._id}}">{{user.surname}} {{user.name}}</a></mat-card-title>
            <mat-card-subtitle>{{user.company.name}}</mat-card-subtitle>
            <!-- <mat-card-subtitle>{{user.type}}</mat-card-subtitle> -->
          </mat-card-header>

        </mat-card>
      </div>

    </div>
  </div>

  <div class="padding20 text-center noresult" *ngIf="!loading && usersList.list.length==0">
    <mat-icon style="font-size:40px" class="marginbottom10">sentiment_very_dissatisfied</mat-icon>
    <h3>{{'NO_RESULTS'|translate}}.</h3>
    <p *ngIf="text">{{'NO_RESULTS_TEXT'|translate}} <span class="searchtext">{{text}}</span>.</p>
    <p *ngIf="text">{{'NO_RESULTS_TRY_AGAIN'|translate}}.</p>
  </div>

  <div *ngIf="!loading && usersList" class="" style="background-color: #fff">
    <div class="row">
      <div class="col-12 col-md-6 d-flex justify-content-end pt-3">
        <button *ngIf="selection.length>0" class="iconButtons" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon>chat_bubble_outline</mat-icon>
            <span>SEND_MESSAGE</span>
          </button>
          <button (click)="deleteSelected()" mat-menu-item>
            <mat-icon>delete_forever</mat-icon>
            <span>DISABLE_SELECTED</span>
          </button>
          <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>ENABLE_SELECTED</span>
          </button>
        </mat-menu>
        <button *ngIf="selection.length>0" class="iconButtons" mat-icon-button aria-label="Message">
          <mat-icon>chat_bubble_outline</mat-icon>
        </button>
        <button *ngIf="selection.length>0" class="iconButtons" mat-icon-button aria-label="Delete" (click)="deleteSelected()" color="warn">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
      <div class="col-12 col-md-6 d-flex justify-content-end text-right">
        <div class="paginationr pr-3">
          <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
          <span class="mr-2">
            {{'PAGE'|translate}}
            <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
              <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p+1}}</mat-option>
              </mat-select>
            </mat-form-field>
            {{'OF'|translate}} {{maxpages}}
          </span>
          <button mat-icon-button class="iconButtons" (click)="prev()" [disabled]="currentpage==0">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button mat-icon-button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="page==='widget'">
  <div class="default_panel">
    <div class="absoluteLoading" *ngIf="loading || progress">

      <div class="absoluteLoading">
        <div class="padding20 text-center">
          <p>{{'LOADING'|translate}}</p>
          <mat-progress-bar class="example-margin" mode="buffer">
          </mat-progress-bar>
        </div>
      </div>
    </div>
    <div class="default_heading d-flex justify-content-between">
      <div *ngIf="usersList" class="d-flex align-self-center">{{title|translate}} ({{usersList.count}})</div>
      <div class="d-flex align-items-center">
        <button mat-icon-button  (click)="prev()" class="iconButtons" [disabled]="currentpage==0">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-icon-button (click)="next()" class="iconButtons" [disabled]="currentpage==(maxpages-1)">
          <mat-icon>chevron_right</mat-icon>
        </button>
        <button mat-icon-button aria-label="Refresh" class="iconButtons" (click)="getUserList()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
    <div class="default_content smfont scrolling">

      <div *ngIf="usersList">
        <div class="d-flex justify-content-between bd-highlight borderbottom" *ngFor="let user of usersList.list;let l = last" [ngClass]="{'mb-3':!l}">
          <div class="avatar">
            <img class="avataricon" [src]="user.image"/>
          </div>
          <div class="flex-fill">
            <p class="smalltitle"><a routerLink="/users/list/{{user._id}}">{{user.name}} {{user.surname}}</a></p>
            <p class="smalltext">{{user.company.name}}</p>
            <p class="smalltext">{{user.phoneNumber}}</p>
          </div>
          <div class="flex-fill text-right">
            <button (click)="deleteUser(user._id)" matTooltip="Disable User" color="primary" *ngIf="user.enable && maincomponent.loggedUser.role.superAdmin" class="small-md" mat-icon-button aria-label="disable">
              <mat-icon>lock_open</mat-icon>
            </button>
            <button (click)="enableUser(user._id, user)" matTooltip="Enable User" color="warn" *ngIf="!user.enable && maincomponent.loggedUser.role.superAdmin" class="small-md" mat-icon-button aria-label="enable">
              <mat-icon>lock</mat-icon>
            </button>
            <button matTooltip="Send message" (click)="openMessage(user)" class="small-md marginleft5" data-toggle="modal" data-target="#emailModal" mat-icon-button aria-label="write message">
              <mat-icon>mail_outline</mat-icon>
            </button>

          </div>
        </div>
      </div>


    </div>

    <div class="default_footer text-center">
      <a routerLink="/users/list" mat-button class="small-md-text">{{'USERS_LIST'|translate}}</a>
    </div>

  </div>
  <div class="modal fade" id="emailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content" *ngIf="recipient">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Write a message to {{recipient.name}} {{recipient.surname}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <p>mail to: {{recipient.email}}</p>

          <mat-form-field class="full-width">
            <mat-label>Type your message</mat-label>
            <textarea matInput [(ngModel)]="message" placeholder="Your message"></textarea>
          </mat-form-field>
        </div>
        <div class="modal-footer">
          <button mat-button data-dismiss="modal">Close</button>
          <button mat-button (click)="sendMessage(message)" [disabled]="!message">Send</button>
          <mat-progress-bar class="example-margin" mode="buffer" *ngIf="sending"></mat-progress-bar>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="padding20 text-center" *ngIf="!maincomponent.loggedUser.role.profile.user.get.company">
  <mat-icon style="font-size:40px" class="marginbottom10">sentiment_very_dissatisfied</mat-icon>
  <h3>{{'NO_ACCESS'|translate}}.</h3>
  <p>{{'NO_ACCESS_TEXT'|translate}}</p>
</div>
