import { Component, Inject } from '@angular/core';
import { Destination, HotelForm, HotelOptions } from "../classes/hotel";
import { BehaviorSubject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { PriceService } from "../../../services/price/price.service";
import { IatacompleteService } from "../../../services/iatacomplete/iatacomplete.service";
import { DossierService } from "../../../services/dossier/dossier.service";
import { HotelService } from "../hotel.service";
import { CompaniesService } from "../../../services/profile/companies.service";
import { TravelersService } from "../../../services/travelers/travelers.service";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ButtonsStrategy, ButtonType, Description, Image, LoadingConfig,
  LoadingType, ModalGalleryConfig, ModalGalleryRef, ModalGalleryService } from "@ks89/angular-modal-gallery";
import { NewTraveller} from "../../../classes/travelers/newTraveller";
import { MapInfoDialogComponent } from "../map-info-dialog/map-info-dialog.component";
import * as $ from 'jquery';
import { MatDialog } from "@angular/material/dialog";
import { AppComponent } from "../../../app.component";
import { MainComponent } from "../../mainmodule/main/main.component";
@Component({
  selector: 'app-new-hotel-quotation-v3',
  templateUrl: './new-hotel-quotation-v3.component.html',
  styleUrls: ['./new-hotel-quotation-v3.component.scss']
})
export class NewHotelQuotationV3Component {
  settings: any;
  form: any;
  hotelForm : HotelForm;
  hotelRes: any;
  loading = false;
  allImages : any[] = [];
  allHotels : any[] = [];
  hotelInfos = false;
  currentTraveler : number;
  currentTab: any;
  guests = [];
  tabs : any[] = [];
  editingTraveler : boolean;
  loadTravelers: boolean;
  selecetedHotel: any;
  roomSelected: any;
  fareSelected: any;
  passengers: any;
  sarce : any;
  allRooms: any = [];
  providerToFilter = [];
  refoundToFilter = [];
  mealToFilter = [];
  mealsTypes = [];
  corporateToFilter = false;
  corporateFilter = false;
  hotelProviders = [];
  filteredRoomList = [];
  images= [];
  selectedTabIndex = 1;
  loadedImages = false;
  requoting = false;
  favorites: any;
  loadedFav = false
  today: any;
  customFields: any[] = [];
  listCorporate: any[] = [];
  /*appComponent: any;
  mainComponent: any;*/
  rating: any;
  minCheckOutDate$ : BehaviorSubject<any> = new BehaviorSubject<any>(undefined)
  constructor(
    public appComponent: AppComponent,
    public mainComponent: MainComponent,
    private router: Router,
    public priceservice: PriceService,
    public iataComplete: IatacompleteService,
    private dossierService: DossierService,
    private hotelService: HotelService,
    private api: CompaniesService,
    private  travelersService: TravelersService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private modalGalleryService: ModalGalleryService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.settings = {
      counter: false,
      // plugins: [lgThumbnail],
      thumbnail: true,
    };
    this.tabs = [
      {
        active: true,
        name: 'INFO'
      },
      {
        active: false,
        name: 'ROOMS'
      },
      {
        active: false,
        name: 'GUESTS'
      },
      {
        active: false,
        name : 'CONFIRM'
      },
      {
        active: false,
        name: 'CHANGE_DATE'
      }

    ];
    if (localStorage.getItem('sarceDossier')) {
      this.sarce = JSON.parse(localStorage.getItem('sarceDossier'));
    } else {
      delete this.sarce;
    }
  }
  ngOnInit(): void {
    this.today = new Date().toISOString();
    this.tabs.forEach(tab => {
      if (tab.name === 'ROOMS') this.selectedTabIndex = tab.index
    })
    this.route.queryParams.subscribe(params => {
      this.form = JSON.parse(JSON.stringify(params));
      if(this.form.occupancy) {
        this.form.occupancy = JSON.parse(this.form.occupancy);
      }
      if(this.form.code.includes(',')){
        this.form.code = this.form.code.split(',');
      }
      this.rating = params.rating;
      this.createHotelForm();
      this.getFavorites();
    });
    this.getCorporates();
    /*console.log('this.data from searchResultV3',this.data)*/
    this.generateForm(false);
    this.createHotelForm();
    this.getFavorites();
  }

  generateForm(redo?:boolean, data?:any){
    if(!redo){
      /*this.form = this.data.queryParams;*/
    } else {
      this.form = data.queryParams;
    }
    /*if(this.form.occupancy) {
      this.form.occupancy = JSON.parse(this.form.occupancy);
    }*/
    if(this.form.code.includes(',')){
      this.form.code = this.form.code.split(',');
    }
    /*if (this.data.rating !== undefined) this.rating = new Array(this.data.rating);*/
    console.log('this.ratings', this.rating)
  }

  starCounter(i : string){
    try{
      const s = parseFloat(i);
      return new Array(s);
    } catch (e) {
      return [];
    }
  }

  getFavorites(){
    this.loadedFav = false;
    // const query = '?skip=' + 0 + '&limit=' + 1000;
    this.hotelService.getFavorites_V3(1000, 0).subscribe((res:any) => {
      this.favorites = res.items;
      this.loadedFav = true;
      console.log('this.favorites => ', this.favorites);
    }, error => {
      this.loadedFav = true;
      console.error('Error => ', error);
    });
  }

  createHotelForm(){
    // this.formLoaded = false;

    this.hotelForm = new HotelForm();
    this.hotelForm.nameSearch = true;
    this.hotelForm.hotels = [];
    const array = Array.isArray(this.form.code);
    if(array){
      this.hotelForm.hotels = this.form.code;
    } else {
      this.hotelForm.hotels = [this.form.code];
    }
    this.hotelForm.chain = '';
    this.hotelForm.strict = false;
    this.hotelForm.sort = 'RA';
    this.hotelForm.cache = 'Live'; // Live / LessRecent / VeryRecent
    this.hotelForm.checkIn = new Date(new Date(this.form.checkIn).setHours(6));
    this.hotelForm.checkOut = new Date(new Date(this.form.checkOut).setHours(6));
    this.hotelForm.occupancy = this.form.occupancy;
    this.hotelForm.options = new HotelOptions();
    this.hotelForm.options.stars = [];
    this.hotelForm.destination = new Destination();
    this.hotelForm.ratePlans = [];
    this.createGuests(false);
  }

  createGuests(redo:boolean): void {
    this.guests = [];
    const traveler = new NewTraveller();
    let adtCount = 0;
    let chdCount = 0;
    let overCount = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.hotelForm.occupancy.length; i ++){
      // tslint:disable-next-line:prefer-for-of
      for (let g = 0; g < this.hotelForm.occupancy[i].guests.length; g ++){
        if (this.hotelForm.occupancy[i].guests[g].code === 10){
          adtCount += this.hotelForm.occupancy[i].guests[g].quantity;
        } else if (this.hotelForm.occupancy[i].guests[g].code === 2){
          overCount += this.hotelForm.occupancy[i].guests[g].quantity;
        } else{
          chdCount += this.hotelForm.occupancy[i].guests[g].quantity;
        }
      }
    }
    console.log('adtCount => ', adtCount);
    console.log('overCount => ', overCount);
    console.log('chdCount => ', chdCount);

    for (let i = 0; i < adtCount; i ++){
      const tr = JSON.parse(JSON.stringify(traveler));
      tr.type = 'ADT';
      this.guests.push(tr);
      console.log('guests => ', this.guests);
    }
    for (let i = 0; i < overCount; i ++){
      const tr = JSON.parse(JSON.stringify(traveler));
      tr.type = 'ADT';
      this.guests.push(tr);
    }
    for (let i = 0; i < chdCount; i ++){
      const tr = JSON.parse(JSON.stringify(traveler));
      tr.type = 'CHD';
      this.guests.push(tr);
      console.log('guests => ', this.guests);
    }
    this.StarthotelSearch(redo);
  }

  StarthotelSearch(redo:boolean){
    if(redo) {
      // tslint:disable-next-line:max-line-length
      const data = {
        queryParams: {
          code: this.form.code,
          checkIn: this.hotelForm.checkIn,
          checkOut: this.hotelForm.checkOut,
          occupancy: JSON.stringify(this.hotelForm.occupancy)
        }
      }
      this.generateForm(true, data);
    }
    this.removeRoomSelection();
    this.loading = true;
    this.allHotels = [];
    this.allImages = [];
    this.allRooms = [];
    this.providerToFilter = [];
    this.refoundToFilter = [];
    this.mealToFilter = [];
    this.mealsTypes = [];
    this.hotelProviders = [];
    this.filteredRoomList = [];

    delete this.hotelForm.destination;
    this.loading = true;
    // this.startCounter();
    if (this.hotelRes){
      delete this.hotelRes;
    }
    this.hotelService.sendHotelSearch(this.hotelForm).subscribe(resp => {
      this.loading = false;
      // console.log('resp =>', resp);
      this.hotelRes = resp;
      this.hotelRes = this.pushRoom(resp);
      this.allRooms.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
      this.filteredRoomList.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
      console.log('this.hotelRes ======>', this.hotelRes);
      this.hotelRes.hotels.forEach((h:any) => {
        this.getHotelNewInfos(h.hotelCode)
      });
      this.loading = false;
      this.setTab(0);

      for (const h of this.hotelRes.hotels){
        for (const r of h.roomsV2){
          r.chain = h.chain;
          r.hotel = JSON.parse(JSON.stringify(h));
          delete r.hotel.roomsV2;
          this.createFilters(r);
        }
      }
      this.selectedTabIndex = 1;
    }, error => {
      console.error(error);
      this.loading = false;
      /*this.dialogRef.close()*/
    });
  }


  getCorporates(){
    this.api.getCorporateCode('hotel', this.mainComponent.loggedUser.company._id).subscribe(data => {
      this.listCorporate = data.list
    }, error => {
      this.listCorporate = [];
    });
  }

  isCorporate(rate){
    let corporate = false;
    const ind = this.listCorporate.findIndex(c => c.code == rate.code);
    if(ind > -1){
      corporate = true;
      this.corporateToFilter = true;
    }
    return corporate;
  }

  pushRoom(resp){
    resp.hotels.forEach(hotel => {
      hotel.roomsV2 = [];
      hotel.rooms.forEach(room => {

        if (hotel.roomsV2.length <= hotel.rooms.length){
          resp.rooms[room].index = room;
          hotel.roomsV2.push(resp.rooms[room]);
          const camera = resp.rooms[room];

          camera.chain = hotel.chain;
          camera.originalAmount = {
            currency: camera.currency,
            amount: camera.amount
          };
          if(camera.currency !== 'EUR') {
            camera.amount = this.convertPrice(camera.amount, camera.currency);
            console.log('camera.roomRates[0].amount => ', camera.roomRates[0].amount);
            camera.roomRates[0].amount = this.convertPrice(camera.roomRates[0].amount, camera.currency);
            camera.currency = 'EUR';
          }
          this.allRooms.push(camera);
          this.filteredRoomList.push(camera);
          // console.log('camera=> ', camera);
          if (!hotel.hotelReference && resp.rooms[room].referenceID){
            hotel.hotelReferenceFull = resp.rooms[room].referenceID;
            hotel.hotelReference = resp.rooms[room].referenceID.split('.')[0];
          }
        }
        hotel.roomsV2.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
      });
    });
    this.allRooms.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
    this.filteredRoomList.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
    return resp;
  }

  getHotelNewInfos(code){
    const body = {
      hotels: [code],
    };
    this.hotelService.askHotelInfos(body).subscribe(res => {
      this.allHotels.push(res[0]);
      if (this.hotelRes.hotels.length > 0){
        this.allImages = this.getAllImages();
        // console.log('allHotels =>', this.allHotels);
        // console.log('allImages before =>', this.allImages);

        this.allImages.forEach((i, index) => {
          this.images.push(new Image(index, {img:i}),)
        });
      }
      // console.log('all image => ', this.allImages);
      setTimeout(() => {
        this.loadedImages = true;
      }, 5000);
    }, error => {
      console.error('error hotelsInfos =>', error);
    });
  }

  getAllImages(){
    const imgs = [];
    // tslint:disable-next-line:prefer-for-of
    for (let h = 0; h < this.allHotels.length; h ++){
      const current = this.allHotels[h];
      if (current.info && current.info.images){
        current.info.images.forEach(i => imgs.push(i.list[0].url));
      }
      if(current.images){
        current.images.forEach(i => {
          if(i && i.list && i.list.length > 0){
            imgs.push(i.list[0].url);
          }
        });
      }
      this.allHotels[h].address = this.hotelRes.hotels[0].address;
    }
    this.hotelInfos = true;
    return imgs.filter((value, index) => imgs.indexOf(value) === index);

  }

  setTab(index){
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.tabs.length; i ++){
      this.tabs[i].active = false;
    }
    this.tabs[index].active = true;
    this.currentTab = this.tabs[index].name;
    if (index === 0){
      setTimeout(() => {
        // this.initMap();
      }, 200);
    }
    $('.mat-sidenav-content').animate({ scrollTop: 300 }, 300);
    /*this.selectedTabIndex = index;*/
  }

  toggleDialog(currentTraveler){
    this.currentTraveler = currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  toggleEditDialog(currentTraveler){
    this.editingTraveler = true;
    this.currentTraveler = currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  removeTraveler(i){
    Object.keys(this.guests[i]).forEach(k => delete this.guests[i][k]);
  }

  loadTravelersList(currentTraveler){
    this.currentTraveler = currentTraveler;
    $('#listTravelerModal').modal('toggle');
    this.loadTravelers = true;
  }

  removeRoomSelection(){
    console.log('this.roomselected prima', this.roomSelected)
    delete this.selecetedHotel;
    delete this.roomSelected;
    delete this.fareSelected;
    this.roomSelected = undefined;
    console.log('this.roomselected dopo', this.roomSelected)
    // this.setTab(1);
  }

  roomSelect(event:any){
    this.selecetedHotel = event.hotel;
    this.roomSelected = event.room;
    console.log('this.roomselected', this.roomSelected)
    this.fareSelected = event.fare;
    if (localStorage.getItem('sarceDossier')){
      this.getTravelerCid();
    }
    if (localStorage.getItem('dossierPassengers')){
      this.passengers = JSON.parse(localStorage.getItem('dossierPassengers'));
      this.getTravelerDossier();
    }
    console.log('fareSelected', this.fareSelected);
    this.setActualTab('GUESTS');
    // this.setTab(2);
  }

  getTravelerCid(){
    this.travelersService.getTravelerCid(this.sarce.cid).subscribe((res : any) => {
      res.type = 'ADT';
      this.travelerSet(res, 0);
    }, error => {
      console.error('res => ', error);
    });
  }

  travelerSet(event, i){
    this.guests[i] = event;
    const z = 1;
    this.guests[i].ref = i + z + 1;
    // console.log('this.data.passengers => ', this.currentHotel.guests);
    delete this.currentTraveler;
  }

  getTravelerDossier(){
    this.travelersService.getTraveler(this.passengers[0]._id).subscribe((res : any) => {
      if (!res.type){
        res.type = 'ADT';
      }
      this.travelerSet(res, 0);
    }, error => {
      console.error('res => ', error);
    });
  }

  passFilters(room) : boolean{
    return this.checkMeal(room) && this.checkCProvider(room) && this.checkRefound(room) && this.checkCorporate(room);
  }

  checkCorporate(room){
    if(this.corporateToFilter && this.corporateFilter){
      return room.corporateFare
    } else {
      return true;
    }

  }

  checkMeal(room) : boolean{
    return this.mealToFilter.length === 0 || this.mealToFilter.includes(room.ratePlans[0].mealPlan);
  }

  checkCProvider(room) : boolean{
    return this.providerToFilter.length === 0 || this.providerToFilter.includes(room.chain);
  }

  checkRefound(room) : boolean{
    return this.refoundToFilter.length === 0 || this.refoundToFilter.includes(room.refoundable);
  }

  setRefound(value){
    const index = this.refoundToFilter.indexOf(value);
    if (index > - 1){
      this.refoundToFilter.splice(index, 1);
    } else{
      this.refoundToFilter.push(value);
    }
  }

  setCorporate(){
    this.corporateFilter =! this.corporateFilter
  }

  setProvider(provider){
    const index = this.providerToFilter.indexOf(provider);
    if (index > - 1){
      this.providerToFilter.splice(index, 1);
    } else{
      this.providerToFilter.push(provider);
    }
  }

  setMeal(meal){
    const index = this.mealToFilter.indexOf(meal);
    if (index > - 1){
      this.mealToFilter.splice(index, 1);
    } else{
      this.mealToFilter.push(meal);
    }
  }

  isFreeCancellation(room){
    return room.ratePlans[0].penalty[0] && room.ratePlans[0].penalty[0].absoluteDeadLine;
  }

  createFilters(room){
    if (!this.mealsTypes.includes(room.ratePlans[0].mealPlan)){
      this.mealsTypes.push(room.ratePlans[0].mealPlan);
    }
    if (!this.hotelProviders.includes(room.chain)){
      this.hotelProviders.push(room.chain);
    }
    if (this.isFreeCancellation(room)){
      room.refoundable = true;
    } else{
      room.refoundable = false;
    }
    room.corporateFare = this.isCorporate(room.ratePlans[0]);
    this.reloadFilteredRooms();
  }

  reloadFilteredRooms(){
    this.filteredRoomList = this.allRooms.filter(room => this.passFilters(room));
    this.filteredRoomList = this.filteredRoomList.sort((a, b) => a.roomRates[0].amount - b.roomRates[0].amount);
  }

  isMealSelected(value){
    return this.mealToFilter.includes(value);
  }

  isCProviderSelected(value){
    return this.providerToFilter.includes(value);
  }

  isRefoundSelected(value){
    return this.refoundToFilter.includes(value);
  }

  closeEvent(event:any){
    delete this.currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  closeEditEvent(event:any){
    this.editingTraveler = false;
    delete this.currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  closeListEvent(event:any){
    delete this.currentTraveler;
    $('#listTravelerModal').modal('toggle');
  }

  travelerController(){
    const isValidTraveler = (value) => !(!value.name || !value.surname || !value.phoneNumber);
    return this.guests.every(isValidTraveler);
  }

  bookHotel(){
    const reservation = {
      hotel: {
        code: this.selecetedHotel.hotelCode,
        name: this.selecetedHotel.name,
        provider: this.selecetedHotel.hotelCode.slice(0, 2),
        checkIn: this.roomSelected.startDate,
        checkOut: this.roomSelected.endDate,
        city: this.selecetedHotel.cityCode,
        address: this.hotelRes.hotels[0].address,
      },
      guests: this.guests,
      room: this.roomSelected,
      session: this.hotelRes.session,
      b2b: false,
      // room: this.currentHotel.roomSelected.room,
    };
    if (this.appComponent.isB2B){
      reservation.b2b = true;
    }

    // CONVERTING ALL AMOUNT IN EUR FROM LOCAL CURRENCY
    if (reservation.room.currency !== 'EUR'){
      reservation.room.amount = this.convertPrice(reservation.room.amount, reservation.room.currency);
      reservation.room.roomRates[0].amount = this.convertPrice(reservation.room.roomRates[0].amount, reservation.room.currency);
      if (reservation.room.ratePlans[0]){
        if (reservation.room.ratePlans[0].penalty){
          // tslint:disable-next-line:prefer-for-of
          for (let p = 0; p < reservation.room.ratePlans[0].penalty.length; p ++){
            // tslint:disable-next-line:max-line-length
            reservation.room.ratePlans[0].penalty[p].amount = this.convertPrice(reservation.room.ratePlans[0].penalty[p].amount, reservation.room.currency);
          }
        }
      }
    }

    // console.log('reservation => ', reservation);

    this.hotelService.postReservation(reservation).subscribe(resp => {
      this.loading = false;
      // console.log(resp);

      if (localStorage.getItem('fl_dossierId')){
        const dossierId = JSON.parse(localStorage.getItem('fl_dossierId'));
        const body = {
          hotels: [resp._id],
        };
        this.dossierService.addHotelsToDossier(dossierId, body).subscribe((res) => {
          // console.log('res => ', res);
        }, error => {
          // console.error('res => ', error);
        });
      }
      /*this.dialogRef.close({respId : resp._id})*/
      /*this.router.navigate(['/hotel/reservations/' + resp._id]);*/
    }, error => {
      alert(error);
      this.loading = false;
    });

  }

  convertPrice(value, currency) : number{
    if (this.hotelRes.currencyConversions){
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.hotelRes.currencyConversions.length; i ++){
        if (this.hotelRes.currencyConversions[i].local === currency){
          return this.priceservice.returnPriceHotel(value * this.hotelRes.currencyConversions[i].rate);
        }
      }
    } else{
      return this.priceservice.returnPriceHotel(value);
    }

  }

  returnTotalGuests() : number{
    let quantity = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.hotelForm.occupancy.length; i ++){
      // tslint:disable-next-line:prefer-for-of
      for (let c = 0; c < this.hotelForm.occupancy[i].guests.length; c ++){
        quantity = quantity + this.hotelForm.occupancy[i].guests[c].quantity;
      }
    }
    return quantity;
  }

  returnChd(i:any){
    let quantity = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let c = 0; c < this.hotelForm.occupancy[i].guests.length; c ++){
      if (this.hotelForm.occupancy[i].guests[c].code === 5){
        quantity = quantity + 1;
      }
    }
    return quantity;
  }

  addGuestType(i, type){
    const newGuest = {
      code: type,
      quantity: 1,
      age: 12,
    };
    if (type !== 5){
      delete newGuest.age;
    }
    const index = this.hotelForm.occupancy[i].guests.findIndex(r => r.code === newGuest.code);
    if (index === - 1 || type === 5){
      this.hotelForm.occupancy[i].guests.push(newGuest);
    } else{
      this.hotelForm.occupancy[i].guests[index].quantity = this.hotelForm.occupancy[i].guests[index].quantity + newGuest.quantity;
    }
    console.log(this.hotelForm.occupancy[i].guests);
  }

  toggleGuestsModal(){
    $('#newGuestModal').modal('toggle');
  }

  removeRoom(value){
    this.hotelForm.occupancy.splice(value, 1);
  }

  removeGuestType(i, type){
    const newGuest = {
      code: type,
      quantity: 1,
    };
    const index = this.hotelForm.occupancy[i].guests.findIndex(r => r.code === newGuest.code);
    this.hotelForm.occupancy[i].guests[index].quantity = this.hotelForm.occupancy[i].guests[index].quantity - newGuest.quantity;
    if (this.hotelForm.occupancy[i].guests[index].quantity === 0){
      this.hotelForm.occupancy[i].guests.splice(index, 1);
    }
    console.log(this.hotelForm.occupancy);
  }

  /**
   *  Return adults count
   */
  returnAdt(i){
    if (this.hotelForm.occupancy[i].guests.find(r => r.code === 10)){
      return this.hotelForm.occupancy[i].guests.find(r => r.code === 10).quantity;
    } else{
      return 0;
    }
  }

  /**
   *  Return over 65 count
   */
  returnOver(i){
    if (this.hotelForm.occupancy[i].guests.find(r => r.code === 2)){
      return this.hotelForm.occupancy[i].guests.find(r => r.code === 2).quantity;
    } else{
      return 0;
    }
  }

  returnProvider(value:string): string{
    if (!this.appComponent.isGat && value.includes('AMADEUS')){
      return this.translate.instant('TARIFFES') + ' LEONARDO TRAVEL';
    }
    return value;
  }

  setActualTab(value:string, changeRoom?:boolean){
    if (changeRoom && value === 'ROOMS' ){
      console.log('ciao sono changeroom')
      this.removeRoomSelection()
    } else if (value === 'CONFIRM') {
      this.requoting = true;
      this.requoteHotel();
    }/* else if (value) {
      this.selectIndex(this.tabs.findIndex(t => t.name === value));
    }*/
    this.selectIndex(this.tabs.findIndex(t => t.name === value));
  }

  requoteHotel(){
    const reservation = {
      hotel: {
        code: this.selecetedHotel.hotelCode,
        name: this.selecetedHotel.name,
        provider: this.selecetedHotel.hotelCode.slice(0, 2),
        checkIn: this.roomSelected.startDate,
        checkOut: this.roomSelected.endDate,
        city: this.selecetedHotel.cityCode,
        address: this.hotelRes.hotels[0].address,
      },
      guests: this.guests,
      room: this.roomSelected,
      session: this.hotelRes.session,
      b2b: false,
      // room: this.currentHotel.roomSelected.room,
    };
    if (this.appComponent.isB2B){
      reservation.b2b = true;
    }

    // CONVERTING ALL AMOUNT IN EUR FROM LOCAL CURRENCY
    if (reservation.room.currency !== 'EUR'){
      reservation.room.amount = this.convertPrice(reservation.room.amount, reservation.room.currency);
      reservation.room.roomRates[0].amount = this.convertPrice(reservation.room.roomRates[0].amount, reservation.room.currency);
      if (reservation.room.ratePlans[0]){
        if (reservation.room.ratePlans[0].penalty){
          // tslint:disable-next-line:prefer-for-of
          for (let p = 0; p < reservation.room.ratePlans[0].penalty.length; p ++){
            // tslint:disable-next-line:max-line-length
            reservation.room.ratePlans[0].penalty[p].amount = this.convertPrice(reservation.room.ratePlans[0].penalty[p].amount, reservation.room.currency);
          }
        }
      }
    }

    this.hotelService.quoteHotelRoom(reservation).subscribe(resp => {
      this.requoting = false;
      // console.log(resp);
      let text = '';
      if(this.roomSelected.originalAmount.amount){
        if(resp.rooms[0].amount !== this.roomSelected.originalAmount.amount){
          text = 'Il prezzo ha subito una variazione, perfavore seleziona un altra tariffa';
          this.removeRoomSelection();
          this.setActualTab('ROOMS');
        } else {
          text = 'Il prezzo è stato confermato';
        }
      } else {
        if(resp.rooms[0].amount !== this.roomSelected.amount){
          text = 'Il prezzo ha subito una variazione, perfavore seleziona un altra tariffa';
          this.removeRoomSelection();
          this.setActualTab('ROOMS');
        } else {
          text = 'Il prezzo è stato confermato';
        }
      }

      this.snackBar.open(text, 'ok', {
        duration: 6000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      const newData = {
        hotel: resp.hotels[0],
        room: resp.rooms[0],
        fare: resp.rooms[0].roomRates[0],
      }
      newData.room.chain = newData.hotel.chain;
      // this.roomSelect(newData);
    }, error => {
      this.snackBar.open('La camera che hai selezionato non è piu disponibile', 'ok', {
        duration: 6000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.requoting = false;
      this.removeRoomSelection();
      this.setActualTab('ROOMS', true);
    });
  }

  openDialogMap(hotel:any){
    /*hotel.images = this.allImages;*/
    hotel.coordinates = this.allHotels[0].info.position;
    const dialogRef = this.dialog.open(MapInfoDialogComponent, {
      width: '800px',
      maxWidth: '100%',
      minHeight:'400px',
      maxHeight: '100%',
      data: {
        hotel,
        form: this.hotelForm,
        available: true,
      },
    });
  }

  addToFavorites(hotel:any){
    this.hotelService.addFavorites_V3(this.form.code).subscribe((res:any) => {
      console.log('this.favorites => ');
      hotel.code = this.form.code;
      this.favorites.push(hotel);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  isFavorite(value:string){
    return this.hotelService.isFavorite(this.favorites, value);
  }

  removeToFavorites(hotel?:any){
    this.hotelService.removeFavorites_V3(this.form.code).subscribe((res:any) => {
      console.log('this.favorites => ');
      const index = this.favorites.findIndex((f:any) => f.code === this.form.code);
      if(index !== -1){
        this.favorites.splice(index,1);
      }
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  setMinCheckOut(e?: Event | any){
    /*console.log('This the event on the change date', e)
    console.log('this is the form date ', this.hotelForm.checkIn, new Date(this.hotelForm.checkIn).toISOString());*/
    this.minCheckOutDate$.next(new Date(this.hotelForm.checkIn).toISOString())
    this.hotelForm.checkOut = new Date(this.hotelForm.checkIn);
  }
  selectIndex(e: number){
    console.log('this is the index ', e)
    this.selectedTabIndex = e;
  }

  openModal(imagesArrayToUse: Image[]): void {
    if(imagesArrayToUse?.length === 0) {
      console.error('Cannot open modal-gallery because images array cannot be empty');
      return;
    }
    /*console.log(this.images)*/
    const libConfig={
      enableCloseOutside: false,
      currentImageConfig: {
        loadingConfig: {enable: true, type: LoadingType.CIRCULAR} as LoadingConfig,
        description: {imageText: '',numberSeparator: ' / '} as Description,
        navigateOnClick: false
      },
      buttonsConfig: {
        visible: true,
        strategy: ButtonsStrategy.CUSTOM,
        buttons:[{
          className: 'close-image',
          type: ButtonType.CLOSE,
          ariaLabel: 'custom close aria label',
          fontSize: '20px'
        }]
      },
      slideConfig: {
        infinite: false,
        sidePreviews: {
          show: false,
          size: {
            width: '100px',
            height: 'auto'
          }
        }
      },
      previewConfig: {
        visible: true,
        size: {
          width: '150px',
          height: 'auto'
        },
        mobileVisible: true
      },
      dotsConfig: {
        visible: false
      }
    }

    const dialogRef: ModalGalleryRef = this.modalGalleryService.open({
      id:1,
      images: imagesArrayToUse,
      currentImage: imagesArrayToUse[0]!,
      libConfig
    } as ModalGalleryConfig) as ModalGalleryRef;
  }

}
