import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CarRequest } from '../../../classes/car-request';
import * as $ from 'jquery';
import { CarService } from '../../../../../services/car/car.service';
import { SearchRes } from '../../../../../classes/flights/search-res';
import * as moment from 'moment';
import { CorporateCode } from '../../../../companies/components/companiescompo/company/corporate-codes/corporate-codes.component';
import { CompaniesService } from '../../../../../services/profile/companies.service';
import { MainComponent } from '../../../../mainmodule/main/main.component';

declare var google : any;

@Component({
  selector: 'app-carsearchform',
  templateUrl: './carsearchform.component.html',
  styleUrls: ['./carsearchform.component.scss'],
})
export class CarsearchformComponent implements OnInit{
  @Input() request : CarRequest;
  // tslint:disable-next-line:no-output-rename
  @Output('update') change : EventEmitter<SearchRes> = new EventEmitter<SearchRes>();
  // tslint:disable-next-line:no-output-native no-output-rename
  @Output('reset') resetForm : EventEmitter<SearchRes> = new EventEmitter<SearchRes>();
  searchResults : any;
  today = new Date();
  loading : boolean;
  sameLocation : boolean;
  formOptions : boolean = false;
  /**
   *  Local variable to set search form visible or not
   */
  formvisible : boolean;
  loyaltyCodes : CorporateCode[];
  selectedLoyaltyCodes : CorporateCode[];

  constructor(
    private carService : CarService,
    private cmpSrv : CompaniesService,
    public mainComponent : MainComponent,
  ){
    this.sameLocation = true;
  }

  ngOnInit() : void{
    this.cmpSrv.getCorporateCode(
      'car',
      this.mainComponent.loggedUser.company._id,
    ).subscribe(data => this.loyaltyCodes = data.list);
  }

  /*toggleOptions(){
    // localStorage.removeItem('searchCarRes');
    // localStorage.removeItem('searchCarForm');
    this.formOptions = !this.formOptions;
    console.log(this.formOptions);
  }*/

  toggleOptions(justOpen = false){
    if ((justOpen && !this.formOptions) || !justOpen){
      $('#collapseOptions').collapse('toggle');
      this.formOptions = !this.formOptions;
    }
    //$('#formOptions').collapse('toggle');
  }

  resetSearchForm(){
    // localStorage.removeItem('searchCarRes');
    // localStorage.removeItem('searchCarForm');
    this.resetForm.emit();
  }

  addList(value): any {
    const input = document.getElementById(value) as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();
      if (places.length === 0) {
        return;
      } else {
        console.log('place => ', places);
        console.log('-> lat => ', places[0].geometry.location.lat());
        console.log('-> lng => ', places[0].geometry.location.lng());

        this.request[value].display = places[0].formatted_address;
        this.request[value].coordinates = {
          latitude: places[0].geometry.location.lat(),
          longitude: places[0].geometry.location.lng(),
        };
      }
      if (this.sameLocation){
        this.request.end.display = this.request[value].display;
        this.request.end.coordinates = this.request[value].coordinates;
      }
    });
    console.log(this.request);
  }

  checkChange(event){
    if (event === true){
      this.request.end.display = this.request.begin.display;
      this.request.end.coordinates = this.request.begin.coordinates;
    }
  }

  clearmodel(value){
    this.request[value].display = '';
    this.request[value].coordinates = {
      latitude: undefined,
      longitude: undefined,
    };
  }

  startSearch(): void{
    if (this.searchResults){
      delete this.searchResults;
      this.change.emit(this.searchResults);
    }
    const body = {
      loyaltyCodes: (this.selectedLoyaltyCodes || []).map(r => new Object({ iata: r.name, number: r.code })),
      begin: {
        date: moment(this.request.begin.date).utcOffset(120).format(),
        coordinates: [this.request.begin.coordinates.longitude, this.request.begin.coordinates.latitude],
      },
      end: {
        date: moment(this.request.end.date).utcOffset(120).format(),
        coordinates: [this.request.end.coordinates.longitude, this.request.end.coordinates.latitude],
      },
    };
    // localStorage.setItem('searchCarForm', JSON.stringify(this.request));
    // localStorage.removeItem('searchCarRes');
    console.log('this.request => ', this.request);
    this.loading = true;
    this.carService.sendCarSearch(body).subscribe((res) => {
      console.log('res => ', res);
      this.searchResults = res;
      this.loading = false;
      // localStorage.setItem('searchCarRes', JSON.stringify(res));
      this.change.emit(this.searchResults);
    }, error => {
      this.loading = false;
      console.error('err => ', error);
    });
  }

  getMinDate(): Date {
    return new Date(this.request.begin.date);
  }

  setEndDate(){
    const beginDate = new Date(this.request.begin.date).getTime();
    const endDate = new Date(this.request.end.date).getTime();
    if (beginDate > endDate){
      this.request.end.date = new Date(this.request.begin.date);
    }
    const test = moment(beginDate).utcOffset(120).format('YYYY-MM-DD HH:mm');
    console.log(this.request.begin.date);
    console.log(test);
  }

  toggleForm(){
    this.formvisible = !this.formvisible;
    $('#searchCard').collapse('toggle');
  }

  invalid(): boolean {
    // tslint:disable-next-line:max-line-length
    return (!this.request.begin.iata && !(this.request.begin.coordinates.longitude && this.request.begin.coordinates.latitude)) || (!this.request.end.iata && !(this.request.end.coordinates.longitude && this.request.end.coordinates.latitude));
  }
}
