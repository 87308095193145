<div *ngIf="loading" class="h-50 d-flex align-items-center justify-content-center"><!--padding20-->
  <div class="loader">
    <div class="text-center"><!--padding20-->
      <div class="d-flex align-self-center justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>
</div>
<div *ngIf="!loading && allHotels[0]" class="container">
  <div class="d-lg-none closeBtnOver" *ngIf="allImages.length > 0">
    <button matTooltip="{{'CLOSE'|translate}}" mat-icon-button class="iconButtons" (click)="dialogRef.close()">
      <mat-icon> close </mat-icon>
    </button>
  </div>
  <div class="d-inline-block d-lg-none mb-3" *ngIf="allImages.length <= 0"><!--closeBtnOver-->
    <button matTooltip="{{'CLOSE'|translate}}" mat-icon-button class="iconButtons closeBtnBefore" (click)="dialogRef.close()" style="color: black">
      <mat-icon> close </mat-icon>
    </button>
  </div>
  <div class="imageContainer" [ngClass]="{'d-none': allImages.length<=0}">
    <app-new-gallery *ngIf="allImages.length > 0" [allImages]="allImages" ></app-new-gallery>
  </div>
  <div class="row d-flex justify-content-center my-4">
    <div class="col-8 col-lg-9">
      <div class="" *ngIf="allHotels[0].address">
        <span class="material-icons">location_on</span>
        {{allHotels[0].address.line}}, {{allHotels[0].address.zip}}, {{allHotels[0].address.city}}
      </div>
      <div class="hotelStars">
        <p class="hotelName d-inline-block">
          {{allHotels[0].name}}
          <span *ngIf="rating">
            <sup class="stars" *ngFor="let star of starCounter(rating)"> <i class="material-icons-outlined"> star </i> </sup>
          </span>
        </p>
      </div>
      <div class="row my-2 my-md-0">
        <div class="col-5">
          <div class="d-flex align-self-center">
            <span class="material-icons clockIcons"> schedule </span>
            <span class="d-block" style="max-height: 40px">
              <p class="my-0">CheckIn</p>
              <p class="my-0">{{form.checkIn | date:'dd/MM/yyyy'}}</p>
            </span>
          </div>
        </div>
        <div class="col-5">
          <div class="d-flex align-self-center">
            <span class="material-icons clockIcons"> schedule </span>
            <span class="d-block" style="max-height: 40px">
                <p class="my-0">CheckOut</p>
                <p class="my-0">{{form.checkOut | date:'dd/MM/yyyy'}}</p>
              </span>
          </div>
        </div>
        <div class="col-2">
          <div class="d-inline-block d-md-flex justify-content-end">
            <button mat-icon-button class="iconButtons" color="warn" (click)="addToFavorites(allHotels[0])"
                    *ngIf="!isFavorite(form.code) && (this.mainComponent.loggedUser.role.company || this.mainComponent.loggedUser.role.superAdmin)" >
              <mat-icon>favorite_border</mat-icon>
            </button>
            <button mat-icon-button class="iconButtons" color="warn" (click)="removeToFavorites(allHotels[0])"
                    *ngIf="isFavorite(form.code) && (this.mainComponent.loggedUser.role.company || this.mainComponent.loggedUser.role.superAdmin)" >
              <mat-icon>favorite</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 col-lg-3">
      <div class="mapBackground text-center">
        <span class="d-block mb-2 material-icons-outlined hotelLocationIcon"> location_on </span>
        <div class="d-flex justify-content-center">
          <button mat-raised-button class="mapButton" color="primary" (click)="openDialogMap(allHotels[0])" >Vedi su mappa </button>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <!--Start Tabs-->
  <div class="row">
    <div class="col">
      <mat-tab-group (selectedIndexChange)="selectIndex($event)" [selectedIndex]="selectedTabIndex" mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="{{'HOTEL.INFO'|translate}}">
          <div *ngIf="allHotels.length > 0">
            <div *ngFor="let hotel of allHotels">
              <div class="descriptionSingle py-3 px-2" *ngFor="let info of hotel.info.descriptions">
                <div class="descriptionInfoTitle mb-1">
                  <span *ngIf="!info.additionalDetail">{{info.infoType}}</span>
                  <span *ngIf="info.additionalDetail">{{info.additionalDetail}}</span>
                </div>
                <div class="descriptionText" [innerHTML]="info.text"></div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{'HOTEL.ROOMS'|translate}}">
          <div *ngIf="!roomSelected">
            <div>
              <div (click)="reloadFilteredRooms()" class="row" style="margin:0;">
                <!-- Fornitore -->
                <div *ngIf="!appComponent.isSbt && !appComponent.isGat" class="col-md-4">
                  <div class="providerFilter">
                    <div class="ml-1 mb-2">{{'PROVIDER'|translate}}</div>
                    <div (click)="setProvider(provider)" *ngFor="let provider of hotelProviders" class="singleProvider">
                      <span *ngIf="!isCProviderSelected(provider)" class="material-icons"> check_box_outline_blank </span>
                      <span *ngIf="isCProviderSelected(provider)" class="material-icons" style="color:cornflowerblue;"> check_box </span>
                      {{returnProvider(provider)}}
                    </div>
                  </div>
                </div>
                <!-- Fornitore -->
                <div *ngIf="appComponent.isSbt && mainComponent.loggedUser.role.superAdmin" class="col-md-4">
                  <div class="providerFilter">
                    <div class="ml-1 mb-2">{{'PROVIDER'|translate}}</div>
                    <div (click)="setProvider(provider)" *ngFor="let provider of hotelProviders" class="singleProvider">
                      <span *ngIf="!isCProviderSelected(provider)" class="material-icons"> check_box_outline_blank </span>
                      <span *ngIf="isCProviderSelected(provider)" class="material-icons" style="color:cornflowerblue;"> check_box </span>
                      {{returnProvider(provider)}}
                    </div>
                  </div>
                </div>
                <!-- Rimborso -->
                <div class="col-md-4">
                  <div class="providerFilter">
                    <div class="ml-1 mb-2">{{'REFOUND'|translate}}</div>
                    <div (click)="setRefound(true)" class="singleProvider">
                      <span *ngIf="!isRefoundSelected(true)" class="material-icons"> check_box_outline_blank </span>
                      <span *ngIf="isRefoundSelected(true)" class="material-icons" style="color:cornflowerblue;"> check_box </span>
                      {{'YES_REFOUND'|translate}}
                    </div>
                    <div *ngIf="corporateToFilter" (click)="setRefound(false)" class="singleProvider">
                      <span *ngIf="!isRefoundSelected(false)" class="material-icons"> check_box_outline_blank </span>
                      <span *ngIf="isRefoundSelected(false)" class="material-icons" style="color:cornflowerblue;"> check_box </span>
                      {{'NO_REFOUND'|translate}}
                    </div>

                    <div (click)="setCorporate()" class="singleProvider greentxt">
                      <span *ngIf="!corporateFilter" class="material-icons"> check_box_outline_blank </span>
                      <span *ngIf="corporateFilter" class="material-icons" style="color:cornflowerblue;"> check_box </span>
                      {{'CORPORATE'|translate}}
                    </div>
                  </div>
                </div>
                <!-- Trattamento -->
                <div class="col-md-4">
                  <div class="providerFilter">
                    <div class="ml-1 mb-2">{{'BOARD'|translate}}</div>
                    <div (click)="setMeal(meal)" *ngFor="let meal of mealsTypes" class="singleProvider">
                      <span *ngIf="!isMealSelected(meal)" class="material-icons"> check_box_outline_blank </span>
                      <span *ngIf="isMealSelected(meal)" class="material-icons" style="color:cornflowerblue;"> check_box </span>
                      {{meal}}
                    </div>
                  </div>
                </div>
              </div>
              <hr/>
            </div>
            <!-- trovate x tariffe, x soddisfano i filtri  -->
            <div class="text-center selectRoomBanner">
              <div class="ml-1 mb-2">
                <div class="d-flex align-self-center justify-content-center hotelListText">
                  <span class="material-icons"> done </span>
                  <span class="text">{{'FOUNDED'|translate}}</span> <b>{{allRooms.length}} {{'TARIFFES'|translate}}</b>
                </div>
              </div>
              <div class="hotelListText" *ngIf="filteredRoomList.length < allRooms.length">
                <span class="material-icons"> center_focus_weak </span>
                <span class="text">{{'SATISFY_FILTER'|translate}}</span> <b>{{filteredRoomList.length}} {{'TARIFFES'|translate}}</b>
              </div>
            </div>
          </div>
          <!-- tariffe camere -->
          <div class="hotelCard mb-2" *ngIf="allHotels.length > 0 && !roomSelected">
            <div class="roomsContainer">
              <div *ngFor="let room of filteredRoomList; let i = index; let l = last">
                <app-single-room-v4 [corporates]="listCorporate" [guests]="guests" [isGat]="appComponent.isGat" [isSbt]="appComponent.isSbt"
                [isB2B]="appComponent.isB2B" (update)="roomSelect($event)" [hotel]="room.hotel" [i]="i" [room]="room"
                [searchRes]="hotelRes" [search]="hotelForm"></app-single-room-v4>
                <hr *ngIf="!l">
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{'HOTEL.GUESTS'|translate}}" [disabled]="!roomSelected">
          <div class="py-3 px-2 overflow-x-hidden">
            <div class="row">
              <div *ngFor="let tra of guests;let i = index" class="col-lg-6 px-2 py-1 mb-3">
                <div class="fakefield mb-2">
                  {{'TYPE'|translate}}:
                  <span> {{tra.type|translate}}</span>
                </div>
                <div class="fakefield mb-2">
                  {{'NAME'|translate}}:
                  <span *ngIf="tra.name"> {{tra.name}}</span>
                  <span *ngIf="!tra.name"> _ _ _ _</span>
                </div>
                <div class="fakefield mb-2">
                  {{'SURNAME'|translate}}:
                  <span *ngIf="tra.surname"> {{tra.surname}}</span>
                  <span *ngIf="!tra.surname"> _ _ _ _</span>
                </div>
                <div class="fakefield mb-2">
                  {{'PHONE'|translate}}:
                  <span *ngIf="tra.phoneNumber"> {{tra.phoneNumber}}</span>
                  <span *ngIf="!tra.phoneNumber"> _ _ _ _</span>
                </div>
                <div class="fakefield mb-2">
                  {{'EMAIL'|translate}}:
                  <span *ngIf="tra.email"> {{tra.email}}</span>
                  <span *ngIf="!tra.email"> _ _ _ _</span>
                </div>

                <div class="buttonrow">
                  <button (click)="toggleDialog(i)" *ngIf="!tra.name || !tra.surname" mat-stroked-button>{{'ADD_GUEST'|translate}}</button>
                  <button (click)="removeTraveler(i)" *ngIf="tra.name || tra.surname" mat-stroked-button>{{'REMOVE_GUEST'|translate}}</button>
                  <button (click)="toggleEditDialog(i)" *ngIf="tra.name || tra.surname" mat-stroked-button>{{'EDIT_GUEST'|translate}}</button>
                  <button (click)="loadTravelersList(i)" *ngIf="!tra.name && !tra.surname" mat-stroked-button>{{'LOAD_TRAVELER'|translate}}</button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="text-center col-12 mt-3">
                <button mat-raised-button class="backBtn mr-3" color="primary" (click)="setActualTab('ROOMS',true)">
                  <mat-icon class="material-icons pr-1"> keyboard_arrow_left </mat-icon><span class="pl-1">{{'CHANGE_ROOM'|translate}}</span>
                </button>
                <button mat-raised-button *ngIf="travelerController()" color="primary" class="showRoomsBtn mr-3" (click)="setActualTab('CONFIRM')">
                  <span class="pr-1">{{'RIEPILOGO'|translate}}</span> <mat-icon iconPositionEnd class="material-icons pl-1"> keyboard_arrow_right </mat-icon>
                </button>
                <div class="pt-2 text-danger" *ngIf="!travelerController()">
                  {{'INSERT_GUESTS_DETAILS'|translate}}
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{'HOTEL.CONFIRM'|translate}}" [disabled]="!((hotelInfos && loadedFav) && (fareSelected && travelerController()))">
          <div *ngIf="hotelInfos && loadedFav">
            <div *ngIf="fareSelected && travelerController()" class="container">
              <div class="row py-3" *ngIf="requoting">
                <div class="col">
                  <div class="text-center">
                    <p>{{'HOTEL.WAIT'|translate}}</p>
                    <p>{{'HOTEL.REQUOTE' | translate}}</p>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="!requoting">
                <div class="col-12">
                  <app-room-selected-v2 [guests]="guests" [isGat]="appComponent.isGat" [isSbt]="appComponent.isSbt" [isB2B]="appComponent.isB2B"
                                     [hotel]="roomSelected.hotel" [room]="roomSelected" [searchRes]="hotelRes" [search]="hotelForm"></app-room-selected-v2>
                  <hr>
                </div>
                <div class="col-12">
                  <div class="selectedGuests">
                    <div *ngFor="let tra of guests;let i = index" class="mb-2 py-3">
                      <div class="row">
                        <div class="col-3 d-flex align-items-center justify-content-center">
                          <div class="guestsIcon text-center">
                            <span class="material-icons"> perm_identity </span>
                            <div class="guestType">
                              {{tra.type|translate}}
                            </div>
                          </div>
                        </div>
                        <div class="col-9 guestsInfo">
                          <div class="guestName mb-2">{{tra.name}} {{tra.surname}}</div>
                          <div class="smalltext mb-1">
                            <span class="material-icons mr-2">phone</span> {{tra.phoneNumber}}
                          </div>
                          <div class="smalltext">
                            <span class="material-icons mr-2">email</span> {{tra.email}}
                          </div>
                        </div>
                      </div>
                      <hr>
                    </div>
                  </div>
                </div>

                <div class="col-12 text-center py-2 my-3">
                  <button mat-raised-button color="primary" class="backBtn mr-3" (click)="setActualTab('GUESTS')">
                    <mat-icon class="material-icons pr-1"> keyboard_arrow_left </mat-icon> <span class="pl-1">{{'MANAGE_GUESTS'|translate}}</span>
                  </button>
                  <button mat-raised-button color="primary" class="showRoomsBtn mr-3" (click)="bookHotel()">
                    <span class="pr-1">{{'CONFIRM_PROCEED'|translate}}</span> <mat-icon iconPositionEnd class="material-icons pl-1" style="width: max-content"> arrow_right_alt </mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{'HOTEL.CHANGE_DATE'|translate}}">
          <div class="container pt-4">
            <div class="row">
              <div class="col-lg-4">
                <mat-form-field class="full-width" appearance="outline">
                  <input matInput readonly [matDatepicker]="checkIn" placeholder="{{'CHECK_IN'|translate}}" (click)="checkIn.open()" [min]="today"
                         [(ngModel)]="hotelForm.checkIn" (dateChange)="setMinCheckOut()">
                  <mat-datepicker-toggle matSuffix [for]="checkIn"></mat-datepicker-toggle>
                  <mat-datepicker #checkIn></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-lg-4">
                <mat-form-field class="full-width" appearance="outline">
                  <input matInput readonly [matDatepicker]="checkOut" placeholder="{{'CHECK_OUT'|translate}}" (click)="checkOut.open()"
                         [min]="minCheckOutDate$.value" [(ngModel)]="hotelForm.checkOut">
                  <mat-datepicker-toggle matSuffix [for]="checkOut"></mat-datepicker-toggle>
                  <mat-datepicker #checkOut></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-lg-4">
                <button (click)="toggleGuestsModal()" class="smallmenu watchFare" mat-button style="height: 56px!important;">
                  {{'ROOMS'|translate}}: {{hotelForm.occupancy.length}} | {{'GUESTS'|translate}}: {{returnTotalGuests()}}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex justify-content-center">
                <button (click)="createGuests(true)" color="primary" class="bookingButton" mat-raised-button>
                  <span class="mr-1"> {{ 'SEARCH'|translate }} </span> <mat-icon class="ml-1 material-icons"> send </mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

</div>

<!-- ADD DIALOG-->
<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="addTravelerModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-add [hotel]="true" (close)="closeEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [newTraveler]="guests[currentTraveler]" [parent]="'searchResults'"></app-add>
      </div>

    </div>
  </div>
</div>
<!-- TRAVELLERS LIST DIALOG-->
<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="listTravelerModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div *ngIf="loadTravelers" class="modal-content">

      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-travelerslist (close)="closeListEvent($event)" [hotel]="true" (setTraveler)="travelerSet($event, currentTraveler)" [newTraveler]="guests[currentTraveler]" [page]="'searchResults'"
                           [passengers]="guests"></app-travelerslist>
        <hr/>
        <div class="buttonrow text-right">
          <button (click)="closeListEvent($event)" mat-stroked-button>{{'CLOSE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- EDIT TRAVELLER DIALOG-->
<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="EditTravelerModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div *ngIf="currentTraveler!=undefined && editingTraveler" class="modal-body">
        <app-edittraveler [hotel]="true" (close)="closeEditEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [currentTraveler]="guests[currentTraveler]"
                          [parent]="'searchResults'"></app-edittraveler>
      </div>
    </div>
  </div>
</div>
<!-- GUESTS DIALOG-->
<div aria-hidden="true" aria-labelledby="addGuestModal" class="modal fade" data-backdrop="static" id="newGuestModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addGuestModalTitle">
          {{'TARIFFES'|translate}}: {{hotelForm.occupancy.length}} | {{'GUESTS'|translate}}: {{returnTotalGuests()}}
        </h5>
        <button (click)="toggleGuestsModal()" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div *ngFor="let room of hotelForm.occupancy; let i = index;" class="mb-3 col-lg-6">
            <div class="singleRoom mb-2">
              <div class="roomTitle">
                <span class="material-icons">hotel</span> {{'ROOM'|translate}} {{i + 1}}
              </div>
              <hr/>
              <div class="guestsActions">
                <div class="row">
                  <div class="col-5 align-self-center">
                    {{'ADULTS'|translate}}
                  </div>
                  <div class="col-7 text-right">
                    <button (click)="removeGuestType(i, 10)" [disabled]="returnAdt(i) === 0 || (returnOver(i) === 0 && returnAdt(i) === 1)" aria-label="Minus" class="text-center" mat-icon-button>
                      <mat-icon style="margin:0;">remove</mat-icon>
                    </button>
                    {{returnAdt(i)}}
                    <button (click)="addGuestType(i, 10)" aria-label="Plus" class="text-center" mat-icon-button>
                      <mat-icon style="margin:0;">add</mat-icon>
                    </button>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-5 align-self-center">
                    {{'CHILDREN'|translate}}
                  </div>
                  <div class="col-7 text-right">
                    <button [disabled]="returnChd(i) === 0" class="text-center" mat-icon-button aria-label="Minus" (click)="removeGuestType(i, 5)">
                      <mat-icon style="margin:0;">remove</mat-icon>
                    </button>
                    {{returnChd(i)}}
                    <button class="text-center" mat-icon-button aria-label="Plus" (click)="addGuestType(i, 5)">
                      <mat-icon style="margin:0;">add</mat-icon>
                    </button>
                  </div>
                </div>
                <div *ngIf="returnChd(i) > 0">
                  <hr style="margin:10px 0"/>
                  <p>{{'HOW_OLD_ARE_CHILDREN'|translate}}</p>
                  <div *ngFor="let guest of room.guests; let g = index">
                    <div *ngIf="guest.code === 5">
                      <mat-form-field>
                        <mat-label>{{'CHILD_AGE'|translate}}</mat-label>
                        <mat-select [(ngModel)]="guest.age">
                          <mat-option [value]="0">0 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="1">1 {{'YEAR_OLD'|translate}}</mat-option>
                          <mat-option [value]="2">2 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="3">3 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="4">4 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="5">5 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="6">6 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="7">7 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="8">8 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="9">9 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="10">10 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="11">11 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="12">12 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="13">13 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="14">14 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="15">15 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="16">16 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="17">17 {{'YEARS_OLD'|translate}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <hr *ngIf="hotelForm.occupancy.length>1"/>
              <div class="buttonrow text-right smallActionButtons">
                <button (click)="removeRoom(i)" *ngIf="hotelForm.occupancy.length>1" mat-stroked-button>
                  {{'REMOVE_ROOM'|translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="text-right">
        <button (click)="toggleGuestsModal()" mat-button type="button">{{'CLOSE'|translate}}</button>
      </div>
    </div>
  </div>
</div>
