<div id="searchCard" class="searchForm mb-5 collapse show">
  <mat-card appearance="outlined" class="search-card searchFlight">
    <mat-card-header>
      <div mat-card-avatar class="search-header-image"></div>
      <mat-card-title>{{'FLIGHTS_SEARCH'|translate}}</mat-card-title>
      <mat-card-subtitle>{{'FILL_FORM'|translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

      <div class="row mb-2 typeRow">
        <div class="col-6 col-md-6 align-self-center">
          <span class="pointer d-block d-sm-inline mb-2">
            {{'PASSENGERS'|translate}}<span *ngIf="paxCounter()>0">: {{paxCounter()}}</span>
          </span>
          <span class="ml-2 mr-2 d-none d-sm-inline">|</span>
          <span *ngIf="mainComponent.loggedUser._id!=='604f240ec5ec740012a28f46'" class="pointer d-block d-sm-inline" (click)="toggleOptions()">
            {{'OTHER'|translate}}
            <span class="material-icons">
              keyboard_arrow_down
            </span>
          </span>
        </div>
        <div class="col-2 align-self-center"></div>

      </div>

      <div id="collapseOptions" class="row standardFields mb-2 collapse">

        <div class="col-md-3 col-sm-6 col-6">
          <div class="animationContent">
            <div *ngIf="!request.options.corporate && !request.options.family && !request.options.alliance && !request.options.etnic && !request.options.tourOperator">

              <mat-form-field class="full-width">
                                <span class="isInfo" matPrefix matTooltip="{{'CARRIER'|translate}}">
                                  <mat-icon>help_outline</mat-icon>
                                </span>
                <input matInput placeholder="{{'CARRIER'|translate}}" aria-label="Carrier" disabled>
              </mat-form-field>

              <div *ngIf="request.options.carrierIdentification.length>0" class="mb-3">
                                <span *ngFor="let car of request.options.carrierIdentification;let c = index;" class="singleiata">
                                  {{car}}
                                </span>
              </div>
            </div>

            <div class="fakefield" *ngIf="request.options.family">
              {{request.options.carrierIdentification[0]}}
            </div>

            <div class="fakefield" *ngIf="request.options.etnic">{{'ONLY_ETNIC'}}</div>
            <div class="fakefield" *ngIf="request.options.tourOperator">{{'TOUR_OPERATOR'}}</div>
            <div class="fakefield" *ngIf="request.options.corporate">
              {{request.options.carrierIdentification[0]}}
            </div>

            <mat-form-field class="full-width" *ngIf="request.options.alliance" required>
              <mat-label>{{'ALLIANCE'}}</mat-label>
              <mat-select [(ngModel)]="request.options.carrierIdentification[0]">
                <mat-option value="*O">ONEWORLD</mat-option>
                <mat-option value="*S">SKYTEAM</mat-option>
                <mat-option value="*A">STAR ALLIANCE</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

        <div class="col-md-2 col-sm-6 col-6">
          <mat-form-field class="full-width">
            <mat-label>{{'CABIN'|translate}}</mat-label>
            <mat-select [(ngModel)]="request.options.cabin" name="cabin" (ngModelChange)="setClassValues($event)">
              <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
              <mat-option value="Y">{{'ECONOMY'|translate}}</mat-option>
              <mat-option value="W">{{'ECONOMY_PLUS'|translate}}</mat-option>
              <mat-option value="C">{{'BUSINESS_CLASS'|translate}}</mat-option>
              <mat-option value="F">{{'FIRST_CLASS'|translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2 col-sm-6 col-6">
          <mat-form-field class="full-width">
            <mat-label>{{'STOPS'|translate}}</mat-label>
            <mat-select [(ngModel)]="request.options.flightType" name="flightType">
              <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
              <mat-option value="N">{{'ONLY_NON_STOP'|translate}}</mat-option>

            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-2 col-sm-6 col-6">
          <mat-form-field class="full-width">
            <mat-label>{{'PRICE_TO_BEAT'|translate}}</mat-label>
            <input matInput type="number" [(ngModel)]="request.options.priceToBeat" name="priceToBeat">

          </mat-form-field>
        </div>

        <div class="col-md-2 col-sm-6 col-6">
          <mat-form-field class="full-width">
            <mat-label>{{'MAX_CONN_TIME'|translate}}</mat-label>
            <mat-select [(ngModel)]="request.options.maxTimeConnections" name="maxTimeConnections">
              <mat-option [value]="null">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
              <mat-option [value]="2">2</mat-option>
              <mat-option [value]="4">4</mat-option>
              <mat-option [value]="6">6</mat-option>
              <mat-option [value]="8">8</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

      <div *ngFor="let section of request.sections;let i = index;let z = index;let j = index;">

        <div class="searchContainer row">
          <div class="col-12 d-flex justify-content-end formTopButtons">
            <div class="formTopAction" [ngClass]="{'ActiveOption': othersOptionsOpen, 'disabled' : !request.edit[z]}" (click)="toggleCollapse(z)">
              <span  class="material-icons">
                tune
              </span>
            </div>
            <!--<div class="formTopAction" (click)="clearSearch()" matTooltip="{{'CLEAR_FORM'|translate}}">
              <span class="material-icons">
                refresh
              </span>
            </div>-->
          </div>
          <hr>
          <div class="col-12 collapse" id="collapseF{{z}}">
            <div class="row inwhiterow">
              <div class="col-md-3 col-sm-6 col-6">
                <mat-form-field class="full-width">
                  <mat-label>{{'TIME'|translate}}</mat-label>
                  <mat-select [(ngModel)]="section.options.timeSlot" name="time">
                    <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                    <mat-option value="2">{{'MORNING'|translate}} (06-12)</mat-option>
                    <mat-option value="3">{{'AFTERNOON'|translate}} (12-18)</mat-option>
                    <mat-option value="4">{{'EVENING'|translate}} (18-24)</mat-option>
                    <mat-option value="1">{{'NIGHT'|translate}} (00-06)</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-6 col-6">
                <mat-form-field class="full-width">
                  <mat-label>{{'CABIN'|translate}}</mat-label>
                  <mat-select [(ngModel)]="section.options.cabin" name="cabin" id="firstClassSelect" (ngModelChange)="singleCabinChange()">
                    <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                    <mat-option value="Y">{{'ECONOMY'|translate}}</mat-option>
                    <mat-option value="W">{{'ECONOMY_PLUS'|translate}}</mat-option>
                    <mat-option value="C">{{'BUSINESS_CLASS'|translate}}</mat-option>
                    <mat-option value="F">{{'FIRST_CLASS'|translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-6 col-6">
                <!--
                <mat-form-field class="full-width">
                  <mat-label>{{'VIA_AIRPORT'|translate}}</mat-label>
                  <input matInput placeholder="Ex. JFK" [(ngModel)]="via[z]">
                  <button mat-button *ngIf="via[z]" matSuffix mat-icon-button aria-label="Clear" (click)="pushBy(z,via[z])">
                    <mat-icon>add</mat-icon>
                  </button>
                </mat-form-field> -->
                <app-airports [parentForm]="request" [index]="z" [isTransit]="true"></app-airports>
                <div *ngIf="request.sections[z].options.viaAirport.length>0" class="mb-3">
                    <span *ngFor="let iata of request.sections[z].options.viaAirport;let ia = index;" class="singleiata">
                      {{iataComplete.returnAirport(iata)}}
                      <span (click)="removeIataVia(z,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                    </span>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-6">
                <mat-form-field class="full-width">
                  <mat-label>{{'STOPS'|translate}}</mat-label>
                  <mat-select [(ngModel)]="section.options.flightType" name="cabin">
                    <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                    <mat-option value="0">{{'NON_STOPS'|translate}}</mat-option>
                    <mat-option value="1">1 {{'STOP'|translate}}</mat-option>
                    <mat-option value="2">2 {{'STOPS'|translate}}</mat-option>
                    <mat-option value="3">3 {{'STOPS'|translate}}</mat-option>
                    <mat-option value="4">4 {{'STOPS'|translate}}</mat-option>
                    <mat-option value="5">5 {{'STOPS'|translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="row lightbg">

          <div class="flightnumeration">
            <span *ngIf="request.searchType=='multy'">{{z + 1}}</span>
            <span *ngIf="request.searchType=='oneway'">
                  <mat-icon class="toright">flight</mat-icon>
                </span>

            <span *ngIf="request.searchType=='roundtrip' && z==0">
                  <mat-icon class="toright">flight</mat-icon>
                </span>
            <span *ngIf="request.searchType=='roundtrip' && z==1">
                  <mat-icon class="toleft">flight</mat-icon>
              </span>
          </div>

          <div class="col-md-6 col-sm-12 col-12">

            <app-airports *ngIf="request.edit[z]" [parentForm]="request" [index]="z" [isTransit]="false"></app-airports>

            <div class="row">
              <div class="col-md-5">
                <div *ngIf="request.sections[z].from.length>0" class="">
                  <span *ngFor="let iata of request.sections[z].from;let ia = index;" class="singleiata">
                    {{iataComplete.returnAirport(iata)}}
                    <span *ngIf="request.edit[z]" (click)="removeIataFrom(z,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                  </span>
                </div>
              </div>
              <div class="col-md-2 col-12 text-center align-self-center">
                <span (click)="invertIata(z)" [ngClass]="request.edit[z] ? 'pointer' : ''" class="switchIcon material-icons" [hidden]="request.sections[z].to.length == 0 && request.sections[z].from.length == 0">
                  {{request.edit[z] ? 'swap_horiz' : 'arrow_forward'}}
                </span>
              </div>
              <div class="col-md-5">
                <div *ngIf="request.sections[z].to.length>0" class="">
                <span *ngFor="let iata of request.sections[z].to;let ia = index;" class="singleiata">
                  {{iataComplete.returnAirport(iata)}}
                  <span *ngIf="request.edit[z]" (click)="removeIataTo(z,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                </span>
                </div>
              </div>
            </div>

          </div>


          <div class="col-md-3 col-sm-6 col-6">

            <mat-form-field class="full-width">
              <input [disabled]="!request.edit[z]" matInput readonly [matDatepicker]="jj" placeholder="{{'DEPARTURE_DATE'|translate}}" (click)="jj.open()" [min]="getMinDate(z)" [(ngModel)]="section.options.date" (dateChange)="setNextDate(z)" name="date{{z}}">
              <mat-datepicker-toggle matSuffix [for]="jj"></mat-datepicker-toggle>
              <mat-datepicker #jj></mat-datepicker>
            </mat-form-field>

          </div>

          <div class="col-md-3 col-sm-6 col-6 d-flex justify-content-end buttonrow">
            <!--<button [disabled]="!request.edit[z]" *ngIf="mainComponent.loggedUser._id!=='604f240ec5ec740012a28f46'" mat-button matTooltip="{{'TUNE_SECTION'|translate}}" aria-label="tune" (click)="toggleCollapse(z)">
              <mat-icon>tune</mat-icon>
              <span style="font-weight: normal">{{'TUNE_SECTION'|translate}}</span>
            </button>-->
            <button mat-raised-button matTooltip="{{'ENABLE_EDITING'|translate}}" (click)="editSegment(z)" *ngIf="!request.edit[z]">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-stroked-button matTooltip="{{'DISABLE_EDITING'|translate}}" (click)="editSegment(z)" *ngIf="request.edit[z]">
              <mat-icon>edit</mat-icon>
            </button>
          </div>

          <!--<div class="col-12 collapse" id="collapseF{{z}}">
            <div class="row inwhiterow">
              <div class="col-md-3 col-sm-6 col-6">
                <mat-form-field class="full-width">
                  <mat-label>{{'TIME'|translate}}</mat-label>
                  <mat-select [(ngModel)]="section.options.timeSlot" name="time">
                    <mat-option value="">&#45;&#45; {{'NO_PREFERENCE'|translate}} &#45;&#45;</mat-option>
                    <mat-option value="2">{{'MORNING'|translate}} (06-12)</mat-option>
                    <mat-option value="3">{{'AFTERNOON'|translate}} (12-18)</mat-option>
                    <mat-option value="4">{{'EVENING'|translate}} (18-24)</mat-option>
                    <mat-option value="1">{{'NIGHT'|translate}} (00-06)</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-6 col-6">
                <mat-form-field class="full-width">
                  <mat-label>{{'CABIN'|translate}}</mat-label>
                  <mat-select [(ngModel)]="section.options.cabin" name="cabin" id="firstClassSelect" (ngModelChange)="singleCabinChange()">
                    <mat-option value="">&#45;&#45; {{'NO_PREFERENCE'|translate}} &#45;&#45;</mat-option>
                    <mat-option value="Y">{{'ECONOMY'|translate}}</mat-option>
                    <mat-option value="W">{{'ECONOMY_PLUS'|translate}}</mat-option>
                    <mat-option value="C">{{'BUSINESS_CLASS'|translate}}</mat-option>
                    <mat-option value="F">{{'FIRST_CLASS'|translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-6 col-6">
                &lt;!&ndash;
                <mat-form-field class="full-width">
                  <mat-label>{{'VIA_AIRPORT'|translate}}</mat-label>
                  <input matInput placeholder="Ex. JFK" [(ngModel)]="via[z]">
                  <button mat-button *ngIf="via[z]" matSuffix mat-icon-button aria-label="Clear" (click)="pushBy(z,via[z])">
                    <mat-icon>add</mat-icon>
                  </button>
                </mat-form-field> &ndash;&gt;
                <app-airports [parentForm]="request" [index]="z" [isTransit]="true"></app-airports>
                <div *ngIf="request.sections[z].options.viaAirport.length>0" class="mb-3">
                    <span *ngFor="let iata of request.sections[z].options.viaAirport;let ia = index;" class="singleiata">
                      {{iataComplete.returnAirport(iata)}}
                      <span (click)="removeIataVia(z,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                    </span>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-6">
                <mat-form-field class="full-width">
                  <mat-label>{{'STOPS'|translate}}</mat-label>
                  <mat-select [(ngModel)]="section.options.flightType" name="cabin">
                    <mat-option value="">&#45;&#45; {{'NO_PREFERENCE'|translate}} &#45;&#45;</mat-option>
                    <mat-option value="0">{{'NON_STOPS'|translate}}</mat-option>
                    <mat-option value="1">1 {{'STOP'|translate}}</mat-option>
                    <mat-option value="2">2 {{'STOPS'|translate}}</mat-option>
                    <mat-option value="3">3 {{'STOPS'|translate}}</mat-option>
                    <mat-option value="4">4 {{'STOPS'|translate}}</mat-option>
                    <mat-option value="5">5 {{'STOPS'|translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>-->
        </div>
      </div>

      <div class="formActionsButtons text-right">
        <div *ngIf="!loading">
          <button mat-raised-button color="primary" type="button" [disabled]="isValid()" (click)="startSearch(false, undefined)">
            {{'SEARCH'|translate}}
          </button>
        </div>
        <div *ngIf="loading" class="text-right">

          <div class="mb-2">{{'LOADING'|translate}}</div>
          <mat-progress-bar style="max-width: 200px;display:inline-block;" mode="indeterminate"></mat-progress-bar>
        </div>

      </div>
    </mat-card-content>
  </mat-card>


</div>

<div *ngIf="loading" class="nowloading padding20 text-center">
  {{'LOADING'|translate}}
  <div class="currentForm">
    <p *ngFor="let sec of request.sections;">
      {{sec.options.date|date:'EEE dd MMM yyyy'}} {{iataComplete.returnAirport(sec.from)}}<sup>{{sec.from}}</sup>
      <mat-icon>arrow_right_alt</mat-icon>
      {{iataComplete.returnAirport(sec.to)}}<sup>{{sec.to}}</sup>
    </p>
  </div>
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>

<div class="modal fade" id="infoResidentsModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="infoResidentsModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="infoResidentsModalLabel">Tariffa di continuità territoriale</h4>
      </div>

      <div class="modal-body">
        <div class="sardidescription">
          <h5 style="margin-bottom:20px;font-weight:normal;">
            Tariffa valida e disponibile per le seguenti categorie di passeggeri:
          </h5>
          <ul class="elencopuntato">
            <li>Residenti in Sardegna*</li>
            <li>Disabili**</li>
            <li>Giovani dai 2 ai 21 anni*</li>
            <li>Anziani al di sopra dei 70 anni*</li>
            <li>Studenti universitari fino al compimento di 27° anno di età ***</li>
          </ul>
          <h5 style="margin-bottom:10px;font-weight:bold;">
            Documentazione da presentare al check-in:
          </h5>
          <ul class="elencopuntato">
            <li>* un documento di identità; valido e che attesti l'effettiva residenza in Sardegna o i requisiti di età previsti per beneficiare della tariffa</li>
            <li>** la certificazione medica comprovante la disabilità;</li>
            <li>*** il libretto/badge universitario in corso di validità e un certificato di iscrizione relativo all'anno accademico in corso.</li>
          </ul>
          <hr/>
          <p>Non è possibile acquistare in un’unica transazione online biglietti aerei per passeggeri che possiedono i requisiti per tariffe agevolate di Continuità Territoriale congiuntamente a passeggeri che non possiedono tali requisiti.</p>
          <p>La compagnia aerea si riserva la possibilità di perseguire presso le sedi competenti i non aventi diritto.</p>
          <hr/>
          <h5 style="margin-bottom:20px;font-weight:normal;">Le tariffe di continuità territoriale sono disponibili per le seguenti tratte:</h5>
          <ul class="elencopuntato">
            <li>OLB - FCO / FCO - OLB</li>
            <li>FCO - CAG / CAG - FCO</li>
            <li>LIN - CAG / CAG - LIN</li>
            <li>LIN - AHO / AHO - LIN</li>
            <li>FCO - AHO / AHO - FCO</li>
            <li>MXP - CAG / CAG - MXP</li>
            <li>MXP - AHO / AHO - MXP</li>
            <li>FCO - AHO / AHO - FCO</li>
          </ul>

        </div>
      </div>

      <div class="modal-footer">
        <button type="button" mat-button (click)="toggleInfoResidents()">{{'CLOSE'|translate}}</button>
      </div>
    </div> <!-- /.modal-content -->

  </div>
</div>
