<div class="hotelResults">

  <div class="row">
    <div [ngClass]="{'col-md-4':showMap,'col-md-10':!showMap}" class="animation">
      <mat-card appearance="outlined" class="mb-3">
        <div class=" row buttonrow">
          <div [ngClass]="{'col-md-12 mb-2':showMap,'col-md-8':!showMap}" class="align-self-center">
            <div class="mb-2 infoSearch">
              <span *ngIf="hotelSearch.destination">{{hotelSearch.destination.display}}</span>
              <span *ngIf="!hotelSearch.destination">Hotel</span><br/>
              CheckIn: {{hotelSearch.checkIn|date:'dd/MM/yyyy'}} - CheckOut: {{hotelSearch.checkOut|date:'dd/MM/yyyy'}}
            </div>
            <button (click)="toggleSearch()" mat-stroked-button>
              <span *ngIf="!formvisible">{{'EDIT_SEARCH'|translate}}</span>
              <span *ngIf="formvisible">{{'HIDE_FORM'|translate}}</span>
            </button>
            <button (click)="toggleMap()" mat-stroked-button>
              <span *ngIf="!showMap">{{'SHOW_MAP'|translate}}</span>
              <span *ngIf="showMap">{{'HIDE_MAP'|translate}}</span>
            </button>
            <button (click)="toggleFilters()" mat-stroked-button>
              {{'OPEN_FILTERS'|translate}}
            </button>
            <button [matMenuTriggerFor]="sortMenu" mat-stroked-button>{{'SORTING'|translate}}</button>
            <mat-menu #sortMenu="matMenu">
              <button (click)="setSort('ASCENDING')" [ngClass]="{'activeMenu':sorting==='ASCENDING'}" mat-menu-item>{{'ASCENDING'|translate}}</button>
              <button (click)="setSort('DESCENDING')" [ngClass]="{'activeMenu':sorting==='DESCENDING'}" mat-menu-item>{{'DESCENDING'|translate}}</button>
              <button (click)="setSort('ALPHABETIC_ASC')" [ngClass]="{'activeMenu':sorting==='ALPHABETIC_ASC'}" mat-menu-item>{{'ALPHABETIC_ASC'|translate}}</button>
              <button (click)="setSort('ALPHABETIC_DESC')" [ngClass]="{'activeMenu':sorting==='ALPHABETIC_DESC'}" mat-menu-item>{{'ALPHABETIC_DESC'|translate}}</button>
              <button (click)="setSort('DISTANCE_ASCENDING')" [ngClass]="{'activeMenu':sorting==='DISTANCE_ASCENDING'}" mat-menu-item>{{'DISTANCE_ASCENDING'|translate}}</button>
              <button (click)="setSort('DISTANCE_DESCENDING')" [ngClass]="{'activeMenu':sorting==='DISTANCE_DESCENDING'}" mat-menu-item>{{'DISTANCE_DESCENDING'|translate}}</button>
              <button (click)="setSort('STAR_ASCENDING')" [ngClass]="{'activeMenu':sorting==='STAR_ASCENDING'}" mat-menu-item>{{'STAR_ASCENDING'|translate}}</button>
              <button (click)="setSort('STAR_DESCENDING')" [ngClass]="{'activeMenu':sorting==='STAR_DESCENDING'}" mat-menu-item>{{'STAR_DESCENDING'|translate}}</button>
              <button (click)="abortSort()" [ngClass]="{'activeMenu':sorting==='RELEVANCE'}" mat-menu-item>{{'RELEVANCE'|translate}}</button>
            </mat-menu>

          </div>
          <div [ngClass]="{'col-md-12':showMap,'col-md-4 text-right':!showMap}" class="">
            <!-- {{filteredRes.length}} / {{hotelDb.length}} {{'RESULTS_FOUND'|translate}} -->

          </div>

        </div>
      </mat-card>

      <div class="filterCar text-right">
        <button (click)="toggleSearch()" aria-label="Search" color="primary" mat-mini-fab>
          <mat-icon>search</mat-icon>
        </button>
        <button (click)="toggleFilters()" [matBadgeHidden]="someFilterSelected() == 0" aria-label="Search" color="primary" mat-mini-fab matBadge="{{someFilterSelected()}}" matBadgeColor="accent">
          <mat-icon>tune</mat-icon>
        </button>
      </div>

      <div *ngIf="filteredRes">
        <div *ngFor="let hotel of obs | async;let i = index" class="card mb-4">

          <div class="row">
            <!--
            <div class="align-self-center hotelSlide" [ngClass]="{'col-12':showMap,'col-md-3':!showMap}">
              <div id="carousel{{i}}" class="carousel slide slideSearch" data-ride="carousel" data-interval="false" *ngIf="hotel.detailInfo">

                <div class="carousel-inner" *ngIf="hotel.detailInfo.info.images.length === 0">
                  <div class="carousel-item active">
                    <img class="d-block" src="/assets/img/placeholder-image.png" alt="First slide">
                    <div class="carousel-caption d-none d-md-block">
                      {{'NO_IMAGES_AVAILABLE'|translate}}
                    </div>
                  </div>
                </div>
                <div class="carousel-inner" *ngIf="hotel.detailInfo.info.images.length>0">
                  <div class="carousel-item" *ngFor="let image of hotel.detailInfo.info.images; let im = index" [ngClass]="{'active':im === 0}">
                    <img class="d-block" src="{{image.list[5].url}}" alt="{{im+1}} slide">
                    <div class="carousel-caption d-none d-md-block">
                      {{im+1}}/{{hotel.detailInfo.info.images.length}}
                    </div>
                  </div>
                </div>
                <a *ngIf="hotel.detailInfo.info.images.length>1" class="carousel-control-prev" href="#carousel{{i}}" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a *ngIf="hotel.detailInfo.info.images.length>1" class="carousel-control-next" href="#carousel{{i}}" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
            <div class="align-self-center" [ngClass]="{'col-12':showMap,'col-md-9':!showMap}">
              <div class="introHotel">
                <div class="row">
                  <div class="" [ngClass]="{'col-12':showMap,'col-md-8':!showMap}">
                    <div class="mb-2">
                      <div class="hotelName">{{hotel.name}}</div>
                      <div class="hotelCity">{{hotel.address.city}}</div>
                      <div class="hotelAddress">{{hotel.address.zip}}, {{hotel.address.line}}</div>
                      <div *ngIf="hotel.detailInfo">
                        <span *ngFor="let category of hotel.detailInfo.info.categories; let l = last">
                          {{category}} <span *ngIf="!l"> - </span>
                        </span>
                      </div>

                    </div>
                  </div>
                  <div class="" [ngClass]="{'col-12':showMap,'col-md-4':!showMap}">
                    <div class="mb-2">
                      <div class="hotelChain">{{hotel.chain}}</div>
                      <div class="availableRooms mb-2">{{'AVAILABLE_ROOMS'|translate}}: {{hotel.rooms.length}}</div>
                      <div class="buttonrow">
                        <button mat-stroked-button color="primary" (click)="toggleSingleHotel(hotel)">{{'SHOW_AVAILABLE_ROOMS'|translate}}</button>
                      </div>

                    </div>
                  </div>
                </div>
                <div>
                  <div *ngFor="let room of hotel.rooms; let i = index">
                    <div *ngIf="i<1">
                      {{'PRICE_FROM'|translate}}
                      {{hotelRes.rooms[room].amount|number:'.2-2'}}€

                    </div>
                  </div>
                </div>
              </div>

            </div>
            -->
            <div [ngClass]="{'col-12':showMap,'col-md-3':!showMap}" class="align-self-center hotelSlide">
              <!--
              <div *ngIf="!hotel.hotels[0].detailInfo">
                <div class="mb-3 text-center" style="font-size:10px">{{'LOADING'|translate}}</div>

                <mat-progress-bar mode="buffer"></mat-progress-bar>
              </div>
              -->
              <div *ngIf="hotel.hotels[0].detailInfo">
                <div [ngStyle]="{backgroundImage:'url('+returnImg(hotel)+')'}" class="introImg"></div>
              </div>
            </div>
            <div [ngClass]="{'col-12':showMap,'col-md-9':!showMap}" class="align-self-center hotelSlide">
              <div class="introHotel">
                <div class="row">
                  <div [ngClass]="{'col-12':showMap,'col-md-8':!showMap}" class="">
                    <div class="mb-2">
                      <div class="hotelName">{{hotel.name}}</div>
                      <div *ngIf="hotel.rating" class="hotelRating">
                      <span *ngFor="let star of starCounter(hotel.rating)" class="material-icons">
                        star_rate
                      </span>
                      </div>
                      <hr/>
                      <div class="hotelAddress mb-2">{{hotel.hotels[0].address.city}}, {{hotel.hotels[0].address.zip}}, {{hotel.hotels[0].address.line}}</div>
                      <div *ngIf="hotel.distance" class="distance">{{checkValue(hotel.distance)}} {{'FROM_SEARCH_POINT'|translate}}</div>

                    </div>
                  </div>
                  <div [ngClass]="{'col-12':showMap,'col-md-4 text-right':!showMap}" class="">
                    <hr *ngIf="showMap"/>
                    <div class="mb-2">
                      <!-- <div class="hotelChain">{{hotel.chain}}</div> -->
                      <!-- <div class="availableRooms mb-2">{{'AVAILABLE_ROOMS'|translate}}: {{hotel.rooms.length}}</div> -->
                      <div class="mb-2">
                        <p class="mb-1 smalltext">{{numberOfNights}} <span *ngIf="numberOfNights == 1">{{'NIGHT'|translate}}</span><span *ngIf="numberOfNights > 1">{{'NIGHTS'|translate}}</span></p>
                        {{'PRICE_FROM'|translate}}
                        <span *ngIf="hotel.minPrice">
                        {{hotel.minPrice|number:'.2-2'}} {{hotel.currency}}
                          <span *ngIf="hotel.currency != 'EUR'"><br/>({{convertPrice(hotel.minPrice, hotel.currency)|number:'.2-2'}}€)</span>
                      </span>
                      </div>
                      <div class="buttonrow">
                        <button (click)="toggleNewSingleHotel(hotel)" color="primary" mat-stroked-button>{{'SHOW_AVAILABLE_ROOMS'|translate}}</button>
                      </div>

                    </div>
                  </div>
                </div>
                <div>
                  <!-- {{'PRICE_FROM'|translate}} <span *ngIf="hotel.minPrice">(min: {{hotel.minPrice|number:'.2-2'}}€)</span>
                  {{hotelRes.rooms[hotel.hotels[0].rooms[0]].amount|number:'.2-2'}}€ <br/> -->
                  <span *ngFor="let hotel of hotel.hotels" class="tagspan" matTooltip="{{'ROOMS'|translate}}: {{hotel.rooms.length}}">
                    <span *ngIf="hotel.chain.toLowerCase() !== 'expedia'"> {{hotel.chain}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div *ngIf="!loading && filteredRes.length==0" class="padding20 text-center noresult">
        <h3>{{'NO_RESULTS'|translate}}.</h3>
        <p>{{'NO_RESULTS_TRY_AGAIN'|translate}}.</p>
      </div>

      <mat-paginator (page)="pageChanged($event)" *ngIf="hotelDb"
                     [length]="hotelDb.length"
                     [pageSizeOptions]="pageSizeOptions"
                     [pageSize]="pageSize">
      </mat-paginator>
    </div>
    <div [ngClass]="{'col-md-8':showMap,'col-md-2':!showMap}" class="animation">
      <div class="hotelMapContainer">
        <div (click)="toggleMap()" *ngIf="!showMap" class="overmap"></div>
        <div class="mb-4" id="map">

        </div>
      </div>
    </div>
  </div>

  <div class="filterSearch">

    <div class="nameFilter">
      <mat-form-field>
        <mat-label>{{'HOTEL_NAME'|translate}} </mat-label>
        <input (change)="doSort('name')" [(ngModel)]="searchName" matInput>
      </mat-form-field>
    </div>

    <hr/>
    <div class="providerFilter">
      <div class="mb-2">{{'HOTEL'|translate}} {{availableHotels.length}}</div>
      <div class="scrolling">
        <div *ngFor="let hotel of availableHotels; let i = index;" class="singleProvider">
          <div (click)="manageHotels(hotel)" class="row">
            <div class="col-2">
              <span *ngIf="!isHotelselected(hotel)" class="material-icons">
                check_box_outline_blank
              </span>
              <span *ngIf="isHotelselected(hotel)" class="material-icons" style="color:cornflowerblue;">
                check_box
              </span>
            </div>
            <div class="col-10">
              {{hotel}}
            </div>
          </div>


        </div>
      </div>

    </div>
    <hr/>
    <div class="providerFilter">
      {{'CHAIN'|translate}}
      <div (click)="manageProviders(provider)" *ngFor="let provider of hotelsProviders" class="singleProvider">
        <span *ngIf="!isProviderSelected(provider)" class="material-icons">
          check_box_outline_blank
        </span>
        <span *ngIf="isProviderSelected(provider)" class="material-icons" style="color:cornflowerblue;">
          check_box
        </span>
        {{provider}}
      </div>
    </div>

    <hr/>
    <div class="providerFilter">
      {{'RATING'|translate}}
      <div (click)="manageStars(star)" *ngFor="let star of allStars" class="singleProvider">
        <span *ngIf="!isStarSelected(star)" class="material-icons">
          check_box_outline_blank
        </span>
        <span *ngIf="isStarSelected(star)" class="material-icons" style="color:cornflowerblue;">
          check_box
        </span>
        <span class="hotelRating"><span *ngFor="let star of starCounter(star)" class="material-icons">star_rate</span></span>
      </div>
    </div>

  </div>
  <div (click)="toggleFilters()" class="filtersearchBackdrop"></div>

  <div aria-hidden="true" aria-labelledby="singleHotelModal" class="modal fade" data-backdrop="static" id="singleHotelModal" role="dialog" tabindex="-1">
    <div class="modal-dialog-scrollable modal-dialog modal-xl" role="document">

      <div *ngIf="currentHotel" class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="addGuestModalTitle">
            {{currentHotel.name}} <span *ngIf="currentHotel.rating" class="hotelRating"><span *ngFor="let star of starCounter(currentHotel.rating)" class="material-icons">star_rate</span></span>
            <span *ngIf="currentHotel.fareSelected"> |
              {{currentHotel.fareSelected.amount|number:'.2-2'}} {{currentHotel.currency}}
              <span *ngIf="currentHotel.currency != 'EUR'">({{convertPrice(currentHotel.fareSelected.amount, currentHotel.currency)|number:'.2-2'}}€)</span>
            </span>
            <div>
              CheckIn: {{hotelSearch.checkIn|date:'dd/MM/yyyy'}} - CheckOut: {{hotelSearch.checkOut|date:'dd/MM/yyyy'}}
            </div>
            <div *ngIf="currentHotel.fareSelected">{{currentHotel.fareSelected.text}}</div>
          </div>
          <button (click)="toggleNewSingleHotel(null)" class="close" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="loadingInfos">
            {{'LOADING'|translate}}<br/>
            <mat-progress-bar mode="buffer"></mat-progress-bar>
          </div>
          <div>
            <mat-tab-group (selectedIndexChange)="changeIndex($event)" [selectedIndex]="tabIndex" mat-align-tabs="start">
              <mat-tab active label="{{'HOTEL_INFOS'|translate}}">
                <br/>
                <app-singlehotelinfo *ngIf="currentHotel.hotels[0].detailInfo" [currentGeo]="currentHotel" [distance]="currentHotel.distance" [hotel]="currentHotel.hotels[0]"
                                     [search]="hotelSearch"></app-singlehotelinfo>
              </mat-tab>
              <mat-tab label="{{'PRICES_AND_ROOMS'|translate}}">
                <br/>
                <!--
                <mat-tab-group mat-align-tabs="start" selectedIndex="0" *ngIf="!appComponent.isSbt">
                  <mat-tab *ngFor="let hotel of currentHotel.hotels" [label]="hotel.chain +' ('+ hotel.roomsV2.length + ')'">
                    <br/>
                    <div *ngIf="hotel.detailInfo">
                      <div *ngFor="let room of hotel.roomsV2 | hotelrooms:'asc':'amount'; let i = index">
                        <app-singlehotelrooms (update)="roomSelect($event)" [search]="hotelSearch" [searchRes]="hotelRes" [hotel]="hotel" [i]="i" [room]="room"></app-singlehotelrooms>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
                -->

                <div *ngIf="currentHotel.hotels[0].detailInfo">
                  <div (click)="reloadFilteredRooms()" class="row" style="margin:0;">

                    <div *ngIf="!appComponent.isSbt" class="col-md-4">
                      <div class="providerFilter">
                        <div class="mb-2">{{'PROVIDER'|translate}}</div>
                        <div (click)="setProvider(provider)" *ngFor="let provider of currentHotel.hotelProviders" class="singleProvider">
                        <span *ngIf="!isCProviderSelected(provider)" class="material-icons">
                          check_box_outline_blank
                        </span>
                          <span *ngIf="isCProviderSelected(provider)" class="material-icons" style="color:cornflowerblue;">
                            check_box
                        </span>
                          {{provider}} <span *ngIf="provider === 'AMADEUS VALUE HOTELS' || provider === 'RESTEL' || provider === 'HotelBeds'"><sup>(prepaid)</sup></span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="providerFilter">
                        <div class="mb-2">{{'REFOUND'|translate}}</div>
                        <div (click)="setRefound(true)" class="singleProvider">
                          <span *ngIf="!isRefoundSelected(true)" class="material-icons">
                            check_box_outline_blank
                          </span>
                          <span *ngIf="isRefoundSelected(true)" class="material-icons" style="color:cornflowerblue;">
                            check_box
                          </span>
                          {{'YES_REFOUND'|translate}}
                        </div>
                        <div (click)="setRefound(false)" class="singleProvider">
                          <span *ngIf="!isRefoundSelected(false)" class="material-icons">
                            check_box_outline_blank
                          </span>
                          <span *ngIf="isRefoundSelected(false)" class="material-icons" style="color:cornflowerblue;">
                            check_box
                          </span>
                          {{'NO_REFOUND'|translate}}
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="providerFilter">
                        <div class="mb-2">{{'BOARD'|translate}}</div>
                        <div (click)="setMeal(meal)" *ngFor="let meal of currentHotel.mealsTypes" class="singleProvider">
                          <span *ngIf="!isMealSelected(meal)" class="material-icons">
                            check_box_outline_blank
                          </span>
                          <span *ngIf="isMealSelected(meal)" class="material-icons" style="color:cornflowerblue;">
                            check_box
                        </span>
                          {{meal}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div *ngIf="roomsLoaded">
                    <div *ngFor="let room of currentHotel.filteredRoomList; let i = index">
                      <div>
                        <app-singlehotelrooms (update)="roomSelect($event)" [hotel]="room.hotel" [i]="i" [room]="room" [searchRes]="hotelRes" [search]="hotelSearch"></app-singlehotelrooms>
                      </div>
                    </div>
                  </div>

                </div>


              </mat-tab>
              <mat-tab *ngIf="currentHotel.roomSelected" label="{{'GUESTS'|translate}}">
                <br/>
                <div class="padding15">
                  <div class="row buttonrow">
                    <div *ngFor="let tra of currentHotel.guests;let i = index" class="col-lg-6 mb-3">
                      <div class="fakefield mb-2">
                        {{'TYPE'|translate}}:
                        <span> {{tra.type|translate}}</span>
                      </div>
                      <div class="fakefield mb-2">
                        {{'NAME'|translate}}:
                        <span *ngIf="tra.name"> {{tra.name}}</span>
                        <span *ngIf="!tra.name"> _ _ _ _</span>
                      </div>
                      <div class="fakefield mb-2">
                        {{'SURNAME'|translate}}:
                        <span *ngIf="tra.surname"> {{tra.surname}}</span>
                        <span *ngIf="!tra.surname"> _ _ _ _</span>
                      </div>
                      <div class="fakefield mb-2">
                        {{'PHONE'|translate}}:
                        <span *ngIf="tra.phoneNumber"> {{tra.phoneNumber}}</span>
                        <span *ngIf="!tra.phoneNumber"> _ _ _ _</span>
                      </div>
                      <div class="fakefield mb-2">
                        {{'EMAIL'|translate}}:
                        <span *ngIf="tra.email"> {{tra.email}}</span>
                        <span *ngIf="!tra.email"> _ _ _ _</span>
                      </div>

                      <button (click)="toggleDialog(i)" *ngIf="!tra.name || !tra.surname" mat-stroked-button>{{'ADD_GUEST'|translate}}</button>
                      <button (click)="removeTraveler(i)" *ngIf="tra.name || tra.surname" mat-stroked-button>{{'REMOVE_GUEST'|translate}}</button>
                      <button (click)="toggleEditDialog(i)" *ngIf="tra.name || tra.surname" mat-stroked-button>{{'EDIT_GUEST'|translate}}</button>
                      <button (click)="loadTravelersList(i)" *ngIf="!tra.name && !tra.surname" mat-stroked-button>{{'LOAD_TRAVELER'|translate}}</button>
                    </div>
                  </div>
                </div>

              </mat-tab>
            </mat-tab-group>
          </div>

        </div>

        <hr/>

        <div class="text-right">
          <button (click)="toggleNewSingleHotel(null)" mat-button type="button">{{'CLOSE'|translate}}</button>
          <button (click)="bookHotel()" *ngIf="currentHotel.fareSelected" [disabled]="!travelerController()" color="primary" mat-button type="button">{{'BOOK'|translate}}</button>
        </div>

      </div>
    </div>
  </div>

</div>

<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="addTravelerModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-add (close)="closeEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [newTraveler]="currentHotel.guests[currentTraveler]" [parent]="'searchResults'"></app-add>
      </div>

    </div>
  </div>
</div>

<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="listTravelerModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div *ngIf="loadTravelers" class="modal-content">

      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-travelerslist (close)="closeListEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [newTraveler]="currentHotel.guests[currentTraveler]" [page]="'searchResults'"
                           [passengers]="currentHotel.guests"></app-travelerslist>
        <hr/>
        <div class="buttonrow text-right">
          <button (click)="closeListEvent($event)" mat-stroked-button>{{'CLOSE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="EditTravelerModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div *ngIf="currentTraveler!=undefined && editingTraveler" class="modal-body">
        <app-edittraveler (close)="closeEditEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [currentTraveler]="currentHotel.guests[currentTraveler]"
                          [parent]="'searchResults'"></app-edittraveler>
      </div>
    </div>
  </div>
</div>
