import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { FlightcrypticComponent } from './components/flightcryptic/flightcryptic.component';
import { AddProfileComponent } from './components/add-profile/add-profile.component';
import { CompaniesautocompleteComponent } from './components/add-profile/companiesautocomplete/companiesautocomplete.component';
import { SellingPlatformComponent } from './components/selling-platform/selling-platform.component';
import { ManageIataComponent } from './components/manage-iata/manage-iata.component';
import { BlockIssuangeRatesFlightComponent } from './components/block-issuange-rates-flight/block-issuange-rates-flight.component';
import { ImportpnrComponent } from './components/importpnr/importpnr.component';
import { CustomCommonModule } from '../common/custom-common.module';
import { IncentivesComponent } from './components/incentives/incentives.component';
import { ManageAirportsComponent } from './components/manage-airports/manage-airports.component';


@NgModule({
  declarations: [
    FlightcrypticComponent,
    AddProfileComponent,
    CompaniesautocompleteComponent,
    SellingPlatformComponent,
    ManageIataComponent,
    BlockIssuangeRatesFlightComponent,
    ImportpnrComponent,
    IncentivesComponent,
    ManageAirportsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    CustomCommonModule,
  ],
  exports: [
    CompaniesautocompleteComponent
  ]
})
export class AdminareaModule{}
