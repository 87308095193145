import { NgModule } from '@angular/core';
import { SnackErrorComponent } from './snackerror/snack-error.component';
import { MaterialModule } from '../material/material.module';
import { NotifierModule } from 'angular-notifier';

@NgModule({
  declarations: [
    SnackErrorComponent,
  ],
  imports: [
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 20,
        },
        vertical: {
          position: 'bottom',
          distance: 70,
        },
      },
    }),
  ],
  providers: [
    SnackErrorComponent,
  ],
  exports: [
    MaterialModule
  ]
})
export class ErrorNotificationModule{}
