<div class="settingsBar">
  <div class="">

    <mat-accordion multi="false" class="headers-align">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>alternate_email</mat-icon> {{'NOTIFICATIONS_MAIL'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>


        <div>
          <mat-slide-toggle class="margin-toggle" labelPosition="after" [(ngModel)]="maincomponent.userSettings.email.flight.timeLimit" (change)="updateValue()">
            {{'NOTIFICATIONS_TIMELIMIT'|translate}}
          </mat-slide-toggle>
          <mat-slide-toggle class="margin-toggle" labelPosition="after" [(ngModel)]="maincomponent.userSettings.email.flight.expiring" (change)="updateValue()">
            {{'NOTIFICATIONS_EXPIRING'|translate}}
          </mat-slide-toggle>
          <mat-slide-toggle class="margin-toggle" labelPosition="after" [(ngModel)]="maincomponent.userSettings.email.flight.change" (change)="updateValue()">
            {{'NOTIFICATIONS_CHANGE'|translate}}
          </mat-slide-toggle>
          <mat-slide-toggle class="margin-toggle" labelPosition="after" [(ngModel)]="maincomponent.userSettings.email.flight.cancelled" (change)="updateValue()">
            {{'NOTIFICATIONS_CANCEL'|translate}}
          </mat-slide-toggle>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="!appcomponent.isSbt && !appcomponent.isB2B && maincomponent.loggedUser._id !== '604f240ec5ec740012a28f46'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>euro_symbol</mat-icon> {{'PRICES'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <h5 class="settsubtitle" *ngIf="maincomponent.loggedUser.company.services.flight">{{'FLIGHTS'|translate}}</h5>
        <div class="flightsettings marginbottom10" *ngIf="maincomponent.loggedUser.company.services.flight">

          <mat-slide-toggle class="margin-toggle" labelPosition="after" [(ngModel)]="maincomponent.userSettings.useMarkup" (change)="updateValue()">
            {{'FLIGHT_MARKUP'|translate}}
          </mat-slide-toggle>
          <div *ngIf="maincomponent.userSettings.useMarkup" class="marginbottom10">
            <hr/>
            <mat-form-field class="inline-field">
              <mat-label>{{'TYPE_MARKUP'|translate}}</mat-label>
              <mat-select [(ngModel)]="maincomponent.userSettings.markup.type" (ngModelChange)="setFlyMarkupType($event)">
                <mat-option value="percent">
                  {{'PERCENT'|translate}}
                </mat-option>
                <mat-option value="sum">
                  {{'SUM'|translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="inline-field">
              <input matInput placeholder="{{'MARKUP_VALUE'|translate}}" [(ngModel)]="maincomponent.userSettings.markup.value" (change)="updateValue()">
              <mat-icon *ngIf="maincomponent.userSettings.markup.type=='sum'" matSuffix>€</mat-icon>
              <mat-icon *ngIf="maincomponent.userSettings.markup.type=='percent'" matSuffix>%</mat-icon>
            </mat-form-field>
            <hr/>
          </div>
          <mat-slide-toggle class="margin-toggle" labelPosition="after" [(ngModel)]="maincomponent.userSettings.hidenego" (change)="updateValue()">
            {{'HIDE_NEGO'|translate}}
          </mat-slide-toggle>

        </div>

        <h5 class="settsubtitle" *ngIf="!appcomponent.isGat && maincomponent.loggedUser.company.services.hotel">{{'HOTELS'|translate}}</h5>
        <div class="flightsettings" *ngIf="!appcomponent.isGat && maincomponent.loggedUser.company.services.hotel">
          <mat-slide-toggle class="margin-toggle" labelPosition="after" [(ngModel)]="maincomponent.userSettings.useMarkupHotel" (change)="updateValue()">
            {{'HOTEL_MARKUP'|translate}}
          </mat-slide-toggle>
          <div *ngIf="maincomponent.userSettings.useMarkupHotel" class="marginbottom10">
            <hr/>
            <mat-form-field class="inline-field">
              <mat-label>{{'TYPE_MARKUP'|translate}}</mat-label>
              <mat-select [(ngModel)]="maincomponent.userSettings.markupHotel.type" (ngModelChange)="setHotelMarkupType($event)">
                <mat-option value="percent">
                  {{'PERCENT'|translate}}
                </mat-option>
                <mat-option value="sum">
                  {{'SUM'|translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="inline-field">
              <input matInput placeholder="{{'MARKUP_VALUE'|translate}}" [(ngModel)]="maincomponent.userSettings.markupHotel.value" (change)="updateValue()">
              <mat-icon *ngIf="maincomponent.userSettings.markupHotel.type=='sum'" matSuffix>€</mat-icon>
              <mat-icon *ngIf="maincomponent.userSettings.markupHotel.type=='percent'" matSuffix>%</mat-icon>
            </mat-form-field>
            <hr/>
          </div>
          <mat-slide-toggle class="margin-toggle" labelPosition="after" [(ngModel)]="maincomponent.userSettings.hideHotelnego" (change)="updateValue()">
            {{'HIDE_NET_PRICE'|translate}}
          </mat-slide-toggle>

        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>format_color_fill</mat-icon> {{'THEME_CONFIGURATION'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="themes" *ngIf="!appcomponent.isSbt && !appcomponent.isB2B && !appcomponent.isGat">
          <span matTooltip="Blue" (click)="appcomponent.onSetTheme('main-theme')" [ngClass]="{'currentTheme': appcomponent.theme === 'main-theme'}">
            <img src="assets/img/themes/default.png">
          </span>

          <span matTooltip="Violet" (click)="appcomponent.onSetTheme('third-theme')" [ngClass]="{'currentTheme': appcomponent.theme === 'third-theme'}">
            <img src="assets/img/themes/violet.png">
          </span>

          <span matTooltip="Green" (click)="appcomponent.onSetTheme('green-theme')" [ngClass]="{'currentTheme': appcomponent.theme === 'green-theme'}">
            <img src="assets/img/themes/green.png">
          </span>

          <span matTooltip="Red" (click)="appcomponent.onSetTheme('red-theme')" [ngClass]="{'currentTheme': appcomponent.theme === 'red-theme'}">
            <img src="assets/img/themes/red.png">
          </span>

          <span matTooltip="Yellow" (click)="appcomponent.onSetTheme('yellow-theme')" [ngClass]="{'currentTheme': appcomponent.theme === 'yellow-theme'}">
            <img src="assets/img/themes/yellow.png">
          </span>

          <span *ngIf="maincomponent.loggedUser.role.superAdmin" matTooltip="b2bTravel" (click)="appcomponent.onSetTheme('b2b-theme')" [ngClass]="{'currentTheme': appcomponent.theme === 'b2b-theme'}">
            <img src="assets/img/themes/b2b.png">
          </span>
          <!--
          <span *ngIf="maincomponent.loggedUser._id !== '604f240ec5ec740012a28f46'" (click)="appcomponent.onSetTheme('second-theme')">
            <img src="assets/img/themes/dark.png">
          </span>
          -->
          <span *ngIf="maincomponent.loggedUser.role.superAdmin" matTooltip="suite4travel" (click)="appcomponent.onSetTheme('sbt-theme')" [ngClass]="{'currentTheme': appcomponent.theme === 'sbt-theme'}">
            <img src="assets/img/themes/suite.png">
          </span>
          <span *ngIf="maincomponent.loggedUser.role.superAdmin" matTooltip="flyGATTINONI" (click)="appcomponent.onSetTheme('gat-theme')" [ngClass]="{'currentTheme': appcomponent.theme === 'gat-theme'}">
            <img src="assets/img/themes/grey.png">
          </span>

        </div>
        <div *ngIf="maincomponent.userSettings && maincomponent.loggedUser._id!=='604f240ec5ec740012a28f46' && (appcomponent.isSbt || appcomponent.isB2B || appcomponent.isGat || maincomponent.loggedUser.role.superAdmin)">
          <hr/>
          <mat-checkbox (change)="updateValue()" [(ngModel)]="maincomponent.userSettings.menuTop" class="example-margin">
            <span>{{'MENU_TOP'|translate}}</span>
          </mat-checkbox>
        </div>
        <div *ngIf="!appcomponent.isB2B">
          <hr/>
          <mat-slide-toggle class="margin-toggle" labelPosition="after" [(ngModel)]="appcomponent.stepSearch" (change)="setStepSearch($event)">
            {{'STEP_SEARCH'|translate}}
          </mat-slide-toggle>
        </div>

      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="maincomponent.loggedUser.role.superAdmin && !appcomponent.isSbt && !appcomponent.isGat">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>tune</mat-icon> {{'CONFIGURATION'|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-slide-toggle class="margin-toggle" labelPosition="after" [(ngModel)]="appcomponent.isSbt" (change)="setB2b($event)">
          {{'IS_SBT'|translate}}
        </mat-slide-toggle>
        <mat-slide-toggle class="margin-toggle" labelPosition="after" [(ngModel)]="appcomponent.isB2B" (change)="setB2bb($event)">
          {{'IS_B2B'|translate}}
        </mat-slide-toggle>

      </mat-expansion-panel>

    </mat-accordion>
  </div>
</div>
<div class="settingsBackDrop" (click)="maincomponent.toggleSettings()"></div>
