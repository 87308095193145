<h1 mat-dialog-title>Edit user group</h1>
<div mat-dialog-content>

  <mat-form-field appearance="outline">
    <mat-label>Select Group</mat-label>
    <mat-select [(ngModel)]="group">
      <mat-option *ngFor="let group of data.groups" [value]="group._id">
        {{group.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <div style="display: flex; justify-content: flex-end; align-items: flex-end; width: 100%">
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button [disabled]="loading" color="primary" mat-button (click)="onSaveClick()">
    <span style="display: flex; align-items: center">
      <mat-spinner *ngIf="loading" diameter="20" style="margin-right: 5px"></mat-spinner>
      <span>Save</span>
    </span>
  </button>
  </div>
</div>

