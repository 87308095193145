import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CompaniesService } from '../../../../../../services/profile/companies.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {AppComponent} from '../../../../../../app.component';

export interface CorporateCodeInterface{
  _id? : string;
  name : string;
  code : string;
}

interface ManageCorporateInterface{
  flight : CorporateCodeInterface[],
  flightOpen : boolean,
  flightSelected : CorporateCodeInterface,
  hotel : CorporateCodeInterface[],
  hotelOpen : boolean,
  hotelSelected : CorporateCodeInterface,
}

class ManageCorporate implements ManageCorporateInterface{
  flight : CorporateCodeInterface[];
  flightOpen : boolean;
  flightSelected : CorporateCodeInterface;
  hotel : CorporateCodeInterface[];
  hotelOpen : boolean;
  hotelSelected : CorporateCodeInterface;
  car : CorporateCodeInterface[];
  carOpen : boolean;
  carSelected : CorporateCodeInterface;
}

export class CorporateCode implements CorporateCodeInterface{
  _id : string | undefined;
  code : string;
  name : string;

  constructor(name : string, code : string, _id? : string){}
}

@Component({
  selector: 'app-corporate-codes',
  templateUrl: './corporate-codes.component.html',
  styleUrls: ['./corporate-codes.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CorporateCodesComponent implements OnInit{
  @Input() public isSearch : boolean = false;
  @Input() public _id : string;
  @Input() public service? : string;
  public corporateCodes : ManageCorporate = new ManageCorporate();
  public displayedColumns : string[] = ['name', 'code'];
  public services : string[] = ['hotel', 'flight', 'car', 'train'];
  public expanded : ManageCorporate = new ManageCorporate();
  public addRecord : boolean = false;
  public loaded : boolean = false;
  public newCorporate : CorporateCode;
  private modelForNewCorporate : CorporateCode = new CorporateCode('nome del nuovo codice', 'inserisci il codice', undefined);
  constructor(
    private api : CompaniesService,
    private appComponent: AppComponent,
  ){
    this.newCorporate = this.modelForNewCorporate;
    if (this.appComponent.isGat) {
      this.services = ['flight'];
    }
  }

  ngOnInit() : void{
    this.corporateCodes.flightOpen = false;
    this.corporateCodes.hotelOpen = false;
    this.getData();
  }

  getData(){
    this.services.forEach(srv =>
      this.api.getCorporateCode(srv, this._id)
          .subscribe(data => this.corporateCodes[srv] = data.list),
    );
  }

  delete(srv, corporateId) : void{
    this.api.deleteCorporateCode(srv, this._id, corporateId)
        .subscribe(data => this.getData());
  }

  save(srv) : void{
    this.api.saveCorporateCode(srv, this._id, this.newCorporate)
        .subscribe(() => this.getData());
    this.addRecord = false;
    this.clearNew();
  }

  update(srv, corporate) : void{
    this.api.updateCorporateCode(srv, this._id, corporate)
        .subscribe(data => this.getData());
    this.addRecord = false;
    this.clearNew();
  }

  clearNew() : void{
    this.newCorporate = this.modelForNewCorporate;
  }
}
