import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BusinessClassPipe} from './business-class.pipe';
import {AlertsPipe} from './alerts.pipe';
import {MsToTimePipe} from './ms-to-time.pipe';
import {ObsWithStatusPipe} from './obs-with-status.pipe';


@NgModule({
  declarations: [BusinessClassPipe, AlertsPipe, MsToTimePipe, ObsWithStatusPipe],
  imports: [
    CommonModule
  ],
    exports: [BusinessClassPipe, AlertsPipe, MsToTimePipe, ObsWithStatusPipe]
})
export class PipesModule { }
