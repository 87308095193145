import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'msToTime'
})
export class MsToTimePipe implements PipeTransform {

  transform(s: number): string {
    const pad = (n, z = 2) => ('00' + n).slice(-z);
    // tslint:disable-next-line:no-bitwise
    return pad(s / 3.6e6 | 0) + 'h ' + pad((s % 3.6e6) / 6e4 | 0) + 'm';
  }

}
