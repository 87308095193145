<custom-title></custom-title>
<div class="padding20">
  <div class="searchNavigation">
    <div class="mb-2">
        <a class="newTabButton" routerLink="/flight/search">
          <span>{{'FLIGHTS_SEARCH'|translate}}</span>
        </a>
        <button class="newTabButtonActive">
          <span>{{'HISTORY'|translate}}</span>
        </button>
    </div>
</div>
  <div class="card marginbottom20">

    <div *ngIf="loading" class="loader">
      <div class="card text-center padding20">
        <div class="d-flex justify-content-center">
          <mat-spinner></mat-spinner>
        </div>
        <h5>{{'LOADING'|translate}}...</h5>
      </div>
    </div>

    <div *ngIf="!loading" class="row">
      <div class="col-6"></div>
      <div class="col-lg-6 d-flex justify-content-end text-right">
        <div class="paginationr pr-3">
          <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
          <span class="mr-2">
            {{'PAGE'|translate}}
            <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
              <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p+1}}</mat-option>
              </mat-select>
            </mat-form-field>
            {{'OF'|translate}} {{maxpages}}
          </span>

          <button mat-icon-button class="iconButtons" (click)="prev()" [disabled]="currentpage==0">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button mat-icon-button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>

      </div>
    </div>

  </div>

  <div class="pnrlist" *ngIf="history">

    <mat-card appearance="outlined" class="example-card mb-3" *ngFor="let search of history.list;let i = index">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image" style="background-size:cover;background-image: url('/assets/img/flights.png')"></div>
        <mat-card-title>
          {{search.createdAt | date:'dd/MM/yyyy : HH:mm'}}
        </mat-card-title>
        <mat-card-subtitle>
          {{search.user.surname}} {{search.user.name}}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="row mb-2">
          <div class="col-12 text-danger mb-2 small text-uppercase" *ngIf="search.options.tourOperator">Ricerca per sole tariffe Tour Operator</div>
          <div class="col-md-8">
            <div class="pt-2 pb-2" *ngFor="let section of search.sections">
              <div class="row">
                <div class="col-5">

                  <div *ngFor="let f of section.from">
                    {{iataComplete.returnAirport(f)}} <b>({{f}})</b>
                  </div>
                </div>
                <div class="col-2">
                  <div>{{'DEPARTURE'|translate}}</div>
                  <div class="font-weight-bold">{{section.options.date| date: 'dd/MM/yyyy'}}</div>
                </div>
                <div class="col-5">
                  <div *ngFor="let t of section.to">
                    {{iataComplete.returnAirport(t)}} <b>({{t}})</b>
                  </div>
                </div>


              </div>

            </div>
          </div>
          <div class="col-md-2 border-left">
            <div>{{'ADULTS'|translate}} <b>{{search.passengers.adt}}</b></div>
            <div>{{'CHILDREN'|translate}} <b>{{search.passengers.chd}}</b></div>
            <div>{{'INFANT'|translate}} <b>{{search.passengers.inf}}</b></div>
          </div>

          <div class="col-md-2 buttonrow border-left">
            <div class="mb-2">
              <span class="material-icons" *ngIf="search.options.calendar" matTooltip="{{'SEARCH_CALENDAR'|translate}}">
                date_range
              </span>
            </div>
            <button mat-button (click)="openHistory(search._id)">{{'OPEN'|translate}}</button>
          </div>

        </div>

      </mat-card-content>
    </mat-card>
  </div>

  <div class="card">
    <div *ngIf="!loading" class="row">
      <div class="col-6"></div>
      <div class="col-lg-6 d-flex justify-content-end text-right">
        <div class="paginationr pl-3">
          <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
          <span class="mr-2">
            {{'PAGE'|translate}}
            <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
              <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p+1}}</mat-option>
              </mat-select>
            </mat-form-field>
            {{'OF'|translate}} {{maxpages}}
          </span>

          <button mat-icon-button class="iconButtons" (click)="prev()" [disabled]="currentpage==0">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button mat-icon-button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>

      </div>
    </div>
  </div>


</div>
