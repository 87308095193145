<div *ngIf="arrayOfAlerts && (appComponent.isB2B || appComponent.isSbt)" style="position:relative;display:inline-block;" appTooltip [tooltipTitle]="tooltip"
     placement="bottom">
  <mat-icon *ngIf="arrayOfAlerts?.length === 0" style="color:green;cursor: pointer;font-size:16px;height:22px" class=" material-icons-outlined">check
  </mat-icon>
  <mat-icon color="warn" *ngIf="arrayOfAlerts?.length > 0" style="cursor: pointer;font-size:16px;height:22px" class=" material-icons-outlined">warning
  </mat-icon>
  <div class="custom-tooltip" #tooltip>
    <div *ngIf="arrayOfAlerts?.length === 0">
      <span style="color: #3F51B5">No restrictions</span>
    </div>
    <div style="display: block" *ngFor="let alert of arrayOfAlerts">
      <span [ngStyle]="{ 'color' : (alert?.block === true)? '#3F51B5' : '#F44336' }">{{alert?.message}}</span>
    </div>
  </div>
</div>
