import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FlightService } from '../../../../../services/flight/flight.service';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { IatacompleteService } from '../../../../../services/iatacomplete/iatacomplete.service';
import { PriceService } from '../../../../../services/price/price.service';
import { MainComponent } from '../../../../mainmodule/main/main.component';
import { HttpserviceService } from '../../../../../services/httpservice.service';
import { NewTraveller } from '../../../../../classes/travelers/newTraveller';
import * as $ from 'jquery';
import * as moment from 'moment';
import { Company } from '../../../../../classes/company';
import { CompaniesService } from '../../../../../services/profile/companies.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UsersService } from '../../../../../services/profile/users.service';
import { AppComponent } from '../../../../../app.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TravelersService } from '../../../../../services/travelers/travelers.service';
import { ConfirmationDialogComponent } from '../../../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import * as fareData from '../searchflight/searchresults/ratesDetailsSupportedCompany.json';
import { TfFareRulesComponent } from '../../../../../components/shared/tf-fare-rules/tf-fare-rules.component';

export interface DialogData{
  reservation : any;
  accept : boolean;
  isSbt : boolean;
  isGat : boolean;
  isCau: boolean;
  priceChange : boolean;
}

@Component({
  selector: 'app-finalizelowcost',
  templateUrl: './finalizelowcost.component.html',
  styleUrls: ['./finalizelowcost.component.scss'],
})

export class FinalizelowcostComponent implements OnInit, OnDestroy{
  /**
   *  Local variable for current traveler index to edit
   */
  currentTraveler : number;
  /**
   *  Local variable for editing traveler status
   */
  editingTraveler : boolean;
  viewTotal = true;
  routingId : string;
  outwardId : string;
  returnId : string;
  reservation : any;
  loading : boolean;
  booking : boolean;
  response : any;
  tabs : any[];
  travelers : NewTraveller[];
  adt : number;
  chd : number;
  inf : number;
  required : any[];
  allRequired : any[];
  newFop : any;
  reservationId : string;
  acceptedCreditCard = [];
  processTermResp : any;
  /**
   *  Local variable for current tab
   */
  currentTab : string;
  loadTravelers : boolean;
  company : Company;
  domain : string;
  luggageAmount : number;
  accept : boolean;
  flights : any[];
  seatmap : any[];
  seatObbligatory = false;
  passengers : any;
  paramsToCheck : any[];
  priceChanged = false;
  newLuggagePrice : number;
  newPrice : number;
  newSeatPrice : number;
  fareData: [];
  tarifLowData : any [];

  constructor(
    private flightService : FlightService,
    private router : Router,
    private route : ActivatedRoute,
    public iataComplete : IatacompleteService,
    public priceservice : PriceService,
    public mainComponent : MainComponent,
    private httpservice : HttpserviceService,
    private companyService : CompaniesService,
    private snackBar : MatSnackBar,
    public dialog : MatDialog,
    private usersService : UsersService,
    public appComponent : AppComponent,
    public translate : TranslateService,
    private travelersService : TravelersService,
    public tfFareRulesComponent:TfFareRulesComponent
  ){

    this.newLuggagePrice = 0;
    this.newSeatPrice = 0;
    this.currentTab = 'PASSENGERS';
    this.travelers = [];
    this.required = [];
    this.allRequired = [];
    this.paramsToCheck = [];
    this.flights = [];
    this.seatObbligatory = false;
    this.tarifLowData = [];
    if (this.appComponent.isSbt){
      this.domain = 'https://suite4travel.gattinoni.it/flight/checklowcost';
    } else{
      this.domain = 'https://flyleo.lts-srl.it/flight/checklowcost';
      // this.domain = 'https://checklowcost.lts-srl.it';
    }
    this.setFixedParamsToCheck();
  }

  travelerController(){
    const isValidTraveler = (value) => !(!value.name || !value.surname || !value.birthday || !value.sex);
    return this.reservation.passengers.every(isValidTraveler);
  }

  setFixedParamsToCheck(){
    this.paramsToCheck.push({ Name: 'sex' });
  }

  ngOnDestroy() : void{
    localStorage.removeItem('fl_lowCostDetails');
  }

  someSeats() : boolean{
    let seats = false;
    this.reservation.passengers.forEach(p => {
      if (p.seat){
        seats = true;
      }
    });
    return seats;
  }

  returnSeatsAmount(price : number = null){
    let total = 0;
    this.reservation.passengers.forEach(p => {
      if (p.seat && p.seat.length > 0){
        p.seat.forEach(s => {
          if (s.price && s.price !== ''){
            total += parseFloat(s.price);
          }
        });
      }
    });
    if (price !== null){
      return price;
    } else{
      return total;
    }
  }

  createNewFop(){
    this.newFop = {
      type: 'CC',
      card: {
        type: 'CA',
        number: '5582362960593050',
        expirationMonth: '03',
        expirationYear: '22',
        holder: 'Bruno Monchiero',
        cvc: '887',
      },
    };
  }

  ngOnInit() : void{
    localStorage.removeItem('fl_lowCostDetails');
    this.createNewFop();
    this.route.queryParams.subscribe(params => {
      this.reservationId = params.param0 || undefined;
      this.routingId = params.param1 || undefined;
      this.outwardId = params.param2 || undefined;
      this.returnId = params.param3 || undefined;
      this.adt = params.param4;
      this.chd = params.param5;
      this.inf = params.param6;
    });
    this.createTravelers();
    if (!localStorage.getItem('fl_lowCostDetails')){
      this.processDetLowCost();
    } else{
      const res = JSON.parse(localStorage.getItem('fl_lowCostDetails'));
      this.elaborateDetails(res);
    }

  }

  navigateToId(pnr){
    const body = {
      // tslint:disable-next-line:max-line-length
      queryParams: {
        param0: pnr,
        param1: this.routingId,
        param2: this.outwardId,
        param3: this.returnId,
        param4: this.adt,
        params5: this.chd,
        param6: this.inf,
      },
      // replaceUrl: true, // optional
    };
    this.router.navigate(['/flight/processLowCost'], body);
  }

  getCompany(id) : void{
    this.companyService.getCompany(id).subscribe((res) => {
      this.company = res;
      this.reservation.company = this.company;
    }, error => {
      this.loading = false;
      console.error('res => ', error);
    });
  }

  getUser(id) : void{
    this.usersService.getUser(id).subscribe((res) => {
      this.reservation.user = res;
    }, error => {
      console.error('Error => ', error);
    });
  }

  createTravelers(){
    for (let i = 0; i < this.adt; i ++){
      const traveler = new NewTraveller();
      traveler.type = 'ADT';
      this.travelers.push(traveler);
    }
    for (let i = 0; i < this.chd; i ++){
      const traveler = new NewTraveller();
      traveler.type = 'CHD';
      this.travelers.push(traveler);
    }
    for (let i = 0; i < this.inf; i ++){
      const traveler = new NewTraveller();
      traveler.type = 'INF';
      this.travelers.push(traveler);
    }
    console.log('this.travelers ', this.travelers);
  }

  processDetLowCost(){
    this.loading = true;
    const body = {
      routingId: this.routingId,
      outwardId: this.outwardId,
      returnId: this.returnId,
    };
    console.log('body => ', body);
    this.flightService.processDetLowCost(body).subscribe((res : any) => {
      // localStorage.setItem('fl_lowCostDetails', JSON.stringify(res));
      this.elaborateDetails(res);
      if (!this.reservationId){
        this.navigateToId(res.pnr);
      }
      this.acceptedCreditCard = res.supportedCards;
      console.log('this.acceptedCreditCard => ', this.acceptedCreditCard);

      if (localStorage.getItem('dossierPassengers')){
        this.passengers = JSON.parse(localStorage.getItem('dossierPassengers'));
        this.getTravelerDossier();
      }

    }, error => {
      this.loading = false;
      console.error('res => ', error);
    });
  }

  getTravelerDossier(){
    this.passengers.length = this.passengers.length;
    for (let i = 0; i < this.passengers.length; i ++){
      this.travelersService.getTraveler(this.passengers[i]._id).subscribe((res : any) => {
        if (!res.type){
          res.type = 'ADT';
        }
        this.travelerSet(res, i);
      }, error => {
        console.error('res => ', error);
      });
    }
  }

  elaborateDetails(res){
    this.response = JSON.parse(JSON.stringify(res));
    this.reservation = res.recommendation;
    this.reservation.fare.amount = parseFloat(this.reservation.fare.amount);
    this.reservation.company = this.mainComponent.loggedUser.company;
    for (let i = 0; i < this.reservation.itineraries.length; i ++){
      this.reservation.itineraries[i] = this.reservation.itineraries[i].sections[0];
    }
    // tslint:disable-next-line:max-line-length
    this.reservation.company.image = this.httpservice.apiUrl + 'api/static/company/' + this.reservation.company._id + '.png?time=' + new Date().getTime();
    this.iataComplete.createToCompletePrn(this.reservation);
    this.reservation.user = this.mainComponent.loggedUser;
    this.reservation.passengers = this.travelers;
    this.required.length = this.reservation.passengers.length;
    console.log('res => ', res);
    this.loading = false;
    this.getCompany(this.mainComponent.loggedUser.company._id);
    this.getUser(this.reservation.user._id);
    this.getRequired(res.requiredParameters);
    this.generateTabs();
    this.getFlights(res);
    this.getSeatMap(res);
    this.reservation.supportedCards = res.supportedCards || [];
  }

  getSeatMap(res){
    const temp = [];
    if (res.recommendation.seatOptions.length > 0){
      for (let y = 0; y < res.recommendation.seatOptions.length; y ++){
        this.seatmap = res.recommendation.seatOptions[y].matrix;
        for (let i = 0; i < this.seatmap.length; i ++){
          this.seatmap[i] = this.seatmap[i].reverse();
        }
        temp.push({ matrix: this.seatmap });
      }
      this.seatmap = temp;
    } else{
      this.seatmap = [];
    }
  }

  getFlights(res){
    const temp = res.recommendation.itineraries;
    for (const t of temp){
      for (const f of t.flights){
        this.flights.push(f);
      }
    }
  }

  generateTabs(){
    if (this.appComponent.isB2B){
      this.tabs = [
        {
          name: 'ITINERARY',
          active: false,
        },
        {
          name: 'FARE',
          active: false,
        },
        {
          name: 'PASSENGERS',
          active: true,
        },
        {
          name: 'BAGGAGES',
          active: false,
        },
        {
          name: 'SEAT_MAP',
          active: false,
        },
        {
          name: 'PAYMENT',
          active: false,
        },
      ];
    } else{
      this.tabs = [
        {
          name: 'ITINERARY',
          active: false,
        },
        {
          name: 'FARE',
          active: false,
        },
        {
          name: 'PASSENGERS',
          active: true,
        },
        {
          name: 'BAGGAGES',
          active: false,
        },
        {
          name: 'SEAT_MAP',
          active: false,
        },
        /*
         {
         name: 'ANCILLARY',
         active: false,
         },*/
        {
          name: 'PAYMENT',
          active: false,
        },
      ];
    }
  }

  getRequired(requiredParameters){
    for (let p = 0; p < this.reservation.passengers.length; p ++){
      if (!this.required[p]){
        this.required[p] = [];
      }
      for (let i = 0; i < requiredParameters.length; i ++){
        if (requiredParameters[i].IsOptional === 'false' && requiredParameters[i].PerPassenger === 'true'){
          const required = JSON.parse(JSON.stringify(requiredParameters[i]));
          const founded = false;
          required.ref = p;
          required.value = '';
          if (required.Name === 'SeatOptions'){
            this.seatObbligatory = true;
          }
          this.required[p].push(required);
        }
      }
    }
    for (let i = 0; i < requiredParameters.length; i ++){
      if (requiredParameters[i].IsOptional === 'false' && requiredParameters[i].PerPassenger === 'true'){
        const required = JSON.parse(JSON.stringify(requiredParameters[i]));
        this.paramsToCheck.push(required);
      }
    }
    console.log('this.required => ', this.required);
    for (let i = 0; i < requiredParameters.length; i ++){
      if (requiredParameters[i].IsOptional === 'false' && requiredParameters[i].PerPassenger === 'false'){
        requiredParameters[i].ref = i;
        requiredParameters[i].value = '';
        this.allRequired.push(requiredParameters[i]);
      }
    }
  }

  setLuggage(event){
    this.viewTotal = false;
    console.log(event);
    this.luggageAmount = event;
    this.viewTotal = true;
  }

  returnLuggageAmount(){
    return this.luggageAmount || 0;
  }

  defaultSeatOption(obj){
    // per il momento non tiene in considerazione il caso in cui a viaggiare sia un child o infant
    for (const att of obj.attribute){
      if (att == 'T' || att == 'CL' || att == 'D' || att == 'EX' || att == 'GN' || att == 'LA' || att == 'ST' || att == 'NS'){
        return false;
      } else{
        return true;
      }
    }
  }

  processTermLowCost(){
    if (this.appComponent.isSbt || this.mainComponent.loggedUser.company._id === '5e0a1e2047a19a0013fedcbe' || this.appComponent.isB2B){
      this.reservation.creditcard = {
        index: this.reservation.creditcard || '',
        type: '',
        supportedCards: '',
      };
    }
    if(this.appComponent.isGat && (isNaN(this.reservation.creditcard) && (typeof this.reservation.creditcard === 'string'))){
      this.reservation.creditcard = {
        index: this.reservation.creditcard,
        type:'',
        supportedCards: ''
      }
    }

    this.booking = true;
    let phone = this.reservation.user.phoneNumber;
    if (!phone.substring(0, 3).includes('+39') && !phone.substring(0, 5).includes('0039')){
      phone = '+39' + phone;
    }
    const body = {
      tfPay: false,
      req: {
        routingId: this.routingId,
        outwardId: this.outwardId,
        returnId: this.returnId,
      },
      perPassengers: this.required,
      required: this.allRequired,
      reservation: this.reservation,
      creditCard: {
        index: this.reservation.creditcard.index,
        type: this.reservation.creditcard.type,
        crediCards: this.reservation.supportedCards,
      },
      userData: {
        // tslint:disable-next-line:max-line-length
        value: this.reservation.user.email + ',' + phone + ',' + this.reservation.user.name + ' ' + this.reservation.user.surname + ' ' + this.reservation.company.name,
      },
      client: '',
    };
    if (this.appComponent.isSbt){
      body.client = 'suitefortravel';
      body.tfPay = true;
      delete body.creditCard;
    } else if (this.appComponent.isB2B){
      body.client = 'b2b';
      body.tfPay = true;
    } else{
      body.client = 'leonardotravel';
    }
    if (this.reservation.company._id === '5e0a1e2047a19a0013fedcbe'){
      body.tfPay = true;
    }
    // tslint:disable-next-line:max-line-length
    // body.userData.value = this.reservation.passengers[0].email + ',' + this.reservation.passengers[0].phoneNumber + ',' +
    // this.newFop.card.holder;
    this.flightService.processTermsLowCost(body, this.reservationId).subscribe((res : any) => {
      // tslint:disable-next-line:max-line-length
      console.log('res => ', res);
      this.booking = false;
      this.processTermResp = res;
      this.updateFareValues(res);
      // window.location.href = (link + encodeURIComponent(notBased));
    }, error => {
      this.booking = false;
      console.error('res => ', error);
    });
  }

  updateFareValues(res){
    if (res?.Router?.GroupList?.Group?.Price){
      const price = res.Router.GroupList.Group.Price;
      const prevPrice = this.reservation.fare.amount + (this.luggageAmount || 0) + (this.reservation?.fare?.services || 0);
      if (prevPrice !== Number(price.Amount)){
        this.reservation.fare.amount = price.Amount;
        this.priceChanged = true;
      }
      this.newPrice = Number(price.Amount);
      if (price.TaxItemList){
        if (!Array.isArray(price.TaxItemList.TaxItem)){
          price.TaxItemList.TaxItem = [price.TaxItemList.TaxItem];
        }
        for (const item of price.TaxItemList.TaxItem){
          if (item.Name === 'Seat charge'){
            this.priceChanged = true;
            this.newSeatPrice += Number(item.Amount);
          }
          if (item.Name === 'Luggage surcharge'){
            this.priceChanged = true;
            this.newLuggagePrice += Number(item.Amount);
          }
        }
      }
      this.reservation.fare.amount = this.newPrice - this.newSeatPrice - this.newLuggagePrice;
      this.luggageAmount = this.newLuggagePrice;
      this.returnSeatsAmount(this.newSeatPrice); // se true gli metto il valore già calcolato
      this.returnSeatsAmount(this.newLuggagePrice);
      // this.openDialog(res);
    }
    this.openDialog(res);
  }

  openDialog(reservation) : void{
    const dialogRef = this.dialog.open(DialogLowCostConfirm, {
      width: '450px',
      maxWidth: '100%',
      data: {
        priceChange: this.priceChanged,
        accept: false,
        reservation,
        isbt: this.appComponent.isSbt,
        isGat: this.appComponent.isGat,
        fareRules: reservation?.Router?.SupplierInfoList?.SupplierInfo || [],
        isCau: !!this.reservation.company.cauDK
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined){
        this.accept = true;
        this.checkBook();
      }
    });
  }

  checkBook() : void{
    console.log('this.accept => ', this.accept);
    this.booking = true;
    let phone = this.reservation.company.phoneNumber;
    if (!phone.substring(0, 3).includes('+39') && !phone.substring(0, 5).includes('0039')){
      phone = '+39' + phone;
    }
    localStorage.removeItem('flychecklw');
    const body = {
      data: this.processTermResp,
      backUrl: this.domain,
      userData: {
        value: this.reservation.company.email,
      },
      creditCard: {
        index: this.reservation.creditcard.index,
        type: this.reservation.creditcard.type,
        crediCards: this.reservation.supportedCards,
      },
      id: this.reservationId,
    };

    localStorage.setItem('flychecklw', JSON.stringify(body));

    /*if (this.appComponent.isSbt) {
     console.log('SBT');
     } else {
     this.flightService.processCheckBook(body, this.reservationId).subscribe((res: any) => {
     // tslint:disable-next-line:max-line-length
     console.log('res => ', res);
     this.booking = false;
     if (res.CardVerificationData) {
     window.location.href = (res.CardVerificationData.Url);
     } else {
     this.goToPnr();
     }
     // window.location.href = (link + encodeURIComponent(notBased));
     }, error => {
     this.booking = false;
     console.error('res => ', error);
     });
     }*/
    this.flightService.processCheckBook(body, this.reservationId).subscribe((res : any) => {
      // tslint:disable-next-line:max-line-length
      console.log('res => ', res);
      this.booking = false;
      if (res.CardVerificationData){
        window.location.href = (res.CardVerificationData.Url);
      } else if (res.callback3DUrlStripe){
        window.location.href = (res.callback3DUrlStripe);
      } else if (res.callback3DUrl){
        console.log(res.description);
        window.location.href = (res.callback3DUrl);
      } else{
        this.goToPnr();
      }
      // window.location.href = (link + encodeURIComponent(notBased));
    }, error => {
      this.booking = false;
      console.error('res => ', error);
    });
  }


  goToPnr() : void{
    setTimeout(() => {
      this.router.navigate(['/flight/reservations/' + this.reservationId]);
    }, 900);
  }

  returnZeroTime(value){
    value = Number(value);
    if (value < 10){
      return '0' + value;
    } else{
      return '' + value;
    }
  }

  /**
   *  Calculate flight duration based on departure and arrival date time and pass result to return time function
   */
  calculateFlightTime(departure, arrival){
    departure = new Date(departure).getTime();
    arrival = new Date(arrival).getTime();
    const flightTime = arrival - departure;
    return this.returnTime(flightTime);
  }

  /**
   *  Return flight duration in dd-hh-mm
   */
  returnTime(t){
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(t / cd);
    let h = Math.floor((t - d * cd) / ch);
    let m = Math.round((t - d * cd - h * ch) / 60000);
    const pad = ((n) => n < 10 ? '0' + n : n);
    if (m === 60){
      h ++;
      m = 0;
    }
    if (h === 24){
      d ++;
      h = 0;
    }
    if (d > 0){
      return [d + 'g ' + pad(h) + 'h', pad(m) + 'm'].join(':');
    } else{
      return [pad(h) + 'h', pad(m) + 'm'].join(':');
    }

  }

  returnMilli(data){
    return this.returnTime(new Date(data).getTime());
  }

  opeSect(it){
    $('#collapse' + it).collapse('toggle');
    $('#itinerary' + it).collapse('toggle');
  }

  /**
   *  Check if the next flight is in the same day
   */
  otherday(value, other){
    return value !== other;
  }

  /**
   *  Set current active and visible tab
   */
  setTab(index){
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.tabs.length; i ++){
      this.tabs[i].active = false;
    }
    this.tabs[index].active = true;
    this.currentTab = this.tabs[index].name;
    if(this.currentTab === 'FARE') {
      this.getLowFareData();
    }
  }

  getLowFareData(){
    const dataList = {...fareData};
    let toCheck: any = [];
    let infoTarifsLow: any = {};
    this.tarifLowData = [];
    toCheck = JSON.parse(localStorage.getItem('selectedRates'));
    for (let j = 0; j < dataList.length; j ++){
      if (dataList[j].marketingCarrier.toUpperCase() === this.reservation.marketingCarrier.toUpperCase()){
        for(const selected of toCheck){
          infoTarifsLow = {};
          infoTarifsLow.supplier = dataList[j].supplier;
          for (const tar of dataList[j].tarifs){
            if (selected.toUpperCase() === tar.name.toUpperCase()){
              infoTarifsLow.tarif = tar;
              this.tarifLowData.push(infoTarifsLow);
            }
          }
        }
      }
    }
  }

  calculateWaiting(arrival, departure){
    const a = new Date(arrival).getTime();
    const b = new Date(departure).getTime();
    return this.returnTime(b - a);
  }

  toggleDialog(currentTraveler){
    this.currentTraveler = currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  closeEvent(event){
    delete this.currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  closeListEvent(event){
    delete this.currentTraveler;
    $('#listTravelerModal').modal('toggle');
  }

  removeTraveler(i){
    Object.keys(this.travelers[i]).forEach(k => {
      const type = this.travelers[i].type;
      delete this.travelers[i][k];
      this.travelers[i].type = type;
    });
  }

  loadTravelersList(currentTraveler){
    this.currentTraveler = currentTraveler;
    $('#listTravelerModal').modal('toggle');
    this.loadTravelers = true;
  }

  openEdit(i){
    $('#EditTravelerModal').modal('toggle');
    this.currentTraveler = i;
    this.editingTraveler = true;
  }

  closeEditEvent(event){
    this.editingTraveler = false;
    delete this.currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  travelerSet(event, i){
    const type = this.reservation.passengers[i].type;
    this.reservation.passengers[i] = event;
    this.reservation.passengers[i].type = type;
    for (let r = 0; r < this.required[i].length; r ++){
      if (this.required[i][r].Name === 'DateOfBirth'){
        if (this.reservation.passengers[i].birthday && this.reservation.passengers[i].birthday !== ''){
          this.required[i][r].value = moment(this.reservation.passengers[i].birthday).format('DD/MM/yyyy');
        } else{
          this.snackBar.open(this.translate.instant('PASSENGER_BIRTH_ERROR'), 'Error', {
            duration: 4000,
          });
        }
      }
    }
    console.log('this.reservation.passengers => ', this.reservation.passengers);
    console.log('this.required[i] => ', this.required[i]);
    this.editingTraveler = false;
    delete this.currentTraveler;
  }

  canBook(){
    // tslint:disable-next-line:max-line-length
    /*
    if (!this.appComponent.isB2B){
      return !!(this.checkPassengers() && this.checkFop() && this.seatOptionCheck());
    } else{
      return !!(this.checkPassengers() && this.seatOptionCheck());
    }
     */
    return !!(this.checkPassengers() && this.checkFop() && this.seatOptionCheck());
  }

  seatOptionCheck(){
    if (this.seatObbligatory){
      for (let i = 0; i < this.required.length; i ++){
        for (let j = 0; j < this.required[i].length; j ++){
          if (this.required[i][j].Name === 'SeatOptions'){
            if (this.required[i][j].value === '' || this.required[i][j].value === undefined){
              return false;
            }
            const allSeats = this.required[i][j].value.split(';');
            allSeats.pop();
            if (allSeats.includes('')){
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  checkPassengers(){
    let passed = false;
    // this.checkSeats();
    for (let pax = 0; pax < this.reservation.passengers.length; pax ++){
      if (!this.reservation.passengers[pax].sex || !this.reservation.passengers[pax].birthday){
        passed = false;
      } else{
        passed = true;
      }
    }
    return passed;
  }

  checkSeats(){
    // const params = res.requiredParameters;
  }

  onPaymentTabChanged(event) {
    delete this.reservation.creditcard;
  }

  checkFop(){
    // tslint:disable-next-line:max-line-length
    /*if (!this.newFop.card.cvc || !this.newFop.card.number || !this.newFop.card.expirationMonth || !this.newFop.card.expirationYear || !this.newFop.card.holder) {
     return false;
     } else {
     return true;
     }*/
    if (!this.appComponent.isSbt){
      if (!this.reservation.creditcard){
        return false;
      } else{
        return true;
      }
    } else{
      return true;
    }

  }

  includedCard(value){
    for (const card of this.acceptedCreditCard){
      card.CardType = card.CardType.toLowerCase().trim().replace(' ', '');
    }
    const find = this.acceptedCreditCard.findIndex(r => r.CardType === value);
    return find > - 1;
  }

  saveSeats(params){
    let travellersSeats = [];
    let counter = 0;
    let almostOneSeat = false;
    for (let i = 0; i < this.reservation.passengers.length; i ++){
      for (let j = 0; j < params.length; j ++){
        travellersSeats.push(params[j][i]);
      }
      this.reservation.passengers[counter].seat = [...travellersSeats];
      travellersSeats = [];
      counter ++;
    }
    console.log('parametri seat-> ', params);
    console.log('macello condiviso -> ', this.reservation);
    this.addToRequiredParams();
    for (let i = 0; i < params.length; i ++){
      for (let j = 0; j < params[i].length; j ++){
        if (params[i][j].name !== ''){
          almostOneSeat = true;
        }
      }
    }
    if (almostOneSeat){
      this.snackBar.open(this.translate.instant('SEAT_SAVED'), 'ok', {
        duration: 2000,
      });
    } else{
      this.snackBar.open(this.translate.instant('SEAT_DEFAULT_SAVED'), 'ok', {
        duration: 2000,
      });
    }
  }

  addToRequiredParams(){
    let selectedSeats;
    let values = '';
    let finded = false;
    for (let i = 0; i < this.reservation.passengers.length; i ++){
      finded = false;
      selectedSeats = [...this.reservation.passengers[i].seat];
      for (const v of selectedSeats){
        if (v.name !== '' && v.flight !== ''){
          values += v.flight + '-' + v.name + ';';
        } else{
          values += ';';
        }
      }
      for (const r of this.required[i]){
        if (r.Name === 'SeatOptions'){
          finded = true;
          r.value = values;
        }
      }
      if (!finded){
        this.required[i].push({
          Name: 'SeatOptions',
          PerPassenger: 'true',
          value: values,
        });
      }
      values = '';
    }
  }

  private openCallbackUrl(url : string){
    window.open(url, '_blank', 'top=100,left=100,height=400px,width=600px\'');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        title: this.appComponent.translate.instant('3DS_TITLE'),
        message: this.appComponent.translate.instant('3DS_MESSAGE'),
        cancelButton: false,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.router.navigate(['/flight/reservations/' + this.reservationId]);
      }
    });
  }
}

@Component({
  selector: 'app-dialog-lowcost',
  templateUrl: 'lowcostConfirm.html',
})
export class DialogLowCostConfirm{

  constructor(
    public dialogRef : MatDialogRef<DialogLowCostConfirm>,
    @Inject(MAT_DIALOG_DATA) public data : DialogData){ }

  onNoClick() : void{
    this.dialogRef.close();
  }
  isLufthansaGroup(){
    const lufthansaGroup = ['lufthansagroupndc'];
    if (lufthansaGroup.includes(this.data.reservation.Router.Supplier))
      return true;
    return false;
  }

}
