<mat-dialog-content>
  <div>
    <div class="" *ngIf="isSupported(data.mk)">
      <div class="text-center">
        <h5>{{'CARRIER'|translate}}: {{data.info[0].supplier.toUpperCase()}}</h5>
        <h5>{{'TARIFF'|translate}}: {{data.info[0].tarif.name.toUpperCase()}}</h5>
        <small>{{data.info[0].tarif.subName}}</small>
      </div>
      <div class="" *ngFor="let ele of data.info[0].tarif.details" >
        <hr class="hrBlue">
        <div class="row">
          <div class="" style="margin-left: 20px;">
            <mat-icon *ngIf="ele.icon">{{ele.icon}}</mat-icon>
          </div>
          <div class="" style="margin-left: 10px;">
            {{ele.name}}<br>{{ele.description}}
          </div>
        </div>
        <div *ngIf="ele.fixedMessage && data.info.length==1" class=" mt-4 text-danger">
          <a href="{{ele.link}}" target="_blank"> <u>{{ele.fixedMessage}}</u></a>
        </div>
      </div>
      <div *ngIf="data.info.length >1">
        <hr class="hrBlue" style="height: 10px;">
        <div class="text-center">
          <h5>{{'CARRIER'|translate}}: {{data.info[1].supplier.toUpperCase()}}</h5>
          <h5>{{'TARIFF'|translate}}: {{data.info[1].tarif.name.toUpperCase()}}</h5>
          <small>{{data.info[1].tarif.subName}}</small>
        </div>
        <div class="" *ngFor="let ele of data.info[1].tarif.details" >
          <hr class="hrBlue">
          <div class="row">
            <div class="" style="margin-left: 20px;">
              <mat-icon *ngIf="ele.icon">{{ele.icon}}</mat-icon>
            </div>
            <div class="" style="margin-left: 10px;">
              {{ele.name}}<br>{{ele.description}}
            </div>
          </div>
          <div *ngIf="ele.fixedMessage" class=" mt-4 text-danger">
            <a href="{{ele.link}}" target="_blank"> <u>{{ele.fixedMessage}}</u></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

