import { Component, Input, OnInit } from '@angular/core';
import { ModalGalleryRef, ModalGalleryConfig, Image, ModalGalleryService, ButtonsStrategy,
  ButtonType, Description, LoadingConfig, LoadingType } from '@ks89/angular-modal-gallery';

@Component({
  selector: 'app-new-gallery',
  templateUrl: './new-gallery.component.html',
  styleUrls: ['./new-gallery.component.scss']
})
export class NewGalleryComponent implements OnInit{
  @Input() allImages : any[] = [];
  numberOfImage: number = 0;
  images: Image[] = [];
  constructor(private modalGalleryService: ModalGalleryService) {
  }
  ngOnInit() {
    this.allImages.forEach((res:any, index: number)=> {
      this.images?.push( new Image(index,{img:res }))
    })
    if(this.allImages){
      this.numberOfImage = this.allImages.length;
    }
  }

  openModal(imagesArrayToUse: Image[]): void {
    if(imagesArrayToUse?.length === 0) {
      console.error('Cannot open modal-gallery because images array cannot be empty');
      return;
    }
    /*console.log(this.images)*/
    const libConfig={
      enableCloseOutside: false,
      currentImageConfig: {
        loadingConfig: {enable: true, type: LoadingType.CIRCULAR} as LoadingConfig,
        description: {imageText: '',numberSeparator: ' / '} as Description,
        navigateOnClick: false
      },
      buttonsConfig: {
        visible: true,
        strategy: ButtonsStrategy.CUSTOM,
        buttons:[{
          className: 'close-image',
          type: ButtonType.CLOSE,
          ariaLabel: 'custom close aria label',
          fontSize: '20px'
        }]
      },
      slideConfig: {
        infinite: false,
        sidePreviews: {
          show: false,
          size: {
            width: '100px',
            height: 'auto'
          }
        }
      },
      previewConfig: {
        visible: true,
        size: {
          width: '150px',
          height: 'auto'
        },
        mobileVisible: true
      },
      dotsConfig: {
        visible: false
      }
    }

    const dialogRef: ModalGalleryRef = this.modalGalleryService.open({
      id:1,
      images: imagesArrayToUse,
      currentImage: imagesArrayToUse[0]!,
      libConfig
    } as ModalGalleryConfig) as ModalGalleryRef;
  }
}
