import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CompaniesService } from '../../../../../services/profile/companies.service';
import * as $ from 'jquery';
import { MainComponent } from '../../../../mainmodule/main/main.component';
import { AppComponent } from '../../../../../app.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { HttpserviceService } from '../../../../../services/httpservice.service';
import { CompaniesList } from '../../../../../classes/companieslist';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cp-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListCompanyComponent implements OnInit {
  title: string;
  recipient: any;
  message: string;
  sending: boolean;
  @Input() page: string;
  displayedColumns = [];
  loading : boolean;
  companiesList : CompaniesList;
  isList : boolean;
  currentpage : number;
  maxpages : number;
  // setQuery
  query : any;
  limit : number;
  skip : number;
  sort : string;
  order : boolean;
  progress : boolean;
  text : string;
  selection = [];
  pagenumbers = [];

  constructor(
    private companiesService: CompaniesService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public appcomponent: AppComponent,
    public maincomponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpserviceService,
    private translate: TranslateService,
  ) {
    this.title = 'COMPANIES_LIST';
    this.loading = true;
    this.progress = false;
    this.isList = true;
  }

  ngOnInit(){

    if (this.maincomponent.loggedUser.role.superAdmin){
      this.displayedColumns = ['select', 'name', 'data', 'type', 'vat', 'phone', 'dk', 'activation'];
    } else{
      this.displayedColumns = ['name', 'data', 'type', 'vat', 'phone'];
    }
    if (this.page == 'dashboard'){
      this.displayedColumns = ['name', 'phone', 'email'];
    }
    if (this.page !== 'widget'){
      this.limit = 10;
    } else{
      this.limit = 5;
    }
    this.sort = 'createdAt';
    this.order = true;
    this.currentpage = 0;
    this.skip = (this.currentpage) * this.limit;

    this.query = {
      options: {
        viewAll: false,
      },
      sort: this.sort,
      limit: this.limit,
      skip: this.skip,
      order: this.order ? 'asc' : 'desc',
      text: '',
    };
    if (!this.page){
      this.route.queryParams.subscribe(params => {
        this.query.sort = params.sort || this.sort;
        this.query.limit = params.limit || this.limit;
        this.query.skip = params.skip || this.skip;
        this.query.order = params.order || 'asc';
        this.query.text = params.text || '';
        this.text = params.text || '';
        this.currentpage = this.query.skip / this.query.limit;
        this.getCompaniesList();
      });
    } else{
      this.navigate();
    }
  }

  returnType(value){
    if (value == 'A'){
      return 'AGENCY';
    } else{
      return 'B2B_COMPANY';
    }
  }

  getCompaniesList() : void{
    console.log('this.query => ', this.query);
    this.progress = true;
    this.companiesService.getCompaniesList(this.query).subscribe((res : CompaniesList) => {
      this.loading = false;

      this.companiesList = res;
      setTimeout(() => {
        this.progress = false;
      }, 500);
      if (!this.page){
        this.scrollTop();
      }
      console.log('this.usersList => ', this.companiesList);
      this.maxpages = Math.ceil((this.companiesList.count / this.limit));
      if (this.maxpages == 0){
        this.maxpages = 1;
      }
      for (let i = 0; i < this.companiesList.list.length; i ++){
        this.companiesList.list[i].image = this.httpService.setApiUrlImage() + 'static/company/' + this.companiesList.list[i]._id + '.png?time=' + new Date().getTime();
      }
      let numeration = [];
      for (let i = 0; i < this.maxpages; i ++){
        numeration.push(i);
      }
      this.pagenumbers = numeration;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  searchName(){
    this.query.text = this.text;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  sortResults(value){
    if (this.query.sort == value){
      this.order = !this.order;
      this.query.order = this.order ? 'asc' : 'desc';
    }
    this.query.sort = value;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  selectValue(element){
    let exist = false;
    for (let i = 0; i < this.selection.length; i ++){
      if (element._id == this.selection[i]._id){
        exist = true;
        this.selection.splice(i, 1);
      }
    }
    if (!exist){
      this.selection.push(element);
    }
    console.log('this.selection => ', this.selection);
  }

  isSelected(element){
    for (let i = 0; i < this.selection.length; i ++){
      if (element._id == this.selection[i]._id){
        return true;
      }
    }
  }

  isAllSelected(){
    return this.selection.length == this.companiesList.list.length;
  }

  someSelected(){
    return this.selection.length > 0 && this.selection.length < this.companiesList.list.length;
  }

  selectOnAll(){
    if (this.selection.length == this.companiesList.list.length && !this.findLogged()){
      this.selection = [];
    } else if (this.findLogged() && this.selection.length == this.companiesList.list.length - 1){
      this.selection = [];
    } else{
      this.selection = [];
      for (let i = 0; i < this.companiesList.list.length; i ++){
        if (typeof this.maincomponent.loggedUser.company !== 'string' && this.companiesList.list[i]._id !== this.maincomponent.loggedUser.company._id){
          this.selection.push(this.companiesList.list[i]);
        }
      }
    }
  }

  findLogged(){
    return this.companiesList.list.find(r => typeof this.maincomponent.loggedUser.company !== 'string' ? r._id === this.maincomponent.loggedUser.company._id : null);
  }

  next(){
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev(){
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value){
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  navigate(){
    this.selection = [];
    if (this.page){
      this.getCompaniesList();
    } else{
      this.router.navigate([], {
        queryParams: this.query,
      });
    }
  }

  scrollTop(){
    $('.tablelist').animate({ scrollTop: 0 }, 400);
    $('#content_main').animate({ scrollTop: 0 }, 400);
  }

  deleteSelected(){
    for (let i = 0; i < this.selection.length; i ++){
      this.selection[i].enable = false;
      this.sequenceDelete(this.selection[i]._id, this.selection[i]);
    }
    this.snackBar.open(this.translate.instant('COMPANY_UPDATED'), 'Ok', {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
    setTimeout(() => {
      this.getCompaniesList();
    }, 500);
  }

  sequenceDelete(id, data){
    this.companiesService.updateCompany(id, data).subscribe((res) => {

    }, error => {

    });
  }

  disableCompany(element){
    element.enable = false;
    this.sequenceDelete(element._id, element);
    this.getCompaniesList();
  }

  enableCompany(element){
    element.enable = true;
    this.sequenceDelete(element._id, element);
    this.getCompaniesList();
  }

  openMessage(element){
    this.message = '';
    this.recipient = element;
  }

  sendMessage(message){
    this.sending = true;
    setTimeout(() => {
      this.sending = false;
      $('#emailModalCompany').modal('hide');
      this.snackBar.open('Message send', 'ok', {
        duration: 2000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }, 1000);
  }

}
