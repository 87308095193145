<custom-title></custom-title>
<div class="padding20">

  <div *ngIf="!buildedYears" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <div class="mb-4 buttonrow" *ngIf="buildedYears">
    <button [color]="tab === currentTab ? 'primary' : undefined" *ngFor="let tab of availableTabs" (click)="setTab(tab)" mat-raised-button>
      {{availableSections[tab]|translate}}
    </button>
    <button [disabled]="loadingPdf" mat-raised-button (click)="downloadPDF()">{{'PRINT'|translate}}</button>
    <div *ngIf="loadingPdf" class="pt-3 mb-3">
      <mat-progress-bar mode="buffer"></mat-progress-bar>
    </div>
  </div>

  <div class="row" *ngIf="buildedYears">

    <div class="col-lg-6">
      <mat-card appearance="outlined" class="example-card mb-3" *ngIf="this.builded">
        <mat-card-header>
          <div mat-card-avatar class="last-year-image"></div>
          <mat-card-title>Report per mese</mat-card-title>
          <mat-card-subtitle>
            <mat-select [(ngModel)]="year" (ngModelChange)="changeYear()">
              <mat-option *ngFor="let year of years" [value]="year">Anno {{year}}</mat-option>
            </mat-select>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions"
            [xaxis]="chartOptions.xaxis"
            [stroke]="chartOptions.stroke"
            [theme]="chartOptions.theme"
          ></apx-chart>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-6">
      <mat-card appearance="outlined" class="example-card mb-3" *ngIf="this.builded">
        <mat-card-header>
          <div mat-card-avatar class="last-year-image"></div>
          <mat-card-title>Report per mese</mat-card-title>
          <mat-card-subtitle>
            Totale emissioni {{year}}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <apx-chart
            [series]="chartOptionsMoney.series"
            [chart]="chartOptionsMoney.chart"
            [dataLabels]="chartOptionsMoney.dataLabels"
            [plotOptions]="chartOptionsMoney.plotOptions"
            [xaxis]="chartOptionsMoney.xaxis"
            [stroke]="chartOptionsMoney.stroke"
            [theme]="chartOptionsMoney.theme"
          ></apx-chart>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-12">
      <mat-card appearance="outlined" class="example-card mb-3" *ngIf="this.builded">
        <mat-card-content>
          <div class="text-right mb-2">
            <mat-checkbox color="primary" class="example-margin" [(ngModel)]="showPnr">Prenotazioni</mat-checkbox>
            <mat-checkbox color="primary" class="example-margin" [(ngModel)]="showSegments">Segmenti</mat-checkbox>
            <mat-checkbox color="primary" class="example-margin" [(ngModel)]="showIncentives">Incentivi</mat-checkbox>
            <mat-checkbox color="primary" class="example-margin" [(ngModel)]="showMargin">Margini</mat-checkbox>
            <mat-checkbox color="primary" class="example-margin" [(ngModel)]="showTotal">Totale emissioni</mat-checkbox>
          </div>

          <div class="table-responsive" id="printpdf">
            <table class="table table-bordered table-hover table-sm">
              <tr>
                <th>Mese</th>
                <th *ngIf="showPnr">Prenotazioni</th>
                <th *ngIf="showSegments">Segmenti</th>
                <th *ngIf="showIncentives">Incentivi</th>
                <th *ngIf="showMargin">Margini negoziate</th>
                <th *ngIf="showTotal">Totele emissioni</th>
              </tr>
              <tr *ngFor="let month of currentYears; let i = index;">
                <td>{{months[month._id.month-1]}}</td>
                <td *ngIf="showPnr">
                  <span class="material-icons text-info-dark" *ngIf="month.pnr == 0 || i==0">
                    trending_flat
                  </span>
                  <span class="material-icons text-danger" *ngIf="month.pnr > 0 && i!=0 && currentYears[i-1].pnr > month.pnr">
                    trending_down
                  </span>
                  <span class="material-icons text-success" *ngIf="month.pnr > 0 && i!=0 && currentYears[i-1].pnr < month.pnr">
                    trending_up
                  </span>
                  <span class="material-icons text-info" *ngIf="month.pnr > 0 && i!=0 && currentYears[i-1].pnr == month.pnr">
                    trending_flat
                  </span>
                  {{month.pnr|number}}
                </td>
                <td *ngIf="showSegments">
                  <span class="material-icons text-info" *ngIf="month.segments == 0 || i==0">
                    trending_flat
                  </span>
                  <span class="material-icons text-danger" *ngIf="month.segments > 0 && i!=0 && currentYears[i-1].segments > month.segments">
                    trending_down
                  </span>
                  <span class="material-icons text-success" *ngIf="month.segments > 0 && i!=0 && currentYears[i-1].segments < month.segments">
                    trending_up
                  </span>
                  <span class="material-icons text-info" *ngIf="month.segments > 0 && i!=0 && currentYears[i-1].segments == month.segments">
                    trending_flat
                  </span>
                  {{month.segments|number}}
                </td>
                <td *ngIf="showIncentives">
                  <span class="material-icons text-info" *ngIf="month.fees == 0 || i==0">
                    trending_flat
                  </span>
                  <span class="material-icons text-danger" *ngIf="month.fees > 0 && i!=0 && currentYears[i-1].fees > month.fees">
                    trending_down
                  </span>
                  <span class="material-icons text-success" *ngIf="month.fees > 0 && i!=0 && currentYears[i-1].fees < month.fees">
                    trending_up
                  </span>
                  <span class="material-icons text-info" *ngIf="month.fees > 0 && i!=0 && currentYears[i-1].fees == month.fees">
                    trending_flat
                  </span>
                  {{month.fees||0|number:'.2-2'}}€
                </td>
                <td *ngIf="showMargin">
                  <span class="material-icons text-info" *ngIf="month.margins == 0 || i==0">
                    trending_flat
                  </span>
                  <span class="material-icons text-danger" *ngIf="month.margins > 0 && i!=0 && currentYears[i-1].margins > month.margins">
                    trending_down
                  </span>
                  <span class="material-icons text-success" *ngIf="month.margins > 0 && i!=0 && currentYears[i-1].margins < month.margins">
                    trending_up
                  </span>
                  <span class="material-icons text-info" *ngIf="month.margins > 0 && i!=0 && currentYears[i-1].margins == month.margins">
                    trending_flat
                  </span>
                  {{month.margins||0|number:'.2-2'}}€
                </td>
                <td *ngIf="showTotal">
                  <span class="material-icons text-info" *ngIf="month.amount == 0 || i==0">
                    trending_flat
                  </span>
                  <span class="material-icons text-danger" *ngIf="month.amount > 0 && i!=0 && currentYears[i-1].amount > month.amount">
                    trending_down
                  </span>
                  <span class="material-icons text-success" *ngIf="month.amount > 0 && i!=0 && currentYears[i-1].amount < month.amount">
                    trending_up
                  </span>
                  <span class="material-icons text-info" *ngIf="month.amount > 0 && i!=0 && currentYears[i-1].amount == month.amount">
                    trending_flat
                  </span>
                  {{month.amount||0|number:'.2-2'}}€
                </td>
              </tr>
              <tr>
                <td>Totale:</td>
                <td *ngIf="showPnr">{{returnCount('pnr')|number}}</td>
                <td *ngIf="showSegments">{{returnCount('segments')|number}}</td>
                <td *ngIf="showIncentives">{{returnCount('fees')|number:'.2-2'}}€</td>
                <td *ngIf="showMargin">{{returnCount('margins')|number:'.2-2'}}€</td>
                <td *ngIf="showTotal">{{returnCount('amount')|number:'.2-2'}}€</td>
              </tr>
            </table>
          </div>
          <div class="legendRow text-right">
            <span class="material-icons text-danger">
              trending_down
            </span>
            <span class="mr-3">Trend negativo</span>
            <span class="material-icons text-success">
              trending_up
            </span>
            <span class="mr-3">Trend positivo</span>
            <span class="material-icons text-info">
              trending_flat
            </span>
            <span>Trend invariato</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-6">
      <mat-card appearance="outlined" class="example-card mb-3" *ngIf="this.buildedYears">
        <mat-card-header>
          <div mat-card-avatar class="all-year-image"></div>
          <mat-card-title>Andamento negli anni</mat-card-title>
          <mat-card-subtitle>
            Prenotazioni e segmenti
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <apx-chart
            [series]="chartOptionsAll.series"
            [chart]="chartOptionsAll.chart"
            [dataLabels]="chartOptionsAll.dataLabels"
            [plotOptions]="chartOptionsAll.plotOptions"
            [xaxis]="chartOptionsAll.xaxis"
            [stroke]="chartOptionsAll.stroke"
            [theme]="chartOptionsAll.theme"
          ></apx-chart>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-6">
      <mat-card appearance="outlined" class="example-card mb-3" *ngIf="this.buildedYears">
        <mat-card-header>
          <div mat-card-avatar class="all-year-image"></div>
          <mat-card-title>Andamento negli anni</mat-card-title>
          <mat-card-subtitle>
            Totale emissioni
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <apx-chart
            [series]="chartOptionsAllMoney.series"
            [chart]="chartOptionsAllMoney.chart"
            [dataLabels]="chartOptionsAllMoney.dataLabels"
            [plotOptions]="chartOptionsAllMoney.plotOptions"
            [xaxis]="chartOptionsAllMoney.xaxis"
            [stroke]="chartOptionsAllMoney.stroke"
            [theme]="chartOptionsAllMoney.theme"
          ></apx-chart>
        </mat-card-content>
      </mat-card>
    </div>

  </div>
</div>

