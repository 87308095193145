import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MainComponent } from 'src/app/modules/mainmodule/main/main.component';
import { CustomAuthService } from 'src/app/services/customAuth/custom-auth.service';
import { HttpserviceService } from 'src/app/services/httpservice.service';
import { UsersService } from 'src/app/services/profile/users.service';
import * as CryptoJS from 'crypto-js';
import { AuthenticationService } from 'src/app/services/authentication.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  password: string = '';
  confirmPassword: string = '';
  hidePassword: boolean = true;
  hideConfirmpassword: boolean = true;
  error: boolean = false;
  strength: number = 5;
  constructor(
    private authService: AuthenticationService,
    public maincomponent: MainComponent,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    private httpservice: HttpserviceService,
    // private titleService: Title
    public customAuth: CustomAuthService,
  ) { }

  ngOnInit(): void {
  }

  samePassword(){
    return this.password === this.confirmPassword;
  }

  update(){
    const oldPasswd = JSON.parse(localStorage.getItem('pwdtmp'));
    const user = {
      email: this.maincomponent.loggedUser.email,
      newPassword: String(CryptoJS.MD5(this.password)),
      changedPassword: new Date(),
    };
    if(user.newPassword == oldPasswd){
      this.error = true;
      // alert('cant use the same password');
    } else {
      this.error = false;
      
      this.usersService.updateUser(this.maincomponent.loggedUser._id, user).subscribe((res) => {
        this.snackBar.open(this.translate.instant('USER_PASS_UPDATED'), 'Ok', {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
        });
        delete this.maincomponent.user;
        delete this.maincomponent.loggedUser;
        this.authService.logout();
      }, error => {
        console.error('Error => ', error);
      });
      
    }
    
  }

  checkStrength(event){
    this.password = event;
    this.strength = 5;
    if (this.password.match(/[a-z]+/)) {
      this.strength -= 1;
    }
    if (this.password.match(/[A-Z]+/)) {
      this.strength -= 1;
    }
    if (this.password.match(/[0-9]+/)) {
      this.strength -= 1;
    }
    if (this.password.match(/[$@#&!]+/)) {
      this.strength -= 1;
    }
    if (this.password.length >= 8) {
      this.strength -= 1;
    }
    console.log(this.strength);
    return this.strength;
  }
}
