<div class="p-2 m-2">
  <mat-form-field class="full-width" appearance="outline">
    <input matInput placeholder="{{'USERNAME'|translate}}" aria-label="Carrier" (ngModelChange)="searchUser($event)" [(ngModel)]="text" [matAutocomplete]="users">
    <button type="button" class="iconButtons" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #users="matAutocomplete" (optionSelected)="getUser($event.option.value)" (closed)="selectFirst()">
      <mat-option *ngFor="let user of userList" [value]="user">
        <span>{{user.name}} {{user.surname}}</span>
        <span class="material-icons"> arrow_right_alt </span>
        <small>{{user.company.name}}</small>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div style="width: 100%; display: flex; justify-content: flex-end">
    <button (click)="saveDefaultUser()" [disabled]="!userToSet" mat-raised-button color="primary">
      Save
    </button>
  </div>
</div>


