
<div class="hotelDetailInfo">
  <div class="row mb-3">
    <div class="align-self-center singleHotelSlide col-md-6 mb-3">
      <div *ngIf="hotel.info" class="slide" data-interval="false" data-ride="" id="">

        <div *ngIf="allImages.length === 0" class="carousel-inner">
          <div class="carousel-item active">
            <img alt="First slide" class="d-block" src="/assets/img/placeholder-image.png">
            <div class="carousel-caption d-none d-md-block alert-dark">
              {{'NO_IMAGES_AVAILABLE'|translate}}
            </div>
          </div>
        </div>

        <div *ngIf="allImages && allImages.length > 0">
          <!-- <gallery gallerize [items]="images"></gallery> -->
          <div class="singleSlideImgFirst mb-2" (click)="showDialogGallery(0)" [ngStyle]="{backgroundImage:'url('+allImages[0]+')'}"></div>
          <div class="thumbsScrollerContainer">
            <div class="leftArr" (click)="getWidthScrollL()">
              <span class="material-icons">
                arrow_back_ios
              </span>
            </div>
            <div class="thumbsContainer">
              <div class="withScrollslide">
                <div class="imageFloat mb-2" id="imageThumb{{i}}" *ngFor="let img of allImages; let i = index">
                  <div class="singleSlideImg" (click)="showDialogGallery(i)" [ngStyle]="{backgroundImage:'url('+img+')'}"></div>
                </div>
              </div>
            </div>
            <div class="rightArr" (click)="getWidthScrollR()">
              <span class="material-icons">
                arrow_forward_ios
              </span>
            </div>
          </div>




        </div>

      </div>

    </div>
    <div class="align-self-center col-md-6 mb-3">
      <div id="hotelMap">

      </div>


    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="mb-3">{{'INFOS'|translate}}</div>
      <div>
        <div class="hotelName">{{hotel.name}}</div>
        <hr/>
        <div class="hotelAddress">{{hotel.address.city}}, {{hotel.address.zip}}, {{hotel.address.line}}</div>
        <div *ngIf="distance" class="distance">{{checkValue(distance)}} {{'FROM_SEARCH_POINT'|translate}}</div>
        <hr/>
        <div class="mb-2">
          <div *ngFor="let contact of hotel.contactInfos">
            <div *ngFor="let phone of contact.phoneNumbers">
              {{phone.type}}: {{phone.number}}
            </div>
          </div>
        </div>
        <div *ngIf="hotel.info" class="hotelAddress mb-2">
              <span *ngFor="let category of hotel.info.categories; let l = last">
                {{category}} <span *ngIf="!l"> - </span>
              </span>
        </div>
      </div>
      <hr/>
      <div class="mb-2">{{'SERVICES'|translate}}</div>
      <div>
        <div class="hotelSection mb-2">
          <div class="hotelServices padding10">
              <span *ngFor="let service of hotel.info.services; let l = last" class="service">
                <span class="material-icons">
                  done
                </span>
                {{service}}
              </span>
          </div>
        </div>
      </div>
      <hr/>
      <div class="mb-3">{{'GENERAL_INFORMATIONS'|translate}}</div>
      <div>
        <div *ngFor="let description of hotel.info.descriptions; let l = last" class="hotelGenInfos">
          <div [innerHTML]="description.text"></div>
          <hr *ngIf="!l"/>
        </div>
      </div>
      <!--
      <mat-tab-group mat-align-tabs="start">
        <mat-tab label="{{'INFOS'|translate}}">

          <div class="padding10">
            <div class="hotelName">{{hotel.name}}</div>
            <hr/>
            <div class="hotelAddress">{{hotel.address.city}}, {{hotel.address.zip}}, {{hotel.address.line}}</div>
            <div *ngIf="distance" class="distance">{{checkValue(distance)}} {{'FROM_SEARCH_POINT'|translate}}</div>
            <hr/>
            <div class="mb-2">
              <div *ngFor="let contact of hotel.contactInfos">
                <div *ngFor="let phone of contact.phoneNumbers">
                  {{phone.type}}: {{phone.number}}
                </div>
              </div>
            </div>
            <div *ngIf="hotel.info" class="hotelAddress mb-2">
              <span *ngFor="let category of hotel.info.categories; let l = last">
                {{category}} <span *ngIf="!l"> - </span>
              </span>
            </div>
          </div>

        </mat-tab>
        <mat-tab label="{{'SERVICES'|translate}}">
          <div class="hotelSection mb-2">
            <div class="hotelServices padding10">
              <span *ngFor="let service of hotel.info.services; let l = last" class="service">
                <span class="material-icons">
                  done
                </span>
                {{service}}
              </span>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{'GENERAL_INFORMATIONS'|translate}}">

          <div class="hotelSection padding10">

            <div *ngFor="let description of hotel.info.descriptions; let l = last" class="hotelGenInfos">
              <div [innerHTML]="description.text"></div>
              <hr *ngIf="!l"/>
            </div>
          </div>

        </mat-tab>

      </mat-tab-group>
      -->

    </div>
  </div>

</div>

<div aria-hidden="true" aria-labelledby="galleryModal" data-keyboard="false" data-backdrop="static" class="modal fade" id="galleryModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content backgTrasp">

      <div class="modal-body ">
        <div class="text-right">
          <span class="material-icons text-white mb-2 pointerC" (click)="hideDialogGallery()">
            close
          </span>
        </div>
        <div *ngIf="hotel.info" class="carousel slide" data-interval="false" data-ride="carouselG" id="carouselG">
          <div *ngIf="allImages.length>0" class="carousel-inner">
            <div *ngFor="let image of allImages; let im = index" [ngClass]="{'active':im === activeImage}" class="carousel-item">
              <!-- <div [ngStyle]="{backgroundImage:'url('+image+')'}" class="slideImg"></div> -->
              <img class="imageBig" src="{{image}}" />
              <div class="carousel-caption d-none d-md-block">
                {{im + 1}}/{{allImages.length}}
              </div>
            </div>
          </div>
          <a *ngIf="allImages.length>1" class="carousel-control-prev" data-slide="prev" href="#carouselG" role="button" (click)="prevSlide()">
            <span aria-hidden="true" class="carousel-control-prev-icon"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a *ngIf="allImages.length>1" class="carousel-control-next" data-slide="next" href="#carouselG" role="button" (click)="nextSlide()">
            <span aria-hidden="true" class="carousel-control-next-icon"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>

        <div class="thumbsScrollerContainer">
          <div class="leftArrCa" (click)="getWidthScrollLCa()">
              <span class="material-icons">
                arrow_back_ios
              </span>
          </div>
          <div class="thumbsContainerCa">
            <div *ngIf="hotel.info" class="withScrollslide">
              <div class="imageFloat mb-2" id="imageThumbCa{{i}}" *ngFor="let img of allImages; let i = index">
                <div class="singleSlideImg" [ngClass]="{'activeNav':i === activeImage}" data-target="#carouselG" attr.data-slide-to="{{i}}" [ngStyle]="{backgroundImage:'url('+img+')'}"></div>
              </div>
            </div>
          </div>
          <div class="rightArrCa" (click)="getWidthScrollRCa()">
              <span class="material-icons">
                arrow_forward_ios
              </span>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>

