<div mat-dialog-title>
  {{'FEE_ASSOCIATION'|translate}}
</div>
<mat-dialog-content class="mat-typography">

  <div class="mb-3">{{'feename'|translate}}: {{currentFee.name}}</div>

  <div class="row">
    <div class="col-6 mb-2">
      <div *ngIf="filtering.length > 0">
        <hr/>
        <div class="mb-2 text-success">{{'ASSOCIATION_COMPANIES'|translate}}</div>
        <div class="mb-2" *ngFor="let cp of filtering">
          {{cp.name}}
          <span (click)="removeCpFromFilter(cp)" class="ms-2 material-icons pointer vertical smallIcon text-danger">
            close
          </span>
        </div>
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="mb-2 text-info">{{'ASSOCIATED_COMPANIES'|translate}}</div>
      <div class="mb-2" *ngIf="cpWithNames.length == 0">{{'NO_ASSOCIATED_COMPANIES'|translate}}</div>
      <div *ngIf="cpWithNames.length > 0">
        <div class="mb-2" *ngFor="let cp of cpWithNames">
          {{cp.name}}
          <span (click)="removeCp(cp)" class="ms-2 material-icons pointer vertical smallIcon text-danger">
            delete_outline
          </span>
        </div>
      </div>
      <div *ngIf="unlink.length > 0">
        <hr/>
        <div class="mb-2 text-danger">{{'UNLINK_COMPANIES'|translate}}</div>
        <div class="mb-2" *ngFor="let cp of unlink">
          {{cp.name}}
          <span (click)="removeCpFromDelete(cp)" class="ms-2 material-icons pointer vertical smallIcon text-danger">
            close
          </span>
        </div>
      </div>

    </div>
  </div>
  <hr/>
  <div class="row" *ngIf="data.profile.company.daughters.length > 0 || data.profile.role.superAdmin">
    <div class="col-12">
      <div class="mb-2">
        {{'ADD_COMPANY'|translate}}
      </div>

      <div class="mb-2">
        <mat-form-field class="full-width" appearance="outline">
          <input matInput placeholder="{{'SEARCH_BY_COMPANY'|translate}}" aria-label="Departure"
                 (ngModelChange)="searchCompany($event)" [(ngModel)]="companytext" [matAutocomplete]="cpcomplete">
          <button type="button" *ngIf="companytext" matSuffix mat-icon-button aria-label="Clear" (click)="clearFrom()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete #cpcomplete="matAutocomplete" (closed)="selectFirst()">
            <mat-option *ngFor="let comp of companiesList" [value]="comp.name" (click)="selectComp(comp)">
              <span>{{comp.name}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'CLOSE'|translate}}</button>
  <button mat-button (click)="save()" *ngIf="!saving && !unlinking">{{'SAVE'|translate}}</button>
</mat-dialog-actions>
