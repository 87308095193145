import { Component, OnDestroy, OnInit } from '@angular/core';
import { Destination, HotelForm, HotelGuest, HotelOptions, Occupancy, } from '../../../classes/hotel';
import { CompaniesService } from '../../../../../services/profile/companies.service';
import { MainComponent } from '../../../../mainmodule/main/main.component';
import { CorporateCode } from '../../../../companies/components/companiescompo/company/corporate-codes/corporate-codes.component';
import { HotelService } from '../../../hotel.service';

/**
 *  Component containing hotel search form component and results component
 */
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchHotelComponent implements OnInit, OnDestroy{
  filters: any;
  /**
   *  Local reference of Occupancy
   */
  singleOccupancy : Occupancy;

  /**
   *  Local reference of HotelForm
   */
  hotelForm : HotelForm;
  /**
   *  Local reference of Hoterlform options
   */
  options : HotelOptions;
  /**
   *  today date used for check-in initial date
   */
  today : Date;

  /**
   *  tomorrow date used for check-out initial date
   */
  tomorrow : Date;
  /**
   * local variable for hotel results
   */
  hotelRes : any;
  /**
   * local variable for hotel results v2
   */
  hotelResV2 : any;

  hotelDb : any;

  sessions : any[];
  corporates : CorporateCode[];
  formLoaded = false;
  favorites: any;
  loadedFav = false;
  /**
   *  constructor set initial search form that will passed to search form component
   */
  constructor(
    private api : CompaniesService,
    public mainComponent : MainComponent,
    private hotelService : HotelService,
  ){
    this.sessions = [];
    this.today = new Date();
    this.tomorrow = new Date();
    this.tomorrow = new Date(this.tomorrow.setDate(this.tomorrow.getDate() + 1));
    this.createHotelForm();
  }

  createHotelForm(){
    // this.formLoaded = false;
    this.hotelForm = new HotelForm();
    this.hotelForm.nameSearch = false;
    this.hotelForm.hotels = [];
    this.hotelForm.iata = '';
    this.hotelForm.chain = '';
    this.hotelForm.strict = false;
    this.hotelForm.sort = 'RA';
    this.hotelForm.cache = 'Live'; // Live / LessRecent / VeryRecent
    this.hotelForm.checkIn = this.today;
    this.hotelForm.checkOut = this.tomorrow;
    this.hotelForm.occupancy = [];
    this.hotelForm.options = new HotelOptions();
    this.hotelForm.options.stars = [0, 1, 2, 3, 4, 5];
    this.hotelForm.destination = new Destination();
    this.hotelForm.destination.radius = 1;
    this.hotelForm.ratePlans = [];
    this.singleOccupancy = new Occupancy();
    this.singleOccupancy.bedTypes = [];
    this.singleOccupancy.roomCategory = [];
    this.singleOccupancy.guests = [];
    const occupancy = JSON.parse(JSON.stringify(this.singleOccupancy));
    occupancy.guests.push(new HotelGuest(10,1));
    this.hotelForm.occupancy.push(occupancy);
    delete this.hotelRes;
    delete this.hotelDb;

    if (localStorage.getItem('sarceDossier')){
      const sarce = JSON.parse(localStorage.getItem('sarceDossier'));
      this.hotelForm.checkIn = sarce.start;
      this.hotelForm.checkOut = sarce.end;
    }
    /*
    if (localStorage.getItem('hotels2')){
      this.hotelRes = JSON.parse(localStorage.getItem('hotels2'));
    }
    if(localStorage.getItem('hotels2Filters')) {
      this.filters = JSON.parse(localStorage.getItem('hotels2Filters'));
    }
    if(localStorage.getItem('HotelForm2')) {
      this.hotelForm = JSON.parse(localStorage.getItem('HotelForm2'));
    }
    */
    this.formLoaded = true;
  }

  getFavorites(){
    this.loadedFav = false;
    // const query = '?skip=' + 0 + '&limit=' + 1000;
    this.hotelService.getFavorites_V3(1000, 0).subscribe((res:any) => {
      this.favorites = res.items;
      this.loadedFav = true;
      console.log('this.favorites => ', this.favorites);
    }, error => {
      this.loadedFav = true;
      console.error('Error => ', error);
    });
  }

  /**
   *  Implement nothing for now
   */
  ngOnInit() : void{
    if (localStorage.getItem('searchHotelForm')){
      this.hotelForm = JSON.parse(localStorage.getItem('searchHotelForm'));
    }
    this.getFavorites();
  }

  resultChange(event){
    delete this.hotelRes;
    this.hotelRes = event;
    console.log('event this.hotelRes ', event);
  }

  filtersChange(event){
    delete this.filters;
    this.filters = event;
    console.log('event this.hotelRes ', event);
  }

  resultChangeV2(event){
    delete this.hotelDb;
    this.hotelDb = event;
  }

  ngOnDestroy() : void{
    localStorage.removeItem('searchHotelRes');
    localStorage.removeItem('searchHotelForm');
  }
}
