import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {HttpserviceService} from '../httpservice.service';
import {Observable} from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {TravelersList} from '../../classes/travelers/travelers';
import {NewTraveller} from '../../classes/travelers/newTraveller';
import {Traveler} from '../../classes/travelers/singletraveler';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root'
})
export class TravelersService extends HttpserviceService {

  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
  }

  getSuggestedTraveler(data): Observable<any> {
    const travelerSuggestedUrl = this.apiUrl + 'addressbook/list?text=' + data + '&fromPnr=true';
    return this.http
      .get<any>(travelerSuggestedUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getTravelersList(data): Observable<TravelersList> {
    // tslint:disable-next-line:max-line-length
    const travelersListUrl = this.apiUrl + 'addressbook/list' + '?sort=' + data.sort + '&limit=' + data.limit + '&skip=' + data.skip + '&order=' + data.order + '&text=' + data.text;
    return this.http
      .get<TravelersList>(travelersListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  addTraveler(data): Observable<NewTraveller> {
    const addTravelerUrl = this.apiUrl + 'addressbook/create';
    return this.http
      .put<NewTraveller>(addTravelerUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  editTraveler(data): Observable<Traveler> {
    const editTravelerUrl = this.apiUrl + 'addressbook/' + data._id;
    return this.http
      .post<Traveler>(editTravelerUrl, data, this.setHttpOptions())
      .pipe(
        retry(2),
        catchError(err => this.handleError(err))
      );
  }

  deleteTraveler(id): Observable<Traveler> {
    const editTravelerUrl = this.apiUrl + 'addressbook/' + id;
    return this.http
      .delete<Traveler>(editTravelerUrl, this.setHttpOptions())
      .pipe(
        retry(2),
        catchError(err => this.handleError(err))
      );
  }

  getTraveler(data): Observable<Traveler> {
    const travelerUrl = this.apiUrl + 'addressbook/' + data;
    return this.http
      .get<Traveler>(travelerUrl, this.setHttpOptions())
      .pipe(
        retry(2),
        catchError(err => this.handleError(err))
      );
  }

  getTravelerCid(data): Observable<Traveler> {
    const travelerUrl = this.apiUrl + 'addressbook/key/' + data;
    return this.http
      .get<Traveler>(travelerUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getFrequentFlyers(id): Observable<any> {
    const frequentFlyersUrl = this.apiUrl + 'addressbook/' + id + '/frequentflyer';
    return this.http
      .get<any>(frequentFlyersUrl, this.setHttpOptions())
      .pipe(
        retry(2),
        catchError(err => this.handleError(err))
      );
  }

  saveFrequentFlyers(id, body): Observable<any> {
    const frequentFlyersUrl = this.apiUrl + 'addressbook/' + id + '/frequentflyer';
    return this.http
      .put<any>(frequentFlyersUrl, body, this.setHttpOptions())
      .pipe(
        retry(2),
        catchError(err => this.handleError(err))
      );
  }

  editFrequentFlyers(id, body): Observable<any> {
    const frequentFlyersUrl = this.apiUrl + 'addressbook/' + id + '/frequentflyer/' + body._id;
    return this.http
      .post<any>(frequentFlyersUrl, body, this.setHttpOptions())
      .pipe(
        retry(2),
        catchError(err => this.handleError(err))
      );
  }

  deleteFrequentFlyers(id, fr): Observable<any> {
    const frequentFlyersUrl = this.apiUrl + 'addressbook/' + id + '/frequentflyer/' + fr;
    return this.http
      .delete<any>(frequentFlyersUrl, this.setHttpOptions())
      .pipe(
        retry(2),
        catchError(err => this.handleError(err))
      );
  }
}
