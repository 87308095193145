import { Injectable } from '@angular/core';
import { HttpserviceService } from '../httpservice.service';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersList } from '../../classes/userlist';

import { Settings } from '../../classes/profiles/settings';
import { retry, catchError } from 'rxjs/operators';
import {Roles} from '../../classes/profiles/role';
import {User} from '../../classes/profiles/user';
import {LoginResponse} from '../../classes/loggedProfile';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends HttpserviceService {
  profileUrl: string;
  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
    this.profileUrl = this.apiUrl;
  }

  getUserSettings(id): Observable<Settings> {
    const userSettingsUrl = this.profileUrl + 'profile/user/' + id + '/setting';
    return this.http
      .get<Settings>(userSettingsUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }

  updateUserSettings(id, settings): Observable<Settings> {
    if (settings.theme && settings.theme.css) {
      settings.theme = settings.theme.name;
    }
    const userUpdateSettingsUrl = this.profileUrl + 'profile/user/' + id + '/setting';
    return this.http
      .post<Settings>(userUpdateSettingsUrl, settings, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, true, 'AN_ERROR_OCCURRED')),
      );
  }

  getUsersList(data): Observable<UsersList> {
    // tslint:disable-next-line:max-line-length
    const usersListUrl = this.profileUrl + 'profile/user/list' + '?sort=' + data.sort + '&limit=' + data.limit + '&skip=' + data.skip + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<UsersList>(usersListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }

  getUsersListCp(data, id): Observable<UsersList> {
    // tslint:disable-next-line:max-line-length
    const usersListUrl = this.profileUrl + 'profile/company/' + id + '/users' + '?sort=' + data.sort + '&limit=' + data.limit + '&skip=' + data.skip + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<UsersList>(usersListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }

  deleteUser(id): Observable<User> {
    const delUserUrl = this.profileUrl + 'profile/user/' + id;
    return this.http
      .delete<User>(delUserUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, true, 'AN_ERROR_OCCURRED'))
      );
  }

  updateUser(id, data): Observable<User> {
    const updateUserUrl = this.profileUrl + 'profile/user/' + id;
    return this.http
      .post<User>(updateUserUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, true, 'AN_ERROR_OCCURRED'))
      );
  }

  getUser(id): Observable<User> {
    const usersUrl = this.profileUrl + 'profile/user/' + id;
    return this.http
      .get<User>(usersUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  uploadImage(id, data) {
    const uploadUrl = this.oldApiUrl + 'static/user/' + id;
    return this.http
      .post(uploadUrl, data, this.setHttpOptionsImage())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getUserRole(id): Observable<Roles> {
    const usersRoleUrl = this.profileUrl + 'profile/user/' + id + '/role';
    return this.http
      .get<Roles>(usersRoleUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
  editUserRole(data, id): Observable<Roles> {
    const editUsersRoleUrl = this.profileUrl + 'profile/user/' + id + '/role';
    return this.http
      .post<Roles>(editUsersRoleUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getUsersComplete(value): Observable<any> {
    const usersCompleteUrl = this.profileUrl + 'profile/autocomplete/users?q=' + value;
    return this.http
      .get<any>(usersCompleteUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  loginAs(data): Observable<any> {
    const loginAsUrl = this.profileUrl + 'profile/superLogin';
    return this.http
      .post<any>(loginAsUrl, data, this.setHttpOptions())
      .pipe(
        retry(2),
        catchError(err => this.handleError(err))
      );
  }
}
