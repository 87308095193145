import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

import {UsersService} from '../../../services/profile/users.service';
import {UsersGroupBulkService} from '../services/users-group-bulk.service';

@Component({
  selector: 'app-selected-users-group',
  templateUrl: './selected-users-group.component.html',
  styleUrls: ['./selected-users-group.component.scss']
})
export class SelectedUsersGroupComponent implements OnInit {

  @Input() selection: SelectionModel<any>;
  @Output() removeSelection = new EventEmitter();
  @Output() roleUpdated = new EventEmitter();

  showAll = false;
  rolesList = [];

  constructor(
    private usersService: UsersService,
    private usersGroupBulkService: UsersGroupBulkService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.usersGroupBulkService.rolesList().pipe(take(1)).subscribe(res => {
      this.rolesList = res.data;
    });
  }

  remove(user): void {
    this.removeSelection.emit(user);
  }

  updateUserRoles(select): void {
    const roleId = select.value;

    if (!roleId) {
      this.snackBar.open('Please select Role', 'Close');
    } else {
      const users = this.selection.selected.map(user => user._id);

      this.usersGroupBulkService.assignGroup({ user: users, group: roleId }).pipe(take(1)).subscribe((res) => {
          this.snackBar.open('Users Assigned Successfully', 'Close');
          this.roleUpdated.emit(true);
          select.value = null;
        },
        error => {
          this.snackBar.open(error.message, 'Close');
        }
      );
    }
  }


}
