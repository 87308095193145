import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeComponent } from './theme/theme.component';
import {AutocompleteComponent} from './theme/theme-components/autocomplete/autocomplete.component';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material/material.module';



@NgModule({
  declarations: [ThemeComponent, AutocompleteComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
  ]
})
export class ThemeComponentsModule { }
