import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import * as $ from 'jquery';
import { ApiService } from '../../../../../../services/api.service';
import { MainComponent } from '../../../../../mainmodule/main/main.component';
import { CorporateCode } from '../../../../../companies/components/companiescompo/company/corporate-codes/corporate-codes.component';
import { CompaniesService } from '../../../../../../services/profile/companies.service';
import { Subject } from 'rxjs';
import { HotelService } from '../../../../hotel.service';

declare var google : any;

/**
 *  Component for hotel search form
 */
@Component({
  selector: 'app-searchformhotel',
  templateUrl: './searchform.component.html',
  styleUrls: ['./searchform.component.scss'],
})
export class SearchHotelFormComponent implements OnInit, OnDestroy{
  /**
   *  Input for hotel Search form
   */
  @Input() hotelSearch;
  /**
   *  Input for singleOccupancy
   */
  @Input() singleOccupancy;
  /**
   *  Input for search result
   */
  @Input() hotelRes;
  @Input() hotelDb;

  @Input() hotelResV2;
  /**
   *  Output for search result update
   */
    // tslint:disable-next-line:no-output-rename
  @Output('update') change : EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-rename
  @Output('updatev2') changev2 : EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-native no-output-rename
  @Output('reset') resetForm : EventEmitter<any> = new EventEmitter<any>();
  /**
   *  object used for push guest in room guests array
   */
  guest = { code: 10, quantity: 1 };
  /**
   *  set hotel form hotelRating
   */
  hotelRating = [
    { code: 1, name: 'ONE_STAR' },
    { code: 2, name: 'TWO_STAR' },
    { code: 3, name: 'THREE_STAR' },
    { code: 4, name: 'FOUR_STAR' },
    { code: 5, name: 'FIVE_STAR' },
  ];
  /**
   *  local variable for room categories
   */
  roomCategories = [
    { name: 'Budget room', code: 'I' },
    { name: 'Business room', code: 'B' },
    { name: 'Comfort room', code: 'G' },
    { name: 'Deluxe room', code: 'D' },
    { name: 'Duplex', code: 'X' },
    { name: 'Executive room', code: 'E' },
    { name: 'Concierge/Executive Suite', code: 'C' },
    { name: 'Family room', code: 'F' },
    { name: 'Junior Suite/Mini Suite', code: 'S' },
    { name: 'Penthouse', code: 'P' },
    { name: 'Residential apartment', code: 'R' },
    { name: 'Standard', code: 'M' },
    { name: 'Studio', code: 'L' },
    { name: 'Superior', code: 'S' },
    { name: 'Villa', code: 'V' },
    { name: 'Unknown', code: '*' },
  ];
  /**
   *  local variable for room bed types
   */
  bedTypes = [
    { name: 'Double', code: 'D' },
    { name: 'King size', code: 'K' },
    { name: 'Pull-out', code: 'P' },
    { name: 'Queen-size', code: 'Q' },
    { name: 'Single', code: 'S' },
    { name: 'Twin', code: 'T' },
    { name: 'Water bed', code: 'W' },
  ];
  /**
   *  local variable for loading status
   */
  loading : boolean;
  /**
   *  local variable for today
   */
  today = new Date();
  showCheckOut = true;
  loadingSecondo : boolean;

  selected : any;
  filtering : any = [];
  hotelsList : any;
  hotels : any;
  hotelText : string;
  currentLoad : number;
  counter : any;
  starSelections : string[];
  corporates : CorporateCode[];
  formvisible : boolean;
  protected ngUnsubscribe : Subject<void> = new Subject<void>();
  private isDestroyed : boolean = false;

  /**
   * The constructor
   * @params apiService
   * @params hotelService
   */
  constructor(
    private apiService : ApiService,
    private hotelService : HotelService,
    public mainComponent : MainComponent,
    private api : CompaniesService,
  ){
    this.loading = false;
    this.currentLoad = 0;
    // this.children = {age: '', };
  }

  /**
   *  Fire the guests count in current search form
   */
  ngOnInit() : void{
    if (this.hotelSearch.occupancy[0].guests.length < 1){
      this.addGuestType(0, 10);
    }

    this.getCorporates();
    // this.hotelSearch.ratePlans=[];
    /*
     this.api.getCorporateCode(
     'hotel',
     this.mainComponent.loggedUser.company._id,
     ).subscribe(data => this.corporates = data.list);
     */
  }

  getCorporates(){
    this.api.getCorporateCode(
      'hotel',
      this.mainComponent.loggedUser.company._id,
    ).subscribe(data => this.corporates = data.list);
  }

  ngOnDestroy() : void{
    this.isDestroyed = true;
  }

  starChanged(){
    console.log('1 this.hotelSearch.options.stars ', this.hotelSearch.options.stars);
    if (this.hotelSearch.options.stars.length < 3){
      this.starSelections = this.hotelSearch.options.stars;
    } else{
      this.hotelSearch.options.stars = this.starSelections;
    }
    console.log('1 this.starSelections ', this.starSelections);
    console.log('2 this.hotelSearch.options.stars ', this.hotelSearch.options.stars);
  }

  clearFrom(){
    this.hotelText = '';
    delete this.hotelsList;
    delete this.selected;
  }

  selectHotel(value){
    console.log('entro => ', value);
    this.selected = value;
    this.hotelSearch.hotels = [];
    this.selected.codes.forEach(c => {
      this.hotelSearch.hotels.push(c);
    });
    delete this.hotelsList;
    console.log('this.hotelSearch => ', this.hotelSearch);
    console.log('this.selected) => ', this.selected);
    this.hotelDb = [];
    this.hotelDb.push(this.selected);
    // this.clearFrom();
    // this.hotelText = '';
  }

  isValidForm(){
    if (this.hotelSearch.nameSearch){
      if (this.hotelSearch.hotels.length > 0){
        return true;
      }
    } else{
      if (this.hotelSearch.destination.display){
        return true;
      }
    }
  }

  selectFirst(){
    if (this.hotelsList){
      this.hotelSearch.hotels = [];
      this.selected = this.hotelsList[0];
      this.selected.codes.forEach(c => {
        this.hotelSearch.hotels.push(c);
      });
      this.hotelDb = [];
      this.hotelDb.push(this.selected);
      delete this.hotelsList;
    }
    // this.clearFrom();
  }

  searchHotel(value){
    delete this.hotelsList;
    this.clearFrom();
    if (value.length >= 2){ this.getHotelsList(value); }
  }

  getHotelsList(value){
    this.hotelSearch.options.stars = [];
    this.clearFrom();
    this.hotelService.sendHotelName(value).subscribe((res) => {
      this.loading = false;
      this.hotelsList = res;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  checkChange(event){
    this.hotelSearch.nameSearch = event;
    this.clearmodel();
  }

  resetSearchForm(){
    localStorage.removeItem('searchHotelRes');
    localStorage.removeItem('searchHotelForm');
    this.resetForm.emit();
    this.addGuestType(0, 10);
  }

  /**
   *  Add room to search form and fire guests count function
   */
  addRoom(){
    const occupancy = JSON.parse(JSON.stringify(this.singleOccupancy));
    this.hotelSearch.occupancy.push(occupancy);
    this.addGuest(this.hotelSearch.occupancy.length - 1);
    this.returnTotalGuests();
  }

  /**
   *  Open modal to manage rooms and guest
   */
  toggleGuestsModal(){
    $('#addGuestModal').modal('toggle');
  }

  /**
   *  Remove room from search form rooms using index
   */
  removeRoom(value){
    this.hotelSearch.occupancy.splice(value, 1);
  }

  /**
   *  Add adult for index room
   */
  addGuest(value){
    const guest = JSON.parse(JSON.stringify(this.guest));
    this.hotelSearch.occupancy[value].guests.push(guest);
  }

  strictMode(event){
    this.hotelSearch.strict = event;
  }

  selectCache(event){
    this.hotelSearch.cache = event;
  }

  /**
   *  Listener for destination input field, implement google place autocomplete and set destination display, coordinates and radius
   */
  addList(){
    const input = document.getElementById('pac-input') as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();
      if (places.length === 0){
        return;
      } else{
        console.log('place => ', places);
        console.log('-> lat => ', places[0].geometry.location.lat());
        console.log('-> lng => ', places[0].geometry.location.lng());

        this.hotelSearch.destination = {
          zoneId: places[0].place_id,
          display: places[0].formatted_address,
          coordinates: [places[0].geometry.location.lng(), places[0].geometry.location.lat()],
          radius: this.hotelSearch.destination.radius,
        };

      }
    });
  }

  /**
   *  Reset model for destination: display, coordinates and radius
   */
  clearmodel(){
    this.hotelSearch.hotels = [];
    this.hotelSearch.destination = {
      zoneId: '',
      display: '',
      coordinates: [],
      radius: 2,
    };
    this.hotelSearch.options.stars = [3, 4, 5];
  }

  /**
   *  Collapse toggle to open search options
   */
  toggleOptions(){
    $('#formOptions').collapse('toggle');
  }

  /**
   *  Reset option type to empty array
   */
  resetOptionType(value){
    this.hotelSearch.options[value] = [];
  }

  startCounter(){
    this.currentLoad = 0;
    this.counter = setInterval(() => {
      this.currentLoad = this.currentLoad + 1;
      if (this.currentLoad >= 100){
        this.stopCounter();
      }
    }, 200);
  }

  stopCounter(){
    clearInterval(this.counter);
  }

  /**
   *  Start hotel search
   */
  StarthotelSearch(){
    this.startCounter();
    if (this.hotelRes){
      delete this.hotelRes;
      this.change.emit(this.hotelRes);
    }
    if (this.hotelSearch.moreIndicator){
      delete this.hotelSearch.moreIndicator;
    }
    this.loading = true;
    this.hotelSearch.checkIn = new Date(new Date(this.hotelSearch.checkIn).setHours(6));
    this.hotelSearch.checkOut = new Date(new Date(this.hotelSearch.checkOut).setHours(6));
    this.hotelService.sendHotelSearch(this.hotelSearch).subscribe(resp => {
        this.loading = false;
        // console.log('resp =>', resp);
        this.hotelRes = resp;
      localStorage.setItem('searchHotelForm', JSON.stringify(this.hotelSearch));
      if (this.hotelRes.moreIndicator){
        this.hotelSearch.moreIndicator = this.hotelRes.moreIndicator;
        this.reDoSearchToken(this.hotelSearch.moreIndicator);
      }
      this.hotelRes = this.pushRoom(resp);
      for (let i = 0; i < this.hotelDb.length; i ++){
        const dbHotel = this.hotelDb[i];
        for (let sh = 0; sh < resp.hotels.length; sh ++){
          const finded = dbHotel.codes.findIndex(r => r === resp.hotels[sh].hotelCode);
          if (dbHotel.referenceID === resp.hotels[sh].hotelReference){
            if (!this.hotelDb[i].hotels){
              this.hotelDb[i].hotels = [];
            }
            resp.hotels[sh].session = resp.session;
            this.hotelDb[i].hotels.push(resp.hotels[sh]);
          }
        }
      }
      this.changev2.emit(this.hotelDb);
      // console.log('this.hotelDb =>', this.hotelDb);
      this.change.emit(this.hotelRes);
    }, error => {
      console.error(error);
      this.loading = false;
    });
  }

  reDoSearchToken(value){
    if (this.isDestroyed){
      return;
    }
    this.loadingSecondo = true;
    this.hotelRes.moreIndicator = value;
    this.hotelService.sendHotelSearch(this.hotelSearch).subscribe(resp => {
      this.loadingSecondo = false;
      if (resp.currencyConversions){
        this.hotelRes.currencyConversions = resp.currencyConversions;
      }
      resp.hotels.forEach(h => h.rooms.forEach(r => r = r + this.hotelRes.hotels.length));
      resp.rooms.forEach(r => r.services.forEach(s => s = s + this.hotelRes.rooms.length));
      resp = this.pushRoom(resp);
      this.hotelRes.hotels.push(...resp.hotels);
      this.hotelRes.rooms.push(...resp.rooms);
      this.hotelRes.services.push(...resp.services);

      // this.hotelRes = this.pushRoom(resp);
      // console.log('this.hotelRes =>', this.hotelRes);
      // localStorage.setItem('searchHotelRes', JSON.stringify(this.hotelRes));
      // localStorage.setItem('searchHotelForm', JSON.stringify(this.hotelSearch));
      for (let i = 0; i < this.hotelDb.length; i ++){
        const dbHotel = this.hotelDb[i];
        for (let sh = 0; sh < resp.hotels.length; sh ++){
          if (dbHotel.referenceID === resp.hotels[sh].hotelReference){
            if (!this.hotelDb[i].hotels){
              this.hotelDb[i].hotels = [];
            }
            resp.hotels[sh].session = resp.session;
            this.hotelDb[i].hotels.push(resp.hotels[sh]);
          }
        }
      }
      this.changev2.emit(this.hotelDb);
      // console.log('this.hotelDb =>', this.hotelDb);
      this.change.emit(this.hotelRes);
      if (resp.moreIndicator){
        this.hotelSearch.moreIndicator = resp.moreIndicator;
        this.reDoSearchToken(this.hotelSearch.moreIndicator);
      }
    }, error => {
      this.loadingSecondo = false;
    });
  }

  pushRoom(resp){
    resp.hotels.forEach(hotel => {
      hotel.roomsV2 = [];
      hotel.rooms.forEach(room => {
        if (hotel.roomsV2.length <= hotel.rooms.length){
          resp.rooms[room].index = room;
          hotel.roomsV2.push(resp.rooms[room]);
          if (!hotel.hotelReference){
            hotel.hotelReferenceFull = resp.rooms[room].referenceID;
            hotel.hotelReference = resp.rooms[room].referenceID.split('.')[0];

          }
        }
      });
      hotel.roomsV2.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
    });
    return resp;
  }

  newHotelSearch(){
    this.loading = true;
    if (this.hotelDb && !this.hotelSearch.nameSearch){
      delete this.hotelDb;
      this.changev2.emit(this.hotelDb);
      delete this.hotelSearch.hotels;
    }
    if (!this.hotelSearch.nameSearch){
      // start hotel db search
      this.hotelService.sendHotelSearchV2(this.hotelSearch).subscribe(res => {
        this.loading = false;
        this.hotelDb = res;
        this.changev2.emit(this.hotelDb);
      }, error => {
        console.error(error);
        this.loading = false;
      });
    } else{
      delete this.hotelSearch.destination;
      this.StarthotelSearch();
    }
  }

  StarthotelSearchV2(){
    if (this.hotelSearch.occupancy[0].guests.length < 1){
      this.addGuestType(0, 10);
    }
    this.loading = true;
    if (this.hotelDb && !this.hotelSearch.nameSearch){
      delete this.hotelDb;
      this.changev2.emit(this.hotelDb);
      delete this.hotelSearch.hotels;
    }

    if (!this.hotelSearch.nameSearch){
      this.hotelService.sendHotelSearchV2(this.hotelSearch).subscribe(res => {
        this.loading = false;
        // console.log('resp =>', res);
        this.hotelDb = res;
        this.StarthotelSearch();
      }, error => {
        console.error(error);
        this.loading = false;
      });
    } else{
      if (this.hotelDb){
        delete this.hotelDb[0].hotels;
      }
      delete this.hotelSearch.destination;
      this.StarthotelSearch();
    }

    /*
     this.hotelService.sendHotelSearchV2(this.hotelSearch).subscribe(res => {
     this.hotelSearch.options.stars = [];
     // this.hotelSearch.nameSearch = true;
     this.loading = false;
     // console.log('resp =>', res);
     this.hotelDb = res;
     // delete this.hotelSearch.destination;
     this.hotelDb.forEach(r => {
     this.hotelSearch.hotels = [];
     r.codes.forEach( c => {
     this.hotelSearch.hotels.push(c);
     });
     this.StarthotelSearch();
     });
     }, error => {
     alert(error);
     this.loading = false;
     });
     */
  }

  /**
   *  Add guest to single room
   */
  addGuestType(i, type){
    const newGuest = {
      code: type,
      quantity: 1,
      age: 12,
    };
    if (type !== 5){
      delete newGuest.age;
    }
    const index = this.hotelSearch.occupancy[i].guests.findIndex(r => r.code === newGuest.code);
    if (index === - 1 || type === 5){
      this.hotelSearch.occupancy[i].guests.push(newGuest);
    } else{
      this.hotelSearch.occupancy[i].guests[index].quantity = this.hotelSearch.occupancy[i].guests[index].quantity + newGuest.quantity;
    }
    console.log(this.hotelSearch.occupancy[i].guests);
  }

  /**
   *  Remove guest from single room
   */
  removeGuestType(i, type){
    const newGuest = {
      code: type,
      quantity: 1,
    };
    const index = this.hotelSearch.occupancy[i].guests.findIndex(r => r.code === newGuest.code);
    this.hotelSearch.occupancy[i].guests[index].quantity = this.hotelSearch.occupancy[i].guests[index].quantity - newGuest.quantity;
    if (this.hotelSearch.occupancy[i].guests[index].quantity === 0){
      this.hotelSearch.occupancy[i].guests.splice(index, 1);
    }
    console.log(this.hotelSearch.occupancy);
  }

  /**
   *  Return adults count
   */
  returnAdt(i){
    if (this.hotelSearch.occupancy[i].guests.find(r => r.code === 10)){
      return this.hotelSearch.occupancy[i].guests.find(r => r.code === 10).quantity;
    } else{
      return 0;
    }
  }

  /**
   *  Return over 65 count
   */
  returnOver(i){
    if (this.hotelSearch.occupancy[i].guests.find(r => r.code === 2)){
      return this.hotelSearch.occupancy[i].guests.find(r => r.code === 2).quantity;
    } else{
      return 0;
    }
  }

  /**
   *  Return children count
   */
  returnChd(i){
    let quantity = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let c = 0; c < this.hotelSearch.occupancy[i].guests.length; c ++){
      if (this.hotelSearch.occupancy[i].guests[c].code === 5){
        quantity = quantity + 1;
      }
    }
    return quantity;
  }

  /**
   *  Calculate guests in search form
   */
  returnTotalGuests() : number{
    let quantity = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.hotelSearch.occupancy.length; i ++){
      // tslint:disable-next-line:prefer-for-of
      for (let c = 0; c < this.hotelSearch.occupancy[i].guests.length; c ++){
        quantity = quantity + this.hotelSearch.occupancy[i].guests[c].quantity;
      }
    }
    return quantity;
  }

  setCheckOutDate(){
    const checkin = new Date(this.hotelSearch.checkIn).getTime();
    const checkout = new Date(this.hotelSearch.checkOut).getTime();
    if (checkin > checkout){
      this.showCheckOut = false;
      this.hotelSearch.checkOut = new Date(new Date(checkin).setDate(new Date(this.hotelSearch.checkIn).getDate() + 1));
      setTimeout(() => {
        this.showCheckOut = true;
      }, 100);
    }
    console.log('this.hotelSearch.checkOut => ', this.hotelSearch.checkOut);
  }
}
