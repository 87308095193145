import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ApiService } from '../../../../../../services/api.service';
import {
  MatPaginator,
  PageEvent,
} from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { HotelService } from '../../../../hotel.service';

@Component({
  selector: 'app-hotelresultsv2',
  templateUrl: './hotelresultsv2.component.html',
  styleUrls: ['./hotelresultsv2.component.scss'],
})
export class Hotelresultsv2Component implements OnInit{
  /**
   * Local variable for angular paginator
   */
  @ViewChild(MatPaginator) paginator : MatPaginator;
  /**
   * Local variable for array paginator
   */
  obs : Observable<any>;
  /**
   * Local variable for paginator datasource
   */
  dataSource : MatTableDataSource<any>;
  /**
   * Local variable for angular paginator pagesize
   */
  pageSize = 10;
  /**
   * Local variable for angular paginator page size options
   */
  pageSizeOptions : number[] = [5, 10];
  /**
   * Local variable for angular paginator page event
   */
  pageEvent : PageEvent;
  currentItemsToShow = [];

  @Input() hotelResV2 : any;
  @Input() hotelSearch : any;
  hotelRates : any;
  loading : boolean;
  hotelsToRate : any;
  count = 0;
  request : any;
  loadingRates = true;
  currentPage : number;

  constructor(
    private apiService : ApiService,
    private hotelService : HotelService,
    private changeDetectorRef : ChangeDetectorRef,
  ){
    this.hotelsToRate = [];
    this.hotelRates = [];
    this.request = {};
  }

  async ngOnInit(){
    for (let i = 0; i < this.hotelResV2.length; i ++){
      this.hotelsToRate.push(...this.hotelResV2[i].codes);
    }
    // this.chunk();
    // this.create();
    // this.createFunctions();
    // Promise.all(this.hotelsToRate).then(r => {
    //
    //   this.createAssotiation(r);
    // });

    let moreToken = 'test';

    while (moreToken){
      try{
        const resp = await this.hotelService.sendHotelSearch(this.hotelSearch).toPromise();
        const searchHotelInfos = await this.hotelService.askHotelInfos({ hotels: resp.hotels.map(r => r.hotelCode) }).toPromise();
        this.loading = false;
        console.log('resp =>', resp);
        moreToken = resp.moreIndicator;

        resp.hotels.forEach(hotel => {
          // add rooms obj to hotel
          hotel.rooms = hotel.rooms.map(hotelRoomId => resp.rooms[hotelRoomId]);
          // find geomark hotel in hotelResV2
          let hotelGeomark = this.hotelResV2.find(marker => marker.codes.includes(hotel.hotelCode));
          if (!hotelGeomark){
            this.hotelResV2.push({
              geo: hotel.position,
              codes: [hotel.hotelCode],
              referenceID: hotel.rooms[0].referenceID,
              rating: undefined,
              chain: hotel.chaim,
              name: hotel.name,
            });
            hotelGeomark = this.hotelResV2[this.hotelResV2.length - 1];
          }
          // create details and rooms if there aren't
          if (!hotelGeomark.details){
            hotelGeomark.details = [];
          }
          if (!hotelGeomark.rooms){
            hotelGeomark.rooms = [];
          }
          if (!hotelGeomark.ratedOn){
            hotelGeomark.ratedOn = new Date().getTime();
          }
          // get min and max price for room
          const min = hotel.rooms.reduce((rex : number, p) => p.amount < rex ? p.amount : rex, hotel.rooms[0].amount);
          const max = hotel.rooms.reduce((rex : number, p) => p.amount > rex ? p.amount : rex, hotel.rooms[0].amount);
          // add infos to geomark
          const infos = searchHotelInfos.find(hotelAma => hotelAma.code === hotel.hotelCode) || {};
          if (!hotelGeomark.details.find(r => r.code === infos.code)){
            hotelGeomark.details.push(infos);
          }
          // add hotelcode to hotel room, so every room is in the same array
          hotel.rooms.forEach(hotelRoom => {
            const type = (hotelRoom.types[0]) ? hotelRoom.types[0].type : hotelRoom.roomRates[0].name;
            const roomAlreadyAdded = hotelGeomark.rooms.find(r => r.type === type) || {};
            if (Object.keys(roomAlreadyAdded).length === 0){
              roomAlreadyAdded.type = type;
              roomAlreadyAdded.rates = [];
              roomAlreadyAdded.minPrice = Number.MAX_SAFE_INTEGER;
              roomAlreadyAdded.maxPrice = 0;
              hotelGeomark.rooms.push(roomAlreadyAdded);
            }
            if (hotelRoom.roomRates[0].amount < roomAlreadyAdded.minPrice){
              hotelGeomark.minPrice = hotelRoom.roomRates[0].amount;
            }
            if (hotelRoom.roomRates[0].amount > roomAlreadyAdded.maxPrice){
              hotelGeomark.maxPrice = hotelRoom.roomRates[0].amount;
            }
            roomAlreadyAdded.rates.push({
              referenceID: hotelRoom.referenceID,
              ratePlans: hotelRoom.ratePlans,
              roomRates: hotelRoom.roomRates,
              services: hotelRoom.services.map(r => resp.services[r - 1]),
              types: hotelRoom.types,
            });
          });
          // add some information if not present and update the oldest one
          if (!hotelGeomark.address){
            hotelGeomark.address = hotel.address;
          }
          if (!hotelGeomark.contacts){
            hotelGeomark.contacts = hotel.contacts;
          }
          if (!hotelGeomark.type){
            hotelGeomark.type = hotel.type;
          }
          if (!hotelGeomark.chain){
            hotelGeomark.chain = hotel.chain;
          }
          if (!hotelGeomark.minPrice || hotelGeomark.minPrice > min){
            hotelGeomark.minPrice = min;
          }
          if (!hotelGeomark.maxPrice || hotelGeomark.maxPrice < max){
            hotelGeomark.maxPrice = max;
          }
        });
        this.doSort();
      } catch(error){
        console.error(error);
        this.loading = false;
      }
    }
  }

  doSort(){
    this.currentItemsToShow = this.hotelResV2.filter(r => r.ratedOn)
                                  .sort((a, b) => a.ratedOn - b.ratedOn);
    // this.currentPage = 0;
    this.dataSource = new MatTableDataSource<any>(this.currentItemsToShow);
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    // this.paginator.firstPage();
    this.obs = this.dataSource.connect();
    console.log('this.currentItemsToShow => ', this.currentItemsToShow);
    // this.getDataSource();
  }

  pageChanged(event){
    setTimeout(() => {
      // this.scrollTop();
      // this.getDataSource();
    }, 200);
  }

  getPopulatedList(){
    return this.hotelResV2.filter(r => r.ratedOn)
               .sort((a, b) => a.ratedOn - b.ratedOn);
  }

  createAssotiation(element){
    if (element[0].rooms){
      // console.log();
    }
    if (element[1].code){
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < element[1].length; i ++){
        if (element[1][i].code){
          for (const hotel of this.hotelResV2){
            if (hotel.codes.includes(element[1][i].code)){
              hotel.generalInfos = element[1][i];
            }
          }
        }
      }
    }
    console.log('this.hotelResV2 redo => ', this.hotelResV2);
  }

  chunk(){
    this.hotelsToRate = Array.from({ length: Math.ceil(this.hotelsToRate.length / 4) }, (v, i) =>
      this.hotelsToRate.slice(i * 4, i * 4 + 4),
    );
  }

  createFunctions(){
    // tslint:disable-next-line:prefer-for-of
    this.hotelsToRate = this.hotelsToRate.map(async (r) => {
      // create promises with rates and infos for selected hotels
      const [rates, infosAma] = await Promise.all([
        this.hotelService.searchRate({ ...this.request, hotels: [...r] }).toPromise(),
        this.hotelService.askHotelInfos({ hotels: [...r] }).toPromise(),
      ]);
      // check if rates exist (and session, if there's we have a rating!)
      if (rates && rates.session){
        // loop every hotel
        rates.hotels.forEach(hotel => {
          // add rooms obj to hotel
          hotel.rooms = hotel.rooms.map(hotelRoomId => rates.rooms[hotelRoomId]);
          // find geomark hotel in hotelResV2
          const hotelGeomark = this.hotelResV2.find(marker => marker.codes.includes(hotel.hotelCode));
          if (hotelGeomark){
            // create details and rooms if there aren't
            if (!hotelGeomark.details){
              hotelGeomark.details = [];
            }
            if (!hotelGeomark.rooms){
              hotelGeomark.rooms = [];
            }
            if (!hotelGeomark.ratedOn){
              hotelGeomark.ratedOn = new Date().getTime();
            }
            // get min and max price for room
            const min = hotel.rooms.reduce((rex, p) => p.amount < rex ? p.amount : rex, hotel.rooms[0].amount);
            const max = hotel.rooms.reduce((rex, p) => p.amount > rex ? p.amount : rex, hotel.rooms[0].amount);
            // add infos to geomark
            const infos = infosAma.find(hotelAma => hotelAma.code === hotel.hotelCode) || {};
            hotelGeomark.details.push(infos);
            // add hotelcode to hotel room, so every room is in the same array
            hotel.rooms.forEach(hotelRoom => hotelGeomark.rooms.push({ ...hotelRoom, hotelCode: hotel.hotelCode }));
            // add some information if not present and update the oldest one
            if (!hotelGeomark.address){
              hotelGeomark.address = hotel.address;
            }
            if (!hotelGeomark.contacts){
              hotelGeomark.contacts = hotel.contacts;
            }
            if (!hotelGeomark.type){
              hotelGeomark.type = hotel.type;
            }
            if (!hotelGeomark.chain){
              hotelGeomark.chain = hotel.chain;
            }
            if (!hotelGeomark.minPrice || hotelGeomark.minPrice > min){
              hotelGeomark.minPrice = min;
            }
            if (!hotelGeomark.maxPrice || hotelGeomark.maxPrice < max){
              hotelGeomark.maxPrice = max;
            }
          }
        });
      }
    });
  }

  getAllImages(details){
    const imgs = [];
    details.forEach(r => {
      if (r && r.info && r.info.images){
        r.info.images.forEach(i => imgs.push(i));
      }
    });
    return imgs;
  }

  getHotelName(hotel){
    if (hotel.details[0]){
      return hotel.details[0].name;
    } else if (hotel.name){
      return hotel.name;
    }
  }

  create(){
    this.request = JSON.parse(JSON.stringify(this.hotelSearch));
    delete this.request.destination;
    // this.AskRate(req);
  }

  AskRate(req){
    this.loading = true;
    req.hotels = this.hotelsToRate[this.count];
    this.count = this.count + 1;
    this.hotelService.searchRate(req).subscribe(res => {
      this.loading = false;
      console.log('resp =>', res);
      if (this.count < this.hotelsToRate.length - 1){
        this.AskRate(req);
      }
    }, error => {
      alert(error);
      this.loading = false;
    });
  }

}
