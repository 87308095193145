<div class="costCenterInputing pnrActionsButtons" *ngIf="costCenterList.length > 0">
    <div class="costTilte mb-2">{{'COST_CENTER_TEXT'|translate}}</div>
    <mat-form-field class="mr-2">
      <mat-label>{{'COST_CENTER'|translate}}</mat-label>
      <mat-select [(ngModel)]="costCenter">
        <mat-option [value]="null" style="color:#b01818;">
          {{'NONE'|translate}}
        </mat-option>
        <mat-option (click)="openCostCenterDialog()" style="color:#4d79f6;">+ {{'ADD_COST_CENTER'|translate}}</mat-option>
        <mat-option *ngFor="let cost of costCenterList" [value]="cost.description">
          {{cost.tag}} -> {{cost.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="updateCostCenter()" [disabled]="updating || loadingCenters">{{'UPDATE'|translate}}</button>
    <mat-progress-bar mode="buffer" *ngIf="updating || loadingCenters"></mat-progress-bar>
  </div>
