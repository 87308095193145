import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NoteModalComponent} from '../note-modal/note-modal.component';
import {ProductInfoDialogComponent} from 'src/app/components/shared/product-info-dialog/product-info-dialog.component';

@Component({
  selector: 'app-authorization-card',
  templateUrl: './authorization-card.component.html',
  styleUrls: [ './authorization-card.component.scss' ]
})
export class AuthorizationCardComponent implements OnInit {

  @Input() authorization: any;
  @Output() submitted = new EventEmitter<any>();

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  openDialog(type: string): void {
    const dialogRef = this.dialog.open(NoteModalComponent, {
      width: '300px',
      disableClose: true,
      data: {
        type
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result.confirmed) {
        return;
      }
      this.submitted.emit(
        {
          id: this.authorization._id,
          note: result.note,
          category: this.authorization.authorisation.ref.toLowerCase(),
          type: result.type
        });
    });
  }

  showProductInfo(){
    const dialogRef = this.dialog.open(ProductInfoDialogComponent, {
      minWidth: '400px',
      disableClose: true,
      data: {
        search: this.authorization.searchId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result.confirmed) {
        return;
      }
      this.submitted.emit(
        {
          id: this.authorization._id,
          note: result.note,
          category: this.authorization.authorisation.ref.toLowerCase(),
          type: result.type
        });
    });
  }

}
