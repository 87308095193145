<div class="padding20">
  <div class="col-6 mb-3">
    <custom-title></custom-title>
    <div class="text-right col-6">
      <a href="https://www.trenitalia.com/it/informazioni/condizioni_generaliditrasporto.html" target="_blank">{{'TRAIN_MODULE.TRENIT_CONDITION'|translate}}</a>
    </div>
  </div>
  <mat-card appearance="outlined">
    <mat-card-title>
      {{'TRAIN_MODULE.SUCCESS_BOOKING' | translate}}
    </mat-card-title>
    <mat-card-content>
      <button (click)="this.downloadPdf(this.bookingId)" class="mat-elevation-z2" mat-button style="margin-left: 10px" type="button">
        {{'TRAIN_MODULE.DOWNLOAD_TICKET' | translate}}
        <mat-icon>download</mat-icon>
      </button>
      <button (click)="this.goTo($event, ['trains', 'reservations'])" class="mat-elevation-z2" mat-button style="margin-left: 10px" type="button">
        {{'TRAIN_MODULE.GO_RESERVATIONS' | translate}}
        <mat-icon>list</mat-icon>
      </button>
      <button (click)="this.goTo($event, ['trains', 'search'])" class="mat-elevation-z2" color="primary" mat-button style="margin-left: 10px" type="button">
        <mat-icon>arrow_left_alt</mat-icon>
        {{'TRAIN_MODULE.NEW_SEARCH' | translate}}
      </button>
      <button (click)="this.goTo($event, ['dashboard'])" class="mat-elevation-z2" mat-button style="margin-left: 10px" type="button">
        {{'TRAIN_MODULE.GO_HOME' | translate}}
        <mat-icon>home</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>
</div>
