import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {CompaniesService} from '../../../../../../services/profile/companies.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MainComponent} from '../../../../../mainmodule/main/main.component';
import {CompanyComponent} from '../company.component';
import {SearchRes} from '../../../../../../classes/flights/search-res';
import {AppComponent} from '../../../../../../app.component';

@Component({
  selector: 'app-cp-services',
  templateUrl: './cpservices.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesCpComponent implements OnInit {
  @Input() cpId: string;
  // tslint:disable-next-line:no-output-rename
  @Output('update') change: EventEmitter<any> = new EventEmitter<any>();
  loading: boolean;
  cpSetting: any;
  constructor(
    private companyService: CompaniesService,
    private snackBar: MatSnackBar,
    public maincomponent: MainComponent,
    public appComponent: AppComponent,
  ) { }

  ngOnInit(): void {
    this.getCpSetting();
  }

  getCpSetting() {
    this.loading = true;
    this.companyService.getCpSettings(this.cpId).subscribe((res) => {
      this.cpSetting = res;
      if(this.cpSetting.addonFlight){
        if(!this.cpSetting.addonFlight.ndc){
          this.cpSetting.addonFlight.ndc = false
        }
        if(!this.cpSetting.addonFlight.postBooking_NDC){
          this.cpSetting.addonFlight.postBooking_NDC = false
        }
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log('error => ', error);
    });
  }

  saveSettings() {
    delete this.cpSetting._id;
    this.loading = true;
    this.companyService.postCpSettings(this.cpId, this.cpSetting).subscribe((res) => {
      this.cpSetting = res;
      this.loading = false;
      this.getCpSetting();
      this.change.emit();
    }, (error) => {
      this.loading = false;
      console.log('error => ', error);
    });
  }

}
