<h3 style="text-align: center" mat-dialog-title>{{data.message}}</h3>
<div mat-dialog-content>

  <app-hotel-card *ngIf="search.ref == 'Hotel'" [productDetails]="productDetails"></app-hotel-card>
  <app-flight-card *ngIf="search.ref == 'Flight'" [productDetails]="productDetails"></app-flight-card>
  <app-vehicle-card *ngIf="search.ref == 'Car'" [productDetails]="productDetails"></app-vehicle-card>
  <app-train-card *ngIf="search.ref == 'Train'" [productDetails]="productDetails"></app-train-card>

</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button [mat-dialog-close]="true">Close</button>
</div>
