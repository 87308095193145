import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as $ from 'jquery';
import { CheckPenaltyResponse } from '../../../../classes/train.models';
import { TrainService } from '../../../../train.service';
import { TrainUtils } from '../../../train.utils';
import * as buffer from 'buffer';

@Component({
  selector: 'train-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss'],
})
export class TrainRefundComponent implements AfterViewInit{
  @Input() isDisabled : boolean;
  @Input() pnr : string;
  @Input() selectedTravelSolution = 0;
  @Input() allowedOperations : any;
  @Input() catalog : any;
  @Output() reloadEvent = new EventEmitter<any>();

  refundReservation$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  checkingPenalty$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  refundAttempt : boolean;
  penalty : CheckPenaltyResponse;
  errorMessage : string;
  priceChecked : boolean;

  solutionNodes : any[] = [];
  selectedPostSaleDetails : any = {};
  private refundableSegments : any = {};
  private travelSolution : any;

  /**
   *  Constructor
   */
  constructor(
    private trainService : TrainService,
  ){ }

  /**
   *  OnInit function is empty for now
   */
  ngAfterViewInit(){
    if (this.allowedOperations){
      TrainUtils.toArray(this.allowedOperations.postSaleDetails).filter(psd => psd.postSaleType.code === 'REFUND')
          .forEach(rs => {
            const idSegment = JSON.stringify(rs.offeredServiceId);
            this.refundableSegments[idSegment] = rs;
          });

      const travellers = {};
      TrainUtils.toArray(this.allowedOperations.travel.travellers).forEach(t => travellers[t.xmlId] = t);
      this.travelSolution = TrainUtils.toArray(this.allowedOperations.travel.travelSolutions)[this.selectedTravelSolution];
      const allSolutionNodes = TrainUtils.toArray(this.travelSolution.solutionNodes).concat(this.travelSolution.returnTravelSolution?.solutionNodes || []);
      const allOffers = TrainUtils.toArray(this.travelSolution.offeredServices).concat(this.travelSolution.returnTravelSolution?.offeredServices || []);
      allSolutionNodes.map(sn => {
        if (['SOLUTION_SEGMENT','SUBSCRIPTION_SEGMENT'].includes(sn.type)){
          return [sn];
        } else if (!!sn.subSegments){
          return sn.subSegments.filter(s => ['SOLUTION_SEGMENT','SUBSCRIPTION_SEGMENT'].includes(s.type));
        } else {
          return [];
        }
      }).forEach(snArray => {
        snArray.forEach(sn => {
          const segmentOffers = allOffers.filter(o => o.solutionNodeRef === sn.idXml);
          const segmentTravellers : any = new Set(segmentOffers.map(o => o.bookingInfo.traveller));
          segmentTravellers.forEach(tid => {
            this.selectedPostSaleDetails[sn.idXml + tid] = false;
            const travellerSegmentOffers = segmentOffers.filter(o => o.bookingInfo.traveller === tid);
            const deleted = segmentOffers.every(o => o.status === 'DELETED');
            const allowed = travellerSegmentOffers.every(o => this.refundableSegments[JSON.stringify(o.id)]?.allowed === 'true');
            this.solutionNodes.push({ ...sn, allowed, deleted, offers: travellerSegmentOffers, traveller: travellers[tid]});
          });
        });
      });

    }
  }

  toggleRefundModal(){
    $('#refundModal').modal('toggle');
  }

  checkPenalty(){
    this.checkingPenalty$.next(true);
    const selectedRefunds : any = Object.values(this.refundableSegments).filter((sr: any) => sr && sr.checked);
    const req = {
      travel: this.allowedOperations.travel,
      post_sale_details: selectedRefunds,
    };
    this.trainService.checkPenalty(this.pnr, req).subscribe(data => {
      this.checkingPenalty$.next(false);
      this.penalty = data;
      this.priceChecked = true;
    }, _ => this.checkingPenalty$.next(false));
  }

  confirmRefund(){
    const selectedRefunds : any = Object.values(this.refundableSegments).filter((sr: any) => sr && sr.checked);
    const req = {
      travel: this.allowedOperations.travel,
      post_sale_details: selectedRefunds,
    };
    this.refundReservation$.next(true);
    this.trainService.refundReservation(this.pnr, req).subscribe(data => {
      this.refundReservation$.next(false);
      this.refundAttempt = true;
      this.reload();
    }, error => {
      this.refundReservation$.next(false);
      this.errorMessage = error.message;
    });
  }

  reload(){
    this.reloadEvent.emit(true);
  }

  getTotalString(){
    if (this.penalty) {
      return this.penalty?.totalValue.amount + this.penalty?.totalValue.currency;
    } else if (this.travelSolution){
      return this.getTravelSolutionTotalPrice() + ' ' + this.travelSolution.totalPrice?.currency;
    } else {
      return '-';
    }
  }

  getTotal() : { totalPenalty: any, totalRefund: any } {
    const response = { totalPenalty: {}, totalRefund: {} };
    const selectedRefunds : any = Object.values(this.refundableSegments).filter((sr: any) => sr && sr.checked);
    if (this.penalty){
      response.totalPenalty = this.penalty.totalPenalties;
      response.totalRefund = this.penalty.totalNetValue;
    } else if (selectedRefunds.length > 0) {
      const penalty = selectedRefunds.reduce((a : any, b : any) => a + Number.parseFloat(b?.penaltyAmount.amount), 0)
      const total = this.getTravelSolutionTotalPrice();
      const currency = selectedRefunds[0].penaltyAmount.currency;
      response.totalRefund = { amount: (total - penalty), currency };
      response.totalPenalty = { amount: penalty, currency };
    }
    return response;
  }

  getTravelSolutionTotalPrice(): number {
    if (!this.travelSolution){
      return 0;
    }
    return Number.parseFloat(this.travelSolution.totalPrice?.amount) +
      Number.parseFloat(this.travelSolution.returnTravelSolution?.totalPrice?.amount || 0);
  }

  setSelection(solutionNodeSelected: any){
    if (!solutionNodeSelected.allowed || solutionNodeSelected.closed) return;
    const rowId = solutionNodeSelected.idXml + solutionNodeSelected.traveller.xmlId;
    this.priceChecked = false;
    delete this.penalty;
    this.selectedPostSaleDetails[rowId] = !this.selectedPostSaleDetails[rowId];
    solutionNodeSelected.offers.forEach(o => {
      this.refundableSegments[JSON.stringify(o.id)].checked = this.selectedPostSaleDetails[rowId];
    });
  }

  getEntitlement(offer : any){
    const idSegment = JSON.stringify(offer.id);
    return this.refundableSegments[idSegment];
  }

  getEntitlementTotal(sn : any): number{
    return sn.offers.map(o => this.refundableSegments[JSON.stringify(o.id)])
               .reduce((a : any, b : any) => a + Number.parseFloat(b?.penaltyAmount.amount), 0);
  }

  noneSelected() : boolean{
    return Object.values(this.selectedPostSaleDetails).every(v => v === false);
  }
}
