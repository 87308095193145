<mat-form-field class="full-width" appearance="outline">
  <mat-label>{{'COMPANY'|translate}}</mat-label>
  <input [ngModelOptions]="{standalone: true}" matInput placeholder="{{'SEARCH_COMPANY'|translate}}" aria-label="Departure"
         (ngModelChange)="searchCompany($event)" [(ngModel)]="companytext" [matAutocomplete]="cpcomplete">
  <button type="button" *ngIf="companytext" matSuffix mat-icon-button aria-label="Clear" (click)="clearFrom()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #cpcomplete="matAutocomplete" (closed)="selectFirst()">
    <mat-option *ngFor="let comp of companiesList" [value]="comp.name" (click)="selectComp(comp)">
      <span>{{comp.name}}</span>
    </mat-option>
  </mat-autocomplete>

</mat-form-field>
<div *ngIf="selected.length>0">
  <span class="badge-material" *ngFor="let company of selected; let i = index;">
    {{company.name}} <button mat-icon-button (click)="removeCompany(company._id)"><mat-icon>close</mat-icon></button>
  </span>
</div>
