import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ApplyDiscountCodeRequest, CreateReservationReq, CreateReservationResponse, LayoutQueryResponse, ProvideLayoutReq, ProvideLayoutResponse,
  RequiredTravellerParameter, TravelSolutionResponse, TrenitTravellerParameter } from '../../classes/train.models';
import { TrainService } from '../../train.service';
import { MatDialog } from '@angular/material/dialog';
import * as $ from 'jquery';
import { TrainMainComponent } from '../common/train-main/train-main.component';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

/**
 * Component to collect the passengers details
 */
@Component({
  selector: 'app-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.scss'],
})
export class PassengersComponent extends TrainMainComponent{
  @Output() nextStepEvent = new EventEmitter<CreateReservationReq>();
  @Output() previousStepEvent = new EventEmitter<string>();
  public createReservationLoading$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public applyDiscountCode$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public seatSelection$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public seatSelection : LayoutQueryResponse[];
  public catalog : any;
  newTravelSolution : any;
  travelPrice : number;
  discount : number = 0;
  seatCharge : number = 0;
  discountCode : string;
  discountCodeApplied : boolean;
  termsAccepted : boolean = false;
  applyDiscountCodeErrorMessage : string = 'TRAIN_MODULE.TRENIT.DISCOUNT_CODE.FAILED';
  selectedSeats : any = {};
  private isCarnet : boolean = false;

  /**
   * @param service
   * @param router .
   * @param titleService
   * @param translate
   * @param titleService
   * @param translate
   * @param dialog
   */
  constructor(private service : TrainService,
              public router : Router,
              public titleService : Title,
              public translate : TranslateService,
              private dialog : MatDialog){
    super(router, titleService, translate);
  }

  private static calculatePrice(travelSolution : any){
    const forwardPrice = travelSolution.totalPrice.amount;
    const returnPrice = (travelSolution.returnTravelSolution) ? travelSolution.returnTravelSolution.totalPrice.amount : 0;
    return (Number.parseFloat(forwardPrice) + Number.parseFloat(returnPrice));
  }

  ngOnInit() : void{
    if (this.trainTravel?.evaluatedTravel){
      const requiredParameters = this.trainTravel.evaluatedTravel.requiredTravellerParameters;
      const travelSolution = this.trainTravel.evaluatedTravel.evaluateTravelSolutionsResponse.travelSolutions;
      this.travelPrice = PassengersComponent.calculatePrice(travelSolution);
      this.trainTravel.travellers.forEach((trv) => {
        const params = trv.parameters || [];
        const alreadySetParams = params.map((p : any) => Number.parseInt(p.typeId, 10));
        requiredParameters[trv.xmlId]?.forEach((param : RequiredTravellerParameter) => {
          if (!alreadySetParams.includes(param.id)){
            params.push(new TrenitTravellerParameter(param.id));
          }
        });
        trv.parameters = params;
      });
      super.updateTrainTravel();
      this.isCarnet = !!travelSolution.context?.searchCriteria?.admissibleServices;
    }
  }

  /**
   * submits the form data to the service to create a reservation
   */
  public makeReservation() : void{
    this.createReservationLoading$.next(true);
    const toBeEvaluatedTravels = this.trainTravel.evaluatedTravel.evaluateTravelSolutionsResponse.travelSolutions;
    const request = new CreateReservationReq(
      (this.newTravelSolution) ? this.newTravelSolution : toBeEvaluatedTravels,
      this.trainTravel.travellers,
      this.trainTravel.customerKey,
    );
    if (this.currentSearch.carnetId){
      request.carnetId = this.currentSearch.carnetId.pnr;
    }
    if (this.isAftersale()) {
      this.nextStepEvent.emit(request);
    } else {
      if (this.isCarnet) {
        this.trainTravel.reservatedTravel = {
          bookingDb: {},
          travel: { travelSolutions: request.travelSolution, travellers: request.travellers }
        };
        this.updateTrainTravel();
        this.router.navigate(['trains', 'trenitalia', 'checkout']);
      } else {
        this.service.createReservation(request).subscribe((result : CreateReservationResponse) => {
          this.createReservationLoading$.next(false);
          this.trainTravel.reservatedTravel = { bookingDb: result.bookingDb, travel: result.result.travel };
          this.updateTrainTravel();
          this.router.navigate(['trains', 'trenitalia', 'checkout']);
        }, _ => this.createReservationLoading$.next(false));
      }
    }
  }

  public goBack($event) : void{
    if (this.isAftersale()){
      this.previousStepEvent.emit('search');
    } else {
      this.router.navigate(['trains', 'search']);
    }
  }

  applyDiscountCode(discountCode : any){
    this.applyDiscountCode$.next(true);
    const request = new ApplyDiscountCodeRequest(
      this.trainTravel.evaluatedTravel.evaluateTravelSolutionsResponse.travelSolutions,
      this.trainTravel.travellers,
      discountCode,
    );
    this.service.applyDiscountCode(request).subscribe((response : TravelSolutionResponse) => {
      this.applyDiscountCode$.next(false);
      this.discountCodeApplied = !!response?.travelSolution;
      this.newTravelSolution = response?.travelSolution;
      if (this.newTravelSolution){
        this.discount = - this.travelPrice + PassengersComponent.calculatePrice(this.newTravelSolution);
      }
    }, error => {
      this.applyDiscountCode$.next(false);
      this.discountCodeApplied = false;
      this.applyDiscountCodeErrorMessage = error.message;
    });
  }

  acceptTermsAndConditions(accepted : boolean){
    this.termsAccepted = accepted;
  }

  allTravellersSet(){
    return this.trainTravel.travellers && this.trainTravel.travellers?.length > 0 && this.trainTravel.travellers?.every((t : any) => typeof t.name !== 'undefined');
  }

  stepCompleted(){
    const allParamsSet = this.trainTravel.forwardJourney || this.trainTravel.travellers?.every((t : any) => !t.parameters || (t.parameters.length === 0 || t.parameters.every(p => !!p.value)));
    return this.allTravellersSet() && (this.termsAccepted || this.trainTravel.forwardJourney) && allParamsSet;
  }

  toggleSeatSelectionModal(init? : boolean){
    $('#seatSelectionModal').modal('toggle');
    if (!!init){
      this.seatSelection$.next(true);
      const selectedTravelSolution = this.trainTravel.evaluatedTravel.evaluateTravelSolutionsResponse.travelSolutions;
      const request = new ProvideLayoutReq((this.newTravelSolution) ? this.newTravelSolution : selectedTravelSolution);
      this.service.provideLayout(request).subscribe((response : ProvideLayoutResponse) => {
        this.seatSelection$.next(false);
        this.seatSelection = response.layoutResults;
        this.catalog = response.catalog;
      }, error => {
        $('#seatSelectionModal').modal('toggle');
        this.seatSelection$.next(false);
      });
    }
  }

  confirmSelectSeat(){
    if (!!this.selectedSeats){
      const seats = {};
      this.seatCharge = 0;
      Object.keys(this.selectedSeats).forEach(segment => Object.keys(this.selectedSeats[segment]).forEach(pax => {
        if (!seats[pax]){
          seats[pax] = {};
        }
        seats[pax][segment] = this.selectedSeats[segment][pax];
        const paxSeatPrices = this.trainTravel.evaluatedTravel.allowedServices.seatSelectionCharged[pax] || {};
        const seatPrice = paxSeatPrices[segment] || 0;
        this.seatCharge += Number.parseFloat(seatPrice);
      }));
      this.trainTravel.travellers = this.trainTravel.travellers.map(t => {
        return {
          ...t,
          seat: seats[t.xmlId],
        };
      });
      $('#seatSelectionModal').modal('toggle');
    }
    console.log(JSON.stringify(this.trainTravel));
  }

  getStationFromCatalog(id){
    return this.catalog.stations[id];
  }

  paxSeatNumber(seat) {
    return seat[Object.keys(seat)[0]].airCraftNumber;
  }

  paxWagonNumber(seat) {
    return seat[Object.keys(seat)[0]].wagon;
  }

}
