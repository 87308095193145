<custom-title></custom-title>
<div class="padding20">

  <div class="col-12">
    <form [formGroup]="groupRequest" id="frmGroup" class="col-12">
      <div class="row">
        <div class="col-5 ml-2">
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-subtitle>
                {{ 'SHOW_OPTIONS' | translate }}
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'PASSENGERS' | translate}}</mat-label>
                    <input matInput formControlName="travellers" type="number">
                  </mat-form-field>
                </div>
                <div class="col-12">
                  <app-carriers [parentForm]="flightRequest"></app-carriers>
                  <div *ngIf="flightRequest.options.carrierIdentification.length>0" class="mb-3">
                      <span *ngFor="let car of flightRequest.options.carrierIdentification;let c = index;" class="singleiata">
                        {{car}}
                        <span (click)="removeIataCarrier(c)" class="cancelicon" aria-hidden="true">&times;</span>
                      </span>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-5 ml-2">
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-subtitle>
                {{'Eventuali note' | translate}}; indicare orari e voli richiesti
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div class="col-12">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'NOTES' | translate}}</mat-label>
                  <textarea matInput formControlName="notes" [maxLength]="256"></textarea>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </form>
    <div class="row mt-2">
      <div class="col-5 mx-2 my-2" *ngFor="let section of flightRequest.sections;let i = index">
        <mat-card appearance="outlined">
          <div *ngIf="flightRequest.sections.length > 1" class="text-right">
            <div class="pointer" (click)="removeSection(i)">&times;</div>
          </div>
          <mat-card-header>
            <mat-card-subtitle>
              {{ 'FLIGHT' | translate}} {{i + 1}}
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <app-airports [parentForm]="flightRequest" [index]="i" [isTransit]="false"></app-airports>
            <div class="row">
              <div class="col-md-5">
                <div *ngIf="flightRequest.sections[i].from.length>0" class="">
                  <span *ngFor="let iata of flightRequest.sections[i].from;let ia = index;" class="singleiata">
                    {{iataComplete.returnAirport(iata)}}
                    <span (click)="removeIataFrom(i,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                  </span>
                </div>
              </div>
              <div class="col-md-2 col-12 text-center align-self-center">
                <span (click)="invertIata(i)" class="switchIcon material-icons" [hidden]="flightRequest.sections[i].to.length == 0 && flightRequest.sections[i].from.length == 0">
                  swap_horiz
                </span>
              </div>
              <div class="col-md-5">
                <div *ngIf="flightRequest.sections[i].to.length>0" class="">
                <span *ngFor="let iata of flightRequest.sections[i].to;let ia = index;" class="singleiata">
                  {{iataComplete.returnAirport(iata)}}
                  <span (click)="removeIataTo(i,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                </span>
                </div>
              </div>
            </div>
            <div class="col-12">
              <mat-form-field class="full-width">
                <mat-label>{{ 'DEPARTURE_DATE' | translate}}</mat-label>
                <input matInput readonly [matDatepicker]="jj" placeholder="{{'DEPARTURE_DATE'|translate}}" (click)="jj.open()" [min]="departureStartDate$ | async" [(ngModel)]="section.options.date"
                       name="date{{i}}">
                <mat-datepicker-toggle matSuffix [for]="jj"></mat-datepicker-toggle>
                <mat-datepicker #jj></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="full-width">
                <mat-label>{{'TIME'|translate}}</mat-label>
                <mat-select [(ngModel)]="section.options.timeSlot" name="time">
                  <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                  <mat-option value="2">{{'MORNING'|translate}} (06-12)</mat-option>
                  <mat-option value="3">{{'AFTERNOON'|translate}} (12-18)</mat-option>
                  <mat-option value="4">{{'EVENING'|translate}} (18-24)</mat-option>
                  <mat-option value="1">{{'NIGHT'|translate}} (00-06)</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="full-width">
                <mat-label>{{'CABIN'|translate}}</mat-label>
                <mat-select [(ngModel)]="section.options.cabin" name="cabin">
                  <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                  <mat-option value="Y">Ecomonimc</mat-option>
                  <mat-option value="W">Ecomonimc Premium</mat-option>
                  <mat-option value="C">Business</mat-option>
                  <mat-option value="F">First</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row buttonrow mt-2 ml-2">
      <button mat-raised-button color="info" (click)="addSection()">
        <mat-icon>add</mat-icon>
        {{ 'ADD_SECTION' | translate}}
      </button>
      <button mat-raised-button color="info" (click)="onSubmit()" [disabled]="!isCompleted()">
        <mat-icon>arrow_right_alt</mat-icon>
        {{ 'SEND_REQUEST' | translate}}
      </button>
    </div>
  </div>

</div>
