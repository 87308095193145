<mat-card-content class="p-0">
  <mat-card-header  *ngIf="!passengers[0] || !passengers[0].phoneNumber">
    <div *ngIf="!passengers[0] || !passengers[0].phoneNumber" class="alert alert-warning">
      <mat-icon>warning</mat-icon>
      {{'INSERT_FIRST_PAX_PHONE'|translate}}
    </div>
  </mat-card-header>

  <div class="row">
    <div class="col-md-6 mb-3" *ngFor="let tra of passengers;let i = index">
      <mat-card appearance="outlined" class="">
        <mat-card-header *ngIf="editable" class="justify-content-end">
          <div (click)="removePax(i)" class="pointer"> x</div>
        </mat-card-header>
        <mat-card-header>
          <mat-card-title class="my-2 pl-3" style="font-size: 20px!important;font-weight: 500">{{tra.name}} {{tra.surname}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="fakefield mb-2">
            {{'TYPE'|translate}}:
            <span> {{tra.type|translate}}</span>
          </div>
          <div class="fakefield mb-2">
            {{'NAME'|translate}}:
            <span *ngIf="tra.name else noData"> {{tra.name}}</span>
          </div>
          <div class="fakefield mb-2">
            {{'SURNAME'|translate}}:
            <span *ngIf="tra.surname else noData"> {{tra.surname}}</span>
          </div>
          <div class="fakefield mb-2">
            {{'PHONE'|translate}}:
            <span *ngIf="tra.phoneNumber else noData"> {{tra.phoneNumber}}</span>
          </div>
          <div class="fakefield mb-2">
            {{'EMAIL'|translate}}:
            <span *ngIf="tra.email else noData"> {{tra.email}}</span>
          </div>
          <div class="fakefield mb-2">
            {{'CARTA_FRECCIA'|translate}}:
            <span *ngIf="getCartaFreccia(tra) else noData"> {{getCartaFreccia(tra)}}</span>
          </div>
          <ng-container *ngFor="let param of getUsefulParams(tra.parameters);let i = index">
            <div *ngIf="travel?.evaluatedTravel && getRequiredParameter(travel.evaluatedTravel.requiredTravellerParameters, i, tra)?.description"
                 class="fakefield mb-2">
              <mat-form-field style="display: block !important;">
                <mat-label>{{getRequiredParameter(travel.evaluatedTravel.requiredTravellerParameters, i, tra)?.description}}</mat-label>
                <input [(ngModel)]="param.value" [disabled]="[52,53,61,21].includes(param.typeId)" required type="text"
                       [maxLength]="getRequiredParameter(travel.evaluatedTravel.requiredTravellerParameters, i, tra)?.typeDefinition.maxLength || 64"
                       [minLength]="getRequiredParameter(travel.evaluatedTravel.requiredTravellerParameters, i, tra)?.typeDefinition.minLength || 0"
                       matInput pattern="{{getRequiredParameter(travel.evaluatedTravel.requiredTravellerParameters, i, tra)?.typeDefinition.validationPattern}}">
              </mat-form-field>
            </div>
          </ng-container>
          <ng-template #noData> _ _ _ _</ng-template>

          <mat-card-actions *ngIf="editable">
            <button (click)="toggleDialog(i)" *ngIf="!tra.name || !tra.surname" mat-stroked-button>{{'ADD_TRAVELER'|translate}}</button>
            <button (click)="loadTravelersList(i)" *ngIf="!tra.name || !tra.surname" mat-stroked-button>{{'LOAD_TRAVELER'|translate}}</button>
            <button (click)="removeTraveler(i)" *ngIf="tra.name || tra.surname" mat-stroked-button>{{'REMOVE_TRAVELER'|translate}}</button>
            <button (click)="toggleEditDialog(i)" *ngIf="tra.name || tra.surname" mat-stroked-button>{{'EDIT_TRAVELER'|translate}}</button>
          </mat-card-actions>
        </mat-card-content>
      </mat-card>
    </div>

    <!--
    <mat-card (click)="addPax()" *ngIf="editable && passengers.length < maxPax" class="pax-card d-flex pointer align-items-center justify-content-center">
      <mat-card-content>
        <mat-icon>person_add</mat-icon>
      </mat-card-content>
    </mat-card>
    -->
  </div>
</mat-card-content>

<div class="text-left buttonrow">
  <button (click)="addPax()" *ngIf="editable && passengers.length < maxPax" mat-raised-button type="button">{{'ADD'|translate}} {{'TRAVELER'|translate}}</button>
</div>

<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="addTravelerModal" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-add (close)="closeEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [childMaxAge]="14" [newTraveler]="passengers[currentTraveler]"
                 [parent]="'trainCompany'"></app-add>
      </div>

    </div>
  </div>
</div>

<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="listTravelerModal" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div *ngIf="loadTravelers" class="modal-content">

      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-travelerslist (close)="closeListEvent($event)"(setTraveler)="travelerSet($event, currentTraveler)" [childMaxAge]="14"
                           [newTraveler]="passengers[currentTraveler]" [page]="'passengers'" [passengers]="passengers"></app-travelerslist>
        <hr/>
        <div class="buttonrow text-right">
          <button (click)="closeListEvent($event)" mat-stroked-button>{{'CLOSE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="EditTravelerModal" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div *ngIf="currentTraveler!=undefined && editingTraveler" class="modal-body">
        <app-edittraveler (close)="closeEditEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [currentTraveler]="passengers[currentTraveler]"
                          [parent]="'trainCompany'"></app-edittraveler>
      </div>
    </div>
  </div>
</div>
