<mat-card appearance="outlined" *ngIf="currentWagon">
  <mat-card-header>
    <div class="train-info">
      <div class="col-sm-4">
        <button (click)="prevWagon()" [disabled]="index === 0" color="primary" mat-icon-button type="button" class="iconButtons">
          <mat-icon>arrow_left</mat-icon>
        </button>
      </div>
      <div class="col-sm-4">
        <button (click)="nextWagon()" [disabled]="index === transportUnits.length - 1" color="primary" mat-icon-button type="button" class="iconButtons">
          <mat-icon>arrow_right</mat-icon>
        </button>
      </div>
      <div class="col-sm-6 mt-2">
        {{ 'TRAIN_MODULE.SEAT_SELECTION.unitIndex' | translate}}: {{ currentWagonAvailability.wagonId }} | {{ currentWagonAvailability.type }}
      </div>
      <div class="col-sm-6 mt-2">
        {{ 'TRAIN_MODULE.SEAT_SELECTION.availableQuantity' | translate}}: {{ currentWagon.availableQuantity }}
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div #wagonLayout class="wagon"></div>
    <div class="row">
      <div class="col-sm-3 mt-2 seat_selected">
        <mat-icon>event_seat</mat-icon>
        {{ 'TRAIN_MODULE.SEAT_SELECTION.SELECTED_SEAT' | translate }}
      </div>
      <div class="col-sm-3 mt-2 sedile">
        <mat-icon>event_seat</mat-icon>
        {{ 'TRAIN_MODULE.SEAT_SELECTION.AVAILABLE_SEAT' | translate }}
      </div>
      <div class="col-sm-3 mt-2 sediledis">
        <mat-icon>event_seat</mat-icon>
        {{ 'TRAIN_MODULE.SEAT_SELECTION.UNAVAILABLE_SEAT' | translate }}
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div class="row ml-2 mb-1">{{ 'PASSENGERS' | translate }}</div>
    <div *ngIf="currentWagonAvailability" class="row ml-3 mb-2">
      <div *ngFor="let p of passengers; let i = index" class="col-auto align-self-right">
        <div (click)="selectedPax = i" [ngClass]="(selectedPax === i) ? 'pax-selected' : 'pax-not-selected'" class="pax-selection">
          {{p.name | uppercase}} {{p.surname  | uppercase}}
          <span class="my-2 seat_selected">
            <mat-icon>event_seat</mat-icon>
            {{ selectedSeats[p.xmlId]?.airCraftNumber || ' -- ' }}
          </span>
        </div>
        <mat-form-field *ngIf="selectedPax === i" appearance="outline" class="full-width" hidden>
          <mat-label>
            {{ 'TRAIN_MODULE.SEAT_SELECTION.unitIndex' | translate}}: {{ selectedSeats[p.xmlId]?.wagon || currentWagonAvailability.wagonId }} |
            {{ currentWagonAvailability.type }}
          </mat-label>
          <mat-select id="seatSel" placeholder="{{ currentWagonAvailability.wagonId }}">
            <mat-option (click)="fillSelectedSeat(seat, currentWagonAvailability.wagonId, p)" *ngFor="let seat of currentWagonAvailability.seats"
                        [disabled]="!seat.available || isAlreadyPicked(seat, currentWagonAvailability.wagonId)" [value]="seat.airCraftNumber"
                        hidden id="{{seat.number+seat.airCraftNumber}}">
              {{seat.airCraftNumber}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
