/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CorporateCreateDto } from '../models/CorporateCreateDto';
import type { CorporateListResponseDto } from '../models/CorporateListResponseDto';
import type { CorporateUpdateDto } from '../models/CorporateUpdateDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class CorporateService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public corporateControllerCreate(
        requestBody: CorporateCreateDto,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v3/hotel/corporate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @param limit
     * @param skip
     * @param text
     * @returns any
     * @throws ApiError
     */
    public corporateControllerGetCorporateList(
        requestBody: CorporateListResponseDto,
        limit: number = 20,
        skip?: number,
        text?: number,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/hotel/corporate',
            query: {
                'limit': limit,
                'skip': skip,
                'text': text,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public corporateControllerPutCorporate(
        id: string,
        requestBody: CorporateUpdateDto,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v3/hotel/corporate/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public corporateControllerDeleteCorporate(
        id: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v3/hotel/corporate/{id}',
            path: {
                'id': id,
            },
        });
    }

}
