import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
  ) {}

  canActivate() {
    if (!this.authService.isLoggedIn()) {
      localStorage.setItem('fl_requestedUrl', JSON.stringify(window.location.pathname) );
      this.router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  }

}
