import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TrainTravel } from '../../../classes/train.models';

@Component({
  selector: 'train-selected-booking-summary',
  templateUrl: './selected-booking-summary.component.html',
  styleUrls: ['./selected-booking-summary.component.scss'],
})
export class SelectedBookingSummaryComponent implements OnInit{
  @Input() public departureStation : string;
  @Input() public arrivalStation : string;
  @Input() public currentTravel : TrainTravel;
  private company : string = 'trenitalia';

  constructor(){ }

  public ngOnInit() : void{
    if (this.currentTravel.italo){
      this.company = 'italo';
    }
  }

  getForwardJourney(){
    let forwardJourney;
    if (this.company === 'trenitalia'){
      forwardJourney = this.travelSolution2Journey(this.currentTravel.selectedTravelSolution);
    } else if (this.company === 'italo'){
      forwardJourney = this.currentTravel.italo.booking.ForwardJourney['detail'];
    }
    return forwardJourney;
  }

  getReturnJourney(){
    let swapper;
    let returnJourney;
    if (this.company === 'trenitalia'){
      swapper = this.departureStation;
      this.departureStation = this.arrivalStation;
      this.arrivalStation = swapper;
      returnJourney = this.travelSolution2Journey(this.currentTravel.selectedTravelSolution?.returnTravelSolution);
      swapper = this.departureStation;
      this.departureStation = this.arrivalStation;
      this.arrivalStation = swapper;
    } else if (this.company === 'italo'){
      returnJourney = this.currentTravel.italo.booking.ReturnJourney['detail'];
    }
    return returnJourney;
  }

  getFwdPrice() : number{
    let price;
    if (this.company === 'trenitalia'){
      price = this.travelSolution2Journey(this.currentTravel.selectedTravelSolution)?.totalPrice;
    } else if (this.company === 'italo'){
      price = this.currentTravel.italo.booking.ForwardJourney['fare'].FullFarePrice;
    }
    return price;
  }

  getRtnPrice() : number{
    let price;
    if (this.company === 'trenitalia'){
      price = this.travelSolution2Journey(this.currentTravel.selectedTravelSolution?.returnTravelSolution)?.totalPrice;
    } else if (this.company === 'italo'){
      price = this.currentTravel.italo.booking.ReturnJourney['fare'].FullFarePrice;
    }
    return price;
  }

  private travelSolution2Journey(travelSolution : any) : any{
    if (travelSolution === null || typeof travelSolution === 'undefined'){
      return;
    }
    const out = {
      departureStation: this.departureStation || this.currentTravel?.forwardJourney.departureStation,
      arrivalStation: this.arrivalStation || this.currentTravel?.forwardJourney.arrivalStation,
      departureDate: null,
      arrivalDate: null,
      totalPrice: 0,
    };
    /**
     * Since the route could be multisegment, dynamically compute the departure/Arrival station from the first/last node
     */
    const iterSolutionNodes = (Array.isArray((travelSolution.solutionNodes))) ? travelSolution.solutionNodes : [travelSolution.solutionNodes];
    for (const solutionNode of iterSolutionNodes){
      // console.log(solutionNode);
      if (typeof solutionNode === 'undefined'){ continue; }
      /**
       * Since the route could be multisegment, dynamically compute the departure/Arrival times from the first/last node
       */
      if (typeof solutionNode.departureTime === 'undefined'){
        if (typeof solutionNode.subSegments !== 'undefined'){
          for (const subSegment of solutionNode.subSegments){
            if (!out.departureDate){
              out.departureDate = new Date(subSegment.departureTime);
            }
            if (subSegment.arrivalTime){
              out.arrivalDate = new Date(subSegment.arrivalTime);
            }
          }
        }
      } else{
        if (!out.departureDate){
          out.departureDate = new Date(solutionNode.departureTime);
        }
        if (solutionNode.arrivalTime){
          out.arrivalDate = new Date(solutionNode.arrivalTime);
        }
      }
    }
    const offeredServices = (travelSolution.offeredServices) ? travelSolution.offeredServices : travelSolution.returnTravelSolution?.offeredServices;
    const offeredServicesArray = (Array.isArray((offeredServices))) ? offeredServices : [offeredServices];
    if (offeredServices){
      for (const offer of offeredServicesArray){
        if (offer.status === 'SELECTED'){
          out.totalPrice += Number.parseFloat(offer.price?.amount);
        }
      }
    }
    return out;
  }

  isVenice(){
    if (!(new Date() >= new Date('2024-7-14'))) return this.getForwardJourney().arrivalStation.toUpperCase().startsWith('VENEZIA') === true
    else return false
  }

}
