import {NgModule} from '@angular/core';
import {FlightCardComponent} from './flight-card.component';
import {PipesModule} from '../../pipes/pipes.module';
import {CommonModule} from '@angular/common';


@NgModule({
  declarations: [ FlightCardComponent ],
  exports: [
    FlightCardComponent
  ],
  imports: [
    CommonModule,
    PipesModule
  ]
})
export class FlightCardModule { }
