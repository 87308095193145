<div class="tpf">
  <div class="tpf__container">
    <div class="padding20">
      <button class="tpf__button-back" mat-icon-button routerLink="/policies"><mat-icon class="tpf__button-icon">keyboard_backspace</mat-icon></button>
      <div class="tpf__header buttonrow">

        <div *ngIf="currentPolicy">Configure <strong>{{currentPolicy.name}}</strong> Policy</div>

        <button mat-stroked-button  (click)="addItem()">
          Add new configuration
        </button>
      </div>
      <form class="tpf__form" [formGroup]="orderForm" (ngSubmit)="onSubmit()">
        <div formArrayName="items">
          <div *ngFor="let item of items?.controls ; let i = index;" class="tpf__row" [formGroupName]="i">
            <div class="tpf__item">
              <mat-form-field appearance="fill">
                <mat-label>Condition</mat-label>
                <mat-select formControlName="type">
                  <!--                   <mat-option [value]="'HOTEL_BEDNBREAKFAST'">Hotel bed and breakfast</mat-option>-->
                  <mat-option [value]="'HOTEL_STARS'">Hotel Stars</mat-option>
                  <mat-option [value]="'HOTEL_PRICE'">Hotel Price</mat-option>
                  <mat-option [value]="'FLIGHT_PRICE'">Flight price</mat-option>
                  <mat-option [value]="'TRANSFER_PRICE'">Transfer Price</mat-option>
                  <mat-option [value]="'CAR_PRICE'">Cars Price</mat-option>
                  <mat-option [value]="'TRAIN_PRICE'">Train Price</mat-option>
                  <mat-option [value]="'TRAIN_DESTINATION'">Train Destination</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="tpf__item">
              <mat-form-field appearance="fill">
                <mat-label>Operators</mat-label>
                <mat-select formControlName="operator">
                  <mat-option [value]="'>='">Equal or greater than</mat-option>
                  <mat-option [value]="'=='">Equal</mat-option>
                  <mat-option [value]="'!='">Not equal</mat-option>
                  <mat-option [value]="'<='">Equal or less than</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="tpf__item">
              <mat-form-field class="example-full-width">
                <mat-label>{{item.get('type').value === 'HOTEL_STARS' ? 'Stars' : 'Price'}}</mat-label>
                <input matInput type="number" formControlName="value" placeholder="Item description">
              </mat-form-field>
            </div>

            <div class="tpf__item">
              <mat-slide-toggle color="primary" [(ngModel)]="slideToggle[i]" formControlName="isAuthorized">{{ !slideToggle[i] ? 'To block' : 'To authorize'}}</mat-slide-toggle>
            </div>
            <div class="tpf__item">
              <div class="tpf__icon" [class.tpf__icon--active]="slideToggle[i]" >
                <mat-icon *ngIf="i !== 0  || orderForm.get('items').length > 1" class="tpf__icon__source" [class.tpf__delete_icon__source--active]="true" (click)="deletePolicy(i)" >delete </mat-icon>
                <mat-icon  class="tpf__icon__source" [class.tpf__icon__source--active]="slideToggle[i]" (click)="addAccess(i)" >settings</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="buttonrow text-right">
          <button class="tpf__button tpf__button--margin-top--md" mat-raised-button color="primary">Save</button>
        </div>

      </form>
    </div>

  </div>

</div>
