import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-moneychart',
  templateUrl: './moneychart.component.html',
  styleUrls: ['./moneychart.component.scss']
})
export class MoneychartComponent implements OnInit, OnChanges {
  @Input() chartmode: string;
  chartOptions: any;
  constructor() { }

  ngOnInit() {
    this.buildChart();
  }

  buildChart() {
    this.chartOptions = {
      theme: {
        palette: 'palette10',
        mode: this.chartmode,
      },
      series: [
        {
          name: 'Segmets',
          data: [1123, 1235, 1642, 1143, 1245, 1543, 1234, 1123, 1234, 1545, 167, 1129]
        },
        {
          name: 'Hotel',
          data: [124, 165, 173, 1189, 1321, 1564, 1789, 1231, 154, 190, 1267, 1456]
        },
        {
          name: 'Car',
          data: [1342, 1234, 1456, 1125, 189, 1167, 1145, 1243, 1456, 132, 1654, 1234]
        },
        {
          name: 'Train',
          data: [145, 1154, 1654, 1234, 112, 1543, 157, 132, 1456, 1321, 1345, 1412]
        },
      ],
      chart: {
        type: 'area',
        height: 230,
        zoom: {
          enabled: false
        },
      },
      /*stroke: {
        curve: 'straight'
      },*/
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top'
          }
        }
      },
      /*dataLabels: {
        enabled: false,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#fff']
        }
      },*/
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]
      }
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildChart();
  }
}
