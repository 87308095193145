<div>
  <div class="card marginbottom20">

    <div *ngIf="loading" class="loader">
      <div class="card text-center padding20">
        <div class="d-flex justify-content-center">
          <mat-spinner></mat-spinner>
        </div>
        <h5>{{'LOADING'|translate}}...</h5>
      </div>
    </div>

    <div *ngIf="!loading" class="row">

      <div class="col-lg-6 align-self-center pl-3">
        <button mat-button [matMenuTriggerFor]="tabMenu" class="listButton">
          <span *ngIf="query.options.tab=='active'">{{'ACTIVE_PNR'|translate}} ({{returnTabNum('active')}}) </span>
          <span *ngIf="query.options.tab=='linked'">{{'RESERVATIONS_PNR'|translate}} ({{returnTabNum('linked')}}) </span>
          <span *ngIf="query.options.tab=='waitingList'">{{'WAITING_LIST'|translate}} ({{returnTabNum('waitingList')}}) </span>
          <span *ngIf="query.options.tab=='control'">{{'CONTROLL_PNR'|translate}} ({{returnTabNum('control')}}) </span>
          <span *ngIf="query.options.tab=='issued'">{{'ISSUED_PNR'|translate}} ({{returnTabNum('issued')}}) </span>
          <span *ngIf="query.options.tab=='cancelled'">{{'DELETED_PNR'|translate}} ({{returnTabNum('cancelled')}}) </span>
          <span *ngIf="query.options.tab=='init'">{{'TEMPLATES_PNR'|translate}} ({{returnTabNum('init')}}) </span>
          <span class="material-icons"> keyboard_arrow_down </span>
        </button>
        <mat-menu #tabMenu="matMenu">
          <button mat-menu-item (click)="changeTab('active')">{{'ACTIVE_PNR'|translate}} ({{returnTabNum('active')}})</button>
          <button mat-menu-item (click)="changeTab('linked')">{{'RESERVATIONS_PNR'|translate}} ({{returnTabNum('linked')}})</button>
          <button mat-menu-item (click)="changeTab('waitingList')">{{'WAITING_LIST'|translate}} ({{returnTabNum('waitingList')}})</button>
          <button mat-menu-item (click)="changeTab('control')">{{'CONTROLL_PNR'|translate}} ({{returnTabNum('control')}})</button>
          <button mat-menu-item (click)="changeTab('issued')">{{'ISSUED_PNR'|translate}} ({{returnTabNum('issued')}})</button>
          <button mat-menu-item (click)="changeTab('cancelled')">{{'DELETED_PNR'|translate}} ({{returnTabNum('cancelled')}})</button>
          <button mat-menu-item (click)="changeTab('init')">{{'TEMPLATES_PNR'|translate}} ({{returnTabNum('init')}})</button>

        </mat-menu>

        <button mat-button [matMenuTriggerFor]="orderMenu" class="listButton">
          {{sort|translate}} <span class="material-icons"> keyboard_arrow_down </span>
        </button>
        <mat-menu #orderMenu="matMenu">
          <button mat-menu-item (click)="sortValue('createdAt')">{{'CREATED_DATE'|translate}}</button>
          <button mat-menu-item (click)="sortValue('generatedAt')">{{'GENERATED_DATE'|translate}}</button>
          <button mat-menu-item *ngIf="query.options.tab=='issued'" (click)="sortValue('issuedOn')">{{'ISSUED_DATE'|translate}}</button>
          <button mat-menu-item *ngIf="query.options.tab=='linked'|| query.options.tab=='deadline'" (click)="sortValue('timeLimit')">{{'EXPIRY_DATE'|translate}}</button>
          <button mat-menu-item *ngIf="query.options.tab=='cancelled'" (click)="sortValue('cancelledAt')">{{'CANCELLATION_DATE'|translate}}</button>
          <button mat-menu-item (click)="sortValue('fare.amount')">{{'PRICE'|translate}}</button>

        </mat-menu>

        <button mat-icon-button (click)="reverseOrder()">
          <mat-icon *ngIf="query.order==='asc'" matTooltip="{{'ASC'|translate}}">vertical_align_top</mat-icon>
          <mat-icon *ngIf="query.order==='desc'" matTooltip="{{'DESC'|translate}}">vertical_align_bottom</mat-icon>
        </button>

        <button mat-icon-button (click)="openFilters()">
          <mat-icon matTooltip="{{'SEARCH'|translate}}">search</mat-icon>
        </button>
      </div>
      <div class="col-lg-6 justify-content-lg-end justify-content-xl-end text-right">
        <div class="paginationr pl-3">
          <span class="d-none d-sm-inline-block">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>

          <span>
                {{'PAGE'|translate}}
            <mat-form-field class="paginationwidth">
                  <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                    <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p+1}}</mat-option>
                  </mat-select>
                </mat-form-field>
            {{'OF'|translate}} {{maxpages}}
              </span>

          <button mat-icon-button (click)="prev()" [disabled]="currentpage==0">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button mat-icon-button (click)="next()" [disabled]="currentpage==(maxpages-1)">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>

      </div>

    </div>

    <div *ngIf="!loading" class="collapse pnrfilters padding10">
      <hr/>
      <div class="row marginbottom10">
        <div class="col-12 col-sm-6 col-md-4 mb-2">
          <form autocomplete="off" class="example-form">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'SEARCH_PNR'|translate}}</mat-label>
              <input [ngModelOptions]="{standalone: true}" autocomplete="off" matInput type="text" [(ngModel)]="query.text" (change)="setFilterText()">
              <button *ngIf="query.text" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilterText()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-hint align="start">{{'DIGIT_YOUR_SEARCH'|translate}}</mat-hint>
            </mat-form-field>
          </form>
        </div>

        <div class="col-12 col-sm-6 col-md-4 mb-2" *ngIf="mainComponent.loggedUser.role.superAdmin">
          <!-- <app-companies [filtering]="filterCompanies" [query]="query"></app-companies> -->
        </div>
        <div class="col-12 mb-2 cpfilter" *ngIf="filterCompanies.length>0">
          <hr/>
          <h6 class="mb-2">{{'FILTERING_COMPANIES'|translate}}</h6>
          <div *ngIf="filterCompanies.length>0">
            <mat-chip-listbox aria-label="Companies selections" class="d-inline" *ngIf="filterCompanies[0].name">
              <mat-chip-option *ngFor="let element of filterCompanies">
                {{element.name}} <mat-icon (click)="removeCpFilter(element._id)" matChipRemove>cancel</mat-icon>
              </mat-chip-option>

            </mat-chip-listbox>
            <div *ngIf="!filterCompanies[0].name">
              {{filterCompanies.length}} {{'COMPANIES_SELECTED'|translate}}
              <button mat-button (click)="clearAllCp()">{{'CLEAR_ALL_CP'|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="" *ngIf="progress">
      <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
    </div>
  </div>

  <div class="hotelList" *ngIf="reservationlist && reservationlist.list.length > 0">
    <div class="card padding20 mb-3" *ngFor="let reservation of reservationlist.list" style="padding:20px;">
      <div class="row">
        <div class="col-9 align-self-center">

          <div class="row">
            <div class="col-lg-4 align-self-center">
              <div *ngIf="(reservation.status==='init' || reservation.status==='linked' || reservation.status==='issued') && !reservation.paper" class="buttonrow mb-2">
                <button mat-stroked-button (click)="addCars(reservation._id)">{{'ADD_TO_FOLDER'|translate}}</button>
              </div>
              <div *ngIf="reservation.paper" class="buttonrow mb-2">
                <a mat-stroked-button routerLink="/dossier/list/{{reservation.paper}}">{{'GO_TO_DOSSIER'|translate}}</a>
              </div>
              <div class="pnrliststatus marginbottom5">
                <span class="init" *ngIf="reservation.status==='init'">{{'TEMPLATE'|translate}}</span>
                <span class="linked" *ngIf="reservation.status==='linked'">{{'LINKED'|translate}}</span>
                <span class="issued" *ngIf="reservation.status==='issued'">{{'ISSUED'|translate}}</span>
                <span class="flown" *ngIf="reservation.status==='flown'">{{'FLOWN'|translate}}</span>
                <span class="expired" *ngIf="reservation.status==='expired'">{{'EXPIRED'|translate}}</span>
                <span class="cancelled" *ngIf="reservation.status==='cancelled'">{{'CANCELLED'|translate}}</span>

                <div *ngIf="reservation.locator" class="listlocator" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
                  <span>{{reservation.locator}}</span>
                </div>
              </div>
            </div>

            <div class="col-lg-4 align-self-center">
              <hr class="d-sm-block d-md-none d-lg-none"/>
              <div class="travelinfo mr-2">
                <div class="mb-2 smalltext">
                  <span class="material-icons" style="vertical-align: bottom">
                    directions_car
                  </span>
                  {{reservation.car.model}}
                </div>
                <div>
                  <div class="carLocations">
                    <div class="carLocation">
                      <div class="dateAtLocation">
                        {{returnDate(reservation.car.pickUpInfo.date)|date:'dd/MM/yyyy - HH:mm'}}
                      </div>
                      <span class="material-icons">
                        gps_fixed
                      </span>
                      {{reservation.car.pickUpInfo.location}}
                    </div>
                    <div class="rotateArrow">
                      <span class="material-icons">
                        arrow_right_alt
                      </span>
                    </div>
                    <div class="carLocation">
                      <span class="material-icons">
                        gps_fixed
                      </span>
                      {{reservation.car.dropOffInfo.location}}
                      <div class="dateAtLocation">
                        {{returnDate(reservation.car.dropOffInfo.date)|date:'dd/MM/yyyy - HH:mm'}}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 align-self-center">
              <hr class="d-md-block d-lg-none"/>
              <div *ngIf="reservation.status==='init'" class="dateaction">
                {{'BOOKED_ON'|translate}} {{reservation.createdAt|date:'dd/MM/yyyy - HH:mm'}}
              </div>
              <div *ngIf="reservation.status==='issued'" class="dateaction">
                {{'ISSUED_ON'|translate}} {{reservation.updatedAt|date:'dd/MM/yyyy - HH:mm'}}
              </div>
              <div *ngIf="reservation.passengers.length>0">
                <div *ngFor="let guest of reservation.passengers" class="listpnrsinglepasseng">
                  <span *ngIf="guest.surname">{{guest.surname}}</span> <span *ngIf="guest.name"> {{guest.name}}</span> <sup> ({{guest.type}})</sup>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-3 align-self-center text-center">

          <div class="reservationimage">
            <img class="rentCpIcon" src="{{reservation.rentCompany.images[0]}}"/>
            <span style="text-transform:uppercase;">
              {{reservation.rentCompany.name}}
            </span>
          </div>
          <div class="reservationPrice marginbottom5">
            <div class="recoprice" *ngFor="let rate of reservation.car.rates">
              {{rate.type}}: {{rate.amount|number:'.2-2'}}€
            </div>
          </div>
          <div class="linktopnr">
            <a routerLink="/car-rent/reservations/{{reservation._id}}">{{'OPEN_RESERVATION'|translate}}</a>
          </div>
        </div>

        <div class="col-12 pnrlistuser">
          <hr/>
          <div>
            <a routerLink="/users/list/{{reservation.user._id}}">{{reservation.user.surname}} {{reservation.user.name}}</a>
            <mat-icon>arrow_right_alt</mat-icon>
            <a routerLink="/companies/list/{{reservation.company._id}}">{{reservation.company.name}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
