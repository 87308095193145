<div class="loginpage h-100">
  <div class="row no-gutters h-100">

    <div class="col-lg-4 d-none d-lg-flex leftlog">
      <div class="bgopacity h-100">

      </div>
    </div>

    <div class="col-lg-8 my-auto"> <!-- my-auto -->
      <div class="padding10 text-right">

        <button mat-icon-button [matMenuTriggerFor]="langmenu" aria-label="Example icon-button with a menu">
          <img *ngIf="appcompo.currentLang==='it'" class="" src="assets/img/flags/italian_flag.png"/>
          <img *ngIf="appcompo.currentLang==='en'" class="" src="assets/img/flags/english_flag.png"/>
        </button>
        <mat-menu #langmenu="matMenu">
          <button [ngClass]="{'active':appcompo.currentLang=='en'}" (click)="appcompo.useLanguage('en')" mat-menu-item>
            <img src="assets/img/flags/english_flag.png"/> English
          </button>
          <button [ngClass]="{'active':appcompo.currentLang=='it'}" (click)="appcompo.useLanguage('it')" mat-menu-item>
            <img src="assets/img/flags/italian_flag.png"/> Italiano
          </button>
        </mat-menu>

      </div>

      <div class="padding20 maxwidth">
        <div class="toplogo text-center">
          <img *ngIf="!appcompo.isSbt && !appcompo.isB2B" src="assets/img/themes/Logo-Flyleo-blue.png"/>
          <img *ngIf="appcompo.isB2B" src="assets/img/themes/b2blogo.png"/>
          <img *ngIf="appcompo.isSbt" src="assets/img/gattinoni.png"/>
        </div>
        <div class="toplogin text-center marginbottom20">
          <h3 class="marginbottom10">{{'WELCOME'|translate}}!</h3>
          <h4 class="ingrey">{{'SIGN_IN_TO_CONTINUE'|translate}}
            <span *ngIf="!appcompo.isSbt && !appcompo.isB2B"> flyLEO</span>
            <span *ngIf="appcompo.isSbt"> SBT</span>
            <span *ngIf="appcompo.isB2B"> B2B</span>
            .</h4>
        </div>
        <div *ngIf="loading">
          <hr/>
          <p>{{'LOADING'|translate}}</p>
          <mat-progress-bar mode="buffer"></mat-progress-bar>
        </div>
      </div>
    </div>

  </div>
</div>

