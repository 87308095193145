<div mat-dialog-content>
    <div class="mb-3">
        {{'EDIT_FIELD'|translate}}
    </div>
    <hr/>
    <div>

      <div class="">
          <div class="row" style="font-size: 12px">
            <div class="col-lg-5 align-self-center">
              <div class="mb-3">
                <input type="text" class="customInput w-100" placeholder="{{'NOME_CAMPO'|translate}}" [(ngModel)]="data.field.fieldName">
              </div>
            </div>
            <div class="col-lg-5 align-self-center">
              <div class="mb-3">
                <select class="customSelect w-100" [(ngModel)]="data.field.fieldType">
                  <option *ngFor="let fieldType of data.fieldTypes" [value]="fieldType">{{fieldType|translate}}</option>
                </select>
              </div>

             
            </div>

            <div class="col-lg-5 align-self-center">
              <div class="mb-3">
                <small>{{'SIAP_FIELD'|translate}}</small>
                <select class="customSelect w-100" [(ngModel)]="data.field.siapField">
                  <option *ngFor="let siapNum of data.siapNumbers" [value]="siapNum" [disabled]="alreadySelected(siapNum)">
                    <span *ngIf="siapNum != 'C0'">{{siapNum}}</span>
                    <span *ngIf="siapNum == 'C0'">C10</span>
                  </option>
                </select>
              </div>
              
            </div>

            <div class="col-lg-5 align-self-center">
              <div class="ml-2">
                <mat-checkbox color="primary" class="example-margin" [(ngModel)]="data.field.isOptional">{{'OPTIONAL'|translate}}</mat-checkbox>
              </div>
            </div>
            
          </div>

          <div *ngIf="isOptionType(data.field.fieldType)">
            <div *ngIf="data.field.fieldOptions.length > 0">
              <div class="pt-2 mb-2">{{'OPTIONS'|translate}}</div>
              <div *ngFor="let option of data.field.fieldOptions; let i = index">
                <div class="row mb-2">
                  <div class="col-5 align-self-center">
                    <input class="optionInput w-100" [(ngModel)]="option.label" placeholder="Label" type="text"/>
                  </div>
                  <div class="col-5 align-self-center">
                    <input class="optionInput w-100" [(ngModel)]="option.value" placeholder="Value" type="text"/>
                  </div>
                  <div class="col-2 align-self-center">
                    <button class="numberAction" (click)="removeOption(i)">
                      <span class="vertical material-icons" >delete_outline</span>
                    </button>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="">
              
              <button class="vertical addOptionBtn" (click)="addOption()">{{'ADD_OPTION'|translate}}</button>
            </div>

          </div>
        </div>
    
    </div>
</div>

<div mat-dialog-actions align="end">
  <button class="abortButton mr-2" (click)="onNoClick()" cdkFocusInitial>{{'ABORT'|translate}}</button>
  <button *ngIf="data.index !== undefined" class="actionButton" [mat-dialog-close]="data" [disabled]="!data.field.fieldType || !data.field.fieldName">{{'SAVE'|translate}}</button>
</div>
