<div class="row">
  <div class="col-12">
    <mat-stepper orientation="horizontal" #stepper>
      <mat-step [stepControl]="firstFormGroup" >
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>{{'POST_BOOKING.CHANGES_SELECTION'|translate}}</ng-template>
          <div class="row" *ngif="this.changesLowCost.result && this.changesLowCost.result.length === 0">
            <div class="col-12 d-flex justify-content-center">
              <mat-icon>mood_bad</mat-icon>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <p>{{'POST_BOOKING.NO_CHANGES_AVAILABLE'|translate}}</p>
            </div>
          </div>
          <div class="col-12" *ngFor="let c of this.changesLowCost.result; let i=index">
            <p><mat-checkbox *ngIf="c.label !== 'ORIGIN' && c.label !== 'DESTINATION'" formControlName="firstCtrl" [checked]="c.selected" [(ngModel)]="c.selected" (change)="onChange($event, i)"> {{'POST_BOOKING.'+ c.label | translate}}</mat-checkbox></p>
          </div>
          <div>
            <button mat-button (click)="goToForm()">{{'NEXT'|translate}}</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Effettua una nuova ricerca:</ng-template>
          <div class="col-12 col-lg-6" *ngIf="this.toCancel">
            <p class="mt-2">{{"POST_BOOKING.CANCEL_CONFIRM"|translate}}?</p>
            <div class="my-2">
              <button mat-button class="mx-2" (click)="cancellationRequest()">{{"POST_BOOKING.CONFIRM"|translate}}</button>
              <button mat-button class="mx-2" (click)="goToFirst()">{{"ABORT"|translate}}</button>
            </div>
          </div>
          <div *ngIf="checking" class="loader" style="border: 1px solid black">
            <div class="justify-content-center">
              <div class="card text-center padding20">
                <mat-spinner  class="loader"></mat-spinner>
                <h5>{{'LOADING'|translate}}...</h5>
                <h5>L'operazione può duare fino a 45 secondi</h5>
              </div>
            </div>
          </div>
          <div class="col-12 mb-2" *ngIf="this.travelChange && !this.ancillaryChange && !this.toCancel">
            <div class="row" *ngFor="let i of reservation.itineraries">
              <div class="col-12 col-md-6 text-center mb-sm-2">
                <small>partenza: {{i.flights[0].departure.location}}</small>
                <small> --> </small>
                <small>{{moment(i.flights[0].departure.dateTime).format('DD/MM/yyyy')}}</small>
              </div>
              <div class="col-12 col-md-6 text-center mb-sm-2" *ngIf="reservation.itineraries.length > 0">
                <small>arrivo: {{i.flights[i.flights.length-1].arrival.location}}</small>
                <small> --> </small>
                <small>{{moment(i.flights[i.flights.length-1].arrival.dateTime).format('DD/MM/yyyy')}}</small>
              </div>
            </div>

            <app-changes-form *ngIf="!loading" [reservation] = "reservation" [changesRequired] = "changesRequired" [changesLowCost] = "changesLowCost" ></app-changes-form>

            <div class="my-2">
              <button mat-button class="mx-2" (click)="goToFirst()">{{'PREVIOUS'|translate}} </button>
            </div>
          </div>
          <div class="col-12" *ngIf="!this.travelChange && this.ancillaryChange && !this.toCancel">
            <p>Qui si modificano gli ancillari</p>
            <div class="my-2">
              <button mat-button class="mx-2">Conferma</button>
              <button mat-button class="mx-2" (click)="goToFirst()">Annulla</button>
            </div>
          </div>
          <div class="col-12" *ngIf="this.travelChange && this.ancillaryChange && !this.toCancel">
            <p>Qui si modificano gli ancillari e le date</p>
            <div class="my-2">
              <button mat-button class="mx-2">Conferma</button>
              <button mat-button class="mx-2" (click)="goToFirst()">Annulla</button>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>{{"POST_BOOKING.OP_RESULT"|translate}}</ng-template>
        <div *ngIf="changesLowCost">
          <div *ngIf="changesLowCost.Status && changesLowCost.Status === 'CancelSucceeded' ">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-md-6 p-3">
                <h2 class="mb-2">{{"POST_BOOKING.CANCEL_OK"|translate}}!</h2>
                <div *ngFor="let valueRetured of changesLowCost.SupplierConfirmationDataItemList.SupplierConfirmationDataItem">
                  <div *ngIf="valueRetured.Name === 'Paid Amount'">
                    {{"POST_BOOKING.PAYED_AMOUNT"|translate}}: {{valueRetured.Value}}
                  </div>
                  <div *ngIf="valueRetured.Name === 'Refund Amount'">
                    {{"POST_BOOKING.REFOUNDED_AMOUNT"|translate}}: {{valueRetured.Value}}
                  </div>
                  <div *ngIf="valueRetured.Name === 'Supplier Charge'">
                    {{"POST_BOOKING.OPERATION_PRICE"|translate}}: {{valueRetured.Value}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="changesLowCost.Status && changesLowCost.Status === 'CancelFailed'">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-md-6 border-danger p-3" style="border: 1px solid;">
                <h2 class="text-center text-danger">{{"POST_BOOKING.CANCELLATION_FAILD"|translate}}</h2>
                <p class="text-center text-danger">{{"POST_BOOKING.ASK_SUPPORT"|translate}}...</p>
              </div>
            </div>

          </div>
        </div>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button (click)="stepper.reset()">Reset</button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
