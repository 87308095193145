<div class="pagetitle">
  Travel Policies
</div>
<div class="maincontent">
  <div class="padding20">
    <mat-card appearance="outlined" class="p-3">
      <div class="create-policy">
        <div class="create-policy__container">
          <div class="create-policy__header buttonrow">
            <div class="create-policy__title">
              Travel policy
            </div>
            <button (click)="createPolicyDialog()"  mat-stroked-button>Create</button>
          </div>
          <div >
            <span *ngIf="!defaultUserLoading">
              Default user: {{defaultUser ? (defaultUser.name + ' ' + defaultUser.surname) : 'NO DEFAULT USER ASSIGNED'}}
            </span>
            <span *ngIf="defaultUserLoading"> Default user: Loading...</span>
            <button mat-icon-button color="primary" (click)="openSelectDefaultUserDialog()" class="iconButtons">
              <mat-icon>edit</mat-icon>
            </button>
          </div>

          <div class="create-policy__body">
            <div class="create-policy__spinner">
              <mat-spinner *ngIf="isLoading"></mat-spinner>
            </div>
            <ng-container *ngIf="!isLoading">
              <div *ngFor="let policy of policyList; let i = index" class="create-policy__list">
                <div class="create-policy__list-item">
                  Name: <strong>{{policy.name}}</strong>
                </div> <span> / </span>
                <div *ngIf="policy.group" class="create-policy__list-item">
                  Role: <strong>{{policy.group.name}}</strong>
                </div>
                <div class="create-policy__buttons buttonrow">
                  <div class="create-policy__list-item">
                    <button (click)="editPolicy(policy._id, i)" mat-icon-button class="iconButtons">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </div>
                  <div class="create-policy__list-item buttonrow">
                    <button *ngIf="configurationButton[i]; else enabledButton" ngDefaultControl matTooltip="Policy is not saved!"
                            matTooltipPosition="above" [class.create-policy__button--disable]="configurationButton[i]" [(ngModel)]="configurationButton[i]"
                            aria-label="Button that displays a tooltip that can be programatically disabled" class="iconButtons" mat-icon-button>
                      <mat-icon >settings</mat-icon>
                    </button>
                    <ng-template #enabledButton>
                      <button class="iconButtons" [routerLink]="['/policy/conditions', policy._id]" mat-icon-button>
                        <mat-icon >settings</mat-icon>
                      </button>
                    </ng-template>
                  </div>
                  <div class="create-policy__list-item">
                    <button class="iconButtons" color="warn" (click)="deletePolicy(policy._id, i)" mat-icon-button>
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>

