import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FlightService} from '../../../../../../services/flight/flight.service';
import {CompaniesService} from '../../../../../../services/profile/companies.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatDialog} from '@angular/material/dialog';
import {MainComponent} from '../../../../../mainmodule/main/main.component';
import {Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {PriceService} from '../../../../../../services/price/price.service';
import {IatacompleteService} from '../../../../../../services/iatacomplete/iatacomplete.service';
import * as $ from 'jquery';
import {SearchRes} from '../../../../../../classes/flights/search-res';
import {NewTraveller} from '../../../../../../classes/travelers/newTraveller';
import {RecodialogComponent} from './recodialog/recodialog.component';
import {Tariffes} from '../../../../../../classes/flights/tariffes';
import {AppComponent} from '../../../../../../app.component';

class Range {
  minDate = Number.MAX_SAFE_INTEGER;
  maxDate = 0;
}

@Component({
  selector: 'app-calendarresults',
  templateUrl: './calendarresults.component.html',
  styleUrls: ['./calendarresults.component.scss']
})
export class CalendarresultsComponent implements OnInit {
  @Input() searchResults: SearchRes;
  @Input() selections: any;
  @Input() request: any;
  @Output('updateSearch') change: EventEmitter<any> = new EventEmitter<any>();
  formvisible: boolean;
  company: any;
  table: any;
  minPrice: number;
  maxPrice: number;
  travelers: NewTraveller[];
  newTraveler: NewTraveller;
  finalTable: any;
  constructor(
    private flightService: FlightService,
    private companyService: CompaniesService,
    private bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    public mainComponent: MainComponent,
    private router: Router,
    notifierService: NotifierService,
    public priceservice: PriceService,
    private changeDetectorRef: ChangeDetectorRef,
    public iataComplete: IatacompleteService,
    private appComponent: AppComponent,
  ) {
    this.minPrice = 50000;
    this.maxPrice = 0;
    this.travelers = [];
    this.finalTable = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    };
    console.log('this.finalTable => ', this.finalTable);
  }

  ngOnInit(): void {
    this.getCompany();
    this.table = this.getRangeDate(this.searchResults.itineraries);
    for (let i = 0; i < this.request.passengers.adt; i++) {
      const traveler = new NewTraveller();
      traveler.type = 'ADT';
      this.travelers.push(traveler);
    }
    for (let i = 0; i < this.request.passengers.chd; i++) {
      const traveler = new NewTraveller();
      traveler.type = 'CHD';
      this.travelers.push(traveler);
    }
    for (let i = 0; i < this.request.passengers.inf; i++) {
      const traveler = new NewTraveller();
      traveler.type = 'INF';
      this.travelers.push(traveler);
    }
    console.log('this.table => ', this.table);
    this.createTable();
  }

  createTable() {
    if (this.table[1]) {
      for (let r = 0; r < this.table[1].length; r++) {
        const row = this.table[1][r];
        for (let c = 0; c < this.table[0].length; c++) {
          const cell = this.table[0][c];
          const reco = this.getRec(cell, row);
          if(this.finalTable[r]){
            this.finalTable[r].push(reco);
          }
        }
      }
    } else {
      for (let c = 0; c < this.table[0].length; c++) {
        const cell = this.table[0][c];
        const reco = this.getRec(cell, cell);
        if(this.finalTable[0]){
          this.finalTable[0].push(reco);
        }
      }
    }
    console.log('this.finalTable => ', this.finalTable);
    for (let i = 0 ; i < 7; i++) {
      console.log('this.finalTable row => ', this.finalTable[i]);
      for (let c = 0; c < this.finalTable[i].length; c ++) {
        let reco = this.finalTable[i][c];
        if (reco !== undefined) {
          for (let z = 0; z < reco.itineraries.length; z++) {
            this.changeSelection(reco.ref, z, reco.itineraries[z].sections[0].ref);
          }
          if (reco.negotiated) {
            reco = this.askFares(reco, reco.ref);
          }
        }
      }
    }
  }

  changeSelection(ref, iti, refIti) {
    if (this.selections[ref][iti] === refIti) { return; } else { this.selections[ref][iti] = refIti; }
  }

  askFares(reco, ref) {
    // tslint:disable-next-line:no-shadowed-variable
    const recommendation = JSON.parse(JSON.stringify(reco));
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      recommendation.itineraries[i] = this.searchResults.itineraries[i].sections[this.selections[ref][i] - 1];
    }
    reco.rates = [];
    console.log('recommendation =>', reco);
    recommendation.options = this.request.options;
    this.flightService.askFares(recommendation).subscribe((res: Tariffes[]) => {
      console.log('res => ', res);
      reco.rates = res;
      for (let rate = 0; rate < reco.rates.length; rate++) {
        if (rate === 0) {
          reco.rates[rate].selected = true;
          reco.tariff = reco.rates[rate];
        } else {
          reco.rates[rate].selected = false;
        }
      }
      reco.fare = reco.rates[0].fare;
      console.log('this.tariffes => ', res);
      if (!reco.fare.publicAmount) {
        reco.negotiated = false;
      }
      return reco;
    }, error => {
      console.log('error => ', error);
      return reco;
    });
  }

  getCompany() {
    this.companyService.getCompany(this.mainComponent.loggedUser.company._id).subscribe((res) => {
      this.company = res;
    });
  }

  toggleForm() {
    this.formvisible = !this.formvisible;
    $('#searchCard').collapse('toggle');
    $('.mat-sidenav-content').animate({scrollTop: 0}, 300);
  }

  getArrayDates(range) {
    let loop = true;
    const arrayDates = [new Date(range.minDate)];
    while (loop) {
      const last = new Date(arrayDates[arrayDates.length - 1].getTime());
      const dateToAdd = new Date(last.setDate(last.getDate() + 1));
      arrayDates.push(dateToAdd);
      if (dateToAdd.getDate() === new Date(range.maxDate).getDate()) {
        loop = false;
      }
    }
    return arrayDates;
  }

  getRangeDate(itineraries) {
    const rangeDates = [];
    itineraries.forEach(r => {
      const range = new Range();
      r.sections.forEach(s => {
        const depDate = new Date(s.flights[0].departure.date).getTime();
        if (depDate > range.maxDate) {
          range.maxDate = depDate;
        }
        if (depDate < range.minDate) {
          range.minDate = depDate;
        }
      });
      rangeDates.push(this.getArrayDates(range));
    });

    return rangeDates;
  }

  getSectionsIndex(dates = [], itineraries) {
    const indexes = [];
    itineraries.forEach((r, i) => {
      const filter = r.sections.filter(s => new Date(s.flights[0].departure.date).getTime() === new Date(dates[i]).getTime())
        .map(r => r.ref);
      indexes.push(filter);
    });
    return indexes;
  }

  getRecommendations(refs = [], recommendations) {
    const filtered = [];
    recommendations.forEach(r => {
      // filter sections;
      let noEmpty = true;
      for (const [i, itinerary] of r.itineraries.entries()) {
        itinerary.sections = [...itinerary.sections].filter(s => {
          return (refs[i].includes(s.ref));
        });
        noEmpty = (noEmpty && itinerary.sections.length > 0);
      }
      if (noEmpty) {
        if (this.minPrice > r.fare.amount) {
          this.minPrice = r.fare.amount;
        }
        if (this.maxPrice < r.fare.amount) {
          this.maxPrice = r.fare.amount;
        }
        filtered.push(r);
      }
    });

    return filtered;
  }

  getCurrent(dateOne, dateTwo) {
    if (dateOne && dateTwo) {
      // tslint:disable-next-line:max-line-length
      const refs = this.getSectionsIndex([new Date(dateOne), new Date(dateTwo)], JSON.parse(JSON.stringify(this.searchResults.itineraries)));
      // tslint:disable-next-line:max-line-length
      const filtered = this.getRecommendations(JSON.parse(JSON.stringify(refs)), JSON.parse(JSON.stringify(this.searchResults.recommendations)));
      if (filtered.length === 0) {
        return 'X';
      } else {
        if (filtered[0].negotiated && !this.mainComponent.appcomponent.isB2B) {
          return filtered[0].fare.amount;
        } else if (filtered[0].negotiated && this.mainComponent.appcomponent.isB2B) {
          return filtered[0].fare.amount;
        } else {
          return filtered[0].fare.amount;
        }
      }
    }
  }

  getRec(dateOne, dateTwo) {
    if (dateOne && dateTwo) {
      // tslint:disable-next-line:max-line-length
      const refs = this.getSectionsIndex([new Date(dateOne), new Date(dateTwo)], JSON.parse(JSON.stringify(this.searchResults.itineraries)));
      // tslint:disable-next-line:max-line-length
      const filtered = this.getRecommendations(JSON.parse(JSON.stringify(refs)), JSON.parse(JSON.stringify(this.searchResults.recommendations)));
      if (filtered.length > 0) {
        refs.forEach((r, i) => {
          filtered[0].itineraries[i].sections = filtered[0].itineraries[i].sections.filter(f => r.includes(f.ref));
        });
      }
      if (filtered.length === 0) {
        return undefined;
      } else {
        filtered[0].dateOne = dateOne;
        filtered[0].dateTwo = dateTwo;
        return filtered[0];
      }
    }
  }

  getValuePrice(value) {
    if (value) {
      const price = value;
      const perc = this.maxPrice - price;

      if (price === this.minPrice) {
        return 'calbestprice';
      } else if (price <= this.minPrice + (perc)) {
        return 'calmiddleprice';
        // tslint:disable-next-line:max-line-length
      } else if (price > this.minPrice + (perc) && price <= this.minPrice + (perc * 4)) {
        return 'calmiddlepriceexp';
      } else if (price > this.minPrice + (perc * 4)) {
        return 'calexpensiveprice';
      }
    } else {
      return 'novalue';
    }

  }

  openDialog(recommendation) {
    console.log('this.recommendation =>', recommendation);
    // tslint:disable-next-line:no-shadowed-variable
    recommendation.options = this.request.options;
    recommendation.company = this.company;
    recommendation.itinew = [];
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      recommendation.itinew[i] = this.searchResults.itineraries[i].sections[this.selections[recommendation.ref][i] - 1];
    }

    const dialogRef = this.dialog.open(RecodialogComponent, {
      data: {
        company: this.company,
        recommendation,
        passengers: this.travelers,
        searchResults: this.searchResults,
        dialog: this.dialog,
        selections: this.selections,
        request: this.request,
        isB2B: this.appComponent.isB2B,
        isSbt: this.appComponent.isSbt,
        isGat: this.appComponent.isGat,
        userSettings: this.mainComponent.userSettings,
        // passPort: this.iataComplete.returnCarrierOPTPassport(recommendation.marketingCarrier, recommendation.itineraries),
        mainComponent: this.mainComponent,
        appComponent: this.appComponent
      },
      disableClose: true,
      width: '100%',
      maxWidth: '960px',
      // height: '100%',
      // maxHeight: '900px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Dialog result:', result);
        this.change.emit(result);
      }
    });
  }
}
