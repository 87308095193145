import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { TrainMainComponent } from '../common/train-main/train-main.component';
import { HoldItaloBookingResponse } from '../../classes/train.models';
import { TrainService } from '../../train.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CompaniesService } from '../../../../services/profile/companies.service';
import { MainComponent } from '../../../mainmodule/main/main.component';
import { Fops } from '../../../companies/classes/fops';
import { DossierService } from '../../../../services/dossier/dossier.service';
import { TravelPolicyService } from '../../../../services/travel-policy/travel-policy.service';

/**
 * Finalizes the booking process for italo
 */
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent extends TrainMainComponent{

  constructor(private service : TrainService,
              private fopsService : CompaniesService,
              private travelPolicyService : TravelPolicyService,
              public mainComponent : MainComponent,
              public router : Router,
              public titleService : Title,
              public translate : TranslateService,
              public dossierService : DossierService,
              private fb : UntypedFormBuilder){
    super(router, titleService, translate);
  }
  public processingPayment$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public fetchingCards$ : BehaviorSubject<boolean> = new BehaviorSubject(true);
  public booking : HoldItaloBookingResponse;
  public payForm : UntypedFormGroup;
  public contactForm : UntypedFormGroup;
  cards : Fops[] = [];
  selectedCard : Fops;
  alreadyRequested = false;
  costCenter: string | undefined;

  public ngOnInit() : void{
    this.booking = this.trainTravel.italo.holdBookingResponse;
    this.initForm(this.booking);
    const companyId = this.mainComponent.loggedUser.company._id;
    this.fopsService.getMethods(companyId).subscribe((res) => {
      this.cards = [] // res.filter(c => !c.cash && !c.manual && ['*', 'train'].includes(c.service)).map(c => c.card);
      for (let card of res){
        if(!card.cash && !card.manual && card.card && (card.service.includes('*') || card.service.includes('train'))){
          this.cards.push(card);
        }
      }
      this.fetchingCards$.next(false);
      console.log('card => ', this.cards);
    }, _ => this.fetchingCards$.next(false));
    
  }

  public rangeExpireYear() : number[]{
    const currentYear = new Date().getFullYear() - 1;
    return Array.from(Array(5).keys()).map((item) => item + currentYear);
  }

  nextStep($event){
    this.processingPayment$.next(true);
    const contact = this.contactForm.getRawValue();
    const request = {
      id: this.booking.Signature,
      cardNumber: this.selectedCard.indexes.lowcost,
      contact,
      costCenter: this.costCenter,
    };
    this.service.italoCompleteOrder(request).subscribe((result) => {
      this.processingPayment$.next(false);
      this.trainTravel.italo.finalizeBookingResponse = result;
      super.updateTrainTravel();
      this.addDossier(result.bookingDb);
      this.router.navigate(['trains', 'reservations', result.bookingDb.pnr]);
    }, _ => this.processingPayment$.next(false));
  }

  submitDisabled(){
    return !this.selectedCard || this.processingPayment$.value || !this.contactForm.valid;
  }

  private initForm(bk : HoldItaloBookingResponse) : void{
    // INit the form according to the current booking
    this.payForm = this.fb.group({
      Signature: [bk.Signature, [Validators.required]],
      Payment: this.fb.group({
        PaymentMethodType: ['1', [Validators.required]],
        PaymentMethodCode: [null, [Validators.required]],
        CurrencyCode: ['EUR', [Validators.required]],
        PaymentID: [0, Validators.required],
        AccountNumber: [null, Validators.required],
        Expiration_month: [1, [Validators.required]],
        Expiration_year: [new Date().getFullYear(), [Validators.required]],
        PaymentFields: this.fb.array(
          [
            this.fb.group({
              FieldName: ['CC::VerificationCode', [Validators.required]],
              FieldValue: [null, [Validators.required]],
            }),
            this.fb.group({
              FieldName: ['CC::AccountHolderName', [Validators.required]],
              FieldValue: [null, [Validators.required]],
            }),
          ],
        ),
      }),
    });

    // init the contact form
      const loggedUserMail = this.mainComponent.loggedUser?.email;
      const defaultMail = loggedUserMail || bk.Booking.Passengers[0]?.email || this.trainTravel.travellers[0].email;
      console.log(defaultMail);
      const defaultPhone = this.trainTravel.travellers[0].phoneNumber;
    this.contactForm = this.fb.group({
      Signature: [bk.Signature, [Validators.required]],
      BookingContact: this.fb.group({
        Phone: [defaultPhone, [Validators.required]],
        EmailAddress: [defaultMail, [Validators.required]],
        Culture: [0, Validators.required],
        DistributionOption: [2, Validators.required],
      }),
      SourceSystem: [1, Validators.required],
      IdPartner: [1234567, Validators.required],
    });
  }

  private addDossier(bookingDb : any){
    if (localStorage.getItem('fl_dossierId')){
      const dossierId = JSON.parse(localStorage.getItem('fl_dossierId'));
      const body = {
        trains: [bookingDb._id],
      };
      this.dossierService.addTrainsToDossier(dossierId, body).subscribe((resp) => {
        console.log('res => ', resp);
      }, error => {
        console.error('res => ', error);
      });
    }
  }

  arrayOfAlerts = () => {
    const arrayOfAlerts = [];
    this.trainTravel.arrayOfAlerts.forEach(e => Array.isArray(e) ? e.forEach(ee => arrayOfAlerts.push(ee)) : arrayOfAlerts.push(e));
    return arrayOfAlerts;
  }

}
