import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {FlightService} from '../../../../../../services/flight/flight.service';

@Component({
  selector: 'app-orderflights',
  templateUrl: './orderflights.component.html',
  styleUrls: ['./orderflights.component.scss']
})
export class OrderflightsComponent implements OnInit {
  @Input() flights: [];
  currentFlights = [];
  @Input() id: string;
  @Input() locator: string;
  @Output('updatePnr') select: EventEmitter<any> = new EventEmitter<any>();
  numberOfItineraries: number;
  public itineraries = [];
  public currentStep: number;
  public itinerariesToSave = [];

  constructor(
    private flightService: FlightService,
  ) {
    this.numberOfItineraries = 0;
    this.currentStep = 1;
  }

  ngOnInit(): void {
    this.currentFlights = JSON.parse(JSON.stringify(this.flights));
  }


  next() {
    this.currentStep += 1;
  }
  prev() {
    this.currentStep -= 1;
    this.currentFlights = JSON.parse(JSON.stringify(this.flights));
    for (let i = 0; i < this.itineraries.length; i++) {
      this.itineraries[i] = [];
      this.itinerariesToSave[i] = [];
    }
  }
  setSteps(event) {
    this.itineraries = new Array(event);
    for (let i = 0; i < this.itineraries.length; i++) {
      this.itineraries[i] = [];
      this.itinerariesToSave[i] = [];
    }

    console.log('this.itineraries =>', this.itineraries);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.itinerariesToSave = [];
    console.log('done => ', this.itineraries);
  }

  sendItinerary() {
    this.itinerariesToSave = new Array(this.itineraries.length);
    for (let i = 0; i < this.itineraries.length; i++) {
      this.itinerariesToSave[i] = {flights: []};
      for (let f = 0; f < this.itineraries[i].length; f++) {
        this.itinerariesToSave[i].flights.push(this.itineraries[i][f].ref);
      }
    }
    console.log('this.itineraries =>', this.itineraries);
    console.log('this.itinerariesToSave =>', this.itinerariesToSave);
    this.postItinerary();
  }

  postItinerary(): any {
    const body = {
      itineraries: this.itinerariesToSave
    };
    this.flightService.reorderPnrFligths(this.id, body).subscribe((res) => {
      console.log('Res => ', res);
      this.select.emit();
    }, error => {
      console.error('Error => ', error);
    });
  }

}
