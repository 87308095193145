<div class="buttonrow text-left mb-3">
  <button (click)="toggleFilters()" class="mainbg" mat-stroked-button>{{'OPEN_FILTERS'|translate}}</button>
</div>

<div *ngIf="loader" class="loader" id="loader" >
  <div class="card text-center padding20">
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
    <h5>{{'LOADING'|translate}}...</h5>
  </div>
</div>

<div class="filterRecom text-right">
  <button (click)="toggleForm()" mat-mini-fab color="primary" aria-label="Search">
    <mat-icon>search</mat-icon>
  </button>
  <button (click)="toggleFilters()" mat-mini-fab color="primary" aria-label="Search">
    <mat-icon matBadge="{{someFilterSelected()}}" matBadgeColor="accent" [matBadgeHidden]="someFilterSelected() == 0">tune</mat-icon>
  </button>
</div>

<div >
  <div *ngFor=" let recommendation of obs | async; let r = index; let l = last;" class="mt-4">
    <div class="isRecom">
      <div class="row">
        <div class="col-8">
          <div class="row" *ngIf="recommendation.lowCost && recommendation.cabins && lowCost">

            <div class="col-lg-4 col-md-6 col-12" *ngIf="isSupported(recommendation.marketingCarrier)">
              <div class="">
                <div class="fareNameNew">
                  <!--
                  <span>
                    {{'FARE'|translate}} {{recommendation.cabins[0].split('=')[1].split('*')[0].split(' ')[1]}}
                  </span>
                  -->
                  <span *ngFor="let cabin of recommendation.cabins; let l = last">
                    {{'FARE'|translate}} {{cabin.name}} <span *ngIf="!l">+ </span>
                  </span>
                </div>
                <div *ngFor="let cabin of recommendation.cabins| slice:0:1" class="d-flex justify-content-lg-start">
                <span *ngIf="lowCost" class="bottomInfoBtn tarifButton" (click)="openBottomSheetLow(recommendation.cabins, recommendation.marketingCarrier, recommendation.cabins)">
                      {{'FARE_INFO'|translate}}
                </span>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div class="col-4 text-right pnrActionPart buttonrow">
          <div class="mb-2">
            <img [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="recoCarrier" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>

            <span *ngIf="!recommendation.fare.publicAmount" class="price">
              {{priceservice.returnPrice(recommendation.fare.amount)|number:'.2-2'}}
              <span *ngIf="!recommendation.fare.currency">€</span>
              <span *ngIf="recommendation.fare.currency">{{recommendation.fare.currency}}</span>
              <span *ngIf="recommendation.lowCost && !appComponent.isSbt && !appComponent.isGat">
                + 6.00€ fee
                <span class="material-icons smaler" matTooltip="{{'LOWCOSTFEE' | translate}}">
                 info
                </span>
              </span>
            </span>
          </div>
          <div class="buttonrow">
            <button mat-raised-button color="primary" *ngIf="mainComponent.loggedUser.role.flight.taw.post.options.link && recommendation.lowCost" (click)="openDialog(recommendation.itineraries, searchResults.routingId)">
              {{'BUY_RESERVATION'|translate}}
            </button>

            <div *ngIf="recommendation.arrayOfAlerts && (appComponent.isB2B || appComponent.isSbt)" style="position:relative;display:inline-block;" appTooltip [tooltipTitle]="tooltip" placement="bottom">
              <mat-icon *ngIf="recommendation.arrayOfAlerts?.length === 0" style="color:green;cursor: pointer;font-size:16px;height:22px" class=" material-icons-outlined">check</mat-icon>
              <mat-icon color="warn" *ngIf="recommendation.arrayOfAlerts?.length > 0" style="cursor: pointer;font-size:16px;height:22px" class=" material-icons-outlined">warning</mat-icon>
              <div class="custom-tooltip" #tooltip>
                <div *ngIf="recommendation.arrayOfAlerts?.length === 0">
                  <span style="color: #3F51B5">No restrictions</span>
                </div>
                <div style="display: block" *ngFor="let alert of recommendation.arrayOfAlerts">
                  <span
                    [ngStyle]="{ 'color' : (alert?.block === true)? '#3F51B5' : '#F44336' }" >
                    {{alert?.message}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="travelDetails" *ngIf="!recommendation.showFares" @listItemAnimation>
        <div class="mb-3" *ngFor="let itinerary of recommendation.itineraries; let i = index;let la = last;">
          <div [ngClass]="{'nextExist':i<recommendation.itineraries.length && i!=recommendation.itineraries.length-1}">
            <div class="introItinerary marginbottom10" *ngIf="recommendation.lowCost">
              <p *ngIf="itinerary.sections.length>0" >
                {{searchResults.itineraries[i].sections[itinerary.sections[0].ref -1].flights[0].departure.dateTime|date:'EEE dd MMM yyyy':"+0000"}}
              </p>
              {{iataComplete.returnAirport(searchResults.itineraries[i].sections[itinerary.sections[0].ref -1].flights[0].departure.location)}}
              <sup>{{searchResults.itineraries[i].sections[itinerary.sections[0].ref -1].flights[0].departure.location}}</sup>
              <mat-icon>arrow_right_alt</mat-icon>
              {{iataComplete.returnAirport(searchResults.itineraries[i].sections[itinerary.sections[0].ref -1].flights[searchResults.itineraries[i].sections[itinerary.sections[0].ref -1].flights.length-1].arrival.location)}}
              <sup>{{searchResults.itineraries[i].sections[itinerary.sections[0].ref-1].flights[searchResults.itineraries[i].sections[itinerary.sections[0].ref -1].flights.length-1].arrival.location}}</sup>
            </div>

            <div class="row" *ngFor="let section of itinerary.sections; let s = index; let z = last" [hidden]="section.hidden || section.hiddenT">
              <div class="col-12 collapse show" id="itinerary{{r}}{{i}}{{s}}">
                <div class="row">
                  <div class="col-sm-1 d-sm-inline-block d-none text-center">
                    <img [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                  </div>
                  <div class="col-3">
                    {{iataComplete.returnAirport(searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.location)}}<br/>
                    {{searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                    {{returnZeroTime(searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.hours)}}:
                    {{returnZeroTime(searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.minutes)}}
                  </div>
                  <div class="col-3">
                    <div>{{returnTime(searchResults.itineraries[i].sections[section.ref-1].flyingTime)}}</div>
                    <div>
                    <span *ngIf="searchResults.itineraries[i].sections[section.ref-1].flights.length>1">
                      {{searchResults.itineraries[i].sections[section.ref-1].flights.length-1}} {{'STOPS'|translate}}
                    </span>
                      <span *ngIf="searchResults.itineraries[i].sections[section.ref-1].flights.length==1">
                      {{'NON_STOPS'|translate}}
                    </span>
                    </div>
                  </div>
                  <div class="col-3">
                    {{iataComplete.returnAirport(searchResults.itineraries[i].sections[section.ref-1].flights[searchResults.itineraries[i].sections[section.ref-1].flights.length-1].arrival.location)}}<br/>
                    {{searchResults.itineraries[i].sections[section.ref-1].flights[searchResults.itineraries[i].sections[section.ref-1].flights.length-1].arrival.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                    {{returnZeroTime(searchResults.itineraries[i].sections[section.ref-1].flights[searchResults.itineraries[i].sections[section.ref-1].flights.length-1].arrival.hours)}}
                    {{returnZeroTime(searchResults.itineraries[i].sections[section.ref-1].flights[searchResults.itineraries[i].sections[section.ref-1].flights.length-1].arrival.minutes)}}
                  </div>

                  <div class="col-3 col-sm-2">
                    <button mat-icon-button *ngIf="recommendation.lowCost" (click)="opeSect(r,i,s)">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-12 collapse issection" id="collapse{{r}}{{i}}{{s}}">

                <div class="row" *ngFor="let flight of searchResults.itineraries[i].sections[section.ref-1].flights; let f = index;let fl = last;">

                  <div class="col-12 mb-2" *ngIf="f===0">
                    <div class="row">
                      <div class="col-1 text-center">
                        <img class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                      </div>
                      <div class="col-9 align-self-center">
                        <div>{{iataComplete.returnCarrier(flight.marketingCarrier)}}</div>
                        <div class="md-titlebis">{{'DEPARTURE'|translate}}: {{flight.departure.date | date:'dd MMM yyyy'}}</div>
                        <div class="md-subhead">{{returnTime(searchResults.itineraries[i].sections[section.ref-1].flyingTime)}}</div>
                      </div>
                      <div class="col-2">
                        <button mat-icon-button (click)="opeSect(r,i,s)">
                          <mat-icon>keyboard_arrow_up</mat-icon>
                        </button>
                      </div>
                    </div>
                    <hr/>
                  </div>

                  <div class="col-md-1 text-center">
                    <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
                  </div>
                  <div class="col-md-11">
                    <div class="flightdet">
                      <p class="headingseg">
                        {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.departure.location)}} ({{flight.departure.location}})
                      </p>
                      <p class="headingdetails">
                        <!--{{'FLIGHT_TIME'|translate}}: {{calculateFlightTime(flight.departure.dateTime,flight.arrival.dateTime)}}-->
                        <span *ngIf="flight.departure.terminal"> Terminal: {{flight.departure.terminal}}</span>
                      </p>
                      <p class="headingseg">
                      <span class="redt" *ngIf="otherday(searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.date | date:'dd',flight.arrival.date | date:'dd')">
                        {{flight.arrival.date | date:'dd MMM yyyy'}} -
                      </span>
                        {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.arrival.location)}} ({{flight.arrival.location}})
                      </p>
                      <p class="fldetails">
                      <span *ngIf="!recommendation.lowCost">
                        Booking Class:
                      <span *ngIf="recommendation.tariff; else loadAnimation">
                        {{recommendation.tariff.bookingClasses[f+i]}} -
                      </span>
                      <ng-template #loadAnimation>
                        loading... -
                      </ng-template>
                      </span>

                        {{flight.operatingCarrier}} {{flight.number}} <span *ngIf="flight.detail && flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
                        <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>

                      </p>
                    </div>

                    <div class="scalepause" *ngIf="!fl">
                    <span>
                      <i class="fas fa-random"></i> {{'STOP'|translate}} in {{flight.arrival.location}}
                      <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}} <!-- ({{flight.waitingTime}}ms) --> </span>
                    </span>
                      <span class="alert-danger changeairport" *ngIf="flight.arrival.location != searchResults.itineraries[i].sections[section.ref-1].flights[f+1].departure.location">
                    {{'CHANGE'|translate}}:
                        {{searchResults.itineraries[i].sections[section.ref-1].flights[f+1].departure.location}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12" *ngIf="!z">
                <hr/>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="overflow-hidden bg-white">
    <div class="row">
      <div class="col-md-6 col-12 go-to-container text-right">
        <span class="smalltext">{{'PAGE'|translate}}</span>
        <mat-form-field>
          <mat-select [(ngModel)]="requestedPage"
                      (placeholder)="currentPage+1">
            <mat-option *ngFor="let data of returnPageLength(); let i = index"
                        [value]="i+1"
                        (click)="goToPage(i)">
              {{i+1}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span class="smalltext">{{'OF'|translate}} {{returnPagesNumber()}}</span>
      </div>
      <div class="col-md-6 col-12">
        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="pageChanged($event)">
        </mat-paginator>
      </div>

    </div>
  </div>
</div>


<div class="filterSearch">

  <div class="carrierFilter">
    <div class="mb-1"><label>{{'CARRIER'|translate}}</label></div>

    <span class="singleFilterCarrier" *ngFor="let carrier of carriersAvailable; let i = index" [ngClass]="{'greenborder':isIncludedCarr(carrier)}" (click)="filterCarrier(carrier)">
      <img [matTooltip]="iataComplete.returnCarrier(carrier)" class="filterCarrier" src="assets/img/airline/icon/{{carrier}}.png"/>
    </span>
  </div>
  <hr/>
  <div class="">
    <div class="mb-1"><label>{{'PRICE'|translate}}</label></div>
    <div class="row">
      <div class="col-4">
        {{minPriceModel}},00€
      </div>
      <div class="col-4 text-center">
        <span class="material-icons">
         sync_alt
        </span>
      </div>
      <div class="col-4 text-right">
        {{maxPriceModel}},00€
      </div>
    </div>
    <!-- TODO: The 'tickInterval' property no longer exists -->
    <mat-slider
      class="diInline50"
      color="primary"
      [max]="maxPriceModel"
      [min]="minPrice"
      [step]="1"
      [(ngModel)]="minPriceModel" #ngSlider><input matSliderThumb /><input matSliderThumb /><input matSliderThumb (change)="doSort()" #ngSliderThumb="matSliderThumb" />
    </mat-slider>
    <!--[thumbLabel]="true"-->
    <!-- TODO: The 'tickInterval' property no longer exists -->
    <mat-slider
      class="diInline50"
      color="primary"
      [max]="maxPrice"
      [min]="minPriceModel"
      [step]="1"
      [(ngModel)]="maxPriceModel" #ngSlider><input matSliderThumb /><input matSliderThumb /><input matSliderThumb (change)="doSort()" #ngSliderThumb="matSliderThumb" />
    </mat-slider>
    <!--[thumbLabel]="true"-->

  </div>
  <hr/>
  <div class="stopsFilter">
    <div *ngFor="let iti of searchResults.itineraries; let i = index; let l = last">
      <div>
        {{'TIME'|translate}} {{'SECTION'|translate}} {{i+1}}
        <!--
        {{searchResults.itineraries[i].sections[0].flights[0].departure.location}}
        <span class="material-icons">
          arrow_right_alt
        </span>
        {{searchResults.itineraries[i].sections[0].flights[searchResults.itineraries[i].sections[0].flights.length-1].arrival.location}}
        -->
      </div>

      <mat-radio-group
        aria-labelledby="stops-label"
        class="stops-radio-group"
        [(ngModel)]="defaultFilter[i]"
        color="primary"
        (ngModelChange)="changeTimeFilter($event, i)">
        <mat-radio-button class="stop-radio-button" *ngFor="let time of filterTime" [value]="time">
          {{time|translate}}
          <span *ngIf="time == 'morning'">(06 - 12)</span>
          <span *ngIf="time == 'afternoon'">(12 - 18)</span>
          <span *ngIf="time == 'evening'">(18 - 24)</span>
          <span *ngIf="time == 'night'">(0 - 6)</span>
        </mat-radio-button>
      </mat-radio-group>
      <hr *ngIf="!l"/>
    </div>


  </div>
  <hr/>
  <div class="mb-1"><label id="stops-label">{{'MAX_STOPS'|translate}}</label></div>
  <div class="stopsFilter" *ngFor="let stops of availableStops; let i = index; let l = last">
    <div>
      {{'SECTION'|translate}} {{i+1}}
    </div>
    <!--
    <div>
      {{searchResults.itineraries[i].sections[0].flights[0].departure.location}}
      <span class="material-icons">
        arrow_right_alt
      </span>
      {{searchResults.itineraries[i].sections[0].flights[searchResults.itineraries[i].sections[0].flights.length-1].arrival.location}}
    </div>
    -->
    <mat-radio-group
      color="primary"
      aria-labelledby="stops-label"
      class="stops-radio-group"
      [(ngModel)]="selectedStops[i]"
      (ngModelChange)="changeStops($event, i)">
      <mat-radio-button class="stop-radio-button" *ngFor="let stop of stops" [value]="stop">
        <span *ngIf="stop === 0">{{'NON_STOPS'|translate}}</span>
        <span *ngIf="stop === 1">{{stop}} {{'STOP'|translate}}</span>
        <span *ngIf="stop > 1">{{stop}} {{'STOPS'|translate}}</span>
      </mat-radio-button>
    </mat-radio-group>
    <hr *ngIf="!l"/>
  </div>


</div>
<div (click)="toggleFilters()" class="filtersearchBackdrop"></div>
