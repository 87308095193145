<div class="ancillaryTabs">
  <div class="">
    <div class="tabButton" [ngClass]="{'activeTab':currentTab===i}" *ngFor="let tab of tabs; let i = index;"
      (click)="setCurrentTab(i)">
      {{flights[i].ref}}{{i+1}}) {{flights[i].departure.location}} -> {{flights[i].arrival.location}}
    </div>
  </div>

  <div *ngIf="seatMap" class="tabContent">
    <div class="mb-3">{{'PLEASE_SELECT_SEATS_FOR'|translate}} {{flights[currentTab].departure.location}} ->
      {{flights[currentTab].arrival.location}}</div>
    <div class="mapCompartmentContainer" *ngIf="!seatmap[currentTab]">
      <div class="mb-3">
        <span class="material-icons">
          sentiment_very_dissatisfied
        </span>
      </div>
      <div>
        {{'NO_SEATMAP_AVAILABLE'|translate}}
      </div>

    </div>
    <div class="mb-3 buttonrow" *ngIf="seatmap[currentTab]">
      <!-- <div class="row">
        <div class="col-lg-6 col-6" >
          <div *ngFor="let seat of seatsSelections[currentTab]; let j = index">
            <button [ngClass]="{'activeBtn':i == currentCompartment}" *ngFor="let compartment of seat; let i = index" mat-stroked-button (click)="setCompartment(i)">
              {{compartment.seatNumber}}
            </button>
          </div>
        </div>
        <div class="col-lg-6 col-6">
          <button [ngClass]="{'activeBtn':passenger.ref == currentPassenger!.ref}" *ngFor="let passenger of passengers" mat-stroked-button (click)="setCurrentPax(passenger)">
            {{passenger.surname}} {{passenger.name}}
          </button>
        </div>
      </div> -->
      <div class="row">
        <div class="col-lg-6">
          <button [ngClass]="{'activeBtn':passenger._id == currentPassenger!._id}" *ngFor="let passenger of passengers"
            mat-stroked-button (click)="setCurrentPax(passenger)">
            {{passenger.surname}} {{passenger.name}}
            <span *ngIf="!passenger.name">{{passenger.type}}</span>
            <span *ngIf="someSeatForPax(passenger)">|
              {{someSeatForPax(passenger)}}
            </span>
          </button>
        </div>
        <div class="col-lg-6">
          <div *ngIf="currentSeat" class="currentSeat">
            <div class="row">
              <div class="col-8 align-self-center">
                {{currentSeat.seatNumber}} | {{currentSeat.price.amount|number:'.2-2'}}
                <span *ngIf="currentSeat.price.currency"> {{currentSeat.price.currency}}</span>
                <span *ngIf="!currentSeat.price.currency"> €</span>
              </div>
              <div class="col-4 text-right align-self-center">
                <button mat-icon-button (click)="currentSeatToView(currentSeat)">
                  <mat-icon>close</mat-icon>
                </button>
              </div>

            </div>

            <div *ngIf="currentSeat.attribute && currentSeat.attribute.length > 0">
              <hr />
              <div class="mb-1" *ngFor="let description of currentSeat.attribute">
                {{description | translate}}
              </div>
            </div>

            <hr />
            <div class="buttonrow" *ngIf="currentSeat.allowed && !alreadyBuy()">
              <div clas="buttonrow text-right" *ngIf="!someSelected(currentSeat)">
                <button mat-stroked-button (click)="selectSeat(currentSeat)">{{'SELECT_SEAT'|translate}}</button>
              </div>
              <div clas="buttonrow text-right" *ngIf="someSelected(currentSeat) && !reserved(currentSeat)">
                <button mat-stroked-button (click)="removeSeat(currentSeat)">{{'REMOVE_SEAT'|translate}}</button>
              </div>
            </div>
            <div *ngIf="reserved(currentSeat) && someSelected(currentSeat)" class="text-danger">
              {{'NOT_ALLOWED_SEAT'|translate}}
            </div>
            <!-- <div *ngIf="someSelected(currentSeat)" class="text-danger">
              <button mat-stroked-button (click)="toggleDeleteModal()">{{'REMOVE_SEAT'|translate}}</button>
            </div> -->
          </div>

          <div *ngIf="selectedSeats.length>0">
            <button mat-raised-button (click)="saveSeatMap()">{{'SAVE_SELECTIONS'|translate}}</button>
          </div>
        </div>
      </div>


    </div>
    <!--
    <div class="mapCompartmentContainer" *ngIf="seatMap[currentTab]">
       <div class="row">
        <div class="col-lg-6">
          <img src="/assets/img/planetotal.png"/>
        </div>
        <div class="col-lg-6">
          <div class="planemap">
            <div class="cabin">
              <div>
                <div class="singleSeat legend" *ngFor="let column of seatMap[currentTab].compartments[currentCompartment].columns">
                  {{column.id}}
                </div>
              </div>
              <div>
                <div class="" *ngFor="let row of seatMap[currentTab].compartments[currentCompartment].seats | keyvalue">
                  <div class="singleSeatCont" *ngFor="let seat of seatMap[currentTab].compartments[currentCompartment].seats[row.key].rows">
                    <div class="singleSeat" [ngClass]="{'not-allowed':!seat.allowed}" *ngIf="seat.exists" (click)="selectSeat(seat)">
                      {{row.key}} {{seat.column}}
                      <div class="seatmapdesc">
                        <div>{{seat.price|number:'.2-2'}}€</div>
                        <div *ngFor="let description of seat.descriptions">
                          {{description.text}}
                        </div>
                      </div>
                    </div>
                    <div class="singleSeat" *ngIf="!seat.exists">
                      x
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
    -->
    <div class="scroller">
      <div class="newmapcontainer">

        <div class="startMap">

        </div>
        <div class="newMap" *ngIf="seatMap[currentTab]">
          <div class="mapseats">
            <div class="compartMent">
              <div class="mapCol">
                <div class="columnName seatSingle {{include(compartment, i, compartment?.attribute, 'A')}}" *ngFor="let compartment of seatMap[currentTab][0]; let i = index;" >
                  {{compartment.seatNumber.slice(-1)}}
                </div>
              </div>
              <div class="mapCol" *ngFor="let compartment of seatMap[currentTab]; let j = index">
                <div class="seatSingle" *ngFor="let seat of compartment; let i = index;">
                  <div class="{{include(seat, i, seat?.attribute, 'A')}}" [ngClass]="{'current':isCurrentSeat(seat), 'personhere':someSelected(seat), 'boundled': isInFare(seat) }" (click)="currentSeatToView(seat)" *ngIf="seat?.allowed == true">
                    {{seat.seatNumber}}
                    <!-- <div class="">
                      <div>{{seat.price.amount|number:'.2-2'}}€</div>
                      <div *ngFor="let description of seat.attribute">
                        {{description}}
                      </div>
                    </div> -->
                  </div>
                  <div class=""  *ngIf="seat?.allowed == false" disabled>
                    X
                  </div>
                </div>
              </div>
              <div class="mapCol">
                <div class="columnName seatSingle {{include(compartment, i, compartment?.attribute, 'A')}}" *ngFor="let compartment of seatMap[currentTab][0]; let i = index">
                  {{compartment.seatNumber.slice(-1)}}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="endMap">

        </div>
      </div>
    </div>


  </div>
</div>


<div class="modal fade" id="deleteSeat" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="deleteSeat" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content" *ngIf="currentSeat">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteSeatModalTitle">{{'DELETE_SEAT_RESERVATION'|translate}} ?</h5>
        <button *ngIf="!deleting" type="button" class="close" (click)="toggleDeleteModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3">{{'DELETE_SEAT_RESERVATION_QUESTION'|translate}} {{currentSeat.name}}?</div>
        <!-- <div>{{'DELETE_RESERVATION_TEXT'|translate}}</div> -->
      </div>

      <hr/>

      <div class="text-right" *ngIf="!deleting">
        <button type="button" mat-button (click)="toggleDeleteModal()">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="removeBookedSeat(currentSeat)">{{'DELETE_SEAT'|translate}}</button>
      </div>
      <div class="text-right" *ngIf="deleting">
        <div class="mb-2" >{{'LOADING'|translate}}</div>
        <mat-progress-bar style="max-width: 200px;display:inline-block;" mode="indeterminate"></mat-progress-bar>
      </div>

    </div>
  </div>
</div>
