<ng-container *ngIf="cards.length === 0 else cardsTmpl">
  {{'TRAIN_MODULE.NO_CARDS' | translate}}
</ng-container>
<ng-template #cardsTmpl class="d-flex justify-content-around">
  <div class="creditCardContainer  mt-2 pt-2">
    <div *ngIf="cards.length == 0">
      {{'NO_CREDIT_CARDS_YET'|translate}}
    </div>
    <div *ngIf="cards.length > 0">

      <div class="singleCreditCard" *ngFor="let method of cards">
        <div class="creditCard">
          <div class="face front bg{{method.card.card.type}}" [ngClass]="{'selectedCard':method.selected}">
            <h3 class="debit">{{method.card.name}}</h3>
            <h3 class="bank">
            <span *ngIf="method.card.type === 'CC'">
              {{returnCreditCardName(method.card.card.type)}}
            </span>
              <span *ngIf="method.card.type !== 'CC'">
              AirPlus
            </span>
            </h3>
            <img class="chip" src="/assets/images/chip.png" alt="chip">
            <h3 class="number">xxxx xxxx xxxx {{method.card.card.number}}</h3>
            <h5 class="valid">
              <span>VALID <br /> THRU</span>
              <span>{{method.card.card.expirationMonth}}/{{method.card.card.expirationYear}}</span>
            </h5>
            <h5 class="card-holder">{{method.card.card.holder}}</h5>
          </div>
          <div class="cardSelection" >
            <span class="selectCard" *ngIf="!method.selected" (click)="selectFop(method)">
              {{'SELECT'|translate}}
            </span>
            <span class="selectedCard" *ngIf="method.selected">
              {{'CARD_SELECTED'|translate}}
            </span>
          </div>
        </div>
      </div>


    </div>
  </div>
</ng-template>
