import {Component, Input, OnInit} from '@angular/core';
import {SearchInformationService} from './services/search-information.service';
import {ActivatedRoute} from '@angular/router';
import {pluck, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-search-information',
  templateUrl: './search-information.component.html',
  styleUrls: ['./search-information.component.scss']
})
export class SearchInformationComponent implements OnInit {
  @Input() id: any;
  search: any;
  // search$ = this.route.params.pipe(pluck('id'), switchMap(id => this.searchInformationService.getSearch(id)));

  constructor(
    private searchInformationService: SearchInformationService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.getInfo();
  }

  getInfo(){
    this.searchInformationService.getSearch(this.id).subscribe((res) => {
      console.log('res reprice => ', res);
      this.search = res.data[0];
    }, error => {

      console.error('Error => ', error);
    });
  }

}
