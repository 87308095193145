import {Component, Input, OnInit} from '@angular/core';
import {FlightService} from '../../../../../../services/flight/flight.service';
import {IatacompleteService} from '../../../../../../services/iatacomplete/iatacomplete.service';

@Component({
  selector: 'app-pnrhistory',
  templateUrl: './pnrhistory.component.html',
  styleUrls: ['./pnrhistory.component.scss']
})
export class PnrhistoryComponent implements OnInit {
  @Input() pnrId : any;
  history: any;
  currentCompare: any;
  currentOption: any;
  constructor(
    private flightService: FlightService,
    public iataComplete: IatacompleteService,
  ) { }

  ngOnInit(): void {
    this.getHistory();
  }

  getHistory(){
    this.flightService.getReservationHistory(this.pnrId).subscribe((res) => {
      this.history = res.history;
      this.history.forEach((f:any) => {
        f.flights.sort((a, b) => a.line - b.line);
      });
      this.currentCompare = this.history[0];
      this.currentOption = this.history[this.history.length-1];
      console.log('currentCompare.departure.dateTime => ', this.currentCompare)
    }, error => {
      console.log('error => ', error);
    });
  }

  selectChange(i:number){
    this.currentCompare = this.history[i];
  }

  returnMilli(data) {
    return this.returnTime(new Date(data).getTime());
  }

  sameSelection(value){
    const currentSelection = JSON.stringify(this.currentCompare);
    const change = JSON.stringify(value);
    return change === currentSelection;
  }

  returnTime(t) {
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(t / cd);
    let h = Math.floor( (t - d * cd) / ch);
    let m = Math.round( (t - d * cd - h * ch) / 60000);
    const pad = ((n) => n < 10 ? '0' + n : n);
    if ( m === 60 ) {
      h++;
      m = 0;
    }
    if ( h === 24 ) {
      d++;
      h = 0;
    }
    if (d > 0) {
      return [d + 'g ' + pad(h) + 'h', pad(m) + 'm'].join(':');
    } else {
      return [pad(h) + 'h', pad(m) + 'm'].join(':');
    }

  }

  returnZeroTime(value) {
    value = Number(value);
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }

  differentFlight(value: any, type: string, ref: number){
    const find = this.currentCompare.flights.findIndex((r:any) => r.ref === ref);
    if(find !== -1){
      if(type === 'departure'){
        if (new Date(this.currentCompare.flights[find].departure.dateTime).getTime() !== new Date(value).getTime()) return true;
      } else if (type === 'arrival') {
        if (new Date(this.currentCompare.flights[find].arrival.dateTime).getTime() !== new Date(value).getTime()) return true;
      } else if (type === 'departureLocation'){
        if (this.currentCompare.flights[find].departure.location !== value) return true;
      } else if (type === 'arrivalLocation'){
        if (this.currentCompare.flights[find].arrival.location !== value) return true;
      } else if (type === 'flightNumber'){
        if (this.currentCompare.flights[find].number !== value) return true;
      }
    }
  }

  getTotalPayment(history: any) {
    let total;
    if (history.flights && history.flights.length>0 && history.flights[0].monetaryDetails) {
      total = 0;
      history.flights[0].monetaryDetails.flatMap(md => md.fareData.monetaryInfo)
        .filter(mi => mi.qualifier === 'T')
        .forEach(mi => total+=Number.parseFloat(mi.amount));
    }
    return total;
  }
}
