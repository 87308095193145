import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MainComponent} from './main/main.component';

import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material/material.module';

import {LayoutModule} from '../layout/layout.module';

import { CompaniesModule } from '../companies/companies.module';
import { UsersModule } from '../users/users.module';
import {TicketingModule} from '../ticketing/ticketing.module';
import {NotificationModule} from '../notification/notification.module';
import {OfflineRequestsModule} from '../offline-requests/offline-requests.module';
import {DashboardModule} from '../dashboard/dashboard.module';
import {ThemeComponentsModule} from '../theme-components/theme-components.module';
import {TrainsModule} from '../trains/trains.module';
import {HotelModule} from '../hotel/hotel.module';
import {CarModule} from '../car/car.module';
import {WidgetsModule} from '../widgets/widgets.module';
import {DossierModule} from '../dossier/dossier.module';
import {AdminareaModule} from '../adminarea/adminarea.module';
import {TravelersModule} from '../travelers/travelers.module';
import {StatisticModule} from '../statistic/statistic.module';
import {PolicyModule} from '../../components/policy/policy.module';
import {ConfirmationDialogModule} from '../../components/shared/confirmation-dialog/confirmation-dialog.module';
import {ConfirmationDialogComponent} from '../../components/shared/confirmation-dialog/confirmation-dialog.component';
import {GroupsModule} from '../../components/groups/groups.module';
import {TravelPolicyFormModule} from '../../components/policy/travel-policy-form/travel-policy-form.module';
import {UserGroupsModule} from '../user-groups/user-groups.module';
import {FlightModule} from '../flight/flight.module';
import { RequestsModule } from '../requests/requests.module';
import { CustomCommonModule } from '../common/custom-common.module';
import {UsersGroupBulkModule} from '../users-group-bulk/users-group-bulk.module';
import {AuthorizationRequestsModule} from '../../components/authorization-requests/authorization-requests.module';
import {SearchInformationModule} from '../../components/search-information/search-information.module';
import {SearchesModule} from '../../components/searches/searches.module';
import {SearchCardModule} from '../../components/shared/search-card/search-card.module';
import {FeesModule} from '../fees/fees.module';

@NgModule({
  declarations: [
    MainComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    CustomCommonModule,
    SharedModule,
    MaterialModule,
    LayoutModule,
    ThemeComponentsModule,
    CompaniesModule,
    UsersModule,
    RequestsModule,
    TicketingModule,
    NotificationModule,
    OfflineRequestsModule,
    DashboardModule,
    TrainsModule,
    HotelModule,
    CarModule,
    WidgetsModule,
    DossierModule,
    AdminareaModule,
    TravelersModule,
    StatisticModule,
    PolicyModule,
    ConfirmationDialogModule,
    GroupsModule,
    TravelPolicyFormModule,
    UserGroupsModule,
    FlightModule,
    UsersGroupBulkModule,
    AuthorizationRequestsModule,
    SearchInformationModule,
    SearchesModule,
    SearchCardModule,
    FeesModule
  ],
  exports: [
    MaterialModule,
  ]
})
export class MainmoduleModule { }
