import { Component, OnInit, HostBinding } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import {TranslateService} from '@ngx-translate/core';
import { HttpserviceService } from './services/httpservice.service';
import { Title } from '@angular/platform-browser';
import {Router} from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title: string;
  isSbt: boolean;
  isB2B: boolean;
  isGat: boolean;
  stepSearch: boolean;
  appName: string;
  theme = 'main-theme';
  manifest : string = '';
  pwaColor : string = '';
  // title = 'flyLEO';

  currentLang: string;
  chartmode: string;
  constructor(
    private overlayContainer: OverlayContainer,
    public translate: TranslateService,
    private httpService: HttpserviceService,
    private titleService: Title,
    private router: Router,
  ) {
    translate.setDefaultLang('it');
    this.currentLang = 'it';
    this.isSbt = environment.isSbt;
    this.isB2B = environment.isB2b;
    this.isGat = environment.isGat;
    this.manifest = environment.manifestSrc;

    this.setAppName();
    this.titleService.setTitle( this.appName + ' - self booking tool');
    this.router.events.subscribe((val) => {
    // this.title = this.findUrl();
    });
  }

  @HostBinding('class') componentCssClass;

  setAppName() {
    if (this.isSbt) {
      this.appName = 'Suite 4 Travel';
      this.pwaColor = "#03a9f4";
    }
    if (this.isGat) {
      this.appName = 'flyGATTINONI';
      this.pwaColor = "#444a54";
    }
    if (this.isB2B) {
      this.appName = 'B2B';
      this.pwaColor = "#6db7fd";
    }
    if (!this.isB2B && !this.isSbt && !this.isGat) {
      this.appName = 'flyLEO';
      this.pwaColor= "#1976d2";
    }
    console.log('app -> ', this.appName);
  }

  ngOnInit(): void {
    console.log('environment => ', environment)
    this.updateManifest()
    // this.title = this.findUrl();
    if (!this.isSbt && !this.isGat && !this.isB2B) {
      if (localStorage.getItem('tha_componentCssClass')) {
        this.componentCssClass =  localStorage.getItem('tha_componentCssClass');
        this.theme = this.componentCssClass;
        this.onSetTheme(this.theme);
      }
      if (!this.componentCssClass) {
        this.componentCssClass = 'main-theme';
        this.theme = this.componentCssClass;
        this.onSetTheme(this.theme);
      }
    } else if (this.isSbt) {
      this.componentCssClass = 'sbt-theme';
      this.theme = this.componentCssClass;
      this.onSetTheme(this.theme);
    }
    else if (this.isGat) {
      this.componentCssClass = 'gat-theme';
      this.theme = this.componentCssClass;
      this.onSetTheme(this.theme);
    }
    else if (this.isB2B) {
      localStorage.setItem('isB2B', 'true');
      this.componentCssClass = 'b2b-theme';
      this.theme = this.componentCssClass;
      this.onSetTheme(this.theme);
    }

    if ( localStorage.getItem('fl_searchType') ) {
      this.stepSearch = JSON.parse(localStorage.getItem('fl_searchType'));
    } else {
      this.stepSearch = false;
    }

    // this.isSbt =  localStorage.getItem('isSbt') === 'true';

  }

  updateManifest() {
    const manifest = document.querySelector('link[rel="manifest"]');
    const themeColor = document.querySelector('meta[name="theme-color"]');
    if (manifest) {
      manifest.setAttribute('href', environment.manifestSrc);
    }
    if (themeColor){
      themeColor.setAttribute('content', this.pwaColor)
    }
  }

  onSetTheme(theme: string) {

    this.theme = theme;
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(theme);
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
    localStorage.setItem('tha_componentCssClass', this.componentCssClass );
    if (theme === 'second-theme') {
      this.chartmode = 'dark';
    } else {
      this.chartmode = 'light';
    }
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.currentLang = language;
  }

  findUrl() {
    for (let i = 0; i < this.router.config[3].children.length; i++) {
      const path = '/' + this.router.config[3].children[i].path;
      if (this.router.url.includes('/users/list/') && path === '/users/list/:id') {
        this.titleService.setTitle(this.appName + ' - ' + this.translate.instant(this.router.config[3].children[i].data.title));
        return this.router.config[3].children[i].data.title;
      }

      if (this.router.url.includes('/companies/list/') && path === '/companies/list/:id') {
        this.titleService.setTitle(this.appName + ' - ' + this.translate.instant(this.router.config[3].children[i].data.title));
        return this.router.config[3].children[i].data.title;
      }
      if (this.router.url.includes('/flight/reservations/') && path === '/flight/reservations/:id') {
        this.titleService.setTitle(this.appName + ' - ' + this.translate.instant(this.router.config[3].children[i].data.title));
        return this.router.config[3].children[i].data.title;
      }
      if (this.router.url.includes(path)) {
        this.title = this.router.config[3].children[i].data.title;
        this.titleService.setTitle(this.appName + ' - ' + this.translate.instant(this.router.config[3].children[i].data.title));
        return this.router.config[3].children[i].data.title;
      }
    }
  }

  setSearch(value) {
    this.isSbt = value;
    this.isB2B = false;
    if (this.isSbt) {
      localStorage.setItem('isSbt', 'true' );
      this.componentCssClass = 'sbt-theme';
      this.theme = this.componentCssClass;
      this.onSetTheme(this.theme);
    } else {
      localStorage.setItem('isSbt', 'false' );
      this.componentCssClass = 'main-theme';
      this.theme = this.componentCssClass;
      this.onSetTheme(this.theme);
    }
    this.setAppName();
  }
  setB2BSearch(value) {
    this.isB2B = value;
    if (this.isB2B) {
      this.isSbt = false;
    }

    localStorage.setItem('isB2B', value );
    if (this.isB2B) {
      this.componentCssClass = 'b2b-theme';
    }
    this.theme = this.componentCssClass;
    this.onSetTheme(this.theme);
    this.setAppName();
  }
}


