import {Component, OnInit,} from '@angular/core';
import {GroupsService} from '../../groups.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {throwError} from 'rxjs';

@Component({
  selector: 'app-all-roles',
  templateUrl: './all-groups.component.html',
  styleUrls: ['./all-groups.component.scss'],
})
export class AllGroupsComponent implements OnInit{

  groups : any;

  constructor(
    private groupsService : GroupsService,
    private snackBar : MatSnackBar,
  ){
  }

  ngOnInit() : void{
    this.getData();
  }

  /*
   getData(): Observable<any> {
   return this.groupsService.groups$.pipe(tap(res => console.log(res)), catchError((err: any) => {
   this.snackBar.open(err.message, 'Close');
   return throwError(err);
   }));
   } */

  getData(){
    this.groupsService.getGroups().subscribe(response => {
      this.groups = response;
    }, error => {
      console.error('Error => ', error);
      return throwError(error);
    });
  }

  handleDelete(id : string) : void{
    this.groupsService.deleteGroup(id).subscribe(result => {
        this.snackBar.open('Group deleted successfully', 'Close');
        this.groups = this.getData();
      },
      error => {
        return throwError(error);
      },
    );
  }

}
