<div class="pagetitle">
  Link users to groups
</div>
<div class="maincontent">
  <div class="padding20">
    <ng-container *ngIf="users; else loading">
    <app-users-table (editClicked)="handleEditUser($event)" [data]="users.list"></app-users-table>
    </ng-container>

    <ng-template #loading>
      <div class="spinner-container">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>
