import {Component, OnInit, ViewChild} from '@angular/core';
import {AdmserviceService} from '../../../../services/adminserv/admservice.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import * as $ from 'jquery';
import {MatSort} from '@angular/material/sort';
@Component({
  selector: 'app-manage-iata',
  templateUrl: './manage-iata.component.html',
  styleUrls: ['./manage-iata.component.scss']
})
export class ManageIataComponent implements OnInit {
  loading: boolean;
  loadingIata: boolean;
  flightCryptic: any;
  searchTerm: string;
  iataList: any;
  obs: any;
  displayedColumns: string[] = ['iata', 'passport', 'blockBook','disableSegments','blockIssuance', 'issuanceInQueue', 'disableVoid', 'blockSearch', 'commandBeforePricing', 'commandAfterPricing', 'action'];
  dataSource: MatTableDataSource<any>;
  editing: any;
  newIata: any;
  newElementBefore: string;
  newElementAfter: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private admService: AdmserviceService,
  ) {
    this.searchTerm = '';
    this.newElementBefore = '';
    this.newElementAfter = '';
    this.resetNewIata();
  }

  resetEditing(obj) {
    if (obj) {
      this.editing = JSON.parse(JSON.stringify(obj));
      if (!this.editing.note) {
        this.editing.note = '';
      }
    } else {
      this.resetNewIata();
    }
  }

  resetNewIata() {
    this.editing = {
      iata: '',
      blockBook: false,
      blockSearch: false,
      commandBeforePricing: [],
      commandAfterPricing: [],
      note: '',
      passport: false,
      blockIssuance: false,
      issuanceInQueue: false,
      disableVoid: false,
      amadeusQueue: '',
      tourOperator : false,
      ethnic : false,
      tfNDC : false,
      tfPayOnly : false,
    };
  }

  editIata(iata): void {
    this.resetEditing(iata);
    $('#editIataModal').modal('toggle');
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  pushStringBeforeEdit() {
    const element = JSON.parse(JSON.stringify(this.newElementBefore));
    this.editing.commandBeforePricing.push(element);
  }
  removeStringBeforeEdit(i) {
    this.editing.commandBeforePricing.splice(i, 1);
  }
  pushStringAfterEdit() {
    const element = JSON.parse(JSON.stringify(this.newElementAfter));
    this.editing.commandAfterPricing.push(element);
  }
  removeStringAfterEdit(i) {
    this.editing.commandAfterPricing.splice(i, 1);
  }

  ngOnInit(): void {
    this.getIataList();
  }

  getIataList() {
    this.loading = true;
    this.admService.getIataList().subscribe((res) => {
      this.iataList = res;
      this.dataSource = new MatTableDataSource<any>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.paginator.firstPage();
    }, error => {
      console.error('Error => ', error);
    });
  }

  editIataCall() {
    this.loadingIata = true;
    if (this.editing.note === '') {
      delete this.editing.note;
    }
    this.admService.editIata(this.editing).subscribe((res) => {
      this.getIataList();
      this.editIata(null);
      this.loadingIata = false;
    }, error => {
      console.error('Error => ', error);
      this.loadingIata = false;
    });
  }

  addIataCall() {
    this.loadingIata = true;
    if (this.editing.note === '') {
      delete this.editing.note;
    }
    this.admService.addIata(this.editing).subscribe((res) => {
      this.getIataList();
      this.editIata(null);
      this.loadingIata = false;
    }, error => {
      console.error('Error => ', error);
      this.loadingIata = false;
    });
  }

  removeIataCall(item) {
    this.loading = true;
    this.admService.removeIata(item).subscribe((res) => {
      this.getIataList();
      this.loadingIata = false;
    }, error => {
      console.error('Error => ', error);
      this.loading = false;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  checkForm(){
    if(this.editing.issuanceInQueue){
      return !this.editing.amadeusQueue;
    }
    return false;
  }

}
