import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PolicyAlertsIconComponent } from './policy-alerts-icon/policy-alerts-icon.component';
import { DirectiveModuleModule } from '../directive/directive-module.module';
import { MatIconModule } from '@angular/material/icon';
import { PolicyApprovalButtonComponent } from './policy-approval-button/policy-approval-button.component';
import { MatButtonModule } from '@angular/material/button';
import { PolicyIconInmodalComponent } from './policy-icon-inmodal/policy-icon-inmodal.component';
import { CostCenterDialogComponent } from './cost-center-dialog/cost-center-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PwaComponent } from './pwa/pwa.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { NewGalleryComponent } from './new-gallery/new-gallery.component';


@NgModule({
  declarations: [
    PolicyAlertsIconComponent,
    PolicyApprovalButtonComponent,
    PolicyIconInmodalComponent,
    CostCenterDialogComponent,
    PwaComponent,
    NewGalleryComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    DirectiveModuleModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatTooltipModule,
    GalleryModule
  ],
    exports: [
      TranslateModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      PolicyAlertsIconComponent,
      PolicyApprovalButtonComponent,
      PolicyIconInmodalComponent,
      CostCenterDialogComponent,
      PwaComponent,
      NewGalleryComponent
    ],
})
export class SharedModule { }
