import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fields-dialog',
  templateUrl: './fields-dialog.component.html',
  styleUrls: ['./fields-dialog.component.scss']
})
export class FieldsDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FieldsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addOption(){
    const option = {
      label: '',
      value: ''
    }
    console.log('this.data.field =>' , this.data.field)
    this.data.field.fieldOptions.push(option);
    console.log('this.data.field.fieldOptions => ', this.data.field.fieldOptions)
  }

  removeOption(index: number){
    this.data.field.fieldOptions.splice(index, 1);
  }

  isOptionType(fieldType:any){
    if (fieldType == 'select' || fieldType == 'checkbox' || fieldType == 'radioButton'){
      return true
    }
    return false
  }

  alreadySelected(siapNum:string){
    let selected = false;
    const ind = this.data.form.findIndex((f:any) => f.siapField == siapNum);
    if(ind != -1){
      selected = true;
    }
    return selected
  }

}
