import { Injectable } from '@angular/core';
import {HttpserviceService} from '../httpservice.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SearchRes} from '../../classes/flights/search-res';
import {catchError, retry} from 'rxjs/operators';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root'
})
export class CarService extends HttpserviceService{
  carUrl: string;
  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
    this.carUrl = this.apiUrl + 'car/';
  }

  sendCarSearch(data): Observable<any> {
    const searchUrl = this.carUrl + 'search';
    return this.http
      .post<any>(searchUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
  // rentCompany
  // car
  // passengers
  generateReservation(data): Observable<any> {
    const linkUrl = this.carUrl + 'pnr';
    return this.http
      .post<any>(linkUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getReservationsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getPnrListUrl = this.carUrl + 'pnr?limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getPnrListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getReservation(data): Observable<any> {
    const getPnrUrl = this.carUrl + 'pnr/' + data;
    return this.http
      .get<any>(getPnrUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  bookReservation(id, data): Observable<any> {
    const bookUrl = this.carUrl + 'pnr/' + id + '/book';
    return this.http
      .post<any>(bookUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  deleteReservation(id): Observable<any> {
    const deleteUrl = this.carUrl + 'pnr/' + id;
    return this.http
      .delete<any>(deleteUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getTst(data): Observable<any> {
    const getTstUrl = this.carUrl + 'pnr/' + data + '/cryptic/tst';
    return this.http
      .get<any>(getTstUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getRetrieveCryptic(data): Observable<any> {
    const getCrypticUrl = this.carUrl + 'pnr/' + data + '/cryptic/retrieve';
    return this.http
      .get<any>(getCrypticUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getTstHistory(data): Observable<any> {
    const getTstHistoryUrl = this.carUrl + 'pnr/' + data + '/cryptic/history';
    return this.http
      .get<any>(getTstHistoryUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getTsm(data): Observable<any> {
    const getTsmUrl = this.carUrl + 'pnr/' + data + '/cryptic/tsm';
    return this.http
      .get<any>(getTsmUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  saveApprovaId(id, data): Observable<any> {
    const sendUrl = this.carUrl + 'pnr/' + id;
    return this.http
      .put<any>(sendUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  setCarCostCenter(id, data):Observable<any> {
    const sendUrl = this.carUrl + 'pnr/' + id + '/costCenter';
    // const sendUrl = this.temporaryLowCostUrl + 'postBooking/book';
    return this.http
      .post<any>(sendUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
}
