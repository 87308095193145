<button (click)="toggleCancelModal()" [disabled]="isDisabled" id="CANCELLATION" mat-button
        type="button">{{'TRAIN_MODULE.AFTERSALE.CANCELLATION'|translate}}</button>

<div aria-hidden="true" aria-labelledby="cancelPnrModal" class="modal fade" data-backdrop="static" id="cancelPnrModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-sm" role="document" style="top:70px">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="cancelPnrModalTitle">{{'TRAIN_MODULE.DELETE_RESERVATION_QUESTION'|translate}}
          ?</h5>
        <button (click)="toggleCancelModal()" *ngIf="!deleting" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <ng-container *ngIf="!cancelAttempt else successDialog">
          <ng-container *ngIf="(cancelReservation$ | async)">
            <div class="mb-2">{{'TRAIN_MODULE.LOADING'|translate}}</div>
            <mat-progress-bar mode="indeterminate" style="max-width: 200px;display:inline-block;"></mat-progress-bar>
          </ng-container>
        </ng-container>
        <ng-template #successDialog>
          <mat-dialog-content>
            <ng-container *ngIf="errorMessage else successMessage">
              <mat-icon>warning</mat-icon>
              {{ errorMessage | translate}}
            </ng-container>
            <ng-template #successMessage>
              {{'TRAIN_MODULE.RESERVATION_DELETED' | translate}}
            </ng-template>
          </mat-dialog-content>
        </ng-template>

        <div *ngIf="!cancelAttempt" class="text-right">
          <button (click)="toggleCancelModal()" mat-button type="button">{{'TRAIN_MODULE.ABORT'|translate}}</button>
          <button (click)="cancelPNR()" mat-button type="button">{{'TRAIN_MODULE.DELETE_RESERVATION'|translate}}</button>
        </div>
      </div>

    </div>
  </div>
</div>
