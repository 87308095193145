import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss']
})
export class BarchartComponent implements OnInit {
  @Input() chartmode: string;
  chartOptions: any;
  constructor() {
  }

  ngOnInit() {
    this.buildChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildChart();
  }

  buildChart() {
    this.chartOptions = {
      theme: {
        mode: this.chartmode,
      },
      series: [
        {
          name: 'Flight',
          data: [10900, 49841, 32335, 51351, 28749, 13462, 45469, 107091, 121148, 27824, 129990, 107800]
        },
        {
          name: 'Hotel',
          data: [8900, 9841, 12335, 61351, 8749, 3462, 5469, 10091, 21148, 7824, 9990, 17800]
        },
        {
          name: 'Car',
          data: [8900, 9841, 12335, 31351, 8749, 3462, 5469, 10091, 21148, 7824, 9990, 17800]
        },
        {
          name: 'Train',
          data: [8900, 9841, 12335, 41351, 8749, 3462, 5469, 10091, 21148, 7824, 9990, 17800]
        },
      ],
      chart: {
        height: 230,
        type: 'bar',
        zoom: {
          enabled: false
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          // endingShape: "rounded"
        }
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]
      }
    };
  }

}
