import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material/material.module';

import { DashboardcpComponent } from './dashboardcp/dashboardcp.component';
import {TicketingModule} from '../ticketing/ticketing.module';
import {WidgetsModule} from '../widgets/widgets.module';
import { HelpdialogComponent } from './dashboardcp/helpdialog/helpdialog.component';
import { RssComponent } from './dashboardcp/rss/rss.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomCommonModule } from '../common/custom-common.module';
import { DashboardVersion2Component } from './dashboard-version2/dashboard-version2.component';
@NgModule({
  declarations: [
    DashboardComponent,
    DashboardcpComponent,
    HelpdialogComponent,
    RssComponent,
    DashboardVersion2Component
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    TicketingModule,
    WidgetsModule,
    CustomCommonModule,
  ],
  exports: [
  ]
})
export class DashboardModule { }
