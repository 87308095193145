import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {FlightService} from '../../../../services/flight/flight.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatDialog} from '@angular/material/dialog';
import {IatacompleteService} from '../../../../services/iatacomplete/iatacomplete.service';
import {DossierService} from '../../../../services/dossier/dossier.service';
import {PriceService} from '../../../../services/price/price.service';
import {CustomAuthService} from '../../../../services/customAuth/custom-auth.service';
import {AppComponent} from '../../../../app.component';
import {TravelPolicyService} from '../../../../services/travel-policy/travel-policy.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { TrainUtils } from '../../../trains/components/train.utils';
import {AdmserviceService} from '../../../../services/adminserv/admservice.service';
declare var google: any;
@Component({
  selector: 'app-singledossier',
  templateUrl: './singledossier.component.html',
  styleUrls: ['./singledossier.component.scss']
})
export class SingledossierComponent implements OnInit {
  /**
   *  Local variable for tabs array
   */
  tabs: any[];
  map: any;
  currentTab: string;
  loading: boolean;
  param: any;
  dossierId: string;
  flights: any[];
  dossier: any;
  searchFl: boolean;
  searchHo: boolean;
  searchCar: boolean;
  searchTr : boolean;
  totalPrice: number;
  itemType: string;
  itemId: string;
  orderedItems: any[] = [];
  travelers: any[];
  allMarkers: any[] = [];
  googleMarkers: any[] = [];
  transferAuth: any;
  allApprovation: any[] = [];
  constructor(
    public mainComponent: MainComponent,
    public appComponent: AppComponent,
    private flightService: FlightService,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public iataComplete: IatacompleteService,
    private dossierService: DossierService,
    public priceService: PriceService,
    public customAuth: CustomAuthService,
    private policyService: TravelPolicyService,
    private snackBar: MatSnackBar,
    private admServ: AdmserviceService,
  ) {
    this.loading = true;
    this.currentTab = 'ITINERARY';
    this.tabs = [
      {
        name: 'ITINERARY',
        active: true,
      },
      {
        name: 'PASSENGERS',
        active: false,
      },
      {
        name: 'MAP',
        active: false,
      },
    ];
    this.travelers = [];
  }

  ngOnInit(): void {
    this.currentTab = 'ITINERARY';
    this.route.params.subscribe(params => {
      this.param = params.id;
      this.getDossierId();
    });
  }

  updateDossier() {
    this.admServ.updateDossier(this.dossierId).subscribe((res) => {
      console.log('res update => ', res);
    }, error => {
      console.error('Error => ', error);
    });
  }

  getDossierId() {
    this.dossierId = this.route.snapshot.paramMap.get('id');
    console.log('this.pnrId => ', this.dossierId);
    this.getDossier(this.dossierId);
  }

  setTab(index) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].active = false;
    }
    this.tabs[index].active = true;
    this.currentTab = this.tabs[index].name;
    if (index === 2) {
      this.reorderMarkers();
      setTimeout(() => {
        this.createMap();
      }, 200);
    }
  }

  getDossier(id) {
    this.totalPrice = 0;
    this.loading = true;
    this.dossierService.getDossier(id).subscribe((res) => {
      this.loading = false;
      this.dossier = res;
      if(this.dossier.cars.length > 0) {
        this.dossier.cars = this.dossier.cars.filter((f:any) => f.doc);
      }
      if(this.dossier.hotels.length > 0) {
        this.dossier.hotels = this.dossier.hotels.filter((f:any) => f.doc);
      }
      if(this.dossier.flights.length > 0) {
        this.dossier.flights = this.dossier.flights.filter((f:any) => f.doc);
      }
      if(this.dossier.trains.length > 0) {
        this.dossier.trains = this.dossier.trains.filter((f:any) => f.doc);
      }
      this.iataComplete.createToCompleteDossier(this.dossier.flights);
      console.log('this.dossier => ', this.dossier);
      this.calculateTotalPrice();
      this.orderedItems = [];
      setTimeout(() => {
        this.orderItems();
      }, 500);
      setTimeout(() => {
        if (localStorage.getItem('dossierIsActive')) {
          // localStorage.removeItem('dossierIsActive');
          if (this.dossier.sarce) {
            localStorage.setItem('sarceDossier', JSON.stringify(this.dossier.sarce));
          } else {
            localStorage.removeItem('sarceDossier');
          }
          this.mainComponent.setDossier(this.dossierId, this.dossier.name, this.orderedItems);
        }
      }, 600);

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  calculateTotalPrice() {
    if (this.dossier.flights.length > 0) {
      this.dossier.flights.forEach( flight => {
        if (flight.doc.negotiated && this.mainComponent.appcomponent.isB2B) {
          this.totalPrice = this.totalPrice + (flight.doc.fare.publicAmount - flight.doc.fare.fees);
        } else if (flight.doc.negotiated && !this.mainComponent.appcomponent.isB2B) {
          this.totalPrice = this.totalPrice + (flight.doc.fare.amount + flight.doc.fare.fees);
        } else {
          this.totalPrice = this.totalPrice + flight.doc.fare.amount;
        }
        this.totalPrice = this.totalPrice + (flight.doc.fare.services || 0);
      });
    }
    if (this.dossier.hotels.length > 0) {
      this.dossier.hotels.forEach( hotel => {
        this.totalPrice = this.totalPrice + hotel.doc.room.roomRates[0].amount;
      });
    }
    if (this.dossier.cars.length > 0) {
      this.dossier.cars.forEach( car => {
        this.totalPrice = this.totalPrice + this.returnCarAmount(car.doc.car.rates);
      });
    }
    if (this.dossier.trains.length > 0) {
      this.dossier.trains.forEach( train => {
        this.totalPrice = this.totalPrice + train.doc.ratedPrice;
      });
    }
  }

  emptyDossier() {
    return this.dossier.flights.length === 0 && this.dossier.hotels.length === 0 && this.dossier.cars.length === 0 && this.dossier.trains.length === 0;
  }

  toggleAddtoTravel(value) {
    if (value === 'searchFl') {
      this.searchFl = true;
      this.searchHo = false;
      this.searchCar = false;
      this.searchTr = false;
    } else if ( value === 'searchHo' ) {
      this.searchFl = false;
      this.searchHo = true;
      this.searchCar = false;
      this.searchTr = false;
    } else if ( value === 'searchCar' ) {
      this.searchFl = false;
      this.searchHo = false;
      this.searchCar = true;
      this.searchTr = false;
    } else if ( value === 'searchTrain' ) {
      this.searchFl = false;
      this.searchHo = false;
      this.searchCar = false;
      this.searchTr = true;
    } else {
      this.searchFl = false;
      this.searchHo = false;
      this.searchCar = false;
      this.searchTr = false;
    }
    $('#travelListModal').modal('toggle');
  }

  closeAddtoTravel() {
    this.searchFl = false;
    this.searchHo = false;
    $('#travelListModal').modal('toggle');
    this.getDossier(this.dossierId);
  }

  removeItem(item, id) {
    if (item && id) {
      this.itemType = item;
      this.itemId = id;
    } else {
      delete this.itemType;
      delete this.itemId;
    }
    $('#removeModal').modal('toggle');
  }

  delete() {
    if (this.itemType === 'flight') {
      const body = {
        flights: [this.itemId]
      };
      this.dossierService.removeFlightsFromDossier(this.dossierId, body).subscribe((res) => {
        console.log('res => ', res);
        this.removeItem(null, null);
        this.getDossier(this.dossierId);
      }, error => {
        console.error('res => ', error);
      });
    }
    if (this.itemType === 'hotel') {
      const body = {
        hotels: [this.itemId]
      };
      this.dossierService.removeHotelsFromDossier(this.dossierId, body).subscribe((res) => {
        console.log('res => ', res);
        this.removeItem(null, null);
        this.getDossier(this.dossierId);
      }, error => {
        console.error('res => ', error);
      });
    }
    if (this.itemType === 'car') {
      const body = {
        cars: [this.itemId]
      };
      this.dossierService.removeCarsFromDossier(this.dossierId, body).subscribe((res) => {
        console.log('res => ', res);
        this.removeItem(null, null);
        this.getDossier(this.dossierId);
      }, error => {
        console.error('res => ', error);
      });
    }
  }

  navigateTo(page) {
    if (this.dossier.sarce) {
      localStorage.setItem('dossierIsActive', JSON.stringify(true));
      localStorage.setItem('sarceDossier', JSON.stringify(this.dossier.sarce));
    }
    if (!this.dossier.sarce && this.dossier.travellers.length > 0) {
      localStorage.setItem('dossierPassengers', JSON.stringify(this.dossier.travellers));
    }
    this.mainComponent.setDossier(this.dossierId, this.dossier.name, this.orderedItems);
    this.router.navigate(['/' + page + '/search']);
  }

  orderItems() {
    if (this.dossier.flights.length > 0) {
      this.dossier.flights.forEach( reservation => {
        reservation = reservation.doc;
        reservation.itineraries.forEach( itinerary => {
          itinerary.type = 'flight';
          itinerary.createdAt = reservation.createdAt;
          itinerary.marketingCarrier = reservation.marketingCarrier;
          itinerary.marketingCarrier = reservation.marketingCarrier;
          itinerary.passengers = reservation.passengers;
          itinerary.link = reservation._id;
          itinerary.status = reservation.status;
          itinerary.locator = reservation.locator;
          itinerary.fare = reservation.fare;
          itinerary.startDate = new Date(itinerary.flights[0].departure.dateTime).getTime();
          if (reservation.timeLimit){
            itinerary.timeLimit = reservation.timeLimit;
          }
          itinerary.flights.forEach( flight => {
            this.callGoogle(this.iataComplete.returnAirport(flight.departure.location) + ' ' + flight.departure.location).then((r) => {
              flight.fromOne = r;
              const element = {
                start: new Date(flight.departure.dateTime),
                end: flight.arrival.dateTime,
                hour: flight.departure.hours,
                minutes: flight.departure.minutes,
                type: 'FLIGHT',
                from: flight.departure.location,
                to: 'Departure',
                latLng: flight.fromOne,
                icon: 'assets/img/maps/plane-pin.png',
              };
              this.allMarkers.push(element);
            });

            this.callGoogle(this.iataComplete.returnAirport(flight.arrival.location) + ' ' + flight.arrival.location).then((r) => {
              flight.toOne = r;
              flight.toOne[0] = flight.toOne[0] - 0.010;
              flight.toOne[1] = flight.toOne[1] - 0.005;
              const element = {
                start: new Date(flight.departure.dateTime),
                end: flight.arrival.dateTime,
                hour: flight.departure.hours,
                minutes: flight.departure.minutes,
                type: 'FLIGHT',
                from: 'Landing',
                to: flight.arrival.location,
                latLng: flight.toOne,
                icon: 'assets/img/maps/plane-pin.png',
              };
              console.log('flight element => ', flight);
              this.allMarkers.push(element);
            });
          });
          this.orderedItems.push(itinerary);
        });
        reservation.passengers.forEach( pax => {
          if (this.travelers.length === 0) {
            this.travelers.push(pax);
          } else {
            const find = this.travelers.findIndex( p => p._id === pax._id);
            if (find === -1) {
              this.travelers.push(pax);
            }
          }
        });
      });
    }
    if (this.dossier.hotels.length > 0) {
      this.dossier.hotels.forEach( reservation => {
        reservation = reservation.doc;
        reservation.type = 'hotel';
        reservation.link = reservation._id;
        reservation.hotel.checkIn = new Date(reservation.hotel.checkIn).setHours(23);
        reservation.startDate = new Date(reservation.hotel.checkIn).getTime();
        this.callGoogle(reservation.hotel.name + ', ' + reservation.hotel.city).then((r) => {
          reservation.fromOne = r;
          const element = {
            start: new Date(reservation.startDate),
            end: reservation.hotel.checkOut,
            hour: '',
            minutes: '',
            type: 'HOTEL',
            from: reservation.hotel.city,
            latLng: reservation.fromOne,
            icon: 'assets/img/maps/hotel-pin.png',
          };
          this.allMarkers.push(element);
        });
        this.orderedItems.push(reservation);
        reservation.guests.forEach( pax => {
          if (this.travelers.length === 0) {
            this.travelers.push(pax);
          } else {
            const find = this.travelers.findIndex( p => p._id === pax._id);
            if (find === -1) {
              this.travelers.push(pax);
            }
          }
        });
      });
    }
    if (this.dossier.cars.length > 0) {
      this.dossier.cars.forEach( reservation => {
        reservation = reservation.doc;
        this.setAddresses(reservation);
        reservation.type = 'car';
        reservation.link = reservation._id;
        reservation.car.pickUpInfo.date = new Date(reservation.car.pickUpInfo.date).setHours(14);
        reservation.startDate = reservation.car.pickUpInfo.date;
        reservation.car.dropOffInfo.date = new Date(reservation.car.dropOffInfo.date).setHours(14);
        reservation.endDate = reservation.car.dropOffInfo.date;
        if (reservation.rentCompany.rentalLocations[0].coordinates.latitude != reservation.rentCompany.rentalLocations[1].coordinates.latitude) {
          reservation.rentCompany.rentalLocations.forEach( rental => {
            const element = {
              start: reservation.startDate,
              end: reservation.endDate,
              type: 'CAR',
              from: rental.address,
              to: '',
              latLng: [rental.coordinates.latitude, rental.coordinates.longitude],
              icon: 'assets/img/maps/car-pin-up.png',
            };
            this.allMarkers.push(element);
          });
        } else {
          const element = {
            start: reservation.startDate,
            end: reservation.endDate,
            type: 'CAR',
            from: reservation.rentCompany.rentalLocations[0].address,
            to: '',
            latLng: [reservation.rentCompany.rentalLocations[0].coordinates.latitude, reservation.rentCompany.rentalLocations[0].coordinates.longitude],
            icon: 'assets/img/maps/car-pin-up.png',
          };
          this.allMarkers.push(element);
        }
        this.orderedItems.push(reservation);
        reservation.passengers.forEach( pax => {
          if (this.travelers.length === 0) {
            this.travelers.push(pax);
          } else {
            const find = this.travelers.findIndex( p => p._id === pax._id);
            if (find === -1) {
              this.travelers.push(pax);
            }
          }
        });
      });
    }

    if (this.dossier.trains.length > 0) {
      this.dossier.trains.forEach( reservation => {
        reservation = reservation.doc;
        reservation.type = 'train';
        reservation.link = reservation._id;
        reservation.departure = {
          date: reservation.itineraries[0].from.date,
          station: reservation.itineraries[0].from.station,
        };
        reservation.arrival = {
          date: reservation.itineraries[reservation.itineraries.length-1].to.date,
          station: reservation.itineraries[reservation.itineraries.length-1].to.station,
        };
        this.orderedItems.push(reservation);
        reservation.travellers.forEach( pax => {
          if (this.travelers.length === 0) {
            this.travelers.push(pax.doc);
          } else {
            const find = this.travelers.findIndex( p => p._id === pax._id);
            if (find === -1) {
              this.travelers.push(pax);
            }
          }
        });
      });
    }
    this.orderedItems.sort((a, b) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });

    console.log('this.orderedItems => ', this.orderedItems);
    /*
    this.allMarkers.sort((a, b) => {
      return new Date(a.start).getTime() - new Date(b.start).getTime();
    });*/
    setTimeout(() => {
      this.reorderMarkers();
    }, 500);
    if(this.mainComponent.loggedUser.company.services.policy){
      this.getTransferPolicy();
    }
  }

  setAddresses(element): void {
    element.car.pickUp = element.rentCompany.rentalLocations[0].address;
    if (element.rentCompany.rentalLocations[1]) {
      element.car.dropOff = element.rentCompany.rentalLocations[1].address;
    } else {
      element.car.dropOff = element.rentCompany.rentalLocations[0].address;
    }
  }

  returnDate(value) {
    return new Date(value);
  }

  reorderMarkers() {
    this.allMarkers.sort((a, b) => {
      return new Date(a.start).getTime() - new Date(b.start).getTime();
    });
    console.log('allMarkers => ', this.allMarkers);
  }

  createMap(): void {
    this.map = new google.maps.Map(document.getElementById('mapDossier') as HTMLElement, {
      center: { lat: this.allMarkers[0].latLng[0], lng: this.allMarkers[0].latLng[1]},
      zoom: 12,
    });
    this.createMarkers();
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < this.googleMarkers.length; i++) {
      bounds.extend(this.googleMarkers[i].getPosition());
    }
    if(this.allMarkers.length > 0 ) {
      const directionPoins = [];
      for (let m = 0; m < this.allMarkers.length; m++) {
        const direction = {
          lat: this.allMarkers[m].latLng[0],
          lng: this.allMarkers[m].latLng[1]
        };
        directionPoins.push(direction);
      }
      console.log('directionPoins: ', directionPoins);
      const lineSymbol = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        geodesic: true,
        strokeColor: '#4b5ccd',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      };
      const line = new google.maps.Polyline({
        path: directionPoins,
        icons: [
          {
            icon: lineSymbol,
            offset: '100%',
          },
        ],
        geodesic: true,
        strokeColor: '#4b5ccd',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        map: this.map,
      });
    }
    if (this.allMarkers.length > 1) {
      this.map.fitBounds(bounds);
    }

  }

  createMarkers() {
    let i = 0;
    this.allMarkers.forEach( mark => {
      i = i + 1;
      const marker = new google.maps.Marker({
        // tslint:disable-next-line:max-line-length
        position: {lat: mark.latLng[0], lng: mark.latLng[1]},
        // label: 'Off',
        map: this.map,
        icon: mark.icon,
        //label: 'a' + i,
      });
      this.googleMarkers.push(marker);
      return marker;
    });
  }

  callGoogle(value) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: 'https://maps.googleapis.com/maps/api/geocode/json?address=' + value + '&key=AIzaSyAqc3WiMFmKwSIZGp8rceIuh0_yU8Hfe-w',
        type: 'GET',
        dataType: 'json',
        cache: false,
        success: (response) => {
          resolve([response.results[0].geometry.location.lat, response.results[0].geometry.location.lng]);
        }
      });
    });
  }

  returnCarAmount(rates) {
    for (let i = 0; i < rates.length; i++) {
      if (rates[i].type === 'estimated total') {
        return rates[i].amount;
      }
    }
  }

  getTransferPolicy(){
    this.policyService.getTransfer(this.dossier._id).subscribe((res) => {
      console.log('res transfer policy => ', res);
      this.transferAuth = res.data;
      this.returnSApproval();
      this.setApprovalStatus();
    }, error => {

      console.error('Error => ', error);
    });
  }

  setApprovalStatus(){
    this.transferAuth.searches.forEach((t:any) => {
      this.orderedItems.forEach((i :any) => {
        if (i.link === t.productDetails._id){
          i.authorisations = t.authorisations;
        }
      })
    });
    // orderedItems
    this.checkAllApproval();
  }

  checkAllApproval(){
    this.orderedItems.forEach((o: any) => {
      if(o.authorisations.length > 0){
        o.authorisations.forEach((auth: any) => {
          if(auth.status === 'approved'){
            this.allApprovation.push(true);
          } else {
            this.allApprovation.push(false);
          }
        })
      } else {
        this.allApprovation.push(true);
      }
    })
  }

  canAskAuth(){
    return !this.allApprovation.includes(false);
  }

  askAuthorisation(){
    this.transferAuth.arrayOfAlerts.forEach(alert => {
      const authorization = {
        transferId: this.transferAuth._id,
        type: alert.config.isDefault === 1 ? 'default' : 'standard',
        note: alert.message,
        authorization_users: alert.config.authorization_users,
        authorization_roles: alert.config.authorization_roles,
        configurationRule: alert,
      };
      this.policyService.askDossierAuth(authorization).subscribe((res) => {
        this.snackBar.open('Authorization sent successfully.', 'Close', {
          duration: 2000,
        });
      }, error => {
        this.snackBar.open('Something went wrong, try again.', 'Close', {
          duration: 2000,
        });
        console.error('Error => ', error);
      });
    });
    setTimeout(() => {
      this.getDossier(this.dossierId);
    }, 500);
  }

  checkPolicy() :boolean {
    if(this.transferAuth && (this.appComponent.isSbt || this.appComponent.isB2B) && this.mainComponent.loggedUser.company.services.policy){
      if (this.transferAuth.arrayOfAlerts?.length > 0){
        return false // !tariff.arrayOfAlerts.every(a => a.block === false)
      }
    }
    return true;
  }

  returnSApproval(){
    this.transferAuth.status = 'pending';
    if(this.transferAuth.authorisations.length > 0){
      const declined = this.transferAuth.authorisations.find(x => x.status === 'declined');
      if (!!declined) {
        this.transferAuth.status = 'declined';
      }

      const approved = this.transferAuth.authorisations.every(x => x.status === 'approved');
      if (!!approved) {
        this.transferAuth.status = 'approved';
      }
    }
  }

  getTrainImage(carrier : any){
    return TrainUtils.getTrainLogo(carrier);
  }
}
