import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { FlightModule } from '../flight/flight.module';
import { GroupRequestsComponent } from './components/group-requests/group-requests.component';
import { CustomCommonModule } from '../common/custom-common.module';
import { UnaccompaniedMinorsComponent } from './components/unaccompanied-minors/unaccompanied-minors.component';
import { RequestListComponent } from './components/list/list.component';
import { RequestsRoutingModule } from './requests-routing.module';


@NgModule({
  declarations: [
    GroupRequestsComponent,
    UnaccompaniedMinorsComponent,
    RequestListComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    FlightModule,
    CustomCommonModule,
    RequestsRoutingModule,
  ],
  exports: [],
})
export class RequestsModule{}
