import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatListModule} from '@angular/material/list';
import {TravelPolicyFormComponent} from './travel-policy-form.component';
import {AccessDialogComponent} from './access-dialog/access-dialog.component';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [
    TravelPolicyFormComponent,
    AccessDialogComponent,
  ],
  exports: [
    TravelPolicyFormComponent,
  ],
  imports: [
    CommonModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    FormsModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatRadioModule,
    MatSnackBarModule,
    MatListModule,
    RouterModule,
  ]
})
export class TravelPolicyFormModule { }
