<div class="auth-card">
  <h5><b>Policy For user group:</b> {{authorization.traveler?.group?.name}}</h5>
  <h5><b>Product type:</b> {{authorization.authorisation.ref}}</h5>
  <h5><b>Status:</b> {{authorization.authorisation.status}}</h5>
  <p><b>Note:</b> {{authorization.authorisation.note}}</p>
  <div>
    <h5><b>Traveller:</b></h5>
  <p>{{authorization?.traveler?.name}} {{authorization?.traveler?.surname}}</p>
  <p><b>Email:</b>{{authorization?.traveler?.email}}</p>
  <p><b>Phone Number:</b> {{authorization?.traveler?.phoneNumber}}</p>
  </div>
  <div>
    <h5><b>Travel arranger:</b></h5>
  <p><b>Name</b> {{authorization?.travelArranger.name}}  {{authorization?.travelArranger.surname}}</p>
    <p><b>Email:</b>{{authorization?.travelArranger?.email}}</p>
    <p><b>Phone Number:</b> {{authorization?.travelArranger?.phoneNumber}}</p>
  </div>
  <p><b>Created at: </b>{{authorization.createdAt | date: 'long'}}</p>
  <p><b>Product Info: </b><button (click)="showProductInfo()" mat-raised-button color="secondary">Show</button></p>

  <div *ngIf="authorization.authorisation.status == 'pending';else error" style="display: flex; justify-content: flex-end">
    <button (click)="openDialog('decline')" mat-raised-button color="primary" style="margin-right: 10px">Decline</button>
    <button (click)="openDialog('approve')" mat-raised-button color="primary">Authorize</button>
  </div>

  <ng-template #error>
    <div><span><b>Someone Already Responsed :</b> {{authorization.authorisation.status}}</span></div>
  </ng-template>
</div>
