import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomAuthService {
  companies = [];
  constructor() {
    this.companies = [
      //'5e0a1e2047a19a0013fedcbe', // eataly
      // '5d8a20b89ee5cd001acb9cf1', // demo company,
      '5fec90e2df2f4d0012c64cf1', // trenitalia
      // '604f240ec5ec740012a28f46', // travel fusion user
    ];
  }

  returnAuth(id) {
    return id !== '5fec90e2df2f4d0012c64cf1';
  }

  returnAuthTf(id) {
    return id !== '604f240ec5ec740012a28f46';
  }

  canBook(id) {
    const ind = this.companies.findIndex(r => r === id);
    if ( ind === -1 ) {
      return true;
    } else {
      return false;
    }
  }
}
