
<div class="dashBoardCont padding20">
    <div class="row mb-3">

        <div class="col-lg-3 col-md-6 mb-3" *ngIf="maincomponent.loggedUser.role.superAdmin || maincomponent.loggedUser.company.services.flight">
            <div class="dsCard">
                <div class="dsCardIcon">
                    <span class="material-icons">flight</span>
                </div>
                <div class="dsCardTitle">{{'FLIGHT'|translate}}</div>
                <div class="dsActions">
                    <a class="dsButtonPrimary pointer mr-1 mb-1" routerLink="/flight/search">{{'SEARCH_FLIGHT'|translate}}</a>
                    <a class="dsButtonSecondary pointer" routerLink="/flight/reservations">{{'PNR_LIST'|translate}}</a>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 mb-3" *ngIf="(maincomponent.loggedUser.role.superAdmin || maincomponent.loggedUser.company.services.hotel) && !appComponent.isGat">
            <div class="dsCard">
                <div class="dsCardIcon">
                    <span class="material-icons">hotel</span>
                </div>
                <div class="dsCardTitle">{{'HOTEL.NAME'|translate}}</div>
                <div class="dsActions">
                    <a class="dsButtonPrimary pointer mr-1 mb-1" routerLink="/hotel/search">{{'HOTEL_SEARCH'|translate}}</a>
                    <a class="dsButtonSecondary pointer" routerLink="/hotel/reservations">{{'HOTEL_RESERVATION_LIST'|translate}}</a>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 mb-3" *ngIf="(maincomponent.loggedUser.role.superAdmin || maincomponent.loggedUser.company.services.train) && !appComponent.isGat">
            <div class="dsCard">
                <div class="dsCardIcon">
                    <span class="material-icons">train</span>
                </div>
                <div class="dsCardTitle">{{'TRAIN'|translate}}</div>
                <div class="dsActions">
                    <a class="dsButtonPrimary pointer mr-1 mb-1" routerLink="/trains/search">{{'TRAIN_SEARCH'|translate}}</a>
                    <a class="dsButtonSecondary pointer" routerLink="/trains/reservations">{{'TRAIN_RESERVATION_LIST'|translate}}</a>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 mb-3" *ngIf="(maincomponent.loggedUser.role.superAdmin || maincomponent.loggedUser.company.services.car) && !appComponent.isGat">
            <div class="dsCard">
                <div class="dsCardIcon">
                    <span class="material-icons">directions_car</span>
                </div>
                <div class="dsCardTitle">{{'CAR_RENT'|translate}}</div>
                <div class="dsActions">
                    <a class="dsButtonPrimary pointer mr-1 mb-1" routerLink="/car-rent/search">{{'CAR_RENT_SEARCH'|translate}}</a>
                    <a class="dsButtonSecondary pointer" routerLink="/car-rent/reservations">{{'CAR_RESERVATION_LIST'|translate}}</a>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 mb-3" *ngIf="(maincomponent.loggedUser.role.superAdmin || (appComponent.isSbt || appComponent.isB2B)) && !appComponent.isGat">
            <div class="dsCard">
                <div class="dsCardIcon">
                    <span class="material-icons">topic</span>
                </div>
                <div class="dsCardTitle">{{'TRAVEL_FOLDER'|translate}}</div>
                <div class="dsActions">
                    <a class="dsButtonPrimary pointer mr-1 mb-1" (click)="maincomponent.addDossierToggle()">{{'NEW_FOLDER'|translate}}</a>
                    <a class="dsButtonSecondary pointer" routerLink="/dossier/list">{{'FOLDER_LIST'|translate}}</a>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 mb-3">
            <div class="dsCard">
                <div class="dsCardIcon">
                    <span class="material-icons">person_outline</span>
                </div>
                <div class="dsCardTitle">{{'REGISTRY'|translate}}</div>
                <div class="dsActions">
                    <a class="dsButtonSecondary pointer  mb-1" routerLink="/travellers/list">{{'TRAVELLERS_BOOK'|translate}}</a>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 mb-3" *ngIf="(maincomponent.loggedUser.role.superAdmin || (appComponent.isB2B || appComponent.isSbt)) && !appComponent.isGat">
            <div class="dsCard">
                <div class="dsCardIcon">
                    <span class="material-icons">moving</span>
                </div>
                <div class="dsCardTitle">{{'REQUESTS'|translate}}</div>
                <div class="dsActions">
                    <a class="dsButtonPrimary pointer mr-1 mb-1" routerLink="/offline-requests/open-request">{{'NEW_TRAVEL_REQUEST'|translate}}</a>
                    <a class="dsButtonSecondary pointer" routerLink="/offline-requests/request-list">{{'REQUESTS_LIST'|translate}}</a>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 mb-3">
            <div class="dsCard dsCardBg">
                <div class="dsCardIcon" style="background-color:#fff">
                    <span class="material-icons">support</span>
                </div>
                <div class="dsCardTitle">{{'SUPPORT'|translate}}</div>
                <div class="dsActions">
                    <a class="dsButtonPrimary pointer mr-1 mb-1" (click)="openBottomSheet()">{{'OPEN_TICKET'|translate}}</a>
                    <a class="dsButtonSecondary pointer" routerLink="/ticketing/list" *ngIf="!appComponent.isGat">{{'REQUESTS_LIST'|translate}}</a>
                    <a class="dsButtonOnTop" (click)="openHelpDialog()" matTooltip="{{'PHONE_SUPPORT'|translate}}">
                        <span class="material-icons">phone</span>
                    </a>
                </div>
            </div>
        </div>

    </div>

    <div class="row mb-3" *ngIf="generalStats && (!appComponent.isB2B && !appComponent.isSbt)">

        <div class="col-12">
            <div class="statBox" >
                <div class="titleBox">Reportistica <span class="uppercase primaryCol">{{today|date: 'MMMM'}}</span></div>
                <div class="monthLine">
                  <div class="startDayMonth">1</div>
                  <div class="lineMonth" [style.width.%]="percentCompleted">

                  </div>
                  <div class="totalDayMonth">{{daysInMonth}}</div>
                  <div class="currentDayMonth" [style.left.%]="percentCompleted">{{daysPassed}}</div>
                </div>
                <div class="singleStatBox pointer" routerLink="/statistic/year">
                    <div class="singleTitBox">Totale emissioni</div>
                    <div class="singleNumBox"> €{{generalStats?.flight?.amount||0|number:'.2-2'}}</div>
                </div>
                <div class="singleStatBox pointer" routerLink="/statistic/year">
                    <div class="singleTitBox">Prenotazioni</div>
                    <div class="singleNumBox"> {{generalStats?.flight?.pnr||0|number}}</div>
                </div>
                <div class="singleStatBox pointer" routerLink="/statistic/year">
                    <div class="singleTitBox">Segmenti</div>
                    <div class="singleNumBox"> {{generalStats?.flight?.segments||0|number}}</div>
                </div>
                <div class="singleStatBox pointer" routerLink="/statistic/year">
                    <div class="singleTitBox">Incentivi</div>
                    <div class="singleNumBox"> €{{generalStats?.flight?.fees||0|number}}</div>
                </div>
                <div class="clear"></div>
            </div>

        </div>
        <!--
        <div class="col-lg-3">
          <div class="homeWidg">
            <div class="row">
              <div class="col-10 align-self-center">
                <h5>Reportistica volo<br/> <span class="smalltext">{{today|date: 'MM/yyyy'}}</span></h5>
                <div class="widAction" routerLink="/statistic/year">
                  {{generalStats?.flight?.amount||0|number:'.2-2'}}€
                </div>
              </div>
              <div class="col-2 align-self-center text-center">
                <span style="color:#1d7cbc;font-size:26px" class="material-icons">flight</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="homeWidg">
            <div class="row">
              <div class="col-10 align-self-center">
                <h5>Prenotazioni<br/> <span class="smalltext">{{today|date: 'MM/yyyy'}}</span></h5>
                <div class="widAction" routerLink="/statistic/year">
                  {{generalStats?.flight?.pnr||0|number}}
                </div>
              </div>
              <div class="col-2 align-self-center text-center">
                <span style="color:#1d7cbc;font-size:26px" class="material-icons">check</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="homeWidg">
            <div class="row">
              <div class="col-10 align-self-center">
                <h5>Segmenti<br/> <span class="smalltext">{{today|date: 'MM/yyyy'}}</span></h5>
                <div class="widAction" routerLink="/statistic/year">
                  {{generalStats?.flight?.segments||0|number}}
                </div>
              </div>
              <div class="col-2 align-self-center text-center">
                <span style="color:#1d7cbc;font-size:26px" class="material-icons">multiple_stop</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="homeWidg">
            <div class="row">
              <div class="col-10 align-self-center">
                <h5>Incentivi<br/> <span class="smalltext">{{today|date: 'MM/yyyy'}}</span></h5>
                <div class="widAction" routerLink="/statistic/year">
                  {{generalStats?.flight?.fees||0|number:'.2-2'}} €
                </div>
              </div>
              <div class="col-2 align-self-center text-center">
                <span style="color:#1d7cbc;font-size:26px" class="material-icons">euro</span>
              </div>
            </div>
          </div>
        </div>
        -->
    </div>

    <div class="row mb-3" *ngIf="!appComponent.isB2B && !appComponent.isSbt">
        <!--
        <div class="col-lg-6 mb-3" *ngIf="maincomponent.loggedUser.role.superAdmin && !appComponent.isGat">
          <app-moneychart></app-moneychart>
        </div>
        -->

        <div class="col-lg-6 mb-3" *ngIf="maincomponent.loggedUser.role.superAdmin && activeFlights && activeHotels && activeTrains && activeCars && !appComponent.isGat">
          <app-donutchart [flights]="activeFlights" [hotels]="activeHotels" [trains]="activeTrains" [cars]="activeCars"></app-donutchart>
        </div>

        <div class="col-lg-6" *ngIf="maincomponent.loggedUser.role.superAdmin && !appComponent.isSbt && !appComponent.isGat">
          <div class="default_panel">
            <div class="default_heading d-flex justify-content-between">
              <div class="d-flex align-self-center">Admin area</div>
              <div class="d-flex align-items-center">

              </div>
            </div>
            <div class="default_content">
              <ul class="dashmenu">
                <li>
                  <a routerLink="/loginAs" routerLinkActive="active-link">{{'LOGIN_AS'|translate}}</a>
                </li>
                <li>
                  <a routerLinkActive="active-link" routerLink="/adminArea/flightCryptic">
                    {{'FLIGHT_CRYPTIC'|translate}}
                  </a>
                </li>
                <li>
                  <a routerLinkActive="active-link" routerLink="/adminArea/selling-platform">
                    {{'SELLING_PLATFORM'|translate}}
                  </a>
                </li>
                <li>
                  <a routerLinkActive="active-link" routerLink="/adminArea/addProfile">
                    {{'ADD_PROFILE_COMPANY'|translate}}
                  </a>
                </li>
                <li>
                  <a routerLinkActive="active-link" routerLink="/adminArea/manage-iata">
                    {{'MANAGE_IATA'|translate}}
                  </a>
                </li>
                <li>
                  <a routerLinkActive="active-link" routerLink="/adminArea/manage-blocked-flight-rates">
                    {{'MANAGE_ISSUE_FARE_FLIGHT'|translate}}
                  </a>
                </li>
                <li>
                  <a routerLinkActive="active-link" routerLink="/adminArea/import-pnr">
                    {{'IMPORT_PNR'|translate}}
                  </a>
                </li>
                <li>
                  <a routerLinkActive="active-link" routerLink="/adminArea/incentives">
                    {{'MANAGE_INCENTIVES'|translate}}
                  </a>
                </li>
              </ul>
            </div>

          </div>

        </div>
    </div>

    <div class="row mb-3">
        <div class="col-lg-6 mb-3" *ngIf="!appComponent.isSbt && !appComponent.isGat">
          <div>
            <div class="widgetTitle">
              {{'REQUESTS_LIST'|translate}}
            </div>
            <div style="background-color: #fff;">
              <app-ticketslist [page]="'dashboard'"></app-ticketslist>
            </div>

          </div>
        </div>
    </div>
</div>
