import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AllSearchesComponent} from './containers/all-searches/all-searches.component';
import {RouterModule, Routes} from '@angular/router';
// import { SearchCardComponent } from './components/search-card/search-card.component';
import {MatCardModule} from '@angular/material/card';
import { FlightProductCardComponent } from './components/products/flight-product-card/flight-product-card.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import { SearchCardModule } from 'src/app/components/shared/search-card/search-card.module';
/*
const routes: Routes = [{
  path: 'policy-searches',
  component: AllSearchesComponent
}];*/
@NgModule({
  declarations: [AllSearchesComponent],
    imports: [
        CommonModule,
        // RouterModule.forChild(routes),
        MatCardModule,
        MatDividerModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        SearchCardModule
    ],
    exports:[
      AllSearchesComponent
    ],
})
export class SearchesModule {
}
