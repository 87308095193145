import {Component, OnInit} from '@angular/core';
import {SearchesService} from '../../services/searches.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-all-searches',
  templateUrl: './all-searches.component.html',
  styleUrls: ['./all-searches.component.scss']
})
export class AllSearchesComponent implements OnInit {

  all$ = this.searchesService.getAll().pipe(map(searches => this.mapSearchStatus(searches)));

  constructor(private searchesService: SearchesService) {
  }

  ngOnInit(): void {
  }

  mapSearchStatus(payload): any {
    console.log(payload);
    const searches = payload.data;
    searches.map((search: any) => {
      if (!search.authorisations) {
        return search;
      }

      searches.map(s => {
        const declined = s.authorisations.find(x => x.status === 'declined');
        if (!!declined) {
          s.status = 'declined';
          return s;
        }

        const approved = s.authorisations.every(x => x.status === 'approved');
        if (!!approved) {
          s.status = 'approved';
          return s;
        }

        s.status = 'pending';
        return s;
      });

      search.authorisations.map(auth => {
        const declined = auth.authorisationUser.find(x => x.status === 'declined');
        console.log(declined);
        if (!!declined) {

          auth.userResponse = declined?.requestUser;
          auth.userResponeNote = declined.note;
          return search;
        }

        const approved = auth.authorisationUser.find(x => x.status === 'approved');
        if (!!approved) {
          console.log(search.authorisations.every(x => x.status === 'approved'));

          auth.userResponse = approved?.requestUser;
          auth.userResponeNote = approved.note;
          return search;
        }
        auth.userResponse = null;
        auth.userResponeNote = null;
        return search;
      });
    });

    console.log(searches);
    return {...payload, data: searches};
  }
}
