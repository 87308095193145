interface Document {
  expired: boolean;
  expiryDate: Date;
  type: string;
  nationality: string;
  number: string;
  issuingCountry: string;
}

interface CostCenter {
  _id: string;
  tag: string;
  description: string;
  company: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export class LoyaltyCode {
  static TRENITALIA = 'TRENITALIA';
  code: string;
  type: string;
  product: string;
}

export class Traveler {
  document: Document;
  frequentFlyers: any[];
  saved: boolean;
  costCenter: CostCenter[];
  _id: string;
  name: string;
  surname: string;
  phoneNumber: string;
  company: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  sex: string;
  birthday: Date;
  email: string;
  type: string;
  loyaltyCodes: LoyaltyCode[];
  serialNumber: string;
}

