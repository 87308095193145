import {Component, Inject, OnInit} from '@angular/core';
import {MainComponent} from '../../../../../mainmodule/main/main.component';
import {AppComponent} from '../../../../../../app.component';
import {FlightService} from '../../../../../../services/flight/flight.service';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TicketingService } from 'src/app/services/ticketing/ticketing.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-refund-dialog',
  templateUrl: './refund-dialog.component.html',
  styleUrls: ['./refund-dialog.component.scss']
})
export class RefundDialogComponent implements OnInit {
  loadingRefund = false;
  refundData: any;
  taxMapping: any;
  tabs: any[] = [];
  currentTab: number = 0;
  error = false;
  ticket: any;
  route : string;
  confirmRefund = false;
  totTax: any[] = [];
  tariffRef: any [] = [];
  private readonly notifier : NotifierService;
  constructor(
    @Inject (MAT_DIALOG_DATA) public data : any,
    private flightService: FlightService,
    private router: Router,
    private dialogRef: MatDialogRef<RefundDialogComponent>,
    private translate: TranslateService,
    private ticketService : TicketingService,
    notifierService : NotifierService,
  ) {
    this.taxMapping = {
      PT: 'REFUND_PT',
      NRT: 'REFUND_NRT',
      R: 'REFUND_R',
      TP: 'REFUND_TP',
      701: 'REFUND_701',
    }
  }

  ngOnInit(): void {
    if(this.data.availableRefund && (this.data.reservation.company.services.addonFlight.automaticRefund || this.data.isSuperAdmin)){
      this.askRefund();
    } else {
      this.error = true;
    }
  }

  askRefund(){
    this.loadingRefund = true;
    const data = {
      id: this.data.reservation._id,
    }
    this.flightService.openRefund(data).subscribe((res) => {
      this.refundData = res;
      this.refundDiff(0)
      this.refundData.contracts.forEach((c:any, i:number) => {
        c.passengers.forEach((p:any, pnum: number) => {
          console.log('pnum => ', pnum);
          const pax = {
            name: p.LastName + ' ' + p.FirstName,
            ind: i,
            active: (i == 0) ? true : false,
          };
          this.tabs.push(pax);
        });
      });
      this.loadingRefund = false;
      this.error = false;
    }, error => {
      this.error = true;
      this.loadingRefund = false;
      console.error('Error => ', error);
    });
  }

  setTab(i:number){
    this.tabs.forEach((t:any) => t.active = false);
    this.tabs[i].active = true;
    this.currentTab = i;
    this.refundDiff(this.currentTab)
  }

  calculateTotal():number{
    let total = 0;
    this.refundData.contracts.forEach((c:any, i:number) => {
      total += c.refundable.amount;
    })
    return total;
  }

  returnFare(currentTab:any){
    let fareRefund = undefined;
    this.refundData.contracts[currentTab].monetary_information.forEach((m:any) => {
      if(m.Qualifier.toLowerCase() === 'fare refund'){
        fareRefund = m;
        const amountInDecimal = parseFloat(m.Amount) / Math.pow(10, parseInt(m.DecimalPlaces));
        // Formattazione dell'importo con il numero corretto di cifre decimali
        fareRefund.formattedAmount = amountInDecimal.toFixed(parseInt(m.DecimalPlaces));
      }
    })
    return fareRefund;
  }

  askingRefund(){
    this.confirmRefund =! this.confirmRefund;
  }

  patchRefund(){
    this.loadingRefund = true;
    const data = {
      id: this.data.reservation._id,
    }
    this.flightService.patchRefund(data).subscribe((res) => {
      this.refundData = res;
      this.loadingRefund = false;
      this.dialogRef.close(this.refundData);
    }, error => {
      this.loadingRefund = false;
      console.error('Error => ', error);
    });
  }

  sendTkt(){
    this.ticket = {
      subject: this.translate.instant('REFUND_TICKET'),
      body: {
        payload: this.router.url,
        data: this.translate.instant('RICHIESTA_RIMBORSO_PER_PNR') + ' ' + this.data.reservation.locator,
      },
      department: '10038',
      section: 'flight',
      locator: this.data.reservation.locator,
    };
    console.log('this.ticket => ', this.ticket);
    this.sendTicket();
    this.dialogRef.close(null);
  }

  sendTicket(){
    this.ticket.requestType = this.ticket.department;
    this.ticket.component = this.ticket.section;
    delete this.ticket.department;
    delete this.ticket.section;
    if(!this.data.isGat){
      this.ticketService.sendTicket(this.ticket).subscribe((res) => {
        this.loadingRefund = false;
        this.notifier.notify('success', this.translate.instant('TICKET_OPENED'));
      }, error => {
        this.loadingRefund = false;
        // this.notifier.notify('success', this.translate.instant('TICKET_OPENED'));
        // this.notifier.notify('error', error);
        this.notifier.notify('error', this.translate.instant(error));
        console.error('Error => ', error);
      });
    } else {
      const form = {
        'orgid': '00D2o000000Z8l4',
        'retURL': 'https://flygattinoni.it/',
        // 'debug': '1',
        'debugEmail':'alessio.marinoni@gattinoni.it',
        'recordType':'0127T000000PElV',
        '00N2o000008u57G': '',
        'name': this.data.reservation.user.name + ' ' + this.data.reservation.user.surname,
        'email': this.data.reservation.user.email,
        'phone': this.data.reservation.user.phoneNumber,
        '00N2o000008uM6n':'FlyGattinoni',
        '00N7T000000qGJS':'Richiesta di rimborso',
        '00N7T000000qFOY':'Richiesta rimborso in GDS',
        'subject':'',
        'description':'',
        'status':'Aperta Richiesta Assistenza',
        '00N7T000001BIcJ':this.data.reservation.locator,
      }
      this.ticketService.postGatTkt(form).subscribe((res) => {
        this.loadingRefund = false;
        this.notifier.notify('success', this.translate.instant('TICKET_OPENED'));
      }, error => {
        this.loadingRefund = false;
        // this.notifier.notify('success', this.translate.instant('TICKET_OPENED'));
        // this.notifier.notify('error', error);
        this.notifier.notify('error', this.translate.instant(error));
        console.error('Error => ', error);
      });
    }
  }

  refundDiff(currentTab : number){
    /*console.log(this.refundData.contracts[currentTab])*/
    this.totTax[currentTab] = 0;
    this.refundData.contracts[currentTab].taxes.forEach((tax:any) => {
      if (tax.Category !== 'PT' && tax.Category !== 'NRT' && tax.Category !== '701') this.totTax[currentTab] += tax.amount;
    })
    this.tariffRef[currentTab] = (this.refundData.contracts[currentTab].refundable.amount - this.totTax[currentTab].toFixed(2)).toFixed(2);
  }
}
