<div [ngClass]="{'padding20':!page}">
  <custom-title></custom-title>
  <div class="card marginbottom20">

    <div *ngIf="loading" class="loader">
      <div class="card text-center padding20">
        <div class="d-flex justify-content-center">
          <mat-spinner></mat-spinner>
        </div>
        <h5>{{'LOADING'|translate}}...</h5>
      </div>
    </div>

    <div *ngIf="!loading" class="row">

      <div class="col-lg-6 d-flex align-items-center pl-3 pt-3 pt-md-0">
        <button [matMenuTriggerFor]="tabMenu" mat-button class="listButton">
          <span *ngIf="!!query.filter">{{ query.filter | translate}} ({{returnTabNum(query.filter)}}) </span>
          <span *ngIf="!query.filter">{{ 'all' | translate}} ({{reservationsList?.total}})</span>
          <span class="material-icons">keyboard_arrow_down</span>
        </button>
        <mat-menu #tabMenu="matMenu">
          <button (click)="changeTab('')" mat-menu-item>{{ 'all' | translate}} ({{reservationsList?.total}})
          </button>
          <span *ngFor="let status of reservationsList?.tabs">
            <button (click)="changeTab(status._id)" mat-menu-item>{{ status._id | translate}} ({{status.total}})</button>
          </span>
        </mat-menu>

        <button [matMenuTriggerFor]="orderMenu" mat-button class="listButton">
          {{sort|translate}}<span class="material-icons">keyboard_arrow_down</span>
        </button>
        <mat-menu #orderMenu="matMenu">
          <button (click)="sortValue('createdAt')" mat-menu-item>{{'CREATED_DATE'|translate}}</button>
          <button (click)="sortValue('issuedDate')" *ngIf="query.filter=='issued'" mat-menu-item>{{'ISSUED_DATE'|translate}}</button>
          <button (click)="sortValue('cancellation.date')" *ngIf="query.filter=='cancelled'" mat-menu-item>{{'CANCELLATION_DATE'|translate}}</button>
          <button (click)="sortValue('ratedPrice')" mat-menu-item>{{'PRICE'|translate}}</button>
        </mat-menu>

        <button (click)="reverseOrder()" mat-icon-button class="iconButtons" [ngSwitch]="order">
          <mat-icon  *ngSwitchCase="true" matTooltip="{{'ASC'|translate}}" style="transform: rotate(180deg)">sort</mat-icon>
          <mat-icon *ngSwitchCase="false" matTooltip="{{'DESC'|translate}}">sort</mat-icon>
          <mat-icon *ngSwitchDefault matTooltip="{{'DESC'|translate}}">sort</mat-icon>
        </button>

        <button class="iconButtons" (click)="openFilters()" mat-icon-button>
          <mat-icon matTooltip="{{'SEARCH'|translate}}">search</mat-icon>
        </button>

        <mat-checkbox *ngIf="mainComponent.loggedUser.role.superAdmin && !appComponent.isSbt && !appComponent.isGat" [(ngModel)]="query.viewAll"
                      class="checkBoxes vertical ml-2 m-md-1 d-inline-block" (ngModelChange)="getReservationList()" color="primary">
          viewAll
        </mat-checkbox>
      </div>
      <div class="col-lg-6 d-flex justify-content-end text-right pb-2 pb-md-0">
        <div class="paginationr pr-3" *ngIf="pageNumbers">
          <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
          <span class="mr-2">
            {{'PAGE'|translate}}
            <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
              <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                <mat-option *ngFor="let p of pageNumbers" [value]="p">{{p + 1}}</mat-option>
              </mat-select>
            </mat-form-field>
            {{'OF'|translate}} {{pageNumbers.length}}
          </span>
          <button *ngIf="pageNumbers" class="iconButtons" (click)="prev()" [disabled]="currentpage==0" mat-icon-button>
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button *ngIf="pageNumbers" class="iconButtons" (click)="next()" [disabled]="currentpage==(pageNumbers.length-1)" mat-icon-button>
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="!loading" class="collapse pnrfilters padding10">
      <hr/>
      <div class="row marginbottom10">
        <div class="col-12 col-sm-6 col-md-4 mb-2 align-self-center">
          <form autocomplete="off" class="example-form">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{'SEARCH_PNR'|translate}}</mat-label>
              <input (change)="setFilterText()" [(ngModel)]="query.pnr" [ngModelOptions]="{standalone: true}" autocomplete="off"
                     matInput type="text">
              <!--
              <button mat-button *ngIf="query.pnr" matSuffix mat-icon-button aria-label="Clear"
                      (click)="clearFilterText()">
                <mat-icon>close</mat-icon>
              </button> -->
              <mat-hint align="start">{{'DIGIT_YOUR_SEARCH'|translate}}</mat-hint>
              <mat-hint (click)="clearFilterText()" *ngIf="query.pnr" align="end" style="color:red;cursor:pointer;">{{'CLEAR_SEARCH'|translate}} X</mat-hint>
            </mat-form-field>
          </form>
        </div>

        <div class="col-12 col-sm-6 col-md-4 mb-2 align-self-center" *ngIf="this.mainComponent.loggedUser.role.superAdmin || this.mainComponent.loggedUser.company.services.b2c || this.mainComponent.loggedUser.company.services.b2b">
         <mat-form-field appearance="outline" class="full-width">
            <mat-select multiple [(ngModel)]="query.channels" name="saleChannel" (closed)="getReservationList()" *ngIf="saleChannels.length > 1">
              <mat-option *ngFor="let channel of saleChannels" [value]="channel" [disabled]="query.channels.length === 1 && query.channels.includes(channel)">
                {{ channel|translate }}
              </mat-option>
            </mat-select>
         </mat-form-field>
        </div>

        <div class="col-12 col-sm-6 col-md-4 mb-2 align-self-center">
          <mat-checkbox [(ngModel)]="query.carnets" (ngModelChange)="getReservationList()">{{ 'TRAIN_MODULE.TRENIT.CARNET_FILTER' | translate }}</mat-checkbox>
        </div>
        <div *ngIf="mainComponent.loggedUser.role.superAdmin && !page" class="col-12 col-sm-6 col-md-4 mb-2">
          <!-- <app-companies [filtering]="filterCompanies" [query]="query"></app-companies> -->
        </div>
        <div *ngIf="filterCompanies.length>0" class="col-12 mb-2 cpfilter">
          <hr/>
          <h6 class="mb-2">{{'FILTERING_COMPANIES'|translate}}</h6>
          <div *ngIf="filterCompanies.length>0 && !page">
            <mat-chip-listbox *ngIf="filterCompanies[0].name" aria-label="Companies selections" class="d-inline">
              <mat-chip-option *ngFor="let element of filterCompanies">
                {{element.name}}
                <mat-icon (click)="removeCpFilter(element._id)" matChipRemove>cancel</mat-icon>
              </mat-chip-option>
            </mat-chip-listbox>
            <div *ngIf="!filterCompanies[0].name">
              {{filterCompanies.length}} {{'COMPANIES_SELECTED'|translate}}
              <button (click)="clearAllCp()" mat-button>{{'CLEAR_ALL_CP'|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="progress" class="">
      <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>

  <div *ngIf="reservationsList && reservationsList?.reservations.length > 0" class="trainList">

    <mat-card appearance="outlined" *ngFor="let reservation of reservationsList?.reservations" class="example-card mb-3 px-2 pt-4 pb-2">
      <mat-card-header class="mx-2 pt-0">
        <div class="example-header-image" mat-card-avatar style="background-image: url('{{getTrenitLogo(reservation)}}')"></div>
        <mat-card-title>
          {{reservation.pnr || reservation.id}}
        </mat-card-title>
        <mat-card-subtitle class="my-3">
          <span [class]="reservation.status" class="pnrNewStatus">
            <span *ngIf="reservation.status=='RESERVED'" class="smalIcon material-icons">done</span>
            <span *ngIf="reservation.status=='COMPLETED'" class="smalIcon material-icons">task_alt</span>
            <span *ngIf="reservation.status=='EXPIRED'" class="smalIcon material-icons">close</span>
            <span *ngIf="reservation.status=='REFUNDED'" class="smalIcon material-icons">close</span>
            <span *ngIf="reservation.status=='CANCELLED'" class="smalIcon material-icons">close</span>
            {{reservation.status|translate}}
          </span>
          <span> - {{reservation.carrier}}</span>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="pb-1">
        <div class="row my-2">
          <div class="col-lg-3">
            <div *ngIf="reservation.createdAt" class="dateaction">
              {{'BOOKED_ON'|translate}} {{reservation.createdAt|date:'dd/MM/yyyy - HH:mm'}}
            </div>
            <div *ngIf="reservation.issuedDate" class="dateaction">
              {{'ISSUED_ON'|translate}} {{reservation.issuedDate|date:'dd/MM/yyyy - HH:mm'}}
            </div>
          </div>
          <div class="col-lg-5">
            <div *ngFor="let itinerary of reservation.itineraries;let l = last" [ngClass]="{'mb-2':!l}" class="row newItemPnr">
              <div class="col-5">
                <b>{{itinerary.from.date|date:'dd/MM/yyyy - HH:mm'}}</b>
                <br/>
                {{itinerary.from.station}}
              </div>
              <div class="col-1 align-self-center" matTooltip="{{ itinerary.trainClassification }} {{ itinerary.trainNumber }}">
                <span class="material-icons">arrow_right_alt</span>
                <span class="material-icons">train</span>
              </div>
              <div class="col-5">
                <b>{{itinerary.to.date|date:'dd/MM/yyyy - HH:mm'}}</b>
                <br/>
                {{itinerary.to.station}}
              </div>
              <div class="col-1">
                <span class="material-icons" matTooltip="{{ itinerary.bookingClass }} {{ itinerary.fareType }}">info</span>
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <div *ngIf="reservation.travellers.length>0">
              <div *ngFor="let guest of reservation.travellers">
                <span *ngIf="guest.doc?.surname">{{guest.doc?.surname}}</span>
                <span *ngIf="guest.doc?.name"> {{guest.doc?.name}}</span>
                <sup> ({{guest.type}})</sup>
              </div>
            </div>
          </div>
          <div class="col-lg-2 text-center">
            <div class="reservationPrice marginbottom5">
              <span class="recoprice">
                {{reservation.ratedPrice|number:'.2-2'}}€
              </span>
            </div>
          </div>
        </div>
        <div class="row pnrActionRow">
          <div class="col-lg-6">
            <div class="mt-2">
              <a routerLink="/users/list/{{reservation.user._id}}">{{reservation.user.surname}} {{reservation.user.name}}</a>
              <span class="material-icons">arrow_right_alt</span>
              <a routerLink="/companies/list/{{reservation.company._id}}">{{reservation.company.name}}</a>
            </div>
          </div>
          <div class="col-lg-6 text-right buttonrowBis">
            <button (click)="toggleAddtoTravel(reservation._id)" *ngIf="!page && !reservation.paper" mat-stroked-button>
              {{'ADD_TO_FOLDER'|translate}}
            </button>
            <a *ngIf="reservation.paper" mat-stroked-button routerLink="/dossier/list/{{reservation.paper}}">
              {{'GO_TO_DOSSIER'|translate}}
            </a>
            <a color="primary" mat-flat-button routerLink="/trains/reservations/{{reservation.pnr}}">
              {{'OPEN_RESERVATION'|translate}}
            </a>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <!--
    <div class="card padding20 mb-3" *ngFor="let reservation of reservationsList?.reservations">
      <div class="row">
        <div class="col-9 align-self-center">

          <div class="row">
            <div class="col-lg-3 align-self-center">
              <div *ngIf="!page && (reservation.issuedDate)" class="buttonrow mb-2">
                <button mat-stroked-button (click)="toggleAddtoTravel(reservation._id)">{{'ADD_TO_FOLDER'|translate}}</button>
              </div>
              <div class="pnrliststatus marginbottom5">
                <span class="{{reservation.status}}">{{reservation.status|translate}}</span>
              </div>
            </div>

            <div class="col-lg-6 align-self-center">
              <hr class="d-sm-block d-md-none d-lg-none"/>
              <div class="travelinfo mr-2">
                <ng-container *ngFor="let itinerary of reservation.itineraries;let l = last;">
                  <div class="smalltext mt-2 font-weight-bold">
                    <div>Departure: {{itinerary.from.station}} @ {{itinerary.from.date|date:'dd/MM/yyyy - HH:mm'}}</div>
                    <div>Arrival: {{itinerary.to.station}} @ {{itinerary.to.date|date:'dd/MM/yyyy - HH:mm'}}</div>
                  </div>
                </ng-container>

              </div>
            </div>

            <div class="col-lg-3 align-self-center">
              <hr class="d-md-block d-lg-none"/>
              <div *ngIf="reservation.createdAt" class="dateaction">
                {{'BOOKED_ON'|translate}} {{reservation.createdAt|date:'dd/MM/yyyy - HH:mm'}}
              </div>
              <div *ngIf="reservation.issuedDate" class="dateaction">
                {{'ISSUED_ON'|translate}} {{reservation.issuedDate|date:'dd/MM/yyyy - HH:mm'}}
              </div>
              <div *ngIf="reservation.travellers.length>0">
                <div *ngFor="let guest of reservation.travellers" class="listpnrsinglepasseng">
                  <span *ngIf="guest.doc?.surname">{{guest.doc?.surname}}</span> <span
                  *ngIf="guest.doc?.name"> {{guest.doc?.name}}</span>
                  <sup> ({{guest.type}})</sup>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-3 align-self-center text-center">
          <div class="reservationimage">
            <img *ngIf="reservation.carrier == 'ITALO'" src="assets/img/trains/italo.png">
            <img *ngIf="reservation.carrier == 'TRENITALIA'" [src]="getTrenitLogo(reservation)">
            <span style="text-transform:uppercase;">
              {{reservation.carrier}}
            </span>
          </div>
          <div class="reservationPrice marginbottom5">
              <span class="recoprice">
                {{reservation.ratedPrice|number:'.2-2'}}€
              </span>
          </div>
          <div class="linktopnr">
            <a routerLink="/trains/reservations/{{reservation.pnr}}">{{'OPEN_RESERVATION'|translate}}</a>
          </div>
        </div>

        <div class="col-12 pnrlistuser">
          <hr/>
          <div>
            <a routerLink="/users/list/{{reservation.user?._id}}">{{reservation.user?.surname}} {{reservation.user?.name}}</a>
            <mat-icon>arrow_right_alt</mat-icon>
            <a routerLink="/companies/list/{{reservation.company?._id}}">{{reservation.company?.name}}</a>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>

  <div *ngIf="!loading" class="card">
    <div class="paginationr pr-3 justify-content-end">
      <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>

      <span class="mr-2" *ngIf="pageNumbers">
        {{'PAGE'|translate}}
        <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
          <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
            <mat-option *ngFor="let p of pageNumbers;" [value]="p">{{p + 1}}</mat-option>
          </mat-select>
        </mat-form-field>
        {{'OF'|translate}} {{pageNumbers.length}}
      </span>

      <button *ngIf="pageNumbers" class="iconButtons" (click)="prev()" [disabled]="currentpage==0" mat-icon-button>
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button *ngIf="pageNumbers" class="iconButtons" (click)="next()" [disabled]="currentpage==(pageNumbers.length-1)" mat-icon-button>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <div aria-hidden="true" aria-labelledby="travelListModal" class="modal fade" data-backdrop="static" id="travelListModal"
       role="dialog" tabindex="-1">
    <div class="modal-dialog-scrollable modal-dialog modal-xl" role="document">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addGuestModalTitle">
            {{'ADD_TO_FOLDER'|translate}}
          </h5>
          <button (click)="toggleAddtoTravel(null)" class="close" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div *ngIf="currentTrainId" class="modal-body">
          <app-dossierlist (close)="closeAddtoTravel()" [idTrain]="currentTrainId" page="train"></app-dossierlist>
        </div>
        <hr/>
        <div class="text-right buttonrow">
          <button (click)="toggleAddtoTravel(null)" mat-button type="button">{{'CLOSE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
