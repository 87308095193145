import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DossierlistComponent } from './components/dossierlist/dossierlist.component';
import { SingledossierComponent } from './components/singledossier/singledossier.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { FlightslistComponent } from './components/flightslist/flightslist.component';
import { HotelslistComponent } from './components/hotelslist/hotelslist.component';
import { CarslistComponent } from './components/carslist/carslist.component';
import { CustomCommonModule } from '../common/custom-common.module';
import {MatChipsModule as MatChipListModule} from "@angular/material/chips";

@NgModule({
  declarations: [
    DossierlistComponent,
    SingledossierComponent,
    FlightslistComponent,
    HotelslistComponent,
    CarslistComponent,
  ],
  exports: [
    DossierlistComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    CustomCommonModule,
    MatChipListModule,
  ],
})
export class DossierModule{}
