<div class="container text-center mt-5" @listItemAnimation *ngIf="!settingsLoaded">
  {{'INITIALIZING'|translate}}
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>
<div class="maincontainer" *ngIf="settingsLoaded && loggedUser.company.services" @listItemAnimation>
  <app-topbar></app-topbar>
  <app-settings *ngIf="userSettings"></app-settings>

  <app-mainnotifications></app-mainnotifications>

  <mat-sidenav-container class="example-container" [hasBackdrop]="mobileQuery.matches">
    <mat-sidenav *ngIf="!userSettings.menuTop" class="main-sidenav" [ngClass]="{'paddingTop40':dossierId}" [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [(opened)]="opened">
      <!--<app-sidemenu *ngIf="!appcomponent.isB2B" [sarce]="sarce"></app-sidemenu> -->
      <app-sidemenu-version2 [sarce]="sarce"></app-sidemenu-version2>
    </mat-sidenav>
    <mat-sidenav-content>
      <div *ngIf="dossierId" class="dossierBar d-none d-sm-block" @listItemAnimation>
        <div class="toolbarContent">
          <div class="row buttonrow">
            <div class="col-6">
              <!--
              <div *ngIf="sarce" class="d-inline-block" style="width: 50px">
                <mat-progress-bar mode="buffer"></mat-progress-bar>
              </div> -->
              <!-- {{'ACTIVE_DOSSIER'|translate}}: {{dossierName}} -->
              <button mat-button matTooltip="{{'GO_TO_DOSSIER'|translate}}" (click)="goToDossier()">
                {{'OPEN_DOSSIER'|translate}} <span class="material-icons">keyboard_arrow_right</span> {{dossierName}}
              </button>
              <button *ngIf="sarce" matTooltip="{{'Torna a sarce'|translate}}" mat-button (click)="backToSarce()">
                Torna a sarce <span class="material-icons">redo</span>
              </button>
            </div>
            <div class="col-6 text-right">
              <button class="iconButtons" matTooltip="{{'INFO'|translate}}" mat-icon-button [matMenuTriggerFor]="menuDossier" aria-label="Expand dossier">
                <mat-icon>info</mat-icon>
              </button>
              <mat-menu #menuDossier="matMenu">
                <div [hidden]="dossierItems.length>0" mat-menu-item class="fulldrop mb-2">
                  {{'DOSSIER_IS_EMPTY'|translate}}
                </div>
                <div [hidden]="dossierItems.length == 0" mat-menu-item class="fulldrop mb-2" *ngFor="let item of dossierItems" [ngSwitch]="item.type">
                  <div class="row mb-2" *ngSwitchCase="'flight'">
                    <div class="col-2">
                      <span class="material-icons">flight</span>
                    </div>
                    <div class="col-4">
                      {{item.flights[0].departure.dateTime|date:'dd/MM/yyyy'}} <br/>
                      {{item.flights[0].departure.hours}}:{{item.flights[0].departure.minutes}} <br/>
                      <span class="locationspan">{{item.flights[0].departure.location}}</span>
                    </div>
                    <div class="col-2 align-self-center">
                      <span class="material-icons">arrow_right_alt</span>
                    </div>
                    <div class="col-4">
                      {{item.flights[item.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy'}} <br/>
                      {{item.flights[item.flights.length-1].arrival.hours}}:{{item.flights[item.flights.length-1].arrival.minutes}} <br/>
                      <span class="locationspan">{{item.flights[item.flights.length-1].arrival.location}}</span>
                    </div>
                  </div>

                  <div class="row mb-2" *ngSwitchCase="'hotel'">
                    <div class="col-2">
                      <span class="material-icons">hotel</span>
                    </div>
                    <div class="col-10 mb-2">
                      <div class="roomName">{{item.room.roomRates[0].text}}</div>
                    </div>
                    <div class="col-2">

                    </div>
                    <div class="col-4">
                      Check-in: {{item.hotel.checkIn|date:'dd/MM/yyyy'}}
                    </div>
                    <div class="col-2">
                      <span class="material-icons">arrow_right_alt</span>
                    </div>
                    <div class="col-4">
                      Check-out: {{item.hotel.checkOut|date:'dd/MM/yyyy'}}
                    </div>
                  </div>

                  <div class="row mb-2" *ngSwitchCase="'car'">
                    <div class="col-2">
                      <span class="material-icons">directions_car</span>
                    </div>
                    <div class="col-4">
                      {{returnDate(item.car.pickUpInfo.date)|date:'dd/MM/yyyy - HH:mm':"+0000"}} <br/>
                      {{item.car.pickUp}}
                    </div>
                    <div class="col-2">
                      <span class="material-icons">arrow_right_alt</span>
                    </div>
                    <div class="col-4">
                      {{returnDate(item.car.dropOffInfo.date)|date:'dd/MM/yyyy - HH:mm':"+0000"}} <br/>
                      {{item.car.dropOff}}
                    </div>
                  </div>

                  <div class="row mb-2" *ngSwitchCase="'train'">
                    <div class="col-2">
                      <span class="material-icons">train</span>
                    </div>
                    <div class="col-4">
                      {{returnDate(item.departure.date)|date:'dd/MM/yyyy - HH:mm':"+0000"}} <br/>
                      {{item.departure.station}}
                    </div>
                    <div class="col-2">
                      <span class="material-icons">arrow_right_alt</span>
                    </div>
                    <div class="col-4">
                      {{returnDate(item.arrival.date)|date:'dd/MM/yyyy - HH:mm':"+0000"}} <br/>
                      {{item.arrival.station}}
                    </div>
                  </div>

                </div>
              </mat-menu>
              <button class="iconButtons" *ngIf="!sarce" matTooltip="{{'STOP_SYNC'|translate}}" mat-icon-button (click)="destroyDossier()"> <!-- [hidden]="loggedUser.role.company === false && !customAuth.canBook(loggedUser.company._id)" -->
                <mat-icon>pause</mat-icon>
              </button>
              <button class="iconButtons" matTooltip="{{'ADD_FLIGHT'|translate}}" mat-icon-button [matMenuTriggerFor]="dossierFlight">
                <mat-icon>flight</mat-icon>
              </button>
              <mat-menu #dossierFlight="matMenu">
                <a [hidden]="loggedUser.role.company === false && !customAuth.canBook(loggedUser.company._id)" routerLink="/flight/reservations"
                   mat-menu-item>{{'PNR_LIST'|translate}}</a>
                <a routerLink="/flight/search" mat-menu-item>{{'SEARCH_FLIGHT'|translate}}</a>
              </mat-menu>

              <button class="iconButtons" matTooltip="{{'ADD_HOTEL'|translate}}" mat-icon-button [matMenuTriggerFor]="dossierHotel">
                <mat-icon>hotel</mat-icon>
              </button>
              <mat-menu #dossierHotel="matMenu">
                <button [hidden]="loggedUser.role.company === false && !customAuth.canBook(loggedUser.company._id)" routerLink="/hotel/reservations"
                        mat-menu-item>{{'HOTEL_RESERVATION_LIST'|translate}}</button>
                <button mat-menu-item routerLink="/hotel/search">{{'HOTEL_SEARCH'|translate}}</button>
              </mat-menu>


              <button class="iconButtons" mat-icon-button [matMenuTriggerFor]="dossierTrain" *ngIf="appcomponent.isB2B || appcomponent.isSbt">
                <mat-icon>train</mat-icon>
              </button>
              <mat-menu #dossierTrain="matMenu">
                <button routerLink="/trains/reservations" mat-menu-item>{{'TRAIN_RESERVATION_LIST'|translate}}</button>
                <button mat-menu-item routerLink="/trains/search">{{'TRAIN_SEARCH'|translate}}</button>
              </mat-menu>
              <button class="iconButtons" mat-icon-button [matMenuTriggerFor]="dossierCar" *ngIf="appcomponent.isB2B || appcomponent.isSbt">
                <mat-icon>directions_car</mat-icon>
              </button>
              <mat-menu #dossierCar="matMenu">
                <button routerLink="/car-rent/reservations" mat-menu-item>{{'CAR_RESERVATION_LIST'|translate}}</button>
                <button mat-menu-item routerLink="/car-rent/search">{{'CAR_RENT_SEARCH'|translate}}</button>
              </mat-menu>

            </div>
          </div>
        </div>

      </div>
      <div class="maincontent" [ngClass]="{'paddingtop40':dossierId, 'boxedWh': appcomponent.isSbt}">
        <router-outlet @listItemAnimation>
          <div class="row buttonrow" style="padding:5px;">
            <div class="col-4 align-self-center">
              <button color="primary" class="ml-1 pl-3" mat-button (click)="backFunction()" *ngIf="isDashBoard()">
                <span class="material-icons">
                  undo
                </span>
                {{'BACK'|translate}}
              </button>
            </div>
            <div class="col-8 align-self-center">
              <div class="text-right">
                <img class="maincplogo" [src]="loggedUser.CpImage"/>
              </div>
            </div>
          </div>

          <div class="modal fade" id="addDosier" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="addDosier" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <div class="modal-title">
                    {{'CREATE_DOSSIER'|translate}}
                  </div>
                  <button type="button" class="close" (click)="addDossierToggle()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">

                  <div class="row mb-2">
                    <div class="col-6">
                      <mat-form-field class="full-width">
                        <mat-label>{{'DOSSIER_NAME'|translate}}</mat-label>
                        <input matInput type="text" [(ngModel)]="newDossier">
                        <button class="iconButtons" *ngIf="newDossier" matSuffix mat-icon-button aria-label="Clear" (click)="newDossier=''">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </div>
                    <div class="col-6 text-center align-self-center">
                      <div>
                        <span class="pointer d-block d-sm-inline mb-2" [matMenuTriggerFor]="menu">
                          {{'PASSENGERS'|translate}}
                          <span *ngIf="paxCounter()>0">:
                            <mat-icon >remove</mat-icon>
                            {{paxCounter()}}
                            <mat-icon >add</mat-icon>
                          </span>
                        </span>
                        <mat-menu #menu="matMenu">
                          <div (click)="$event.stopPropagation();" mat-menu-item class="menuItem">
                            <button class="iconButtons" [disabled]="numberOfAdults==1" mat-icon-button aria-label="Minus" (click)="removePax('adt')">
                              <mat-icon>remove</mat-icon>
                            </button>
                            <mat-form-field class="inline-width">
                              <mat-label>{{'ADULTS'|translate}}</mat-label>
                              <input class="text-center" readonly name="adt" [(ngModel)]="numberOfAdults" matInput>
                            </mat-form-field>
                            <button class="iconButtons" [disabled]="maxPaxReached()" mat-icon-button aria-label="Plus" (click)="addPax('adt')">
                              <mat-icon>add</mat-icon>
                            </button>
                          </div>
                          <div (click)="$event.stopPropagation();" mat-menu-item *ngIf="!appcomponent.isSbt && !appcomponent.isB2B" class="menuItem">
                            <button class="iconButtons" [disabled]="numberOfChildren==0" mat-icon-button aria-label="Minus" (click)="removePax('chd')">
                              <mat-icon>remove</mat-icon>
                            </button>
                            <mat-form-field class="inline-width">
                              <mat-label>{{'CHILDREN'|translate}}</mat-label>
                              <input class="text-center" readonly [(ngModel)]="numberOfChildren" matInput>
                            </mat-form-field>
                            <button class="iconButtons" [disabled]="maxPaxReached()" mat-icon-button aria-label="Plus" (click)="addPax('chd')">
                              <mat-icon>add</mat-icon>
                            </button>
                          </div>
                          <div (click)="$event.stopPropagation();" mat-menu-item  *ngIf="!appcomponent.isSbt && !appcomponent.isB2B" class="menuItem">
                            <button class="iconButtons" [disabled]="numberOfInfants==0" mat-icon-button aria-label="Minus" (click)="removePax('inf')">
                              <mat-icon>remove</mat-icon>
                            </button>
                            <mat-form-field class="inline-width">
                              <mat-label>{{'INFANT'|translate}}</mat-label>
                              <input class="text-center" readonly [(ngModel)]="numberOfInfants" matInput>
                            </mat-form-field>
                            <button class="iconButtons" [disabled]="maxInfReached()" mat-icon-button aria-label="Plus" (click)="addPax('inf')">
                              <mat-icon>add</mat-icon>
                            </button>
                          </div>
                          <div *ngIf="maxPaxReached()" (click)="$event.stopPropagation();" mat-menu-item class="warn my-1">
                            {{ "MAX_PAX" | translate }}
                          </div>
                          <div *ngIf="numberOfAdults==numberOfInfants && maxInfReached()" (click)="$event.stopPropagation();" class="warn my-1" mat-menu-item>
                            {{ "MAX_INF_PER_ADT" | translate }}
                          </div>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-6 mb-4" *ngFor="let tra of selectedTravelers; let i = index;">

                      <div>
                        <div class="fakefield mb-2">
                          {{'TYPE'|translate}}:
                          <span> {{tra.type|translate}}</span>
                        </div>
                        <div class="fakefield mb-2">
                          {{'NAME'|translate}}:
                          <span *ngIf="tra.name"> {{tra.name}}</span>
                          <span *ngIf="!tra.name"> _ _ _ _</span>
                        </div>
                        <div class="fakefield mb-2">
                          {{'SURNAME'|translate}}:
                          <span *ngIf="tra.surname"> {{tra.surname}}</span>
                          <span *ngIf="!tra.surname"> _ _ _ _</span>
                        </div>
                        <div class="fakefield mb-2">
                          {{'PHONE'|translate}}:
                          <span *ngIf="tra.phoneNumber"> {{tra.phoneNumber}}</span>
                          <span *ngIf="!tra.phoneNumber"> _ _ _ _</span>
                        </div>
                        <div class="fakefield mb-2">
                          {{'EMAIL'|translate}}:
                          <span *ngIf="tra.email"> {{tra.email}}</span>
                          <span *ngIf="!tra.email"> _ _ _ _</span>
                        </div>
                        <div class="buttonrow">
                          <button mat-stroked-button *ngIf="!tra.name || !tra.surname" (click)="toggleDialog(i)">{{'ADD_TRAVELER'|translate}}</button>
                          <button mat-stroked-button *ngIf="tra.name || tra.surname" (click)="removeTraveler(i)">{{'REMOVE_TRAVELER'|translate}}</button>
                          <button mat-stroked-button *ngIf="tra.name || tra.surname" (click)="toggleEditDialog(i)">{{'EDIT_TRAVELER'|translate}}</button>
                          <button mat-stroked-button *ngIf="!tra.name && !tra.surname"  (click)="loadTravelersList(i)">{{'LOAD_TRAVELER'|translate}}</button>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div class="modal fade" id="mainaddTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel"
                       aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div *ngIf="currentTraveler!=undefined" class="modal-body">
                          <app-add [trDate]="" [newTraveler]="selectedTravelers[currentTraveler]" (close)="closeEvent($event)"
                                   (setTraveler)="travelerSet($event, currentTraveler)"></app-add>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal fade" id="mainlistTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel"
                       aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content" *ngIf="loadTravelers">

                        <div *ngIf="currentTraveler!=undefined" class="modal-body">
                          <app-travelerslist [trDate]="" [newTraveler]="selectedTravelers[currentTraveler]" [passengers]="selectedTravelers" [page]="'main'"
                                             (close)="closeListEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)"></app-travelerslist>
                          <hr/>
                          <div class="buttonrow text-right">
                            <button mat-stroked-button (click)="closeListEvent($event)">{{'CLOSE'|translate}}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal fade" id="mainEditTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel"
                       aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div *ngIf="currentTraveler!=undefined && editingTraveler" class="modal-body">
                          <app-edittraveler (close)="closeEditEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)"
                                            [currentTraveler]="selectedTravelers[currentTraveler]" [parent]="'main'"></app-edittraveler>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="text-right">
                    <button type="button" mat-button (click)="addDossierToggle()">{{'CLOSE'|translate}}</button>
                    <button type="button" mat-button color="primary" [disabled]="!newDossier" (click)="createDossier()">{{'CREATE_DOSSIER'|translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <app-openticket *ngIf="customAuth.returnAuth(loggedUser._id)" ></app-openticket>

  <div class="activeDossier d-block d-sm-none" *ngIf="dossierId">
    <span class="material-icons errorIc" (click)="openDsDetails()">
      error
    </span>
    <span class="material-icons closeIc" (click)="openDsDetails()">
      close
    </span>
  </div>
  <div class="dossierDetails d-block d-sm-none" *ngIf="dossierId">
    <div class="insideContent">
      <span class="material-icons"> topic </span>
      <div class="mt-2">{{'ACTIVE_DOSSIER'|translate}}</div>
      <hr/>
      <div class="mb-2">{{dossierName}}</div>
      <hr/>
      <div class="buttonrow">
        <button mat-stroked-button class="stopsync" (click)="goToDossier()">
          {{'GO_TO_DOSSIER'|translate}}
          <span class="material-icons">
            arrow_right_alt
          </span>
        </button>
        <button *ngIf="!sarce" mat-icon-button matTooltip="{{'STOP_SYNC'|translate}}" class="stopsync" (click)="destroyDossier()">
          <mat-icon>
            stop
          </mat-icon>
        </button>
        <button *ngIf="sarce" matTooltip="{{'BACK_TO_SARCE'|translate}}" mat-icon-button (click)="backToSarce()">
          <mat-icon>redo</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

