<div mat-dialog-title>
  <span *ngIf="data.availableRefund">
    {{'AUTOMATIC_REFUND'|translate}}
  </span>
  <span *ngIf="!data.availableRefund">
    {{'REFUND_TICKET'|translate}}
  </span>
</div>
<mat-dialog-content class="mat-typography">
  <div *ngIf="data.availableRefund">
    <div *ngIf="loadingRefund">
      <div class="text-center mb-3">
        {{'LOADING'|translate}}
      </div>

      <div><mat-progress-bar mode="buffer"></mat-progress-bar></div>
    </div>
    <div *ngIf="refundData && !loadingRefund">

      <div class="newPnrPagination">

        <div (click)="setTab(tab.ind)" class="singleNewVoice" [ngClass]="{'activeNew':tab.active}" *ngFor="let tab of tabs; let i = index">
          {{tab.name}}
        </div>
      </div>
      <div class="newTabHeader">
        <div class="row">
          <div class="col-6 align-self-center">{{'REFUND_DETAILS'|translate}}</div>
        </div>

      </div>
      <div id="tabsContainerNew" class="marginbottom20 padding1020">
        <div class="mb-2">
          <div *ngFor="let station of this.refundData.contracts[currentTab].route; let l = last" class="d-inline-block">
            {{station}}
            <span *ngIf="!l">
              <span class="material-icons">
                arrow_right_alt
              </span>
            </span>
          </div>
        </div>
        <div class="table-responsive small">
          <table class="table table-sm">
            <tr>
              <td>{{"TAX_CATEGORY" | translate}}</td>
              <td>{{"TAX_TYPE" | translate}}</td>
              <td class="text-right">{{"AMOUNT" | translate}}</td>
            </tr>
            <tr *ngFor="let tax of this.refundData.contracts[currentTab].taxes" [ngClass]="{'text-danger':tax.Category==='NRT'}" [hidden]="tax.Category === 'PT'">
              <td>{{taxMapping[tax.Category] | translate}}</td>
              <td>{{tax.ISO_Code}}</td>
              <td class="text-right">{{tax.currencyCode}} {{tax.amount}}</td>
            </tr>
            <tr [ngClass]="{'text-danger': tariffRef[currentTab] < 0}" *ngIf="tariffRef[currentTab] >= 0">
              <td>{{ "TARIFF_REFUND" | translate }}</td>
              <td></td>
              <td class="text-right">{{ tariffRef[currentTab] }}</td>
            </tr>
            <tr [ngClass]="{'text-danger': tariffRef[currentTab] < 0}" *ngIf="tariffRef[currentTab] < 0 && data.isSuperAdmin">
              <td>{{ "TARIFF_REFUND" | translate }}</td>
              <td></td>
              <td class="text-right">{{ tariffRef[currentTab] }}</td>
            </tr>
            <!--
            <tr *ngIf="returnFare(currentTab)">
              <td>Rimborso tariffa</td>
              <td>n.d.</td>
              <td class="text-right">{{returnFare(currentTab).formattedAmount|number:'.2-2'}}</td>
            </tr>
            -->
          </table>
        </div>
        <div class="text-right">
          {{'REFUND'|translate}}: {{this.refundData.contracts[currentTab].refundable.amount}} {{this.refundData.contracts[currentTab].refundable.currency}}
        </div>
      </div>

      <div class="text-right">{{'TOTAL_REFUND'|translate}}: {{calculateTotal()|number:'.2-2'}} {{this.refundData.contracts[0].refundable.currency}}</div>
      <hr/>
    </div>
    <div *ngIf="error && !loadingRefund" class="mb-3">
      {{'NO_REFUND_FOUND'|translate}}
    </div>
  </div>
  <div *ngIf="!data.availableRefund" class="mb-3">
    {{'NO_REFUND_FOUND'|translate}}
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <div *ngIf="confirmRefund" class="d-block w-100 mb-2 small text-danger">{{'REFUND_TEXT'|translate}}</div>
  <button mat-button mat-dialog-close>{{'ABORT'|translate}}</button>
  <button mat-button *ngIf="data.availableRefund && !confirmRefund" [disabled]="loadingRefund" (click)="askingRefund()">{{'ASK_REFUND'|translate}}</button>
  <button mat-button *ngIf="!data.availableRefund" [disabled]="loadingRefund" (click)="sendTkt()">{{'ASK_TICKET_REFUND'|translate}}</button>
  <button mat-button [disabled]="loadingRefund" *ngIf="confirmRefund" (click)="patchRefund()">
    {{'PROCEED_TO_REFUND'|translate}}
  </button>
</mat-dialog-actions>
