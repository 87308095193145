import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import { Transactions } from '../../../../bisclasses/transactions';
import {MatSnackBar} from '@angular/material/snack-bar';
import "bootstrap";

@Component({
  selector: 'app-latest-transactions',
  templateUrl: './latest-transactions.component.html',
  styleUrls: ['./latest-transactions.component.scss']
})
export class LatestTransactionsComponent implements OnInit {
  transactions:Transactions[] = [];
  loading:boolean;
  constructor(
    private api: ApiService,
    private _snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.getTransactions();
  }

  getTransactions(){
    this.loading=true;
    this.api.getTransactions().subscribe(resp => {
      setTimeout(()=>{
        this.loading = false;
      }, 1000);
      console.log('resp =>', resp);
      this.transactions = resp.body;
      console.log('this.transactions => ',this.transactions);
    });
  }

  convertDate(value){
    return new Date(value);
  }

}
