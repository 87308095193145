import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { OfflineRequestsComponent } from './offline-requests/offline-requests.component';
import { TravelersModule } from '../travelers/travelers.module';
import { OfflineListComponent } from './list/list.component';
import { CustomCommonModule } from '../common/custom-common.module';


@NgModule({
  declarations: [
    OfflineRequestsComponent,
    OfflineListComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    TravelersModule,
    CustomCommonModule,
  ],
})
export class OfflineRequestsModule{}
