import {Component, OnInit} from '@angular/core';
import {MainComponent} from '../../../../mainmodule/main/main.component';
import * as $ from 'jquery';
import {FlightService} from '../../../../../services/flight/flight.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BottomsheetComponent} from '../searchflight/bottomsheet/bottomsheet.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatDialog} from '@angular/material/dialog';
import {FarerulesComponent} from '../farerules/farerules.component';
import {IatacompleteService} from '../../../../../services/iatacomplete/iatacomplete.service';
import {DossierService} from '../../../../../services/dossier/dossier.service';
import {HttpserviceService} from '../../../../../services/httpservice.service';
import {PriceService} from '../../../../../services/price/price.service';
import {TkbottomsheetComponent} from '../../../../ticketing/components/tkbottomsheet/tkbottomsheet.component';
import {CustomAuthService} from '../../../../../services/customAuth/custom-auth.service';
import * as moment from 'moment';
import {AppComponent} from '../../../../../app.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AdmserviceService} from '../../../../../services/adminserv/admservice.service';
import {UsersService} from '../../../../../services/profile/users.service';
import {CompaniesService} from '../../../../../services/profile/companies.service';
import {Tariffes} from '../../../../../classes/flights/tariffes';
import {TravelersService} from '../../../../../services/travelers/travelers.service';
import {TravelPolicyService} from '../../../../../services/travel-policy/travel-policy.service';
import {catchError, take} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {SearchInformationService} from '../../../../../components/search-information/services/search-information.service';
import { ConfirmationDialogComponent } from '../../../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import * as fareData from '../searchflight/searchresults/ratesDetailsSupportedCompany.json';
import {RefundDialogComponent} from './refund-dialog/refund-dialog.component';
import {CostcenterComponent} from '../../../../travelers/components/costcenter/costcenter.component';
import {CostcenterService} from '../../../../../services/costcenter/costcenter.service';
import {CostCenterDialogComponent} from '../../../../shared/cost-center-dialog/cost-center-dialog.component';
import { orderBy } from 'lodash';
/**
 * Component container for flight reservation
 */
@Component({
  selector: 'app-singlepnr',
  templateUrl: './singlepnr.component.html',
  styleUrls: ['./singlepnr.component.scss']
})
export class SinglepnrComponent implements OnInit {
  WHEELCHAIR_OPTIONS = ['WCBD','WCBW','WCHC','WCHR','WCHS','WCMP','WCOB'];
  showFull = false;
  showFullNew = false;
  /**
   *  Local variable for current traveler index to edit
   */
  currentTraveler: number;
  /**
   *  Local variable for editing traveler status
   */
  editingTraveler: boolean;

  isChurning = false;
  /**
   *  Local variable for flight reservation
   */
  reservation: any;
  /**
   *  Local variable for loading status
   */
  loading: boolean;
  /**
   *  Local variable for current tab
   */
  currentTab: string;
  /**
   *  Local variable for general loading over page
   */
  generalLoading: boolean;
  /**
   *  Local variable to check form op payment selection
   */
  fopSelected: boolean;
  /**
   *  Local variable for tabs array
   */
  tabs: any[];
  /**
   *  Local variable for flights array
   */
  flights: any[];
  param: any;
  pnrId: string;
  passengersToSplit: any[];
  loadingFares: boolean;
  currentTariff: any;
  loadingChangeTariff: boolean;
  deleting: boolean;
  booking: boolean;
  recipients: string[];
  showPrice: boolean;
  changePrice: boolean;
  showNotes: boolean;
  reservationNotes: string;
  priceChange: number;
  showTicket: boolean;
  locator: string;
  status: string;
  reprice: any;
  newuser: any;
  text: string;
  userList: any;
  userToLog: any;
  newTimeLimit: any;
  stopUpdateTimeLimit: boolean;
  canBookFare: any;
  authForCarrier: any;
  paxToRemoveWheelchair : any;
  paxToAddWheelchair : any;
  paxToAddFq : any;
  paxToRemoveFq: any;
  wheelchair : any;
  loadingWheelchair = false;
  loadingFq = false;
  askNewFares = false;
  newFares: any[] = [];
  newSelectedFare: any;
  redoingRes = false;
  fqPax: any;
  makingSplit: boolean;
  webCheckin: any;
  dataForCheckin: any;
  checkInOptions: any = [];
  iataInfo: any;
  fops: any;
  methods: any;
  availablePayments = 0;
  search:any;
  transferId: any;
  changesLowCost: any = [];
  modifyEnabled: boolean;
  timeLimitLowCost: string;
  hourTimeLimitLowCost : string;
  canCancel: boolean;
  policyTransfer: any;
  tasfStatus : any;
  private paymentIntentId;
  loadingRules = false;
  rulesResponse: any;
  originalRulesResponse: any;
  originalRulesAmaResponse: any;
  atcInfos: any;
  tarifLowData: any = [];
  tfCanBeVoid = false;
  costCenter: any[];
  loadingCenters = false;
  updating = false;
  scheduleChangesTFResult: string;
  calledAtc = false;
  miniToShow : any[] = [
    'BDT',
    'BNT',
    'ADT',
    'ANT',
    'WAI',
    'RVA',
    'BDA',
    'BDV',
    'BNW',
    'BNR',
    'BNA',
    'BNV',
    'ADW',
    'ADR',
    'ADA',
    'ADV',
    'ANW',
    'ANR',
    'ANA',
    'ANV',
    'MSC',
    'MIS'
  ]
  /**
   * The "constructor"
   *
   * @params {MainComponent} mainComponent
   * @params {Router} router
   * @params {FlightService} flightService
   * @params {MatBottomSheet} bottomSheet
   */
  constructor(
    /**
     *  Local variable for MainComponent
     */
    public mainComponent: MainComponent,
    public appComponent: AppComponent,
    private flightService: FlightService,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public iataComplete: IatacompleteService,
    private dossierSerivice: DossierService,
    private httpservice: HttpserviceService,
    public priceservice: PriceService,
    public customAuth: CustomAuthService,
    private snackBar: MatSnackBar,
    private admServ: AdmserviceService,
    private usersService: UsersService,
    private companyService: CompaniesService,
    private dossierService: DossierService,
    private travelersService: TravelersService,
    private policyService: TravelPolicyService,
    private searchInformationService: SearchInformationService,
    private costCenterService:CostcenterService,
  ) {
    this.showPrice = true;
    this.showNotes = false;
    this.changePrice = false;
    this.reservationNotes = '';
    this.loading = true;
    this.passengersToSplit = [];
    this.booking = false;
    this.recipients = [];
    this.showTicket = false;
    this.changesLowCost = [];
    this.modifyEnabled = false;
    this.timeLimitLowCost = '';
    this.canCancel = false;
    this.scheduleChangesTFResult = '';
    this.dataForCheckin = {
      FIRST: '',
      LAST: '',
      PNR: '',
      FLT_NO:'',
    };
    this.checkInOptions = ['FIRST','FLT_NO','LAST','PNR'];
  }

  /**
   *  Call the get reservation method based on router id
   */
  ngOnInit(): void {
    if ( localStorage.getItem('FlReservation') ) {
      this.reservation = JSON.parse(localStorage.getItem('FlReservation'));
      console.log('this.reservation => ', this.reservation);
      this.loading = false;
      this.generateTabs();
    }
    this.route.queryParams.subscribe(params => {
      if (!!params.payment_intent) {
        this.paymentIntentId = params.payment_intent;
      }
    });
    this.route.params.subscribe(params => {
      this.param = params.id;
      this.getPnrId(); // reset and set based on new parameter this time
    });
    this.flightService.getBlockedRates().subscribe(data =>
      this.canBookFare = data.find(d=>d.b2b===(this.mainComponent.loggedUser.company.type==='C'))
    );
  }

  getCostsCenters(){
    this.costCenter = [];
    this.loadingCenters = true;
    const query = {
      sort: 'createdAt',
      limit: 1000,
      skip: 0,
      order: 'asc',
      text: '',
    };
    this.costCenterService.getCostCenterList(query, this.reservation.company._id).subscribe((res: any) => {
      this.loadingCenters = false;
      this.costCenter = res.list;
      this.costCenter.sort( (a, b) => {
      if (a.tag < b.tag) {
        return -1;
      }
      if (a.tag > b.tag) {
        return 1;
      }
      return 0;
    });
      console.log('this.costCenter', this.costCenter);
    }, error => {
      this.loadingCenters = false;
      console.error('Error => ', error);
    });
  }

  openCostCenterDialog() {
    const dialogRef = this.dialog.open(CostCenterDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result){
        console.log(result);
        this.reservation.costCenter = result;
        this.getCostsCenters();
        this.updateCostCenter();
      }
    });
  }

  updateCostCenter(){
    this.updating = true;
    const body = {
      costCenter: this.reservation.costCenter,
    }
    this.flightService.setFlightCostCenter(this.reservation._id, body).subscribe((res: any) => {
      this.updating = false;
      console.log('this.costCenter', this.costCenter);
    }, error => {
      this.updating = false;
      console.error('Error => ', error);
    });
  }

  isInTimeLimit(){
    const date = new Date(this.reservation.timeLimit);
    date.setHours(date.getHours() -1);
    const gg = date.getDate();
    const mm = date.getMonth()+1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const min = date.getMinutes();
    const ss = date.getSeconds();
    const now = new Date();
    if(date.getTime() > now.getTime()){
      if(this.changesLowCost){
        console.log('-------------->' + JSON.stringify(this.changesLowCost));
      }
      this.modifyEnabled = true;
      this.timeLimitLowCost = gg+'/'+mm+'/'+year;
      // this.hourTimeLimitLowCost = hour+':'+ (''+min)?.padStart(2, '0');
      this.hourTimeLimitLowCost = '23:59';
      this.tfCanBeVoid = true;
    } else if(new Date(this.reservation.itineraries[0].flights[0].departure.date).getTime() > now.getTime()) {
      this.tfCanBeVoid = false;
      this.modifyEnabled = true;
    } else {
      this.modifyEnabled = false;
      this.tfCanBeVoid = false;
    }
  }
  calculateMargin(a, b, c) {
    return Math.round(((a - b - c) * 100) / 100);
  }

  regenerateModal(): void {
    $('#regenerateModal').modal('toggle');
    console.log('reservation =>', this.reservation);
    this.askingFares();
  }
  toggleRegenerate(): void {
    $('#regenerateModal').modal('toggle');
  }

  askingFares(): void {
    this.askNewFares = true;
    this.flightService.askFares(this.reservation).subscribe((res: Tariffes[]) => {
      this.newFares = res;
      this.askNewFares = false;
      for (let rate = 0; rate < this.newFares.length; rate++) {
        this.newFares[rate].selected = false;
      }
    }, error => {
      this.askNewFares = false;
      console.log('error => ', error);
    });
  }

  selectFare(f) {
    // tslint:disable-next-line:prefer-for-of
    for (let rate = 0; rate < this.newFares.length; rate++) {
      this.newFares[rate].selected = false;
    }
    this.newFares[f].selected = true;
    this.reservation.fare = this.newFares[f].fare;
    this.reservation.tariff = this.newFares[f];
  }

  someNewFare(): boolean {
    const find = this.newFares.findIndex(r => r.selected === true);
    return find !== -1;
  }

  redoReservation(): void {
    this.redoingRes = true;
    delete this.reservation._id;
    this.flightService.generateReservation(this.reservation, this.isChurning).subscribe((res: any) => {
      console.log('res => ', res);
      this.redoingRes = false;
      if ( localStorage.getItem('fl_dossierId') ) {
        const dossierId = JSON.parse(localStorage.getItem('fl_dossierId'));
        const body = {
          flights: [res._id]
        };
        this.dossierService.addFlightsToDossier(dossierId, body).subscribe((resp) => {
          console.log('res => ', resp);
        }, error => {
          console.error('res => ', error);
        });
      }
      this.toggleRegenerate();
      // localStorage.setItem('FlReservation', JSON.stringify(this.data.recommendation));
      this.router.navigate(['/flight/reservations/' + res._id ]);
    }, error => {
      this.redoingRes = false;
      if(error.errorMessage.includes('DUPLICATE')) this.isChurning = true;
      console.error('res => ', error);
    });
  }

  openBottomSheetHelp() {
    this.bottomSheet.open(TkbottomsheetComponent, {
      data: {
        emission: true,
        discount: true,
        locator:  this.reservation.locator,
        text: 'ASK_DISCOUNT_EMISSION_FOR_PNR_NUMBER',
      },
      // disableClose: true,
    });
  }

  checkFromSrvIfCanBook() {
    let res = true;

    if(this.reservation.negotiated && !this.canBookFare?.negotiated) res = false;
    else if(this.reservation.etnic && !this.canBookFare?.etnic) res = false;
    else if(this.reservation.residents && !this.canBookFare?.residents) res = false;
    else if(this.reservation.tourOperator && !this.canBookFare?.tourOperator) res = false;
    else if(this.appComponent.isB2B && this.reservation.negotiated) res = false;
    const data = (res && this.isCarrierAllowedForBooking());
    return data
  }

  isCarrierAllowedForBooking() {
    let allowed = true;

    if(this.authForCarrier && this.authForCarrier.blockBook){
      allowed =  false;
    }

    return allowed
  }

  canBook(): boolean {
    return !(this.authForCarrier && this.authForCarrier.blockIssuance);
  }

  openBottomSheetEmission() {
    this.bottomSheet.open(TkbottomsheetComponent, {
      data: {
        emission: true,
        discount: false,
        blocked: true,
        locator:  this.reservation.locator,
        text: 'ASK_EMISSION_FOR_PNR_NUMBER',
      },
      // disableClose: true,
    });
  }

  openBottomSheetChange() {
    this.bottomSheet.open(TkbottomsheetComponent, {
      data: {
        logged: this.mainComponent.loggedUser,
        isGat: this.appComponent.isGat,
        isSbt: this.appComponent.isSbt,
        isB2B: this.appComponent.isB2B,
        change: true,
        locator:  this.reservation.locator,
        text: 'CHANGE_CONFIRMATION',
      },
      // disableClose: true,
    });
  }

  confirmChangesTF() {
    const data = {
      supplierReference: this.reservation.tfResult.supplierReference,
      tfReference: this.reservation.locator
    }
    this.flightService.confirmChangesTF(data).subscribe((res) => {
      if(res) {
        this.scheduleChangesTFResult = 'ok';
      } else {
        this.scheduleChangesTFResult = 'errore';
      }
      this.toggleTFScheduleConfirm();
    })
  }

  getPnrId() {
    this.pnrId = this.route.snapshot.paramMap.get('id');
    console.log('this.pnrId => ', this.pnrId);
    this.getPnr(this.pnrId);
  }

  getFops(){
    this.companyService.getFops(this.reservation.company._id).subscribe((res) => {
      this.loading = false;
      this.fops = res;
      this.availablePayments = this.availablePayments + this.fops.length;
      if(this.reservation.company.services.paymentMethods){
          this.getMethods();
      }
      }, error => {
      this.loading = false;
      if(this.reservation.company.services.paymentMethods){
          this.getMethods();
      }
      // this.notifier.notify('error', error);
      console.error('Error => ', error);
    });
  }
  getMethods(){
    this.companyService.getMethods(this.reservation.company._id, false).subscribe((res) => {
      this.loading = false;
      this.methods = res;
      this.availablePayments = this.availablePayments + this.methods.length;
      this.generateTabs();
    }, error => {
      this.loading = false;
      this.generateTabs();
      console.error('Error => ', error);
    });
  }

  getPnr(id) {
    this.loading = true;
    this.currentTab = '';
    this.flightService.getReservation(id).subscribe((res) => {
      this.loading = false;
      this.reservation = res;
      console.log(this.reservation, 'reservation stampa')
      if (this.paymentIntentId) {
        const body = {
          company: this.reservation.company._id,
          service: 'flight',
          refId: res.locator,
          locator: res.locator,
          paymentIntentId: this.paymentIntentId
        }
        /*
        this.flightService.confirm3dsPayment(id, body).subscribe(r => {
          this.router.navigate(['flight','reservations',id,]);
        });*/
        if (res.lowCost) {
          this.loading = true;
          let body = {}
          if(localStorage.getItem('flychecklw')){
            body = JSON.parse(localStorage.getItem('flychecklw'));
          }
          this.flightService.confirm3dsPaymentLowCost(id, body).subscribe(r => {
            this.loading = false;
            localStorage.removeItem('flychecklw');
            this.router.navigate(['flight','reservations',id,]);
          }, error => {
            this.snackBar.open(error.errorMessage, 'OK', {duration: 2000});
            this.router.navigate(['flight','reservations',id,]);
          }
          )} else {
          this.flightService.confirm3dsPayment(id, body).subscribe(r => {
            this.router.navigate(['flight','reservations',id,]);
          });
        }
      }

      if(this.reservation.negotiated){
        this.flightService.getTransactions(this.reservation._id).subscribe(tx => {
          this.tasfStatus = tx.status;
          console.log('tasfStatus ', this.tasfStatus);
        });
      }

      this.flightService.getAuthorizationFroCarrier(this.reservation.marketingCarrier).subscribe((res:any) => {
        if(res[0]){
          this.authForCarrier = res[0];
        } else{
          this.authForCarrier = {issuanceInQueue: false, disableVoid: false,};
        }
        if(this.tfCanBeVoid){
          this.tfCanBeVoid = this.isNDCCompany();
        }
        console.log('authForCarrier ', this.authForCarrier);
      }, error => {
        console.error('Error => ', error);
      });
      if (!this.reservation.stopUpdateTimeLimit) {
        this.stopUpdateTimeLimit = false;
      } else {
        this.stopUpdateTimeLimit = true;
      }
      this.getCpSettings();
      if(this.reservation.status === 'linked'){
        // this.getFops();
      } else {

      }

      if (this.reservation.lowCost && this.reservation.status === 'init' && !this.reservation.waitingList) {
        this.processDetLowCost();
      }

      this.flights = [];
      res.itineraries.forEach( i => i.flights.forEach(f => this.flights.push(f)));
      // console.error('this.flights => ', this.flights);
      this.iataComplete.createToCompletePrn(this.reservation);
      if (this.reservation.company) {
        // tslint:disable-next-line:max-line-length
        this.reservation.company.image = this.httpservice.oldApiUrl + 'static/company/' + this.reservation.company._id + '.png?time=' + new Date().getTime();
      }
      this.recipients.push(this.reservation.user.email);
      if (this.reservation.notes && this.reservation.notes.text) {
        this.reservationNotes = this.reservation.notes.text;
      }
      if (this.reservation.notes && this.reservation.notes.price) {
        this.priceChange = this.reservation.notes.price;
        this.changePrice = true;
      }
      if (this.reservation.changed) {
        this.checkChange();
      }

      this.getPaxFrequentFlyers();

      if (this.reservation.status === 'issued' && !this.reservation.lowCost) {
        this.getWebCheckin();
        if(this.reservation.carriersLocators.length > 0){
          this.dataForCheckin.PNR = this.reservation.carriersLocators[0].locator;
        } else {
          this.dataForCheckin.PNR = this.reservation.locator;
        }

      }
      // this.getIataInfo();
      // this.prnRetrieve();

      if(!this.checkPolicy() && this.reservation.approvalId) {
        this.getInfo()
      }
      if(!this.checkPolicy() && this.reservation.paper){
        this.getTransfer();
      }
      if(this.checkPolicy() && (this.appComponent.isSbt || this.appComponent.isB2B) && this.reservation.paper && this.mainComponent.loggedUser.company.services.policy){
        this.getTransfer();
      }
      if(this.reservation.status ==='issued' && this.isAvailableDateAtcRefund() && !this.reservation.lowCost){
        this.askIataATCForEnablingRefundButtonAndATCTab();
      }

      if(this.appComponent.isB2B || this.appComponent.isSbt || this.mainComponent.loggedUser.role.superAdmin){
        console.log('costo');
        this.getCostsCenters();
      }

    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getTransfer(){
    this.policyService.getTransfer(this.reservation.paper).subscribe((res) => {
      console.log('res reprice => ', res);
      this.policyTransfer = res.data;
      this.transferId = res.data._id;
      console.log('transfer => ', res);
    }, error => {

      console.error('Error => ', error);
    });
  }

  TempModifyChangesFilter(){
    const temp = [];
    for(const c of this.changesLowCost.result) {
      if(c !== 'LUGGAGE' && c !== 'SEATS' && c !== 'TRAVEL_CLASS' && c !== 'CANCEL'){
          temp.push(c);
        }

        if(c === 'CANCEL' && this.reservation.company.services.addonFlight.postBooking_NDC && !this.tfCanBeVoid ){
          this.canCancel = true;
          temp.push(c)
        }
    }
/*
    if(this.changesLowCost.result.includes('OUT_DATE') && this.changesLowCost.result.includes('RET_DATE')){
      temp.push('DATES');
    }


    if(this.changesLowCost.result.includes('ORIGIN') && this.changesLowCost.result.includes('DESTINATION')) {
      temp.push('LOCATIONS');
    }
*/
    this.changesLowCost.result = temp;
  }


  getInfo(){
    this.searchInformationService.getSearch(this.reservation.approvalId).subscribe((res) => {
      console.log('res reprice => ', res);
      this.search = res.data[0];
      this.returnSApproval();
    }, error => {

      console.error('Error => ', error);
    });
  }

  returnSApproval(){
    this.search.status = 'pending';
    const declined = this.search.userAuthorisations.find(x => x.status === 'declined');
    if (!!declined) {
      this.search.status = 'declined';
    }

    const approved = this.search.userAuthorisations.every(x => x.status === 'approved');
    if (!!approved) {
      this.search.status = 'approved';
    }
    // this.search.status = 'pending';
  }

  getIataInfo(): void {
    this.admServ.getIata(this.reservation.marketingCarrier).subscribe((res) => {
      if(res[0]){
        this.iataInfo = res;
      } else{
        this.iataInfo = [{issuanceInQueue: false}];
      }

    }, error => {
      console.error('Error => ', error);
    });
  }

  getPaxFrequentFlyers(): void {
    this.reservation.passengers.forEach(p => {
      if (p.addressBook) {
        this.travelersService.getFrequentFlyers(p.addressBook).subscribe((res) => {
          p.frequentFlyers = res;
          p.frequentFlyers.forEach(f => {
            f.selected = false;
          })
        }, error => {
          console.log('error => ', error);
        });
      }
    });
  }

  getCpSettings() {
    this.companyService.getCpSettings(this.reservation.company._id).subscribe((res) => {
      this.reservation.company.services = res;
      this.getNDCChangesInfo();
      if(this.reservation.status === 'linked'){
        if(this.reservation.company.services.creditCards){
          this.getFops();
        } else if(!this.reservation.company.services.creditCards && this.reservation.company.services.paymentMethods) {
          this.getMethods()
        }
      }
      this.generateTabs();
    }, (error) => {
      // this.loading = false;
      console.log('error => ', error);
      this.generateTabs();
    });
  }

  getNDCChangesInfo(){
    if(this.reservation.company.services.addonFlight.ndc && this.reservation.lowCost && this.isNDCCompany()){
      this.flightService.getAvailableChanges(this.route.snapshot.paramMap.get('id')).subscribe(data => {
        this.changesLowCost = data;
        this.TempModifyChangesFilter();
        if (this.reservation.timeLimit && this.reservation.timeLimit !== ''){
          this.isInTimeLimit();
        }
      })
      // this.generateTabs();
    }

  }

  isNDCCompany(){
    if(this.authForCarrier && this.authForCarrier.iata &&
      (this.authForCarrier.iata === this.reservation.marketingCarrier) &&
      this.authForCarrier.tfNDC) {
      return true;
    } else {
      return false;
    }
  }


  onPaymentTabChanged(event) {
    delete this.reservation.creditcard;
  }

  processDetLowCost() {
    const body = {
      // tslint:disable-next-line:max-line-length
      queryParams: { param0: this.reservation._id, param1: this.reservation.lowCostPricing.routingId, param2: undefined, param3: undefined, param4: 1, param5: 0, param6: 0 },

    };
    if ( this.reservation.lowCostPricing.outwardId) {
      body.queryParams.param2 = this.reservation.lowCostPricing.outwardId;
    }


    this.router.navigate(['/flight/processLowCost'], body);

  }

  checkChange(): void {
    this.currentTab = 'ITINERARY';
    for (let i = 0; i < this.reservation.itineraries.length; i++) {
      setTimeout(() => {
        this.opeSect(i);
      }, 200);

    }
  }
  /**
   *  Call the pnr retrieve function
   */
  prnRetrieve() {
    this.flightService.pnrRetrieve(this.reservation._id).subscribe((res) => {
      console.log('Retrieve => ', res);
      this.flights = res.flights;
      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  /**
   *  Generates the tabs for current page checking reservation status
   */
  generateTabs() {
    if (this.reservation.status === 'init' && !this.reservation.lowCost) {
      this.tabs = [
        {
          name: 'ITINERARY',
          active: false,
        },
        {
          name: 'TARIFFES',
          active: true,
        },
        {
          name: 'PASSENGERS',
          active: false,
        }
      ];
      this.currentTab = 'TARIFFES';
    }
    if (this.reservation.status === 'linked') {
      this.tabs = [
        {
          name: 'ITINERARY',
          active: false,
        },
        {
          name: 'TARIFFES',
          active: false,
        },
        {
          name: 'PASSENGERS',
          active: false,
        },
        {
          name: 'PNR',
          active: true,
        },
        {
          name: 'SEAT_MAP',
          active: false,
        },
        {
          name: 'ANCILLARY',
          active: false,
        },
        {
          name: 'PAYMENT',
          active: false,
        },
      ];
      this.currentTab = 'PNR';
      if(this.availablePayments === 1){
        console.log('entro fop linked');
        if(this.fops.length > 0){
          this.reservation.creditcard = this.fops[0].indexes.lowcost;
        } else if(this.methods.length === 1) {
          this.reservation.creditcard = this.methods[0]._id.toString();
        }

        if (this.reservation.lowCost) {
          if (this.fops[0].card) {
            this.reservation.creditcard = {};
            this.reservation.creditcard.index = this.fops[0].indexes.lowcost;
            this.reservation.creditcard.type = this.fops[0].card.type;
          }
        };
        const find = this.tabs.findIndex(r => r.name === 'PAYMENT');
        this.tabs.splice(find, 1);

      }
    }
    if (this.reservation.status === 'issued' && !this.reservation.lowCost) {
      this.tabs = [
        {
          name: 'ITINERARY',
          active: false,
        },
        {
          name: 'TARIFFES',
          active: false,
        },
        {
          name: 'PASSENGERS',
          active: false,
        },
        {
          name: 'PNR',
          active: true,
        },
        /*{
          name: 'SEAT_MAP',
          active: false,
        } */
      ];
      this.currentTab = 'PNR';
    }
    if (this.reservation.status === 'init' && this.reservation.lowCost) {
      this.tabs = [
        {
          name: 'ITINERARY',
          active: false,
        },
        {
          name: 'PASSENGERS',
          active: true,
        },
        {
          name: 'PAYMENt',
          active: false,
        }
      ];
      this.currentTab = 'PASSENGERS';
    }
    if (this.reservation.status === 'issued' && this.reservation.lowCost) {
      this.isInTimeLimit()
      if(this.modifyEnabled && this.isNDCCompany()){
        this.tabs = [
          {
            name: 'ITINERARY',
            active: true,
          },
          {
            name: 'PASSENGERS',
            active: false,
          },
          {
            name: 'BAGGAGES',
            active: false,
          },
          {
            name: 'FARE',
            active: false,
          },
          {
            name: 'FARE_RULES',
            active: false
          },
          {
            name: 'POST_BOOKING.CHANGE_BOOK',
            active: false
          }
        ];
      } else if (this.isNDCCompany()){
        this.tabs = [
          {
            name: 'ITINERARY',
            active: true,
          },
          {
            name: 'PASSENGERS',
            active: false,
          },
          {
            name: 'BAGGAGES',
            active: false,
          },
          {
            name: 'FARE',
            active: false,
          },
          {
            name: 'FARE_RULES',
            active: false
          }
        ];
      } else {
        this.tabs = [
          {
            name: 'ITINERARY',
            active: true,
          },
          {
            name: 'PASSENGERS',
            active: false,
          },
          {
            name: 'BAGGAGES',
            active: false,
          },
          {
            name: 'FARE',
            active: false,
          }
        ];
      }
      this.currentTab = 'ITINERARY';
    }

    if ((this.reservation.status === 'cancelled' || this.reservation.status === 'expired') && !this.reservation.lowCost) {
      this.tabs = [
        {
          name: 'ITINERARY',
          active: true,
        },
        {
          name: 'TARIFFES',
          active: false,
        },
        {
          name: 'PASSENGERS',
          active: false,
        },
      ];
      this.currentTab = 'ITINERARY';
    }

    if ((this.reservation.status === 'cancelled' || this.reservation.status === 'expired') && this.reservation.lowCost) {
      this.tabs = [
        {
          name: 'ITINERARY',
          active: true,
        },
        {
          name: 'PASSENGERS',
          active: false,
        },
      ];
      this.currentTab = 'ITINERARY';
    }

    if ((this.reservation.status === 'cancelled' || this.reservation.status === 'expired') && !this.reservation.lowCost && this.mainComponent.loggedUser.role.superAdmin){
      const tabAdmin = {
        name: 'PNR',
        active: false,
      }
      this.tabs.push(tabAdmin);
    }

    if (this.reservation.status === 'flown' && !this.reservation.lowCost) {
      this.tabs = [
        {
          name: 'ITINERARY',
          active: false,
        },
        {
          name: 'TARIFFES',
          active: false,
        },
        {
          name: 'PASSENGERS',
          active: false,
        }
      ];
      this.currentTab = 'ITINERARY';
    }

    if (this.reservation.status === 'flown' && this.reservation.lowCost) {
      this.tabs = [
        {
          name: 'ITINERARY',
          active: false,
        },
        {
          name: 'PASSENGERS',
          active: false,
        }
      ];
      this.currentTab = 'ITINERARY';
    }

    if(!this.reservation.lowCost){
      const tab = {
        name: 'PNRHISTORY',
        active: false,
      };
      this.tabs.push(tab);
    }

    if (this.mainComponent.loggedUser.role.flight.taw.post.options.issue &&
      !this.reservation.changed &&
      !this.checkFromSrvIfCanBook()  &&
      this.reservation.status==='linked') {
      const pnr = this.tabs.findIndex(r => r.name === 'PNR');
      const iti = this.tabs.findIndex(r => r.name === 'ITINERARY');
      this.tabs.splice(pnr, 1);
      const payment = this.tabs.findIndex(r => r.name === 'PAYMENT');
      this.tabs.splice(payment, 1);
      this.currentTab = 'ITINERARY';
      this.tabs[iti].active = true;
    }

    if ((this.reservation.status === 'linked' || this.reservation.status === 'init') && this.reservation.lowCost) {
      this.tabs = [
        {
          name: 'ITINERARY',
          active: false,
        },
        {
          name: 'PASSENGERS',
          active: false,
        }
      ];
      this.currentTab = 'ITINERARY';
    }

    if (this.appComponent.isSbt || this.appComponent.isB2B) {
      const pnr = this.tabs.findIndex(r => r.name === 'PNR');
      console.log('entro');
      this.currentTab = 'ITINERARY';
      this.tabs.splice(pnr, 1);
    }

    if(this.reservation.approvalId){
      const seat = this.tabs.findIndex(r => r.name === 'SEAT_MAP');
      this.tabs.splice(seat, 1);
      const bag = this.tabs.findIndex(r => r.name === 'ANCILLARY');
      this.tabs.splice(bag, 1);
    }
  }

  /**
   *  Set current active and visible tab
   */
  setTab(index) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].active = false;
    }
    this.tabs[index].active = true;
    this.currentTab = this.tabs[index].name;
    if(this.currentTab === 'FARE'){
      this.getLowFareData();
    }
    if (this.reservation.tariffes) {
      delete this.reservation.tariffes;
      delete this.currentTariff;
    }
  }


  getLowFareData(){
    const dataList = {...fareData};
    const toCheck: any = [];
    let infoTarifsLow: any = {};
    this.tarifLowData = [];
    for(let i=0; i< this.reservation.cabins.length; i++){
      toCheck.push(this.reservation.cabins[i].split('*')[0].split('=')[1]);
    }
    for (let j = 0; j < dataList.length; j ++){
      if (dataList[j].marketingCarrier.toUpperCase() === this.reservation.marketingCarrier.toUpperCase()){
        for(const selected of toCheck){
          infoTarifsLow = {};
          infoTarifsLow.supplier = dataList[j].supplier;
          for (const tar of dataList[j].tarifs){
            if (selected.toUpperCase() === tar.name.toUpperCase()){
              infoTarifsLow.tarif = tar;
              this.tarifLowData.push(infoTarifsLow);
            }
          }
        }
      }
    }
  }

  /**
   *  Add to to hours and minute if missing
   */
  returnZeroTime(value) {
    if(this.reservation.lowCost){
      return value;
    } else{
      value = Number(value);
      if (value < 10) {
        return '0' + value;
      } else {
        return value;
      }
    }
  }

  /**
   *  Calculate flight duration based on departure and arrival date time and pass result to return time function
   */
  calculateFlightTime(departure, arrival) {
    departure = new Date(departure).getTime();
    arrival = new Date(arrival).getTime();
    const flightTime = arrival - departure;
    return this.returnTime(flightTime);
  }

  /**
   *  Return flight duration in dd-hh-mm
   */
  returnTime(t) {
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(t / cd);
    let h = Math.floor( (t - d * cd) / ch);
    let m = Math.round( (t - d * cd - h * ch) / 60000);
    const pad = ((n) => n < 10 ? '0' + n : n);
    if ( m === 60 ) {
      h++;
      m = 0;
    }
    if ( h === 24 ) {
      d++;
      h = 0;
    }
    if (d > 0) {
      return [d + 'g ' + pad(h) + 'h', pad(m) + 'm'].join(':');
    } else {
      return [pad(h) + 'h', pad(m) + 'm'].join(':');
    }

  }

  calculateWaiting(arrival, departure) {
    const a = new Date(arrival).getTime();
    const b = new Date(departure).getTime();
    return this.returnTime(b - a);
  }

  /**
   *  Check if the next flight is in the same day
   */
  otherday(value, other) {
    return value.toString() !== other.toString();
    // return value !== other;
  }

  /**
   *  Toggle open status of a single flight section
   */
  opeSect(it) {
    $('#collapse' + it).collapse('toggle');
    $('#itinerary' + it).collapse('toggle');
  }

  /**
   *  Cancel current reservation
   */
  cancelReservation() {
    this.deleting = true;
    this.flightService.cancelReservation(this.reservation._id).subscribe((res: any) => {
      console.log('res => ', res);
      this.deleting = false;
      this.reservation.status = 'cancelled';
      this.generateTabs();
      this.toggleDeleteModal();
      this.getPnr(this.pnrId);
      // this.router.navigate(['/flight/search']);
    }, error => {
      this.deleting = false;
      console.error('res => ', error);
      this.toggleDeleteModal();
    });
  }

  cancelLowCostReservation(){
    console.log('invia richiesta di cancellazione al servizio!!');
    this.toggleDeleteLowCostNDCModal();
    this.loading = true;
    try{
      this.flightService.cancelTFRequest(this.reservation._id).subscribe((data) =>{
        this.changesLowCost = data;
        this.loading = false;
        this.deleteLowCostPnrModalResult();
        window.location.reload();
      })
    } catch (e) {
      this.loading = false;
      console.log(e.message);
    }

  }

  toggleRepriceModal() {
    $('#repricePnrModal').modal('toggle');
  }

  isBuyDisabled() :boolean {
    const noCreditCard = !this.reservation.creditcard;
    const notPassPolicy = this.checkPolicy();
    const userCantAccess = !this.mainComponent.loggedUser.role.flight.taw.post.options.issue;
    /*
    console.log('noCreditCard ', noCreditCard);
    console.log('notPassPolicy ', notPassPolicy);
    console.log('userCantAccess ', userCantAccess);
     */
    return noCreditCard || userCantAccess;
  }
  checkPolicy() :boolean {
    if ((this.appComponent.isSbt || this.appComponent.isB2B) && this.reservation.tariff.arrayOfAlerts?.length > 0 && this.mainComponent.loggedUser.company.services.policy){
      return false // !tariff.arrayOfAlerts.every(a => a.block === false)
    }
    return true;
  }

  transferApproved(){
    let valid = true;
    if (this.policyTransfer && this.reservation.approvalId && this.policyTransfer.arrayOfAlerts.length>0){
      if(this.policyTransfer.authorisations) {
        valid = false;
        const approved = this.policyTransfer.authorisations.every(x => x.status === 'approved');
        if (!!approved) {
          this.policyTransfer.status = 'approved';
          valid = true;
        }
      } else {
        valid = false;
      }
    }
    // console.log(' trf');
    return valid;
  }


  repriceReservation() {
    this.toggleRepriceModal();
    this.booking = true;
    const body = {
      cc: this.reservation.creditcard,
    };
    this.flightService.rePriceReservation(this.reservation._id, body).subscribe((res) => {
      console.log('res reprice => ', res);
      this.booking = false;
      this.reprice = res;
    }, error => {
      this.booking = false;
      console.error('Error => ', error);
    });
  }

  callSearch(){
    const pax = [this.reservation.user._id];
    const transferId = this.transferId || undefined;
    this.policyService.createSearch(this.reservation,this.reservation.tariff, pax).subscribe((res: any) => {
      console.log('res auth => ', res);
      const authBody = this.policyService.createAuthorization(this.reservation,this.reservation.tariff, res.data.searchId);
      console.log(authBody);
      authBody.forEach(auth => {
        this.policyService.askAuth(auth).pipe(take(1), catchError(err => {
          this.snackBar.open(err.message, 'Error', {
            duration: 2000,
          });
          // this.isLoading = false;
          return throwError(err);
        })).subscribe(authRes => {
          this.snackBar.open('Authorization request sent successfully', 'Close', {duration: 2000});
          // this.dialogRef.close();
        });
      });
    }, error => {
      console.log('error => ', error);
    });
  }

  requestAuth(){
    const authorizationRequest = {
      searchId : '',
      type: this.reservation.tariff.arrayOfAlerts[0].policy?.isDefault === 1 ? 'default' : 'standard',
      configurationRule: this.reservation.tariff.arrayOfAlerts[0].policy,
      policyId :this.reservation.tariff.arrayOfAlerts[0].policy.id,
      product : this.reservation._id,
      note : this.reservation.tariff.arrayOfAlerts[0].message,
      authorization_users: this.reservation.tariff.arrayOfAlerts[0].policy.authorization_users,
      authorization_roles: this.reservation.tariff.arrayOfAlerts[0].policy.authorization_roles,
    };
    console.log('authorizationRequest => ', authorizationRequest);
    this.policyService.askAuth(authorizationRequest).subscribe((res: any) => {
      console.log('res auth => ', res);
    }, error => {
      console.log('error => ', error);
    });
  }

  requestAuthorization(): void {
    // this.isLoading = true;
    const travelers:any = [];
    const dossier = this.transferId || undefined;
    travelers.push(this.reservation.user._id);
    this.policyService.createSearch(this.reservation, this.reservation.tariff, travelers, dossier).pipe(catchError(err => {
      this.snackBar.open(err.message, 'Error', {
        duration: 2000,
      });
      // this.isLoading = false;
      return throwError(err);
    })).subscribe(res => {
      this.saveApprovalId(res.data._id);
      const authBody = this.policyService.createAuthorization(this.reservation, this.reservation.tariff, res.data.searchId, dossier);
      console.log(authBody);
      authBody.forEach(auth => {
        this.policyService.postAuthorization(auth).pipe(take(1), catchError(err => {
          this.snackBar.open(err.message, 'Error', {
            duration: 2000,
          });
          // this.isLoading = false;
          return throwError(err);
        })).subscribe(authRes => {
          this.snackBar.open('Authorization request sent successfully', 'Close', {duration: 2000});
          // this.dialogRef.close();
        });
      });
      // this.isLoading = false;
    });
  }

  bookReservation() {
    this.booking = true;
    console.log('fop =>', this.reservation.creditcard);
    const body = {
      cc: this.reservation.creditcard,
    };
    this.flightService.bookReservation(this.reservation._id, body).subscribe((res) => {
      this.booking = false;
      console.log('res book => ', res);
      this.toggleRepriceModal();
      if (res.callback3DUrl){
        console.log(res.description);
        this.openCallbackUrl(res.callback3DUrl);
      } else {
        this.getPnr(this.pnrId);
      }
    }, error => {
      this.toggleRepriceModal();
      this.booking = false;
      console.error('Error => ', error);
    });
  }

  private openCallbackUrl(url: string){
    window.open(url, '_self', 'top=100,left=100,height=400px,width=600px\'');
    this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        title: this.appComponent.translate.instant('3DS_TITLE'),
        message: this.appComponent.translate.instant('3DS_MESSAGE'),
        cancelButton: false
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.getPnr(this.pnrId);
      }
    });
  }

  /**
   *  Toggle modal for cancellation confirm
   */
  toggleDeleteModal() {
    $('#deletePnrModal').modal('toggle');
  }

  toggleDeleteLowCostNDCModal() {
    $('#deleteLowCostNDCModal').modal('toggle');
  }
  deleteLowCostPnrModalResult(){
    $('#deleteLowCostPnrModalResult').modal('toggle');
  }

  togglePenaltiesModal() {
    $('#penaltiesModal').modal('toggle');
    if(!this.mainComponent.loggedUser.role.superAdmin){
      this.askRules();
    } else {
      this.askMiniRules();
    }

  }

  toggleRemoveDossierModal() {
    $('#RemoveDossierModal').modal('toggle');
  }

  toggleRemoveWheelchairModal(paxIndex?: number) {
    this.paxToRemoveWheelchair = paxIndex;
    $('#RemoveWheelchairModal').modal('toggle');
  }

  toggleAddWheelchairModal(paxIndex?: number) {
    this.paxToAddWheelchair = paxIndex;
    if (paxIndex === undefined) delete this.wheelchair;
    $('#AddWheelchairModal').modal('toggle');
  }

  toggleAddFqModal(paxIndex?: number) {
    if (paxIndex === undefined) {
      delete this.fqPax;
      delete this.paxToAddFq;
    } else {
      this.paxToAddFq = paxIndex;
      this.fqPax = this.reservation.passengers[paxIndex];
    }
    $('#AddFqFModal').modal('toggle');
  }

  selectFqForPax(fq, i): void {
    this.fqPax.frequentFlyers.forEach( r =>  {
      if (r.number !== this.fqPax.frequentFlyers[i].number) {
        r.selected = false;
      }
    });
    this.fqPax.frequentFlyers[i].selected = ! this.fqPax.frequentFlyers[i].selected;
    // delete this.fqPax.frequentFlyer;
    // this.fqPax.frequentFlyer = fq;
  }

  someFqsel(): boolean {
    const find = this.fqPax.frequentFlyers.findIndex(r => r.selected);
    if (find !== -1) {
      return false;
    } else {
      return true;
    }
  }

  confirmFqAdd(): void {
    this.loadingFq = true;
    const find = this.fqPax.frequentFlyers.findIndex(r => r.selected);
    const fq = this.fqPax.frequentFlyers[find];
    this.flightService.addFq(this.reservation._id, this.paxToAddFq, fq).subscribe((res) => {
      console.log('res => ', res);
      this.getPnr(this.pnrId);
      this.loadingFq = false;
      this.toggleAddFqModal();
    }, error => {
      console.error('res => ', error);
      this.loadingFq = false;
      this.toggleAddFqModal();
      $('#AddFqFModal').modal('toggle');
    });
  }

  toggleRemoveFq(paxIndex?: number) {
    this.paxToRemoveFq = paxIndex;
    $('#RemoveFq').modal('toggle');
  }

  confirmFqRemove() {
    this.loadingWheelchair = true;
    this.flightService.deleteFq(this.reservation._id, this.paxToRemoveFq).subscribe((res) => {
      console.log('res => ', res);
      this.getPnr(this.pnrId);
      this.loadingFq = false;
      this.toggleRemoveFq();
    }, error => {
      console.error('res => ', error);
      this.loadingFq = false;
      this.toggleRemoveFq();
    });
  }

  toText(toSet) {
    toSet = toSet.replace(new RegExp('\n', 'g'), '<br/>');
    toSet = toSet.replace(new RegExp('\r', 'g'), '<br/>');
    return toSet;
  }

  confirmDossierRemove() {
    const body = {
      flights: [this.pnrId]
    };
    this.dossierSerivice.removeFlightsFromDossier(this.reservation.paper, body).subscribe((res) => {
      console.log('res => ', res);
      this.getPnr(this.pnrId);
    }, error => {
      console.error('res => ', error);
    });
    $('#RemoveDossierModal').modal('toggle');
  }

  /**
   * Open in bottomsheet tariff details
   * @params fare (tariff to open)
   */
  openBottomSheet(fare) {
    this.bottomSheet.open(BottomsheetComponent, {
      data: {fare},
    });
  }

  openDialogRules(tariff) {
    const dialogRef = this.dialog.open(FarerulesComponent, {
      data: {
        isGat: this.appComponent.isGat,
        loggedUser: this.mainComponent.loggedUser,
        tariff,
        pnr: JSON.parse(JSON.stringify(this.reservation)),
      },
      disableClose: true,
      width: '100%',
      maxWidth: '960px',
      // height: '100%',
      maxHeight: '900px',
    });
  }

  travelerSet(event, i) {
    this.reservation.passengers[i] = event;
    console.log('this.reservation.passengers => ', this.reservation.passengers);
    this.editingTraveler = false;
    delete this.currentTraveler;
  }

  openEdit(i) {
    $('#EditTravelerModal').modal('toggle');
    this.currentTraveler = i;
    this.editingTraveler = true;
  }

  closeEditEvent(event) {
    this.editingTraveler = false;
    delete this.currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  getReservationTariffs(id) {
     this.loadingFares = true;
     this.flightService.getReservationTariffs(id).subscribe((res) => {
      this.loadingFares = false;
      this.reservation.tariffes = res;
      // console.error('tariffs => ', res);
    }, error => {
      this.loadingFares = false;
      console.error('Error => ', error);
    });
  }

  selectTariff(tariff, id) {
    this.loadingChangeTariff = true;
    this.flightService.setReservationTariff(tariff, id).subscribe((res) => {
      this.loadingChangeTariff = false;
      this.closeTariffConfirm();
      this.getPnr(id);
      // console.error('res set tar => ', res);
    }, error => {
      this.loadingChangeTariff = false;
      this.closeTariffConfirm();
      console.error('Error => ', error);
    });
  }

  toggleTariffConfirm(tariff) {
    this.currentTariff =  tariff;
    $('#TariffConfirmModal').modal('toggle');
  }

  closeTariffConfirm() {
    delete this.currentTariff;
    $('#TariffConfirmModal').modal('hide');
  }

  closeTfScheduleConfirm() {
    $('#tfScheduleConfirm').modal('hide');
  }

  toggleTFScheduleConfirm(){
    $('#tfScheduleConfirm').modal('toggle');
  }


  confirmTariffSelection() {
    this.selectTariff(this.currentTariff, this.reservation._id);
  }

  openDialog(tariff) {
    const dialogRef = this.dialog.open(FarerulesComponent, {
      data: {
        isGat: this.appComponent.isGat,
        loggedUser: this.mainComponent.loggedUser,
        id: this.reservation._id,
        tariff,
      },
      disableClose: true,
      width: '100%',
      maxWidth: '960px',
      // height: '100%',
      maxHeight: '900px',
    });
  }

  toggleAddtoTravel() {
    if (!this.mainComponent.dossierId) {
      $('#travelListModal').modal('toggle');
    } else {
      this.addFlights(this.pnrId);
    }
  }
  addFlights(id) {
    const body = {
      flights: [id]
    };
    this.dossierSerivice.addFlightsToDossier(this.mainComponent.dossierId, body).subscribe((res) => {
      console.log('res => ', res);
      this.getPnr(id);
    }, error => {
      console.error('res => ', error);
    });
  }

  closeAddtoTravel() {
    $('#travelListModal').modal('toggle');
    this.getPnr(this.pnrId);
  }

  returnMilli(data) {
    return this.returnTime(new Date(data).getTime());
  }

  reloadFromSeatMap() {
    this.getPnr(this.pnrId);
    setTimeout(() => {
      this.setTab(4);
    }, 900);
  }

  reloadFromAncillary() {
    this.getPnr(this.pnrId);
    setTimeout(() => {
      this.setTab(5);
    }, 900);
  }

  print(): void {
    let printContents;
    let popupWin;
    printContents = document.getElementById('toPrint').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=900px,width=1200px');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
          <title>Flight-Ticket</title>
          <style>
            #toPrint{
                font-size:12px;
                padding:10px;
                border:1px solid #ccc;
                background-color:#fff;
                max-width:1000px;
                margin:auto;
            }
            .companyImage{
              max-height:85px;
              max-width:200px;
            }
            .reservationimage img{
                width:30px;
                display:inline-block;
                border-radius:50%;
                border:1px solid #ccc;
                margin:0 5px 0 0;
                vertical-align: middle;
            }
            .material-icons{
                vertical-align: middle;
            }
            .pnrdeparture{
              font-size:11px;
              color:#999;
            }
            table td{
                width:25%;
            }
            table td.infoIti{
              background-color:#eee;
            }
            .operatingImage{
                width:30px;
                border-radius: 50%;
                border:1px solid #ccc;
            }
            .backPrint{
                padding:10px;
            }
            .maincabin{
              font-size: 10px;
              text-transform:uppercase;
            }
            .infoTar .material-icons, .infoBag .material-icons{
                font-size:18px;
                margin-right:5px;
                vertical-align: middle;;
            }
            .fakeTable{
              border:1px solid #ccc;
              padding:10px;
              font-size:16px;
            }
            .elTkt{
              font-size:16px!important;
            }
            .fakeTable hr{
              margin:5px 0;
            }
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  toggleSendModal(): void {
    $('#SendMailModal').modal('toggle');
  }

  toggleNotes(): void {
    $('#EditNotesModal').modal('toggle');
  }

  togglePrint(): void {
    $('#PrintModal').modal('toggle');
  }

  addRecipient(): void {
    this.recipients.push('');
  }

  trackByFn(index: any) {
    return index;
  }

  removeRecipient(i: number) {
    this.recipients.splice(i, 1);
  }

  askFares(): void {
    console.log('this.reservation => ', this.reservation);
  }

  sendMail(): void {
    console.log('this.recipients => ', this.recipients);
    let printContents;
    printContents = document.getElementById('toPrint').innerHTML;
    const data = {
      receivers: this.recipients,
      html: `
      <html>
        <head>
          <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
          <title>Flight-Ticket</title>
          <style>
            #toPrint{
                font-size:12px;
                padding:10px;
                border:1px solid #ccc;
                background-color:#fff;
            }
            .companyImage{
              max-height:40px;
              max-width:200px;
            }
            .reservationimage img{
                width:30px;
                display:inline-block;
                border-radius:50%;
                border:1px solid #ccc;
                margin:0 5px 0 0;
                vertical-align: middle;
            }
            .material-icons{
                vertical-align: middle;
            }
            .pnrdeparture{
              font-size:11px;
              color:#999;
            }
            table td{
                width:25%;
            }
            .operatingImage{
                width:30px;
                border-radius: 50%;
                border:1px solid #ccc;
            }
            .backPrint{
                padding:10px;
            }
            .maincabin{
              font-size: 10px;
              text-transform:uppercase;
            }
            .infoTar .material-icons, .infoBag .material-icons{
                font-size:18px;
                margin-right:5px;
                vertical-align: middle;;
            }
            .fakeTable{
              border:1px solid #ccc;
              padding:10px;
            }
            .fakeTable hr{
              margin:5px 0;
            }
          </style>
        </head>
        <body>${printContents}</body>
      </html>`,
      options: {
        showPrice : this.showPrice,
      },
    };
    this.flightService.sendByMail(this.reservation._id, data).subscribe((res) => {
      console.log('res send => ', res);
      this.snackBar.open(this.appComponent.translate.instant('EMAIL_SENDED'), 'X', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
      $('#SendMailModal').modal('toggle');
    }, error => {
      console.error('Error => ', error);
    });
  }

  saveNotes() {
    const data = {
      notes: {
        text: null,
        price: null,
      },
    };
    if (this.reservationNotes) {
      data.notes.text = this.reservationNotes;
    } else {
      data.notes.text = null;
    }
    if (this.priceChange && this.changePrice) {
      data.notes.price = this.priceChange;
    } else {
      this.changePrice = false;
      data.notes.price = null;
    }
    this.flightService.saveNotes(this.reservation._id, data).subscribe((res) => {
      console.log('res => ', res);
      this.snackBar.open(this.appComponent.translate.instant('NOTES_SAVED'), 'X', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
      this.getPnr(this.reservation._id);
    }, error => {
      console.error('Error => ', error);
    });
  }

  isLowPrice(value) {
    return this.priceChange < value;
  }

  isInDay() {
    const today = moment(new Date()).format('DD/MM/yyyy');
    const currentTime = new Date().getHours();
    if (this.reservation.status === 'issued') {
      const issuingDate = moment(this.reservation.issuedOn).format('DD/MM/yyyy');
      if (today === issuingDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  countDown(limit) {
    const today = new Date();
    const thelimit = new Date(limit);

    const date1Ms = today.getTime();
    const date2Ms = thelimit.getTime();

    // Calculate the difference in milliseconds
    let differenceMs = date2Ms - date1Ms;
    // take out milliseconds
    differenceMs = differenceMs / 1000;

    differenceMs = differenceMs / 60;
    const minutes = Math.floor(differenceMs % 60);
    differenceMs = differenceMs / 60;
    const hours = Math.floor(differenceMs % 24);
    const days = Math.floor(differenceMs / 24);

    // tslint:disable-next-line:max-line-length
    return days + ' ' + this.appComponent.translate.instant('DAYS') + ', ' + hours + ' ' + this.appComponent.translate.instant('HOURS') + ', ' + minutes + ' ' + this.appComponent.translate.instant('MINUTES');

  }

  includeChange(iti) {
    const codes = ['TK', 'UN', 'UX', 'HX', 'WK', 'UC', 'NO'];

    for (let i = 0; i < iti.flights.length; i++) {
      const some = iti.flights[i].status.some(r => codes.includes(r));

      if (some) {
        return true; // Restituisci true se almeno uno dei codici è incluso
      }
    }

    return false; // Se nessun codice è stato trovato in nessun elemento
  }

  canSplit() {
    // tslint:disable-next-line:max-line-length
    if ( this.mainComponent.loggedUser.company.services.addonFlight.splitPNR && (this.reservation.status === 'linked' || this.reservation.status === 'issued') && this.reservation.passengers.length > 1 && !this.reservation.negotiated && !this.reservation.lowCost && !this.appComponent.isSbt) {
      if (this.reservation.passengers.length > 1 && this.reservation.passengers.length <= 2) {
        const inf = this.reservation.passengers.findIndex(r => r.type === 'INF');
        const chd = this.reservation.passengers.findIndex(r => r.type === 'CHD');
        if (inf > -1 || chd > -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }

  }

  canBeSplitted(pax) {

    let adults = 0;
    let children = 0;
    let infants = 0;
    this.reservation.passengers.forEach(p => {
      if (p.type === 'ADT'){
        adults += 1
      }
      if (p.type === 'CHD'){
        children += 1
      }
      if (p.type === 'INF'){
        infants += 1
      }
    });
    console.log('slit ctrl');
    if ( pax.type === 'ADT') {
      if(this.passengersToSplit.length === adults - 1){
        return false;
      }
      if(infants > 0){
        if(infants === adults){
          return false;
        }
        if (adults - this.passengersToSplit.length === infants) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  selectPaxToSplit(pax) {
    if (this.passengersToSplit.length > 0) {
      const ind = this.passengersToSplit.findIndex(r => r.ref === pax.ref);
      if ( ind === -1 ) {
        this.passengersToSplit.push(pax);
      } else {
        this.passengersToSplit.splice(ind, 1);
      }
    } else {
      this.passengersToSplit.push(pax);
    }
    console.log('this.passengersToSplit => ', this.passengersToSplit);
  }

  isSplitSelection(pax) {
    let found = -1;
    let isSelected = false;
    if (this.passengersToSplit.length > 0) {
      found = this.passengersToSplit.findIndex(r => r.ref === pax.ref);
    }
    if ( found !== -1 ) {
      isSelected = true;
    } else {
      isSelected = false;
    }
    return isSelected;
  }

  splitReservation() {
    this.makingSplit = true;
    this.mainComponent.scrollTop();
    const body  = {
      passengers: this.passengersToSplit,
    };

    this.flightService.splitReservation(body, this.reservation._id).subscribe((res: any) => {
      this.makingSplit = false;
      this.getPnr(this.reservation._id);
      console.log('res => ', res);
    }, error => {
      this.makingSplit = false;
      console.error('res => ', error);
    });
  }

  updateReservation() {
    const body = {
      status: '',
      locator: '',
      timeLimit: '',
      stopUpdateTimeLimit: this.stopUpdateTimeLimit,
      property: {
        user: '',
        company: '',
      }
    };
    if (this.newuser && this.newuser.user && this.newuser.company) {
      body.property = this.newuser;
    } else {
      delete body.property;
    }
    if (this.status !== this.reservation.status) {
      body.status = this.status;
    } else {
      delete body.status;
    }
    if (this.locator !== '') {
      body.locator = this.locator;
    } else {
      delete body.locator;
    }
    if (this.newTimeLimit !== '') {
      body.timeLimit = this.newTimeLimit;
    } else {
      delete body.timeLimit;
    }
    setTimeout(() => {
      this.getPnr(this.reservation._id);
    }, 300);
    this.admServ.updateFlightRes(body, this.reservation._id).subscribe((res) => {
      console.log('reservation => ', res);
      if (this.reservation.paper) {
        this.updateDossier();
      }
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  searchUser(value) {
    delete this.userList;
    if (value.length >= 2 ) {
      this.getUsersList(value);
    }
  }

  getUsersList(value) {
    delete this.userList;
    delete this.userToLog;
    this.usersService.getUsersComplete(value).subscribe((res) => {
      this.loading = false;
      this.userList = res;
      console.log('this.airports => ', this.userList);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  clear() {
    this.text = '';
    delete this.userToLog;
    delete this.userList;
    delete this.newuser;
  }

  getUser(value) {
    const user = value;
    delete this.userList;
    this.text = user.surname + ' ' + user.name + ' -> ' + user.company.name;
    this.newuser = {
      user: user._id,
      company: user.company._id,
    };
    console.log(value);
  }

  selectFirst() {
    if (this.userList && this.userList[0]) {
      const user = this.userList[0];
      delete this.userList;
      this.text = user.surname + ' ' + user.name + ' -> ' + user.company.name;
      this.userToLog = user;
      this.newuser = {
        user: user._id,
        company: user.company._id,
      };
    }
  }

  updateDossier() {
    this.admServ.updateDossier(this.reservation.paper).subscribe((res) => {
      console.log('reservation => ', res);
    }, error => {
      console.error('Error => ', error);
    });
  }

  confirmWheelChairRemove(){
    this.loadingWheelchair = true;
    this.flightService.removeWheelchair(this.reservation._id, this.paxToRemoveWheelchair).subscribe((res) => {
      console.log('res => ', res);
      this.getPnr(this.pnrId);
      this.loadingWheelchair = false;
      $('#RemoveWheelchairModal').modal('toggle');
    }, error => {
      console.error('res => ', error);
      this.loadingWheelchair = false;
      $('#RemoveWheelchairModal').modal('toggle');
    });
  }

  confirmWheelChairAdd(){
    this.loadingWheelchair = true;
    this.flightService.addWheelchair(this.reservation._id, this.paxToAddWheelchair, this.wheelchair).subscribe((res) => {
      console.log('res => ', res);
      this.getPnr(this.pnrId);
      this.loadingWheelchair = false;
      $('#AddWheelchairModal').modal('toggle');
    }, error => {
      console.error('res => ', error);
      this.loadingWheelchair = false;
      $('#AddWheelchairModal').modal('toggle');
    });
  }

  scroll_to(id) {
    $('.mat-drawer-content').animate({
      scrollTop: $('#' + id).offset().top
    }, 300);
  }

  postTasf(): void {
    this.flightService.postReservationTasf(this.reservation._id).subscribe((res) => {
      if (res.callback3DUrl){
        this.openCallbackUrl(res.callback3DUrl);
      } else {
        this.getPnr(this.reservation._id);
      }
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getWebCheckin(): void {
    this.iataComplete.getWebCheckin(this.reservation.marketingCarrier).subscribe((res) => {
      console.log('res => ', res);
      this.webCheckin = res;
    }, err => {
      console.error('error => ', err);
    })
  }

  toggleWebCheckinModal(): void {
    let openModal = false;
    this.checkInOptions.forEach(o=>{
      if (!openModal) {
        if(this.webCheckin[o]){
          openModal = true;
        }
      }
    });
    if (openModal) {
      $('#webCheckinModal').modal('toggle');
    } else {
      window.open(
        this.webCheckin.href,
        '_blank' // <- This is what makes it open in a new window.
      );
    }
  }

  elaborateCheckin(): void {
    console.log('dataForCheckin => ', this.dataForCheckin);
    let href = this.webCheckin.href;
    this.checkInOptions.forEach(o=>{
      if(href.includes(`{${o}}`)){
        href = href.replace(`{${o}}`, this.dataForCheckin[o]);
      }
    });
    this.toggleWebCheckinModal();
    console.log('this.webCheckin.href ', this.webCheckin.href);
    window.open(
      href,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  disabledCheckin(){
    let inValid = false;
    this.checkInOptions.forEach(o=>{
      if (!inValid) {
        if(this.webCheckin[o] && this.dataForCheckin[o] === ''){
          inValid = true;
        }
      }

    });
    return inValid;
  }

  saveApprovalId(value: string){
    const data = {
      approvalId: value,
    };
    this.flightService.saveApprovaId(this.reservation._id, data).subscribe((res) => {
      console.log('res => ', res);
      this.getPnr(this.pnrId)
      // this.getPnr(this.reservation._id);
    }, error => {
      console.error('Error => ', error);
    });
  }

  askRules(){
    this.loadingRules = true;
    this.flightService.penaltiesFromPnr(this.reservation._id).subscribe((res) => {
      console.log('res => ', res);
      this.loadingRules = false;
      this.rulesResponse = res.message;
      // this.getPnr(this.reservation._id);
    }, error => {
      this.loadingRules = false;
      console.error('Error => ', error);
    });
  }

  alaborateRules(rules:any){
    rules.forEach((element, i) => {
      const arrayDate = [];
      element.dateInfo.forEach(el => {
        if(this.todisplay(el.infoType)){
          arrayDate.push(el);
        }
      });
      element.dateInfo = arrayDate;
      if(element.dateInfo.length > 0){
        element.dateInfo = this.sortedRulesDate(element);
      }

      const arraymnrRestriAppLoc = [];
      element.mnrRestriAppLoc.forEach(el => {
        if(this.todisplay(el.infoType)){
          arraymnrRestriAppLoc.push(el);
        }
      });
      element.mnrRestriAppLoc = arraymnrRestriAppLoc;
      if(element.mnrRestriAppLoc.length > 0){
        element.mnrRestriAppLoc = this.sortedRulesmnrRestriAppLoc(element);
      }

      const arraymnrMonLoc = [];
      element.mnrMonLoc.forEach(el => {
        if(this.todisplay(el.infoType)){
          arraymnrMonLoc.push(el);
        }
      });
      element.mnrMonLoc = arraymnrMonLoc;
      if(element.mnrMonLoc.length > 0){
        element.mnrMonLoc = this.sortedRulesmnrMonLoc(element);
      }

      element.mnrRestriAppLoc.forEach(el => {
        if(el.infoType == 'BDA' && el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'BDT');
          if(find != -1){
            element.mnrMonLoc[find].freeText = el.freeText;
          }
        } else if(el.infoType == 'BDA' && !el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'BDT');
          if(element.numberCategory == '31'){
            element.mnrMonLoc[find].freeText = 'Riemissione consentita - ' + element.mnrMonLoc[find].freeText;
          } else if (element.numberCategory == '33'){
            element.mnrMonLoc[find].freeText = 'Rimborso consentito - ' + element.mnrMonLoc[find].freeText;
          }
        }

        if(el.infoType == 'BNA' && el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'BNT');
          if(find != -1){
            element.mnrMonLoc[find].freeText = el.freeText;
          }
        } else if(el.infoType == 'BNA' && !el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'BNT');
          if(element.numberCategory == '31'){
            element.mnrMonLoc[find].freeText = 'Riemissione consentita - ' + element.mnrMonLoc[find].freeText;
          } else if (element.numberCategory == '33'){
            element.mnrMonLoc[find].freeText = 'Rimborso consentito - ' + element.mnrMonLoc[find].freeText;
          }
        }

        if(el.infoType == 'ANA' && el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'ANT');
          if(find != -1){
            element.mnrMonLoc[find].freeText = el.freeText;
          }
        } else if(el.infoType == 'ANA' && !el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'ANT');
          if(element.numberCategory == '31'){
            element.mnrMonLoc[find].freeText = 'Riemissione consentita - ' + element.mnrMonLoc[find].freeText;
          } else if (element.numberCategory == '33'){
            element.mnrMonLoc[find].freeText = 'Rimborso consentito - ' + element.mnrMonLoc[find].freeText;
          }
        }

        if(el.infoType == 'ADA' && el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'ADT');
          if(find != -1){
            element.mnrMonLoc[find].freeText = el.freeText;
          }
        } else if(el.infoType == 'ADA' && !el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'ADT');
          if(element.numberCategory == '31'){
            element.mnrMonLoc[find].freeText = 'Riemissione consentita - ' + element.mnrMonLoc[find].freeText;
          } else if (element.numberCategory == '33'){
            element.mnrMonLoc[find].freeText = 'Rimborso consentito - ' + element.mnrMonLoc[find].freeText;
          }
        }

      });

      element.mnrMonLoc.forEach((el:any) => {
        if(element.numberCategory == '31' && el.infoType == 'BNT' && el.freeText.toLowerCase().includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'ANT');
          if(find != -1){
            element.mnrMonLoc[find].freeText = el.freeText;
          }
          if (rules[i-1]){
            let prevElement = rules[i-1];
            const findP = prevElement.mnrMonLoc.findIndex(m => m.infoType == 'ANT');
            if(findP != -1){
              prevElement.mnrMonLoc[findP].freeText = el.freeText;
            }
          }
        }
        if(element.numberCategory == '31' && el.infoType == 'ANT' && el.freeText.toLowerCase().includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'BNT');
          if(find != -1){
            element.mnrMonLoc[find].freeText = el.freeText;
          }
          if (rules[i-1]){
            let prevElement = rules[i-1];
            const findP = prevElement.mnrMonLoc.findIndex(m => m.infoType == 'BNT');
            if(findP != -1){
              prevElement.mnrMonLoc[findP].freeText = el.freeText;
            }
          }
        }

      })

    });
    return rules;
  }

  askMiniRules(){
    this.loadingRules = true;
    this.flightService.penaltiesFromPnrMini(this.reservation._id).subscribe((res) => {
      console.log('res => ', res);
      this.loadingRules = false;
      //this.rulesResponse = res;
      this.originalRulesResponse = res;
      this.originalRulesAmaResponse = JSON.parse(JSON.stringify(this.originalRulesResponse));
      //this.rulesResponse = this.alaborateRules(this.rulesResponse);
      this.originalRulesResponse.pricingRecord.forEach((rules:any) => {
        rules = this.alaborateRules(rules);
      })

      this.originalRulesResponse.pricingRecord.forEach((record, z) => {
        record.forEach((element: any, i) => {
          //element.dateInfo = this.sortedRulesDate(i);
          //element.mnrMonLoc = this.sortedRulesmnrMonLoc(i);
          //element.mnrRestriAppLoc = this.sortedRulesmnrRestriAppLoc(i);
          console.log('element.nameCategory ', element.nameCategory)
          if(element.nameCategory == 'ADVANCE_PURCHASE'){
            record.splice(i, 1);
            // this.originalRulesResponse.splice(i, 1);
          }
        })
      });

      if(this.originalRulesResponse){
        //this.rulesResponse = this.rulesResponse.reverse();
        this.originalRulesResponse.pricingRecord.forEach((rule:any) => {
          // rule = rule.reverse();
        })
        // this.originalRulesResponse = this.originalRulesResponse.reverse();
      }

      console.log('res rules => ', this.rulesResponse);
      // this.getPnr(this.reservation._id);
    }, error => {
      this.loadingRules = false;
      console.error('Error => ', error);
    });
  }

  sortedRulesDate(element) {
    return element.dateInfo.sort((a, b) => b.textType.localeCompare(a.textType));
  }

  sortedRulesmnrMonLoc(element){
    return element.mnrMonLoc.sort((a, b) => b.textType.localeCompare(a.textType));
  }

  sortedRulesmnrRestriAppLoc(element){
    return element.mnrRestriAppLoc.sort((a, b) => b.textType.localeCompare(a.textType));
  }

  openRefund(){
    this.dialog.open(RefundDialogComponent, {
      disableClose: true,
      width: '100%',
      maxWidth:'800px',
      data: {
        reservation: this.reservation,
        availableRefund: this.isAvailableAtcRefund(),
        isSuperAdmin: this.mainComponent.loggedUser.role.superAdmin,
        isGat: this.appComponent.isGat,
      },
    }).afterClosed().subscribe(result => {
      if(result){
        this.getPnr(this.pnrId);
      }

     });
  }

  isAvailableAtcRefund(){
    const now = new Date();
    const issueDate = new Date(this.reservation.issuedOn);
    const isSameDay = (issueDate.getDate() === now.getDate() && issueDate.getMonth() === now.getMonth() && issueDate.getFullYear() === now.getFullYear())
    const departureDate = new Date(this.reservation.itineraries[0].flights[0].departure.dateTime);
    const tripStarted = now.getTime() >= departureDate.getTime();
    if(this.atcInfos && this.atcInfos.refund){
      return !isSameDay && !tripStarted;
    } else {
      return false;
    }

  }

  isAvailableDateAtcRefund(){
    const now = new Date();
    const issueDate = new Date(this.reservation.issuedOn);
    const isSameDay = (issueDate.getDate() === now.getDate() && issueDate.getMonth() === now.getMonth() && issueDate.getFullYear() === now.getFullYear())
    const departureDate = new Date(this.reservation.itineraries[0].flights[0].departure.dateTime);
    const tripStarted = now.getTime() >= departureDate.getTime();
    return !isSameDay && !tripStarted;
  }

  askIataATCForEnablingRefundButtonAndATCTab(){
    this.flightService.availableAtc(this.reservation.marketingCarrier).subscribe((res) => {
      this.atcInfos = res;
      this.calledAtc = true;
      if (this.atcInfos.reissue && !this.reservation.negotiate && !this.appComponent.isGat && this.mainComponent.loggedUser.role.superAdmin) {
        this.tabs.push({
          name: 'ATC.TAB',
          active: false,
        });
      }
    }, error => {
      this.calledAtc = true;
      console.error('Error => ', error);
    });
  }

  isLowPostBookEnable(){
    if(this.reservation.company.services.addonFlight){
      return this.reservation.company.services.addonFlight.postBooking_NDC ? true : false;
    }
  }

  isVoidInADay() {
    const now = new Date().getTime();
    const timeLimit = new Date(this.reservation.timeLimit).getTime();
    return now < timeLimit ? true : false;

  }


  todisplay(rule:any){
    return this.miniToShow.includes(rule)
  }

  toTextMini(toSet) {
    toSet = toSet.replace(new RegExp('\n', 'g'), '<br/>');
    toSet = toSet.replace(new RegExp('\r', 'g'), '<br/>');
    toSet = toSet.replace(new RegExp('consentito', 'ig'), '<span class="greencolor">consentito</span>');
    toSet = toSet.replace(new RegExp('consentita', 'ig'), '<span class="greencolor">consentita</span>');
    toSet = toSet.replace(new RegExp('non <span class="greencolor">consentito</span>', 'ig'), '<span class="redcolor">non consentito</span>');
    toSet = toSet.replace(new RegExp('non permesso', 'ig'), '<span class="redcolor">non permesso</span>');
    toSet = toSet.replace(new RegExp('non <span class="greencolor">consentita</span>', 'ig'), '<span class="redcolor">non consentita</span>');
    toSet = toSet.replace(new RegExp('non applicabile', 'ig'), '<span class="redcolor">non applicabile</span>');
    toSet = toSet.replace(new RegExp('CHANGES', 'ig'), '<span class="orangecolor"><b>CHANGES</b></span>');
    toSet = toSet.replace(new RegExp('PE.PENALTIES', 'ig'), '<span class="orangecolor"><b>sPE.PENALTIES</b></span>');
    return toSet;
  }

  returnDate(value){
    const date = new Date(value)
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const final = new Date(date.getTime() + userTimezoneOffset);
    return final;
  }

  convertMilliseconds(det) {
    const ms = det.to - det.from;
    // Un'ora ha 3,600,000 millisecondi
    const hours = Math.floor(ms / 3600000);
    // Un minuto ha 60,000 millisecondi
    const minutes = Math.floor((ms % 3600000) / 60000);

    // Ritorna la durata in formato 'ore minuti'
    return `${hours}h ${minutes}m`;
  }

  checkTechnicalStops(flights){
    let count = 0;
    flights.forEach((f:any) => {
      if(f.detail.technicalStop && f.detail.technicalStop.from > 0){
        count += 1;
      }
    });
    return count;
  }
}
