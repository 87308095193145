import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompaniesService} from '../../../../../services/profile/companies.service';
import {SearchRes} from '../../../../../classes/flights/search-res';
@Component({
  selector: 'app-companiesautocomplete',
  templateUrl: './companiesautocomplete.component.html',
  styleUrls: ['./companiesautocomplete.component.scss']
})
export class CompaniesautocompleteComponent implements OnInit {

  @Input() query: any;
  company: any;
  @Input() subordinated: boolean;

  @Input() isForm : boolean = false;
  // tslint:disable-next-line:no-output-rename
  @Output('update') change: EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-rename
  @Output('setSub') setSub: EventEmitter<any> = new EventEmitter<any>();
  companiesList: any[] = [];
  loading: boolean;
  companytext: string = '';
  constructor(
    private companiesService: CompaniesService,
  ) {
    this.loading = false;
  }

  ngOnInit(): void {
  }

  clearFrom() {
    this.companytext = '';
    // delete this.company;
    delete this.companiesList;
  }

  selectComp(value:any) {
    this.companytext = '';
    console.log('entro => ', value);
    this.company = value;
    this.clearFrom();
    if (!this.subordinated) {
      this.change.emit(this.company);
    } else {
      this.setSub.emit(this.company);
      // this.setSub.emit(this.company._id);
    }
  }

  selectFirst() {
    this.companytext = '';
    if ( this.companiesList ) {
      this.company = this.companiesList[0];
      console.log('company => ', this.company);
    }
    if (!this.subordinated) {
      this.change.emit(this.company);
    } else {
      this.setSub.emit(this.company);
    }
    this.clearFrom();
  }

  searchCompany(value:any) {
    delete this.companiesList;
    this.clearFrom();
    if (value && value.length >= 2) {
      this.getCompaniesList(value);
    }
  }

  getCompaniesList(value:any) {
    this.clearFrom();
    this.companiesService.getCompaniesAuto(value).subscribe((res:any) => {
      this.loading = false;
      this.companiesList = res;
      console.log('this.companiesList => ', this.companiesList);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  removeCompany(id) {
    this.company = '';
    if (!this.subordinated) {
      this.change.emit(this.company);
    } else {
      this.setSub.emit(this.company);
    }
  }

}
