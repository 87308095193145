import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';
import { GroupRequestsComponent } from './components/group-requests/group-requests.component';
import { UnaccompaniedMinorsComponent } from './components/unaccompanied-minors/unaccompanied-minors.component';
import { RequestListComponent } from './components/list/list.component';
import { AuthGuard } from '../../guards/auth.guard';


const routes : Routes = [
  { path: 'group-request', data: { title: 'GROUP_REQUEST' }, component: GroupRequestsComponent, canActivate: [AuthGuard]  },
  { path: 'minors-request', data: { title: 'UNACCOMPANIED_MINORS' }, component: UnaccompaniedMinorsComponent, canActivate: [AuthGuard]  },
  { path: 'list', data: { title: 'TRAVEL_REQUESTS_LIST' }, component: RequestListComponent, canActivate: [AuthGuard]  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RequestsRoutingModule{}
