import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {PriceService} from '../../../../../services/price/price.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-singlehotelrooms',
  templateUrl: './singlehotelrooms.component.html',
  styleUrls: ['./singlehotelrooms.component.scss'],
})
export class SinglehotelroomsComponent implements OnInit{
  @Input() hotel : any;
  @Input() searchRes : any;
  @Input() search : any;
  @Input() room : any;
  @Input() i : any;
  @Input() isSbt: any;
  @Input() isB2B: any;
  @Input() isGat: any;
  numberOfNights : number;
  // tslint:disable-next-line:no-output-rename
  @Output('update') change : EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public priceService: PriceService,
    private translate: TranslateService,
  ){ }

  ngOnInit() : void{
    this.room.Infos = [];
    this.room.images = [];
    // this.findRoomInfos();
    this.calculateNights();
    // console.log('this.room => ', this.room);
  }

  calculateNights(){
    const date1 = new Date(this.search.checkIn);
    const date2 = new Date(this.search.checkOut);
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    this.numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

  findRoomInfos(){
    for (let i = 0; i < this.hotel.detailInfo?.rooms.length; i ++){
      if (this.hotel.detailInfo?.rooms[i].all === true){
        this.room.Infos.push(this.hotel.detailInfo?.rooms[i]);
        if (this.hotel.detailInfo?.rooms[i].images){
          this.room.images = this.hotel.detailInfo?.rooms[i].images;
        }
      } else{
        // tslint:disable-next-line:prefer-for-of
        for (let r = 0; r < this.room.types.length; r ++){
          if (this.room.types[r].type === this.hotel.detailInfo?.rooms[i].type){
            this.room.Infos.push(this.hotel.detailInfo?.rooms[i]);
            if (this.hotel.detailInfo?.rooms[i].images){
              this.room.images.push(this.hotel.detailInfo?.rooms[i].images);
            }
          }
        }
      }
    }
    // console.log('this.hotel => ', this.room);
  }

  removeHr(date){
    date = new Date(date);
    date = date.setHours(date.getHours() - 4);
    return date;
  }

  selectRoomFare(rate, ind){
    // console.log('this.room', this.room);
    const room = {
      hotel: this.hotel,
      index: this.i,
      room: this.room,
      fare: rate,
      rateIndex: ind,
    };
    this.change.emit(room);
  }

  isFreeCancellation(){
    return this.room.roomRates[0].text.toLowerCase().includes('cancellazione gratuita');
  }

  isFreeCancellationBis(){
    return this.room.ratePlans[0].penalty[0] && this.room.ratePlans[0].penalty[0].absoluteDeadLine;
  }

  convertPrice(value, currency) : number{
    if (this.searchRes.currencyConversions){
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.searchRes.currencyConversions.length; i ++){
        if (this.searchRes.currencyConversions[i].local === currency){
          return this.priceService.returnPriceHotel(value * this.searchRes.currencyConversions[i].rate);
        }
      }
    } else{
      return this.priceService.returnPriceHotel(value);
    }

  }

  checkVoucher(value){
    if (value.includes('AMADEUS') || value.includes('HotelBeds')) {
      return true;
    } else {
      return false
    }
  }

  corporate(text){
    return text.includes('CORPORATE') || text.includes('corporate');
  }

  returnProvider(value:string): string{
    if (!this.isGat && value.includes('AMADEUS')){
      return this.translate.instant('TARIFFES') + ' LEONARDO TRAVEL';
    }
    return value;
  }

  isBooking(value:string){
    return !!value.includes('BOOKING');
  }

}
