import {Component, OnInit} from '@angular/core';
import {CreatePolicyDialogComponent} from './create-policy-dialog/create-policy-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {LocalStorageService} from '../../services/storage/storage.service';
import {DeletePolicyDialogComponent} from './delete-policy-dialog/delete-policy-dialog.component';
import {TravelPolicyService} from '../../services/travel-policy/travel-policy.service';
import {Router} from '@angular/router';
import {UsersService} from '../../services/profile/users.service';
import {take} from 'rxjs/operators';
import {DefaultUserComponent} from './default-user/default-user.component';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-create-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  policyList: any[] = [];
  configurationButton = [];
  policyHasChange = false;
  isLoading: boolean;
  private rolesList;

  defaultUserLoading = false;
  defaultUser = null;

  constructor(private matDialog: MatDialog,
              private localStorageService: LocalStorageService,
              private router: Router,
              private usersService: UsersService,
              private snackBar: MatSnackBar,
              private travelPolicyService: TravelPolicyService) {

  }

  ngOnInit(): void {
    // this.fetchPolicy();
    // this.fetchRoles();
    this.callPolicies();
    this.callGroups();
    this.getDefaultUser();
  }

  getDefaultUser() {
    this.defaultUserLoading = true;
    this.travelPolicyService.getDefaultUser().pipe(take(1)).subscribe(res => {
      this.defaultUser = res.data.rules.defaultUser;
      this.defaultUserLoading = false;
    }, error => {
      this.defaultUserLoading = false;
    });
  }

  openSelectDefaultUserDialog(): void {
    const dialogRef = this.matDialog.open(DefaultUserComponent);

    dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      this.getDefaultUser();
    });
  }

  /**
   * Get policies from api
   */
  /*
  fetchPolicy(): void {
    this.isLoading = true;
    this.travelPolicyService.list().subscribe(response => {
      this.isLoading = false;
      this.policyList = response.data;
    });
  } */

  callPolicies() {
    this.travelPolicyService.getPoliciesList().subscribe(response => {
      this.isLoading = false;
      this.policyList = response.data;
    });
  }

  callGroups() {
    this.travelPolicyService.getGroupsList().subscribe(response => {
      this.isLoading = false;
      this.rolesList = response.data;
      console.log(this.rolesList);
    });
  }

  /**
   * Get groups from api
   */
  /*
  fetchRoles(): void {
    this.travelPolicyService.roleList().subscribe(roles => {
      this.rolesList = roles.data;
      console.log(this.rolesList);
    });
  }
  */

  /**
   * Dialog for creating new policy
   */
  createPolicyDialog(): void {
    let dialogRef;
    const title = {title: 'Create new', policies: this.policyList, rolesList: this.rolesList};
    dialogRef = this.matDialog.open(CreatePolicyDialogComponent, {
      width: '400px',
      minWidth: '280px',
      data: {data: title}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('result =>', result);
      if (result) {
        if (result.policyName && result.policyRole) {
          const selectedRole = this.findRole(result.policyRole.value);
          const newPolicy = {
            name: result.policyName.value,
            role: selectedRole._id,
            configuration: [],
            hotels: result.hotels.value,
            flights: result.flights.value,
            vehicles: result.vehicles.value,
            trains: result.trains.value,
            transfers: result.transfers.value,
          };

          this.checkPolicyChange();
          this.travelPolicyService.add(newPolicy).subscribe(response => {
            this.callPolicies();
          });
          /*
          this.travelPolicyService.add(newPolicy).subscribe(() => {
            this.fetchPolicy();
          });
          */
        }
      }
    });

  }

  findRole(roleName): any {
    return this.rolesList.find(role => role.name === roleName);
  }


  deletePolicy(id, i: number): void {
    let dialogRef;
    dialogRef = this.matDialog.open(DeletePolicyDialogComponent, {
      width: '400px',
      minWidth: '280px',
      data: {data: this.policyList[i]}
    });

    dialogRef.afterClosed().subscribe((result = null) => {
      if (result) {
        /*
        this.travelPolicyService.remove(id).subscribe(() => {
          this.fetchPolicy();
        }); */
        this.travelPolicyService.remove(id).subscribe(response => {
          this.callPolicies();
        });
        this.checkPolicyChange();
      }
    });
  }

  editPolicy(id, i: number): void {
    const editablePolicy = this.policyList[i];
    editablePolicy.title = 'Edit';
    editablePolicy.rolesList = this.rolesList;
    let dialogRef;
    console.log(editablePolicy);
    dialogRef = this.matDialog.open(CreatePolicyDialogComponent, {
      width: '400px',
      minWidth: '280px',
      data: {data: editablePolicy}
    });

    dialogRef.afterClosed().subscribe((result = null) => {
      console.log('result', result);
      if (result) {
        if (result.policyName && result.policyRole) {
          const role = this.findRole(result.policyRole.value);
          editablePolicy.name = result.policyName.value;
          editablePolicy.role = role._id;

          const newPolicy = {
            name: result.policyName.value,
            group: role._id,
            configuration: editablePolicy.configuration,
            hotels: result.hotels.value,
            flights: result.flights.value,
            vehicles: result.vehicles.value,
            trains: result.trains.value,
            transfers: result.transfers.value,
          };

          this.travelPolicyService.updatePolicy(newPolicy, id).subscribe(() => {
            this.callPolicies();
          });
          this.checkPolicyChange();
        }
      }
    });
  }


  checkPolicyChange(): void {
    this.policyHasChange = JSON.stringify(this.policyList) !== this.localStorageService.get('savedPolicy');
  }
}
