<div class="default_panel">
  <div class="absoluteLoading" *ngIf="loading">

    <div class="absoluteLoading" *ngIf="loading">
      <div class="padding20 text-center">
        <p>{{'LOADING'|translate}}</p>
        <mat-progress-bar class="example-margin" mode="buffer">
        </mat-progress-bar>
      </div>
    </div>
  </div>
  <div class="default_heading d-flex justify-content-between">
    <div class="d-flex align-self-center">New registrations ({{userList.length}})</div>
    <div class="d-flex align-items-center">
      <button mat-icon-button aria-label="Refresh" class="small-md" (click)="getUserList()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
  <div class="default_content smfont scrolling">

    <div *ngIf="userList">
      <div class="d-flex justify-content-between bd-highlight borderbottom" *ngFor="let user of userList;let l = last" [ngClass]="{'mb-3':!l}">
        <div class="avatar">
          <img class="avataricon" [src]="user.image"/>
        </div>
        <div class="flex-fill">
          <p class="smalltitle">{{user.name}} {{user.surname}}</p>
          <p class="smalltext">{{user.nationality}} | +{{user.prefix}} {{user.phoneNumber}}</p>
          <p class="smalltext">#{{user.id}}</p>
        </div>
        <div class="flex-fill text-right">
          <button (click)="toggleActivation(user)" matTooltip="Disable User" color="primary" *ngIf="user.active" class="small-md" mat-icon-button aria-label="disable">
            <mat-icon>lock</mat-icon>
          </button>
          <button (click)="toggleActivation(user)" matTooltip="Enable User" color="warn" *ngIf="!user.active" class="small-md" mat-icon-button aria-label="enable">
            <mat-icon>lock_open</mat-icon>
          </button>
          <button matTooltip="Send message" (click)="openMessage(user)" class="small-md marginleft5" data-toggle="modal" data-target="#emailModal" mat-icon-button aria-label="write message">
            <mat-icon>mail_outline</mat-icon>
          </button>
          <button class="small-md marginleft5" mat-icon-button aria-label="user details">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </div>
      </div>
    </div>


  </div>

  <div class="default_footer text-center">
    <button mat-button class="small-md-text">go to users list</button>
  </div>

</div>
<div class="modal fade" id="emailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="recipient">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Write a message to {{recipient.name}} {{recipient.surname}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <p>mail to: {{recipient.email}}</p>

        <mat-form-field class="full-width">
          <mat-label>Type your message</mat-label>
          <textarea matInput [(ngModel)]="message" placeholder="Your message"></textarea>
        </mat-form-field>
      </div>
      <div class="modal-footer">
        <button mat-button data-dismiss="modal">Close</button>
        <button mat-button (click)="sendMessage(message)" [disabled]="!message">Send</button>
        <mat-progress-bar class="example-margin" mode="buffer" *ngIf="sending"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>
