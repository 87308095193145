<div mat-dialog-title>
  <span class="" *ngIf="!data.fee?._id">{{'ADD'|translate}}</span>
  <span class="" *ngIf="data.fee?._id">{{'EDIT'|translate}}</span>
  {{'FEE'|translate}}
</div>
<mat-dialog-content class="mat-typography">

  <div class="">
    <div  class="text-center" *ngIf="!currentFee">
      Loading
    </div>
    <div *ngIf="currentFee">

      <div class="sectionFeeTitle mb-2">
        {{'SETTINGS'|translate}}
      </div>
      <div class="row">
        <div class="col-6 align-self-center">
          <mat-form-field class="">
            <mat-label>Nome fee</mat-label>
            <input matInput type="text" [(ngModel)]="currentFee.name">
          </mat-form-field>
        </div>
        <div class="col-6 align-self-center">
          <div class="example-section" *ngIf="loggedUser.company.services.b2c || loggedUser.role.superAdmin">
            <mat-checkbox class="example-margin" [(ngModel)]="currentFee.b2c">B2C</mat-checkbox>
          </div>
          <div class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="currentFee.enable">Enabled</mat-checkbox>
          </div>
        </div>
      </div>

      <div class="sectionFeeTitle mb-2">
        {{'FLIGHT'|translate}}
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee volo di linea</mat-label>
            <input matInput type="number" [(ngModel)]="currentFee.flight.amount">
            <mat-icon matSuffix>euro</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee type</mat-label>
            <mat-select [(ngModel)]="currentFee.flight.feeServiceFor">
              <mat-option *ngFor="let type of flightFees" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee volo lowCost</mat-label>
            <input matInput type="number" [(ngModel)]="currentFee.lowCost.amount">
            <mat-icon matSuffix>euro</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee type</mat-label>
            <mat-select [(ngModel)]="currentFee.lowCost.feeServiceFor">
              <mat-option *ngFor="let type of flightFees" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee volo NDC</mat-label>
            <input matInput type="number" [(ngModel)]="currentFee.ndc.amount">
            <mat-icon matSuffix>euro</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee type</mat-label>
            <mat-select [(ngModel)]="currentFee.ndc.feeServiceFor">
              <mat-option *ngFor="let type of flightFees" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>



      <div class="sectionFeeTitle mb-2">
        {{'HOTEL'|translate}}
      </div>

      <div class="row">
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee hotel</mat-label>
            <input matInput type="number" [(ngModel)]="currentFee.hotel.amount">
            <mat-icon matSuffix>euro</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee type</mat-label>
            <mat-select [(ngModel)]="currentFee.hotel.feeServiceFor">
              <mat-option *ngFor="let type of stantardFees" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="sectionFeeTitle mb-2">
        {{'CAR'|translate}}
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee car</mat-label>
            <input matInput type="number" [(ngModel)]="currentFee.car.amount">
            <mat-icon matSuffix>euro</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee type</mat-label>
            <mat-select [(ngModel)]="currentFee.car.feeServiceFor">
              <mat-option *ngFor="let type of stantardFees" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="sectionFeeTitle mb-2">
        {{'ITALO'|translate}}
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee Italo</mat-label>
            <input matInput type="number" [(ngModel)]="currentFee.italo.amount">
            <mat-icon matSuffix>euro</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee type</mat-label>
            <mat-select [(ngModel)]="currentFee.italo.feeServiceFor">
              <mat-option *ngFor="let type of stantardFees" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="sectionFeeTitle mb-2">
        {{'TRENITALIA'|translate}}
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee Trenitalia</mat-label>
            <input matInput type="number" [(ngModel)]="currentFee.trenitalia.amount">
            <mat-icon matSuffix>euro</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="">
            <mat-label>Fee type</mat-label>
            <mat-select [(ngModel)]="currentFee.trenitalia.feeServiceFor">
              <mat-option *ngFor="let type of stantardFees" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'CLOSE'|translate}}</button>
  <button *ngIf="data.fee?._id && currentFee" mat-button color="primary" (click)="save()">{{'SAVE'|translate}}</button>
  <button *ngIf="!data.fee?._id && currentFee" mat-button color="primary" [disabled]="!currentFee.name" (click)="create()">{{'SAVE'|translate}}</button>
</mat-dialog-actions>
