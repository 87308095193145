<mat-form-field class="full-width" appearance="outline">
  <mat-label>{{'USER'|translate}}</mat-label>
  <input [ngModelOptions]="{standalone: true}" matInput placeholder="{{'SEARCH_USER'|translate}}" aria-label="Departure"
         (ngModelChange)="searchUser($event)" [(ngModel)]="usertext" [matAutocomplete]="cpcomplete">
  <button type="button" *ngIf="usertext" matSuffix mat-icon-button aria-label="Clear" (click)="clearFrom()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #cpcomplete="matAutocomplete" (closed)="selectFirst()">
    <mat-option *ngFor="let user of usersList" [value]="user.name" (click)="selectValue(user)">
      <span>{{user.name}} {{user.surname}} ({{user.company.name}})</span>
    </mat-option>
  </mat-autocomplete>

</mat-form-field>
<div *ngIf="selected.length>0">
  <span class="badge-material" *ngFor="let user of selected; let i = index;">
    {{user.name}} {{user.surname}} ({{user.company.name}}) <button mat-icon-button (click)="removeUser(user._id)"><mat-icon>close</mat-icon></button>
  </span>
</div>
