import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AppComponent } from '../../../app.component';
import { TravelPolicyService } from '../../../services/travel-policy/travel-policy.service';
import { MainComponent } from '../../mainmodule/main/main.component';
import { BehaviorSubject } from 'rxjs';
import { TrainService } from '../../trains/train.service';
import { SearchInformationService } from '../../../components/search-information/services/search-information.service';

@Component({
  selector: 'app-policy-approval-button',
  templateUrl: './policy-approval-button.component.html',
  styleUrls: ['./policy-approval-button.component.scss'],
})
export class PolicyApprovalButtonComponent implements OnInit{
  alreadyRequested = false;
  @Input() loading$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() disabled = false;
  search : any;
  @Input() reservation : any;
  @Input() type = 'train'; // train hotel car flight
  @Input() arrayOfAlerts = [];
  @Output() buyAction = new EventEmitter<any>();
  private service : any;
  private policyTransfer : any;

  constructor(
    public mainComponent : MainComponent,
    public appComponent : AppComponent,
    private travelPolicyService : TravelPolicyService,
    private searchInformationService : SearchInformationService,
    private trainService : TrainService,
  ){
    this.service = {
      train: trainService,
    };
  }

  ngOnInit() : void{
    this.getInfo();
  }

  shouldCheckPolicy() : boolean{
    this.arrayOfAlerts = this.arrayOfAlerts.filter(value => value !== null);
    let policy = false;
    if (this.appComponent.isSbt || this.appComponent.isB2B){
        if(this.reservation.approvalId && this.mainComponent.loggedUser.company.services.policy){
          policy = true;
        }
        if(this.arrayOfAlerts.length > 0 && this.mainComponent.loggedUser.company.services.policy){
          policy = true;
        } else {
          policy = false;
        }
      
    } else {
      policy = false;
    }
    return policy;
  }

  requestAuthorization(){
    this.loading$.next(true);
    const arrayOfAlerts = [];
    this.arrayOfAlerts.forEach(e => Array.isArray(e) ? e.forEach(ee => arrayOfAlerts.push(ee)) : arrayOfAlerts.push(e));
    const tariff = { arrayOfAlerts };
    this.travelPolicyService.createSearchTrain(this.reservation, arrayOfAlerts).subscribe(res => {
      this.reservation.approvalId = res.data._id;
      this.service[this.type].updateApprovalId(this.reservation._id, res.data._id).subscribe(r => {
        this.travelPolicyService.createAuthorization(this.reservation, tariff, res.data.searchId, this.reservation.paper)
            .forEach(auth => {
              this.travelPolicyService.postAuthorization(auth).subscribe(authRes => {
                console.log('Authorization request sent successfully');
                this.alreadyRequested = true;
                this.loading$.next(false);
                this.getInfo();
              });
            });
      });
    });
  }

  transferApproved(){
    let valid = true;
    if (this.policyTransfer && this.reservation.approvalId && this.policyTransfer.arrayOfAlerts.length > 0){
      if (this.policyTransfer.authorisations){
        valid = false;
        const approved = this.policyTransfer.authorisations.every(x => x.status === 'approved');
        if (!!approved){
          this.policyTransfer.status = 'approved';
          valid = true;
        }
      } else{
        valid = false;
      }
    }
    return valid;
  }

  takeAction(){
    this.loading$.next(true);
    this.buyAction.emit(true);
  }

  getInfo(){
    if (this.reservation.approvalId){
      this.loading$.next(true);
      this.searchInformationService.getSearch(this.reservation.approvalId).subscribe((res) => {
        this.loading$.next(false);
        this.search = res.data[0];
        this.returnSApproval();
      }, error => {
        this.loading$.next(false);
        console.error('Error => ', error);
      });
    }
  }

  private returnSApproval(){
    this.search.status = 'pending';
    const declined = this.search.userAuthorisations.find(x => x.status === 'declined');
    if (!!declined){
      this.search.status = 'declined';
    }

    const approved = this.search.userAuthorisations.every(x => x.status === 'approved');
    if (!!approved){
      this.search.status = 'approved';
    }
  }

}
