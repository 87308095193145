<div class="loginpage h-100">
  <div class="row no-gutters h-100">

    <div class="d-none d-lg-flex leftlog" [ngClass]="{'col-lg-7':appcompo.isB2B || appcompo.isGat, 'col-lg-4':!appcompo.isB2B && !appcompo.isGat}">
      <div class="bgopacity h-100">

      </div>
    </div>

    <div class="my-auto" [ngClass]="{'col-lg-5':appcompo.isB2B || appcompo.isGat, 'col-lg-8':!appcompo.isB2B && !appcompo.isGat}"> <!-- my-auto -->
      <div class="padding10 text-right">

        <button mat-icon-button [matMenuTriggerFor]="langmenu" aria-label="Example icon-button with a menu">
          <img *ngIf="appcompo.currentLang==='it'" class="" src="assets/img/flags/italian_flag.png"/>
          <img *ngIf="appcompo.currentLang==='en'" class="" src="assets/img/flags/english_flag.png"/>
        </button>
        <mat-menu #langmenu="matMenu">
          <button [ngClass]="{'active':appcompo.currentLang=='en'}" (click)="appcompo.useLanguage('en')" mat-menu-item>
            <img src="assets/img/flags/english_flag.png"/> English
          </button>
          <button [ngClass]="{'active':appcompo.currentLang=='it'}" (click)="appcompo.useLanguage('it')" mat-menu-item>
            <img src="assets/img/flags/italian_flag.png"/> Italiano
          </button>
        </mat-menu>

      </div>

      <div *ngIf="!forgot" class="padding20 maxwidth">
        <div class="toplogo text-center">
          <img *ngIf="!appcompo.isSbt && !appcompo.isB2B && !appcompo.isGat" src="assets/img/themes/Logo-Flyleo-blue.png"/>
          <img *ngIf="appcompo.isB2B" src="assets/img/themes/b2blogo.png"/>
          <img *ngIf="appcompo.isSbt" src="assets/img/suite.png"/>
          <img class="" *ngIf="appcompo.isGat" src="assets/img/FlyGattinoniNero.png"/>
        </div>
        <div class="toplogin text-center marginbottom20">
          <h3 class="marginbottom10">{{'WELCOME'|translate}}!</h3>
          <h4 class="ingrey">{{'SIGN_IN_TO_CONTINUE'|translate}}
            <span *ngIf="!appcompo.isSbt && !appcompo.isB2B && !appcompo.isGat"> flyLEO</span>
            <span *ngIf="appcompo.isSbt"> SUITE 4 TRAVEL</span>
            <span *ngIf="appcompo.isB2B"> B2B</span>
            <span *ngIf="appcompo.isGat"> flyGATTINONI</span>
            .</h4>
        </div>

        <hr/>
        <form #loginForm="ngForm">
          <mat-form-field appearance="outline">
            <mat-label>{{'USERNAME'|translate}}</mat-label>
            <input minlength="3" [(ngModel)]="user.email" name="email" #email="ngModel" required matInput type="email">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{'PASSWORD'|translate}}</mat-label>
            <input matInput minlength="4" [(ngModel)]="user.password" name="password" #password required [type]="hide ? 'password' : 'text'">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>

          <div class="mb-2">
            <!-- <p>{{'NOT_REGISTERED'|translate}}? <span class="linkspan">{{'SIGNUP'|translate}}</span></p> -->
            <!-- <p><span class="linkspan" (click)="forgot = ! forgot">{{'FORGOT_PASSWORD'|translate}}?</span></p> -->
            <button style="margin-right:10px;" mat-button (click)="forgot = ! forgot">
              {{'FORGOT_PASSWORD'|translate}}?
            </button>
          </div>
          <div style="padding-left:15px; font-size:11px">{{'PASSWORD_REMINDER'|translate}}</div>
          <hr/>
          <div>
            <button class="full-width" mat-raised-button [disabled]="!loginForm.form.valid" type="submit" (click)="login()" color="primary">
              {{'LOGIN'|translate}}
              <mat-icon *ngIf="!loading">keyboard_arrow_right</mat-icon>
              <mat-icon class="rotatingloop" *ngIf="loading">autorenew</mat-icon>
            </button>
          </div>
          <!--
          <div *ngIf="!appcompo.isSbt && !appcompo.isGat">
            <div class="text-center mt-3 mb-3">Oppure</div>
            <div *ngIf="appcompo.isB2B">
              <a href="https://tool.flyleo.it/company-login" class="full-width" mat-raised-button>Accedi alla vecchia piattaforma</a>
            </div>
            <div *ngIf="!appcompo.isB2B">
              <a href="https://tool.flyleo.it/login" class="full-width" mat-raised-button>Accedi alla vecchia piattaforma</a>
            </div>
          </div>
          <div *ngIf="appcompo.isGat">
            <div class="text-center mt-3 mb-3">Oppure</div>
            <div>
              <a href="https://old.flygattinoni.it/login" class="full-width" mat-raised-button>Accedi alla vecchia piattaforma</a>
            </div>
          </div>
          -->
        </form>
      </div>

      <div *ngIf="forgot" class="padding20 maxwidth">
        <div class="toplogo text-center">
          <img *ngIf="!appcompo.isSbt && !appcompo.isB2B && !appcompo.isGat" src="assets/img/themes/Logo-Flyleo-blue.png"/>
          <img *ngIf="appcompo.isB2B" src="assets/img/themes/b2blogo.png"/>
          <img *ngIf="appcompo.isSbt" src="assets/img/suite.png"/>
          <img class="" *ngIf="appcompo.isGat" src="assets/img/FlyGattinoniNero.png"/>
        </div>
        <div class="toplogin text-center marginbottom20">
          <h3 class="marginbottom10">Recupero password.</h3>
          <h4 class="ingrey">Inserisci l'indirizzo email associato al tuo account.</h4>
          <p>Riceverai un messaggio con una password provvisoria per effettuare il login.</p>
        </div>

        <hr/>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>{{'EMAIL'|translate}}</mat-label>
            <input minlength="3" [(ngModel)]="user.email" required matInput type="email">
          </mat-form-field>
          <hr/>
          <div>
            <button style="margin-right:10px;" mat-button (click)="forgot = ! forgot">
              Torna al login
            </button>
            <button mat-button color="primary" [disabled]="!user.email" (click)="sendForgot()">
              Invia credenziali
              <mat-icon *ngIf="!loading">keyboard_arrow_right</mat-icon>
              <mat-icon class="rotatingloop" *ngIf="loading">autorenew</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="pt-4 maxwidth text-center small" *ngIf="!appcompo.isGat">
        <div class="d-inline vertical mr-3">
          <a href="https://flyleo.it/" target="_blank">About</a>
        </div>
        <div class="d-inline vertical">
          <a href="https://www.leonardotravel.net/privacy" target="_blank">Privacy policy</a>
        </div>
        

      </div>

    </div>

  </div>
</div>

<div class="modal fade" id="loginModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body" *ngIf="link">
        <div class="text-center">
          <span class="material-icons">
            warning_amber
          </span>
        </div>
        <div class="text-center">
          Non sei autorizzato al login su questa piattaforma
        </div>
        <div class="text-center">
          Perfavore effettua l'accesso su: <a href="https://{{link}}">{{link}}</a>
        </div>

      </div>
    </div>
  </div>
</div>
