import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'italo'
})
export class ItaloCatalogPipe implements PipeTransform {
  static BOOKING = {
    0: 'Default',
    1: 'Hold',
    2: 'Confirmed',
    3: 'Closed',
    4: 'HoldCanceled',
    5: 'PendingArchive',
    6: 'Archived',
    7: 'Unmapped'
  }

  static PAYMENT = {
     0: 'New',
     1: 'Received',
     2: 'Pending',
     3: 'Approved',
     4: 'Declined',
     5: 'Unknown',
     6: 'PendingCustomerAction',
     7: 'Unmapped',
  }

  transform(value: number, type: string = 'booking'): any {
    if (!value) { return value; }
    switch(type){
      case 'booking':
        return ItaloCatalogPipe.BOOKING[value]
      case 'payment':
        return ItaloCatalogPipe.PAYMENT[value]
      default:
        return ''
    }
  }

}
