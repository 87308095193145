import { Injectable } from '@angular/core';
import {Roles} from '../../classes/profiles/role';
import {HttpserviceService} from '../httpservice.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends HttpserviceService {
  roleUrl: string;
  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
    this.roleUrl = this.apiUrl + 'acl/';
  }

  getRoles(id): Observable<any> {
    const getRolesUrl = this.roleUrl + 'list?company=' + id;
    return this.http
      .get<any>(getRolesUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  addRole(value) {
    const getRolesUrl = this.roleUrl + 'create';
    return this.http
      .put<any>(getRolesUrl, value, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getRole(id) {
    const getRolesUrl = this.roleUrl + id;
    return this.http
      .get<any>(getRolesUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  editRole(id, body) {
    if (body.users) {
      delete body.users;
    }
    const getRolesUrl = this.roleUrl + id;
    return this.http
      .post<any>(getRolesUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  manageUsersRole(id, body) {
    const users = {users: body};
    const getRolesUrl = this.roleUrl + id + '/user';
    return this.http
      .post<any>(getRolesUrl, users, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  returnPermission(role, product, action) {

  }

}
