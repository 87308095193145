

<ng-container *ngIf="shouldCheckPolicy() else buyButton">
  <ng-container *ngIf="!reservation.approvalId">
    <button (click)="requestAuthorization()" [disabled]="alreadyRequested" color="primary" mat-raised-button>
      {{'REQUEST_AUTHORIZATION'|translate}}
      <span class="material-icons" *ngIf="!(loading$ | async) else loadingSpinner">
        arrow_right_alt
      </span>
    </button>
    <app-policy-alerts-icon [arrayOfAlerts]="arrayOfAlerts"></app-policy-alerts-icon>
  </ng-container>
  <ng-container *ngIf="reservation.approvalId">
    <ng-container *ngIf="!search || search.status !== 'approved' || !transferApproved() else buyButton">
      <div *ngIf="!transferApproved()" class="pt-2 mb-1 smalltext text-danger">Trasferta non ancora autorizzata</div>
      <div *ngIf="search && search.status === 'pending'" class="mb-1 smalltext text-warning">
        Richiesta di approvazione inviata.
      </div>

      <div *ngIf="search" class="smalltext">
        <div class="mb-1 pt-2">
          Stato approvazione servizio:
          <span *ngIf="search.status === 'pending'" class="text-warning">{{search.status}}</span>
          <span *ngIf="search.status === 'approved'" class="text-success">{{search.status}}</span>
          <span *ngIf="search.status === 'declined'" class="text-danger">{{search.status}}</span>
        </div>

        <div *ngIf="search.status === 'declined'">
          <div *ngFor="let auth of search.userAuthorisations">
            <ng-container *ngIf="auth.status === 'declined'">
              <div>Ragione: <span class="text-danger">{{auth.note}}</span></div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

</ng-container>

<ng-template #buyButton>
  <button (click)="takeAction()" color="primary" mat-raised-button [disabled]="disabled || (loading$ | async) || (shouldCheckPolicy() && !!search && search.status !== 'approved')">
    {{'BUY_RESERVATION'|translate}}
    <span class="material-icons" *ngIf="!(loading$ | async) else loadingSpinner">
      arrow_right_alt
    </span>
  </button>
</ng-template>

<ng-template #loadingSpinner>
  <mat-icon class="spin">autorenew</mat-icon>
</ng-template>
