<div class="container">

  <app-selected-users-group [selection]="selection" (removeSelection)="removeSelection($event)" (roleUpdated)="getUserList();emptySelection()"></app-selected-users-group>

  <div class="table-actionbar">
    <div class="search-box">
      <mat-form-field class="example-form-field" appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Search by Name, Surname and Email</mat-label>
        <input matInput type="text" [value]="value" (keyup)="applyFilter($event)">
        <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <mat-table #table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    <!-- checkbox header -->
    <ng-container matColumnDef="select">
      <mat-header-cell class="select-header w-100p" *matHeaderCellDef>
        <div>
          <label class="select-label">Select all</label>
          <mat-checkbox color="primary" (change)="$event ? selectAllUsers() : null" [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </div>
        <div>
          <label class="select-label">Select page</label>
          <mat-checkbox color="primary" (change)="$event ? toggleSelectedPageUsers() : null" [checked]="selection.hasValue() && isCurrentPageSelected()"
                        [indeterminate]="selection.hasValue() && !isCurrentPageSelected()">
          </mat-checkbox>
        </div>
      </mat-header-cell>
      <mat-cell class="w-100p" *cdkCellDef="let row">
        <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{ row.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="surname">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Surname</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{ row.surname }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Email</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{ row.email }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="group">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Role</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        {{row?.group?.name ? row?.group?.name : 'NOT ASSIGNED'}}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <!-- table pagination -->
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
