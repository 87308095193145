<button (click)="toggleRefundModal()" [disabled]="isDisabled" id="REFUND" mat-button
        type="button">{{'TRAIN_MODULE.AFTERSALE.REFUND'|translate}}</button>

<div aria-hidden="true" aria-labelledby="refundModal" class="modal fade" data-backdrop="static" id="refundModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document" style="top:70px">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="refundModalTitle">{{'TRAIN_MODULE.DELETE_RESERVATION_QUESTION'|translate}}</h5>
        <button (click)="toggleRefundModal()" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <ng-container *ngIf="!refundAttempt else successDialog">
          <div *ngIf="!(refundReservation$ | async) else loadingPenalty" class="col-12">
            <div class="row">
              <table class="table table-borderless w-100">
                <tbody>
                <tr>
                  <td>{{ 'TARIFF' | translate }}</td>
                  <td>{{ 'TRAIN_MODULE.AFTERSALE.PENALTY' | translate }}</td>
                  <td>{{ 'SELECT' | translate }}</td>
                </tr>
                <tr *ngFor="let sn of solutionNodes; let i = index" (click)="setSelection(sn)" [ngClass]="sn.allowed ? 'segment-row' : 'disabled-segment-row'">
                  <td>
                    <div class="row">
                      <div class="col-4">{{ catalog.stations[sn.start.id] }} -> {{ catalog.stations[sn.end.id] }}</div>
                      <div class="col-4" *ngIf="sn.traveller as traveller">{{ traveller?.name }} {{ traveller?.surname }}</div>
                      <div class="col-4 offers-text">
                        <div *ngFor="let offer of sn.offers">
                          - {{ catalog.offers[offer?.offer.id] }}
                          | {{ catalog.services[offer?.catalogServiceId.id] }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div *ngIf="getEntitlementTotal(sn) as entitlement">
                      {{ entitlement | number: '.2-2' }}€
                    </div>
                  </td>
                  <td>
                    <ng-container *ngIf="sn.allowed">
                      <mat-checkbox [checked]="selectedPostSaleDetails[sn.idXml + sn.traveller.xmlId]"></mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="sn.closed">
                      <mat-icon>clear</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="!sn.allowed && !sn.closed">
                      <mat-icon>block</mat-icon>
                    </ng-container>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <mat-divider></mat-divider>
            <div class="row mt-2">
              <div class="col-6">
                <table class="table table-responsive-md table-borderless w-100">
                  <tbody>
                  <tr>
                    <td>{{'TRAIN_MODULE.TOTAL'|translate}}</td>
                    <td>{{ getTotalString() }}</td>
                  </tr>
                  <tr>
                    <td>{{'NO_REFUNDABLE_FARE'|translate}}</td>
                    <td *ngIf="getTotal().totalPenalty.amount else unknownRefund">
                      <ng-container *ngIf="!penalty">~</ng-container>
                      {{ getTotal().totalPenalty.amount | number:'.2-2' }} {{ getTotal().totalPenalty.currency }}</td>
                  </tr>
                  <tr>
                    <td>{{'TRAIN_MODULE.AFTERSALE.REFUND'|translate}}</td>
                    <td *ngIf="getTotal().totalRefund.amount else unknownRefund">
                      <ng-container *ngIf="!penalty">~</ng-container>
                      {{ getTotal().totalRefund?.amount | number:'.2-2' }} {{ getTotal().totalRefund?.currency }}</td>
                  </tr>
                  <ng-template #unknownRefund>
                    <td>-</td>
                  </ng-template>
                  </tbody>
                </table>
              </div>
              <div class="col-6">
                <div class="row my-3" *ngIf="priceChecked">
                    <span class="alert alert-warning" *ngIf="penalty.allowed === 'false' else allowedRefund">
                      <mat-icon>warning</mat-icon>{{ penalty.message }}
                    </span>
                  <ng-template #allowedRefund>
                    <span class="alert alert-success">
                      <mat-icon>done</mat-icon>{{ 'TRAIN_MODULE.AFTERSALE.POSSIBLE_CHANGE' | translate }}
                    </span>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

        </ng-container>
        <ng-template #loadingPenalty>
          <div class="mb-2">{{'TRAIN_MODULE.LOADING'|translate}}</div>
          <mat-progress-bar mode="indeterminate" style="max-width: 200px;display:inline-block;"></mat-progress-bar>
        </ng-template>
        <ng-template #successDialog>
          <mat-dialog-content>
            <ng-container *ngIf="errorMessage else successMessage">
              <mat-icon>warning</mat-icon>
              {{ errorMessage | translate}}
            </ng-container>
            <ng-template #successMessage>
              {{'TRAIN_MODULE.RESERVATION_DELETED' | translate}}
            </ng-template>
          </mat-dialog-content>
        </ng-template>

        <div *ngIf="!refundAttempt" class="text-right">
          <button (click)="checkPenalty()" mat-button type="button" [disabled]="priceChecked || noneSelected()">
            {{'ASK_TARIFFES'|translate}}
            <ng-container *ngIf="(checkingPenalty$ | async)">
              <mat-icon class="spin">autorenew</mat-icon>
            </ng-container>
          </button>
          <button (click)="confirmRefund()" [disabled]="!priceChecked || (refundReservation$ | async)" mat-button type="button">
            {{'TRAIN_MODULE.DELETE_RESERVATION'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
