<mat-form-field class="full-width" appearance="outline">
  <input matInput placeholder="{{'SEARCH_BY_COMPANY'|translate}}" aria-label="Departure"
         (ngModelChange)="searchCompany($event)" [(ngModel)]="companytext" [matAutocomplete]="cpcomplete">
  <button type="button" *ngIf="companytext" matSuffix mat-icon-button aria-label="Clear" (click)="clearFrom()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #cpcomplete="matAutocomplete" (close)="selectFirst()">
    <mat-option *ngFor="let comp of companiesList" [value]="comp.name" (click)="selectComp(comp)">
      <span>{{comp.name}}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
