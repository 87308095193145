<div *ngIf="loadingRates || loading" class="mb-2">
  <mat-progress-bar mode="query" style="max-width: 100%;"></mat-progress-bar>
</div>
<div class="hotelResults">
  <div class="row">
    <div class="col-md-8">
      <div *ngFor="let hotel of obs|async;let i = index" class="card">
        <div class="row">
          <div class="col-md-3">
            {{i + 1}} /  {{getPopulatedList().length}}
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-12">
                <h3>{{hotel.name}}</h3>
                <div class="row">
                  <div class="col-md-9">

                    <address>
                      {{hotel.address.line}}<br/> {{hotel.address.city}}, {{hotel.address.zip}}
                    </address>
                    <span class="badge badge-default">{{hotel.rooms.length }} Rooms Availables</span>
                    <br/>
                    Price from: {{hotel.minPrice}}
                  </div>
                  <div class="col-md-3">

                    <button class="btn btn-success btn-md" type="button">
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <mat-paginator (page)="pageChanged($event)" *ngIf="currentItemsToShow"
                     [length]="currentItemsToShow.length"
                     [pageSizeOptions]="pageSizeOptions"
                     [pageSize]="pageSize">
      </mat-paginator>
    </div>
    <div class="col-md-4">
    </div>
  </div>
</div>


<!--<table class="table">-->
<!--  <thead>-->
<!--  <tr>-->
<!--    <th scope="col">Name</th>-->
<!--    <th scope="col">City</th>-->
<!--    <th scope="col">Address</th>-->
<!--    <th scope="col">Image</th>-->
<!--    <th scope="col">Rooms Counter</th>-->
<!--    <th scope="col">Max Price</th>-->
<!--    <th scope="col">Max Price</th>-->
<!--  </tr>-->
<!--  </thead>-->
<!--  <tbody>-->
<!--  <tr *ngFor="let hotel of this.getPopulatedList()">-->
<!--    <th scope="row">{{hotel.name}}</th>-->
<!--    <th scope="row">{{hotel.address.city}}</th>-->
<!--    <th scope="row">{{hotel.address.line}}</th>-->
<!--    <th-->
<!--      scope="row">{{this.getAllImages(hotel.details)[0] ? this.getAllImages(hotel.details)[0].list[0].url.substring(0, 8) : ''}}</th>-->
<!--    <th scope="row">{{hotel.rooms.length}}</th>-->
<!--    <td scope="row">{{hotel.minPrice}}</td>-->
<!--    <td scope="row">{{hotel.maxPrice}}</td>-->
<!--  </tr>-->
<!--  </tbody>-->
<!--</table>-->
