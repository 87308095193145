import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject } from 'rxjs';
import { MainComponent } from '../../../mainmodule/main/main.component';
import { DossierService } from '../../../../services/dossier/dossier.service';
import { AdmserviceService } from '../../../../services/adminserv/admservice.service';
import { CustomAuthService } from '../../../../services/customAuth/custom-auth.service';
import { TrainMainComponent } from '../common/train-main/train-main.component';
import { TrainAllowedOperations, TrainAuditTransaction, TrainLogResponse, TrainReservation, TrainTravel } from '../../classes/train.models';
import { TrainService } from '../../train.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TrainUtils } from '../train.utils';

@Component({
  selector: 'train-singlereservation',
  templateUrl: './singlereservation.component.html',
  styleUrls: ['./singlereservation.component.scss'],
})
export class TrainReservationComponent extends TrainMainComponent{
  loadingAuditTransactions$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingAllowedOperations$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingShoppingCart$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  deleteReservation$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  @ViewChild('menuTrigger') menuTrigger : MatMenuTrigger;
  query : any;
  pnr : string;
  reservation : TrainReservation;
  tabs : any[];
  currentTab : string;

  loading : boolean;

  allowedOperations : TrainAllowedOperations;
  trainShoppingCart : TrainLogResponse;
  selectedTravelSolution = 0;
  deleteErrorMessage : any;
  auditTransactions : TrainAuditTransaction[];
  downloadingPdf = false;

  constructor(
    private route : ActivatedRoute,
    private trainService : TrainService,
    private dossierService : DossierService,
    private bottomSheet : MatBottomSheet,
    private admServ : AdmserviceService,
    public mainComponent : MainComponent,
    public customAuth : CustomAuthService,
    public router : Router,
    public titleService : Title,
    public translate : TranslateService,
  ){
    super(router, titleService, translate);
    this.loading = true;
    this.currentTab = 'TRAINS';
    this.tabs = [
          {
              name: 'TRAINS',
              active: true,
              enabled: true,
          },
          {
              name: 'PASSENGERS',
              active: false,
              enabled: true,
          },
          {
              name: 'AFTERSALE',
              active: false,
              enabled: false,
          },
          {
              name: 'AUDIT',
              active: false,
              enabled: false,
          },
          {
              name: 'SHOPPING_CART',
              active: false,
              enabled: false,
          },
      ];
  }

  override onInit() : void{
    this.route.params.subscribe(params => {
      this.query = params.id;
      this.pnr = this.route.snapshot.paramMap.get('id');
      console.log('this.trainId => ', this.pnr);
      this.loading = true;
      this.trainService.getReservation(this.pnr).subscribe((res) => {
        this.loading = false;
        this.reservation = res;
        if (this.reservation.status !== 'RESERVED'){
          this.reload();
          this.tabs[2].enabled = true;
          this.tabs[3].enabled = true;
        } else{
          this.loadShoppingCart();
          this.tabs[4].enabled = true;
        }
      }, error => {
        this.loading = false;
        console.error('Error => ', error);
      });
    });
  }

  returnTime(t){
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(t / cd);
    let h = Math.floor((t - d * cd) / ch);
    let m = Math.round((t - d * cd - h * ch) / 60000);
    const pad = ((n) => n < 10 ? '0' + n : n);
    if (m === 60){
      h ++;
      m = 0;
    }
    if (h === 24){
      d ++;
      h = 0;
    }
    if (d > 0){
      return [d + 'g ' + pad(h) + 'h', pad(m) + 'm'].join(':');
    } else{
      return [pad(h) + 'h', pad(m) + 'm'].join(':');
    }

  }

  calculateTime(departure, arrival){
    const a = new Date(departure).getTime();
    const b = new Date(arrival).getTime();
    return this.returnTime(b - a);
  }

  setTab(index){
    for (let i = 0; i < this.tabs.length; i ++){
      this.tabs[i].active = false;
    }
    this.tabs[index].active = true;
    this.currentTab = this.tabs[index].name;
  }

  getReservation(id){
    this.loading = true;
    this.trainService.getReservation(id).subscribe((res) => {
      this.loading = false;
      this.reservation = res;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  toggleRemoveDossierModal(){
    $('#RemoveDossierModal').modal('toggle');
  }

  confirmDossierRemove(){
    const body = {
      trains: [this.pnr],
    };
    this.dossierService.removeTrainsFromDossier(this.reservation.paper, body).subscribe((res) => {
      console.log('res => ', res);
      this.getReservation(this.pnr);
    }, error => {
      console.error('res => ', error);
    });
    $('#RemoveDossierModal').modal('toggle');
  }

  toggleAddtoTravel(){
    if (!this.mainComponent.dossierId){
      $('#travelListModal').modal('toggle');
    } else{
      this.addTrain(this.reservation._id);
    }
  }

  addTrain(id){
    const body = {
      trains: [id],
    };
    this.dossierService.addTrainsToDossier(this.mainComponent.dossierId, body).subscribe((res) => {
      console.log('res => ', res);
      this.getReservation(this.pnr);
    }, error => {
      console.error('res => ', error);
    });
  }

  closeAddtoTravel(){
    $('#travelListModal').modal('toggle');
    this.getReservation(this.pnr);
  }

  updateDossier(){
    this.admServ.updateDossier(this.reservation.paper).subscribe((res) => {
      console.log('reservation => ', res);
    }, error => {
      console.error('Error => ', error);
    });
  }

  downloadPdf(){
    if (this.isItalo()) return;
    this.downloadingPdf = true;
    this.trainService.getReservationPDF(this.reservation.pnr).subscribe(data => {
      const blob = new Blob([data], { type: 'file/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', url);
      link.setAttribute('download', `${this.reservation.pnr}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.downloadingPdf = false;
    }, _ => {
      this.downloadingPdf = true;
    });
  }

  loadShoppingCart(){
    this.loadingShoppingCart$.next(true);
    this.trainService.getCreatedReservation(this.pnr).subscribe((res) => {
      this.loadingShoppingCart$.next(false);
      this.trainShoppingCart = res;
      this.trainTravel = {
          ...new TrainTravel(),
          reservatedTravel: { bookingDb: this.reservation, travel: res.createReservationResponse.travel },
      };
      super.updateTrainTravel();
    }, error => {
      this.loadingShoppingCart$.next(false);
      console.error('Error => ', error);
    });
  }


  /** Postvendita **/

  reload(){
    this.getReservation(this.pnr);
    this.loadingAllowedOperations$.next(true);
    this.trainService.getAllowedOperations(this.pnr).subscribe((res) => {
      this.loadingAllowedOperations$.next(false);
      this.allowedOperations = res;
    }, error => {
      this.loadingAllowedOperations$.next(false);
      console.error('Error => ', error);
    });
    this.loadingAuditTransactions$.next(true);
    this.trainService.getAuditTransactions(this.pnr).subscribe((res) => {
      this.loadingAuditTransactions$.next(false);
      this.auditTransactions = res;
    }, error => {
      this.loadingAuditTransactions$.next(false);
      console.error('Error => ', error);
    });
  }

  isAftersaleDisabled(action : string){
    if (!this.allowedOperations?.postSaleDetails || this.allowedOperations.postSaleDetails.length === 0){
      return true;
    }
    const activeTravelSolution = TrainUtils.toArray(this.allowedOperations.travel.travelSolutions)[this.selectedTravelSolution];
    const mainTravelSolutionId = activeTravelSolution?.id.travelSolutionId;
    const returnActiveTravelSolutionId = activeTravelSolution?.returnTravelSolution?.id.travelSolutionId;
    const travelSolutionIds = [mainTravelSolutionId, returnActiveTravelSolutionId].filter(a => !!a);
    console.log('Active TravelSolutions => ' + JSON.stringify(travelSolutionIds));
    const allowedMap = {};
    this.allowedOperations.postSaleDetails.filter(f => f.allowed).forEach(psd => {
      allowedMap[psd.postSaleType.code] = allowedMap[psd.postSaleType.code] || [];
      allowedMap[psd.postSaleType.code].push(psd.offeredServiceId);
    });
    console.log('Allowed Operations => ' + JSON.stringify(allowedMap));
    const postSaleDetail = this.allowedOperations.postSaleDetails
      .filter(d => travelSolutionIds.includes(d.offeredServiceId.travelSolutionId))
      .find(detail => detail.postSaleType.code === action);
    return !postSaleDetail || !postSaleDetail?.allowed;
  }

  isFreeCancellationBis(){
    if (!this.reservation.issuedDate){
      return false;
    }
    const nowDate = new Date();
    const diffDays = (date, otherDate) => Math.abs(otherDate - date) / 1000 / 60;
    const tempo : number = diffDays(nowDate.getTime(), new Date(this.reservation.issuedDate).getTime());
    return tempo < 30;
  }

  toggleDeleteModal(){
    $('#deletePnrModal').modal('toggle');
  }

  deleteReservation(){
    this.deleteReservation$.next(true);
    this.trainService.deleteReservation(this.pnr).subscribe(a => {
      this.deleteReservation$.next(false);
      this.router.navigate(['/trains/reservations']);
    }, e => {
      this.deleteErrorMessage = e;
      this.deleteReservation$.next(false);
    });
  }

  setTravelSolutionTab($event : number){
    this.selectedTravelSolution = $event;
  }

  getTotalTransactions(){
    return this.auditTransactions.map(at => ((at.amountPaid || 0) - (at.amountRefunded || 0))).reduce((a, b) => a + b, 0);
  }

  isEconomy(reservation : TrainReservation) : boolean{
    const economyREGX = /Economy/i;
    return !!reservation.itineraries.find(i => economyREGX.test(i.fareType));
  }

  isItalo(){
    return this.reservation.carrier === 'ITALO';
  }
  isVenice(arrival:any){
    return arrival.station.toUpperCase().startsWith('VENEZIA')
  }
}
