import { Component, OnInit } from '@angular/core';
import {CostcenterService} from '../../../services/costcenter/costcenter.service';
import {MatDialogRef} from '@angular/material/dialog';
import {MainComponent} from '../../mainmodule/main/main.component';

@Component({
  selector: 'app-cost-center-dialog',
  templateUrl: './cost-center-dialog.component.html',
  styleUrls: ['./cost-center-dialog.component.scss']
})
export class CostCenterDialogComponent implements OnInit {
  costCenter: any;
  adding = false;
  profile: any;
  constructor(
    private costCenterServ: CostcenterService,
    public dialogRef: MatDialogRef<CostCenterDialogComponent>,
  ) {
    this.costCenter = {
      tag: '',
      description: '',
    }
  }

  ngOnInit(): void {
    this.profile = JSON.parse(localStorage.getItem('fl_profile'));
    console.log(this.profile);
  }

  addCostCenter() {
    this.adding = true;
    this.costCenterServ.addCostCenter(this.profile.company._id, this.costCenter).subscribe((res: any) => {
      this.dialogRef.close(this.costCenter.description);
    }, error => {
      this.adding = false;
      console.error('Error => ', error);
    });
  }

}
