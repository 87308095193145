<div class="default_panel">
  <div class="absoluteLoading" *ngIf="loading">

    <div class="absoluteLoading" *ngIf="loading">
      <div class="padding20 text-center">
        <p>{{'LOADING'|translate}}</p>
        <mat-progress-bar class="example-margin" mode="buffer">
        </mat-progress-bar>
      </div>
    </div>
  </div>
  <div class="default_heading d-flex justify-content-between">
    <div class="d-flex align-self-center">Latest transactions ({{transactions.length}})</div>
    <div class="d-flex align-items-center">
      <button mat-icon-button aria-label="Refresh" class="small-md" (click)="getTransactions()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
  <div class="default_content smfont scrolling">

    <div *ngIf="transactions">
      <div class="d-flex justify-content-between bd-highlight borderbottom" *ngFor="let transaction of transactions;let l = last" [ngClass]="{'mb-3':!l}">
        <div class="iconlist {{transaction.status}}icon">
          <mat-icon *ngIf="transaction.success">check</mat-icon>
          <mat-icon *ngIf="!transaction.success && transaction.status!=='waiting_approval'">close</mat-icon>
          <mat-icon *ngIf="!transaction.success && transaction.status==='waiting_approval'">schedule</mat-icon>

        </div>
        <div class="flex-fill">
          <p class="smalltitle">{{transaction.type}} #{{transaction.id}}</p>
          <p class="smalltext">{{convertDate(transaction.date)|date:'dd/MM/yyyy - HH:mm'}}</p>
        </div>
        <div class="flex-fill text-right">
          <p class="smalltitle">${{transaction.amount|number:'.2-2'}}</p>
          <p class="{{transaction.status}}">{{transaction.status}}</p>
        </div>
      </div>
    </div>


  </div>

  <div class="default_footer text-center">
    <button mat-button class="small-md-text">go to transaction list</button>
  </div>

</div>
