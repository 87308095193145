import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material/material.module';
import {MoneychartComponent} from './widgets/moneychart/moneychart.component';
import {AreachartComponent} from './widgets/areachart/areachart.component';
import {LatestTransactionsComponent} from './widgets/latest-transactions/latest-transactions.component';
import {RegistrationsComponent} from './widgets/registrations/registrations.component';
import {BarchartComponent} from './widgets/barchart/barchart.component';
import {DonutchartComponent} from './widgets/donutchart/donutchart.component';
import {NgApexchartsModule} from 'ng-apexcharts';

@NgModule({
  declarations: [
    DonutchartComponent,
    AreachartComponent,
    MoneychartComponent,
    BarchartComponent,
    RegistrationsComponent,
    LatestTransactionsComponent,
    MoneychartComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgApexchartsModule,
    MaterialModule,
  ],
  exports: [
    DonutchartComponent,
    MoneychartComponent,
    BarchartComponent,
  ],
})
export class WidgetsModule { }
