<custom-title></custom-title>
<div class="padding20">
  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <div *ngIf="!loading && page==undefined && centerList" class="searchrow">
    <div class="row">
      <div class="col-8 d-flex align-items-center">
        <mat-form-field class="marginfield">
          <input placeholder="{{'SEARCH'|translate}}" matInput [(ngModel)]="text" (change)="searchName()">
          <mat-icon *ngIf="!text" matSuffix>search</mat-icon>
          <button *ngIf="text" matSuffix mat-icon-button aria-label="Clear" (click)="text='';searchName()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-hint align="start">{{'DIGIT_YOUR_SEARCH'|translate}}</mat-hint>
        </mat-form-field>
        <button class="iconButtons" mat-icon-button matTooltip="{{'ADD_COST_CENTER'|translate}}" (click)="toggleNewCenter()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!loading && centerList" class="" style="background-color: #fff">
    <div class="row">
      <div class="col-12 d-flex justify-content-end text-right">
        <div class="paginationr pr-3">
          <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
          <span class="mr-2">
            {{'PAGE'|translate}}
            <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
              <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p+1}}</mat-option>
              </mat-select>
            </mat-form-field>
            {{'OF'|translate}} {{maxpages}}
          </span>
          <button mat-icon-button class="iconButtons" (click)="prev()" [disabled]="currentpage==0">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button mat-icon-button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="list_table" *ngIf="!loading && page!='dashboard'">
    <div class="progressLoader" *ngIf="progress">
      <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
    </div>

    <div class="tablelist" [ngClass]="{'withscroll':page==undefined}">
      <table mat-table [dataSource]="centerList.list">
        <ng-container matColumnDef="name" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <span class="sorting tablepadding" matRipple matRippleCentered="true" (click)="sortResults('tag')">{{'COST_CENTER'|translate}}</span>
            <mat-icon *ngIf="query.sort==='tag' && query.order==='asc'">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="query.sort==='tag' && query.order==='desc'">keyboard_arrow_up</mat-icon>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="tablepadding">
              <span>{{element.tag}}</span>
            </span>
          </td>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> {{'DESCRIPTION'|translate}} </th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.description}}</span>
          </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="actions">
          <th class="text-right" mat-header-cell *matHeaderCellDef> {{'ACTIONS'|translate}} </th>
          <td class="text-right" mat-cell *matCellDef="let element">
            <button (click)="toggleEditCenter(element)" mat-icon-button class="iconButtons" color="primary">
              <mat-icon>create</mat-icon>
            </button>
            <button (click)="toggleRemoveCenter(element)" mat-icon-button class="iconButtons" color="warn">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

  <div class="modal fade" id="removeCostCenterModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="removeCostCenterModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div *ngIf="currentCenter" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{'REMOVE_COST_CENTER'|translate}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{'REMOVE_COST_CENTER_QUESTION'|translate}}?
        </div>
        <div class="modal-footer">
          <button (click)="removeCostCenter()" mat-button>{{'DELETE'|translate}}</button>
          <button mat-button data-dismiss="modal">{{'ABORT'|translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="addCostCenterModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="addCostCenterModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{'ADD_COST_CENTER'|translate}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <mat-form-field class="full-width">
              <mat-label>{{'NAME'|translate}}</mat-label>
              <input [(ngModel)]="costCenter.tag" matInput placeholder="...">
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>{{'CODE'|translate}}</mat-label>
              <input [(ngModel)]="costCenter.description" matInput placeholder="">
            </mat-form-field>
          </div>
        </div>
        <div class="modal-footer">
          <button (click)="addCostCenter()" mat-button>{{'SAVE'|translate}}</button>
          <button mat-button data-dismiss="modal">{{'CLOSE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="editCostCenterModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="editCostCenterModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div *ngIf="currentCenter" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{'EDIT_COST_CENTER'|translate}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <mat-form-field class="full-width">
              <mat-label>{{'TAG'|translate}}</mat-label>
              <input [(ngModel)]="currentCenter.tag" matInput placeholder="...">
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>{{'DESCRIPTION'|translate}}</mat-label>
              <input [(ngModel)]="currentCenter.description" matInput placeholder="">
            </mat-form-field>
          </div>
        </div>
        <div class="modal-footer">
          <button (click)="editCostCenter()" mat-button>{{'SAVE'|translate}}</button>
          <button mat-button data-dismiss="modal">{{'CLOSE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
