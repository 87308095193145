import {Component, OnInit, ViewChild} from '@angular/core';
import {AppComponent} from '../../../../app.component';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CompaniesService} from '../../../../services/profile/companies.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import * as $ from 'jquery';
@Component({
  selector: 'app-incentives',
  templateUrl: './incentives.component.html',
  styleUrls: ['./incentives.component.scss']
})
export class IncentivesComponent implements OnInit {
  incentiveslist: any;
  loading: boolean;
  obs: any;
  displayedColumns: string[] = ['index','name','action'];
  dataSource: MatTableDataSource<any>;
  editing: any;
  newIata: any;
  newElementBefore: string;
  newElementAfter: string;
  searchTerm: string;
  loadingInc: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    public appComponent: AppComponent,
    public mainComponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    private companyService : CompaniesService,
  ) {
    this.searchTerm = '';
    this.newElementBefore = '';
    this.newElementAfter = '';
    this.resetEditing();
  }

  ngOnInit(): void {
    this.getIncentives();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  resetEditing() {
    this.editing = {
      name: '',
    };
  }

  editInc(inc): void {
    if (!inc) {
      this.resetEditing();
    } else {
      this.editing = JSON.parse(JSON.stringify(inc));
    }
    $('#editIncModal').modal('toggle');
  }

  getIncentives(): void {
    this.loading = true;
    this.companyService.getIncentives().subscribe((res) => {
      this.loading = false;
      console.log('res ince => ', res);
      this.incentiveslist = res.list;
      this.dataSource = new MatTableDataSource<any>(res.list);
      this.dataSource.paginator = this.paginator;
      this.paginator.firstPage();
      // this.loading = false;
    }, (error) => {
      this.loading = false;
      // this.loading = false;
      console.log('error => ', error);
    });
  }

  editIncCall() {
    this.loadingInc = true;
    this.companyService.editInc(this.editing).subscribe((res) => {
      this.getIncentives();
      this.editInc(null);
      this.loadingInc = false;
    }, error => {
      console.error('Error => ', error);
      this.loadingInc = false;
    });
  }

  addIncCall() {
    this.loadingInc = true;
    this.companyService.addInc(this.editing).subscribe((res) => {
      this.getIncentives();
      this.editInc(null);
      this.loadingInc = false;
    }, error => {
      console.error('Error => ', error);
      this.loadingInc = false;
    });
  }

}
