import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-unaccompanied-minors',
  templateUrl: './unaccompanied-minors.component.html',
  styleUrls: ['./unaccompanied-minors.component.scss']
})
export class UnaccompaniedMinorsComponent implements OnInit {
  @Input() ticket;

  constructor() { }

  ngOnInit(): void {
  }

}
