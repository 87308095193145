<h2 mat-dialog-title align="center">{{'PHONE_SUPPORT'|translate}}</h2>
<mat-dialog-content class="text-center">
  <div class="mb-3">
    <span style="color:#fa7d33;font-size:36px" class="material-icons">phone</span>
  </div>
  <div [innerHTML]="html"></div>
  <hr/>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <div class="buttonrow">
    <button mat-button mat-dialog-close>{{'CLOSE'|translate}}</button>
  </div>
</mat-dialog-actions>
