<custom-title></custom-title>
<div class="userlist padding20">

  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>
  <div class="searchrow" *ngIf="!loading">
    <div class="row buttonrow">
      <div class="col-lg-3 align-self-center filtercol pl-3">
        <mat-form-field class="full-width">
          <mat-label>{{'SEARCH_TICKET'|translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="query.text" (change)="setFilterText()">
          <button *ngIf="query.text" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilterText()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-hint align="start">{{'DIGIT_YOUR_SEARCH'|translate}}</mat-hint>
        </mat-form-field>
      </div>

      <div class="col-lg-3 align-self-center filtercol pl-3">
        <mat-form-field class="full-width">
          <mat-label>{{'REQUEST_TYPE' | translate}}</mat-label>
          <mat-select [(ngModel)]="filter" name="Filter" (ngModelChange)="this.getTicketsList()">
            <mat-option *ngFor="let f of filterTypes" [value]="f" >{{ f | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-lg-6 d-flex justify-content-end text-right" style="box-shadow: none">
        <div class="paginationr pr-3 text-right">
          <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
          <span>
            {{'PAGE'|translate}}
            <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
              <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p+1}}</mat-option>
              </mat-select>
            </mat-form-field>
            {{'OF'|translate}} {{maxpages}}
          </span>
          <button class="iconButtons" mat-icon-button (click)="prev()" [disabled]="currentpage==0">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button class="iconButtons" mat-icon-button (click)="next()" [disabled]="currentpage==(maxpages-1)">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="" *ngIf="ticketslist">
    <div *ngIf="progress">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="tablelist withscroll">
      <table mat-table [dataSource]="ticketslist.list">

        <!-- Position Column -->
        <!--
        <ng-container matColumnDef="avatar">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <img class="listimage" [src]="element.image"/>

          </td>
        </ng-container>
        -->

        <ng-container matColumnDef="subject" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <span class="sorting tablepadding" matRipple matRippleCentered="true" (click)="sortValue('subject')">{{'SUBJECT'|translate}}</span>
            <mat-icon *ngIf="query.sort==='subject' && query.order==='asc'">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="query.sort==='subject' && query.order==='desc'">keyboard_arrow_up</mat-icon>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="tablepadding" [ngClass]="{'toRead':!element.read[type]}" matTooltip="{{truncateObj(element.object)}}">
              <a routerLink="/ticketing/list/{{element._id}}">{{truncateObj(element.object)}}</a>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>
            <span class="sorting" matRipple matRippleCentered="true" (click)="sortValue('createdAt')">{{'CREATION'|translate}}</span>
            <mat-icon *ngIf="query.sort==='createdAt' && query.order==='asc'">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="query.sort==='createdAt' && query.order==='desc'">keyboard_arrow_up</mat-icon>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.createdAt|date:'dd/MM/yyyy - HH:mm'}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="update">
          <th mat-header-cell *matHeaderCellDef> {{'LAST_UPDATE'|translate}} </th>
          <td mat-cell *matCellDef="let element"> <span matTooltip="{{element.updatedAt|date:'dd/MM/yyyy - HH:mm'}}">{{element.updatedAt|date:'dd/MM/yyyy - HH:mm'}}</span> </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef> {{'USER'|translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element?.user.surname}} {{element?.user.name}} </td>
        </ng-container>

        <!-- Star Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>

          </th>
          <td mat-cell *matCellDef="let element">
            <span class="material-icons" *ngIf="element.closed">
              lock
            </span>
            <span class="material-icons" *ngIf="!element.closed">
              lock_open
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <div class="padding20 text-center noresult" *ngIf="!loading && ticketslist.list.length==0">
    <mat-icon style="font-size:40px" class="marginbottom10">sentiment_very_dissatisfied</mat-icon>
    <h3>{{'NO_RESULTS'|translate}}.</h3>
    <p *ngIf="text">{{'NO_RESULTS_TEXT'|translate}} <span class="searchtext">{{text}}</span>.</p>
    <p *ngIf="text">{{'NO_RESULTS_TRY_AGAIN'|translate}}.</p>
  </div>
  <div class="card" *ngIf="!loading">
    <div class="paginationr pr-3 justify-content-end" style="box-shadow: none">
      <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>

        <span>
          {{'PAGE'|translate}}
          <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
            <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
              <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p+1}}</mat-option>
            </mat-select>
          </mat-form-field>
          {{'OF'|translate}} {{maxpages}}
        </span>

      <button class="iconButtons" mat-icon-button (click)="prev()" [disabled]="currentpage==0">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button class="iconButtons" mat-icon-button (click)="next()" [disabled]="currentpage==(maxpages-1)">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>

</div>
