import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, finalize, skipWhile, switchMap, tap } from 'rxjs/operators';
import { SearchTrainsResponse, StationResponse, TrainTravel, TrenitTraveller } from '../../../classes/train.models';
import * as $ from 'jquery';
import { TrainMainComponent } from '../../common/train-main/train-main.component';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TrainService } from '../../../train.service';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { CompaniesService } from '../../../../../services/profile/companies.service';
import { MainComponent } from '../../../../mainmodule/main/main.component';

/**
 * main component for handling search parametrs
 */
@Component({
  selector: 'train-search-trains',
  styleUrls: ['./train-search-trains.component.scss'],
  templateUrl: './train-search-trains.component.html',
})
export class TrainSearchTrainsComponent extends TrainMainComponent implements AfterViewInit{
    @Output() toggle = new EventEmitter<any>();
    @Output() nextStepEvent = new EventEmitter<string>();
    @Input() travellers : any;
    @ViewChild('auto_dep') matAutocompleteDep : MatAutocomplete;
    @ViewChild('auto_arr') matAutocompleteArr : MatAutocomplete;


  // Autocomplete
  isDepartureStationLoading = false;
  isArrivalStationLoading = false;
  filteredDepartureStation : StationResponse[];
  filteredArrivalStation : StationResponse[];

    // Form
  searchForm : FormGroup;
  departureStartDate$ = new BehaviorSubject('');
  returnStartDate$ = new BehaviorSubject('');
  ar = false;

  // Search
  searching$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  searchResults : SearchTrainsResponse;
  filterButton : boolean;
  isVenice : boolean;
  usePitcode = false;
  pitCode: string;
  pitCodes: any[];

  /**
   * @param fb FormBuilder
   * @param service TrainService instance for http cals
   * @param router
   * @param titleService
   * @param translate
   * @param mainComponent
   * @param api
   */
  constructor(
      private fb : UntypedFormBuilder,
      private service : TrainService,
      public router : Router,
      public titleService : Title,
      public translate : TranslateService,
      public mainComponent: MainComponent,
      private api : CompaniesService,
  ){
      super(router, titleService, translate);
      this.filterButton = false;
  }

  /**
   * conditional validator for reactive form field "return_Date"
   * the field is mandatory only if roundtrip is true
   */
  private static conditionalReturnDateValidator(formControl : AbstractControl){
    return (formControl && formControl.parent && formControl.parent.get('roundTrip').value) ? Validators.required(formControl) : null;
  }

  override onInit() : void{
    this.initSearchForm();
  }

    ngAfterViewInit(){
        this.searchForm.patchValue(this.currentSearch);
      this.isVenice = this.currentSearch.isVenice;
        if (this.trainTravel?.travellers){
            this.travellers = JSON.parse(JSON.stringify(this.trainTravel?.travellers));
        }
        /*if (this.pax){
          this.travellers = this.pax.map(p => {
            return { ...p, ...new TrenitTraveller(p._id || p.xmlId) };
          });
        }*/
    }

  toggleSearchForm(event?){
    this.toggle.emit(event);
  }

  removePax(index : number){
    this.travellers.splice(index, 1);
  }

  initSearchForm(reset : boolean = false){
    if (reset){
      this.currentSearch = {};
      this.updateCurrentSearch();
    }
    this.searchForm = this.fb.group({
      departure_station: [undefined, [Validators.required]],
      arrival_station: [undefined, [Validators.required]],
      departure_date: [new Date().toISOString(), [Validators.required]],
      return_date: [undefined, [TrainSearchTrainsComponent.conditionalReturnDateValidator.bind(this)]],
      roundTrip: [false, []],
      use_pit: [false, []],
      pit_code: [undefined, []],
    });
    this.departureStartDate$.next(new Date().toISOString());
    const companyId = this.mainComponent.loggedUser.company._id;
    this.api.getCorporateCode('train', companyId).subscribe(data =>{
        /*
      this.pitCode = data.list.find(m => m.name === 'PIT_CODE')?.code
      if (this.pitCode) this.searchForm.patchValue({ pit_code: this.pitCode });
      */
      this.pitCodes = data.list;
      if(this.pitCodes.length>0){
        this.searchForm.patchValue({ pit_code: this.pitCodes[0].code });
      }
    });
    this.initStationSearch('departure_station');
    this.initStationSearch('arrival_station');
    this.searchForm.valueChanges.subscribe((val) => {
      if (!val.roundTrip && this.ar !== val.roundTrip){
          this.ar = val.roundTrip;
          this.searchForm.patchValue({ return_date: null });
      }
      this.ar = val.roundTrip;
      if (val.roundTrip && val.return_date === null){
        this.onDepartureReturnDateChange(null);
      }
    });
  }

  displayFn(station : StationResponse) : string{ return (station) ? station.name : ''; }

  /**
   * @param $event Submit event
   * send the search params to the server
   */
  onSubmit($event?) : void{
    this.travellers.forEach(t => {
      if (!t.xmlId){
        t.xmlId = t._id;
      }
      if (!t._id){
        t._id = t.xmlId;
      }
    });
    this.trainTravel = {
      ...new TrainTravel(),
      travellers: this.travellers,
    };
    super.updateTrainTravel();
    const searchTrainRequest = this.searchForm.getRawValue();
    searchTrainRequest.carnetId = searchTrainRequest.carnetId?.pnr;
    searchTrainRequest.arrival_station = searchTrainRequest.arrival_station?.id;
    searchTrainRequest.departure_station = searchTrainRequest.departure_station?.id;
    searchTrainRequest.adults = this.travellers.filter(t => {if (t.type === 'ADULT' || t.type === 'ADT'){ return t;}}).length;
    searchTrainRequest.children = this.travellers.filter(t => {if (t.type === 'CHILDREN' || t.type === 'CHD'){return t;}}).length;
    this.searching$.next(true);
    this.filterButton = false;
    this.service.searchTrains(searchTrainRequest).subscribe((response : SearchTrainsResponse) => {
      this.searching$.next(false);
      this.filterButton = true;
      this.searchResults = response;
      this.currentSearch = this.searchForm.getRawValue();
      this.currentSearch.isVenice = this.isVenice;
      super.updateCurrentSearch();
    }, _ => this.searching$.next(false));
  }

  togglePaxModal(){
    $('#paxModal').modal('toggle');
  }

  /**
   * Ensures the consitency between departure and arrival date for roundTrip travels
   */
  onDepartureReturnDateChange(e? : Event | any) : void{
    let departureDate : any = new Date(this.searchForm.controls.departure_date.value).toISOString();
    let returnDate: any = new Date(this.searchForm.controls.return_date.value).toISOString();
    /*console.log('departureDate => ', departureDate);*/
    if (this.searchForm.controls.return_date.value !== null){
      if (departureDate > returnDate){
        this.searchForm.patchValue({
          return_date: departureDate,
        });
      }
    } else if (this.ar && this.searchForm.controls.departure_date.value){
      /*this.returnStartDate$.next(departureDate);*/
      this.searchForm.patchValue({
        return_date: departureDate,
      });
    }
    this.returnStartDate$.next(departureDate);
  }

  isFormValid(){
    return this.searchForm?.valid && this.travellers.length > 0 && this.travellers.every(t => t.name && t._id);
  }

  travellersLoad(){
    return this.travellers.length > 0 && this.travellers.every(t => t.name);
  }

  resetForm(){
    this.resetStorage();
    this.initSearchForm(true);
    this.travellers.splice(0, this.travellers.length);
    this.isVenice = false;
    delete this.searchResults;
  }

  toggleFilters(){
    $('.filterSearch').toggleClass('openfilters');
    $('.filtersearchBackdrop').toggleClass('openfiltersearchBackdrop');
  }

  private initStationSearch(field : string) : void{
    this.searchForm.get(field).valueChanges.pipe(
      debounceTime(500),
      skipWhile((val) => val.toString() === '[object Object]' || val.toString() === ''),
      tap(() => {
        switch(field){
          case 'arrival_station':
            this.isArrivalStationLoading = true;
            break;
          case 'departure_station':
            this.isDepartureStationLoading = true;
            break;
        }
      }),
      switchMap((value) => {
        if (typeof value === 'object'){
            return of([null]);
        } else {
          return this.service.getStations(value)
            .pipe(
              finalize(() => {
                switch(field) {
                  case 'arrival_station':
                    this.isArrivalStationLoading = false;
                    break;
                  case 'departure_station':
                    this.isDepartureStationLoading = false;
                    break;
                }
              })
            );
        }
      })).subscribe((res : StationResponse[]) => {
        switch(field){
          case 'arrival_station':
            this.filteredArrivalStation = res;
            break;
          case 'departure_station':
            this.filteredDepartureStation = res;
            break;
        }
      });
  }

  selectFirst(departure : boolean){
    const activeOption = this.matAutocompleteDep.options.find(o => o.active) || this.matAutocompleteArr.options.find(o => o.active);
    if (activeOption){
      if (departure){
        this.searchForm.patchValue({ departure_station: activeOption.value });
      } else{
        this.searchForm.patchValue({ arrival_station: activeOption.value });
      }
    }
  }

  selectOption(station : any, departure: boolean){
    if (departure){
      this.searchForm.patchValue({ departure_station: station });
    } else{
      this.searchForm.patchValue({ arrival_station: station });
      this.checkIfVenice(station)
    }
  }

  checkIfVenice(arrivalStation : any){
    if (!(new Date() >= new Date('2024-7-14'))){
      if(arrivalStation.id.toString() === "63403b07aacb370db3b4c4f9" || arrivalStation.name.toUpperCase().startsWith('VENEZIA')) this.isVenice = true;
      else this.isVenice = false;
    } else {
      this.isVenice = false;
    }
  }

}
