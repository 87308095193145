import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material/material.module';
import {JwtModule} from '@auth0/angular-jwt';
import {jwtTokenGetter} from '../login/login.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: ['localhost'],
        disallowedRoutes: ['localhost/auth/login']
      }
    }),
  ]
})
export class SinglesinginModule { }
