<div class="padding20 position-relative">
  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>
  <div *ngIf="booking" class="absoluteLoading">
    <div class="text-center padding20">
      <mat-progress-bar mode="buffer" class="mb-5"></mat-progress-bar>
      <h5>{{'BOOKING'|translate}}...</h5>
    </div>
  </div>
  <div class="cardPnr marginbottom10" *ngIf="!loading && reservation">
    <div class="padding20">
      <div class="row">
        <div class="col-md-4 align-self-center">
          <div class="reservationimage">
            <img class="mb-2" style="margin:0" src="/assets/img/airline/icon/{{reservation.marketingCarrier}}.png">
            <div style="text-transform:uppercase;">{{iataComplete.returnCarrier(reservation.marketingCarrier)}}</div>
          </div>
          <div class="reservationPrice marginbottom5" *ngIf="!mainComponent.appcomponent.isB2B">
            <span class="recoprice">
                {{'FARE'|translate}} {{reservation.fare.amount|number:'.2-2'}} <span>{{reservation.fare.currency}}</span>
              </span>

          </div>
          <div *ngIf="luggageAmount > 0">{{'BAGGAGES'|translate}} {{luggageAmount}} {{reservation.fare.currency}}</div>
          <div *ngIf="returnSeatsAmount() > 0">{{'SEAT_MAP'|translate}}: {{returnSeatsAmount()| number: '.2-2'}} {{reservation.fare.currency}}</div>
          <div >

            <span class="recoprice" *ngIf="viewTotal && !priceChanged">
                {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.amount + (luggageAmount||0) + (reservation.fare?.services||0) + returnSeatsAmount())|number:'.2-2'}} {{reservation.fare.currency}}
            </span>
            <span class="recoprice" *ngIf="priceChanged">
                {{'TOTAL'|translate}} {{(reservation.fare.amount + newSeatPrice + newLuggagePrice ) |number:'.2-2'}} {{reservation.fare.currency}}
            </span>
            <span *ngIf="reservation.lowCost && !appComponent.isSbt && !appComponent.isGat && !reservation.company.cauDK">
                + 6.00€ fee
                <span class="material-icons smaler" matTooltip="{{'LOWCOSTFEE' | translate}}">
                 info
                </span>
              </span>
          </div>

        </div>
        <div class="col-md-4 align-self-center mb-2">
          <div class="pnrstatus">
            <span class="init" *ngIf="reservation.status==='init'">{{'TEMPLATE'|translate}}</span>
            <span class="linked" *ngIf="reservation.status==='linked'">{{'LINKED'|translate}}</span>
            <span class="issued" *ngIf="reservation.status==='issued'">{{'ISSUED'|translate}}</span>
            <span class="flown" *ngIf="reservation.status==='flown'">{{'FLOWN'|translate}}</span>
            <span class="expired" *ngIf="reservation.status==='expired'">{{'EXPIRED'|translate}}</span>
            <span class="cancelled" *ngIf="reservation.status==='cancelled'">{{'CANCELLED'|translate}}</span>

          </div>
          <div>

          </div>
        </div>
        <div class="col-md-4 align-self-center companydetails">
          <div><a routerLink="/company-profile/{{reservation.company._id}}">{{reservation.company.name}}</a></div>
          <div>
            <mat-icon>subdirectory_arrow_right</mat-icon>
            <a routerLink="/users/list/{{reservation.user._id}}">{{reservation.user.surname}} {{reservation.user.name}}</a>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-md-4 align-self-center mb-2">
          <div class="travelinfo">
            <div *ngFor="let itinerary of reservation.itineraries;let l = last" [ngClass]="{'marginbottom5':!l}">
              <div class="pnrdeparture">
                {{itinerary.flights[0].departure.dateTime|date:'dd/MM/yyyy':'+0000'}} -
                {{itinerary.flights[0].departure.hours}}:{{itinerary.flights[0].departure.minutes}}
              </div>
              <span class="locationspan" [matTooltip]="itinerary.flights[0].departure.location">
                {{iataComplete.returnAirport(itinerary.flights[0].departure.location)}} <sup>{{itinerary.flights[0].departure.location}}</sup>
              </span>
              <mat-icon>arrow_right_alt</mat-icon>
              <span class="locationspan" [matTooltip]="itinerary.flights[itinerary.flights.length-1].arrival.location">
                {{iataComplete.returnAirport(itinerary.flights[itinerary.flights.length-1].arrival.location)}} <sup>{{itinerary.flights[itinerary.flights.length-1].arrival.location}}</sup>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-4 align-self-center">
          <div *ngIf="reservation.passengers.length>0">
            <div *ngFor="let passenger of reservation.passengers" class="listpnrsinglepasseng">
              <span *ngIf="passenger.surname">{{passenger.surname}}</span> <span *ngIf="passenger.name"> {{passenger.name}}</span> <sup> ({{passenger.type}})</sup>
            </div>
          </div>
        </div>
        <div class="col-md-4 align-self-center pnrActionsButtons">
          <button color="primary" [class]= "{canbuy:canBook()}"[disabled]="!canBook() || !travelerController() || booking" (click)="processTermLowCost()" mat-button>{{'BUY_RESERVATION'|translate}}</button>
          <div class="smalltext text-danger" *ngIf="required.length > 0">
            <app-required-parameters [title]="'REQUIRED_FIELDS_PER_PASSENGERS'|translate" [passengers]="reservation.passengers" [requiredList] ="paramsToCheck" [cardsRequired]="true" [cardSelected]="this.checkFop()" >

            </app-required-parameters>
            <!--
            {{}}:<br/>
            <div>- {{'SEX'|translate}}</div>
            <div *ngFor="let req of required[0]">
              <span *ngIf="req.IsOptional === 'false'">
                - {{req.Name|translate}}
              </span>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pnrPagination" *ngIf="!loading && reservation">
    <div (click)="setTab(i)" class="singleVoice" [ngClass]="{'active':tab.active, 'bg-warning':tab.name==='PAYMENT' && !checkFop(),'bg-light':tab.name==='PAYMENT' && checkFop() }" *ngFor="let tab of tabs; let i = index" [hidden]="!reservation.passengers[0].name && (tab.name==='BAGGAGES' || tab.name === 'SEAT_MAP')">
      <mat-icon *ngIf="tab.name==='ITINERARY'">flight</mat-icon>
      <mat-icon *ngIf="tab.name==='FARE'">attach_money</mat-icon>
      <mat-icon *ngIf="tab.name==='PASSENGERS'">directions_walk</mat-icon>
      <mat-icon *ngIf="tab.name==='SEAT_MAP'">airline_seat_recline_normal</mat-icon>
      <mat-icon *ngIf="tab.name==='BAGGAGES'">work_outline</mat-icon>
      <mat-icon *ngIf="tab.name==='ANCILLARY'">surfing</mat-icon>
      <mat-icon *ngIf="tab.name==='PAYMENT'">credit_card</mat-icon>

      <span class="tabname"> {{tab.name|translate}}</span>
    </div>
  </div>

  <div class="card marginbottom20 padding10" *ngIf="!loading && reservation">
    <div *ngIf="currentTab=='ITINERARY'">
      {{'ITINERARY'|translate}}
      <hr/>
      <div class="travelDetails">
        <div class="mb-3" *ngFor="let itinerary of reservation.itineraries; let i = index;let la = last;">
          <div [ngClass]="{'nextExist':i<reservation.itineraries.length && i!=reservation.itineraries.length-1}">

            <div class="introItinerary">
              <p *ngIf="itinerary.flights.length>0" >
                {{itinerary.flights[0].departure.dateTime|date:'EEE dd MMM yyyy'}}
              </p>
              {{iataComplete.returnAirport(itinerary.flights[0].departure.location)}}
              <mat-icon>arrow_right_alt</mat-icon>
              {{iataComplete.returnAirport(itinerary.flights[itinerary.flights.length-1].arrival.location)}}
              <div class="flightCabin marginbottom10">
                <span *ngIf="reservation.cabins[i] === 'Y'">Economic</span>
                <span *ngIf="reservation.cabins[i]=='M'">Economic standard</span>
                <span *ngIf="reservation.cabins[i]=='W'">Economic premium</span>
                <span *ngIf="reservation.cabins[i]=='C'">Business</span>
                <span *ngIf="reservation.cabins[i]=='F'">First</span>
              </div>
            </div>

            <div class="row">
              <div class="col-12 collapse show" id="itinerary{{i}}">
                <div class="row">
                  <div class="col-sm-1 d-sm-inline-block d-none text-center align-self-center">
                    <img class="marketingcarrierimage" src="assets/img/airline/icon/{{reservation.marketingCarrier}}.png"/>
                  </div>
                  <div class="col-3 align-self-center">
                    {{iataComplete.returnAirport(itinerary.flights[0].departure.location)}}<sup>{{itinerary.flights[0].departure.location}}</sup><br/>
                    {{itinerary.flights[0].departure.dateTime|date:'dd/MM/yyyy':'+0000'}} -
                    {{returnZeroTime(itinerary.flights[0].departure.hours)}}:
                    {{returnZeroTime(itinerary.flights[0].departure.minutes)}}
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <div>{{returnTime(itinerary.flyingTime)}}</div> -->
                    <div>
                      <span *ngIf="itinerary.flights.length>1">
                        {{itinerary.flights.length-1}} {{'STOPS'|translate}}
                      </span>
                      <span *ngIf="itinerary.flights.length==1">
                        {{'NON_STOPS'|translate}}
                      </span>
                    </div>
                    <div>
                      {{returnMilli(itinerary.flyingTime)}}
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    {{iataComplete.returnAirport(itinerary.flights[itinerary.flights.length-1].arrival.location)}}<sup>{{itinerary.flights[itinerary.flights.length-1].arrival.location}}</sup><br/>
                    {{itinerary.flights[itinerary.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy':'+0000'}} -
                    {{returnZeroTime(itinerary.flights[itinerary.flights.length-1].arrival.hours)}}
                    {{returnZeroTime(itinerary.flights[itinerary.flights.length-1].arrival.minutes)}}
                  </div>

                  <div class="col-3 col-sm-2 align-self-center">
                    <button mat-icon-button (click)="opeSect(i)">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-12 collapse issection" id="collapse{{i}}">

                <div class="row" *ngFor="let flight of itinerary.flights; let f = index;let fl = last;">

                  <div class="col-12 mb-2" *ngIf="f===0">
                    <div class="row">
                      <div class="col-1 text-center">
                        <img class="marketingcarrierimage" src="assets/img/airline/icon/{{reservation.marketingCarrier}}.png"/>
                      </div>
                      <div class="col-9 align-self-center">
                        <div class="md-titlebis">{{'DEPARTURE'|translate}}: {{flight.departure.dateTime | date:'dd MMM yyyy'}}</div>
                        <div class="md-subhead">{{returnMilli(itinerary.flyingTime)}}</div>
                      </div>
                      <div class="col-2">
                        <button mat-icon-button (click)="opeSect(i)">
                          <mat-icon>keyboard_arrow_up</mat-icon>
                        </button>
                      </div>
                    </div>
                    <hr/>
                  </div>

                  <div class="col-md-1 text-center">
                    <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
                  </div>
                  <div class="col-md-11">
                    <div class="flightdet">
                      <p class="headingseg">
                        {{flight.departure.date | date:'dd/MM/yyyy':'+0000'}} -
                        {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.departure.location)}} <sup>{{flight.departure.location}}</sup>
                      </p>
                      <p class="headingdetails">
                        <!-- {{'FLIGHT_TIME'|translate}}: HH MM -->
                        <span *ngIf="flight.departure.terminal"> - Terminal: {{flight.departure.terminal}}</span>
                      </p>
                      <p class="headingseg">
                        <!--
                      <span class="redt" *ngIf="otherday(flight.departure.date | date:'dd',flight.arrival.date | date:'dd')">
                        {{flight.arrival.date | date:'dd MMM yyyy'}} -
                      </span> -->
                        {{flight.arrival.date | date:'dd/MM/yyyy':'+0000'}} -
                        {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.arrival.location)}} <sup>{{flight.arrival.location}}</sup>
                      </p>
                      <p class="fldetails">
                        {{flight.operatingCarrier}} {{flight.number}} <span *ngIf="flight.detail && flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
                        <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>
                        <span *ngIf="flight.status">
                          | status: {{flight.status}}
                        </span>
                      </p>
                    </div>

                    <div *ngIf="flight.oldFlight" class="flightdet oldFlight">
                      <p class="headingseg">
                        {{flight.oldFlight.departure.date | date:'dd/MM/yyyy':'+0000'}} -
                        {{returnZeroTime(flight.oldFlight.departure.hours)}}:{{returnZeroTime(flight.oldFlight.departure.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.oldFlight.departure.location)}} <sup>{{flight.oldFlight.departure.location}}</sup>
                      </p>
                      <p class="headingdetails">
                        <!-- {{'FLIGHT_TIME'|translate}}: HH MM -->
                        <span *ngIf="flight.oldFlight.departure.terminal"> - Terminal: {{flight.oldFlight.departure.terminal}}</span>
                      </p>
                      <p class="headingseg">
                        <!--
                      <span class="redt" *ngIf="otherday(flight.oldFlight.departure.date | date:'dd',flight.oldFlight.arrival.date | date:'dd')">
                        {{flight.oldFlight.arrival.date | date:'dd MMM yyyy'}} -
                      </span> -->
                        {{flight.oldFlight.arrival.date | date:'dd/MM/yyyy':'+0000'}} -
                        {{returnZeroTime(flight.oldFlight.arrival.hours)}}:{{returnZeroTime(flight.oldFlight.arrival.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.oldFlight.arrival.location)}} <sup>{{flight.oldFlight.arrival.location}}</sup>
                      </p>
                      <p class="fldetails">
                        {{flight.oldFlight.operatingCarrier}} {{flight.oldFlight.number}} <span *ngIf="flight.oldFlight.detail && flight.oldFlight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.oldFlight.detail.equipment}}</span>
                        <span *ngIf="flight.oldFlight.arrival.terminal"> - Terminal: {{flight.oldFlight.arrival.terminal}}</span>
                        <span *ngIf="flight.oldFlight.status">
                          | status: {{flight.oldFlight.status}}
                        </span>
                      </p>
                    </div>

                    <div class="scalepause" *ngIf="!fl">
                    <span>
                      <i class="fas fa-random"></i> {{'STOP'|translate}} in {{iataComplete.returnAirport(flight.arrival.location)}} <sup>{{flight.arrival.location}}</sup>
                      <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}} </span>
                      <span *ngIf="!flight.waitingTime"> {{'WAITING'|translate}}: {{calculateWaiting(flight.arrival.dateTime, itinerary.flights[f+1].departure.dateTime)}} </span>
                    </span>
                      <span class="changeairport" *ngIf="flight.arrival.location != itinerary.flights[f+1].departure.location">
                      {{'CHANGE'|translate}}:
                        {{iataComplete.returnAirport(itinerary.flights[f+1].departure.location)}} <sup>{{itinerary.flights[f+1].departure.location}}</sup></span>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div *ngIf="currentTab=='FARE'" class="marginbottom20 padding10">
      {{'FARE'|translate}}
      <hr/>
      <div *ngIf="this.tarifLowData.length === 0" class="row mt-2">
        <h3 class="text-center">{{'NO_FARE' | translate}}</h3>
      </div>
      <div *ngIf="this.tarifLowData.length > 0" class="mt-2 row marginbottom20 padding10">
        <div *ngFor="let t of this.tarifLowData; let i = index;" class="col-6 pb-2">
          <div *ngIf="i === 0" class="text-center pb-2 mb-2">{{'POST_BOOKING.OUTWARD_TARIF'|translate}}</div>
          <div *ngIf="i === 1" class="text-center pb-2 mb-2">{{'POST_BOOKING.OUT_RET_TARIF'|translate}}</div>
            <div class="col-12 card h-100">
              <div  class="p-2 selectedTarifsCard">
                <div>
                  <h4 class="text-center">{{t.supplier}}</h4>
                  <h4 class="text-center">{{t.tarif.name}}</h4>
                  <h4 *ngIf="t.tarif.subName" class="text-left mb-2 pl-2">{{t.tarif.subName}}</h4>
                  <hr style="color: #0c5593; height: 2px;">
                  <div *ngFor="let d of t.tarif.details" class="row">
                    <div class="col-12 text-left pl-2">
                      <mat-icon>{{d.icon || ''}}</mat-icon>
                      <span class="pl-2 mt-2">
                        {{d.name}}
                        {{d.description}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div *ngIf="currentTab=='PASSENGERS'">
      {{'PASSENGERS'|translate}}
      <hr/>
      <div class="row">
        <div class="col-md-6 col-12" *ngFor="let passenger of reservation.passengers; let i = index">
          <div class="fakefield mb-2">
            {{'NAME'|translate}}:
            <span *ngIf="passenger.name"> {{passenger.name}}</span>
            <span *ngIf="!passenger.name"> _ _ _ _</span>
          </div>
          <div class="fakefield mb-2">
            {{'SURNAME'|translate}}:
            <span *ngIf="passenger.surname"> {{passenger.surname}}</span>
            <span *ngIf="!passenger.surname"> _ _ _ _</span>
          </div>
          <div class="fakefield mb-2">
            {{'PHONE'|translate}}:
            <span *ngIf="passenger.phoneNumber"> {{passenger.phoneNumber}}</span>
            <span *ngIf="!passenger.phoneNumber"> _ _ _ _</span>
          </div>
          <div class="fakefield mb-2">
            {{'EMAIL'|translate}}:
            <span *ngIf="passenger.email"> {{passenger.email}}</span>
            <span *ngIf="!passenger.email"> _ _ _ _</span>
          </div>

          <div class="buttonrow">
            <button mat-stroked-button *ngIf="!passenger.name || !passenger.surname" (click)="toggleDialog(i)">{{'ADD_TRAVELER'|translate}}</button>
            <button mat-stroked-button *ngIf="passenger.name || passenger.surname" (click)="removeTraveler(i)">{{'REMOVE_TRAVELER'|translate}}</button>
            <button mat-stroked-button *ngIf="passenger.name || passenger.surname" (click)="openEdit(i)">{{'EDIT_TRAVELER'|translate}}</button>
            <button mat-stroked-button *ngIf="!passenger.name && !passenger.surname"  (click)="loadTravelersList(i)">{{'LOAD_TRAVELER'|translate}}</button>
          </div>
        </div>
      </div>

      <div class="modal fade" id="EditTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

            <div *ngIf="currentTraveler!=undefined && editingTraveler" class="modal-body">
              <app-edittraveler (close)="closeEditEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [currentTraveler]="reservation.passengers[currentTraveler]" [parent]="'pnrPage'"></app-edittraveler>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="addTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

            <div *ngIf="currentTraveler!=undefined" class="modal-body">
              <app-add [trDate]="reservation.itineraries[reservation.itineraries.length-1].flights[reservation.itineraries[reservation.itineraries.length-1].flights.length-1].departure.date" [newTraveler]="reservation.passengers[currentTraveler]" [parent]="'searchResults'" (close)="closeEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)"></app-add>
            </div>

          </div>
        </div>
      </div>

      <div class="modal fade" id="listTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content" *ngIf="loadTravelers">

            <div *ngIf="currentTraveler!=undefined" class="modal-body">
              <app-travelerslist
                [trDate]="reservation.itineraries[reservation.itineraries.length-1].flights[reservation.itineraries[reservation.itineraries.length-1].flights.length-1].departure.date"
                [newTraveler]="reservation.passengers[currentTraveler]" [passengers]="reservation.passengers" [page]="'searchResults'" (close)="closeListEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)"></app-travelerslist>
              <hr/>
              <div class="buttonrow text-right">
                <button mat-stroked-button (click)="closeListEvent($event)">{{'CLOSE'|translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentTab=='BAGGAGES'">
      {{'BAGGAGES'|translate}}
      <hr/>
      <app-luggages *ngIf="reservation.luggages" [required]="required" [reservation]="reservation" (onluggageAmount)="setLuggage($event)"></app-luggages>
    </div>

    <div *ngIf="currentTab=='SEAT_MAP'">
      {{'SEAT_MAP'|translate}}
      <hr/>
      <!-- includere qui il componente che visualizza la seatmap -->
      <app-seatmapLowCost  [passengers]="reservation.passengers" [flights]="flights" [seatmap]="seatmap" (someUpdate)= "saveSeats($event)"> </app-seatmapLowCost>
    </div>

    <div *ngIf="currentTab=='ANCILLARY'">
      {{'ANCILLARY'|translate}}
      <hr/>
    </div>

    <div *ngIf="currentTab=='PAYMENT'">
      {{'PAYMENT'|translate}}
      <hr/>
      <!--
      <div>
        <div class="row">
          <div class="col-lg-6">

            <div *ngIf="newFop.type==='CC'">
              <mat-form-field class="full-width">
                <mat-label>{{'CARD_CIRCUIT'|translate}}</mat-label>
                <mat-select [(ngModel)]="newFop.card.type">
                  <mat-option value="AX" *ngIf="includedCard('americanexpress')">American Express</mat-option>
                  <mat-option value="DC" *ngIf="includedCard('diners') || includedCard('dinersclub')">Diners</mat-option>
                  <mat-option value="CA" *ngIf="includedCard('mastercard')">Master Card</mat-option>
                  <mat-option value="VI" *ngIf="includedCard('visa') || includedCard('visacredit')">Visa</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="small-width">
                <mat-label>{{'CARD_MONTH_EXPIRATION'|translate}}</mat-label>
                <mat-select [(ngModel)]="newFop.card.expirationMonth">
                  <mat-option value="01">01</mat-option>
                  <mat-option value="02">02</mat-option>
                  <mat-option value="03">03</mat-option>
                  <mat-option value="04">04</mat-option>
                  <mat-option value="05">05</mat-option>
                  <mat-option value="06">06</mat-option>
                  <mat-option value="07">07</mat-option>
                  <mat-option value="08">08</mat-option>
                  <mat-option value="09">09</mat-option>
                  <mat-option value="10">10</mat-option>
                  <mat-option value="11">11</mat-option>
                  <mat-option value="12">12</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="small-width">
                <mat-label>{{'CARD_YEAR_EXPIRATION'|translate}}</mat-label>
                <mat-select [(ngModel)]="newFop.card.expirationYear">
                  <mat-option value="20">20</mat-option>
                  <mat-option value="21">21</mat-option>
                  <mat-option value="22">22</mat-option>
                  <mat-option value="23">23</mat-option>
                  <mat-option value="24">24</mat-option>
                  <mat-option value="25">25</mat-option>
                  <mat-option value="26">26</mat-option>
                  <mat-option value="27">27</mat-option>
                  <mat-option value="28">28</mat-option>
                  <mat-option value="29">29</mat-option>
                  <mat-option value="30">30</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field class="full-width">
                <input matInput maxlength="13" [(ngModel)]="newFop.card.number" placeholder="{{'CARD_NUMBER'|translate}}">
              </mat-form-field>
            </div>

            <div *ngIf="newFop.type==='CC'">
              <mat-form-field class="full-width">
                <input matInput minlength="6" [(ngModel)]="newFop.card.holder" placeholder="{{'CARD_OWNER'|translate}}">
              </mat-form-field>
            </div>
            <div *ngIf="newFop.type==='CC'">
              <mat-form-field class="small-width">
                <input matInput maxlength="3" [(ngModel)]="newFop.card.cvc" placeholder="{{'CARD_CVC'|translate}}">
                <mat-hint align="end">{{newFop.card.cvc.length}} / 3</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="col-lg-6">
            <mat-card class="mb-4">
              <mat-card-header>
                <div *ngIf="newFop.type==='CC'" mat-card-avatar class="example-header-image" [style.background]="'url(assets/img/fops/' + newFop.card.type + '.png)'"></div>
                <div *ngIf="newFop.type!=='CC'" mat-card-avatar class="example-header-image" style="background-image: url('assets/img/fops/TP.png')"></div>
                <mat-card-title class="text-right"><span *ngIf="newFop.type==='CC'">Credit card</span> <span *ngIf="newFop.type!=='CC'">(Airplus)</span></mat-card-title>
                <mat-card-subtitle>{{newFop.card.type}}</mat-card-subtitle>
                <mat-card-subtitle>
                  <span *ngIf="!newFop.card.number">_ _ _ _  _ _ _ _  _ _ _ _  _ _ _ _</span>
                  <span *ngIf="newFop.card.number">{{newFop.card.number}}</span>
                </mat-card-subtitle>
                <mat-card-subtitle>Valid thru {{newFop.card.expirationMonth}}/{{newFop.card.expirationYear}}</mat-card-subtitle>
                <mat-card-subtitle>Holder {{newFop.card.holder}}</mat-card-subtitle>
              </mat-card-header>
            </mat-card>

          </div>
        </div>
      </div>
      -->
      <!--
      <app-fops [id]="reservation.company._id" [reservation]="reservation"></app-fops>
      -->
      <mat-tab-group mat-align-tabs="start" mat-stretch-tabs="false" (selectedTabChange)="onPaymentTabChanged($event);">
        <mat-tab *ngIf="mainComponent.loggedUser.company.services.paymentMethods && !appComponent.isGat" label="{{'FOPS'|translate}}">
          <br/>
          <app-methods [id]="mainComponent.loggedUser.company._id" [reservation]="reservation" [resType]="'flight'"></app-methods>
        </mat-tab>
        <mat-tab *ngIf="mainComponent.loggedUser.company.services.creditCards" label="{{'CREDIT_CARDS'|translate}}"> <!-- && canBook() -->
          <br/>
          <app-fops [id]="mainComponent.loggedUser.company._id" [reservation]="reservation"></app-fops>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="mt-5 terminal" *ngIf="response && mainComponent.loggedUser.role.superAdmin && !appComponent.isSbt" cdkDrag> <!-- && mainComponent.loggedUser.role.superAdmin -->
    <div class="terminalcontent">
      <div class="text-right">
        <div class="example-handle" cdkDragHandle>
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
            <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>
      <pre>
        <code>required: {{required|json}}</code>
      </pre>
      <!-- <pre>
        <code>reservation.luggages: {{reservation.luggages|json}}</code>
      </pre> -->
      <pre>
        <code>requiredParameters: {{response.requiredParameters|json}}</code>
      </pre>
      <pre>
        <code>supplierHandoffData: {{response.supplierHandoffData|json}}</code>
      </pre>
    </div>
  </div>

  <div class="cardPnr marginbottom10" *ngIf="!loading && !reservation">
    <div class="padding20">
      <div class="mb-3">{{'TARIFF_IS_EXPIRED'|translate}}</div>
      <div>
        <a routerLink="/flight/search" mat-raised-button color="primary">{{'MAKE_NEW_SEARCH'|translate}}</a>
      </div>
    </div>

  </div>

</div>
