<div class="dialog">

  <h2 mat-dialog-title>
    Configure Authorization
  </h2>
  <form class="dialog__form" [formGroup]="orderForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <div class="dialog__form-group">
        <mat-radio-group class="full-width" (change)="onSelectedRadio()" formControlName="radioButton" aria-label="Select an option">
          <mat-radio-button color="primary" class="dialog__radio" value="1">Default user</mat-radio-button>
          <mat-radio-button color="primary" class="dialog__radio" value="2">Select user to request authorization</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="full-width">
            <mat-label>Select Groups</mat-label>
            <mat-select (selectionChange)="onSelectedRadio()" [disabled]="isRole" formControlName="authorization_roles"  multiple>
              <mat-option *ngFor="let role of rolesList" [value]="role._id">{{role.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="full-width">
            <mat-label>Select Users</mat-label>
            <mat-select (selectionChange)="onSelectedRadio()" [disabled]="isRole" formControlName="authorization_users"  multiple>
              <mat-option *ngFor="let user of userList" [value]="user._id">{{user.surname}} {{user.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <mat-error *ngIf="orderForm.errors?.empty">Please select at least one role or one user.</mat-error>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div class="buttonrow">
        <button mat-raised-button (click)="onNoClick()">Cancel</button>
        <button (click)="onSubmit()" mat-raised-button color="primary" [disabled]="isValid" cdkFocusInitial>Ok</button>
      </div>
    </mat-dialog-actions>
  </form>

</div>
