import {
  Component,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TicketingService } from '../../../../services/ticketing/ticketing.service';
import { TranslateService } from '@ngx-translate/core';
import { Airports } from '../../../flight/classes/airports';
import {
  debounceTime,
  finalize,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { FlightService } from '../../../../services/flight/flight.service';
import { NotifierService } from 'angular-notifier';
import { OpenTicketRequest } from '../../../../services/ticketing/api/ticketing.service.models';
import { AppComponent } from '../../../../app.component';

@Component({
  selector: 'unaccompanied-minors',
  templateUrl: './unaccompanied-minors.component.html',
  styleUrls: ['./unaccompanied-minors.component.scss']
})
export class UnaccompaniedMinorsComponent implements OnInit {
  showWarnings = true;
  childRequests : UntypedFormGroup[] = [];
  flightRequests : UntypedFormGroup[] = [];
  activeViw = 0;
  views : string[] = ['SHOW_INFO','CHILD','FLIGHT'];

  isArrivalLoading : boolean;
  isDepartureLoading : boolean;
  filteredArrival : Airports[];
  filteredDeparture : Airports[];

  private readonly notifier: NotifierService;

  constructor(
    private fb : UntypedFormBuilder,
    private ticketService : TicketingService,
    public flightService : FlightService,
    public translate: TranslateService,
    private appComponent: AppComponent,
    notifierService : NotifierService,
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.resetForm();
  }

  addChild(){
    this.childRequests.push(this.fb.group({
      name: [undefined, [Validators.required]],
      surname: [undefined, [Validators.required]],
      birthday: [undefined, [Validators.required]],
      address: [undefined, [Validators.required]],
      city: [undefined, [Validators.required]],
      postal_code: [undefined, [Validators.required]],
      language: [undefined, [Validators.required]],
      diseases: [undefined, []],
      other: [undefined, []],
    }));
  }

  addFlight(){
    const form = this.fb.group({
      departure: [undefined, [Validators.required]],
      arrival: [undefined, [Validators.required]],
      date: [new Date(), [Validators.required]],
      time_slot: ['', []],
      departure_name: [undefined, [Validators.required]],
      departure_surname: [undefined, [Validators.required]],
      departure_city: [undefined, [Validators.required]],
      departure_postal_code: [undefined, [Validators.required]],
      departure_address: [undefined, [Validators.required]],
      departure_cellular: [undefined, [Validators.required]],
      departure_phone_number: [undefined, [Validators.required]],
      departure_family: [undefined, [Validators.required]],
      arrival_name: [undefined, [Validators.required]],
      arrival_surname: [undefined, [Validators.required]],
      arrival_city: [undefined, [Validators.required]],
      arrival_postal_code: [undefined, [Validators.required]],
      arrival_address: [undefined, [Validators.required]],
      arrival_cellular: [undefined, [Validators.required]],
      arrival_phone_number: [undefined, [Validators.required]],
      arrival_family: [undefined, [Validators.required]],
    });
    this.initAirportAutocomplete(form, 'departure');
    this.initAirportAutocomplete(form, 'arrival');
    this.flightRequests.push(form);
  }

  removeChild(i : number){
    this.childRequests.splice(i, 1);
  }

  allChildrenCompleted(){
    return this.childRequests.every(cr => cr.valid);
  }

  allFlightsCompleted(){
    return this.flightRequests.every(cr => cr.valid);
  }

  private initAirportAutocomplete(form : UntypedFormGroup, field : string) : void{
    form.get(field).valueChanges.pipe(
      debounceTime(500),
      skipWhile((val) => val.toString() === '[object Object]' || val.toString() === ''),
      tap(() => {
        switch(field){
          case 'arrival':
            this.isArrivalLoading = true;
            break;
          case 'departure':
            this.isDepartureLoading = true;
            break;
        }
      }),
      switchMap((value) => {
          if (typeof value === 'object'){
            return of([null]);
          } else{
            return this.flightService.getAirportsList(value)
               .pipe(
                 finalize(() => {
                   switch(field){
                     case 'arrival':
                       this.isArrivalLoading = false;
                       break;
                     case 'departure':
                       this.isDepartureLoading = false;
                       break;
                   }
                 }),
               );
          }
        },
      ),
    )
        .subscribe((res : Airports[]) => {
            switch(field){
              case 'arrival':
                this.filteredArrival = res;
                break;
              case 'departure':
                this.filteredDeparture = res;
                break;
            }
          },
        );
  }

  displayFn(airport : Airports) : string{ return (airport) ? airport.iata+'-'+airport.name+'_'+airport.iatacity : ''; }

  private resetForm(){
    this.childRequests = [];
    this.flightRequests = [];
    this.addChild();
    this.addFlight();
  }

  sendRequest(){
    const payload = {
      flights: [...this.flightRequests.map(f => f.getRawValue())],
      children: [...this.childRequests.map(f => f.getRawValue())]
    }
    const request: OpenTicketRequest = {
      subject: 'Unaccompanied Minors',
      body: {
        payload,
        data: JSON.stringify(payload, null, 4),
      },
      section: 'flight',
      department: 'underage',
      // requestType: '10038', // Fisso il ID di Biglietteria
      component: 'unaccompanied'
    };
    this.ticketService.sendTicket(request).subscribe(res => {
      this.notifier.notify('success', this.translate.instant('TICKET_OPENED'));
      console.log('res => ', res);
      this.resetForm();
    }, error => {
      this.notifier.notify('error', this.translate.instant(error));
      console.error('Error => ', error);
    });
  }
}
