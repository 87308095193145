<div *ngIf="loading" class="loader">
  <div class="card text-center padding20">
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
    <h5>{{'LOADING'|translate}}...</h5>
  </div>
</div>

<div *ngIf="role && !loading">

  <div class="mb-4">{{'GROUP'|translate}}: {{role.name}}</div>
  <hr/>
  <form>
    <fieldset [disabled]="true">

      <div class="marginbottom20">
        <h3 class="titlecolor">
          <mat-icon aria-hidden="false" aria-label="plane">person</mat-icon> {{'USER_TYPE'|translate}}
        </h3>
        <div class="row marginbottom20">

          <div class="col-xl-4 marginbottom10">
            <h4>{{'TYPE'|translate}}</h4>
            <p>
              <i>
                {{'B2C_USER_DESCRIPTION'|translate}}
              </i>
            </p>
          </div>
          <div class="col-xl-8">
            <div class="singleslider">
              <div>
                <span *ngIf="role.customer else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'CUSTOMER'|translate}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="marginbottom20">
        <h3 class="titlecolor">
          <mat-icon aria-hidden="false" aria-label="plane">airplanemode_active</mat-icon> {{'FLIGHTS'|translate}}
        </h3>
        <!--
        <div class="row marginbottom20">

          <div class="col-xl-4 marginbottom10">
            <h4>{{'HISTORY'|translate}}</h4>
            <p>
              <i>
                {{'HISTORY_DESCR'|translate}}
              </i>
            </p>
          </div>
          <div class="col-xl-8">
            <div class="singleslider">
              <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="role.flight.search.get.company" (change)="changeFlightChrono()">
                {{'VIEW_HISTORY'|translate}}
              </mat-checkbox>
            </div>

            <div class="singleslider" *ngIf="role.flight.search.get.company">
              <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="role.flight.search.post.company">
                {{'EDIT_HISTORY'|translate}}
              </mat-checkbox>
            </div>

            <div class="singleslider" *ngIf="role.flight.search.get.company">
              <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="role.flight.search.delete.company">
                {{'DELETE_HISTORY'|translate}}
              </mat-checkbox>
            </div>

          </div>
        </div>
        -->

        <div class="row">
          <div class="col-xl-4 marginbottom10">
            <h4>{{'RESERVATIONS'|translate}}</h4>
            <p>
              <i>
                {{'RESERVATIONS_DESCR'|translate}}
              </i>
            </p>
          </div>
          <div class="col-xl-8">
            <div class="singleslider">
              <div>
                <span *ngIf="role.flight.taw.get.company else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'RESERVATIONS_VIEW'|translate}}
              </div>
            </div>

            <div class="singleslider">
              <div>
                <span *ngIf="role.flight.taw.post.options.link else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'RESERVATIONS_LINK'|translate}}
              </div>
            </div>

            <div class="singleslider">
              <div>
                <span *ngIf="role.flight.taw.post.options.issue else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'RESERVATIONS_ISSUE'|translate}}
              </div>
            </div>

            <div class="singleslider">
              <div>
                <span *ngIf="role.flight.taw.delete.options.linked else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'RESERVATIONS_DELETE'|translate}}
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="marginbottom20" *ngIf="maincomponent.loggedUser.role.superAdmin || maincomponent.loggedUser.company.type!='A'">
        <hr/>
        <h3 class="titlecolor">
          <mat-icon aria-hidden="false" aria-label="hotel">hotel</mat-icon> Hotel
        </h3>
        <!--
        <div class="row marginbottom20">

          <div class="col-xl-4 marginbottom10">
            <h4>{{'HISTORY'|translate}}</h4>
            <p>
              <i>
                {{'HISTORY_DESCR'|translate}}
              </i>
            </p>
          </div>
          <div class="col-xl-8">
            <div class="singleslider">
              <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="role.hotel.search.get.company">
                {{'VIEW_HISTORY'|translate}}
              </mat-checkbox>
            </div>

          </div>
        </div>

        -->

        <div class="row marginbottom20">

          <div class="col-xl-4 marginbottom10">
            <h4>{{'RESERVATIONS'|translate}}</h4>
            <p>
              <i>
                {{'RESERVATIONS_HT_DESCR'|translate}}
              </i>
            </p>
          </div>
          <div class="col-xl-8">
            <div class="singleslider">
              <div>
                <span *ngIf="role.hotel.taw.get.company else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'RESERVATIONS_VIEW'|translate}}
              </div>
            </div>

            <div class="singleslider">
              <div>
                <span *ngIf="role.hotel.taw.post.options.issue else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'RESERVATIONS_LINK'|translate}}
              </div>
            </div>

            <div class="singleslider">
              <div>
                <span *ngIf="role.hotel.taw.delete.options.issued else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'RESERVATIONS_DELETE'|translate}}
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="marginbottom20">
        <hr/>
        <h3 class="titlecolor">
          <mat-icon aria-hidden="false" aria-label="users">person</mat-icon> {{'USERS_COMPANY'|translate}}
        </h3>

        <div class="row marginbottom20">

          <div class="col-xl-4 marginbottom10">
            <h4>{{'USERS'|translate}}</h4>
            <p>
              <i>
                {{'USERS_DESCR'|translate}}
              </i>
            </p>
          </div>
          <div class="col-xl-8">
            <div class="singleslider">
              <div>
                <span *ngIf="role.profile.user.get.company else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'VIEW_USERS'|translate}}
              </div>
            </div>

            <div class="singleslider" *ngIf="role.profile.user.get.company">
              <div>
                <span *ngIf="role.profile.user.post.company else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'EDIT_USERS'|translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="row marginbottom20">

          <div class="col-xl-4 marginbottom10">
            <h4>{{'COMPANY'|translate}}</h4>
            <p>
              <i>
                {{'COMPANY_DESCR'|translate}}
              </i>
            </p>
          </div>
          <div class="col-xl-8">
            <div class="singleslider">
              <div>
                <span *ngIf="role.profile.company.get.company else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'VIEW_COMPANY'|translate}}
              </div>
            </div>

            <div class="singleslider" *ngIf="role.profile.company.get.company">
              <div>
                <span *ngIf="role.profile.company.post.company else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'EDIT_COMPANY'|translate}}
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="marginbottom20">
        <hr/>
        <h3 class="titlecolor">
          <mat-icon aria-hidden="false" aria-label="users">euro</mat-icon> {{'TARIFFES'|translate}}
        </h3>

        <div class="row marginbottom20">

          <div class="col-xl-4 marginbottom10">
            <h4>{{'MANAGE_CORPORATE'|translate}}</h4>
            <p>
              <i>
                {{'MANAGE_CORPORATE_DESCR'|translate}}
              </i>
            </p>
          </div>
          <div class="col-xl-8">
            <div class="singleslider">
              <div>
                <span *ngIf="role.profile.company.corporate.read else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'VIEW_CORPORATE'|translate}}
              </div>
            </div>

            <div class="singleslider" *ngIf="role.profile.company.corporate.read">
              <div>
                <span *ngIf="role.profile.company.corporate.post else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'EDIT_CORPORATE'|translate}}
              </div>
            </div>

            <div class="singleslider" *ngIf="role.profile.company.corporate.read">
              <div>
                <span *ngIf="role.profile.company.corporate.put else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'ADD_CORPORATE'|translate}}
              </div>
            </div>

            <div class="singleslider" *ngIf="role.profile.company.corporate.read">
              <div>
                <span *ngIf="role.profile.company.corporate.delete else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'DELETE_CORPORATE'|translate}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="marginbottom20" *ngIf="maincomponent.loggedUser.role.superAdmin">
        <hr/>
        <h3 class="titlecolor">
          <mat-icon aria-hidden="false" aria-label="security">security</mat-icon> {{'ADMINISTRATION'|translate}}
        </h3>

        <div class="row marginbottom20">

          <div class="col-xl-4 marginbottom10">
            <h4>{{'ADMINISTRATION_ROLE'|translate}}</h4>
            <p>
              <i>
                {{'ADMINISTRATION_DESCR'|translate}}
              </i>
            </p>
          </div>
          <div class="col-xl-8">
            <div class="singleslider">
              <div>
                <span *ngIf="role.superLogin else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'LOGIN_AS'|translate}}
              </div>
            </div>

            <div class="singleslider">
              <div>
                <span *ngIf="role.adv else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'USERS_ACTIVATIONS'|translate}}
              </div>
            </div>

            <div class="singleslider">
              <div>
                <span *ngIf="role.promoter else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'PROMOTER'|translate}}
              </div>
            </div>

            <div class="singleslider">
              <div>
                <span *ngIf="role.isTktOperator else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'TKT_MANAGEMENT'|translate}}
              </div>
            </div>

            <div class="singleslider" *ngIf="role.isTktOperator">
              <div>
                <span *ngIf="role.tktZones.office else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'OFFICE'|translate}}
              </div>
            </div>

            <div class="singleslider" *ngIf="role.isTktOperator">
              <div>
                <span *ngIf="role.tktZones.tech else close" class="material-icons text-success mr-2" style="font-size:18px;">check</span>
                {{'TECH_SUPPORT'|translate}}
              </div>
            </div>

          </div>
        </div>

      </div>
      <!--
      <div class="row">
        <div class="col-xl-4 marginbottom10"></div>
        <div class="col-xl-8 buttonrow">
          <button class="marginright10" (click)="editUserRole()" color="primary" mat-stroked-button mat-button>
            {{'SAVE'|translate}}
          </button>
          <button color="warn" mat-stroked-button (click)="abortEdit()" mat-button>
            {{'ABORT_EDITING'|translate}}
          </button>
        </div>

      </div>
      -->

    </fieldset>

  </form>


</div>

<ng-template #close>
  <span class="material-icons text-danger mr-2" style="font-size:18px;"> close </span>
</ng-template>
