<div class="row mt-2 mb-2">
  <div *ngIf="mustBeVisible()" class="text-center col-12">Regole tariffarie:</div>
</div>
  <mat-accordion class="example-headers-align" multi >
    <div *ngFor="let info of fareRules" >
      <div *ngIf="info.DisplayName.substring(0, 9) === 'Fare Rule'" class="pb-2 mb-2">
        <mat-expansion-panel hideToggle  class="expansionHeight">
          <mat-expansion-panel-header>
            <mat-panel-title class="overflow-auto">
              {{info.DisplayName.substring(10, info.DisplayName.length-1)}}
            </mat-panel-title>
            <mat-panel-description class="mat-panel-description">
              <mat-icon>flight_takeoff</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
            <div class="scrollable">
              <div [innerHTML]="toText(info.Info)"></div>
            </div>
        </mat-expansion-panel>
      </div>
    </div>
  </mat-accordion>

