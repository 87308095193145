import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from 'src/app/modules/mainmodule/main/main.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomAuthService } from 'src/app/services/customAuth/custom-auth.service';

@Component({
  selector: 'app-sidemenu-version2',
  templateUrl: './sidemenu-version2.component.html',
  styleUrls: ['./sidemenu-version2.component.scss']
})
export class SidemenuVersion2Component implements OnInit {
  @Input() sarce?: any;
  constructor(
    public appcomponent: AppComponent,
    public maincomponent: MainComponent,
    private router: Router,
    public customAuth: CustomAuthService,
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
  }

  logOut(): void {
    this.authService.logout();
  }
  goto(value): void {
    this.router.navigate(['/' + value]);
  }

}
