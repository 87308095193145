<custom-title></custom-title>
<div class="padding20" *ngIf="!loading">
  <div *ngIf="currentStep == 0">
    <div class="row mb-3">
      <div class="col-lg-6">
        <mat-card appearance="outlined">
          <div class="p-3">
            <p>{{'PERSONAL_INFORMATIONS'|translate}}</p>
            <hr/>
            <mat-form-field class="full-width mb-1">
              <mat-label>{{'NAME'|translate}}</mat-label>
              <input matInput [(ngModel)]="userform.user.name" required>
            </mat-form-field>

            <mat-form-field class="full-width mb-1">
              <mat-label>{{'SURNAME'|translate}}</mat-label>
              <input matInput [(ngModel)]="userform.user.surname" required>
            </mat-form-field>

            <mat-form-field class="full-width mb-1">
              <mat-label>{{'PHONE'|translate}}</mat-label>
              <input matInput [(ngModel)]="userform.user.phoneNumber" required [pattern]="phoneNumberPattern" (ngModelChange)="checkSpaces()">
              <mat-error *ngIf="spaces">{{'CHARS_NOT_ALLOWED' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width mb-1">
              <mat-label>{{'BIRTHDAY'|translate}}</mat-label>
              <input (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="userform.user.birthday" [max]="today">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <div>
              <label id="example-radio-group-label">{{'SEX'|translate}}</label>
              <mat-radio-group [(ngModel)]="userform.user.sex"
                               aria-labelledby="example-radio-group-label"
                               class="example-radio-group">
                <mat-radio-button class="example-radio-button" *ngFor="let value of sex" [value]="value">
                  <span *ngIf="value == 'M'">{{'MALE'|translate}}</span>
                  <span *ngIf="value == 'F'">{{'FEMALE'|translate}}</span>
                </mat-radio-button>
              </mat-radio-group>
            </div>

          </div>
        </mat-card>
      </div>
      <div class="col-lg-6">
        <mat-card appearance="outlined">
          <div class="p-3">
            <p>{{'ACCESS_DATA'|translate}}</p>
            <hr/>
            <mat-form-field class="full-width mb-1">
              <mat-label>{{'EMAIL'|translate}}</mat-label>
              <input matInput [(ngModel)]="userform.user.email" required>
            </mat-form-field>

            <div>
              <mat-form-field class="full-width mb-1">
                <mat-label>{{'ROLE'|translate}}</mat-label>
                <mat-select [(ngModel)]="userform.user.role" required>
                  <mat-option *ngFor="let role of companyLevels" [value]="role.level">{{role.trad|translate}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div>
              <label id="example-radio-group-label2">{{'ACTIVATION'|translate}}</label>
              <mat-radio-group [(ngModel)]="userform.user.enable"
                               aria-labelledby="example-radio-group-label2"
                               class="example-radio-group">
                <mat-radio-button class="example-radio-button" [value]="true">
                  {{'ACTIVE'|translate}}
                </mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="false">
                  {{'INACTIVE'|translate}}
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <mat-form-field class="full-width mb-1">
              <mat-label>{{'PASSWORD'|translate}}</mat-label>
              <input required [type]="hide ? 'password' : 'text'" matInput [(ngModel)]="userform.user.password" (ngModelChange)="measureStrength(userform.user.password)">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="full-width mb-1">
              <mat-label>{{'PASSWORD_CONFIRM'|translate}}</mat-label>
              <input required [type]="hideConfirm ? 'password' : 'text'" matInput [(ngModel)]="passwordconfirm">
              <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirm">
                <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>

            <p class="mt-0 text-danger small" *ngIf="passwordconfirm !== userform.user.password">
              Le password non coincidono
            </p>

            <div class="text-left mb-3" style="font-size:11px;">
              <div>
                <span style="font-size:11px;vertical-align: middle">La password deve contenere almeno 4 lettere, due numeri ed un carattere speciale</span>
              </div>
              <span style="vertical-align: middle;font-size:14px;color:red;" class="material-icons" *ngIf="barValue <= 66">
                close
            </span>
              <span style="vertical-align: middle;font-size:14px;color:green;" class="material-icons" *ngIf="barValue > 66">
                done
            </span>
              <span style="vertical-align: middle">Forza della password</span>
              <div class="bar force{{barValue}}">
                <div class="barStep1"></div>
                <div class="barStep2"></div>
                <div class="barStep3"></div>
              </div>
            </div>

          </div>
        </mat-card>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-lg-6">
        <mat-card appearance="outlined">
          <div class="p-3">
            <div>
              <label id="example-radio-group-label3">{{'AGENCY'|translate}}</label>
              <hr/>
              <mat-radio-group [(ngModel)]="newagency" (ngModelChange)="resetCompany()"
                               aria-labelledby="example-radio-group-label3"
                               class="example-radio-group">
                <mat-radio-button class="example-radio-button" [value]="false">
                  {{'EXISTING_AGENCY'|translate}}
                </mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="true">
                  {{'NEW_AGENCY'|translate}}
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <div *ngIf="!newagency">
              <app-companiesautocomplete [subordinated]="false" (update)="setCompany($event)"></app-companiesautocomplete>
            </div>

          </div>
        </mat-card>
      </div>
      <div class="col-lg-6">
        <mat-card appearance="outlined">
          <div class="p-3">
            <p>{{'ADDRESS'|translate}}</p>
            <div class="row">
              <div class="col-6">
                <p>
                  <mat-slide-toggle [(ngModel)]="isNotItalyUser" (ngModelChange)="changeCountry($event)">Stato estero</mat-slide-toggle>
                </p>
              </div>
              <div class="col-6">
                <p class="text-right pointer" *ngIf="userform.company.location && !newagency" (click)="setAddress()">
                  {{'USE_COMPANY_ADDRESS'|translate}}
                  <span class="material-icons" *ngIf="usenewaddress">
                    check_box_outline_blank
                  </span>
                  <span class="material-icons" *ngIf="!usenewaddress">
                check_box
              </span>
                </p>
              </div>
            </div>
            <hr/>
            <div *ngIf="!isNotItalyUser">
              <mat-form-field class="full-width mb-1">
                <mat-label>{{'COUNTRY'|translate}}</mat-label>
                <mat-select required [(ngModel)]="userform.user.location.country">
                  <mat-option *ngFor="let country of countries" [value]="country.value">
                    {{country.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div *ngIf="!isNotItalyUser">
              <mat-form-field class="full-width mb-1">
                <mat-label>{{'REGION'|translate}}</mat-label>
                <mat-select (selectionChange)="setRegion()"  [(ngModel)]="userform.user.location.region" required>
                  <mat-option *ngFor="let region of regions" [value]="region.value">
                    {{region.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div *ngIf="!isNotItalyUser">
              <mat-form-field class="full-width mb-1" *ngIf="userform.user.location.region">
                <mat-label>{{'PROVINCE'|translate}}</mat-label>
                <mat-select [(ngModel)]="userform.user.location.province" required>
                  <mat-option *ngFor="let province of provinces" [value]="province.value">
                    {{province.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div *ngIf="isNotItalyUser">
              <mat-form-field class="full-width mb-1">
                <mat-label>{{'FOREIGN_COUNTRY'|translate}}</mat-label>
                <input matInput type="text" [(ngModel)]="userform.user.location.country" required>
              </mat-form-field>
            </div>

            <div *ngIf="isNotItalyUser">
              <mat-form-field class="full-width mb-1">
                <mat-label>{{'REGION'|translate}}</mat-label>
                <input matInput type="text" [(ngModel)]="userform.user.location.region" required>
              </mat-form-field>
            </div>

            <div *ngIf="isNotItalyUser">
              <mat-form-field class="full-width mb-1" *ngIf="userform.user.location.region">
                <mat-label>{{'PROVINCE'|translate}}</mat-label>
                <input matInput [(ngModel)]="userform.user.location.province" type="text" required>
              </mat-form-field>
            </div>

            <mat-form-field class="full-width mb-1" *ngIf="userform.user.location.province">
              <mat-label>{{'CITY'|translate}}</mat-label>
              <input matInput placeholder="Città" [(ngModel)]="userform.user.location.city" required>
            </mat-form-field>

            <mat-form-field class="full-width mb-1" *ngIf="userform.user.location.city">
              <mat-label>{{'POSTAL_CODE'|translate}}</mat-label>
              <input matInput placeholder="Codice postale" [(ngModel)]="userform.user.location.postalCode" required>
            </mat-form-field>

            <mat-form-field class="full-width mb-1" *ngIf="userform.user.location.postalCode">
              <mat-label>{{'ADDRESS'|translate}}</mat-label>
              <input matInput placeholder="Indirizzo" [(ngModel)]="userform.user.location.address" required>
            </mat-form-field>

          </div>
        </mat-card>
      </div>
    </div>

    <div class="text-right">
      <button [disabled]="!validForm() || barValue < 100" *ngIf="!newagency" (click)="createUser(true)" mat-button>{{'SAVE'|translate}} <span class="material-icons">save</span></button>
      <button [disabled]="!validUser()" (click)="nextStep()" *ngIf="newagency" mat-button>{{'NEXT'|translate}} <span class="material-icons">chevron_right</span></button>
    </div>
  </div>

  <div class="row mb-3" *ngIf="currentStep == 1">

    <div class="col-12">
      <mat-card appearance="outlined" class="mb-3 p-3">
        <mat-slide-toggle [(ngModel)]="importCp">{{'IMPORT_SIAP_COMPANY'|translate}}</mat-slide-toggle>
      </mat-card>

      <mat-card class="mx-3 mb-3" *ngIf="importCp">
        <div class="row">
          <div class="col-2">
            <mat-form-field class="mb-1 px-3" *ngIf="!appComponent.isGat">
              <mat-label>{{ 'AGENCY_TYPE' | translate }}</mat-label>
              <mat-select [(ngModel)]="userform.company.type" required>
                <mat-option *ngFor="let type of companyType" [value]="type.value">
                  {{ type.trad|translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field class="mb-1">
              <mat-label>{{ 'DK_NUMBER'|translate }}</mat-label>
              <input matInput [(ngModel)]="userform.company.dkNumber" required>
            </mat-form-field>
          </div>
        </div>
        <div class="buttonrow" *ngIf="userform.company.dkNumber">
          <button mat-raised-button color="primary" (click)="importCompany()">{{'IMPORT'|translate}}</button>
        </div>
      </mat-card>
    </div>

    <div class="col-lg-6" *ngIf="!importCp">
      <mat-card appearance="outlined">
        <div class="p-3">
          <p>{{'COMPANY_DETAILS'|translate}}</p>
          <hr/>

          <mat-form-field class="full-width mb-1">
            <mat-label>{{'RAGION'|translate}}</mat-label>
            <input matInput [(ngModel)]="userform.company.name" required>
          </mat-form-field>

          <mat-form-field class="full-width mb-1">
            <mat-label>{{'PIVA'|translate}}</mat-label>
            <input matInput [(ngModel)]="userform.company.pIva" required>
          </mat-form-field>

          <mat-form-field class="full-width mb-1">
            <mat-label>{{'PHONE'|translate}}</mat-label>
            <input matInput [(ngModel)]="userform.company.phoneNumber" required [pattern]="phoneNumberPattern" (ngModelChange)="checkSpaces()">
            <mat-error *ngIf="!spaces">{{'CHARS_NOT_ALLOWED' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field class="full-width mb-1">
            <mat-label>{{'EMAIL'|translate}}</mat-label>
            <input matInput [(ngModel)]="userform.company.email" required>
            <mat-hint style="color:red;" class="mb-2">questa mail riceverà tutti i servizi acquistati</mat-hint>
          </mat-form-field>

          <mat-form-field class="full-width mb-1" *ngIf="!appComponent.isGat">
            <mat-label>{{'TYPE'|translate}}</mat-label>
            <mat-select [(ngModel)]="userform.company.type" required>
              <mat-option *ngFor="let type of companyType" [value]="type.value">
                {{type.trad|translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <app-companiesautocomplete [subordinated]="true" (setSub)="setSubordinated($event)"></app-companiesautocomplete>

          <mat-form-field class="full-width pt-3 mb-1" *ngIf="userform.company.subordinatedOf">
            <mat-label>{{'CAU_CODE'|translate}}</mat-label>
            <input matInput [(ngModel)]="userform.company.cau" disabled>
          </mat-form-field>

          <mat-form-field class="full-width mb-1">
            <mat-label>{{'DK_NUMBER'|translate}}</mat-label>
            <input matInput [(ngModel)]="userform.company.dkNumber" required>
          </mat-form-field>

          <mat-form-field class="full-width mb-1">
            <mat-label>{{'AMADEUS_PROFILE'|translate}}</mat-label>
            <input matInput [(ngModel)]="userform.company.amadeusProfile">
          </mat-form-field>

          <mat-form-field class="full-width mb-1"  *ngIf="incentives" [hidden]="appComponent.isSbt || appComponent.isB2B">
            <mat-label>{{'INCENTIVE'|translate}}</mat-label>
            <mat-select [(ngModel)]="userform.company.incentivePlan">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let incentive of incentives.list" [value]="incentive._id">
                {{incentive.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card>
    </div>

    <div class="col-lg-6" *ngIf="!importCp">
      <mat-card appearance="outlined">
        <div class="p-3">
          <p>{{'ADDRESS'|translate}}</p>
          <div class="row">
            <div class="col-6">
              <p>
                <mat-slide-toggle [(ngModel)]="isNotItalyCompany" (ngModelChange)="changeCountryCompany($event)" [disabled]="disableToggle" >Stato estero</mat-slide-toggle>
              </p>
            </div>
            <div class="col-6">
              <p class="text-right pointer" (click)="setCpAddress()">
                {{'USE_USER_ADDRESS'|translate}}
                <span class="material-icons" *ngIf="usenewcompanyaddress">
                  check_box_outline_blank
                </span>
                <span class="material-icons" *ngIf="!usenewcompanyaddress">
                  check_box
                </span>
              </p>
            </div>
          </div>
          <hr/>
          <div>
            <mat-form-field class="full-width mb-1">
              <mat-label>{{'COUNTRY'|translate}}</mat-label>
              <mat-select *ngIf="usenewcompanyaddress && !isNotItalyCompany" required [(ngModel)]="userform.company.location.country">
                <mat-option *ngFor="let country of countries" [value]="country.value">
                  {{country.viewValue}}
                </mat-option>
              </mat-select>
              <mat-select *ngIf="!usenewcompanyaddress && !isNotItalyCompany" required [(ngModel)]="userform.company.location.country">
                <mat-option *ngFor="let country of countries" [value]="country.value">
                  {{country.viewValue}}
                </mat-option>
              </mat-select>
              <input matInput *ngIf="usenewcompanyaddress && isNotItalyCompany" [(ngModel)]="userform.company.location.country" required  type="text">
              <input matInput *ngIf="!usenewcompanyaddress && isNotItalyCompany" [(ngModel)]="userform.company.location.country" required  type="text">
            </mat-form-field>
          </div>

          <div >
            <mat-form-field class="full-width mb-1">
              <mat-label>{{'REGION'|translate}}</mat-label>
              <mat-select (selectionChange)="setCpRegion()" [(ngModel)]="userform.company.location.region" required *ngIf="usenewcompanyaddress && !isNotItalyCompany">
                <mat-option *ngFor="let region of regions" [value]="region.value">
                  {{region.viewValue}}
                </mat-option>
              </mat-select>
              <mat-select (selectionChange)="setCpRegion()" [(ngModel)]="userform.company.location.region" required *ngIf="!usenewcompanyaddress && !isNotItalyCompany">
                <mat-option *ngFor="let region of regions" [value]="region.value">
                  {{region.viewValue}}
                </mat-option>
              </mat-select>
              <input matInput *ngIf="usenewcompanyaddress && isNotItalyCompany" [(ngModel)]="userform.company.location.region" required  type="text">
              <input matInput *ngIf="!usenewcompanyaddress && isNotItalyCompany" [(ngModel)]="userform.company.location.region" required  type="text">
            </mat-form-field>

          </div>

          <div>
            <mat-form-field class="full-width mb-1" *ngIf="userform.company.location.region" >
              <mat-label>{{'PROVINCE'|translate}}</mat-label>
              <mat-select [(ngModel)]="userform.company.location.province" *ngIf="usenewcompanyaddress && !isNotItalyCompany" required>
                <mat-option *ngFor="let province of cpProvinces" [value]="province.value">
                  {{province.viewValue}}
                </mat-option>
              </mat-select>
              <mat-select [(ngModel)]="userform.company.location.province" *ngIf="!usenewcompanyaddress && !isNotItalyCompany" required>
                <mat-option *ngFor="let province of cpProvinces" [value]="province.value">
                  {{province.viewValue}}
                </mat-option>
              </mat-select>
              <input matInput *ngIf="usenewcompanyaddress && isNotItalyCompany" [(ngModel)]="userform.company.location.province" required  type="text">
              <input matInput *ngIf="!usenewcompanyaddress && isNotItalyCompany" [(ngModel)]="userform.company.location.province" required  type="text">
            </mat-form-field>
          </div>

          <mat-form-field class="full-width mb-1" *ngIf="userform.company.location.province">
            <mat-label>{{'CITY'|translate}}</mat-label>
            <input matInput placeholder="Città" [(ngModel)]="userform.company.location.city" required>
          </mat-form-field>

          <mat-form-field class="full-width mb-1" *ngIf="userform.company.location.city">
            <mat-label>{{'POSTAL_CODE'|translate}}</mat-label>
            <input matInput placeholder="Codice postale" [(ngModel)]="userform.company.location.postalCode" required>
          </mat-form-field>

          <mat-form-field class="full-width mb-1" *ngIf="userform.company.location.postalCode">
            <mat-label>{{'ADDRESS'|translate}}</mat-label>
            <input matInput placeholder="Indirizzo" [(ngModel)]="userform.company.location.address" required>
          </mat-form-field>

        </div>
      </mat-card>
    </div>

  </div>

  <div class="row" *ngIf="currentStep==1">
    <div class="col-6">
      <button mat-button (click)="prevStep()">
        <span class="material-icons">chevron_left</span> {{'BACK'|translate}}
      </button>
    </div>
    <div class="col-6 text-right">
      <button [disabled]="!validForm() || barValue < 100" mat-button (click)="createUser(false)">{{'SAVE'|translate}} <span class="material-icons">save</span></button>
    </div>
  </div>


</div>

<div class="padding20" *ngIf="loading">
  <div class="text-center mb-3">{{'LOADING'|translate}}</div>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
