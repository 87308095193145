<custom-title></custom-title>
<div class="padding20">

  <div class="" @listItemAnimation *ngIf="currentStep==1">
    <mat-card appearance="outlined" class="marginbottom20">
      <div class="row">
        <div class="col-md-9 p-2">
          <p class="d-flex align-items-center p-2">
            <mat-icon class="marginright10" color="accent" role="img"> info </mat-icon>
            <span>Inserisci i viaggiatori per la tua richiesta</span>
            <button (click)="setNewTraveler()" mat-icon-button class="iconButtons">
              <mat-icon>add</mat-icon>
            </button>
          </p>
        </div>
        <div class="col-md-3 d-flex align-items-center justify-content-end">
          <button [disabled]="!travelerController()" mat-stroked-button (click)="nextStep()" class="mx-3">
            Next step <span class="material-icons"> keyboard_arrow_right </span>
          </button>
        </div>
      </div>
    </mat-card>

    <div class="row">
      <div class="col-lg-6 p-3 mb-3" *ngFor="let tra of travelers;let i = index;let j = index">
        <div class="default_panel">
          <div class="default_heading d-flex justify-content-between">
            <div class="d-flex align-self-center">
              <span class="tabtitle">{{'TRAVELER'|translate}} {{i+1}}</span>
            </div>
            <div class="d-flex align-items-center">
              <button (click)="removeTraveler(i)" mat-icon-button class="small-md" *ngIf="travelers.length>1">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
          <div class="default_content smfont">
            <div class="fakefield mb-2">
              {{'NAME'|translate}}:
              <span *ngIf="tra.name"> {{tra.name}}</span>
              <span *ngIf="!tra.name"> _ _ _ _</span>
            </div>
            <div class="fakefield mb-2">
              {{'SURNAME'|translate}}:
              <span *ngIf="tra.surname"> {{tra.surname}}</span>
              <span *ngIf="!tra.surname"> _ _ _ _</span>
            </div>
            <div class="fakefield mb-2">
              {{'PHONE'|translate}}:
              <span *ngIf="tra.phoneNumber"> {{tra.phoneNumber}}</span>
              <span *ngIf="!tra.phoneNumber"> _ _ _ _</span>
            </div>
            <div class="fakefield mb-2">
              {{'EMAIL'|translate}}:
              <span *ngIf="tra.email"> {{tra.email}}</span>
              <span *ngIf="!tra.email"> _ _ _ _</span>
            </div>
            <div class="buttonrow">
              <button mat-raised-button *ngIf="!tra.name || !tra.surname" (click)="toggleDialog(i)">{{'ADD_TRAVELER'|translate}}</button>
              <button mat-raised-button *ngIf="tra.name || tra.surname" (click)="removeTraveler(i)">{{'REMOVE_TRAVELER'|translate}}</button>
              <button mat-raised-button *ngIf="tra.name || tra.surname" (click)="toggleEditDialog(i)">{{'EDIT_TRAVELER'|translate}}</button>
              <button mat-raised-button *ngIf="!tra.name && !tra.surname"  (click)="loadTravelersList(i)">{{'LOAD_TRAVELER'|translate}}</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="addTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">

        <div *ngIf="currentTraveler!=undefined" class="modal-body">
          <app-add [newTraveler]="travelers[currentTraveler]" [parent]="'offlineRequest'" (close)="closeEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)"></app-add>
        </div>

      </div>
    </div>
  </div>

  <div class="modal fade" id="listTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" *ngIf="loadTravelers">

        <div *ngIf="currentTraveler!=undefined" class="modal-body">
          <app-travelerslist [newTraveler]="travelers[currentTraveler]" [passengers]="travelers" [page]="'offlineRequest'" (close)="closeListEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)"></app-travelerslist>
          <hr/>
          <div class="buttonrow text-right">
            <button mat-stroked-button (click)="closeListEvent($event)">{{'CLOSE'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="EditTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">

        <div *ngIf="currentTraveler!=undefined && editingTraveler" class="modal-body">
          <app-edittraveler (close)="closeEditEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [currentTraveler]="travelers[currentTraveler]" [parent]="'offlineRequest'"></app-edittraveler>
        </div>
      </div>
    </div>
  </div>

  <div class="" @listItemAnimation *ngIf="currentStep==2">
    <mat-card appearance="outlined" class="marginbottom20">
      <div class="row">
        <div class="col-9 p-2">
          <p class="d-flex align-items-center p-2">
            <mat-icon class="marginright10" color="accent" role="img"> info </mat-icon> Aggiungi i servizi richiesti ed invia la richiesta.
          </p>
        </div>
        <div class="col-3 d-flex align-items-center justify-content-end mx-3">
          <button mat-stroked-button (click)="prevStep()">
            <span class="material-icons"> keyboard_arrow_left </span> Prev step
          </button>
        </div>
      </div>
    </mat-card>

    <div class="row">
      <div class="col-lg-6 mb-3">
        <div class="default_panel">

          <div class="absoluteLoading" *ngIf="loading">
            <div class="absoluteLoading">
              <div class="padding20 text-center">
                <p>{{'LOADING'|translate}}</p>
                <mat-progress-bar class="example-margin" mode="buffer">
                </mat-progress-bar>
              </div>
            </div>
          </div>

          <div class="default_heading d-flex justify-content-between">
            <div class="d-flex align-self-center"><span class="tabtitle">{{'YOUR_REQUEST'|translate}}</span></div>
          </div>
          <div class="default_content smfont">
            <form autocomplete="off">
              <!--
              <div class="row paxPart">
                <div class="col-12">
                  <h5 class="mb-3">{{'PASSENGERS'|translate}}</h5>
                </div>
                <div class="col-4 mb-2">
                  <mat-form-field class="smallWidth text-center">
                    <button (click)="removePax(0)" mat-button matPrefix mat-icon-button aria-label="Clear">
                      <mat-icon>remove</mat-icon>
                    </button>
                    <mat-label>{{'ADULTS'|translate}}</mat-label>
                    <input matInput type="number" maxlength="2" disabled placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="passengers[0].number">
                    <button (click)="addPax(0)" mat-button matSuffix mat-icon-button aria-label="Clear">
                      <mat-icon>add</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div class="col-4 mb-2">
                  <mat-form-field class="smallWidth text-center">
                    <button (click)="removePax(1)" mat-button matPrefix mat-icon-button aria-label="Clear">
                      <mat-icon>remove</mat-icon>
                    </button>
                    <mat-label>{{'CHILDREN'|translate}}</mat-label>
                    <input matInput type="number" maxlength="2" disabled placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="passengers[1].number">
                    <button (click)="addPax(1)" mat-button matSuffix mat-icon-button aria-label="Clear">
                      <mat-icon>add</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div class="col-4 mb-2">
                  <mat-form-field class="smallWidth text-center">
                    <button (click)="removePax(2)" mat-button matPrefix mat-icon-button aria-label="Clear">
                      <mat-icon>remove</mat-icon>
                    </button>
                    <mat-label>{{'INFANT'|translate}}</mat-label>
                    <input matInput type="number" maxlength="2" disabled placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="passengers[2].number">
                    <button (click)="addPax(2)" mat-button matSuffix mat-icon-button aria-label="Clear">
                      <mat-icon>add</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div> -->
              <div class="mb-2">
                <mat-form-field class="full-width">
                  <mat-label>{{'SUBJECT'|translate}}</mat-label>
                  <input matInput type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="summary.subject">
                  <button *ngIf="summary.subject" matSuffix mat-icon-button aria-label="Clear" (click)="summary.subject=''">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field class="full-width">
                  <mat-label>{{'TEXT'|translate}}</mat-label>
                  <textarea matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="summary.freeText"></textarea>
                </mat-form-field>
              </div>
              <div>
                <h5 class="mb-3">{{'INSERT_SERVICE'|translate}} {{summary.services.length+1}}</h5>
              </div>
              <mat-form-field class="full-width">
                <mat-label>{{'SERVICE_TYPE'|translate}}</mat-label>
                <mat-select [ngModelOptions]="{standalone: true}" [(ngModel)]="request.service.type">
                  <mat-option value="flight">
                    {{'FLIGHT'|translate}}
                  </mat-option>
                  <mat-option value="train">
                    {{'TRAIN'|translate}}
                  </mat-option>
                  <mat-option value="hotel">
                    {{'HOTEL'|translate}}
                  </mat-option>
                  <mat-option value="car">
                    {{'CAR_RENT'|translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="request.service.type==='hotel'" class="full-width">
                <mat-label>{{'CITY'|translate}}</mat-label>
                <input matInput type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="request.service.location">
                <button *ngIf="request.service.location" matSuffix mat-icon-button aria-label="Clear" (click)="request.service.location=''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field *ngIf="request.service.type!='hotel'" class="full-width">
                <mat-label>{{'DEPARTURE'|translate}}</mat-label>
                <input matInput type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="request.service.from">
                <button *ngIf="request.service.from" matSuffix mat-icon-button aria-label="Clear" (click)="request.service.from=''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field *ngIf="request.service.type!='hotel'"  class="full-width">
                <mat-label>{{'ARRIVAL'|translate}}</mat-label>
                <input matInput type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="request.service.to">
                <button *ngIf="request.service.to" matSuffix mat-icon-button aria-label="Clear" (click)="request.service.to=''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label *ngIf="request.service.type=='flight' || request.service.type=='train'">{{'DEPARTURE_DATE'|translate}}</mat-label>
                <mat-label *ngIf="request.service.type=='car'">{{'PICKUP_DATE'|translate}}</mat-label>
                <mat-label *ngIf="request.service.type=='hotel'">{{'CHECK_IN'|translate}}</mat-label>
                <input matInput (click)="picker.open()" [matDatepicker]="picker" [ngModelOptions]="{standalone: true}" [(ngModel)]="request.service.date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <mat-form-field *ngIf="request.service.type==='car' || request.service.type==='hotel'" class="full-width">
                <mat-label *ngIf="request.service.type=='car'">{{'RETURN_DATE'|translate}}</mat-label>
                <mat-label *ngIf="request.service.type=='hotel'">{{'CHECK_OUT'|translate}}</mat-label>
                <input matInput (click)="picker1.open()" [matDatepicker]="picker1" [ngModelOptions]="{standalone: true}" [(ngModel)]="request.service.dateTo">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>{{'NOTES'|translate}}</mat-label>
                <textarea [ngModelOptions]="{standalone: true}" [(ngModel)]="request.service.notes" matInput></textarea>
              </mat-form-field>

              <div>
                <button [disabled]="!checkForm()" mat-stroked-button color="primary" (click)="pushToSummary()">
                  {{'ADD_SERVICE_TO_REQUEST'|translate}}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="default_panel">
          <div class="default_heading d-flex justify-content-between">
            <div class="d-flex align-self-center"><span class="tabtitle">{{'REQUEST_SUMMARY'|translate}}</span></div>
          </div>
          <div class="default_content smfont">
            <div class="mb-3">
              <p>{{'SUBJECT'|translate}}: {{summary.subject}}</p>
              <p>{{'TEXT'|translate}}:<br/> {{summary.freeText}}</p>
              <hr/>
              <h5 class="mb-2">{{'PASSENGERS'|translate}}</h5>
              <span *ngFor="let traveler of travelers; let ind = index;">
              <span class="singlepax">
                {{returnType(traveler.type)|translate}} {{traveler.name}} {{traveler.surname}}
              </span>
            </span>
            </div>
            <div *ngIf="summary.services.length>0">
              <div @listItemAnimation class="singleService" *ngFor="let service of sortedActivities; let ind = index;">
                <div class="row mb-2">

                  <div class="col-2 text-center align-self-center">
                    <span *ngIf="service.type=='flight'" class="material-icons">flight</span>
                    <span *ngIf="service.type=='train'" class="material-icons">train</span>
                    <span *ngIf="service.type=='hotel'" class="material-icons">hotel</span>
                    <span *ngIf="service.type=='car'" class="material-icons">directions_car</span>
                  </div>

                  <div class="col-8 align-self-center">

                    <div *ngIf="service.type === 'flight' || service.type === 'train'">
                      <div>Partenza da {{service!.from}} il {{service.date | date:'dd/MM/yyyy'}}</div>
                      <div>Arrivo a {{service!.to}}</div>
                      <div>{{service!.notes}}</div>
                    </div>

                    <div *ngIf="service.type === 'hotel'">
                      <div>Pernottamento a {{service!.location}}</div>
                      <div>{{'CHECK_IN'|translate}} {{service.date | date:'dd/MM/yyyy'}}</div>
                      <div>{{'CHECK_OUT'|translate}} {{service.dateTo | date:'dd/MM/yyyy'}}</div>
                      <div>{{service!.notes}}</div>
                    </div>

                    <div *ngIf="service.type === 'car'">
                      <div>{{'PICKUP_DATE'|translate}} il {{service.date | date:'dd/MM/yyyy'}} a {{service!.from}}</div>
                      <div>{{'RETURN_DATE'|translate}} il {{service.dateTo | date:'dd/MM/yyyy'}} a {{service!.to}}</div>
                      <div>{{service!.notes}}</div>
                    </div>

                  </div>
                  <div class="col-2 text-center align-self-center">
                    <button matTooltip="Remove service" mat-icon-button (click)="removeService(ind)"><mat-icon>close</mat-icon></button>
                  </div>
                </div>
              </div>
              <button (click)="sendRequest()" mat-stroked-button>{{'SEND_REQUEST'|translate}}</button>
            </div>
            <div *ngIf="summary.services.length==0">
              {{'NO_SERVICES_ADDED'|translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
