<div class="corporateCont">
  <mat-accordion class="example-headers-align" *ngFor="let srv of services" style="margin-left: 8px">
    <mat-expansion-panel style="margin-left: 8px;" (opened)="corporateCodes[srv+'Open'] = true" (closed)="corporateCodes[services+'Open'] = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{srv}}
        </mat-panel-title>
        <mat-panel-description>
<!--
"COMPANY_CORP_CODES": {
    "ADD": "Add",
    "SAVE": "Save",
    "CLEAR": "Clear",
    "UPDATE": "Update",
    "DELETE": "Delete",
    "NAME": "Name",
    "CODE": "Code"
  }
-->
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="row">
        <div class="demo-button-container col-lg-6 buttonrow">
          <button mat-button (click)="addRecord = !addRecord" class="demo-button">
            {{ "COMPANY_CORP_CODES.ADD" | translate }}
          </button>
          <button mat-button *ngIf="addRecord" (click)="save(srv)" class="demo-button">
            {{ "COMPANY_CORP_CODES.SAVE" | translate }}
          </button>
          <button mat-button *ngIf="addRecord" (click)="clearNew()" class="demo-button">
            {{ "COMPANY_CORP_CODES.CLEAR" | translate }}
          </button>
        </div>
        <div class="col-lg-6" *ngIf="addRecord">
          <div class="example-element-diagram">
            <mat-form-field appearance="fill">
              <mat-label>{{ "COMPANY_CORP_CODES.NAME" | translate }}</mat-label>
              <input matInput [(ngModel)]="newCorporate.name">
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{ "COMPANY_CORP_CODES.CODE" | translate }}</mat-label>
              <input matInput [(ngModel)]="newCorporate.code">
            </mat-form-field>
          </div>
        </div>
        <table mat-table [dataSource]="corporateCodes[srv]" multiTemplateDataRows class="mat-elevation-z8">
          <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef> {{column}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="example-element-detail"
                   [@detailExpand]="element == corporateCodes[srv+'Selected'] ? 'expanded' : 'collapsed'">
                <hr/>
                <div class="row">
                  <div class="col-6">
                    <mat-form-field appearance="fill">
                      <mat-label>{{ "COMPANY_CORP_CODES.NAME" | translate }}</mat-label>
                      <input matInput [(ngModel)]="element.name">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                      <mat-label>{{ "COMPANY_CORP_CODES.CODE" | translate }}</mat-label>
                      <input matInput [(ngModel)]="element.code">
                    </mat-form-field>
                  </div>
                  <div class="col-6 buttonrow">
                    <button color="primary" mat-button (click)="update(srv,element)" class="demo-button">
                      {{ "COMPANY_CORP_CODES.UPDATE" | translate }}
                    </button>
                    <button color="warn" mat-button (click)="delete(srv,element._id)" class="demo-button">
                      {{ "COMPANY_CORP_CODES.DELETE" | translate }}
                    </button>
                  </div>
                </div>

              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
              [class.example-expanded-row]="corporateCodes[srv+'Selected'] === element"
              (click)="corporateCodes[srv+'Selected'] = corporateCodes[srv+'Selected'] === element ? null : element">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>




