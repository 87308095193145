<div *ngIf="!rss">
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>
<div *ngIf="rss" class="rssCont">
  <div *ngFor="let element of rss" class="rssItem">
    <div class="rssdate">{{element.date|date:'dd/MM/yyyy'}}</div>
    <div class="rsstitle">{{element.title}}</div>

    <div class="rssdescr">
      <div [innerHTML]="element.descr"></div>
    </div>
  </div>
</div>
