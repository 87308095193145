import {Component, Input, OnInit} from '@angular/core';
import {Fops} from '../../companies/classes/fops';
import {ActivatedRoute} from '@angular/router';
import {CompaniesService} from '../../../services/profile/companies.service';
import {MainComponent} from '../../mainmodule/main/main.component';
import {MatChipInputEvent} from '@angular/material/chips';
import * as $ from 'jquery';
import 'bootstrap';

@Component({
  selector: 'app-fops',
  templateUrl: './fops.component.html',
  styleUrls: ['./fops.component.scss']
})
export class FopsComponent implements OnInit {

  @Input() id: string;
  @Input() reservation: any;
  @Input() user?: any = false;
  loading: boolean;
  fops: Fops[];
  newFop: any;
  thisYear: number;
  years: [];
  thisMonth: number;
  currentFop: any;
  months: [];
  supportedCards: boolean;
  constructor(
    private route: ActivatedRoute,
    private companyService: CompaniesService,
    public maincomponent: MainComponent,
  ) {
  }

  ngOnInit() {
    this.supportedCards = false;
    this.createNewFop();
    this.getFops();
    this.thisYear = new Date().getFullYear();
    this.thisMonth = new Date().getMonth();
  }

  getFops() {
    this.loading = true;
    this.companyService.getFops(this.id, this.user).subscribe((res) => {
      this.loading = false;
      this.fops = res;
      console.log(this.fops.length, 'fops length');
      if (this.fops){

        this.fops.forEach(f => {
          f.accepted = true;
        });
        if (this.reservation && this.reservation.hotel) {
          this.elaborateFop();
        }
        else if(this.reservation && this.reservation.lowCost && this.reservation.supportedCards.length > 0){
          this.supportedCards = true;
          let tempData = [];
          for(const ele of this.reservation.supportedCards){
            if((!ele.CardType.includes('Debit')))
              tempData.push(ele);
          }
          this.reservation.supportedCards = tempData;
        }
      }
    }, error => {
      this.loading = false;
      // this.notifier.notify('error', error);
      console.error('Error => ', error);
    });
  }

  elaborateFop(): void {
    this.fops.forEach(f => {
      if (f.type === 'CC') {
        const find = this.reservation.room.ratePlans[0].guarantee.accepted.findIndex(r => r.cardCode === f.card.type);
        if (find !== -1) {
          f.accepted = true;
        } else {
          f.accepted = false;
        }
      } else {
        f.accepted = false;
      }
    });
  }

  toggleAddModal() {
    $('#addFopModal').modal('toggle');
  }
  toggleDeleteModal(fop) {
    if (fop) {
      this.currentFop = fop;
    } else {
      delete this.currentFop;
    }
    $('#deleteFopModal').modal('toggle');
  }

  deleteFop(currentFop) {
    console.log('currentFop => ', currentFop);
    this.loading = true;
    this.companyService.deleteFop(this.id, currentFop.indexes, this.user).subscribe((res) => {
      this.loading = false;
      this.toggleDeleteModal(undefined);
      this.getFops();
    }, error => {
      this.loading = false;
      this.toggleDeleteModal(undefined);
    });
  }


  addFop() {
    this.loading = true;
    if(this.newFop.type === 'TP'){
      this.newFop.card.type = 'TP';
    }

    if(this.newFop.card.number){
      this.newFop.card.number = this.newFop.card.number.toString();
    }

    this.companyService.saveFop(this.id, this.newFop, this.user).subscribe((res) => {
      this.loading = false;
      this.toggleAddModal();
      this.getFops();
      this.createNewFop();
    }, error => {
      this.loading = false;
      this.toggleAddModal();
    });

  }

  createNewFop() {
    this.newFop = {
      type: 'CC',
      card: {
        type: 'CA',
        number: '',
        expirationMonth: '',
        expirationYear: '',
        holder: '',
        cvc: ''
      }
    };
  }

  validForm() {
    let valid = false;
    if (this.newFop.type === 'CC') {
      // tslint:disable-next-line:max-line-length triple-equals
      valid = (this.newFop.card.expirationMonth && this.newFop.card.expirationYear && this.newFop.card.number) && (this.newFop.card.holder.length > 5 && this.newFop.card.cvc.length >= 3);
    } else {
      valid = !!(this.newFop.card.number && this.newFop.card.expirationMonth && this.newFop.card.expirationYear);
    }
    return valid;
  }

  selectFop(fop) {
    console.log('this fop');
    if (this.reservation && fop.accepted) {
      for (let i = 0; i < this.fops.length; i++) {
        this.fops[i].selected = false;
      }
      fop.selected = true;
      this.reservation.creditcard = fop.indexes.lowcost;
      if (this.reservation.lowCost) {
        if (fop.card) {
          this.reservation.creditcard = {};
          this.reservation.creditcard.index = fop.indexes.lowcost;
          this.reservation.creditcard.type = fop.card.type;
        }
      }
      // this.reservation.creditcard.type = fop.type;
    }
  }

  returnCreditCardName(value:string):string{
    if (value === 'AX') return 'American Express';
    if (value === 'DC') return 'Diners';
    if (value === 'CA') return 'Master Card';
    if (value === 'VI') return 'Visa';
    return '';
  }

}
