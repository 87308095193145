import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as $ from 'jquery';
import { BehaviorSubject } from 'rxjs';
import {
  ApplyChange,
  CreateReservationReq,
  TrainAllowedOperations,
  TrainTraveller,
  ValidateChangeServiceResponse,
} from '../../../../classes/train.models';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TrainService } from '../../../../train.service';
import { MatStepper } from '@angular/material/stepper';
import { TrainUtils } from '../../../train.utils';

@Component({
  selector: 'train-change-new-search',
  templateUrl: './change-new-search.component.html',
  styleUrls: ['./change-new-search.component.scss']
})
export class ChangeNewSearchComponent implements OnInit {
  @Input() isDisabled : boolean;
  @Input() pnr : string;
  @Input() selectedTravelSolution = 0;
  @Input() travellers: TrainTraveller[] = [];
  @Input() allowedOperations : TrainAllowedOperations;
  @Output() reloadEvent = new EventEmitter<any>();
  @ViewChild(MatStepper) stepper;

  loading = false;
  changeAttempt = false;
  errorMessage : string;
  step = 'search';

  changeReservation$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  searchFormGroup : UntypedFormGroup;
  paxFormGroup : UntypedFormGroup;
  completedFormGroup : UntypedFormGroup;
  newTravel : any;
  validatedTravelResponse : ValidateChangeServiceResponse;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private trainService : TrainService,
  ) { }

  ngOnInit(): void {
    this.searchFormGroup = this._formBuilder.group({
      done: ['', Validators.required],
    });
    this.paxFormGroup = this._formBuilder.group({
      done: ['', Validators.required],
    });
    this.completedFormGroup = this._formBuilder.group({
      done: ['', Validators.required],
    });
  }

  toggleModal(){
    $('#changeNewTravelModal').modal('toggle');
  }

  reload(){
    this.reloadEvent.emit(true);
  }

  confirmChange(){
    const request: ApplyChange = {
      travel: this.validatedTravelResponse.travel,
      selectedSimilarRecord: {
        validatedPostSaleRecord: this.validatedTravelResponse.validatedPostSaleRecord,
        reusedPaymentRecords: this.validatedTravelResponse.reusedPaymentRecords,
        reversedPaymentRecords: this.validatedTravelResponse.reversedPaymentRecords,
        additionalCharges: this.validatedTravelResponse.additionalCharges,
        missingAmount: this.validatedTravelResponse.missingAmount,
      }
    };
    this.changeReservation$.next(true);
    this.trainService.confirmChangeService(this.pnr, request).subscribe(response => {
      this.changeAttempt = true;
      this.toggleModal();
      this.reload();
      this.changeReservation$.next(false);
    }, error => {
      this.changeReservation$.next(false);
      this.errorMessage = error?.message;
    });
  }

  stepperNextStep($event : string, formGroup: UntypedFormGroup){
    formGroup.patchValue({ done: true });
    this.step = $event;
    this.stepper.next();
  }

  stepperReset(){
    this.step = 'search';
    this.searchFormGroup.patchValue({ done: undefined });
    this.paxFormGroup.patchValue({ done: undefined });
    this.stepper.reset();
  }

  stepperFinalStep($event : CreateReservationReq, formGroup: UntypedFormGroup){
    const oldTravelSolutions = TrainUtils.toArray(this.allowedOperations.travel.travelSolutions);
    const travellers = TrainUtils.toArray(this.allowedOperations.travel.travellers);
    const newTravelSolution = {
      ...$event.travelSolution,
      offeredServices: $event.travelSolution.offeredServices.filter(o => o.status === 'SELECTED')
    }
    this.newTravel = {
      ...this.allowedOperations.travel,
      travelSolutions: oldTravelSolutions.concat(newTravelSolution),
      travellers: travellers.concat($event.travellers)
    };
    const postSaleDetails = this.allowedOperations.postSaleDetails.filter(psd => psd.postSaleType.code === 'TRAVEL_CHANGE');
    const request = { travel: this.newTravel, postSaleDetails };
    this.trainService.validateAlternative(this.pnr, request).subscribe(response => {
      this.validatedTravelResponse = response;
      this.newTravel = response.travel;
      formGroup.patchValue({ done: true });
      this.step = 'completed';
      this.stepper.next();
    }, _ => {
      this.stepperReset();
    });
  }

  getTravellers(){
    return this.travellers.map(t => t.doc);
  }
}
