<div class="row">
  <div class="col-2">{{ 'PASSENGERS' | translate}}: {{ticket.body.payload.travellers}}</div>
  <div class="col-10">
    <div class="row">{{'CARRIER'|translate}} </div>
    <div class="row" *ngIf="ticket.body.payload.flights[0].options.carrierIdentification.length>0">
    <span *ngFor="let car of ticket.body.payload.flights[0].options.carrierIdentification;let c = index;" class="singleiata">
      {{car}}
    </span>
    </div>
  </div>
</div>

<div class="row mt-2">
  <div class="col-6" *ngFor="let section of ticket.body.payload.flights[0].sections;let i = index">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-subtitle>
          {{ 'FLIGHT' | translate}} {{i + 1}}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-md-5">
            <div *ngIf="ticket.body.payload.flights[0].sections[i].from.length>0" class="">
                  <span *ngFor="let iata of ticket.body.payload.flights[0].sections[i].from;let ia = index;" class="singleiata">
                    {{iata}}
                  </span>
            </div>
          </div>
          <div class="col-2">
            <mat-icon>chevron_right</mat-icon>
          </div>
          <div class="col-md-5">
            <div *ngIf="ticket.body.payload.flights[0].sections[i].to.length>0" class="">
                <span *ngFor="let iata of ticket.body.payload.flights[0].sections[i].to;let ia = index;" class="singleiata">
                    {{iata}}
                </span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <mat-form-field class="full-width">
            <mat-label>{{ 'DEPARTURE_DATE' | translate}}</mat-label>
            <input matInput [matDatepicker]="jj" placeholder="{{'DEPARTURE_DATE'|translate}}" (click)="jj.open()" [(ngModel)]="section.options.date"
                   name="date{{i}}" disabled>
            <mat-datepicker-toggle matSuffix [for]="jj"></mat-datepicker-toggle>
            <mat-datepicker #jj></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="full-width">
            <mat-label>{{'TIME'|translate}}</mat-label>
            <mat-select [(ngModel)]="section.options.timeSlot" name="time" disabled>
              <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
              <mat-option value="2">{{'MORNING'|translate}} (06-12)</mat-option>
              <mat-option value="3">{{'AFTERNOON'|translate}} (12-18)</mat-option>
              <mat-option value="4">{{'EVENING'|translate}} (18-24)</mat-option>
              <mat-option value="1">{{'NIGHT'|translate}} (00-06)</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="full-width" disabled>
            <mat-label>{{'CABIN'|translate}}</mat-label>
            <mat-select [(ngModel)]="section.options.cabin" name="cabin" disabled>
              <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
              <mat-option value="Y">Ecomonimc</mat-option>
              <mat-option value="W">Ecomonimc Premium</mat-option>
              <mat-option value="C">Business</mat-option>
              <mat-option value="F">First</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
