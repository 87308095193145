import {
  Component,
  OnInit,
} from '@angular/core';
import {
  NavigationEnd,
  Router,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import {
  filter,
  map,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'router-container',
  templateUrl: './router-container.component.html',
  styleUrls: ['./router-container.component.scss'],
})
export class RouterContainerComponent implements OnInit{

  constructor(
    private router : Router,
    private titleService : Title,
    private translate: TranslateService,
  ){
  }

  ngOnInit(){
    this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.router),
        ).subscribe((event) => {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).map(t => this.translate.instant(t)).join(' | ');
        this.titleService.setTitle(title);
      },
    );
  }

  getTitle(state, parent){
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title){
      data.push(parent.snapshot.data.title);
    }

    if (state && parent){
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

}
