import { Component, Input, OnInit } from '@angular/core';
import { CompaniesList } from '../../../classes/companieslist';
import { CompaniesService } from '../../../services/profile/companies.service';
import { SendComponent } from '../notifications/send/send.component';

@Component({
  selector: 'app-companyautocomp',
  templateUrl: './companyautocomp.component.html',
  styleUrls: ['./companyautocomp.component.scss']
})
export class CompanyautocompComponent implements OnInit {

  @Input() query: any;
  @Input() selected: any = [];
  @Input() filtering: any = [];
  companiesList: CompaniesList;
  loading: boolean;
  company: any;
  companytext: string;
  constructor(
    private companiesService: CompaniesService,
    private sendNoti: SendComponent,
  ) {
    this.loading = false;
  }

  ngOnInit() {
  }

  clearFrom() {
    this.companytext = '';
    delete this.company;
    delete this.companiesList;
  }

  selectComp(value) {
    console.log('entro => ',value);
    let find = this.query.sendTo.companies.findIndex(r => r === value._id);
    if ( find == -1 ) {
      this.selected.push(value);
      this.query.sendTo.companies.push(value._id);
      console.log('this.query.sendTo.companies => ',this.query.sendTo.companies);
      delete this.companiesList;
      //this.pnrlistcp.setCpFilter();
    }
    this.clearFrom();
    this.companytext = '';
  }

  selectFirst() {
    if ( this.companiesList ) {
      if ( !this.query.sendTo.companies.findIndex(r => r === this.companiesList[0]._id) ) {
        this.selected.push(this.companiesList[0]);
        this.query.sendTo.companies.push(this.companiesList[0]._id);
        delete this.companiesList;
        //this.pnrlistcp.setCpFilter();
      }
      console.log('this.query.sendTo.companies => ',this.query.sendTo.companies);
    }
    this.clearFrom();
  }

  searchCompany(value) {
    delete this.companiesList;
    this.clearFrom();
    if (value.length >= 2) { this.getCompaniesList(value); }
  }

  getCompaniesList(value) {
    this.clearFrom();
    this.companiesService.getCompaniesAuto(value).subscribe((res) => {
      this.loading = false;
      this.companiesList = res;
      console.log('this.companiesList => ', this.companiesList);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  removeCompany(id){
    let toCp = this.query.sendTo.companies.findIndex(r => r === id);
    let flCp = this.selected.findIndex(r => r._id === id);
    console.log('flCp =>', toCp);
    console.log('flCp =>', flCp);
    if(toCp !== -1){
      this.query.sendTo.companies.splice(toCp, 1);
    }
    if(flCp !== -1){
      this.selected.splice( flCp, 1);
    }
  }


}
