import {
  Component,
  OnInit,
} from '@angular/core';
import { FlightService } from '../../../../services/flight/flight.service';
import {AppComponent} from '../../../../app.component';

interface AuthorizatedServices{
  _id : boolean;
  b2b : boolean;
  tourOperator : boolean;
  residents : boolean;
  etnic : boolean;
  negotiated : boolean;
  corporate : boolean;
  tasf : boolean;
}

@Component({
  selector: 'app-block-issuange-rates-flight',
  templateUrl: './block-issuange-rates-flight.component.html',
  styleUrls: ['./block-issuange-rates-flight.component.scss'],
})


export class BlockIssuangeRatesFlightComponent implements OnInit{

  authsSrvTypes : AuthorizatedServices[] = [];
  constructor(
    private flightAPI : FlightService,
    public appComponent: AppComponent,
  ){ }

  ngOnInit() : void{
    this.flightAPI.getBlockedRates().subscribe(r => this.authsSrvTypes = r);
  }

  update(i) : void{
    this.flightAPI.postBlockedRates(this.authsSrvTypes[i]).subscribe(r => {
      this.flightAPI.getBlockedRates().subscribe(r => this.authsSrvTypes = r);
    });
  }

  getServiceTypes(authorizatedService : AuthorizatedServices): string[]{
    return Object.keys(authorizatedService).filter(t => !['_id', 'b2b'].includes(t));
  }
}
