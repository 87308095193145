import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

import {MatDialog} from '@angular/material/dialog';
import {ProductInfoDialogComponent} from 'src/app/components/shared/product-info-dialog/product-info-dialog.component';
import {SearchesService} from '../../searches/services/searches.service';

@Component({
  selector: 'app-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss']
})
export class SearchCardComponent implements OnInit {
  @Input() search;
  @Input() delete = false;
  @Output() onSearchDeleted = new EventEmitter<any>();

  constructor(private router: Router,
    private searchService : SearchesService,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  approvedSearch(search): number {
    if (!!search.userAuthorisations?.length) {
      const approvedAuth = search.userAuthorisations.filter((auth) => {
        return auth.status === 'approved';
      });
      return approvedAuth.length;
    }
    return 0;
  }

  declinedSearch(search): number {
    if (!!search.userAuthorisations?.length) {
      const declinedAuth = search.userAuthorisations.filter((auth) => {
        return auth.status === 'declined';
      });
      return declinedAuth.length;
    }
    return 0;
  }

  continueBooking(search): void {
    if (search.ref === 'Car') {
      // this.router.navigate(['hotels/' + search._id]);
      this.router.navigate(['/car-rent/reservations/' + search.productDetails._id]);
    }
    if (search.ref === 'Flight') {
      this.router.navigate(['/flight/reservations/' + search.productDetails._id]);
    }
    if (search.ref === 'Hotel') {
      this.router.navigate(['/hotel/reservations/' + search.productDetails._id]);
    }
  }

  deleteSearch(search){
    this.searchService.deleteSearch(search._id).subscribe((res)=>{
      this.onSearchDeleted.emit();
    })
  }


  showProductInfo(){
    const dialogRef = this.dialog.open(ProductInfoDialogComponent, {
      minWidth: '400px',
      disableClose: true,
      data: {
        search: this.search
      }
    });
  }

}
