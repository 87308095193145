import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-rooms-list-dialog',
  templateUrl: './rooms-list-dialog.component.html',
  styleUrls: ['./rooms-list-dialog.component.scss']
})
export class RoomsListDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RoomsListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private router: Router,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  roomSelect(event:any){
    this.dialogRef.close(event);
  }


}
