<h1 mat-dialog-title>{{'CONFIRM_EMISSION'|translate}}</h1>
<div mat-dialog-content>
  <p>{{'CONFIRM EMISSION_QUESTION'|translate}} {{data.reservation.Router.GroupList.Group.Price.Amount|number:'.2-2'}} {{data.reservation.Router.GroupList.Group.Price.Currency}}</p>
  <p *ngIf="data.newPrice !== 0">{{'POST_BOOKING.PRICE_TO_PAY'|translate}}: {{data.newPrice}}</p>
  <p *ngIf="data.prepayed !== 0">{{'POST_BOOKING.PREPAYED_AMOUNT'|translate}}: {{(data.prepayed)}}</p>
  <p *ngIf="data.netTotalPrice !== 0">{{'POST_BOOKING.NET_PRICE'|translate}}: {{data.netTotalPrice}}</p>
  <p *ngIf="data.notRefundablePrice !== 0">{{'POST_BOOKING.NOT_REFUNDABLE'|translate}}: {{data.notRefundablePrice}}</p>
  <p *ngIf="data.fees !== 0">{{'POST_BOOKING.OPERATION_COST'|translate}}: {{data.fees}}</p>
  <p *ngIf="data.creditCardFee !== 0">{{'POST_BOOKING.FEES'|translate}}:{{data.creditCardFee}}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'ABORT'|translate}}</button>
  <button mat-button (click)="data.accept = true" [mat-dialog-close]="data.accept" cdkFocusInitial>{{'BUY_RESERVATION'|translate}}</button>
</div>

