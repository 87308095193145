import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpserviceService} from '../../../services/httpservice.service';
import {Router} from '@angular/router';
import {SnackErrorComponent} from '../../../modules/errorNotification/snackerror/snack-error.component';
import {catchError, retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchInformationService extends HttpserviceService {
  private policyEndpointBis = 'policy/';

  constructor(
    public router: Router,
    public httpClient: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, httpClient, snackComponent);
  }

  getSearch(id: string): Observable<any> {
    const path = this.policyEndpointBis + 'search/' + id;
    return this.http
      .get<any>(this.apiUrl + path, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }
}
