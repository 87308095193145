<div class="editingForm">
  <div class="text-right">
    <button mat-icon-button (click)="closeModal()"><mat-icon>close</mat-icon></button>
  </div>
  <div *ngIf="loading" class="text-center">
    <mat-progress-bar mode="buffer"></mat-progress-bar>
  </div>
  <form *ngIf="traveler">

    <h4>{{'TRAVELER_DETAILS'|translate}}</h4>

    <div class="row">
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>{{'NAME'|translate}}</mat-label>
          <input matInput [(ngModel)]="traveler.name" name="name" required [disabled]="parent==='pnrPage'">
          <mat-icon matSuffix matTooltip="{{'NAME_VALIDATION_TEXT'|translate}}">help_outline</mat-icon>
          <mat-hint class="text-danger" *ngIf="traveler.name.length > 0 && !namesControll(traveler.name)">
            {{'NOT_VALID_FIELD'|translate}}
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>{{'SURNAME'|translate}}</mat-label>
          <input matInput [(ngModel)]="traveler.surname" name="surname" required [disabled]="parent==='pnrPage'">
          <mat-icon matSuffix matTooltip="{{'NAME_VALIDATION_TEXT'|translate}}">help_outline</mat-icon>
          <mat-hint class="text-danger" *ngIf="traveler.surname.length > 0 && !namesControll(traveler.surname)">
            {{'NOT_VALID_FIELD'|translate}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>{{'PHONE'|translate}}</mat-label>
          <input matInput [(ngModel)]="traveler.phoneNumber" name="phone" required [disabled]="parent==='pnrPage'" (ngModelChange)="phoneChange($event)">
          <mat-hint>Sono accettati solo numeri</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>{{'EMAIL'|translate}}</mat-label>
          <input matInput type="email" [(ngModel)]="traveler.email" name="email" [disabled]="parent==='pnrPage'">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>{{'BIRTHDAY'|translate}}</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="traveler.birthday" (dateChange)="dateEvent($event)"
                 [required]="traveler.type === 'CHD' || traveler.type === 'INF' || passPort || traveler.document.number" name="birthday" (click)="picker.open()"
                 readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker touchUi></mat-datepicker>
          <mat-hint class="text-danger" *ngIf="!traveler.birthday && (traveler.type === 'CHD' || traveler.type === 'INF' || passPort || traveler.document.number)">
            {{'MANDATORY_FIELD'|translate}}
          </mat-hint>
        </mat-form-field>

        <div class="mb-2" *ngIf="!traveler.type && traveler.birthday">
          {{'AGE'|translate}} {{calcAge()}} | {{'TYPE'|translate}} {{traveler.type|translate}}
        </div>
        <div class="mb-2 text-danger" *ngIf="ageError">
          {{'AGE_ERROR'|translate}} | {{'PLEASE_INSERT'|translate}} {{traveler.type|translate}}
        </div>
      </div>
      <div class="col-6 align-self-center">

        <mat-form-field class="full-width">
          <mat-label>{{'SEX'|translate}}</mat-label>
          <mat-select [ngModelOptions]="{standalone:true}" [(ngModel)]="traveler.sex"
                      [required]="traveler.birthday || traveler.type === 'CHD' || traveler.type === 'INF' || passPort">
            <mat-option value="M">{{'MALE'|translate}}</mat-option>
            <mat-option value="F">{{'FEMALE'|translate}}</mat-option>
            <mat-option value="X">{{'NOT_SPECIFIED'|translate}}</mat-option>
            <mat-option value="U">{{'UNKNOW'|translate}}</mat-option>
          </mat-select>
          <mat-hint class="text-danger" *ngIf="!traveler.sex && (traveler.birthday || traveler.type === 'CHD' || traveler.type === 'INF' || passPort)">
            {{'MANDATORY_FIELD'|translate}}
          </mat-hint>
        </mat-form-field>
        <p class="errorField" *ngIf="traveler.birthday && !traveler.sex">{{'MANDATORY_FIELD'|translate}}</p>
      </div>
    </div>

    <hr/>
    <h4>{{'PASSPORT'|translate}}</h4>

    <div class="row">
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>{{'PASSPORT_NUMBER'|translate}}</mat-label>
          <input matInput [(ngModel)]="traveler.document.number" name="passport">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>{{'EXPIRY'|translate}}</mat-label>
          <input matInput [matDatepicker]="picker2" (click)="picker2.open()" readonly [(ngModel)]="traveler.document.expiryDate" name="expiryDate">

          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 touchUi></mat-datepicker>
          <mat-hint class="text-danger" *ngIf="traveler.document.number && !traveler.document.expiryDate">{{'MANDATORY_FIELD'|translate}}</mat-hint>
        </mat-form-field>

      </div>

    </div>

    <div class="row">
      <div class="col-6">
        <!--
        <mat-form-field class="full-width">
          <mat-label>{{'NATIONALITY'|translate}}</mat-label>
          <input matInput placeholder="State" [required]="traveler.document.number" aria-label="State" name="nationality" [matAutocomplete]="auto" [(ngModel)]="traveler.document.nationality" (ngModelChange)="filterStates($event)">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let country of filterCountries" [value]="country.code">
              <span>{{country.name}}</span>
            </mat-option>
          </mat-autocomplete>
          <p class="errorField" *ngIf="traveler.document.number && !traveler.document.nationality">{{'MANDATORY_FIELD'|translate}}</p>
        </mat-form-field>-->
        <mat-form-field class="full-width mb-2">
          <mat-label>{{'NATIONALITY'|translate}}</mat-label>
          <mat-select [ngModelOptions]="{standalone:true}" [(ngModel)]="traveler.document.nationality">
            <mat-option [value]="null">-- {{'NATIONALITY'|translate}} --</mat-option>
            <mat-option *ngFor="let country of countriesSl.countries" [value]="country.code">{{country.name}}</mat-option>
          </mat-select>
          <mat-hint class="text-danger" *ngIf="traveler.document.number && !traveler.document.nationality">{{'MANDATORY_FIELD'|translate}}</mat-hint>
          <mat-hint class="text-danger" *ngIf="traveler.document.nationality && !validCountry(traveler.document.nationality)">
            {{'MANDATORY_FIELD'|translate}}
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-6">
        <!--
        <mat-form-field class="full-width">
          <mat-label>{{'ISSUING_COUNTRY'|translate}}</mat-label>
          <input matInput placeholder="State" [required]="traveler.document.number" aria-label="State" name="issuingCountry" [matAutocomplete]="nation" [(ngModel)]="traveler.document.issuingCountry" (ngModelChange)="filteringIssuing($event)">
          <mat-autocomplete #nation="matAutocomplete">
            <mat-option *ngFor="let country of filterIssuing" [value]="country.code">
              <span>{{country.name}}</span>
            </mat-option>
          </mat-autocomplete>
          <p class="errorField" *ngIf="traveler.document.number && !traveler.document.issuingCountry">{{'MANDATORY_FIELD'|translate}}</p>
        </mat-form-field>
        -->
        <mat-form-field class="full-width mb-2">
          <mat-label>{{'ISSUING_COUNTRY'|translate}}</mat-label>
          <mat-select [ngModelOptions]="{standalone:true}" [(ngModel)]="traveler.document.issuingCountry">
            <mat-option [value]="null">-- {{'ISSUING_COUNTRY'|translate}} --</mat-option>
            <mat-option *ngFor="let country of countriesSl.countries" [value]="country.code">{{country.name}}</mat-option>
          </mat-select>
          <mat-hint class="text-danger" *ngIf="traveler.document.number && !traveler.document.issuingCountry">{{'MANDATORY_FIELD'|translate}}</mat-hint>
          <mat-hint class="text-danger" *ngIf="traveler.document.issuingCountry && !validCountry(traveler.document.issuingCountry)">
            {{'MANDATORY_FIELD'|translate}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="traveler.costCenter.length > 0">
      <hr/>
      <h4>{{'COST_CENTER'|translate}}</h4>
      <div class="row">
        <div class="col-12">
          <p *ngIf="traveler.costCenter.length > 0">
            {{traveler.costCenter[0].tag}}
          </p>

        </div>
      </div>
    </div>

    <traveller-loyalty-codes [traveller]="traveler"> </traveller-loyalty-codes>

    <app-frequentflyer [page]="'addressbook'" [id]="traveler._id"></app-frequentflyer>

    <hr/>

    <div class="text-right buttonrow">
      <button type="button" mat-stroked-button (click)="closeModal()">{{'CLOSE'|translate}}</button>
      <button type="button" mat-stroked-button [disabled]="ageError || isInvalidForm() || !namesControll(traveler.name) || !namesControll(traveler.surname)"
              (click)="saveTraveler()">{{'SAVE'|translate}}</button>
    </div>

  </form>

</div>
