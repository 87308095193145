import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SnackErrorComponent } from '../errorNotification/snackerror/snack-error.component';
import { environment } from '../../../environments/environment';
import {OpenAPI} from '../../sdk/hotel';
import {SearchFormRequestDto} from '../../sdk/hotel';
import {SearchFormResponseDto} from '../../sdk/hotel/models/SearchFormResponseDto';
import {SearchService} from '../../sdk/hotel';
import {EstablishmentService, SearchFareRequestDto, SearchFareResponseDto, SearchHotelResultListDto} from '../../sdk/hotel';
import {HttpserviceService} from '../../services/httpservice.service';



@Injectable({
  providedIn: 'root',
})
export class HotelService extends HttpserviceService{

  private readonly hotelUrl : string;

  constructor(
    public router : Router,
    public http : HttpClient,
    public snackComponent : SnackErrorComponent,
    public searchHotelService: SearchService,
    public favoriteService: EstablishmentService,
  ){
    super(router, http, snackComponent);
    this.hotelUrl = environment.apiUrl + 'hotel/';
    this.initSdKV3();
  }

  initSdKV3(){
    if (!this.isTest && !this.isSbt && !this.isGat) {
      OpenAPI.BASE = 'https://api.flyleo.it'
    } else if (!this.isTest && !this.isSbt && this.isGat) {
      OpenAPI.BASE = 'https://api.flygattinoni.it'
    } else if (!this.isTest && this.isSbt && !this.isGat) {
      OpenAPI.BASE = 'https://api.s4t.flygattinoni.it'
    }
    if(this.isTest){
      OpenAPI.BASE = 'https://api.stage.flyleo.it'
    }
    // OpenAPI.BASE = 'http://localhost:3000';
    // OpenAPI.BASE = 'http://192.168.22.79:3000'
    OpenAPI.TOKEN = async () => `${localStorage.getItem('fl_access_token')}`;

  }

  sendHotelSearch(data) : Observable<any>{
    const searchUrl = this.hotelUrl + 'search';
    return this.http
               .post<any>(searchUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  sendHotelSearchV2(data) : Observable<any>{
    // tslint:disable-next-line:max-line-length
    const searchUrl = this.hotelUrl + 'search/facilities?latitude=' + data.destination.coordinates[1] + '&longitude=' + data.destination.coordinates[0] + '&radius=' + data.destination.radius + '&rating=[' + data.options.stars + ']';
    return this.http
               .get<any>(searchUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  sendHotelName(data) : Observable<any>{
    const searchUrl = this.hotelUrl + 'facilities?q=' + data;
    return this.http
               .get<any>(searchUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  searchRate(data) : Observable<any>{
    const searchUrl = this.hotelUrl + 'search';
    return this.http
               .post<any>(searchUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  askHotelInfos(data) : Observable<any>{
    const searchUrl = this.hotelUrl + 'info';
    return this.http
               .post<any>(searchUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  getReservationsList(data) : Observable<any>{
    // tslint:disable-next-line:max-line-length
    console.log('data => ', data);
    const getPnrListUrl = this.hotelUrl + 'pnr?limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
               .get<any>(getPnrListUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  postReservation(data) : Observable<any>{
    const reservationUrl = this.hotelUrl + 'pnr';
    return this.http
               .post<any>(reservationUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  quoteHotelRoom(data) : Observable<any>{
    const reservationUrl = this.hotelUrl + 'enhanced';
    return this.http
      .post<any>(reservationUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }

  getReservation(data) : Observable<any>{
    const getPnrUrl = this.hotelUrl + 'pnr/' + data;
    return this.http
               .get<any>(getPnrUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  getReprice(data) : Observable<any>{
    const getPnrUrl = this.hotelUrl + 'pnr/' + data + '/reprice';
    return this.http
               .get<any>(getPnrUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err, true, 'La camera che hai richiesto non è piu disponibile, prova con una nuova ricerca. ' + err?.errorMessage)),
               );
  }

  cancelReservation(data) : Observable<any>{
    const deletePnrUrl = this.hotelUrl + 'pnr/' + data;
    return this.http
               .delete<any>(deletePnrUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  rateHotels(data) : Observable<any>{
    const searchUrl = this.hotelUrl + 'search/facilities/rate';
    return this.http
               .post<any>(searchUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  bookHotel(data, id) : Observable<any>{
    const searchUrl = this.hotelUrl + 'pnr/' + id + '/book';
    return this.http
               .post<any>(searchUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err, true, 'Riscontrata anomalia; contattare l\'\ assistenza per verificare la prenotazione. ' + err?.errorMessage)),
               );
  }

  getTst(data) : Observable<any>{
    const getTstUrl = this.hotelUrl + 'pnr/' + data + '/cryptic/tst';
    return this.http
               .get<any>(getTstUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  getRetrieveCryptic(data) : Observable<any>{
    const getCrypticUrl = this.hotelUrl + 'pnr/' + data + '/cryptic/retrieve';
    return this.http
               .get<any>(getCrypticUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  saveApprovaId(id, data): Observable<any> {
    const sendUrl = this.hotelUrl + 'pnr/' + id;
    return this.http
      .put<any>(sendUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
  private handleErrorV3(error : HttpErrorResponse, showSnackBar : boolean = true, customMsg? : string){
    let message : any = '';
    if(error.message) {
      message = error.message;
    }
    if (showSnackBar && !error.error?.isTrusted){
      const msg = customMsg ? customMsg : (error.message) ? error.message : JSON.stringify(message);
      this.snackComponent.openSnackBar(msg);
    }
    // return an observable with a user-facing error message
    return throwError(message);
  }
  // -- Private Methods -- //

  getTstHistory(data) : Observable<any>{
    const getTstHistoryUrl = this.hotelUrl + 'pnr/' + data + '/cryptic/history';
    return this.http
               .get<any>(getTstHistoryUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  getTsm(data) : Observable<any>{
    const getTsmUrl = this.hotelUrl + 'pnr/' + data + '/cryptic/tsm';
    return this.http
               .get<any>(getTsmUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }



  sendHotelSearch_V2(data, id) : Observable<any>{
    let searchUrl = '';
    if (id) {
      searchUrl = this.hotelUrl + 'search/fast/' + id + '?time=' + data.time + '&limit=100000';
      return this.http
        .get<any>(searchUrl, this.setHttpOptions())
        .pipe(
          retry(0),
          catchError(err => this.handleError(err)),
        );
    } else {
      searchUrl = this.hotelUrl + 'search/fast' + '?limit=100000';
      return this.http
        .post<any>(searchUrl, data, this.setHttpOptions())
        .pipe(
          retry(0),
          catchError(err => this.handleError(err)),
        );
    }
  }

  sendHotelSearch_V3(data) : Observable<any>{
    return this.sendSearch(data)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  fareHotel_V3(data) : Observable<any>{
    return this.sendSearchFare(data)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  sendSearch(data:SearchFormRequestDto) : Observable<SearchHotelResultListDto>{
    return this.searchHotelService.searchControllerSearch(data);
  }

  sendSearchFare(data:SearchFareRequestDto): Observable<SearchFareResponseDto>{
    return this.searchHotelService.searchControllerFare(data);
  }

  getFiltersSearch_V2(id) : Observable<any>{
    const searchUrl = this.hotelUrl + 'search/fast/' + id + '/filters';
    return this.http
      .get<any>(searchUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );

  }

  getFavorites_V3(limit, skip) : Observable<any>{
    return this.getFav(limit, skip)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  getFav(limit, skip) : Observable<any>{
    return this.favoriteService.establishmentControllerGetFavourites(limit, skip);
  }

  addFavorites_V3(value:string) : Observable<any>{
    return this.addFav(value)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  addFav(value:string): Observable<any>{
    return this.favoriteService.establishmentControllerAddToFavourite(value);
  }

  removeFavorites_V3(value:string) : Observable<any>{
    return this.removeFav(value)
      .pipe(
        retry(0),
        catchError(err => this.handleErrorV3(err)),
      );
  }

  removeFav(value:string): Observable<any>{
    return this.favoriteService.establishmentControllerRemoveFromFavourite(value);
  }

  isFavorite(favorites:any, value:string){
    const index = favorites.findIndex((f:any) => f.code === value);
    if(index !== -1){
      return true;
    }
    return false;
  }

  setHotelCostCenter(id, data):Observable<any> {
    const sendUrl = this.hotelUrl + 'pnr/' + id + '/costCenter';
    // const sendUrl = this.temporaryLowCostUrl + 'postBooking/book';
    return this.http
      .post<any>(sendUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

}
