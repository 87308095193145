import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
@Component({
  selector: 'app-tf-fare-rules',
  templateUrl: './tf-fare-rules.component.html',
  styleUrls: ['./tf-fare-rules.component.scss']
})
export class TfFareRulesComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() fareRules: any;

  constructor() {

  }

  ngOnInit(): void {
    console.log(this.fareRules);
  }

  toText(toSet) {
    // toSet = toSet.replace(new RegExp('\*', 'g'), '');
    toSet = toSet.replace(new RegExp('\n', 'g'), '<br/>');
    toSet = toSet.replace(new RegExp('\r', 'g'), '<br/>');
    toSet = toSet.replace(new RegExp('cancellations', 'ig'), '<span class="orangecolor"><b>CANCELLATIONS</b></span>');
    toSet = toSet.replace(new RegExp('cancellation', 'ig'), '<span class="orangecolor"><b>CANCELLATION</b></span>');
    toSet = toSet.replace(new RegExp('CHANGES', 'ig'), '<span class="orangecolor"><b>CHANGES</b></span>');
    toSet = toSet.replace(new RegExp('PE.PENALTIES', 'ig'), '<span class="orangecolor"><b>sPE.PENALTIES</b></span>');
    return toSet;
  }


  mustBeVisible(){
    for(const f of this.fareRules) {
      if(f.DisplayName.substring(0, 9) === 'Fare Rule'){
        return true;
      }
    }
    return false;
  }

}
