<custom-title></custom-title>
<div class="padding20">

  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. u2" #input>
      </mat-form-field>
    </div>
    <div class="col-6 text-right">
      <button mat-icon-button class="iconButtons" (click)="editIata(null)">
        <mat-icon> add </mat-icon>
      </button>
    </div>

  </div>


  <div class="smallTable" >
    <table mat-table class="w-100" [dataSource]="dataSource" matSort>

      <!-- ID Column -->
      <ng-container matColumnDef="iata" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Iata </th>
        <td mat-cell *matCellDef="let row" (click)="editIata(row)"> {{row.iata}} </td>
      </ng-container>

      <ng-container matColumnDef="disableSegments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Disable Segments </th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.disableSegments">
            <span class="material-icons text-success">
              task_alt
            </span>
          </div>
          <div *ngIf="!row.disableSegments">
            <span class="material-icons text-danger">
              clear
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="blockBook">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Prenotabile </th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="!row.blockBook">
            <span class="material-icons text-success">
              task_alt
            </span>
          </div>
          <div *ngIf="row.blockBook">
            <span class="material-icons text-danger">
              clear
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="blockSearch">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Visibile in ricerca </th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="!row.blockSearch">
            <span class="material-icons text-success">
              task_alt
            </span>
          </div>
          <div *ngIf="row.blockSearch">
            <span class="material-icons text-danger">
              clear
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="blockIssuance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Emissione </th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="!row.blockIssuance">
            <span class="material-icons text-success">
              task_alt
            </span>
          </div>
          <div *ngIf="row.blockIssuance">
            <span class="material-icons text-danger">
              clear
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="issuanceInQueue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Coda Emissione </th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.issuanceInQueue">
            <span class="material-icons text-success">
              task_alt
            </span>
          </div>
          <div *ngIf="!row.issuanceInQueue">
            <span class="material-icons text-danger">
              clear
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="disableVoid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Void prenotazione </th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.disableVoid">
            <span class="material-icons text-danger">
              clear
            </span>
          </div>
          <div *ngIf="!row.disableVoid">
            <span class="material-icons text-success">
              task_alt
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="passport">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Pas. obbligatorio </th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="!row.passport">
            <span class="material-icons text-danger">
              clear
            </span>
          </div>
          <div *ngIf="row.passport">
            <span class="material-icons text-success">
              task_alt
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="commandBeforePricing">
        <th mat-header-cell *matHeaderCellDef> Comandi prima del pricing </th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.commandBeforePricing.length>0">
            <div *ngFor="let command of row.commandBeforePricing">{{command}}</div>
          </div>
          <div *ngIf="row.commandBeforePricing.length==0">Nessun comando</div>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="commandAfterPricing">
        <th mat-header-cell *matHeaderCellDef > Comandi dopo il pricing </th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.commandAfterPricing.length>0">
            <div *ngFor="let command of row.commandAfterPricing">{{command}}</div>
          </div>
          <div *ngIf="row.commandAfterPricing.length==0">Nessun comando</div>
        </td>
      </ng-container>


      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Azioni </th>
        <td mat-cell *matCellDef="let row" class="smallActionButtons">
          <span class="material-icons mr-2" matTooltip="Modifica" (click)="editIata(row)">edit</span>
          <span class="material-icons text-danger" matTooltip="Elimina" (click)="removeIataCall(row)">clear</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell pl-2 " colspan="6">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

  <div class="modal fade" id="editIataModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit IATA</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field class="full-width mb-2">
                <mat-label>Iata Code</mat-label>
                <input [(ngModel)]="editing.iata" matInput placeholder="Placeholder">
              </mat-form-field>

              <section class="example-section">
                <mat-checkbox class="example-margin" [(ngModel)]="editing.disableSegments">Disable segments</mat-checkbox>
              </section>

              <section class="example-section">
                <mat-checkbox class="example-margin" [(ngModel)]="editing.blockBook">Blocca prenotazione</mat-checkbox>
              </section>

              <section class="example-section">
                <mat-checkbox class="example-margin" [(ngModel)]="editing.blockIssuance">Blocca emissione</mat-checkbox>
              </section>

              <section class="example-section">
                <mat-checkbox class="example-margin" [(ngModel)]="editing.issuanceInQueue">Coda emissione</mat-checkbox>
              </section>

              <div *ngIf="editing.issuanceInQueue">
                <mat-form-field class="full-width mb-2">
                  <mat-label>Coda di emissione (es: TRNIG2125/99C0)</mat-label>
                  <input [(ngModel)]="editing.amadeusQueue" matInput placeholder="TRNIG2125/99C0">
                </mat-form-field>
              </div>

              <section class="example-section">
                <mat-checkbox class="example-margin" [(ngModel)]="editing.disableVoid">Void disabilitato</mat-checkbox>
              </section>

              <section class="example-section">
                <mat-checkbox class="example-margin" [(ngModel)]="editing.blockSearch">Blocca in ricerca</mat-checkbox>
              </section>

              <section class="example-section">
                <mat-checkbox class="example-margin" [(ngModel)]="editing.passport">Passaporto obbligatorio</mat-checkbox>
              </section>

              <section class="example-section">
                <mat-checkbox class="example-margin" [(ngModel)]="editing.tourOperator">Tour operator</mat-checkbox>
              </section>

              <section class="example-section">
                <mat-checkbox class="example-margin" [(ngModel)]="editing.ethnic">Tariffe Etniche</mat-checkbox>
              </section>

              <section class="example-section">
                <mat-checkbox class="example-margin" [(ngModel)]="editing.tfNDC">tf NDC</mat-checkbox>
              </section>

              <section class="example-section">
                <mat-checkbox class="example-margin" [(ngModel)]="editing.tfPayOnly">Solo Travel fusion pay</mat-checkbox>
              </section>

              <div *ngIf="editing.blockIssuance">
                <hr/>
                <mat-form-field class="full-width mb-1">
                  <mat-label>Fee per passeggero €</mat-label>
                  <input type="number" [(ngModel)]="editing.feePerPax" matInput placeholder="Fee">
                </mat-form-field>
              </div>

            </div>
            <div class="col-md-6">
              <div class="mb-2" *ngIf="editing.commandBeforePricing.length > 0">
                <mat-form-field class="full-width mb-1" *ngFor="let item of editing.commandBeforePricing; let i = index ; trackBy:trackByFn">
                  <mat-label>Command before pricing {{i+1}}</mat-label>
                  <input [(ngModel)]="editing.commandBeforePricing[i]" matInput placeholder="Command">
                  <mat-icon matSuffix (click)="removeStringBeforeEdit(i)">clear</mat-icon>
                </mat-form-field>
              </div>
              <div class="text-right buttonrow">
                <button mat-button (click)="pushStringBeforeEdit()">Add command before pricing</button>
              </div>

              <div class="mb-2" *ngIf="editing.commandAfterPricing.length > 0">
                <mat-form-field class="full-width mb-1" *ngFor="let item of editing.commandAfterPricing; let i = index ; trackBy:trackByFn">
                  <mat-label>Command after pricing {{i+1}}</mat-label>
                  <input [(ngModel)]="editing.commandAfterPricing[i]" matInput placeholder="Command">
                  <mat-icon matSuffix (click)="removeStringAfterEdit(i)">clear</mat-icon>
                </mat-form-field>
              </div>
              <div class="text-right buttonrow">
                <button mat-button (click)="pushStringAfterEdit()">Add command after pricing</button>
              </div>

            </div>
          </div>
          <hr/>
          <div class="buttonrow text-right">
            <button color="warn" mat-button (click)="editIata(null)">Annulla</button>
            <button color="primary" mat-button *ngIf="editing._id" (click)="editIataCall()" [disabled]="checkForm()">{{'SAVE'|translate}}</button>
            <button color="primary" mat-button *ngIf="!editing._id" (click)="addIataCall()">{{'ADD'|translate}}</button>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>
