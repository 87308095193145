import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
@Component({
  selector: 'app-donutchart',
  templateUrl: './donutchart.component.html',
  styleUrls: ['./donutchart.component.scss']
})
export class DonutchartComponent implements OnInit, OnChanges {
  @Input() chartmode: string;
  @Input() flights: number;
  @Input() hotels: number;
  @Input() trains: number;
  @Input() cars: number;
  chartOptions: any;
  constructor() { }

  ngOnInit() {
    this.buildChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildChart();
  }

  buildChart() {
    this.chartOptions = {
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
      },
      series: [25, 15, 44, 55],
      chart: {
        type: 'donut',
        height: 230,
      },
      labels: [
        'Flights',
        'Hotels',
        'Trains',
        'Cars',
      ],
      theme: {
        palette: 'palette10',
        mode: this.chartmode,
      },
      title: {
        // text: "Client satisfaction"
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };
    if (this.flights) {
      this.chartOptions.series = [this.flights, this.hotels, this.trains, this.cars];
    }
    /*
    this.chartOptions = {
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
      },
      series: [
        {
          name: 'Active reservations',
          data: [80, 50, 30, 40]
        }
      ],
      chart: {
        height: 230,
        type: 'radar'
      },
      title: {
        text: 'Basic Radar Chart'
      },
      xaxis: {
        categories: ['Flights', 'Hotels', 'Trains', 'Cars']
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }; */
  }
}
