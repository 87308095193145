import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SeatAvailability, TransportUnit } from '../../../classes/train.models';
import { TrainUtils } from '../../train.utils';

@Component({
  selector: 'train-seat-map',
  templateUrl: './train-seat-map.component.html',
  styleUrls: ['./train-seat-map.component.scss'],
})
export class TrainSeatMapComponent implements OnInit, AfterViewInit{
  @ViewChild('wagonLayout') wagonLayout : ElementRef;
  @Input() transportUnits : TransportUnit[];
  @Input() prevSelectedSeats : any = {};
  @Input() passengers : any[];
  @Output() selectedSeatEvent = new EventEmitter<any>();

  selectedSeats : any = {};
  currentWagon : TransportUnit;
  currentWagonAvailability : SeatAvailability;
  index = 0;
  selectedPax : number = 0;
  private js = `
      function highlight(seatId, seatDisplayId){
        parent.document.getElementById('seatSel').click();
        parent.document.getElementById(seatId+seatDisplayId).click();
      }`;
  private wagonStyle = `
      .corridoio{
        fill: grey;
      }
      .sedile{
        fill: cornflowerblue;
        cursor: pointer;
      }
      .sediledis{
        fill: dimgrey;
      }
      .sediledis .testo{
        fill: grey;
      }
      .sedileevid{
        fill: dodgerblue;
      }
      .tavolo{
        fill: dimgrey;
      }
      .wc{
        fill: skyblue;
      }
      .testo {
        fill: antiquewhite;
      }
    `;

  constructor(){ }

  ngOnInit() : void{
    this.selectedSeats = this.prevSelectedSeats;
    this.setCurrentWagon();
  }

  ngAfterViewInit(){
    this.setSvg();
  }

  setCurrentWagon(){
    if (this.transportUnits){
      this.transportUnits = TrainUtils.toArray(this.transportUnits);
      this.currentWagon = this.transportUnits[this.index];
      this.currentWagonAvailability = JSON.parse(this.currentWagon.seatsAvailability);
      this.setSvg();
    }
  }

  prevWagon(){
    if (this.index > 0){
      this.index -= 1;
      this.setCurrentWagon();
    }
  }

  nextWagon(){
    if (this.index < this.transportUnits.length){
      this.index += 1;
      this.setCurrentWagon();
    }
  }

  fillSelectedSeat(seat : { number : number; airCraftNumber : string; available : boolean }, wagonId : any, pax? : any){
    if (!!seat && !seat.available || !pax){
      return;
    }
    const newSelection = { ...seat, wagon: wagonId };
    if (!!this.selectedSeats[pax.xmlId] && JSON.stringify(this.selectedSeats[pax.xmlId]) === JSON.stringify(newSelection)){
      return this.fillSelectedSeat(undefined, wagonId, pax);
    }
    this.selectedSeats[pax.xmlId] = newSelection;
    this.selectedSeatEvent.emit(this.selectedSeats);
    this.setSvg();
  }

  isAlreadyPicked(seat : { number : number; airCraftNumber : string; available : boolean }, wagon : any){
    if (!this.selectedSeats){
      return false;
    }
    return !!Object.values(this.selectedSeats).find((s : any) => s.airCraftNumber === seat.airCraftNumber && s.wagon === wagon);
  }

  private setSvg(){
    if (!this.wagonLayout){return;}
    this.wagonLayout.nativeElement.innerHTML = this.currentWagon.layout;
    const js = document.createElement('script');
    js.appendChild(document.createTextNode(this.js));
    const style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(this.wagonStyle));
    this.wagonLayout.nativeElement.appendChild(style);
    this.wagonLayout.nativeElement.appendChild(js);
    Object.values(this.selectedSeats).filter((s : any) => !!s && this.currentWagonAvailability.wagonId === s.wagon).forEach((seatSelected : any) => {
      const seat = document.getElementById('seat_' + seatSelected.number);
      if (!!seat){
        seat.style.fill = 'black';
      }
    });
  }

}
