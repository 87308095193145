<div class="pwa">
  <button color="primary" mat-fab *ngIf="shouldInstall()" class="addToHomeBtn" (click)="installPWA()" matTooltipPosition="before"
          matTooltip="{{'DOWNLOAD_PWA'|translate}}">
    <mat-icon>
        install_mobile
    </mat-icon>
  </button>
</div>


<div *ngIf="showBottomDialog && shouldInstall()">
  <div class="bottomDial showY" id="dialogAnim">
    <div class="text-right mb-2">
      <span class="material-icons" (click)="inoutAnimation(true)">
        highlight_off
      </span>
    </div>
    <div class="row">
      <div class="col-3 align-self-center">
        <img class="vertical mr-2" *ngIf="!appcompo.isSbt && !appcompo.isB2B && !appcompo.isGat" src="assets/img/themes/Logo-Flyleo-blue.png"/>
        <img class="vertical mr-2" *ngIf="appcompo.isB2B" src="assets/img/themes/b2blogo.png"/>
        <img class="vertical mr-2" *ngIf="appcompo.isSbt" src="assets/img/suite.png"/>
        <img class="vertical mr-2" *ngIf="appcompo.isGat" src="assets/img/FlyGattinoniNero.png"/>
      </div>
      <div class="col-9 align-self-center">
        <div>{{helper.translateString('INSTALL_PWA')}}</div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-6 align-self-center">
        <span class="install" (click)="hideMessage()">{{helper.translateString('DONT_ASK_AGAIN')}}</span>
      </div>
      <div class="col-6 align-self-center">
        <div class="text-right">
          <span class="install" (click)="installPWA()">

            {{helper.translateString('INSTALL')}}
            <span class="material-icons ml-2">
            install_mobile
            </span>
          </span>
        </div>
      </div>
    </div>

  </div>
</div>
