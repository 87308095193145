import {Component, Input, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {FlightService} from '../../../../../services/flight/flight.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MainComponent} from '../../../../mainmodule/main/main.component';
import {AppComponent} from '../../../../../app.component';
import {PriceService} from '../../../../../services/price/price.service';
import {IatacompleteService} from '../../../../../services/iatacomplete/iatacomplete.service';
import {DossierService} from '../../../../../services/dossier/dossier.service';
import {CompaniesService} from '../../../../../services/profile/companies.service';

@Component({
  selector: 'app-reservationlist',
  templateUrl: './reservationlist.component.html',
  styleUrls: ['./reservationlist.component.scss']
})

export class ReservationlistComponent implements OnInit {
  currentTab: number;
  reservationlist: any;
  reservationlistAll: any;
  loadingCp: boolean;
  company: any;
  companytext: string;
  companiesList: any;
  @Input() page: string;
  @Input() idcompany: string;
  @Input() iduser: string;
  loading: boolean;
  currentpage: number;
  maxpages: number;
  // setQuery
  query: any;
  limit: number;
  skip: number;
  sort: string;
  order: boolean;
  progress: boolean;
  text: string;
  filterCompanies = [];
  pagenumbers = [];
  curretFlId: string;
  allowedChannels = {
    b2c: false,
    b2b: false,
    cau: false,
    direct: true,
  };

  constructor(
    public appComponent: AppComponent,
    public mainComponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    private flightService: FlightService,
    public priceservice: PriceService,
    // public dateservice: DateService,
    public iataComplete: IatacompleteService,
    private dossierService: DossierService,
  ) {
    this.currentTab = 1;
    this.loading = true;
    this.progress = false;
    console.log('this.appcomponent.loggedUser =>', this.mainComponent.loggedUser);
  }

  ngOnInit() {

    this.limit = 10;
    this.sort = 'createdAt';
    this.order = true;
    this.currentpage = 0;
    this.skip = (this.currentpage) * this.limit;

    this.query = {
      options: {
        tab: 'active',
        companies: [],
        users: [],
        carrier: '',
        salesChannels: ['DIRECT'],
      },
      sort: this.sort,
      limit: this.limit,
      skip: this.skip,
      order: this.order ? 'asc' : 'desc',
      text: '',
    };
    if(this.mainComponent.loggedUser.company.services.b2c){
      this.query.options.salesChannels.push('B2C');
      this.allowedChannels.b2c = true;
    }
    if(this.mainComponent.loggedUser.company.services.b2b){
      this.query.options.salesChannels.push('B2B');
      this.allowedChannels.b2b = true;
    }
    if(!this.mainComponent.loggedUser.role.superAdmin && !this.mainComponent.loggedUser.company.services.b2b && !this.mainComponent.loggedUser.company.services.b2c){
      this.allowedChannels.direct = false;
      this.query.options.salesChannels = [];
    }
    if (!this.page) {
      this.route.queryParams.subscribe(params => {
        this.query.sort = params.sort || this.sort;
        this.query.limit = params.limit || this.limit;
        this.query.skip = params.skip || this.skip;
        this.query.order = params.order || 'asc';
        this.query.text = params.text || '';
        this.query.options = (params.options) ? JSON.parse(params.options) : this.query.options;
        this.text = params.text || '';
        this.currentpage = this.query.skip / this.query.limit;
        if ( this.filterCompanies.length === 0 ) { this.filterCompanies = JSON.parse(JSON.stringify(this.query.options.companies)); }
        this.getReservationList();
      });
    } else {
      if ( this.page === 'user' && this.iduser) { this.query.options.users.push(this.iduser); }
      if ( this.page === 'company' && this.idcompany) { this.query.options.companies.push(this.idcompany); }
      this.navigate();
    }
    if(this.mainComponent.loggedUser.role.superAdmin){
      // this.getAllReservation();
    }

  }

  calculateMargin(a, b, c) {
    return Math.round(((a - b - c) * 100) / 100);
  }

  addOrRemoveSalesChannels(type:string){
    const tipo = type.toUpperCase();
    const find = this.query.options.salesChannels.findIndex((s:any) => s === tipo);
    if(find >=0 ){
      this.query.options.salesChannels.splice(find, 1);
    } else {
      this.query.options.salesChannels.push(tipo);
    }
    this.getReservationList()
  }

  setB2bChannels(event:any){
    this.allowedChannels.b2b = event;
    this.addOrRemoveSalesChannels('B2B');
  }
  setB2cChannels(event:any){
    this.allowedChannels.b2c = event;
    this.addOrRemoveSalesChannels('B2C');
  }
  setDirectChannels(event:any){
    this.allowedChannels.direct = event;
    this.addOrRemoveSalesChannels('DIRECT');
  }
  setCauChannels(event:any){
    this.allowedChannels.cau = event;
    this.addOrRemoveSalesChannels('CAU');
  }
  removeCpFilter(id) {
    const index =  this.filterCompanies.findIndex( r => r._id === id);
    if ( index > -1 ) {
      this.query.options.companies.splice(index, 1);
      this.filterCompanies.splice(index, 1);
      this.currentpage = 0;
      this.query.skip = 0;
      this.navigate();
    }
  }

  clearAllCp() {
    this.query.options.companies = [];
    this.filterCompanies = [];
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  setCpFilter() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  changeTab(value) {
    if (value === 'init') {
      this.query.sort = 'createdAt';
      this.sort = 'createdAt';
    }
    if (value === 'linked' || value === 'withoutTL') {
      this.query.sort = 'generatedAt';
      this.sort = 'generatedAt';
    }
    if (value === 'active') {
      this.query.sort = 'createdAt';
      this.sort = 'createdAt';
    }
    if (value === 'linked' || value === 'expired' || value === 'control') {
      this.query.sort = 'generatedAt';
      this.sort = 'generatedAt';
    }
    if (value === 'departures') {
      this.query.sort = 'generatedAt';
      this.sort = 'generatedAt';
    }
    if (value === 'deadline') {
      this.query.sort = 'timeLimit';
      this.sort = 'timeLimit';
    }
    if (value === 'issued' || value === 'flown') {
      this.query.sort = 'issuedOn';
      this.sort = 'issuedOn';
    }
    if (value === 'cancelled') {
      this.query.sort = 'cancelledAt';
      this.sort = 'cancelledAt';
    }
    if (value === 'deadline') {
      this.order = false;
      this.query.order = 'desc';
    }
    if (this.query.options.tab !== value) {
      this.query.options.tab = value;
      this.currentpage = 0;
      this.query.skip = 0;
      this.navigate();
    }

  }

  sortValue(value) {
    this.query.sort = value;
    this.sort = value;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  reverseOrder() {
    if (!this.order) {
      this.query.order = 'asc';
      this.order = true;
    } else {
      this.query.order = 'desc';
      this.order = false;
    }
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  openFilters() {
    $('.pnrfilters').collapse('toggle');
  }

  setFilterText() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }
  clearFilterText() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.query.text = '';
    this.navigate();
  }
  setFilterIata() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }
  clearFilterIata() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.query.options.carrier = '';
    this.navigate();
  }

  navigate() {
    if (this.page) {
      this.getReservationList();
    } else {
      this.query.options = JSON.stringify(this.query.options);
      this.router.navigate([], {
        queryParams: this.query,
      });
    }
    if(this.mainComponent.loggedUser.role.superAdmin){
      // this.getAllReservation();
    }

  }

  next() {
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev() {
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value) {
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  getAllReservation(){
    const newQuery = {
        sort : this.query.sort,
        limit : 3000,
        skip : 0,
        order : this.query.order,
        text : this.query.text,
        options : this.query.options,
    }
    this.reservationlistAll = {
      list: [],
      count: 0,
    };
    this.flightService.getReservationsList(newQuery).subscribe((res) => {
      const list = res;
      list.list.forEach(element => {
        if (!element.marketingCarrier){
          this.reservationlistAll.list.push(element);
          this.reservationlistAll.count += 1;
        }
      });

    }, error => {
      console.error('Error all reservations => ', error);
    });
  }

  getReservationList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.flightService.getReservationsList(this.query).subscribe((res) => {
      this.loading = false;
      this.reservationlist = res;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      this.scrollTop();
      console.log('this.reservationlist => ', this.reservationlist);
      this.maxpages = Math.ceil((this.reservationlist.count / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }

      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;

      // PASSO IN EXPIRED TUTTE LE PRENOTAZIONI CHE HANNO UN TIME LIMIT NEL PASSATO
      const now = new Date();
      const nowMs = now.getTime();
      // tslint:disable-next-line:prefer-for-of
      for (let pn = 0; pn < this.reservationlist.list.length; pn++) {
        const reservation = this.reservationlist.list[pn];
        if (reservation.timeLimit && reservation.status === 'linked') {
          const limite = new Date(reservation.timeLimit);
          const date2Ms = limite.getTime();

          let differenceMs = date2Ms - nowMs;
          // take out milliseconds
          differenceMs = differenceMs / 1000;

          const days = Math.floor(differenceMs / 24);
          if (days < 0) {
            reservation.status = 'expired';
          }
        }
      }
      this.iataComplete.createToCompletePrnList(this.reservationlist);
    }, error => {
      this.loading = false;
      this.progress = false;
      console.error('Error => ', error);
    });
  }

  // tslint:disable-next-line:variable-name
  returnTabNum(string) {
    if (this.reservationlist) { return this.reservationlist.tabs[string]; }
    if (!this.reservationlist) { return 0; }
  }

  scrollTop() {
    if ( !this.page ) { $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300); }
    if ( this.page ) { $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300); }
  }

  getDifference(limit) {
    const today = new Date();
    const thelimit = new Date(limit);

    const date1Ms = today.getTime();
    const date2Ms = thelimit.getTime();

    // Calculate the difference in milliseconds
    let differenceMs = date2Ms - date1Ms;
    // take out milliseconds
    differenceMs = differenceMs / 1000;

    differenceMs = differenceMs / 60;
    const minutes = Math.floor(differenceMs % 60);
    differenceMs = differenceMs / 60;
    const hours = Math.floor(differenceMs % 24);
    const days = Math.floor(differenceMs / 24);

    // tslint:disable-next-line:max-line-length
    return days + ' ' + this.appComponent.translate.instant('DAYS') + ', ' + hours + ' ' + this.appComponent.translate.instant('HOURS') + ', ' + minutes + ' ' + this.appComponent.translate.instant('MINUTES');

  }

  toggleAddtoTravel(id) {
    if (!this.mainComponent.dossierId) {
      if (id) {
        this.curretFlId = id;
      } else {
        delete this.curretFlId;
      }
      $('#travelListModal').modal('toggle');
    } else if (id && this.mainComponent.dossierId) {
      this.addFlights(id);
    }
  }

  addFlights(id) {
    const body = {
      flights: [id]
    };
    this.dossierService.addFlightsToDossier(this.mainComponent.dossierId, body).subscribe((res) => {
      console.log('res => ', res);
      this.getReservationList();
    }, error => {
      console.error('res => ', error);
    });
  }

  closeAddtoTravel() {
    delete this.curretFlId;
    $('#travelListModal').modal('toggle');
    this.getReservationList();
  }

  clearFrom() {
    this.companytext = '';
    delete this.company;
    delete this.companiesList;
  }

}
