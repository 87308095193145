<custom-title></custom-title>
<div class="padding20">
  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-3 col-lg-12">
      <div *ngIf="!loading" class="cardPnr marginbottom10">
        <div class="padding20">
          <div class="row">
            <div class="col-12 mb-3">
              <div>{{'EDIT_SEARCH'|translate}}</div>
              <hr/>
              <div>
                <mat-form-field appearance="fill" class="full-width">
                  <mat-label>CheckIn & CheckOut</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input [(ngModel)]="hotelForm.checkIn" matStartDate placeholder="checkin">
                    <input [(ngModel)]="hotelForm.checkOut" matEndDate placeholder="checkout">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>

                </mat-form-field>

              </div>

              <div>
                <div class="mb-2">
                  <button (click)="toggleGuestsModal()" class="smallmenu watchFare" mat-button>
                    {{'TARIFFES'|translate}}: {{hotelForm.occupancy.length}} | {{'GUESTS'|translate}}: {{returnTotalGuests()}}
                  </button>
                </div>
              </div>

              <div class="text-right buttonrow">
                <button mat-stroked-button color="primary" (click)="createGuests(true)">Avvia la ricerca</button>
              </div>
            </div>

            <div class="col-12">
              <div class="pr-2">
                <div *ngIf="!fareSelected" class="singleRoomDetails">
                  <div class="row pointer" (click)="setTab(1)">
                    <div class="col-2 text-center align-self-center">
                <span class="material-icons">
                  bed
                </span>
                    </div>
                    <div class="col-9 align-self-center">Nessuna camera selezionata</div>
                  </div>
                </div>

                <div *ngIf="fareSelected">
                  <div class="mb-2">
                    <app-singlehotelrooms (update)="roomSelect($event)" [hotel]="roomSelected.hotel" [i]="0" [room]="roomSelected" [searchRes]="hotelRes" [search]="hotelForm"></app-singlehotelrooms>
                  </div>
                  <div class="buttonrow mb-2">
                    <button (click)="removeRoomSelection()" *ngIf="fareSelected" color="warn" mat-stroked-button type="button">Rimuovi camera</button>
                    <button (click)="bookHotel()" *ngIf="fareSelected && travelerController()" [disabled]="!travelerController()" color="primary" mat-stroked-button type="button">Conferma e prosegui</button>
                    <button (click)="setTab(2)" *ngIf="fareSelected && !travelerController()" color="primary" mat-stroked-button type="button">Inserici ospite/i</button>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>
    <div class="col-xl-9 col-lg-12">
      <div class="cardPnr marginbottom10" *ngIf="!loading && hotelInfos">
        <div class="padding20">
          <div *ngIf="!loading && hotelInfos">
            {{allHotels[0].name}}
          </div>
          <hr/>

          <div class="navigationQuotation">

          </div>

          <mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="selectedTabIndex" animationDuration="0">
            <mat-tab  *ngFor="let tab of tabs; let i = index">
              <ng-template mat-tab-label>
                <mat-icon *ngIf="tab.name==='HOTEL_INFOS'">info</mat-icon>
                <mat-icon *ngIf="tab.name==='TARIFFES'">hotel</mat-icon>
                <mat-icon *ngIf="tab.name==='GUESTS'">directions_walk</mat-icon>
                {{tab.name|translate}}
              </ng-template>
              <div class="pt-3 pb-3 overflow-x-hidden" *ngIf="selectedTabIndex === 0">

                <app-singlehotelinfo [hotelRes]="hotelRes" [images]="images" [allImages]="allImages" [allHotels]="allHotels" [currentGeo]="allHotels[0]" [distance]="allHotels[0].distance" [hotel]="allHotels[0]"
                                     [search]="hotelForm"></app-singlehotelinfo>
              </div>
              <div class="pt-3 pb-3 overflow-x-hidden" *ngIf="selectedTabIndex === 1">
                <div (click)="reloadFilteredRooms()" class="row" style="margin:0;">

                  <div *ngIf="!appComponent.isSbt && !appComponent.isGat" class="col-md-4">
                    <div class="providerFilter">
                      <div class="mb-2">{{'PROVIDER'|translate}}</div>
                      <div (click)="setProvider(provider)" *ngFor="let provider of hotelProviders" class="singleProvider">
                        <span *ngIf="!isCProviderSelected(provider)" class="material-icons">
                          check_box_outline_blank
                        </span>
                        <span *ngIf="isCProviderSelected(provider)" class="material-icons" style="color:cornflowerblue;">
                            check_box
                        </span>
                        {{returnProvider(provider)}}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="providerFilter">
                      <div class="mb-2">{{'REFOUND'|translate}}</div>
                      <div (click)="setRefound(true)" class="singleProvider">
                          <span *ngIf="!isRefoundSelected(true)" class="material-icons">
                            check_box_outline_blank
                          </span>
                        <span *ngIf="isRefoundSelected(true)" class="material-icons" style="color:cornflowerblue;">
                            check_box
                          </span>
                        {{'YES_REFOUND'|translate}}
                      </div>
                      <div (click)="setRefound(false)" class="singleProvider">
                          <span *ngIf="!isRefoundSelected(false)" class="material-icons">
                            check_box_outline_blank
                          </span>
                        <span *ngIf="isRefoundSelected(false)" class="material-icons" style="color:cornflowerblue;">
                            check_box
                          </span>
                        {{'NO_REFOUND'|translate}}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="providerFilter">
                      <div class="mb-2">{{'BOARD'|translate}}</div>
                      <div (click)="setMeal(meal)" *ngFor="let meal of mealsTypes" class="singleProvider">
                          <span *ngIf="!isMealSelected(meal)" class="material-icons">
                            check_box_outline_blank
                          </span>
                        <span *ngIf="isMealSelected(meal)" class="material-icons" style="color:cornflowerblue;">
                            check_box
                        </span>
                        {{meal}}
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-md-4" *ngFor="let room of filteredRoomList; let i = index">

                    <div>
                      <app-singlehotelrooms [isGat]="appComponent.isGat" [isSbt]="appComponent.isSbt" [isB2B]="appComponent.isB2B" (update)="roomSelect($event)" [hotel]="room.hotel" [i]="i" [room]="room" [searchRes]="hotelRes" [search]="hotelForm"></app-singlehotelrooms>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-3 pb-3 overflow-x-hidden" *ngIf="selectedTabIndex === 2">
                <div class="padding20" *ngIf="!roomSelected">
                  Seleziona una camera prima di inserire gli ospiti.
                </div>
                <div *ngIf="roomSelected" class="row">
                  <div *ngFor="let tra of guests;let i = index" class="col-lg-6 mb-3">
                    <div class="fakefield mb-2">
                      {{'TYPE'|translate}}:
                      <span> {{tra.type|translate}}</span>
                    </div>
                    <div class="fakefield mb-2">
                      {{'NAME'|translate}}:
                      <span *ngIf="tra.name"> {{tra.name}}</span>
                      <span *ngIf="!tra.name"> _ _ _ _</span>
                    </div>
                    <div class="fakefield mb-2">
                      {{'SURNAME'|translate}}:
                      <span *ngIf="tra.surname"> {{tra.surname}}</span>
                      <span *ngIf="!tra.surname"> _ _ _ _</span>
                    </div>
                    <div class="fakefield mb-2">
                      {{'PHONE'|translate}}:
                      <span *ngIf="tra.phoneNumber"> {{tra.phoneNumber}}</span>
                      <span *ngIf="!tra.phoneNumber"> _ _ _ _</span>
                    </div>
                    <div class="fakefield mb-2">
                      {{'EMAIL'|translate}}:
                      <span *ngIf="tra.email"> {{tra.email}}</span>
                      <span *ngIf="!tra.email"> _ _ _ _</span>
                    </div>

                    <div class="buttonrow">
                      <button (click)="toggleDialog(i)" *ngIf="!tra.name || !tra.surname" mat-stroked-button>{{'ADD_GUEST'|translate}}</button>
                      <button (click)="removeTraveler(i)" *ngIf="tra.name || tra.surname" mat-stroked-button>{{'REMOVE_GUEST'|translate}}</button>
                      <button (click)="toggleEditDialog(i)" *ngIf="tra.name || tra.surname" mat-stroked-button>{{'EDIT_GUEST'|translate}}</button>
                      <button (click)="loadTravelersList(i)" *ngIf="!tra.name && !tra.surname" mat-stroked-button>{{'LOAD_TRAVELER'|translate}}</button>
                    </div>

                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="text-right" *ngIf="fareSelected && travelerController()">
          <hr/>
          <button class="mb-2 mr-2" (click)="bookHotel()" [disabled]="!travelerController()" color="primary" mat-stroked-button type="button">
            Conferma e prosegui
            <span class="material-icons ml-1">
              arrow_right_alt
            </span>
          </button>
        </div>
      </div>

      <!--
      <div class="pnrPagination" *ngIf="!loading && hotelInfos">
        <div (click)="setTab(i)" *ngFor="let tab of tabs; let i = index" [ngClass]="{'active':tab.active}" class="singleVoice">
          <mat-icon *ngIf="tab.name==='HOTEL_INFOS'">info</mat-icon>
          <mat-icon *ngIf="tab.name==='TARIFFES'">hotel</mat-icon>
          <mat-icon *ngIf="tab.name==='GUESTS'">directions_walk</mat-icon>

          <span class="tabname"> {{tab.name|translate}}</span>
        </div>
      </div>
      -->

      <div *ngIf="!loading && hotelInfos" class="cardPnr marginbottom10">
        <!--
        <div class="padding20" *ngIf="currentTab === 'HOTEL_INFOS'">
          {{'HOTEL_INFOS'|translate}}
          <hr/>
          <app-singlehotelinfo [hotelRes]="hotelRes" [images]="images" [allImages]="allImages" [allHotels]="allHotels" [currentGeo]="allHotels[0]" [distance]="allHotels[0].distance" [hotel]="allHotels[0]"
                               [search]="hotelForm"></app-singlehotelinfo>

        </div>
        -->
        <!--
        <div class="padding20" *ngIf="currentTab === 'TARIFFES'">
          {{'FARES'|translate}}
          <hr/>
          <div (click)="reloadFilteredRooms()" class="row" style="margin:0;">

            <div *ngIf="!appComponent.isSbt && !appComponent.isGat" class="col-md-4">
              <div class="providerFilter">
                <div class="mb-2">{{'PROVIDER'|translate}}</div>
                <div (click)="setProvider(provider)" *ngFor="let provider of hotelProviders" class="singleProvider">
                        <span *ngIf="!isCProviderSelected(provider)" class="material-icons">
                          check_box_outline_blank
                        </span>
                  <span *ngIf="isCProviderSelected(provider)" class="material-icons" style="color:cornflowerblue;">
                            check_box
                        </span>
                  {{returnProvider(provider)}}
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="providerFilter">
                <div class="mb-2">{{'REFOUND'|translate}}</div>
                <div (click)="setRefound(true)" class="singleProvider">
                          <span *ngIf="!isRefoundSelected(true)" class="material-icons">
                            check_box_outline_blank
                          </span>
                  <span *ngIf="isRefoundSelected(true)" class="material-icons" style="color:cornflowerblue;">
                            check_box
                          </span>
                  {{'YES_REFOUND'|translate}}
                </div>
                <div (click)="setRefound(false)" class="singleProvider">
                          <span *ngIf="!isRefoundSelected(false)" class="material-icons">
                            check_box_outline_blank
                          </span>
                  <span *ngIf="isRefoundSelected(false)" class="material-icons" style="color:cornflowerblue;">
                            check_box
                          </span>
                  {{'NO_REFOUND'|translate}}
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="providerFilter">
                <div class="mb-2">{{'BOARD'|translate}}</div>
                <div (click)="setMeal(meal)" *ngFor="let meal of mealsTypes" class="singleProvider">
                          <span *ngIf="!isMealSelected(meal)" class="material-icons">
                            check_box_outline_blank
                          </span>
                  <span *ngIf="isMealSelected(meal)" class="material-icons" style="color:cornflowerblue;">
                            check_box
                        </span>
                  {{meal}}
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="row">
            <div class="col-md-4" *ngFor="let room of filteredRoomList; let i = index">

              <div>
                <app-singlehotelrooms [isGat]="appComponent.isGat" [isSbt]="appComponent.isSbt" [isB2B]="appComponent.isB2B" (update)="roomSelect($event)" [hotel]="room.hotel" [i]="i" [room]="room" [searchRes]="hotelRes" [search]="hotelForm"></app-singlehotelrooms>
              </div>
            </div>
          </div>


        </div>
        -->
        <!--
        <div class="padding20" *ngIf="currentTab === 'GUESTS' && !roomSelected">
          Seleziona una camera prima di inserire gli ospiti.
        </div>
        <div class="padding20" *ngIf="currentTab === 'GUESTS' && roomSelected">
          {{'GUESTS'|translate}}
          <hr/>
          <div class="row">
            <div *ngFor="let tra of guests;let i = index" class="col-lg-6 mb-3">
              <div class="fakefield mb-2">
                {{'TYPE'|translate}}:
                <span> {{tra.type|translate}}</span>
              </div>
              <div class="fakefield mb-2">
                {{'NAME'|translate}}:
                <span *ngIf="tra.name"> {{tra.name}}</span>
                <span *ngIf="!tra.name"> _ _ _ _</span>
              </div>
              <div class="fakefield mb-2">
                {{'SURNAME'|translate}}:
                <span *ngIf="tra.surname"> {{tra.surname}}</span>
                <span *ngIf="!tra.surname"> _ _ _ _</span>
              </div>
              <div class="fakefield mb-2">
                {{'PHONE'|translate}}:
                <span *ngIf="tra.phoneNumber"> {{tra.phoneNumber}}</span>
                <span *ngIf="!tra.phoneNumber"> _ _ _ _</span>
              </div>
              <div class="fakefield mb-2">
                {{'EMAIL'|translate}}:
                <span *ngIf="tra.email"> {{tra.email}}</span>
                <span *ngIf="!tra.email"> _ _ _ _</span>
              </div>

              <div class="buttonrow">
                <button (click)="toggleDialog(i)" *ngIf="!tra.name || !tra.surname" mat-stroked-button>{{'ADD_GUEST'|translate}}</button>
                <button (click)="removeTraveler(i)" *ngIf="tra.name || tra.surname" mat-stroked-button>{{'REMOVE_GUEST'|translate}}</button>
                <button (click)="toggleEditDialog(i)" *ngIf="tra.name || tra.surname" mat-stroked-button>{{'EDIT_GUEST'|translate}}</button>
                <button (click)="loadTravelersList(i)" *ngIf="!tra.name && !tra.surname" mat-stroked-button>{{'LOAD_TRAVELER'|translate}}</button>
              </div>

            </div>
          </div>

        </div>
        -->

      </div>
    </div>
  </div>




</div>
<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="addTravelerModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-add (close)="closeEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [newTraveler]="guests[currentTraveler]" [parent]="'searchResults'"></app-add>
      </div>

    </div>
  </div>
</div>

<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="listTravelerModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div *ngIf="loadTravelers" class="modal-content">

      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-travelerslist (close)="closeListEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [newTraveler]="guests[currentTraveler]" [page]="'searchResults'"
                           [passengers]="guests"></app-travelerslist>
        <hr/>
        <div class="buttonrow text-right">
          <button (click)="closeListEvent($event)" mat-stroked-button>{{'CLOSE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="EditTravelerModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div *ngIf="currentTraveler!=undefined && editingTraveler" class="modal-body">
        <app-edittraveler (close)="closeEditEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [currentTraveler]="guests[currentTraveler]"
                          [parent]="'searchResults'"></app-edittraveler>
      </div>
    </div>
  </div>
</div>

<div aria-hidden="true" aria-labelledby="addGuestModal" class="modal fade" data-backdrop="static" id="addGuestModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addGuestModalTitle">
          {{'TARIFFES'|translate}}: {{hotelForm.occupancy.length}} | {{'GUESTS'|translate}}: {{returnTotalGuests()}}
        </h5>
        <button (click)="toggleGuestsModal()" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div *ngFor="let room of hotelForm.occupancy; let i = index;" class="mb-3 col-lg-6">
            <div class="singleRoom mb-2">
              <div class="roomTitle">
                <span class="material-icons">hotel</span> {{'ROOM'|translate}} {{i + 1}}
              </div>
              <!-- ROOM OPTIONS
              <div class="roomOptions">
                <div class="row">
                  <div class="col-12">{{'ROOM_OPTIONS'|translate}}</div>
                  <div class="col-6">
                    <mat-form-field class="full-width">
                      <mat-label>{{'ROOM_CATEORIES'|translate}}</mat-label>
                      <mat-select multiple [(ngModel)]="room.roomCategory">
                        <mat-option *ngFor="let cat of roomCategories" [value]="cat.code">{{cat.name|translate}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-6">
                    <mat-form-field class="full-width">
                      <mat-label>{{'BED_TYPE'|translate}}</mat-label>
                      <mat-select multiple [(ngModel)]="room.bedTypes">
                        <mat-option *ngFor="let bed of bedTypes" [value]="bed.code">{{bed.name|translate}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                </div>
              </div>
              -->
              <hr/>
              <div class="guestsActions">
                <div class="row">
                  <div class="col-5 align-self-center">
                    {{'ADULTS'|translate}}
                  </div>
                  <div class="col-7 text-right">
                    <button (click)="removeGuestType(i, 10)" [disabled]="returnAdt(i) === 0 || (returnOver(i) === 0 && returnAdt(i) === 1)" aria-label="Minus" class="text-center" mat-icon-button>
                      <mat-icon style="margin:0;">remove</mat-icon>
                    </button>
                    {{returnAdt(i)}}
                    <button (click)="addGuestType(i, 10)" aria-label="Plus" class="text-center" mat-icon-button>
                      <mat-icon style="margin:0;">add</mat-icon>
                    </button>
                  </div>
                </div>
                <!--
                <hr/>

                <div class="row">
                  <div class="col-5 align-self-center">
                    {{'OVER_65'|translate}}
                  </div>
                  <div class="col-7 text-right">
                    <button [disabled]="returnOver(i) === 0 || (returnAdt(i) === 0 && returnOver(i) === 1)" class="text-center" mat-icon-button aria-label="Minus" (click)="removeGuestType(i, 2)">
                      <mat-icon style="margin:0;">remove</mat-icon>
                    </button>
                    {{returnOver(i)}}
                    <button class="text-center" mat-icon-button aria-label="Plus" (click)="addGuestType(i, 2)">
                      <mat-icon style="margin:0;">add</mat-icon>
                    </button>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-5 align-self-center">
                    {{'CHILDREN'|translate}}
                  </div>
                  <div class="col-7 text-right">
                    <button [disabled]="returnChd(i) === 0" class="text-center" mat-icon-button aria-label="Minus" (click)="removeGuestType(i, 5)">
                      <mat-icon style="margin:0;">remove</mat-icon>
                    </button>
                    {{returnChd(i)}}
                    <button class="text-center" mat-icon-button aria-label="Plus" (click)="addGuestType(i, 5)">
                      <mat-icon style="margin:0;">add</mat-icon>
                    </button>
                  </div>
                </div>
                <div *ngIf="returnChd(i) > 0">
                  <hr style="margin:10px 0"/>
                  <p>{{'HOW_OLD_ARE_CHILDREN'|translate}}</p>
                  <div *ngFor="let guest of room.guests; let g = index">
                    <div *ngIf="guest.code === 5">
                      <mat-form-field>
                        <mat-label>{{'CHILD_AGE'|translate}}</mat-label>
                        <mat-select [(ngModel)]="guest.age">
                          <mat-option [value]="0">0 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="1">1 {{'YEAR_OLD'|translate}}</mat-option>
                          <mat-option [value]="2">2 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="3">3 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="4">4 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="5">5 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="6">6 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="7">7 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="8">8 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="9">9 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="10">10 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="11">11 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="12">12 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="13">13 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="14">14 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="15">15 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="16">16 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="17">17 {{'YEARS_OLD'|translate}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                -->
              </div>
              <hr *ngIf="hotelForm.occupancy.length>1"/>
              <div class="buttonrow text-right smallActionButtons">
                <button (click)="removeRoom(i)" *ngIf="hotelForm.occupancy.length>1" mat-stroked-button>
                  {{'REMOVE_ROOM'|translate}}
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <hr/>

      <div class="text-right">
        <!-- <button type="button" mat-button (click)="addRoom()">{{'ADD_ROOM'|translate}}</button> -->
        <button (click)="toggleGuestsModal()" mat-button type="button">{{'CLOSE'|translate}}</button>
      </div>

    </div>
  </div>
</div>


