<div class="text-center" *ngIf="loading">
  <p>{{'LOADING'|translate}}</p>
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>

<div class="padding20" *ngIf="cpSetting">
  <div class="row">
    <div class="col-md-4 elementList">
      <div class="mb-3">{{'TRAVEL_SERVICES'|translate}}</div>
      <div class="singleElement brRadiusTop">
        <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.flight">{{'ALLOW'|translate}} {{'FLIGHT'|translate}} </mat-slide-toggle>
        <div class="border-left mt-2 ml-3  pl-3" *ngIf="cpSetting.flight">
          <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.addonFlight.lowcost">{{'ALLOW'|translate}} {{'Lowcost'|translate}} </mat-slide-toggle>
        </div>
        <div class="border-left mt-2 ml-3  pl-3" *ngIf="cpSetting.flight && !appComponent.isSbt">
          <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.addonFlight.splitPNR">{{'ALLOW'|translate}} {{'Split_PNR'|translate}} </mat-slide-toggle>
        </div>
        <div class="border-left mt-2 ml-3  pl-3" *ngIf="cpSetting.flight && !appComponent.isSbt">
          <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.addonFlight.ndc">{{'ALLOW'|translate}} {{'NDC'|translate}} </mat-slide-toggle>
        </div>
        <div class="border-left mt-2 ml-3  pl-3" *ngIf="cpSetting.flight && !appComponent.isSbt && !appComponent.isGat">
          <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.addonFlight.postBooking_NDC">{{'ALLOW'|translate}} {{'post-Booking NDC'|translate}} </mat-slide-toggle>
        </div>

        <div class="border-left mt-2 ml-3  pl-3" *ngIf="cpSetting.flight && !appComponent.isSbt">
          <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.addonFlight.automaticRefund">{{'ALLOW'|translate}} {{'AUTOMATIC_REFUND'|translate}} </mat-slide-toggle>
        </div>

        <div class="border-left mt-2 ml-3  pl-3" *ngIf="cpSetting.flight && !appComponent.isSbt && !appComponent.isGat">
          <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.addonFlight.mobileMessanger">{{'ALLOW'|translate}} {{'MOBILE_MESSANGER'|translate}} </mat-slide-toggle>
        </div>

        <div class="border-left mt-2 ml-3  pl-3" *ngIf="cpSetting.flight && !appComponent.isSbt && !appComponent.isGat">
          <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.adminPnr">{{'ALLOW'|translate}} {{'ADMIN_PNR'|translate}} </mat-slide-toggle>
        </div>



      </div>
      <div class="singleElement" *ngIf="!appComponent.isGat">
        <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.hotel">{{'ALLOW'|translate}} {{'HOTEL.NAME'|translate}}</mat-slide-toggle>
      </div>
      <div class="singleElement" *ngIf="!appComponent.isGat">
        <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.car">{{'ALLOW'|translate}} {{'CAR'|translate}}</mat-slide-toggle>
      </div>
      <div class="singleElement" *ngIf="!appComponent.isGat">
        <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.train">{{'ALLOW'|translate}} {{'TRAIN'|translate}}</mat-slide-toggle>
        <div class="border-left mt-2 ml-3 pl-3" *ngIf="cpSetting.train">
          <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.addonTrain.trenitalia">{{'ALLOW'|translate}} {{'Trenitalia'|translate}} </mat-slide-toggle>
        </div>
        <div class="border-left mt-2 ml-3 pl-3" *ngIf="cpSetting.train">
          <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.addonTrain.italo">{{'ALLOW'|translate}} {{'Italo'|translate}} </mat-slide-toggle>
        </div>
      </div>
      <div class="singleElement" *ngIf="!appComponent.isSbt && !appComponent.isGat">
        <div>
          <mat-label>{{'TASF'|translate}}</mat-label>
          <mat-select [(ngModel)]="cpSetting.tasf" class="smallWidth ml-2">
            <mat-option *ngFor="let val of ['inherit','enable','disable']" [value]="val">{{val}}</mat-option>
          </mat-select>
        </div>
      </div>
    </div>

    <div class="col-md-4 elementList">
      <div class="mb-3">{{'FUNCTIONALITIES'|translate}}</div>
      <div class="singleElement brRadiusTop" *ngIf="!appComponent.isGat">
        <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.dossier">{{'ALLOW'|translate}} {{'DOSSIER'|translate}}</mat-slide-toggle>
      </div>
      <div class="singleElement" *ngIf="!appComponent.isGat">
        <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.siap">{{'ALLOW'|translate}} {{'SIAP'|translate}} </mat-slide-toggle>
      </div>

      <div class="singleElement" *ngIf="!appComponent.isGat">
        <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.policy">{{'ALLOW'|translate}} {{'Travel policies'|translate}}</mat-slide-toggle>
      </div>

      <div class="singleElement">
        <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.paymentMethods">{{'ALLOW'|translate}} {{'PAYMENT_METHODS'|translate}}</mat-slide-toggle>
      </div>

      <div class="singleElement">
        <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.creditCards">{{'ALLOW'|translate}} {{'CREDIT_CARDS'|translate}}</mat-slide-toggle>
      </div>

      <div class="singleElement" *ngIf="!appComponent.isGat && !appComponent.isB2B && !appComponent.isSbt">
        <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.b2c" [ngClass]="{'mb-2':cpSetting.b2c}">{{'B2C'|translate}}</mat-slide-toggle>
        <div class="pl-2" *ngIf="cpSetting.b2c">
          <div class="mb-2">
            <input class="customInput w-100 mb-1" [(ngModel)]="cpSetting.b2cLink" placeholder="Sottodominio B2C" type="text"/>
            <div class="small">
              indica solo la parte in verde: <span class="text-success">sottodominio</span><span class="ml-1">.b2ctravel</span><span class="ml-1">.it</span>
            </div>
          </div>
        </div>
        <div class="pl-2" *ngIf="cpSetting.b2c">
          <div class="">Markup</div>
          <div >
            <div class="mb-2">
              <input class="customInput w-100 mb-1" [(ngModel)]="cpSetting.markup.ratehawk" placeholder="Ratehawk" type="number"/>
              <div class="small">
                Markup Ratehawk %
              </div>
            </div>
          </div>
          <div class="">
            <div class="mb-2">
              <input class="customInput w-100 mb-1" [(ngModel)]="cpSetting.markup.adalte" placeholder="AdAlte" type="number"/>
              <div class="small">
                Markup adAlte %
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="singleElement" *ngIf="!appComponent.isGat && !appComponent.isSbt">
        <mat-slide-toggle color="primary" [(ngModel)]="cpSetting.customFields">{{'SIAP_CUSTOM_FIELDS'|translate}}</mat-slide-toggle>
      </div>

    </div>

  </div>

  <div class="buttonRow">
    <button mat-button (click)="saveSettings()">{{'SAVE'|translate}}</button>
  </div>

</div>
