<div>
  <custom-title></custom-title>
  <div [ngClass]="{'padding20':!page}">
    <div class="card marginbottom20">

      <div *ngIf="loading" class="loader">
        <div class="card text-center padding20">
          <div class="d-flex justify-content-center">
            <mat-spinner></mat-spinner>
          </div>
          <h5>{{'LOADING'|translate}}...</h5>
        </div>
      </div>
      <div *ngIf="!loading" class="row">

        <div class="col-lg-6 d-md-flex align-items-md-center px-3 pt-3 pt-md-0">
          <button mat-button [matMenuTriggerFor]="tabMenu" class="listButton">
            <span *ngIf="query.options.tab=='active'">{{'ACTIVE_PNR'|translate}} ({{returnTabNum('active')}}) </span>
            <span *ngIf="query.options.tab=='withoutTL'">{{'NO_TIME_LIMIT_PNR'|translate}} ({{returnTabNum('withoutTL')}}) </span>
            <span *ngIf="query.options.tab=='linked'">{{'RESERVATIONS_PNR'|translate}} ({{returnTabNum('linked')}}) </span>
            <span *ngIf="query.options.tab=='waitingList'">{{'WAITING_LIST'|translate}} ({{returnTabNum('waitingList')}}) </span>
            <span *ngIf="query.options.tab=='deadline'">{{'EXPIRING_PNR'|translate}} ({{returnTabNum('deadline')}}) </span>
            <span *ngIf="query.options.tab=='control'">{{'CONTROLL_PNR'|translate}} ({{returnTabNum('control')}}) </span>
            <span *ngIf="query.options.tab=='departures'">{{'DEPARTURE_PNR'|translate}} ({{returnTabNum('departures')}}) </span>
            <span *ngIf="query.options.tab=='issued'">{{'ISSUED_PNR'|translate}} ({{returnTabNum('issued')}}) </span>
            <span *ngIf="query.options.tab=='flown'">{{'FLOWN_PNR'|translate}} ({{returnTabNum('flown')}}) </span>
            <span *ngIf="query.options.tab=='expired'">{{'EXPIRED_PNR'|translate}} ({{returnTabNum('expired')}}) </span>
            <span *ngIf="query.options.tab=='cancelled'">{{'DELETED_PNR'|translate}} ({{returnTabNum('cancelled')}}) </span>
            <span *ngIf="query.options.tab=='init'">{{'TEMPLATES_PNR'|translate}} ({{returnTabNum('init')}}) </span>
            <span class="material-icons">keyboard_arrow_down</span>
          </button>
          <mat-menu #tabMenu="matMenu">
            <button mat-menu-item (click)="changeTab('withoutTL')">{{'NO_TIME_LIMIT_PNR'|translate}} ({{returnTabNum('withoutTL')}})</button>
            <button mat-menu-item (click)="changeTab('active')">{{'ACTIVE_PNR'|translate}} ({{returnTabNum('active')}})</button>
            <button mat-menu-item (click)="changeTab('linked')">{{'RESERVATIONS_PNR'|translate}} ({{returnTabNum('linked')}})</button>
            <button mat-menu-item (click)="changeTab('waitingList')">{{'WAITING_LIST'|translate}} ({{returnTabNum('waitingList')}})</button>
            <button mat-menu-item (click)="changeTab('deadline')">{{'EXPIRING_PNR'|translate}} ({{returnTabNum('deadline')}})</button>
            <button mat-menu-item (click)="changeTab('control')">{{'CONTROLL_PNR'|translate}} ({{returnTabNum('control')}})</button>
            <button mat-menu-item (click)="changeTab('departures')">{{'DEPARTURE_PNR'|translate}} ({{returnTabNum('departures')}})</button>
            <button mat-menu-item (click)="changeTab('issued')">{{'ISSUED_PNR'|translate}} ({{returnTabNum('issued')}})</button>
            <button mat-menu-item (click)="changeTab('flown')">{{'FLOWN_PNR'|translate}} ({{returnTabNum('flown')}})</button>
            <button mat-menu-item (click)="changeTab('expired')">{{'EXPIRED_PNR'|translate}} ({{returnTabNum('expired')}})</button>
            <button mat-menu-item (click)="changeTab('cancelled')">{{'DELETED_PNR'|translate}} ({{returnTabNum('cancelled')}})</button>
            <button mat-menu-item (click)="changeTab('init')">{{'TEMPLATES_PNR'|translate}} ({{returnTabNum('init')}})</button>
          </mat-menu>

          <button mat-button class="listButton" [matMenuTriggerFor]="orderMenu">
            {{sort|translate}}<span class="material-icons">keyboard_arrow_down</span>
          </button>
          <mat-menu #orderMenu="matMenu">
            <button mat-menu-item (click)="sortValue('createdAt')">{{'CREATED_DATE'|translate}}</button>
            <button mat-menu-item (click)="sortValue('generatedAt')">{{'GENERATED_DATE'|translate}}</button>
            <button mat-menu-item *ngIf="query.options.tab=='issued'" (click)="sortValue('issuedOn')">{{'ISSUED_DATE'|translate}}</button>
            <button mat-menu-item *ngIf="query.options.tab=='linked'|| query.options.tab=='deadline'" (click)="sortValue('timeLimit')">{{'EXPIRY_DATE'|translate}}</button>
            <button mat-menu-item *ngIf="query.options.tab=='cancelled'" (click)="sortValue('cancelledAt')">{{'CANCELLATION_DATE'|translate}}</button>
            <button mat-menu-item (click)="sortValue('fare.amount')">{{'PRICE'|translate}}</button>

          </mat-menu>

          <button mat-icon-button class="iconButtons" (click)="reverseOrder()">
            <mat-icon *ngIf="query.order==='asc'" matTooltip="{{'ASC'|translate}}" >vertical_align_top</mat-icon>
            <mat-icon *ngIf="query.order==='desc'" matTooltip="{{'DESC'|translate}}" >vertical_align_bottom</mat-icon>
          </button>

          <button mat-icon-button class="iconButtons" (click)="openFilters()">
            <mat-icon matTooltip="{{'SEARCH'|translate}}" >search</mat-icon>
          </button>

          <button mat-icon-button class="iconButtons" matTooltip="{{'CONTROLL_PNR'|translate}}" (click)="changeTab('control')" matBadgeColor="accent" matBadge="{{returnTabNum('control')}}">
            <mat-icon>warning_amber</mat-icon>
          </button>

          <ng-container *ngIf="!appComponent.isGat && !appComponent.isSbt && (mainComponent.loggedUser.role.superAdmin || mainComponent.loggedUser.company.services.b2b || mainComponent.loggedUser.company.services.b2c)">
            <mat-checkbox color="primary" [disabled]="query.options.salesChannels.length == 1 && allowedChannels.direct"
                          class="checkBoxes vertical ml-2 m-md-1 d-inline-block" [(ngModel)]="allowedChannels.direct" (ngModelChange)="setDirectChannels($event)">
              flyLeo
            </mat-checkbox>
          </ng-container>

          <ng-container *ngIf="(mainComponent.loggedUser.company.services.b2b || mainComponent.loggedUser.role.superAdmin) && !appComponent.isGat && !appComponent.isSbt">
            <mat-checkbox color="primary" [disabled]="query.options.salesChannels.length == 1 && allowedChannels.b2b"
                          class="checkBoxes vertical ml-2 m-md-1 d-inline-block" [(ngModel)]="allowedChannels.b2b" (ngModelChange)="setB2bChannels($event)">
              B2B
            </mat-checkbox>
          </ng-container>

          <ng-container *ngIf="(mainComponent.loggedUser.company.services.b2c || mainComponent.loggedUser.role.superAdmin) && !appComponent.isGat && !appComponent.isSbt">
            <mat-checkbox color="primary" [disabled]="query.options.salesChannels.length == 1 && allowedChannels.b2c"
                          class="checkBoxes vertical ml-2 m-md-1 d-inline-block" [(ngModel)]="allowedChannels.b2c" (ngModelChange)="setB2cChannels($event)">
              B2C
            </mat-checkbox>
          </ng-container>

          <ng-container *ngIf="mainComponent.loggedUser.role.superAdmin && !appComponent.isGat">
            <mat-checkbox color="primary" [disabled]="query.options.salesChannels.length == 1 && allowedChannels.cau"
                          class="checkBoxes vertical ml-2 m-md-1 d-inline-block"
                          [(ngModel)]="allowedChannels.cau"
                          (ngModelChange)="setCauChannels($event)">
              Altre IATA
            </mat-checkbox>
          </ng-container>
        </div>

        <div class="col-lg-6 d-flex justify-content-end text-right pb-2 pb-md-0">
          <div class="paginationr pr-3">
            <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
            <span class="mr-2">
              {{'PAGE'|translate}}
              <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
                <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
                  <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p+1}}</mat-option>
                </mat-select>
              </mat-form-field>
              {{'OF'|translate}} {{maxpages}}
            </span>
            <button mat-icon-button class="iconButtons" (click)="prev()" [disabled]="currentpage==0">
              <mat-icon>chevron_left</mat-icon>
            </button>
            <button mat-icon-button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)">
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>

      </div>

      <div *ngIf="!loading" class="collapse pnrfilters padding10">
        <hr/>
        <div class="row marginbottom10">
          <div class="col-12 col-sm-6 col-md-4 mb-2">
            <form autocomplete="off" class="example-form">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'SEARCH_PNR'|translate}}</mat-label>
                <input matInput type="text" [(ngModel)]="query.text" [ngModelOptions]="{standalone: true}" (change)="setFilterText()">
                <!--
                <button mat-button *ngIf="query.text" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilterText()">
                  <mat-icon>close</mat-icon>
                </button>
                -->
                <mat-hint align="start">{{'DIGIT_YOUR_SEARCH'|translate}}</mat-hint>
                <mat-hint style="color:red;cursor:pointer;" *ngIf="query.text" (click)="clearFilterText()" align="end">{{'CLEAR_SEARCH'|translate}} X</mat-hint>
              </mat-form-field>
            </form>

          </div>
          <div class="col-12 col-sm-6 col-md-4 mb-2">
            <form autocomplete="off" class="example-form">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'SEARCH_BY_IATA'|translate}}</mat-label>
                <input [ngModelOptions]="{standalone: true}" autocomplete="off" matInput type="text" [(ngModel)]="query.options.carrier" (change)="setFilterIata()">
                <!--
                <button mat-button *ngIf="query.options.carrier" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilterIata()">
                  <mat-icon>close</mat-icon>
                </button> -->
                <mat-hint align="start">{{'DIGIT_YOUR_SEARCH'|translate}}</mat-hint>
                <mat-hint style="color:red;cursor:pointer;" *ngIf="query.options.carrier" (click)="clearFilterIata()" align="end">{{'CLEAR_SEARCH'|translate}} X</mat-hint>
              </mat-form-field>
            </form>
          </div>
          <div class="col-12 col-sm-6 col-md-4 mb-2" *ngIf="mainComponent.loggedUser.role.superAdmin && !page">
            <!-- <app-companies [filtering]="filterCompanies" [query]="query"></app-companies> -->
            <app-autocomplete-cm [filtering]="filterCompanies" [query]="query" (onChange)="setCpFilter()"></app-autocomplete-cm>

          </div>
          <div class="col-12 mb-2 cpfilter" *ngIf="filterCompanies.length>0">
            <hr/>
            <h6 class="mb-2">{{'FILTERING_COMPANIES'|translate}}</h6>
            <div *ngIf="filterCompanies.length>0 && !page">
              <mat-chip-listbox aria-label="Companies selections" class="d-inline" *ngIf="filterCompanies[0].name">
                <mat-chip-option *ngFor="let element of filterCompanies">
                  {{element.name}} <mat-icon (click)="removeCpFilter(element._id)" matChipRemove>cancel</mat-icon>
                </mat-chip-option>

              </mat-chip-listbox>
              <div *ngIf="!filterCompanies[0].name">
                {{filterCompanies.length}} {{'COMPANIES_SELECTED'|translate}}
                <button mat-button (click)="clearAllCp()">{{'CLEAR_ALL_CP'|translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="" *ngIf="progress">
        <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
      </div>

    </div>

    <div class="">

      <div class="alert alert-warning padding15 mb-2" *ngIf="!appComponent.isGat">{{'TIME_LIMIT_TXT'|translate}}</div>
      <div class="alert alert-warning padding15 mb-2" *ngIf="appComponent.isGat">{{'TIME_LIMIT_TXT_GAT'|translate}}</div>

      <div class="pnrlist" *ngIf="reservationlist">

        <mat-card appearance="outlined" class="example-card mb-3" [ngClass]="{'corporateBorder':reservation.corporate && reservation.negotiated}" *ngFor="let reservation of reservationlist.list;let i = index">
          <mat-card-header>
            <div mat-card-avatar class="example-header-image" style="background-image: url('/assets/img/airline/icon/{{reservation.marketingCarrier}}.png')"></div>
            <mat-card-title>
              <span *ngIf="reservation.locator && !reservation.lowCost">{{reservation.locator}}</span>
              <span *ngIf="reservation.carriersLocators && reservation.carriersLocators.length > 0 && reservation.lowCost && reservation.status != 'init'">
                <span>{{reservation.locator}}</span>
              </span>

              <span class="smIcon" *ngIf="reservation.negotiated && !mainComponent.appcomponent.isSbt && !mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isGat">
                <mat-icon color="accent"
                          [matTooltipClass]="'my-tooltip'"
                          matTooltip="{{'PUBLIC_FARE'|translate}}: {{reservation.fare.publicAmount|number:'.2-2'}}€ &#13;
                              {{'NET_FARE'|translate}}: {{reservation.fare.amount|number:'.2-2'}}€ &#13;
                              {{'FLYLEO_FEE'|translate}}: {{reservation.fare.fees|number:'.2-2'}}€ &#13;
                              {{'NET_DISCOUNT'|translate}}: {{calculateMargin(reservation.fare.publicAmount,reservation.fare.amount, reservation.fare.fees)|number:'.2-2'}}€">
                  star_border
                </mat-icon>
              </span>
              <span class="smIcon" *ngIf="reservation.negotiated && !mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt && mainComponent.appcomponent.isGat">
                <mat-icon color="accent"
                          [matTooltipClass]="'my-tooltip'"
                          matTooltip="{{'PUBLIC_FARE'|translate}}: {{reservation.fare.publicAmount|number:'.2-2'}}€ &#13;
                              {{'NET_FARE'|translate}}: {{reservation.fare.amount|number:'.2-2'}}€ &#13;
                              {{'FLYGAT_FEE'|translate}}: {{reservation.fare.fees|number:'.2-2'}}€ &#13;
                              {{'NET_DISCOUNT'|translate}}: {{calculateMargin(reservation.fare.publicAmount,reservation.fare.amount, reservation.fare.fees)|number:'.2-2'}}€">
                  star_border
                </mat-icon>
              </span>
              <span class="smIcon" *ngIf="reservation.negotiated && (mainComponent.appcomponent.isB2B || mainComponent.appcomponent.isSbt)">
                <mat-icon color="accent"
                          [matTooltipClass]="'my-tooltip'"
                          matTooltip="{{'DISCOUNT_FARE'|translate}}">
                  star_border
                </mat-icon>
              </span>
              <span class="smIcon" *ngIf="reservation.notes && reservation.notes.price">
                <mat-icon color="accent" matTooltip="{{'PRICE_MUTED'|translate}} {{reservation.notes.price|number:'.2-2'}}€">
                  euro
                </mat-icon>
              </span>
            </mat-card-title>
            <mat-card-subtitle>
              {{iataComplete.returnCarrier(reservation.marketingCarrier)}}
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="mb-3">
              <span *ngIf="reservation.status==='init'">{{'TEMPLATE'|translate}}</span>
              <span class="pnrNewStatus linkedNew" *ngIf="reservation.status==='linked'">
                <span class="smalIcon material-icons">done</span> {{'LINKED'|translate}}
              </span>
              <span class="pnrNewStatus issuedNew" *ngIf="reservation.status==='issued'">
                <span class="smalIcon material-icons">task_alt</span> {{'ISSUED'|translate}}
              </span>
              <span class="pnrNewStatus issuedNew" *ngIf="reservation.status==='flown'">
                <span class="smalIcon material-icons">task_alt</span> {{'FLOWN'|translate}}
              </span>
              <span class="pnrNewStatus expiredNew" *ngIf="reservation.status==='expired'">
                <span class="smalIcon material-icons">close</span> {{'EXPIRED'|translate}}
              </span>
              <span class="pnrNewStatus cancelledNew" *ngIf="reservation.status==='cancelled'">
                <span class="smalIcon material-icons">close</span> {{'CANCELLED'|translate}}
              </span>
            </div>
            <div class="row mb-2">
              <div class="col-lg-3">
                <div *ngIf="reservation.status!=='init' && !reservation.lowCost" class="dateaction">
                  {{'BOOKED_ON'|translate}} {{reservation.createdAt|date:'dd/MM/yyyy - HH:mm'}}
                </div>
                <div *ngIf="reservation.status==='issued'" class="dateaction">
                  <span *ngIf="!reservation.lowCost">{{'ISSUED_ON'|translate}} {{reservation.issuedOn|date:'dd/MM/yyyy - HH:mm'}}</span>
                  <span *ngIf="reservation.lowCost">{{'ISSUED_ON'|translate}} {{reservation.createdAt|date:'dd/MM/yyyy - HH:mm'}}</span>
                </div>

                <div *ngIf="reservation.changed" class="variationpnr">
                  <span class="smalIcon material-icons">warning</span> {{'CHANGE_PNR'|translate}}
                </div>

                <div *ngIf="reservation.status==='linked'">
                  <div class="ssrtimelimit marginbottom5" *ngIf="mainComponent.loggedUser.role.superAdmin && reservation.calculatedTimeLimit">
                    [SSR: {{reservation.calculatedTimeLimit|date:'dd/MM/yyyy- HH:mm'}}]
                  </div>
                  <div class="timelimit" *ngIf="reservation.timeLimit">
                    <mat-icon>
                      timer
                    </mat-icon>
                    {{reservation.timeLimit|date:'dd/MM/yyyy- HH:mm'}}
                    <div class="counterlimit">{{getDifference(reservation.timeLimit)}}</div>
                  </div>

                  <div class="waitingtimelimit" *ngIf="!reservation.timeLimit">Time limit in attesa</div>
                </div>

              </div>
              <div class="col-lg-5">
                <div class="row newItemPnr" *ngFor="let itinerary of reservation.itineraries;let l = last" [ngClass]="{'mb-2':!l}">
                  <div *ngIf="!itinerary.flights[0]" class="text-danger">
                    <span class="material-icons vertical-align">warning</span> Attenzione: possibile errore di importazione
                  </div>
                  <div class="col-5" *ngIf="itinerary.flights[0]">
                    <b>{{itinerary.flights[0].departure.dateTime|date:'dd/MM/yyyy - HH:mm':"+0000"}}</b>
                    <br/>
                    {{iataComplete.returnAirport(itinerary.flights[0].departure.location)}}
                    <sup><b>{{itinerary.flights[0].departure.location}}</b></sup>
                  </div>
                  <div class="col-2 align-self-center" *ngIf="itinerary.flights[itinerary.flights.length-1]">
                    <span class="material-icons">arrow_right_alt</span>
                  </div>
                  <div class="col-5" *ngIf="itinerary.flights[itinerary.flights.length-1]">
                    <b>{{itinerary.flights[itinerary.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy - HH:mm':"+0000"}}</b>
                    <br/>
                    {{iataComplete.returnAirport(itinerary.flights[itinerary.flights.length-1].arrival.location)}}
                    <sup><b>{{itinerary.flights[itinerary.flights.length-1].arrival.location}}</b></sup>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div *ngIf="reservation.passengers.length>0">
                  <div *ngFor="let passenger of reservation.passengers" class="">
                    <span *ngIf="passenger.surname">{{passenger.surname}}</span> <span *ngIf="passenger.name"> {{passenger.name}}</span> <sup> ({{passenger.type}})</sup>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 text-center">
                <div class="reservationPrice marginbottom5" *ngIf="!mainComponent.appcomponent.isB2B">
                <span *ngIf="reservation.negotiated && priceservice.isNegoHidden()" class="recoprice">
                  {{priceservice.returnPrice(reservation.fare.publicAmount)|number:'.2-2'}}€
                </span>
                  <span *ngIf="reservation.negotiated && !priceservice.isNegoHidden()" class="recoprice">
                  {{priceservice.returnPrice(reservation.fare.amount+(reservation.fare.fees||0))|number:'.2-2'}}€
                </span>
                  <span *ngIf="!reservation.negotiated" class="recoprice">
                  {{priceservice.returnPrice(reservation.fare.amount)|number:'.2-2'}}
                    <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                  <span *ngIf="!reservation.fare.currency">€</span>
                </span>
                  <span *ngIf="reservation.fare.services != 0" class="smalltext">
                  <br/>
                    {{'SERVICES'|translate}}: {{reservation.fare.services|number:'.2-2'}}
                    <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                  <span *ngIf="!reservation.fare.currency">€</span>
                </span>
                </div>
                <div class="reservationPrice marginbottom5" *ngIf="mainComponent.appcomponent.isB2B">
                <span *ngIf="reservation.negotiated" class="recoprice">
                  {{priceservice.returnPrice(reservation.fare.publicAmount - reservation.fare.fees)|number:'.2-2'}}€
                </span>
                  <span *ngIf="!reservation.negotiated" class="recoprice">
                  {{priceservice.returnPrice(reservation.fare.amount)|number:'.2-2'}}
                    <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                  <span *ngIf="!reservation.fare.currency">€</span>
                </span>
                  <span *ngIf="reservation.fare.services != 0" class="smalltext">
                  <br/>
                    {{'SERVICES'|translate}}: {{reservation.fare.services|number:'.2-2'}}
                    <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                  <span *ngIf="!reservation.fare.currency">€</span>
                </span>
                </div>
              </div>

            </div>
            <div *ngIf="reservation.notes && reservation.notes.text" class="row notesicons mb-1">
              <div class="col-12">
                <hr/>
                <span class="smalIcon material-icons">comment</span>
                <span>{{reservation.notes.text}}</span>
              </div>
            </div>
            <div class="row pnrActionRow pt-2 pb-2">
              <div class="col-lg-6">
                <div class="mt-2">
                  <a routerLink="/users/list/{{reservation.user._id}}">{{reservation.user.surname}} {{reservation.user.name}}</a>
                  <span class="material-icons">arrow_right_alt</span>
                  <a routerLink="/companies/list/{{reservation.company._id}}">{{reservation.company.name}}</a>
                </div>
              </div>
              <div class="col-lg-6 text-right buttonrowBis">
                <button mat-stroked-button  *ngIf="!page && (reservation.status==='linked' || reservation.status==='issued') && !reservation.paper && (appComponent.isSbt || appComponent.isB2B || mainComponent.loggedUser.role.superAdmin) && !appComponent.isGat" (click)="toggleAddtoTravel(reservation._id)">
                  {{'ADD_TO_FOLDER'|translate}}
                </button>
                <a mat-stroked-button *ngIf="reservation.paper" routerLink="/dossier/list/{{reservation.paper}}">
                  {{'GO_TO_DOSSIER'|translate}}
                </a>
                <a color="primary" class="ml-2" mat-flat-button routerLink="/flight/reservations/{{reservation._id}}">
                  {{'OPEN_RESERVATION'|translate}}
                </a>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <!--
        <div class="marginbottom20 singlepnr" *ngFor="let reservation of reservationlist.list;let i = index">
          <div *ngIf="reservation.notes || reservation.negotiated" class="text-right notesicons">
            <div class="textnotes" *ngIf="reservation.notes && reservation.notes.text">
              <div [innerHTML]="reservation.notes.text"></div>
            </div>
            <span *ngIf="reservation.negotiated && !mainComponent.appcomponent.isB2B">
              <mat-icon accent color="accent"
                        [matTooltipClass]="'my-tooltip'"
                        matTooltip="{{'PUBLIC_FARE'|translate}}: {{reservation.fare.publicAmount|number:'.2-2'}}€ &#13;
                            {{'NET_FARE'|translate}}: {{reservation.fare.amount|number:'.2-2'}}€ &#13;
                            {{'FLYLEO_FEE'|translate}}: {{reservation.fare.fees|number:'.2-2'}}€ &#13;
                            {{'NET_DISCOUNT'|translate}}: {{reservation.fare.margin|number:'.2-2'}}€">
                star_border
              </mat-icon>
            </span>
            <span *ngIf="reservation.negotiated && mainComponent.appcomponent.isB2B">
              <mat-icon accent color="accent"
                        [matTooltipClass]="'my-tooltip'"
                        matTooltip="{{'DISCOUNT_FARE'|translate}}">
                star_border
              </mat-icon>
            </span>
            <span *ngIf="reservation.notes && reservation.notes.price">
              <mat-icon color="accent" matTooltip="{{'PRICE_MUTED'|translate}} {{reservation.notes.price|number:'.2-2'}}€">
                euro
              </mat-icon>
            </span>

            <hr/>
          </div>
          <div class="row">
            <div class="col-9 align-self-center">
              <div class="row">
                <div class="col-lg-4 col-md-6 marginbottom5 align-self-center">
                  <div *ngIf="!page && (reservation.status==='linked' || reservation.status==='issued') && !reservation.paper" class="buttonrow mb-2">
                    <button mat-stroked-button (click)="toggleAddtoTravel(reservation._id)">{{'ADD_TO_FOLDER'|translate}}</button>
                  </div>
                  <div *ngIf="reservation.paper" class="buttonrow mb-2">
                    <a mat-stroked-button routerLink="/dossier/list/{{reservation.paper}}">{{'GO_TO_DOSSIER'|translate}}</a>
                  </div>
                  <div class="pnrliststatus marginbottom5">
                    <span class="init" *ngIf="reservation.status==='init'">{{'TEMPLATE'|translate}}</span>
                    <span class="linked" *ngIf="reservation.status==='linked'">{{'LINKED'|translate}}</span>
                    <span class="issued" *ngIf="reservation.status==='issued'">{{'ISSUED'|translate}}</span>
                    <span class="flown" *ngIf="reservation.status==='flown'">{{'FLOWN'|translate}}</span>
                    <span class="expired" *ngIf="reservation.status==='expired'">{{'EXPIRED'|translate}}</span>
                    <span class="cancelled" *ngIf="reservation.status==='cancelled'">{{'CANCELLED'|translate}}</span>

                    <div *ngIf="reservation.locator && !reservation.lowCost" class="listlocator" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
                      <span>{{reservation.locator}}</span>
                    </div>
                    <div *ngIf="reservation.carriersLocators && reservation.carriersLocators.length > 0 && reservation.lowCost && reservation.status != 'init'" class="pnrlocator" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
                      <span>{{reservation.carriersLocators[0].locator}}</span>
                    </div>

                    <div *ngIf="reservation.changed" class="variationpnr">
                      <mat-icon>warning</mat-icon> {{'CHANGE_PNR'|translate}}
                    </div>
                  </div>

                  <div *ngIf="reservation.status==='linked'">
                    <div class="ssrtimelimit marginbottom5" *ngIf="mainComponent.loggedUser.role.superAdmin && reservation.calculatedTimeLimit">
                      [SSR: {{reservation.calculatedTimeLimit|date:'dd/MM/yyyy- HH:mm'}}]
                    </div>
                    <div class="timelimit" *ngIf="reservation.timeLimit">
                      <mat-icon>
                        timer
                      </mat-icon>
                      {{reservation.timeLimit|date:'dd/MM/yyyy- HH:mm'}}
                      <div class="counterlimit">{{getDifference(reservation.timeLimit)}}</div>
                    </div>

                    <div class="waitingtimelimit" *ngIf="!reservation.timeLimit">Time limit in attesa</div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 marginbottom5 align-self-center">
                  <hr class="d-sm-block d-md-none d-lg-none"/>
                  <div class="travelinfo">
                    <div *ngFor="let itinerary of reservation.itineraries;let l = last" [ngClass]="{'marginbottom5':!l}">
                      <div class="pnrdeparture">
                        {{itinerary.flights[0].departure.dateTime|date:'dd/MM/yyyy'}} -
                        {{itinerary.flights[0].departure.dateTime|date: 'HH'}}:{{itinerary.flights[0].departure.dateTime|date:'mm'}}
                      </div>
                      <span class="locationspan" [matTooltip]="itinerary.flights[0].departure.location">
                        {{iataComplete.returnAirport(itinerary.flights[0].departure.location)}} <sup>{{itinerary.flights[0].departure.location}}</sup>
                      </span>
                      <mat-icon>arrow_right_alt</mat-icon>
                      <span class="locationspan" [matTooltip]="itinerary.flights[itinerary.flights.length-1].arrival.location">
                        {{iataComplete.returnAirport(itinerary.flights[itinerary.flights.length-1].arrival.location)}} <sup>{{itinerary.flights[itinerary.flights.length-1].arrival.location}}</sup>
                      </span>

                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 marginbottom5 align-self-center">
                  <hr class="d-md-block d-lg-none"/>
                  <div *ngIf="reservation.status!=='init'" class="dateaction">
                    {{'BOOKED_ON'|translate}} {{reservation.createdAt|date:'dd/MM/yyyy - HH:mm'}}
                  </div>
                  <div *ngIf="reservation.status==='issued'" class="dateaction">
                    {{'ISSUED_ON'|translate}} {{reservation.issuedOn|date:'dd/MM/yyyy - HH:mm'}}
                  </div>
                  <div *ngIf="reservation.passengers.length>0">
                    <div *ngFor="let passenger of reservation.passengers" class="listpnrsinglepasseng">
                      <span *ngIf="passenger.surname">{{passenger.surname}}</span> <span *ngIf="passenger.name"> {{passenger.name}}</span> <sup> ({{passenger.type}})</sup>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-3 align-self-center text-center">

              <div class="reservationimage">
                <img class="" src="/assets/img/airline/icon/{{reservation.marketingCarrier}}.png">
                <span style="text-transform:uppercase;">{{iataComplete.returnCarrier(reservation.marketingCarrier)}}</span>
              </div>
              <div class="reservationPrice marginbottom5" *ngIf="!mainComponent.appcomponent.isB2B">
                <span *ngIf="reservation.negotiated && priceservice.isNegoHidden()" class="recoprice">
                  {{priceservice.returnPrice(reservation.fare.publicAmount)|number:'.2-2'}}€
                </span>
                <span *ngIf="reservation.negotiated && !priceservice.isNegoHidden()" class="recoprice">
                  {{priceservice.returnPrice(reservation.fare.amount+(reservation.fare.fees||0))|number:'.2-2'}}€
                </span>
                <span *ngIf="!reservation.negotiated" class="recoprice">
                  {{priceservice.returnPrice(reservation.fare.amount)|number:'.2-2'}}
                  <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                  <span *ngIf="!reservation.fare.currency">€</span>
                </span>
                <span *ngIf="reservation.fare.services != 0" class="smalltext">
                  <br/>
                  {{'SERVICES'|translate}}: {{reservation.fare.services|number:'.2-2'}}
                  <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                  <span *ngIf="!reservation.fare.currency">€</span>
                </span>
              </div>
              <div class="reservationPrice marginbottom5" *ngIf="mainComponent.appcomponent.isB2B">
                <span *ngIf="reservation.negotiated" class="recoprice">
                  {{priceservice.returnPrice(reservation.fare.publicAmount - reservation.fare.fees)|number:'.2-2'}}€
                </span>
                <span *ngIf="!reservation.negotiated" class="recoprice">
                  {{priceservice.returnPrice(reservation.fare.amount)|number:'.2-2'}}
                  <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                  <span *ngIf="!reservation.fare.currency">€</span>
                </span>
                <span *ngIf="reservation.fare.services != 0" class="smalltext">
                  <br/>
                  {{'SERVICES'|translate}}: {{reservation.fare.services|number:'.2-2'}}
                  <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                  <span *ngIf="!reservation.fare.currency">€</span>
                </span>
              </div>
              <div class="linktopnr">
                <a routerLink="/flight/reservations/{{reservation._id}}">{{'OPEN_RESERVATION'|translate}}</a>
              </div>
            </div>

            <div class="col-12 pnrlistuser">
              <hr/>
              <div>
                <a routerLink="/users/list/{{reservation.user._id}}">{{reservation.user.surname}} {{reservation.user.name}}</a>
                <mat-icon>arrow_right_alt</mat-icon>
                <a routerLink="/companies/list/{{reservation.company._id}}">{{reservation.company.name}}</a>
              </div>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
    <div *ngIf="reservationlist">
      <div class="padding20 text-center noresult" *ngIf="!loading && reservationlist.list.length==0">
        <h3>{{'NO_RESULTS'|translate}}.</h3>
        <p *ngIf="text">{{'NO_RESULTS_TEXT'|translate}} <span class="searchtext">{{text}}</span>.</p>
        <p *ngIf="text">{{'NO_RESULTS_TRY_AGAIN'|translate}}.</p>
      </div>
    </div>


    <div class="card" *ngIf="!loading">
      <div class="paginationr pr-3 justify-content-end">
        <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
        <span class="mr-2">
          {{'PAGE'|translate}}
          <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
            <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
              <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p+1}}</mat-option>
            </mat-select>
          </mat-form-field>
          {{'OF'|translate}} {{maxpages}}
        </span>
        <button mat-icon-button class="iconButtons" (click)="prev()" [disabled]="currentpage==0">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-icon-button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>

    <div class="terminal" *ngIf="reservationlistAll && reservationlistAll.list && mainComponent.loggedUser.role.superAdmin">
      <div class="terminalcontent" >
        <div class="mb-2">Possibili errori di importazione: {{reservationlistAll.count}}</div>
        <div *ngFor="let res of reservationlistAll.list ; let l = last" >
          {{res.locator}} <a routerLink="/flight/reservations/{{res._id}}"> {{'OPEN_RESERVATION'|translate}} <span class="material-icons">arrow_right_alt</span></a>
          <hr *ngIf="!l" style="border-color:grey; border-style: dashed;"/>
        </div>
      </div>
    </div>


  </div>

</div>
<div class="modal fade" id="travelListModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="travelListModal" aria-hidden="true">
  <div class="modal-dialog-scrollable modal-dialog modal-xl" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addGuestModalTitle">
          {{'ADD_TO_FOLDER'|translate}}
        </h5>
        <button type="button" class="close" (click)="toggleAddtoTravel(null)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="curretFlId">
        <app-dossierlist page="flight" [idFlight]="curretFlId" (close)="closeAddtoTravel()"></app-dossierlist>
      </div>

      <hr/>

      <div class="text-right buttonrow">
        <button type="button" mat-button (click)="toggleAddtoTravel(null)">{{'CLOSE'|translate}}</button>
      </div>

    </div>
  </div>
</div>
