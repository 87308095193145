import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {PriceService} from '../../../../../../services/price/price.service';
@Component({
  selector: 'app-bottomsheet',
  templateUrl: './bottomsheet.component.html',
  styleUrls: ['./bottomsheet.component.scss']
})
export class BottomsheetComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<BottomsheetComponent>,
    public priceservice: PriceService,
  ) { }

  ngOnInit(): void {
  }

  convertMessage(message:any){
    const mex = message.replace(/\n/g, '<br />');
    return mex;
  }

}
