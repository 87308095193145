<ng-container *ngIf="!notifications$">
  <mat-dialog-content>
    <mat-progress-bar mode="buffer"></mat-progress-bar>
  </mat-dialog-content>
</ng-container>

<ng-container *ngIf="notifications$ | async as notifications">


<div class="dialog-header">
<h2 mat-dialog-title>Notifications</h2>
</div>
<div>
  <mat-dialog-content>
  <div class="request-list" *ngFor="let notification of notifications.data; let l = last">
    <div class="request-list__title">Authorization : {{notification.ref}}</div>
    <div class="request-list__title">Category : {{notification.category}}</div>
    <div class="request-list__subtitle">Status: {{notification.status}}</div>
    <div class="request-list__subtitle">Created At: {{notification.createdAt | date}}</div>
    <hr *ngIf="!l"/>
  </div>
    <div *ngIf="notifications.data.length === 0">
      <p>You don't have any notification.</p>
    </div>
  </mat-dialog-content>

  <div style="display: flex; justify-content: flex-end" mat-dialog-actions>
    <button matDialogClose mat-raised-button>Close</button>
  </div>


</div>
</ng-container>
