/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EstablishmentInfoResponseDto } from '../models/EstablishmentInfoResponseDto';
import type { HotelAutocompleteResponseDto } from '../models/HotelAutocompleteResponseDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class EstablishmentService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param q
     * @param latitude
     * @param longitude
     * @param radius
     * @returns HotelAutocompleteResponseDto
     * @throws ApiError
     */
    public establishmentControllerAutocomplete(
        q: string,
        latitude?: number,
        longitude?: number,
        radius?: number,
    ): Observable<HotelAutocompleteResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/hotel/establishment',
            query: {
                'q': q,
                'latitude': latitude,
                'longitude': longitude,
                'radius': radius,
            },
        });
    }

    /**
     * @param ids
     * @returns EstablishmentInfoResponseDto
     * @throws ApiError
     */
    public establishmentControllerGetInfos(
        ids: Array<string>,
    ): Observable<Array<EstablishmentInfoResponseDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/hotel/establishment/info',
            query: {
                'ids': ids,
            },
        });
    }

    /**
     * @param limit
     * @param skip
     * @param text
     * @returns any
     * @throws ApiError
     */
    public establishmentControllerGetFavourites(
        limit: number = 20,
        skip?: number,
        text?: number,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v3/hotel/establishment/favourite',
            query: {
                'limit': limit,
                'skip': skip,
                'text': text,
            },
        });
    }

    /**
     * @param establishment
     * @returns any
     * @throws ApiError
     */
    public establishmentControllerAddToFavourite(
        establishment: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v3/hotel/establishment/favourite/{establishment}',
            path: {
                'establishment': establishment,
            },
        });
    }

    /**
     * @param establishment
     * @returns any
     * @throws ApiError
     */
    public establishmentControllerRemoveFromFavourite(
        establishment: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v3/hotel/establishment/favourite/{establishment}',
            path: {
                'establishment': establishment,
            },
        });
    }

}
