export class RateDetails {

  name:String;
  label:String;
  description:Array<any>;

  constructor(name:String, label:String, description){
    if(name) this.name = name;
    if(label) this.label = label;
    if(!Array.isArray(description)) this.description = [description];
    else this.description = description;
  }
}