import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AccessDialogComponent} from './access-dialog/access-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LocalStorageService} from '../../../services/storage/storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TravelPolicyService} from '../../../services/travel-policy/travel-policy.service';
import {take} from 'rxjs/operators';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-travel-policy-form',
  templateUrl: './travel-policy-form.component.html',
  styleUrls: ['./travel-policy-form.component.scss']
})
export class TravelPolicyFormComponent implements OnInit {

  formNumber: [1];
  orderForm;
  items: UntypedFormArray;
  isAccessible: any;
  travelPolicyObj;
  checked = true;
  slideToggle = [];
  currentPolicyItems = [];
  currentPolicy: any;


  // private currentPolicyId: number;
  private policyIdFromRoute: string;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private storageService: LocalStorageService,
    private policyService: TravelPolicyService,
    private router: Router
  ) {

  }

  get isAuthorized(): AbstractControl {
    return this.items.get('isAuthorized').value;
  }

  get type(): AbstractControl {
    return this.items.get('type');
  }

  ngOnInit(): void {
    this.getRouterParam();
    this.orderForm = this.formBuilder.group({items: this.formBuilder.array([])});
    // this.initPolicy();
  }

  getRouterParam(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.policyIdFromRoute = routeParams.get('policyId');


    this.fetchPolicy(this.policyIdFromRoute);
  }

  fetchPolicy(id): void {

    this.policyService.show(id).subscribe(policy => {
      console.log(policy);
      this.currentPolicy = policy.data;
      this.initPolicy();
    });
  }

  initPolicy(): void {

    this.currentPolicyItems = this.currentPolicy ? this.currentPolicy.configuration : [];
    if (this.currentPolicyItems.length === 0) {
      this.orderForm = this.formBuilder.group({
        items: this.formBuilder.array([])
      });
      this.items = this.orderForm.get('items') as UntypedFormArray;
      this.addItem();
    } else {
      this.orderForm = this.formBuilder.group({
        items: this.formBuilder.array([])
      });
      this.items = this.orderForm.get('items') as UntypedFormArray;
      this.setInitItems();
    }
  }

  createItem(): UntypedFormGroup {
    return this.formBuilder.group({
      type: ['', Validators.required],
      operator: ['', Validators.required],
      value: ['', Validators.required],
      isAuthorized: null
    });
  }

  addItem(): void {
    this.currentPolicyItems.push({
      conditions: {
        type: '',
        operator: '',
        value: 0,
      },
      isAuthorized: false,
      isDefault: 1,
      authorization_users: [],
      authorization_roles: []
    });
    this.items.push(this.createItem());
  }

  createExistingItems(policyItem): UntypedFormGroup {
    return this.formBuilder.group({
      type: [policyItem.conditions.type, Validators.required],
      operator: [policyItem.conditions.operator, Validators.required],
      value: [policyItem.conditions.value, Validators.required],
      isAuthorized: [policyItem.isAuthorized]
    });
  }

  setInitItems(): void {

    this.currentPolicyItems.forEach((item, index) => {
      this.slideToggle[index] = item.isAuthorized;
      this.items.push(this.createExistingItems(item));
      this.formBuilder.group({
        items: item
      });
    });
  }

  addAccess(id): void {
    let dialogRef;
    let isOldData;
    let userPolicy;
    if (this.currentPolicyItems.length - 1 >= id) {
      userPolicy = {
        authorization: this.currentPolicyItems[id].isDefault,
        authorization_users: this.currentPolicyItems[id].authorization_users,
        authorization_roles: this.currentPolicyItems[id].authorization_roles
      };

      isOldData = true;
      dialogRef = this.matDialog.open(AccessDialogComponent, {
        width: '400px',
        minWidth: '280px',
        maxHeight: '100%',
        data: {id, userPolicy, isOldData}
      });
    } else {
      isOldData = false;
      dialogRef = this.matDialog.open(AccessDialogComponent, {
        width: '400px',
        minWidth: '280px',
        maxHeight: '100%',
        data: {id, isOldData}
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.hasOwnProperty('radioButton')) {
        this.currentPolicyItems[id].isDefault = +result.radioButton;
        if (result.radioButton === '1') {
          this.isAccessible = this.items.controls[id].value.requestMethod === 'default';
        }
        if (result.radioButton === '2') {
          this.isAccessible = this.items.controls[id].value.requestMethod === 'standard';
        }
      }
      this.currentPolicyItems[id].authorization_users = result.authorization_users;
      this.currentPolicyItems[id].authorization_roles = result.authorization_roles;
    });
  }

  deletePolicy(i: number): void {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '280px',
      data: {message: 'Are you sure you want to delete?'},
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(res => {
      if (res) {
        this.items.removeAt(i);
        this.currentPolicyItems = this.items.value;
      }

    });
  }


  onSubmit(): void {
    this.travelPolicyObj = this.items.controls.map(item => item.value);
    this.travelPolicyObj.map(item => {
      if (!item.hasOwnProperty('requestMethod')) {
        return item.requestMethod = 1;
      }
      return item;
    });

    if (this.orderForm.valid) {

      this.currentPolicyItems.map((item, index) => {
        const conditions = {
          type: this.travelPolicyObj[index].type,
          operator: this.travelPolicyObj[index].operator,
          value: this.travelPolicyObj[index].value
        };
        item.isAuthorized = this.travelPolicyObj[index].isAuthorized;
        return item.conditions = conditions;
      });
      this.currentPolicy.configuration = this.currentPolicyItems;
      /*
      this.policyService.update(this.currentPolicy, this.policyIdFromRoute)
        .subscribe(result => {
        });
      */
      this.policyService.updateInPolicy(this.currentPolicy, this.policyIdFromRoute).subscribe(() => {
        // this.callPolicies();
      });
      this.snackBar.open('Travel policy has been saved!', 'Success', {
        duration: 2000,
      });
      this.router.navigate(['/policies']).then();

    } else {
      this.snackBar.open('Check the invalid field!', 'Failed', {
        duration: 2000
      });
    }
  }
}
