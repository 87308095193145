import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpserviceService} from '../../../services/httpservice.service';
import {Router} from '@angular/router';
import {SnackErrorComponent} from '../../../modules/errorNotification/snackerror/snack-error.component';
import {catchError, retry} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthorizationRequestsService extends HttpserviceService {
  private policyEndpointBis = 'policy/';
  private authorizationEndpoint = this.policyEndpointBis + 'user-authorisation';
  private authorizationApproveEndpoint = this.policyEndpointBis + 'user-authorisation/approve/';
  private authorizationDeclineEndpoint = this.policyEndpointBis + 'user-authorisation/decline/';
  constructor(
    public router: Router,
    public httpClient: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, httpClient, snackComponent);
  }

  getAuthorizations(): Observable<any> {
    return this.http
      .get<any>(this.apiUrl + this.authorizationEndpoint, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }

  sendAuthorizationResponse(payload): Observable<any>{
    return this.http
      .post<any>(this.apiUrl + this.authorizationEndpoint + '/' + payload.type + '/' + payload.id, payload, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
    // return this.http.post(`${environment.base_url}api/user-authorisation/${payload.type}/${payload.id}`, payload);
  }
}
