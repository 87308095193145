<div class="padding20">
  <!-- <h1 style="margin-top: 20px">Search</h1> -->
  <ng-container *ngIf="search">

    <mat-card appearance="outlined">
      <h4>Search Id: {{search.searchId}}</h4>
      <h5>Product type: {{search.ref}}</h5>
      <h5>Created at: {{search.createdAt | date: 'long'}}</h5>
      <!-- <h2 style="display: inline-block">Product details: </h2> <button style="margin-left: 10px" mat-raised-button>Info</button> -->
     <!--  <div  *ngFor="let alert of search.alerts"
            [ngClass]="{'warn-alert': !alert.block, 'danger-alert': alert.block}">
        {{alert?.message}}
      </div> -->

      <app-hotel-card *ngIf="search.ref == 'Hotel'" [productDetails]="search.productDetails"></app-hotel-card>
      <app-flight-card *ngIf="search.ref == 'Flight'" [productDetails]="search.productDetails" [userAuthorisations]="search.userAuthorisations"></app-flight-card>
      <app-vehicle-card *ngIf="search.ref == 'Car'" [productDetails]="search.productDetails"></app-vehicle-card>
      <app-train-card *ngIf="search.ref == 'Train'" [productDetails]="search.productDetails"></app-train-card>

    </mat-card>

  </ng-container>
</div>

