import { Component, OnInit } from '@angular/core';
import {UsersService} from '../../../../services/profile/users.service';
import {AuthenticationService} from '../../../../services/authentication.service';
import {Router} from '@angular/router';
import {MainComponent} from '../../../mainmodule/main/main.component';

@Component({
  selector: 'app-login-as',
  templateUrl: './login-as.component.html',
  styleUrls: ['./login-as.component.scss']
})
export class LoginAsComponent implements OnInit {
  text: string;
  userList: any;
  loading: boolean;
  userToLog: any;
  constructor(
    private router: Router,
    private usersService: UsersService,
    private authService: AuthenticationService,
    private mainComponent: MainComponent,
  ) {
    this.loading = false;
    this.text = '';
  }

  ngOnInit(): void {
  }

  searchUser(value) {
    delete this.userList;
    if (value.length >= 2 ) {
      this.getUsersList(value);
    }
  }

  getUsersList(value) {
    delete this.userList;
    delete this.userToLog;
    this.usersService.getUsersComplete(value).subscribe((res) => {
      this.loading = false;
      this.userList = res;
      console.log('this.airports => ', this.userList);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  clear() {
    this.text = '';
    delete this.userToLog;
    delete this.userList;
  }

  getUser(value) {
    const user = value;
    delete this.userList;
    this.text = user.surname + ' ' + user.name + ' -> ' + user.company.name;
    this.userToLog = user;
  }

  selectFirst() {
    if (this.userList && this.userList.length > 0){
      const user = this.userList[0];
      delete this.userList;
      this.text = user.surname + ' ' + user.name + ' -> ' + user.company.name;
      this.userToLog = user;
    }
  }

  loginAs() {
    const body = {
      email: this.userToLog.email,
      id: this.userToLog._id,
      name: this.userToLog.name,
      surname: this.userToLog.surname,
    };
    this.usersService.loginAs(body).subscribe(response => {

      localStorage.setItem('prev_fl_profile', localStorage.getItem('fl_profile'));
      localStorage.setItem('prev_fl_access_token', localStorage.getItem('fl_access_token'));

      localStorage.setItem('fl_profile', JSON.stringify(response.profile));
      localStorage.setItem('fl_access_token', response.token);

      this.mainComponent.setUserAs();

      this.router.navigate(['/dashboard']);
    }, error => {
      console.error('Error => ', error);
    });
  }

}
