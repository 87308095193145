<div>
  <div *ngIf="objtosend">

    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-lg-12 align-self-center">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'SUBJECT'|translate}}</mat-label>
              <input matInput placeholder="" [(ngModel)]="objtosend.subject" [ngModelOptions]="{standalone: true}" required>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 align-self-center">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'CATEGORY'|translate}}</mat-label>
              <mat-select name="category" [(ngModel)]="objtosend.category" [ngModelOptions]="{standalone: true}" required>
                <mat-option *ngFor="let category of categories" [value]="category.value">{{category.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 align-self-center">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'STATUS'|translate}}</mat-label>
              <mat-select name="category" [(ngModel)]="objtosend.status" [ngModelOptions]="{standalone: true}" required>
                <mat-option *ngFor="let status of status" [value]="status.name">{{status.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 align-self-center">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'LINK'|translate}}</mat-label>
              <input matInput placeholder="" [(ngModel)]="objtosend.link" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>
        </div>

      </div>
      <div class="col-md-6">

        <div class="row">
          <div class="col-lg-12">
            <label id="notitype">Tipo di notifica</label>
            <mat-radio-group
              aria-labelledby="notitype" class="example-radio-group" [(ngModel)]="objtosend.block">
              <mat-radio-button [value]="false">Non bloccante</mat-radio-button>
              <mat-radio-button [value]="true">Di blocco</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label id="example-radio-group-label">Invia a</label>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="objtosend.sendTo.all">
              <mat-radio-button [value]="false">Solo agli utenti selezionati</mat-radio-button>
              <mat-radio-button [value]="true">A tutti gli utenti</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-md-6">
            <label id="radio-group-cau">Escludi CAU</label>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="objtosend.sendTo.cau">
              <mat-radio-button [value]="true">Includi CAU</mat-radio-button>
              <mat-radio-button [value]="false">Escludi CAU</mat-radio-button>
            </mat-radio-group>
          </div>


        </div>

        <div class="row" *ngIf="objtosend.sendTo.all && !appComponent.isSbt && !appComponent.isGat">
          <div class="col-lg-12">
            <div>Selezione invio:</div>
            <mat-form-field appearance="outline">
              <mat-label>Aziende</mat-label>
              <mat-select [(ngModel)]="objtosend.sendTo.b2b">
                <mat-option [value]="undefined">TUTTI</mat-option>
                <mat-option [value]="false">Tutte le AGENZIE</mat-option>
                <mat-option [value]="true">Tutte le AZIENDE (b2b)</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

        <div class="row" *ngIf="!objtosend.sendTo.all">
          <div class="col-lg-12">
            <label id="user-radio-group-label">Seleziona</label>
            <mat-radio-group
              aria-labelledby="user-radio-group-label"
              class="example-radio-group"
              [(ngModel)]="to" [ngModelOptions]="{standalone: true}" (ngModelChange)="removeSelection($event)">
              <mat-radio-button [value]="'users'">Uno o più utenti</mat-radio-button>
              <mat-radio-button [value]="'companies'">Una o più aziende</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row mb-3" *ngIf="!objtosend.sendTo.all && to === 'companies'">
          <div class="col-lg-12">
            <app-companyautocomp [query]="objtosend" [selected]="filterCompany"></app-companyautocomp>
          </div>
        </div>

        <div class="row mb-3" *ngIf="!objtosend.sendTo.all && to === 'users'">
          <div class="col-lg-12">
            <app-usernotifierautocomplete [query]="objtosend" [selected]="filterUser"></app-usernotifierautocomplete>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <!--
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Notification text</mat-label>
          <textarea [ngModelOptions]="{standalone: true}" [(ngModel)]="objtosend.body" matInput placeholder="Your text.."></textarea>
        </mat-form-field>
        <hr/>
        -->
        <ckeditor [editor]="Editor" [(ngModel)]="objtosend.body" (change)="onChange($event)"></ckeditor>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-lg-12 text-right">
        <button mat-button color="warn" (click)="resetObj()">Reset</button>
        <button [disabled]="!isValidForm()" mat-button color="primary" (click)="sendNotification()">Send</button>
      </div>
    </div>
  </div>
</div>
