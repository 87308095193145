import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { KeyValue } from '@angular/common';
import * as $ from 'jquery';

/**
 * Seatmap component
 */
@Component({
  selector: 'app-seatmapLowCost',
  templateUrl: './seatmapLowCost.component.html',
  styleUrls: ['./seatmapLowCost.component.scss'],
})
export class SeatmapLowCostComponent implements OnInit{
  deleting : boolean;
  /**
   * Local variable for flights passed from parent component
   */
  @Input() flights : any[];
  /**
   * Local variable for tabs ( one tab for any flight)
   */
  tabs = [];
  /**
   * Local variable for current active tab
   */
  currentTab : number;
  currentCompartment : number;
  currentSeat : any;
  /**
   * Local variable for passengers passed from parent component
   */
  @Input() seatmap : any[];
  //@Input() seatsSelections: any;
  /**
   * Local variable for selected passenger
   */
  currentPassenger : any;
  /**
   * Local variable selected seats
   */
  selectedSeats : any[];
  seatsSelections : any[];
  seatMap : any[];

  @Output('someUpdate') select : EventEmitter<any> = new EventEmitter<any>();

  @Input() passengers : any[];

  constructor(){
    this.selectedSeats = [];
    this.seatMap = [];
    this.deleting = false;
  }

  ngOnInit() : void{
    let temp = [];
    let row;
    this.selectedSeats.length = this.flights.length;
    for (let i = 0; i < this.passengers.length; i ++){
      if (this.passengers[i].seat){
        for (let j = 0; j < this.passengers[i].seat.length; j ++){
          if (!Array.isArray(this.selectedSeats[j])){
            this.selectedSeats[j] = [];
          }
          this.selectedSeats[j].push(this.passengers[i].seat[j]);
        }
      }
    }
    if (this.seatmap){
      for (let i = 0; i < this.seatmap.length; i ++){
        temp = this.seatmap[i].matrix;
        [row] = temp;
        this.seatMap.push(temp);
      }
      console.log(this.seatMap);
      this.tabs.length = this.flights.length;
      this.currentTab = 0;
      this.currentCompartment = 0;
      this.currentPassenger = this.passengers[0];
    }


  }


  /**
   * Set currentTab
   * @param i number
   */
  setCurrentTab(i){
    this.currentTab = i;
    this.currentCompartment = 0;
    delete this.currentSeat;
  }

  /**
   * Set current passenger selection
   * @params passenger
   */
  setCurrentPax(passenger){
    this.currentPassenger = passenger;
  }

  /**
   * Set the service category to display
   * @params value
   */
  setCompartment(value){
    this.currentCompartment = value;
  }

  originalOrder = (a : KeyValue<number, string>, b : KeyValue<number, string>) : number => {
    return 0;
  };

  // /**
  //  * Set current seat and select it for current passenger
  //  * @params seat
  //  */
  selectSeat(element){
    const seat = {
      name: element.seatNumber,
      ref: this.currentPassenger._id,
      price: element.price.amount,
      passenger: this.currentPassenger._id,
      flight: element.flightNumber,
      currency: element.price.currency || undefined,
    };
    if (this.selectedSeats[this.currentTab] !== undefined){
      const found = this.selectedSeats[this.currentTab].findIndex(r => r.ref === seat.ref);
      if (found > - 1){
        this.selectedSeats[this.currentTab][found] = seat;
      } else{
        this.selectedSeats[this.currentTab].push(seat);
      }
    } else{
      this.selectedSeats[this.currentTab] = [];
      this.selectedSeats[this.currentTab].push(seat);
    }
    delete this.currentSeat;
    console.log('this.selectedSeats =>', this.selectedSeats);
  }

  // /**
  //  * Remove current seat from selection
  //  * @params seat
  //  */
  removeSeat(element){
    const seat = {
      name: element.seatNumber,
      ref: this.currentPassenger._id,
      price: element.price.amount,
    };
    if (this.selectedSeats[this.currentTab] !== undefined){
      const found = this.selectedSeats[this.currentTab].findIndex(r => r.name === seat.name);
      if (found > - 1){
        this.selectedSeats[this.currentTab].splice(found, 1);
      }
    }
    delete this.currentSeat;
    console.log('this.selectedSeats =>', this.selectedSeats);
  }

  removeBookedSeat(element){
    this.deleting = true;
    const body = {
      name: element.name,
      ref: this.currentPassenger.ref,
    };
    console.log('seat to remove =>', body);
    this.deleting = false;
    this.toggleDeleteModal();
  }

  toggleDeleteModal(){
    $('#deleteSeat').modal('toggle');
  }

  // /**
  //  * If seat include given code
  //  * @params element
  //  * @params value
  //  */
  include(col, i, element, value){
    if (element && Array.isArray(element)){
      let reserved = element.includes('T');
      if (reserved){
        return 'seatReserved';
      }
    }
  }

  reserved(seat) : boolean{
    if (seat && Array.isArray(seat.attribute)){
      if (seat.attribute.includes('T')){
        return true;
      } else{
        return false;
      }
    } else{
      return false;
    }
  }

  isInFare(seat){
    if (Array.isArray(this.selectedSeats[this.currentTab]) && this.selectedSeats[this.currentTab].length > 0){
      for (let i = 0; this.selectedSeats[this.currentTab].length; i ++){
        if (seat.name === this.selectedSeats[this.currentTab].name){
          return false;
        }
      }
    }
    return seat.price.amount === '0' || (seat.price.amount === '0.00' && (seat.attribute && !seat.attribute.includes('T')));
  }

  /**
   * Set current seat
   * @params seat
   */
  currentSeatToView(seat){
    if (this.currentSeat && this.currentSeat.seatNumber === seat.seatNumber){
      delete this.currentSeat;
    } else{
      this.currentSeat = seat;
    }
  }

  /**
   * CHeck seat map and change color to seat === to current seat
   * @params seat
   */
  isCurrentSeat(seat){
    if (this.currentSeat){
      if (seat.seatNumber === this.currentSeat.seatNumber){
        return true;
      }
    }
  }

  someSelected(seat){
    if (seat.attribute && Array.isArray(seat.attribute)){
      if (seat.attribute.includes('T')){
        return true;
      }
    }
    if (this.selectedSeats[this.currentTab] !== undefined){
      const found = this.selectedSeats[this.currentTab].findIndex(r => r.name === seat.seatNumber);
      if (found > - 1){
        return true;
      } else{
        return false;
      }
    }
  }

  someSeatForPax(passenger){

    if (this.selectedSeats[this.currentTab] !== undefined){
      const found = this.selectedSeats[this.currentTab].findIndex(r => r.passenger === passenger._id);
      if (found > - 1){
        let seat = this.selectedSeats[this.currentTab][found].name + ' ' + this.selectedSeats[this.currentTab][found].price;
        if (this.selectedSeats[this.currentTab][found].currency){
          seat += ' ' + this.selectedSeats[this.currentTab][found].currency;
        } else{
          seat += ' €';
        }
        return seat;
      }
    }
  }

  saveSeatMap(){
    let temp = {};
    let tempArray = new Array(this.passengers.length);
    for (let i = 0; i < this.flights.length; i ++){
      for (let x = 0; x < this.passengers.length; x ++){
        temp = {
          flight: '',
          name: '',
          passenger: this.passengers[x]._id,
          price: '',
          ref: this.passengers[x]._id,
        };
        tempArray[x] = temp;
      }
      if (!(this.selectedSeats[i] === undefined)){
        if (!Array.isArray(this.selectedSeats[i])){
          [this.selectedSeats[i]];
        }
        for (let j = 0; j < tempArray.length; j ++){
          const index = this.selectedSeats[i].findIndex(ele => ele.passenger === tempArray[j].passenger);
          if (index > - 1){
            tempArray[j] = this.selectedSeats[i][index];
          }
        }
      }
      this.selectedSeats[i] = [...tempArray];
      tempArray = [];
    }
    this.select.emit(this.selectedSeats);


    console.log('this.selectedSeats => ', this.selectedSeats);

  }

  alreadyBuy(){
    if (!this.seatsSelections){
      return false;
    }
    const found = this.seatsSelections[this.currentTab].findIndex(r => r.passenger === this.currentPassenger.ref);
    return found > - 1;
  }
}
