<h1 mat-dialog-title>{{data.element.subject}}</h1>
<div mat-dialog-content>
  <div [innerHTML]="data.element.body">

  </div>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'CLOSE'|translate}}</button> <!-- cdkFocusInitial -->
  <!-- <button mat-button [mat-dialog-close]="data.element._id">{{'MARK_AS_READ'|translate}}</button> -->
</div>
