<div class="booked-list__container " style="margin-top: 20px">
  <div class="request-list booked__item" *ngFor="let userRequest of requestsForActiveUser; let i = index;">
    <h2 class="request-list__title">Authorization request:</h2>
    <p>Created at: {{userRequest.createdAt | date: 'full'}}</p>
    <h3 class="request-list__subtitle">User requested: {{userRequest.requestUser.name}}</h3>
    <div *ngIf="userRequest.authorisation?.product">
      <h3 class="request-list__subtitle" *ngIf="  !userRequest.authorisation.product.length && userRequest.authorisation.product.name">Hotel
        request: {{userRequest.authorisation.product.name}}</h3>
      <h3 class="request-list__subtitle" *ngIf="!userRequest.authorisation.product.length && !userRequest.authorisation.product?.name">Flight request:
        {{userRequest.authorisation.product.departure.location}}
        - {{userRequest.authorisation.product.arrival.location}}</h3>
      <h3 *ngIf="userRequest.authorisation.product.length > 0">
        <span *ngFor="let product of userRequest.authorisation.product">
          <span>Products: </span>
        <span *ngIf="product.name">{{product.name}}, </span>
        <span *ngIf="!product.name">
          {{product.departure.location}}
          - {{product.arrival.location}}, </span>
      </span>
      </h3>
    </div>
    <h3 class="request-list__subtitle" *ngIf="userRequest.searchId">Search ID: {{userRequest.searchId}}</h3>
    <h3 class="request-list__subtitle"  *ngIf="userRequest.transferId">Transfer ID: {{userRequest.transferId}}</h3>
    <h3 class="request-list__subtitle">Status: {{userRequest.status}}</h3>
    <h3 class="request-list__subtitle">Reason: {{userRequest.authorisation.note}}</h3>
    <div class="request-list__alert" *ngIf="userRequest.status == 'pending'">
      <div class="request-list__buttons">
        <button class="request-list__button" (click)="approve(userRequest,i)" color="primary" mat-raised-button>Accept
        </button>
        <button class="request-list__button" (click)="decline(userRequest,i)" mat-raised-button>Reject</button>
      </div>
    </div>
  </div>

  <h2 style="margin-top:50px;" *ngIf="!requestsForActiveUser || requestsForActiveUser.length == 0">No authorization
    requests</h2>

</div>
