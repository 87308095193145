import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as $ from 'jquery';
import {
  ApplyChange,
  ChangeServiceResponse,
  TrainOffer,
  ValidateChangeServiceResponse,
} from '../../../../classes/train.models';
import { TrainService } from '../../../../train.service';
import { TrainMainComponent } from '../../../common/train-main/train-main.component';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TrainUtils } from '../../../train.utils';

@Component({
  selector: 'train-change-service',
  templateUrl: './train-change-service.component.html',
  styleUrls: ['./train-change-service.component.scss'],
})
export class TrainChangeServiceComponent extends TrainMainComponent {
  @Input() isDisabled : boolean;
  @Input() pnr : string;
  @Input() catalog : any;
  @Input() selectedTravelSolution = 0;
  @Output() reloadEvent = new EventEmitter<any>();

  modifyReservation$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  evaluateTravelLoading$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  fetchingData : boolean;
  changeAttempt : boolean;
  alternatives : ChangeServiceResponse;
  errorMessage : string;
  selectedChange : any;
  sortedSegments : any;
  selectedOfferId : any[];
  selectedClass : any[];
  selectedIndex = 0;
  validatedTravel : ValidateChangeServiceResponse;
  travelSolutions : any = ['ANDATA'];
  activeTravelSolution = 'ANDATA';
  private newTravelSolution : any;
  selectedOffer : any = {};

  constructor(
    private trainService : TrainService,
    public router : Router,
    public titleService : Title,
    public translate : TranslateService,
  ){
    super(router, titleService, translate);
  }

  toggleModal(){
    if (!this.alternatives){
      this.checkAlternatives();
    }
    $('#changeServiceModal').modal('toggle');
  }

  checkAlternatives(){
    this.fetchingData = true;
    this.trainService.checkAlternatives(this.pnr).subscribe((data: ChangeServiceResponse) => {
      this.fetchingData = false;
      this.alternatives = data;
      this.newTravelSolution = data.result.travelSolutions;
      this.sortAlternatives(data);
      if (this.newTravelSolution.returnTravelSolution){
        this.travelSolutions.push('RITORNO');
      }
    }, _ => {
      this.fetchingData = false;
      $('#changeServiceModal').modal('toggle');
    });
  }

  validateAlternative(){
    this.evaluateTravelLoading$.next(true);
    const oldTravelSolutions = Array.isArray(this.alternatives.travel.travelSolutions) ?
      this.alternatives.travel.travelSolutions : [this.alternatives.travel.travelSolutions];
    const newTravelSolution = {
      ...this.newTravelSolution,
      state: 'NEW'
    }
    const travelSolutions = oldTravelSolutions.concat(newTravelSolution);
    const req = {
      postSaleDetails: this.alternatives.selectedPostSaleDetails,
      travel: {
        ...this.alternatives.travel,
        travelSolutions,
      },
    };
    this.trainService.validateAlternative(this.pnr, req).subscribe((data: ValidateChangeServiceResponse) => {
      this.evaluateTravelLoading$.next(false);
      this.validatedTravel = data;
    }, err => {
      this.evaluateTravelLoading$.next(false);
    });
  }

  confirmChange(){
    this.modifyReservation$.next(true);
    const req: ApplyChange = {
      travel: this.validatedTravel.travel,
      selectedSimilarRecord: {
        validatedPostSaleRecord: this.validatedTravel.validatedPostSaleRecord,
        reusedPaymentRecords: this.validatedTravel.reusedPaymentRecords,
        reversedPaymentRecords: this.validatedTravel.reversedPaymentRecords,
        additionalCharges: this.validatedTravel.additionalCharges,
        missingAmount: this.validatedTravel.missingAmount,
      }
    }
    this.trainService.confirmChangeService(this.pnr, req).subscribe(data => {
      this.modifyReservation$.next(false);
      this.changeAttempt = true;
      this.reload();
    }, error => {
      this.modifyReservation$.next(false);
      this.errorMessage = error;
      this.reload();
    });
  }

  reload(){
    this.reloadEvent.emit(true);
  }

  getDisabledOffer(){
    const selectedClass = this.selectedClass;
    const offerId = this.selectedOfferId;
    if (!selectedClass || !offerId || offerId.includes(undefined)){
      return true;
    }
    return selectedClass.map(s => {
      return offerId.map(o => {
        return s[o] && s[o].eligibility.level !== 'ELIGIBLE';
      }).includes(true);
    }).includes(true);
  }

  getEligibilityMessage(){
    const selectedClass = this.selectedClass;
    const offerId = this.selectedOfferId;
    if (!selectedClass || !offerId || offerId.includes(undefined)){
      return '';
    }
    const messages = new Set();
    this.selectedClass.forEach(s => {
      this.selectedOfferId.filter(o => !!s[o] && !!s[o].eligibility.messages)
          .forEach(offerName => {
            const msg = Array.isArray(s[offerName].eligibility.messages) ? s[offerName].eligibility.messages : [s[offerName].eligibility.messages];
            msg.forEach(m => messages.add(m));
          });
    });
    return Array.from(messages).join('. ');
  }

  selectTrenitaliaOffer(solutionNodeRef : string, offer : any, paxId: number, pax : any) : void{
    delete this.validatedTravel;
    if (this.isOfferDisabled(pax, offer)){
      return;
    }
    this.selectedOfferId[paxId] = offer.name;
    this.newTravelSolution = {
      ...this.newTravelSolution,
      offeredServices: super.getFilteredOffers(paxId, this.newTravelSolution.offeredServices, solutionNodeRef, this.selectedClass, this.selectedOfferId),
    };
  }

  /**
   * @param j Jurney instance from searchResult
   * Loads the journey detail call the appropriate service according to ITALO (getAvailableTrain)/TRENIT(searchBase)
   */
  changeTab(value){
    this.selectedOfferId = new Array(this.alternatives.result.travellers.length);
    this.selectedClass = new Array(this.alternatives.result.travellers.length);
    this.selectedIndex = value;
  }

  getCurrentSelectedOffer(segment : any){
    const travelSolutions = Array.isArray(this.alternatives.travel.travelSolutions) ?
      this.alternatives.travel.travelSolutions : [this.alternatives.travel.travelSolutions];
    const tsIndex = travelSolutions.length-1 >= this.selectedTravelSolution ? this.selectedTravelSolution : 0;
    const offeredServices = travelSolutions[tsIndex].offeredServices;
    const offers = TrainUtils.toArray(offeredServices);
    return offers.filter(o => o.status === 'PURCHASED' && o.solutionNodeRef === segment.idXml);
  }

  getOfferName(id : any){
    return this.catalog.offers[id];
  }

  getServiceName(id : any){
    return this.catalog.services[id];
  }

  getServiceDisplayName(serviceClass: any){
    return serviceClass.displayName + ', ' + serviceClass?.level?.map(l => TrainUtils.toArray(l.values).map(v => v.value).join('/'));
  }

  getTraveller(pax : any){
    return this.alternatives.travel.travellers.find(t => t.xmlId === pax.paxId || t._id === pax.paxId);
  }

  isOfferDisabled(pax : any, offer : any){
    const travellerParams = this.alternatives.travel.travellers.find(t => t.xmlId === pax.paxId).parameters.map((p : any) => Number.parseInt(p.typeId, 10));
    return offer?.available === 0 || offer?.eligibility.level !== 'ELIGIBLE' || !TrainUtils.toArray(offer.requiresParams).every(paramId => travellerParams.includes(paramId));
  }

  private sortAlternatives(response){
    this.sortedSegments = response.segments.slice().map((s : any) => {
      return {
        ...s,
        services: s.services.map((paxServices : any) => {
          return { ...paxServices, services: this.getSortedServices(paxServices) };
        }),
      };
    });
    this.selectedOfferId = new Array(this.alternatives.result.travellers.length);
    this.selectedClass = new Array(this.alternatives.result.travellers.length);
  }

  deleteValidation(){
    delete this.validatedTravel;
  }

  setOffer(serviceClass : any, idXml : any, paxIndex : number, pax : any){
    if (serviceClass.offers.length === 1 && serviceClass.offers[0].offers.length === 1 && !this.isOfferDisabled(pax, this.selectedOffer[paxIndex])){
      this.selectedOffer[paxIndex] = serviceClass.offers[0]?.offers[0];
      this.selectTrenitaliaOffer(idXml, this.selectedOffer[paxIndex], paxIndex, pax);
    }
  }
}
