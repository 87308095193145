import {Component, Input, OnInit} from '@angular/core';
import {FlightService} from '../../../../../../services/flight/flight.service';

/**
 * Reservation tst component
 */
@Component({
  selector: 'app-tst',
  templateUrl: './tst.component.html',
  styleUrls: ['./tst.component.scss']
})
export class TstComponent implements OnInit {
  /**
   * Local variable for reservation id passed from parent component
   */
  @Input() reservationId: string;
  /**
   * Local variable for loading tst status
   */
  loading: boolean;
  /**
   * Local variable for tst
   */
  tst: any;
  /**
   * The "constructor"
   */
  constructor(
    /**
     *  Local variable for flightService
     */
    private flightService: FlightService,
  ) { }

  /**
   * On init fire get tst function
   */
  ngOnInit(): void {
    this.getCryptic();
  }

  /**
   * Call tst for given reservation
   * @params {reservationId} string
   */
  getTst(): any {
    this.loading = true;
    this.flightService.getTst(this.reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.tst = this.toText(res.message);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  /**
   * Call tst history for given reservation
   * @params {reservationId} string
   */
  getTstHistory(): any {
    this.loading = true;
    this.flightService.getTstHistory(this.reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.tst = this.toText(res.message);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getTsm(): any {
    this.loading = true;
    this.flightService.getTsm(this.reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.tst = this.toText(res.message);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getCryptic(): any {
    this.loading = true;
    this.flightService.getRetrieveCryptic(this.reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.tst = this.toText(res.message);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  /**
   * Add br to tst message
   * @params {toSet} string
   */
  toText(toSet) {
    return toSet.replace(new RegExp('\n', 'g'), '<br/>');
  }

}
