import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenticketComponent } from './components/openticket/openticket.component';
import { TkbottomsheetComponent } from './components/tkbottomsheet/tkbottomsheet.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { TicketslistComponent } from './components/ticketslist/ticketslist.component';
import { SingleticketComponent } from './components/singleticket/singleticket.component';
import { CustomCommonModule } from '../common/custom-common.module';
import { GroupRequestComponent } from './components/group-request/group-request.component';
import { FlightModule } from '../flight/flight.module';
import { UnaccompaniedMinorsComponent } from './components/unaccompanied-minors/unaccompanied-minors.component';


@NgModule({
  declarations: [
    OpenticketComponent,
    TkbottomsheetComponent,
    TicketslistComponent,
    SingleticketComponent,
    GroupRequestComponent,
    UnaccompaniedMinorsComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        CustomCommonModule,
    ],
  exports: [
    OpenticketComponent,
    TkbottomsheetComponent,
    TicketslistComponent,
  ],
})
export class TicketingModule{}
