import {Component, DoCheck, Input, IterableDiffers, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {MainComponent} from '../../../../../mainmodule/main/main.component';
import {Router} from '@angular/router';
import 'bootstrap';
import MarkerClusterer from '@google/markerclusterer';
import {PriceService} from '../../../../../../services/price/price.service';
declare var google : any;
@Component({
  selector: 'app-search-results-v2',
  templateUrl: './search-results-v2.component.html',
  styleUrls: ['./search-results-v2.component.scss']
})
export class SearchResultsV2Component implements OnInit, DoCheck {
  @Input() hotelForm : any;
  @Input() hotelRes: any;
  @Input() filters: any;
  seletedfilters: any;
  iterableDiffer : any;
  results: any;
  formvisible : boolean;
  numberOfNights : number;
  limit = 20;
  sorting : string;
  loadMore = false;
  showMap = false;
  markers : any;
  map : any;
  searchName : string;
  changeCount = 0;
  /**
   *  local variable for clusterMarker
   */
  clusterMarker : any;
  resultsLength = 0;
  constructor(
    private iterableDiffers : IterableDiffers,
    public mainComponent : MainComponent,
    private router: Router,
    public priceService: PriceService,
  ) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
    this.formvisible = true;
    this.sorting = 'RELEVANCE';

    this.seletedfilters = {
      rating:[],
      chain:[],
      amount:{
        min:0,
        max:100000,
      },
      services: [],
      roomAmenities: [],
    };

    this.showMap = false;
    this.formvisible = true;
    this.markers = [];
    this.searchName = '';
    // this.seletedfilters = JSON.parse(JSON.stringify(this.filters));
  }

  ngOnInit(): void {
    this.sorting = 'RELEVANCE';
    console.log('hotelres v2 => ', this.hotelRes);
    this.results = JSON.parse(JSON.stringify(this.hotelRes));
    setTimeout(() => {
      if (this.filters && this.filters.rating) {
        this.filters.rating.sort((a, b) => a - b);
      }
      //this.seletedfilters = JSON.parse(JSON.stringify(this.filters));
    }, 500);
    this.resultsLength = this.hotelRes.results.length;
    this.toggleSearch();
    this.initMap();
    this.doSort();
  }

  ngDoCheck(){
    this.changeCount = 0;
    const changes = this.iterableDiffer.diff(this.hotelRes.results);
    if (changes){
      this.results = JSON.parse(JSON.stringify(this.hotelRes));
      this.changeCount += 1;
    }

    if (this.changeCount >= 1){
      if (this.filters) {
        this.filters.rating.sort((a, b) => a - b);
        // this.seletedfilters = JSON.parse(JSON.stringify(this.filters));
      }
      // console.log('resultsLength => ', this.resultsLength);
      const hotels = JSON.parse(JSON.stringify(this.hotelRes.results));
      hotels.splice(0, this.resultsLength);
      this.resultsLength = this.hotelRes.results.length;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < hotels.length; i ++){
        this.addMarker(hotels[i]);
      }
      this.doSort();
    }
    // this.initMap();
  }

  calculateNights(){
    const date1 = new Date(this.hotelForm.checkIn);
    const date2 = new Date(this.hotelForm.checkOut);
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    this.numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

  toggleSearch(){
    this.formvisible = !this.formvisible;
    $('#searchCard').collapse('toggle');
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }

  returnImg(hotel){
    let img = '';
    if (hotel.info.images.length > 0) {
      img = hotel.info.images[0].list[0].url;
    } else {
      hotel.noImg = true;
      img = 'assets/img/placeholder-image.png';
    }
    return img;
  }

  noImg(hotel){
    if (hotel.info.images.length === 0){
      return true;
    }
  }

  starCounter(i : number){
    return new Array(i);
  }

  updateLimit(): void {
    this.loadMore = true;
    setTimeout(() => {
      this.loadMore = false;
      this.limit += 20;
    }, 1500);
  }

  isLast(): boolean {
    return this.limit < this.results.results.length;
  }

  setSort(value){
    this.sorting = value;
    this.doSort();
  }

  rad(x){
    return x * Math.PI / 180;
  }

  getDistance(p2){
    const p1 = {
      lat: this.hotelForm.destination.coordinates[1],
      lng: this.hotelForm.destination.coordinates[0],
    };
    const R = 6378137; // Raggio della terra in metri
    const dLat = this.rad(p2.lat - p1.lat);
    const dLong = this.rad(p2.lng - p1.lng);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  checkValue(a){
    if (a < 1000){
      return Math.round(a) + ' m';
    } else{
      return Math.round(a / 1000) + ' km';
    }
  }

  doSort(): void {
    console.log('esplodo');
    this.results.results = [];
    this.hotelRes.results.forEach((r:any) => {
      if (this.isInStarRange(r.rating) && this.isInRoomServicesSelection(r.roomAmenities) && this.isInServicesSelection(r.services) && this.isInProviderSelection(r.chain) && this.isInNameFilter(this.hotelRes.hotels[r.hotelCode].name)) {
        const p2 = {
          lat: this.hotelRes.hotels[r.hotelCode].info.position.latitude,
          lng: this.hotelRes.hotels[r.hotelCode].info.position.longitude,
        };
        if(this.hotelForm.destination){
          r.distance = this.getDistance(p2);
        }
        this.results.results.push(r);
      }
    });
    console.log('this.results.results ?> ', this.results.results);
    switch (this.sorting) {
    case 'ASCENDING':
      this.results.results = this.results.results.sort((a, b) => a.amount - b.amount);
      break;
    case 'DESCENDING':
      this.results.results = this.results.results.sort((a, b) => b.amount - a.amount);
      break;
    case 'ALPHABETIC_ASC':
      this.results.results = this.results.results.sort((a, b) => a.name.localeCompare(b.name));;
      break;
    case 'ALPHABETIC_DESC':
      this.results.results = this.results.results.sort((a, b) => b.name.localeCompare(a.name));
      break;
    case 'DISTANCE_ASCENDING':
      this.results.results.forEach(hotel => {
        hotel.sortingDistance = Math.round(hotel.distance * 1000);
      });
      this.results.results.sort((a, b) => a.sortingDistance - b.sortingDistance);
      break;
    case 'DISTANCE_DESCENDING':
      this.results.results.forEach(hotel => {
        hotel.sortingDistance = Math.round(hotel.distance * 1000);
      });
      this.results.results.sort((a, b) => b.sortingDistance - a.sortingDistance);
      break;
    case 'STAR_ASCENDING':
      this.results.results = this.results.results.sort((a, b) => a.rating - b.rating);
      break;
    case 'STAR_DESCENDING':
      this.results.results = this.results.results.sort((a, b) => b.rating - a.rating);
      break;
    case 'RELEVANCE':
      this.results.results = this.results.results.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      break;
    }
    // name
  }

  isInProviderSelection(r) {
    if (this.seletedfilters.chain.length === 0) {
      return true;
    } else return this.seletedfilters.chain.includes(r);
  }

  isInServicesSelection(arr) {
    if (this.seletedfilters.services.length === 0) {
      return true;
    } else return arr.filter(r=> this.seletedfilters.services.includes(r)).length === this.seletedfilters.services.length;
  }
  isInRoomServicesSelection(arr) {
    if (this.seletedfilters.roomAmenities.length === 0) {
      return true;
    } else return arr.filter(r=> this.seletedfilters.roomAmenities.includes(r)).length === this.seletedfilters.roomAmenities.length;
  }

  isInStarRange(value): boolean {
    if (this.seletedfilters.rating.length === 0) {
      return true;
    } else return this.seletedfilters.rating.includes(value);
  }

  isInNameFilter(name){
    if (this.searchName === '') {
      return true;
    } else return name.toLowerCase().includes(this.searchName.toLowerCase());
  }

  quote(code){
    // tslint:disable-next-line:max-line-length
    const url = this.router.serializeUrl(this.router.createUrlTree(['/hotel/quotation'], { queryParams: { code, checkIn: this.hotelRes.form.checkIn, checkOut: this.hotelRes.form.checkOut, occupancy: JSON.stringify(this.hotelRes.form.occupancy) } }));
    window.open(url, '_blank');
    // tslint:disable-next-line:max-line-length
    // this.router.navigate(['/hotel/quotation'], { queryParams: { code, checkIn: this.hotelRes.form.checkIn, checkout: this.hotelRes.form.checkOut, occupancy: JSON.stringify(this.hotelRes.form.occupancy) } });
  }


  initMap() : void{
    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 41.90313516565217, lng: 12.493317924529677 },
      // zoom: 8,
    });
    // console.log('hotelForm => ', this.hotelSearch);
    const image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
    let myLatlng;
    if (this.hotelForm.destination){
      myLatlng = new google.maps.LatLng(this.hotelForm.destination.coordinates[1], this.hotelForm.destination.coordinates[0]);
    } else{
      myLatlng = new google.maps.LatLng(41.90313516565217, 12.493317924529677);
    }
    const marker = new google.maps.Marker({
      position: myLatlng,
      title: 'Punto di interesse',
      icon: image,
    });
    const infoWindow = new google.maps.InfoWindow({
      content: '<p style="font-size:12px;">' + marker.title + '</p>',
    });
    marker.addListener('mouseover', () => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    marker.addListener('mouseout', () => {
      infoWindow.close();
      marker.setZIndex(1);
    });
    marker.setMap(this.map);
    this.createMarkers();
  }

  /**
   * Create markers for google map
   */

  printStars(rating): string{
    const arr = new Array(rating);
    let content = '';
    for (let i = 0; i < arr.length; i++) {
      content += '<span>&#9733;</span>';
    }
    // console.log('content ', content);
    return content;
  }

  createMarkers() : void{

    // info.location.position.latitude;
    // info.location.position.longitude;

    this.markers = this.hotelRes.results.filter(hotel => this.hotelRes.hotels[hotel.hotelCode].info.position).map((hotel, i) => {
      console.log('this.hotelRes.hotels[hotel.hotelCode] => ', this.hotelRes.hotels[hotel.hotelCode]);
      let infoWindow;
      const img = this.returnImg(this.hotelRes.hotels[hotel.hotelCode]);
      infoWindow = new google.maps.InfoWindow({
        content: '<div style="max-width:200px"><div style="margin-bottom:5px;">' + this.hotelRes.hotels[hotel.hotelCode].name + '</div>' +
        '<div style="margin-bottom:10px;color: #ffaf00;font-size:12px;">' + this.printStars(hotel.rating) + '</div>' +
        '<div style="margin-bottom:5px;"><img style="max-width:150px;max-height:150px;" src="' + img+ '"/></div>' +
        '<div>'+ this.mainComponent.appcomponent.translate.instant('PRICE_FROM') + ' ' + hotel.amount + '€' + '</div>' +
        '</div>'
      });

      const marker = new google.maps.Marker({
        // tslint:disable-next-line:max-line-length
        position: { lat: this.hotelRes.hotels[hotel.hotelCode].info.position.latitude, lng: this.hotelRes.hotels[hotel.hotelCode].info.position.longitude },
        label: 'H',
        // label: labels[i % labels.length],
      });
      marker.addListener('mouseover', () => {
        infoWindow.open(this.map, marker);
        marker.setZIndex(100);
      });
      marker.addListener('click', () => {
        this.quote(hotel.hotelCode);
        marker.setZIndex(1);
      });
      /*
      marker.addListener('mouseout', () => {
        infoWindow.close();
        marker.setZIndex(1);
      });
      */
      /*
      if (hotel.hotels){
        marker.addListener('click', () => {
          // this.toggleNewSingleHotel(hotel);
        });
      }*/
      return marker;
    });
    const bounds = new google.maps.LatLngBounds();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.markers.length; i ++){
      bounds.extend(this.markers[i].getPosition());
    }
    this.map.fitBounds(bounds);
    this.clusterMarkers(this.markers);
  }

  addMarker(hotel){
    let infoWindow;
    const img = this.returnImg(this.hotelRes.hotels[hotel.hotelCode]);
    infoWindow = new google.maps.InfoWindow({
      content: '<div style="max-width:200px"><div style="margin-bottom:5px;">' + this.hotelRes.hotels[hotel.hotelCode].name + '</div>' +
        '<div style="margin-bottom:10px;color: #ffaf00;font-size:12px;">' + this.printStars(hotel.rating) + '</div>' +
        '<div style="margin-bottom:5px;"><img style="max-width:150px;max-height:150px;" src="' + img+ '"/></div>' +
        '<div>'+ this.mainComponent.appcomponent.translate.instant('PRICE_FROM') + ' ' + hotel.amount + '€' + '</div>' +
        '</div>',
    });

    const marker = new google.maps.Marker({
      // tslint:disable-next-line:max-line-length
      position: { lat: this.hotelRes.hotels[hotel.hotelCode].info.position.latitude, lng: this.hotelRes.hotels[hotel.hotelCode].info.position.longitude },
      label: 'H',
      // label: labels[i % labels.length],
    });
    marker.addListener('mouseover', () => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    marker.addListener('mouseout', () => {
      infoWindow.close();
      marker.setZIndex(1);
    });
    marker.addListener('click', () => {
      this.quote(hotel.hotelCode);
      marker.setZIndex(1);
    });
    if(this.hotelRes.hotels[hotel.hotelCode].info.position){
      this.markers.push(marker);
    }
    this.clusterMarker.addMarker(marker);
  }

  /**
   * Cluster all markers in google map
   */
  clusterMarkers(makers) : void{
    this.clusterMarker = new MarkerClusterer(
      this.map,
      makers,
      { maxZoom: 16, imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' },
    );
  }

  /**
   *  Hide or show map function
   */
  toggleMap(){
    this.showMap = !this.showMap;
    $('.hotelMapContainer').toggleClass('openMap');
    setTimeout(() => {
      const bounds = new google.maps.LatLngBounds();
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.markers.length; i ++){
        bounds.extend(this.markers[i].getPosition());
      }
      this.map.fitBounds(bounds);
      // this.initMap();
    }, 200);
    // this.createMarkers();
  }

}
