<!--{{'ATC.TAB'|translate}}-->
<hr/>
<ng-container *ngIf="!eligibility">
  <ng-container *ngIf="!eligibilityError">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    {{'LOADING'|translate}}
  </ng-container>
  <ng-container *ngIf="eligibilityError">
    <mat-icon>warning</mat-icon>
    {{eligibilityError}}
  </ng-container>
</ng-container>
<ng-container *ngIf="eligibility && eligibility.eligibility && eligibility.allowedCarriers">
  <div class="row ml-0 mr-2 mb-2 p-0">
    <div *ngFor="let paxEligibility of eligibility.eligibility" class="pax-atc-card">
      <div>{{ paxEligibility.pax.name }} {{ paxEligibility.pax.surname }}</div>
      <div>
        <ul>
          <li *ngFor="let conditions of paxEligibility.eligibility" [ngClass]="conditions.value ? 'allowed' : 'notallowed' ">
            {{ conditions.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row mb-2">
    <app-searchformatc class="w-100" *ngIf="showForm"
                       (update)="resultChange($event)"
                       (reset)="createForm()"
                       (updateReq)="requestChange($event)"
                       (updateSel)="resultSelChange($event)"
                       [selections]="selections"
                       [searchResults]="searchResults"
                       [request]="request"
                       [pnr]="pnr"
                       [allowedCarriers]="getAllowedCarriers()"
                       [singleSection]="singleSection"></app-searchformatc>
    <mat-tab-group selectedIndex="0" class="w-100" *ngIf="(searchResults && searchResults.itineraries ) && !request.options.calendar">
      <mat-tab *ngIf="searchResults && searchResults.itineraries">
        <ng-template mat-tab-label>
          Linea&nbsp;
          <span class="ml-2" *ngIf="!searchResults || !searchResults.itineraries"> Loading...</span>
          <span *ngIf="searchResults && searchResults.itineraries">
              <span>{{'FROM'|translate}}</span>
              <span class="ml-2 mr-2" *ngIf="!searchResults.recommendations[0].negotiated"> {{searchResults.recommendations[0].fare.amount|number:'.2-2'}}€ </span>
              <span class="ml-2 mr-2" *ngIf="searchResults.recommendations[0].negotiated"> {{searchResults.recommendations[0].fare.publicAmount || searchResults.recommendations[0].fare.amount |number:'.2-2'}}€ </span>
              <span class="smalltext"> ({{searchResults.recommendations.length}})</span>
            </span>
        </ng-template>
        <div *ngIf="searchResults && searchResults.itineraries && !appComponent.stepSearch && iataComplete.carriers && !request.options.calendar">
          <app-searchresults [atc]="pnr" [lowCost]="false" [selections]="selections" [searchResults]="searchResults" [request]="request"></app-searchresults>
        </div>
        <div style="overflow-x:hidden;" *ngIf="searchResults && searchResults.itineraries && appComponent.stepSearch && iataComplete.carriers && !request.options.calendar">
          <app-stepsearchresultscp [selections]="selections" [searchResults]="searchResults" [request]="request"></app-stepsearchresultscp>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>
