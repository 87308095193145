<div class="product__item" role="listitem">
  <div class="product__header">
    <h2 class="product__name">Car : {{productDetails.car.model}}</h2>
  </div>
  <div>
    <img style="width:50%" [src]="productDetails.car.images[0]">
  </div>
  <div class="product__body">
    <h3 class="product__rooms"><b>Car info:</b> </h3>
    <div class="product__room">
      <p class="product__item"><b>Pickup location:</b> {{productDetails.car.pickUpInfo.location}}</p>
      <p class="product__item"><b>Pickup date:</b> {{productDetails.car.pickUpInfo.date | date: 'dd/MM/yyyy H:m:s'}}</p>
      <p class="product__item"><b>Drop Off location:</b> {{productDetails.car.dropOffInfo.location}}</p>
      <p class="product__item"><b>Drop Off date:</b> {{productDetails.car.dropOffInfo.date | date: 'dd/MM/yyyy H:m:s'}}</p>
      <p class="product__item"><b>Company:</b> {{productDetails.rentCompany.name}}</p>
    </div>
  </div>
  <div class="product__body">
    <h3 class="product__rooms"><b>Rates:</b> </h3>
    <div class="product__room" *ngFor="let rate of productDetails.car.rates">
      <p class="product__item"><b>Type:</b> {{rate.type}}</p>
      <p class="product__item"><b>Amount:</b> &euro; {{rate.amount}}</p>
    </div>
  </div>
  <div class="product__body">
    <h3 class="product__rooms"><b>Passangers:</b> </h3>
    <div class="product__room" *ngFor="let passenger of productDetails.passengers
">
      <p class="product__item"><b>Name:</b> {{passenger.name}}</p>
      <p class="product__item"><b>Surname:</b> {{passenger.surname}}</p>
      <p class="product__item"><b>Email:</b> {{passenger.email}}</p>
      <p class="product__item"><b>PhoneNumber:</b> {{passenger.phoneNumber}}</p>
    </div>
  </div>
</div>

