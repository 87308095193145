<mat-form-field class="full-width" >
  <mat-label *ngIf="!subordinated">{{'SEARCH_COMPANY'|translate}}</mat-label>
  <mat-label *ngIf="subordinated">{{'SUBORDINATED_OF'|translate}}</mat-label>
  <input matInput placeholder="{{'SEARCH_COMPANY'|translate}}" aria-label="Departure"
         [(ngModel)]="companytext" (ngModelChange)="searchCompany($event)" [matAutocomplete]="cpcomplete" [disabled]="isForm"> <!--[ngModelOptions]="{standalone: true}"-->
  <button type="button" *ngIf="companytext" matSuffix mat-icon-button aria-label="Clear" (click)="clearFrom()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #cpcomplete="matAutocomplete" (closed)="selectFirst()">
    <mat-option *ngFor="let comp of companiesList" (click)="selectComp(comp)">
      <span>{{comp.name}}</span>
    </mat-option>
  </mat-autocomplete>

</mat-form-field>
<div *ngIf="company && company.name">
  <span class="companySubSpan">
    {{company.name}} <button mat-icon-button (click)="removeCompany(company._id)"><mat-icon>close</mat-icon></button>
  </span>
</div>
