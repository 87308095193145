<div class="form-header-container">
  <button style="margin-right: 10px" mat-icon-button color="primary" routerLink="/allgroups">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <div>Edit Group</div>
</div>
<div class="container">
  <div class="card">
    <div class="padding20">
      <app-role-form
        [loading]="loadingRequest"
        *ngIf="group; else loading"
        [group]="group.data"
        (submitted)="handleSubmit($event)"
      >
      </app-role-form>
    </div>
  </div>


</div>
<ng-template #loading>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

