import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VehicleCardComponent} from './vehicle-card.component';


@NgModule({
    declarations: [VehicleCardComponent],
    exports: [
        VehicleCardComponent
    ],
    imports: [
        CommonModule
    ]
})
export class VehicleCardModule { }
