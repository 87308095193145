import { Component, OnInit } from '@angular/core';
import {FeesModService} from '../fees.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AddEditFeesComponent} from '../add-edit-fees/add-edit-fees.component';
import {FeeAssociationComponent} from '../fee-association/fee-association.component';
import {FeeDeleteComponent} from '../fee-delete/fee-delete.component';
import {MainComponent} from '../../mainmodule/main/main.component';

@Component({
  selector: 'app-generic-fees-list',
  templateUrl: './generic-fees-list.component.html',
  styleUrls: ['./generic-fees-list.component.scss']
})
export class GenericFeesListComponent implements OnInit {
  userProfile: any;
  currentpage: number;
  maxpages: number;
  // setQuery
  query: any;
  limit: number;
  skip: number;
  sort: string;
  order: boolean;
  progress: boolean;
  fees: any;
  loading: boolean;
  pagenumbers = [];
  constructor(
    private feesService: FeesModService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog : MatDialog,
    public mainComponent: MainComponent,
  ) {
    this.loading = true;
    this.progress = false;
  }

  ngOnInit(): void {
    this.userProfile = JSON.parse(localStorage.getItem('fl_profile'));

    this.limit = 10;
    this.sort = 'createdAt';
    this.order = true;
    this.currentpage = 0;
    this.skip = (this.currentpage) * this.limit;

    this.query = {
      options: {
        tab: 'active',
        companies: [],
        users: [],
        carrier: '',
        viewAll: false,
      },
      sort: this.sort,
      limit: this.limit,
      skip: this.skip,
      order: this.order ? 'asc' : 'desc',
      text: '',
    };

    this.route.queryParams.subscribe(params => {
      this.query.sort = params.sort || this.sort;
      this.query.limit = params.limit || this.limit;
      this.query.skip = params.skip || this.skip;
      this.query.order = params.order || 'asc';
      this.query.text = params.text || '';
      this.query.options = (params.options) ? JSON.parse(params.options) : this.query.options;
      this.currentpage = this.query.skip / this.query.limit;
      this.getAllFess();
    });

  }

  getAllFess(){
    this.feesService.getAllFees(this.query).subscribe((res) => {
      this.fees = res;
      this.maxpages = Math.ceil((this.fees.count / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }

      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;
      this.loading = false;
    }, err => {
      console.log('error => ', err);
      this.loading = false;
    });
  }

  sortValue(value) {
    this.query.sort = value;
    this.sort = value;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  reverseOrder() {
    if (!this.order) {
      this.query.order = 'asc';
      this.order = true;
    } else {
      this.query.order = 'desc';
      this.order = false;
    }
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  navigate() {
    this.query.options = JSON.stringify(this.query.options);
    this.router.navigate([], {
      queryParams: this.query,
    });
  }

  next() {
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev() {
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value) {
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  toggleAddEditFees(fee?:any){
    const dialogRef = this.dialog.open(AddEditFeesComponent, {
      data: {
        fee
      },
      disableClose: true,
      width: '100%',
      maxWidth: '600px',
      // height: '100%',
      maxHeight: '900px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getAllFess();
      }
    });
  }

  toggleAssociation(fee:any){
    const dialogRef = this.dialog.open(FeeAssociationComponent, {
      data: {
        profile: this.mainComponent.loggedUser,
        fee
      },
      disableClose: true,
      width: '100%',
      maxWidth: '600px',
      // height: '100%',
      maxHeight: '900px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getAllFess();
      }
    });
  }

  toggleDelete(fee:any){
    const dialogRef = this.dialog.open(FeeDeleteComponent, {
      data: {
        fee
      },
      disableClose: true,
      width: '100%',
      maxWidth: '600px',
      // height: '100%',
      maxHeight: '900px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getAllFess();
      }
    });
  }

}
