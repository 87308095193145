<div id="searchCard" class="searchForm mb-5 collapse show">
  <div class="buttonrow mb-2">
    <button mat-button color="primary">
      <span class="material-icons">
        search
      </span>
      {{'FLIGHTS_SEARCH'|translate}}
    </button>
    <button mat-button routerLink="/flight/history">
      <span class="material-icons">
        history
      </span>
      {{'HISTORY'|translate}}
    </button>
  </div>
  <mat-card appearance="outlined" class="search-card searchFlight">
    <mat-card-header>
      <div mat-card-avatar class="search-header-image"></div>
      <mat-card-title>{{'FLIGHTS_SEARCH'|translate}}</mat-card-title>
      <mat-card-subtitle>{{'FILL_FORM'|translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

      <div class="row mb-2 typeRow">
        <div class="col-6 col-md-6 align-self-center">
         <!-- <label id="typeLabel">{{'TYPE'|translate}}</label> -->
          <mat-radio-group
            aria-labelledby="typeLabel"
            class="radio-group"
            [(ngModel)]="request.searchType"
            (ngModelChange)="changeType()"
            [disabled]="request.options.family">
            <mat-radio-button class="radio-button" value="oneway">{{'ONEWAY'|translate}}</mat-radio-button>
            <mat-radio-button class="radio-button" value="roundtrip">{{'ROUND'|translate}}</mat-radio-button>
            <mat-radio-button *ngIf="mainComponent.loggedUser._id!=='604f240ec5ec740012a28f46'" class="radio-button" value="multy">{{'MULTY'|translate}}</mat-radio-button>
          </mat-radio-group>

        </div>
        <div class="col-6 col-md-6 align-self-center">
          <span class="pointer d-block d-sm-inline mb-2" [matMenuTriggerFor]="menu">
            {{'PASSENGERS'|translate}}
            <span *ngIf="paxCounter()>0">: {{paxCounter()}}</span>
          </span>
          <span class="ml-2 mr-2 d-none d-sm-inline">|</span>
          <mat-menu #menu="matMenu">
            <div (click)="$event.stopPropagation();" mat-menu-item>
              <button class="text-center" [disabled]="request.passengers.adt==1 || (request.passengers.adt==2 && request.options.family)" mat-icon-button aria-label="Minus" (click)="removePax('adt')">
                <mat-icon style="margin:0;">remove</mat-icon>
              </button>
              <mat-form-field class="inline-width">
                <input class="text-center" readonly name="adt" [(ngModel)]="request.passengers.adt" matInput placeholder="{{'ADULTS'|translate}}">
              </mat-form-field>
              <button class="text-center" [disabled]="maxPaxReached()" mat-icon-button aria-label="Plus" (click)="addPax('adt')">
                <mat-icon style="margin:0;">add</mat-icon>
              </button>
            </div>
            <div *ngIf="!appComponent.isB2B && !appComponent.isSbt" (click)="$event.stopPropagation();" mat-menu-item>
              <button class="text-center" [disabled]="request.passengers.chd==0 || (request.passengers.chd==1 && request.options.family)" mat-icon-button aria-label="Minus" (click)="removePax('chd')">
                <mat-icon style="margin:0;">remove</mat-icon>
              </button>
              <mat-form-field class="inline-width">
                <input class="text-center" readonly [(ngModel)]="request.passengers.chd" matInput placeholder="{{'CHILDREN'|translate}}">
              </mat-form-field>
              <button class="text-center" [disabled]="maxPaxReached()" mat-icon-button aria-label="Plus" (click)="addPax('chd')">
                <mat-icon style="margin:0;">add</mat-icon>
              </button>
            </div>
            <div *ngIf="!appComponent.isB2B && !appComponent.isSbt" (click)="$event.stopPropagation();" mat-menu-item>
              <button class="text-center" [disabled]="request.passengers.inf==0" mat-icon-button aria-label="Minus" (click)="removePax('inf')">
                <mat-icon style="margin:0;">remove</mat-icon>
              </button>
              <mat-form-field class="inline-width">
                <input class="text-center" readonly [(ngModel)]="request.passengers.inf" matInput placeholder="{{'INFANT'|translate}}">
              </mat-form-field>
              <button class="text-center" [disabled]="maxInfReached()" mat-icon-button aria-label="Plus" (click)="addPax('inf')">
                <mat-icon style="margin:0;">add</mat-icon>
              </button>
            </div>
            <div *ngIf="maxPaxReached()" (click)="$event.stopPropagation();" mat-menu-item>
              Massimo numero di passeggeri raggiunto
            </div>
            <div *ngIf="request.passengers.adt==request.passengers.inf && maxInfReached()" (click)="$event.stopPropagation();" mat-menu-item>
              Massimo 1 neonato per ogni adulto
            </div>
          </mat-menu>
          <span *ngIf="mainComponent.loggedUser._id!=='604f240ec5ec740012a28f46'" class="pointer d-block d-sm-inline" (click)="toggleOptions()">
            {{'OTHER'|translate}}
            <span class="material-icons">
              keyboard_arrow_down
            </span>
          </span>
        </div>
        <div class="col-2 align-self-center">

        </div>

      </div>

      <div id="" class="row standardFields mb-2 collapse">
        <!--
        <div class="col-md-3 col-sm-6 col-6">
          <div class="watchFare">

            <mat-form-field class="full-width">
              <mat-label>{{'TYPE'|translate}}</mat-label>
              <mat-select [(ngModel)]="request.searchType" (ngModelChange)="changeType()" [disabled]="request.options.family">
                <mat-option value="oneway">{{'ONEWAY'|translate}}</mat-option>
                <mat-option value="roundtrip"> {{'ROUND'|translate}} </mat-option>
                <mat-option value="multy">{{'MULTY'|translate}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div> -->
        <!--
        <div class="col-md-3 col-sm-6 col-6" >
          <button class="smallmenu watchFare" mat-button [matMenuTriggerFor]="menu">
            {{'PASSENGERS'|translate}}
            <span *ngIf="paxCounter()>0">: {{paxCounter()}}</span>
          </button>
          <mat-menu #menu="matMenu">
            <div (click)="$event.stopPropagation();" mat-menu-item>
              <button class="text-center" [disabled]="request.passengers.adt==1 || (request.passengers.adt==2 && request.options.family)" mat-icon-button aria-label="Minus" (click)="removePax('adt')">
                <mat-icon style="margin:0;">remove</mat-icon>
              </button>
              <mat-form-field class="inline-width">
                <input class="text-center" readonly name="adt" [(ngModel)]="request.passengers.adt" matInput placeholder="{{'ADULTS'|translate}}">
              </mat-form-field>
              <button class="text-center" [disabled]="maxPaxReached()" mat-icon-button aria-label="Plus" (click)="addPax('adt')">
                <mat-icon style="margin:0;">add</mat-icon>
              </button>
            </div>
            <div (click)="$event.stopPropagation();" mat-menu-item>
              <button class="text-center" [disabled]="request.passengers.chd==0 || showCorporate || (request.passengers.chd==1 && request.options.family)" mat-icon-button aria-label="Minus" (click)="removePax('chd')">
                <mat-icon style="margin:0;">remove</mat-icon>
              </button>
              <mat-form-field class="inline-width">
                <input class="text-center" readonly [(ngModel)]="request.passengers.chd" matInput placeholder="{{'CHILDREN'|translate}}">
              </mat-form-field>
              <button class="text-center" [disabled]="maxPaxReached() || showCorporate" mat-icon-button aria-label="Plus" (click)="addPax('chd')">
                <mat-icon style="margin:0;">add</mat-icon>
              </button>
            </div>
            <div (click)="$event.stopPropagation();" mat-menu-item>
              <button class="text-center" [disabled]="request.passengers.inf==0 || showCorporate" mat-icon-button aria-label="Minus" (click)="removePax('inf')">
                <mat-icon style="margin:0;">remove</mat-icon>
              </button>
              <mat-form-field class="inline-width">
                <input class="text-center" readonly [(ngModel)]="request.passengers.inf" matInput placeholder="{{'INFANT'|translate}}">
              </mat-form-field>
              <button class="text-center" [disabled]="maxInfReached() || showCorporate" mat-icon-button aria-label="Plus" (click)="addPax('inf')">
                <mat-icon style="margin:0;">add</mat-icon>
              </button>
            </div>
            <div *ngIf="maxPaxReached()" (click)="$event.stopPropagation();" mat-menu-item>
              Massimo numero di passeggeri raggiunto
            </div>
            <div *ngIf="request.passengers.adt==request.passengers.inf && maxInfReached()" (click)="$event.stopPropagation();" mat-menu-item>
              Massimo 1 neonato per ogni adulto
            </div>
          </mat-menu>
        </div> -->

        <div class="col-md-3 col-sm-6 col-6">
          <div class="animationContent">
            <div *ngIf="!request.options.corporate && !request.options.family && !request.options.alliance && !request.options.etnic && !request.options.tourOperator">

              <app-carriers [parentForm]="request"></app-carriers>

              <div *ngIf="request.options.carrierIdentification.length>0" class="mb-3">
                <span *ngFor="let car of request.options.carrierIdentification;let c = index;" class="singleiata">
                  {{car}}
                  <span (click)="removeIataCarrier(c)" class="cancelicon" aria-hidden="true">&times;</span>
                </span>
              </div>
            </div>

            <div class="fakefield" *ngIf="request.options.family">
              {{request.options.carrierIdentification[0]}}
            </div>

            <div class="fakefield" *ngIf="request.options.etnic">{{'ONLY_ETNIC'}}</div>
            <div class="fakefield" *ngIf="request.options.tourOperator">{{'TOUR_OPERATOR'}}</div>
            <div class="fakefield" *ngIf="request.options.corporate">
              {{request.options.carrierIdentification[0]}}
            </div>

            <mat-form-field class="full-width" *ngIf="request.options.alliance" required>
              <mat-label>{{'ALLIANCE'}}</mat-label>
              <mat-select [(ngModel)]="request.options.carrierIdentification[0]">
                <mat-option value="*O">ONEWORLD</mat-option>
                <mat-option value="*S">SKYTEAM</mat-option>
                <mat-option value="*A">STAR ALLIANCE</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

        <div class="col-md-2 col-sm-6 col-6">
          <mat-form-field class="full-width">
            <mat-label>{{'CABIN'|translate}}</mat-label>
            <mat-select [(ngModel)]="request.options.cabin" name="cabin" (ngModelChange)="setClassValues($event)">
              <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
              <mat-option value="Y">{{'ECONOMY'|translate}}</mat-option>
              <mat-option value="W">{{'ECONOMY_PLUS'|translate}}</mat-option>
              <mat-option value="C">{{'BUSINESS_CLASS'|translate}}</mat-option>
              <mat-option value="F">{{'FIRST_CLASS'|translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2 col-sm-6 col-6">
          <mat-form-field class="full-width">
            <mat-label>{{'STOPS'|translate}}</mat-label>
            <mat-select [(ngModel)]="request.options.flightType" name="flightType">
              <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
              <mat-option value="N">{{'ONLY_NON_STOP'|translate}}</mat-option>

            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-2 col-sm-6 col-6">
          <mat-form-field class="full-width">
            <mat-label>{{'PRICE_TO_BEAT'|translate}}</mat-label>
            <input matInput type="number" [(ngModel)]="request.options.priceToBeat" name="priceToBeat">

          </mat-form-field>
        </div>

        <div class="col-md-2 col-sm-6 col-6">
          <mat-form-field class="full-width">
            <mat-label>{{'MAX_CONN_TIME'|translate}}</mat-label>
            <mat-select [(ngModel)]="request.options.maxTimeConnections" name="maxTimeConnections">
              <mat-option [value]="null">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
              <mat-option [value]="2">2</mat-option>
              <mat-option [value]="4">4</mat-option>
              <mat-option [value]="6">6</mat-option>
              <mat-option [value]="8">8</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

      <div *ngFor="let section of request.sections;let i = index;let z = index;let j = index;">
        <div class="row lightbg">

          <div class="flightnumeration">
            <span *ngIf="request.searchType=='multy'">{{z+1}}</span>
            <span *ngIf="request.searchType=='oneway'">
                  <mat-icon class="toright">flight</mat-icon>
                </span>

            <span *ngIf="request.searchType=='roundtrip' && z==0">
                  <mat-icon class="toright">flight</mat-icon>
                </span>
            <span *ngIf="request.searchType=='roundtrip' && z==1">
                  <mat-icon class="toleft">flight</mat-icon>
              </span>
          </div>

          <div class="col-md-6 col-sm-12 col-12">

            <app-airports [parentForm]="request" [index]="z" [isTransit]="false"></app-airports>

            <div class="row">
              <div class="col-md-5">
                <div *ngIf="request.sections[z].from.length>0" class="">
                  <span *ngFor="let iata of request.sections[z].from;let ia = index;" class="singleiata">
                    {{iataComplete.returnAirport(iata)}}
                    <span (click)="removeIataFrom(z,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                  </span>
                </div>
              </div>
              <div class="col-md-2 col-12 text-center align-self-center">
                <span (click)="invertIata(z)" class="switchIcon material-icons" [hidden]="request.sections[z].to.length == 0 && request.sections[z].from.length == 0">
                  swap_horiz
                </span>
              </div>
              <div class="col-md-5">
                <div *ngIf="request.sections[z].to.length>0" class="">
                <span *ngFor="let iata of request.sections[z].to;let ia = index;" class="singleiata">
                  {{iataComplete.returnAirport(iata)}}
                  <span (click)="removeIataTo(z,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                </span>
                </div>
              </div>
            </div>
            <!--
            <mat-form-field class="full-width">
              <mat-label>{{'DEPARTURE'|translate}}</mat-label>
              <input matInput placeholder="Ex. MXP" [(ngModel)]="from[z]" >
              <button mat-button *ngIf="from[z]" matSuffix mat-icon-button aria-label="Clear" (click)="pushFrom(z,from[z])">
                <mat-icon>add</mat-icon>
              </button>
            </mat-form-field> -->

          </div>

          <!-- <div class="col-md-3 col-sm-6 col-6">
            <mat-form-field class="full-width">
              <mat-label>{{'ARRIVAL'|translate}}</mat-label>
              <input matInput placeholder="Ex. JFK" [(ngModel)]="to[z]">
              <button mat-button *ngIf="to[z]" matSuffix mat-icon-button aria-label="Clear" (click)="pushTo(z,to[z])">
                <mat-icon>add</mat-icon>
              </button>
            </mat-form-field>

            <div *ngIf="request.sections[z].to.length>0" class="mb-3">
                <span *ngFor="let iata of request.sections[z].to;let ia = index;" class="singleiata">
                  {{iata}}
                  <span (click)="removeIataTo(z,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                </span>
            </div>

          </div> -->

          <div class="col-md-3 col-sm-6 col-6"> <!-- [ngClass]="{'col-md-3':searchType==='oneway','col-md-3':searchType!='oneway'}" -->

            <mat-form-field class="full-width">
              <input matInput readonly [matDatepicker]="jj" placeholder="{{'DEPARTURE_DATE'|translate}}" (click)="jj.open()" [min]="getMinDate(z)" [(ngModel)]="section.options.date" (dateChange)="setNextDate(z)" name="date{{z}}">
              <mat-datepicker-toggle matSuffix [for]="jj"></mat-datepicker-toggle>
              <mat-datepicker #jj></mat-datepicker>
            </mat-form-field>

          </div>

          <div class="col-md-3 col-sm-6 col-6 buttonrow">
            <button mat-icon-button color="warn" matTooltip="{{'REMOVE_SECTION'}}" aria-label="delete" (click)="removeSection(z)" [hidden]="request.searchType=='oneway' || request.sections.length<=1">
              <mat-icon>delete_outline</mat-icon>
            </button>
            <button *ngIf="mainComponent.loggedUser._id!=='604f240ec5ec740012a28f46'" mat-button matTooltip="{{'TUNE_SECTION'|translate}}" aria-label="tune" (click)="toggleCollapse(z)">
              <mat-icon>tune</mat-icon> <span style="font-weight: normal">{{'TUNE_SECTION'|translate}}</span>
            </button>
          </div>

          <div class="col-12 collapse" id="{{z}}">
            <div class="row inwhiterow">
              <div class="col-md-3 col-sm-6 col-6">
                <mat-form-field class="full-width">
                  <mat-label>{{'TIME'|translate}}</mat-label>
                  <mat-select [(ngModel)]="section.options.timeSlot" name="time">
                    <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                    <mat-option value="2">{{'MORNING'|translate}} (06-12)</mat-option>
                    <mat-option value="3">{{'AFTERNOON'|translate}} (12-18)</mat-option>
                    <mat-option value="4">{{'EVENING'|translate}} (18-24)</mat-option>
                    <mat-option value="1">{{'NIGHT'|translate}} (00-06)</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-6 col-6">
                <mat-form-field class="full-width">
                  <mat-label>{{'CABIN'|translate}}</mat-label>
                  <mat-select [(ngModel)]="section.options.cabin" name="cabin" id="firstClassSelect" (ngModelChange)="singleCabinChange()">
                    <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                    <mat-option value="Y">{{'ECONOMY'|translate}}</mat-option>
                    <mat-option value="W">{{'ECONOMY_PLUS'|translate}}</mat-option>
                    <mat-option value="C">{{'BUSINESS_CLASS'|translate}}</mat-option>
                    <mat-option value="F">{{'FIRST_CLASS'|translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-6 col-6">
                <!--
                <mat-form-field class="full-width">
                  <mat-label>{{'VIA_AIRPORT'|translate}}</mat-label>
                  <input matInput placeholder="Ex. JFK" [(ngModel)]="via[z]">
                  <button mat-button *ngIf="via[z]" matSuffix mat-icon-button aria-label="Clear" (click)="pushBy(z,via[z])">
                    <mat-icon>add</mat-icon>
                  </button>
                </mat-form-field> -->
                <app-airports [parentForm]="request" [index]="z" [isTransit]="true"></app-airports>
                <div *ngIf="request.sections[z].options.viaAirport.length>0" class="mb-3">
                    <span *ngFor="let iata of request.sections[z].options.viaAirport;let ia = index;" class="singleiata">
                      {{iataComplete.returnAirport(iata)}}
                      <span (click)="removeIataVia(z,ia)" class="cancelicon" aria-hidden="true">&times;</span>
                    </span>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-6">
                <mat-form-field class="full-width">
                  <mat-label>{{'STOPS'|translate}}</mat-label>
                  <mat-select [(ngModel)]="section.options.flightType" name="cabin">
                    <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                    <mat-option value="0">{{'NON_STOPS'|translate}}</mat-option>
                    <mat-option value="1">1 {{'STOP'|translate}}</mat-option>
                    <mat-option value="2">2 {{'STOPS'|translate}}</mat-option>
                    <mat-option value="3">3 {{'STOPS'|translate}}</mat-option>
                    <mat-option value="4">4 {{'STOPS'|translate}}</mat-option>
                    <mat-option value="5">5 {{'STOPS'|translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="searchoptions marginbottom10" *ngIf="mainComponent.loggedUser._id!=='604f240ec5ec740012a28f46'">
        <mat-checkbox *ngIf="!appComponent.isB2B && !appComponent.isSbt && !request.options.family && !request.options.etnic && !request.options.tourOperator && !showCorporate" [(ngModel)]="request.options.alliance" (ngModelChange)="setAlliance($event)">{{'ALLIANCE'|translate}}</mat-checkbox>
        <!--
        <mat-checkbox *ngIf="!appComponent.isB2B &&!appComponent.isSbt && !request.options.alliance && !request.options.etnic && !request.options.refundable  && !showCorporate  && !request.options.tourOperator" [(ngModel)]="request.options.family" (ngModelChange)="setFamily($event)">{{'FAMILY_PLAN_ALITALIA'|translate}}</mat-checkbox>
        -->
        <mat-checkbox *ngIf="!appComponent.isB2B &&!appComponent.isSbt && !request.options.alliance && !request.options.family && !request.options.refundable  && !showCorporate  && !request.options.tourOperator" [(ngModel)]="request.options.etnic" (ngModelChange)="setEtnic($event)">{{'ONLY_ETNIC'|translate}}</mat-checkbox>
        <!--
        <mat-checkbox *ngIf="!request.options.family && !request.options.etnic  && !showCorporate && !request.options.tourOperator" [(ngModel)]="request.options.refundable" (ngModelChange)="setRefound($event)">{{'ONLY_REFOUNDABLE'|translate}}</mat-checkbox>
        -->
        <mat-checkbox *ngIf="corporateCodes.length>0 && !request.options.family && !request.options.etnic && !request.options.tourOperator && !request.options.refundable && !request.options.alliance" [(ngModel)]="showCorporate" (ngModelChange)="setCorporate($event)">{{'CORPORATES_FARES'|translate}}</mat-checkbox>

        <mat-checkbox *ngIf="!appComponent.isB2B && !appComponent.isSbt && !request.options.alliance && !request.options.family && !request.options.etnic && !request.options.refundable  && !showCorporate && (mainComponent.loggedUser.role.superAdmin || mainComponent.company.cauDK)" [(ngModel)]="request.options.tourOperator" (ngModelChange)="settourOperator($event)">{{'TOUR_OPERATOR'|translate}}</mat-checkbox>

        <span *ngIf="checkResidents() && !request.options.alliance && !request.options.family && !request.options.etnic && !request.options.refundable  && !showCorporate && !request.options.tourOperator">
          <mat-checkbox (ngModelChange)="setResidents($event)" [(ngModel)]="request.options.resident">
            {{'RESIDENTS'|translate}}
          </mat-checkbox>
          <span (click)="toggleInfoResidents()" class="material-icons" style="vertical-align: baseline">
            contact_support
          </span>
        </span>

        <div class="col-md-2 col-sm-6 col-6" *ngIf="showCorporate">
          <mat-form-field class="full-width">
            <mat-select [(ngModel)]="request.options.corporate" name="cabin">
              <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
              <mat-option *ngFor="let corporateCode of corporateCodes" [value]="corporateCode.code" >{{ corporateCode.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="formActionsButtons text-right">
        <div *ngIf="!loading">
          <button mat-raised-button color="primary" type="button" type="button" (click)="addNewSection()" [hidden]="request.searchType!='multy' || request.sections.length>=6">
            {{'ADD_SECTION'|translate}}
          </button>
          <button mat-raised-button color="primary" type="button" type="button" (click)="addNewSection()" [hidden]="request.searchType!='roundtrip' || request.sections.length>=2">
            {{'ADD_RETURN'|translate}}
          </button>
          <button mat-raised-button color="primary" type="button" [disabled]="isValid()" (click)="startSearch(false, undefined)">
            {{'SEARCH'|translate}}
          </button>
          <button matTooltip="{{'NO_LOWCOST_FOR_CALENDAR'|translate}}" *ngIf="mainComponent.loggedUser._id!=='604f240ec5ec740012a28f46'" (click)="startSearch(true, undefined)" mat-raised-button color="primary" type="button" [hidden]="request.sections.length>2" [disabled]="isValid()">
            {{'SEARCH_CALENDAR'|translate}}
          </button>
          <button mat-raised-button color="warn" type="button" (click)="clearSearch()">{{'CLEAR_FORM'|translate}}</button>
        </div>
        <div *ngIf="loading || loadinglow" class="text-right">

          <div class="mb-2">{{'LOADING'|translate}}</div>
          <mat-progress-bar style="max-width: 200px;display:inline-block;" mode="indeterminate"></mat-progress-bar>
        </div>

      </div>
    </mat-card-content>
  </mat-card>


</div>

<div *ngIf="loading || loadinglow" class="nowloading padding20 text-center">
  {{'LOADING'|translate}}
  <div class="currentForm">
    <p *ngFor="let sec of request.sections;">
      {{sec.options.date|date:'EEE dd MMM yyyy'}} {{iataComplete.returnAirport(sec.from)}}<sup>{{sec.from}}</sup> <mat-icon>arrow_right_alt</mat-icon> {{iataComplete.returnAirport(sec.to)}}<sup>{{sec.to}}</sup>
    </p>
  </div>
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>
<!--
<div *ngIf="searchResults && searchResults.itineraries" class="buttonrow mb-3 text-right">
  <button class="mainbg" mat-stroked-button (click)="toggleForm()">
    <span *ngIf="!formvisible">{{'EDIT_SEARCH'|translate}}</span>
    <span *ngIf="formvisible">{{'HIDE_FORM'|translate}}</span>
  </button>
</div> -->

<div class="modal fade" id="infoResidentsModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="infoResidentsModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="top:70px" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="infoResidentsModalLabel">Tariffa di continuità territoriale</h4>
      </div>

      <div class="modal-body">
        <div class="sardidescription">
          <h5 style="margin-bottom:20px;font-weight:normal;">
            Tariffa valida e disponibile per le seguenti categorie di passeggeri:
          </h5>
          <ul class="elencopuntato">
            <li>Residenti in Sardegna*</li>
            <li>Disabili**</li>
            <li>Giovani dai 2 ai 21 anni*</li>
            <li>Anziani al di sopra dei 70 anni*</li>
            <li>Studenti universitari fino al compimento di 27° anno di età ***</li>
          </ul>
          <h5 style="margin-bottom:10px;font-weight:bold;">
            Documentazione da presentare al check-in:
          </h5>
          <ul class="elencopuntato">
            <li>* un documento di identità; valido e che attesti l'effettiva residenza in Sardegna o i requisiti di età previsti per beneficiare della tariffa </li>
            <li>** la certificazione medica comprovante la disabilità; </li>
            <li>*** il libretto/badge universitario in corso di validità e un certificato di iscrizione relativo all'anno accademico in corso.</li>
          </ul>
          <hr/>
          <p>Non è possibile acquistare in un’unica transazione online biglietti aerei per passeggeri che possiedono i requisiti per tariffe agevolate di Continuità Territoriale congiuntamente a passeggeri che non possiedono tali requisiti.</p>
          <p>La compagnia aerea si riserva la possibilità di perseguire presso le sedi competenti i non aventi diritto.</p>
          <hr/>
          <h5 style="margin-bottom:20px;font-weight:normal;">Le tariffe di continuità territoriale sono disponibili per le seguenti tratte:</h5>
          <ul class="elencopuntato">
            <li>OLB - FCO / FCO - OLB</li>
            <li>FCO - CAG / CAG - FCO</li>
            <li>LIN - CAG / CAG - LIN</li>
            <li>LIN - AHO / AHO - LIN</li>
            <li>FCO - AHO / AHO - FCO</li>
            <li>MXP - CAG / CAG - MXP</li>
            <li>MXP - AHO / AHO - MXP</li>
            <li>FCO - AHO / AHO - FCO</li>
          </ul>

        </div>
      </div>

      <div class="modal-footer">
        <button type="button" mat-button (click)="toggleInfoResidents()">{{'CLOSE'|translate}}</button>
      </div>
    </div> <!-- /.modal-content -->

  </div>
</div>
