import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-helpdialog',
  templateUrl: './helpdialog.component.html',
  styleUrls: ['./helpdialog.component.scss']
})
export class HelpdialogComponent implements OnInit {
  now = new Date();
  phone: any;
  phoneNew = '0172652510';
  html: any;
  reperibilita = false;
  constructor(
    
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data.isGat || this.data.isSbt) {
      this.phone = '0392622860';
    } else if(this.data.mainComponent.loggedUser.company._id === '5e2715022aeeb50011f53f5d'){
      this.phone = '0171451411';
    } else {
      this.phone = '0110708337';
    }
  }

  ngOnInit(): void {
    /*
    console.log('now day = ', this.now.getDay());
    console.log('now hour = ', this.now.getHours());
    if ((this.now.getDay() === 6 && this.now.getHours() > 12) || this.now.getDay() === 0) {
      this.html = '' +
        // tslint:disable-next-line:max-line-length
        '<div class="alert alert-danger" style="text-align:left;font-size:14px;color:#a94442!important;background-color:#f2dede!important">' +
        '<b>Attenzione:</b><br/>Ricordiamo che le richieste di assistenza telefonica sono evase durante l orario di ufficio.</div>';
    } else {
      this.html = 'Puoi contattarci al numero: <a href="tel:+390110708337">+39 ' + this.phone + '</a><br/>';
    }
    */
   if(this.data.isGat){
    if ((this.now.getDay() === 6 && this.now.getHours() > 12) || this.now.getDay() === 0) {
      this.html = '' +
        // tslint:disable-next-line:max-line-length
        '<div class="alert alert-danger" style="text-align:left;font-size:14px;color:#a94442!important;background-color:#f2dede!important">' +
        '<b>Attenzione:</b><br/>Ricordiamo che le richieste di assistenza telefonica sono evase durante l orario di ufficio.</div>';
    } else {
      this.html = 'Puoi contattarci al numero: <a href="tel:+390110708337">+39 ' + this.phone + '</a><br/>';
    }
   } else {
    this.checkDate();
   }
    
  }

  isDataSuccessivaOMarzo2024(data) {
    // Crea un oggetto data per il 1 marzo 2024
    var dataMarzo2024 = new Date('2024-03-01');
    // Confronta le date
    if (data >= dataMarzo2024) {
        return true; // La data è successiva o uguale al 1 marzo 2024
    } else {
        return false; // La data è precedente al 1 marzo 2024
    }
  } 

  checkDate(){
    this.html = '' + 
    '<div class="mb-2 small">(ORARIO UFFICIO dal lunedì al venerdì 09.00 - 19.30 ed il  Sabato 09.00 - 13.00)</div>' +
    '<div class="mb-2">Per emergenze è a disposizione il servizio di assistenza con reperibilità in H24/365 a pagamento<sup>*</sup></div>' +
    '<div class="mb-2"><b>Attenzione:</b> il servizio d’emergenza fuori orario d’ufficio, s’intende esclusivamente telefonico.</div>' + 
    '<div class="text-center mb-2">NUMERO EMERGENZE IN H24: <a href="tel:+39'+ this.phoneNew + '">+39 ' + this.phoneNew + '</a></div>' +
    '<div class="text-center small">* tariffa come da contratto</div>';
    if((this.now.getDay() === 6 && this.now.getHours() < 9) && this.now.getHours() >= 13 && this.now.getMinutes() > 1){
      this.reperibilita = true;
    } else if(this.now.getDay() != 6 && this.now.getDay() != 0 && this.now.getHours() < 9 && (this.now.getHours() > 19 && this.now.getMinutes() > 30)){
      this.reperibilita = true;
    } else if(this.now.getDay() == 0){
      this.reperibilita = true;
    } else {
      this.reperibilita = false;
      this.html = 'Puoi contattarci al numero: <a href="tel:+39'+ this.phone + '">+39 ' + this.phone + '</a><br/>';
    }

  }

}
