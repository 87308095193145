import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchHotelFormComponent } from './components/hotel/search/searchform/searchform.component';
import { SearchHotelComponent } from './components/hotel/search/search.component';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { SearchHotelresultsComponent } from './components/hotel/search/searchresults/searchresults.component';
import { HotelReservationlistComponent } from './components/hotel/reservationlist/reservationlist.component';
import { SinglehotelinfoComponent } from './components/hotel/singlehotelinfo/singlehotelinfo.component';
import { SinglehotelroomsComponent } from './components/hotel/singlehotelrooms/singlehotelrooms.component';
import { TravelersModule } from '../travelers/travelers.module';
import { HotelreservationComponent } from './components/hotel/hotelreservation/hotelreservation.component';
import { DossierModule } from '../dossier/dossier.module';
import { Hotelresultsv2Component } from './components/hotel/search/hotelresultsv2/hotelresultsv2.component';
import { HotelroomsPipe } from '../../pipe/hotelrooms.pipe';
import { NewresultsComponent } from './components/hotel/search/newresults/newresults.component';
import { FopsModule } from '../fops/fops.module';
import { HoteltstComponent } from './components/hotel/hotelreservation/hoteltst/hoteltst.component';
import { CustomCommonModule } from '../common/custom-common.module';
import { HotelRoutingModule } from './hotel-routing.module';
import { AutocompletecompaniesComponent } from './components/autocompletecompanies/autocompletecompanies.component';
import { SearchformV2Component } from './components/hotel/search/searchform-v2/searchform-v2.component';
import { SearchResultsV2Component } from './components/hotel/search/search-results-v2/search-results-v2.component';
import { SingleHotelQuotationComponent } from './components/hotel/single-hotel-quotation/single-hotel-quotation.component';
import { GalleryModule } from  'ng-gallery';
import { LightboxModule } from  'ng-gallery/lightbox';
import { LIGHTBOX_CONFIG } from 'ng-gallery/lightbox';
import { BannerSlideComponent } from './components/banner-slide/banner-slide.component';
import { EstablishmentService, SearchService } from '../../sdk/hotel';
import { SearchResultsV3Component } from './components/hotel/search/search-results-v3/search-results-v3.component';
import { SearchFormV3Component } from './components/hotel/search/search-form-v3/search-form-v3.component';
import { RangeSliderModule } from 'ngx-range-slider';
import { MapInfoDialogComponent } from './map-info-dialog/map-info-dialog.component';
import { MapResultsDialogComponent } from './map-results-dialog/map-results-dialog.component';
import { HotelQuotationV3Component } from './hotel-quotation-v3/hotel-quotation-v3.component';
import { SingleRoomV3Component } from './single-room-v3/single-room-v3.component';
import { LightgalleryModule } from 'lightgallery/angular/13';
import { RoomsListDialogComponent } from './rooms-list-dialog/rooms-list-dialog.component';
import { RoomSelectedComponent } from './room-selected/room-selected.component';
import { FlightModule } from "../flight/flight.module";
import { HotelQuotationV4Component } from './hotel-quotation-v4/hotel-quotation-v4.component';
import { SingleRoomV4Component } from './single-room-v4/single-room-v4.component';
import { RoomSelectedV2Component } from './room-selected-v2/room-selected-v2.component';
import { NewHotelQuotationV3Component } from './new-hotel-quotation-v3/new-hotel-quotation-v3.component';

@NgModule({
  declarations: [
    SearchHotelComponent,
    SearchHotelFormComponent,
    SearchHotelresultsComponent,
    HotelReservationlistComponent,
    SinglehotelinfoComponent,
    SinglehotelroomsComponent,
    HotelreservationComponent,
    Hotelresultsv2Component,
    HotelroomsPipe,
    NewresultsComponent,
    HoteltstComponent,
    AutocompletecompaniesComponent,
    SearchformV2Component,
    SearchResultsV2Component,
    SingleHotelQuotationComponent,
    BannerSlideComponent,
    SearchResultsV3Component,
    SearchFormV3Component,
    MapInfoDialogComponent,
    MapResultsDialogComponent,
    HotelQuotationV3Component,
    SingleRoomV3Component,
    RoomsListDialogComponent,
    RoomSelectedComponent,
    HotelQuotationV4Component,
    SingleRoomV4Component,
    RoomSelectedV2Component,
    NewHotelQuotationV3Component,
  ],
    imports: [
        LightgalleryModule,
        HotelRoutingModule,
        CommonModule,
        SharedModule,
        MaterialModule,
        TravelersModule,
        DossierModule,
        FopsModule,
        CustomCommonModule,
        GalleryModule,
        LightboxModule,
        RangeSliderModule,
        FlightModule,
    ],
  exports: [
    HotelreservationComponent,
    HotelReservationlistComponent,
  ],
  providers: [
    SearchService,
    EstablishmentService,
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: false
      }
    }
  ]
})
// @ts-ignore
export class HotelModule{}
