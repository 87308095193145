<div class="text-center" *ngIf="loading">
  <p>{{'LOADING'|translate}}</p>
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>

<div class="padding20">
  <div class="row">
    <div class="col-lg-6">
      <div *ngIf="!activeRole" class="mb-2 mr-3">
        <h5 class="mb-2">{{'ADD_GROUP'|translate}}</h5>
        <form autocomplete="chrome-off">
          <mat-form-field class="full-width">
            <mat-label>Nome gruppo</mat-label>
            <input matInput type="text" [(ngModel)]="newName" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </form>
        <div class="buttonrow">
          <button [disabled]="!newName" mat-raised-button color="primary" (click)="addRole()">{{'ADD_GROUP'|translate}}</button>
        </div>
      </div>
    </div>
    <div class="col-lg-6" *ngIf="!loading && roles && !activeRole">
      <h5 class="mb-2">{{'GROUPS'|translate}}</h5>
      <div class="table-responsive buttonrow smalltext">
        <div class="table table-bordered table-condensed">
          <table class="full-width">
            <tr>
              <th>{{'GROUP'|translate}}</th>
              <th>{{'USERS'|translate}}</th>
              <th>{{'ACTIONS'|translate}}</th>
            </tr>
            <tr *ngFor="let role of roles.list">
              <td>{{role.name || 'default'}}</td>
              <td>{{role.users.length}}</td>
              <td><span class="pointer" (click)="openRole(role)">{{'EDIT'|translate}}</span></td>
            </tr>
          </table>
        </div>
      </div>
      <!--
      <div class="mb-2 buttonrow" *ngFor="let role of roles.list">
        {{role.name || 'default'}}
        <span class="material-icons">
         arrow_right_alt
        </span>
        {{'USERS'|translate}} {{role.users.length}}
        <span class="material-icons">
         arrow_right_alt
        </span>
        <button mat-stroked-button (click)="openRole(role)">{{'EDIT'|translate}}</button>
      </div> -->
    </div>
  </div>

  <div *ngIf="activeRole">
    <div class="mb-2 row">
      <div class="col-lg-6">
        <div class="mb-2">
          <form autocomplete="chrome-off">
            <mat-form-field class="example-form-field">
              <mat-label>Nome gruppo</mat-label>
              <input matInput type="text" [(ngModel)]="activeRole.name" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </form>
        </div>

        <div class="mb-2 text-danger" *ngIf="activeRole.users.length == 0">
          Nessun utente assegnato a questo gruppo.
        </div>
        <div class="mb-2">{{'USERS'|translate}}</div>
        <div class="mb-2" *ngIf="!usersList">
          {{'LOADING'|translate}}
          <mat-progress-bar mode="buffer"></mat-progress-bar>
        </div>
        <!--
        <div *ngIf="usersList">
          <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
          </mat-form-field>

          <div class="mb-2">
            <table mat-table [dataSource]="dataSource">


              <ng-container matColumnDef="selection">
                <th mat-header-cell *matHeaderCellDef> Selezione </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox class="mb-1" color="primary" [(ngModel)]="row.selected" (ngModelChange)="manageUser(row._id)">
                  </mat-checkbox>
                </td>
              </ng-container>


              <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef> Progress </th>
                <td mat-cell *matCellDef="let row"> {{row.surname}} {{row.name}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>

        </div> -->

        <div class="mb-2 withScroll" *ngIf="usersList">
          <form autocomplete="chrome-off">
            <mat-form-field class="full-width">
              <mat-label>{{'SEARCH'|translate}}</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
            </mat-form-field>
          </form>
          <div *ngFor="let user of obs | async">
            <mat-checkbox class="mb-1" color="primary" [(ngModel)]="user.selected" [ngModelOptions]="{standalone: true}" (ngModelChange)="manageUser(user._id)">
              {{user.surname}} {{user.name}}
            </mat-checkbox>
          </div>
        </div>

        <div class="text-right buttonrow mr-2">
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          <button mat-stroked-button (click)="saveUsers()">{{'SAVE'|translate}}</button>
          <button color="warn" mat-stroked-button (click)="closeRole()">
            {{'ABORT_EDITING'|translate}}
          </button>
        </div>

      </div>
      <div class="col-lg-6">

        <form>
          <fieldset [disabled]="!maincomponent.loggedUser.role.profile.user.role || !maincomponent.loggedUser.role.profile.user.role.post">

            <div class="marginbottom20" *ngIf="!maincomponent.appcomponent.isGat">
              <h3 class="titlecolor">
                <mat-icon aria-hidden="false" aria-label="plane">person</mat-icon> {{'USER_TYPE'|translate}}
              </h3>
              <div class="row marginbottom20">

                <div class="col-xl-4 marginbottom10">
                  <h4>{{'TYPE'|translate}}</h4>
                  <p>
                    <i>
                      {{'B2C_USER_DESCRIPTION'|translate}}
                    </i>
                  </p>
                </div>
                <div class="col-xl-8">
                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.customer" (change)="changeUserB2c($event)">
                      {{'CUSTOMER'|translate}}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>


            <div class="marginbottom20">
              <h3 class="titlecolor">
                <mat-icon aria-hidden="false" aria-label="plane">airplanemode_active</mat-icon> {{'FLIGHTS'|translate}}
              </h3>

              <div class="row marginbottom20">

                <div class="col-xl-4 marginbottom10">
                  <h4>{{'HISTORY'|translate}}</h4>
                  <p>
                    <i>
                      {{'HISTORY_DESCR'|translate}}
                    </i>
                  </p>
                </div>
                <div class="col-xl-8">
                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.flight.search.get.company" (change)="changeFlightChrono()">
                      {{'VIEW_HISTORY'|translate}}
                    </mat-checkbox>
                  </div>
                  <!--
                  <div class="singleslider" *ngIf="activeRole.flight.search.get.company">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.flight.search.post.company">
                      {{'EDIT_HISTORY'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider" *ngIf="activeRole.flight.search.get.company">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.flight.search.delete.company">
                      {{'DELETE_HISTORY'|translate}}
                    </mat-checkbox>
                  </div>
                  -->

                </div>
              </div>


              <div class="row">
                <div class="col-xl-4 marginbottom10">
                  <h4>{{'RESERVATIONS'|translate}}</h4>
                  <p>
                    <i>
                      {{'RESERVATIONS_DESCR'|translate}}
                    </i>
                  </p>
                </div>
                <div class="col-xl-8">
                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.flight.taw.get.company">
                      {{'RESERVATIONS_VIEW'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.flight.taw.post.options.link">
                      {{'RESERVATIONS_LINK'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.flight.taw.post.options.issue">
                      {{'RESERVATIONS_ISSUE'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.flight.taw.delete.options.linked">
                      {{'RESERVATIONS_DELETE'|translate}}
                    </mat-checkbox>
                  </div>

                </div>
              </div>
            </div>

            <div class="marginbottom20" *ngIf="(maincomponent.loggedUser.role.superAdmin || maincomponent.loggedUser.company.type!='A') && !maincomponent.appcomponent.isGat">
              <hr/>
              <h3 class="titlecolor">
                <mat-icon aria-hidden="false" aria-label="hotel">hotel</mat-icon> Hotel
              </h3>
              <!--
              <div class="row marginbottom20">

                <div class="col-xl-4 marginbottom10">
                  <h4>{{'HISTORY'|translate}}</h4>
                  <p>
                    <i>
                      {{'HISTORY_DESCR'|translate}}
                    </i>
                  </p>
                </div>
                <div class="col-xl-8">
                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.hotel.search.get.company">
                      {{'VIEW_HISTORY'|translate}}
                    </mat-checkbox>
                  </div>

                </div>
              </div>
              -->

              <div class="row marginbottom20">

                <div class="col-xl-4 marginbottom10">
                  <h4>{{'RESERVATIONS'|translate}}</h4>
                  <p>
                    <i>
                      {{'RESERVATIONS_HT_DESCR'|translate}}
                    </i>
                  </p>
                </div>
                <div class="col-xl-8">
                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.hotel.taw.get.company">
                      {{'RESERVATIONS_VIEW'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.hotel.taw.post.options.issue">
                      {{'RESERVATIONS_LINK'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.hotel.taw.delete.options.issued">
                      {{'RESERVATIONS_DELETE'|translate}}
                    </mat-checkbox>
                  </div>

                </div>
              </div>

            </div>

            <div class="marginbottom20" *ngIf="(maincomponent.loggedUser.role.superAdmin || maincomponent.loggedUser.company.type!='A') && !maincomponent.appcomponent.isGat">
              <hr/>
              <h3 class="titlecolor">
                <mat-icon aria-hidden="false" aria-label="hotel">train</mat-icon> Trains
              </h3>

              <div class="row marginbottom20" *ngIf="activeRole.train">

                <div class="col-xl-4 marginbottom10">
                  <h4>{{'RESERVATIONS'|translate}}</h4>
                  <p>
                    <i>
                      {{'RESERVATIONS_HT_DESCR'|translate}}
                    </i>
                  </p>
                </div>
                <div class="col-xl-8">
                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.train.taw.get.company">
                      {{'RESERVATIONS_VIEW'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.train.taw.post.options.issue">
                      {{'RESERVATIONS_LINK'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.train.taw.delete.options.issued">
                      {{'RESERVATIONS_DELETE'|translate}}
                    </mat-checkbox>
                  </div>

                </div>
              </div>

            </div>

            <div class="marginbottom20">
              <hr/>
              <h3 class="titlecolor">
                <mat-icon aria-hidden="false" aria-label="users">person</mat-icon> {{'USERS_COMPANY'|translate}}
              </h3>

              <div class="row marginbottom20">

                <div class="col-xl-4 marginbottom10">
                  <h4>{{'USERS'|translate}}</h4>
                  <p>
                    <i>
                      {{'USERS_DESCR'|translate}}
                    </i>
                  </p>
                </div>
                <div class="col-xl-8">
                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.profile.user.get.company" (change)="changeUser()">
                      {{'VIEW_USERS'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" *ngIf="activeRole.profile.user.get.company" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.profile.user.post.company">
                      {{'EDIT_USERS'|translate}}
                    </mat-checkbox>
                  </div>
                </div>
              </div>

              <div class="row marginbottom20">

                <div class="col-xl-4 marginbottom10">
                  <h4>{{'COMPANY'|translate}}</h4>
                  <p>
                    <i>
                      {{'COMPANY_DESCR'|translate}}
                    </i>
                  </p>
                </div>
                <div class="col-xl-8">
                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.profile.company.get.company" (change)="changeCompany()">
                      {{'VIEW_COMPANY'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" *ngIf="activeRole.profile.company.get.company" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.profile.company.post.company">
                      {{'EDIT_COMPANY'|translate}}
                    </mat-checkbox>
                  </div>
                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.company">
                      {{'MANAGER/ADMIN'|translate}}
                    </mat-checkbox>
                  </div>
                </div>
              </div>

            </div>

            <div class="marginbottom20">
              <hr/>
              <h3 class="titlecolor">
                <mat-icon aria-hidden="false" aria-label="users">euro</mat-icon> {{'TARIFFES'|translate}}
              </h3>

              <div class="row marginbottom20">

                <div class="col-xl-4 marginbottom10">
                  <h4>{{'MANAGE_CORPORATE'|translate}}</h4>
                  <p>
                    <i>
                      {{'MANAGE_CORPORATE_DESCR'|translate}}
                    </i>
                  </p>
                </div>
                <div class="col-xl-8">
                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.profile.company.corporate.read" (change)="changeCorporate()">
                      {{'VIEW_CORPORATE'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider" *ngIf="activeRole.profile.company.corporate.read">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.profile.company.corporate.post">
                      {{'EDIT_CORPORATE'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider" *ngIf="activeRole.profile.company.corporate.read">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.profile.company.corporate.put">
                      {{'ADD_CORPORATE'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider" *ngIf="activeRole.profile.company.corporate.read">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.profile.company.corporate.delete">
                      {{'DELETE_CORPORATE'|translate}}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <div class="marginbottom20" *ngIf="maincomponent.loggedUser.role.superAdmin">
              <hr/>
              <h3 class="titlecolor">
                <mat-icon aria-hidden="false" aria-label="security">security</mat-icon> {{'ADMINISTRATION'|translate}}
              </h3>

              <div class="row marginbottom20">

                <div class="col-xl-4 marginbottom10">
                  <h4>{{'ADMINISTRATION_ROLE'|translate}}</h4>
                  <p>
                    <i>
                      {{'ADMINISTRATION_DESCR'|translate}}
                    </i>
                  </p>
                </div>
                <div class="col-xl-8">

                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.superAdmin">
                      {{'SUPERADMIN'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.superLogin">
                      {{'LOGIN_AS'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.adv">
                      {{'USERS_ACTIVATIONS'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.promoter">
                      {{'PROMOTER'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.isTktOperator" (change)="changeTickets()">
                      {{'TKT_MANAGEMENT'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider" *ngIf="activeRole.isTktOperator">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.tktZones.office">
                      {{'OFFICE'|translate}}
                    </mat-checkbox>
                  </div>

                  <div class="singleslider" *ngIf="activeRole.isTktOperator">
                    <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}"  [(ngModel)]="activeRole.tktZones.tech">
                      {{'TECH_SUPPORT'|translate}}
                    </mat-checkbox>
                  </div>

                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-xl-4 marginbottom10"></div>
              <div class="col-xl-8 buttonrow">
                <button class="marginright10" (click)="editRole()" color="primary" mat-stroked-button>
                  {{'SAVE'|translate}}
                </button>
                <button color="warn" mat-stroked-button (click)="closeRole()" mat-button>
                  {{'ABORT_EDITING'|translate}}
                </button>
              </div>

            </div>

          </fieldset>

        </form>

      </div>
    </div>

  </div>
</div>



