<custom-title></custom-title>
<div class="padding20">
  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}</h5>
    </div>

  </div>

  <div class="profilecontainer" *ngIf="profile">

    <div class="profiletopdetails marginbottom20">
      <div class="row">
        <div class="col-xl-4">
          <div class="userAvatarContainer">
            <div class="userimage">
              <img [src]="profile.image"/>
            </div>
            <div class="uploadIcon">
              <button mat-mini-fab color="primary" data-toggle="modal" data-target="#exampleModal">
                <mat-icon>photo_camera</mat-icon>
              </button>
            </div>
          </div>

          <div class="userMain">
            <span class="username">{{profile.name}} {{profile.surname}}</span>
            <span class="userrole">{{profile.type}}</span>
            <span class="userAgency" *ngIf="customAuth.canBook(maincomponent.loggedUser._id) && maincomponent.loggedUser.role.profile.company.get.company">
              <mat-icon style="vertical-align:bottom;" aria-hidden="false" aria-label="subdirectory_arrow_right">subdirectory_arrow_right</mat-icon>
              <a routerLink="/companies/list/{{profile.company._id}}">{{profile.company.name}}</a>
            </span>
          </div>

        </div>
        <div class="col-xl-4"></div>
        <div class="col-xl-4 align-self-center">
          <div class="contacts">
            <p class="d-flex align-self-center">
              <mat-icon aria-hidden="false" aria-label="phone">phone</mat-icon> {{'PHONE'|translate}}: {{profile.phoneNumber}}
            </p>
            <p class="d-flex align-self-center">
              <mat-icon aria-hidden="false" aria-label="Email">mail_outline</mat-icon> {{'EMAIL'|translate}}: {{profile.email}}
            </p>
            <p class="d-flex align-self-center">
              <mat-icon aria-hidden="false" aria-label="Location">my_location</mat-icon> {{'LOCATION'|translate}}: {{profile.location.city}} ({{profile.location.province}}), {{profile.location.region}} - {{profile.location.country}}
            </p>
          </div>
          <button *ngIf="maincomponent.loggedUser.role.profile.user.post.company || maincomponent.loggedUser._id == profile._id" class="togglebutton" mat-stroked-button (click)="showEditForm()" color="{{showEdit ? 'warn' : ''}}" mat-button>
            <span *ngIf="!showEdit">{{'EDIT_PROFILE'|translate}}</span>
            <span *ngIf="showEdit">{{'ABORT_EDITING'|translate}}</span>
          </button>
          <button *ngIf="showEdit && maincomponent.loggedUser._id!=profile._id && (maincomponent.loggedUser.role.superAdmin || maincomponent.loggedUser.role.adv)" mat-stroked-button (click)="toggleUSerEnable()" color="warn" mat-button>
            <span *ngIf="profile.enable">{{'DISABLE_PROFILE'|translate}}</span>
            <span *ngIf="!profile.enable">{{'ENABLE_PROFILE'|translate}}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="card marginbottom20" [hidden]="showEdit">
      <mat-tab-group dynamicHeight #tabGroup [selectedIndex]="currentTab" (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="px-3">
              <mat-icon>person</mat-icon> {{'USER_DETAILS'|translate}}
            </div>
          </ng-template>
          <div class="padding20">
            <div class="row">
              <div class="col-xl-3 align-self-center marginbottom20">
                <div class="usercompany">
                  <img [src]="profile.company.image"/>
                </div>
              </div>
              <div class="col-xl-3 align-self-center marginbottom20" *ngIf="maincomponent.loggedUser.role.superAdmin">
                <p>{{'ROLE_ASSIGNED'|translate}}: <i>{{profile.type}}</i></p>
                <!--<p>Gruppi assegnati: <i>Manager, IT, Agente</i></p>-->
                <p>
                  {{'ACTIVATION'|translate}}
                  <span *ngIf="profile.enable">
                    <mat-icon color="primary" aria-label="activation">fiber_manual_record</mat-icon>
                    {{'ACTIVE'|translate}}
                  </span>
                  <span *ngIf="!profile.enable">
                    <mat-icon color="warn" aria-label="activation">cancell</mat-icon>
                    {{'INACTIVE'|translate}}
                  </span>

                </p>

              </div>
              <div class="col-xl-6 align-self-center marginbottom20">
                <div class="row">
                  <div class="col-xl-6 mx-auto">
                    <div class="counteruser">
                      <h1><span class="count">{{profile.createdAt|date:'dd'}}</span>/<span class="count">{{profile.createdAt|date:'MM'}}</span>/<span class="count">{{profile.createdAt|date:'yyyy'}}</span></h1>
                      <h5>{{'REGISTRATION'|translate}}</h5>
                    </div>
                    <div class="counteruser">
                      <h1><span class="count">{{profile.updatedAt|date:'dd'}}</span>/<span class="count">{{profile.updatedAt|date:'MM'}}</span>/<span class="count">{{profile.updatedAt|date:'yyyy'}}</span></h1>
                      <h5>{{'LAST_UPDATE'|translate}}</h5>
                    </div>
                    <div class="counteruser" *ngIf="profile.lastLogin">
                      <h1><span class="count">{{profile.lastLogin|date:'dd'}}</span>/<span class="count">{{profile.lastLogin|date:'MM'}}</span>/<span class="count">{{profile.lastLogin|date:'yyyy'}}</span></h1>
                      <h5>{{'LAST_LOGIN'|translate}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <div class="px-3">
              <mat-icon>vpn_key</mat-icon> {{'SYSTEM_ROLES'|translate}}
            </div>
          </ng-template>
          <div class="padding20">

            <div class="text-center" *ngIf="loadingTab">
              <p>{{'LOADING'|translate}}</p>
              <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>

            <div *ngIf="currentTab==1 && !loadingTab">
              <app-permission [user]="profile._id" (update)="setUserRole($event)"></app-permission>
            </div>
          </div>
        </mat-tab>
        <ng-container *ngIf="this.profile.schema">
          <mat-tab *ngIf="this.profile.schema.customer">
            <ng-template mat-tab-label>
              <mat-icon>payment</mat-icon> {{'CREDIT_CARDS'|translate}}
            </ng-template>
            <div class="padding20">

              <div class="text-center" *ngIf="loadingTab">
                <p>{{'LOADING'|translate}}</p>
                <mat-progress-bar mode="buffer"></mat-progress-bar>
              </div>

              <div *ngIf="currentTab==2 && !loadingTab">
                <app-fops [user]="this.profile.schema.customer" [id]="profile._id" [reservation]="undefined"></app-fops>
              </div>
            </div>
          </mat-tab>
        </ng-container>


        <mat-tab *ngIf="maincomponent.loggedUser.role.flight.taw.get.company">
          <ng-template mat-tab-label>
            <div class="px-3">
              <mat-icon>airplanemode_active</mat-icon> {{'FLIGHTS'|translate}}
            </div>
          </ng-template>
          <div class="padding10 nooverflow">
            <div class="text-center" *ngIf="loadingTab">
              <p>{{'LOADING'|translate}}</p>
              <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>
            <div *ngIf="currentTab==2 && !loadingTab">
              <app-reservationlist [page]="'user'" [iduser]="profile._id"></app-reservationlist>
            </div>

          </div>
        </mat-tab>

        <!--
        <mat-tab *ngIf="maincomponent.loggedUser.role.hotel.taw.get.company">
          <ng-template mat-tab-label>
            <mat-icon>hotel</mat-icon> {{'HOTELS'|translate}}
          </ng-template>
          <div class="padding10 nooverflow">
            <div class="text-center" *ngIf="loadingTab">
              <p>{{'LOADING'|translate}}</p>
              <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>
            <div *ngIf="currentTab==3 && !loadingTab">
              <app-reservationlisthotel [page]="'user'" [iduser]="profile._id"></app-reservationlisthotel>
            </div>

          </div>
        </mat-tab>
        -->



      </mat-tab-group>
    </div>

    <div class="row" [hidden]="!showEdit">

      <div class="col-xl-6 marginbottom10">
        <form [formGroup]="UserForm" (ngSubmit)="editUser()" novalidate class="formfull">
          <div class="default_panel">
            <div class="default_heading">
              <span class="heading_panel">{{'PERSONAL_INFO'|translate}}</span>

              <mat-slide-toggle class="float-right smalltext marginright10 margintop5" [(ngModel)]="activeform" [ngModelOptions]="{standalone: true}" (click)="toggleForm()">
                {{'ENABLE_EDITING'|translate}}
              </mat-slide-toggle>
            </div>
            <div class="default_content">

              <div class="example-container">

                <div>
                  <mat-form-field>
                    <mat-label>{{'NAME'|translate}}</mat-label>
                    <input matInput placeholder="{{'NAME'|translate}}" formControlName="name" required>
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field>
                    <mat-label>{{'SURNAME'|translate}}</mat-label>
                    <input matInput placeholder="{{'SURNAME'|translate}}" formControlName="surname" required>
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field>
                    <mat-label>{{'PHONE'|translate}}</mat-label>
                    <input matInput placeholder="{{'PHONE'|translate}}" formControlName="phoneNumber" required>
                  </mat-form-field>
                </div>

                <div class="marginbottom10">
                  <label class="marginright10" id="example-radio-group-label">{{'SEX'|translate}}</label>
                  <mat-radio-group formControlName="sex" aria-labelledby="example-radio-group-label">
                    <mat-radio-button class="example-radio-button" value="M">
                      {{'MALE'|translate}}
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" value="F">
                      {{'FEMALE'|translate}}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>

                <div>
                  <mat-form-field class="example-full-width">
                    <input matInput [matDatepicker]="picker" formControlName="birthday" placeholder="{{'BIRTHDAY'|translate}}" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                  </mat-form-field>
                </div>



              </div>
              <mat-accordion style="position:relative">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{'ADDRESS'|translate}}
                    </mat-panel-title>

                  </mat-expansion-panel-header>
                  <div class="row">
                    <div class="col d-flex justify-content-end">
                      <mat-slide-toggle class="my-3" [ngModelOptions]="{standalone: true}" [disabled]="UserForm.disabled" [(ngModel)]="toggleCountry" (change)="changeCountryType()">{{ 'FOREIGN_COUNTRY' | translate  }}</mat-slide-toggle>
                    </div>
                  </div>
                  <div class="example-container" formGroupName="location">

                    <div *ngIf="!toggleCountry">
                      <mat-form-field>
                        <mat-label>{{'COUNTRY'|translate}}</mat-label>
                        <mat-select formControlName="country" required>
                          <mat-option *ngFor="let country of countries" [value]="country.value">
                            {{country.viewValue}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div *ngIf="!toggleCountry">
                      <mat-form-field>
                        <mat-label>{{'REGION'|translate}}</mat-label>
                        <mat-select (selectionChange)="setRegion()"  formControlName="region" required>
                          <mat-option *ngFor="let region of regions" [value]="region.value">
                            {{region.viewValue}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div *ngIf="!toggleCountry">
                      <mat-form-field>
                        <mat-label>{{'PROVINCE'|translate}}</mat-label>
                        <mat-select formControlName="province" required>
                          <mat-option *ngFor="let province of provinces" [value]="province.value">
                            {{province.viewValue}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div *ngIf="toggleCountry">
                      <mat-form-field class="full-width mb-1">
                        <mat-label>{{'FOREIGN_COUNTRY'|translate}}</mat-label>
                        <input matInput style="text-transform: uppercase" type="text" formControlName="country" required>
                      </mat-form-field>
                    </div>

                    <div *ngIf="toggleCountry">
                      <mat-form-field class="full-width mb-1">
                        <mat-label>{{'REGION'|translate}}</mat-label>
                        <input matInput type="text" formControlName="region" required>
                      </mat-form-field>
                    </div>

                    <div *ngIf="toggleCountry">
                      <mat-form-field class="full-width mb-1">
                        <mat-label>{{'PROVINCE'|translate}}</mat-label>
                        <input matInput formControlName="province" type="text" required>
                      </mat-form-field>
                    </div>

                    <mat-form-field>
                      <mat-label>{{'CITY'|translate}}</mat-label>
                      <input matInput placeholder="Città" formControlName="city" required>
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>{{'POSTAL_CODE'|translate}}</mat-label>
                      <input matInput placeholder="Codice postale" formControlName="postalCode" required>
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>{{'ADDRESS'|translate}}</mat-label>
                      <input matInput placeholder="Indirizzo" formControlName="address" required>
                    </mat-form-field>

                  </div>
                  <div class="d-flex justify-content-end" >
                    <button type="button" [disabled]="UserForm.disabled" (click)="resetData($event)" mat-stroked-button color="primary" class="marginbottom5">{{ 'RESET_ADDRESSES' | translate }}</button>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="default_footer">
              <div>
                <button type="submit" [disabled]="!UserForm.valid" mat-stroked-button color="primary" class="marginbottom5">{{'SAVE'|translate}}</button>
                <p class="error" *ngIf="!UserForm.valid && activeform">{{'COMPLETE_ALL_FIELDS'|translate}}</p>
              </div>
            </div>
          </div>

        </form>
      </div>

      <div class="col-xl-6">
        <div class="default_panel">
          <div class="default_heading">
            <span class="heading_panel">{{'LOGIN_CREDENTIALS'|translate}}</span>

            <mat-slide-toggle class="float-right smalltext marginright10 margintop5" [(ngModel)]="activecredential" [ngModelOptions]="{standalone: true}" (click)="toggleCredential()">
              {{'ENABLE_EDITING'|translate}}
            </mat-slide-toggle>
          </div>
          <div class="default_content">

            <div class="example-container">
              <form [formGroup]="UserCredential" (ngSubmit)="editPassword()" novalidate class="formfull">
                <div>
                  <mat-form-field>
                    <mat-label>{{'EMAIL'|translate}}</mat-label>
                    <input matInput placeholder="{{'EMAIL'|translate}}" formControlName="email" required>
                    <mat-error *ngIf="UserCredential.controls.email.invalid">{{getCredentialsErrorMessage()}}</mat-error>
                  </mat-form-field>
                </div>

                <mat-form-field>
                  <mat-label><mat-icon>lock</mat-icon> {{'NEW_PASSWORD'|translate}}</mat-label>
                  <input matInput minlength="4" formControlName="password" maxlength="15" #password required [type]="hidePassword ? 'password' : 'text'">
                  <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                    <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="UserCredential.hasError('required', 'password')">
                    {{'ENTER_NEW_PASSWORD'|translate}}
                  </mat-error>
                  <mat-error *ngIf="UserCredential.hasError('minlength', 'password')">
                    {{'ENTER_4_CHARSET'|translate}}
                  </mat-error>
                  <mat-hint align="end">{{UserCredential.value.password.length || 0}}/15 max</mat-hint>

                </mat-form-field>

                <mat-form-field class="marginbottom20">
                  <mat-label><mat-icon>lock</mat-icon> {{'CONFIRM_PASSWORD'|translate}}</mat-label>
                  <input [errorStateMatcher]="matcher" matInput minlength="4" formControlName="confirmPassword" maxlength="15" #password required [type]="hideConfirmpassword ? 'password' : 'text'">
                  <button type="button" mat-icon-button matSuffix (click)="hideConfirmpassword = !hideConfirmpassword" [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hideConfirmpassword">
                    <mat-icon>{{hideConfirmpassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="UserCredential.hasError('notSame')">
                    {{'PASSWORD_NOT_EQUAL'|translate}}
                  </mat-error>
                  <mat-hint align="end">{{UserCredential.value.confirmPassword.length || 0}}/15 max</mat-hint>

                </mat-form-field>

                <button type="submit" [disabled]="!UserCredential.valid" class="marginbottom5" mat-stroked-button color="primary">{{'SAVE'|translate}}</button>
                <p class="error" *ngIf="!UserCredential.valid && activecredential">{{'COMPLETE_ALL_FIELDS'|translate}}</p>

              </form>

            </div>

          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Carica immagine profilo</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="uploadImage text-center">
            <div>
              <div class="form-group">
                <input type="file" id="image" class="inputfile" name="image" (change)="fileProgress($event)" />
                <label for="image">
                  <mat-icon>cloud_upload</mat-icon>
                  Choose a file
                </label>
              </div>
              <div *ngIf="fileUploadProgress">
                Upload progress: {{ fileUploadProgress }}
              </div>
              <div class="image-preview" *ngIf="previewUrl">
                <img [src]="previewUrl"/>
              </div>

            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button mat-button mat-stroked-button (click)="onSubmit()" [disabled]="!fileData">Upload</button>
          <button mat-button mat-stroked-button data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

