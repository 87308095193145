import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-required-parameters',
  templateUrl: './required-parameters.component.html',
  styleUrls: ['./required-parameters.component.scss']
})
export class RequiredParametersComponent implements OnInit {

  @Input() passengers: any[];
  @Input() title: string[];
  @Input() requiredList: any[];
  @Input() cardsRequired:boolean;
  @Input() cardSelected: boolean;

  constructor() {
    console.log('req-param-> ' +this.passengers);
    console.log('req-requiredList-> ' +this.requiredList);
  }

  ngOnInit(): void {
    console.log('req-param-> ' +JSON.stringify(this.passengers));
    console.log('req-requiredList-> ' +JSON.stringify(this.requiredList));
    if(this.cardsRequired){
      this.requiredList.push({Name:'Credit card'})
    }
  }

  almostOne(requiredList){
    for(const r of requiredList){
      const res = this.dataFilled(r);
      if (!res) return false
    }
    return true;
  }

  dataFilled(requiredParam){
    const checker = [];
    if (requiredParam.Name){
      for (let i = 0; i < this.passengers.length; i ++){
        if (requiredParam.Name.toLowerCase() === 'dateofbirth'){
          checker.push(this.checkDateOfBird());
        } else if (requiredParam.Name.toLowerCase() === 'sex'){
          checker.push(this.checkSex());
        } else if (requiredParam.Name.toLowerCase() === 'creditcard' || requiredParam.Name.toLowerCase() === 'credit card'){
          return this.cardSelected;
        }
      }
      for (const c of checker){
        if (!c) return false;
      }
      return true;
    }
  }

  checkDateOfBird(){
    let counter = 0;
    for(const p of this.passengers){
      if(p.birthday && p.birthday !== ''){
        counter++;
      }
    }
    if(counter === this.passengers.length) return true;
    else return false;

  }


  checkSex(){
    let counter = 0;
    for(const p of this.passengers){
      if(p.sex && p.sex !== ''){
        counter++;
      }
    }
    if(counter === this.passengers.length) return true;
    else return false;
  }
}
