import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddComponent } from './components/travelers/add/add.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { TravelerslistComponent } from './components/travelers/travelerslist/travelerslist.component';
import { EdittravelerComponent } from './components/travelers/edittraveler/edittraveler.component';
import { CostcenterComponent } from './components/costcenter/costcenter.component';
import { FrequentflyerComponent } from './components/frequentflyer/frequentflyer.component';
import { TravellerLoyaltyCodesComponent } from './components/loyaltyCodes/traveller-loyalty-codes.component';
import { CustomCommonModule } from '../common/custom-common.module';

@NgModule({
  declarations: [
    AddComponent,
    TravelerslistComponent,
    EdittravelerComponent,
    CostcenterComponent,
    FrequentflyerComponent,
    TravellerLoyaltyCodesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    CustomCommonModule,
  ],
  exports: [
    AddComponent,
    TravelerslistComponent,
    EdittravelerComponent,
  ],
})
export class TravelersModule{}
