import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../../../components/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-roles-table',
  templateUrl: './groups-table.component.html',
  styleUrls: ['./groups-table.component.scss']
})
export class GroupsTableComponent implements OnInit {

  @Input() data;

  @Output() deleted = new EventEmitter<any>();

  displayedColumns = ['name', 'company', 'buttons'];

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  handleDelete(element): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this group?',
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleted.emit(element._id);
      }
    });
  }

}
