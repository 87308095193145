import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { CarService } from 'src/app/services/car/car.service';
import { CustomAuthService } from 'src/app/services/customAuth/custom-auth.service';
import { DossierService } from 'src/app/services/dossier/dossier.service';
import { FlightService } from 'src/app/services/flight/flight.service';
import { StatisticService } from 'src/app/services/statistic/statistic.service';
import { HotelService } from '../../hotel/hotel.service';
import { MainComponent } from '../../mainmodule/main/main.component';
import { TrainService } from '../../trains/train.service';
import { TkbottomsheetComponent } from '../../ticketing/components/tkbottomsheet/tkbottomsheet.component';
import { HelpdialogComponent } from '../dashboardcp/helpdialog/helpdialog.component';
import * as $ from 'jquery';
@Component({
  selector: 'app-dashboard-version2',
  templateUrl: './dashboard-version2.component.html',
  styleUrls: ['./dashboard-version2.component.scss']
})
export class DashboardVersion2Component implements OnInit {
  newDossier: string;
  activeFlights: number;
  percFlights: number;
  activeHotels: number;
  percHotels: number;
  activeCars: number;
  percCars: number;
  activeTrains: number;
  percTrains: number;
  activeDossier: number;
  totalCount: number;
  query: any;
  today = new Date();
  fromGeneral: Date = new Date(new Date().setDate(1));
  toGeneral: Date = new Date();
  generalStats: any;
  currentDate: Date = new Date();
  daysInMonth: number = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0).getDate();
  daysPassed: number = this.currentDate.getDate();
  percentCompleted: number = (this.daysPassed / this.daysInMonth) * 100;
  constructor(
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private dossierSerivice: DossierService,
    public maincomponent: MainComponent,
    public customAuth: CustomAuthService,
    private flightService: FlightService,
    private hotelService: HotelService,
    private carService: CarService,
    private trainService: TrainService,
    private statsService: StatisticService,
    public appComponent: AppComponent,
    public dialog: MatDialog
  ) {
    this.newDossier = '';
    this.query = {
      options: {
        tab: 'active',
        companies: [],
        users: [],
        carrier: '',
      },
      sort: 'createdAt',
      limit: 10,
      skip: 0,
      order: 'desc',
      text: '',
    };
   }

   ngOnInit() {
    /*
    if (this.maincomponent.loggedUser.role.company === false && (this.appComponent.isB2B || this.appComponent.isSbt)) {
      this.router.navigate(['/dossier/list']);
    }
    */
    if (!this.customAuth.returnAuthTf(this.maincomponent.loggedUser._id)) {
      this.router.navigate(['/flight/search']);
    }
    if (this.maincomponent.loggedUser.company.services.flight) {
      this.getActiveFlights();
    }
    if (this.maincomponent.loggedUser.company.services.hotel && !this.maincomponent.appcomponent.isGat) {
      this.getActiveHotels();
    }
    if (this.maincomponent.loggedUser.company.services.car && !this.maincomponent.appcomponent.isGat) {
      this.getActiveCars();
    }
    if (this.maincomponent.loggedUser.company.services.train && !this.maincomponent.appcomponent.isGat) {
      this.getactiveTrains();
    }
    this.countTotal();
    this.getGeneralStats();
  }

  getGeneralStats(): void {
    const options = {
      from: this.fromGeneral.toISOString().substr(0,10),
      to: this.toGeneral.toISOString().substr(0,10),
      company: null,
    };
    this.statsService.getGeneral(JSON.stringify(options)).subscribe((res) => {
      this.generalStats = {};
      res.data.forEach(a => {
        this.generalStats[a._id] = {...a};
      });
    }, error => {
      console.error('Error => ', error);
    });
  }

  countTotal() {
    setTimeout(() => {
      this.totalCount = this.activeFlights + this.activeHotels + this.activeCars + this.activeTrains;
      this.percFlights = ((100 * this.activeFlights) / this.totalCount);
      this.percHotels = ((100 * this.activeHotels) / this.totalCount);
      this.percTrains = ((100 * this.activeTrains) / this.totalCount);
      this.percCars = ((100 * this.activeCars) / this.totalCount);
      console.log('this.totalCount => ', this.totalCount);
    }, 2500);
  }

  reverse() {
    $('.flip').toggleClass('reverse');
  }

  openBottomSheet() {
    this.bottomSheet.open(TkbottomsheetComponent, {
      data: {
        logged: this.maincomponent.loggedUser,
        isGat: this.appComponent.isGat,
        isSbt: this.appComponent.isSbt,
        isB2B: this.appComponent.isB2B,
      },
      // disableClose: true,
    });
  }

  getActiveFlights(): void {
    this.flightService.getReservationsList(this.query).subscribe((res) => {
      setTimeout(() => {
        this.activeFlights = res.count;
      }, 500);
    }, error => {
      console.error('Error => ', error);
    });
  }

  getActiveHotels(): void {
    this.hotelService.getReservationsList(this.query).subscribe((res) => {
      setTimeout(() => {
        this.activeHotels = res.count;
      }, 500);
    }, error => {
      console.error('Error => ', error);
    });
  }

  getActiveCars(): void {
    this.carService.getReservationsList(this.query).subscribe((res) => {
      setTimeout(() => {
        this.activeCars = res.count;
      }, 500);
    }, error => {
      console.error('Error => ', error);
    });
  }

  getactiveTrains(): void {
    this.trainService.getReservationList(this.query).subscribe((res) => {
      setTimeout(() => {
        for (let i = 0; i < res.tabs.length; i++) {
          if (res.tabs[i]._id === 'COMPLETED') {
            this.activeTrains = res.tabs[i].total;
          }
        }
      }, 500);
    }, error => {
      console.error('Error => ', error);
    });
  }

  openHelpDialog() {
    this.dialog.open(HelpdialogComponent, {
      data: {
        logged: this.maincomponent.loggedUser,
        isGat: this.appComponent.isGat,
        isSbt: this.appComponent.isSbt,
        isB2B: this.appComponent.isB2B,
        mainComponent: this.maincomponent,
      },
      // disableClose: true,
    });
    /*
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
    */
  }

  getYearStats(){
    const body = {
      service: 'flight',
    };
    this.statsService.getYear(body).subscribe((res) => {
      const current = JSON.parse(JSON.stringify(res.data));
      const all = [...res.data];
      console.log('res =>', res);
      // this.currentYears = res;
    }, error => {
      console.error('Error => ', error);
    });
  }

}
