<app-notificationpagetitle [title]="title"></app-notificationpagetitle>
<div class="padding20">

 <!--  <div class="row">
    <div class="col-lg-6 marginbottom20">
      <div class="default_panel">
        <div class="default_heading d-flex justify-content-between">
          <div class="d-flex align-self-center"><span class="tabtitle">{{'COMMUNICATIONS'|translate}}</span></div>
          <div class="d-flex align-items-center">
            <mat-icon *ngIf="maincomponent.unreadManual==0">chat_bubble_outline</mat-icon>
            <mat-icon *ngIf="maincomponent.unreadManual>0" matBadge="{{maincomponent.unreadManual}}" matBadgeColor="accent" >
              textsms
            </mat-icon>
          </div>
        </div>
        <div class="default_content smfont scrolling scrollingbig">
          <app-auto type="widget" notificationType="manual" read="all"></app-auto>
        </div>
      </div>
    </div>
    <div class="col-lg-6 marginbottom20">
      <div class="default_panel">
        <div class="default_heading d-flex justify-content-between">
          <div class="d-flex align-self-center"><span class="tabtitle">{{'NOTIFICATIONS'|translate}}</span></div>
          <div class="d-flex align-items-center">
            <mat-icon *ngIf="maincomponent.unreadAuto==0">notifications_none</mat-icon>
            <mat-icon *ngIf="maincomponent.unreadAuto>0" matBadge="{{maincomponent.unreadAuto}}" matBadgeColor="warn">notification_important</mat-icon>
          </div>
        </div>
        <div class="default_content smfont scrolling scrollingbig">
          <app-auto type="widget" notificationType="auto" read="all"></app-auto>
        </div>
      </div>
    </div>
  </div> -->

  <!--
  <div class="default_panel">

    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>

          <span class="tabtitle">{{'COMMUNICATIONS'|translate}}</span>
          <mat-icon *ngIf="maincomponent.unreadManual==0">chat_bubble_outline</mat-icon>
          <mat-icon *ngIf="maincomponent.unreadManual>0" matBadge="{{maincomponent.unreadManual}}" matBadgeColor="accent" >
            textsms
          </mat-icon>
        </ng-template>
        <div class="padding20">
          {{'COMMUNICATIONS'|translate}}
          <hr/>
          <app-auto type="widget" notificationType="manual" read="all"></app-auto>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="tabtitle">{{'NOTIFICATIONS'|translate}}</span>
          <mat-icon *ngIf="maincomponent.unreadAuto==0">notifications_none</mat-icon>
          <mat-icon *ngIf="maincomponent.unreadAuto>0" matBadge="{{maincomponent.unreadAuto}}" matBadgeColor="warn">notification_important</mat-icon>
        </ng-template>
        <div class="padding20">
          {{'NOTIFICATIONS'|translate}}
          <hr/>
          <app-auto type="widget" notificationType="auto" read="all"></app-auto>
        </div>
      </mat-tab>
    </mat-tab-group>

  </div>
  -->

  <div class="row">
    <div class="col-lg-12">
      <div class="default_panel">
        <div class="default_heading d-flex justify-content-between">
          <div class="d-flex align-self-center"><span class="tabtitle">{{'SEND_NOTIFICATIONS'|translate}}</span></div>
        </div>
        <div class="default_content smfont">
          <app-send></app-send>
        </div>
      </div>

    </div>
  </div>
</div>


