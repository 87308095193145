<custom-title></custom-title>
<div class="padding20">
  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <div class="width500" *ngIf="dossier && emptyDossier()">

    <div class="card padding10 text-center">
      <p>{{'DOSSIER_IS_EMPTY'|translate}}</p>
      <hr/>
      <p>{{'ADD_SERVICES'|translate}}</p>
      <div class="pnrPagination">
        <div class="singleVoice" (click)="navigateTo('flight')"> <!-- [matMenuTriggerFor]="flightMenu" -->
          <mat-icon>flight</mat-icon>
          <span class="tabname"> {{'FLIGHT'|translate}}</span>
        </div>
        <mat-menu #flightMenu="matMenu">
          <button [hidden]="mainComponent.loggedUser.role.company === false && !customAuth.canBook(mainComponent.loggedUser.company._id)" mat-menu-item (click)="toggleAddtoTravel('searchFl')">{{'ADD_FLIGHT'|translate}}</button>
          <button mat-menu-item (click)="navigateTo('flight')">{{'SEARCH_FLIGHT'|translate}}</button>
        </mat-menu>
        <div class="singleVoice" (click)="navigateTo('hotel')"> <!-- [matMenuTriggerFor]="hotelMenu" -->
          <mat-icon>hotel</mat-icon>
          <span class="tabname"> {{'HOTEL'|translate}}</span>
        </div>
        <mat-menu #hotelMenu="matMenu">
          <button [hidden]="mainComponent.loggedUser.role.company === false && !customAuth.canBook(mainComponent.loggedUser.company._id)" mat-menu-item (click)="toggleAddtoTravel('searchHo')">{{'ADD_HOTEL'|translate}}</button>
          <button mat-menu-item (click)="navigateTo('hotel')">{{'HOTEL_SEARCH'|translate}}</button>
        </mat-menu>
        <div *ngIf="appComponent.isB2B || appComponent.isSbt" class="singleVoice" (click)="navigateTo('car-rent')"> <!-- [matMenuTriggerFor]="flightMenu" -->
          <mat-icon>directions_car</mat-icon>
          <span class="tabname"> {{'CAR'|translate}}</span>
        </div>
        <mat-menu #CarMenu="matMenu">
          <button [hidden]="mainComponent.loggedUser.role.company === false && !customAuth.canBook(mainComponent.loggedUser.company._id)" mat-menu-item (click)="toggleAddtoTravel('searchCar')">{{'ADD_CAR'|translate}}</button>
          <button mat-menu-item (click)="navigateTo('car-rent')">{{'SEARCH_CAR'|translate}}</button>
        </mat-menu>
        <div *ngIf="appComponent.isB2B || appComponent.isSbt || mainComponent.loggedUser.role.superAdmin" class="singleVoice" (click)="navigateTo('trains')"> <!-- [matMenuTriggerFor]="flightMenu" -->
          <mat-icon>train</mat-icon>
          <span class="tabname"> {{'TRAIN'|translate}}</span>
        </div>
        <mat-menu #TrainMenu="matMenu">
          <button [hidden]="mainComponent.loggedUser.role.company === false && !customAuth.canBook(mainComponent.loggedUser.company._id)" mat-menu-item
                  (click)="toggleAddtoTravel('searchTrain')">{{'ADD_TRAIN'|translate}}</button>
          <button mat-menu-item (click)="navigateTo('trains')">{{'TRAIN'|translate}}</button>
        </mat-menu>
        <!--
        <div class="singleVoice">
          <mat-icon>train</mat-icon>
          <span class="tabname"> {{'TRAIN'|translate}}</span>
        </div>
        <div class="singleVoice">
          <mat-icon>directions_car</mat-icon>
          <span class="tabname"> {{'CAR'|translate}}</span>
        </div>
        -->
      </div>
    </div>

  </div>

  <div *ngIf="dossier && !emptyDossier()">

    <div class="cardPnr mb-3">
      <div class="padding20">
        <div class="row">
          <div class="col-md-4 align-self-center">
            <div class="reservationimage">
              <img class="mb-2" style="margin:0" src="/assets/img/folder-invoices.png">
              <div style="text-transform:uppercase;">
                {{dossier.name}}
              </div>
            </div>
            <div class="reservationPrice marginbottom5">
              <span class="recoprice">
                {{'TOTAL_PRICE'|translate}} {{totalPrice|number:'.2-2'}}€
              </span>
            </div>
          </div>
          <div class="col-md-4 align-self-center mb-2">
            <div class="pnrstatus">
              Locator: {{dossier.locator}}
            </div>
            <div class="pt-3">
              <span [matBadge]="dossier.flights.length" *ngIf="dossier.flights.length > 0" class="material-icons mr-3">
                flight
              </span>
              <span [matBadge]="dossier.hotels.length" *ngIf="dossier.hotels.length > 0" class="material-icons mr-3">
                hotel
              </span>
              <span [matBadge]="dossier.cars.length" *ngIf="dossier.cars.length > 0" class="material-icons">
                directions_car
              </span>
              <span [matBadge]="dossier.trains.length" *ngIf="dossier.trains.length > 0" class="material-icons">
                train
              </span>
            </div>
          </div>
          <div class="col-md-4 align-self-center companydetails">
            <div><a routerLink="/companies/list/{{dossier.company._id}}">{{dossier.company.name}}</a></div>
            <div>
              <mat-icon>subdirectory_arrow_right</mat-icon>
              <a routerLink="/users/list/{{dossier.user._id}}">{{dossier.user.surname}} {{dossier.user.name}}</a>
            </div>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-md-8 align-self-center mb-2">
            <div class="travelinfo">
              <div class="mb-1" *ngIf="dossier.flights.length > 0">
                <div *ngFor="let flight of dossier.flights" [ngClass]="{'del': flight.doc.status === 'cancelled'}">
                  <span claSS="mr-2" *ngFor="let itinerary of flight.doc.itineraries">
                    <span class="dossierCarrier">
                      <img class="" src="/assets/img/airline/icon/{{flight.doc.marketingCarrier}}.png">
                    </span>
                    <span class="dossierIata">{{itinerary.flights[0].departure.location}}</span>
                    <mat-icon>arrow_right_alt</mat-icon>
                    <span class="dossierIata">{{itinerary.flights[itinerary.flights.length-1].arrival.location}}</span>
                  </span>
                  <span *ngIf="!mainComponent.appcomponent.isB2B">
                    <span *ngIf="flight.doc.negotiated && !priceService.isNegoHidden()">{{flight.doc.fare.amount + flight.doc.fare.fees|number:'.2-2'}}€</span>
                    <span *ngIf="flight.doc.negotiated && priceService.isNegoHidden()">{{flight.doc.fare.publicAmount|number:'.2-2'}}€</span>
                    <span *ngIf="!flight.doc.negotiated">
                      {{flight.doc.fare.amount|number:'.2-2'}}
                      <span *ngIf="flight.doc.fare.currency">{{flight.doc.fare.currency}}</span>
                      <span *ngIf="!flight.doc.fare.currency">€</span>
                    </span>
                    <span *ngIf="flight.doc.fare.services != 0"> | {{'SERVICES'|translate}} {{flight.doc.fare.services|number:'.2-2'}}
                      <span *ngIf="flight.doc.fare.currency">{{flight.doc.fare.currency}}</span>
                      <span *ngIf="!flight.doc.fare.currency">€</span>
                    </span>
                    <span *ngIf="flight.doc.negotiated" matTooltip="{{'NEGOTIATED_FARE'|translate}}" class="ml-1 material-icons">
                      star_border
                    </span>
                  </span>
                  <span *ngIf="mainComponent.appcomponent.isB2B">
                    <span *ngIf="!flight.doc.negotiated">{{flight.doc.fare.amount|number:'.2-2'}}€</span>
                    <span *ngIf="flight.doc.negotiated">{{flight.doc.fare.publicAmount - flight.doc.fare.fees|number:'.2-2'}}€</span>
                    <span *ngIf="flight.doc.fare.services != 0"> | {{'SERVICES'|translate}} {{flight.doc.fare.services|number:'.2-2'}}€</span>
                    <span *ngIf="flight.doc.negotiated" matTooltip="{{'DISCOUNT_FARE'|translate}}" class="ml-1 material-icons">
                      star_border
                    </span>
                  </span>

                  <span class="summaryStatus"> {{flight.doc.status|translate}}</span>

                  <span class="ml-1 material-icons pointer" (click)="removeItem('flight', flight.doc._id)">
                    delete_forever
                  </span>
                </div>
              </div>

              <div class="mb-1" *ngIf="dossier.hotels.length > 0">
                <div *ngFor="let hotel of dossier.hotels">

                  <span claSS="mr-2">
                    <span class="dossierCarrier">
                      <img class="" src="/assets/img/hotel-icon.png">
                    </span>
                    <span class="dossierIata">{{hotel.doc.hotel.name}}</span>
                    <mat-icon>arrow_right_alt</mat-icon>
                    <span class="dossierIata">{{hotel.doc.hotel.city}}</span>
                  </span>
                  <span>{{hotel.doc.room.roomRates[0].amount|number:'.2-2'}}€</span>
                  <span class="summaryStatus"> {{hotel.doc.status|translate}}</span>
                  <span class="ml-1 material-icons pointer" (click)="removeItem('hotel', hotel.doc._id)">
                    delete_forever
                  </span>
                </div>
              </div>

              <div class="mb-1" *ngIf="dossier.cars.length > 0">
                <div *ngFor="let car of dossier.cars">
                    <span claSS="mr-2">
                      <span class="dossierCarrier">
                        <img class="" src="/assets/img/cars/Car-Icon.png">
                      </span>
                      <span class="dossierIata">{{car.doc.car.model}}</span>
                      <mat-icon>arrow_right_alt</mat-icon>
                      <span class="dossierIata">{{car.doc.car.pickUpInfo.location}} -> {{car.doc.car.dropOffInfo.location}}</span>
                    </span>
                    <span>{{returnCarAmount(car.doc.car.rates)}} €</span>
                  <span class="summaryStatus"> {{car.doc.status|translate}}</span>
                    <span class="ml-1 material-icons pointer" (click)="removeItem('car', car.doc._id)">
                      delete_forever
                    </span>
                </div>
              </div>

            </div>
          </div>

          <div class="col-md-4 align-self-center buttonrow">

            <button mat-stroked-button [matMenuTriggerFor]="flightMenu">
              {{'ADD_FLIGHT'|translate}}
            </button>
            <mat-menu #flightMenu="matMenu">
              <button [hidden]="mainComponent.loggedUser.role.company === false && !customAuth.canBook(mainComponent.loggedUser.company._id)" mat-menu-item (click)="toggleAddtoTravel('searchFl')">{{'ADD_FLIGHT'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('flight')">{{'SEARCH_FLIGHT'|translate}}</button>
            </mat-menu>
            <button mat-stroked-button [matMenuTriggerFor]="hotelMenu">
              {{'ADD_HOTEL'|translate}}
            </button>
            <mat-menu #hotelMenu="matMenu">
              <button [hidden]="mainComponent.loggedUser.role.company === false && !customAuth.canBook(mainComponent.loggedUser.company._id)" mat-menu-item (click)="toggleAddtoTravel('searchHo')">{{'ADD_HOTEL'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('hotel')">{{'HOTEL_SEARCH'|translate}}</button>
            </mat-menu>

            <button mat-stroked-button *ngIf="appComponent.isB2B || appComponent.isSbt" [matMenuTriggerFor]="carMenu">
              {{'ADD_CAR'|translate}}
            </button>
            <mat-menu #carMenu="matMenu">
              <button [hidden]="mainComponent.loggedUser.role.company === false && !customAuth.canBook(mainComponent.loggedUser.company._id)" mat-menu-item (click)="toggleAddtoTravel('searchCar')">{{'ADD_CAR'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('car-rent')">{{'CAR_RENT_SEARCH'|translate}}</button>
            </mat-menu>

            <button mat-stroked-button *ngIf="appComponent.isB2B || appComponent.isSbt" [matMenuTriggerFor]="trainMenu">
              {{'ADD_TRAIN'|translate}}
            </button>
            <mat-menu #trainMenu="matMenu">
              <button [hidden]="mainComponent.loggedUser.role.company === false && !customAuth.canBook(mainComponent.loggedUser.company._id)" mat-menu-item (click)="toggleAddtoTravel('searchTrain')">{{'ADD_TRAIN'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('trains')">{{'TRAIN_SEARCH'|translate}}</button>
            </mat-menu>

            <span *ngIf="transferAuth && transferAuth.arrayOfAlerts && transferAuth.authorisations.length === 0">
              <span class="text-right" *ngIf="transferAuth.arrayOfAlerts.length > 0 && canAskAuth()">
                <button mat-raised-button color="primary" (click)="askAuthorisation()">Richiedi autorizzazione</button>
              </span>
            </span>
            <div *ngIf="mainComponent.loggedUser.role.superAdmin && dossier.sarce">
              <button mat-stroked-button click="updateDossier()">Update dossier</button>
            </div>
          </div>
        </div>

        <div class="text-right pt-3" *ngIf="!checkPolicy()">
          <div class="smalltext">
            <div class="mb-1">
              Stato approvazione trasferta:
              <span class="text-warning" *ngIf="transferAuth.status === 'pending'">{{transferAuth.status}}</span>
              <span class="text-success" *ngIf="transferAuth.status === 'approved'">{{transferAuth.status}}</span>
              <span class="text-danger" *ngIf="transferAuth.status === 'declined'">{{transferAuth.status}}</span>
            </div>

            <div *ngIf="transferAuth.status === 'declined'">
              <div *ngFor="let auth of transferAuth.userAuthorisations">
                <ng-container *ngIf="auth.status === 'declined'">
                  <div>Ragione: <span class="text-danger">{{auth.note}}</span></div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

    <div class="pnrPagination" *ngIf="!loading">
      <div (click)="setTab(i)" class="singleVoice" [ngClass]="{'active':tab.active}" *ngFor="let tab of tabs; let i = index">
        <mat-icon *ngIf="tab.name==='ITINERARY'">explore</mat-icon>
        <mat-icon *ngIf="tab.name==='PASSENGERS'">directions_walk</mat-icon>
        <mat-icon *ngIf="tab.name==='MAP'">map</mat-icon>
        <span class="tabname"> {{tab.name|translate}}</span>
      </div>
    </div>

    <div class="" *ngIf="currentTab=='ITINERARY'">
      {{'ITINERARY'|translate}}
      <hr/>

      <mat-card appearance="outlined" class="example-card mb-3"  *ngFor="let item of orderedItems" [ngSwitch]="item.type">
        <div class="text-right" *ngIf="transferAuth && transferAuth.searches && item.authorisations">
          <p *ngSwitchCase="'flight'">{{'TOTAL'|translate}} {{item.fare.amount + item.fare.services + item.fare.fees|number:'.2-2'}}€</p>
          <p *ngSwitchCase="'hotel'">{{'TOTAL'|translate}} {{item.room.amount|number:'.2-2'}}€</p>
          <div *ngFor="let auth of item.authorisations" class="small">
            <p>{{auth.note}} <span class="material-icons">arrow_right_alt</span>
              <span [ngClass]="{'text-warning': auth.status === 'pending', 'text-success': auth.status === 'approved'}">Status: {{auth.status}}</span>
            </p>
          </div>
        </div>
        <div class="text-right text-success" *ngIf="transferAuth && transferAuth.searches && !item.authorisations">
          <div class="small">In policy</div>
        </div>
        <mat-card-header *ngSwitchCase="'flight'">
          <div mat-card-avatar class="example-header-image" style="background-image: url('/assets/img/airline/icon/{{item.marketingCarrier}}.png')"></div>
          <mat-card-title>
            <span *ngIf="item.locator && !item.lowCost">{{item.locator}}</span>
            <span *ngIf="item.carriersLocators && item.carriersLocators.length > 0 && item.lowCost && item.status != 'init'">
              <span>{{item.carriersLocators[0].locator}}</span>
            </span>
          </mat-card-title>
          <mat-card-subtitle>
            <span>{{item.status|translate}}</span> - {{iataComplete.returnCarrier(item.marketingCarrier)}}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-header *ngSwitchCase="'hotel'">
          <div mat-card-avatar class="example-header-image" style="background-image: url('/assets/img/hotel-icon.png')"></div>
          <mat-card-title>
            <span *ngIf="item.locator">{{item.locator}}</span>
          </mat-card-title>
          <mat-card-subtitle>
            <span>{{item.status|translate}}</span> -
            <span>{{item.hotel.address.city}}, {{item.hotel.address.line}}</span>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-header *ngSwitchCase="'car'">
          <div mat-card-avatar class="example-header-image" style="background-image: url('{{item.rentCompany.images[0]}}')"></div>
          <mat-card-title>
            <span *ngIf="item.locator">{{item.locator}}</span>
          </mat-card-title>
          <mat-card-subtitle>
            <span>{{item.status|translate}}</span> - <span>{{item.car.model}}</span>

          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-header *ngSwitchCase="'train'">
          <div mat-card-avatar class="example-header-image" style="background-image: url('{{getTrainImage(item.carrier)}}')"></div>
          <mat-card-title>
            <span *ngIf="item.pnr">{{item.pnr}}</span>
          </mat-card-title>
          <mat-card-subtitle>
            <span>{{item.status|translate}}</span> - <span>{{item.carrier}}</span>

          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="row" *ngSwitchCase="'flight'">
            <div class="col-3">
              {{item.flights[0].departure.dateTime|date:'dd/MM/yyyy'}} <br/>
              {{item.flights[0].departure.hours}}:{{item.flights[0].departure.minutes}}<br/>
              <span class="smalltext" *ngIf="item.timeLimit">Time limit: {{item.timeLimit| date:'dd/MM/yyyy - HH:mm'}}</span>
              <br/>
              <span class="locationspan" [matTooltip]="item.flights[0].departure.location">
                {{iataComplete.returnAirport(item.flights[0].departure.location)}} <sup>{{item.flights[0].departure.location}}</sup>
              </span>
            </div>
            <div class="col-2 align-self-center">
              <span class="material-icons">arrow_right_alt</span>
            </div>
            <div class="col-3">
              {{item.flights[item.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy'}} <br/>
              {{item.flights[item.flights.length-1].arrival.hours}}:{{item.flights[item.flights.length-1].arrival.minutes}}
              <br/>
              <span class="locationspan" [matTooltip]="item.flights[item.flights.length-1].arrival.location">
                {{iataComplete.returnAirport(item.flights[item.flights.length-1].arrival.location)}} <sup>{{item.flights[item.flights.length-1].arrival.location}}</sup>
              </span>
            </div>
            <div class="col-2 ">
              <div *ngIf="item.passengers.length>0">
                <div *ngFor="let passenger of item.passengers" class="">
                  <span *ngIf="passenger.surname">{{passenger.surname}}</span> <span *ngIf="passenger.name"> {{passenger.name}}</span> <sup> ({{passenger.type}})</sup>
                </div>
              </div>
            </div>
            <div class="col-2 buttonrowBis">
              <a mat-button routerLink="/flight/reservations/{{item.link}}">{{'OPEN_RESERVATION'|translate}} <span class="material-icons">arrow_right_alt</span></a>
            </div>
          </div>

          <div class="row" *ngSwitchCase="'hotel'">
            <div class="col-12 mb-2">
              <div class="roomName">{{item.room.roomRates[0].text}}</div>
            </div>
            <div class="col-3">

              Check-in: {{item.hotel.checkIn|date:'dd/MM/yyyy'}}

            </div>
            <div class="col-2">
              <span class="material-icons">arrow_right_alt</span>
            </div>
            <div class="col-3">

              Check-out: {{item.hotel.checkOut|date:'dd/MM/yyyy'}}

            </div>
            <div class="col-2">
              <div *ngIf="item.guests.length>0">
                <div *ngFor="let passenger of item.guests" class="">
                  <span *ngIf="passenger.surname">{{passenger.surname}}</span> <span *ngIf="passenger.name"> {{passenger.name}}</span> <sup> ({{passenger.type}})</sup>
                </div>
              </div>
            </div>
            <div class="col-2 buttonrowBis">
              <a mat-button routerLink="/hotel/reservations/{{item.link}}">{{'OPEN_RESERVATION'|translate}} <span class="material-icons">arrow_right_alt</span></a>
            </div>
          </div>

          <div class="row" *ngSwitchCase="'car'">
            <div class="col-3">
              {{returnDate(item.car.pickUpInfo.date)|date:'dd/MM/yyyy - HH:mm':"+0000"}}
              <br/>
              {{item.car.pickUp}}
            </div>
            <div class="col-2">
              <span class="material-icons">arrow_right_alt</span>
            </div>
            <div class="col-3">
              {{returnDate(item.car.dropOffInfo.date)|date:'dd/MM/yyyy - HH:mm':"+0000"}}
              <br/>
              {{item.car.dropOff}}
            </div>
            <div class="col-2">
              <div *ngIf="item.passengers.length>0">
                <div *ngFor="let guest of item.passengers" class="">
                  <span *ngIf="guest.surname">{{guest.surname}}</span> <span *ngIf="guest.name"> {{guest.name}}</span> <sup> ({{guest.type}})</sup>
                </div>
              </div>
            </div>
            <div class="col-2 buttonrowBis">
              <a mat-button routerLink="/car-rent/reservations/{{item.link}}">{{'OPEN_RESERVATION'|translate}} <span class="material-icons">arrow_right_alt</span></a>
            </div>
          </div>

          <div class="row" *ngSwitchCase="'train'">
            <div class="col-3">
              {{returnDate(item.departure.date)|date:'dd/MM/yyyy - HH:mm':"+0000"}}
              <br/>
              {{item.departure.station}}
            </div>
            <div class="col-2">
              <span class="material-icons">arrow_right_alt</span>
            </div>
            <div class="col-3">
              {{returnDate(item.arrival.date)|date:'dd/MM/yyyy - HH:mm':"+0000"}}
              <br/>
              {{item.arrival.station}}
            </div>
            <div class="col-2">
              <div *ngIf="item.travellers.length>0">
                <div *ngFor="let guest of item.travellers" class="">
                  <span *ngIf="guest.doc?.surname">{{guest.doc?.surname}}</span> <span *ngIf="guest.doc?.name"> {{guest.doc?.name}}</span> <sup> ({{guest.type}})</sup>
                </div>
              </div>
            </div>
            <div class="col-2 buttonrowBis">
              <a mat-button routerLink="/trains/reservations/{{item.pnr}}">{{'OPEN_RESERVATION'|translate}} <span class="material-icons">arrow_right_alt</span></a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <!--
      <div class="singleItem" *ngFor="let item of orderedItems" [ngSwitch]="item.type">

        <div class="row" *ngSwitchCase="'flight'">
          <div class="col-9 align-self-center">
            <div class="row">
              <div class="col-lg-12 col-md-12 marginbottom5 align-self-center">
                <div class="pnrliststatus marginbottom5">
                  <span class="init" *ngIf="item.status==='init'">{{'TEMPLATE'|translate}}</span>
                  <span class="linked" *ngIf="item.status==='linked'">{{'LINKED'|translate}}</span>
                  <span class="issued" *ngIf="item.status==='issued'">{{'ISSUED'|translate}}</span>
                  <span class="flown" *ngIf="item.status==='flown'">{{'FLOWN'|translate}}</span>
                  <span class="expired" *ngIf="item.status==='expired'">{{'EXPIRED'|translate}}</span>
                  <span class="cancelled" *ngIf="item.status==='cancelled'">{{'CANCELLED'|translate}}</span>

                  <div *ngIf="item.locator && !item.lowCost" class="listlocator" [ngClass]="{'marginbottom5':item.status==='linked'}">
                    <span>{{item.locator}}</span>
                  </div>
                  <div *ngIf="item.carriersLocators && item.lowCost" class="pnrlocator" [ngClass]="{'marginbottom5':item.status==='linked'}">
                    <span>{{item.carriersLocators[0].locator}}</span>
                  </div>
                </div>

              </div>

              <div class="col-lg-9 col-md-12 marginbottom5 align-self-center">
                <hr class="d-sm-block d-md-none d-lg-none"/>
                <div>
                  <div class="pnrdeparture">
                    <div class="row">
                      <div class="col-5 align-self-center">
                        {{item.flights[0].departure.dateTime|date:'dd/MM/yyyy'}} <br/>
                        {{item.flights[0].departure.hours}}:{{item.flights[0].departure.minutes}}
                        <br/>
                        <span class="locationspan" [matTooltip]="item.flights[0].departure.location">
                          {{item.flights[0].departure.location}} <sup>{{item.flights[0].departure.location}}</sup>
                        </span>
                      </div>
                      <div class="col-2 align-self-center">
                        <mat-icon>flight</mat-icon>
                      </div>
                      <div class="col-5 align-self-center">
                        {{item.flights[item.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy'}} <br/>
                        {{item.flights[item.flights.length-1].arrival.hours}}:{{item.flights[item.flights.length-1].arrival.minutes}}
                        <br/>
                        <span class="locationspan" [matTooltip]="item.flights[item.flights.length-1].arrival.location">
                          {{item.flights[item.flights.length-1].arrival.location}} <sup>{{item.flights[item.flights.length-1].arrival.location}}</sup>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-12 marginbottom5 align-self-center">
                <hr class="d-md-block d-lg-none"/>
                <div class="dateaction">
                  {{'BOOKED_ON'|translate}} {{item.createdAt|date:'dd/MM/yyyy - HH:mm'}}
                </div>
                <div *ngIf="item.passengers.length>0">
                  <div *ngFor="let passenger of item.passengers" class="listpnrsinglepasseng">
                    <span *ngIf="passenger.surname">{{passenger.surname}}</span> <span *ngIf="passenger.name"> {{passenger.name}}</span> <sup> ({{passenger.type}})</sup>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-3 align-self-center text-center">

            <div class="reservationimage">
              <img class="" src="/assets/img/airline/icon/{{item.marketingCarrier}}.png">
              <span style="text-transform:uppercase;">{{item.marketingCarrier}}</span>
            </div>

            <div class="linktopnr">
              <a routerLink="/flight/reservations/{{item.link}}">{{'OPEN_RESERVATION'|translate}}</a>
            </div>
          </div>

        </div>

        <div class="" *ngSwitchCase="'hotel'">
          <div class="row">
            <div class="col-9 align-self-center">

              <div class="row">
                <div class="col-lg-12 col-md-12 marginbottom5 align-self-center">
                  <div class="pnrliststatus marginbottom5">
                    <span class="init" *ngIf="item.status==='init' && !item.waitingList">{{'TEMPLATE'|translate}}</span>
                    <span class="init" *ngIf="item.status==='init' && item.waitingList">{{'WAITING_CONFIRM'|translate}}</span>
                    <span class="linked" *ngIf="item.status==='linked'">{{'LINKED'|translate}}</span>
                    <span class="issued" *ngIf="item.status==='issued'">{{'ISSUED'|translate}}</span>
                    <span class="flown" *ngIf="item.status==='flown'">{{'FLOWN'|translate}}</span>
                    <span class="expired" *ngIf="item.status==='expired'">{{'EXPIRED'|translate}}</span>
                    <span class="cancelled" *ngIf="item.status==='cancelled'">{{'CANCELLED'|translate}}</span>
                  </div>
                </div>

                <div class="col-lg-9 col-md-12 marginbottom5 align-self-center">
                  <hr class="d-sm-block d-md-none d-lg-none"/>
                  <div class="pnrdeparture">
                    <div class="row">
                      <div class="col-5">
                        {{item.hotel.checkIn|date:'dd/MM/yyyy'}}
                      </div>
                      <div class="col-2">
                        <span class="material-icons">room_service</span>
                      </div>
                      <div class="col-5 mb-2">
                        {{item.hotel.checkOut|date:'dd/MM/yyyy'}}
                      </div>
                      <div class="col-12">
                        <div class="roomName">{{item.room.roomRates[0].text}}</div>
                      </div>
                    </div>

                  </div>

                </div>

                <div class="col-lg-3 col-md-12 marginbottom5 align-self-center">
                  <hr class="d-md-block d-lg-none"/>
                  <div *ngIf="item.status==='init'" class="dateaction">
                    {{'BOOKED_ON'|translate}} {{item.createdAt|date:'dd/MM/yyyy - HH:mm'}}
                  </div>
                  <div *ngIf="item.status==='issued'" class="dateaction">
                    {{'ISSUED_ON'|translate}} {{item.issuedOn|date:'dd/MM/yyyy - HH:mm'}}
                  </div>
                  <div *ngIf="item.guests.length>0">
                    <div *ngFor="let guest of item.guests" class="listpnrsinglepasseng">
                      <span *ngIf="guest.surname">{{guest.surname}}</span> <span *ngIf="guest.name"> {{guest.name}}</span> <sup> ({{guest.type}})</sup>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-3 align-self-center text-center">

              <div class="reservationimage">
                <img class="hotelIcon" src="assets/img/hotel-icon.png"/>
                <span style="text-transform:uppercase;">
                  {{item.hotel.name}}
                </span>
              </div>

              <div class="linktopnr">
                <a routerLink="/hotel/reservations/{{item._id}}">{{'OPEN_RESERVATION'|translate}}</a>
              </div>
            </div>

          </div>
        </div>

        <div class="" *ngSwitchCase="'car'">
          <div class="row">
            <div class="col-9 align-self-center">
              <div class="row">
                <div class="col-lg-12 col-md-6 marginbottom5 align-self-center">
                  <div class="pnrliststatus marginbottom5">
                    <span class="init" *ngIf="item.status==='init' && !item.waitingList">{{'TEMPLATE'|translate}}</span>
                    <span class="init" *ngIf="item.status==='init' && item.waitingList">{{'WAITING_CONFIRM'|translate}}</span>
                    <span class="linked" *ngIf="item.status==='linked'">{{'LINKED'|translate}}</span>
                    <span class="issued" *ngIf="item.status==='issued'">{{'ISSUED'|translate}}</span>
                    <span class="flown" *ngIf="item.status==='flown'">{{'FLOWN'|translate}}</span>
                    <span class="expired" *ngIf="item.status==='expired'">{{'EXPIRED'|translate}}</span>
                    <span class="cancelled" *ngIf="item.status==='cancelled'">{{'CANCELLED'|translate}}</span>
                  </div>
                </div>

                <div class="col-lg-9 col-md-12 marginbottom5 align-self-center">
                  <hr class="d-sm-block d-md-none d-lg-none"/>
                  <div class="pnrdeparture">
                    <div *ngIf="item.rentCompany.rentalLocations[1]" class="row">
                      <div class="col-5">
                        {{item.car.pickUpInfo.date|date:'dd/MM/yyyy'}}<br/>
                        {{item.rentCompany.rentalLocations[0].address}}
                      </div>
                      <div class="col-2">
                        <span class="material-icons">toys</span>
                      </div>
                      <div class="col-5">
                        {{item.car.dropOffInfo.date|date:'dd/MM/yyyy'}}<br/>
                        {{item.rentCompany.rentalLocations[1].address}}
                      </div>
                    </div>
                    <div *ngIf="!item.rentCompany.rentalLocations[1]" class="row">
                      <div class="col-12">
                        {{item.car.pickUpInfo.date|date:'dd/MM/yyyy'}}
                        <span class="material-icons">toys</span>
                        {{item.car.dropOffInfo.date|date:'dd/MM/yyyy'}}<br/>
                        {{item.rentCompany.rentalLocations[0].address}}
                      </div>
                    </div>
                  </div>

                  <div class="travelinfo">
                    {{item.car.model}}
                  </div>
                </div>

                <div class="col-lg-3 col-md-12 marginbottom5 align-self-center">
                  <hr class="d-md-block d-lg-none"/>
                  <div class="dateaction">
                    {{'BOOKED_ON'|translate}} {{item.createdAt|date:'dd/MM/yyyy - HH:mm'}}
                  </div>
                  <div *ngIf="item.passengers.length>0">
                    <div *ngFor="let passenger of item.passengers" class="listpnrsinglepasseng">
                      <span *ngIf="passenger.surname">{{passenger.surname}}</span> <span *ngIf="passenger.name"> {{passenger.name}}</span> <sup> ({{passenger.type}})</sup>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-3 align-self-center text-center">

              <div class="reservationimage">
                <img class="carIcon" src="{{item.rentCompany.images[0]}}"/>
                <span style="text-transform:uppercase;">
                  {{item.rentCompany.name}}
                </span>
              </div>
              <div class="linktopnr">
                <a routerLink="/car-rent/reservations/{{item._id}}">{{'OPEN_RESERVATION'|translate}}</a>
              </div>
            </div>
          </div>
        </div>

      </div>
      -->

    </div>
    <div class="card padding10" *ngIf="currentTab=='PASSENGERS'">
      {{'PASSENGERS'|translate}}
      <hr/>
      <div class="row">
        <div class="col-md-4" *ngFor="let traveler of travelers">
          <mat-card appearance="outlined" class="example-card">
            <mat-card-header>
              <div mat-card-avatar class="example-header-image"></div>
              <mat-card-title>{{traveler.name}} {{traveler.surname}}</mat-card-title>
              <mat-card-subtitle style="margin-bottom:10px;" *ngIf="traveler.email">{{traveler.email}}</mat-card-subtitle>
              <mat-card-subtitle style="margin-bottom:10px;" *ngIf="traveler.phoneNumber">{{traveler.phoneNumber}}</mat-card-subtitle>
              <mat-card-subtitle style="margin-bottom:0;" *ngIf="traveler.type">{{traveler.type|translate}}</mat-card-subtitle>
            </mat-card-header>

          </mat-card>
        </div>
      </div>

    </div>

    <div class="card padding10" *ngIf="currentTab=='MAP'">
      {{'MAP'|translate}}
      <hr/>
      <div class="" id="mapDossier"></div>
    </div>

  </div>
</div>

<div class="modal fade" id="travelListModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="travelListModal" aria-hidden="true">
  <div class="modal-dialog-scrollable modal-dialog modal-xl" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addGuestModalTitle">
          {{'ADD_TO_FOLDER'|translate}}
        </h5>
        <button type="button" class="close" (click)="toggleAddtoTravel(null)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="searchFl">
        <app-flightslist page="dossier" [dossierId]="dossier._id" (close)="closeAddtoTravel()"></app-flightslist>
      </div>
      <div class="modal-body" *ngIf="searchHo">
        <app-hotelslist page="dossier" [dossierId]="dossier._id" (close)="closeAddtoTravel()"></app-hotelslist>
      </div>
      <div class="modal-body" *ngIf="searchCar">
        <app-carslist-dos page="dossier" [dossierId]="dossier._id" (close)="closeAddtoTravel()"></app-carslist-dos>
      </div>
<!--      <div class="modal-body" *ngIf="searchTr">-->
<!--        <train-reservationlist page="dossier" [dossierId]="dossier._id" (close)="closeAddtoTravel()"></train-reservationlist>-->
<!--      </div>-->
      <hr/>

      <div class="text-right buttonrow">
        <button type="button" mat-button (click)="toggleAddtoTravel(null)">{{'CLOSE'|translate}}</button>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="removeModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="removeModal" aria-hidden="true">
  <div class="modal-dialog-scrollable modal-dialog" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="aremoveModalTitle">
          {{'REMOVE_FROM_FOLDER'|translate}}?
        </h5>
        <button type="button" class="close" (click)="removeItem(null, null)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{'ARE_YOUR_SHURE_TO_REMOVE_ITEM'|translate}}
      </div>
      <hr/>

      <div class="text-right buttonrow">
        <button type="button" mat-button (click)="removeItem(null, null)">{{'ABORT'|translate}}</button>
        <button type="button" mat-button (click)="delete()">{{'REMOVE'|translate}}</button>
      </div>

    </div>
  </div>
</div>
