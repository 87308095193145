import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-luggages',
  templateUrl: './luggages.component.html',
  styleUrls: ['./luggages.component.scss']
})
export class LuggagesComponent implements OnInit {
  @Input() reservation: any;
  @Input() required: any;
  @Output() onluggageAmount = new EventEmitter<number>();

  luggageSelection: any;
  tabs: any[];
  currentTab: number;
  round: boolean;
  currentPaxIndex: number;
  currentPax: any;
  luggageAmount: number;
  totalLuggagesAmount: number;
  ancillary: any[] = [];
  ancillaryAmount: number;
  constructor() {
    this.tabs = [];
    this.currentTab = 0;
    this.currentPaxIndex = 0;
    this.totalLuggagesAmount = 0;
  }

  ngOnInit(): void {
    if(this.reservation.ancillary){
      this.reservation.ancillary.forEach((a:any) => {
        const item = {
          'description' : a.description.trim().substr(1),
          'price' : a.description.split('-')[1],
          'amount' : a.description.split('-')[1].trim().split(' ')[0],
          'currency' : a.description.split('-')[1].trim().split(' ')[1],
          'id': a.id,
        }
        this.ancillary.push(item);
      })
    }
    // this.round = !Array.isArray(this.reservation.luggages);
    this.round = this.reservation.luggages.ret.length !== 0;
    this.tabs.length = this.reservation.itineraries.length;
    this.currentPax = this.reservation.passengers[0];
    this.luggageAmount = 0;
  }

  setCurrentTab(i) {
    this.currentTab = i;
  }

  setCurrentPax(i) {
    this.currentPaxIndex = i;
  }

  isSelectedAnc(id, i){
    if (!this.round) {
      const found = this.find(i, 'AdvancedAncillaryOptions');
      if (found !== -1) {
        return this.required[i][found] && this.required[i][found].value === id;
      }
    } else {
      const found = this.find(i, 'AdvancedAncillaryOptions');
      return this.required[i][found] && this.required[i][found].value === id;
    }
  }

  isSelected(id, i) {
    if (!this.round) {
      const found = this.find(i, 'LuggageOptions');
      if (found !== -1) {
        return this.required[i][found] && this.required[i][found].value === id;
      }
    } else {
      if (this.currentTab === 0) {
        const found = this.find(i, 'OutwardLuggageOptions');
        if (found !== -1) {
          return this.required[i][found] && this.required[i][found].value === id;
        }
      } else {
        const found = this.find(i, 'ReturnLuggageOptions');
        if (found !== -1) {
          return this.required[i][found] && this.required[i][found].value === id;
        }
      }
    }
  }

  selectAncid(id, i, amount, index){
    const anc = {
      Name: 'AdvancedAncillaryOptions',
      value: id,
      price: Math.round(amount),
      ref: index,
    };
    console.log(anc);
    this.ancillaryAmount += amount;
    if (this.required[i].length === 0) {
      this.required[i] = [];
      this.required[i].push(anc);
    } else {
      const found = this.find(i, 'AdvancedAncillaryOptions');
      if (found !== -1) {
        if (this.required[i][found].value === anc.value) {
          this.required[i].splice(found, 1);
        } else {
          this.required[i][found] = anc;
        }
      } else {
        this.required[i].push(anc);
      }
    }
    console.log(this.required);
    this.calculateTotal();
  }

  selectBagid(id, i, amount, index) {
    const bag = {
      Name: '',
      value: id,
      price: amount,
      ref: index,
    };
    if (!this.round) {
      bag.Name = 'LuggageOptions';
      this.luggageAmount = amount; // Number(amount.trim().split(' ')[0]);
      if (this.required[i].length === 0) {
        this.required[i] = [];
        this.required[i].push(bag);
      } else {
        const found = this.find(i, 'LuggageOptions');
        if (found !== -1) {
          if (this.required[i][found].value === bag.value) {
            this.required[i].splice(found, 1);
          } else {
            this.required[i][found] = bag;
          }
        } else {
          this.required[i].push(bag);
        }
      }
    } else {
      this.luggageAmount = 0;
      if (this.currentTab === 0) {
        bag.Name = 'OutwardLuggageOptions';
        this.luggageAmount += amount; // Number(amount.trim().split(' ')[0]);
        if (this.required[i].length === 0) {
          this.required[i] = [];
          this.required[i].push(bag);
        } else {
          const found = this.find(i, 'OutwardLuggageOptions');
          if (found !== -1) {
            if (this.required[i][found].value === bag.value) {
              this.required[i].splice(found, 1);
            } else {
              this.required[i][found] = bag;
            }
          } else {
            this.required[i].push(bag);
          }
        }
      } else {
        bag.Name = 'ReturnLuggageOptions';
        this.luggageAmount += amount; // Number(amount.trim().split(' ')[0]);
        if (this.required[i].length === 0) {
          this.required[i] = [];
          this.required[i].push(bag);
        } else {
          const found = this.find(i, 'ReturnLuggageOptions');
          if (found !== -1) {
            if (this.required[i][found].value === bag.value) {
              this.required[i].splice(found, 1);
            } else {
              this.required[i][found] = bag;
            }
          } else {
            this.required[i].push(bag);
          }
        }
      }
    }
    console.log(this.required);
    this.calculateTotal();
  }

  calculateTotal() {
    this.totalLuggagesAmount = 0;
    for (let i = 0; i < this.required.length; i++) {
      for (let s = 0; s < this.required[i].length; s++) {
        if (this.required[i][s].Name === 'AdvancedAncillaryOptions' || this.required[i][s].Name === 'ReturnLuggageOptions' || this.required[i][s].Name === 'OutwardLuggageOptions' || this.required[i][s].Name === 'LuggageOptions') {
          let amount = this.required[i][s].price;
          if (typeof amount !== 'number') {
            amount = parseFloat(amount);
          }
          this.totalLuggagesAmount += amount; // Number(amount.trim().split(' ')[0]);
        }
      }
    }
    this.onluggageAmount.emit(this.totalLuggagesAmount);
    console.log(this.totalLuggagesAmount);
  }

  find(i, name) {
    return this.required[i].findIndex(r => r.Name === name);
  }
}
