export class ChangesData {
  manageBook = false;
  changeBook = false;
  cancelBook = false;

  manageBookValue = ['LUGGAGE','INSURANCE','SEATS','DETAILS','TRAVEL_CLASS'];
  changeBookValues = ['OUT_DATE','RET_DATE', 'ORIGIN', 'DESTINATION'];
  cancelBookValues = ['CANCEL'];


  constructor(manageBook, changeBook, cancelBook) {
    this.manageBook = manageBook || false;  // ancillari
    this.changeBook = changeBook || false; // date e destinazioni
    this.cancelBook = cancelBook || false; // cancella
  }

  findFlow(selection){}

  checkDepartureDate(selectionOptions){
    for(const s of selectionOptions) {
      if(s.label.trim() === 'OUT_DATE' && s.selected) return true;
    }
    return false;
  }

  checkArrivalDate(selectionOptions){
    for(const s of selectionOptions){
      if(s.label.trim() === 'RET_DATE' && s.selected) return true;
    }
    return false;
  }

  checkOrigin(selectionOptions){
    for(const s of selectionOptions){
      if(s.label.trim() === 'ORIGIN' && s.selected) return true;
    }
    return false;
  }

  checkDestination(selectionOptions){
    for(const s of selectionOptions){
      if(s.label.trim() === 'DESTINATION' && s.selected) return true;
    }
    return false;
  }

  checkTemporaryOriginAndDestination(selectionOptions){
    for(const s of selectionOptions){
      if(s.label.trim() === 'LOCATIONS' && s.selected) return true;
    }
    return false;
  }

}
