<div class="mb-2">
  <div class="row buttonrow mb-3">
    <div class="col-6">
      <button *ngIf="currentStep>0" mat-raised-button class="mainbg" color="primary" (click)="openCurrentSelection(travel)">{{'CURRENT_SELECTION'|translate}}</button>
      <button class="mainbg mb-2" mat-stroked-button (click)="toggleForm()">
        <span *ngIf="!formvisible">{{'EDIT_SEARCH'|translate}}</span>
        <span *ngIf="formvisible">{{'HIDE_FORM'|translate}}</span>
      </button>
      <button (click)="toggleFilters()" class="mainbg mb-2" mat-stroked-button *ngIf="currentStep <= searchResults.itineraries.length-1">
        {{'OPEN_FILTERS'|translate}}
      </button>
      <br/><br/>
      <div *ngIf="currentStep<searchResults.itineraries.length">
        <span class="countStep">{{currentStep+1}}</span>
        {{'SELECT_FLIGHT'|translate}}: {{request.sections[currentStep].options.date | date:'dd/MM/yyyy'}} {{iataComplete.returnAirport(request.sections[currentStep].from)||request.sections[currentStep].from}}
        <span class="material-icons">arrow_right_alt</span> {{iataComplete.returnAirport(request.sections[currentStep].to)||request.sections[currentStep].to}}
        <br/>
        <div class="pt-2" style="font-size:10px;text-transform:uppercase;font-weight: bold;">
          <span class="material-icons" style="font-size:16px;margin:0 5px;">
            sell
          </span>
          <span *ngIf="searchResults.recommendations[0].cabins[currentStep] === 'Y'">Economic</span>
          <span *ngIf="searchResults.recommendations[0].cabins[currentStep] ==='M'">Economic standard</span>
          <span *ngIf="searchResults.recommendations[0].cabins[currentStep] === 'W'">Economic premium</span>
          <span *ngIf="searchResults.recommendations[0].cabins[currentStep] === 'C'">Business</span>
          <span *ngIf="searchResults.recommendations[0].cabins[currentStep] === 'F'">First</span>
        </div>

      </div>
      <div *ngIf="currentStep>=searchResults.itineraries.length" @listItemAnimation>
        <span class="countStep">{{currentStep+1}}</span>
        {{'SELECT_FARE'|translate}}
      </div>
    </div>
    <div *ngIf="currentStep>0" class="col-6 text-right">
      <button mat-stroked-button class="mainbg" color="primary" (click)="back()">
        <span class="material-icons">navigate_before</span> {{'BACK'|translate}}
      </button>
      <button [disabled]="checkPolicy(currentReco.tariff)" *ngIf="currentStep >= searchResults.itineraries.length && !loadingTar" mat-raised-button class="mainbg" color="primary" (click)="openDialog()">
        {{'PLACE_RESERVATION'|translate}}
      </button>
    </div>

  </div>

</div>
<div class="padding10" *ngIf="currentStep <= searchResults.itineraries.length-1"> <!-- *ngFor="let recommendation of searchResults.recommendations; let r = index; let l = last;" -->
  <div *ngFor="let section of filteredResults(); let s = index; let z = last">
    <div class="row singleStepSr" *ngIf="isInFilter(section) && (selectedStops >= section.flights.length-1)">
      <!-- <div *ngIf="returnNego(section.ref) && appComponent.isSbt" class="col-12 mb-2 negoBgGreen">{{'NEGOTIATED_FARE'|translate}}</div> -->
      <div *ngIf="returnNego(section.ref) && (appComponent.isB2B || appComponent.isSbt)" class="col-12 mb-2 negoBgGreen">{{'DISCOUNT_FARE'|translate}}</div>
      <div class="priceAbs" *ngIf="s>0">
        + {{returnAmountPlus(section.ref)|number:'.2-2'}}€
        <!-- + {{returnAmountPlus(recommendation.fare.amount)|number:'.2-2'}} € -->
      </div>
      <div class="col-12 collapse show" id="itinerary{{currentStep}}{{s}}">

        <div class="smalltext mb-2" *ngIf="iataComplete.carriersOptions[section.flights[0].marketingCarrier] && !appComponent.isB2B && !appComponent.isSbt">
          <div *ngIf="iataComplete.carriersOptions[section.flights[0].marketingCarrier].blockIssuance">
            L'emissione per questo vettore ha un costo aggiuntivo di {{iataComplete.carriersOptions[section.flights[0].marketingCarrier].feePerPax| number:'.2-2'}}€ per passeggero.<br/>
            Non è possibile emettere manualmente questa prenotazione, potrai contattare la bigliettaria per richiederne l'emissione dopo la prenotazione.
            <div>Il costo aggiuntivo è di {{(request.passengers.adt + request.passengers.chd + request.passengers.inf) * iataComplete.carriersOptions[section.flights[0].marketingCarrier].feePerPax| number:'.2-2'}}€</div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-1 d-sm-inline-block d-none text-center align-self-center">
            <img [matTooltip]="iataComplete.returnCarrier(section.flights[0].marketingCarrier)" class="marketingcarrierimage" src="assets/img/airline/icon/{{section.flights[0].marketingCarrier}}.png"/>
          </div>
          <div class="col-3 align-self-center">
            <b>{{iataComplete.returnAirport(section.flights[0].departure.location)}}</b><br/>
            {{section.flights[0].departure.dateTime|date:'dd/MM/yyyy'}} -
            {{returnZeroTime(section.flights[0].departure.hours)}}:
            {{returnZeroTime(section.flights[0].departure.minutes)}}
          </div>
          <div class="col-3 align-self-center">
            <div class="font-weight-bold">
              <span *ngIf="section.flights.length>1">
                {{section.flights.length-1}}
                <span *ngIf="section.flights.length-1 > 1">{{'STOPS'|translate}}</span>
                <span *ngIf="section.flights.length-1 === 1">{{'STOP'|translate}}</span>
              </span>
              <span *ngIf="section.flights.length==1">
                {{'NON_STOPS'|translate}}
              </span>
            </div>
            <div>{{returnTime(section.flyingTime)}}</div>
          </div>
          <div class="col-3 align-self-center">
            <b>{{iataComplete.returnAirport(section.flights[section.flights.length-1].arrival.location)}}</b><br/>
            {{section.flights[section.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy'}} -
            {{returnZeroTime(section.flights[section.flights.length-1].arrival.hours)}}
            {{returnZeroTime(section.flights[section.flights.length-1].arrival.minutes)}}
          </div>

          <div class="col-3 col-sm-2 text-right align-self-center">
            <div class="mb-1" style="font-size:12px;">
              <span *ngIf="request.sections.length == 1">{{'PRICE_ONE_WAY'|translate}}</span>
              <span *ngIf="request.sections.length == 2">{{'PRICE_ROUNDTRIP'|translate}}</span>
              <span *ngIf="request.sections.length > 2">{{'PRICE_MULTY'|translate}}</span>
            </div>
            <span class="price">
              {{returnAmount(section, section.ref)|number:'.2-2'}}€
            </span>
            <app-policy-alerts-icon [arrayOfAlerts]="section.arrayOfAlerts"></app-policy-alerts-icon>
            <br/>
            <div class="buttonrow">
              <button mat-stroked-button (click)="opeSect(currentStep,s)">
                {{'DETAILS'|translate}} <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
            <div class="buttonrow">
              <button mat-stroked-button color="primary" (click)="select(section.ref, section)">{{'SELECT'|translate}}</button>
            </div>

            <!-- <span class="price">{{recommendation.fare.amount|number:'.2-2'}} €</span> -->

          </div>
        </div>
      </div>

      <div class="col-12 collapse issection" id="collapse{{currentStep}}{{s}}">

        <div class="row" *ngFor="let flight of section.flights; let f = index;let fl = last;">

          <div class="col-12 mb-2" *ngIf="f===0">

            <div class="row">
              <div class="col-1 text-center">
                <img class="marketingcarrierimage" src="assets/img/airline/icon/{{flight.marketingCarrier}}.png"/>
              </div>
              <div class="col-9 align-self-center">
                <div>{{iataComplete.returnCarrier(flight.marketingCarrier)}}</div>
                <div class="md-titlebis">{{'DEPARTURE'|translate}}: {{flight.departure.date | date:'dd MMM yyyy'}}</div>
                <div class="md-subhead">{{returnTime(section.flyingTime)}}</div>
              </div>
              <div class="col-2 text-right buttonrow">
                <!--
                <button mat-icon-button (click)="opeSect(currentStep,s)">
                  <mat-icon>keyboard_arrow_up</mat-icon>
                </button>-->
                <div class="mb-1" style="font-size:12px;">
                  <span *ngIf="request.sections.length == 1">{{'PRICE_ONE_WAY'|translate}}</span>
                  <span *ngIf="request.sections.length == 2">{{'PRICE_ROUNDTRIP'|translate}}</span>
                  <span *ngIf="request.sections.length > 2">{{'PRICE_MULTY'|translate}}</span>
                </div>
                <span class="price">{{returnAmount(section, section.ref)}}€</span>
                <app-policy-alerts-icon [arrayOfAlerts]="section.arrayOfAlerts"></app-policy-alerts-icon>
                <br/>
                <button mat-stroked-button (click)="opeSect(currentStep,s)">{{'DETAILS'|translate}} <mat-icon>keyboard_arrow_up</mat-icon></button>
                <button mat-stroked-button color="primary" (click)="select(section.ref, section)">{{'SELECT'|translate}}</button>
              </div>
            </div>
            <hr/>

          </div>

          <div class="col-md-1 text-center">
            <img [matTooltip]="iataComplete.returnCarrier(flight.operatingCarrier)" class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
          </div>
          <div class="col-md-11">
            <div class="flightdet">
              <p class="headingseg">
                {{flight.departure.date|date:'dd/MM/yyyy'}} - {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
                {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.departure.location)}} ({{flight.departure.location}})
              </p>
              <p class="headingdetails">
                <!--{{'FLIGHT_TIME'|translate}}: {{calculateFlightTime(flight.departure.dateTime,flight.arrival.dateTime)}}-->
                <span *ngIf="flight.departure.terminal"> Terminal: {{flight.departure.terminal}}</span>
              </p>
              <p class="headingseg">
                <!--
                <span class="redt" *ngIf="otherday(section.flights[0].departure.date | date:'dd',flight.arrival.date | date:'dd')">
                  {{flight.arrival.date | date:'dd MMM yyyy'}} -
                </span> -->
                {{flight.arrival.date|date:'dd/MM/yyyy'}} -
                {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
                {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.arrival.location)}} ({{flight.arrival.location}})
              </p>
              <p class="fldetails">
                {{flight.operatingCarrier}} {{flight.number}} <span *ngIf="flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
                <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>
              </p>
            </div>

            <div class="scalepause" *ngIf="!fl">
            <span>
              <i class="fas fa-random"></i> {{'STOP'|translate}} in {{iataComplete.returnAirport(flight.arrival.location)}}
              <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}} </span>
            </span>
              <span class="alert-danger changeairport" *ngIf="flight.arrival.location != section.flights[f+1].departure.location">
               {{'CHANGE'|translate}}:
                {{iataComplete.returnAirport(section.flights[f+1].departure.location)}}
            </span>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

</div>


<div *ngIf="loadingTar" class="nowloading padding20 text-center">
  {{'LOADING'|translate}}
  <div class="currentForm">
    <p *ngFor="let sec of request.sections;">
      {{sec.options.date|date:'EEE dd MMM yyyy'}} {{sec.from}} <mat-icon>arrow_right_alt</mat-icon> {{sec.to}}
    </p>
  </div>
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>


<div *ngIf="currentStep == searchResults.itineraries.length && currentReco" @listItemAnimation>
  <div class="steptariffContainer" *ngIf="currentReco.rates">
    <div class="faresResults">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12" *ngFor="let fare of currentReco.rates;let f = index;">
          <div class="fareNameNew">
            <span *ngFor="let class of fare.details;let l = last;">
              <span>{{class.name||class.tariffClass}}</span> <span *ngIf="!l"> + </span>
            </span>
            <app-policy-alerts-icon [arrayOfAlerts]="fare.arrayOfAlerts"></app-policy-alerts-icon>
          </div>
          <div class="dialogFareNew" [ngClass]="{'selectedRate':fare.selected}">
            <div class="bagInfo">
              <div *ngIf="!currentReco.lowCost" class="mb-1">
                {{'CABINS'|translate}}:
                <span *ngFor="let class of fare.bookingClasses;let l = last;">
                  <span class="singleClass" [ngClass]="{'mr-1': !l}">{{class}}</span> <!-- <span *ngIf="!l"> -> </span> -->
                </span>
              </div>
              <div *ngIf="fare.passengers.ADT">
                <span class="material-icons">
                  work
                </span>
                {{'BAG_FOR_ADULT'|translate}}:
                <span *ngFor="let bag of fare.passengers.ADT.baggage; let i = index;let l = last">
                  <span *ngIf="l && i != 0"> - </span>
                  {{bag.allowance}}
                  <span *ngIf="bag.allowance < 10">
                            BAG
                        </span>
                        <span *ngIf="bag.allowance >= 10">
                          Kg
                        </span>
                </span>
              </div>
              <div *ngIf="fare.passengers.CHD">
                <span class="material-icons">
                  work
                </span>
                {{'BAG_FOR_CHILD'|translate}}:
                <span *ngFor="let bag of fare.passengers.CHD.baggage; let i = index;let l = last">
                  <span *ngIf="l && i != 0"> - </span>
                  {{bag.allowance}}
                  <span *ngIf="bag.allowance < 10">
                            BAG
                        </span>
                        <span *ngIf="bag.allowance >= 10">
                          Kg
                        </span>
                </span>
              </div>
              <div *ngIf="fare.passengers.INF">
                <span class="material-icons">
                  work
                </span>
                {{'BAG_FOR_INFANT'|translate}}:
                <span *ngFor="let bag of fare.passengers.INF.baggage; let i = index;let l = last">
                  <span *ngIf="l && i != 0"> - </span>
                  {{bag.allowance}}
                  <span *ngIf="bag.allowance < 10">
                            BAG
                        </span>
                        <span *ngIf="bag.allowance >= 10">
                          Kg
                        </span>
                </span>
              </div>
            </div>
            <div class="bottomActions">
            <span *ngIf="!fare.refundable">
              <span class="material-icons greytxt">
                cancel
              </span>
              {{'NO_REFOUND'|translate}}
            </span>
            <span *ngIf="fare.refundable">
              <span class="material-icons greentxt">
                check_circle
              </span> {{'YES_REFOUND'|translate}}
            </span>
            <span class="bottomInfoBtn" (click)="openBottomSheet(fare)">
              {{'FARE_INFO'|translate}}
            </span>
            <span class="bottomInfoBtn" (click)="askRules(fare)">
              {{'FARE_RULES'|translate}}
            </span>
              <!--
            <span class="material-icons yellow-text" matTooltip="Negotiated fare" *ngIf="fare.negotiated && appComponent.isSbt">
              star
            </span> -->
            <span class="material-icons yellow-text" matTooltip="Discount fare" *ngIf="fare.negotiated && (appComponent.isB2B || appComponent.isSbt)">
              star
            </span>
              <span class="material-icons yellow-text" matTooltip="Negotiated fare" *ngIf="fare.negotiated && !(appComponent.isB2B || appComponent.isSbt)">
              star
            </span>
            </div>
            <div class="farePrice" (click)="selectFare(f)">
              <span *ngIf="fare.negotiated && (!appComponent.isB2B && !appComponent.isSbt)">
                {{fare.fare.publicAmount|number:'.2-2'}}€
                <span class="material-icons">
                  arrow_right_alt
                </span>
                {{calculateNewFare(fare.fare.publicAmount, fare.fare.amount, fare.fare.fees)|number:'.2-2'}}€
                <!-- {{fare.fare.amount|number:'.2-2'}}€ -->
              </span>
              <!--
              <span *ngIf="fare.negotiated && appComponent.isSbt">
                {{fare.fare.publicAmount|number:'.2-2'}}€
                <span class="material-icons">
                  arrow_right_alt
                </span>
                {{fare.fare.amount|number:'.2-2'}}€
              </span> -->
              <span *ngIf="fare.negotiated && (appComponent.isB2B || appComponent.isSbt)">
                <del>{{fare.fare.publicAmount|number:'.2-2'}}€</del>
                <span class="material-icons">
                  arrow_right_alt
                </span>
                {{returnNegoB2B(fare.fare)|number:'.2-2'}}€
              </span>
              <span *ngIf="!fare.negotiated">
                {{fare.fare.amount|number:'.2-2'}}€
              </span>
            </div>
            <!--
            <div class="selectionFare">
              <span (click)="selectFare(f,r)" *ngIf="!fare.selected">
                <mat-icon>
                  check_box_outline_blank
                </mat-icon>
                <span>Select</span>
              </span>

              <span *ngIf="fare.selected">
                <mat-icon color="primary">
                  check_box
                </mat-icon>
                <span>Selected</span>
              </span>

            </div>
            -->
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="filterSearch">

  <div class="carrierFilter">
    <div class="mb-1"><label>{{'CARRIER'|translate}}</label></div>
    <span class="singleFilterCarrier" *ngFor="let carrier of carriersAvailable; let i = index" [ngClass]="{'greenborder':isIncludedCarr(carrier)}" (click)="filterCarrier(carrier)">
      <img [matTooltip]="iataComplete.returnCarrier(carrier)" class="filterCarrier" src="assets/img/airline/icon/{{carrier}}.png"/>
    </span>
  </div>
  <hr/>
  <div class="">
    <div class="mb-1"><label>{{'PRICE'|translate}}</label></div>
    <div class="row">
      <div class="col-4">
        {{minPriceModel}},00€
      </div>
      <div class="col-4 text-center">
        <span class="material-icons">
         sync_alt
        </span>
      </div>
      <div class="col-4 text-right">
        {{maxPriceModel}},00€
      </div>
    </div>
    <mat-slider
      class="diInline50"
      color="primary"
      [max]="maxPriceModel"
      [min]="minPrice"
      [step]="1"
      [(ngModel)]="minPriceModel"><input matSliderThumb /><input matSliderThumb /><input matSliderThumb />
    </mat-slider>
    <!--[thumbLabel]="true"-->
    <mat-slider
      class="diInline50"
      color="primary"
      [max]="maxPrice"
      [min]="minPriceModel"
      [step]="1"
      [(ngModel)]="maxPriceModel"><input matSliderThumb /><input matSliderThumb /><input matSliderThumb />
    </mat-slider>
    <!--[thumbLabel]="true"-->
  </div>
  <hr/>
  <div class="stopsFilter">
    <div class="mb-1"><label id="time-label">{{'TIME'|translate}}</label></div>
    <mat-radio-group
      aria-labelledby="stops-label"
      class="stops-radio-group"
      [(ngModel)]="defaultFilter"
      (ngModelChange)="changeTimeFilter()">
      <mat-radio-button class="stop-radio-button" *ngFor="let time of filterTime" [value]="time">
        {{time|translate}}
        <span *ngIf="time == 'morning'">(06 - 12)</span>
        <span *ngIf="time == 'afternoon'">(12 - 18)</span>
        <span *ngIf="time == 'evening'">(18 - 24)</span>
        <span *ngIf="time == 'night'">(0 - 6)</span>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <hr/>
  <div class="stopsFilter">
    <div class="mb-1"><label id="stops-label">{{'MAX_STOPS'|translate}}</label></div>

    <mat-radio-group
      aria-labelledby="stops-label"
      class="stops-radio-group"
      [(ngModel)]="selectedStops">
      <mat-radio-button class="stop-radio-button" *ngFor="let stop of availableStops" [value]="stop">
        <span *ngIf="stop === 0">{{'NON_STOPS'|translate}}</span>
        <span *ngIf="stop === 1">{{stop}} {{'STOP'|translate}}</span>
        <span *ngIf="stop > 1">{{stop}} {{'STOPS'|translate}}</span>
      </mat-radio-button>
    </mat-radio-group>
  </div>

</div>
<div (click)="toggleFilters()" class="filtersearchBackdrop"></div>
