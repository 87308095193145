import { Component, OnInit } from '@angular/core';
import {NotificationsService} from '../../../../services/notifications/notifications.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import {AppComponent} from '../../../../app.component';
@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.scss']
})
export class SendComponent implements OnInit {
  categories: any;
  to: any;
  filterCompany: any;
  filterUser: any;
  status: any;
  objtosend: any;
  sending: boolean;
  public Editor = ClassicEditor;
  constructor(
    private notificationService: NotificationsService,
    private snackBar: MatSnackBar,
    public appComponent: AppComponent,
  ) {
    this.sending = false;
    this.categories = [
      {
        name: 'Commerciale',
        value: 'adv'
      },
      {
        name: 'Di sistema',
        value: 'system'
      }
    ];
    this.to = {
      users: false,
      companies: false
    };
    this.filterCompany = [];
    this.filterUser = [];

    this.status = [
      {
        name: 'danger'
      },
      {
        name: 'warning'
      },
      {
        name: 'info'
      },
      {
        name: 'success'
      }
    ];
  }

  ngOnInit() {
    this.resetObj();
  }

  removeSelection(event) {
    this.filterCompany = [];
    this.filterUser = [];
  }

  resetObj() {
    this.objtosend = {
      subject : '',
      block : false,
      link: '',
      category: '',
      status: '',

      sendTo: {
        companies: [],
        users: [],
        all: true,
        b2b: undefined,
        cau: true
      },
      body: ''
    };
    this.filterCompany = [];
    this.filterUser = [];
  }

  sendNotification() {
    console.log('obj 1 => ', this.objtosend);
    if ( this.objtosend.sendTo.all) {
      this.objtosend.sendTo.companies = [];
      this.objtosend.sendTo.users = [];
    } else {
      this.objtosend.sendTo.b2b = undefined;
    }
    console.log('obj 2 => ', this.objtosend);
    this.notificationService.sendNotification(this.objtosend).subscribe((res) => {
      this.sending = false;
      this.resetObj();
      this.snackBar.open('Notification sended.', 'X', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
      });
    }, error => {
      this.sending = false;
      console.error('Error => ', error);
    });

  }

  public onChange( { editor }: ChangeEvent ) {
    this.objtosend.body = editor.getData();
    console.log('this.objtosend.body =>', this.objtosend.body);
  }

  isValidForm() {
    if (this.objtosend.sendTo.all) {
      return !!(this.objtosend.subject && this.objtosend.category && this.objtosend.status);
    } else {
      return this.objtosend.subject && this.objtosend.category && this.objtosend.status && (this.objtosend.sendTo.companies.length > 0 || this.objtosend.sendTo.users.length > 0);
    }
  }

}
