import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MainComponent} from '../../../../mainmodule/main/main.component';
import {UsersService} from '../../../../../services/profile/users.service';
import {Roles} from '../../../../../classes/profiles/role';
import {RoleService} from '../../../../../services/role/role.service';
import {SearchRes} from '../../../../../classes/flights/search-res';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent implements OnInit {
  role: Roles;
  oldRole: any;
  loading: boolean;
  @Input() user: string;
  // tslint:disable-next-line:no-output-rename
  @Output('update') change: EventEmitter<any> = new EventEmitter<any>();
  currentRole: any;
  constructor(
    private usersService: UsersService,
    public maincomponent: MainComponent,
    private roleServ: RoleService,
  ) {
    this.loading = false;
  }

  ngOnInit() {
    console.log('this.user => ', this.user);
    this.getUserRole();
  }

  abortEdit() {
    // console.log('this.oldRole => ',this.oldRole)
    this.getUserRole();
    // this.role = this.oldRole;
  }

  getUserRole() {
    this.loading = true;
    this.usersService.getUserRole(this.user).subscribe((res) => {
      this.loading = false;
      // this.getUser(id);
      this.role = res;
      this.change.emit(this.role);
      // this.oldRole = res;
      console.log('this.role => ', this.role);
      // this.getRole(res._id);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);

    });
  }

  getRole(id){
    this.roleServ.getRole(id).subscribe((res) => {
      this.currentRole = res;
      console.log('this.role => ', this.role);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  editUserRole() {
    this.loading = true;
    this.usersService.editUserRole(this.role, this.user).subscribe((res) => {
      this.loading = false;
      console.log('this.role => ', this.role);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);

    });
  }

  changeFlightChrono() {
    if (this.role.flight.search.get.company === false) {
      this.role.flight.search.post.company = false;
      this.role.flight.search.delete.company = false;
    }
  }

  changeUser() {
    if (this.role.profile.user.get.company === false) {
      this.role.profile.user.post.company = false;
    }
  }
  changeCompany() {
    if (this.role.profile.company.get.company === false) {
      this.role.profile.company.post.company = false;
    }
  }
  changeCorporate() {
    if (this.role.profile.company.corporate.read === false) {
      this.role.profile.company.corporate.post = false;
      this.role.profile.company.corporate.put = false;
      this.role.profile.company.corporate.delete = false;
    }
  }
  changeTickets() {
    if (this.role.isTktOperator === false) {
      this.role.tktZones.office = false;
      this.role.tktZones.tech = false;
    }
  }

}
