<div>
  <custom-title></custom-title>
  <div class="padding20">
    <!--
    <app-searchform-v2 (reset)="createHotelForm()" (updateFilters)="filtersChange($event)" (update)="resultChange($event)" (updatev2)="resultChangeV2($event)" *ngIf="formLoaded" [hotelDb]="hotelDb" [hotelResV2]="hotelResV2"
                         [hotelRes]="hotelRes" [hotelSearch]="hotelForm" [singleOccupancy]="singleOccupancy"></app-searchform-v2>
    -->
    <app-search-form-v3 [favorites]="favorites" (reset)="createHotelForm()" (update)="resultChange($event)" (updatev2)="resultChangeV2($event)" *ngIf="loadedFav && formLoaded" [hotelDb]="hotelDb" [hotelResV2]="hotelResV2"
                       [hotelRes]="hotelRes" [hotelSearch]="hotelForm" [singleOccupancy]="singleOccupancy"></app-search-form-v3>

    <div *ngIf="hotelRes">

      <app-hotelsearchresults *ngIf="hotelForm.nameSearch" [hotelDb]="hotelDb" [hotelRes]="hotelRes" [hotelSearch]="hotelForm"></app-hotelsearchresults>
      <!--
      <app-search-results-v2 *ngIf="!hotelForm.nameSearch" [filters]="filters" [hotelForm]="hotelForm" [hotelRes]="hotelRes"></app-search-results-v2>
      -->
      <app-search-results-v3 *ngIf="loadedFav && !hotelForm.nameSearch" [favorites]="favorites" [hotelForm]="hotelForm" [hotelRes]="hotelRes"></app-search-results-v3>
    </div>
    <!--
    <div *ngIf="hotelDb">
      <app-newresults [hotelForm]="hotelForm" [hotelDb]="hotelDb"></app-newresults>
    </div>
    -->
    <!--
    <div *ngIf="hotelDb">
      <app-hotelresultsv2 [hotelSearch]="hotelForm" [hotelResV2]="hotelDb"></app-hotelresultsv2>
    </div> -->

  </div>
</div>
