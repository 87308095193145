import {Component, Input, OnInit} from '@angular/core';
import {CarService} from '../../../../../services/car/car.service';

@Component({
  selector: 'app-cartst',
  templateUrl: './cartst.component.html',
  styleUrls: ['./cartst.component.scss']
})
export class CartstComponent implements OnInit {
  @Input() reservationId: any;
  loading: boolean;
  /**
   * Local variable for tst
   */
  tst: any;
  constructor(
    private carService: CarService,
  ) { }

  ngOnInit(): void {
    this.getCryptic();
  }

  /**
   * Call tst for given reservation
   * @params {reservationId} string
   */
  getTst(): any {
    this.loading = true;
    this.carService.getTst(this.reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.tst = this.toText(res.message);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  /**
   * Call tst history for given reservation
   * @params {reservationId} string
   */
  getTstHistory(): any {
    this.loading = true;
    this.carService.getTstHistory(this.reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.tst = this.toText(res.message);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getTsm(): any {
    this.loading = true;
    this.carService.getTsm(this.reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.tst = this.toText(res.message);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getCryptic(): any {
    this.loading = true;
    this.carService.getRetrieveCryptic(this.reservationId).subscribe((res) => {
      console.log('Res => ', res);
      this.tst = this.toText(res.message);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  /**
   * Add br to tst message
   * @params {toSet} string
   */
  toText(toSet) {
    return toSet.replace(new RegExp('\n', 'g'), '<br/>');
  }

}
