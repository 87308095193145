<custom-title></custom-title>
<div class="padding20">
  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <div *ngIf="!loading" class="searchrow">
    <div class="row">
      <div class="col-8">
        <mat-form-field class="marginfield">
          <input placeholder="{{'SEARCH'|translate}}" matInput [(ngModel)]="text" (change)="searchName()">
          <mat-icon *ngIf="!text" matSuffix>search</mat-icon>
          <button mat-icon-button class="iconButtons" *ngIf="text" matSuffix  aria-label="Clear" (click)="text='';searchName()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-hint align="start">{{'DIGIT_YOUR_SEARCH'|translate}}</mat-hint>
        </mat-form-field>

      </div>

      <div class="col-4 text-right" *ngIf="!page">
        <button class="iconButtons" matTooltip="{{'ADD_TRAVELER'|translate}}" mat-icon-button aria-label="Clear" (click)="togglenewTraveler()">
          <mat-icon>person_add</mat-icon>
        </button>
      </div>

    </div>
  </div>

  <div *ngIf="!loading" class="row paginators">
    <div class="col-12 d-flex justify-content-end text-right px-0 pr-3">
      <div class="paginationr pr-3">
        <span class="d-none d-sm-inline-block mx-2">{{'ITEMS_PER_PAGE'|translate}} {{limit}}</span>
        <span>
          {{'PAGE'|translate}}
          <mat-form-field class="selectPage m-lg-2 my-2 my-lg-0">
            <mat-select (ngModelChange)="changePage($event)" [(ngModel)]="currentpage">
              <mat-option *ngFor="let p of pagenumbers;" [value]="p">{{p + 1}}</mat-option>
            </mat-select>
            </mat-form-field>
            {{'OF'|translate}} {{maxpages}}
          </span>
        <button class="iconButtons ml-2" (click)="prev()" [disabled]="currentpage==0" mat-icon-button>
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button class="iconButtons" (click)="next()" [disabled]="currentpage==(maxpages-1)" mat-icon-button>
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="list_table" *ngIf="!loading">
    <div class="progressLoader" *ngIf="progress">
      <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
    </div>

    <div class="tablelist">
      <table mat-table [dataSource]="travelersList.list">

        <ng-container matColumnDef="select">
          <th mat-header-cell style="min-width: 85px;width:85px;" *matHeaderCellDef>
            <div *ngIf="!page">
              <mat-checkbox [ngModel]="isAllSelected()" (change)="selectOnAll()" [indeterminate]="someSelected()" class="example-margin">
              </mat-checkbox>
              <button *ngIf="selection.length>0" mat-icon-button aria-label="Delete" (click)="toggleDeleteTraveler()">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </th>
          <td mat-cell style="min-width: 85px;width:85px;" *matCellDef="let element">
            <div *ngIf="!page">
              <mat-checkbox [ngModel]="isSelected(element._id)" (change)="selectValue(element._id)" class="example-margin"> </mat-checkbox>
            </div>
            <div *ngIf="page!=undefined">
              <button class="iconButtons" [disabled]="isPaxSelected(element._id) || isDifferent(element)" mat-icon-button matTooltip="{{'SELECT'|translate}}"
                      (click)="selectTraveler(element)">
                <mat-icon>touch_app</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="data">
          <th mat-header-cell *matHeaderCellDef>
            <span class="sorting" matRipple matRippleCentered="true" (click)="sortResults('createdAt')">{{'CREATION'|translate}}</span>
            <mat-icon *ngIf="query.sort==='createdAt' && query.order==='asc'">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="query.sort==='createdAt' && query.order==='desc'">keyboard_arrow_up</mat-icon>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.createdAt|date:'dd/MM/yyyy - HH:mm'}} </td>
        </ng-container>


        <ng-container matColumnDef="name" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <span class="sorting tablepadding" matRipple matRippleCentered="true" (click)="sortResults('surname')">{{'SURNAME_NAME'|translate}}</span>
            <mat-icon *ngIf="query.sort==='surname' && query.order==='asc'">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="query.sort==='surname' && query.order==='desc'">keyboard_arrow_up</mat-icon>
          </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="page">
              <span class="tablepadding">
                <span matTooltip="{{element.surname}} {{element.name}}">{{element.surname}} {{element.name}}
                  <span style="font-size:10px" *ngIf="element.type">({{element.type}})</span>
                </span>
              </span>
            </div>
            <div *ngIf="!page">
              <span class="tablepadding">
                <button mat-button class="smallbtn" (click)="editTraveler(element)">
                 <span matTooltip="{{element.surname}} {{element.name}}">{{element.surname}} {{element.name}}
                   <span style="font-size:10px" *ngIf="element.type">({{element.type}})</span>
                 </span>
                </button>
              </span>
            </div>
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> {{'PHONE'|translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

  </div>
</div>

<div class="modal fade" id="addTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">
        <app-add (close)="closeEvent($event)" (refreshList)="getTravelersList()" [newTraveler]="null" [parent]="'travelersList'"></app-add>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="editTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="editingTraveler">

      <div class="modal-body">
        <app-edittraveler (close)="closeEditEvent($event)" (refreshList)="getTravelersList()" [currentTraveler]="editingTraveler" [parent]="'travelersList'">
        </app-edittraveler>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="deleteTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="deleteTravelerModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'CANCELLATION'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{'DELETE'|translate}} {{'PASSENGERS'|translate}}?
      </div>
      <div class="text-right">
        <button mat-button data-dismiss="modal">{{'ABORT'|translate}} </button>
        <button mat-button (click)="deleteTravelers()">{{'DELETE'|translate}} {{'TRAVELER'|translate}}</button>
      </div>
    </div>
  </div>
</div>
