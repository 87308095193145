import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CarService} from '../../../../services/car/car.service';
import {AppComponent} from '../../../../app.component';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {ActivatedRoute, Router} from '@angular/router';
import {PriceService} from '../../../../services/price/price.service';
import {DossierService} from '../../../../services/dossier/dossier.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-carslist-dos',
  templateUrl: './carslist.component.html',
  styleUrls: ['./carslist.component.scss']
})
export class CarslistComponent implements OnInit {
  currentTab: number;
  reservationlist: any;
  @Input() idcompany: string;
  @Input() iduser: string;
  @Input() dossierId: string;
  // tslint:disable-next-line:no-output-native no-output-rename
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();
  loading: boolean;
  currentpage: number;
  maxpages: number;
  query: any;
  limit: number;
  skip: number;
  sort: string;
  order: boolean;
  progress: boolean;
  text: string;
  filterCompanies = [];
  pagenumbers = [];
  currentCarId: any;
  constructor(
    private carService: CarService,
    public appComponent: AppComponent,
    public mainComponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    public priceservice: PriceService,
    private dossierService: DossierService,
  ) {
    this.currentTab = 1;
    this.loading = true;
    this.progress = false;
  }

  ngOnInit(): void {
    this.limit = 10;
    this.sort = 'createdAt';
    this.order = true;
    this.currentpage = 0;
    this.skip = (this.currentpage) * this.limit;

    this.query = {
      options: {
        tab: 'active',
        companies: [],
        users: [],
      },
      sort: this.sort,
      limit: this.limit,
      skip: this.skip,
      order: this.order ? 'asc' : 'desc',
      text: '',
    };

    this.route.queryParams.subscribe(params => {
      this.query.sort = params.sort || this.sort;
      this.query.limit = params.limit || this.limit;
      this.query.skip = params.skip || this.skip;
      this.query.order = params.order || 'asc';
      this.query.text = params.text || '';
      this.query.options = (params.options) ? JSON.parse(params.options) : this.query.options;
      this.text = params.text || '';
      this.currentpage = this.query.skip / this.query.limit;
      if ( this.filterCompanies.length === 0 ) { this.filterCompanies = JSON.parse(JSON.stringify(this.query.options.companies)); }
      this.getReservationList();
    });

    this.loading = false;
  }

  // tslint:disable-next-line:variable-name
  returnTabNum(string) {
    if (this.reservationlist) { return this.reservationlist.tabs[string]; }
    if (!this.reservationlist) { return 0; }
  }

  removeCpFilter(id) {
    const index =  this.filterCompanies.findIndex( r => r._id === id);
    if ( index > -1 ) {
      this.query.options.companies.splice(index, 1);
      this.filterCompanies.splice(index, 1);
      this.currentpage = 0;
      this.query.skip = 0;
      this.navigate();
    }
  }

  clearAllCp() {
    this.query.options.companies = [];
    this.filterCompanies = [];
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  setCpFilter() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  changeTab(value) {
    if (value === 'init') {
      this.query.sort = 'createdAt';
      this.sort = 'createdAt';
    }
    if (value === 'linked' || value === 'withoutTL') {
      this.query.sort = 'generatedAt';
      this.sort = 'generatedAt';
    }
    if (value === 'active') {
      this.query.sort = 'createdAt';
      this.sort = 'createdAt';
    }
    if (value === 'linked' || value === 'expired' || value === 'control') {
      this.query.sort = 'generatedAt';
      this.sort = 'generatedAt';
    }
    if (value === 'departures') {
      this.query.sort = 'generatedAt';
      this.sort = 'generatedAt';
    }
    if (value === 'deadline') {
      this.query.sort = 'timeLimit';
      this.sort = 'timeLimit';
    }
    if (value === 'issued' || value === 'flown') {
      this.query.sort = 'issuedOn';
      this.sort = 'issuedOn';
    }
    if (value === 'cancelled') {
      this.query.sort = 'cancelledAt';
      this.sort = 'cancelledAt';
    }
    if (value === 'deadline') {
      this.order = false;
      this.query.order = 'desc';
    }
    if (this.query.options.tab !== value) {
      this.query.options.tab = value;
      this.currentpage = 0;
      this.query.skip = 0;
      this.navigate();
    }
  }

  sortValue(value) {
    this.query.sort = value;
    this.sort = value;
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  reverseOrder() {
    if (!this.order) {
      this.query.order = 'asc';
      this.order = true;
    } else {
      this.query.order = 'desc';
      this.order = false;
    }
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  openFilters() {
    $('.pnrfilters').collapse('toggle');
  }

  setFilterText() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.navigate();
  }

  clearFilterText() {
    this.currentpage = 0;
    this.query.skip = 0;
    this.query.text = '';
    this.navigate();
  }

  navigate() {
    this.getReservationList();
  }

  next() {
    this.currentpage = (this.query.skip / this.query.limit) + 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  prev() {
    this.currentpage = this.query.skip / this.query.limit - 1;
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  changePage(value) {
    this.currentpage = value;
    console.log(this.currentpage);
    this.query.skip = this.currentpage * this.query.limit;
    this.navigate();
  }

  getReservationList() {
    console.log('this.query => ', this.query);
    this.progress = true;
    this.carService.getReservationsList(this.query).subscribe((res) => {
      this.loading = false;
      this.reservationlist = res;
      setTimeout(() => {
        console.log('hide');
        this.progress = false;
      }, 500);
      console.log('this.reservationlist => ', this.reservationlist);
      this.maxpages = Math.ceil((this.reservationlist.count / this.limit));
      if (this.maxpages === 0) { this.maxpages = 1; }
      const numeration = [];
      for (let i = 0; i < this.maxpages; i++) {
        numeration.push(i);
      }
      this.pagenumbers = numeration;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  addCars(id) {
    const body = {
      cars: [id]
    };
    this.dossierService.addCarsToDossier(this.dossierId, body).subscribe((res) => {
      console.log('res => ', res);
      this.close.emit();
    }, error => {
      console.error('res => ', error);
    });
  }

  returnDate(value) {
    return new Date(value);
  }

}
