import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../../../app.component';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TicketingService} from '../../../../services/ticketing/ticketing.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-singleticket',
  templateUrl: './singleticket.component.html',
  styleUrls: ['./singleticket.component.scss']
})
export class SingleticketComponent implements OnInit {
  loading: boolean;
  id: string;
  ticket: any;
  chatMode: boolean;
  newMessage: string;
  sendAnswer: boolean;
  operators: any;
  operator: string;
  sections: string[];
  departments: string[];
  loadActions: boolean;
  jiraTkt: boolean = false;
  constructor(
    public appComponent: AppComponent,
    public mainComponent: MainComponent,
    private route: ActivatedRoute,
    private router: Router,
    private ticketService: TicketingService,
  ) {
    this.loading = true;
    this.chatMode = true;
    this.newMessage = '';
    this.operator = '';
    this.sections = ['flight', 'hotel', 'request'];
    this.departments = ['tech', 'office'];
    this.loadActions = false;
  }

  ngOnInit(): void {
    if (!this.appComponent.isSbt && !this.appComponent.isGat) {
      this.jiraTkt = true;
    }
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (!this.jiraTkt) {
        this.getTicketDetail();
      } else {
        this.getJiraTicketDetail();
      }

    });
    // this.getOperators();
  }

  getJiraTicketDetail() {
    console.log('this.id => ', this.id);
    this.ticketService.getJiraTicketDetails(this.id).subscribe((res) => {
      this.loading = false;
      this.ticket = res;
      console.log('this.ticket => ', this.ticket);
      if (this.chatMode) {
        $('.ChatMain').animate({ scrollTop: 6000 }, 1000);
      }
      this.scrollTo();
      if (this.ticket.operator) {
        this.operator = this.ticket.operator;
      }
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
      this.getTicketDetail();
    });
  }

  getTicketDetail() {
    this.jiraTkt = false;
    console.log('this.id => ', this.id);
    this.ticketService.getTicketDetails(this.id).subscribe((res) => {
      this.loading = false;
      this.ticket = res;
      console.log('this.ticket => ', this.ticket);
      if (this.chatMode) {
        $('.ChatMain').animate({ scrollTop: 6000 }, 1000);
      }
      this.scrollTo();
      if (this.ticket.operator) {
        this.operator = this.ticket.operator;
      }
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  scrollTo() {
    $('.mat-sidenav-content').animate({ scrollTop: 6000 }, 1000);
  }

  returnImage(id) {
    return this.ticketService.apiUrl + 'api/static/user/' + id + '.png';
  }

  toggleChat(event) {
    this.chatMode = event.checked;
    if (this.chatMode) {
      setTimeout(() => {
        $('.ChatMain').animate({ scrollTop: 6000 }, 1000);
      }, 500);
    }
    $('.mat-sidenav-content').animate({ scrollTop: 6000 }, 1000);
  }

  putTicket() {
    this.sendAnswer = true;
    const body = {
      data: this.newMessage,
    };
    this.ticketService.putTicketAnswer(body, this.id).subscribe((res) => {
      console.log('this.ticket => ', this.ticket);
      this.newMessage = '';
      this.sendAnswer = false;
      this.getTicketDetail();
    }, error => {
      this.sendAnswer = false;
      console.error('Error => ', error);
    });
  }

  postJiraTicket() {
    this.sendAnswer = true;
    const body = this.newMessage;
    this.ticketService.postJiraTicketAnswer(body, this.id).subscribe((res) => {
      console.log('this.ticket => ', this.ticket);
      this.newMessage = '';
      setTimeout(() => {
        this.sendAnswer = false;
        this.getJiraTicketDetail();
      }, 5000);
    }, error => {
      this.newMessage = '';

      setTimeout(() => {
        this.sendAnswer = false;
        this.getJiraTicketDetail();
      }, 5000);
      console.error('Error => ', error);
    });
  }

  truncateObj(value) {
    const totaltitle = value;
    const index = totaltitle.indexOf(':');
    const newtitle = totaltitle.substr(index + 1);
    return newtitle;
  }

  getOperators() {
    console.log('this.id => ', this.id);
    this.ticketService.getOperators().subscribe((res) => {
      this.operators = res;
    }, error => {
      console.error('Error => ', error);
    });
  }

  setOperator() {
    this.loadActions = true;
    this.ticketService.setOperator(this.operator, this.id).subscribe((res) => {
      this.loadActions = false;
      this.getTicketDetail();
    }, error => {
      this.loadActions = false;
      console.error('Error => ', error);
    });
  }

  setSection() {
    this.loadActions = true;
    this.ticketService.setSection(this.ticket.section, this.id).subscribe((res) => {
      this.loadActions = false;
      this.getTicketDetail();
    }, error => {
      this.loadActions = false;
      console.error('Error => ', error);
    });
  }

  setDepartment() {
    this.loadActions = true;
    this.ticketService.setDepartment(this.ticket.department, this.id).subscribe((res) => {
      this.loadActions = false;
      this.getTicketDetail();
    }, error => {
      this.loadActions = false;
      console.error('Error => ', error);
    });
  }

  closeTkt() {
    this.loadActions = true;
    this.ticketService.closeTkt(this.id).subscribe((res) => {
      this.loadActions = false;
      this.getTicketDetail();
    }, error => {
      this.loadActions = false;
      console.error('Error => ', error);
    });
  }

  returnType(value) {
    if (value === 'ADT') {return 'ADULTS'; }
    if (value === 'CHD') {return 'CHILDREN'; }
    if (value === 'INF') {return 'INFANT'; }
  }

}
