import { Component, OnInit } from '@angular/core';
import {StatisticService} from '../../../../services/statistic/statistic.service';

@Component({
  selector: 'app-rss',
  templateUrl: './rss.component.html',
  styleUrls: ['./rss.component.scss']
})
export class RssComponent implements OnInit {
  rss: any;
  constructor(
    private statsService: StatisticService,
  ) { }

  ngOnInit(): void {
    this.getRss();
  }

  getRss(): void {
    this.statsService.getRss().subscribe((res) => {
      this.rss = res;
    }, error => {
      console.error('Error => ', error);
    });
  }

}
