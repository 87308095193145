import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserGroupsService } from '../../services/user-groups.service';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-select-group-dialog',
  templateUrl: './select-group-dialog.component.html',
  styleUrls: [ './select-group-dialog.component.scss' ]
})
export class SelectGroupDialogComponent implements OnInit {

  loading = false;
  group: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<SelectGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userGroupsService: UserGroupsService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.group = this.data.selectedGroup;
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onSaveClick(): void {
    this.loading = true;
    const body = {
      user: this.data.user,
      group: this.group
    };
    this.userGroupsService.assignGroup(body).pipe(take(1)).subscribe(result => {
        this.snackBar.open('Group Edited Successfully', 'Close');
        this.loading = false;
        this.dialogRef.close(true);
      },
      error => {
        this.loading = false;
        this.snackBar.open(error.errorMessage, 'Close');
      }
    );

  }

}
