<div class="default_panel">
  <div class="default_heading d-flex justify-content-between">
    <div class="d-flex align-self-center">Visits</div>
    <div class="d-flex align-items-center">
      <button (click)="buildChart()" mat-icon-button aria-label="Refresh" class="small-md">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
  <div class="default_content">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke"
      [theme]="chartOptions.theme"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
    ></apx-chart>
  </div>

</div>
