<div class="mb-2">
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" src="/assets/city/new-york.jpeg" alt="First slide">
        <div class="carousel-caption d-md-block">
          <p>
            Le ricerche con un raggio minore saranno più veloci
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/city/rome.jpeg" alt="Second slide">
        <div class="carousel-caption d-md-block">
          <p>
            Cerca utilizzando un punto di interesse. <br/>
            Es: Colosseo Roma.
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/city/paris.jpeg" alt="Second slide">
        <div class="carousel-caption d-md-block">
          <p>
            Alcuni risultati saranno presenti più volte a causa delle molte tariffe disponibili
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/city/sydney.jpeg" alt="Second slide">
        <div class="carousel-caption d-md-block">
          <p>
            Stiamo interrogando piu di 1000 provider.
          </p>

        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
